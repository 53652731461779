<template>
	<Typography
		v-tooltip="{
			content: getDateTooltip
		}"
		:data-qa="dataQa"
		:type="type"
		:color="color"
	>
		<span v-if="prefix">{{ prefix }}</span>
		{{ calculatedTime }}
		<span v-if="user"> by <UserNameEmail :user-id="user" /></span>
		<template v-if="postfix">{{ postfix }}</template>
	</Typography>
</template>

<script lang="ts">
import { Typography, TypographyTypes, TypographyColor } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { UserNameEmail } from "@/shared/components";
import { formatDateToRegularFormat, getRelativeTime } from "@/utils";

export default defineComponent({
	name: "CustomTimeStamp",

	components: {
		UserNameEmail,
		Typography
	},

	props: {
		type: {
			type: String as PropType<TypographyTypes>,
			default: () => "p2"
		},

		color: {
			type: String as PropType<TypographyColor>,
			default: () => "light"
		},

		time: {
			type: String,
			required: true
		},

		prefix: {
			type: String
		},

		postfix: String,

		user: {
			type: String
		},

		format: {
			type: String as PropType<"relative" | "absolute">,
			default: "relative"
		},

		dataQa: {
			type: String,
			default: "time-stamp"
		}
	},

	data() {
		return {
			timer: null as ReturnType<typeof setInterval> | null,
			calculatedTime: ""
		};
	},

	computed: {
		formattedTime() {
			return this.time.length === 13 ? Number(this.time) : Number(this.time) * 1000;
		},

		getDateTooltip() {
			return formatDateToRegularFormat(this.formattedTime);
		}
	},

	watch: {
		formattedTime: {
			immediate: true,

			handler() {
				this.updateTime();
				this.clearTimer();

				if (this.format === "relative") {
					const timeDifference = Date.now() - this.formattedTime;

					// We only need to update time in realtime if it's less than 60 minutes
					if (timeDifference < 1000 * 60 * 60) {
						this.timer = setInterval(this.updateTime, 1000);
					}
				}
			}
		}
	},

	unmounted() {
		this.clearTimer();
	},

	methods: {
		clearTimer() {
			if (this.timer) {
				clearInterval(this.timer);
			}
		},

		updateTime() {
			this.calculatedTime =
				this.format === "absolute"
					? formatDateToRegularFormat(this.formattedTime)
					: getRelativeTime(this.formattedTime);
		}
	}
});
</script>
