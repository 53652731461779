<template>
	<Container
		:gap="0"
		:padding="0"
		separator
		:grow="1"
		align="stretch"
		data-qa-org-settings-page
		class="height-100-per flex-shrink-0"
	>
		<Wrapper width="320px">
			<MenuOptions
				:selected-tab="selectedTab"
				:members-count="totalOrgMembers ?? undefined"
				data-qa-select-org-view
				@select="select"
			/>
		</Wrapper>
		<Container :grow="1" :padding="0">
			<Wrapper v-if="activeOrg && selectedTab === 'overview'">
				<OrgDetails :active-org="activeOrg" data-qa-org-info />
			</Wrapper>
			<Wrapper v-if="activeOrg && selectedTab === 'members'">
				<Container padding="10px" class="height-100-per">
					<OrgMembersTab
						v-if="activeOrg && activeOrg?.id"
						:org="activeOrg"
						data-qa-org-members-view
					/>
				</Container>
			</Wrapper>
			<Wrapper v-if="activeOrg && selectedTab === 'dependencies'">
				<Container padding="10px" class="height-100-per">
					<DependencyListTab />
				</Container>
			</Wrapper>
			<Wrapper v-if="activeOrg && selectedTab === 'policy'">
				<Container padding="10px" class="height-100-per">
					<PolicyListTabDetails />
				</Container>
			</Wrapper>
			<Wrapper v-if="activeOrg && selectedTab === 'env-classifications'">
				<Container padding="20px 10px" class="height-100-per">
					<EnvClassification />
				</Container>
			</Wrapper>
			<Wrapper v-if="activeOrg && selectedTab === 'credentials'">
				<Container padding="10px" class="height-100-per">
					<CredentialsWrapper :is-org-level="true" />
				</Container>
			</Wrapper>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { breadcrumbStore } from "@/modules/core/breadcrumb-store";
import CredentialsWrapper from "@/modules/credentials/views/CredentialsWrapper.vue";
import DependencyListTab from "@/modules/dependency-registry/components/DependencyListTab.vue";
import EnvClassification from "@/modules/env-classification/EnvClassification.vue";
import OrgMembersTab from "@/modules/members/components/OrgMembersTab.vue";
import { membersStore } from "@/modules/members/members-store";
import PolicyListTabDetails from "@/modules/project-list/PolicyListTabDetails.vue";
import { userStore } from "@/modules/user/user-store";

import { orgStore } from "../../org-store";
import MenuOptions from "../components/MenuOptions.vue";
import OrgDetails from "../components/OrgDetails.vue";
import { SelectedTabType } from "../org-settings-types";

const TabToHeader: Record<SelectedTabType, string> = {
	overview: "Organization Overview",
	members: "Organization Members",
	dependencies: "Organization Dependencies",
	policy: "Organization Control Gates",
	"env-classifications": "Organization Environment classifications",
	credentials: "Organization Credentials",
	"execution-platforms": "Organization Execution Platforms"
};

export default defineComponent({
	name: "OrgSettingsWrapper",

	components: {
		Container,
		Wrapper,
		MenuOptions,
		OrgDetails,
		OrgMembersTab,
		DependencyListTab,
		PolicyListTabDetails,
		EnvClassification,
		CredentialsWrapper
	},

	data: () => ({
		selectedTab: "overview" as SelectedTabType
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		organizations() {
			return orgStore.GET_ORGS;
		},

		activeOrg() {
			const { orgId } = this.$route.params;
			return this.organizations.find(matchingOrg => matchingOrg.id === orgId);
		},

		title() {
			const { orgs } = orgStore;
			const { orgId } = this.$route.params;
			const org = orgs.find(matchingOrg => matchingOrg.id === orgId);
			return org?.name;
		},

		user() {
			return userStore.profile;
		},

		totalOrgMembers() {
			const invitedMembers = membersStore.invitedUser[this.orgId] ?? [];
			const orgMembers = membersStore.members[this.orgId] ?? [];

			return invitedMembers.length + orgMembers.length;
		}
	},

	watch: {
		$route: {
			handler(to, from) {
				const { tabName } = this.$route.params;
				/* Update tab as well */
				if (to !== from && tabName && tabName !== this.selectedTab) {
					this.selectedTab = tabName as SelectedTabType;
				}
			},

			immediate: true
		},

		selectedTab: {
			immediate: true,

			handler(selectedTab: SelectedTabType) {
				breadcrumbStore.SET_ADDITIONAL_BREADCRUMBS([
					{
						qaId: `org-settings-${selectedTab}`,
						label: TabToHeader[selectedTab],
						route: {
							name: "org-settings-global",
							props: {
								tabName: selectedTab
							}
						}
					}
				]);
			}
		},

		title: {
			immediate: true,

			handler() {
				if ("document" in window && this.title) {
					window.document.title = this.title;
				}
			}
		}
	},

	mounted() {
		(async () => {
			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
			this.selectedTab = (this.$route.params.tabName || "overview") as SelectedTabType;

			if (await orgStore.IS_USER_ORG_ADMIN({ orgId: this.orgId })) {
				this.loadInfoPopover();

				membersStore.FETCH_USERS({
					orgId: this.orgId,
					invite: {
						org: {
							id: this.orgId
						}
					}
				});
			}
		})();
	},

	methods: {
		loadInfoPopover() {
			const { tabName } = this.$route.params;
			if (!tabName && this.user?.metadata?.checkListFlow?.orgFlow) {
				this.selectedTab = "credentials";
			}
		},

		select(tabName: SelectedTabType) {
			if (this.selectedTab !== tabName) {
				this.selectedTab = tabName;
				this.$router.replace({
					name: "org-settings-global",
					params: {
						orgId: this.orgId,
						tabName
					}
				});
			}
		}
	}
});
</script>
