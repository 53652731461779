<template>
	<Container :padding="0" :grow="1" align="center" align-self="center">
		<EmptyState :icon="emptyIcon" shape="squircle">
			<Container
				data-qa-empty-members-list
				align="center"
				direction="column"
				:gap="24"
				:padding="0"
				align-self="center"
			>
				<Container
					v-if="fromSearch"
					align="center"
					:gap="8"
					direction="column"
					:padding="0"
					data-qa-empty-search-members
				>
					<Typography type="h3" weight="bold" color="dark">No Results Found</Typography>
					<Typography type="p1-para" color="gray-100" class="width-350 text-align-center"
						>No members found for your search term.</Typography
					>
				</Container>

				<Container
					v-else
					align="center"
					:gap="8"
					direction="column"
					:shrink="0"
					:padding="0"
					data-qa-empty-members
				>
					<Typography type="h3" weight="bold" color="dark" data-qa-empty-members-text
						>Invite members</Typography
					>
					<Typography type="p1-para" color="gray-100" class="width-350 text-align-center"
						>Invite Developers or DevOps engineers from your team here.</Typography
					>
				</Container>

				<slot />
			</Container>
		</EmptyState>
	</Container>
</template>
<script lang="ts">
import { Container, Typography, EmptyState, EmptyStateIconProp } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyMembers",

	components: {
		Container,
		Typography,
		EmptyState
	},

	props: {
		fromSearch: {
			type: Boolean
		}
	},

	data: () => ({
		emptyIcon: {
			name: "i-user",
			type: "filled",
			state: "light",
			size: "large"
		} as EmptyStateIconProp
	})
});
</script>
