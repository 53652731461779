import { componentListItem } from "@/protocol/infra";

export type FilterOption = {
	id?: string;
	text?: string;
	selected?: boolean;
};

export type GroupComponents = {
	groupName: string;
	componentsList: componentListItem[];
};

export const CommonProviders = ["azurerm", "aws", "google", "azure"];
