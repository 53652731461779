<template>
	<Container
		:gap="0"
		padding="0px 0px 0px 0px"
		separator
		:grow="1"
		align="stretch"
		data-qa-org-settings-page
		class="height-100-per flex-shrink-0"
	>
		<Wrapper width="260px">
			<SettingsTab :selected-tab="selectedTab" @select="select" />
		</Wrapper>
		<Container :grow="1" :padding="0">
			<Wrapper v-if="selectedTab === projectSettingsTabValuesObj.details">
				<ProjectDetails
					data-qa-project-settings-project-details
					data-qa-org-info
					:project="project"
					:selected-policy="selectedPolicy"
					:envs-count="envsCount"
					:apps-count="appsCount"
					:variables-count="variablesCount"
				/>
			</Wrapper>
			<Wrapper v-if="selectedTab === projectSettingsTabValuesObj.members">
				<Container padding="15px 10px 0px 10px" class="height-100-per">
					<ProjectMembersTab :project="project" data-qa-project-settings-project-members />
				</Container>
			</Wrapper>
			<Wrapper v-if="selectedTab === projectSettingsTabValuesObj.credentials">
				<Container padding="15px 10px 0px 10px" class="height-100-per">
					<CredentialsWrapper
						:project="project"
						data-qa-project-settings-project-credentials
						:is-org-level="false"
					/>
				</Container>
			</Wrapper>
			<Wrapper v-if="selectedTab === projectSettingsTabValuesObj.classifications">
				<Container padding="15px" class="height-100-per" align="left top">
					<EnvClassification :project="project" data-qa-project-settings-project-classifications />
				</Container>
			</Wrapper>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import CredentialsWrapper from "@/modules/credentials/views/CredentialsWrapper.vue";
import EnvClassification from "@/modules/env-classification/EnvClassification.vue";
import ProjectMembersTab from "@/modules/members/components/ProjectMembersTab.vue";
import { project } from "@/protocol/identity";
import { PolicySet } from "@/protocol/validator";

import { ProjectSettingsTabName, ProjectSettingsTabValues } from "../project-types";

import ProjectDetails from "./ProjectDetails.vue";
import SettingsTab from "./SettingsTab.vue";

export default defineComponent({
	name: "SettingsTabWrapper",

	components: {
		Container,
		Wrapper,
		SettingsTab,
		ProjectDetails,
		ProjectMembersTab,
		CredentialsWrapper,
		EnvClassification
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		},

		selectedPolicy: {
			type: Object as PropType<PolicySet | null>
		},

		envsCount: {
			type: Number,
			required: true
		},

		appsCount: {
			type: Number,
			required: true
		},

		variablesCount: {
			type: Number,
			required: true
		},

		parentTabName: {
			type: String,
			default: "settings"
		}
	},

	data: () => ({
		selectedTab: "details" as ProjectSettingsTabName,
		projectSettingsTabValuesObj: ProjectSettingsTabValues
	}),

	mounted() {
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
		this.selectedTab = (this.$route.params.subSectionTab || "details") as ProjectSettingsTabName;
	},

	methods: {
		async select(tabName: ProjectSettingsTabName) {
			if (this.selectedTab !== tabName) {
				this.selectedTab = tabName;
				await this.$router.push({
					name: "projectListWithProject",
					params: {
						orgId: this.project.orgId,
						projectId: this.project.id,
						tabName: this.parentTabName,
						subSectionTab: tabName
					}
				});
			}
		}
	}
});
</script>
