export type ProjectTabName =
	| "environments"
	| "applications"
	| "output"
	| "policies"
	| "variables"
	| "members"
	| "credentials"
	| "code-promotion"
	| "settings";

export type ProjectSettingsTabName =
	| "details"
	| "credentials"
	| "classifications"
	| "activityLogs"
	| "members";

export enum ProjectSettingsTabValues {
	details = "details",
	credentials = "credentials",
	classifications = "classifications",
	activityLogs = "activityLogs",
	members = "members"
}
