<template>
	<Tabs data-qa-deployment-logs-view-screen class="width-100-per height-100-per">
		<Tab
			v-if="showLogsError"
			:selected="currentTab === 'issues'"
			width="100px"
			data-qa-issues-tab
			@click="currentTab = 'issues'"
		>
			<Typography type="p1" color="danger-200" weight="bold">Issues</Typography>
		</Tab>

		<Tab
			v-for="logId in logFiles"
			:key="logId"
			:selected="currentTab === logId"
			:data-qa-log-terminal="logId"
			@click="currentTab = logId"
		>
			<p class="paragraph-2">{{ formatTabName(logId) }}</p>
		</Tab>

		<PipelineTerminalActions
			:logs="logDownloadOptions ?? undefined"
			:enable-filters="Boolean(logs)"
			:enable-wrapping="Boolean(logs)"
			:filter-id="terminalFilter?.id"
			:enabled-filter-ids="enabledFilterIds"
			@toggle-wrap="wrapText = $event"
			@toggle-terminal-filter="toggleTerminalFilter"
		>
			<slot name="pipeline-actions"></slot>
		</PipelineTerminalActions>

		<template #content>
			<Container :padding="0" direction="column" :gap="0" align="left top">
				<PipelineLogError
					v-if="currentTab === 'issues' && showLogsError && currentJob.message"
					:errors="[currentJob.message]"
				/>

				<Container v-else padding="12px 0 0 12px" :grow="1" align="left stretch">
					<LogsView v-if="strLogs" :logs="strLogs" :wrap-text="wrapText" data-qa-output-terminal>
					</LogsView>

					<PipelineLogStatus
						v-else
						entity-name="Logs"
						:is-plural="true"
						:status="pipelineLogStatus"
						@download-logs="fetchAppPipelineLogs()"
					/>
				</Container>
			</Container>
		</template>
	</Tabs>
</template>

<script lang="ts">
import { Container, Tab, Tabs, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { JobStep } from "@/protocol/common";
import { jobResponse } from "@/protocol/deployment";
import PipelineLogError from "@/shared/components/pipelines/PipelineLogError.vue";
import PipelineLogStatus from "@/shared/components/pipelines/PipelineLogStatus.vue";
import PipelineTerminalActions, {
	LogItem
} from "@/shared/components/pipelines/PipelineTerminalActions.vue";
import LogsView from "@/shared/LogsView.vue";
import {
	JOB_STATUS,
	PIPELINE_UNFINISHED_JOB_STATUSES,
	PipelineJobStatus,
	TERMINAL_FILTERS,
	TERMINAL_FILTER_ITEMS,
	TerminalFilter,
	TerminalFilterId
} from "@/shared/pipeline-constants";
import { downloadFile, fileToString } from "@/utils";

export default defineComponent({
	name: "AppLogsView",

	components: {
		Container,
		Tab,
		Tabs,
		LogsView,
		Typography,
		PipelineLogError,
		PipelineLogStatus,
		PipelineTerminalActions
	},

	props: {
		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		envId: {
			type: String,
			required: true
		},

		depId: {
			type: String,
			default: () => ""
		},

		currentJob: {
			type: Object as PropType<jobResponse>,
			required: true
		},

		currentStep: {
			type: Object as PropType<JobStep>
		}
	},

	data: () => ({
		wrapText: false,
		currentTab: null as string | "issues" | null,
		logsLoading: false,
		terminalFilter: null as TerminalFilter | null
	}),

	computed: {
		logDownloadOptions(): LogItem[] {
			return this.logFiles.map((logFile, idx) => ({
				name: logFile,
				showDivider: idx === this.logFiles.length - 1,
				downloadLog: () => this.downloadFile(logFile)
			}));
		},

		showLogsError() {
			return this.currentJob.jobStatus === "failed" && this.currentJob.message !== "";
		},

		logs() {
			return this.currentTab
				? applicationDeploymentStore.logs?.[this.currentJob.id]?.[this.currentTab]
				: undefined;
		},

		strLogs() {
			if (!this.logs) {
				return null;
			}

			return fileToString(this.logs);
		},

		enabledFilterIds() {
			const enabledFilterIds: Set<TerminalFilterId> = new Set();

			const filterLog = (logLine: string) => {
				TERMINAL_FILTER_ITEMS.forEach(filter => {
					if (logLine.includes(filter.filterString)) {
						enabledFilterIds.add(filter.id);
					}
				});
			};

			this.strLogs?.split("\n").forEach(filterLog);

			return Array.from(enabledFilterIds);
		},

		isRunningPipeline() {
			return PIPELINE_UNFINISHED_JOB_STATUSES.includes(
				this.currentJob.jobStatus as PipelineJobStatus
			);
		},

		pipelineLogStatus() {
			if (this.logsLoading) {
				return "loading";
			}

			if (this.isRunningPipeline) {
				return "progress";
			}

			if (this.currentJob.jobStatus === JOB_STATUS.CANCELLED) {
				return "cancelled";
			}

			if (this.currentJob.jobStatus === JOB_STATUS.FAILED) {
				return "pipeline-failed";
			}

			return "download-failed";
		},

		logFiles() {
			return this.currentStep?.logs ?? [];
		}
	},

	watch: {
		currentJob: {
			deep: true,

			handler() {
				this.fetchAppPipelineLogs();
			}
		},

		currentStep: {
			immediate: true,

			handler() {
				this.fetchAppPipelineLogs();
			}
		}
	},

	mounted() {
		this.fetchAppPipelineLogs();
	},

	methods: {
		// Get pretty tab names from our log files
		formatTabName(tabName: string) {
			const newTabName = tabName
				.replace(/[_-]/gi, " ")
				// All log files begin with their step name which is not necessary to render
				.replace(/^(app apply|app deploy)/i, "")
				.replace(".log", "")
				.replace(".json", "")
				.trim();

			return `${newTabName.substring(0, 1).toLocaleUpperCase()}${newTabName.substring(1)}`;
		},

		downloadFile(fileName: string) {
			const artifact = applicationDeploymentStore.logs?.[this.currentJob.id]?.[fileName] ?? null;

			if (artifact) {
				downloadFile(artifact, fileName);
			}
		},

		async fetchAppPipelineLogs() {
			const { logFiles } = this;
			const [firstLog] = logFiles;

			this.currentTab = null;

			if (!firstLog) {
				return;
			}

			this.logsLoading = true;

			await Promise.allSettled(
				logFiles.map(logFile =>
					applicationDeploymentStore.GET_APP_DEPLOYMENT_LOGS({
						orgId: this.orgId,
						projId: this.projectId,
						envId: this.envId,
						depId: this.depId,
						jobId: this.currentJob.id,
						artifactName: logFile
					})
				)
			);

			this.currentTab = this.showLogsError ? "issues" : firstLog;

			this.logsLoading = false;
		},

		toggleTerminalFilter(filter: TerminalFilterId) {
			this.terminalFilter = TERMINAL_FILTERS[filter];
		}
	}
});
</script>
