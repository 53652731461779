import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { userStore } from "@/modules/user/user-store";
import { organization, orgId as OrgId } from "@/protocol/identity";
import {
	componentListItem,
	Dependency,
	DependencyId,
	DependencyListRequest,
	DependencyResolver,
	listComponentsRequest
} from "@/protocol/infra";
import { captureError } from "@/utils";

import { applicationStore } from "../application/application-store";
import { applicationDeploymentStore } from "../application-deployment/application-deployment-store";
import { applicationIntegrationStore } from "../application-integration/application-integration-store";
import { credentialStore } from "../credentials/credential-store";
import { envCreateStore } from "../env-create/env-create-store";
import { envListStore } from "../env-list/env-list-store";
import { newEnvPipelineStore } from "../env-pipeline/env-pipeline-store";
import { membersStore } from "../members/members-store";
import { policyListStore } from "../policy-list/policy-list-store";
import { projectStore } from "../project-list/project-store";
import { getStore } from "../store";

import {
	createOrg,
	deleteOrg,
	dependencyList,
	dependencyResolversList,
	getOrg,
	listComponent,
	listOrg,
	updateOrg
} from "./org-service";
import { OrgRole } from "./org-types";

export const DEFAULT_ORG_IDENTIFIER = "default";

const ACTIVE_ORG_KEY = "active_org";
@Module({
	namespaced: true,
	dynamic: true,
	name: "org",
	store: getStore()
})
class OrgStore extends VuexModule {
	orgs: Array<organization> = [];
	dependencyList: Dependency[] = [];
	dependencyResolversList: Record<string, DependencyResolver[]> = {};
	componentsList: componentListItem[] = [];
	activeOrgId: string | null = null;
	showAppFirstFlow = false;

	get activeOrg() {
		const orgId = this.activeOrgId;
		return this.orgs.find(org_ => org_.id === orgId);
	}

	get isUserOrgAdmin() {
		return this.activeOrg?.role === OrgRole.ADMIN;
	}

	get currentUserId() {
		return userStore.profile?.id;
	}

	get GET_ORGS(): Array<organization & { formatedName: string; orgInitial: string }> {
		const { orgs } = this;

		let modifiedOrgs = [];

		if (!orgs.length) {
			return [];
		}

		const userEmail = this.context.rootState?.user?.profile?.email || "";

		modifiedOrgs = orgs.map(org => {
			let formatedName = org.name;
			let orgInitial = org.name.substring(0, 2);
			if (org.name === DEFAULT_ORG_IDENTIFIER) {
				formatedName = `You - ${userEmail}`;
				orgInitial = userEmail.substring(0, 2);
			}
			return {
				...org,
				formatedName,
				orgInitial: orgInitial.toUpperCase()
			};
		});

		return modifiedOrgs;
	}

	@Mutation
	SET_ACTIVE_ORG_ID(orgId: string | null) {
		if (orgId) {
			sessionStorage.setItem(ACTIVE_ORG_KEY, orgId);
			this.activeOrgId = orgId;
		} else {
			sessionStorage.removeItem(ACTIVE_ORG_KEY);
			this.activeOrgId = null;
		}
	}

	@Mutation
	SET_COMPONENT_LIST(compList: componentListItem[]) {
		this.componentsList = compList;
	}

	@Mutation
	SET_DEPENDENCY_LIST(depList: Dependency[]) {
		this.dependencyList = depList;
	}

	@Mutation
	SET_DEPENDENCY_RESOLVERS_LIST({ id, items }: { id: string; items: DependencyResolver[] }) {
		this.dependencyResolversList[id] = items;
	}

	@Mutation
	SET_ORGS(orgs: Array<organization>) {
		this.orgs = orgs;
	}

	@Mutation
	SET_ORG(org: organization) {
		const existingOrgIdx = this.orgs.findIndex(_org => _org.id == org.id);
		if (existingOrgIdx > -1) {
			this.orgs[existingOrgIdx] = org;
		} else {
			this.orgs[this.orgs.length - 1] = org;
		}
	}

	@Mutation
	SET_APP_FIRST_FLOW(val: boolean) {
		this.showAppFirstFlow = val;
	}

	@Action
	INIT_ORG() {
		if (userStore.isUserLoggedIn) {
			this.GET_SET_ORGS();
		}
	}

	@Action
	async GET_SET_ORGS() {
		const { orgs } = await listOrg();

		if (orgs) {
			this.SET_ORGS(orgs);
		}
	}

	@Action
	async GET_SET_ORG(request: OrgId) {
		const org = await getOrg(request);
		this.SET_ORG(org);
		return org;
	}

	@Action
	async FETCH_DEPENDENCY_LIST(request: DependencyListRequest) {
		const { items } = await dependencyList(request);

		if (items) {
			this.SET_DEPENDENCY_LIST(items);
		}
	}

	@Action
	async FETCH_DEPENDENCY_RESOLVERS_LIST(request: DependencyId) {
		const { items } = await dependencyResolversList(request);

		if (items) {
			this.SET_DEPENDENCY_RESOLVERS_LIST({ id: request.id, items });
		}
	}

	@Action
	async FETCH_COMPONENT_LIST(request: listComponentsRequest) {
		const { items } = await listComponent(request);

		if (items) {
			this.SET_COMPONENT_LIST(items);
		}
	}

	@Action
	CREATE_NEW_ORG({
		name,
		description,
		metadata
	}: {
		name: string;
		description: string;
		metadata?: { [key: string]: unknown };
	}) {
		return createOrg({
			name,
			description,
			metadata
		});
	}

	@Action
	UPDATE_ORG({
		id,
		name,
		description,
		metadata
	}: {
		id: string;
		name: string;
		description: string;
		metadata?: { [key: string]: unknown };
	}) {
		return updateOrg({
			id,
			name,
			description,
			metadata
		});
	}

	@Action
	SWITCH_ORG({ orgId }: { orgId: string }) {
		/**
		 * Reset project related stores
		 */
		projectStore.RESET_PROJECTS();

		/**
		 * resetting application related stores
		 */
		applicationStore.RESET_APPLICATION();
		applicationDeploymentStore.RESET_APPLICATION_DEPLOYMENT();
		applicationIntegrationStore.RESET_APPLICATION_INTEGRATION();

		/**
		 * resetting environment related stores
		 */
		envCreateStore.RESET_ENV_CREATE();
		envListStore.RESET_ENVLIST();
		newEnvPipelineStore.RESET_ENV_PIPELINE_STORE();

		credentialStore.RESET_CREDENTIALS();
		policyListStore.RESET_POLICIES();
		membersStore.RESET_MEMBER();

		this.SET_ACTIVE_ORG_ID(orgId);
	}

	@Action
	DELETE_ORG({ id }: { id: string }) {
		return deleteOrg({ id });
	}

	@Action
	async IS_USER_ORG_ADMIN({ orgId }: { orgId: string }) {
		try {
			let org: organization | null = null;
			const activeStoreOrg = this.activeOrg;
			if (activeStoreOrg?.id === orgId) {
				org = activeStoreOrg;
			}

			if (!org) {
				org = await this.GET_SET_ORG({ id: orgId });
			}
			return org.role === OrgRole.ADMIN;
		} catch (error) {
			captureError(error);
		}
	}
}

const orgStore = getModule(OrgStore);

export { orgStore };
