<script lang="ts">
import {
	Button,
	Container,
	EmptyState,
	Icon,
	Pictogram,
	SearchInput,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, computed, ref, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";

import { DeploymentStatusInfo } from "@/modules/application-deployment/constants";
import CreateAndDeployApp from "@/modules/env-list/components/CreateAndDeployApp.vue";
import DeployMultipleApps from "@/modules/env-list/components/DeployMultipleApps.vue";
import { AppDeployment } from "@/protocol/deployment";
import { project as projectProto } from "@/protocol/identity";
import { environment as EnvironmentProto } from "@/protocol/infra";
import { CustomTimeStamp } from "@/shared/components";
import { getShortCode } from "@/utils";

export default {
	name: "DeploymentsListSidebar"
};
</script>

<script lang="ts" setup>
const props = defineProps({
	deployments: {
		type: Array as PropType<AppDeployment[]>,
		required: true
	},

	deployment: {
		type: Object as PropType<AppDeployment>
	},

	project: {
		type: Object as PropType<projectProto>,
		required: true
	},

	env: {
		type: Object as PropType<EnvironmentProto>,
		required: true
	},

	statusTextWithIcons: {
		type: Array as PropType<DeploymentStatusInfo[]>,
		required: true
	}
});

const { deployment, deployments } = toRefs(props);

const searchText = ref("");
const deployModalOpen = ref(false);
const router = useRouter();
const route = useRoute();

const filteredDeployments = computed(() => {
	return deployments.value.filter(
		deployment =>
			deployment.name?.toLocaleLowerCase().includes(searchText.value.toLocaleLowerCase())
	);
});

function loadDeployment(deployment: AppDeployment) {
	router.replace({
		name: "envDetailWithDepId",
		params: {
			orgId: route.params.orgId,
			projectId: route.params.projectId,
			envId: route.params.envId,
			deploymentId: deployment.id
		}
	});
}
</script>

<template>
	<Wrapper width="360px" height="100%">
		<Container direction="column" :padding="0" :gap="0" :grow="1">
			<Container padding="16px 12px 16px 0px" :shrink="0">
				<SearchInput v-model:value="searchText" data-qa-deployments-search-input />

				<DeployMultipleApps :app-dep-entities="deployments" :env="env">
					<Button
						v-tooltip="{
							content: 'Select applications that you want to deploy together.'
						}"
						state="icon"
						type="success"
						data-qa-toggle-multiple-apps-toggle-btn
					>
						<Icon name="i-launch" state="success" :effects="false" size="small" />
					</Button>
				</DeployMultipleApps>

				<CreateAndDeployApp
					v-if="project"
					:project="project"
					:environment="env"
					:open="deployModalOpen"
					@back="deployModalOpen = false"
					@close="deployModalOpen = false"
				>
					<Button
						v-tooltip="{
							content: 'Deploy new application'
						}"
						state="icon"
						data-qa-env-detailed-deploy-new-app-btn
						@click="deployModalOpen = true"
					>
						<Icon name="i-plus" state="light" :effects="false" size="small" />
					</Button>
				</CreateAndDeployApp>
			</Container>

			<Container
				v-if="filteredDeployments.length > 0"
				data-qa-deployments-list
				direction="column"
				:padding="0"
				:gap="0"
				overflow="auto"
				class="flow-add-scrollbar bar-width-2"
				:grow="1"
				align="left top"
			>
				<Container
					v-for="(filteredDeployment, idx) in filteredDeployments"
					:key="filteredDeployment.id"
					:padding="0"
					:gap="0"
					:shrink="0"
				>
					<Slab
						size="medium"
						:type="filteredDeployment.id === deployment?.id ? 'filled' : 'transparent'"
						:effects="filteredDeployment.id !== deployment?.id"
						class="cursor-pointer slab-primary-padding-0"
						:data-qa-field="filteredDeployment.name"
						@click="loadDeployment(filteredDeployment)"
					>
						<template #primary-action>
							<Container padding="0px 0px 0px 12px">
								<Pictogram
									shape="squircle"
									:data-qa-app-deployment-shortcode="getShortCode(filteredDeployment.name)"
								>
									<Typography type="h3">{{ getShortCode(filteredDeployment.name) }}</Typography>
								</Pictogram>
							</Container>
						</template>

						<Container :padding="0" :gap="4" direction="column">
							<Typography
								v-tooltip="{
									content: filteredDeployment.name
								}"
								type="h4"
								weight="medium"
								color="dark"
								:data-qa-app-widget-name="filteredDeployment.name"
								overflow="ellipsis"
								>{{ filteredDeployment.name }}</Typography
							>

							<CustomTimeStamp
								v-if="statusTextWithIcons[idx]!.time"
								type="p3"
								:time="statusTextWithIcons[idx]!.time"
								:prefix="statusTextWithIcons[idx]!.text"
								data-qa="app-widget-pipline-updated-date"
							/>
							<Typography v-else type="p3" color="light">Not deployed</Typography>
						</Container>
					</Slab>
				</Container>
			</Container>

			<Container
				v-else
				data-qa-empty-deployments-from-search
				:grow="1"
				direction="column"
				class="height-100-per"
				align="center center"
			>
				<EmptyState
					message="No Results Found"
					subtitle="No Results found for the search term that you entered"
					shape="squircle"
				/>
			</Container>
		</Container>
	</Wrapper>
</template>
