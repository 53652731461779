export function getShortCode(str?: string) {
	if (!str) {
		return "";
	}

	if (str.length < 3) {
		return str.toUpperCase();
	}

	// Split by anything that's not a valid word character and filter our empty items
	const cleanSplit = str.split(/[^\w]/).filter(Boolean);

	if (cleanSplit.length === 1) {
		return cleanSplit[0]!.substring(0, 2).toUpperCase();
	}

	if (cleanSplit.length > 1) {
		return `${cleanSplit[0]!.charAt(0).toUpperCase()}${cleanSplit[1]!.charAt(0).toUpperCase()}`;
	}

	return str.substring(0, 2).toUpperCase();
}

export function getCamelShortCode(str?: string) {
	const shortCode = getShortCode(str);

	return shortCode.length > 1
		? `${shortCode.charAt(0)}${shortCode.charAt(1).toLocaleLowerCase()}`
		: shortCode;
}
