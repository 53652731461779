<template>
	<Slab
		:effects="!isCredSelected"
		type="bottom-border"
		size="large"
		:class="isUserOrgAdmin ? 'cursor-pointer' : ''"
		data-qa="assignable-cred-item"
		@click.stop="onClick"
	>
		<template v-if="credIcon" #primary-action>
			<Icon
				:data-qa="`assignable-cred-list-grp-${cred.name}-icon`"
				type="filled"
				state="dark"
				size="medium"
				:name="credIcon"
				:effects="false"
			/>
		</template>
		<Container :padding="0">
			<Container :padding="0" direction="column" :gap="2">
				<Typography
					type="p1"
					family="logs"
					color="dark"
					:data-qa="`assignable-cred-item-name-${cred.name}`"
				>
					{{ cred.name }}
				</Typography>
				<Typography
					v-if="!isCredSelected && isCredInherited && !selectedCreds.length"
					type="h5"
					family="logs"
					weight="light"
					color="gray-300"
					data-qa="assign-cred-inherited-txt"
				>
					Inherited from project
				</Typography>
			</Container>

			<ClassificationTag v-if="cred.classificationId" :classification-id="cred.classificationId" />
		</Container>

		<template v-if="isUserOrgAdmin" #secondary-actions>
			<Checkbox v-if="isCredSelected" :checked="isCredSelected" />
			<Checkbox v-else-if="!selectedCreds.length" :indeterminate="isCredInherited" />
			<Checkbox v-else />
		</template>
	</Slab>
</template>

<script lang="ts">
import { Typography, Container, Icon, Slab, Checkbox } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import ClassificationTag from "@/modules/credentials/components/credential-assign/ClassificationTag.vue";
import { CREDENTIAL_TYPE_ICONS } from "@/modules/credentials/constants";
import { orgStore } from "@/modules/org/org-store";
import { VGEntities } from "@/protocol/common";
import { Creds } from "@/protocol/identity";

export default defineComponent({
	name: "AssignableCredItem",
	components: { Typography, Container, Icon, Slab, ClassificationTag, Checkbox },

	props: {
		selectedCreds: {
			type: Array as PropType<Creds[]>,
			required: true
		},

		assignedCreds: {
			type: Array as PropType<Creds[]>,
			required: true
		},

		cred: {
			type: Object as PropType<Creds>,
			required: true
		}
	},

	computed: {
		assignedCred() {
			return this.assignedCreds.find(assigned => assigned.id === this.cred.id) ?? null;
		},

		isCredInherited() {
			return this.assignedCred?.inheritedFrom === VGEntities.project;
		},

		credIcon() {
			return (this.cred.type && CREDENTIAL_TYPE_ICONS[this.cred.type]) ?? "";
		},

		isCredSelected() {
			return this.selectedCreds.findIndex(selected => selected.id === this.cred.id) > -1;
		},

		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		}
	},

	methods: {
		onClick() {
			if (!this.isUserOrgAdmin) {
				return;
			}
			if (!this.isCredSelected) {
				return this.$emit("onAdd", this.cred);
			} else {
				return this.$emit("onRemove", this.cred);
			}
		}
	}
});
</script>
