<template>
	<PipelineWrapper :show-history="showHistory" :is-loading="isLoadingPipeline">
		<template #header>
			<PipelineHeader
				v-if="currentJob"
				:job-start="String(currentJob.createdAt)"
				:pipeline-name="pipelineName"
				:started-by="currentJob.updatedBy"
			/>
		</template>

		<template #stage-view>
			<PipelineStageView
				v-if="chartMetaData"
				data-qa-integration-dag-view
				:nodes="chartMetaData.nodes || []"
				:edges="chartMetaData.edges || []"
				title="Pipeline modules"
				:show-accordion-view="true"
				:pipeline-modules="pipelineModules"
				@select-stage="stepChanged"
			/>
		</template>
		<template #logs-view>
			<IntegrationPipelineLogsView
				v-if="currentJob && currentStep"
				:current-job="currentJob"
				:current-step="currentStep"
			>
				<template #pipeline-actions>
					<Button
						v-if="isRunningPipeline"
						size="small"
						data-qa-intgration-dag-cancel-pipeline
						type="error"
						state="curved"
						:disabled="isAttemptingCancellation || isCancellingJob"
						@click="cancelDeployment"
					>
						<Icon name="i-close" color="gray-600" type="filled" size="x-small" />
						<Typography
							type="p2"
							color="gray-600"
							transform="uppercase"
							weight="bold"
							overflow="nowrap"
							>Cancel</Typography
						>
					</Button>

					<Button
						v-else
						size="small"
						type="primary"
						state="curved"
						:disabled="retryAttempted"
						data-qa-retry-pipeline
						@click="retryPipeline"
					>
						<Icon name="i-tick" color="gray-600" type="filled" size="x-small" />
						<Typography
							type="p2"
							color="gray-600"
							transform="uppercase"
							weight="bold"
							overflow="nowrap"
							>Retry</Typography
						>
					</Button>

					<Button
						:type="showHistory ? 'secondary' : 'default'"
						state="curved"
						size="small"
						overflow="nowrap"
						data-qa-toggle-pipeline-history
						@click="setPipelineHistory(!showHistory)"
					>
						<Icon
							name="i-clock-outline"
							:color="showHistory ? 'gray-600' : 'gray-100'"
							type="filled"
							size="small"
						/>
						<Typography type="p2" :color="showHistory ? 'gray-600' : 'gray-100'" weight="bold">
							Pipeline history
						</Typography>
					</Button>
				</template>
			</IntegrationPipelineLogsView>
		</template>
		<template #pipeline-history>
			<IntegrationPipelineHistory
				:integration-id="integrationId"
				:selected-pipeline-id="jobId"
				:is-loading="isLoadingPipeline"
				@set-pipeline-history="setPipelineHistory"
				@select-pipeline-history="selectPipelineHistory"
			/>
		</template>
	</PipelineWrapper>
</template>

<script lang="ts">
import { Button, Icon, Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { BreadCrumb, breadcrumbStore } from "@/modules/core/breadcrumb-store";
import { triggerIntegrationJob } from "@/protocol/app";
import { GitRevisionType } from "@/protocol/common";
import PipelineHeader from "@/shared/components/pipelines/PipelineHeader.vue";
import PipelineStageView from "@/shared/components/pipelines/PipelineStageView.vue";
import PipelineWrapper from "@/shared/components/pipelines/PipelineWrapper.vue";
import {
	JOB_STATUS,
	PIPELINE_UNFINISHED_JOB_STATUSES,
	PipelineJobStatus
} from "@/shared/pipeline-constants";

import {
	applicationIntegrationStore,
	getIntegrationDagMeta,
	getPipelineModuleMeta,
	getSortedIntegrationJobList
} from "../application-integration-store";

import { PipelineModuleMeta } from "./AddIntegrationPipelineWrapper.vue";
import IntegrationPipelineHistory from "./IntegrationPipelineHistory.vue";
import IntegrationPipelineLogsView from "./IntegrationPipelineLogsView.vue";

export default defineComponent({
	name: "IntegrationPipelineWrapper",

	components: {
		Icon,
		Typography,
		Button,
		PipelineStageView,
		PipelineWrapper,
		IntegrationPipelineHistory,
		PipelineHeader,
		IntegrationPipelineLogsView
	},

	props: {
		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		appId: {
			type: String,
			required: true
		},

		integrationId: {
			type: String,
			default: () => ""
		},

		jobId: {
			type: String,
			default: () => null
		}
	},

	data: () => ({
		currentStepIndex: 0,
		isLoadingPipeline: false,
		showHistory: false,
		isAttemptingCancellation: false,
		retryAttempted: false
	}),

	computed: {
		pipelineName() {
			return this.integration ? this.integration.name : "";
		},

		isRunningPipeline() {
			return PIPELINE_UNFINISHED_JOB_STATUSES.includes(
				this.currentJob?.jobStatus as PipelineJobStatus
			);
		},

		isCancellingJob() {
			return (this.currentJob?.jobStatus as PipelineJobStatus) === JOB_STATUS.CANCELLING;
		},

		chartMetaData() {
			return this.currentJob ? getIntegrationDagMeta(this.currentJob, this.currentStepIndex) : null;
		},

		currentJob() {
			if (this.jobId) {
				return applicationIntegrationStore.integrationJob[this.jobId] ?? null;
			}

			return (
				applicationIntegrationStore.sortedIntegrationJobs.filter(
					appJob => appJob.appId === this.appId && appJob.integrationId === this.integrationId
				)[0] ?? null
			);
		},

		currentStep() {
			return this.currentJob?.steps?.[this.currentStepIndex];
		},

		integration() {
			if (!this.integrationId) {
				return null;
			}

			const integrations = Object.values(applicationIntegrationStore.integrations).flat();

			return integrations.find(integration => integration.id === this.integrationId);
		},

		pipelineModules(): PipelineModuleMeta[] {
			if (!this.integration) {
				return [];
			}
			const modules = getPipelineModuleMeta(this.integration.pipeline).map(module => {
				return {
					icon:
						module.provisioner === "gcp" || module.provisioner === "azure"
							? `p-${module.provisioner}`
							: "p-aws-dark",
					name: module.displayName!,
					desc: module.description!,
					data: { ...module, type: "module" },
					type: "module"
				};
			});

			return [...modules];
		},

		breadcrumbs(): BreadCrumb[] {
			const app = applicationStore.projectApps[this.projectId]?.find(
				app_ => app_.id === this.appId
			);

			const { integration } = this;

			if (!app || !integration) {
				return [];
			}

			return [
				{
					qaId: "projectListWithApp",
					label: app.name,
					route: {
						name: "projectListWithApp",
						props: {
							orgId: app.orgId,
							projectId: app.projId,
							appId: app.id
						}
					}
				},
				{
					qaId: "addIntegrationView",
					label: integration.name,
					route: {
						name: "addIntegrationView",
						props: {
							orgId: integration.orgId,
							projectId: integration.projId,
							appId: integration.appId,
							integrationId: integration.id
						}
					}
				},
				{
					qaId: "stageViewIntegration",
					label: "Logs",
					route: {
						name: "stageViewIntegration",
						props: {
							orgId: integration.orgId,
							projectId: integration.projId,
							appId: integration.appId,
							integrationId: integration.id
						}
					}
				}
			];
		}
	},

	watch: {
		breadcrumbs: {
			handler() {
				breadcrumbStore.SET_ADDITIONAL_BREADCRUMBS(this.breadcrumbs);
			},

			deep: true,
			immediate: true
		},

		jobId: {
			immediate: true,

			handler() {
				if (this.jobId) {
					this.showHistory = true;
				}

				this.loadIntegrationsAndJobs();
			}
		}
	},

	methods: {
		async loadIntegrationsAndJobs() {
			applicationIntegrationStore.FETCH_INTEGRATIONS({
				appId: this.appId,
				orgId: this.orgId,
				projId: this.projectId
			});

			applicationIntegrationStore.FETCH_INTEGRATION_MODULES({
				orgId: this.orgId,
				keywords: []
			});

			this.isLoadingPipeline = true;

			/**
			 * Fetches all the existing jobs for an integration
			 * UPDATE: On rety job we need existing latest job so that the
			 * input artifact resolvedCommitHash can be used for the new
			 * job trigger.
			 */

			await applicationIntegrationStore.FETCH_INTEGRATION_JOBS({
				orgId: this.orgId,
				projId: this.projectId,
				appId: this.appId,
				integrationId: this.integrationId
			});

			if (this.jobId) {
				await applicationIntegrationStore.FETCH_INTEGRATION_JOB({
					orgId: this.orgId,
					projId: this.projectId,
					appId: this.appId,
					integrationId: this.integrationId,
					id: this.jobId
				});
			} else {
				// Find the latest and fetch that job
				const [firstJob] = getSortedIntegrationJobList(this.integrationId);

				if (!firstJob) {
					return;
				}

				await applicationIntegrationStore.FETCH_INTEGRATION_JOB({
					orgId: this.orgId,
					projId: this.projectId,
					appId: this.appId,
					integrationId: this.integrationId,
					id: firstJob.id
				});
			}

			this.currentStepIndex = this.currentJob?.progress ? this.currentJob.progress - 1 : 0;

			this.isLoadingPipeline = false;
		},

		setPipelineHistory(showHistory: boolean) {
			this.showHistory = showHistory;
		},

		selectPipelineHistory(jobId: string) {
			if (this.jobId !== jobId) {
				this.$router.push({
					name: "stageViewIntegrationWithJob",
					params: {
						orgId: this.orgId,
						projectId: this.projectId,
						appId: this.appId,
						integrationId: this.integrationId,
						jobId
					}
				});
			}
		},

		stepChanged(stepName: string) {
			this.currentStepIndex =
				this.currentJob?.steps?.findIndex(step => step.name === stepName) ?? 0;
		},

		async cancelDeployment() {
			if (!this.currentJob) {
				return;
			}

			this.isAttemptingCancellation = true;

			await applicationIntegrationStore.CANCEL_INTEGRATION_JOB({
				id: this.currentJob.id,
				integrationId: this.currentJob.integrationId,
				orgId: this.orgId,
				projId: this.projectId,
				appId: this.currentJob.appId
			});

			this.isAttemptingCancellation = false;
		},

		async retryPipeline() {
			if (!this.currentJob) {
				return;
			}

			this.retryAttempted = true;

			this.setPipelineHistory(false);

			// Set the job trigger payload
			let startJobPayload: triggerIntegrationJob = {
				integrationId: this.currentJob.integrationId,
				orgId: this.orgId,
				projId: this.projectId,
				appId: this.currentJob.appId
			};

			/**
			 * If the current job has successfully triggered, then it should have filled resolvedCommitHash value.
			 * if we don't pass the latest commit value, the artifact information required is pulled from the original
			 * app artifact and the pipeline then shows incorrect information.
			 * eg: If a user's PR is recently merged, we would want that commit hash to be used in the pipeline instead
			 * of the default identifier configured on app artifact.
			 */
			const commitHash = this.currentJob.inputAppArtifact?.gitCode?.resolvedCommitHash;

			if (commitHash) {
				startJobPayload = {
					...startJobPayload,
					inputAppArtifact: {
						gitCode: {
							type: GitRevisionType.commit,
							identifier: commitHash
						},

						id: this.currentJob.inputAppArtifact?.id
					}
				};
			}
			const latestJob = await applicationIntegrationStore.START_INTEGRATION_JOB(startJobPayload);

			// Re-route to the latest job logsview
			this.$router.push({
				name: "stageViewIntegrationWithJob",
				params: {
					orgId: this.orgId,
					projectId: this.projectId,
					appId: latestJob.appId,
					integrationId: latestJob.integrationId,
					jobId: latestJob.id
				}
			});
			this.retryAttempted = false;
		}
	}
});
</script>
