import { ActivityDetails } from "@/modules/activity/activity-utils";

export type Member = {
	id?: string;
	email?: string;
	role?: { id: MemberRole; name: string };
	hasJoined: boolean;
	name?: string;
	lastActivity?: ActivityDetails;
	membershipStatus?: {
		dateTime: number;
		relativeTime: string;
	};
	selected?: boolean;
	isNew?: boolean;
	isReadOnly: boolean;
};

// These are enumerated from backend roles
export const MEMBER_ROLES = {
	admin: { isAdmin: true, id: "admin", name: "Admin" },
	editor: { isAdmin: false, id: "editor", name: "Editor" },
	viewer: { isAdmin: false, id: "viewer", name: "Viewer" },
	limited: { isAdmin: false, id: "limited", name: "Restricted" }
} as const;

export const MEMBER_ROLES_ARR = [
	MEMBER_ROLES.admin,
	MEMBER_ROLES.editor,
	MEMBER_ROLES.viewer,
	MEMBER_ROLES.limited
] as const;

export type MemberRole = keyof typeof MEMBER_ROLES;
