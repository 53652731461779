<template>
	<f-div
		id="createOrgPopover"
		width="432px"
		height="hug-content"
		direction="column"
		align="top-left"
		position="relative"
	>
		<!-- Header -->
		<f-div
			padding="medium"
			height="hug-content"
			gap="large"
			state="secondary"
			direction="row"
			width="100%"
		>
			<f-icon source="i-arrow-left" class="cursor-pointer" state="default" @click="goBack()">
			</f-icon>
			<f-text variant="para" size="small" weight="bold">
				{{ true ? "Import organization configurations via a bundle" : "Create organization" }}
			</f-text>
			<f-div align="middle-right" gap="small" width="10%">
				<f-icon
					source="i-question-filled"
					class="cursor-pointer"
					data-qa-org-info-toggle-btn
					@click="$emit('toggleWelcome')"
				>
				</f-icon>
				<f-icon
					source="i-close"
					size="small"
					class="cursor-pointer"
					data-qa-org-create-close-btn
					@click="quitFlow()"
				>
				</f-icon>
			</f-div>
		</f-div>
		<f-div
			v-if="isSuccess"
			padding="medium"
			height="50%"
			gap="medium"
			state="success"
			direction="row"
			width="100%"
		>
			<f-text size="medium" weight="medium" data-qa-auth-success-title
				>Authentication successful</f-text
			>
			<f-div align="middle-right">
				<f-icon
					class="cursor-pointer"
					source="i-close"
					size="x-small"
					data-qa-auth-success-close-icon
					@click="isSuccess = false"
				>
				</f-icon>
			</f-div>
		</f-div>
		<f-div direction="column" padding="none" overflow="scroll" style="max-height: 70vh">
			<!-- Body -->
			<f-div
				padding="medium"
				width="100%"
				height="hug-content"
				gap="medium"
				state="secondary"
				direction="column"
				class="description-field"
			>
				<!-- Org fields -->
				<f-form-builder
					class="width-100-per"
					:field.prop="formFields"
					:values.prop="formValues"
					@input="handleInput"
					@state-change="formState = $event.detail"
				/>

				<f-button
					v-if="!formValues.__isOrgNameFilled__"
					label="Import organization configurations via a bundle"
					icon-left="i-chevron-right"
					category="transparent"
					size="x-small"
					state="primary"
					:loading="isSubmitting"
					:disabled="shouldDisableSubmitBtn"
					data-qa-create-org-with-import-bundle-btn
					@click="showBundleFields"
				></f-button>
			</f-div>

			<!-- Git oauth form -->
			<f-div
				v-if="isPrivateRepo && !isCredAuthenticated"
				padding="none"
				height="hug-content"
				gap="medium"
				state="secondary"
				direction="column"
				width="100%"
				overflow="scroll"
			>
				<f-divider> </f-divider>
				<f-div padding="medium medium none large">
					<f-text size="small" weight="regular" variant="para"
						>This seems to be a private repository. Please authenticate the git account to continue
						importing.</f-text
					>
				</f-div>
				<!-- Github credn form -->
				<GithubCredentialModal
					ref="credentialModal"
					git-form-ref="appFirstFlow"
					:update-cred-list="true"
					:is-resusable-state="true"
					:new-org-id="baseOrgId"
					@authenticated="setAuthenticated"
					@on-cred-create="onCredCreate"
				/>
			</f-div>
		</f-div>
		<!-- Show error message here  -->
		<f-div
			v-if="submitError"
			padding="medium"
			height="hug-content"
			gap="medium"
			direction="row"
			width="100%"
			state="danger"
		>
			<f-text size="small" weight="regular" variant="para" color="danger" data-qa-submit-err-text>
				{{ submitError }}</f-text
			>
			<f-div align="top-right" width="hug-content">
				<f-icon
					class="cursor-pointer"
					source="i-close"
					size="x-small"
					data-qa-error-close-icon
					@click="submitError = ''"
				>
				</f-icon>
			</f-div>
		</f-div>
		<!-- Footer -->
		<f-button
			state="success"
			:disabled="shouldDisableSubmitBtn"
			data-qa-create-org-submit-btn
			:loading="isSubmitting"
			category="fill"
			variant="block"
			:label="formValues.__isOrgNameFilled__ ? 'Next' : 'CREATE ORGANIZATION'"
			@click="handleSteps"
		></f-button>
	</f-div>
</template>
<script lang="ts">
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";

import GithubCredentialModal from "@/modules/credentials/components/credential-form/GithubCredentialModal.vue";
import { GitRevisionType, GitServer } from "@/protocol/common";
import { Creds } from "@/protocol/identity";
import { LibraryImportRequest } from "@/protocol/infra";
import {
	AppFirstFlowFormStorageService,
	GithubOauthStorageService
} from "@/services/storage-service";
import {
	applyEntityNameRules2,
	entityNameRules2
} from "@/shared/custom-validation-rules/entityNameRules";
import { getErrorCode, getErrorMessage, parseGitUrl } from "@/utils";

import { credentialStore } from "../credentials/credential-store";
import { ToastItem } from "../notifications/notification-types";
import { notificationsStore } from "../notifications/notifications-store";
import { listPolicySets } from "../policy-list/services/policy-list-service";
import { projectStore } from "../project-list/project-store";
import { userStore } from "../user/user-store";

import { importInfrastructureLibrary } from "./org-service";
import { orgStore } from "./org-store";

type FormFieldValues = {
	name?: string;
	logo?: File | null;
	description?: string;
	bundleField?: string;
	authMethod?: string;
	branch?: string;
	directory?: string;
	__showDescription__: boolean;
	__isOrgNameFilled__: boolean;
};

export default defineComponent({
	name: "ImportOrgBundle",

	components: {
		GithubCredentialModal
	},

	props: {
		isOauthComplete: {
			type: Boolean,
			default: false
		}
	},

	emits: ["back", "close", "toggleWelcome", "close-flow", "update:formValues"],

	data: () => ({
		isSubmitting: false,
		isPrivateRepo: false,
		isCredAuthenticated: false,
		isSuccess: false,

		formValues: {
			name: "",
			logo: null,
			description: "",
			bundleField: "",
			authMethod: "",
			branch: "",
			directory: "",
			__showDescription__: false,
			__isOrgNameFilled__: false
		} as FormFieldValues,

		formState: null as FormBuilderState | null,

		baseOrgId: "",
		newCred: null as Creds | null,

		submitError: ""
	}),

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					name: {
						type: "text",
						qaId: "name",
						label: { title: "Name" },
						placeholder: "Enter organization name",
						validationRules: applyEntityNameRules2(
							this.existingOrgNames,
							"This organization name is already taken."
						),

						showWhen: () => {
							return !this.formValues.__isOrgNameFilled__;
						}
					},

					addDescription: {
						type: "button",
						label: "Add description",
						iconLeft: "i-plus",
						category: "transparent",
						size: "x-small",
						qaId: "addDescription",
						disabled: this.isSubmitting,
						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: true };
						},

						showWhen(values) {
							const formValues = values as FormFieldValues;
							return (
								!formValues.__showDescription__ &&
								!formValues.description &&
								!formValues.__isOrgNameFilled__
							);
						}
					},

					__showDescription__: {
						type: "separator",
						showWhen: () => {
							return !this.formValues.__isOrgNameFilled__;
						}
					},

					description: {
						type: "textarea",
						label: { title: "Description" },
						qaId: "description",
						placeholder: "Add a brief description about your organization",
						showWhen(values) {
							const formValues = values as FormFieldValues;
							return (
								!formValues.__isOrgNameFilled__ &&
								Boolean(
									(typeof formValues.description === "string" &&
										formValues.description.length > 0) ||
										formValues.__showDescription__
								)
							);
						}
					},

					removeDescription: {
						type: "button",
						qaId: "removeDescription",
						label: "Remove organization description",
						iconLeft: "i-close",
						category: "transparent",
						state: "danger",
						size: "x-small",
						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: false, description: "" };
						},

						showWhen(values) {
							const formValues = values as FormFieldValues;
							return (
								!formValues.__isOrgNameFilled__ &&
								Boolean(formValues.__showDescription__ || formValues.description)
							);
						}
					},

					bundleField: {
						type: "text",
						qaId: "env-code-source-select-repo",
						placeholder: "Enter repo URL",
						label: { title: "Repository URL" },
						prefix: "https://",
						validationRules: [{ name: "required" }, entityNameRules2.entityValidURLRule],
						showWhen(values) {
							const formValues = values as FormFieldValues;
							return formValues.__isOrgNameFilled__;
						}
					},

					branch: {
						type: "text",
						id: "branch",
						label: { title: "Base branch" },
						placeholder: "Enter base branch",
						validationRules: [{ name: "required" }],
						showWhen: () => {
							return this.formValues.__isOrgNameFilled__;
						}
					},

					directory: {
						type: "text",
						id: "directory",
						label: { title: "Directory path" },
						placeholder: "Enter directory path",
						validationRules: [{ name: "required" }],
						showWhen: () => {
							return this.formValues.__isOrgNameFilled__;
						}
					}
				}
			};
		},

		shouldDisableSubmitBtn() {
			const { isSubmitting } = this;
			return isSubmitting || this.formValues.name === "" || !this.formState?.isValid;
		},

		existingOrgNames(): string[] {
			return orgStore.orgs.map(org => org.name);
		}
	},

	mounted() {
		this.initializeModal();
	},

	methods: {
		initializeModal() {
			// Retrieve the existing form data from local storage
			const existingForm = AppFirstFlowFormStorageService.getAppFirstFlowForm();
			if (
				// Check if the existing form data is related to the first flow of the application
				!(
					Object.keys(existingForm).length &&
					this.isOauthComplete &&
					existingForm.gitFormRef === "appFirstFlow"
				)
			) {
				return;
			}

			// If yes, initialize the modal form fields for the app first flow
			this.isPrivateRepo = true;
			this.isSuccess = true;

			// Retrieve the necessary data from the existing form data
			this.formValues = {
				...this.formValues,
				__isOrgNameFilled__: true,
				bundleField: existingForm.bundleField,
				branch: existingForm.branch,
				directory: existingForm.directory,
				name: existingForm.name
			};

			this.newCred = cloneDeep(existingForm.cred) ?? null;

			this.baseOrgId = existingForm.baseOrgId ?? "";
		},

		handleInput(event: CustomEvent<FormFieldValues>) {
			const input = { ...event.detail };

			if (input.__isOrgNameFilled__ && input.bundleField) {
				const parsedRepo = parseGitUrl(input.bundleField);

				if (parsedRepo?.repo) {
					input.bundleField = parsedRepo.repo;
					// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
					input.directory = parsedRepo.path || input.directory;
				}

				if (parsedRepo?.identifier) {
					input.branch = parsedRepo.identifier;
				}

				this.formValues = { ...input };
				this.$emit("update:formValues", input);
			}
		},

		/* Create notification message after creating the org */

		generateNotification(createOrgWithBundle = false) {
			let toastMsg: ToastItem = {
				qaId: "toast-org-created",
				title: "Success",
				text: `Organization ${this.formValues.name} is created`,
				status: "success"
			};
			if (createOrgWithBundle) {
				toastMsg = {
					...toastMsg,
					...{
						autoDismiss: true,
						actions: [
							{
								actionType: "default",
								onAction: () => this.routeToPage("credentials"),
								text: "ADD CLOUD CREDENTIALS",
								qaId: "data-qa-notify-add-cloud-creds-link-btn"
							}
						]
					}
				};
			}
			notificationsStore.ADD_TOAST(toastMsg);
		},

		async routeToPage(tabName: string) {
			await this.$router
				.replace({
					name: "org-settings-global",
					params: {
						orgId: this.baseOrgId,
						tabName
					}
				})
				.catch();

			notificationsStore.DISMISS_ALL();
		},

		/**
		 * Handle Steps / SUBMIT button
		 * This handles the creation of org without bundle.
		 */
		showBundleFields() {
			this.formValues = { ...this.formValues, __isOrgNameFilled__: true };
		},

		async createSandboxProject({ orgId }: { orgId: string }): Promise<void> {
			const { policySets } = await listPolicySets({ orgId, privateOnly: false });
			let defaultPolicySet;

			if (policySets) {
				/**
				 * We set the default control gate whichever has the default flag set to true.
				 */
				const ollionBestPracticePolicy = policySets.find(policySet => policySet.metadata?.default);
				defaultPolicySet = ollionBestPracticePolicy ?? policySets[0];
			}

			const project = await projectStore.CREATE_NEW_PROJECT({
				orgId,
				name: "Project-1",
				description: "My first project",
				policySetId: defaultPolicySet?.id ?? "",
				metadata: {
					emojiObj: { native: "🗂️" }
				},
				variables: []
			});

			const projId = project.id;

			await orgStore.GET_SET_ORGS();
			orgStore.SWITCH_ORG({ orgId });

			this.$router
				.push({
					name: "projectListWithProject",
					params: { orgId, projectId: projId }
				})
				.catch(() => {
					// Do nothing
				})
				.finally(() => {
					this.$emit("close");
				});
		},

		/**
		 * Handle Steps / SUBMIT button
		 * This handles the flow for org creation, bundle creation steps.
		 */
		async handleSteps(): Promise<void> {
			// First check if the user is just creating an org and not importing the library
			if (!this.formValues.__isOrgNameFilled__) {
				this.isSubmitting = true;
				if (this.baseOrgId === "") {
					await this.createOrg();
				}
				await this.createSandboxProject({ orgId: this.baseOrgId });
				orgStore.SET_APP_FIRST_FLOW(false);
				this.generateNotification();
				// remove the form values from local storage as we don't need it anymore
				AppFirstFlowFormStorageService.removeAppFirstFlowForm();
				this.isSubmitting = false;
			} else if (this.formState?.isValid) {
				this.isSubmitting = true;

				if (this.baseOrgId === "") {
					// If the org is never created in the flow, then allow the user to create one.
					await this.createOrg();
				}

				await this.importInfraLibrary();
			}
		},

		goBack() {
			if (!this.formValues.__isOrgNameFilled__) {
				this.$emit("back");
			} else {
				// reset fields to default.
				this.formValues = { ...this.formValues, __isOrgNameFilled__: false };
				this.newCred = null;
				this.isPrivateRepo = false;
				this.submitError = "";
			}
		},

		/**
		 * called to create org for the bundle
		 */
		async createOrg() {
			const { id } = await orgStore.CREATE_NEW_ORG({
				name: this.formValues.name as string,
				description: this.formValues.description ?? "",
				metadata: {}
			});
			this.baseOrgId = id;
		},

		/**
		 * As we don't have a separate API to check if a URL is private,
		 * we have to call parse bundle api on the URL and check if it returns parseBundle response.
		 * If it returns parseBundle response, then the URL is public.
		 * If it returns 400, then the URL is private.
		 */
		async importInfraLibrary(): Promise<void> {
			let { bundleField, branch, directory, name } = this.formValues;

			if (!bundleField || !branch || !directory) {
				this.isSubmitting = false;
				return;
			}

			// Store the bundleURL and orgId in session storage
			const existingFormValues = AppFirstFlowFormStorageService.getAppFirstFlowForm();
			AppFirstFlowFormStorageService.setAppFirstFlowForm({
				...existingFormValues,
				bundleField,
				baseOrgId: this.baseOrgId,
				gitFormRef: "appFirstFlow",
				branch: branch as GitRevisionType,
				directory,
				name
			});

			// this code checks if the url has https, if not then it prepends https
			if (!bundleField.startsWith("https://")) {
				bundleField = `https://${bundleField}`;
			}
			/**
			 * To check if the repo is private or not we passed required values
			 * with some dummy ones and check the response.
			 */
			const bundleRequest = {
				artifact: {
					gitCode: {
						type: GitRevisionType.branch,
						driver: GitServer.github,
						dir: this.formValues.directory ?? "/",
						repo: bundleField,
						identifier: this.formValues.branch ?? ""
					}
				},

				orgId: this.baseOrgId
			};

			try {
				const payload: LibraryImportRequest = this.newCred?.id
					? { ...bundleRequest, artifactCredId: this.newCred.id }
					: bundleRequest;

				await importInfrastructureLibrary(payload);
				await orgStore.GET_SET_ORG({ id: this.baseOrgId });

				this.isPrivateRepo = false;
				this.isSubmitting = false;

				orgStore.SET_APP_FIRST_FLOW(false);
				orgStore.SWITCH_ORG({ orgId: this.baseOrgId });

				this.generateNotification(true);

				this.$router
					.push({
						name: "org-settings-global",
						params: { orgId: this.baseOrgId, tabName: "credentials" }
					})
					.catch(err => {
						throw new Error(err);
					});

				// remove the form values from local storage as we don't need it anymore
				AppFirstFlowFormStorageService.removeAppFirstFlowForm();
			} catch (error) {
				const msg = getErrorMessage(error);
				this.submitError = msg;
				const errorCode = getErrorCode(error);
				this.isSubmitting = false;
				if (errorCode === "InfraLibraryInvalidSourceRepo") {
					/**
					 * Either of the below is true
					 * - Spelling mistake in the form
					 * - Repo is private and needs credentials access.
					 * Therefore show the GitHub Form to the user.
					 */
					this.isPrivateRepo = true;
				}
			} finally {
				// After successfull apply trigger set the org flow flag to true
				await userStore.UPDATE_USER_META({
					checkListFlow: {
						orgFlow: true,
						credFlow: false,
						projFlow: false
					}
				});
			}
		},

		async setAuthenticated(isAuthenticated: boolean) {
			if (isAuthenticated) {
				// check if credentialModal ref exists and call saveGithubCredentials
				if (this.$refs.credentialModal) {
					await (
						this.$refs.credentialModal as InstanceType<typeof GithubCredentialModal>
					).saveGithubCredentials();
				}
				this.isPrivateRepo = false;
			}
		},

		/**
		 *
		 * @param cred - Cred passed from the GithubCredentialModal component
		 * This cred is saved in localStorage to be accessed later when the bundle is applied
		 */
		onCredCreate(cred: Creds) {
			this.isCredAuthenticated = true;
			this.isSuccess = true;
			this.isSubmitting = false;

			this.newCred = cred;
			const existingFormValues = AppFirstFlowFormStorageService.getAppFirstFlowForm();

			AppFirstFlowFormStorageService.setAppFirstFlowForm({
				...existingFormValues,
				cred,
				gitFormRef: "appFirstFlow"
			});
		},

		/**
		 * This function is called when the user clicks on the "close" button
		 * It will reset the app first flow flag and remove the form values from local storage if any
		 */
		async quitFlow() {
			await userStore.UPDATE_USER_META({
				appFirstFlow: true
			});
			orgStore.SET_APP_FIRST_FLOW(false);
			AppFirstFlowFormStorageService.removeAppFirstFlowForm();
			GithubOauthStorageService.removeGithubUserOauth();
			this.$emit("close-flow");
			// If the org is created,delete it as the user has quit the flow.
			if (this.baseOrgId) {
				// Delete the creds first if they are created.
				if (this.newCred) {
					await credentialStore.deleteCredential({ cred: this.newCred });
				}
				await orgStore.DELETE_ORG({ id: this.baseOrgId });
			}
		}
	}
});
</script>
<style lang="scss" scoped>
.description-field > div {
	width: 100%;
}
</style>
