<template>
	<PopOver
		ref="popover"
		:target="target"
		:placement="placement"
		data-qa-create-app-wizard
		:open="open"
		@overlay-click="$emit('overlay-click')"
	>
		<slot />
		<template #content>
			<Wrapper
				class="create-app-wrapper"
				border-radius="4px"
				background="element-light"
				:border="true"
				:width="wrapperWidth"
				data-qa-create-app-wizard-modal
				max-height="90vh"
				overflow="visible"
			>
				<Container
					:padding="0"
					:gap="0"
					direction="column"
					:grow="1"
					align="start top"
					overflow="hidden"
				>
					<Header v-if="submitError || currentStep !== '5-create-app'">
						<Container align="left center" :padding="0" :grow="1">
							<Icon
								v-if="!submitError && currentStep !== '1-git-info'"
								name="i-arrow-left"
								type="filled"
								size="x-small"
								data-qa-create-app-popover-close
								@click.stop="handleBackAction"
							/>

							<Container :gap="12" :padding="0">
								<Typography data-qa-create-app-modal-head-title type="h4" color="dark"
									>Add application to project</Typography
								>
							</Container>

							<Container align="right center" :grow="1" :padding="0">
								<Icon
									name="i-close"
									type="filled"
									size="x-small"
									data-qa-create-app-modal-close
									@click.stop="$emit('close')"
								/>
							</Container>
						</Container>
					</Header>

					<CreateApplicationWizardPipelineStep
						v-if="currentStep === '4-rsaf-pipeline-config'"
						:project="project"
						@output="handlePipelineOutput"
					/>

					<Container
						v-else
						padding="10px 16px 16px 16px"
						direction="column"
						overflow="scroll"
						class="flow-add-scrollbar"
						align="stretch top"
					>
						<template v-if="currentStep === '1-git-info'">
							<Typography type="p1" weight="regular" color="gray-200"
								>Connect Git repository</Typography
							>

							<Divider />

							<GitRevisionForm
								v-model:form-values="gitFormValues"
								:revision-type="GitRevisionType.branch"
								:existing-names="existingAppNames"
							/>
						</template>

						<template v-else-if="currentStep === '2-deployment-inputs'">
							<Typography type="p1" weight="regular" color="gray-200"
								>Deployment pipeline configuration</Typography
							>

							<Divider />

							<DeploymentInputsAndVariables v-model:values="deploymentValues" :project="project" />
						</template>

						<template v-else-if="currentStep === '3-container'">
							<Typography type="p1" weight="regular" color="gray-200"
								>Connect container repository</Typography
							>

							<Divider />

							<ApplicationContainerForm
								v-model:form-values="containerFormValues"
								:suggested-container-repo="suggestedContainerRepo"
								:suggested-container-provider="suggestedContainerProvider ?? undefined"
							/>
						</template>

						<template v-else-if="currentStep === '5-create-app'">
							<Typography type="p1" weight="regular" color="gray-200"
								>Creating application</Typography
							>

							<Divider />

							<Container
								v-for="step in Object.values(applicationSaveSteps)"
								:key="step.title"
								:data-qa-app-create-step="step.title"
								:data-qa-app-create-step-is-complete="step.isComplete"
								:data-qa-app-create-step-is-loading="step.isWorking"
								padding="0"
							>
								<Icon v-if="step.isComplete" name="i-tick-2" size="x-small" color="success-300" />
								<Icon v-else-if="step.isWorking" name="i-loader" size="x-small" animate />
								<Icon v-else name="clock" size="x-small" color="gray-400" />

								<Typography type="p2" weight="regular" color="white">{{ step.title }}</Typography>
							</Container>

							<Container v-if="submitError" data-qa-app-create-submit-error padding="10px 0 0 0">
								<Typography type="p2" color="error" family="logs">{{ submitError }}</Typography>
							</Container>
						</template>
					</Container>

					<Footer v-if="currentStep !== '5-create-app'">
						<Container direction="column" :grow="1" :gap="0" :padding="0">
							<Button
								state="full"
								type="success"
								:disabled="isNextStepDisabled"
								data-qa-app-wizard-next-step
								@click="handleStepAction"
								>{{ actionText }}</Button
							>
						</Container>
					</Footer>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	PopOver,
	PopoverPlacement,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import {
	applicationDeploymentStore,
	getEnvMapFromVariables
} from "@/modules/application-deployment/application-deployment-store";
import { applicationIntegrationStore } from "@/modules/application-integration/application-integration-store";
import { credentialStore } from "@/modules/credentials/credential-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import { app as appProto } from "@/protocol/app";
import { Artifact, ArtifactType, GitRevisionType, GitServer, sourceEnum } from "@/protocol/common";
import { triggerType } from "@/protocol/externalEvents";
import { CredScope, credsType, project } from "@/protocol/identity";
import { pipelineModule } from "@/protocol/pipeline";
import GitRevisionForm, {
	GitRevisionFormValues
} from "@/shared/components/Form/GitRevisionForm.vue";
import { CredsTypeToContainerRegistryProvider } from "@/shared/constants";
import { addProtocol, captureError, getErrorMessage, parseGitUrl, safeEntityString } from "@/utils";
import { artitactToArtifactSelector } from "@/utils/artifact-to-artifactselector";

import { applicationStore } from "../application-store";

import ApplicationContainerForm, {
	ApplicationContainerFormValues
} from "./ApplicationContainerForm.vue";
import CreateApplicationWizardPipelineStep, {
	PipelineOutput
} from "./CreateApplicationWizardPipelineStep.vue";
import DeploymentInputsAndVariables, {
	DeploymentInputsAndVariablesValues
} from "./DeploymentInputsAndVariables.vue";

function getApplicationSaveSteps() {
	return {
		app: {
			title: "Creating app",
			isComplete: false,
			isWorking: false
		},
		deployment: {
			title: "Creating deployments",
			isComplete: false,
			isWorking: false
		},
		pipelines: {
			title: "Creating pipelines",
			isComplete: false,
			isWorking: false
		}
	};
}

export default defineComponent({
	name: "CreateApplicationWizard",

	components: {
		ApplicationContainerForm,
		Button,
		Container,
		CreateApplicationWizardPipelineStep,
		DeploymentInputsAndVariables,
		Divider,
		Footer,
		GitRevisionForm,
		Header,
		Icon,
		PopOver,
		Typography,
		Wrapper
	},

	props: {
		open: Boolean,
		target: String,

		project: {
			type: Object as PropType<project>,
			required: true
		},

		placement: {
			type: String as PropType<PopoverPlacement>,
			default: () => "right"
		}
	},

	emits: {
		goBack: null,
		close: null,
		"overlay-click": null,

		appCreated: (app: appProto) => {
			return Boolean(app.id);
		}
	},

	data() {
		return {
			GitRevisionType,
			isPipelineStepValid: false,
			pipelinesOutput: [] as PipelineOutput[],
			gitFormValues: {} as GitRevisionFormValues,
			containerFormValues: {} as ApplicationContainerFormValues,
			submitError: "",

			currentStep: "1-git-info" as
				| "1-git-info"
				| "2-deployment-inputs"
				| "3-container"
				| "4-rsaf-pipeline-config"
				| "5-create-app",

			deploymentValues: {
				isValid: false,
				variables: [],
				inputs: {},
				template: null as pipelineModule | null
			} as DeploymentInputsAndVariablesValues,

			applicationSaveSteps: getApplicationSaveSteps()
		};
	},

	computed: {
		projectHasEnvironments() {
			const envs = envListStore.envs[this.project.id];
			return envs && envs.length > 0;
		},

		existingAppNames() {
			return (applicationStore.projectApps[this.project.id] ?? []).map(app => app.name);
		},

		suggestedContainerProvider() {
			const dockerCred = credentialStore.entityCredentials[this.project.id]?.find(
				cred => cred.credScope?.includes(CredScope.docker)
			);

			if (!dockerCred?.type) {
				return null;
			}

			return CredsTypeToContainerRegistryProvider[dockerCred.type];
		},

		suggestedContainerRepo() {
			if (!this.gitFormValues.repoName) {
				return "";
			}

			try {
				const parsedUrl = parseGitUrl(this.gitFormValues.repoName);

				if (!parsedUrl) {
					return "";
				}

				return new URL(parsedUrl.protocolRepo).pathname.replace(/^\//, "");
			} catch {
				return "";
			}
		},

		showRsafAzureSteps() {
			const entityCredentials = credentialStore.entityCredentials[this.project.id] ?? [];
			const cloudCred = entityCredentials.find(cred => cred.credScope?.includes(CredScope.cloud));

			const isAzure = cloudCred?.type === credsType.credsType_azure;

			// We only allow the pipeline step if the cloud is Azure
			return featureFlagStore.featureMap.ENABLE_NX_PLATFORM && isAzure;
		},

		hasContainerRequirement() {
			return this.deploymentValues.template?.expectedArtifactType?.includes(
				ArtifactType.ContainerImage
			);
		},

		wrapperWidth() {
			if (this.currentStep === "2-deployment-inputs") {
				return "700px";
			}

			if (this.currentStep === "4-rsaf-pipeline-config") {
				return "470px";
			}

			return "432px";
		},

		isNextStepDisabled() {
			if (this.currentStep === "1-git-info" && !this.gitFormValues.isValid) {
				return true;
			}

			if (this.currentStep === "2-deployment-inputs" && !this.deploymentValues.isValid) {
				return true;
			}

			if (this.currentStep === "3-container" && !this.containerFormValues.isValid) {
				return true;
			}

			if (this.currentStep === "4-rsaf-pipeline-config") {
				return !this.isPipelineStepValid;
			}

			return false;
		},

		actionText() {
			const finalStepTitle = this.showRsafAzureSteps
				? "Next - Configure integration pipeline"
				: "Create app";

			if (this.currentStep === "1-git-info") {
				return this.projectHasEnvironments
					? "Next - Configure Deployment Pipeline"
					: finalStepTitle;
			}

			if (this.currentStep === "2-deployment-inputs") {
				return this.hasContainerRequirement ? "Next - Configure container details" : "Next";
			}

			if (this.currentStep === "3-container") {
				return finalStepTitle;
			}

			if (this.currentStep === "4-rsaf-pipeline-config") {
				return finalStepTitle;
			}

			return "Next";
		}
	},

	watch: {
		open: {
			immediate: true,

			handler() {
				this.currentStep = "1-git-info";
				this.gitFormValues = {};
				this.containerFormValues = {
					isValid: false
				};
				this.deploymentValues = {
					isValid: false,
					variables: [],
					inputs: {},
					template: null
				};
				this.applicationSaveSteps = getApplicationSaveSteps();
				this.updateLayout();
			}
		},

		"deploymentValues.template"() {
			this.updateLayout();
		},

		containerFormValues() {
			this.updateLayout();
		}
	},

	methods: {
		updateLayout() {
			const popOver = this.$refs.popover as typeof PopOver | undefined;
			popOver?.update();
		},

		async handleStepAction() {
			// If we have CMP flow it needs an additional step
			const finalStep = this.showRsafAzureSteps ? "4-rsaf-pipeline-config" : "5-create-app";

			if (this.currentStep === "1-git-info") {
				this.currentStep = this.projectHasEnvironments ? "2-deployment-inputs" : finalStep;
				// If we are in inputs step and we found out that the user needs to provide a container then
				// we take them to container step
			} else if (this.currentStep === "2-deployment-inputs") {
				// If container step is not needed but the user is a CMP user where we need to have a special CI step
				// we send them there
				this.currentStep = this.hasContainerRequirement ? "3-container" : finalStep;
			} else if (this.currentStep === "3-container") {
				this.currentStep = finalStep;
			} else if (this.currentStep === "4-rsaf-pipeline-config") {
				this.currentStep = "5-create-app";
			}

			// If we ended up on the create app step then we try to create the app
			if (this.currentStep === "5-create-app") {
				try {
					await this.createApp();
				} catch (err) {
					captureError(err);
					this.submitError = getErrorMessage(err, true);
				}
			}

			this.updateLayout();
		},

		async createApp() {
			this.submitError = "";
			this.applicationSaveSteps.app.isWorking = true;

			const appName = safeEntityString(
				this.gitFormValues.artifactName ?? "",
				this.existingAppNames
			);

			const artifacts: Artifact[] = [
				{
					name: "git repository",
					gitCode: {
						type: this.gitFormValues.revisionType?.data.id ?? GitRevisionType.branch,
						identifier: this.gitFormValues.revisionIdentifier!,
						dir: this.gitFormValues.directoryPath!,
						repo: addProtocol(this.gitFormValues.repoName!),
						driver: GitServer.github
					}
				}
			];

			if (this.hasContainerRequirement) {
				artifacts.push({
					name: "container repository",
					containerImage: {
						provider: this.containerFormValues.containerProvider!.data.id!,
						host: this.containerFormValues.appHost,
						repo: this.containerFormValues.containerRepo!,
						reference: this.containerFormValues.containerTag,
						versionTagPattern: this.containerFormValues.versionTagPattern?.data.id
					}
				});
			}

			const app = await applicationStore.CREATE_APPLICATION({
				orgId: this.project.orgId,
				projId: this.project.id,
				name: appName,
				artifacts,
				appConfig: {
					language: this.gitFormValues.appLanguage?.data.id ?? sourceEnum.source_no
				}
			});

			this.applicationSaveSteps.app.isComplete = true;
			this.applicationSaveSteps.deployment.isWorking = true;

			const environments = envListStore.envs[this.project.id];

			const { template: depTemplate, inputs, variables } = this.deploymentValues;

			if (environments && depTemplate) {
				const artifact = app.artifacts?.find(
					artifact_ =>
						artifact_.type ===
						(this.hasContainerRequirement ? ArtifactType.ContainerImage : ArtifactType.GitCode)
				);

				await Promise.all(
					environments.map(env => {
						return applicationDeploymentStore.CREATE_APPLICATION_DEPLOYMENT({
							orgId: this.project.orgId,
							projId: this.project.id,
							envId: env.id,
							name: safeEntityString(`${appName} ${env.name}`),
							appId: app.id,
							deploymentConfig: {
								artifact: artifact && artitactToArtifactSelector(artifact),
								template: {
									moduleRefId: `${depTemplate.provisioner}:${depTemplate.name}@${depTemplate.version}`,
									inputs: inputs as Record<string, unknown>
								},
								...getEnvMapFromVariables(variables)
							}
						});
					})
				);
			}

			this.applicationSaveSteps.deployment.isComplete = true;
			this.applicationSaveSteps.pipelines.isWorking = true;

			await Promise.all(
				this.pipelinesOutput.map(async pipeline => {
					const pipelineModules = pipeline.modules
						.filter(module => module.module)
						.map(module => ({
							moduleRefId: `${module.module!.provisioner}:${module.module!.name}@${
								module.module!.version
							}`,
							inputs: module.input
						}));

					const gitArtifact = app.artifacts?.find(
						artifact => artifact.type === ArtifactType.GitCode
					);

					const integration = await applicationIntegrationStore.CREATE_APP_INTEGRATION({
						appId: app.id,
						name: pipeline.name,
						orgId: this.project.orgId,
						projId: this.project.id,
						inputAppArtifact: gitArtifact && artitactToArtifactSelector(gitArtifact),
						pipeline: pipelineModules,
						// Hotfix for backend as it crashes with no empty object
						// https://cldcvr.slack.com/archives/G013A596198/p1675776525704019
						config: {}
					});

					if (pipeline.webhook) {
						await webHooksStore.CREATE_WEB_HOOK({
							orgId: this.project.orgId,
							projectId: this.project.id,
							entityId: integration.id,
							triggerType: triggerType.appInt,
							appInt: {
								id: integration.id,
								appId: app.id,
								orgId: this.project.orgId,
								projId: this.project.id
							},
							githubTrigger: {
								triggerEvent: pipeline.webhook.trigger,
								identifier: pipeline.webhook.branch
							}
						});
					}
				})
			);

			this.applicationSaveSteps.pipelines.isComplete = true;

			await new Promise(resolve => window.setTimeout(resolve, 1000));

			this.$emit("appCreated", app);
		},

		handleBackAction() {
			if (this.currentStep === "2-deployment-inputs") {
				this.currentStep = "1-git-info";
			} else if (this.currentStep === "4-rsaf-pipeline-config") {
				if (this.hasContainerRequirement) {
					this.currentStep = "3-container";
				} else {
					this.currentStep = this.projectHasEnvironments ? "2-deployment-inputs" : "1-git-info";
				}
			} else if (this.currentStep === "3-container") {
				this.currentStep = "2-deployment-inputs";
			} else if (this.currentStep === "5-create-app") {
				this.currentStep = "2-deployment-inputs";
			}
		},

		handlePipelineOutput({
			isValid,
			pipelines
		}: {
			isValid: boolean;
			pipelines: PipelineOutput[];
		}) {
			this.isPipelineStepValid = isValid;
			this.pipelinesOutput = pipelines;
		}
	}
});
</script>

<style lang="scss" scoped>
.create-app-wrapper {
	will-change: width;
	transition: width 0.3s ease-in-out;
}
</style>
