<template>
	<PopOver :open="showEditModal" placement="left" @overlay-click="validateAndClosePopover">
		<Icon
			v-tooltip="{
				content: 'edit credential',
				placement: 'left'
			}"
			name="i-setting"
			size="small"
			type="filled"
			state="primary"
			:data-qa="'cred-edit-btn-' + cred.name"
			@click="showGithubCredentialModal"
		/>
		<template #content>
			<GithubCredentialModal
				ref="credentialModal"
				:cred="cred"
				:git-form-ref="cred.id"
				:update-cred-list="true"
				@close-modal="closeModal"
			/>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { Icon, PopOver } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { Creds } from "@/protocol/identity";
import { GithubOauthStorageService } from "@/services/storage-service";

import GithubCredentialModal from "./GithubCredentialModal.vue";

export default defineComponent({
	name: "EditGithubCredentialModel",

	components: {
		GithubCredentialModal,
		PopOver,
		Icon
	},

	props: {
		cred: {
			type: Object as PropType<Creds>,
			required: true
		}
	},

	data: () => ({
		showEditModal: false
	}),

	mounted() {
		this.githubModalObserver();
	},

	methods: {
		showGithubCredentialModal() {
			this.showEditModal = !this.showEditModal;
			this.$emit("modelOpen", this.showEditModal ? this.cred.id : null);
		},

		validateAndClosePopover() {
			const ref = this.$refs.credentialModal as InstanceType<typeof GithubCredentialModal>;
			if (ref) {
				ref.validateAndCloseModal();
			}
		},

		closeModal() {
			this.showEditModal = false;
			this.$emit("closeModal");
			this.$emit("modelOpen", null);
		},

		scroll(id: string) {
			return document.getElementById(id)?.scrollIntoView({
				behavior: "smooth"
			});
		},

		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === this.cred.id) {
				this.showGithubCredentialModal();
				this.scroll(this.cred.id);
			}
		}
	}
});
</script>
