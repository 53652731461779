<script setup lang="ts">
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { EmptyState, PopOver } from "@cldcvr/flow-vue3";
import { PropType, computed, onMounted, ref, toRefs, watch } from "vue";

import AssignCredentialList from "@/modules/credentials/components/credential-assign/AssignCredentialList.vue";
import { credentialStore } from "@/modules/credentials/credential-store";
import { newEnvPipelineStore } from "@/modules/env-pipeline/env-pipeline-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { OrgRole } from "@/modules/org/org-types";
import { CredScope } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { GithubOauthStorageService } from "@/services/storage-service";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { buildForm } from "@/shared/components/JSONSchemaFormBuilder2.vue";
import { CredsTypeToProvisioner } from "@/shared/constants";
import { JSONSchemaToObject, captureError, getErrorMessage, removeEmptyValues } from "@/utils";

import { envListStore } from "../env-list-store";

const props = defineProps({
	env: { type: Object as PropType<environment>, required: true }
});

const { env } = toRefs(props);

const pipelineConfigForm = ref<FormBuilderField | null>(null);
const pipelineConfigValues = ref(env.value.pipelineConfig);
const pipelineFormState = ref<FormBuilderState | null>(null);
const isSavingForm = ref(false);
const credModalOpen = ref(false);
const isLoading = ref(true);
const submitError = ref("");
const gitFormRef = "EnvPipelineConfiuration";

const hasEnvironmentPermissions = computed(() => {
	return env.value.role === OrgRole.ADMIN;
});

const envCredential = computed(() => {
	const credential = credentialStore.entityCredentials[env.value.id]?.find(
		cred => cred.credScope?.includes(CredScope.cloud)
	);

	return credential?.type ? CredsTypeToProvisioner[credential.type] : null;
});

watch(
	[env, envCredential],
	async () => {
		// Don't have permissions or credentials
		if (!hasEnvironmentPermissions.value || !envCredential.value) {
			isLoading.value = false;
			return;
		}

		try {
			isLoading.value = true;

			const envValue = env.value;

			const pipelineConfig = await newEnvPipelineStore.GET_ENV_PIPELINE_CONFIG({
				envId: envValue.id,
				orgId: envValue.orgId,
				projId: envValue.projId
			});

			if (pipelineConfig.inputs) {
				pipelineConfigForm.value = buildForm({
					schema: pipelineConfig.inputs,
					name: "",
					parentName: "",
					isNested: false,
					isRequired: true
				});

				// Merge default values
				pipelineConfigValues.value = JSONSchemaToObject(
					pipelineConfig.inputs,
					pipelineConfigValues.value
				) as Record<string, unknown>;
			}
		} catch (err) {
			submitError.value = getErrorMessage(err, true);
			captureError(err);
		} finally {
			isLoading.value = false;
		}
	},
	{
		immediate: true
	}
);

async function saveEnvironment() {
	try {
		isSavingForm.value = true;
		const envValue = env.value;

		await envListStore.UPDATE_ENV({
			id: envValue.id,
			orgId: envValue.orgId,
			projId: envValue.projId,
			name: envValue.name,
			description: envValue.description,
			revision: envValue.revision,
			variables: envValue.variables,
			policySetId: envValue.policySetId,
			metadata: envValue.metadata,
			tfVersion: envValue.tfVersion,
			pipelineConfig: removeEmptyValues(pipelineConfigValues.value, {
				removeEmptyStrings: true
			}),
			replaceVariables: false
		});

		notificationsStore.ADD_TOAST({
			qaId: "toast-env-save-success",
			title: "Pipeline configuration updated successfully",
			text: `The environment pipeline for ${env.value.name} is updated.`,
			status: "success"
		});
	} catch (err) {
		submitError.value = getErrorMessage(err, true);
		captureError(err);
	} finally {
		isSavingForm.value = false;
	}
}

// Although cloud creds can never be from github but adding this as future-proofing
onMounted(() => {
	const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
	if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === gitFormRef) {
		credModalOpen.value = true;
	}
});

defineEmits(["assign-cloud-account"]);
</script>

<template>
	<f-div v-if="isLoading" width="fill-container" height="fill-container">
		<CustomLoader />
	</f-div>
	<f-div v-else direction="column" height="fill-container" align="top-left" padding="large">
		<EmptyState
			v-if="!hasEnvironmentPermissions"
			icon="i-user-stop"
			message="No permissions"
			subtitle="You do not have permissions to edit environment pipeline configuration."
		/>
		<EmptyState
			v-else-if="!envCredential"
			icon="i-cloud"
			message="Cloud account missing"
			subtitle="You have not assigned a cloud account to this environment."
			action="Assign cloud account"
			@actions="credModalOpen = true"
		/>
		<f-div
			v-else
			max-width="500px"
			overflow="visible"
			direction="column"
			gap="large"
			height="hug-content"
		>
			<f-text size="small" variant="heading" data-qa-pipeline-configuration-title
				>Pipeline Configuration</f-text
			>
			<f-form-builder
				v-if="pipelineConfigForm"
				data-qa-env-pipeline-configuration-form
				:field.prop="pipelineConfigForm"
				:values.prop="pipelineConfigValues"
				@state-change="pipelineFormState = $event.detail"
			/>

			<f-text v-if="submitError" state="danger">{{ submitError }}</f-text>

			<f-button
				label="Save"
				variant="block"
				state="success"
				:disabled="!pipelineFormState?.isValid"
				:loading="isSavingForm"
				data-qa-save-pipeline-configuration
				@click="saveEnvironment"
			></f-button>
		</f-div>
	</f-div>

	<PopOver v-if="env" target="[data-qa-empty-state-action]" :open="credModalOpen">
		<template #content>
			<AssignCredentialList
				:entity="env"
				:git-form-ref="gitFormRef"
				:show-back-button="false"
				entity-kind="environment"
				:cred-scope="CredScope.cloud"
				@on-close="credModalOpen = false"
				@force-close="credModalOpen = false"
				@on-credential-assigned="credModalOpen = false"
			/>
		</template>
	</PopOver>
</template>
