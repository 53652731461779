import { pipelineModule } from "@/protocol/pipeline";

/**
 * Pipeline status for application deployment/env deployment jobs are not typed from the server
 * but plain strings. So we type them and export them here.
 */
export const JOB_STATUS = {
	ACTIVE: "active",
	DONE: "done",
	FAILED: "failed",
	CANCELLING: "canceling",
	CANCELLED: "canceled",
	PENDING: "pending",
	WAITING: "waiting",
	STALLED: "stalled"
} as const;

export type PipelineJobStatus = (typeof JOB_STATUS)[keyof typeof JOB_STATUS];
export type PipelineJobStepStatus = (typeof JOB_STEP_STATUS)[keyof typeof JOB_STEP_STATUS];

export const JOB_STEP_STATUS = {
	[JOB_STATUS.ACTIVE]: "Processing",
	[JOB_STATUS.DONE]: "Completed",
	[JOB_STATUS.FAILED]: "Failed",
	[JOB_STATUS.CANCELLING]: "Cancelling",
	[JOB_STATUS.CANCELLED]: "Cancelled",
	[JOB_STATUS.PENDING]: "Not Started",
	[JOB_STATUS.WAITING]: "Waiting",
	[JOB_STATUS.STALLED]: "Stalled"
} as const;

export const STAGECARD_STATUS: Record<PipelineJobStatus, string> = {
	[JOB_STATUS.ACTIVE]: "inProgress",
	[JOB_STATUS.DONE]: "success",
	[JOB_STATUS.FAILED]: "warning",
	[JOB_STATUS.CANCELLING]: "inProgress",
	[JOB_STATUS.CANCELLED]: "error",
	[JOB_STATUS.PENDING]: "inProgress",
	[JOB_STATUS.WAITING]: "inProgress",
	[JOB_STATUS.STALLED]: "disabled"
};

export const PIPELINE_FINISHED_JOB_STATUSES: PipelineJobStatus[] = [
	JOB_STATUS.DONE,
	JOB_STATUS.FAILED,
	JOB_STATUS.CANCELLED
];

export const PIPELINE_ACTIVE_JOB_STATUSES: PipelineJobStatus[] = [
	JOB_STATUS.ACTIVE,
	JOB_STATUS.PENDING,
	JOB_STATUS.WAITING,
	JOB_STATUS.STALLED
];

export const PIPELINE_UNFINISHED_JOB_STATUSES: PipelineJobStatus[] = [
	...PIPELINE_ACTIVE_JOB_STATUSES,
	JOB_STATUS.CANCELLING
];

export const PIPELINE_BAD_STATUSES: PipelineJobStatus[] = [
	JOB_STATUS.FAILED,
	JOB_STATUS.CANCELLED,
	JOB_STATUS.CANCELLING
];

export const TERMINAL_FILTERS = {
	TRACE: { id: "TRACE", name: "Trace", filterString: "[TRACE]" },
	DEBUG: { id: "DEBUG", name: "Debug", filterString: "[DEBUG]" },
	INFO: { id: "INFO", name: "Info", filterString: "[INFO]" },
	WARNING: { id: "WARNING", name: "Warning", filterString: "[WARN]" },
	ERROR: { id: "ERROR", name: "Error", filterString: "[ERROR]" }
} as const;

export const TERMINAL_FILTER_ITEMS = Object.values(TERMINAL_FILTERS);

export type TerminalFilterId = keyof typeof TERMINAL_FILTERS;
export type TerminalFilter = (typeof TERMINAL_FILTERS)[TerminalFilterId];

export interface PipelineNode {
	name?: string;
	nodeNumber: string;
	type: string;
	statusText: string;
	status: PipelineJobStepStatus;
	selected: boolean;
	infoIcon: {
		remove: boolean;
	};
}

export interface PipelineEdge {
	source: string;
	target: string;
}

export interface PipelineModuleToAddRef {
	moduleRefId: string;
	name: string;
	moduleValues: Record<string, any>;
	module: pipelineModuleWithRefId;
	order?: number;
}

export interface PipelineModuleToAdd {
	module: pipelineModuleWithRefId;
	moduleValues: Record<string, any>;
}

export type pipelineModuleWithRefId = pipelineModule & {
	moduleRefId: string;
	isAdded: boolean;
	icon?: string;
	type?: string;
};
