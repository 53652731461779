import { CheckboxOption, html } from "@cldcvr/flow-form-builder";

import { variable, variableType } from "@/protocol/common";
import { environment } from "@/protocol/infra";

export type RenderedEnvironment = {
	env: environment;
	selected: boolean;
};

export type VariableTable = VariableProjectTable | VariableEnvTable | VariableAppTable;

export type VariableProjectTable = {
	title: string;
	id: string;
	type: "project";
	rows: VariableProjectRow[];
};

export type VariableEnvTable = {
	title: string;
	id: string;
	type: "environment";
	rows: VariableEnvRow[];
};

export type VariableAppTable = {
	title: string;
	id: string;
	type: "application-deployment";
	rows: VariableAppRow[];
};

export type VariableState = "equal" | "notequal" | "error" | "secret";

export type QuickFilter = VariableState | "none";

export type VariableValueState = "success" | "error" | "secret";

export type VariableSource =
	| "project"
	| "environment"
	| "application-deployment"
	| "application-integration";

export type VariableEditInfo =
	| {
			type: "project";
			orgId: string;
			projectId: string;
	  }
	| {
			type: "environment";
			orgId: string;
			projectId: string;
			envId: string;
	  }
	| {
			type: "application-deployment";
			orgId: string;
			projectId: string;
			envId: string;
			applicationDeploymentId?: string;
			applicationId: string;
	  }
	| {
			type: "application-integration";
			orgId: string;
			projectId: string;
			applicationintegrationId?: string;
			applicationId: string;
	  };

export type VariableColumn = {
	name: string;
	state: VariableValueState;
	value: string | null;
	key: string | null;
	type: variableType | null;
	editInfo: null | VariableEditInfo;
	isSensitive: boolean;
	inheritedValues?: Array<{
		title?: string;
		variable: variable;
		valueFrom: VariableSource;
		icon?: string;
	}>;
};

export type VariableRow = VariableProjectRow | VariableEnvRow | VariableAppRow;

export type VariableProjectRow = {
	state: VariableState;
	key: string;
	isSensitive: boolean;
	varType: variableType | null;
	column: VariableColumn;
};

export type VariableEnvRow = {
	state: VariableState;
	key: string;
	isSensitive: boolean;
	varType: variableType | null;
	columns: Record<string, VariableColumn>;
};

export type VariableAppRow = {
	key: string;
	isSensitive: boolean;
	state: VariableState;
	columns: VariableColumn[];
};

export type VariableTableType = {
	id: string;
	title: string;
	header?: string;
	bottomBorder?: boolean;
	icon?: {
		name: string;
	};
	pictogram?: {
		shape?: "squircle" | "hexagon";
		content: string;
	};
};

export const TF_OPTION: CheckboxOption & { id: variableType } = {
	id: variableType.tf_var,
	iconTooltip: "Terraform variables define characterstics of application",
	title: html`<f-div gap="small" data-qa-id="terraform-radio-option">
		<f-text size="medium">Terraform Variable</f-text>
		<f-tag size="small" label="TF" state="custom, #924AEE"> </f-tag>
	</f-div>`
} as const;

export const ENV_OPTION: CheckboxOption & { id: variableType } = {
	id: variableType.env_var,
	iconTooltip:
		"Environment variables define aspects of the environment into which you are deploying the application.",
	title: html`<f-div gap="small" data-qa-id="environment-radio-option">
		<f-text size="medium">Environment Variable</f-text>
		<f-tag size="small" label="Env" state="custom, #008272"></f-tag>
	</f-div>`
} as const;

export type VariableTypeFormOption = typeof TF_OPTION | typeof ENV_OPTION;

export type Variable = {
	key: string;
	value: string;
	variableType: variableType;
	isMasked: boolean;
};

export const ALL_VARIABLES: VariableTableType = {
	id: "all-vars",
	title: "All variables",
	icon: {
		name: "i-variable"
	},
	bottomBorder: true
};

export const PROJECT_VARIABLES: VariableTableType = {
	id: "project-vars",
	title: "Project",
	bottomBorder: false,
	pictogram: {
		content: "🏠"
	}
};

export const INFRA_VARIABLES: VariableTableType = {
	id: "infra-vars",
	title: "Infrastructure",
	bottomBorder: true,
	icon: {
		name: "i-env-shape"
	}
};

export type AddVariableScope = {
	id: string;
	name: string;
	variableFrom: VariableSource;
	variables: Variable[];
};

export type VariableScope = {
	id: string;
	name: string;
	variableFrom: VariableSource;
	groupName: string;
	editInfo: VariableEditInfo;
	variables: Array<{
		key: string;
		variableType: variableType;
	}>;
	icon?: {
		name: string;
	};
	pictogram?: {
		shape?: "squircle" | "hexagon";
		content: string;
	};
};
