<template>
	<PopOver
		:open="openUsedIn"
		placement="right-start"
		data-qa-assigned-list
		@overlay-click="openUsedIn = !openUsedIn"
	>
		<InfoPopover v-if="isAssigned">
			<Container
				class="cursor-pointer"
				:gap="16"
				:padding="0"
				@mouseover="isHoverOnAssigned = true"
				@mouseleave="isHoverOnAssigned = false"
			>
				<!-- Assigned to - Project -->
				<Container v-if="projectCount" :gap="4" :padding="0" @click="openUsedInModal('projs')">
					<Pictogram
						size="s"
						shape="default"
						:state="isHoverOnAssigned ? 'primary-dark' : 'default'"
					>
						<Typography
							type="p4"
							weight="bold"
							color="dark"
							:data-qa="`assigned-to-project-count-${cred.name}`"
							>{{ projectCount }}</Typography
						>
					</Pictogram>
					<Typography
						family="logs"
						type="p2"
						overflow="nowrap"
						:data-qa="`assigned-to-project-title-${cred.name}`"
						:color="isHoverOnAssigned ? 'primary' : 'light'"
						>Project{{ projectCount > 1 ? "s" : "" }}</Typography
					>
				</Container>

				<!-- Assigned to - Environments -->
				<Container v-if="envCount" :gap="4" :padding="0" @click="openUsedInModal('envs')">
					<Pictogram
						size="s"
						shape="hexagon"
						:state="isHoverOnAssigned ? 'primary-dark' : 'default'"
					>
						<Typography
							type="p4"
							weight="bold"
							color="dark"
							:data-qa="`assigned-to-env-count-${cred.name}`"
							>{{ envCount }}</Typography
						>
					</Pictogram>
					<Typography
						family="logs"
						overflow="nowrap"
						type="p2"
						:data-qa="`assigned-to-env-title-${cred.name}`"
						:color="isHoverOnAssigned ? 'primary' : 'light'"
						>Environment{{ envCount > 1 ? "s" : "" }}</Typography
					>
				</Container>

				<!-- Assigned to - Applications -->
				<Container v-if="appCount" :gap="4" :padding="0" @click="openUsedInModal('apps')">
					<Pictogram
						size="s"
						shape="squircle"
						:state="isHoverOnAssigned ? 'primary-dark' : 'default'"
					>
						<Typography
							type="p4"
							weight="bold"
							color="dark"
							:data-qa="`assigned-to-app-count-${cred.name}`"
							>{{ appCount }}</Typography
						>
					</Pictogram>
					<Typography
						family="logs"
						overflow="nowrap"
						type="p2"
						:color="isHoverOnAssigned ? 'primary' : 'light'"
						:data-qa="`assigned-to-app-title-${cred.name}`"
						>App{{ appCount > 1 ? "s" : "" }}</Typography
					>
				</Container>
			</Container>
			<!-- Assigned to - ToolTip -->
			<template #body>
				<Typography type="p2-para" color="dark" data-qa="assigned-list-container-see-all"
					>See all</Typography
				>
			</template>
		</InfoPopover>
		<Typography v-else type="p2-para" color="dark"> - </Typography>

		<template #content>
			<AssignedToEntityModal
				:cred="cred"
				:force-select-tab="selectedTab"
				@close="openUsedIn = false"
			/>
		</template>
	</PopOver>
</template>
<script lang="ts">
import { Container, InfoPopover, Pictogram, PopOver, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import {
	default as AssignedToEntityModal,
	SelectedTab
} from "@/modules/credentials/components/credential-list/AssignedToEntityModal.vue";

import { CredWithAssignedEntities } from "../../store-types";

export default defineComponent({
	name: "AssignedToEntityCounts",

	components: {
		Container,
		Typography,
		InfoPopover,
		PopOver,
		Pictogram,
		AssignedToEntityModal
	},

	props: {
		cred: {
			type: Object as PropType<CredWithAssignedEntities>,
			required: true
		}
	},

	data: () => ({
		isHoverOnAssigned: false,
		openUsedIn: false,
		selectedTab: null as SelectedTab | null
	}),

	computed: {
		envCount() {
			return this.cred.stats.environments.length;
		},

		appCount() {
			return this.cred.stats.apps.length;
		},

		projectCount() {
			return this.cred.stats.projects.length;
		},

		isAssigned() {
			return this.projectCount > 0 || this.envCount > 0 || this.appCount > 0;
		}
	},

	methods: {
		openUsedInModal(tabName: SelectedTab) {
			this.selectedTab = tabName;
			this.openUsedIn = true;
		}
	}
});
</script>
