import { captureException } from "@sentry/vue";

export function captureError(err: unknown) {
	if (process.env.NODE_ENV !== "test") {
		captureException(err);
	}

	if (process.env.NODE_ENV !== "production") {
		// eslint-disable-next-line no-console
		console.error(err);
	}
}
