<template>
	<Loader v-if="appDepLoading" />

	<Container v-else-if="!currentApps.length" align="center" :grow="1">
		<EmptyApplications />
	</Container>

	<DeploymentsList v-else :deployments="deployments" :project-id="projectId" :env="env" />
</template>

<script>
import { Container, Loader } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import EmptyApplications from "@/modules/project-landing/views/EmptyApplications.vue";

import DeploymentsList from "./DeploymentsList.vue";

export default defineComponent({
	name: "AppSection",
	components: { EmptyApplications, Container, Loader, DeploymentsList },

	props: {
		metaKeys: {
			type: Object,
			required: true
		},

		env: { type: Object, required: true }
	},

	data: () => ({
		appDepLoading: true
	}),

	computed: {
		deployments() {
			const depEntities = this.envDeps[this.metaKeys.envId];

			if (!depEntities) {
				return [];
			}

			return depEntities.slice().sort((ent1, ent2) => ent1.name.localeCompare(ent2.name));
		},

		envDeps() {
			return applicationDeploymentStore.deploymentsInEnv;
		},

		projectId() {
			return this.metaKeys.projectId;
		},

		currentApps() {
			return applicationStore.projectApps[this.projectId] ?? [];
		}
	},

	mounted() {
		this.loadAppDeployments({
			orgId: this.metaKeys.orgId,
			projectId: this.projectId,
			envId: this.metaKeys.envId
		});
	},

	methods: {
		async loadAppDeployments({ orgId, projectId, envId }) {
			this.appDepLoading = true;
			await applicationDeploymentStore.LIST_ENV_APP_DEPLOYMENTS({
				orgId,
				projectId,
				envId
			});
			this.appDepLoading = false;
		}
	}
});
</script>
