import { classification as classificationProto } from "@/protocol/infra";

export function getClassificationIcon(classification?: classificationProto) {
	let classificationIcon = "i-dev";
	if (!classification) {
		return classificationIcon;
	}
	const icon = classification.metadata?.icon;
	const lowerCaseName = classification.name.toLowerCase();

	if (lowerCaseName) {
		const productionNames = ["prod", "production"];
		const stagingNames = ["stage", "staging"];

		if (productionNames.some(name => lowerCaseName.includes(name))) {
			classificationIcon = "i-prod";
		} else if (stagingNames.some(name => lowerCaseName.includes(name))) {
			classificationIcon = "i-staging";
		}
	}

	return typeof icon === "string" ? icon : classificationIcon;
}
