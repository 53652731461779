<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		data-qa-int-pipeline-confirm-cancel-popover
	>
		<Header>
			<Typography type="h4" color="dark" data-qa-confirm-pipeline-title>Cancel pipeline</Typography>
			<Icon
				size="x-small"
				type="filled"
				name="i-close"
				data-qa-confirm-pipeline-close-btn
				@click="$emit('close')"
			/>
		</Header>

		<Container
			padding="0 16px 16px 16px"
			align="left top"
			direction="column"
			:gap="20"
			overflow="visible"
		>
			<Typography type="p1-para" color="dark" data-qa-confirm-pipeline-desc>
				Are you sure you want to cancel this pipeline?
			</Typography>
		</Container>

		<Footer>
			<Button state="full" type="success" data-qa-confirm-pipeline-cancel-btn @click="triggerEvent"
				>CANCEL</Button
			>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "ConfirmCancelPipeline",

	components: {
		Wrapper,
		Container,
		Typography,
		Footer,
		Button,
		Header,
		Icon
	},

	emits: ["close", "confirmed"],

	methods: {
		triggerEvent() {
			this.$emit("confirmed");

			this.$nextTick(() => {
				this.$emit("close");
			});
		}
	}
});
</script>
