<template>
	<Layout
		id="app"
		v-model:open-sidebar="openMobileSidebar"
		class="home fc-dark"
		:data-qa-route="routeQaPath"
		:mode="scheme.mode"
		:theme="scheme.theme"
		:data-qa-layout-banner="scheme.theme"
	>
		<template v-if="isUserLoggedIn" #navbar>
			<CoreHeader v-if="isUserLoggedIn" @on-mobile-sidebar="toggleMobileSidebar" />
		</template>

		<template v-if="isUserLoggedIn" #sidebar-header>
			<SidebarHeader
				:show-search="openMobileSidebar"
				:search-string="searchString"
				@on-search-update="searchUpdate"
			/>
		</template>

		<!-- Sidebar to show the dashboard icons, only when user logged in -->
		<template v-if="isUserLoggedIn" #sidebar>
			<Container
				direction="column"
				align="stretch space-between"
				basis="100%"
				:padding="0"
				:gap="0"
			>
				<SidebarTopSection
					:search-string="searchString"
					@close-sidebar="openMobileSidebar = false"
				/>
				<SidebarBottomSection :open-mobile-sidebar="openMobileSidebar" />
			</Container>
		</template>

		<template #notification>
			<Container
				v-if="updatedVersion"
				data-qa-codepipes-version-update-notification
				align="left center"
				background="primary-dark"
				:grow="1"
			>
				<Icon size="small" name="i-info-fill" type="filled" state="white" :effects="false" />
				<Typography color="white"
					>An updated version of Code Pipes (v{{ updatedVersion }}) is available, you can update by
					reloading this tab.
				</Typography>
				<Container align="right center" :padding="0" :grow="1">
					<Button size="small" data-qa-reload-page-on-update @click="reloadPage">
						<Typography color="white" weight="bold">RELOAD</Typography>
					</Button>
					<Icon
						size="small"
						name="i-close"
						state="white"
						data-qa-dismiss-update-dialog
						@click="updatedVersion = null"
					/>
				</Container>
			</Container>
		</template>
		<!-- Commenting this as the org bundle import feature will be modified and we are not sure if this will be required anymore -->
		<!-- <BundleStatus v-if="bundleAppliedFor === 'org'" /> -->
		<RealtimeUpdateListener />
		<ToastManager />
		<OrgProjectChangeListener />
		<GithubOauthRouteListener v-if="isUserLoggedIn" />
		<AppUpdateChecker @version-updated="updatedVersion = $event" />
		<AppFirstFlowCredListener v-if="isUserLoggedIn" />

		<div v-if="allowDebugMenu">
			<DebugMenu />
		</div>

		<Container v-if="showCldCvrLogo" align="right">
			<Icon
				v-if="allowDebugMenu"
				id="debug-menu-trigger"
				v-tooltip="{
					content: 'Open Debug Menu',
					container: 'div.flow-layout',
					placement: 'bottom-end'
				}"
				name="i-bug"
				state="light"
				size="20px"
				type="filled"
				data-qa-debug-menu-icon
				@click="openDebugMenu"
			/>
		</Container>

		<div v-if="showCldCvrLogo" class="flow-auth-logo-outside">
			<Icon
				:name="codepipesFullIcon"
				state="dark"
				type="filled"
				:effects="false"
				size="xxx-large"
			/>
		</div>

		<!-- Main body container -->
		<router-view v-slot="{ Component }">
			<transition :name="transitionName" mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
	</Layout>

	<XPathInspector v-if="xPathInspectorState.isEnabled" />
</template>

<script lang="ts">
import { Button, Container, Icon, Layout, Typography } from "@cldcvr/flow-vue3";
import { defineAsyncComponent, defineComponent } from "vue";
import { RouteRecordRaw } from "vue-router";

import CoreHeader from "@/modules/core/components/CoreHeader.vue";
import SidebarBottomSection from "@/modules/core/components/SidebarBottomSection.vue";
import SidebarHeader from "@/modules/core/components/SidebarHeader.vue";
import SidebarTopSection from "@/modules/core/components/SidebarTopSection.vue";
import GithubOauthRouteListener from "@/modules/credentials/components/credential-form/GithubOauthRouteListener.vue";
import { debugMenuStore } from "@/modules/debug/debug-store";
import XPathInspector, { xPathInspectorState } from "@/modules/debug/XPathInspector.vue";
import { userStore } from "@/modules/user/user-store";
import { codepipesFullIcon } from "@/shared/icons";
import { setAnalyticsUserId } from "@/utils";
import { ENVIRONMENTS, getEnvironment } from "@/utils/get-environment";

import AppFirstFlowCredListener from "./AppFirstFlowCredListener.vue";
import AppUpdateChecker from "./AppUpdateChecker.vue";
import OrgProjectChangeListener from "./OrgProjectChangeListener.vue";
import RealtimeUpdateListener from "./RealtimeUpdateListener.vue";
import ToastManager from "./ToastManager.vue";

const DebugMenu = defineAsyncComponent(() => import("@/modules/debug/debug-menu.vue"));

export default defineComponent({
	name: "App",

	components: {
		Typography,
		Button,
		Container,
		CoreHeader,
		Icon,
		Layout,
		RealtimeUpdateListener,
		ToastManager,
		SidebarBottomSection,
		SidebarHeader,
		OrgProjectChangeListener,
		SidebarTopSection,
		AppUpdateChecker,
		GithubOauthRouteListener,
		DebugMenu,
		AppFirstFlowCredListener,
		XPathInspector
	},

	data: () => ({
		allowDebugMenu: getEnvironment() !== ENVIRONMENTS.PRODUCTION,
		transitionName: "trans-flip",
		openMobileSidebar: false,
		searchString: "",
		updatedVersion: null as null | string,
		xPathInspectorState,
		codepipesFullIcon
	}),

	computed: {
		userId() {
			return userStore.profile?.id;
		},

		scheme() {
			return userStore.colorScheme;
		},

		isUserLoggedIn() {
			return (
				userStore.isUserLoggedIn &&
				// This prevents rendering of the app when the user is logged in
				// but we haven't navigated to an auth page yet
				this.$route.meta.requiresAuth
			);
		},

		routeQaPath() {
			return this.$route.name ?? this.$route.path;
		},

		showCldCvrLogo() {
			let showLogo = false;
			if (this.$route.meta.showCldCvrLogo) {
				showLogo = true;
			}
			return showLogo;
		}
		// Commenting this as the org bundle feature will be modified and we are not sure if this is required anymore.
		// bundleAppliedFor() {
		// 	return bundleStore.bundleAppliedFor;
		// }
	},

	watch: {
		$route(to: RouteRecordRaw, from: RouteRecordRaw) {
			const routerAnim = "trans-flip";
			this.transitionName = routerAnim;
			const to_transition = to.meta?.transition;
			const from_transition = from.meta?.transition;
			if (!to_transition || !from_transition) {
				this.transitionName = "routing";
			}
		},

		userId: {
			immediate: true,

			handler(userId) {
				setAnalyticsUserId(userId ?? null);
			}
		}
	},

	methods: {
		toggleMobileSidebar() {
			this.openMobileSidebar = !this.openMobileSidebar;
		},

		searchUpdate(searchString: string) {
			this.searchString = searchString;
		},

		reloadPage() {
			window.location.reload();
		},

		openDebugMenu() {
			debugMenuStore.SHOW_DEBUG_MENU();
		}
	}
});
</script>

<style lang="scss">
// Flow 2 form builder needs it's own css to work with Flow 2 f-div. But this breaks compatibility with Flow 1
// So we have a workaround here
.container[data-direction="column"] f-form-builder {
	width: 100%;
}

.show-on-hover-parent {
	&:hover {
		.show-on-hover {
			display: flex !important;
		}
	}
	.show-on-hover {
		display: none !important;
	}
}

// This fixes jumping of table contents when switching between icons or text
td.flow-table-cell {
	vertical-align: middle;
}

// For compatibility between Flow 1 and 2
[data-direction="column"] {
	> f-div[height="fill-container"],
	> f-div:not([height]) {
		flex: 1 1;
		max-height: 100%;
	}

	> f-div[width="fill-container"],
	> f-div:not([width]) {
		width: 100%;
	}

	> f-form-builder,
	> f-accordion {
		width: 100%;
	}
	> f-accordion {
		flex: 0 0 auto;
	}
}

[data-direction="row"] {
	flex-direction: row;
	> f-div[height="fill-container"],
	> f-div:not([height]) {
		height: 100%;
	}

	> f-div[width="fill-container"],
	> f-div:not([width]) {
		flex: 1 1;
		max-width: 100%;
	}
}

.slide-left-enter-active,
.slide-left-leave-active {
	transition: all 0.2s ease-in-out;
}

.slide-left-enter-from,
.slide-left-leave-to {
	transform: translateX(100%);
	will-change: transform;
}
</style>
