<template>
	<Wrapper
		max-height="70vh"
		border-radius="4px"
		background="element-light"
		class="min-width-350"
		data-qa-github-cred-model
	>
		<!--START : Container-->
		<!--START : PopOver header-->
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			class="height-100-per flex-shrink-0"
		>
			<Header v-if="!isResusableState">
				<Icon
					v-if="showBackBtn"
					name="i-arrow-left"
					size="small"
					data-qa-github-cred-model-back-btn
					@click="validateAndCloseModal('back')"
				/>
				<Typography type="h4" color="dark" data-qa-github-cred-model-title>{{
					headerTxt
				}}</Typography>
				<Container :padding="0" :grow="1" align="right center">
					<Icon
						name="i-question-filled"
						type="filled"
						size="x-small"
						data-qa-github-cred-model-help-btn
						@click="openHelp"
					/>
					<Icon
						v-if="showCloseBtn"
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-github-cred-model-close-btn
						@click="validateAndCloseModal"
					/>
				</Container>
			</Header>
			<!--END : PopOver header-->
			<ClosePopoverConfirmationWarning
				v-if="!isResusableState"
				ref="closeConfirmation"
				:initial-form-values="{ ...initialformValues, oauthUserEmail: null }"
				:form-values="{ ...githubCredFormValues, oauthUserEmail }"
				@force-close="closeModal"
			/>

			<ModalNotificationBanner
				v-if="cred && !isResusableState"
				banner-type="primary"
				banner-body="Changes made are applicable to the future deployments."
			/>

			<ModalNotificationBanner
				v-if="submitError"
				banner-type="error"
				:banner-body="submitError"
				:show-close-icon="true"
				@close="submitError = null"
			/>

			<Container :padding="16" :gap="10" direction="column" overflow="auto" align="left top">
				<f-form-builder
					data-qa-github-cred-model-form
					:field.prop="formFields"
					:values.prop="githubCredFormValues"
					@input="saveFormValues"
					@state-change="formState = $event.detail"
				/>

				<Container
					v-if="authType == 'oauth'"
					overflow="visible"
					direction="column"
					padding="12px 4px"
					:gap="16"
					align-self="center"
				>
					<Typography v-if="oauthUserEmail" type="p2" data-qa-github-cred-modal-oauth-txt-1
						>Authenticated with GitHub account associated with
						<Typography inline type="p2" weight="bold" color="primary">{{
							oauthUserEmail
						}}</Typography>
					</Typography>
					<Typography v-else type="p2" data-qa-github-cred-modal-oauth-txt-2>
						Please click on the button below to authenticate with your GitHub account.</Typography
					>

					<Button
						v-if="!oauthUserEmail"
						size="normal"
						:type="isResusableState ? 'primary' : 'default'"
						:state="isResusableState ? 'outlined' : 'filled'"
						:class="isResusableState ? 'width-200' : 'width-150'"
						data-qa-github-cred-modal-oauth-btn
						@click="getGithubOauthCode()"
					>
						<Icon name="p-github" :effects="false" type="filled" size="small" />
						{{ isResusableState ? "Authenticate" : "GitHub" }}
					</Button>
				</Container>

				<!-- Auth button for PAT when the component is consumed in other -->
				<Container
					v-if="authType == 'pat' && isResusableState"
					overflow="visible"
					direction="column"
					padding="12px 4px"
					:gap="16"
					align-self="center"
				>
					<Button
						size="normal"
						:type="isResusableState ? 'primary' : 'default'"
						:state="isResusableState ? 'outlined' : 'filled'"
						data-qa-github-cred-modal-oauth-btn
						:loading="isSubmitting"
						loading-type="icon"
						@click="saveGithubCredentials()"
					>
						Authenticate
					</Button>
				</Container>
			</Container>

			<Footer v-if="!isResusableState">
				<Button
					state="full"
					:disabled="!formState?.isValid"
					:loading="isSubmitting"
					:type="isSubmitting ? 'default' : 'success'"
					data-qa-github-cred-model-save-btn
					@click="saveGithubCredentials"
					>{{ ctaBtnTxt }}</Button
				>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { AUTH_IDENTITY_PROVIDER } from "@/modules/auth/constants";
import { signInUsingIdentityProvider } from "@/modules/auth/services/auth-service";
import { credentialStore } from "@/modules/credentials/credential-store";
import { ClassificationType } from "@/modules/credentials/credential-types";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { Creds, CredsCreate, credsType, CredsUpdate, githubCreds } from "@/protocol/identity";
import {
	AppFirstFlowFormStorageService,
	GithubOauthStorageService
} from "@/services/storage-service";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import {
	captureError,
	getClassificationFormField,
	getClassificationFormValue,
	getErrorMessage
} from "@/utils";

export default defineComponent({
	name: "GithubCredentialModal",

	components: {
		ClosePopoverConfirmationWarning,
		ModalNotificationBanner,
		Typography,
		Container,
		Wrapper,
		Button,
		Footer,
		Header,
		Icon
	},

	props: {
		assignCred: {
			type: Boolean
		},

		showBackBtn: {
			type: Boolean
		},

		showCloseBtn: {
			type: Boolean,
			default: () => true
		},

		updateCredList: {
			type: Boolean
		},

		gitFormRef: {
			type: String,
			required: true
		},

		cred: {
			type: Object as PropType<Creds>
		},

		isResusableState: {
			type: Boolean,
			default: false
		},

		newOrgId: {
			type: String,
			default: null
		},

		allowedClassificationIds: {
			type: Array as PropType<string[]>
		}
	},

	data() {
		const addGitWithOauth: AddCredentialWithItem = {
			data: { id: "oauth" },
			title: "OAuth"
		};

		const githubCredFormValues: LocalGithubCredFormValues = {
			authMethod: addGitWithOauth
		};

		return {
			existingCredNames: [] as string[],
			addGitWithOauth,
			addGithubWithUserPass: {
				data: { id: "pat" },
				title: "Personal Access Token"
			} as AddCredentialWithItem,

			githubCredFormValues,
			initialformValues: githubCredFormValues,
			isSubmitting: false,
			submitError: null as string | null,
			authenticatedGithubToken: null as string | null,
			githubAuthenticationError: null as string | null,
			oauthUserEmail: null as string | null,

			formState: null as FormBuilderState | null
		};
	},

	computed: {
		formFields(): FormBuilderField {
			const showNameField = Boolean(
				!this.isResusableState &&
					(this.authType === "pat" || (this.authType === "oauth" && this.oauthUserEmail))
			);

			return {
				type: "object",
				direction: "vertical",
				fields: {
					authMethod: {
						type: "select",
						id: "authMethod",
						label: { title: "Select authentication method" },
						placeholder: "Select authentication method",
						options: [this.addGitWithOauth, this.addGithubWithUserPass],
						validationRules: [{ name: "required" }],
						disabled: !!this.cred
					},

					name: {
						type: "text",
						id: "name",
						label: { title: "Display name" },
						placeholder: "Enter account name",
						validationRules: applyEntityNameRules2(
							this.existingCredNames,
							"This display name already exists."
						),

						showWhen: () => showNameField
					},

					...getClassificationFormField(this.cred, this.allowedClassificationIds, showNameField),

					username: {
						type: "text",
						id: "username",
						label: { title: "Username" },
						validationRules: [{ name: "required" }],
						placeholder: "Enter username",
						showWhen: () => this.authType === "pat"
					},

					password: {
						type: "password",
						id: "password",
						label: { title: "Personal access token" },
						validationRules: [{ name: "required" }],
						placeholder: "Enter personal access token",
						showWhen: () => this.authType === "pat"
					}
				}
			};
		},

		orgId() {
			return this.$route.params.orgId as string;
		},

		headerTxt() {
			if (this.assignCred) {
				return "Assign credential";
			} else if (this.cred) {
				return "Edit Github credential";
			}
			return "Add Github credential";
		},

		authType() {
			return this.githubCredFormValues.authMethod?.data.id;
		},

		ctaBtnTxt() {
			if (this.assignCred) {
				return "Assign credential";
			}
			return "Save Github credential";
		},

		// Computed property to count all the credentials and generate a new number with number
		// of credentials + 1
		credentialCount() {
			return credentialStore.existingCredentialNames.length + 1;
		}
	},

	mounted() {
		this.githubOauthAuthentication();
		this.existingCredNames = credentialStore.existingCredentialNames.filter(_credName =>
			this.cred ? this.cred.name !== _credName : true
		);

		this.submitError = this.githubAuthenticationError ?? null;

		if (this.cred) {
			this.githubCredFormValues = {
				...this.githubCredFormValues,
				authMethod: this.getAuthTypeFromCred(),
				name: this.cred.name,
				...(this.cred.classificationId && getClassificationFormValue(this.cred.classificationId))
			};

			if (!this.cred.github?.OAuth) {
				this.githubCredFormValues = {
					...this.githubCredFormValues,
					name: this.cred.name,
					username: this.cred.github?.githubPatOption?.username
				};
			}

			this.initialformValues = this.githubCredFormValues;
		}
	},

	methods: {
		// eslint-disable-next-line max-statements
		async saveGithubCredentials() {
			if (this.isSubmitting || !this.formState?.isValid) {
				return null;
			}

			this.submitError = "";
			this.isSubmitting = true;

			try {
				let cred: Creds | null = null;
				const github: githubCreds = {
					OAuth: this.authType === "oauth"
				};

				if (this.authType === "oauth" && this.authenticatedGithubToken) {
					github.githubOAuthOption = {
						token: this.authenticatedGithubToken
					};
				}

				if (this.authType === "pat") {
					github.githubPatOption = {
						username: this.githubCredFormValues.username!,
						password: this.githubCredFormValues.password!
					};
				}

				if (this.cred) {
					const updateCredPayload: CredsUpdate = {
						name: this.githubCredFormValues.name!,
						id: this.cred.id,
						orgId: this.cred.orgId,
						type: credsType.credsType_github,
						github,
						// API doesn't update the value of displayId and isNotSensitive from the request payload, so we
						// can sent empty values.
						displayId: "",
						isNotSensitive: false
					};

					await credentialStore.updateCredential({ cred: updateCredPayload });

					// Get latest cred object and update the store.
					credentialStore.GET_UPDATE_CREDENTIAL({ orgId: this.orgId, id: this.cred.id });
				} else {
					const createCredpayload: CredsCreate = {
						// The suggested name is needed in the case of app first flow - cred creation
						name: this.githubCredFormValues.name ?? `github-cred-${this.credentialCount}`,
						orgId: this.newOrgId || this.orgId,
						type: credsType.credsType_github,
						github,
						...(this.githubCredFormValues.classification && {
							classificationId: this.githubCredFormValues.classification.data.id
						})
					};

					cred = await credentialStore.createCredential({ cred: createCredpayload });

					// Update the credential list
					if ("id" in cred) {
						credentialStore.UPDATE_CRED_AND_STATS_BY_ID(cred);
					}

					// Emit created cred id
					this.$emit("onCredCreate", cred);

					const existingFormValues = AppFirstFlowFormStorageService.getAppFirstFlowForm();
					AppFirstFlowFormStorageService.setAppFirstFlowForm({
						...existingFormValues,
						cred,
						gitFormRef: this.gitFormRef
					});
				}

				GithubOauthStorageService.removeGithubUserOauth();

				if (this.updateCredList) {
					if (this.cred) {
						notificationsStore.ADD_TOAST({
							qaId: "toast-cloud-cred-github-edited-successfully",
							title: "Git credential updated",
							text: `Credential ${this.githubCredFormValues.name} is successfully updated.`,
							status: "success"
						});
					} else if (!this.isResusableState) {
						notificationsStore.ADD_TOAST({
							qaId: "toast-cloud-cred-github-added-successfully",
							title: "New credential added",
							text: `New credential ${this.githubCredFormValues.name} is successfully added.`,
							status: "success"
						});
					}

					this.closeModal();
				}
			} catch (err) {
				this.submitError = getErrorMessage(err);
				captureError(err);
			}
			this.isSubmitting = false;
			return null;
		},

		saveFormValues(event: CustomEvent<LocalGithubCredFormValues>) {
			this.githubCredFormValues = event.detail;
		},

		closeModal() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.gitFormRef === this.gitFormRef) {
				GithubOauthStorageService.removeGithubUserOauth();
			}
			this.$emit("closeModal");
		},

		validateAndCloseModal(eventType: "back" | "closeModal" = "closeModal") {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				if (eventType === "back") {
					return this.$emit("back");
				}
				return this.closeModal();
			}
			return;
		},

		async getGithubOauthCode() {
			const provider = AUTH_IDENTITY_PROVIDER.GITHUB;
			try {
				this.isSubmitting = true;
				const response = await signInUsingIdentityProvider({
					sso: {
						idpName: provider.idpName,
						clientRedirect: window.VUE_APP_SITE_URL + this.$route.path
					}
				});

				if (response.isRedirect && response.redirectURL) {
					GithubOauthStorageService.setGithubUserOauth({
						isUserOauthActive: true,
						gitFormRef: this.gitFormRef
					});
					window.open(response.redirectURL, "_self");
				}
			} catch (error) {
				this.submitError = getErrorMessage(error);
				captureError(error);
			} finally {
				this.isSubmitting = false;
			}
		},

		openHelp() {
			window.open("https://docs.codepipes.io/docs/code-sources#add-a-github-source-code", "_blank");
		},

		getAuthTypeFromCred() {
			if (!this.cred) {
				return this.githubCredFormValues.authMethod ?? this.addGitWithOauth;
			}

			if (this.cred.github?.OAuth) {
				return this.addGitWithOauth;
			} else {
				return this.addGithubWithUserPass;
			}
		},

		async githubOauthAuthentication() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (!githubOauthResult.isUserOauthActive) {
				return null;
			}
			if (githubOauthResult.gitFormRef !== this.gitFormRef) {
				this.closeModal();
				return null;
			}
			try {
				if (githubOauthResult.idpCode) {
					const authenticatedGithubUser = await credentialStore.GET_USER_FROM_GIT_OAUTH_TOKEN({
						identityProviderToken: githubOauthResult.idpCode
					});

					this.authenticatedGithubToken = authenticatedGithubUser.token ?? null;
					this.oauthUserEmail = authenticatedGithubUser.email ?? null;

					// Emits authentication successfull
					this.$emit("authenticated", true);
				} else {
					this.githubAuthenticationError = githubOauthResult.idpError ?? "Something went wrong";
				}
			} catch (err) {
				this.$emit("authenticated", false);
				captureError(err);
				throw err;
			}
		}
	}
});

type LocalGithubCredFormValues = {
	authMethod?: AddCredentialWithItem;
	name?: string;
	classification?: ClassificationType;
	password?: string;
	username?: string;
};

type AddCredentialWithItem = FSelectOptionObject & {
	data: { id: "oauth" | "pat" };
};
</script>
