<template>
	<f-form-builder
		ref="formBuilder"
		data-qa-create-app-model-form
		:field.prop="formFields"
		:values.prop="formValues"
		width="100%"
		@input="updateFormValues"
		@state-change="formState = $event.detail"
	/>

	<f-tooltip id="containerRepo_info_tootip">
		<Container :padding="0" direction="column">
			<Typography type="p2" weight="medium" color="white">
				It is the location where the container image is stored.
			</Typography>
			<Container :padding="0" :gap="0" direction="column">
				<Typography type="p2-para" color="white">
					Following are the examples of valid repository path:
				</Typography>
				<ul class="bullets">
					<li>
						<Container :padding="0" :gap="6" direction="column">
							<Typography type="p3" family="logs" color="gray-400"
								>dkr.ecr.us-west-2.amazonaws.com/my-repo:latest</Typography
							>
							<Typography type="p3" family="logs" color="light">
								<Typography type="p3" family="logs" color="light" weight="bold" :inline="true"
									>Format:</Typography
								>
								registry/repository:tag
							</Typography>
						</Container>
					</li>
					<li>
						<Container :padding="0" :gap="6" direction="column">
							<Typography type="p3" family="logs" color="gray-400"
								>gcr.io/cldcvr/my-repo:latest</Typography
							>
							<Typography type="p3" family="logs" color="light">
								<Typography type="p3" family="logs" color="light" weight="bold" :inline="true"
									>Format:</Typography
								>
								host/orgname/repository:tag
							</Typography>
						</Container>
					</li>
					<li>
						<Container :padding="0" :gap="6" direction="column">
							<Typography type="p3" family="logs" color="gray-400"
								>gcr.io/cldcvr/my-repo</Typography
							>
							<Typography type="p3" family="logs" color="light">
								<Typography type="p3" family="logs" color="light" weight="bold" :inline="true"
									>Format:</Typography
								>
								host/orgname/repository
							</Typography>
						</Container>
					</li>
					<li>
						<Container :padding="0" :gap="6" direction="column">
							<Typography type="p3" family="logs" color="gray-400">cldcvr/my-repo</Typography>
							<Typography type="p3" family="logs" color="light">
								<Typography type="p3" family="logs" color="light" weight="bold" :inline="true"
									>Format:</Typography
								>
								orgname/repository
							</Typography>
						</Container>
					</li>
				</ul>
			</Container>
		</Container>
	</f-tooltip>
</template>

<script lang="ts">
import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Container, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { ContainerRegistryProvider } from "@/protocol/common";
import { parseUrl } from "@/utils";

import {
	VERSION_TAGS,
	applicationCreateUpdateFormFields,
	applicationHostnameField,
	containerProviderOptions
} from "./app-widget/applicationForm";

export default defineComponent({
	name: "ApplicationContainerForm",

	components: {
		Container,
		Typography
	},

	props: {
		formValues: {
			type: Object as PropType<ApplicationContainerFormValues>,
			required: true
		},

		showDisplayName: Boolean,
		suggestedContainerRepo: String,
		suggestedDisplayName: String,
		suggestedContainerProvider: String as PropType<ContainerRegistryProvider>,
		existingContainerRepoNames: Array as PropType<string[]>
	},

	emits: ["update:formValues"],

	data() {
		return {
			formState: null as FormBuilderState | null,
			appHostWasModified: this.formValues.appHost !== undefined
		};
	},

	computed: {
		formFields(): FormBuilderField {
			const formFields = applicationCreateUpdateFormFields({
				suggestedRepoValue: this.suggestedContainerRepo,
				containerRepoValue: this.formValues.containerRepo,
				existingContainerRepoNames: this.existingContainerRepoNames ?? [],
				showDisplayName: this.showDisplayName,
				suggestedDisplayName: this.suggestedDisplayName
			});

			if (this.isCustomContainerProvider) {
				formFields.fields.appHost = applicationHostnameField({
					suggestion: this.suggestedHostName ?? ""
				});
			}

			return formFields;
		},

		isCustomContainerProvider() {
			const containerProviderId =
				this.formValues.containerProvider?.data.id ?? this.suggestedContainerProvider;

			return (
				containerProviderId &&
				[
					ContainerRegistryProvider.custom_registry,
					ContainerRegistryProvider.google_container_registry,
					ContainerRegistryProvider.elastic_container_registry,
					ContainerRegistryProvider.azure_container_registry
				].includes(containerProviderId)
			);
		},

		suggestedHostName() {
			const { containerRepo } = this.formValues;
			if (!containerRepo) {
				return null;
			}

			const hostname = parseUrl(containerRepo)?.hostname;

			return hostname?.includes(".") ? hostname : null;
		}
	},

	watch: {
		"formState.isValid": {
			handler(isValid: boolean) {
				this.$emit("update:formValues", {
					...this.formValues,
					isValid
				});
			}
		}
	},

	mounted() {
		const { formValues } = this;

		this.$emit("update:formValues", {
			...formValues,

			// On first mount assume the form is valid, form builder will send the real value
			// after update
			isValid: true,

			containerProvider:
				formValues.containerProvider ??
				containerProviderOptions.find(
					provider => provider.data.id === this.suggestedContainerProvider
				),

			containerRepo:
				typeof formValues.containerRepo !== "string"
					? this.suggestedContainerRepo
					: formValues.containerRepo,

			appContainerImageName:
				typeof formValues.appContainerImageName !== "string"
					? this.suggestedDisplayName
					: formValues.appContainerImageName,

			containerTag:
				typeof formValues.containerTag !== "string" ? "latest" : formValues.containerTag,

			versionTagPattern: formValues.versionTagPattern ?? VERSION_TAGS[0],
			appHost: this.appHostWasModified ? formValues.appHost : this.suggestedHostName
		});
	},

	methods: {
		updateFormValues(event: CustomEvent<ApplicationContainerFormValues>) {
			const formValues = event.detail;

			if (
				formValues.appHost &&
				(!this.suggestedHostName || this.suggestedHostName.startsWith(formValues.appHost))
			) {
				this.appHostWasModified = true;
			}

			this.$emit("update:formValues", {
				...formValues,
				appHost: this.appHostWasModified ? this.formValues.appHost : this.suggestedHostName,
				isValid: Boolean(this.formState?.isValid)
			});
		},

		// used by the parent component
		// eslint-disable-next-line vue/no-unused-properties
		submit() {
			(this.$refs.formBuilder as FFormBuilder).submit();
		}
	}
});

export type ApplicationContainerFormValues = {
	isValid?: boolean;
	containerProvider?:
		| (FSelectOptionObject & {
				data: { id: ContainerRegistryProvider };
		  })
		| null;
	containerRepo?: string;
	containerTag?: string;
	appHost?: string;
	appContainerImageName?: string;
	versionTagPattern?: FSelectOptionObject & {
		data: { id: string };
	};
};
</script>
