<template>
	<Container
		class="dashboard-input"
		align="space-between top"
		overflow="visible"
		:shrink="0"
		padding="0px 0px 16px 0px"
		data-qa-creds-header-container
	>
		<Container :padding="0" :gap="4" overflow="visible">
			<Container direction="column" :padding="12" :gap="4">
				<Typography type="h4" weight="bold" color="dark" data-qa-creds-page-title-left>
					Credentials
				</Typography>
				<Typography
					v-if="!projectView"
					type="p2"
					weight="regular"
					color="light"
					data-qa-creds-page-org-name-left
				>
					{{ orgName }}
				</Typography>
			</Container>
			<Container v-if="!projectView" padding="0px 24px">
				<Icon name="i-separator" state="dark" :effects="false" />
			</Container>
			<FilterCredByScope
				:credential-group="credentialGroup"
				@on-cred-filter-change="onCredFilterChange"
			/>
		</Container>
		<Container :gap="12" :padding="0">
			<Wrapper width="240px" class="overflow-visible">
				<SearchInput
					v-model:value="searchStr"
					placeholder="Search credentials"
					data-qa-field="data-qa-cred-search-input"
				/>
			</Wrapper>
			<!-- plus button for project assign credential menu -->
			<AssignCredentialMenuOptions
				v-if="projectView && project && isUserOrgAdmin"
				:project="project"
			/>
			<!-- plus button to add new credential on global cred page -->
			<AddNewCred v-else git-form-ref="headerGitForm" />
		</Container>
	</Container>
</template>

<script lang="ts">
import { Container, SearchInput, Wrapper, Typography, Icon } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import AssignCredentialMenuOptions from "@/modules/credentials/components/credential-assign/AssignCredentialMenuOptions.vue";
import AddNewCred from "@/modules/credentials/components/credential-form/AddNewCred.vue";
import FilterCredByScope from "@/modules/credentials/components/credential-list/FilterCredByScope.vue";
import { orgStore } from "@/modules/org/org-store";
import { project } from "@/protocol/identity";

import { CredFilters } from "../../credential-types";
import { CredentialGroupGroupScope } from "../../store-types";

export default defineComponent({
	name: "CredentialHeader",

	components: {
		AssignCredentialMenuOptions,
		FilterCredByScope,
		SearchInput,
		Typography,
		AddNewCred,
		Container,
		Wrapper,
		Icon
	},

	props: {
		projectView: {
			type: Boolean,
			default: false
		},

		project: {
			type: Object as PropType<project>
		},

		credentialGroup: {
			type: Object as PropType<CredentialGroupGroupScope>,
			required: true
		},

		// eslint-disable-next-line vue/no-unused-properties
		searchText: {
			type: String,
			required: true
		},

		// eslint-disable-next-line vue/no-unused-properties
		filters: {
			type: Object as PropType<CredFilters>,
			required: true
		}
	},

	data() {
		return { searchStr: "" };
	},

	computed: {
		orgName() {
			return orgStore.activeOrg?.name ?? "";
		},

		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		}
	},

	watch: {
		searchStr() {
			this.$emit("update:searchText", this.searchStr);
		}
	},

	methods: {
		onCredFilterChange(filters: CredFilters) {
			this.$emit("update:filters", filters);
		}
	}
});
</script>
