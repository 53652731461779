import {
	Creds,
	CredsList,
	credsType,
	identityService,
	ListCredEntitiesResponse,
	success
} from "@/protocol/identity";
import { classifications, infraService } from "@/protocol/infra";
import { AuthCodeResponse } from "@/protocol/user";
import ApiService, { IDENTITY_API, INFRA_API } from "@/services/api-service";

// GET all credentials
export const listCreds: identityService["listCreds"] = async function ({ id }) {
	const response = await ApiService.get<CredsList>(`${IDENTITY_API}/organizations/${id}/creds`);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

// GET applied credentials
export const listCredEntities: identityService["listCredEntities"] = async function ({
	orgId,
	id
}) {
	const response = await ApiService.get<ListCredEntitiesResponse>(
		`${IDENTITY_API}/organizations/${orgId}/creds/${id}/applied`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

// GET classifications
export const listClassifications: infraService["listClassification"] = async function ({ orgId }) {
	const response = await ApiService.get<classifications>(
		`${INFRA_API}/organizations/${orgId}/classifications`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const createCreds: identityService["createCreds"] = async function (payload) {
	const { orgId } = payload;

	const response = await ApiService.post<Creds>(
		`${IDENTITY_API}/organizations/${orgId}/creds`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateCreds: identityService["updateCreds"] = async function (payload) {
	const { orgId, id } = payload;

	const response = await ApiService.put<Record<string, never>>(
		`${IDENTITY_API}/organizations/${orgId}/creds/${id}`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const createGithubCreds = function ({
	orgId,
	name,
	username,
	personalAccessToken
}: {
	orgId: string;
	name: string;
	username: string;
	personalAccessToken: string;
}) {
	return createCreds({
		name,
		orgId,
		type: credsType.credsType_github,
		github: {
			githubPatOption: {
				username,
				password: personalAccessToken
			},
			OAuth: false
		}
	});
};

export const editGithubCreds = function ({
	orgId,
	credId,
	name,
	username,
	personalAccessToken
}: {
	orgId: string;
	credId: string;
	name: string;
	username: string;
	personalAccessToken: string;
}) {
	return updateCreds({
		name,
		orgId,
		id: credId,
		type: credsType.credsType_github,
		displayId: "",
		isNotSensitive: false,
		github: {
			githubPatOption: {
				username,
				password: personalAccessToken
			},
			OAuth: false
		}
	});
};

export const createGithubOauthCreds = function ({
	orgId,
	name,
	token
}: {
	orgId: string;
	name: string;
	token: string;
}) {
	return createCreds({
		name,
		orgId,
		type: credsType.credsType_github,
		github: {
			githubOAuthOption: {
				token
			},
			OAuth: false
		}
	});
};

export const editGithubOauthCreds = function ({
	orgId,
	credId,
	name,
	token
}: {
	orgId: string;
	credId: string;
	name: string;
	token: string;
}) {
	return updateCreds({
		name,
		orgId,
		id: credId,
		type: credsType.credsType_github,
		displayId: "",
		isNotSensitive: false,
		github: {
			githubOAuthOption: {
				token
			},
			OAuth: false
		}
	});
};

export const getUserFromGithubOauthCode: identityService["callback"] = async function (payload) {
	const { code } = payload;
	const response = await ApiService.get<AuthCodeResponse>(`${IDENTITY_API}/callback?code=${code}`);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const assignCredsScope: identityService["assignCredsScope"] = async function (payload) {
	const { orgId } = payload;
	const response = await ApiService.post<Record<string, never>>(
		`${IDENTITY_API}/organizations/${orgId}/creds/assignscope`,
		payload,
		{}
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const unassignCredsScope: identityService["unassignCredsScope"] = async function (payload) {
	const { orgId } = payload;
	const response = await ApiService.post<Record<string, never>>(
		`${IDENTITY_API}/organizations/${orgId}/creds/unassignscope`,
		payload,
		{}
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const appliedCreds: identityService["appliedCreds"] = async function (payload) {
	const { orgId } = payload;
	const response = await ApiService.post<CredsList>(
		`${IDENTITY_API}/organizations/${orgId}/creds/applied`,
		payload,
		{}
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getCred: identityService["getCreds"] = async function ({
	orgId,
	id
}: {
	orgId: string;
	id: string;
}) {
	const response = await ApiService.get<Creds>(
		`${IDENTITY_API}/organizations/${orgId}/creds/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteCred: identityService["deleteCreds"] = async function ({
	orgId,
	id
}: {
	orgId: string;
	id: string;
}) {
	const response = await ApiService.delete<success>(
		`${IDENTITY_API}/organizations/${orgId}/creds/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
