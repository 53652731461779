export const ENVIRONMENTS = {
	DEVELOPMENT: "DEVELOPMENT",
	PRODUCTION: "PRODUCTION",
	STAGING: "STAGING"
};

// https://cldcvr.atlassian.net/wiki/spaces/CO/pages/18831867980/All+Codepipes+and+CMP+environments+and+URLs
export const CMP_ENV_URLS = {
	STAGING: "https://cmp-frontend.azurewebsites.net",
	PRODUCTION: "https://cmp.nxplatform.io",
	DEVELOPMENT: "http://localhost:8082/"
};

// https://cldcvr.atlassian.net/wiki/spaces/CO/pages/18831867980/All+Codepipes+and+CMP+environments+and+URLs
export function getEnvironment(host = window.location.host) {
	if (host === "dash.codepipes.io" || host === "dev.nxplatform.io") {
		return ENVIRONMENTS.PRODUCTION;
	} else if (
		host === "dash.staging.codepipes.io" ||
		host === "dash.int-prod.codepipes.io" ||
		host === "dash.az.codepipes.io"
	) {
		return ENVIRONMENTS.STAGING;
	}

	return ENVIRONMENTS.DEVELOPMENT;
}

export function getCmpUrl() {
	switch (getEnvironment()) {
		case ENVIRONMENTS.STAGING:
			return new URL(CMP_ENV_URLS.STAGING);

		case ENVIRONMENTS.DEVELOPMENT:
			return new URL(CMP_ENV_URLS.DEVELOPMENT);
	}

	return new URL(CMP_ENV_URLS.PRODUCTION);
}
