<template>
	<Wrapper width="150px">
		<Container :padding="0" :grow="1" align="center" :gap="0">
			<Icon
				class="trigger trigger-left"
				size="xx-small"
				name="i-circle"
				type="filled"
				:effects="false"
				color="gray-800"
			/>
			<Divider />

			<Icon
				class="trigger trigger-right"
				size="small"
				name="i-play"
				type="filled"
				:effects="false"
				color="gray-800"
			/>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import { Container, Divider, Icon, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "ArrowPointer",

	components: {
		Divider,
		Container,
		Icon,
		Wrapper
	}
});
</script>
