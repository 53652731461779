<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		data-qa-add-bundle-model
		overflow="visible"
	>
		<Container :padding="0" :gap="0" direction="column" :grow="1" overflow="visible">
			<Header>
				<Container align="left center" :padding="0" :grow="1">
					<Icon
						v-if="showBackBtn"
						name="i-arrow-left"
						type="filled"
						size="small"
						data-qa-add-bundle-popover-close
						@click.stop="triggerBackEvent"
					/>
					<Typography data-qa-add-bundle-model-head-title type="h4" color="dark">
						Add new bundle
					</Typography>
					<Container align="right center" :grow="1" :padding="0">
						<Icon
							name="i-close"
							type="filled"
							size="x-small"
							data-qa-add-bundle-model-close
							@click.stop="toggleCreateBundle"
						/>
					</Container>
				</Container>
			</Header>

			<Container padding="10px 16px 16px 16px" direction="column" overflow="visible">
				<f-form-builder
					ref="bundleForm"
					data-qa-add-bundle-model-form
					:field.prop="formFields"
					:values.prop="formValues"
					@input="handleInput"
					@state-change="formState = $event.detail"
				/>
			</Container>

			<Footer>
				<Container direction="column" :grow="1" :gap="0" :padding="0">
					<Container v-if="submitError" padding="0 12px 12px 12px">
						<Typography data-qa-submit-error family="logs" type="p2" color="error">{{
							submitError
						}}</Typography>
					</Container>
					<Container :padding="0">
						<Button
							state="full"
							:type="isSubmitting ? 'default' : 'success'"
							:disabled="isSubmitting"
							:loading="isSubmitting"
							data-qa-add-bundle-btn
							@click="addBundle"
							>Add Bundle
						</Button>
					</Container>
				</Container>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { credentialStore } from "@/modules/credentials/credential-store";
import { getGitServerByRepo } from "@/modules/credentials/credential-types";
import { orgStore } from "@/modules/org/org-store";
import { GitRevisionType } from "@/protocol/common";
import { CredScope, project } from "@/protocol/identity";
import { GIT_REVISION_OPTIONS } from "@/shared/constants";
import { entityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import { captureError, getCredentialIcon2, getErrorMessage, parseGitUrl } from "@/utils";

import { bundleStore } from "../bundle-store";

const OPEN_SOURCE_GIT_OPTION: GitCredOption = {
	data: { id: "" },
	icon: "i-code",
	title: "Open Source",
	qaId: "Open Source"
};

export default defineComponent({
	name: "BundleAddDialog",

	components: {
		Typography,
		Container,
		Wrapper,
		Button,
		Header,
		Footer,
		Icon
	},

	props: {
		showBackBtn: {
			type: Boolean,
			default: () => null
		},

		toggleCreateBundle: {
			type: Function as PropType<() => void>,
			required: true
		},

		project: {
			type: Object as PropType<project>,
			required: true
		}
	},

	data: () => ({
		formValues: {
			directoryPath: "/",
			revisionType: {
				data: { id: GitRevisionType.branch },
				title: "Branch"
			}
		} as LocalFormValues,

		formState: null as FormBuilderState | null,

		isSubmitting: false,
		submitError: ""
	}),

	computed: {
		canAccessCreds() {
			return orgStore.isUserOrgAdmin;
		},

		gitSources(): GitCredOption[] {
			return Object.values(credentialStore.genericCredentials)
				.filter(credential => {
					return credential.credScope?.includes(CredScope.git);
				})
				.map(credential => ({
					data: { id: credential.id },
					title: credential.name,
					qaId: credential.name,
					icon: getCredentialIcon2(credential)
				}));
		},

		formFields(): FormBuilderField {
			const formFields: FormBuilderField = {
				type: "object",
				direction: "vertical",
				fields: {}
			};

			if (this.canAccessCreds) {
				formFields.fields.gitCredential = {
					type: "select",
					label: { title: "Select git credential" },
					id: "gitCredential",
					placeholder: "Select option",
					options: [OPEN_SOURCE_GIT_OPTION, ...this.gitSources],
					validationRules: [{ name: "required" }]
				};
			}

			formFields.fields = {
				...formFields.fields,
				repositoryPath: {
					type: "text",
					id: "repositoryPath",
					label: { title: "Repository path" },
					helperText: "URL to the repo of the bundle",
					prefix: "https://",
					validationRules: [{ name: "required" }, entityNameRules2.entityValidURLRule]
				},

				directoryPath: {
					type: "text",
					id: "directoryPath",
					label: { title: "Directory path" },
					placeholder: "/",
					helperText: "Path to the directory in the repo",
					validationRules: [{ name: "required" }]
				},

				__separator__: {
					type: "separator"
				},

				revisionType: {
					type: "select",

					id: "revisionType",
					placeholder: "Select option",
					label: { title: "Revision type" },
					validationRules: [{ name: "required" }],
					options: GIT_REVISION_OPTIONS
				},

				identifier: {
					type: "text",
					id: "identifier",
					label: { title: "Revision identifier" },
					placeholder: this.revisionIdentifierPlaceholder,
					validationRules: [{ name: "required" }]
				}
			};

			return formFields;
		},

		revisionIdentifierPlaceholder() {
			switch (this.formValues.revisionType?.data.id) {
				case GitRevisionType.branch:
					return "Enter branch name";
				case GitRevisionType.tag:
					return "Enter tag name";
				case GitRevisionType.commit:
					return "Enter commit hash";
				default:
					return "main";
			}
		}
	},

	mounted() {
		(async () => {
			if (await orgStore.IS_USER_ORG_ADMIN({ orgId: this.project.orgId })) {
				credentialStore.GET_ORG_CREDENTIALS({
					orgId: this.project.orgId
				});
			}
		})();
	},

	methods: {
		handleInput(input: CustomEvent<LocalFormValues>) {
			this.formValues = { ...input.detail };

			if (this.formValues.repositoryPath) {
				const parsedRepo = parseGitUrl(this.formValues.repositoryPath);

				if (parsedRepo?.repo) {
					this.formValues.repositoryPath = parsedRepo.repo;
					// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
					this.formValues.directoryPath = parsedRepo.path || this.formValues.directoryPath;
				}

				if (parsedRepo?.identifier) {
					this.formValues.identifier = parsedRepo.identifier;
				}
			}
		},

		triggerBackEvent() {
			this.$emit("goBack");
		},

		async addBundle() {
			(this.$refs.bundleForm as FFormBuilder).submit();

			if (
				!this.formState?.isValid ||
				!this.formValues.revisionType ||
				!this.formValues.repositoryPath ||
				!this.formValues.identifier
			) {
				return;
			}

			this.submitError = "";
			this.isSubmitting = true;

			try {
				// Apply the bundle without deploying
				await bundleStore.APPLY_BUNDLE({
					shouldDeploy: false,
					shouldUpdateConfig: true,
					bundleRequest: {
						artifact: {
							gitCode: {
								type: this.formValues.revisionType.data.id,
								driver: getGitServerByRepo(this.formValues.repositoryPath),
								dir: this.formValues.directoryPath ?? "/",
								repo: `https://${this.formValues.repositoryPath}`,
								identifier: this.formValues.identifier
							}
						},
						artifactCredId: this.formValues.gitCredential?.data.id,
						baseOrgId: this.project.orgId,
						baseProjId: this.project.id
					},
					bundleAppliedFor: "project"
				});

				this.toggleCreateBundle();
			} catch (err) {
				captureError(err);
				this.submitError = getErrorMessage(err, true);
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});

type LocalFormValues = {
	gitCredential?: GitCredOption;
	repositoryPath?: string;
	directoryPath?: string;
	revisionType?: RevisionOption | null;
	identifier?: string;
};

type GitCredOption = FSelectOptionObject & { data: { id: string } };
type RevisionOption = FSelectOptionObject & { data: { id: GitRevisionType } };
</script>
