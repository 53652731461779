<template>
	<Container direction="column" align="stretch top" :padding="0" :gap="0" overflow="auto">
		<SidebarItem v-for="project in projects" :key="project.id">
			<Slab
				:selected="currentProject ? currentProject.id === project.id : false"
				:effects="true"
				size="small"
				type="bottom-border"
				:data-qa-sidebar-project-row="project.name"
				@click.stop="setCurrentProject(project)"
			>
				<Pictogram
					v-if="getEmoji(project)"
					v-tooltip="{
						content: project.name,
						container: 'div.flow-layout',
						placement: 'right-start'
					}"
					:data-qa-sidebar-project-emoji="project.name"
					state="element"
					:effects="true"
				>
					{{ getEmoji(project) }}
				</Pictogram>

				<ProjectAvatar
					v-else
					:project-name="project.name"
					:data-qa-sidebar-project-avatar-initials="project.name"
				/>

				<Typography
					type="p1"
					color="dark"
					overflow="nowrap"
					:data-qa-sidebar-project-name="project.name"
					>{{ project.name }}</Typography
				>
			</Slab>
		</SidebarItem>
	</Container>
</template>
<script lang="ts">
import { Container, Pictogram, SidebarItem, Slab, Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { orgStore } from "@/modules/org/org-store";
import { projectStore } from "@/modules/project-list/project-store";
import { project as projectProto } from "@/protocol/identity";
import ProjectAvatar from "@/shared/components/ProjectAvatar.vue";
import { filterByNameString, getEmoji } from "@/utils";

export default defineComponent({
	name: "SidebarTopSection",
	components: { ProjectAvatar, Slab, SidebarItem, Pictogram, Typography, Container },

	props: {
		searchString: {
			type: String,
			default: ""
		}
	},

	computed: {
		currentProject() {
			return projectStore.currentProject;
		},

		orgId() {
			return orgStore.activeOrgId;
		},

		projects() {
			return Object.values(projectStore.projects)
				.filter(project => filterByNameString(project, this.searchString))
				.sort((projectA, projectB) => {
					return Number(projectB.updatedAt) - Number(projectA.updatedAt);
				});
		}
	},

	methods: {
		getEmoji,

		setCurrentProject(project: projectProto) {
			if (!this.orgId) {
				return;
			}

			if (this.currentProject?.id === project.id) {
				this.$emit("close-sidebar");
				return false;
			}

			this.$router
				.replace({
					name: "projectListWithProject",
					params: { orgId: this.orgId, projectId: project.id }
				})
				.catch(() => {
					// it's fine if we navigate to same route again
				});

			this.$emit("close-sidebar");
		}
	}
});
</script>
