<template>
	<Container
		:direction="showSearch ? 'row' : 'column'"
		:shrink="0"
		align="center top"
		:padding="showSearch ? '12px 12px 12px 0px' : '0px'"
		:grow="1"
		:gap="8"
	>
		<SearchInput
			v-if="showSearch"
			class="flex-1"
			:value="searchString"
			placeholder="Search project..."
			data-qa-field="data-qa-search-input-sidebar"
			@update:value="onSearchUpdate($event)"
		/>
	</Container>
</template>

<script lang="ts">
import { SearchInput, Container } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "SidebarHeader",
	components: { SearchInput, Container },

	props: {
		showSearch: {
			type: Boolean,
			required: true
		},

		searchString: {
			type: String
		}
	},

	methods: {
		onSearchUpdate(searchString: string) {
			this.$emit("onSearchUpdate", searchString);
		}
	}
});
</script>
