import { ProjectId } from "@/protocol/common";
import { identityService, project, projects, success } from "@/protocol/identity";
import ApiService, { IDENTITY_API } from "@/services/api-service";

export const getProjectById: identityService["getProject"] = async function ({ orgId, id }) {
	const response = await ApiService.get<project>(
		`${IDENTITY_API}/organizations/${orgId}/projects/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listProjects: identityService["listProject"] = async function ({ orgId, detailed }) {
	const response = await ApiService.get<projects>(
		`${IDENTITY_API}/organizations/${orgId}/projects?detailed=${detailed}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateProjectVariables: identityService["updateVariables"] = async function (payload) {
	const { orgId, id } = payload;

	const response = await ApiService.patch<success>(
		`${IDENTITY_API}/organizations/${orgId}/projects/${id}/vars`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteProjectVariables: identityService["deleteVariables"] = async function (payload) {
	const { orgId, id } = payload;

	const response = await ApiService.patch<success>(
		`${IDENTITY_API}/organizations/${orgId}/projects/${id}/vars/delete`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteProjectEntities: identityService["deleteProjectEntities"] = async function (
	payload
) {
	const { orgId, id } = payload;

	const response = await ApiService.delete<success>(
		`${IDENTITY_API}/organizations/${orgId}/projects/${id}/delete-all`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteProject: identityService["deleteProject"] = async function (payload) {
	const { orgId, id } = payload;

	const response = await ApiService.delete<success>(
		`${IDENTITY_API}/organizations/${orgId}/projects/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const createProject: identityService["createProject"] = async function (payload) {
	const { orgId } = payload;

	const response = await ApiService.post<ProjectId>(
		`${IDENTITY_API}/organizations/${orgId}/projects`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const editProject: identityService["updateProject"] = async function (payload) {
	const { orgId, id } = payload;

	const response = await ApiService.put<Record<string, never>>(
		`${IDENTITY_API}/organizations/${orgId}/projects/${id}`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
