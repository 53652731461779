import { JSONSchema } from "@/protocol/common";

import { captureError } from ".";

export function JSONSchemaObjectToObject(schema: JSONSchema, value?: unknown) {
	if (!isJSONObjectSchema(schema)) {
		captureError(new Error(`JSONSchemaObjectToObject: Invalid schema type ${schema.type}}`));
		return {};
	}

	const object: Record<string, unknown> = {};

	for (const [key, propVal] of Object.entries(schema.properties)) {
		const defaultValue = (schema.default as Record<string, unknown> | undefined)?.[key];
		const existingValueForKey = isObject(value) ? value[key] : defaultValue;
		const isPropRequired = schema.required?.includes(key) ?? false;

		const jsonOutput = JSONSchemaToObject(propVal, existingValueForKey, isPropRequired);
		object[key] = jsonOutput;
	}

	return object;
}

export function JSONSchemaArrayToObject(schema: JSONSchema, value?: unknown, isRequired?: boolean) {
	if (!isJSONArraySchema(schema)) {
		captureError(new Error(`JSONSchemaArrayToObject: Invalid schema type ${schema.type}}`));
		return [];
	}

	// If user has provided values for the array, then fill them in
	if (isArray(value) && value.length > 0) {
		return value.map(value_ => JSONSchemaToObject(schema.items, value_));
	} else {
		return isRequired ? [JSONSchemaToObject(schema.items)] : [];
	}
}

export function JSONSchemaToObject(
	schema?: JSONSchema,
	existingValue?: unknown,
	isRequired?: boolean
): unknown {
	if (!schema) {
		throw new Error("Schema is undefined");
	}

	if (isJSONObjectSchema(schema)) {
		return JSONSchemaObjectToObject(schema, existingValue);
	} else if (isJSONArraySchema(schema)) {
		return JSONSchemaArrayToObject(schema, existingValue, isRequired);
	} else if (
		schema.type === "string" ||
		schema.type === "number" ||
		schema.type === "integer" ||
		schema.type === "boolean"
	) {
		return existingValue ?? schema.default ?? undefined;
	}

	throw new Error(`Unknown schema type: ${schema.type}!`);
}

export function splitJSONObjectSchema(schema: JSONSchemaWithObject) {
	let requiredFields = false;
	let optionalFields = false;

	const requiredSchema: JSONSchemaWithObject = { ...schema, properties: {} };
	const optionalSchema: JSONSchemaWithObject = { ...schema, properties: {} };

	Object.entries(schema.properties).forEach(([name, property]) => {
		if (schema.required?.includes(name)) {
			requiredFields = true;
			requiredSchema.properties[name] = property;
		} else {
			optionalFields = true;
			optionalSchema.properties[name] = property;
		}
	});

	return {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		requiredSchema: requiredFields ? requiredSchema : null,
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		optionalSchema: optionalFields ? optionalSchema : null
	};
}

export type JSONSchemaWithObject = JSONSchema & {
	type: "object";
	properties: Record<string, JSONSchema>;
};

export type JSONSchemaWitArray = JSONSchema & { type: "array"; items: JSONSchema };

function isObject(item: unknown): item is Record<string, unknown> {
	return Boolean(item) && typeof item === "object" && !Array.isArray(item);
}

function isArray(item: unknown): item is unknown[] {
	return Boolean(item) && Array.isArray(item);
}

export function isJSONObjectSchema(schema: JSONSchema): schema is JSONSchemaWithObject {
	return schema.type === "object" && "properties" in schema && Boolean(schema.properties);
}

export function isJSONArraySchema(schema: JSONSchema): schema is JSONSchemaWitArray {
	return schema.type === "array" && "items" in schema && Boolean(schema.items);
}
