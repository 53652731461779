<template>
	<Card background="element-light" data-qa-int-pipeline-widget>
		<template #header>
			<Container overflow="visible" data-qa-int-pipeline-widget-header>
				<Slab :no-padding="true" overflow="visible" type="transparent">
					<Container :padding="0">
						<Container direction="column" :gap="0" :padding="0">
							<Typography type="h4" color="dark" overflow="ellipsis" data-qa-int-pipeline-name>{{
								integration.name
							}}</Typography>
						</Container>
					</Container>
					<template #secondary-actions>
						<PopOver
							:open="isSettingsOpen || isDeleteOpen"
							placement="left-start"
							@overlay-click="
								isSettingsOpen = false;
								isDeleteOpen = false;
							"
						>
							<Icon
								name="i-more"
								size="small"
								type="filled"
								data-qa-int-pipeline-more-actions
								@click="isSettingsOpen = true"
							/>
							<template #content>
								<Wrapper
									v-if="isSettingsOpen"
									border-radius="4px"
									background="element-light"
									:border="true"
									width="150px"
									data-qa-int-pipeline-more-actions-detail
								>
									<Container direction="column" :padding="0" :gap="0">
										<Slab
											size="large"
											type="transparent"
											:effects="true"
											class="cursor-pointer"
											data-qa-field="edit-integration"
											@click="editIntegration"
										>
											<template #primary-action>
												<Icon name="i-edit" color="gray-200" size="small" :effects="false" />
											</template>
											<Typography type="h5" weight="regular">Edit</Typography>
										</Slab>
										<Slab
											size="large"
											type="transparent"
											:effects="true"
											class="cursor-pointer"
											data-qa-field="delete-integration"
											@click="
												isDeleteOpen = true;
												isSettingsOpen = false;
											"
										>
											<template #primary-action>
												<Icon name="i-delete" color="danger-200" size="small" :effects="false" />
											</template>
											<Typography type="h5" weight="regular" color="danger-200">Delete</Typography>
										</Slab>
									</Container>
								</Wrapper>
								<ConfirmDeleteIntegration
									v-if="isDeleteOpen"
									:integration="integration"
									@close="isDeleteOpen = false"
								/>
							</template>
						</PopOver>
					</template>
				</Slab>
			</Container>
		</template>

		<Container
			padding="10px 16px 12px 10px"
			:gap="16"
			align="left"
			direction="column"
			overflow="visible"
			data-qa-del-org-categories
		>
			<Table border="none" :hover="false">
				<template #body>
					<TableRow :hover="false">
						<TableCell padding="8px 0 8px 8px" width="160px">
							<Typography type="p2" color="gray-200">Pipeline Cloud</Typography>
						</TableCell>
						<TableCell padding="8px 0 8px 8px">
							<Container v-if="integrationProvisioner" padding="0" data-qa-int-pipeline-cred-detail>
								<Icon :name="getProvisionerIcon(integrationProvisioner)" size="x-small" />

								<Typography type="p2" color="gray-100">{{
									getProvisionerName(integrationProvisioner)
								}}</Typography>
							</Container>
						</TableCell>
					</TableRow>

					<TableRow
						v-if="
							credProvisioner &&
							integrationProvisioner &&
							integrationProvisioner !== credProvisioner
						"
					>
						<TableCell padding="8px 0 8px 8px" :colspan="2">
							<f-text size="small" state="danger">
								<f-icon source="i-alert" size="small" state="danger" /> This pipeline does not match
								the cloud credential assigned to the application. Please update the assigned
								credentials.</f-text
							>
						</TableCell>
					</TableRow>

					<TableRow
						v-if="integration.finalInputArtifact?.type === ArtifactType.GitCode"
						:hover="false"
					>
						<TableCell padding="8px 0 8px 8px">
							<Container padding="0" :gap="0">
								<Typography type="p2" color="gray-200">Webhooks</Typography>
							</Container>
						</TableCell>
						<TableCell padding="8px 0 8px 8px">
							<GitTriggerForm
								:artifact="integration.finalInputArtifact"
								:integration="integration"
							/>
						</TableCell>
					</TableRow>
					<TableRow :hover="false">
						<TableCell padding="8px 0 8px 8px">
							<Typography type="p2" color="gray-200">Pipeline input</Typography>
						</TableCell>
						<TableCell padding="8px 0 8px 8px">
							<Container data-qa-int-pipeline-input-artifact-detail padding="0">
								<Icon
									v-if="getIconName(integration.finalInputArtifact)"
									:name="getIconName(integration.finalInputArtifact)?.icon ?? ''"
									size="x-small"
								/>
								<Typography type="p2" color="gray-100">{{
									getIconName(integration.finalInputArtifact)?.name
								}}</Typography>
							</Container>
						</TableCell>
					</TableRow>
					<TableRow :hover="false">
						<TableCell padding="8px 0 8px 8px">
							<Typography type="p2" color="gray-200">Integration modules</Typography>
						</TableCell>
						<TableCell padding="8px 0 8px 8px">
							<Container
								align="left center"
								:grow="1"
								:gap="4"
								:flex-wrap="true"
								padding="0"
								data-qa-int-pipeline-module-details
							>
								<Tag
									v-for="tag in getModuleMeta(integration.pipeline)"
									:key="tag.displayName"
									size="medium"
									:data-qa-field="tag.displayName"
									>{{ tag.displayName }}</Tag
								>
							</Container>
						</TableCell>
					</TableRow>
					<TableRow v-if="integration.finalOutputArtifact" :hover="false">
						<TableCell padding="8px 0 8px 8px">
							<Typography type="p2" color="gray-200">Pipeline output</Typography>
						</TableCell>
						<TableCell padding="8px 0 8px 8px">
							<Container data-qa-int-pipeline-output-artifact-details padding="0">
								<Icon
									v-if="getIconName(integration.finalOutputArtifact)"
									:name="getIconName(integration.finalOutputArtifact)?.icon ?? ''"
									size="x-small"
								/>
								<Typography type="p2" color="gray-100">{{
									getIconName(integration.finalOutputArtifact)?.name
								}}</Typography>
							</Container>
						</TableCell>
					</TableRow>
				</template>
			</Table>
		</Container>

		<template #footer>
			<IntegrationsPipelineCardFooter
				:latest-job="latestJob"
				:is-submitting="isSubmitting"
				:app="app"
				:integration="integration"
				:submit-error="submitError"
				@edit-integration="editIntegration"
				@confirmed="triggerPipeline"
				@cancel="cancelPipeline"
			/>
		</template>
	</Card>
</template>
<script lang="ts">
import {
	Card,
	Container,
	Icon,
	PopOver,
	Slab,
	Table,
	TableCell,
	TableRow,
	Tag,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import {
	applicationIntegrationStore,
	getPipelineModuleMeta,
	getSortedIntegrationJobList
} from "@/modules/application-integration/application-integration-store";
import ConfirmDeleteIntegration from "@/modules/application-integration/components/ConfirmDeleteIntegration.vue";
import { IntegrationPipelineConfirmConfig } from "@/modules/application-integration/components/ConfirmSaveAndRun.vue";
import GitTriggerForm from "@/modules/application-integration/components/GitTriggerForm.vue";
import { credentialStore, getCredByScope } from "@/modules/credentials/credential-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { appIntegration, app as appProto, integrationJobAbstract } from "@/protocol/app";
import { Artifact, ArtifactType } from "@/protocol/common";
import { CredScope } from "@/protocol/identity";
import { pipelineModuleReferenceV2 } from "@/protocol/pipeline";
import { CredsTypeToProvisioner } from "@/shared/constants";
import { captureError, getErrorMessage, getProvisionerIcon, getProvisionerName } from "@/utils";

import IntegrationsPipelineCardFooter from "./IntegrationsPipelineCardFooter.vue";

export default defineComponent({
	name: "IntegrationsPipelineCard",

	components: {
		IntegrationsPipelineCardFooter,
		ConfirmDeleteIntegration,
		GitTriggerForm,
		Typography,
		Container,
		TableCell,
		TableRow,
		Wrapper,
		PopOver,
		Table,
		Slab,
		Icon,
		Card,
		Tag
	},

	props: {
		app: {
			type: Object as PropType<appProto>,
			required: true
		},

		integration: {
			type: Object as PropType<appIntegration>,
			required: true
		}
	},

	data: () => ({
		ArtifactType,
		isSubmitting: false,
		submitError: "",
		isSettingsOpen: false,
		isDeleteOpen: false
	}),

	computed: {
		cred() {
			const [assignedCloudCred] = getCredByScope({ entityId: this.app.id, scope: CredScope.cloud });
			return assignedCloudCred;
		},

		credProvisioner() {
			return this.cred ? CredsTypeToProvisioner[this.cred.type!] : null;
		},

		integrationProvisioner() {
			return this.integration.config?.buildPlatform;
		},

		latestJob(): integrationJobAbstract | undefined {
			const [firstJob] = getSortedIntegrationJobList(this.integration.id);

			if (!firstJob) {
				return undefined;
			}

			return applicationIntegrationStore.integrationJob[firstJob.id];
		}
	},

	mounted() {
		credentialStore.GET_CREDS_FOR_ENTITY({
			orgId: this.app.orgId,
			app: { id: this.app.id, orgId: this.app.orgId, projId: this.app.projId }
		});
	},

	methods: {
		getProvisionerIcon,
		getProvisionerName,

		getModuleMeta(moduleRefs: pipelineModuleReferenceV2[] | undefined) {
			return getPipelineModuleMeta(moduleRefs);
		},

		getIconName(artifact: Artifact | undefined) {
			if (!artifact) {
				return null;
			}

			if (artifact.type === ArtifactType.ContainerImage) {
				return {
					icon: "i-box",
					name: artifact.name
				};
			} else if (artifact.type === ArtifactType.GitCode) {
				return {
					icon: "p-github",
					name: artifact.name
				};
			}
		},

		async cancelPipeline() {
			try {
				if (!this.latestJob) {
					return;
				}

				this.isSubmitting = true;

				await applicationIntegrationStore.CANCEL_INTEGRATION_JOB({
					appId: this.integration.appId,
					id: this.latestJob.id,
					integrationId: this.integration.id,
					orgId: this.integration.orgId,
					projId: this.integration.projId
				});

				notificationsStore.ADD_TOAST({
					qaId: "toast-integration-deploy-success",
					title: "Integration pipeline is cancelling",
					text: `${this.integration.name} is cancelling`,
					status: "success"
				});
			} catch (error) {
				notificationsStore.ADD_TOAST({
					qaId: "toast-integration-cancel-failure",
					title: "Error",
					text: getErrorMessage(error),
					status: "error"
				});

				captureError(error);
			} finally {
				this.isSubmitting = false;
			}
		},

		async triggerPipeline(artifactConfig: IntegrationPipelineConfirmConfig) {
			try {
				this.isSubmitting = true;

				await applicationIntegrationStore.START_INTEGRATION_JOB({
					integrationId: this.integration.id,
					orgId: this.integration.orgId,
					projId: this.integration.projId,
					appId: this.integration.appId,
					inputAppArtifact: artifactConfig.inputArtifact ?? undefined,
					outputAppArtifact: artifactConfig.outputArtifact ?? undefined
				});

				notificationsStore.ADD_TOAST({
					qaId: "toast-integration-deploy-success",
					title: "Integration pipeline triggered successfully",
					text: `${this.integration.name} is triggered`,
					status: "success"
				});

				// Navigate to pipeline view screen.
				this.$router.push({
					name: "stageViewIntegration",
					params: {
						orgId: this.app.orgId,
						projId: this.app.projId,
						appId: this.app.id,
						integrationId: this.integration.id
					}
				});
			} catch (error) {
				captureError(error);
				this.submitError = getErrorMessage(error);
			} finally {
				this.isSubmitting = false;
			}
		},

		editIntegration() {
			this.$router.push({
				name: "addIntegrationView",
				params: {
					orgId: this.app.orgId,
					projId: this.app.projId,
					appId: this.app.id,
					integrationId: this.integration.id
				}
			});
		}
	}
});
</script>
