<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		class="overflow-visible"
		data-qa-webhook-modal
	>
		<Header>
			<Container align="left center" :padding="0" :grow="1">
				<Icon
					v-tooltip="'Back'"
					name="i-arrow-left"
					type="filled"
					size="small"
					data-qa-back
					@click.stop="$emit('back')"
				/>
				<Typography type="h4" color="dark">Add webhook</Typography>
				<Container align="right center" :grow="1" :padding="0">
					<Icon
						v-tooltip="'Close'"
						size="x-small"
						type="filled"
						name="i-close"
						data-qa-close
						@click="$emit('close')"
					/>
				</Container>
			</Container>
		</Header>

		<ModalNotificationBanner banner-type="primary" :banner-body="bannerText" />

		<Container
			overflow="visible"
			:shrink="1"
			align="left top"
			:padding="16"
			:gap="16"
			direction="column"
		>
			<Container align="stretch top" direction="column" :grow="1" :padding="0" overflow="visible">
				<f-form-builder
					ref="formBuilder"
					:field.prop="formFields"
					:values.prop="formValues"
					@input="handleInput"
					@state-change="formState = $event.detail"
				>
					<f-tooltip id="appDepIntegration_tooltip" data-qa-info-for="appDepIntegration">
						Please select a pipeline upon successful completion of which this app deployment will
						start.
					</f-tooltip>
				</f-form-builder>
			</Container>
		</Container>

		<Footer>
			<Container direction="column" padding="0" :grow="1">
				<Container v-if="errorMessage" padding="0 16px 8px 16px">
					<Icon name="i-alert" size="x-small" color="danger-200" />
					<Typography type="p2" color="error" data-qa-webhook-error>{{ errorMessage }}</Typography>
				</Container>
				<Button
					state="full"
					type="success"
					:loading="isSaving"
					data-qa-add-webhook
					@click="addWebHook()"
					>Add webhook</Button
				>
			</Container>
		</Footer>
	</Wrapper>
</template>

<script lang="ts">
import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import { appIntegration } from "@/protocol/app";
import { AppIntegrationId } from "@/protocol/common";
import { AppDeployment, DeploymentJobAction } from "@/protocol/deployment";
import { triggerType, webhookRequest } from "@/protocol/externalEvents";
import { environment } from "@/protocol/infra";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import { CUSTOM_WEBHOOK_OPTION, INTEGRATION_OPTION, WebhookOptionType } from "@/shared/constants";
import { captureError, getErrorMessage } from "@/utils";

export default defineComponent({
	name: "AppDepWebHookModal",

	components: {
		ModalNotificationBanner,
		Button,
		Container,
		Footer,
		Header,
		Icon,
		Typography,
		Wrapper
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		},

		appDepEntity: {
			type: Object as PropType<AppDeployment>,
			required: true
		},

		integrationPipelines: {
			type: Object as PropType<appIntegration[]>,
			required: true
		}
	},

	emits: ["close", "back"],

	data() {
		return {
			isSaving: false,
			errorMessage: null as null | string,
			formValues: {
				webhookOption: INTEGRATION_OPTION
			} as FormValues,

			formState: null as FormBuilderState | null
		};
	},

	computed: {
		formFields(): FormBuilderField {
			const { webhookOption } = this.formValues;
			return {
				type: "object",
				direction: "vertical",
				fields: {
					// @todo - backend doesn't support deployent custom webhook yet
					// we keep this option open for when they enable it
					// webhookOption: {
					// 	type: "select",
					// 	label: {
					// 		title: "Webhook type",
					// 		iconTooltip: "The type of webhook you want to create."
					// 	},

					// 	qaId: "webhookOption",
					// 	placeholder: "Select webhook type",
					// 	options: [INTEGRATION_OPTION, CUSTOM_WEBHOOK_OPTION],
					// 	optionTemplate: webhookOptionTemplate,
					// 	disabled: Boolean(),
					// 	validationRules: [{ name: "required" }]
					// },

					appDepIntegration: {
						type: "select",
						qaId: "appDepIntegration",
						id: "",
						label: {
							title: "Integration pipeline",
							iconTooltip: "#appDepIntegration_tooltip"
						},

						placeholder: "Select integration pipeline",
						validationRules: [{ name: "required" }],
						options: this.integrationPipelineOptions,

						showWhen() {
							return webhookOption.data.id === INTEGRATION_OPTION.data.id;
						}
					}
				}
			};
		},

		bannerText() {
			if (this.formValues.webhookOption.data.id === CUSTOM_WEBHOOK_OPTION.data.id) {
				return "Webhook will deploy your application on each POST message to the URL.";
			}

			return "Webhook will automatically deploy your application on completion of the pipeline.";
		},

		integrationPipelineOptions() {
			const options: any = [];
			Object.values(this.integrationPipelines).forEach(pipelineObj => {
				if (Object.keys(pipelineObj).length > 0) {
					options.push({
						id: pipelineObj.id,
						title: pipelineObj.name,
						data: { id: pipelineObj.id, details: pipelineObj.description },
						qaId: pipelineObj.name
					});
				}
			});
			return options;
		}
	},

	methods: {
		async addWebHook() {
			this.errorMessage = null;

			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();

			const { appDepIntegration, webhookOption } = this.formValues;

			if (!this.formState?.isValid || !this.appDepEntity.id) {
				return;
			}

			try {
				this.isSaving = true;

				const hasCustomTrigger = webhookOption.data.id === CUSTOM_WEBHOOK_OPTION.data.id;

				const payload: webhookRequest = {
					orgId: this.env.orgId,
					projectId: this.env.projId,
					entityId: this.appDepEntity.id,
					triggerType: triggerType.appDep,
					action: DeploymentJobAction.deploy,
					appDep: {
						id: this.appDepEntity.id,
						orgId: this.appDepEntity.orgId!,
						projId: this.appDepEntity.projId!,
						EnvId: this.appDepEntity.envId!
					}
				};

				if (hasCustomTrigger) {
					payload.customTrigger = {};
				} else if (appDepIntegration?.id) {
					payload.codepipesTrigger = {
						appInt: {
							id: appDepIntegration.id,
							orgId: this.appDepEntity.orgId,
							projId: this.appDepEntity.projId,
							appId: this.appDepEntity.appId
						} as AppIntegrationId
					};
				}

				await webHooksStore.CREATE_WEB_HOOK(payload);

				notificationsStore.ADD_TOAST({
					qaId: "toast-env-hook-added",
					title: "Webhook added",
					text: ` Webhook is added on ${
						appDepIntegration ? appDepIntegration.title : "custom URL"
					}.`,
					status: "success"
				});

				this.$emit("back");
			} catch (err) {
				captureError(err);
				this.errorMessage = getErrorMessage(err, true);
			} finally {
				this.isSaving = false;
			}
		},

		handleInput(event: CustomEvent<FormValues>) {
			this.formValues = event.detail;
		}
	}
});

type AppDepIntegrationOption = FSelectOptionObject & { name: string; id: string };

type FormValues = {
	webhookOption: WebhookOptionType;
	appDepIntegration?: AppDepIntegrationOption;
};
</script>
