export const APP_FIRST_FLOW_ORG_CREATE_INFO_POPOVERS: InfoContent[] = [
	{
		stepNo: 1,
		stepName: "org",
		target: "checkListMain",
		infoContent: {
			title: "Welcome to Code Pipes!",
			paragraphs: [
				"Since this is a new account, we need to have a few things configured before you get going.",
				"During the configuration, you will have access to this checklist progress on the right to help guide you.",
				"You can find the checklist progress by clicking on the in the top navigation bar"
			],
			link: null
		}
	},
	{
		stepNo: 2,
		stepName: "create-org",
		target: "createOrgPopover",
		infoContent: {
			title: "What is an organization?",
			paragraphs: [
				"With an organization, you can easily manage projects, credentials, environments, and teams",
				"Your teams can collaborate across multiple projects for applications and infrastructure deployments."
			],
			link: {
				label: "Learn more about organizations",
				url: "https://github.com/cldcvr"
			}
		}
	},
	{
		stepNo: 3,
		stepName: "import-bundle",
		target: "createOrgPopover",
		infoContent: {
			title: "What is a bundle?",
			paragraphs: [
				"Import a bundle of configurations for your organization. The imported configurations will be available for all projects when required.",
				"This can include environment classifications, environment base templates, app dependencies, policy sets, integration modules and more."
			],
			link: {
				label: "Learn more about bundles",
				url: "https://github.com/cldcvr"
			}
		}
	}
];

export const APP_FIRST_FLOW_CHECKLIST_STEPS: ChecklistStep[] = [
	{
		stepNo: 1,
		stepName: "orgFlow",
		stepLabel: "Create Organization",
		desc: "Let’s create your first organization. You can create more than one if required.",
		disabled: false,
		completed: false
	},
	{
		stepNo: 2,
		stepName: "credFlow",
		stepLabel: "Add Cloud Credentials",
		desc: "Add cloud credentials to allow Code Pipes to manage Application and Environment deployments.",
		disabled: false,
		completed: false
	},
	{
		stepNo: 3,
		stepName: "projFlow",
		stepLabel: "Create Project",
		desc: "Inside an organization you can have multiple projects. We will learn more about projects next.",
		disabled: false,
		completed: false
	}
];

export type ChecklistStep = {
	stepNo: number;
	stepName: string;
	stepLabel: string;
	desc: string;
	disabled: boolean;
	completed: boolean;
};

export type CheckListFlags = {
	[key: string]: boolean | undefined;
	orgFlow?: boolean;
	credFlow?: boolean;
	projFlow?: boolean;
};

export type InfoContent = {
	stepNo: number;
	stepName: string;
	target: string;
	infoContent: {
		title: string;
		paragraphs: string[];
		link: null | {
			label: string;
			url: string;
		};
	};
};
export type InfoFlags = {
	creds?: boolean;
	envClassification?: boolean;
	policySets?: boolean;
	projWelcome?: boolean;
	hasProjectApplicationPopover?: boolean;
	hasProjectCredentialsPopover?: boolean;
	hasProjectDetailsPopover?: boolean;
	hasProjectClassificationPopover?: boolean;
	hasProjectMembersPopover?: boolean;
	hasProjectHomePagePopover?: boolean;
};
