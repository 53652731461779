<template>
	<div>
		<AuthWrapper
			v-if="verificationStatus.verifying"
			key="verifying-email"
			header="Please wait while we verify your email..."
			:show-back-icon="false"
		>
			<Section padding="horizontal">
				<div class="center-of-container">
					<Icon name="i-history" type="filled" size="medium" />
				</div>
			</Section>
		</AuthWrapper>
		<AuthWrapper
			v-if="verificationStatus.success"
			key="verify-email-success"
			header="Email verified"
			:show-back-icon="false"
		>
			<p class="fc-normal paragraph-1 line-h-24">
				Your email address is verified and your Code Pipes account is ready, please log in to
				continue.
			</p>
			<template #alt-auth>
				<Section padding="vertical" class="center-of-container">
					<div class="center-of-container margin-tp-12 flex-gap-12 width-80-per">
						<Button
							type="primary"
							state="filled"
							:full-width="true"
							@click="redirectMixin({ routeName: 'login' })"
						>
							log in
						</Button>
					</div>
				</Section>
			</template>
		</AuthWrapper>
		<AuthWrapper
			v-if="verificationStatus.failed"
			key="verify-email-failed"
			:header="errorMessage"
			:show-back-icon="false"
		>
			<template #alt-auth>
				<Section padding="vertical" class="center-of-container">
					<div class="center-of-container margin-tp-12 flex-gap-12 width-80-per">
						<Button
							type="primary"
							state="outlined"
							@click="redirectMixin({ routeName: 'verification-email' })"
						>
							re-send verification
						</Button>
						<Button type="primary" state="outlined" @click="redirectMixin({ routeName: 'login' })">
							log in
						</Button>
					</div>
				</Section>
			</template>
		</AuthWrapper>
	</div>
</template>

<script>
import { Section, Icon, Button } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { verifyUserEmailCode } from "@/modules/auth/services/auth-service";
import AuthWrapper from "@/modules/auth/views/AuthWrapper.vue";
import routerMixin from "@/shared/mixins/routerMixin";
import { getErrorMessage } from "@/utils";

const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please try again later.";

export default defineComponent({
	name: "VerifyUserEmail",
	components: { Section, AuthWrapper, Icon, Button },
	mixins: [routerMixin],

	beforeRouteEnter(to, from, next) {
		const { code } = to.query;
		if (!code) {
			return next("login");
		}
		next();
	},

	data() {
		return {
			verificationStatus: {
				success: false,
				verifying: true,
				failed: false
			},

			code: null,
			errorMessage: ""
		};
	},

	created() {
		this.code = this.$route.query.code;
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (this.code) {
			this.verifyUserEmailCode(this.code);
		} else {
			this.verificationStatus.verifying = false;
			this.verificationStatus.failed = true;
			if (!this.errorMessage) {
				this.errorMessage = DEFAULT_ERROR_MESSAGE;
			}
		}
	},

	methods: {
		async verifyUserEmailCode(code) {
			try {
				await verifyUserEmailCode({ code });
				this.verificationStatus.success = true;
			} catch (error) {
				this.errorMessage = getErrorMessage(error) || DEFAULT_ERROR_MESSAGE;
				this.verificationStatus.failed = true;
			}
			this.verificationStatus.verifying = false;
		}
	}
});
</script>
