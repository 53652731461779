<template>
	<CPTable class="flow-add-scrollbar bar-height-6">
		<Section v-if="isLoadingSequence" data-qa-loading-promo-seq>
			<CustomLoader />
		</Section>
		<!-- Header -->
		<CPRow
			v-if="!isLoadingSequence && !showEmptyCodePromoScreen"
			class="margin-tp-12"
			data-qa-app-wise-sequences-header
			header
		>
			<CPColumn type="app" />
			<template v-for="(env, i) in promoEnvs" :key="i">
				<CPColumn v-if="envHasDeps(env)" type="env">
					<Container
						padding="8px 0px 8px 0px"
						:gap="12"
						class="cursor-pointer"
						@click.stop="openEnv(env)"
					>
						<Pictogram size="s" shape="hexagon">{{ getEmojiOrShortCode(envs[env.id]) }}</Pictogram>
						<Typography type="p1" color="dark" :data-qa-promo-env-name="env.name">{{
							env.name
						}}</Typography>
					</Container>
				</CPColumn>
				<CPColumn type="trigger" />
			</template>
		</CPRow>
		<div v-if="!showEmptyCodePromoScreen">
			<CPRow v-for="(sequence, i) in appwiseSequence" :key="i" data-qa-app-wise-sequences-body>
				<!-- SEQUENCE START -->

				<!-- APP -->
				<CPColumn type="app">
					<CodePromoApp :name="sequence.app?.name" />
				</CPColumn>

				<!-- DEV -->
				<template v-for="(dep, idx) in sequence.deps" :key="idx">
					<CPColumn>
						<!-- Promo card which has been promoted at least once -->
						<CodePromoAppCard
							v-if="dep.lastJob"
							:version="dep.lastJob.deployVersion ?? ''"
							:job="getLatestJob(dep)"
							:deployment="dep"
						/>
						<!-- Show this card for the empty states for all deps except the first one -->
						<EmptyCodePromoCard
							v-if="dep.lastJob === null && idx != 0"
							:env-name="dep.env.name ?? ''"
						/>
						<!-- This is a feature dependent on the UX and backend -->
						<!-- idx === 0 -> only to show the promo trigger for the first deployment in the sequence. -->
						<CodePromoAppNotDeployed
							v-if="!isAppDeployed(dep) && idx === 0"
							:dep="dep"
							:app="sequence.app"
							:versions="getCurrentVersion(dep)"
							:promote-from-env="dep.env.name"
							:promote-to-env="getNextDepEntity(sequence.deps, idx)?.env.name ?? ''"
							:is-submitting="isSubmitting"
							:submit-error="submitError"
							@close="closeConfirmPopover"
							@confirm="promoteApplication"
						/>
					</CPColumn>

					<CPColumn v-if="sequence.deps.length - 1 !== idx" type="trigger">
						<PopOver
							:open="openConfirmFor?.id === dep.id"
							data-qa-close-trigger-promo-popover-by-overlay
							@overlay-click="closeConfirmPopover"
						>
							<CodePromoTrigger
								:job="getNextJob(sequence.deps, idx)"
								:version="dep?.lastJob?.deployVersion ?? ''"
								:is-app-deployed="isAppDeployed(dep)"
								:next-dep-entity="getNextDepEntity(sequence.deps, idx)"
								:data-qa-cp-trigger-job="getNextJob(sequence.deps, idx)"
								data-qa-cp-trigger-arrow-click
								@confirm="openConfirmation(dep)"
							/>
							<template #content>
								<PromoteAppConfirm
									v-if="showPromoTriggerPopover(sequence.deps, idx)"
									:versions="getCurrentVersion(dep)"
									:deployment="getNextDepEntity(sequence.deps, idx)"
									:app="sequence.app"
									:promote-from-env="dep.env.name"
									:promote-to-env="getNextDepEntity(sequence.deps, idx)?.env.name ?? ''"
									:is-submitting="isSubmitting"
									:submit-error="submitError"
									@close="closeConfirmPopover"
									@confirm="promoteApplication"
								/>
								<CancelCodePromotion
									v-if="isJobInProgress(sequence.deps, idx, dep)"
									:job="getNextJob(sequence.deps, idx)"
									:deployment="getNextDepEntity(sequence.deps, idx)"
									:is-submitting="isSubmitting"
									:app="sequence.app"
									:promote-from-env="dep.env.name"
									:promote-to-env="getNextDepEntity(sequence.deps, idx)?.env.name ?? ''"
									@close="closeConfirmPopover"
									@confirm="cancelPromotion"
								/>
							</template>
						</PopOver>
					</CPColumn>
				</template>
			</CPRow>
		</div>
		<EmptyCodePromotionSequence
			v-if="!isLoadingSequence && showEmptyCodePromoScreen"
			data-qa-no-promo-seq-available
		/>
	</CPTable>
</template>
<script lang="ts">
import { Container, Section, Typography, Pictogram, PopOver } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { codePromotionStore } from "@/modules/code-promotion/code-promotion-store";
import CancelCodePromotion from "@/modules/code-promotion/components/CancelCodePromotion.vue";
import CodePromoApp from "@/modules/code-promotion/components/CodePromoApp.vue";
import CodePromoAppCard from "@/modules/code-promotion/components/CodePromoAppCard.vue";
import CodePromoAppNotDeployed from "@/modules/code-promotion/components/CodePromoAppNotDeployed.vue";
import CodePromoTrigger from "@/modules/code-promotion/components/CodePromoTrigger.vue";
import EmptyCodePromoCard from "@/modules/code-promotion/components/EmptyCodePromoCard.vue";
import EmptyCodePromotionSequence from "@/modules/code-promotion/components/EmptyCodePromotionSequence.vue";
import PromoteAppConfirm from "@/modules/code-promotion/components/PromoteAppConfirm.vue";
import { EnvRoute } from "@/modules/env-list/components/env-widget/env-header/EnvWidgetHeader.vue";
import { envListStore } from "@/modules/env-list/env-list-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { app as AppProto } from "@/protocol/app";
import {
	AppDeployment,
	DeploymentJobAction,
	jobResponse,
	promotedVersion
} from "@/protocol/deployment";
import { project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import CPColumn from "@/shared/components/code-promotion/CPColumn.vue";
import CPRow from "@/shared/components/code-promotion/CPRow.vue";
import CPTable from "@/shared/components/code-promotion/CPTable.vue";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { PipelineJobStatus, PIPELINE_UNFINISHED_JOB_STATUSES } from "@/shared/pipeline-constants";
import { getEmoji, getErrorMessage, getShortCode } from "@/utils";

export default defineComponent({
	name: "CodePromotionWrapper",

	components: {
		Container,
		CodePromoAppNotDeployed,
		CancelCodePromotion,
		EmptyCodePromotionSequence,
		PopOver,
		EmptyCodePromoCard,
		PromoteAppConfirm,
		CodePromoAppCard,
		CodePromoTrigger,
		CodePromoApp,
		Section,
		CustomLoader,
		Typography,
		Pictogram,
		CPTable,
		CPRow,
		CPColumn
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		}
	},

	data: () => ({
		isLoadingSequence: false,
		appDeployments: {} as AllAppDeployments,
		openConfirmFor: null as AppDeployment | null,
		pipelineUnfinishedStatuses: PIPELINE_UNFINISHED_JOB_STATUSES,
		isSubmitting: false,
		submitError: ""
	}),

	computed: {
		promotableVersions() {
			return codePromotionStore.promotableVersions;
		},

		apps(): AppProto[] {
			return applicationStore.projectApps[this.project.id] ?? [];
		},

		allAppJobs() {
			return applicationDeploymentStore.allAppJobs;
		},

		promoEnvs() {
			return codePromotionStore.projPromoEnvs(this.project.id);
		},

		envs(): Record<string, environment> {
			const projEnvs = envListStore.envs[this.project.id];
			return projEnvs?.reduce((obj, env) => Object.assign(obj, { [env.id]: env }), {}) ?? {};
		},

		showEmptyCodePromoScreen() {
			return this.appwiseSequence.map((item: promotionSchemaItem) => item.deps).flat().length === 0;
		},

		appwiseSequence() {
			// Generate code promotion schema
			const schemaItems: promotionSchemaItem[] = [];
			const appDepEntities = applicationDeploymentStore.deploymentsInApp;
			Object.entries(appDepEntities).forEach(([appId, appDeployments]) => {
				const EnvDepMapping: EnvDepMapping[] = [];

				/**
				 * As we don't have multiple dep entities shown in the Code-promotion flow
				 * we only take one dep eneity per env ( latest ).
				 */
				const latestDepPerEnv = [
					...new Map(appDeployments.map(item => [item.envId, item])).values()
				];

				latestDepPerEnv.forEach((appDep: AppDeployment) => {
					const currEnv = this.promoEnvs.find((env: environment) => env.id === appDep.envId);

					if (currEnv?.id) {
						EnvDepMapping.push({ ...appDep, env: currEnv });
					}
				});
				// Sort the deps based on the Promo env seq in the array
				const deps = this.promoEnvs.map(env =>
					EnvDepMapping.find(appDep => appDep.envId === env.id)
				);
				// Added the app object in the schema items by searching using the appId
				const [application]: AppProto[] = this.apps.filter(app => app.id === appId);
				if (application) {
					schemaItems.push({
						app: application,
						deps: deps.filter(removeFalsy)
					});
				}
			});

			return schemaItems;
		}
	},

	mounted() {
		this.isLoadingSequence = true;
		/**
		 * Check if there are apps present in the project and each app has a deployment
		 * entity created/associated with it.
		 * If present, then fetch the promotableVersions for each deployment.
		 * If promotable version is present, then that deployment has been promoted to the env
		 */
		this.loadCodePromoSequences();
	},

	methods: {
		async loadCodePromoSequences() {
			// Fetch apps as user can refresh the page
			await applicationStore.FETCH_APPS_IN_PROJECT({
				orgId: this.project.orgId,
				projectId: this.project.id
			});

			/**
			 * Get deployment entities for each app because we only show the promotion card
			 * if a dep entity is created for an env.
			 */
			for (const app of this.apps) {
				const deps = await applicationDeploymentStore.GET_APP_DEPLOYMENTS({
					orgId: this.project.orgId,
					projId: this.project.id,
					id: app.id
				});

				if (deps !== undefined) {
					// We only need those apps that have atleast one deployment entity associated with it.
					this.appDeployments[app.id] = deps;
				}
			}

			// Get promotable versions
			const promises: Promise<void>[] = [];
			Object.keys(this.appDeployments).forEach(appId => {
				const appDeps = this.appDeployments[appId];

				appDeps?.forEach(dep => {
					if (dep.id && dep.envId && dep.projId && dep.orgId) {
						promises.push(
							codePromotionStore.GET_PROMOTABLE_VERSIONS({
								envId: dep.envId,
								id: dep.id,
								orgId: dep.orgId,
								projId: dep.projId,
								appId
							})
						);
					}
				});
			});

			await Promise.all(promises);

			/**
			 * Once we have apps, deps, versions, we then get the promotion sequence of the envs
			 * for the current project.
			 * If there are is no seq, we show empty code-promo screen.
			 */
			await codePromotionStore.GET_PROJ_PROMOTION_SEQUENCE({
				projId: this.project.id,
				orgId: this.project.orgId
			});

			this.isLoadingSequence = false;
		},

		isAppDeployed(dep: EnvDepMapping) {
			return dep.lastSuccessfulJob?.jobType === DeploymentJobAction.deploy;
		},

		getJobStatus(dep: EnvDepMapping | undefined) {
			if (!dep) {
				return "" as PipelineJobStatus;
			}
			return this.getLatestJob(dep)?.jobStatus as PipelineJobStatus;
		},

		getCurrentVersion(dep: AppDeployment): promotedVersion[] {
			if (!dep.id) {
				return [];
			}
			return this.promotableVersions[dep.id] ?? [];
		},

		openEnv(env: environment) {
			const params: EnvRoute = {
				orgId: env.orgId,
				projectId: env.projId,
				envId: env.id
			};
			this.$router.push({
				name: "envDetail",
				params
			});
		},

		openConfirmation(dep: AppDeployment) {
			this.openConfirmFor = dep;
		},

		closeConfirmPopover() {
			this.openConfirmFor = null;
			this.submitError = "";
		},

		async promoteApplication({
			dep,
			version
		}: {
			dep: AppDeployment & { env: environment };
			version: string;
		}) {
			try {
				this.submitError = "";
				this.isSubmitting = true;

				if (dep.id && dep.orgId && dep.envId && dep.appId && dep.projId) {
					await codePromotionStore.PROMOTE_APP({
						envId: dep.envId,
						id: dep.id,
						orgId: dep.orgId,
						projId: dep.projId,
						version
					});
				}
				this.openConfirmFor = null;
				notificationsStore.ADD_TOAST({
					qaId: "toast-promotion-triggered-successfully",
					title: "Started app promotion",
					text: `Started promotion with version ${version} to ${dep.env.name}`,
					status: "success"
				});
			} catch (error) {
				this.submitError = getErrorMessage(error);
				notificationsStore.ADD_TOAST({
					qaId: "toast-promotion-trigger-failed",
					title: "Code promotion failed",
					text: `${this.submitError}`,
					status: "error"
				});
			} finally {
				this.isSubmitting = false;
			}
		},

		async cancelPromotion({
			dep,
			job
		}: {
			dep: AppDeployment & { env: environment };
			job: jobResponse;
		}) {
			if (!job.id || !dep.envId || !dep.projId || !dep.orgId) {
				return;
			}

			this.isSubmitting = true;

			await applicationDeploymentStore.CANCEL_DEPLOYMENT({
				id: job.id,
				envId: dep.envId,
				orgId: dep.orgId,
				projId: dep.projId,
				depId: job.depId
			});

			this.openConfirmFor = null;
			this.isSubmitting = false;
		},

		getEmojiOrShortCode(env: environment | undefined) {
			return getEmoji(env) ?? getShortCode(env?.name);
		},

		getNextDepEntity(deps: EnvDepMapping[], idx: number) {
			return deps[idx + 1];
		},

		getLatestJob(dep: EnvDepMapping) {
			return (
				this.allAppJobs
					.filter(job => job.depId === dep.id)
					.sort((jobA, jobB) => Number(jobB.createdAt ?? 0) - Number(jobA.createdAt ?? 0))[0] ??
				null
			);
		},

		getNextJob(deps: EnvDepMapping[], idx: number) {
			const dep = deps[idx + 1];
			return dep ? this.getLatestJob(dep) : null;
		},

		showPromoTriggerPopover(deps: EnvDepMapping[], idx: number) {
			return !this.pipelineUnfinishedStatuses.includes(
				this.getJobStatus(this.getNextDepEntity(deps, idx))
			);
		},

		isJobInProgress(deps: EnvDepMapping[], idx: number, dep: EnvDepMapping) {
			return (
				dep.lastJob !== undefined &&
				this.pipelineUnfinishedStatuses.includes(
					this.getJobStatus(this.getNextDepEntity(deps, idx))
				)
			);
		},

		envHasDeps(env: environment) {
			return Boolean(
				this.appwiseSequence.find((item: promotionSchemaItem) =>
					item.deps.find((dep: EnvDepMapping) => dep.envId === env.id)
				)
			);
		}
	}
});
function removeFalsy<T>(val: T): val is NonNullable<T> {
	return Boolean(val);
}

export type EnvDepMapping = AppDeployment & { env: environment };
export type promotionSchemaItem = {
	app: AppProto;
	deps: EnvDepMapping[];
};
export type AllAppDeployments = { [appId: string]: AppDeployment[] };
</script>
