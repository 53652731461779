<template>
	<f-text v-if="hasNoArtifacts" state="danger"
		>You must create an application artifact before you can deploy it.</f-text
	>
	<f-form-builder
		v-else
		data-qa-deployment-artifact-selector
		:field.prop="formFields"
		:values.prop="formValues"
		@input="handleInput"
		@state-change="formState = $event.detail"
	/>
</template>

<script lang="ts">
import { FSelectOptionObject, FSelectSingleOption } from "@cldcvr/flow-core";
import { FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { PropType, defineComponent } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { Artifact, ArtifactSelector, ArtifactType } from "@/protocol/common";
import { AppDeployment } from "@/protocol/deployment";
import {
	BRANCH_GIT_REVISION_OPTION,
	GIT_REVISION_OPTIONS,
	GitRevisionOption
} from "@/shared/constants";
import { getArtifactIcon, getArtifactSimpleIdentifier, getRelativeTime } from "@/utils";

import { applicationStore } from "../application-store";

const MANUAL_TAG_OPTION: ContainerTagOption = {
	title: "Add manually",
	data: { id: "manual" }
};

export default defineComponent({
	name: "DeploymentArtifactSelector",

	props: {
		appId: {
			type: String,
			required: true
		},

		deployment: {
			type: Object as PropType<AppDeployment>
		},

		selectedArtifactId: {
			type: String
		}
	},

	emits: ["artifact-info"],

	data() {
		return {
			formValues: {} as LocalFormValues,
			formState: null as FormBuilderState | null
		};
	},

	computed: {
		hasNoArtifacts() {
			return !this.app?.artifacts || this.app.artifacts.length === 0;
		},

		app() {
			return applicationStore.apps[this.appId];
		},

		containerTagOptions() {
			const options: ContainerTagOption[] =
				this.currentAppBuilds?.map(build => {
					return {
						title: `Build ${build.buildNumber} (${getRelativeTime(build.updatedAt ?? "")})`,
						data: { id: build.containerRef?.tag ?? "" }
					};
				}) ?? [];

			options.unshift(MANUAL_TAG_OPTION);

			return options;
		},

		formFields(): FormBuilderField {
			const artifact = this.formValues.applicationArtifact?.data.artifact;
			const isManualTag = this.formValues.containerTag?.data.id === "manual";

			const formFields: FormBuilderField = {
				type: "object",
				direction: "vertical",
				fields: {
					applicationArtifact: {
						type: "select",
						id: "app-deployment-artifact",
						label: { title: "Select artifact" },
						options: this.app?.artifacts ? this.app.artifacts.map(getArtifactOption) : [],
						validationRules: [{ name: "required" }],

						//@ts-expect-error
						optionTemplate: (option: ArtifactFormOption) =>
							html` <f-div gap="medium" overflow="hidden" align="middle-left">
								<f-icon size="small" source="${option.icon}"> </f-icon>
								<f-text ellipsis="true" size="small">${option.title}</f-text>
							</f-div>`
					},

					revisionType: {
						type: "select",
						qaId: "revisionType",
						label: { title: "Revision type" },
						placeholder: "Select revision type",
						options: GIT_REVISION_OPTIONS,
						validationRules: [{ name: "required" }],
						showWhen() {
							return artifact?.type === ArtifactType.GitCode;
						}
					},

					revisionIdentifier: {
						type: "text",
						id: "app-deployment-artifact-identifier",
						label: { title: "Revision identifier" },
						validationRules: [{ name: "required" }],
						showWhen() {
							return artifact?.type === ArtifactType.GitCode;
						}
					},

					containerTag: {
						type: "select",
						id: "app-deployment-artifact-container-tag",
						label: { title: "Container tag" },
						validationRules: [{ name: "required" }],
						options: this.containerTagOptions,
						//@ts-expect-error
						optionTemplate: (option: FSelectOptionObject) =>
							html` <f-div
								direction="column"
								gap="x-small"
								data-qa-container-id="${option.data?.id}"
							>
								<f-text size="small">${option.title}</f-text>
								${option.data?.id !== "manual"
									? html`<f-text state="secondary" size="x-small">${option.data?.id}</f-text>`
									: ""}
							</f-div>`,

						showWhen() {
							return artifact?.type === ArtifactType.ContainerImage;
						}
					},

					manualContainerTag: {
						type: "text",
						id: "app-deployment-artifact-manual-tag",
						label: { title: "Custom container tag" },
						validationRules: [{ name: "required" }],
						showWhen() {
							return artifact?.type === ArtifactType.ContainerImage && isManualTag;
						}
					}
				}
			};

			return formFields;
		},

		// Selects app builds which have a successful container tag created
		currentAppBuilds() {
			return this.app?.id
				? applicationDeploymentStore.appBuilds[this.app.id]?.filter(
						build => build.containerRef?.tag
				  )
				: undefined;
		}
	},

	watch: {
		// Initialise default artifact values
		deployment: {
			immediate: true,

			handler() {
				let currentArtifact: Artifact | undefined = undefined;
				const appArtifacts = this.app?.artifacts;
				const firstArtifact = appArtifacts?.[0];

				if (!firstArtifact) {
					return;
				}

				// An artifact sent from props takes priority over everything else
				if (this.selectedArtifactId) {
					currentArtifact = appArtifacts.find(
						artifact_ => artifact_.id === this.selectedArtifactId
					);
				}

				// Check from current app deployment if present
				if (!currentArtifact) {
					const { deployment } = this;
					currentArtifact = deployment?.deploymentConfig?.artifact;
				}

				// Fall back to the first artifact
				if (!currentArtifact) {
					currentArtifact = firstArtifact;
				}

				this.formValues.applicationArtifact = getArtifactOption(currentArtifact);

				this.emitArtifactInfo();
			}
		},

		// Set default values for manual tag and branch identifier if the artifact selection is changed
		"formValues.applicationArtifact": {
			immediate: true,

			handler() {
				const artifact = this.formValues.applicationArtifact?.data.artifact;

				// Only update the values if they haven't changed by the user
				if (artifact?.type === ArtifactType.ContainerImage && !this.formValues.manualContainerTag) {
					this.formValues.manualContainerTag = artifact.containerImage?.reference;
				} else if (artifact?.type === ArtifactType.GitCode && !this.formValues.revisionIdentifier) {
					this.formValues.revisionType = GIT_REVISION_OPTIONS.find(
						option => option.data.id === artifact.gitCode?.type
					);
					this.formValues.revisionIdentifier = artifact.gitCode?.identifier;
				}

				this.emitArtifactInfo();
			}
		},

		// Fetch latest app builds
		app: {
			immediate: true,

			handler() {
				if (this.app) {
					applicationDeploymentStore.GET_APP_BUILDS({
						id: this.app.id,
						orgId: this.app.orgId,
						projId: this.app.projId
					});
				}
			}
		},

		// When we find a new app build we update the tag options
		currentAppBuilds: {
			immediate: true,

			handler() {
				// Use manually entered tags when no app builds were found
				if (!this.currentAppBuilds) {
					this.formValues.containerTag = MANUAL_TAG_OPTION;
					return;
				}

				const [latestBuild] = this.currentAppBuilds;
				let latestTag = latestBuild?.containerRef?.tag;

				// If an existing app deployment is present we check if the user entered a custom tag
				const { deployment: appDeployment } = this;
				const appDepArtifact = appDeployment?.deploymentConfig?.artifact;
				if (appDepArtifact?.type === ArtifactType.ContainerImage) {
					latestTag = appDepArtifact.containerImage?.reference;
				}

				this.formValues.containerTag =
					this.containerTagOptions.find(tag => tag.data.id === latestTag) ?? MANUAL_TAG_OPTION;
			}
		},

		"formState.isValid": {
			handler() {
				this.emitArtifactInfo();
			}
		}
	},

	methods: {
		handleInput(event: CustomEvent<LocalFormValues>) {
			this.formValues = {
				...event.detail
			};

			this.emitArtifactInfo();
		},

		emitArtifactInfo() {
			const { formValues } = this;

			let artifactorSelector: ArtifactSelector;
			const selectedArtifact = this.formValues.applicationArtifact?.data.artifact;

			if (selectedArtifact?.type === ArtifactType.GitCode) {
				artifactorSelector = {
					id: selectedArtifact.id,
					gitCode: {
						type: this.formValues.revisionType?.data.id ?? BRANCH_GIT_REVISION_OPTION.data.id,
						identifier: this.formValues.revisionIdentifier ?? "main"
					}
				};
			} else if (selectedArtifact?.type === ArtifactType.ContainerImage) {
				const { containerTag } = formValues;

				const containerTagStr =
					this.formValues.containerTag?.data.id === "manual"
						? this.formValues.manualContainerTag
						: containerTag?.data.id;

				artifactorSelector = {
					id: selectedArtifact.id,
					containerImage: {
						reference: containerTagStr
					}
				};
			} else {
				throw new Error(`Unsupported artifact type: ${selectedArtifact?.type}`);
			}

			const updatedInfo: ArtifactSelectorInfo = {
				artifactType: selectedArtifact.type,
				artifactorSelector,
				isValid: Boolean(this.formState?.isValid)
			};

			this.$emit("artifact-info", updatedInfo);
		}
	}
});

function getArtifactOption(artifact: Artifact): ArtifactFormOption {
	return {
		title: artifact.name,
		data: {
			artifact,
			details: getArtifactSimpleIdentifier(artifact)
		},
		icon: getArtifactIcon(artifact),
		qaId: artifact.name
	};
}

type ContainerTagOption = FSelectSingleOption & { data: { id: string } };

type ArtifactFormOption = FSelectOptionObject & {
	data: {
		details: string;
		artifact: Artifact;
	};
};

type LocalFormValues = {
	containerTag?: ContainerTagOption | null;
	revisionType?: GitRevisionOption;
	revisionIdentifier?: string;
	manualContainerTag?: string;
	applicationArtifact?: ArtifactFormOption;
};

export type ArtifactSelectorInfo = {
	artifactType?: ArtifactType;
	artifactorSelector?: ArtifactSelector;
	isValid: boolean;
};
</script>
