<template>
	<Wrapper width="450px" border-radius="4px" background="element-light" data-qa-delete-application>
		<Container :padding="0" :gap="0" direction="column" :grow="1">
			<Header>
				<Typography type="h4" color="dark"> Remove {{ app.name }} </Typography>
				<Icon
					name="i-close"
					type="filled"
					size="small"
					data-qa-delete-application-close
					@click="closeAppDeletePopover(app)"
				/>
			</Header>
			<Container
				direction="column"
				:gap="20"
				:padding="16"
				:shrink="0"
				align="left stretch"
				:grow="1"
			>
				<Container :padding="0" :gap="4" data-qa-delete-app-confirmation-text>
					<Typography type="p1" color="dark" weight="thin"
						>Are you sure you want to remove
					</Typography>
					<Typography type="p1" color="dark" weight="bold" :style="{ whiteSpace: 'pre-wrap' }"
						>{{ app.name }}?</Typography
					>
				</Container>
				<Container :padding="0" :gap="4">
					<Typography v-if="!appEnvs || !appEnvs.length" type="p1-para" color="dark" weight="thin"
						>{{ app.name }} is not deployed and you can safely remove the application.</Typography
					>
					<Typography v-else type="p1-para" color="dark" weight="thin"
						>To proceed, please undeploy this application from the environments below</Typography
					>
				</Container>
			</Container>
			<Container
				v-if="deploymentEntities && deploymentEntities.length"
				direction="column"
				:padding="16"
				:grow="0"
				:gap="12"
				overflow="auto"
				align="left top"
			>
				<template v-for="(env, i) in appEnvs" :key="env.id + i">
					<Accordion :open="accordionRotation[env.id] === 180">
						<template #header>
							<Slab :no-padding="true" overflow="visible" @click="toggleAccordian(env.id)">
								<template #primary-action>
									<Pictogram shape="hexagon" state="avatar" label="P"> </Pictogram>
								</template>
								<Container direction="column" padding="0px 8px 0px 12px" :gap="4">
									<Container :padding="0" :gap="12">
										<Typography type="h5" weight="medium" color="dark">{{ env.name }}</Typography>
									</Container>
									<Typography type="p3" color="light"
										>{{ env.depEntities && env.depEntities.length }} deployment</Typography
									>
								</Container>
								<template #secondary-actions>
									<Icon
										name="i-chevron-down"
										type="filled"
										size="small"
										:rotate="accordionRotation[env.id]"
									/>
								</template>
							</Slab>
						</template>
						<Container padding="16px 0px 4px 16px" direction="column">
							<Slab
								v-for="deployEntity in env.depEntities"
								:key="deployEntity.id"
								align-items="start"
								:no-padding="true"
								overflow="visible"
							>
								<Container padding="0px 8px 0px 0px" :gap="12" align="left top">
									<Pictogram shape="squircle" state="avatar" label="M" size="s"> </Pictogram>
									<Container direction="column" :padding="0" :gap="4">
										<Container :gap="12" :padding="0">
											<Typography type="p2" color="dark">{{ deployEntity.name }}</Typography>
											<Tag size="small">
												<Typography type="p3" color="light">Latest</Typography>
											</Tag>
										</Container>
										<Container
											v-if="
												currentFailedJobMessage(deployEntity) &&
												deployEntity.id &&
												undeployFlags[deployEntity.id] === ''
											"
											:gap="8"
											:padding="0"
										>
											<Typography type="p2" color="light">{{
												currentFailedJobMessage(deployEntity)
											}}</Typography>
										</Container>
										<Container
											v-if="
												isCurrentJobActive(deployEntity) ||
												(deployEntity.id && undeployFlags[deployEntity.id] == 'undeploy')
											"
											:gap="8"
											:padding="0"
										>
											<Icon name="i-history" state="error" size="x-small" />
											<Typography type="p2" color="light">undeploying application... • </Typography>
											<Typography
												type="h6"
												weight="medium"
												:link="true"
												color="error"
												transform="uppercase"
												@click="openAppPipeline(deployEntity)"
												>View Logs</Typography
											>
										</Container>
									</Container>
								</Container>
								<template #secondary-actions>
									<Button
										v-if="
											deployEntity.id &&
											undeployFlags[deployEntity.id] == '' &&
											showUndeployButton(deployEntity)
										"
										size="x-small"
										type="error"
										@click="triggerAppUndeployment(deployEntity)"
										>undeploy</Button
									>
								</template>
							</Slab>
						</Container>
					</Accordion>
				</template>
			</Container>

			<Container direction="column" :gap="16" :shrink="0" :padding="16" align="left stretch">
				<Divider> </Divider>
				<Container
					:padding="0"
					direction="column"
					:gap="8"
					background="element-light"
					overflow="visible"
				>
					<Typography type="p2" weight="medium" data-qa-delete-app-confirmation-text-entry
						>To confirm this action, enter the application name below</Typography
					>
					<InputText
						v-model:value="enteredAppName"
						data-qa-app-del-form-app-name-field
						:disabled="(appEnvs.length > 0 || disableSubmitInputButton) && !hasFailedJobs"
						placeholder="Enter application name to confirm"
					>
					</InputText>
				</Container>
				<Container v-if="appDeleteErrorMsg" :gap="8" :padding="0">
					<Typography type="p2" color="error">{{ appDeleteErrorMsg }}</Typography>
				</Container>
			</Container>
			<!-- appEnvs.length > 0 ||  -->
			<Footer>
				<Button
					data-qa-app-del-confirm-btn
					state="full"
					type="error"
					:disabled="app.name !== enteredAppName || disableSubmitInputButton"
					@click="triggerDeleteApplication(app)"
				>
					<Icon v-if="isAppDeleteLoading" name="i-history" state="light" :effects="false" />
					<span>Remove {{ app.name }}</span>
				</Button>
			</Footer>
		</Container>
	</Wrapper>
</template>
<script lang="ts">
import {
	Accordion,
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	InputText,
	Pictogram,
	Slab,
	Tag,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import {
	routerToAppPipeline,
	startApplicationPipeline
} from "@/modules/application-deployment/mixins/AppCommonMixin";
import { CurrentAppJob } from "@/modules/application-deployment/store-types";
import { envListStore } from "@/modules/env-list/env-list-store";
import { projectStore } from "@/modules/project-list/project-store";
import { app } from "@/protocol/app";
import { AppDeployment, DeploymentJobAction } from "@/protocol/deployment";
import { environment } from "@/protocol/infra";
import { JOB_STATUS } from "@/shared/pipeline-constants";
import { getErrorMessage } from "@/utils";

export default defineComponent({
	name: "AppDeletePopover",

	components: {
		Button,
		Header,
		Footer,
		Container,
		Typography,
		Icon,
		Wrapper,
		InputText,
		Divider,
		Accordion,
		Pictogram,
		Tag,
		Slab
	},

	props: {
		// Props
		closeAppDeletePopover: {
			type: Function as PropType<(application: app) => void>,
			required: true
		},

		app: {
			type: Object as PropType<app>,
			required: true
		},

		isDeletePopoverOpen: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		// LocalVariables
		enteredAppName: "",

		isAppDeleteLoading: false,
		undeployFlags: {} as { [key: string]: string },
		accordionRotation: {} as { [key: string]: number },
		appDeleteErrorMsg: ""
	}),

	computed: {
		// Computed Properties
		deploymentEntities() {
			const allDepEntities = applicationDeploymentStore.deploymentsInApp;
			return allDepEntities[this.app.id];
		},

		appEnvs() {
			if (!(this.envs.length && this.deploymentEntities?.length)) {
				return [];
			}

			const onlyDeployedEntites: AppDeployment[] = this.deploymentEntities.filter(
				(deployment: AppDeployment) =>
					deployment.lastSuccessfulJob?.jobType === DeploymentJobAction.deploy
			);

			const newEnvArr: NewEnv[] = [];

			this.envs.forEach((env: environment) => {
				const newEnv: NewEnv = { ...env, depEntities: [] };
				if (onlyDeployedEntites.length) {
					onlyDeployedEntites.forEach((depEntity: AppDeployment) => {
						if (depEntity.envId === env.id && depEntity.appId === this.app.id) {
							newEnv.depEntities.push(depEntity);
						}
					});
					if (newEnv.depEntities.length) {
						newEnvArr.push(newEnv);
					}
				}
			});

			return newEnvArr;
		},

		currentJobs() {
			return applicationDeploymentStore.allAppJobs.filter(job => job.appId === this.app.id);
		},

		envs() {
			const { envs } = envListStore;
			const routeProjId =
				typeof this.$route.params.projectId === "string" ? this.$route.params.projectId : null;
			const projectId = routeProjId ?? projectStore.currentProject?.id ?? "";
			return envs[projectId] ?? [];
		},

		disableSubmitInputButton() {
			let disable = false;

			this.currentJobs.forEach(job => {
				if (job.appId === this.app.id && job.jobStatus === JOB_STATUS.ACTIVE) {
					disable = true;
				}
			});

			return disable;
		},

		hasFailedJobs() {
			let disable = false;

			this.currentJobs.forEach(job => {
				if (job.appId === this.app.id && job.jobStatus === "failed") {
					disable = true;
				}
			});

			return disable;
		}
	},

	watch: {
		currentJobs: {
			deep: true,

			handler(currentJobs: CurrentAppJob[]) {
				currentJobs.forEach(job => {
					if (
						job.jobStatus === JOB_STATUS.ACTIVE ||
						job.jobStatus === JOB_STATUS.PENDING ||
						job.jobStatus === JOB_STATUS.WAITING
					) {
						this.undeployFlags[job.depId] = "undeploy";
					} else {
						this.undeployFlags[job.depId] = "";
					}
				});
			}
		},

		isDeletePopoverOpen(value: boolean) {
			if (!value) {
				this.enteredAppName = "";
				this.appDeleteErrorMsg = "";
			}
		}
	},

	mounted() {
		this.deploymentEntities?.forEach(dep => {
			if (dep.id) {
				this.undeployFlags[dep.id] = "";
			}
		});

		this.appEnvs.forEach((env: environment) => {
			this.accordionRotation[env.id] = 0;
		});
	},

	methods: {
		// Methods
		openAppPipeline(depEntity: AppDeployment) {
			routerToAppPipeline(this.$router, depEntity, DeploymentJobAction.undeploy);
		},

		getCurrentJob(depEntity: AppDeployment) {
			return depEntity.lastJob;
		},

		toggleAccordian(id: string) {
			const degree = this.accordionRotation[id] === 0 ? 180 : 0;
			this.accordionRotation[id] = degree;
		},

		showUndeployButton(deployEntity: AppDeployment) {
			const currentJob = this.getCurrentJob(deployEntity);

			return (
				(!currentJob ||
					(currentJob.jobType === "deploy" && currentJob.jobStatus === "done") ||
					(currentJob.jobType === "undeploy" && currentJob.jobStatus === "failed")) &&
				deployEntity.lastSuccessfulJob?.jobType === DeploymentJobAction.deploy
			);
		},

		async triggerDeleteApplication(application: app) {
			this.isAppDeleteLoading = true;

			try {
				await applicationStore.DELETE_APPLICATION({
					orgId: application.orgId,
					projId: application.projId,
					id: application.id,

					// Force delete all app child entities
					force: true
				});

				this.enteredAppName = "";
				this.appDeleteErrorMsg = "";

				this.closeAppDeletePopover(application);
			} catch (err) {
				this.appDeleteErrorMsg = getErrorMessage(err);
			} finally {
				this.isAppDeleteLoading = false;
			}
		},

		async triggerAppUndeployment(depEntity: AppDeployment) {
			if (depEntity.id) {
				this.undeployFlags[depEntity.id] = "undeploy";
			}

			// This starts the pipeline and updates the app deployment
			await startApplicationPipeline({
				depEntity,
				jobType: DeploymentJobAction.undeploy
			});

			const job = applicationDeploymentStore.allDeployments[depEntity.id!]?.lastJob;

			if (job?.jobStatus === "done" || job?.jobStatus === "failed") {
				this.undeployFlags[depEntity.id!] = "";
			}
		},

		currentFailedJob(deployEntity: AppDeployment) {
			const currJob = this.getCurrentJob(deployEntity);
			return currJob?.jobStatus === "failed" ? currJob : null;
		},

		currentFailedJobMessage(deployEntity: AppDeployment) {
			return this.currentFailedJob(deployEntity)?.message;
		},

		isCurrentJobActive(deployEntity: AppDeployment) {
			const currJob = this.getCurrentJob(deployEntity);
			return (
				currJob &&
				(currJob.jobStatus === JOB_STATUS.ACTIVE ||
					currJob.jobStatus === JOB_STATUS.WAITING ||
					currJob.jobStatus === JOB_STATUS.PENDING)
			);
		}
	}
});

type NewEnv = environment & { depEntities: AppDeployment[] };

export interface Step {
	Deploy?: null;
	Finalize?: null;
}
</script>
