<template>
	<PopOver :open="isOpen">
		<Container
			v-tooltip="{
				content: isItemsEmpty ? disabledTitle : label
			}"
			overflow="auto"
			:data-qa="dataQa"
			class="width-100-per cursor-pointer"
			:disabled="isItemsEmpty"
			@click="!isItemsEmpty ? (isModuleListOpen = true) : () => {}"
		>
			<Card type="placeholder" :clickable="true" :disabled="isItemsEmpty">
				<Container :gap="12">
					<Button state="icon" size="small">
						<Icon
							name="i-plus"
							color="primary-300"
							size="small"
							:effects="false"
							:data-qa-plus-icon="dataQa"
						/>
					</Button>
					<Typography type="p1-para" color="light" weight="light" :data-qa-popover-label="dataQa">
						{{ label }}
					</Typography>
				</Container>
			</Card>
		</Container>

		<template #content>
			<Wrapper
				v-if="isModuleListOpen"
				border-radius="4px"
				background="element-light"
				:border="true"
				height="60vh"
				width="950px"
				:data-qa-add-pipeline-module-content="dataQa"
			>
				<Container
					:padding="0"
					:gap="0"
					direction="column"
					:grow="1"
					align="start top"
					class="height-inherit"
				>
					<Header>
						<Container align="space-between center" :padding="0" :grow="1">
							<Container :gap="12" :padding="0">
								<Typography type="h4" color="dark" :data-qa-popover-title="dataQa">{{
									title
								}}</Typography>
							</Container>
							<Container align="right center" :grow="1" :padding="0">
								<Icon
									name="i-close"
									type="filled"
									size="small"
									:data-qa-add-pipeline-module-close="dataQa"
									@click.stop="
										isModuleListOpen = false;
										isAddModuleOpen = false;
										searchText = '';
										$emit('close');
									"
								/>
							</Container>
						</Container>
					</Header>

					<Container :padding="0">
						<Divider />
					</Container>

					<Container align="left top" :gap="0" :grow="1" padding="0px">
						<Container
							padding="20px 0px"
							direction="column"
							overflow="auto"
							class="height-100-per"
							align="left top"
							:shrink="0"
							basis="45%"
							:gap="0"
						>
							<Container padding="0px 16px 10px 16px" align="left top" :shrink="0">
								<SearchInput
									v-model:value="searchText"
									placeholder="Search modules"
									:data-qa-search-input-field="dataQa"
								/>
							</Container>
							<Container
								v-if="Object.keys(filteredModules).length"
								:padding="0"
								align="left top"
								:gap="0"
								direction="column"
								overflow="auto"
							>
								<!-- If the user is searching then open all groups -->
								<Accordion
									v-for="(name, i) in Object.keys(filteredModules)"
									:key="i"
									:open="searchText.length > 0 || closedGroups[name]"
								>
									<template #header>
										<Container
											:clickable="true"
											align="space-between center"
											padding="16px 16px"
											:grow="1"
											overflow="visible"
											:data-qa-add-dependency-group="name"
											@click="closedGroups[name] = !closedGroups[name]"
										>
											<Container :gap="12" :padding="0" align="space-between center" :grow="1">
												<Typography
													transform="uppercase"
													weight="book"
													type="h5"
													color="dark"
													:data-qa-pipeline-category-name="name"
													>{{ name }}</Typography
												>
												<Tag
													v-if="hasAddedModules(name) && !closedGroups[name]"
													background="var(--success-500)"
													:data-qa-pipeline-module-added-label="name"
												>
													<Container :padding="0" :gap="8">
														<Icon name="i-tick" size="xx-small" color="success-300" />
														<Typography type="p3" color="success-300"> Added</Typography>
													</Container>
												</Tag>
											</Container>
											<Icon
												name="i-chevron-down"
												size="x-small"
												:rotate="!closedGroups[name] ? 0 : 180"
												color="gray-300"
											/>
										</Container>
										<Divider />
									</template>
									<Container
										v-for="(module, idx) in filteredModules[name]"
										:id="idx"
										:key="i + idx"
										:padding="0"
										overflow="auto"
										direction="column"
										:gap="0"
										:background="
											selectedModule === module ? 'dashboard-background' : 'element-light'
										"
										class="cursor-pointer"
										:data-qa-add-module-name="module.name"
									>
										<Container :padding="16" @click="selectModule(module)">
											<Container :gap="16" :padding="0">
												<Container padding="0px 0px 0px 12px" :clickable="true">
													<Icon :name="getIcon(module)" data-qa-module-cloud-icon />
													<Container :gap="24" :padding="0" align="space-between center" :grow="1">
														<Typography
															type="p1"
															weight="bold"
															class="padding-lt-8"
															:data-qa-module-name="module.name"
															>{{ module.name }}</Typography
														>
														<Tag
															v-if="module.isAdded"
															background="var(--success-500)"
															:data-qa-pipeline-module-added-label="module.name"
														>
															<Container :padding="0" :gap="8">
																<Icon name="i-tick" size="xx-small" color="success-300" />
																<Typography type="p3" color="success-300"> Added</Typography>
															</Container>
														</Tag>
													</Container>
												</Container>
											</Container>
										</Container>
										<Divider />
									</Container>
								</Accordion>
							</Container>

							<!-- Artifacts -->
							<template v-if="filteredArtifacts.length">
								<Container
									v-for="(artifact, idx) in filteredArtifacts"
									:id="idx"
									:key="idx"
									padding="0px"
									overflow="auto"
									direction="column"
									:gap="0"
									:background="
										selectedArtifact === artifact ? 'dashboard-background' : 'element-light'
									"
									class="cursor-pointer"
									:data-qa-add-module-name="artifact.name"
								>
									<Container :padding="16" @click="selectModule(artifact)">
										<Container :gap="0" :padding="0">
											<Container padding="0px" :clickable="true">
												<Icon :name="artifact.icon" data-qa-module-cloud-icon />
												<Container :gap="24" :padding="0" align="space-between center" :grow="0">
													<Typography
														type="p1"
														weight="bold"
														class="padding-lt-8"
														:data-qa-module-name="artifact.name"
														>{{ artifact.name }}</Typography
													>
												</Container>
											</Container>
										</Container>
									</Container>
									<Divider />
								</Container>
							</template>
						</Container>
						<Divider direction="vertical" />

						<Wrapper overflow="scroll" height="100%" class="flow-add-scrollbar">
							<PipelineModuleDetails
								v-if="selectedModule"
								:module="selectedModule"
								@add-new-module="toggleAddNewModulePopover()"
							/>
							<ModuleReadOnlyInfo
								v-if="selectedArtifact"
								:pipeline="selectedArtifact"
								:show-close="false"
								@add-new-artifact="artifactSelected"
							/>
						</Wrapper>
					</Container>
				</Container>
			</Wrapper>

			<!-- Add new module popover -->
			<AddNewModulePopOver
				v-else-if="isAddModuleOpen"
				:module="selectedModule"
				:show-back-btn="true"
				:pipeline-module-ref="pipelineModuleRef"
				@back="
					isModuleListOpen = true;
					isAddModuleOpen = false;
				"
				@close="
					isModuleListOpen = false;
					isAddModuleOpen = false;
				"
				@submitted="addModuleToList"
			/>
		</template>
	</PopOver>
</template>
<script lang="ts">
import {
	Accordion,
	Button,
	Card,
	Container,
	Divider,
	Header,
	Icon,
	PopOver,
	SearchInput,
	Tag,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { pipelineModule, pipelineModuleReference } from "@/protocol/pipeline";
import { PipelineModuleToAddRef, pipelineModuleWithRefId } from "@/shared/pipeline-constants";

import { ArtifactMeta } from "../application-integration-store";

import AddNewModulePopOver from "./AddNewModulePopOver.vue";
import ModuleReadOnlyInfo from "./ModuleReadOnlyInfo.vue";
import PipelineModuleDetails from "./PipelineModuleDetails.vue";

export type ModuleItems = Record<string, pipelineModuleWithRefId[]>;

export default defineComponent({
	name: "SelectArtifactModulePopover",

	components: {
		Accordion,
		Button,
		Container,
		Divider,
		Header,
		Icon,
		PopOver,
		SearchInput,
		Typography,
		Wrapper,
		Card,
		PipelineModuleDetails,
		Tag,
		AddNewModulePopOver,
		ModuleReadOnlyInfo
	},

	props: {
		modules: {
			type: Object as PropType<ModuleItems>,
			required: true
		},

		artifacts: {
			type: Array as PropType<ArtifactMeta[]>,
			required: true
		},

		title: {
			type: String,
			required: true
		},

		label: {
			type: String,
			required: true
		},

		disabledTitle: {
			type: String,
			required: true
		},

		dataQa: {
			type: String,
			required: true
		}
	},

	emits: ["artifact-added", "module-added", "close"],

	data: () => ({
		isModuleListOpen: false,
		isAddModuleOpen: false,
		searchText: "",
		closedGroups: {} as Record<string | number, boolean>,
		selectedModule: null as pipelineModuleWithRefId | null,
		selectedArtifact: null as ArtifactMeta | null,
		pipelineModuleRef: null as pipelineModuleReference | null
	}),

	computed: {
		isOpen() {
			return this.isModuleListOpen || this.isAddModuleOpen;
		},

		isItemsEmpty() {
			return Object.keys(this.filteredModules).length
				? Object.keys(this.filteredModules).every(k => this.filteredModules[k]?.length === 0)
				: this.filteredArtifacts.length === 0;
		},

		filteredModules() {
			const filteredModules: ModuleItems = {};
			const searchText = this.searchText.toLowerCase();

			Object.entries(this.modules).forEach(([key, pipelineModules]) => {
				const filteredPipelines = pipelineModules.filter(module_ => {
					return (
						module_.displayName?.toLowerCase().includes(searchText) ??
						module_.category?.includes(searchText)
					);
				});

				if (filteredPipelines.length > 0) {
					filteredModules[key] = filteredPipelines;
				}
			});

			return filteredModules;
		},

		filteredArtifacts() {
			return this.artifacts.filter(artifact => artifact.name.includes(this.searchText));
		}
	},

	watch: {
		isModuleListOpen(val: boolean) {
			if (val) {
				// Set initial module as selected
				const [firstObj] = Object.keys(this.filteredModules)
					.map(k => this.filteredModules[k])
					.flat();

				if (firstObj !== undefined && "moduleRefId" in firstObj) {
					this.selectedModule = firstObj;
				}

				// Set initial artifact as selected
				const [artifact] = this.filteredArtifacts;
				if (artifact) {
					this.selectedArtifact = artifact;
				}
			}
		}
	},

	methods: {
		selectModule(module: pipelineModuleWithRefId | ArtifactMeta) {
			if ("moduleRefId" in module) {
				this.selectedModule = module;
			} else {
				this.selectedArtifact = module;
			}
		},

		hasAddedModules(name: string) {
			return this.modules[name]?.map(a => a).find(a => a.isAdded === true) ? true : false;
		},

		addModuleToList(selectedModule: PipelineModuleToAddRef) {
			const module = this.modules[selectedModule.name]?.find(
				m => m.moduleRefId === selectedModule.moduleRefId
			);
			if (module !== undefined) {
				module.isAdded = true;
				this.$emit("module-added", {
					module,
					moduleValues: selectedModule.moduleValues
				});

				this.pipelineModuleRef = {
					moduleRefId: module.moduleRefId,
					inputs: selectedModule.moduleValues
				};
			}
		},

		toggleAddNewModulePopover() {
			// if the selected item is of type artifact i.e input or output artifact we directly add to the list instead of showing the input form.
			if (this.selectedModule !== null && this.selectedModule.type === "artifact") {
				this.$emit("artifact-added", this.selectedModule);
			} else {
				this.isAddModuleOpen = true;
			}
			this.isModuleListOpen = false;
		},

		getIcon(module: pipelineModule | ArtifactMeta) {
			const moduleIcon =
				(module as pipelineModule).provisioner === "gcp" ||
				(module as pipelineModule).provisioner === "azure"
					? `p-${(module as pipelineModule).provisioner}`
					: `p-aws-dark`;
			return (module as ArtifactMeta).icon ? (module as ArtifactMeta).icon : moduleIcon;
		},

		artifactSelected(artifact: ArtifactMeta) {
			this.$emit("artifact-added", artifact);
			this.isModuleListOpen = false;
		}
	}
});
</script>
