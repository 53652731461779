<template>
	<Wrapper
		v-if="assignedCred"
		width="50px"
		height="50px"
		background="gray-600"
		border-radius="4px"
		:data-qa="`cred-card-modal-target-${credScope}`"
	>
		<InfoPopover @click="$emit('onClick')">
			<EntityCard :icon="isLoading ? 'i-loader' : credIcon" type="default" />
			<template #body>
				<Container :padding="0" direction="column" :gap="8">
					<Typography type="h5" color="dark" transform="capitalize">{{
						credScopeTitle
					}}</Typography>
					<Typography type="h6" color="dark" data-qa="assigned-cred-tooltip-content">{{
						tooltipContent
					}}</Typography>
					<Typography v-if="assignedCred && assignedCred.createdAt" type="p3" color="gray-200">
						Added • {{ changeDateTimeFormat(assignedCred.createdAt) }}
					</Typography>
				</Container>
			</template>
		</InfoPopover>
	</Wrapper>
</template>

<script lang="ts">
import { Typography, Container, Wrapper, InfoPopover } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { CREDENTIAL_TYPE_ICONS } from "@/modules/credentials/constants";
import { entityKind } from "@/modules/credentials/credential-types";
import { projectStore } from "@/modules/project-list/project-store";
import { app } from "@/protocol/app";
import { VGEntities } from "@/protocol/common";
import { Creds, CredScope, project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import EntityCard from "@/shared/components/code-pipes/EntityCard.vue";
import { formatDateToRegularFormat } from "@/utils";

export default defineComponent({
	name: "AssignedCredIcon",
	components: { Typography, Container, Wrapper, EntityCard, InfoPopover },

	props: {
		entity: {
			type: Object as PropType<project | environment>,
			required: true
		},

		entityKind: {
			type: String as PropType<entityKind>,
			required: true
		},

		credScope: {
			type: String as PropType<CredScope>,
			required: true
		},

		isLoading: {
			type: Boolean
		},

		assignedCreds: {
			type: Array as PropType<Creds[]>,
			required: true
		}
	},

	computed: {
		credScopeTitle() {
			return this.credScope === CredScope.docker ? "Container" : this.credScope;
		},

		assignedCred() {
			const [assignedCred] = this.assignedCreds;
			return assignedCred ? assignedCred : null;
		},

		isCredInherited() {
			return this.assignedCred?.inheritedFrom === VGEntities.project;
		},

		tooltipContent() {
			if (!this.assignedCred) {
				return null;
			}
			if (
				this.isCredInherited &&
				(this.entityKind === "environment" || this.entityKind === "app")
			) {
				const projectId = (this.entity as environment | app).projId;
				const localProject = projectStore.projects[projectId];
				return `${this.assignedCred.name} is inherited from project ${localProject?.name}`;
			}
			return this.assignedCred.name;
		},

		credIcon() {
			if (!this.assignedCred?.type) {
				return "";
			}
			return CREDENTIAL_TYPE_ICONS[this.assignedCred.type];
		}
	},

	methods: {
		changeDateTimeFormat(timestamp: string) {
			return timestamp ? formatDateToRegularFormat(Number(timestamp) * 1000) : "";
		}
	}
});
</script>
