<template>
	<Table border="rows" data-qa-dependency-list-table>
		<template #header>
			<TableRow>
				<TableCellHead padding="16px 12px" width="200px">
					<Container :padding="0" :gap="12" overflow="visible">
						<Typography type="p2" color="gray-300">Name</Typography>
						<Icon
							:name="sortDirection === 'asc' ? 'i-arrow-up' : 'i-arrow-down'"
							:state="sortDirection === 'desc' ? 'primary' : 'light'"
							color="gray-300"
							size="x-small"
							data-qa-sort-by-dependency-name
							@click="$emit('update:sort-direction', sortDirection === 'asc' ? 'desc' : 'asc')"
						/>
					</Container>
				</TableCellHead>
				<TableCellHead padding="16px 12px" width="150px">
					<Container :padding="0" :gap="12" overflow="visible">
						<Typography type="p2" color="gray-300">Category</Typography>
						<PopOver
							v-model:open="isFilterOpen"
							placement="right-start"
							:overlay="false"
							class="align-items-center"
							data-qa-dependency-category-filter-popover
						>
							<Icon
								name="i-filter"
								:state="isFilterOpen ? 'primary' : 'light'"
								color="gray-300"
								size="x-small"
								data-qa-filter-by-dependency-category
								@click="isFilterOpen = !isFilterOpen"
							/>
							<template #content>
								<Wrapper border-radius="4px" background="gray-500" :border="true" width="200px">
									<Container
										v-for="category in categories"
										:key="category"
										direction="column"
										:padding="0"
										:gap="0"
										:data-qa-dep-filter-category="category"
									>
										<Container :padding="10">
											<Checkbox
												state="primary"
												:checked="selectedCategories.includes(category ?? '')"
												:data-qa-dependency-list-cateogory-filter="category"
												@click="$emit('select-category', category)"
											/>
											<Typography type="p2">{{ category }}</Typography>
										</Container>
										<Divider :size="1" />
									</Container>
									<Container
										direction="column"
										:padding="10"
										:gap="0"
										class="cursor-pointer"
										data-qa-reset-category-filter-button
										@click="$emit('reset')"
									>
										<Typography type="h5" weight="bold" color="primary">RESET</Typography>
									</Container>
								</Wrapper>
							</template>
						</PopOver>
					</Container>
				</TableCellHead>
				<TableCellHead padding="16px 12px" width="150px">
					<Container :padding="0" :gap="12" overflow="visible">
						<Typography type="p2" color="gray-300">Used</Typography>
					</Container>
				</TableCellHead>
				<TableCellHead padding="16px 12px" width="150px">
					<Container :padding="0" :gap="12" overflow="visible">
						<Typography type="p2" color="gray-300">Last updated</Typography>
					</Container>
				</TableCellHead>
				<TableCellHead padding="16px 12px" width="170px">
					<Container :padding="0" :gap="12" overflow="visible">
						<Typography type="p2" color="gray-300"> </Typography>
					</Container>
				</TableCellHead>
			</TableRow>
		</template>
		<template v-if="loading" #body>
			<LoadingRows v-for="r in loadingRowCount" :key="r" :show-checkbox="false" />
		</template>
		<template v-else-if="sortedDependencyList.length > 0" #body>
			<DependencyRow
				v-for="dependency in sortedDependencyList"
				:key="dependency.name"
				:dependency="dependency"
				@view-details="$emit('toggleDetails', $event)"
			>
			</DependencyRow>
		</template>
	</Table>
</template>
<script lang="ts">
import {
	Checkbox,
	Container,
	Divider,
	Icon,
	PopOver,
	Table,
	TableCellHead,
	TableRow,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import LoadingRows from "@/modules/members/components/LoadingRows.vue";
import { Dependency } from "@/protocol/infra";

import DependencyRow from "./DependencyRow.vue";

export default defineComponent({
	name: "DependencyListTable",

	components: {
		Table,
		TableRow,
		TableCellHead,
		DependencyRow,
		Container,
		Typography,
		Icon,
		LoadingRows,
		PopOver,
		Wrapper,
		Checkbox,
		Divider
	},

	props: {
		loadingRowCount: {
			type: Number,
			default: () => 5
		},

		loading: {
			type: Boolean,
			default: () => false
		},

		sortDirection: {
			type: String,
			required: true
		},

		sortedDependencyList: {
			type: Array as PropType<Dependency[]>,
			required: true
		},

		categories: {
			type: Array as PropType<string[]>,
			required: true
		},

		selectedCategories: {
			type: Array as PropType<string[]>,
			requried: true,
			default: () => []
		}
	},

	data: () => ({
		isFilterOpen: false
	})
});
</script>
