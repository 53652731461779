<template>
	<Wrapper
		v-if="appliedBundle"
		data-qa-bundle-status
		height="auto"
		:background="bundleDeploymentError ? 'gray-500' : 'primary-dark'"
		border-radius="4px"
		overflow="visible"
	>
		<Container align="center" overflow="visible">
			<Icon
				size="small"
				name="i-app"
				type="filled"
				:state="bundleDeploymentError ? 'error' : 'primary'"
				:effects="false"
			/>

			<PopOver
				v-if="bundleDeploymentError"
				:open="showBundleError"
				@overlay-click="showBundleError = false"
			>
				<Typography>
					<template v-if="hasTriedDeployingBundle">
						Bundle deployment has failed.
						<Typography data-qa-bundle-status-project-name inline weight="bold">{{
							` ${entity?.name ?? ""}`
						}}</Typography>
						could not be deployed.
					</template>
					<template v-else
						>Failed to apply bundle in
						<Typography data-qa-bundle-status-project-name inline weight="bold">{{
							` ${entity?.name ?? ""}`
						}}</Typography
						>.
					</template>

					<Typography
						link
						inline
						color="error"
						weight="bold"
						data-qa-bundle-error-learn-more
						@click="showBundleError = true"
						>Learn more</Typography
					>
				</Typography>

				<!-- error dialog content -->
				<template #content>
					<Wrapper border-radius="4px" width="432px" background="gray-500">
						<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
							<Header>
								<Container align="space-between center" :padding="0" :grow="1">
									<Container :gap="12" :padding="0">
										<Typography
											v-if="hasTriedDeployingBundle"
											type="h4"
											color="dark"
											data-qa-bundle-deployment-error-dialog-title
											>Deployment failed</Typography
										>
										<Typography
											v-else
											type="h4"
											color="dark"
											data-qa-bundle-deployment-error-dialog-title
											>Bundle apply failed</Typography
										>
									</Container>
									<Icon
										name="i-close"
										type="filled"
										size="x-small"
										data-qa-env-wid-add-variable-close
										@click.stop="showBundleError = false"
									/>
								</Container>
							</Header>

							<Container align="left top">
								<Icon name="i-alert" size="small" state="error" :effects="false" />
								<Container :padding="0" :grow="1" direction="column">
									<Typography
										data-qa-bundle-deployment-error-dialog-text
										type="h5"
										weight="regular"
										color="danger-100"
										family="logs"
										>{{ bundleDeploymentError }}</Typography
									>
								</Container>
								<Button
									data-qa-bundle-deployment-error-dialog-retry
									type="default"
									state="icon"
									size="small"
									@click="copyErrorToClipboard"
								>
									<Icon
										v-tooltip="{ content: 'Copy to clipboard' }"
										:name="errorCopied ? 'i-tick' : 'i-copy'"
										size="x-small"
									/>
								</Button>
							</Container>

							<Footer v-if="hasTriedDeployingBundle">
								<Container direction="column" :grow="1" :gap="0" :padding="0">
									<Button
										state="full"
										data-qa-retry-deploy-bundle
										:disabled="isAttemptingBundleDeploy"
										@click="deployBundle"
									>
										<span>Retry Deployment</span>
									</Button>
								</Container>
							</Footer>
						</Container>
					</Wrapper>
				</template>
			</PopOver>

			<Typography v-else
				>Bundle added to
				<Pictogram v-if="emoji" type="transparent" size="xx-small">{{ emoji }}</Pictogram>
				<Typography data-qa-bundle-status-project-name inline weight="bold">{{
					` ${entity?.name ?? ""}`
				}}</Typography
				>. Deploying this bundle will run deployment pipelines for all its environments and apps.
			</Typography>

			<Container align="right center" :padding="0" :grow="1" overflow="visible">
				<DropdownWithStatus
					data-qa-bundle-entity-info
					:value="entityDeploymentCurrentState"
					:options="entityDeploymentOptions"
				/>

				<Button
					v-if="appliedBundle?.bundleRequest"
					data-qa-deploy-bundle
					:loading="isAttemptingBundleDeploy"
					:disabled="isAttemptingBundleDeploy"
					@click="deployBundle"
					>Deploy Bundle</Button
				>
			</Container>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Header,
	Footer,
	Button,
	Container,
	Icon,
	Pictogram,
	Typography,
	PopOver,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import {
	bundleStore,
	getBundleEntityDeploymentStatus,
	getBundleErrors
} from "@/modules/bundle/bundle-store";
import { orgStore } from "@/modules/org/org-store";
import { project as ProjectProto } from "@/protocol/identity";
import DropdownWithStatus, {
	DropdownWithStatusCurrentState,
	DropdownWithStatusOption
} from "@/shared/components/DropdownWithStatus.vue";
import { captureError, getEmoji, getErrorMessage } from "@/utils";

export default defineComponent({
	name: "BundleStatus",

	components: {
		Button,
		Header,
		Footer,
		Icon,
		Pictogram,
		Container,
		Typography,
		DropdownWithStatus,
		PopOver,
		Wrapper
	},

	props: {
		project: {
			type: Object as PropType<ProjectProto | null>,
			default: null
		}
	},

	data: () => ({
		isWaitingForBundleResponse: false,
		deployBundleError: null as string | null,
		showBundleError: false,
		errorCopied: false,
		hasTriedDeployingBundle: false
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		org() {
			return orgStore.orgs.find(org => org.id === this.orgId);
		},

		emoji() {
			return getEmoji(this.project);
		},

		entity() {
			return this.project ?? this.org;
		},

		appliedBundle() {
			return bundleStore.appliedBundles[this.orgId];
		},

		isAttemptingBundleDeploy() {
			if (this.isWaitingForBundleResponse) {
				return true;
			}

			const { appliedBundle } = this;
			return appliedBundle ? appliedBundle.installationStatus.progress !== 7 : false;
		},

		hasBundleFailures(): boolean {
			let hasFailures = false;

			this.entityDeploymentOptions.forEach(option => {
				if (option.state === "error") {
					hasFailures = true;
				}
			});

			return hasFailures;
		},

		entityDeploymentCurrentState(): DropdownWithStatusCurrentState {
			const totalEntities = this.entityDeploymentOptions.length;

			if (!this.isAttemptingBundleDeploy) {
				if (this.hasBundleFailures) {
					return this.hasTriedDeployingBundle
						? {
								label: "Bundle deployment failed",
								state: "error"
						  }
						: {
								label: "Failed to apply bundle",
								state: "error"
						  };
				} else {
					return {
						label: `${totalEntities} entities to deploy`,
						state: "default"
					};
				}
			}

			return {
				state: this.hasBundleFailures ? "error" : "running",
				label: `Deploying ${totalEntities} entities`
			};
		},

		entityDeploymentOptions() {
			const entities: DropdownWithStatusOption[] = [];

			this.appliedBundle?.installation.environments?.forEach(envStatus => {
				entities.push({
					shape: "hexagon",
					label: envStatus.name ?? "",
					state: getBundleEntityDeploymentStatus(envStatus)
				});
			});

			this.appliedBundle?.installation.deployments?.forEach(depStatus => {
				entities.push({
					shape: "squircle",
					label: depStatus.name ?? "",
					state: getBundleEntityDeploymentStatus(depStatus)
				});
			});

			return entities;
		},

		bundleDeploymentError() {
			if (this.deployBundleError) {
				return this.deployBundleError;
			}

			return this.appliedBundle?.installation
				? getBundleErrors(this.appliedBundle.installation).join("\n\n")
				: null;
		},

		bundleAppliedFor() {
			return bundleStore.bundleAppliedFor;
		}
	},

	mounted() {
		if (this.project?.id) {
			bundleStore.FETCH_AND_WATCH_LATEST_BUNDLE({
				projId: this.project.id,
				orgId: this.orgId
			});
		} else {
			bundleStore.FETCH_AND_WATCH_LATEST_BUNDLE({
				orgId: this.orgId
			});
		}
	},

	methods: {
		async deployBundle() {
			try {
				this.hasTriedDeployingBundle = true;
				this.deployBundleError = null;
				this.errorCopied = false;
				this.showBundleError = false;
				this.isWaitingForBundleResponse = true;

				const { appliedBundle } = this;

				if (!appliedBundle?.bundleRequest) {
					throw new Error("No bundle found for project");
				}

				await bundleStore.APPLY_BUNDLE({
					shouldDeploy: true,
					shouldUpdateConfig: false,
					bundleRequest: appliedBundle.bundleRequest,
					bundleAppliedFor: this.bundleAppliedFor
				});
			} catch (err) {
				captureError(err);
				this.deployBundleError = getErrorMessage(err);
			} finally {
				this.isWaitingForBundleResponse = false;
			}
		},

		async copyErrorToClipboard() {
			if (!this.bundleDeploymentError) {
				return;
			}

			await navigator.clipboard.writeText(this.bundleDeploymentError);

			this.errorCopied = true;
		}
	}
});
</script>

<style lang="scss">
[data-qa-bundle-status] {
	div.flow-popover {
		height: auto;
	}
	[data-family="logs"] {
		white-space: pre-wrap;
	}
}
</style>
