<template>
	<Card background="element-light">
		<template #header>
			<Container overflow="visible" align="space-between center" padding="8px 12px">
				<Container
					v-tooltip="{
						content: name
					}"
					:padding="0"
				>
					<Pictogram
						shape="squircle"
						state="border"
						:border-state="pictogramBorderState"
						:loading="isLoading"
					>
						<Typography type="h5" weight="bold" color="dark">{{
							emoji ? emoji : shortCode
						}}</Typography>
					</Pictogram>
					<Typography
						type="h5"
						color="gray-100"
						weight="medium"
						overflow="ellipsis"
						:data-qa-deployment-name="name"
						>{{ name }}</Typography
					>
				</Container>
				<PopOver
					v-if="!isLoading"
					:open="openOptions"
					placement="left-start"
					@overlay-click="openOptions = false"
				>
					<!--Trigger-->
					<Icon
						v-tooltip="{
							content: 'Deployment options'
						}"
						name="i-more"
						size="small"
						color="gray-200"
						data-qa-deployment-action
						@click="openOptions = !openOptions"
					/>
					<template #content>
						<Wrapper border-radius="4px" background="element-light" :border="true" width="255px">
							<Container direction="column" :padding="0" :gap="0">
								<Slab
									size="large"
									type="transparent"
									:effects="true"
									class="cursor-pointer"
									data-qa-field="edit-deployment"
									@click="edit"
								>
									<template #primary-action>
										<Icon name="i-setting" color="gray-200" size="small" :effects="false" />
									</template>
									<Typography type="h5" weight="regular">Edit Deployment</Typography>
								</Slab>
								<Slab
									size="large"
									type="transparent"
									:effects="true"
									class="cursor-pointer"
									data-qa-field="redeploy-deployment"
									@click="deploy"
								>
									<template #primary-action>
										<Icon name="i-launch" color="gray-200" size="small" :effects="false" />
									</template>
									<Typography type="h5" weight="regular">{{
										isJobDone ? "Re-deploy" : "Deploy"
									}}</Typography>
								</Slab>
								<Slab
									v-if="isDeployed"
									size="large"
									type="transparent"
									:effects="true"
									class="cursor-pointer"
									data-qa-field="undeploy-deployment"
									@click="undeploy"
								>
									<template #primary-action>
										<Icon name="i-dev" color="danger-200" size="small" :effects="false" />
									</template>
									<Typography type="h5" weight="regular">Un-deploy</Typography>
								</Slab>
								<Slab
									v-tooltip="
										isDeployed
											? {
													content: 'Undeploy the application before removing it'
											  }
											: null
									"
									size="large"
									type="transparent"
									:effects="true"
									:disabled="isDeployed"
									class="cursor-pointer"
									data-qa-field="remove-deployment"
									@click="remove"
								>
									<template #primary-action>
										<Icon name="i-delete" size="small" :effects="false" />
									</template>
									<Typography type="h5" weight="regular" color="danger-200"
										>Remove deployment</Typography
									>
								</Slab>
							</Container>
						</Wrapper>
					</template>
				</PopOver>
			</Container>
		</template>
		<Container :padding="8">
			<Table border="none">
				<template #body>
					<TableRow v-for="row in details" :key="row.key" :hover="false">
						<TableCell padding="8px 0 8px 8px">
							<Typography type="p2" color="gray-200" :data-qa-deployment-row-name="row.key">
								<Icon
									v-if="row.icon"
									:name="row.icon"
									color="gray-200"
									size="x-small"
									:padding="10"
									:effects="false"
								/>
								{{ row.key }}
							</Typography>
						</TableCell>
						<TableCell padding="8px 0 8px 8px" max-width="200px">
							<Typography
								v-tooltip="{
									content: row.value.text
								}"
								type="p2"
								:color="row.value.isLink ? 'primary-300' : 'gray-100'"
								:link="row.value.isLink"
								overflow="breakall"
								:data-qa-deployment-row-value="row.value.text"
							>
								<a
									v-if="row.value.isLink"
									class="fc-primary"
									rel="noopener"
									:href="row.value.text"
									target="_blank"
									>{{ row.value.text }}</a
								>
								<template v-else>{{ row.value.text }}</template>
							</Typography>
						</TableCell>
					</TableRow>
				</template>
			</Table>
		</Container>

		<f-divider />
		<Container
			v-if="outputs && Object.keys(outputs).length > 0"
			direction="column"
			overflow="visible"
			align="space-between center"
			padding="4px 12px"
			:gap="0"
		>
			<Container align="left" :gap="0" padding="8px">
				<Typography
					type="h5"
					color="gray-100"
					weight="medium"
					overflow="ellipsis"
					:data-qa-deployment-name="name"
					>Outputs</Typography
				>
			</Container>
			<Table border="none">
				<template #body>
					<TableRow v-for="(value, key) in outputs" :key="key" :hover="false">
						<TableCell padding="8px 0 8px 8px" width="127px">
							<Typography type="p2" color="gray-200" :data-qa-deployment-output-row-name="key">
								{{ key }}
							</Typography>
						</TableCell>
						<TableCell padding="8px 0 8px 8px" max-width="200px">
							<Typography
								v-tooltip="{
									content: value
								}"
								type="p2"
								:color="isLink(value) ? 'primary-300' : 'gray-100'"
								:link="isLink(value)"
								overflow="breakall"
								:data-qa-deployment-row-value="value"
							>
								<a
									v-if="isLink(value)"
									class="fc-primary"
									rel="noopener"
									:href="value"
									target="_blank"
									>{{ value }}</a
								>
								<template v-else>{{ value }}</template>
							</Typography>
						</TableCell>
					</TableRow>
				</template>
			</Table>
		</Container>
		<Container v-else padding="12px">
			<Typography type="p3" color="gray-300" data-qa-deployment-output-status>
				No outputs available
			</Typography>
		</Container>

		<template #footer>
			<Container align="space-between center">
				<CustomTimeStamp
					v-if="statusText.time"
					:time="statusText.time"
					:prefix="statusText.text"
					data-qa="deployment-status"
				/>
				<Typography v-else type="p3" color="gray-300" data-qa-deployment-status>{{
					statusText.text
				}}</Typography>
				<Button
					v-if="!status"
					size="small"
					type="success"
					data-qa-deploy-application
					@click="$emit('deploy')"
				>
					<Icon name="i-launch" size="x-small" :effects="false" />
					deploy
				</Button>

				<Container v-else-if="!isJobDone" :padding="0">
					<Button
						data-qa-view-deployment-logs
						type="default"
						state="outlined"
						size="small"
						@click="$emit('view-logs')"
					>
						VIEW LOGS
					</Button>
					<PopOver :open="showConfirmCancel">
						<Button
							type="error"
							state="outlined"
							size="small"
							:disabled="status === 'canceling'"
							@click="() => (showConfirmCancel = true)"
							>Cancel</Button
						>
						<template #content>
							<ConfirmCancel
								data-qa-cancel-deployment
								:name="name"
								:environment="environment"
								@confirm="confirmCancel"
								@close="() => (showConfirmCancel = false)"
							/>
						</template>
					</PopOver>
				</Container>

				<Button
					v-else-if="status"
					data-qa-view-deployment-logs
					type="default"
					state="outlined"
					size="small"
					@click="$emit('view-logs')"
					>VIEW LOGS</Button
				>
			</Container>
		</template>
	</Card>
</template>
<script lang="ts">
import {
	Card,
	Container,
	Typography,
	Pictogram,
	Icon,
	Button,
	Table,
	TableRow,
	TableCell,
	PopOver,
	Wrapper,
	Slab
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { CustomTimeStamp } from "@/shared/components";
import {
	JOB_STATUS,
	JOB_STEP_STATUS,
	PipelineJobStatus,
	PIPELINE_BAD_STATUSES,
	PIPELINE_FINISHED_JOB_STATUSES,
	PIPELINE_UNFINISHED_JOB_STATUSES
} from "@/shared/pipeline-constants";
import { getShortCode, isLink } from "@/utils";
import { KeyValue } from "@/utils/get-artifact-details";

import ConfirmCancel from "./ConfirmCancel.vue";

export default defineComponent({
	name: "DeploymentCard",

	components: {
		Card,
		Container,
		CustomTimeStamp,
		Typography,
		Pictogram,
		Icon,
		Table,
		TableRow,
		TableCell,
		Button,
		PopOver,
		Wrapper,
		Slab,
		ConfirmCancel
	},

	props: {
		/**
		 * Name of deployment
		 */
		name: {
			type: String,
			required: true
		},

		/**
		 * emoji if present
		 */
		emoji: {
			type: String
		},

		/**
		 * details of deployment to display in tabular structure
		 */
		details: {
			type: Array as PropType<KeyValue[]>
		},

		/**
		 * outputs summary of deployment to display in tabular structure
		 */
		outputs: {
			type: Object as PropType<Record<string, unknown>>
		},

		/**
		 * status of deployment
		 */

		status: {
			type: String as PropType<PipelineJobStatus>
		},

		/**
		 * suffix date string will be added after `Deployed` statusText. <br/>
		 *
		 * Note : Used when status is 'deployed'
		 */
		deployedDate: {
			type: String
		},

		/**
		 * returns hsort code when emoji not present
		 */

		/**
		 * Env Name of deployment
		 */
		environment: {
			type: String,
			required: true
		},

		/**
		 * Env Name of deployment
		 */
		isDeployed: {
			type: Boolean,
			required: true
		}
	},

	emits: ["deploy", "view-logs", "cancel", "undeploy", "edit", "remove"],

	data: () => ({
		openOptions: false,
		showConfirmCancel: false
	}),

	computed: {
		isJobDone() {
			return this.status ? PIPELINE_FINISHED_JOB_STATUSES.includes(this.status) : false;
		},

		shortCode() {
			return getShortCode(this.name);
		},

		/**
		 * Display status in form of descriptive text
		 */
		statusText() {
			if (!this.status) {
				return { text: "Not deployed yet", time: "" };
			}

			// App was undeployed
			if (this.status === JOB_STATUS.DONE && !this.isDeployed) {
				return { text: "Undeployed", time: this.deployedDate ?? this.deployedDate };
			}

			if (PIPELINE_FINISHED_JOB_STATUSES.includes(this.status)) {
				return { text: JOB_STEP_STATUS[this.status], time: this.deployedDate ?? this.deployedDate };
			}

			return { text: JOB_STEP_STATUS[this.status], time: "" };
		},

		pictogramBorderState() {
			if (this.status === JOB_STATUS.DONE && this.isDeployed) {
				return "success";
			}

			if (this.status && PIPELINE_BAD_STATUSES.includes(this.status)) {
				return "error";
			}

			return "default";
		},

		isLoading() {
			if (!this.status) {
				return false;
			}

			return PIPELINE_UNFINISHED_JOB_STATUSES.includes(this.status);
		}
	},

	methods: {
		isLink,

		confirmCancel() {
			this.$emit("cancel");
			this.showConfirmCancel = false;
		},

		undeploy() {
			this.openOptions = false;
			this.$emit("undeploy");
		},

		deploy() {
			this.openOptions = false;
			this.$emit("deploy");
		},

		edit() {
			this.openOptions = false;
			this.$emit("edit");
		},

		remove() {
			this.openOptions = false;
			this.$emit("remove");
		}
	}
});
</script>
