<template>
	<Wrapper
		width="50px"
		height="50px"
		:background="background"
		border-radius="4px"
		:style="{ flexShrink: '0' }"
	>
		<Card :type="cardType" :disabled="disabled" :clickable="true">
			<Container :padding="16" align="center center" :shrink="0" class="height-100-per">
				<Icon
					:name="icon"
					size="20px"
					:color="iconColor ?? undefined"
					:effects="false"
					:animate="icon.includes('i-loader')"
				/>
			</Container>
		</Card>
	</Wrapper>
</template>
<script lang="ts">
import { Wrapper, Card, Container, Icon } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "EntityCard",

	components: {
		Wrapper,
		Card,
		Container,
		Icon
	},

	props: {
		disabled: {
			type: Boolean,
			default: () => null
		},

		icon: {
			type: String,
			required: true
		},

		type: {
			type: String as PropType<EntityCardType>,
			default: () => "default"
		}
	},

	computed: {
		iconColor() {
			if (this.icon === "i-shield") {
				return "color5-300";
			} else if (this.icon === "i-more") {
				return "gray-200";
			}

			return null;
		},

		cardType() {
			return this.type === "empty" ? "placeholder" : "outlined";
		},

		background() {
			return this.type === "empty" ? "gray-600" : "gray-500";
		}
	}
});

export type EntityCardType = "default" | "empty";
</script>
