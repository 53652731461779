import { Router, RouteRecordRaw } from "vue-router";

import CredentialsWrapper from "@/modules/credentials/views/CredentialsWrapper.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/org/:orgId/credentials",
		name: "credentials-global",
		component: CredentialsWrapper,
		meta: {
			title: "Credentials",
			requiresAuth: true,
			reuse: false
		},
		props: true
	}
];

export function registerCredentialRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
