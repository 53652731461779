<template>
	<Wrapper height="42px">
		<Container align="left center" padding="0 14px" :grow="1">
			<Typography
				type="p2"
				weight="bold"
				color="dark"
				transform="uppercase"
				data-qa-pipeline-name
				>{{ pipelineName }}</Typography
			>

			<f-icon
				source="i-info-fill"
				data-qa-pipeline-info
				tooltip="#pipeline-header-tooltip"
			></f-icon>

			<f-tooltip id="pipeline-header-tooltip">
				<f-div direction="column" gap="large" data-qa-pipeline-info-tooltip>
					<CustomTimeStamp
						v-if="jobStart"
						:time="jobStart"
						prefix="Started"
						:postfix="` (${formatDateToRegularFormat(normalizeUnixTime(jobStart))})`"
						:user="startedBy ?? startedBy"
						data-qa="env-widget-pipline-updated-date"
					/>

					<Typography
						v-if="resourceChanges"
						type="p2"
						color="light"
						weight="medium"
						overflow="nowrap"
					>
						<Typography type="p2" color="dark" weight="medium" :inline="true">Plan</Typography>:
						<Typography type="p2" color="success-200" :inline="true"
							>{{ resourceChanges.create }} create</Typography
						>
						|
						<Typography type="p2" color="primary-200" :inline="true"
							>{{ resourceChanges.update }} update</Typography
						>
						|
						<Typography type="p2" color="danger-200" :inline="true"
							>{{ resourceChanges.delete }} delete</Typography
						>
					</Typography>
				</f-div>
			</f-tooltip>
		</Container>
	</Wrapper>
</template>
<script lang="ts">
import { Container, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { resourceChanges } from "@/protocol/infra";
import { CustomTimeStamp } from "@/shared/components";
import { formatDateToRegularFormat, normalizeUnixTime } from "@/utils";

export default defineComponent({
	name: "PipelineHeader",

	components: {
		Wrapper,
		CustomTimeStamp,
		Container,
		Typography
	},

	props: {
		pipelineName: {
			type: String,
			required: true
		},

		resourceChanges: {
			type: Object as PropType<resourceChanges>
		},

		jobStart: {
			type: String
		},

		startedBy: String
	},

	methods: {
		formatDateToRegularFormat,
		normalizeUnixTime
	}
});
</script>
