<template>
	<PopOver
		placement="right-start"
		:open="showModel"
		class="align-items-center"
		@overlay-click="closeProjectAssignModal"
	>
		<Button
			v-tooltip="{
				content: 'Assign credentials',
				placement: 'right'
			}"
			type="primary"
			state="icon"
			size="small"
			data-qa-project-actions-btn
			@click="showModel = true"
		>
			<Icon name="i-plus" type="filled" size="small" :effects="false" />
		</Button>
		<template #content>
			<Wrapper
				v-if="!openAssignCredWithScope"
				border-radius="4px"
				background="element-light"
				:border="true"
				width="352px"
				data-qa-project-actions-list
			>
				<Container direction="column" :padding="0" :gap="0">
					<Header>
						<Typography type="h4" color="dark"> Actions </Typography>
						<Icon name="i-close" type="filled" size="x-small" @click="showModel = false" />
					</Header>

					<Slab
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-assign-cloud-creds-action-btn
						@click="openAssignCredWithScope = credScope.cloud"
					>
						<template #primary-action>
							<Icon name="i-cloud" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default">
							Assign cloud credential
						</Typography>
						<template #secondary-actions>
							<Icon
								name="i-chevron-right"
								size="small"
								data-qa-terraform-go-back-btn
								align="space-between right"
							/>
						</template>
					</Slab>

					<Container :padding="0">
						<Divider />
					</Container>
					<Slab
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-assign-git-creds-action-btn
						@click="openAssignCredWithScope = credScope.git"
					>
						<template #primary-action>
							<Icon name="i-git" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default">
							Assign git credential
						</Typography>
						<template #secondary-actions>
							<Icon
								name="i-chevron-right"
								size="small"
								data-qa-terraform-go-back-btn
								align="space-between right"
							/>
						</template>
					</Slab>

					<Container :padding="0">
						<Divider />
					</Container>

					<Slab
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						width="100%"
						data-qa-assign-container-creds-action-btn
						@click="openAssignCredWithScope = credScope.docker"
					>
						<template #primary-action>
							<Icon name="i-box" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default">
							Assign container credential
						</Typography>
						<template #secondary-actions>
							<Icon
								name="i-chevron-right"
								size="small"
								data-qa-terraform-go-back-btn
								align="space-between right"
							/>
						</template>
					</Slab>
				</Container>
			</Wrapper>

			<!-- github credential assign and unassign -->
			<AssignCredentialList
				v-else
				ref="AssignCredentialList"
				:entity="project"
				:git-form-ref="gitFormRef"
				:show-back-button="true"
				entity-kind="project"
				:cred-scope="openAssignCredWithScope"
				@on-close="closeProjectAssignModal"
				@force-close="forceClose"
				@back-button-action="openAssignCredWithScope = null"
				@on-credential-assigned="$emit('onCredentialAssigned')"
			/>
		</template>
	</PopOver>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Header,
	Icon,
	PopOver,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import AssignCredentialList from "@/modules/credentials/components/credential-assign/AssignCredentialList.vue";
import { CredScope, project } from "@/protocol/identity";
import { GithubOauthStorageService } from "@/services/storage-service";

export default defineComponent({
	name: "AssignCredentialMenuOptions",

	components: {
		AssignCredentialList,
		Typography,
		Wrapper,
		PopOver,
		Button,
		Icon,
		Header,
		Divider,
		Slab,
		Container
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		}
	},

	data: () => ({
		credScope: CredScope,
		gitFormRef: "projectAssignCredGitForm",
		showModel: false,
		openAssignCredWithScope: null as null | CredScope.git | CredScope.docker | CredScope.cloud
	}),

	mounted() {
		this.githubModalObserver();
	},

	methods: {
		forceClose() {
			this.openAssignCredWithScope = null;
			this.showModel = false;
		},

		closeProjectAssignModal() {
			if (this.openAssignCredWithScope) {
				const ref = this.$refs.AssignCredentialList as InstanceType<typeof AssignCredentialList>;
				if (ref) {
					ref.safelyCloseModal();
				}
			} else {
				this.forceClose();
			}
		},

		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === this.gitFormRef) {
				// render github form
				this.openAssignCredWithScope = CredScope.git;
				this.showModel = true;
			}
		}
	}
});
</script>
