<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		width="320px"
		max-height="80vh"
		data-qa-org-switcher-popover
	>
		<Header>
			<Container :grow="1" :padding="0" align="space-between center">
				<Typography type="h4">Organizations</Typography>
				<!-- <Icon name="i-search" size="small" type="filled" state="light" /> -->
			</Container>
		</Header>
		<Divider />
		<Container direction="column" padding="8px 0px" :gap="0" overflow="auto" align="left top">
			<Container :shrink="0" padding="8px 16px">
				<Typography type="h6" weight="regular" color="light" transform="uppercase"
					>Personal Organizations</Typography
				>
			</Container>

			<template v-for="org in adminOrgs" :key="org.id">
				<Container
					:shrink="0"
					padding="8px 16px"
					:gap="12"
					:clickable="true"
					:data-qa-owner-org="org.name"
					@click="switchOrganization(org)"
				>
					<Pictogram
						state="avatar"
						:label="org.orgInitial"
						shape="circle"
						size="m"
						:effects="true"
					/>
					<Typography
						type="h4"
						weight="regular"
						overflow="ellipsis"
						:data-qa="`org-item-${org.name}`"
						>{{ org.name }}</Typography
					>
					<Icon
						v-if="activeOrgId === org.id"
						class="margin-lt-auto"
						name="i-tick"
						state="success"
						type="filled"
						:data-qa-selected-org="org.id"
						size="small"
					/>
				</Container>
			</template>

			<Container :shrink="0" padding="8px 16px">
				<Typography type="h6" weight="regular" color="light" transform="uppercase"
					>Organizations</Typography
				>
			</Container>
			<template v-for="org in nonAdminOrgs" :key="org.id">
				<Container
					:shrink="0"
					padding="8px 16px"
					:gap="12"
					:clickable="true"
					:data-qa-non-owner-org="org.id"
					@click="switchOrganization(org)"
				>
					<Pictogram
						state="avatar"
						:label="org.orgInitial"
						shape="circle"
						size="m"
						:effects="true"
					/>
					<Typography
						type="h4"
						weight="regular"
						overflow="ellipsis"
						:data-qa="`org-item-${org.name}`"
						>{{ org.name }}</Typography
					>
					<Icon
						v-if="activeOrgId === org.id"
						class="margin-lt-auto"
						name="i-tick"
						state="success"
						type="filled"
						:data-qa-selected-org="org.id"
						size="small"
					/>
				</Container>
			</template>
			<Container
				:shrink="0"
				padding="8px 16px"
				:gap="12"
				:clickable="true"
				data-qa-create-org-popover-open-btn
				@click="$emit('create-org')"
			>
				<Pictogram state="primary" shape="circle" size="m" :effects="true">
					<Icon name="i-plus" type="filled" state="black" :effects="false" size="small" />
				</Pictogram>
				<Typography type="h4" weight="regular" overflow="ellipsis" color="primary" :link="true"
					>Create organization</Typography
				>
			</Container>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Icon,
	Wrapper,
	Container,
	Typography,
	Header,
	Divider,
	Pictogram
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { userStore } from "@/modules/user/user-store";
import { organization } from "@/protocol/identity";

type Org = organization & { formatedName: string; orgInitial: string };
export default defineComponent({
	name: "OrgList",

	components: {
		Icon,
		Wrapper,
		Container,
		Typography,
		Header,
		Divider,
		Pictogram
	},

	props: {
		activeOrgId: {
			type: String,
			required: true
		},

		orgs: {
			type: Array as PropType<Array<Org>>,
			required: true
		}
	},

	emits: {
		"create-org": null,

		"switch-org": (org: Org) => {
			return Boolean(org.id);
		}
	},

	computed: {
		currentUser() {
			return userStore.profile;
		},

		adminOrgs(): Org[] {
			return this.orgs
				.filter(org => org.id === this.currentUser?.id)
				.sort((org1, org2) => {
					return org1.name.toLocaleLowerCase().localeCompare(org2.name.toLocaleLowerCase());
				});
		},

		nonAdminOrgs(): Org[] {
			return this.orgs
				.filter(org => org.id !== this.currentUser?.id)
				.sort((org1, org2) => {
					return org1.name.toLocaleLowerCase().localeCompare(org2.name.toLocaleLowerCase());
				});
		}
	},

	methods: {
		switchOrganization(org: Org) {
			// this.switchOrg(org);
			this.$emit("switch-org", org);
		}
	}
});
</script>
