<template>
	<div />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { GithubOauthStorageService } from "@/services/storage-service";

export default defineComponent({
	name: "GithubOauthRouteListener",

	mounted() {
		const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();

		if (!githubOauthResult.isUserOauthActive) {
			return false;
		}

		const idpCode = this.$route.query.code as string;
		const idpError = this.$route.query.error as string;

		if (idpCode || idpError) {
			GithubOauthStorageService.setGithubUserOauth({
				...githubOauthResult,
				idpCode,
				idpError
			});
			/**
			 * removes the query params from the URL
			 */
			// @ts-expect-error
			this.$router.replace({ query: null });
		}
	}
});
</script>
