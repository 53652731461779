<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		max-height="500px"
	>
		<Header>
			<Typography type="h4" color="dark" data-qa-confirm-pipeline-title>
				{{ confirmMeta.title }}
			</Typography>
			<Icon
				size="x-small"
				type="filled"
				name="i-close"
				data-qa-confirm-pipeline-close-btn
				@click="close"
			/>
		</Header>

		<Container
			class="flow-add-scrollbar"
			padding="0 0 12px 0"
			align="left top"
			direction="column"
			:gap="0"
			overflow="scroll"
		>
			<Container
				align="left top"
				padding="0 16px 16px 16px"
				direction="column"
				overflow="visible"
				:gap="20"
			>
				<Typography type="p2" color="dark" data-qa-confirm-pipeline-desc>
					{{ confirmMeta.description }}
				</Typography>

				<Divider />

				<f-form-builder
					data-qa-select-log-verbosity
					:field.prop="formFields"
					:values.prop="verbosityFormValues"
					@state-change="verbosityFormState = $event.detail"
					@input="handleInput"
				/>
			</Container>
		</Container>

		<!-- Show error if any -->
		<Container v-if="errorMessage" direction="column" padding="8px 15px 15px 15px" :gap="0">
			<Typography type="p2" color="error" data-qa-confirm-pipeline-error-msg>
				{{ errorMessage }}
			</Typography>
		</Container>

		<Footer>
			<Button
				state="full"
				:loading="isSubmitting"
				:type="confirmMeta.buttonType"
				:disabled="!verbosityFormState?.isValid"
				data-qa-confirm-pipeline-submit-btn
				@click="confirm"
				>{{ confirmMeta.buttonText }}</Button
			>
		</Footer>
	</Wrapper>
</template>

<script lang="ts">
import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import {
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { footerContent } from "@/modules/env-list/components/env-widget/env-footer/footerContent";
import { newEnvPipelineStore } from "@/modules/env-pipeline/env-pipeline-store";
import { GitRevisionOverride } from "@/protocol/common";
import { actionType, environment, tfLogLevel } from "@/protocol/infra";
import { EnvCreateStepFlowService } from "@/services/storage-service";
import {
	BRANCH_GIT_REVISION_OPTION,
	GIT_REVISION_OPTIONS,
	GitRevisionOption
} from "@/shared/constants";
import { captureError, getErrorMessage, getRelativeTime } from "@/utils";

import { ConfirmPipelineMeta } from "../constants";
import { getSortedEnvJobs } from "../env-pipeline-store";

type LogLevelInput = FSelectOptionObject & {
	data: { id: tfLogLevel };
};

const ErrorInput: LogLevelInput = {
	data: { id: tfLogLevel.error },
	title: "Error"
};

export default defineComponent({
	name: "ConfirmPipeline",

	components: {
		Wrapper,
		Container,
		Divider,
		Typography,
		Button,
		Footer,
		Header,
		Icon
	},

	props: {
		confirmMeta: {
			type: Object as PropType<ConfirmPipelineMeta>,
			required: true
		},

		action: {
			type: String as PropType<actionType>,
			required: true
		},

		env: {
			type: Object as PropType<environment>,
			required: true
		}
	},

	emits: ["close", "toast"],

	data() {
		const gitRevision = this.env.revision;

		const revisionType =
			GIT_REVISION_OPTIONS.find(option => option.data.id === gitRevision?.type) ??
			BRANCH_GIT_REVISION_OPTION;

		const revisionIdentifier = gitRevision?.identifier ?? "main";

		return {
			verbosityFormState: null as FormBuilderState | null,
			verbosityFormValues: {
				revisionType,
				revisionIdentifier,
				logVerbosity: ErrorInput
			} as FormValues,

			isSubmitting: false,
			errorMessage: ""
		};
	},

	computed: {
		currentJob() {
			const [currentJob] = getSortedEnvJobs(this.env.id, this.action);
			return currentJob ?? null;
		},

		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					revisionType: {
						type: "select",
						qaId: "revisionType",
						label: { title: "Revision type" },
						placeholder: "Select revision type",
						options: GIT_REVISION_OPTIONS,
						validationRules: [{ name: "required" }]
					},

					revisionIdentifier: {
						type: "text",
						qaId: "revisionIdentifier",
						placeholder: "Enter base branch",
						label: {
							title: "Base branch"
						},

						validationRules: [{ name: "required" }]
					},

					logVerbosity: {
						qaId: "logLevel",
						type: "select",
						label: {
							title: "Log level",
							iconTooltip:
								"By default, Code Pipes displays Terraform log messages at the Error (ERROR) level. If you would like to display log messages at a different level, choose one of the other options in the drop down."
						},

						validationRules: [
							{
								name: "required"
							}
						],

						options: [
							{ data: { id: tfLogLevel.default }, title: "Default" },
							ErrorInput,
							{ data: { id: tfLogLevel.warn }, title: "Warn" },
							{ data: { id: tfLogLevel.debug }, title: "Debug" },
							{ data: { id: tfLogLevel.info }, title: "Info" },
							{ data: { id: tfLogLevel.trace }, title: "Trace" }
						]
					}
				}
			};
		}
	},

	methods: {
		handleInput(event: CustomEvent<FormValues>) {
			this.verbosityFormValues = event.detail;
		},

		timestampFormat(value: number | string) {
			return getRelativeTime(value);
		},

		async confirm() {
			this.isSubmitting = true;
			this.errorMessage = "";

			try {
				await startEnvPipeline({
					action: this.action,
					envId: this.env.id,
					projectId: this.env.projId,
					orgId: this.env.orgId,
					logVerbosity: this.verbosityFormValues.logVerbosity.data.id,
					gitRevision: {
						type: this.verbosityFormValues.revisionType.data.id,
						identifier: this.verbosityFormValues.revisionIdentifier
					}
				});

				if (this.currentJob?.status === "failed") {
					this.isSubmitting = false;
					this.errorMessage = this.currentJob.message ?? "Unknown error";
				} else {
					this.isSubmitting = false;

					// Emit toast event
					this.$emit("toast", {
						title: `${this.env.name} ${footerContent[this.action]?.done.actionName} started`,
						action: this.action,
						status: "active",
						message: `${this.env.name} is getting ${footerContent[
							this.action
						]?.done.text?.toLocaleLowerCase()}`,
						timeString: this.timestampFormat(
							this.currentJob?.updatedAt ?? new Date().getTime() / 1000
						)
					});

					this.close();

					// Complete the step flow as we show the flow only till validate.
					EnvCreateStepFlowService.toggleFlow({ isEnabled: false });
				}
			} catch (error) {
				this.isSubmitting = false;
				this.errorMessage = getErrorMessage(error);
				captureError(error);
			}
		},

		close() {
			this.$emit("close");
		}
	}
});

type FormValues = {
	revisionType: GitRevisionOption;
	revisionIdentifier: string;
	logVerbosity: LogLevelInput;
};

export async function startEnvPipeline({
	action,
	envId,
	projectId,
	orgId,
	logVerbosity,
	gitRevision
}: {
	action: actionType;
	envId: string;
	projectId: string;
	orgId: string;
	logVerbosity: tfLogLevel;
	gitRevision?: GitRevisionOverride;
}) {
	/**
	 * This function validates the config, infra used while creating the env.
	 */
	if (!(orgId && projectId && envId)) {
		return null;
	}

	// Trigger the pipeline
	await newEnvPipelineStore.START_ENV_PIPELINE({
		orgId,
		projId: projectId,
		envId,
		action,
		logVerbosity,
		gitRevision
	});

	newEnvPipelineStore.SET_USER_CONFIRMATION_FOR_PIPELINE_ACTION(false);
}
</script>
