<template>
	<AuthWrapper header="Reset Password" :show-back-icon="false">
		<ResetPasswordForm :reset-code="resetCode" />
	</AuthWrapper>
</template>

<script>
import { defineComponent } from "vue";

import ResetPasswordForm from "@/modules/auth/components/ResetPasswordForm.vue";
import AuthWrapper from "@/modules/auth/views/AuthWrapper.vue";
import routerMixin from "@/shared/mixins/routerMixin";

export default defineComponent({
	name: "ResetPassword",

	components: {
		ResetPasswordForm,
		AuthWrapper
	},

	mixins: [routerMixin],

	beforeRouteEnter(to, from, next) {
		const { code } = to.query;
		if (!code) {
			return next("login");
		}
		next();
	},

	data() {
		return {
			resetCode: ""
		};
	},

	created() {
		this.resetCode = this.$route.query.code;
	}
});
</script>
