<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		width="480px"
		max-height="80vh"
		overflow="visible"
	>
		<Header>
			<Icon
				v-if="!editData?.id"
				name="i-arrow-left"
				size="small"
				data-qa-terraform-go-back-btn
				@click="validateAndCloseModal('back')"
			/>
			<Typography type="h4" color="dark"> {{ getHeaderText }} </Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Icon
					size="x-small"
					type="filled"
					name="i-close"
					data-qa-close-connect-terraform-icon
					@click="validateAndCloseModal"
				/>
			</Container>
		</Header>
		<ClosePopoverConfirmationWarning
			ref="closeConfirmation"
			:initial-form-values="initialFormValues"
			:form-values="formValues"
			@force-close="close"
		/>
		<Container
			:padding="16"
			direction="column"
			:gap="0"
			overflow="auto"
			align="left top"
			class="flow-add-scrollbar"
		>
			<GitRevisionForm
				ref="gitForm"
				v-model:form-values="formValues"
				@initial-values="initialFormValues = $event"
			/>

			<Container v-if="submitError" direction="column" padding="15px 0 0 0">
				<Typography type="p2" color="error" data-qa-git-form-error>
					{{ submitError }}
				</Typography>
			</Container>
		</Container>

		<Footer>
			<Button
				state="full"
				type="success"
				:loading="isSubmitting"
				data-qa-connect-terraform-submit-btn
				data-qa-terraform-next-connect-cloud-account-btn
				@click="submit"
			>
				{{ getButtonText }}
			</Button>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { getGitServerByRepo } from "@/modules/credentials/credential-types";
import { app } from "@/protocol/app";
import { Artifact, GitRevision, sourceEnum } from "@/protocol/common";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import { APPLICATION_LANGUAGE_MAP2, GIT_REVISION_OPTIONS } from "@/shared/constants";
import { addProtocol, captureError, getErrorMessage, parseGitUrl } from "@/utils";

import GitRevisionForm, { GitRevisionFormValues } from "./GitRevisionForm.vue";

export default defineComponent({
	name: "GitRevisionWrapper",

	components: {
		Icon,
		Typography,
		Container,
		ClosePopoverConfirmationWarning,
		Button,
		Footer,
		Header,
		Wrapper,
		GitRevisionForm
	},

	props: {
		app: {
			type: Object as PropType<app>,
			required: true
		},

		editData: {
			type: Object as PropType<Artifact>
		}
	},

	data() {
		let formValues: GitRevisionFormValues = {};

		if (this.editData) {
			const gitRevision = this.editData.gitCode;

			formValues = {
				// When we send repo to server we add the protocol, when we render on client we remove it
				repoName: gitRevision?.repo ? parseGitUrl(gitRevision.repo)?.repo : "",
				revisionType: GIT_REVISION_OPTIONS.find(option => option.data.id === gitRevision?.type),
				revisionIdentifier: gitRevision?.identifier ?? "",
				directoryPath: gitRevision?.dir,
				artifactName: this.editData.name,
				appLanguage:
					APPLICATION_LANGUAGE_MAP2[this.app.appConfig?.language ?? sourceEnum.source_no],

				isValid: true
			};
		}

		return {
			submitError: null as string | null,
			isSubmitting: false,
			formValues,
			initialFormValues: formValues
		};
	},

	computed: {
		getHeaderText() {
			const actionText = this.editData?.id ? "Edit" : "Add";
			return `${actionText} app source code repo`;
		},

		getButtonText() {
			const actionText = this.editData?.id ? "EDIT" : "ADD";
			return `${actionText} SOURCE CODE REPO`;
		}
	},

	methods: {
		isValidForm() {
			return (this.$refs.gitForm as InstanceType<typeof GitRevisionForm>).validateForm();
		},

		close() {
			this.$emit("close");
		},

		validateAndCloseModal(eventType: "back" | "closeModal" = "closeModal") {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				if (eventType === "back") {
					return this.$emit("goBack");
				}
				return this.close();
			}
			return;
		},

		async submit() {
			this.isSubmitting = true;
			this.submitError = null;

			if (!this.isValidForm()) {
				this.isSubmitting = false;
				return;
			}

			try {
				const repo = addProtocol(this.formValues.repoName!);
				/* Git repo fields */
				const gitRepoApp: GitRevision = {
					type: this.formValues.revisionType!.data.id,
					identifier: this.formValues.revisionIdentifier!,
					repo,
					dir: this.formValues.directoryPath,
					driver: getGitServerByRepo(repo)
				};

				await this.addGitAppArtifacts({
					...(this.editData?.id && { id: this.editData.id }),
					name: this.formValues.artifactName ?? "",
					gitCode: gitRepoApp
				});

				this.close();
			} catch (error) {
				this.submitError = getErrorMessage(error);
				captureError(error);
			} finally {
				this.isSubmitting = false;
			}
		},

		async addGitAppArtifacts(artifact: Artifact) {
			let artifacts = this.app.artifacts ?? [];

			/* Check if App is selected and opened in edit mode */
			if (this.editData?.id) {
				artifacts = artifacts.filter(
					(artifactItem: Artifact) => artifactItem.id !== (this.editData as Artifact).id
				);
			}

			artifacts.push(artifact);

			try {
				await applicationStore.UPDATE_APPLICATION({
					id: this.app.id,
					orgId: this.app.orgId,
					projId: this.app.projId,
					name: this.app.name,
					description: this.app.description,
					artifacts,
					replaceVariables: false,
					appConfig: {
						...(this.app.appConfig ?? {}),
						language: this.formValues.appLanguage?.data.id ?? sourceEnum.source_no
					}
				});
			} catch (error) {
				this.submitError = getErrorMessage(error);
			}
		}
	}
});
</script>
