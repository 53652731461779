<template>
	<f-form-builder
		ref="formBuilder"
		data-qa-git-revision-form
		:field.prop="formFields"
		:values.prop="formValues"
		@input="handleInput"
		@state-change="formState = $event.detail"
	/>

	<f-tooltip id="repoName_info_tootip">
		<Container :padding="0" direction="column">
			<Typography type="p2" weight="medium" color="white"> Repository URL </Typography>
			<Container :padding="0" :gap="0" direction="column">
				<Typography type="p2-para" color="white">
					URL of source code repo for this application
				</Typography>
			</Container>
		</Container>
	</f-tooltip>

	<f-tooltip id="revisionIdentifier_info_tootip">
		<Container :padding="0" direction="column">
			<Typography type="p2" weight="medium" color="white"> Revision idenifier </Typography>
			<Container :padding="0" :gap="0" direction="column">
				<Typography type="p2-para" color="white">
					Name of the branch / tag / git commit hash
				</Typography>
			</Container>
		</Container>
	</f-tooltip>

	<f-tooltip id="revisionType_info_tootip">
		<Container :padding="0" direction="column">
			<Typography type="p2" weight="medium" color="white"> Revision type </Typography>
			<Container :padding="0" :gap="0" direction="column">
				<Typography type="p2-para" color="white">
					Revision of source control to checkout. (Branch / Tag / git commit hash)
				</Typography>
			</Container>
		</Container>
	</f-tooltip>

	<f-tooltip id="directoryPath_info_tootip">
		<Container :padding="0" direction="column">
			<Typography type="p2" weight="medium" color="white"> Directory Path </Typography>
			<Container :padding="0" :gap="0" direction="column">
				<Typography type="p2-para" color="white">
					Directory in source code repo where the application code resides (default "/")
				</Typography> </Container
			>it
		</Container>
	</f-tooltip>

	<f-tooltip id="artifactName_info_tootip">
		<Container :padding="0" direction="column">
			<Typography type="p2" weight="medium" color="white"> Display name </Typography>
			<Container :padding="0" :gap="0" direction="column">
				<Typography type="p2-para" color="white">
					Display name in source code repo for this application
				</Typography>
			</Container>
		</Container>
	</f-tooltip>
</template>
<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Container, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { GitRevisionType, sourceEnum } from "@/protocol/common";
import {
	APPLICATION_LANGUAGE_LIST2,
	APPLICATION_LANGUAGE_MAP2,
	GIT_REVISION_OPTIONS,
	GitRevisionOption
} from "@/shared/constants";
import {
	applyEntityNameRules2,
	entityNameRules2
} from "@/shared/custom-validation-rules/entityNameRules";
import { extractGitHubRepoPath, parseGitUrl, safeEntityString } from "@/utils";

export default defineComponent({
	name: "GitRevisionForm",

	components: {
		Typography,
		Container
	},

	props: {
		revisionType: {
			type: String as PropType<GitRevisionType>
		},

		formValues: {
			type: Object as PropType<GitRevisionFormValues>,
			required: true
		},

		existingNames: Array as PropType<string[]>
	},

	emits: ["initial-values", "update:formValues"],

	data() {
		return {
			formState: null as FormBuilderState | null
		};
	},

	computed: {
		formFields(): FormBuilderField {
			const revisionType = this.formValues.revisionType
				? this.formValues.revisionType
				: GitRevisionType.branch;

			const revisionIdentifierText =
				revisionType === GitRevisionType.branch
					? {
							label: "Base branch",
							placeholder: "Enter base branch"
					  }
					: {
							label: "Revision identifier",
							placeholder: "Enter revision identifier"
					  };

			const gitSuggestedRepo = safeEntityString(
				extractGitHubRepoPath(this.formValues.repoName) ?? "",
				this.existingNames
			);

			const suggestedArtifactName =
				this.formValues.artifactName && this.formValues.artifactName === gitSuggestedRepo
					? gitSuggestedRepo
					: undefined;

			return {
				type: "object",
				direction: "vertical",
				fields: {
					repoName: {
						type: "text",
						qaId: "repoName",
						placeholder: "Enter repo URL",
						label: { title: "Repository URL", iconTooltip: "#repoName_info_tootip" },
						validationRules: [{ name: "required" }, entityNameRules2.entityValidURLRule],
						prefix: "https://"
					},

					revisionType: this.revisionType
						? {
								type: "hidden"
						  }
						: {
								type: "select",
								qaId: "revisionType",
								label: { title: "Revision type", iconTooltip: "#revisionType_info_tootip" },
								placeholder: "Select revision type",
								options: GIT_REVISION_OPTIONS,
								validationRules: [{ name: "required" }]
						  },

					revisionIdentifier: {
						type: "text",
						qaId: "revisionIdentifier",
						placeholder: revisionIdentifierText.placeholder,
						label: {
							title: revisionIdentifierText.label,
							iconTooltip: "#revisionIdentifier_info_tootip"
						},

						validationRules: [{ name: "required" }]
					},

					directoryPath: {
						type: "text",
						qaId: "directoryPath",
						placeholder: "Enter directory path",
						label: { title: "Directory path", iconTooltip: "#directoryPath_info_tootip" },
						suffix: "suggested",
						suffixWhen(value) {
							return value === "/";
						},

						validationRules: [{ name: "required" }]
					},

					artifactName: {
						type: "text",
						qaId: "artifactName",
						placeholder: "Enter display name",
						label: { title: "Display name", iconTooltip: "#artifactName_info_tootip" },
						suffix: "suggested",
						suffixWhen(value) {
							return value === suggestedArtifactName;
						},

						validationRules: this.existingNames
							? applyEntityNameRules2(this.existingNames, "This display name already exists.")
							: undefined
					},

					appLanguage: {
						type: "select",

						qaId: "appLanguage",
						label: { title: "Application language" },
						options: APPLICATION_LANGUAGE_LIST2,
						validationRules: [{ name: "required" }]
					}
				}
			};
		}
	},

	watch: {
		"formState.isValid": {
			handler(isValid: boolean) {
				this.$emit("update:formValues", {
					...this.formValues,
					isValid
				});
			}
		}
	},

	mounted() {
		const values = { ...this.formValues };

		const propRevisionType = GIT_REVISION_OPTIONS.find(
			option => option.data.id === this.revisionType
		);

		values.revisionType ??= propRevisionType ?? GIT_REVISION_OPTIONS[0];
		values.directoryPath ??= "/";
		values.appLanguage ??= APPLICATION_LANGUAGE_MAP2[sourceEnum.source_no];

		this.handleInput(new CustomEvent("input", { detail: { ...values } }));
		this.$emit("initial-values", values);
	},

	methods: {
		// used by parent component to validate form
		// eslint-disable-next-line vue/no-unused-properties
		validateForm() {
			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();
			return this.formState?.isValid;
		},

		handleInput(event: CustomEvent<GitRevisionFormValues>) {
			const input = { ...event.detail, isValid: this.formState?.isValid };

			if (input.repoName) {
				const parsedRepo = parseGitUrl(input.repoName);

				if (parsedRepo?.repo) {
					input.repoName = parsedRepo.repo;
					// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
					input.directoryPath = parsedRepo.path || input.directoryPath;
				}

				if (parsedRepo?.identifier) {
					input.revisionIdentifier = parsedRepo.identifier;
				}
			}

			// Check if its add form and artifactName is not touched
			const extractGitRepo = extractGitHubRepoPath(input.repoName);
			if (input.artifactName === undefined && extractGitRepo) {
				input.artifactName = safeEntityString(extractGitRepo, this.existingNames);
			}

			this.$emit("update:formValues", input);
		}
	}
});

export type GitRevisionFormValues = {
	repoName?: string;
	revisionType?: GitRevisionOption;
	revisionIdentifier?: string;
	directoryPath?: string;
	artifactName?: string;
	appLanguage?: (typeof APPLICATION_LANGUAGE_LIST2)[number];
	isValid?: boolean;
};
</script>
