<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		max-height="70vh"
		width="540px"
		data-qa-add-pipeline-module
	>
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			:shrink="0"
			class="height-inherit"
		>
			<Header>
				<Container align="left center" :padding="0" :grow="1" :shrink="0">
					<Icon
						v-if="showBackBtn"
						name="i-arrow-left"
						type="filled"
						size="small"
						data-qa-add-module-popover-back-btn
						@click="$emit('back')"
					/>
					<Container :gap="12" :padding="0">
						<Typography type="h4" color="dark" data-qa-add-module-display-name>{{
							module?.displayName
						}}</Typography>
					</Container>
					<Container align="right center" :grow="1" :padding="0">
						<Icon
							name="i-close"
							type="filled"
							size="small"
							data-qa-add-module-popover-close-btn
							@click.stop="$emit('close')"
						/>
					</Container>
				</Container>
			</Header>
			<f-div v-if="showInfo" state="subtle" width="fill-container" data-qa-module-show-info>
				<Container align="space-between center" :grow="1" padding="4px 16px 4px 8px" :shrink="0">
					<f-div padding="small" gap="small" width="fill-container">
						<Icon name="i-info-fill" color="primary-100" size="small" />
						<Typography weight="regular" type="p1"
							>Please add the required inputs to add the module.
						</Typography>
					</f-div>
					<Icon
						name="i-close"
						size="x-small"
						data-qa-show-info-close-icon
						@click="showInfo = false"
					/>
				</Container>
			</f-div>
		</Container>
		<Container
			class="flow-add-scrollbar"
			direction="column"
			overflow="scroll"
			align="left top"
			:padding="16"
			:grow="1"
		>
			<JSONSchemaFormBuilder2
				v-if="formFields"
				overflow="visible"
				:fields="formFields"
				:default-values="defaultValues ?? undefined"
				@info="handleFieldInfo"
			/>
		</Container>
		<Footer>
			<Button
				state="full"
				type="primary"
				:disabled="!isFormValid"
				data-qa-add-module-submit-btn
				@click="submit"
				>{{ saveMode ? "Save " : "Add " }} Module</Button
			>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { JSONSchema } from "@/protocol/common";
import { pipelineModuleReference } from "@/protocol/pipeline";
import JSONSchemaFormBuilder2 from "@/shared/components/JSONSchemaFormBuilder2.vue";
import { pipelineModuleWithRefId } from "@/shared/pipeline-constants";

export default defineComponent({
	name: "AddNewModulePopOver",

	components: {
		JSONSchemaFormBuilder2,
		Container,
		Header,
		Icon,
		Typography,
		Wrapper,
		Footer,
		Button
	},

	props: {
		module: {
			type: Object as PropType<pipelineModuleWithRefId | null>,
			required: true
		},

		pipelineModuleRef: {
			type: Object as PropType<pipelineModuleReference | null>
		},

		showBackBtn: {
			type: Boolean,
			required: true
		},

		saveMode: {
			type: Boolean,
			default: () => null
		},

		order: {
			type: Number,
			default: () => undefined
		}
	},

	emits: ["submitted", "close", "back"],

	data: () => ({
		formFields: null as JSONSchema | null,
		defaultValues: null as Record<string, any> | null,

		isFormValid: false,
		formValues: null as unknown | null,

		showInfo: true
	}),

	computed: {},

	mounted() {
		if (this.module?.inputs !== undefined) {
			this.formFields = this.module.inputs;
		}
		if (this.pipelineModuleRef?.inputs && this.saveMode) {
			this.defaultValues = this.pipelineModuleRef.inputs;
		}
	},

	methods: {
		handleFieldInfo({ inputs, isValid }: { inputs: unknown; isValid: boolean }) {
			this.isFormValid = isValid;
			this.formValues = inputs;
		},

		submit() {
			this.$emit("submitted", {
				moduleRefId: this.module?.moduleRefId,
				name: this.module?.category,
				moduleValues: this.formValues,
				module: this.module,
				order: this.order
			});
			this.$emit("close");
		}
	}
});
</script>
