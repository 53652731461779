<template>
	<Container
		data-qa-app-creation-rsaf-pipeline-step
		padding="16px"
		align="left top"
		direction="column"
	>
		<Typography type="p1" color="gray-200">Configuration for integration pipeline</Typography>

		<Divider />

		<Container
			:gap="20"
			:padding="0"
			align="left top"
			:shrink="0"
			direction="column"
			overflow="visible"
		>
			<Container :grow="1" :padding="0" :shrink="0" align="left center" overflow="visible">
				<Checkbox
					v-model:checked="runChecksPipeline"
					data-qa-app-creation-rsaf-pipeline-run-checks-checkbox
				/>

				<Typography type="p1" weight="bold">Run RSAF checks pipeline</Typography>

				<Container
					align="right center"
					:grow="1"
					:padding="0"
					:gap="8"
					:shrink="0"
					overflow="visible"
				>
					<Typography
						type="p2"
						color="primary-300"
						link
						data-qa-app-creation-rsaf-pipeline-run-checks-gitops-toggle
						@click="runChecksGitOps = !runChecksGitOps"
						>{{ runChecksGitOps ? "Remove" : "Add" }} GitOps webhooks</Typography
					>

					<InfoPopover>
						<Icon name="i-info-fill" size="small" color="gray-300" />
						<template #body>
							<Container :padding="0" direction="column" :gap="8">
								<Typography type="p2-para" color="white"
									>This pipeline will run Fortify, Sonarqube and Nexus IQ security
									scans.</Typography
								>
							</Container>
						</template>
					</InfoPopover>
				</Container>
			</Container>

			<Container v-if="runChecksGitOps" :padding="0" :shrink="0" :gap="16">
				<Typography type="p1" overflow="nowrap">on</Typography>
				<Multiselect
					v-model="checkPipelineTrigger"
					data-qa-app-creation-rsaf-pipeline-run-checks-trigger
					:options="WEBHOOK_OPTIONS"
					:show-labels="false"
					:allow-empty="false"
					class="width-200"
				/>
				<Typography type="p1" overflow="nowrap">on</Typography>
				<InputText
					v-model:value="checkPipelineBranch"
					data-qa-app-creation-rsaf-pipeline-run-checks-branch
				/>
			</Container>

			<Divider />

			<Container :grow="1" :padding="0" :shrink="0" align="left center" overflow="visible">
				<Checkbox
					v-model:checked="runChecksAndBuildPipeline"
					data-qa-app-creation-rsaf-pipeline-run-build-checkbox
				/>

				<Typography type="p1" weight="bold">Run RSAF checks and build pipeline </Typography>

				<Container :grow="1" :padding="0" :gap="8" :shrink="0" overflow="visible">
					<Typography
						type="p2"
						color="primary-300"
						link
						data-qa-app-creation-rsaf-pipeline-run-build-gitops-toggle
						@click="runChecksAndBuildGitOps = !runChecksAndBuildGitOps"
						>{{ runChecksAndBuildGitOps ? "Remove" : "Add" }} GitOps webhooks</Typography
					>

					<InfoPopover>
						<Icon name="i-info-fill" size="small" color="gray-300" />
						<template #body>
							<Container :padding="0" direction="column" :gap="8">
								<Typography type="p2-para" color="white"
									>This pipeline will run Fortify, Sonarqube and Nexus IQ security scans. It will
									also build and publish the app.</Typography
								>
							</Container>
						</template>
					</InfoPopover>
				</Container>
			</Container>

			<Container v-if="runChecksAndBuildGitOps" :padding="0" :shrink="0" :gap="16">
				<Typography type="p1" overflow="nowrap">on</Typography>
				<Multiselect
					v-model="checkAndBuildPipelineWebhook"
					data-qa-app-creation-rsaf-pipeline-run-build-trigger
					:options="WEBHOOK_OPTIONS"
					:show-labels="false"
					:allow-empty="false"
					class="width-200"
				/>
				<Typography type="p1" overflow="nowrap">on</Typography>
				<InputText
					v-model:value="checkAndBuildPipelineBranch"
					data-qa-app-creation-rsaf-pipeline-run-build-branch
				/>
			</Container>
		</Container>
	</Container>
</template>

<script lang="ts">
import {
	Checkbox,
	Container,
	Divider,
	Icon,
	InfoPopover,
	InputText,
	Multiselect,
	Typography
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationIntegrationStore } from "@/modules/application-integration/application-integration-store";
import { triggerEvent } from "@/protocol/externalEvents";
import { project } from "@/protocol/identity";
import { pipelineModule } from "@/protocol/pipeline";
import { JSONSchemaObjectToObject } from "@/utils";

const WEBHOOK_OPTIONS = ["Pull request", "Push to branch"];

function getWebhookFromName(name?: string) {
	if (name === "Pull request") {
		return triggerEvent.pullReq;
	} else if (name === "Push to branch") {
		return triggerEvent.pushToBranch;
	}

	return triggerEvent.no_trigger_event_support;
}

export default defineComponent({
	name: "CreateApplicationWizardPipelineStep",

	components: {
		Checkbox,
		Container,
		Divider,
		Multiselect,
		Typography,
		InfoPopover,
		InputText,
		Icon
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		}
	},

	emits: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		output: (_output: { isValid: boolean; pipelines: PipelineOutput[] }) => true
	},

	data() {
		return {
			WEBHOOK_OPTIONS,
			runChecksPipeline: true,
			runChecksGitOps: false,
			runChecksAndBuildPipeline: true,
			runChecksAndBuildGitOps: false,
			checkPipelineTrigger: WEBHOOK_OPTIONS[0],
			checkAndBuildPipelineWebhook: WEBHOOK_OPTIONS[0],
			checkPipelineBranch: "main",
			checkAndBuildPipelineBranch: "main",
			isLoading: false
		};
	},

	computed: {
		isValid() {
			let isValid = true;

			if (this.runChecksGitOps) {
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				isValid = isValid && this.checkPipelineBranch.length > 0;
			}

			if (this.runChecksAndBuildGitOps) {
				isValid = isValid && this.checkAndBuildPipelineBranch.length > 0;
			}

			return isValid;
		},

		stepOutput() {
			const pipelines: PipelineOutput[] = [];

			const appSecModule = applicationIntegrationStore.pipelineModules["azure:rsaf-appsec@1"];
			const publishModule = applicationIntegrationStore.pipelineModules["azure:publish-artifact@1"];

			const secAndBuildModules: PipelineOutput["modules"] = [];
			const secModules: PipelineOutput["modules"] = [];

			if (appSecModule) {
				secAndBuildModules.push({
					module: appSecModule,
					input: appSecModule.inputs ? JSONSchemaObjectToObject(appSecModule.inputs) : {}
				});
				secModules.push({
					module: appSecModule,
					input: appSecModule.inputs ? JSONSchemaObjectToObject(appSecModule.inputs) : {}
				});
			}

			if (publishModule) {
				secAndBuildModules.push({
					module: publishModule,
					input: publishModule.inputs ? JSONSchemaObjectToObject(publishModule.inputs) : {}
				});
			}

			if (this.runChecksAndBuildPipeline) {
				pipelines.push({
					name: "RSAF checks and build",
					modules: secAndBuildModules,
					webhook: this.runChecksAndBuildGitOps
						? {
								branch: this.checkAndBuildPipelineBranch,
								trigger: getWebhookFromName(this.checkAndBuildPipelineWebhook)
						  }
						: null
				});
			}

			if (this.runChecksPipeline) {
				pipelines.push({
					name: "RSAF checks",
					modules: secModules,
					webhook: this.runChecksGitOps
						? {
								branch: this.checkPipelineBranch,
								trigger: getWebhookFromName(this.checkPipelineTrigger)
						  }
						: null
				});
			}

			return {
				isValid: this.isValid,
				pipelines
			};
		}
	},

	watch: {
		stepOutput: {
			handler() {
				this.$emit("output", this.stepOutput);
			},

			immediate: true,
			deep: true
		}
	},

	mounted() {
		(async () => {
			const { pipelineModules } = applicationIntegrationStore;

			if (Object.keys(pipelineModules).length === 0) {
				this.isLoading = true;

				await applicationIntegrationStore.FETCH_INTEGRATION_MODULES({
					orgId: this.project.orgId,
					keywords: []
				});

				this.isLoading = false;
			}
		})();
	}
});

export type PipelineOutput = {
	name: string;
	modules: { module?: pipelineModule; input: Record<string, any> }[];
	webhook: null | {
		branch: string;
		trigger: triggerEvent;
	};
};
</script>
