import { Router, RouteRecordRaw } from "vue-router";

import EnvStageViewWrapper from "@/modules/env-pipeline/components/EnvStageViewWrapper.vue";

export const envPipelineRoutes: RouteRecordRaw[] = [
	{
		path: "/org/:orgId/projects/:projectId/envs/:envId/:action",
		name: "stageViewEnv",
		component: EnvStageViewWrapper,
		meta: {
			requiresAuth: true,
			title: "Environment Pipeline"
		},
		props: true
	},
	{
		path: "/org/:orgId/projects/:projectId/envs/:envId/:action/job/:jobId",
		name: "stageViewEnvWithJob",
		component: EnvStageViewWrapper,
		meta: {
			requiresAuth: true,
			title: "Environment Pipeline"
		},
		props: true
	}
];

export function registerEnvPipelineRoutes(router: Router) {
	envPipelineRoutes.forEach(route => {
		router.addRoute(route);
	});
}
