import { AxiosResponse } from "axios";

import {
	appList,
	appService,
	success,
	app,
	AppId,
	appBuilds,
	ciInferResponse
} from "@/protocol/app";
import { identityService, GitLanguageListResponse } from "@/protocol/identity";
import ApiService, { APPLICATION_API } from "@/services/api-service";
import { IDENTITY_API } from "@/shared/constants";
import { getErrorMessage } from "@/utils";

export const getApp: appService["getApp"] = async function (request: AppId) {
	const response: AxiosResponse<app> = await ApiService.get(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listAppsInProject: appService["listApp"] = async function ({ orgId, projId }) {
	const response: AxiosResponse<appList> = await ApiService.get(
		`${APPLICATION_API}/organizations/${orgId}/projects/${projId}/apps`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteApplication: appService["deleteApp"] = async function ({ orgId, projId, id }) {
	const response: AxiosResponse<success> = await ApiService.delete(
		`${APPLICATION_API}/organizations/${orgId}/projects/${projId}/apps/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteAppEntities: appService["deleteAppEntities"] = async function ({
	orgId,
	projId,
	id
}) {
	const response: AxiosResponse<success> = await ApiService.delete(
		`${APPLICATION_API}/organizations/${orgId}/projects/${projId}/apps/${id}/delete-all`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const createApplication: appService["createApp"] = async function (appPayload) {
	const response: AxiosResponse<app> = await ApiService.post(
		`${APPLICATION_API}/organizations/${appPayload.orgId}/projects/${appPayload.projId}/apps`,
		appPayload,
		{}
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateApplication: appService["updateApp"] = async function (appPayload) {
	try {
		const response: AxiosResponse<success> = await ApiService.put(
			`${APPLICATION_API}/organizations/${appPayload.orgId}/projects/${appPayload.projId}/apps/${appPayload.id}`,
			appPayload
		);

		if (response.status === 200) {
			return response.data;
		}

		throw response;
	} catch (error) {
		throw new Error(getErrorMessage(error));
	}
};

export const updateAppDependency: appService["updateAppDependency"] = async function (request) {
	const response: AxiosResponse<success> = await ApiService.put(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/dependencies`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listAppBuilds: appService["listAppBuilds"] = async function (request) {
	const response: AxiosResponse<appBuilds> = await ApiService.get(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/builds`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const inferCIPipeline: appService["autoInferCIApp"] = async function (request) {
	const { appId, orgId, projId, cloud, language, outputType } = request;
	// create query string for the request
	let queryString = "";
	if (cloud) {
		queryString += `cloud=${cloud}`;
	}
	if (language) {
		queryString += `&language=${language}`;
	}
	if (outputType) {
		queryString += `&outputType=${outputType}`;
	}

	const response: AxiosResponse<ciInferResponse> = await ApiService.get(
		`${APPLICATION_API}/organizations/${orgId}/projects/${projId}/apps/${appId}/infer-ci?${queryString}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getGitLanguageList: identityService["getGitLanguageList"] = async function (request) {
	const response: AxiosResponse<GitLanguageListResponse> = await ApiService.post(
		`${IDENTITY_API}/organizations/${request.orgId}/creds/${request.id}/gitrepolangs`,
		{
			repoUrl: request.repoUrl
		}
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
