<template>
	<AuthWrapper v-if="hasNxPlatformFlag">
		<Section padding="horizontal">
			<div class="center-of-container margin-tp-24">
				<Button
					type="default"
					:full-width="true"
					state="outlined"
					data-qa="swtich-to-nx-platform"
					@click="redirectCmpUser"
				>
					Switch to NX Platform
				</Button>
			</div>
		</Section>
	</AuthWrapper>
	<AuthWrapper v-else header="Log In" alternate-auth-text="Or, log in with">
		<SignInEmailPasswordForm />
		<template #alt-auth>
			<WrapperSignInIdentityProvider />
		</template>
	</AuthWrapper>
</template>

<script>
import { Button, Section } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { getCmpUrl } from "@/utils/get-environment";

import SignInEmailPasswordForm from "../components/SignInEmailPasswordForm.vue";
import WrapperSignInIdentityProvider from "../components/WrapperSignInIdentityProvider.vue";

import AuthWrapper from "./AuthWrapper.vue";

export default defineComponent({
	name: "Login",

	components: {
		WrapperSignInIdentityProvider,
		SignInEmailPasswordForm,
		AuthWrapper,
		Section,
		Button
	},

	computed: {
		hasNxPlatformFlag() {
			return featureFlagStore.featureMap.ENABLE_NX_PLATFORM;
		}
	},

	methods: {
		redirectCmpUser() {
			const url = getCmpUrl();
			window.location.replace(url);
		}
	}
});
</script>
