<template>
	<div class="display-contents">
		<form>
			<f-form-builder
				ref="formBuilder"
				:field.prop="formFields"
				:values.prop="formValues"
				@input="handleInput"
				@state-change="formState = $event.detail"
			/>
		</form>

		<Container
			type="grid"
			padding="16px 24px 0px 24px"
			align="center"
			item-size="40%"
			direction="column"
		>
			<f-button data-qa-login-button label="Log In" :loading="isSubmitting" @click="loginUser" />

			<f-button
				category="outline"
				state="neutral"
				data-qa-signup-button
				label="Sign Up"
				@click="$router.push({ name: 'signup' })"
			/>
		</Container>
	</div>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { Container } from "@cldcvr/flow-vue3";
import { AxiosError } from "axios";
import { defineComponent } from "vue";

import { getErrorMessage, reportLogin } from "@/utils";

import { authStore } from "../auth-store";
import { signInUsingEmailPassword } from "../services/auth-service";

export default defineComponent({
	name: "SignInEmailPasswordForm",

	components: {
		Container
	},

	data() {
		return {
			isSubmitting: false,
			submitError: "",
			formValues: {
				__showResendVerification__: false
			} as FormValues,

			formState: null as FormBuilderState | null
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					__showResendVerification__: {
						type: "hidden"
					},

					email: {
						type: "email",
						qaId: "email",
						label: { title: "Email" },
						autocomplete: "username",
						placeholder: "Enter email",
						helperText: this.submitError
							? html`<f-text
									size="small"
									state="danger"
									data-qa-error-for="email"
									data-qa-login-error
									>${this.submitError}</f-text
							  >`
							: "",

						validationRules: [{ name: "required" }, { name: "email" }]
					},

					__verification__: {
						type: "button",
						label: "Send verification link",
						category: "transparent",
						variant: "block",
						onClick: async () => {
							await this.$router.push({ name: "verification-email" });
						},

						showWhen(values) {
							return (values as FormValues).__showResendVerification__;
						}
					},

					password: {
						type: "password",
						qaId: "password",
						autocomplete: "current-password",
						label: {
							title: "Password",
							subTitle: html`<f-text
								size="small"
								state="primary"
								style="cursor: pointer;"
								data-qa-info-payload="reset-password"
								@click=${this.goToResetPassword}
							>
								Forgot password?
							</f-text>`
						},

						validationRules: [{ name: "required" }, { name: "min", params: { length: 7 } }],
						placeholder: "Enter password",
						onKeyPress: async event => {
							if (event.key === "Enter") {
								await this.loginUser();
							}
						}
					}
				}
			};
		}
	},

	methods: {
		async goToResetPassword() {
			await this.$router.push({ name: "reset-password" });
		},

		handleInput(event: CustomEvent<FormValues>) {
			this.submitError = "";
			this.formValues = event.detail;
		},

		async loginUser() {
			this.submitError = "";

			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();

			if (this.isSubmitting || !this.formState?.isValid) {
				return;
			}

			this.isSubmitting = true;

			try {
				await signInUsingEmailPassword({
					self: {
						email: this.formValues.email,
						password: this.formValues.password
					}
				});

				const user = await authStore.LOGIN_SUCCESS();

				if (user.id) {
					reportLogin("email");

					const { redirect } = this.$route.query;

					if (typeof redirect === "string") {
						await this.$router.push(redirect);
					} else {
						await this.$router.push({
							name: "home",
							params: { orgId: user.id }
						});
					}
				}
			} catch (error: unknown | AxiosError) {
				let errorCode = "";

				const axiosError = error as AxiosError | undefined;
				if (axiosError?.response?.data?.Code) {
					errorCode = axiosError.response.data.Code;
				}

				if (errorCode === "IdentityEmailNotVerified") {
					this.formValues.__showResendVerification__ = true;
				}

				this.submitError = getErrorMessage(error, true);
			} finally {
				this.isSubmitting = false;
			}

			return false;
		}
	}
});

type FormValues = {
	__showResendVerification__: boolean;
	email: string;
	password: string;
};
</script>
