/* eslint-disable @typescript-eslint/no-explicit-any */

export function searchObject(obj: Record<string, any> | any[], search: string) {
	return getAllValues(obj).join(" ").toLowerCase().includes(search.toLowerCase());
}

function getAllValues(obj: Record<string, any> | any[]): any[] {
	return Object.values(obj)
		.map(value => {
			if (value instanceof Array || isObject(value)) {
				return getAllValues(value);
			}

			return value;
		})
		.flat();
}

function isObject(obj: any): obj is Record<string, any> {
	return Object.prototype.toString.call(obj) === "[object Object]";
}
