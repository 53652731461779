<template>
	<div />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { codePromotionStore } from "@/modules/code-promotion/code-promotion-store";
import { credentialStore } from "@/modules/credentials/credential-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { membersStore } from "@/modules/members/members-store";
import { orgStore } from "@/modules/org/org-store";
import { OrgRole } from "@/modules/org/org-types";
import { policyListStore } from "@/modules/policy-list/policy-list-store";
import { projectStore } from "@/modules/project-list/project-store";

export default defineComponent({
	name: "OrgProjectChangeListener",

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		projectId() {
			return this.$route.params.projectId;
		}
	},

	watch: {
		orgId: {
			immediate: true,

			async handler(orgId: string, prevOrgId: string) {
				if (!orgId || orgId === prevOrgId) {
					return;
				}
				orgStore.SET_ACTIVE_ORG_ID(orgId);
				if (await orgStore.IS_USER_ORG_ADMIN({ orgId })) {
					credentialStore.GET_ORG_CREDENTIALS({
						orgId
					});
				}

				policyListStore.GET_POLICYSETS({ orgId });

				projectStore.GET_PROJECTS({
					orgId
				});
			}
		},

		projectId: {
			immediate: true,

			async handler(projectId: string, prevProjectId: string) {
				if (!this.orgId || !projectId) {
					projectStore.SET_CURRENT_PROJECT_ID(null);
					return;
				}

				if (projectId === prevProjectId) {
					return;
				}

				let project = projectStore.projects[projectId];

				// preemptively update project
				if (project) {
					projectStore.SET_CURRENT_PROJECT_ID(project.id);
				}

				// Fetch all the apps in the project
				applicationStore.FETCH_APPS_IN_PROJECT({
					projectId,
					orgId: this.orgId
				});

				// Fetch all the environments in the project
				envListStore.GET_ENVS({
					orgId: this.orgId,
					projectId
				});

				// Fetch the list of members in the project
				membersStore.FETCH_PROJECT_ACCESS_LIST({
					id: projectId,
					orgId: this.orgId
				});

				// Fetch promotionsequences for the project
				codePromotionStore.GET_PROJ_PROMOTION_SEQUENCE({ projId: projectId, orgId: this.orgId });

				project = await projectStore.GET_PROJECT({
					orgId: this.orgId,
					projectId
				});

				projectStore.SET_CURRENT_PROJECT_ID(project.id);

				credentialStore.GET_CLASSIFICATIONS({ orgId: this.orgId });

				// Fetch credentials for the project
				if (project.role === OrgRole.ADMIN) {
					credentialStore.GET_CREDS_FOR_ENTITY({
						orgId: this.orgId,
						project: {
							id: projectId,
							orgId: this.orgId
						}
					});
				}
			}
		}
	}
});
</script>
