<template>
	<Wrapper
		width="432px"
		max-height="80vh"
		border-radius="4px"
		background="element-light"
		data-qa-env-set-with-radio-btn-popover-wrapper
	>
		<Header>
			<Typography type="h4" color="dark"> Select execution platform </Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Icon
					v-tooltip="{
						content:
							'Environments will be created based on the configurations of the following execution platforms.'
					}"
					name="i-question-filled"
					size="x-small"
					type="filled"
					state="light"
					:effects="true"
				/>
				<Icon
					:disabled="isCreatingEnvSet"
					name="i-close"
					type="filled"
					size="x-small"
					@click="$emit('close')"
				/>
			</Container>
		</Header>

		<Container
			:padding="0"
			align="left top"
			overflow="auto"
			direction="column"
			:gap="0"
			class="flow-add-scrollbar"
		>
			<Slab
				v-for="templates in envSetTemplates"
				:key="templates.id"
				:no-padding="true"
				type="bottom-border"
				overflow="visible"
				data-qa-env-set-slab
				@click="onTemplateSelect(templates)"
			>
				<Container :padding="16" :gap="12" align="left top" :data-qa-env-set-item="templates.id">
					<RadioButton :checked="selectedTemplate?.id === templates.id"> </RadioButton>
					<Container direction="column" :gap="4" :padding="0">
						<Container :gap="12" :padding="0">
							<Typography type="h5" color="dark" weight="medium">{{
								templates && templates.name
							}}</Typography>
							<Icon
								v-if="templates.description"
								v-tooltip="{
									content: templates.description
								}"
								name="i-info-fill"
								size="x-small"
								type="filled"
								:effects="true"
							/>
						</Container>
					</Container>
				</Container>
			</Slab>
		</Container>
		<Footer>
			<Button
				state="full"
				type="success"
				:disabled="!selectedTemplate?.id"
				:loading="isCreatingEnvSet"
				data-qa-env-set-popover-submit-btn
				@click="createEnvSet"
			>
				<span>CREATE ENVIRONMENTS</span>
			</Button>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import {
	Header,
	Wrapper,
	Icon,
	Slab,
	Footer,
	Button,
	Container,
	RadioButton,
	Typography
} from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { codePromotionStore } from "@/modules/code-promotion/code-promotion-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { template } from "@/protocol/infra";
import { captureError, getErrorMessage } from "@/utils";

import { envCreateStore } from "../env-create-store";

export default defineComponent({
	name: "CreateEnvSetWithOptions",

	components: {
		Footer,
		Button,
		Header,
		Wrapper,
		Icon,
		Slab,
		Container,
		RadioButton,
		Typography
	},

	props: {
		projId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		selectedTemplate: null as template | null,
		isCreatingEnvSet: false,
		submitError: null as string | null
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		envSetTemplates() {
			return envCreateStore.envSetTemplates;
		}
	},

	methods: {
		async createEnvSet() {
			if (!this.selectedTemplate || this.isCreatingEnvSet) {
				return;
			}

			this.isCreatingEnvSet = true;

			try {
				await envCreateStore.CREATE_ENV_FROM_TEMPLATE({
					orgId: this.orgId,
					projId: this.projId,
					templateId: this.selectedTemplate.id,
					classifications: []
				});

				await Promise.all([
					envListStore.GET_ENVS({
						orgId: this.orgId,
						projectId: this.projId
					}),
					codePromotionStore.GET_PROJ_PROMOTION_SEQUENCE({
						orgId: this.orgId,
						projId: this.projId
					})
				]);

				notificationsStore.ADD_TOAST({
					status: "info",
					title: "Creating environment set",
					text: `As part of the set, selected environment will be created.`,
					subText: "Just now",
					qaId: "env-set-created"
				});

				this.isCreatingEnvSet = false;
				this.$emit("close");
			} catch (err) {
				captureError(err);
				this.submitError = getErrorMessage(err, true);
				notificationsStore.ADD_TOAST({
					status: "error",
					title: "Error while creating environment set",
					text: this.submitError,
					qaId: "error-env-set"
				});

				this.selectedTemplate = null;
				this.isCreatingEnvSet = false;
			}
		},

		onTemplateSelect(selectedOption: template) {
			this.selectedTemplate = selectedOption;
		}
	}
});
</script>
