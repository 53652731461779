<template>
	<Container
		v-if="!hasNoVariables"
		class="height-100-per"
		direction="column"
		align="left top"
		:gap="0"
		:grow="1"
		:padding="0"
	>
		<Container
			v-if="!hasNoVariables"
			class="dashboard-input"
			align="space-between top"
			overflow="visible"
			:shrink="0"
			padding="16px 0px 16px 0px"
		>
			<Container :padding="0" :gap="4" overflow="visible">
				<Wrapper width="232px" class="overflow-visible">
					<Multiselect
						v-model="selectedVariable"
						:options="availableVariables"
						:searchable="true"
						:close-on-select="true"
						:show-labels="false"
						:placeholder="ALL_VARIABLES.title"
						:allow-empty="false"
						track-by="title"
						data-qa-wid-variables-list-select
					>
						<template #singleLabel="props">
							{{ props.option.title }}
						</template>
						<template #option="props">
							<Container padding="8px 8px 8px 6px">
								<Icon
									v-if="props.option.icon"
									:name="props.option.icon.name"
									type="filled"
									state="light"
									size="small"
								/>
								<Pictogram
									v-if="props.option.pictogram"
									:shape="props.option.pictogram.shape"
									state="element"
									size="s"
									>{{ props.option.pictogram.content }}</Pictogram
								>
								<Typography type="p2">{{ props.option.title }}</Typography>
							</Container>
						</template>
					</Multiselect>
				</Wrapper>

				<PopOver
					:open="openVariableFilter"
					:overlay="false"
					placement="bottom-start"
					@overlay-click="openVariableFilter = !openVariableFilter"
				>
					<Pictogram :effects="true" @click="openVariableFilter = !openVariableFilter">
						<Icon
							v-tooltip="{
								content: 'Select environments to compare variables',
								placement: 'bottom-end'
							}"
							name="i-env-shape"
							size="20px"
							type="filled"
							state="dark"
							:effects="false"
							data-qa-wid-compare-variables-icon
						/>
					</Pictogram>
					<template #content>
						<Wrapper width="250px" max-height="70vh" border-radius="4px" background="element-light">
							<Container :padding="0" :gap="0" direction="column" :grow="1">
								<Header>
									<Container :padding="0" :gap="8" :grow="1" direction="column">
										<Container :padding="0" :grow="1" align="space-between center">
											<Typography type="h6" color="light" weight="regular" transform="uppercase">
												Comapare variables in...
											</Typography>
											<Button
												size="small"
												:type="showEnvironmentSearch ? 'primary' : 'default'"
												state="icon"
												data-qa-wid-variables-compare-button
												@click="showEnvironmentSearch = !showEnvironmentSearch"
											>
												<Icon name="i-search" size="x-small" type="filled" :effects="false" />
											</Button>
										</Container>
										<SearchInput
											v-if="showEnvironmentSearch"
											v-model:value="environmentSearchText"
											size="small"
											placeholder="Search environment..."
											data-qa-field="variables-environment-search"
										>
										</SearchInput>
									</Container>
								</Header>

								<Container
									overflow="auto"
									align="stretch top"
									direction="column"
									:grow="1"
									padding="0px 16px 16px 16px"
									:gap="12"
								>
									<Container
										v-for="env in searchedEnvironments"
										:key="env.env.name"
										:shrink="0"
										:padding="2"
										align="space-between center"
									>
										<Container :gap="12" :padding="0">
											<Pictogram shape="hexagon" size="s" state="avatar" :label="env.env.name" />
											<Typography type="p2" color="light">{{ env.env.name }}</Typography>
										</Container>
										<Checkbox
											v-model:checked="env.selected"
											data-qa-wid-variables-compare-env-checkbox
										/>
									</Container>
								</Container>
								<Divider />
								<Container :gap="0" :padding="0">
									<Button
										state="text"
										data-qa-wid-variables-compare-reset
										@click="resetEnvironments"
									>
										RESET
									</Button>
								</Container>
							</Container>
						</Wrapper>
					</template>
				</PopOver>

				<Icon name="i-separator" state="element-light" :effects="false" />

				<ToggleBox
					v-tooltip="{
						content: 'Variables with equal values',
						placement: 'bottom-start'
					}"
					:disabled="variableCounts.equal === 0"
					:selected="currentFilter === 'equal'"
					@click="toggleQuickFilter('equal')"
				>
					<Icon name="i-equal" type="filled" size="small" state="dark" :effects="false" />
					<Tag
						size="small"
						shape="rounded"
						:background="currentFilter === 'equal' ? 'var(--primary-100)' : undefined"
						:color="currentFilter === 'equal' ? 'var(--gray-500)' : 'var(--gray-100)'"
						>{{ variableCounts.equal }}</Tag
					>
				</ToggleBox>

				<ToggleBox
					v-tooltip="{
						content: 'Variables with unequal values',
						placement: 'bottom-start'
					}"
					:disabled="variableCounts.notequal === 0"
					:selected="currentFilter === 'notequal'"
					@click="toggleQuickFilter('notequal')"
				>
					<Icon name="i-not-equal" type="filled" size="small" state="dark" :effects="false" />
					<Tag
						size="small"
						shape="rounded"
						:background="currentFilter === 'notequal' ? 'var(--primary-100)' : undefined"
						:color="currentFilter === 'notequal' ? 'var(--gray-500)' : 'var(--gray-100)'"
						>{{ variableCounts.notequal }}</Tag
					>
				</ToggleBox>

				<ToggleBox
					v-tooltip="{
						content: 'Undeclared variables',
						placement: 'bottom-start'
					}"
					:disabled="variableCounts.error === 0"
					:selected="currentFilter === 'error'"
					@click="toggleQuickFilter('error')"
				>
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						:state="currentFilter === 'error' ? 'dark' : 'warning'"
						:effects="false"
					/>
					<Tag
						size="small"
						shape="rounded"
						:background="currentFilter === 'error' ? 'var(--primary-100)' : undefined"
						:color="currentFilter === 'error' ? 'var(--gray-500)' : 'var(--gray-100)'"
						>{{ variableCounts.error }}</Tag
					>
				</ToggleBox>

				<ToggleBox
					v-tooltip="{
						content: 'Variables with masked values',
						placement: 'bottom-start'
					}"
					:disabled="variableCounts.secret === 0"
					:selected="currentFilter === 'secret'"
					@click="toggleQuickFilter('secret')"
				>
					<Icon name="i-lock" type="filled" size="small" state="dark" :effects="false" />
					<Tag
						size="small"
						shape="rounded"
						:background="currentFilter === 'secret' ? 'var(--primary-100)' : undefined"
						:color="currentFilter === 'secret' ? 'var(--gray-500)' : 'var(--gray-100)'"
						>{{ variableCounts.secret }}</Tag
					>
				</ToggleBox>

				<Icon name="i-separator" state="element-light" :effects="false" />

				<Container :padding="0" :gap="8" overflow="visible">
					<SwitchBox v-model:on="showValues" data-qa-wid-variables-show-values />
					<Typography type="p2" color="light">Show values</Typography>
				</Container>
			</Container>

			<Container :padding="0" :gap="12">
				<Wrapper width="240px" class="overflow-visible">
					<SearchInput
						v-model:value="variableSearchText"
						placeholder="Search variables"
						data-qa-field="variables-search"
					/>
				</Wrapper>

				<AddVariableModal ref="tableModal">
					<Button
						id="variables-list-add-variable-primary"
						type="primary"
						data-qa-add-variable-main
						class="add-var-realtive-trigger"
						@click="showModal('#variables-list-add-variable-primary')"
						>+ ADD NEW</Button
					>
				</AddVariableModal>
			</Container>
		</Container>
		<Container
			v-if="!hasNoVariables"
			direction="column"
			:padding="0"
			:grow="1"
			align="left top"
			data-qa-variables-list
		>
			<Container
				direction="column"
				padding="0px 0px 50px 0px"
				align="left top"
				:grow="1"
				:gap="8"
				overflow="auto"
				class="flow-add-scrollbar bar-width-8"
			>
				<VariableListTable
					v-for="variableTable in filteredTables"
					:key="variableTable.id"
					:variable-table="variableTable"
					:filtered-environments="filteredEnvironments"
					:show-values="showValues"
					:current-filter="currentFilter"
					@add-variable="showModal"
				/>
			</Container>
		</Container>
	</Container>
	<Container
		v-else
		direction="column"
		align="left top"
		:gap="0"
		:grow="1"
		:padding="0"
		class="height-100-per"
	>
		<Container
			v-if="hasNoVariables || noSearchResults"
			direction="column"
			:grow="1"
			align="left top"
			data-qa-variables-list-empty
		>
			<VariableListEmptyState
				:project="skipProjectForEmptyState ? undefined : project"
				:entity-ids="envIds"
				:has-search="variableSearchText !== ''"
				:has-filter="currentFilter !== 'none'"
			/>
		</Container>
	</Container>
</template>

<script lang="ts">
import {
	Button,
	Checkbox,
	Container,
	Divider,
	Header,
	Icon,
	Multiselect,
	Pictogram,
	PopOver,
	SearchInput,
	SwitchBox,
	Tag,
	ToggleBox,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { app } from "@/protocol/app";
import { project as projectProto } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { getShortCode } from "@/utils";

import { applicationDeploymentStore } from "../application-deployment/application-deployment-store";
import { envListStore } from "../env-list/env-list-store";

import AddVariableModal from "./AddVariableModal.vue";
import {
	ALL_VARIABLES,
	INFRA_VARIABLES,
	PROJECT_VARIABLES,
	QuickFilter,
	RenderedEnvironment,
	VariableAppRow,
	VariableEnvRow,
	VariableProjectRow,
	VariableRow,
	VariableTable,
	VariableTableType
} from "./variable-list-types";
import VariableListEmptyState from "./VariableListEmptyState.vue";
import VariableListTable from "./VariableListTable.vue";
import { variablesListStore } from "./variables-list-store";

export default defineComponent({
	name: "VariablesList",

	components: {
		AddVariableModal,
		Button,
		Checkbox,
		Container,
		Divider,
		Header,
		Icon,
		Multiselect,
		Pictogram,
		PopOver,
		SearchInput,
		SwitchBox,
		Tag,
		ToggleBox,
		Typography,
		VariableListTable,
		VariableListEmptyState,
		Wrapper
	},

	props: {
		envs: {
			type: Array as PropType<environment[]>
		},

		apps: {
			type: Array as PropType<app[]>
		},

		project: {
			type: Object as PropType<projectProto>
		},

		skipProjectForEmptyState: {
			type: Boolean
		}
	},

	data: () => ({
		ALL_VARIABLES,
		renderedEnvironments: [] as RenderedEnvironment[],
		selectedVariable: null as VariableTableType | null,
		openVariableFilter: false,
		currentFilter: "none" as QuickFilter,
		showEnvironmentSearch: false,
		environmentSearchText: "",
		variableSearchText: "",
		showValues: false
	}),

	computed: {
		envIds() {
			return this.envs?.map(env => env.id);
		},

		availableVariables(): VariableTableType[] {
			let variableTableTypes = [ALL_VARIABLES];

			if (this.project) {
				variableTableTypes.push(PROJECT_VARIABLES);
			}

			if (this.envs) {
				variableTableTypes.push(INFRA_VARIABLES);
			}

			if (this.apps) {
				variableTableTypes = variableTableTypes.concat(
					this.apps.map(
						(app_, appIdx) =>
							({
								id: app_.id,
								title: app_.name,
								header: appIdx === 0 ? "Applications" : undefined,
								bottomBorder: false,
								pictogram: {
									shape: "squircle",
									content: getShortCode(app_.name)
								}
							}) as VariableTableType
					)
				);
			}
			return variableTableTypes;
		},

		allVariables() {
			let vars: VariableTable[] = [];

			if (this.project) {
				vars.push({
					title: this.project.name,
					id: PROJECT_VARIABLES.id,
					type: "project",
					rows: variablesListStore.variablesForProject
				});
			}

			if (this.envs) {
				vars.push({
					title: INFRA_VARIABLES.title,
					id: INFRA_VARIABLES.id,
					type: "environment",
					rows: variablesListStore.variablesForEnvironment
				});
			}

			if (this.apps) {
				variablesListStore.variablesForAppDeployment.forEach(varsForApp => {
					vars.push({
						title: varsForApp.app.name,
						id: varsForApp.app.id,
						rows: varsForApp.variables,
						type: "application-deployment"
					});
				});
			}

			vars = vars.filter(variableTable => variableTable.rows.length > 0);

			return vars;
		},

		variableCounts() {
			return countVariables(this.allVariables);
		},

		hasNoVariables() {
			return this.allVariables.length === 0;
		},

		noSearchResults() {
			if (this.hasNoVariables) {
				return true;
			}

			let allFilteredVariables: VariableRow[] = [];

			this.filteredTables.forEach(filteredVariable => {
				allFilteredVariables = allFilteredVariables.concat(filteredVariable.rows);
			});

			return allFilteredVariables.length === 0;
		},

		filteredTables() {
			if (!this.selectedVariable) {
				return [];
			}

			let filteredTableTypes = this.allVariables;

			const selectedId = this.selectedVariable.id;
			if (selectedId !== ALL_VARIABLES.id) {
				filteredTableTypes = this.allVariables.filter(ivar => ivar.id === selectedId);
			}

			// TS can be dumb sometimes
			// https://github.com/microsoft/TypeScript/issues/33591
			filteredTableTypes = filteredTableTypes.map(filteredTable => {
				return {
					...filteredTable,
					rows: filterVariables({
						rows: filteredTable.rows as VariableProjectRow[],
						currentFilter: this.currentFilter,
						variableSearchText: this.variableSearchText
					})
				} as VariableTable;
			});
			return filteredTableTypes;
		},

		filteredEnvironments() {
			return this.renderedEnvironments.filter(e => e.selected);
		},

		searchedEnvironments() {
			return this.renderedEnvironments.filter(env =>
				env.env.name.toLocaleLowerCase().includes(this.environmentSearchText.toLocaleLowerCase())
			);
		}
	},

	watch: {
		variableSearchText() {
			if (this.variableSearchText.length > 0) {
				this.showValues = true;
			}
		},

		envs: {
			handler() {
				// We are not using a getter here because these values need to be mutated
				if (!this.renderedEnvironments.length && this.envs) {
					this.renderedEnvironments = this.envs.map(env => ({ env, selected: true }));
				}
			},

			immediate: true
		}
	},

	mounted() {
		// Fetch deployments and environments in background
		if (this.project) {
			applicationDeploymentStore.LIST_PROJECT_APP_DEPLOYMENTS({
				orgId: this.project.orgId,
				projectId: this.project.id
			});

			envListStore.GET_ENVS({
				orgId: this.project.orgId,
				projectId: this.project.id
			});
		}

		const selectedVariable = this.availableVariables[0];
		if (selectedVariable) {
			this.selectedVariable = selectedVariable;
		}
	},

	methods: {
		showModal(target: string, scopeIds?: string[]) {
			const modal = this.$refs.tableModal as InstanceType<typeof AddVariableModal>;
			modal.openDialog(target, scopeIds ?? []);
		},

		resetEnvironments() {
			this.renderedEnvironments.forEach(env => (env.selected = true));
		},

		toggleQuickFilter(filter: QuickFilter) {
			this.currentFilter = filter === this.currentFilter ? "none" : filter;
		}
	}
});

function filterVariables<T extends VariableEnvRow | VariableAppRow | VariableProjectRow>({
	rows,
	currentFilter,
	variableSearchText
}: {
	rows: T[];
	currentFilter: QuickFilter;
	variableSearchText: string;
}): T[] {
	let filteredRows = rows;

	if (currentFilter === "secret") {
		filteredRows = filteredRows.filter(ivar => ivar.isSensitive);
	} else if (currentFilter !== "none") {
		filteredRows = filteredRows.filter(ivar => "state" in ivar && ivar.state === currentFilter);
	}

	if (variableSearchText) {
		const searchText = variableSearchText.toLocaleLowerCase();
		filteredRows = filteredRows.filter(ivar => {
			const keyMatches = ivar.key.toLocaleLowerCase().includes(searchText);

			if (keyMatches) {
				return true;
			}

			if ("column" in ivar) {
				return ivar.column.value?.toLocaleLowerCase().includes(searchText);
			} else {
				return Object.values(ivar.columns).some(
					value => value.value?.toLocaleLowerCase().includes(searchText)
				);
			}
		});
	}

	return filteredRows;
}

function countVariables(variableTables: VariableTable[]) {
	const variableCounts: Record<QuickFilter, number> = {
		none: 0,
		equal: 0,
		notequal: 0,
		error: 0,
		secret: 0
	};

	variableTables.forEach(variableTable => {
		variableTable.rows.forEach(variable => {
			if (!("state" in variable)) {
				return;
			}

			// Project variables are always equal so doesn't make sense to add them
			if (variableTable.type === "project" && variable.state === "equal") {
				return;
			}

			variableCounts[variable.state]++;

			if (variable.isSensitive) {
				variableCounts.secret++;
			}
		});
	});

	return variableCounts;
}
</script>

<style lang="scss">
.add-var-realtive-trigger {
	position: sticky !important;
}

div.flow-toggle-box {
	&[disabled] {
		pointer-events: none;
		opacity: 0.4;
	}
}
</style>
