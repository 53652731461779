<template>
	<Container :padding="0" align="space-between center" :grow="1">
		<Container :padding="14" :gap="12">
			<Icon
				id="toggle-menu"
				name="i-hamburger"
				class="mobile cursor-pointer"
				size="large"
				type="filled"
				:effects="false"
				data-qa-mobile-hamburger-icon
				@click="onToggleMobileSidebar()"
			/>
			<Icon
				id="open-project"
				v-tooltip="{
					content: 'Home',
					container: 'div.flow-layout',
					placement: 'right-start'
				}"
				:name="codepipesSmallIcon"
				state="dark"
				type="filled"
				size="medium"
				class="margin-rt-4"
				data-qa-cldcvr-icon-header
				@click="openOrgProjects()"
			/>
			<Icon
				v-if="breadcrumbs.length > 1"
				name="i-arrow-left"
				type="filled"
				state="light"
				size="small"
				data-qa-breadcrumb-back
				@click="navigateBack()"
			/>
			<Breadcrumbs :text-only="true" :crumbs="breadcrumbs" :crumb-width="300" @click="navigate" />
		</Container>
		<Container :padding="14" :gap="28">
			<FogBender v-if="showCustomerSuccess" />

			<Icon
				v-if="allowDebugMenu"
				id="debug-menu-trigger"
				v-tooltip="{
					content: 'Open Debug Menu',
					container: 'div.flow-layout',
					placement: 'bottom-end'
				}"
				name="i-bug"
				state="light"
				size="20px"
				type="filled"
				data-qa-debug-menu-icon
				@click="openDebugMenu"
			/>

			<Container
				v-if="hasNxPlatformFlag"
				clickable
				data-qa-field="logout"
				:grow="1"
				padding="10px 0"
				data-qa="switch-to-cmp-btn"
				@click="switchToNxPlatform()"
			>
				<Icon :name="codepipesSmallIcon" type="filled" state="dark" size="small" :effects="false" />
				<Typography type="p2" color="white">Switch to NX Platform</Typography>
			</Container>

			<Icon
				v-tooltip="{
					content: 'Open Docs',
					container: 'div.flow-layout',
					placement: 'bottom-end'
				}"
				name="i-file"
				state="light"
				type="filled"
				size="20px"
				data-qa-header-help-icon
				@click="openDocsLink"
			/>

			<OrgSwitcher v-if="activeOrgId && !hasNxPlatformFlag" :active-org-id="activeOrgId" />

			<PopOver
				v-model:open="openProfile"
				placement="bottom-end"
				@overlay-click="openProfile = false"
			>
				<Icon
					v-tooltip="{
						content: 'Profile',
						container: 'div.flow-layout',
						placement: 'bottom-end'
					}"
					data-qa-user-icon
					name="i-user"
					state="light"
					size="20px"
					type="filled"
					@click="openProfile = !openProfile"
				/>

				<template #content>
					<Wrapper border-radius="4px" background="element-light" :border="true" width="255px">
						<Container direction="column" :padding="0" :gap="0">
							<Slab size="medium" type="bottom-border" :effects="false" border-type="solid">
								<Typography type="p3" transform="uppercase" color="light">Logged in as</Typography>
								<Typography type="p1" color="dark" data-qa-header-user-name>{{
									userProfile && userProfile.email
								}}</Typography>
							</Slab>

							<Slab
								size="large"
								type="transparent"
								:effects="true"
								class="cursor-pointer"
								:data-qa-field="isDark ? 'dark-mode-toggle' : 'light-mode-toggle'"
							>
								<template #primary-action>
									<Icon name="i-theme" type="filled" state="dark" size="small" :effects="false" />
								</template>
								<p class="paragraph-1 fc-dark line-h-14 text-ellipsis">Dark mode</p>
								<template #secondary-actions>
									<CustomLoader v-if="isSavingColorScheme" size="small" />
									<SwitchBox v-else v-model:on="isDark" />
								</template>
							</Slab>

							<Slab
								size="large"
								type="transparent"
								:effects="true"
								class="cursor-pointer"
								data-qa-field="logout"
								@click="logoutUser()"
							>
								<template #primary-action>
									<Icon name="i-logout" type="filled" state="dark" size="small" :effects="false" />
								</template>
								<p class="paragraph-1 fc-dark line-h-14 text-ellipsis">Logout</p>
							</Slab>
						</Container>
					</Wrapper>
				</template>
			</PopOver>
		</Container>
	</Container>
</template>
<script lang="ts">
import {
	Breadcrumbs,
	Container,
	Icon,
	PopOver,
	Slab,
	SwitchBox,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import FogBender from "@/modules/customer-success/FogBender.vue";
import { debugMenuStore } from "@/modules/debug/debug-store";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { orgStore } from "@/modules/org/org-store";
import OrgSwitcher from "@/modules/org/OrgSwitcher.vue";
import { ColorScheme } from "@/modules/user/constants";
import { userStore } from "@/modules/user/user-store";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { codepipesSmallIcon } from "@/shared/icons";
import { ENVIRONMENTS, getCmpUrl, getEnvironment } from "@/utils/get-environment";

import { BreadCrumb, breadcrumbStore } from "../breadcrumb-store";

export default defineComponent({
	name: "CoreHeader",

	components: {
		Breadcrumbs,
		Container,
		PopOver,
		Icon,
		Slab,
		Typography,
		Wrapper,
		SwitchBox,
		OrgSwitcher,
		CustomLoader,
		FogBender
	},

	data: () => ({
		allowDebugMenu: getEnvironment() !== ENVIRONMENTS.PRODUCTION,
		openProfile: false,
		cldcvrDocsURL: "https://docs.codepipes.io",
		isSavingColorScheme: false,
		codepipesSmallIcon
	}),

	computed: {
		breadcrumbs() {
			return breadcrumbStore.breadcrumbs;
		},

		userProfile() {
			return userStore.profile;
		},

		scheme() {
			return userStore.colorScheme;
		},

		activeOrgId() {
			return orgStore.activeOrgId;
		},

		isDark: {
			get() {
				return this.scheme.theme === "dark-theme" && this.scheme.mode === "dark-mode";
			},

			set(value: boolean) {
				const scheme: ColorScheme = { theme: "light-theme", mode: "light-mode" };

				if (value) {
					scheme.mode = "dark-mode";
					scheme.theme = "dark-theme";
				}

				(async () => {
					this.isSavingColorScheme = true;

					await userStore.UPDATE_COLOR_SCHEME(scheme);

					this.isSavingColorScheme = false;
				})();
			}
		},

		hasNxPlatformFlag() {
			return featureFlagStore.featureMap.ENABLE_NX_PLATFORM && orgStore.isUserOrgAdmin;
		},

		showCustomerSuccess() {
			return userStore.fogBenderWidgetId !== "";
		}
	},

	methods: {
		switchToNxPlatform() {
			const url = getCmpUrl();
			window.location.replace(url);
		},

		navigateBack() {
			this.$router.go(-1);
		},

		navigate(obj: BreadCrumb) {
			this.$router
				.push({
					name: obj.route.name,
					params: obj.route.props
				})
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				.catch(() => {});
		},

		onToggleMobileSidebar() {
			this.$emit("onMobileSidebar");
		},

		openOrgProjects() {
			return this.$router
				.push({
					name: "home",
					params: { orgId: this.activeOrgId! }
				})
				.catch(() => {
					// add catch code-block here
				});
		},

		openDocsLink() {
			window.open(this.cldcvrDocsURL, "_blank");
		},

		openDebugMenu() {
			debugMenuStore.SHOW_DEBUG_MENU();
		},

		async logoutUser() {
			// if cmp user, don't redirect to CP login, instead logout & navigate to the cmp platform
			// If Codepipes user, logout and redirect to login screen
			if (this.hasNxPlatformFlag) {
				await authStore.USER_LOGOUT({
					forceLogout: true,
					isRedirect: false
				});
				const url = getCmpUrl();
				url.pathname = "logout";
				window.location.replace(url);
			} else {
				await authStore.USER_LOGOUT({
					forceLogout: true,
					isRedirect: true
				});
			}
		}
	}
});
</script>
