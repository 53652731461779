<template>
	<PipelineHistory
		:selected-filter="selectedFilter"
		:is-loading-history="isLoadingHistory"
		:history-list="historyList"
		@close-pipeline-history="closePipelineHistory"
		@select-pipeline-filter="selectPipelineFilter"
		@select-pipeline-history="selectPipelineHistory"
	/>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { actionType } from "@/protocol/infra";
import PipelineHistory, {
	HistoryFilterOption,
	PipelineHistorySchema
} from "@/shared/components/pipelines/PipelineHistory.vue";
import {
	JOB_STATUS,
	JOB_STEP_STATUS,
	PipelineJobStatus,
	PIPELINE_UNFINISHED_JOB_STATUSES
} from "@/shared/pipeline-constants";
import { formatDateToRegularFormat, getDuration, getElapsedTimeString } from "@/utils";

import { newEnvPipelineStore } from "../env-pipeline-store";

export default defineComponent({
	name: "EnvPipelineHistory",

	components: {
		PipelineHistory
	},

	props: {
		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		envId: {
			type: String,
			required: true
		},

		action: {
			type: String as PropType<actionType>,
			required: true
		},

		selectedPipelineId: {
			type: String
		}
	},

	emits: ["set-pipeline-history"],

	data: () => ({
		isLoadingHistory: false,
		selectedFilter: null as HistoryFilterOption | null
	}),

	computed: {
		historyList(): PipelineHistorySchema[] {
			const pipelineHistory = newEnvPipelineStore.pipelineHistory[this.envId];

			if (!pipelineHistory) {
				return [];
			}

			return [...pipelineHistory]
				.filter(job => {
					if (this.selectedFilter === null) {
						return true;
					}

					const jobStatus = job.status as PipelineJobStatus;

					if (this.selectedFilter.id === "completed") {
						return jobStatus === JOB_STATUS.DONE;
					} else if (this.selectedFilter.id === "failed") {
						return jobStatus === JOB_STATUS.FAILED;
					} else if (this.selectedFilter.id === "cancelled") {
						return jobStatus === JOB_STATUS.CANCELLED;
					}

					return PIPELINE_UNFINISHED_JOB_STATUSES.includes(jobStatus);
				})
				.sort((jobA, jobB) => Number(jobB.createdAt ?? 0) - Number(jobA.createdAt ?? 0))
				.map((job): PipelineHistorySchema => {
					return {
						id: job.id,
						date: job.createdAt ? formatDateToRegularFormat(Number(job.createdAt) * 1000) : null,
						resourceChanges: job.resourceChanges,
						state: JOB_STEP_STATUS[job.status as PipelineJobStatus],
						isSelected: job.id === this.selectedPipelineId,
						duration: getDuration(Number(job.updatedAt ?? 0), Number(job.createdAt ?? 0)),
						triggeredBy: job.updatedBy,
						elapsedTime: job.createdAt
							? getElapsedTimeString(Date.now(), Number(job.createdAt) * 1000)
							: null
					};
				});
		}
	},

	async mounted() {
		this.isLoadingHistory = true;

		await newEnvPipelineStore.FETCH_PIPELINE_HISTORY({
			envId: this.envId,
			orgId: this.orgId,
			projId: this.projectId,
			limit: 20,
			action: this.action
		});

		this.isLoadingHistory = false;
	},

	methods: {
		closePipelineHistory() {
			this.$emit("set-pipeline-history", false);
		},

		selectPipelineFilter(filter: HistoryFilterOption | null) {
			if (filter?.id === this.selectedFilter?.id) {
				this.selectedFilter = null;
			} else {
				this.selectedFilter = filter;
			}
		},

		selectPipelineHistory(pipelineId: string) {
			this.$router.replace({
				name: "stageViewEnvWithJob",
				params: {
					orgId: this.orgId,
					projectId: this.projectId,
					envId: this.envId,
					action: this.action,
					jobId: pipelineId
				}
			});
		}
	}
});
</script>
