<template>
	<Container
		padding="12px 0"
		direction="column"
		class="height-100-per"
		overflow="auto"
		align="space-between center"
		basis="45%"
		:gap="24"
		data-qa-selected-module-input-list
	>
		<Container
			v-for="(input, idx) in formattedInputs"
			:key="idx"
			:gap="8"
			padding="0"
			direction="column"
			overflow="auto"
		>
			<Typography type="h4" :data-qa-selected-module-input-name="input.title">{{
				input.title
			}}</Typography>
			<Typography type="p2" :data-qa-selected-module-input-info="input.description">{{
				input.description
			}}</Typography>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { JSONSchema } from "@/protocol/common";

export default defineComponent({
	name: "PipelineModuleInputList",

	components: {
		Container,
		Typography
	},

	props: {
		inputs: {
			type: Object as PropType<JSONSchema>,
			required: true
		}
	},

	computed: {
		formattedInputs() {
			// We only support rendering the root level object type
			if (this.inputs.type === "object" && this.inputs.properties) {
				return Object.values(this.inputs.properties);
			}

			return [];
		}
	}
});
</script>
