<template>
	<Wrapper
		width="450px"
		max-height="70vh"
		border-radius="4px"
		background="element-light"
		overflow="scroll"
	>
		<Container
			v-if="credFormType === 'form-list'"
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
		>
			<Header>
				<Icon
					name="i-arrow-left"
					size="small"
					:effects="true"
					data-qa="assign-cred-back-btn"
					@click="$emit('goBack')"
				/>
				<Typography type="h4" color="dark" :data-qa="`${credScope}-cred-assign-modal-title`">
					{{ credCategoryDetail.headerTitle }}
				</Typography>
				<Icon
					name="i-close"
					type="filled"
					size="x-small"
					:data-qa="`${credScope}-cred-assign-project-action-modal-close-btn`"
					@click="$emit('onClose')"
				/>
			</Header>
			<Wrapper border-radius="4px" background="element" :border="true">
				<Container direction="column" :padding="0" :gap="0">
					<Slab
						v-for="cred in credentialOptions"
						:key="cred.type"
						:data-qa="`assignable-cred-list-grp-${cred.name}`"
						size="large"
						type="filled"
						:effects="true"
						class="cursor-pointer"
						@click="credFormType = cred.type"
					>
						<template #primary-action>
							<Icon
								:data-qa="`assignable-cred-list-grp-${cred.name}-icon`"
								type="filled"
								state="dark"
								size="medium"
								:name="cred.icon"
								:effects="false"
							/>
						</template>
						<Typography
							:data-qa="`assignable-cred-list-grp-${cred.name}-name`"
							type="h4"
							color="dark"
							>{{ cred.name }}</Typography
						>
						<template #secondary-actions>
							<Button
								state="icon"
								class="show-on-hover"
								size="small"
								data-qa-add-container-cred-plus-btn
								:data-qa="`assignable-cred-list-grp-${cred.name}-btn`"
								@click="credFormType = cred.type"
							>
								<Icon name="i-plus" type="filled" size="small" :effects="false" />
							</Button>
						</template>
					</Slab>
				</Container>
			</Wrapper>
		</Container>
		<component
			:is="credFormName"
			v-else
			ref="add-cred-form"
			:git-form-ref="gitFormRef"
			:allowed-classification-ids="allowedClassificationIds"
			:show-back-btn="true"
			:assign-cred="true"
			@back="credFormType = 'form-list'"
			@close-modal="$emit('onClose')"
			@on-cred-create="$emit('onCredCreated', $event)"
		/>
	</Wrapper>
</template>

<script lang="ts">
import { Typography, Container, Icon, Slab, Wrapper, Button, Header } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import AwsCredentialModel from "@/modules/credentials/components/credential-form/AwsCredentialModel.vue";
import AzureCredentialModel from "@/modules/credentials/components/credential-form/AzureCredentialModel.vue";
import DockerGenricCredentialModal from "@/modules/credentials/components/credential-form/DockerGenricCredentialModal.vue";
import DockerHubCredentialModal from "@/modules/credentials/components/credential-form/DockerHubCredentialModal.vue";
import GcpCredentialModel from "@/modules/credentials/components/credential-form/GcpCredentialModel.vue";
import GitGenericCredentialModal from "@/modules/credentials/components/credential-form/GitGenericCredentialModal.vue";
import GithubCredentialModal from "@/modules/credentials/components/credential-form/GithubCredentialModal.vue";
import GitLabCredentialModal from "@/modules/credentials/components/credential-form/GitLabCredentialModal.vue";
import {
	credCategoryDetails,
	credentialOptionsMap,
	credOptions,
	SupportedCredScope
} from "@/modules/credentials/credential-types";
import { CredScope, credsType } from "@/protocol/identity";
import { GithubOauthStorageService } from "@/services/storage-service";

export default defineComponent({
	name: "AddNewCredModel",

	components: {
		DockerGenricCredentialModal,
		GitGenericCredentialModal,
		DockerHubCredentialModal,
		GithubCredentialModal,
		GitLabCredentialModal,
		AzureCredentialModel,
		AwsCredentialModel,
		GcpCredentialModel,
		Typography,
		Container,
		Wrapper,
		Button,
		Header,
		Icon,
		Slab
	},

	props: {
		credScope: {
			type: String as PropType<SupportedCredScope>,
			required: true
		},

		gitFormRef: {
			type: String,
			required: true
		},

		allowedClassificationIds: {
			type: Array as PropType<string[]>
		}
	},

	data: () => ({
		credFormType: "form-list" as credsType | "form-list",
		localCredsType: credsType
	}),

	computed: {
		credCategoryDetail() {
			return credCategoryDetails[this.credScope];
		},

		credFormName() {
			switch (this.credFormType) {
				case credsType.credsType_aws:
					return "AwsCredentialModel";
				case credsType.credsType_azure:
					return "AzureCredentialModel";
				case credsType.credsType_gcp:
					return "GcpCredentialModel";
				case credsType.credsType_gitgeneric:
					return "GitGenericCredentialModal";
				case credsType.credsType_github:
					return "GithubCredentialModal";
				case credsType.credsType_gitlab:
					return "GitLabCredentialModal";
				case credsType.credsType_dockergeneric:
					return "DockerGenricCredentialModal";
				case credsType.credsType_dockerhub:
					return "DockerHubCredentialModal";
				default:
					return "form-list";
			}
		},

		credentialOptions() {
			return this.supportCredPlatforms.reduce<credOptions[]>((options, credType: credsType) => {
				const credConfig = credentialOptionsMap[credType];
				if (credConfig) {
					options.push(credConfig);
				}
				return options;
			}, []);
		},

		supportCredPlatforms() {
			switch (this.credScope) {
				case CredScope.cloud:
					return [credsType.credsType_aws, credsType.credsType_gcp, credsType.credsType_azure];
				case CredScope.docker:
					return [
						credsType.credsType_aws,
						credsType.credsType_gcp,
						credsType.credsType_azure,
						credsType.credsType_dockergeneric,
						credsType.credsType_dockerhub,
						credsType.credsType_gitlab
					];
				case CredScope.git:
					return [
						credsType.credsType_gitgeneric,
						credsType.credsType_github,
						credsType.credsType_gitlab
					];
				default:
					return [];
			}
		}
	},

	mounted() {
		if (this.credScope === CredScope.git) {
			this.githubModalObserver();
		}
	},

	methods: {
		// called by parent component
		// eslint-disable-next-line vue/no-unused-properties
		safelyCloseModal() {
			if (this.credFormType === "form-list") {
				this.$emit("onClose");
				return;
			}
			const ref = this.$refs["add-cred-form"] as
				| InstanceType<typeof AwsCredentialModel>
				| InstanceType<typeof GcpCredentialModel>
				| InstanceType<typeof AzureCredentialModel>
				| InstanceType<typeof DockerGenricCredentialModal>
				| InstanceType<typeof DockerHubCredentialModal>
				| InstanceType<typeof GithubCredentialModal>;

			if (ref) {
				ref.validateAndCloseModal();
				return;
			}
		},

		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === this.gitFormRef) {
				// render github form
				this.credFormType = this.localCredsType.credsType_github;
			}
		}
	}
});
</script>
