<template>
	<PopOver :target="target" :open="open" placement="right-end" @overlay-click="$emit('close')">
		<slot />
		<template #content>
			<Wrapper
				border-radius="4px"
				background="gray-500"
				width="432px"
				max-height="80vh"
				overflow="visible"
				data-qa-delete-dependency-confirm
			>
				<Header>
					<Typography type="h4" color="dark">Confirm deletion</Typography>
					<Container :padding="0" :grow="1" align="right center">
						<Icon size="x-small" type="filled" name="i-close" @click="$emit('close')" />
					</Container>
				</Header>
				<Container
					padding="0 16px 16px 16px"
					:gap="16"
					align="left top"
					direction="column"
					overflow="visible"
				>
					<Typography type="p1-para" color="dark" data-qa-delete-dependency-confirm-message
						>Are you sure you want to delete
						<Typography
							type="p1-para"
							color="dark"
							weight="bold"
							inline
							:style="{ whiteSpace: 'pre-wrap' }"
							>{{ dependencyName }}</Typography
						>
						in
						<Typography
							type="p1-para"
							color="dark"
							weight="bold"
							inline
							:style="{ whiteSpace: 'pre-wrap' }"
							>{{ app.name }}</Typography
						>
						?
					</Typography>
				</Container>

				<Footer>
					<Container direction="column" padding="0" :grow="1">
						<Container v-if="errorMessage">
							<Typography type="p2" color="error" data-qa-edit-dependency-error>{{
								errorMessage
							}}</Typography>
						</Container>
						<Button
							state="full"
							type="error"
							:loading="isDeleting"
							data-qa-delete-dependency-confirm-submit
							@click="deleteDependency"
							>DELETE {{ dependencyName }}</Button
						>
					</Container>
				</Footer>
			</Wrapper>
		</template>
	</PopOver>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	PopOver,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { app as appProto } from "@/protocol/app";
import { captureError, getErrorMessage } from "@/utils";

import { applicationStore } from "../application-store";

export default defineComponent({
	name: "DeleteDependencyPopover",

	components: {
		Header,
		Typography,
		Container,
		Icon,
		Footer,
		PopOver,
		Button,
		Wrapper
	},

	props: {
		open: Boolean,

		app: {
			type: Object as PropType<appProto>,
			required: true
		},

		dependencyName: {
			type: String,
			required: true
		},

		target: String
	},

	data() {
		return {
			isDeleting: false,
			errorMessage: null as string | null
		};
	},

	methods: {
		async deleteDependency() {
			this.isDeleting = true;
			this.errorMessage = null;

			try {
				const appDependsOn = { ...this.app.dependsOn };

				delete appDependsOn[this.dependencyName];

				await applicationStore.UPDATE_DEPENDENCY({
					orgId: this.app.orgId,
					projId: this.app.projId,
					appId: this.app.id,
					dependsOn: appDependsOn
				});

				this.$emit("close");
			} catch (err) {
				captureError(err);
				this.errorMessage = getErrorMessage(err);
			} finally {
				this.isDeleting = false;
			}

			this.isDeleting = false;
			this.$emit("close");
		}
	}
});
</script>
