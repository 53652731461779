<template>
	<AuthWrapper
		header="Success! Check your email"
		:show-back-icon="true"
		@back-click="redirectMixin({ routeName: 'reset-password' })"
	>
		<Section padding="horizontal">
			<p class="fc-normal paragraph-1">
				We’ve sent an email to reset your password on
				<span class="fc-dark fw-bold">
					{{ params.email }}
				</span>
			</p>
			<p class="fc-normal paragraph-1 margin-tp-12">
				Click on the link in your email to change your Code Pipes account password. If you can’t
				find the email, check your spam folder or resend the password reset link email.
			</p>
			<p class="fc-normal paragraph-1 margin-tp-12">
				Didn’t receive the email?

				<span class="cursor-pointer fc-primary">
					<a data-qa-resend-password @click="redirectMixin({ routeName: 'reset-password' })"
						>Resend password link.</a
					>
				</span>
			</p>
		</Section>
	</AuthWrapper>
</template>

<script>
import { Section } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import AuthWrapper from "@/modules/auth/views/AuthWrapper.vue";
import routerMixin from "@/shared/mixins/routerMixin";

export default defineComponent({
	name: "SendResetPasswordLinkSuccess",
	components: { Section, AuthWrapper },
	mixins: [routerMixin],

	props: {
		params: {
			type: Object,
			required: true
		}
	}
});
</script>
