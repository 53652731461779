<template>
	<Wrapper
		max-height="70vh"
		border-radius="4px"
		background="element-light"
		data-qa-DockerGenric-cred-model
	>
		<!--START : Container-->
		<!--START : PopOver header-->
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			class="height-100-per flex-shrink-0"
		>
			<Header>
				<Icon
					v-if="showBackBtn"
					name="i-arrow-left"
					size="small"
					data-qa-DockerGenric-cred-model-back-btn
					@click="validateAndCloseModal('back')"
				/>
				<Typography type="h4" color="dark" data-qa-DockerGenric-cred-model-title>{{
					headerTxt
				}}</Typography>
				<Container :padding="0" :grow="1" align="right center">
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-DockerGenric-cred-model-close-btn
						@click="validateAndCloseModal"
					/>
				</Container>
			</Header>
			<!--END : PopOver header-->

			<ClosePopoverConfirmationWarning
				ref="closeConfirmation"
				:initial-form-values="initialformValues"
				:form-values="dockerGenricCredFormValues"
				@force-close="closeModal"
			/>

			<ModalNotificationBanner
				v-if="cred"
				banner-type="primary"
				banner-body="Changes made are applicable to the future deployments."
			/>

			<ModalNotificationBanner
				v-if="submitError"
				banner-type="error"
				:banner-body="submitError"
				:show-close-icon="true"
				@close="submitError = null"
			/>

			<Container :padding="16" :gap="24" direction="column" overflow="auto" align="left top">
				<f-form-builder
					ref="formBuilder"
					data-qa-DockerGenric-cred-model-form
					:field.prop="formFields"
					:values.prop="dockerGenricCredFormValues"
					@input="saveFormValues"
					@state-change="formState = $event.detail"
				/>
			</Container>

			<Footer>
				<Button
					state="full"
					:disabled="isSubmitting"
					:loading="isSubmitting"
					:type="isSubmitting ? 'default' : 'success'"
					data-qa-DockerGenric-cred-model-save-btn
					@click="saveDockerGenricCredentials"
					>{{ ctaBtnTxt }}</Button
				>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { credentialStore } from "@/modules/credentials/credential-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import {
	Creds,
	CredsCreate,
	credsType,
	CredsUpdate,
	dockergenericCreds
} from "@/protocol/identity";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import {
	captureError,
	getClassificationFormField,
	getClassificationFormValue,
	getErrorMessage,
	getUrlHost
} from "@/utils";

import { ClassificationType } from "../../credential-types";

export default defineComponent({
	name: "DockerGenricCredentialModal",

	components: {
		ClosePopoverConfirmationWarning,
		ModalNotificationBanner,
		Typography,
		Container,
		Wrapper,
		Button,
		Footer,
		Header,
		Icon
	},

	props: {
		assignCred: {
			type: Boolean
		},

		showBackBtn: {
			type: Boolean
		},

		updateCredList: {
			type: Boolean
		},

		cred: {
			type: Object as PropType<Creds>
		},

		allowedClassificationIds: {
			type: Array as PropType<string[]>
		}
	},

	data: () => {
		return {
			formState: null as FormBuilderState | null,
			dockerGenricCredFormValues: {} as LocalDockerGenricCredFormValues,
			initialformValues: {} as LocalDockerGenricCredFormValues,
			isSubmitting: false,
			submitError: null as string | null,
			existingCredNames: [] as string[]
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					username: {
						validationRules: [{ name: "required" }],

						label: { title: "Username" },

						type: "text",
						id: "username",
						placeholder: "Enter username"
					},

					password: {
						validationRules: [{ name: "required" }],

						label: { title: "Enter password", iconTooltip: "Enter access token or password" },

						type: "password",
						id: "password",
						placeholder: "Enter password"
					},

					registry: {
						validationRules: [{ name: "required" }],

						label: { title: "Docker registry" },

						type: "text",
						id: "registry",
						placeholder: "Enter docker registry url"
					},

					__separator__: {
						type: "separator"
					},

					...getClassificationFormField(this.cred, this.allowedClassificationIds),

					name: {
						type: "text",
						validationRules: [
							...applyEntityNameRules2(this.existingCredNames, "This display name already exists.")
						],

						label: {
							title: "Display name",
							iconTooltip: "This name will be used to refer to your saved credential on Code Pipes"
						},

						id: "name",
						placeholder: "Enter display name"
					}
				}
			};
		},

		orgId() {
			return this.$route.params.orgId as string;
		},

		headerTxt() {
			if (this.assignCred) {
				return "Assign credential";
			} else if (this.cred) {
				return "Edit Docker credential";
			}
			return "Add Docker credential";
		},

		ctaBtnTxt() {
			if (this.assignCred) {
				return "Assign credential";
			}
			return "Save Docker credential";
		}
	},

	mounted() {
		this.existingCredNames = credentialStore.existingCredentialNames.filter(_credName =>
			this.cred ? this.cred.name !== _credName : true
		);
		if (this.cred) {
			this.dockerGenricCredFormValues = {
				name: this.cred.name,
				username: this.cred.dockergeneric?.username,
				registry: this.cred.dockergeneric?.host,
				...(this.cred.classificationId && getClassificationFormValue(this.cred.classificationId))
			};

			this.initialformValues = this.dockerGenricCredFormValues;
		}
	},

	methods: {
		// eslint-disable-next-line max-statements
		async saveDockerGenricCredentials() {
			if (this.isSubmitting) {
				return null;
			}
			this.submitError = "";
			this.isSubmitting = true;

			(this.$refs.formBuilder as FFormBuilder).submit();

			if (!this.formState?.isValid) {
				this.isSubmitting = false;
				return null;
			}

			try {
				let cred: Creds | null = null;

				// FormBuilder ensures that the form is not submitted without required fields.
				const dockerGenricPayload: dockergenericCreds = {
					username: this.dockerGenricCredFormValues.username!,
					password: this.dockerGenricCredFormValues.password!,
					host: getUrlHost(this.dockerGenricCredFormValues.registry!)
				};

				if (this.cred) {
					const updateCredPayload: CredsUpdate = {
						name: this.dockerGenricCredFormValues.name!,
						id: this.cred.id,
						orgId: this.cred.orgId,
						type: credsType.credsType_dockergeneric,
						// API doesn't update the value of displayId and isNotSensitive from the request payload, so we
						// can sent empty values.
						displayId: "",
						isNotSensitive: false,
						dockergeneric: dockerGenricPayload
					};

					await credentialStore.updateCredential({ cred: updateCredPayload });

					// Get latest cred object and update the store.
					credentialStore.GET_UPDATE_CREDENTIAL({ orgId: this.orgId, id: this.cred.id });
				} else {
					const createCredpayload: CredsCreate = {
						name: this.dockerGenricCredFormValues.name!,
						orgId: this.orgId,
						type: credsType.credsType_dockergeneric,
						dockergeneric: dockerGenricPayload,
						...(this.dockerGenricCredFormValues.classification && {
							classificationId: this.dockerGenricCredFormValues.classification.data.id
						})
					};

					cred = await credentialStore.createCredential({ cred: createCredpayload });

					// Update the credential list
					if ("id" in cred) {
						credentialStore.UPDATE_CRED_AND_STATS_BY_ID(cred);
					}
				}
				if (this.updateCredList) {
					if (this.cred) {
						notificationsStore.ADD_TOAST({
							qaId: "toast-container-cred-DockerGenric-edited-successfully",
							title: "Container credential updated",
							text: `Container credential ${this.dockerGenricCredFormValues.name} is successfully updated.`,
							status: "success"
						});
					} else {
						notificationsStore.ADD_TOAST({
							qaId: "toast-container-cred-DockerGenric-added-successfully",
							title: "New container credential added",
							text: `New container credential ${this.dockerGenricCredFormValues.name} is successfully added.`,
							status: "success"
						});
					}
					this.closeModal();
				}

				this.$emit("onCredCreate", cred);
			} catch (err) {
				this.submitError = getErrorMessage(err);
				captureError(err);
			}
			this.isSubmitting = false;
			return null;
		},

		saveFormValues(event: CustomEvent<LocalDockerGenricCredFormValues>) {
			this.dockerGenricCredFormValues = event.detail;
		},

		closeModal() {
			this.$emit("closeModal");
		},

		validateAndCloseModal(eventType: "back" | "closeModal" = "closeModal") {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				if (eventType === "back") {
					return this.$emit("back");
				}
				return this.closeModal();
			}
			return;
		}
	}
});

type LocalDockerGenricCredFormValues = {
	name?: string;
	username?: string;
	password?: string;
	registry?: string;
	classification?: ClassificationType;
};
</script>
