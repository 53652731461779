<template>
	<PopOver :open="open" @overlay-click="$emit('close')">
		<slot />
		<template #content>
			<Wrapper
				border-radius="4px"
				background="element-light"
				:border="true"
				height="60vh"
				width="950px"
				data-qa-add-dependency
			>
				<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
					<Header>
						<Container align="space-between center" :padding="0" :grow="1">
							<!-- <Icon
								name="i-arrow-left"
								type="filled"
								size="x-small"
								@click.stop="$emit('close')"
							/> -->
							<Container :gap="12" :padding="0">
								<Typography type="h4" color="dark">App dependencies</Typography>
							</Container>
							<Container align="right center" :grow="1" :padding="0">
								<Icon
									v-tooltip="{ content: 'Install with VSCode Extension', placement: 'auto' }"
									state="primary"
									name="p-vscode"
									type="filled"
									size="small"
									data-qa-add-dependency-open-vscode
									@click.stop="openVSCodeLink"
								/>
								<Icon
									name="i-close"
									type="filled"
									size="x-small"
									data-qa-add-dependency-close
									@click.stop="$emit('close')"
								/>
							</Container>
						</Container>
					</Header>

					<Container :padding="0">
						<Divider />
					</Container>

					<Container align="left top" :gap="0" :grow="1" padding="0px">
						<Container
							padding="20px 0px"
							direction="column"
							overflow="auto"
							align="left top"
							:grow="1"
							:shrink="0"
							basis="45%"
						>
							<Container padding="0px 16px 10px 16px" align="left top">
								<SearchInput
									v-model:value="searchText"
									placeholder="Search Dependencies"
									data-qa-field="data-qa-add-dependency-search"
								/>
							</Container>

							<Wrapper v-if="isLoading" height="300px">
								<CustomLoader />
							</Wrapper>

							<Container v-else :padding="0" align="left top" direction="column" overflow="auto">
								<Accordion
									v-for="[groupName, dependencies] in groupedDependencies"
									:key="groupName"
									:open="!closedGroups[groupName]"
								>
									<template #header>
										<Container
											:clickable="true"
											align="space-between center"
											padding="10px 16px"
											:grow="1"
											overflow="visible"
											:data-qa-add-dependency-group="groupName"
											@click="closedGroups[groupName] = !closedGroups[groupName]"
										>
											<Container :gap="12" :padding="0">
												<Typography
													transform="uppercase"
													weight="book"
													type="h5"
													color="gray-200"
													>{{ groupName }}</Typography
												>
											</Container>
											<Icon
												name="i-chevron-down"
												size="x-small"
												:rotate="!closedGroups[groupName] ? 180 : 0"
												color="gray-300"
											/>
										</Container>
									</template>
									<Container
										v-for="(dependency, idx) in dependencies"
										:id="idx"
										:key="dependency.id"
										:padding="0"
										overflow="auto"
										:background="
											dependency.id === selectedDependency?.id
												? 'dashboard-background'
												: 'element-light'
										"
										:clickable="true"
										:data-qa-add-dependency-name="dependency.name"
										@click="selectDependency(dependency)"
									>
										<Container :padding="16">
											<Container padding="0px 12px 0px 0px" align="left center">
												<Checkbox
													:checked="selectedDependencyIds.includes(dependency.id)"
													:data-qa-add-dependency-checkbox="dependency.name"
													@click="checkDependency(dependency)"
												/>
											</Container>
											<Container :gap="16" :padding="0" align="left center">
												<Container padding="0" align="left center">
													<Pictogram size="l" :label="getShortCode(dependency.name)" state="avatar">
													</Pictogram>
												</Container>
												<Container :padding="0" :gap="4" direction="column" align="left center">
													<Typography type="p1" weight="bold">{{ dependency.name }}</Typography>
													<Typography type="p2" weight="light" color="gray-200">{{
														dependency.description
													}}</Typography>
												</Container>
											</Container>
										</Container>
									</Container>
								</Accordion>

								<EmptyState
									v-if="!groupedDependencies.length"
									:icon="{
										name: 'i-plus',
										type: 'filled',
										state: 'default',
										size: 'medium',
										color: 'gray-200'
									}"
									message="No Dependency"
									subtitle="No Dependency available for the searched text"
									shape="squircle"
								/>
							</Container>
						</Container>

						<Divider direction="vertical" />

						<Wrapper overflow="scroll" height="100%" class="flow-add-scrollbar">
							<DependencyDescription v-if="selectedDependency" :dependency="selectedDependency" />
						</Wrapper>
					</Container>

					<Container :padding="0">
						<Divider />
					</Container>

					<Footer>
						<Container align="left" :grow="1" position="fixed">
							<Typography
								v-if="errorMessage"
								type="p2"
								color="error"
								data-qa-edit-dependency-error
								>{{ errorMessage }}</Typography
							>
							<Typography v-else-if="selectedModulesCount === 0" type="p1" color="gray-200"
								>Select dependencies to add</Typography
							>
							<Typography
								v-else
								type="p1"
								color="gray-200"
								data-qa-add-dependency-selected-dependencies
							>
								<span style="font-weight: bold">
									{{ selectedModulesCount }}
									{{ selectedModulesCount === 1 ? "dependency" : "dependencies" }}</span
								>
								selected
							</Typography>
						</Container>
						<Container align="right" :grow="1" position="fixed">
							<Button
								type="success"
								:disabled="selectedModulesCount === 0"
								:loading="isSubmitting"
								data-qa-add-dependency-submit
								@click="addSelectedDependencyIds"
								>Add Dependencies</Button
							>
						</Container>
					</Footer>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import {
	Accordion,
	Button,
	Checkbox,
	Container,
	Divider,
	EmptyState,
	Footer,
	Header,
	Icon,
	PopOver,
	Pictogram,
	SearchInput,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { orgStore } from "@/modules/org/org-store";
import { app as appProto } from "@/protocol/app";
import { Dependency } from "@/protocol/infra";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { captureError, getErrorMessage, getShortCode } from "@/utils";

import { applicationStore } from "../application-store";

import DependencyDescription from "./DependencyDescription.vue";

export default defineComponent({
	name: "AddDependencyPopover",

	components: {
		Accordion,
		Button,
		Checkbox,
		Container,
		DependencyDescription,
		Divider,
		EmptyState,
		Footer,
		Header,
		Icon,
		PopOver,
		Pictogram,
		SearchInput,
		Typography,
		CustomLoader,
		Wrapper
	},

	props: {
		open: Boolean,

		app: {
			type: Object as PropType<appProto>,
			required: true
		}
	},

	emits: ["close"],

	data() {
		return {
			searchText: "",
			isLoading: true,
			isSubmitting: false,
			closedGroups: {} as Record<string, boolean>,
			selectedDependencyIds: [] as string[],
			selectedDependency: null as Dependency | null,
			errorMessage: null as string | null
		};
	},

	computed: {
		dependecies() {
			return orgStore.dependencyList.filter(dependency => {
				return dependency.name.toLowerCase().includes(this.searchText.toLowerCase());
			});
		},

		groupedDependencies() {
			const dependencyGroups: Map<string, Dependency[]> = new Map();

			this.dependecies.forEach(dependency => {
				const groupName = dependency.group ? dependency.group : "Global";
				const groups = dependencyGroups.get(groupName) ?? [];
				groups.push(dependency);
				dependencyGroups.set(groupName, groups);
			});

			return Array.from(dependencyGroups.entries());
		},

		selectedModulesCount() {
			return this.selectedDependencyIds.length;
		}
	},

	watch: {
		// Reset form state on popover open
		open() {
			if (this.open) {
				this.searchText = "";
				this.closedGroups = {};
				this.selectedDependencyIds = [];
			}
		}
	},

	mounted() {
		this.initDependencies();
	},

	methods: {
		async initDependencies() {
			this.isLoading = true;

			await orgStore.FETCH_DEPENDENCY_LIST({
				orgId: this.app.orgId
			});

			this.selectedDependency = this.dependecies[0] ?? null;

			this.isLoading = false;
		},

		openVSCodeLink() {
			window.open(
				"https://marketplace.visualstudio.com/items?itemName=Codepipes.codepipes-vscode-extension",
				"_blank"
			);
		},

		selectDependency(dep: Dependency) {
			this.selectedDependency = dep;
		},

		checkDependency(dep: Dependency) {
			if (this.selectedDependencyIds.includes(dep.id)) {
				this.selectedDependencyIds.splice(this.selectedDependencyIds.indexOf(dep.id), 1);
			} else {
				this.selectedDependencyIds.push(dep.id);
			}
		},

		async addSelectedDependencyIds() {
			this.isSubmitting = true;
			this.errorMessage = null;

			try {
				const dependecies = this.dependecies.filter(dep =>
					this.selectedDependencyIds.includes(dep.id)
				);

				const dependsOn = this.app.dependsOn ?? {};

				dependecies.forEach(dependency => {
					// Inputs are meant to be populated by the user, so we blank them for now
					const mappedInputs = { ...dependency.inputs };
					for (const mappedKey in mappedInputs) {
						mappedInputs[mappedKey] = "";
					}

					// Outputs are env variables so we make sure they are in upper case
					const mappedOutputs = { ...dependency.outputs };
					for (const mappedKey in mappedOutputs) {
						mappedOutputs[mappedKey] = mappedKey;
					}

					dependsOn[dependency.name] = {
						dependencyName: dependency.name,
						inputs: mappedInputs,
						outputs: mappedOutputs
					};
				});

				await applicationStore.UPDATE_DEPENDENCY({
					orgId: this.app.orgId,
					projId: this.app.projId,
					appId: this.app.id,
					dependsOn
				});

				this.$emit("close");
			} catch (err) {
				captureError(err);
				this.errorMessage = getErrorMessage(err);
			} finally {
				this.isSubmitting = false;
			}
		},

		getShortCode
	}
});
</script>
