export function getRelativeTime(time: number | string): string {
	const timestamp = time.toString().length === 13 ? Number(time) : Number(time) * 1000;
	return relativeTimeFromDates(new Date(timestamp));
}

const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
	{ unit: "year", ms: 31536000000 },
	{ unit: "month", ms: 2628000000 },
	{ unit: "week", ms: 604800000 },
	{ unit: "day", ms: 86400000 },
	{ unit: "hour", ms: 3600000 },
	{ unit: "minute", ms: 60000 },
	{ unit: "second", ms: 1000 }
];
export const monthShortNames = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec"
];
const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

/**
 * Get language-sensitive relative time message from Dates.
 * @param relative  - the relative dateTime, generally is in the past or future
 * @param pivot     - the dateTime of reference, generally is the current time
 */
export function relativeTimeFromDates(relative: Date | null, pivot: Date = new Date()): string {
	if (!relative) {
		return "";
	}
	const elapsed = relative.getTime() - pivot.getTime();
	return relativeTimeFromElapsed(elapsed);
}

/**
 * Get language-sensitive relative time message from elapsed time.
 * @param elapsed   - the elapsed time in milliseconds
 */
export function relativeTimeFromElapsed(elapsed: number): string {
	for (const { unit, ms } of units) {
		if (Math.abs(elapsed) >= ms || unit === "second") {
			return rtf.format(Math.round(elapsed / ms), unit);
		}
	}
	return "";
}

/**
 *
 * @param from unix time
 * @param to unix time
 * @returns formatted time string
 */
export function getDuration(from: number, to: number): string {
	const fromDate = new Date(from * 1000).valueOf();
	const toDate = new Date(to * 1000).valueOf();
	const diff = fromDate - toDate;
	const milliSeconds = Math.abs(diff);
	return milliSecsToMinutesAndSeconds(milliSeconds);
}

/**
 *
 * @param millis time in milliseconds
 * @returns  formatted string for minutes and seconds.
 */
export function milliSecsToMinutesAndSeconds(millis: number) {
	const minutes = Math.floor(millis / 60000);
	const seconds = Number(((millis % 60000) / 1000).toFixed(0));
	return `${minutes}m ${seconds < 10 ? "0" : ""}${seconds}s`;
}

/**
 *
 * @param timestamp  Unix timestamp
 * @returns formatted string
 */
export function formatDateToRegularFormat(timestamp: number | undefined): string {
	if (!timestamp) {
		return "";
	}
	const date = new Date(timestamp);
	const mm: number | string = date.getMonth(); // Months start at 0!
	let dd: number | string = date.getDate();
	const hh: number | string = date.getHours();
	let min: number | string = date.getMinutes();
	const yy = date.getFullYear();
	if (dd < 10) {
		dd = `0${dd}`;
	}
	if (min < 10) {
		min = `0${min}`;
	}
	const convertedHH = hh > 12 ? Math.abs(12 - hh) : hh;
	return `${convertedHH}:${min}${hh > 12 ? " PM" : " AM"}, ${dd} ${
		monthShortNames[Number(mm)]
	} ${yy}`;
}

/**
 * We have a discrepancy in the timestamp format we get from the backend. It's supposed to be unix time stamp but
 * it's missing the milliseconds. So we need to add them if they are missing.
 */
export function normalizeUnixTime(timestamp?: string) {
	if (timestamp === undefined) {
		return -1;
	}

	const timeToNumber = Number(timestamp);

	return timeToNumber < 10000000000 ? timeToNumber * 1000 : timeToNumber;
}
