<template>
	<Pictogram
		v-if="!nextDepEntity?.env.isDeployed"
		v-tooltip="{
			content: `Can't promote app. The next environment is not deployed.`
		}"
		shape="circle"
		:effects="true"
	>
		<Icon name="i-arrow-right" type="filled" size="small" color="gray-200" :effects="false" />
	</Pictogram>
	<Pictogram
		v-else-if="!isAppDeployed"
		v-tooltip="{
			content: `The application needs to be deployed before it can be promoted.`
		}"
		shape="circle"
		:effects="true"
	>
		<Icon name="i-arrow-right" type="filled" size="small" color="gray-200" :effects="false" />
	</Pictogram>
	<Pictogram
		v-else
		v-tooltip="{
			content: `Promote ${version ?? '-'} to ${nextDepEntity?.env.name}`
		}"
		:state="job?.jobStatus === 'canceling' ? 'error' : 'primary'"
		shape="circle"
		:border-state="job?.jobStatus === 'canceling' ? 'error' : 'primary'"
		:loading="loading"
		:effects="true"
		@click="$emit('confirm', nextDepEntity)"
	>
		<Icon name="i-arrow-right" type="filled" size="small" state="black" :effects="false" />
	</Pictogram>
</template>
<script lang="ts">
import { Pictogram, Icon } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { jobResponse } from "@/protocol/deployment";

import { EnvDepMapping } from "../views/CodePromotionWrapper.vue";

export default defineComponent({
	name: "CodePromoTrigger",

	components: {
		Pictogram,
		Icon
	},

	props: {
		job: {
			type: Object as PropType<jobResponse | null>
		},

		isAppDeployed: {
			type: Boolean
		},

		nextDepEntity: {
			type: Object as PropType<EnvDepMapping>
		},

		version: {
			type: String
		}
	},

	computed: {
		loading() {
			return (
				this.job?.jobStatus === "waiting" ||
				this.job?.jobStatus === "canceling" ||
				this.job?.jobStatus === "active"
			);
		}
	}
});
export type TriggerState = "default" | "running" | "disabled" | "cancelling";
</script>
