<template>
	<VariableListProjectTable
		v-if="variableTable.type === 'project'"
		:variable-table="variableTable"
		:show-values="showValues"
		:current-filter="currentFilter"
		@add-variable="(...args) => $emit('add-variable', ...args)"
	/>

	<VariableListEnvironmentTable
		v-else-if="variableTable.type === 'environment'"
		:variable-table="variableTable"
		:show-values="showValues"
		:current-filter="currentFilter"
		:filtered-environments="filteredEnvironments"
		@add-variable="(...args) => $emit('add-variable', ...args)"
	/>

	<VariableListApplicationTable
		v-else
		:variable-table="variableTable"
		:show-values="showValues"
		:current-filter="currentFilter"
		:filtered-environments="filteredEnvironments"
		@add-variable="(...args) => $emit('add-variable', ...args)"
	/>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { QuickFilter, RenderedEnvironment, VariableTable } from "./variable-list-types";
import VariableListApplicationTable from "./VariableListApplicationTable.vue";
import VariableListEnvironmentTable from "./VariableListEnvironmentTable.vue";
import VariableListProjectTable from "./VariableListProjectTable.vue";

export default defineComponent({
	name: "VariableListTable",

	components: {
		VariableListProjectTable,
		VariableListEnvironmentTable,
		VariableListApplicationTable
	},

	props: {
		variableTable: {
			type: Object as PropType<VariableTable>,
			required: true
		},

		filteredEnvironments: {
			type: Array as PropType<RenderedEnvironment[]>,
			required: true
		},

		showValues: {
			type: Boolean,
			required: true
		},

		currentFilter: {
			type: String as PropType<QuickFilter>,
			required: true
		}
	},

	emits: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		"add-variable": (_target: string, _scopeIds?: string[]) => {
			return true;
		}
	}
});
</script>
