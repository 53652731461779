<template>
	<Container align="center">
		<Wrapper>
			<Container :grow="1" align="center top" :gap="52" :padding="0">
				<Wrapper width="600px">
					<Container direction="column" padding="0px 12px" align="center" :gap="24" :shrink="0">
						<Container :padding="0" direction="column" :gap="24" align="center">
							<Container :padding="0" direction="row" :gap="12">
								<Pictogram size="xl" shape="hexagon">
									<Icon name="i-plus" :effects="false" />
								</Pictogram>
							</Container>
							<Typography type="h3" weight="bold" color="dark" data-qa-empty-state-message
								>No environments available</Typography
							>
						</Container>
						<Typography type="p1-para" data-qa-empty-state-subtitle>
							Easily create environment with pre-configured execution platforms.
						</Typography>
						<Container :padding="0" align="center">
							<Button
								v-if="isUserOrgAdmin"
								data-qa-create-first-environment
								data-qa-empty-state-action
								state="outlined"
								@click="$emit('createSingleEnv')"
								>CREATE SINGLE ENVIRONMENT</Button
							>
							<Button
								v-if="!createEnvWithRadio"
								data-qa-create-first-environment
								@click="$emit('toggleSlideout')"
								>CREATE ENVIRONMENT SETS</Button
							>
							<PopOver
								v-else
								v-tooltip="{
									content:
										envSetTemplates.length === 0
											? 'There are no execution platform pre-configured' +
											  '\n' +
											  'in this project to create environments.'
											: null,
									container: 'div.flow-layout',
									placement: 'auto'
								}"
								:open="showEnvSetForm"
								class="align-items-center"
							>
								<Button
									data-qa-create-first-environment
									:disabled="envSetTemplates.length === 0"
									@click="showEnvSetForm = true"
									>CREATE ENVIRONMENT SETS</Button
								>
								<template #content>
									<Wrapper
										v-if="showEnvSetForm"
										border-radius="4px"
										background="element-light"
										:border="true"
										data-qa-project-actions-list
									>
										<Container direction="column" :padding="0" :gap="0">
											<CreateEnvSetWithOptions :proj-id="projId" @close="showEnvSetForm = false" />
										</Container>
									</Wrapper>
								</template>
							</PopOver>
						</Container>
					</Container>
				</Wrapper>
			</Container>
		</Wrapper>
	</Container>
</template>
<script lang="ts">
import {
	Container,
	Wrapper,
	Pictogram,
	Icon,
	Typography,
	Button,
	PopOver
} from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import CreateEnvSetWithOptions from "@/modules/env-create/components/CreateEnvSetWithOptions.vue";
import { envCreateStore } from "@/modules/env-create/env-create-store";
import { orgStore } from "@/modules/org/org-store";

export default defineComponent({
	name: "EnvSetEmptyView",

	components: {
		Container,
		Wrapper,
		Pictogram,
		Icon,
		Typography,
		Button,
		PopOver,
		CreateEnvSetWithOptions
	},

	props: {
		projId: {
			type: String,
			default: null
		},

		createEnvWithRadio: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		showEnvSetForm: false
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		// Only org admins can create manual environments
		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		},

		envSetTemplates() {
			return envCreateStore.envSetTemplates;
		}
	},

	mounted() {
		this.getEnvSets();
	},

	methods: {
		async getEnvSets() {
			await Promise.all([
				envCreateStore.GET_ORG_ENV_SET_TEMPLATES({
					orgId: this.orgId
				}),
				envCreateStore.GET_ORG_ENV_SET_CLASSIFICATIONS({
					orgId: this.orgId
				})
			]);
		}
	}
});
</script>
