<script lang="ts">
/**
 * This component renders logs in a terminal like view.
 *
 * It's optimized to handle really large log files and render them incrementally
 * without slowing the browser
 */

// Anser is used to highlight bash color codes
import anser from "anser";
import { nextTick, ref, toRefs, watch } from "vue";

import { getUniqueStringId } from "@/utils";

// The number of lines we process in one batch
const DEFAULT_BATCH_SIZE = 1000;

// The maximum character length we process in a line, this is to prevent overflows
const MAXIMUM_LINE_LENGTH = 10000;
</script>

<script setup lang="ts">
const props = defineProps({
	logs: {
		type: String,
		required: true
	},

	wrapText: Boolean
});

const scrollRef = ref<HTMLElement | null>(null);
const logContainer = ref<HTMLElement | null>(null);
const { logs } = toRefs(props);

// These pointers are used to find out the current index of the logs string being rendered
const lastPointerIdx = ref(0);
const currentIdx = ref(0);

// This is used to track the current batch of logs being rendered
// If the logs update then like a timeout the previous rendering should stop
const currentBatchId = ref(getUniqueStringId());

// Processes the current log batch and renders the lines in the terminal
function processNextBatch(batchSize = DEFAULT_BATCH_SIZE) {
	let currentBatchSize = 0;

	let fragment = "";

	while (currentBatchSize < batchSize) {
		if (
			// If we have reached a new line
			logs.value[currentIdx.value] === "\n" ||
			// Or if we exceed maximum log lines we render and move on
			currentIdx.value - lastPointerIdx.value > MAXIMUM_LINE_LENGTH
		) {
			fragment = `${fragment}${getCurrentLogLine()}`;
			lastPointerIdx.value = currentIdx.value + 1;
			currentBatchSize++;
		}

		// Check if we ended up processing beyond the file
		if (currentIdx.value === logs.value.length) {
			fragment = `${fragment}${getCurrentLogLine()}`;
			break;
		}

		currentIdx.value++;
	}

	logContainer.value?.append(document.createRange().createContextualFragment(fragment));
}

function getCurrentLogLine() {
	return `<span>${anser.ansiToHtml(
		formatLogLine(logs.value.substring(lastPointerIdx.value, currentIdx.value + 1))
	)}</span>`;
}

// Renders log in batches to prevent browser from freezing
function renderBatchedLogs(batchId: string) {
	if (currentIdx.value < logs.value.length && currentBatchId.value === batchId) {
		processNextBatch();
		if (scrollRef.value && logs.value.length > 0) {
			scrollRef.value.scrollTop = scrollRef.value.scrollHeight;
		}
		requestIdleCallback(() => renderBatchedLogs(batchId));
	}
}

watch(
	[logs, logContainer],
	() => {
		if (!logContainer.value) {
			return;
		}

		lastPointerIdx.value = 0;
		currentIdx.value = 0;
		currentBatchId.value = getUniqueStringId();
		logContainer.value.innerHTML = "";

		nextTick(() => {
			renderBatchedLogs(currentBatchId.value);
		});
	},
	{
		immediate: true
	}
);

// Adds some general formatting/highlighting to logs
function formatLogLine(logLine: string) {
	// Highlight [xxx] with a greyed out version
	let newLine = logLine
		// Highlight quoted strings
		.replaceAll(/("[^"]*?"|'[^']*?')/g, '<span style="color: var(--color5-300)">$1</span>')

		// Highlight bracket contents
		// 100 is an arbitrary limit to prevent catastrophic backtracking in Regex
		.replaceAll(
			/(\[[^\]]{0,100}\])/g,
			'<span style="color: var(--gray-300); font-weight: bold;">$1</span>'
		)

		// Highlight potential dates (YYYY/MM/DD HH:MM:SS)
		.replaceAll(
			/(\d{1,4}\/\d{1,2}\/\d{1,4}(?: \d{1,2}:\d{1,2}:\d{1,2})?)/g,
			'<span style="color: var(--color3-200)">$1</span>'
		)

		// Highlight potential dates (YYYY-MM-DD with timezone)
		.replaceAll(
			/(\d{1,4}-\d{1,2}-\d{1,4}(?:\s?T?\d{1,2}:\d{1,2}:[\d.]{1,10})?Z?)/g,
			'<span style="color: var(--color3-200)">$1</span>'
		)

		// Highlight YAML keys
		// 100 is an arbitrary limit to prevent catastrophic backtracking in Regex
		.replaceAll(
			/(^\s*[-\sa-z0-9_]{1,100}:\s)/gi,
			'<span style="color: var(--primary-300)">$1</span>'
		)

		// Highlight urls
		.replaceAll(
			/((https?:\/\/)([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}(:[0-9]+)?(\/[^" ]*)?)/g,
			'<a style="color: var(--primary-300); text-decoration: underline" href="$1" rel="noopener" target="_blank">$1</a>'
		);

	if (/(ERROR|FAILED)/gi.test(newLine)) {
		newLine = `<span style="background: var(--danger-500)">${newLine}</span>`;
	}

	return newLine;
}
</script>

<template>
	<f-div
		ref="scrollRef"
		:class="{ 'logs-view': true, 'flow-add-scrollbar': true, 'wrap-text': Boolean(wrapText) }"
		align="top-left"
		overflow="scroll"
		width="100%"
		height="100%"
	>
		<pre ref="logContainer"></pre>
	</f-div>
</template>

<style lang="scss">
.logs-view {
	pre {
		counter-reset: line;
		font-size: 13px;
		line-height: 16px;
		font-family: "Courier Prime", monospace;

		> span {
			counter-increment: line;
			&:before {
				content: counter(line);
				color: var(--gray-300);
				padding-right: 12px;
				text-align: right;
				min-width: 2em;
				display: inline-block;
				-webkit-user-select: none;
				user-select: none;
			}
		}
	}

	&.wrap-text pre > span {
		white-space: pre-wrap;
	}
}
</style>
