import { monthShortNames } from "./time-operations";

/**
 * Returns human friendly elapsed time between current and a given date
 */
export function getElapsedTimeString(newTime: number, oldTime: number): string {
	const currentTime = new Date(newTime);
	const previousTime = new Date(oldTime);

	if (currentTime.getFullYear() > previousTime.getFullYear()) {
		return `${monthShortNames[new Date(oldTime).getMonth()]} ${new Date(oldTime).getFullYear()}`;
	}

	if (currentTime.getMonth() > previousTime.getMonth()) {
		const mm = new Date(oldTime).getMonth();
		return monthShortNames[mm] ?? "";
	}

	if (currentTime.getDate() - previousTime.getDate() > 7) {
		return "This month";
	}

	if (currentTime.getDate() - previousTime.getDate() > 1) {
		return "This week";
	}

	if (currentTime.getDate() - previousTime.getDate() === 1) {
		return "Yesterday";
	}

	return "Today";
}
