<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		class="overflow-visible"
		data-qa-webhook-modal
	>
		<Header>
			<Container align="left center" :padding="0" :grow="1">
				<Typography type="h4" color="dark">{{ isEditing ? "Edit" : "Add" }} webhook</Typography>
				<Container align="right center" :grow="1" :padding="0">
					<Icon
						v-tooltip="'Close'"
						size="x-small"
						type="filled"
						name="i-close"
						data-qa-close
						@click="$emit('close')"
					/>
				</Container>
			</Container>
		</Header>

		<ModalNotificationBanner v-if="!isEditing" banner-type="primary" :banner-body="bannerText" />

		<Container
			overflow="visible"
			:shrink="1"
			align="left top"
			:padding="16"
			:gap="16"
			direction="column"
		>
			<Accordion :open="openTerraformSource" class="width-100-per">
				<template #header>
					<Container align="space-between center" :padding="0" :shrink="0" :gap="4">
						<Typography type="p3" transform="uppercase" color="gray-200"
							>Terraform source</Typography
						>
						<Icon
							name="i-chevron-down"
							:rotate="openTerraformSource ? 180 : 0"
							type="filled"
							state="light"
							size="small"
							data-qa-toggle-terraform-source
							@click="openTerraformSource = !openTerraformSource"
						/>
					</Container>
				</template>

				<Table aria-label="service table" border="none">
					<template #body>
						<TableRow>
							<TableCell width="30%" vertical-align="top" padding="12px 12px 12px 0px">
								<Typography type="p2" color="gray-200">Repository URL</Typography>
							</TableCell>
							<TableCell vertical-align="top">
								<Typography
									type="p2"
									color="primary"
									:link="true"
									:data-qa-terraform-repo-url="gitRepo"
								>
									<a class="fc-primary" rel="noopener" :href="gitRepo" target="_blank">{{
										gitRepo
									}}</a>
								</Typography>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell width="30%" vertical-align="top" padding="12px 12px 12px 0px">
								<Typography type="p2" color="gray-200">Directory path</Typography>
							</TableCell>
							<TableCell vertical-align="top" :data-qa-terraform-repo-path="dirPath">
								<Typography type="p2">{{ dirPath }}</Typography>
							</TableCell>
						</TableRow>
					</template>
				</Table>
			</Accordion>

			<Divider />

			<Container align="stretch top" direction="column" :grow="1" :padding="0" overflow="visible">
				<f-form-builder
					ref="formBuilder"
					:field.prop="formFields"
					:values.prop="formValues"
					@input="handleInput"
					@state-change="formState = $event.detail"
				>
					<f-tooltip id="gitEvent_tooltip" data-qa-info-for="gitEvent">
						A Git event on which you want to initiate the webhook.
					</f-tooltip>

					<f-tooltip id="pipelineAction_tooltip" data-qa-info-for="pipelineAction">
						The webhook will initiate this pipeline action.
					</f-tooltip>

					<f-tooltip id="eventIdentifier_tooltip" data-qa-info-for="eventIdentifier">
						Event identifier is based on the selected Git event. <br />
						It can be a branch, tag or a base branch name.
					</f-tooltip>
				</f-form-builder>
			</Container>

			<Slab v-if="!editedHook && formState?.isValid && !errorMessage" :no-padding="true">
				<template #primary-action>
					<Container padding="0 12px 0 0">💡</Container>
				</template>
				<Typography type="p2" color="warning-200" data-qa-webhook-help-text>{{
					helpString
				}}</Typography>
			</Slab>

			<template v-if="editedHook && editedHook.updatedAt">
				<Divider />
				<Table aria-label="service table" border="none">
					<template #body>
						<TableRow>
							<TableCell width="30%" vertical-align="top" padding="12px 12px 12px 0px">
								<Typography type="p2" color="gray-200">Last updated by</Typography>
							</TableCell>
							<TableCell vertical-align="top">
								<Typography type="p2" data-qa-webhook-created-by>
									<UserNameEmail :user-id="editedHook.updatedBy" />
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell width="30%" vertical-align="top" padding="12px 12px 12px 0px">
								<Typography type="p2" color="gray-200">Last updated</Typography>
							</TableCell>
							<TableCell vertical-align="top">
								<Typography type="p2" data-qa-webhook-updated-at>{{
									getRelativeTime(editedHook?.updatedAt)
								}}</Typography>
							</TableCell>
						</TableRow>
					</template>
				</Table>
			</template>
		</Container>

		<Footer>
			<Container direction="column" padding="0" :grow="1">
				<Container v-if="errorMessage" padding="0 16px 8px 16px">
					<Icon name="i-alert" size="x-small" color="danger-200" />
					<Typography type="p2" color="error" data-qa-webhook-error>{{ errorMessage }}</Typography>
				</Container>
				<Button
					state="full"
					type="success"
					:loading="isSaving"
					data-qa-add-webhook
					@click="isEditing ? updateWebHook() : addWebHook()"
					>{{ isEditing ? "Update" : "Add" }} webhook</Button
				>
			</Container>
		</Footer>
	</Wrapper>
</template>

<script lang="ts">
import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import {
	Accordion,
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	Slab,
	Table,
	TableCell,
	TableRow,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import { triggerEvent, triggerType, webhook, webhookRequest } from "@/protocol/externalEvents";
import { actionType, environment } from "@/protocol/infra";
import { UserNameEmail } from "@/shared/components";
import { EnvironmentActionTextMap } from "@/shared/components/environment/EnvironmentActionConstants";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import {
	CUSTOM_WEBHOOK_OPTION,
	GIT_WEBHOOK_OPTION,
	TRIGGER_EVENT_TO_STRING,
	WEBHOOK_OPTIONS,
	WebhookOptionType,
	webhookOptionTemplate
} from "@/shared/constants";
import { captureError, getErrorMessage, getRelativeTime } from "@/utils";

type GitEventOption = FSelectOptionObject & { data: { id: triggerEvent } };
type PipelineActionOption = FSelectOptionObject & { data: { id: actionType } };

const PUSH_TO_BRANCH_OPTION: FSelectOptionObject = {
	data: { id: triggerEvent.pushToBranch },
	title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToBranch]()
};

export default defineComponent({
	name: "EnvWebHookModal",

	components: {
		ModalNotificationBanner,
		Accordion,
		Button,
		Container,
		Divider,
		Footer,
		Header,
		Icon,
		Slab,
		Table,
		TableCell,
		TableRow,
		Typography,
		Wrapper,
		UserNameEmail
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		},

		editedHook: Object as PropType<webhook>
	},

	emits: ["back", "close"],

	data() {
		const editedAction = this.editedHook?.action as actionType | undefined;
		const gitAction = this.editedHook?.githubTrigger?.triggerEvent;

		return {
			getRelativeTime,
			isSaving: false,
			openTerraformSource: false,
			errorMessage: null as null | string,
			formValues: {
				webhookOption:
					!this.editedHook || this.editedHook.githubTrigger?.identifier
						? GIT_WEBHOOK_OPTION
						: CUSTOM_WEBHOOK_OPTION,

				eventIdentifier: this.editedHook?.githubTrigger?.identifier,
				gitEvent: gitAction
					? { data: { id: gitAction }, title: TRIGGER_EVENT_TO_STRING[gitAction]() }
					: PUSH_TO_BRANCH_OPTION,

				pipelineAction: editedAction
					? { data: { id: editedAction }, title: EnvironmentActionTextMap[editedAction] }
					: undefined
			} as FormValues,

			formState: null as FormBuilderState | null
			// formFields
		};
	},

	computed: {
		bannerText() {
			if (this.formValues.webhookOption.data.id === CUSTOM_WEBHOOK_OPTION.data.id) {
				return "Webhook will initiate pipeline actions on each POST message to the URL.";
			}

			return "Webhook will automatically initiate pipeline actions to run on specified git events.";
		},

		gitRepo() {
			return this.env.revision?.repo;
		},

		dirPath() {
			return this.env.revision?.dir;
		},

		isEditing() {
			return this.editedHook !== undefined;
		},

		helpString() {
			const pipelineAction = this.formValues.pipelineAction?.data.id;

			if (this.formValues.webhookOption.data.id === CUSTOM_WEBHOOK_OPTION.data.id) {
				return `This environment’s ${
					pipelineAction ? EnvironmentActionTextMap[pipelineAction] : ""
				} pipeline will be triggered on each POST message sent to the webhook URL.`;
			}

			const gitAction = this.formValues.gitEvent?.data.id;
			const branchText = gitAction === triggerEvent.pushToTag ? "tag" : "branch";
			const triggerText = gitAction === triggerEvent.pullReq ? "pull request" : "push";

			return `This environment’s ${
				pipelineAction ? EnvironmentActionTextMap[pipelineAction] : ""
			} pipeline will be triggered on each ${triggerText} to the ${
				this.formValues.eventIdentifier
			} ${branchText}.`;
		},

		formFields() {
			// eslint-disable-next-line prefer-destructuring
			const editedHook = this.editedHook;
			const { webhookOption } = this.formValues;

			const formFields: FormBuilderField = {
				type: "object",
				direction: "vertical",
				fields: {
					webhookOption: {
						type: "select",
						label: {
							title: "Webhook type",
							iconTooltip: "The type of webhook you want to create."
						},

						qaId: "webhookOption",
						placeholder: "Select webhook type",
						options: WEBHOOK_OPTIONS,
						disabled: Boolean(),
						validationRules: [{ name: "required" }],
						optionTemplate: webhookOptionTemplate,

						// Only show this option when adding a webhook
						showWhen() {
							return !editedHook;
						}
					},

					gitEvent: {
						type: "select",
						label: {
							title: "Git event",
							iconTooltip: "#gitEvent_tooltip"
						},

						qaId: "gitEvent",
						validationRules: [{ name: "required" }],
						placeholder: "Select git action",
						options: [
							{
								data: { id: triggerEvent.pullReq },
								title: TRIGGER_EVENT_TO_STRING[triggerEvent.pullReq]()
							},
							PUSH_TO_BRANCH_OPTION,
							{
								data: { id: triggerEvent.pushToTag },
								title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToTag]()
							}
						],

						showWhen() {
							return webhookOption.data.id === GIT_WEBHOOK_OPTION.data.id;
						}
					},

					eventIdentifier: {
						type: "text",
						qaId: "eventIdentifier",
						label: {
							title: "Event identifier",
							iconTooltip: `#eventIdentifier_tooltip`
						},

						placeholder: "Enter event identifier",
						validationRules: [{ name: "required" }],

						showWhen() {
							return webhookOption.data.id === GIT_WEBHOOK_OPTION.data.id;
						}
					},

					__separator__: {
						type: "separator"
					},

					pipelineAction: {
						type: "select",
						qaId: "pipelineAction",
						label: {
							title: "Pipeline action",
							iconTooltip: "#pipelineAction_tooltip"
						},

						placeholder: "Select pipeline action",
						validationRules: [{ name: "required" }],
						options: [
							{
								data: { id: actionType.validateOnly },
								title: EnvironmentActionTextMap[actionType.validateOnly]
							},
							{
								data: { id: actionType.ValidateAndApply },
								title: EnvironmentActionTextMap[actionType.ValidateAndApply]
							},
							{
								data: { id: actionType.destroy },
								title: EnvironmentActionTextMap[actionType.destroy]
							}
						]
					}
				}
			};

			return formFields;
		}
	},

	methods: {
		async addWebHook() {
			this.errorMessage = null;

			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();

			const { gitEvent, eventIdentifier, pipelineAction, webhookOption } = this.formValues;

			if (!this.formState?.isValid || !pipelineAction) {
				return;
			}

			try {
				this.isSaving = true;

				const hasCustomTrigger = webhookOption.data.id === CUSTOM_WEBHOOK_OPTION.data.id;

				const payload: webhookRequest = {
					orgId: this.env.orgId,
					projectId: this.env.projId,
					entityId: this.env.id,
					triggerType: triggerType.environment,
					action: pipelineAction.data.id,
					environment: {
						id: this.env.id,
						orgId: this.env.orgId,
						projId: this.env.projId
					}
				};

				if (hasCustomTrigger) {
					payload.customTrigger = {};
				} else if (eventIdentifier && gitEvent?.data.id) {
					payload.githubTrigger = {
						identifier: eventIdentifier,
						triggerEvent: gitEvent.data.id
					};
				}

				await webHooksStore.CREATE_WEB_HOOK(payload);

				notificationsStore.ADD_TOAST({
					qaId: "toast-env-hook-added",
					title: "Webhook added",
					text: ` Webhook is added on ${this.env.name}. ${this.helpString}`,
					status: "success"
				});

				this.$emit("back");
			} catch (err) {
				captureError(err);
				this.errorMessage = getErrorMessage(err, true);
			} finally {
				this.isSaving = false;
			}
		},

		async updateWebHook() {
			this.errorMessage = null;

			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();

			const webHookId = this.editedHook?.id;

			const { gitEvent, eventIdentifier, pipelineAction } = this.formValues;

			if (
				!webHookId ||
				!eventIdentifier ||
				!gitEvent ||
				!pipelineAction ||
				!this.formState?.isValid
			) {
				return;
			}

			try {
				this.isSaving = true;

				await webHooksStore.UPDATE_WEB_HOOK({
					orgId: this.env.orgId,
					projectId: this.env.projId,
					entityId: this.env.id,
					webhookId: webHookId,
					action: pipelineAction.data.id,
					githubTrigger: {
						identifier: eventIdentifier,
						triggerEvent: gitEvent.data.id
					}
				});

				this.$emit("back");
			} catch (err) {
				captureError(err);
				this.errorMessage = getErrorMessage(err, true);
			} finally {
				this.isSaving = false;
			}
		},

		handleInput(event: CustomEvent<FormValues>) {
			this.formValues = event.detail;
		}
	}
});

type FormValues = {
	webhookOption: WebhookOptionType;
	gitEvent?: GitEventOption;
	eventIdentifier?: string;
	pipelineAction?: PipelineActionOption;
};
</script>
