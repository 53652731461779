<template>
	<f-div direction="row" :style="parentStyle" variant="block" height="hug-content">
		<f-div direction="column">
			<f-div v-if="xPathError" state="danger" padding="small">
				<f-text>{{ xPathError }}</f-text>
			</f-div>
			<f-input
				type="text"
				size="medium"
				prefix="XPath"
				placeholder="Enter your XPath here"
				:state="xPathError.length > 0 ? 'danger' : 'success'"
				:value.prop="xpathInput"
				@input="xpathInput = $event.detail.value"
				@keyup="goToNextElement"
			></f-input>
		</f-div>
		<f-div
			v-if="nodes.length > 0"
			width="hug-content"
			height="fill-container"
			align="middle-left"
			padding="small"
			:style="{ padding: '0 20px' }"
		>
			<f-text>{{ currentHighlightedELementIndex + 1 }} / {{ nodes.length }}</f-text>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { CSSProperties, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";
import { parse } from "xpath-next";

import { getErrorMessage } from "@/utils";

type XPathInspectorState = {
	isEnabled: boolean;
};

export const xPathInspectorState = reactive<XPathInspectorState>({
	isEnabled: isXPathSelectorEnabled()
});

export function isXPathSelectorEnabled() {
	try {
		const xPathState: XPathInspectorState | null = JSON.parse(
			localStorage.getItem("XPathInspectorState") ?? ""
		);
		return Boolean(xPathState?.isEnabled);
	} catch (err) {
		return false;
	}
}

watch(
	() => xPathInspectorState.isEnabled,
	newValue => {
		localStorage.setItem(
			"XPathInspectorState",
			JSON.stringify({ ...xPathInspectorState, isEnabled: newValue })
		);
	}
);
</script>

<script setup lang="ts">
const xpathInput = ref("");
const nodes = ref<HTMLElement[]>([]);
const parentStyle = ref<CSSProperties>({
	position: "fixed",
	bottom: 0,
	width: "100%",
	zIndex: 1000000
});
const xPathError = ref("");
const currentHighlightedElement = ref<HTMLElement | null>(null);
const currentHighlightedELementIndex = ref(0);

const observer = new MutationObserver(searchXPath);

watch(xpathInput, searchXPath);

watch(nodes, (newNodes, prevNodes) => {
	prevNodes.forEach(prevNode => {
		cleanNode(prevNode);
	});

	const [firstNode] = newNodes;

	newNodes.forEach(newNode => {
		matchNode(newNode);
	});

	if (
		firstNode &&
		(!currentHighlightedElement.value || !newNodes.includes(currentHighlightedElement.value))
	) {
		currentHighlightedElement.value = firstNode;
	}

	hightlightSelectedElement();
});

watch(
	currentHighlightedElement,
	(_newNode, prevNode) => {
		cleanNode(prevNode);

		hightlightSelectedElement();
	},
	{ deep: true, immediate: true }
);

onMounted(() => {
	observer.observe(document.body, {
		childList: true,
		subtree: true,
		attributes: false
	});
});

onBeforeUnmount(() => {
	observer.disconnect();
});

function hightlightSelectedElement() {
	const element = currentHighlightedElement.value;

	if (element && "classList" in element) {
		highlightNode(element);
	}
}

function goToNextElement(event: KeyboardEvent) {
	// On enter start highlighting elements
	if (event.key === "Enter") {
		if (currentHighlightedELementIndex.value >= nodes.value.length - 1) {
			currentHighlightedELementIndex.value = 0;
		} else {
			currentHighlightedELementIndex.value++;
		}

		const node = nodes.value[currentHighlightedELementIndex.value];
		if (node) {
			currentHighlightedElement.value = node;
			node.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}
}

function searchXPath() {
	try {
		xPathError.value = "";
		if (xpathInput.value.length === 0) {
			xPathError.value = "";
			nodes.value = [];
			return;
		}

		const newNodes = parse(xpathInput.value).select({ node: document.body, isHtml: true });
		nodes.value = newNodes;
	} catch (err) {
		nodes.value = [];
		currentHighlightedElement.value = null;
		xPathError.value = getErrorMessage(err);
	}
}

function matchNode(node?: HTMLElement) {
	if (!node || !("style" in node)) {
		return;
	}

	node.style.transform = "translateZ(0)";
	node.style.boxShadow = "0px 0px 150px 10px #ff8800 inset";
}

function highlightNode(node?: HTMLElement) {
	if (!node || !("style" in node)) {
		return;
	}

	node.style.transform = "translateZ(0)";
	node.style.boxShadow = "0px 0px 50px 10px #0074ff inset";
	node.style.outline = "1px solid #0074ff";
}

function cleanNode(node: HTMLElement | null | undefined) {
	if (!node || !("style" in node)) {
		return;
	}

	node.style.transform = "";
	node.style.boxShadow = "";
	node.style.outline = "";
}
</script>

<style lang="scss">
.xpath-matching-element,
.xpath-highlighted-element {
	transform: translateZ(0);
	box-shadow: 0px 0px 150px 10px #ff8800 inset;
}

.xpath-highlighted-element {
	box-shadow: 0px 0px 50px 10px #0074ff inset;
	outline: 1px solid #0074ff;
}
</style>
