export function filterByNameString(obj: { name: string }, filterBy: string) {
	const lowerSearchString = filterBy.toLocaleLowerCase();
	if (!lowerSearchString) {
		return true;
	}
	const lowerAccountName = obj.name.toLocaleLowerCase();
	if (lowerAccountName.includes(lowerSearchString)) {
		return true;
	}
	return false;
}
