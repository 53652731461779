<template>
	<table
		v-if="variableTable.rows.length > 0"
		:id="`table-${variableTable.id}`"
		class="flow-table fixed-layout border-bottom width-100-per"
		:class="{ 'margin-bt-32': isOpen }"
	>
		<thead class="flow-table-head">
			<tr class="flow-table-row element-light rounded-corners">
				<th
					:id="`env-${variableTable.id}`"
					class="text-align-start v-align-middle width-400 sticky-horizontal sticky-vertical add-var-realtive-trigger"
					:style="{ zIndex: 3 }"
				>
					<Container padding="12px 12px 12px 16px" :gap="20">
						<Icon
							:rotate="isOpen ? 0 : 180"
							name="i-chevron-up"
							type="filled"
							size="small"
							state="light"
							@click="isOpen = !isOpen"
						/>
						<Container :gap="10" :padding="0">
							<Typography type="p1" overflow="ellipsis" color="light">{{
								variableTable.title
							}}</Typography>
							<Tag shape="rounded" size="small">{{ filteredRows.length }}</Tag>
							<Icon
								:name="sorting === 'desc' ? 'i-arrow-down' : 'i-arrow-up'"
								type="filled"
								size="x-small"
								state="light"
								@click="toggleSorting()"
							/>
							<Button
								state="icon"
								size="x-small"
								class="show-on-hover"
								@click="showAddVariableModalById(`#env-${variableTable.id}`)"
							>
								<Icon
									v-tooltip="{
										content: `Add new variable to environments`,
										placement: 'bottom-start'
									}"
									name="i-plus"
									state="dark"
									size="xx-small"
									type="filled"
									:effects="false"
								/>
							</Button>
						</Container>
					</Container>
				</th>
				<th
					v-for="(environment, envIdx) in filteredEnvironments"
					:id="`env-${variableTable.id}-${environment.env.name}`"
					:key="`env-header-${variableTable.id}-${environment.env.name}-${envIdx}`"
					class="text-align-start v-align-middle sticky-vertical add-var-realtive-trigger"
				>
					<Container padding="12px 12px 12px 0px">
						<Pictogram shape="hexagon" size="m" state="avatar" :label="environment.env.name" />
						<Typography type="p2" color="light" transform="uppercase" overflow="ellipsis">{{
							environment.env.name
						}}</Typography>
						<Icon
							v-tooltip="{
								content: `Go to ${environment.env.name}`,
								placement: 'bottom-start'
							}"
							name="i-view"
							class="show-on-hover"
							state="primary"
							size="20px"
							type="filled"
							@click="goToEnvironment(environment)"
						/>
						<Button
							state="icon"
							size="x-small"
							class="show-on-hover"
							@click="
								showAddVariableModalById(
									`#env-${variableTable.id}-${environment.env.name}`,
									environment.env.id
								)
							"
						>
							<Icon
								v-tooltip="{
									content: `Add new variable to ${environment.env.name}`,
									placement: 'bottom-start'
								}"
								name="i-plus"
								state="dark"
								size="xx-small"
								type="filled"
								:effects="false"
							/>
						</Button>
					</Container>
				</th>
			</tr>
		</thead>
		<tbody v-if="isOpen" class="flow-table-body">
			<tr
				v-for="variableRow in filteredRows"
				:key="variableRow.key + variableRow.varType"
				:data-qa-variables-list-row="variableRow.key"
				class="flow-table-row highlight-on-hover"
			>
				<td class="flow-table-cell cell-padding-12 sticky-horizontal">
					<Container :padding="0" :gap="16">
						<template v-if="!isSingleEnvironment">
							<Container v-if="variableRow.state === 'error'" padding="0px 0px 0px 14px">
								<f-icon
									source="i-close"
									size="x-small"
									state="warning"
									tooltip="Variable is not declared"
								/>
							</Container>
							<Container v-if="variableRow.state === 'equal'" padding="0px 0px 0px 12px">
								<f-icon source="i-equal" size="small" state="subtle" />
							</Container>
							<Container v-if="variableRow.state === 'secret'" padding="0px 0px 0px 12px">
								<f-icon source="i-lock" size="small" state="subtle" tooltip="Value is masked" />
							</Container>
							<Container v-if="variableRow.state === 'notequal'" padding="0px 0px 0px 12px">
								<f-icon source="i-not-equal" size="small" state="subtle" />
							</Container>
						</template>

						<Container v-else padding="0px 0px 0px 12px">&nbsp;</Container>

						<Typography
							type="p1"
							:color="!isSingleEnvironment && variableRow.state === 'error' ? 'warning' : 'dark'"
							family="logs"
							>{{ variableRow.key }}</Typography
						>

						<Tag v-if="variableRow.varType === 'tf_var'" size="small" background="#924AEE">{{
							variableRow.varType
						}}</Tag>
					</Container>
				</td>
				<template
					v-for="(environment, envIdx) in filteredEnvironments"
					:key="environment.env.name + envIdx"
				>
					<VariableListColumn
						v-if="variableRow.columns[environment.env.name]"
						:show-values="showValues"
						:variable-column="variableRow.columns[environment.env.name]"
						:column-name="environment.env.name"
					/>
				</template>
			</tr>
		</tbody>
	</table>
</template>

<script lang="ts">
import { Button, Container, Icon, Pictogram, Tag, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { RenderedEnvironment, VariableEnvTable } from "./variable-list-types";
import VariableListColumn from "./VariableListColumn.vue";

export default defineComponent({
	name: "VariableListEnvironmentTable",

	components: {
		Button,
		Container,
		Icon,
		Pictogram,
		Tag,
		Typography,
		VariableListColumn
	},

	props: {
		variableTable: {
			type: Object as PropType<VariableEnvTable>,
			required: true
		},

		filteredEnvironments: {
			type: Array as PropType<RenderedEnvironment[]>,
			required: true
		},

		showValues: {
			type: Boolean,
			required: true
		}
	},

	emits: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		"add-variable": (_target: string, _scopeIds?: string[]) => {
			return true;
		}
	},

	data: () => ({
		sorting: "asc" as Sorting,
		isOpen: true
	}),

	computed: {
		isSingleEnvironment() {
			return this.filteredEnvironments.length === 1;
		},

		filteredRows() {
			const sortedRows = this.variableTable.rows.slice().sort((listA, listB) => {
				if (this.sorting === "desc") {
					return listB.key.localeCompare(listA.key);
				}
				return listA.key.localeCompare(listB.key);
			});

			const filteredEnvironmentNames = this.filteredEnvironments.map(
				environment => environment.env.name
			);

			// Filter out all rows that don't match the current filter
			// i.e. all of them have empty variables, which means they are not relevant to this condition
			return sortedRows.filter(row => {
				const isAllErrors =
					row.state === "error" &&
					Object.entries(row.columns).every(([row_, column]) =>
						filteredEnvironmentNames.includes(row_) ? column.state === "error" : true
					);

				return !isAllErrors;
			});
		}
	},

	methods: {
		showAddVariableModalById(eleId: string, envId?: string) {
			const ids = envId ? [envId] : this.filteredEnvironments.map(env => env.env.id);
			this.$emit("add-variable", eleId, ids);
		},

		toggleSorting() {
			this.sorting = this.sorting === "asc" ? "desc" : "asc";
		},

		goToEnvironment(environment: RenderedEnvironment) {
			this.$router.push({
				name: "envDetail",
				params: {
					orgId: environment.env.orgId,
					projectId: environment.env.projId,
					envId: environment.env.id
				}
			});
		}
	}
});

type Sorting = "asc" | "desc";
</script>
