<template>
	<AuthWrapper
		header="Reset Password"
		alternate-auth-text="Or, sign up with"
		:show-back-icon="true"
		@back-click="redirectMixin({ routeName: 'login' })"
	>
		<p class="fc-normal paragraph-1 margin-bt-24">
			Enter your email address below and we will send you a password reset email.
		</p>
		<SendResetPasswordLinkForm />
		<template #alt-auth>
			<WrapperSignInIdentityProvider />
		</template>
	</AuthWrapper>
</template>

<script>
import { defineComponent } from "vue";

import SendResetPasswordLinkForm from "@/modules/auth/components/SendResetPasswordLinkForm.vue";
import WrapperSignInIdentityProvider from "@/modules/auth/components/WrapperSignInIdentityProvider.vue";
import AuthWrapper from "@/modules/auth/views/AuthWrapper.vue";
import routerMixin from "@/shared/mixins/routerMixin";

export default defineComponent({
	name: "SendResetPasswordLink",

	components: {
		WrapperSignInIdentityProvider,
		SendResetPasswordLinkForm,
		AuthWrapper
	},

	mixins: [routerMixin]
});
</script>
