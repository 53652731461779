<template>
	<Container overflow="visible" :padding="16" class="cursor-pointer" @click.stop="openEnv(env)">
		<Slab :no-padding="true" overflow="visible">
			<Container :padding="0" align="left top">
				<Pictogram
					:loading="isPipelineRunning"
					:border-state="isPipelineRunning ? 'primary' : 'default'"
					shape="hexagon"
					state="default"
					size="xl"
				>
					<template v-if="getEmoji(env)">{{ getEmoji(env) }}</template>
					<Icon v-else name="i-dev" type="filled" :effects="false" class="cursor-pointer" />
				</Pictogram>

				<Container direction="column" :gap="4" :padding="0">
					<Container :gap="12" padding="0px 16px 0px 0px">
						<Typography
							v-tooltip="{
								content: env.name,
								container: 'div.flow-layout',
								placement: 'auto'
							}"
							:data-qa-env-wid-name="env.name"
							type="h3"
							color="dark"
							overflow="ellipsis"
							>{{ env.name }}</Typography
						>
						<ClassificationTag
							v-if="env.classification"
							:classification-id="env.classification.id"
						/>
						<InfoPopover
							v-if="assignedCloudCred && canAccessCreds"
							data-qa-env-widget-assigned-cloud-info
						>
							<Icon
								:name="assignedCloudCred.icon"
								size="small"
								type="filled"
								:effects="false"
								data-qa-env-widget-assigned-cloud-info-icon
							/>
							<template #body>
								<Typography type="p2" data-qa-env-widget-assigned-cloud-name>{{
									assignedCloudCred.name
								}}</Typography>
							</template>
						</InfoPopover>
					</Container>
					<CustomTimeStamp
						v-if="env.updatedAt"
						:time="env.updatedAt"
						prefix="Updated"
						:user="env.updatedBy"
						:data-qa="`env-wid-created-at-date-${env.name}`"
					/>
				</Container>
			</Container>
			<template #secondary-actions>
				<Container :gap="16" :padding="0" @click.stop="() => {}">
					<PopOver :open="modalState !== 'closed'" @overlay-click="modalState = 'closed'">
						<Button
							v-tooltip="{
								content: 'Add new',
								placement: 'top-start'
							}"
							size="small"
							type="primary"
							state="icon"
							data-qa-env-wid-plus-btn
							:data-qa-env-plus-name="env.name"
							@click.stop="modalState = 'add-new'"
						>
							<Icon
								id="add-variable-model"
								name="i-plus"
								size="small"
								type="filled"
								:effects="false"
							/>
						</Button>
						<template #content>
							<!--Start : Widget + button options-->
							<Wrapper
								v-if="modalState === 'add-new'"
								border-radius="4px"
								background="element"
								:border="true"
								width="255px"
							>
								<Container direction="column" :padding="0" :gap="0">
									<Slab size="large" :effects="false" border-type="solid">
										<Typography type="p2" weight="medium" color="dark">Add new</Typography>
										<template #secondary-actions>
											<Icon
												name="i-close"
												type="filled"
												size="x-small"
												@click.stop="modalState = 'closed'"
											/>
										</template>
									</Slab>
									<Slab
										size="large"
										type="transparent"
										:effects="true"
										class="cursor-pointer"
										@click.stop="modalState = 'add-variables'"
									>
										<template #primary-action>
											<Icon
												name="i-variable"
												type="filled"
												state="light"
												size="small"
												data-qa-env-wid-add-variable-model-btn
												:effects="false"
											/>
										</template>
										<Typography type="h5" weight="regular">Variables</Typography>
									</Slab>
									<Slab
										size="large"
										type="transparent"
										:effects="true"
										class="cursor-pointer"
										@click.stop="modalState = 'app-list'"
									>
										<template #primary-action>
											<Icon
												name="i-app"
												type="filled"
												state="light"
												size="small"
												:effects="false"
												data-qa-env-wid-add-application-btn
											/>
										</template>
										<Typography type="h5" weight="regular">Application</Typography>
									</Slab>
								</Container>
							</Wrapper>
							<!--END : Widget + button options-->

							<AddVariableContent
								v-if="modalState == 'add-variables'"
								:pre-selected-ids="[env.id]"
								@close-dialog="modalState = 'closed'"
							/>

							<CreateAndDeployApp
								v-if="project"
								:target="`[data-qa-env-plus-name='${env.name}']`"
								:project="project"
								:environment="env"
								:open="modalState === 'app-list'"
								@close="modalState = 'closed'"
								@back="modalState = 'closed'"
							/>
						</template>
					</PopOver>
					<PopOver :open="openEditEnv" @overlay-click="openEditEnv = false">
						<Container :padding="0" :gap="12">
							<Icon
								v-tooltip="{
									content: 'Environment settings',
									placement: 'top-start'
								}"
								name="i-setting"
								size="20px"
								type="filled"
								:data-qa-env-wiget-setting-btn="env.name"
								@click.stop="openEnvSetting"
							/>
						</Container>
						<template #content>
							<EnvEditPopOver v-if="openEditEnv" :env="env" @close="openEditEnv = false" />
						</template>
					</PopOver>
				</Container>
			</template>
		</Slab>
	</Container>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Icon,
	InfoPopover,
	Pictogram,
	PopOver,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import ClassificationTag from "@/modules/credentials/components/credential-assign/ClassificationTag.vue";
import { CREDENTIAL_TYPE_ICONS } from "@/modules/credentials/constants";
import { getCredByScope } from "@/modules/credentials/credential-store";
import EnvEditPopOver from "@/modules/env-create/components/EnvEditPopOver.vue";
import CreateAndDeployApp from "@/modules/env-list/components/CreateAndDeployApp.vue";
import { orgStore } from "@/modules/org/org-store";
import { projectStore } from "@/modules/project-list/project-store";
import AddVariableContent from "@/modules/variables-list/AddVariableContent.vue";
import { VGEntities } from "@/protocol/common";
import { CredScope } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { CustomTimeStamp } from "@/shared/components";
import { getEmoji } from "@/utils";

export default defineComponent({
	name: "EnvWidgetHeader",

	components: {
		AddVariableContent,
		CreateAndDeployApp,
		ClassificationTag,
		CustomTimeStamp,
		EnvEditPopOver,
		InfoPopover,
		Typography,
		Pictogram,
		Container,
		Wrapper,
		PopOver,
		Button,
		Icon,
		Slab
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		},

		isPipelineRunning: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		openEditEnv: false,
		modalState: "closed" as "closed" | "app-list" | "add-variables" | "add-new"
	}),

	computed: {
		assignedCloudCred() {
			const [assignedCred] = getCredByScope({
				entityId: this.env.id,
				scope: CredScope.cloud
			});

			return assignedCred && assignedCred.inheritedFrom === VGEntities.invalid_entity
				? {
						...assignedCred,
						icon: assignedCred.type ? CREDENTIAL_TYPE_ICONS[assignedCred.type] : ""
				  }
				: null;
		},

		project() {
			return projectStore.projects[this.env.projId];
		},

		canAccessCreds() {
			return orgStore.isUserOrgAdmin;
		}
	},

	methods: {
		getEmoji,

		openEnv(env: environment) {
			const params: EnvRoute = {
				orgId: env.orgId,
				projectId: env.projId,
				envId: env.id
			};
			this.$router.push({
				name: "envDetail",
				params
			});
		},

		openEnvSetting() {
			this.openEditEnv = true;
		},

		// eslint-disable-next-line vue/no-unused-properties
		togglePopover() {
			this.modalState = "add-variables";
		}
	}
});

export type EnvRoute = {
	projectId?: string;
	orgId?: string;
	envId?: string;
};
</script>
