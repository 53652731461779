<script lang="ts">
import { Button, Container, Icon, PopOver, Typography } from "@cldcvr/flow-vue3";
import { PropType, computed, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

import { applicationStore } from "@/modules/application/application-store";
import ApplicationDeploymentModal from "@/modules/application/components/ApplicationDeploymentModal.vue";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import AppDeploymentConfirmationPopover from "@/modules/application-deployment/components/AppDeploymentConfirmationPopover.vue";
import DelDepPopover from "@/modules/application-deployment/components/DelDepPopover.vue";
import DepUndeployPopver from "@/modules/application-deployment/components/DepUndeployPopver.vue";
import { DeploymentStatusInfo } from "@/modules/application-deployment/constants";
import { routerToAppPipeline } from "@/modules/application-deployment/mixins/AppCommonMixin";
import { AppDeployment, DeploymentJobAction } from "@/protocol/deployment";
import { CustomTimeStamp } from "@/shared/components";
import ConfirmCancel from "@/shared/components/application/ConfirmCancel.vue";
import {
	JOB_STATUS,
	PIPELINE_ACTIVE_JOB_STATUSES,
	PIPELINE_FINISHED_JOB_STATUSES,
	PipelineJobStatus
} from "@/shared/pipeline-constants";

export default {
	name: "DeploymentsListHeader"
};
</script>

<script lang="ts" setup>
const props = defineProps({
	projectId: {
		type: String,
		required: true
	},

	deployment: {
		type: Object as PropType<AppDeployment>,
		required: true
	},

	statusTextWithIcon: {
		type: Object as PropType<DeploymentStatusInfo>
	}
});

const { projectId, deployment } = toRefs(props);

const deploymentToEdit = ref<AppDeployment | null>(null);
const deploymentToDeploy = ref<AppDeployment | null>(null);
const deploymentToUndeploy = ref<AppDeployment | null>(null);
const deploymentToDelete = ref<AppDeployment | null>(null);

//@todo - implement
const deploymentToCancel = ref<AppDeployment | null>(null);

const router = useRouter();

const app = computed(() => {
	const projectApps = applicationStore.projectApps[projectId.value];
	return projectApps?.find(app => app.id === deployment.value.appId);
});

const isJobDone = computed(() => {
	const jobStatus = deployment.value.lastJob?.jobStatus as PipelineJobStatus | undefined;
	return jobStatus ? PIPELINE_FINISHED_JOB_STATUSES.includes(jobStatus) : false;
});

const isDeployed = computed(() => {
	return deployment.value.lastSuccessfulJob?.jobType === DeploymentJobAction.deploy;
});

const isPipelineRunning = computed(() => {
	return PIPELINE_ACTIVE_JOB_STATUSES.includes(
		deployment.value.lastJob?.jobStatus as PipelineJobStatus
	);
});

const isPipelineCancelling = computed(() => {
	return deployment.value.lastJob?.jobStatus === JOB_STATUS.CANCELLING;
});

function openPipeline() {
	const { lastJob } = deployment.value;

	routerToAppPipeline(router, deployment.value, lastJob?.jobType ?? DeploymentJobAction.deploy);
}

function cancelDeployment(deployment: AppDeployment) {
	deploymentToCancel.value = null;

	const { lastJob } = deployment;

	if (!lastJob || !deployment.orgId || !deployment.projId) {
		return;
	}

	applicationDeploymentStore.CANCEL_DEPLOYMENT({
		id: lastJob.id,
		depId: lastJob.depId,
		envId: deployment.envId!,
		orgId: deployment.orgId,
		projId: deployment.projId
	});
}
</script>

<template>
	<Container direction="column" padding="0" :shrink="0" :gap="0">
		<Container :padding="0" :shrink="0" :gap="0" align="left top" overflow="visible">
			<Container direction="column" padding="0" :gap="4">
				<Container :padding="0" :flex-wrap="true">
					<Typography
						v-tooltip="{
							content: deployment?.name
						}"
						type="h3"
						color="dark"
						overflow="ellipsis"
						:data-qa-deployments-current-app-name="deployment?.name"
						>{{ deployment?.name }}</Typography
					>

					<f-icon
						v-if="deployment?.description"
						data-qa-deployments-app-description
						source="i-question-filled"
						state="secondary"
						:tooltip="deployment?.description"
					></f-icon>
				</Container>

				<CustomTimeStamp
					v-if="deployment?.updatedAt"
					type="p2-para"
					:time="deployment.updatedAt"
					prefix="Updated"
					:user="deployment.updatedBy"
					data-qa="deployment-updated-date"
				/>

				<Container v-if="statusTextWithIcon?.text" data-qa-status-text :padding="0" :gap="4">
					<Icon
						v-if="statusTextWithIcon!.icon"
						:name="statusTextWithIcon!.icon!.name"
						:state="statusTextWithIcon!.icon!.state"
						type="filled"
						size="small"
						:effects="false"
						:animate="statusTextWithIcon!.icon!.loading"
						:data-qa-deployment-status-icon="statusTextWithIcon!.text"
					/>

					<CustomTimeStamp
						v-if="statusTextWithIcon!.time"
						type="p2-para"
						:time="statusTextWithIcon!.time"
						:prefix="statusTextWithIcon!.text"
						data-qa="deployment-status-date"
					/>
				</Container>
				<Typography v-else data-qa-status-text type="p3" color="light">Not deployed</Typography>
			</Container>

			<Container v-if="deployment" :flex-wrap="true" :padding="0" :grow="1" align="right center">
				<Button
					v-if="deployment?.lastJob"
					state="text"
					size="small"
					data-qa-field="view-logs"
					data-qa-view-logs
					@click="openPipeline"
				>
					View logs
				</Button>

				<Button
					size="small"
					type="success"
					data-qa-field="redeploy-deployment"
					data-qa-deploy-app
					:disabled="isPipelineRunning"
					@click="deploymentToDeploy = deployment"
				>
					<Icon name="i-launch" color="gray-600" size="small" :effects="false" />
					{{ isJobDone ? "Re-deploy" : "Deploy" }}
				</Button>

				<PopOver v-if="isPipelineRunning" :open="Boolean(deploymentToCancel)">
					<Button
						size="small"
						type="warning"
						data-qa-cancel-pipeline
						:loading="isPipelineCancelling"
						@click="deploymentToCancel = deployment"
					>
						Cancel pipeline
					</Button>
					<template #content>
						<ConfirmCancel
							v-if="deploymentToCancel"
							data-qa-cancel-deployment
							:name="deploymentToCancel.name!"
							@confirm="cancelDeployment(deploymentToCancel)"
							@close="() => (deploymentToCancel = null)"
						/>
					</template>
				</PopOver>

				<!-- Undeploy popover -->
				<PopOver
					v-if="isDeployed && !isPipelineRunning"
					:open="Boolean(deploymentToUndeploy)"
					@overlay-click="deploymentToUndeploy = null"
				>
					<Button
						size="small"
						type="error"
						data-qa-field="undeploy-deployment"
						data-qa-destroy-app
						@click="deploymentToUndeploy = deployment"
					>
						<Icon name="i-dev" color="gray-600" size="small" :effects="false" />
						Undeploy
					</Button>

					<template #content>
						<DepUndeployPopver
							v-if="deploymentToUndeploy"
							:dep-entity="deploymentToUndeploy"
							:close-un-deploy-popover="() => (deploymentToUndeploy = null)"
							@back="() => (deploymentToUndeploy = null)"
						/>
					</template>
				</PopOver>

				<!-- Edit deployment popover -->
				<PopOver v-if="app" :open="Boolean(deploymentToEdit)">
					<f-icon
						data-qa-field="edit-deployment"
						:data-qa-app-widget-edit="deployment.name"
						tooltip="Edit deployment"
						source="i-setting"
						color="gray-600"
						size="medium"
						clickable
						@click="deploymentToEdit = deployment"
					></f-icon>

					<template #content>
						<ApplicationDeploymentModal
							v-if="deploymentToEdit"
							:app="app"
							:project-id="projectId"
							:environment-id="deploymentToEdit.envId"
							:disable-back-button="true"
							:app-deployment="deploymentToEdit"
							@close="() => (deploymentToEdit = null)"
						/>
					</template>
				</PopOver>

				<!-- Delete deployment popover -->
				<PopOver :open="Boolean(deploymentToDelete)" @overlay-click="deploymentToDelete = null">
					<f-icon
						:tooltip="
							isDeployed ? 'Undeploy the application before removing it' : 'Remove deployment'
						"
						source="i-delete"
						color="gray-600"
						size="medium"
						clickable
						:disabled="isDeployed"
						data-qa-field="remove-deployment"
						@click="deploymentToDelete = deployment"
					></f-icon>

					<template #content>
						<DelDepPopover
							v-if="deploymentToDelete"
							:dep-entity="deploymentToDelete"
							:is-del-dep-popover-open="Boolean(deploymentToDelete)"
							:close-delete-dep-popover="() => (deploymentToDelete = null)"
							@back="() => (deploymentToDelete = null)"
						/>
					</template>
				</PopOver>
			</Container>

			<!-- Deployment confirmation popover -->
			<AppDeploymentConfirmationPopover
				v-if="deploymentToDeploy && Boolean(deploymentToDeploy)"
				target="[data-qa-deploy-app]"
				:should-redirect-to-pipeline="true"
				:deployment="deploymentToDeploy"
				@close="() => (deploymentToDeploy = null)"
				@back="() => (deploymentToDeploy = null)"
			/>
		</Container>
	</Container>
</template>
