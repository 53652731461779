<template>
	<PopOver
		:open="isModelOpen"
		:data-qa="`${credScope}-cred-assign-modal`"
		@overlay-click="safelyCloseModal"
	>
		<Container :padding="0" :gap="0" align="center bottom">
			<slot name="assignCredBtn" :show-cred-assign-modal="showCredAssignModal">
				<AssignedCredIcon
					v-if="assignedCreds.length"
					:cred-scope="credScope"
					:entity-kind="entityKind"
					:is-loading="isLoadingEntityCreds"
					:assigned-creds="assignedCreds"
					:entity="entity"
					@on-click="showCredAssignModal"
				/>
				<NoAssignedCredCard
					v-else
					:is-loading="isLoadingEntityCreds"
					:cred-category-detail="credCategoryDetail"
					:entity-kind="entityKind"
					:cred-scope="credScope"
					@on-click="showCredAssignModal"
				/>
			</slot>
		</Container>
		<template #content>
			<AssignCredentialList
				ref="AssignCredentialList"
				:entity="entity"
				:git-form-ref="gitFormRef"
				:cred-scope="credScope"
				:entity-kind="entityKind"
				:unassign-credential="unassignCredential"
				@force-close="closeCredAssignModal({ closeSilently: true })"
				@on-close="closeCredAssignModal({ closeSilently: false })"
				@on-credential-assigned="$emit('onCredentialAssigned')"
			>
				<template #header-item>
					<slot name="header-item" />
				</template>
				<template #header-step>
					<slot name="header-step" />
				</template>
			</AssignCredentialList>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { Container, PopOver } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import AssignCredentialList from "@/modules/credentials/components/credential-assign/AssignCredentialList.vue";
import { credentialStore, getCredByScope } from "@/modules/credentials/credential-store";
import {
	AssignCredToEntities,
	credCategoryDetails,
	entityKind,
	SupportedCredScope
} from "@/modules/credentials/credential-types";
import { CredScope, Creds } from "@/protocol/identity";
import { EnvCreateStepFlowService, GithubOauthStorageService } from "@/services/storage-service";

import AssignedCredIcon from "./AssignedCredIcon.vue";
import NoAssignedCredCard from "./NoAssignedCredCard.vue";

export default defineComponent({
	name: "AssignCredentialModal",

	components: {
		AssignCredentialList,
		NoAssignedCredCard,
		AssignedCredIcon,
		Container,
		PopOver
	},

	props: {
		credScope: {
			type: String as PropType<SupportedCredScope>,
			required: true
		},

		entity: {
			type: Object as PropType<AssignCredToEntities>,
			required: true
		},

		entityKind: {
			type: String as PropType<entityKind>,
			required: true
		},

		gitFormRef: {
			type: String,
			default: ""
		},

		unassignCredential: {
			type: Object as PropType<Creds | null>,

			default: () => {
				return null;
			}
		}
	},

	data: () => ({
		isModelOpen: false
	}),

	computed: {
		credCategoryDetail() {
			return credCategoryDetails[this.credScope];
		},

		assignedCreds() {
			return getCredByScope({
				entityId: this.entity.id,
				scope: this.credScope
			});
		},

		isLoadingEntityCreds() {
			return !!credentialStore.loadingEntityCredentials[this.entity.id];
		}
	},

	mounted() {
		if (this.credScope === CredScope.git) {
			this.githubModalObserver();
		}
	},

	methods: {
		showCredAssignModal() {
			this.isModelOpen = true;
		},

		closeCredAssignModal({ closeSilently }: { closeSilently: boolean }) {
			this.isModelOpen = false;
			EnvCreateStepFlowService.toggleFlow({ isEnabled: false });
			if (!closeSilently) {
				// Only close the flow when the user has forced closed the modal
				EnvCreateStepFlowService.toggleFlow({ isEnabled: false });
				this.$emit("closed", true);
			}
		},

		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === this.gitFormRef) {
				// render github form
				this.showCredAssignModal();
			}
		},

		safelyCloseModal() {
			const ref = this.$refs.AssignCredentialList as InstanceType<typeof AssignCredentialList>;
			if (ref) {
				ref.safelyCloseModal();
				this.$emit("closed", true);
			}
		}
	}
});
</script>
==
