import { IconState } from "@cldcvr/flow-vue3";

import { AppDeployment, DeploymentJobAction } from "@/protocol/deployment";
import {
	PipelineJobStatus,
	JOB_STATUS,
	PIPELINE_UNFINISHED_JOB_STATUSES,
	JOB_STEP_STATUS
} from "@/shared/pipeline-constants";

export type DeploymentStatusInfo = {
	text: string;
	icon: {
		name: string;
		state: IconState;
		loading?: boolean;
	} | null;
	time: string;
};

export function pipelineStatusMessageWithIcon(deployment: AppDeployment): DeploymentStatusInfo {
	const { lastJob } = deployment;
	const lastJobStatus = lastJob?.jobStatus as PipelineJobStatus | undefined;

	const hasLastPipelineFailed =
		lastJobStatus === JOB_STATUS.FAILED || lastJobStatus === JOB_STATUS.CANCELLED;

	const isCurrentJobActive = lastJobStatus
		? PIPELINE_UNFINISHED_JOB_STATUSES.includes(lastJobStatus)
		: false;

	const lastJobType = lastJob?.jobType;

	const icon = getStatusIcon(deployment);

	if (hasLastPipelineFailed && !isCurrentJobActive) {
		const text = `${lastJobType === "deploy" ? "Deployment" : "Undeployment"} ${
			lastJob?.jobStatus === "canceled" ? "cancelled" : "failed"
		}`;

		return {
			text,
			icon,
			time: lastJob?.updatedAt ?? ""
		};
	}

	if (isCurrentJobActive) {
		const text = `${JOB_STEP_STATUS[lastJob?.jobStatus as PipelineJobStatus]} ${deployment.name}`;
		return {
			text,
			icon,
			time: lastJob?.updatedAt ?? ""
		};
	}

	const { lastSuccessfulJob } = deployment;
	const deployedTime = lastSuccessfulJob?.updatedAt;

	if (lastSuccessfulJob?.jobType === "deploy" && deployedTime) {
		return { text: "Deployed", icon, time: deployedTime };
	}

	return {
		text: !lastJobType ? "" : "Undeployed successfully",
		icon,
		time: lastJob?.updatedAt ?? ""
	};
}

function getStatusIcon(
	deployment: AppDeployment
): { name: string; state: IconState; loading?: boolean } | null {
	const jobStatus = deployment.lastJob?.jobStatus as PipelineJobStatus;
	const lastJobType = deployment.lastJob?.jobType;
	const type = lastJobType === DeploymentJobAction.deploy ? "app-deploy" : "app-destroy";

	switch (jobStatus) {
		case JOB_STATUS.ACTIVE: {
			return lastJobType === DeploymentJobAction.undeploy
				? { name: "i-loader-danger", state: "primary", loading: true }
				: {
						name: "i-loader-success",
						state: "primary",
						loading: true
				  };
		}

		case JOB_STATUS.DONE:
			return lastJobType === DeploymentJobAction.undeploy
				? { name: "i-destroy", state: "error", loading: false }
				: {
						name: type === "app-deploy" ? "i-launch" : "i-tick",
						state: type === "app-deploy" ? "success" : "primary",
						loading: false
				  };

		case JOB_STATUS.CANCELLING:
			return { name: "i-loader-danger", state: "primary", loading: true };

		case JOB_STATUS.CANCELLED:
			return { name: "i-stop", state: "error", loading: false };

		case JOB_STATUS.FAILED:
			return {
				name: "i-alert",
				state: "error",
				loading: false
			};
	}

	return null;
}
