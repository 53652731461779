<template>
	<Container
		direction="column"
		:padding="0"
		:shrink="0"
		:gap="6"
		overflow="visible"
		align="center bottom"
	>
		<Container
			v-if="stateIcon || job?.createdAt"
			:padding="0"
			:shrink="0"
			:gap="6"
			:style="{ marginTop: '-18px' }"
		>
			<Icon
				v-if="stateIcon"
				:name="stateIcon.name"
				size="x-small"
				:data-qa-pipeline-status="type"
				:animate="stateIcon.animate"
				:color="stateIcon.color"
				:effects="false"
			/>
			<CustomTimeStamp
				v-if="job?.createdAt"
				:time="job.createdAt"
				:prefix="statusText"
				:data-qa-pipeline-duration-for="type"
				type="p4"
				color="gray-200"
			/>
		</Container>
		<Container :padding="0" :shrink="0" :gap="0" class="shared-env-action">
			<Wrapper
				width="50px"
				height="50px"
				background="gray-500"
				:border-radius="subAction ? undefined : '4px'"
			>
				<InfoPopover>
					<Card
						:type="cardType"
						:background="cardType === 'outlined' ? 'default' : 'border-light'"
						:disabled="!isPipelineIcon && (state === 'loading' || isDestroyDisabled)"
						:clickable="true"
						:[primaryQaAttribute]="type"
						@click="$emit('primary-action')"
					>
						<Container :padding="16" align="center center" :shrink="0" class="height-100-per">
							<Icon
								v-if="!isPipelineIcon"
								:name="iconName"
								:color="iconColor"
								size="small"
								:effects="false"
							/>
							<Icon v-if="isPipelineIcon" name="i-pipe" size="small" :effects="false" />
						</Container>
					</Card>
					<template #body>
						<Container v-if="successTooltip" :padding="0" direction="column" :gap="4">
							<Container
								v-for="[key, value] of successTooltipKeyValues"
								:key="key"
								:padding="0"
								:gap="0"
							>
								<Typography type="p2-para" color="dark">{{ key }}</Typography>
								<Typography type="p2-para" color="gray-200"> : {{ value }} </Typography>
							</Container>
						</Container>
						<Typography v-else type="p2-para" color="dark">{{ primaryActionTooltip }}</Typography>
					</template>
				</InfoPopover>
			</Wrapper>
			<Wrapper
				v-if="subAction"
				v-tooltip="{ content: subAction.tooltip, placement: 'bottom' }"
				width="75px"
				height="50px"
				:background="subAction.background"
			>
				<Card
					type="outlined"
					:disabled="subAction.disabled"
					:clickable="true"
					:[secondaryQaAttribute]="type"
					@click="$emit('secondary-action')"
				>
					<Container :padding="16" align="center center" :shrink="0" class="height-100-per">
						<Icon
							:name="subAction.icon ?? ''"
							:color="subAction.iconColor"
							size="small"
							:effects="false"
						/>
					</Container>
				</Card>
			</Wrapper>
		</Container>
	</Container>
</template>
<script lang="ts">
import {
	Card,
	Container,
	Icon,
	IconColor,
	InfoPopover,
	Typography,
	Wrapper,
	WrapperBackground
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { EnvPipelineViewedJob } from "@/modules/env-pipeline/env-pipeline-types";
import { actionType } from "@/protocol/infra";
import { CustomTimeStamp } from "@/shared/components";

import { PIPELINE_UNFINISHED_JOB_STATUSES, PipelineJobStatus } from "./../../pipeline-constants";
import {
	ACTION_STATE_ICONS,
	ACTION_STATE_TEXT,
	ACTION_TYPE_ICONS,
	ACTION_TYPE_ICON_COLORS,
	SUB_ACTION_STATE
} from "./EnvironmentActionConstants";

export default defineComponent({
	name: "EnvironmentAction",

	components: {
		Wrapper,
		Card,
		Container,
		CustomTimeStamp,
		Icon,
		Typography,
		InfoPopover
	},

	props: {
		type: {
			type: String as PropType<actionType>,
			default: () => "validate"
		},

		disabled: {
			type: Boolean
		},

		state: {
			type: String as PropType<EnvironmentActionState>,
			required: true
		},

		job: {
			type: Object as PropType<EnvPipelineViewedJob>
		},

		successTooltip: {
			type: Object as PropType<Record<string, string> | null>,
			default: () => null
		},

		primaryQaAttribute: {
			type: String,
			default: () => "data-qa-primary-action"
		},

		secondaryQaAttribute: {
			type: String,
			default: () => "data-qa-secondary-action"
		},

		//@todo - this prop should not exist, the primary/secondary actions are confusing to use
		isDestroyDisabled: {
			type: Boolean
		}
	},

	emits: ["primary-action", "secondary-action"],

	computed: {
		iconName() {
			return ACTION_TYPE_ICONS[this.type];
		},

		iconColor() {
			return ACTION_TYPE_ICON_COLORS[this.type];
		},

		statusText() {
			return this.isPipelineInProgress ? "Started" : ACTION_STATE_TEXT[this.state][this.type];
		},

		isPipelineInProgress() {
			return (
				this.job?.status &&
				PIPELINE_UNFINISHED_JOB_STATUSES.includes(this.job.status as PipelineJobStatus)
			);
		},

		stateIcon() {
			const stateIcon = ACTION_STATE_ICONS[this.state][this.type];

			return stateIcon;
		},

		subAction() {
			const subAction = SUB_ACTION_STATE[this.state];

			if (subAction !== null) {
				// If the type is destroy, we need to take disabled state from the prop and not from the constant
				if (this.type === "destroy") {
					return { ...subAction[this.type], disabled: this.disabled };
				}
				return subAction[this.type];
			}

			return null;
		},

		cardType() {
			return this.state === "default" || this.state === "loading" ? "outlined" : "default";
		},

		isPipelineIcon() {
			return this.state === "default" || this.state === "loading" ? false : true;
		},

		primaryActionTooltip() {
			return this.isPipelineIcon || !this.statusText || this.state === "done"
				? "View logs"
				: this.statusText;
		},

		successTooltipKeyValues() {
			return this.successTooltip ? Object.entries(this.successTooltip) : [];
		}
	}
});

export type EnvironmentActionState = PipelineJobStatus | "loading" | "default";

export type EnvironmentSubAction = {
	background: WrapperBackground;
	icon: string;
	iconColor: IconColor;
	disabled?: boolean;
	tooltip?: string;
};
</script>
<style lang="scss">
/**
* Note : This is env-action-card specific css, hence no need to include in flow and this is maintaned here only!
*/
.shared-env-action {
	> div.flow-wrapper:only-child > div.card {
		border-radius: 4px;
	}

	> div.flow-wrapper:first-child:not(:only-child) {
		border-top-left-radius: 4px !important;
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		border-bottom-left-radius: 4px !important;
		> div.card {
			border-top-left-radius: 4px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 4px;
		}
	}
	> div.flow-wrapper:nth-child(2) {
		border-top-left-radius: 0px !important;
		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		border-bottom-left-radius: 0px !important;
		> div.card {
			border-top-left-radius: 0px;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 0px;
		}
	}
}
</style>
