<template>
	<Card background="element-light" :separator="false" :data-qa-env-wid="env.name">
		<template #header>
			<EnvWidgetHeader ref="envWidgetHeader" :is-pipeline-running="isEnvJobLoading" :env="env" />
		</template>
		<Tabs :sticky="true" size="small" class="height-100-per">
			<Tab
				:selected="selectedTabIndex === 1"
				:data-qa-env-wid-app-tab="env.name"
				@click="selectedTabIndex = 1"
			>
				<Container :gap="8" :padding="0">
					<Typography
						type="p2"
						:weight="selectedTabIndex === 1 ? 'medium' : 'regular'"
						:color="selectedTabIndex === 1 ? 'default' : 'light'"
						:data-qa-env-wid-app-tab-title="env.name"
						>App deployments</Typography
					>
					<Tag v-if="envDeploymentCount > 0" size="small">
						<Typography type="p3" color="light" :data-qa-env-wid-app-tab-count="env.name">{{
							envDeploymentCount
						}}</Typography>
					</Tag>
				</Container>
			</Tab>
			<Tab
				:selected="selectedTabIndex === 2"
				:data-qa-env-wid-output-tab="env.name"
				@click="selectedTabIndex = 2"
			>
				<Container :gap="8" :padding="0">
					<Typography
						type="p2"
						:weight="selectedTabIndex === 2 ? 'medium' : 'regular'"
						:color="selectedTabIndex === 2 ? 'default' : 'light'"
						:data-qa-env-wid-output-tab-title="env.name"
					>
						Output
						<Tag v-if="outputCount > 0" size="small">
							<Typography type="p3" color="light" :data-qa-env-wid-output-tab-count="env.name">{{
								outputCount
							}}</Typography>
						</Tag>
					</Typography>
				</Container>
			</Tab>
			<Tab
				:selected="selectedTabIndex === 3"
				:data-qa-env-wid-variable-tab="env.name"
				@click="selectedTabIndex = 3"
			>
				<Container :gap="8" :padding="0">
					<Typography
						type="p2"
						:weight="selectedTabIndex === 3 ? 'medium' : 'regular'"
						:color="selectedTabIndex === 3 ? 'default' : 'light'"
						:data-qa-env-wid-variable-tab-title="env.name"
					>
						Variables
					</Typography>
					<Tag v-if="variableCount > 0" size="small">
						<Typography type="p3" color="light" :data-qa-env-wid-variable-tab-count="env.name">{{
							variableCount
						}}</Typography>
					</Tag>
				</Container>
			</Tab>
			<template #content>
				<!--START : Apps Tab-->
				<EnvWidgetAppsTabContent v-if="selectedTabIndex === 1" :env="env" />
				<!--END : Apps Tab-->
				<!--START : Output Tab for undeployed-->
				<EnvWidgetEmptyOutputContent v-if="selectedTabIndex === 2" :env="env" />
				<!--END : Output Tab for undeployed-->
				<EnvWidgetVariableContent
					v-if="selectedTabIndex === 3 && env.variables"
					:project-variables="projectVariables"
					:env-variables="env.variables"
					:project="project"
					:open-add-variable-model="openAddVariableModel"
				/>
			</template>
		</Tabs>
		<template #footer>
			<EnvWidgetFooter
				:env="env"
				:is-fetching-env-jobs="isFetchingEnvJobs"
				@job-loading="onJobLoadingUpdate"
			/>
		</template>
	</Card>
</template>

<script lang="ts">
import { Card, Container, Tab, Tabs, Tag, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { credentialStore } from "@/modules/credentials/credential-store";
import EnvWidgetFooter from "@/modules/env-list/components/env-widget/env-footer/EnvWidgetFooter.vue";
import EnvWidgetHeader from "@/modules/env-list/components/env-widget/env-header/EnvWidgetHeader.vue";
import EnvWidgetAppsTabContent from "@/modules/env-list/components/env-widget/env-tab-content/EnvWidgetAppsTabContent.vue";
import EnvWidgetEmptyOutputContent from "@/modules/env-list/components/env-widget/env-tab-content/EnvWidgetEmptyOutputContent.vue";
import EnvWidgetVariableContent from "@/modules/env-list/components/env-widget/env-tab-content/EnvWidgetVariableContent.vue";
import { envListStore } from "@/modules/env-list/env-list-store";
import { newEnvPipelineStore } from "@/modules/env-pipeline/env-pipeline-store";
import { orgStore } from "@/modules/org/org-store";
import { projectStore } from "@/modules/project-list/project-store";
import { project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";

export default defineComponent({
	name: "EnvWidget",

	components: {
		EnvWidgetEmptyOutputContent,
		EnvWidgetVariableContent,
		EnvWidgetAppsTabContent,
		EnvWidgetHeader,
		EnvWidgetFooter,
		Typography,
		Container,
		Tabs,
		Tab,
		Card,
		Tag
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		},

		project: {
			type: Object as PropType<project>,
			required: true
		}
	},

	data: () => ({
		isFetchingEnvJobs: true,
		isEnvJobLoading: false,
		selectedTabIndex: 1
	}),

	computed: {
		envDeploymentCount() {
			const deployments = applicationDeploymentStore.deploymentsInEnv[this.env.id] ?? [];
			return deployments.length;
		},

		projectVariables() {
			return projectStore.currentProject?.variables ?? [];
		},

		variableCount() {
			return this.projectVariables.length + (this.env.variables?.length ?? 0);
		},

		outputCount() {
			let count = 0;
			const outputSummary = envListStore.envOutputSummary[this.env.id];
			if (outputSummary?.outputs) {
				count = Object.keys(outputSummary.outputs).length;
			}
			return count;
		}
	},

	mounted() {
		(async () => {
			this.isFetchingEnvJobs = true;

			await Promise.allSettled([
				envListStore.GET_ENV_OUTPUT_SUMMARY({
					orgId: this.env.orgId,
					projectId: this.env.projId,
					envId: this.env.id
				}),
				newEnvPipelineStore.LIST_JOBS_FOR_ENV({
					orgId: this.env.orgId,
					projId: this.env.projId,
					envId: this.env.id,
					limit: 1
				})
			]);

			if (await orgStore.IS_USER_ORG_ADMIN({ orgId: this.env.orgId })) {
				credentialStore.GET_CREDS_FOR_ENTITY({
					orgId: this.env.orgId,
					environment: {
						id: this.env.id,
						orgId: this.env.orgId,
						projId: this.env.projId
					}
				});
			}

			this.isFetchingEnvJobs = false;
		})();
	},

	methods: {
		onJobLoadingUpdate(jobLoading: true) {
			this.isEnvJobLoading = jobLoading;
		},

		openAddVariableModel() {
			//@ts-expect-error
			this.$refs.envWidgetHeader.togglePopover();
		}
	}
});
</script>
