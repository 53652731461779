<template>
	<Icon
		v-if="status === 'Completed'"
		v-tooltip="{
			content: status
		}"
		:effects="false"
		name="i-tick-fill"
		type="filled"
		size="small"
		state="success"
	/>
	<Icon
		v-else-if="status === 'Failed'"
		v-tooltip="{
			content: status
		}"
		:effects="false"
		name="i-alert"
		type="filled"
		size="small"
		state="error"
	/>
	<Icon
		v-else-if="status === 'Cancelled'"
		v-tooltip="{
			content: status
		}"
		:effects="false"
		name="i-stop"
		type="filled"
		size="small"
		state="error"
	/>
	<Icon
		v-else-if="status === 'Cancelling'"
		v-tooltip="{
			content: status
		}"
		:effects="false"
		name="i-loader-danger"
		type="filled"
		size="small"
		animate
	/>
	<Icon
		v-else-if="status === 'Processing'"
		v-tooltip="{
			content: status
		}"
		:effects="false"
		name="i-loader"
		type="filled"
		size="small"
		animate
	/>
	<Icon
		v-else
		v-tooltip="{
			content: status
		}"
		:effects="false"
		name="i-clock-outline"
		type="filled"
		size="small"
		state="light"
		disabled
	/>
</template>
<script lang="ts">
import { Icon } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { PipelineJobStepStatus } from "@/shared/pipeline-constants";

export default defineComponent({
	name: "PipelineIconStatus",

	components: {
		Icon
	},

	props: {
		status: {
			type: String as PropType<PipelineJobStepStatus | null>
		}
	}
});
</script>
