import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
	deleteWebhookRequest,
	listWebhookRequest,
	updateWebhookRequest,
	webhook,
	webhookRequest
} from "@/protocol/externalEvents";

import { getStore } from "../store";

import { createWebhook, deleteWebhook, listWebhook, updateWebhook } from "./web-hooks-service";

@Module({
	namespaced: true,
	dynamic: true,
	name: "webHooks",
	store: getStore()
})
class WebHooksStore extends VuexModule {
	webHooksList: Record<string, webhook[]> = {};

	@Mutation
	UPDATE_WEB_HOOKS({ entityId, webhooks }: { entityId: string; webhooks: webhook[] }) {
		this.webHooksList[entityId] = webhooks;
	}

	@Action
	async LIST_WEB_HOOKS(request: listWebhookRequest & { entityId: string }) {
		const response = await listWebhook(request);

		if (response.webhooks) {
			this.UPDATE_WEB_HOOKS({
				entityId: request.entityId,
				webhooks: response.webhooks
			});
		}
	}

	@Action
	async CREATE_WEB_HOOK(request: webhookRequest) {
		const response = await createWebhook(request);

		const existingHooks = this.webHooksList[request.entityId] ?? [];

		this.UPDATE_WEB_HOOKS({
			entityId: request.entityId,
			webhooks: [...existingHooks, response]
		});

		return response;
	}

	@Action
	async DELETE_WEB_HOOK(request: deleteWebhookRequest & { entityId: string }) {
		await deleteWebhook(request);

		await this.LIST_WEB_HOOKS({
			orgId: request.orgId,
			projectId: request.projectId,
			entityId: request.entityId
		});
	}

	@Action
	async UPDATE_WEB_HOOK(request: updateWebhookRequest) {
		await updateWebhook(request);

		await this.LIST_WEB_HOOKS({
			orgId: request.orgId,
			projectId: request.projectId,
			entityId: request.entityId
		});
	}
}

const webHooksStore = getModule(WebHooksStore);

export { webHooksStore };
