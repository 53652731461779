<template>
	<Tag size="medium">
		<Icon
			:state="iconConfig.state"
			:name="iconConfig.name"
			:data-qa="`cred-classification-icon-${dataQaId ?? classificationId}`"
			type="filled"
			:effects="false"
			size="xx-small"
		/>
		<Typography
			type="p3"
			weight="regular"
			:data-qa="`cred-classification-name-${dataQaId ?? classificationId}`"
		>
			{{ classification?.name }}
		</Typography>
	</Tag>
</template>

<script lang="ts">
import { Typography, Icon, Tag } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { getClassificationIcon } from "@/utils";

import { credentialStore } from "../../credential-store";

export default defineComponent({
	name: "ClassificationTag",
	components: { Typography, Icon, Tag },

	props: {
		classificationId: {
			type: String,
			required: true
		},

		dataQaId: {
			type: String
		}
	},

	computed: {
		iconConfig() {
			const name = this.getClassificationIcon(this.classification);
			let state: "default" | "warning" = "default";
			if (name === "i-prod") {
				state = "warning";
			}
			return { name, state };
		},

		classification() {
			return credentialStore.classificationList.find(
				classification => classification.id === this.classificationId
			);
		}
	},

	methods: {
		getClassificationIcon
	}
});
</script>
