import { Artifact } from "@/protocol/common";
import { ARTIFACT_TYPE_ICON } from "@/shared/constants";

export type KeyValue = {
	key: string;
	icon?: string;
	value: {
		isLink?: boolean;
		text?: string;
	};
};

export function getArtifactDetails(artifact?: Artifact): KeyValue[] {
	const details: KeyValue[] = [];

	if (!artifact) {
		return details;
	}

	if (artifact.gitCode) {
		details.push({
			key: "Repo",
			icon: artifact.type ? ARTIFACT_TYPE_ICON[artifact.type] : "i-box",
			value: {
				isLink: true,
				text: artifact.gitCode.repo
			}
		});
	}

	if (artifact.containerImage) {
		details.push(
			{
				key: "Repo",
				icon: artifact.type ? ARTIFACT_TYPE_ICON[artifact.type] : "i-box",
				value: {
					text: artifact.containerImage.repo
				}
			},
			{
				key: "Reference",
				value: {
					text: artifact.containerImage.reference
				}
			}
		);
	}

	return details;
}
