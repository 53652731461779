import { UserMetadata, userStore } from "@/modules/user/user-store";
import { identityService, success } from "@/protocol/identity";
import { AuthCodeRequest, AuthCodeResponse, LoginRequest, LoginResponse } from "@/protocol/user";
import ApiService from "@/services/api-service";
import { TokenService } from "@/services/storage-service";
import { IDENTITY_API } from "@/shared/constants";

export const signInUsingIdentityProvider: identityService["login"] = async function (request) {
	const response = await ApiService.post<LoginResponse>(`${IDENTITY_API}/login`, request);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export type AuthCodeResponseWithMeta = AuthCodeResponse & {
	metadata?: {
		email?: string;
		verified_email?: boolean;
		family_name?: string;
		given_name?: string;
		hd?: string;
		id?: string;
		locale?: string;
		name?: string;
		picture?: string;
		render_meta: UserMetadata;
	};
};

export const getUserFromIdentityCode = async function (request: AuthCodeRequest) {
	const response = await ApiService.get<AuthCodeResponseWithMeta>(
		`${IDENTITY_API}/callback?code=${request.code}`
	);
	setUserAndHeaders(response.data);
	ApiService.mount401Interceptor();

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export type LoginResponseWithMeta = LoginResponse & {
	metadata?: UserMetadata;
};

export const signInUsingEmailPassword = async function (request: LoginRequest) {
	const response = await ApiService.post<LoginResponseWithMeta>(`${IDENTITY_API}/login`, request);
	setUserAndHeaders(response.data);
	ApiService.mount401Interceptor();

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const logoutUser = async function () {
	const response = await ApiService.delete<success>(`${IDENTITY_API}/users/self/logout`);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const signUpUsingEmailPassword: identityService["signup"] = async function (request) {
	const response = await ApiService.post<success>(`${IDENTITY_API}/signup`, request);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const verifyUserEmailCode: identityService["verifyEmail"] = async function ({ code }) {
	const response = await ApiService.get<success>(
		`${IDENTITY_API}/callback/verifyemail?code=${code}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const sendVerificationEmail: identityService["emailVerificationLink"] = async function ({
	email
}) {
	const response = await ApiService.post<success>(`${IDENTITY_API}/verificationlink`, { email });
	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const sendResetPasswordEmail: identityService["forgotPasswordLink"] = async function ({
	email
}) {
	const response = await ApiService.post<success>(`${IDENTITY_API}/forgotpassword`, { email });

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const resetPassword: identityService["forgotPassword"] = async function (request) {
	const response = await ApiService.put<success>(`${IDENTITY_API}/forgotpassword`, request);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const setNewPassword: identityService["setNewPassword"] = async function (request) {
	const response = await ApiService.post<success>(`${IDENTITY_API}/users/self/password`, request);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const refreshToken: identityService["getToken"] = async function (request) {
	const response = await ApiService.post<LoginResponse>(`${IDENTITY_API}/token`, request);

	setUserAndHeaders(response.data);

	if (response.status === 200) {
		userStore.GET_USER();
		return response.data;
	}

	throw response;
};

export function setUserAndHeaders(response: LoginResponse | AuthCodeResponse) {
	if (response.token) {
		TokenService.saveToken(response.token);
	}

	if (response.refreshToken) {
		TokenService.saveRefreshToken(response.refreshToken);
	}
}

export function resetUserAndHeaders() {
	TokenService.removeToken();
	TokenService.removeRefreshToken();
	userStore.RESET_USER();
	ApiService.removeHeader();
	ApiService.unmount401Interceptor();
}
