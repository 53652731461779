import { AxiosResponse } from "axios";

import {
	appIntegration,
	AppIntegrationCreate,
	appIntegrationDelete,
	AppIntegrationId,
	appIntegrationList,
	appIntegrationListReq,
	appIntegrationUpdate,
	appService,
	deleteAppIntegrationVarsReq,
	deleteAppVarsReq,
	integrationJob,
	integrationJobId,
	integrationJobList as integrationJobListProto,
	integrationJobListRequest,
	logResponse,
	moduleListFilter,
	success,
	triggerIntegrationJob,
	updateAppIntegrationVarsReq,
	updateAppVarsReq
} from "@/protocol/app";
import { pipelineModules } from "@/protocol/pipeline";
import ApiService, { APPLICATION_API } from "@/services/api-service";

export const createAppIntegration: appService["createAppIntegration"] = async function (
	request: AppIntegrationCreate
) {
	const response: AxiosResponse<appIntegration> = await ApiService.post(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateAppIntegration: appService["updateAppIntegration"] = async function (
	request: appIntegrationUpdate
) {
	const response: AxiosResponse<appIntegration> = await ApiService.put(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.id}`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteAppIntegration: appService["deleteAppIntegration"] = async function (
	request: appIntegrationDelete
) {
	const response: AxiosResponse<success> = await ApiService.delete(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getAppIntegration: appService["getAppIntegration"] = async function (
	request: AppIntegrationId
) {
	const response: AxiosResponse<appIntegration> = await ApiService.get(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listAppIntegration: appService["listAppIntegration"] = async function (
	request: appIntegrationListReq
) {
	const response: AxiosResponse<appIntegrationList> = await ApiService.get(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listAvailableModules: appService["listAvailableModules"] = async function (
	request: moduleListFilter
) {
	const response: AxiosResponse<pipelineModules> = await ApiService.get(
		`${APPLICATION_API}/organizations/${request.orgId}/pipelinemodules`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const startIntegrationJob: appService["startIntegrationJob"] = async function (
	request: triggerIntegrationJob
) {
	const response: AxiosResponse<integrationJob> = await ApiService.put(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.integrationId}/jobs`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const cancelIntegrationJob: appService["cancelIntegrationJob"] = async function (
	request: integrationJobId
) {
	const response: AxiosResponse<success> = await ApiService.post(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.integrationId}/jobs/${request.id}/actions/cancel`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const integrationJobList: appService["integrationJobList"] = async function (
	request: integrationJobListRequest
) {
	const response: AxiosResponse<integrationJobListProto> = await ApiService.get(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.integrationId}/jobs`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const integrationJobStatus: appService["integrationJobStatus"] = async function (
	request: integrationJobId
) {
	const response: AxiosResponse<integrationJob> = await ApiService.get(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.integrationId}/jobs/${request.id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getIntegrationLogs: appService["getIntegrationLogs"] = async function (
	request: integrationJobId
) {
	const response: AxiosResponse<logResponse> = await ApiService.get(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.integrationId}/jobs/${request.id}/logs`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getJobArtifact: appService["getJobArtifact"] = async function (request) {
	if (!request.job) {
		throw new Error("Missing job in request");
	}

	const response: AxiosResponse<logResponse> = await ApiService.get(
		`${APPLICATION_API}/organizations/${request.job.orgId}/projects/${request.job.projId}/apps/${request.job.appId}/integrations/${request.job.integrationId}/jobs/${request.job.id}/artifacts/${request.artifactName}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateAppVars: appService["updateAppVars"] = async function (
	request: updateAppVarsReq
) {
	const response: AxiosResponse<success> = await ApiService.patch(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.id}/vars`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteAppVars: appService["deleteAppVars"] = async function (
	request: deleteAppVarsReq
) {
	const response: AxiosResponse<success> = await ApiService.patch(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.id}/vars/delete`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateAppIntegrationVars: appService["updateAppIntegrationVars"] = async function (
	request: updateAppIntegrationVarsReq
) {
	const response: AxiosResponse<success> = await ApiService.patch(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.id}/vars`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteAppIntegrationVars: appService["deleteAppIntegrationVars"] = async function (
	request: deleteAppIntegrationVarsReq
) {
	const response: AxiosResponse<success> = await ApiService.patch(
		`${APPLICATION_API}/organizations/${request.orgId}/projects/${request.projId}/apps/${request.appId}/integrations/${request.id}/vars/delete`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
