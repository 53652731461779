<script setup lang="ts">
import { FTextStateProp } from "@cldcvr/flow-core";
import { Token } from "marked";
import { PropType } from "vue";
import { useRouter } from "vue-router";

defineProps({
	tokens: {
		type: Array as PropType<Token[]>,
		required: true
	},

	state: {
		type: String as PropType<FTextStateProp>
	},

	size: {
		type: String as PropType<"x-large" | "large" | "medium" | "small" | "x-small">
	}
});

const router = useRouter();

function openLink(url: string, event: MouseEvent) {
	// Try to open internal links without redirect
	if (event.ctrlKey || event.metaKey || !url.startsWith("/")) {
		return;
	}

	event.preventDefault();
	event.stopImmediatePropagation();
	router.push({
		path: url,
		force: true
	});
}

function sanitiseUrl(url: string) {
	if (url.startsWith("javascript:")) {
		return "";
	}

	return url;
}
</script>

<template>
	<template v-for="token in tokens" :key="token.raw">
		<template v-if="token.type === 'text'">
			<template v-if="'tokens' in token && token.tokens">
				<MarkdownTokensRenderer :tokens="token.tokens" :state="state" :size="size" />
			</template>
			<template v-else>
				<f-text inline :state="state" :size="size">{{ token.raw }}</f-text>
			</template>
		</template>
		<template v-else-if="token.type === 'strong'">
			<strong class="markdown-token">
				<template v-if="'tokens' in token && token.tokens">
					<MarkdownTokensRenderer :tokens="token.tokens" :state="state" :size="size" />
				</template>
				<template v-else>
					<f-text inline :state="state" :size="size" weight="bold">{{ token.raw }}</f-text>
				</template>
			</strong>
		</template>
		<template v-else-if="token.type === 'em'">
			<em class="markdown-token">
				<template v-if="'tokens' in token && token.tokens">
					<MarkdownTokensRenderer :tokens="token.tokens" :state="state" :size="size" />
				</template>
				<template v-else>
					<f-text inline :state="state" :size="size">{{ token.raw }}</f-text>
				</template>
			</em>
		</template>
		<template v-else-if="token.type === 'link'">
			<f-text inline state="primary" :size="size">
				<a
					:href="sanitiseUrl(token.href)"
					rel="noopener"
					target="_blank"
					@click="openLink(token.href, $event)"
					>{{ token.text }}</a
				>
			</f-text>
		</template>
	</template>
</template>

<style lang="scss">
strong.markdown-token > * {
	font-weight: bold !important;
}
em.markdown-token > * {
	font-style: italic !important;
}
</style>
