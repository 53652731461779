<template>
	<Wrapper
		width="450px"
		max-height="80vh"
		border-radius="4px"
		background="element-light"
		data-qa-list-with-radio-btn-popover-wrapper
	>
		<Header>
			<Typography type="h4" color="dark" data-qa-list-with-radio-btn-popover-title>
				{{ title }}
			</Typography>
			<Container :padding="0" :grow="1" align="right center">
				<f-icon
					source="i-search"
					state="primary"
					class="cursor-pointer"
					@click="enableSearch = !enableSearch"
				></f-icon>
				<Icon
					v-tooltip="{
						content:
							'With policies, Code Pipes evaluates your Terraform for potential security misconfigurations and compliance violations prior to deployment.'
					}"
					name="i-question-filled"
					size="x-small"
					type="filled"
					state="light"
					:effects="true"
					data-qa-list-with-radio-btn-popover-info-icon
				/>
				<Icon
					name="i-close"
					type="filled"
					size="x-small"
					data-qa-list-with-radio-btn-popover-close-icon
					@click="emitCloseEvent"
				/>
			</Container>
		</Header>
		<Container v-if="enableSearch">
			<SearchInput
				v-model:value="searchText"
				size="small"
				placeholder="Search control gates..."
				data-qa-field="search-control-gate"
			></SearchInput>
		</Container>
		<Container
			:padding="0"
			align="left top"
			overflow="auto"
			direction="column"
			:gap="0"
			class="flow-add-scrollbar"
		>
			<f-div
				v-for="listItem in filteredList"
				:key="listItem.id"
				:no-padding="true"
				type="bottom-border"
				overflow="visible"
				data-qa-list-slab
				direction="column"
				@click="toggleListSelection(listItem.id)"
			>
				<f-div
					padding="small"
					gap="medium"
					align="middle-left"
					:data-qa-list-item="listItem.qaId"
					direction="column"
					class="cursor-pointer"
				>
					<f-div padding="x-small" direction="row" gap="medium">
						<RadioButton :checked="selectedListItem === listItem.id"> </RadioButton>
						<f-div direction="column" gap="small" width="78%" padding="none" align="middle-center">
							<f-div gap="medium" padding="none">
								<f-text
									variant="heading"
									color="dark"
									size="x-small"
									weight="medium"
									data-qa-list-with-radio-btn-popover-policy-name
									>{{ listItem && listItem.name }}</f-text
								>
							</f-div>
							<!-- We might brind this back in the future, commenting for now -->
							<!-- <f-div direction="column">
								<f-text
									variant="para"
									state="secondary"
									size="small"
									data-qa-list-with-radio-btn-popover-policy-desc
								>
									{{ listItem.description }}.
									<f-text
										variant="para"
										state="primary"
										size="small"
										:link="true"
										inline
										data-qa="policy-project-link"
										@click="openHelp(listItem.name)"
									>
										Learn more
									</f-text>
								</f-text>
							</f-div> -->
						</f-div>
						<f-div align="middle-right" :tooltip="'#tooltip-' + listItem.id">
							<f-text state="primary" size="small" weight="bold">
								{{ getSetLengthText(listItem) }}
							</f-text>
						</f-div>
						<f-tooltip :id="'tooltip-' + listItem.id">
							<f-div gap="small" width="hug-content" direction="column">
								<f-text v-for="(name, i) in getToolTip(listItem)" :key="i" size="small">{{
									`${i + 1} . ${name}`
								}}</f-text></f-div
							>
						</f-tooltip>
					</f-div>
					<f-divider
						variant="solid"
						direction="horizontal"
						state="subtle"
						size="medium"
					></f-divider>
				</f-div>
			</f-div>
		</Container>
		<Container v-if="filteredList.length === 0" :padding="16" :gap="8" align="center top">
			<Icon name="i-shield" type="filled" state="default" size="x-small" :effects="false" />
			<Typography v-if="searchText !== ''" type="p2-para" color="default" weight="thin" a>
				No matching control gate found
			</Typography>
			<Typography v-else type="p2-para" color="default" weight="thin">
				No control gates available
			</Typography>
		</Container>

		<!-- <Container :padding="16" :gap="8" align="left top">
			<Icon name="i-bulb" type="filled" state="warning" size="x-small" :effects="false" />
			<Typography type="p2-para" color="warning" weight="thin">
				<Typography type="p2-para" color="warning" weight="bold" :inline="true"> Note </Typography>
				: This change is only applicable when re-deploying old environments or creating a new one.
			</Typography>
		</Container> -->
		<Footer>
			<Button
				state="full"
				type="success"
				:disabled="shouldDisableBtn(selectedListItem)"
				:loading="buttonLoading"
				data-qa-list-popover-submit-btn
				@click="triggerButtonClick"
			>
				<span>{{ buttonTitle }}</span>
			</Button>
		</Footer>
	</Wrapper>
</template>

<script lang="ts">
import {
	Wrapper,
	Footer,
	Button,
	Container,
	Icon,
	Typography,
	Header,
	RadioButton,
	SearchInput
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { policyListStore } from "@/modules/policy-list/policy-list-store";
import { PolicySet } from "@/protocol/validator";

export type ListWithRadioBtnPopoverList = Array<
	{
		name: string;
		id?: string;
		description?: string;
		qaId: string;
	} & PolicySet
>;

export default defineComponent({
	name: "ListWithRadioBtnPopover",

	components: {
		Wrapper,
		Footer,
		Button,
		Container,
		Icon,
		Typography,
		Header,
		RadioButton,
		SearchInput
	},

	props: {
		title: {
			type: String,
			default: () => ""
		},

		buttonTitle: {
			type: String,
			default: () => ""
		},

		list: {
			type: Array as PropType<ListWithRadioBtnPopoverList>,
			required: true
		},

		selectedListItem: {
			type: String,
			required: true
		},

		preSelectedListItem: {
			type: Object as PropType<{ id: string }>,
			required: true
		},

		btnDisableFlag: {
			type: Boolean,
			default: () => null
		},

		buttonLoading: {
			type: Boolean,
			default: () => null
		}
	},

	emits: ["togglePopover", "footerButtonClick", "toggleListSelection"],

	data: () => ({
		enableSearch: false,
		searchText: ""
	}),

	computed: {
		filteredList() {
			return this.list.filter(listItem =>
				listItem.name.toLowerCase().includes(this.searchText.toLowerCase())
			);
		},

		policySets() {
			return policyListStore.policySets;
		}
	},

	methods: {
		shouldDisableBtn(selectedListItem: string): boolean {
			const noSearchResult = this.filteredList.length === 0 && this.searchText !== "";
			return this.btnDisableFlag
				? this.btnDisableFlag
				: selectedListItem === this.preSelectedListItem.id || noSearchResult;
		},

		emitCloseEvent() {
			this.$emit("togglePopover");
		},

		triggerButtonClick() {
			this.$emit("footerButtonClick", this.selectedListItem);
		},

		toggleListSelection(listItemKey?: string) {
			if (!listItemKey || this.buttonLoading) {
				return;
			}

			this.$emit("toggleListSelection", listItemKey);
		},

		// openHelp(policyName: string) {
		// 	// We have a separate/dedicated link for soc2 only. For others we have a commmon policies link.
		// 	switch (policyName.toLocaleLowerCase()) {
		// 		case "cc soc2":
		// 			window.open("https://docs.codepipes.io/docs/soc2-compliance", "_blank");
		// 			break;
		// 		default:
		// 			window.open("https://docs.codepipes.io/docs/policies", "_blank");
		// 			break;
		// 	}
		// },

		getSetLengthText(policy: PolicySet) {
			if (policy.importSets?.length === 0) {
				return "NO SET";
			} else {
				return `${policy.importSets?.length ?? 0} SET${policy.importSets?.length !== 1 ? "S" : ""}`;
			}
		},

		getToolTip(policy: PolicySet) {
			if (policy.importSets?.length === 0) {
				return "No set is imported for this policy";
			} else {
				const importSetIds = policy.importSets ?? [];
				const importSetNames = importSetIds.map(id => {
					const importSet = this.policySets.find(item => item.id === id);
					return importSet?.name;
				});
				return importSetNames;
			}
		}
	}
});
</script>
