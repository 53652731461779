<template>
	<Container :padding="0" :shrink="0">
		<Wrapper height="42px">
			<Container padding="0px 14px" :grow="1" :shrink="0" align="space-between center">
				<Typography type="p2" weight="bold" color="dark" transform="uppercase"
					>PIPELINE HISTORY</Typography
				>
				<Container :padding="0" :gap="16" :shrink="0">
					<PopOver
						v-model:open="showHistoryFilter"
						placement="bottom-end"
						:overlay="false"
						class="align-items-center"
					>
						<Pictogram
							v-tooltip="{ content: 'Filter history' }"
							shape="circle"
							size="m"
							effects
							:state="showHistoryFilter || selectedFilter ? 'primary' : 'default'"
							:type="showHistoryFilter || selectedFilter ? 'solid' : 'transparent'"
							data-qa-pipeline-filter-button
							@click="showHistoryFilter = !showHistoryFilter"
						>
							<Icon
								name="i-filter"
								size="small"
								:state="showHistoryFilter ? 'black' : 'dark'"
								type="filled"
								:effects="false"
							/>
						</Pictogram>
						<template #content>
							<Wrapper border-radius="4px" background="gray-400" :border="true" width="250px">
								<Container direction="column" :padding="0" :gap="0">
									<Slab
										v-for="filterOption in filterOptions"
										:key="filterOption.text"
										size="medium"
										:effects="true"
										:type="filterOption.icon ? 'transparent' : 'bottom-border'"
										class="cursor-pointer"
										:data-qa-pipeline-filter-option="filterOption.text"
										@click="selectPipelineFilter(filterOption)"
									>
										<template #primary-action>
											<Icon
												v-if="filterOption.icon"
												:name="filterOption.icon.name"
												size="small"
												:state="filterOption.icon.state ? filterOption.icon.state : 'default'"
												:effects="false"
											/>
										</template>
										<Typography type="p1" weight="regular" color="dark">{{
											filterOption.text
										}}</Typography>
										<template v-if="filterOption.selected" #secondary-actions>
											<Icon name="i-tick" state="dark" size="small" :effects="false" />
										</template>
									</Slab>
								</Container>
							</Wrapper>
						</template>
					</PopOver>
					<Icon
						v-tooltip="{ content: 'Close' }"
						name="i-close"
						size="small"
						state="dark"
						type="filled"
						data-qa-close-pipeline-history
						@click="closePipelineHistory"
					/>
				</Container>
			</Container>
		</Wrapper>
	</Container>

	<Divider class="flex-0" />

	<Container
		v-if="historyList.length > 0"
		:padding="0"
		overflow="scroll"
		align="left top"
		:gap="8"
		direction="column"
		class="margin-tp-0"
		data-qa-pipeline-history-list
	>
		<Container
			v-for="(history, idx) in historyList"
			:key="history.id"
			:padding="0"
			:shrink="0"
			:gap="0"
			direction="column"
		>
			<Container
				v-if="historyList[idx - 1]?.elapsedTime !== history.elapsedTime"
				:padding="12"
				:shrink="0"
			>
				<Typography type="p2" color="light" transform="uppercase" weight="bold">
					{{ history.elapsedTime }}
				</Typography>
			</Container>
			<Container
				:key="history.id"
				:padding="history.isSelected ? '12px 12px 12px 10px' : 12"
				align="left top"
				:shrink="0"
				:gap="8"
				:background="history.isSelected ? 'element-light' : 'default'"
				:data-selected="history.isSelected"
				clickable
				class="pipeline-history-card"
				:data-qa-pipeline-history-list-item="history.id"
				@click="selectPipelineHistory(history.id)"
			>
				<PipelineIconStatus :status="history.state" />
				<Container direction="column" :padding="0" :gap="6">
					<Typography type="p1" weight="bold" color="dark">{{ history.date }}</Typography>
					<Typography v-if="history.resourceChanges" type="p2" color="light">
						<Typography type="p2" color="light" weight="medium" inline>Plan: </Typography
						>{{ history.resourceChanges.create }} create |
						{{ history.resourceChanges.update }} update |
						{{ history.resourceChanges.delete }} delete</Typography
					>
					<Typography type="p3" color="light">Duration {{ history.duration }}</Typography>
					<Typography v-if="history.triggeredBy" type="p3" color="gray-300">
						Started by <UserNameEmail :user-id="history.triggeredBy" />
					</Typography>
					<Typography
						v-tooltip="{ content: history.subTitle ?? history.id }"
						:data-qa-pipeline-history-list-item-id="history.id"
						type="p3"
						color="gray-200"
						overflow="ellipsis"
					>
						<template v-if="history.subTitle">
							{{ history.subTitle }}
						</template>
						<template v-else> Pipeline #{{ history.id }} </template>
					</Typography>
				</Container>
			</Container>
		</Container>
	</Container>

	<CustomLoader v-else-if="isLoadingHistory" position="relative" />

	<!-- Empty Pipeline Start -->
	<Container
		v-else
		:grow="1"
		direction="column"
		:gap="16"
		padding="70px 0px 0px 0px"
		class="align-items-center"
	>
		<Typography type="p1" color="gray-200">There are no active pipelines</Typography>
		<Button v-if="selectedFilter" state="outlined" size="small" @click="selectPipelineFilter(null)"
			>CLEAR FILTER</Button
		>
	</Container>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Icon,
	IconState,
	Pictogram,
	PopOver,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { resourceChanges } from "@/protocol/infra";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import PipelineIconStatus from "@/shared/components/pipelines/PipelineIconStatus.vue";
import { PipelineJobStepStatus } from "@/shared/pipeline-constants";

import { UserNameEmail } from "..";

export default defineComponent({
	name: "PipelineHistory",

	components: {
		Button,
		Container,
		Divider,
		Icon,
		CustomLoader,
		Pictogram,
		PopOver,
		Slab,
		Typography,
		PipelineIconStatus,
		Wrapper,
		UserNameEmail
	},

	props: {
		selectedFilter: {
			type: Object as PropType<HistoryFilterOption | null>,
			default: () => null
		},

		isLoadingHistory: {
			type: Boolean,
			default: () => null
		},

		historyList: {
			type: Array as PropType<PipelineHistorySchema[]>,
			required: true
		}
	},

	emits: ["selectPipelineFilter", "closePipelineHistory", "selectPipelineHistory"],

	data: () => ({
		showHistoryFilter: false
	}),

	computed: {
		filterOptions(): HistoryFilterOption[] {
			return [
				{
					id: "running",
					text: "Running",
					icon: { name: "i-loader", animate: true },
					selected: this.selectedFilter?.id === "running"
				},
				{
					id: "completed",
					text: "Completed",
					icon: { name: "i-tick-fill", state: "success" },
					selected: this.selectedFilter?.id === "completed"
				},
				{
					id: "cancelled",
					text: "Cancelled",
					icon: { name: "i-stop", state: "error" },
					selected: this.selectedFilter?.id === "cancelled"
				},
				{
					id: "failed",
					text: "Failed",
					icon: { name: "i-alert", state: "error" },
					selected: this.selectedFilter?.id === "failed"
				}
			];
		}
	},

	methods: {
		closePipelineHistory() {
			this.$emit("closePipelineHistory");
		},

		selectPipelineFilter(filter: HistoryFilterOption | null) {
			if (filter?.id === this.selectedFilter?.id) {
				this.$emit("selectPipelineFilter", null);
			} else {
				this.$emit("selectPipelineFilter", filter);
			}

			this.showHistoryFilter = false;
		},

		selectPipelineHistory(id: string) {
			this.$emit("selectPipelineHistory", id);
		}
	}
});

export type HistoryFilterOption = {
	icon?: {
		name: string;
		state?: IconState;
		animate?: boolean;
	};
	id: "running" | "completed" | "failed" | "cancelled";
	text: string;
	selected: boolean;
	bottomBorder?: boolean;
};

export type PipelineHistorySchema = {
	date: string | null;
	resourceChanges?: resourceChanges;
	duration: string;
	elapsedTime: string | null;
	id: string;
	isSelected: boolean;
	state: PipelineJobStepStatus;
	triggeredBy?: string;
	subTitle?: string;
};
</script>

<style lang="scss">
.pipeline-history-card[data-selected] {
	border-left: 2px solid var(--secondary-300);
}
</style>
