<template>
	<Container
		data-qa-advanced-form-container
		:padding="0"
		direction="column"
		:gap="16"
		:overflow="overflow"
		align="left top"
	>
		<template v-if="requiredFields">
			<Typography v-if="!embeddableView" type="h5" transform="uppercase" color="gray-200"
				>Required Inputs</Typography
			>

			<f-form-builder
				ref="requiredForm"
				data-qa-advanced-form-required-inputs
				:field.prop="requiredFields"
				:values.prop="requiredValue as FormBuilderValues"
				@input="handleRequiredInput"
				@state-change="requiredState = { ...$event.detail }"
			/>
		</template>

		<Divider v-if="!embeddableView && requiredFields && optionalFields" />

		<!-- If we only have optional fields then just render the form, no accordion needed -->
		<Container
			v-if="!embeddableView && !requiredFields && optionalFields"
			padding="0"
			:grow="1"
			direction="column"
			data-qa-advanced-form-container
		>
			<f-form-builder
				ref="optionalForm"
				data-qa-advanced-form
				:field.prop="optionalFields"
				:values.prop="optionalValue as FormBuilderValues"
				@input="handleOptionalInput"
				@state-change="optionalState = { ...$event.detail }"
			/>
		</Container>

		<Accordion
			v-else-if="(embeddableView && optionalFields) || (requiredFields && optionalFields)"
			:open="isAdvancedConfigOpen"
			:bottom-border="false"
			@transitionend="updateModalLayout"
		>
			<template #header>
				<Slab
					effects
					type="transparent"
					size="medium"
					no-padding
					border-type="inset"
					@click="isAdvancedConfigOpen = !isAdvancedConfigOpen"
				>
					<template #secondary-actions>
						<Container padding="5px 0" :gap="12">
							<Icon
								data-qa-advanced-form-edit
								name="i-edit"
								size="small"
								type="filled"
								state="primary"
								@click.stop="toggleAdvancedEdit"
							/>
							<Icon
								data-qa-advanced-form-expand
								name="i-chevron-down"
								:rotate="isAdvancedConfigOpen ? 180 : 0"
								size="small"
								type="filled"
								state="primary"
								@click.stop="isAdvancedConfigOpen = !isAdvancedConfigOpen"
							/>
						</Container>
					</template>

					<Typography type="h5" transform="uppercase" color="primary-300">
						Advanced Configuration
					</Typography>
				</Slab>
			</template>

			<Container padding="16px 0" :grow="1" direction="column" data-qa-advanced-form-container>
				<!-- We need to hide the FormBuilder not remove it because we still need it's values -->
				<f-form-builder
					v-show="isAdvancedConfigEditing"
					ref="optionalForm"
					data-qa-advanced-form
					:class="isAdvancedConfigEditing ? '' : 'hidden'"
					:field.prop="optionalFields"
					:values.prop="optionalValue as FormBuilderValues"
					@input="handleOptionalInput"
					@state-change="optionalState = { ...$event.detail }"
				/>

				<FormFieldTable
					v-if="!isAdvancedConfigEditing"
					data-qa-advanced-form-table
					:fields="optionalFields"
					:values="optionalValue"
				/>
			</Container>
		</Accordion>
	</Container>
</template>
<script lang="ts">
import {
	FFormBuilder,
	FormBuilderField,
	FormBuilderState,
	FormBuilderValidationRules,
	FormBuilderValues
} from "@cldcvr/flow-form-builder";
import {
	Accordion,
	Container,
	ContainerOverflow,
	Divider,
	Icon,
	Slab,
	Typography
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { JSONSchema } from "@/protocol/common";
import FormFieldTable from "@/shared/components/FormFieldTable.vue";
import {
	isJSONArraySchema,
	isJSONObjectSchema,
	JSONSchemaToObject,
	splitJSONObjectSchema
} from "@/utils";

export default defineComponent({
	name: "JSONSchemaFormBuilder2",

	components: {
		Divider,
		Slab,
		Icon,
		Accordion,
		Typography,
		Container,
		FormFieldTable
	},

	inject: ["updateLayout"],

	props: {
		fields: {
			type: Object as PropType<JSONSchema>,
			required: true
		},

		defaultValues: {
			type: Object as PropType<FormBuilderValues>
		},

		prefillValues: {
			type: Array as PropType<string[]>
		},

		overflow: {
			type: String as PropType<ContainerOverflow>,
			default: () => "auto"
		},

		embeddableView: Boolean
	},

	data() {
		return {
			isAdvancedConfigOpen: false,
			isAdvancedConfigEditing: false,

			requiredSchema: null as JSONSchema | null,
			requiredFields: null as FormBuilderField | null,
			requiredState: null as FormBuilderState | null,
			requiredValue: null as unknown,

			optionalSchema: null as JSONSchema | null,
			optionalFields: null as FormBuilderField | null,
			optionalState: null as FormBuilderState | null,
			optionalValue: null as unknown
		};
	},

	computed: {
		isValid() {
			return (
				(this.requiredState ? this.requiredState.isValid : true) &&
				(this.optionalState ? this.optionalState.isValid : true)
			);
		}
	},

	watch: {
		fields: {
			deep: true,
			immediate: true,

			handler() {
				if (!isJSONObjectSchema(this.fields)) {
					this.updateRequiredSchema(null);
					this.updateOptionalSchema(this.fields);
				} else {
					const { requiredSchema, optionalSchema } = splitJSONObjectSchema(this.fields);
					this.updateRequiredSchema(requiredSchema);
					this.updateOptionalSchema(optionalSchema);
				}

				this.$nextTick(this.emitInfo);
			}
		},

		isValid() {
			this.emitInfo();
		}
	},

	methods: {
		updateRequiredSchema(schema: JSONSchema | null) {
			this.requiredState = null;
			this.requiredSchema = schema;
			this.requiredFields = schema
				? buildForm({
						schema,
						name: "",
						parentName: "",
						isNested: false,
						isRequired: true,
						prefillValues: this.prefillValues
				  })
				: null;
			this.requiredValue = schema ? JSONSchemaToObject(schema, this.defaultValues) : null;
		},

		updateOptionalSchema(schema: JSONSchema | null) {
			this.optionalState = null;
			this.optionalSchema = schema;
			this.optionalFields = schema
				? buildForm({
						schema,
						name: "",
						parentName: "",
						isNested: false,
						isRequired: true,
						prefillValues: this.prefillValues
				  })
				: null;
			this.optionalValue = schema ? JSONSchemaToObject(schema, this.defaultValues) : null;
		},

		// used by paret component to validate the form
		// eslint-disable-next-line vue/no-unused-properties
		validateForm({ silent }: { silent: boolean }) {
			const optionalFormRef = this.getOptionalForm();
			if (optionalFormRef) {
				optionalFormRef.validateForm(silent);
			}

			const requiredFormRef = this.getReqiredForm();
			if (requiredFormRef) {
				requiredFormRef.validateForm(silent);
			}
		},

		getOptionalForm() {
			return this.$refs.optionalForm as FFormBuilder | undefined;
		},

		getReqiredForm() {
			return this.$refs.requiredForm as FFormBuilder | undefined;
		},

		updateModalLayout() {
			if (this.updateLayout) {
				//@ts-expect-error Vue 2.x has no way to type injects
				this.updateLayout();
			}
		},

		toggleAdvancedEdit() {
			const optionalFormRef = this.getOptionalForm();

			if (this.isAdvancedConfigEditing && optionalFormRef) {
				const isValid = this.optionalState?.isValid ?? true;

				// If we are trying to turn off editing but the form is invalid we
				// need to diallow it and ensure that the form is open
				if (!isValid) {
					this.isAdvancedConfigOpen = true;
					return;
				}
			} else {
				// Make sure advanced config is open if we are going to edit it
				this.isAdvancedConfigOpen = true;
			}

			this.isAdvancedConfigEditing = !this.isAdvancedConfigEditing;

			if (this.isAdvancedConfigEditing && optionalFormRef) {
				this.$nextTick(() => {
					optionalFormRef.validateForm();
				});
			}
		},

		getFormInfo() {
			const formValues = {
				...(this.optionalValue ?? {}),
				...(this.requiredValue ?? {})
			};

			return {
				// For backwards compatibility
				inputs: formValues,
				value: formValues,
				isValid: this.isValid
			};
		},

		emitInfo() {
			this.$emit("info", this.getFormInfo());
		},

		handleRequiredInput(event: CustomEvent) {
			if (!this.requiredSchema) {
				return;
			}

			this.requiredValue = JSONSchemaToObject(this.requiredSchema, {
				...event.detail
			} as FormBuilderValues);

			this.$nextTick(this.emitInfo);
		},

		handleOptionalInput(event: CustomEvent) {
			if (!this.optionalSchema) {
				return;
			}

			this.optionalValue = JSONSchemaToObject(this.optionalSchema, {
				...event.detail
			} as FormBuilderValues);

			this.$nextTick(this.emitInfo);
		}
	}
});

type BuildFormParams = {
	schema: JSONSchema;

	name: string;

	// Needed for better QA ids
	parentName: string;

	// Nested field labels render differently
	isNested: boolean;

	// Used for form validation
	isRequired: boolean;

	// Automatically suggest inputs for plain text fields
	prefillValues?: string[];
};

export function buildForm(formParams: BuildFormParams): FormBuilderField {
	const { schema, name, isNested, isRequired } = formParams;

	if (isJSONArraySchema(schema)) {
		return {
			type: "array",

			label: {
				title: schema.title ?? "",
				iconTooltip: schema.description?.trim() ?? undefined
			},

			allowEmpty: !isRequired,

			field: buildForm({
				...formParams,
				schema: schema.items,
				isNested: true,
				isRequired
			})
		};
	}

	if (isJSONObjectSchema(schema)) {
		const { properties, required, internal = [] } = schema;

		return {
			type: "object",
			direction: "vertical",
			label: {
				title: schema.title ?? "",
				iconTooltip: schema.description?.trim() ?? undefined
			},
			fieldSeparator: !isNested,
			fields: Object.keys(properties)
				// Filter out internal properties
				.filter(propertyName => !internal.includes(propertyName))
				.reduce(
					(fields, propertyName) => {
						const itemSchema = properties[propertyName];

						if (!itemSchema) {
							return fields;
						}

						fields[propertyName] = buildForm({
							...formParams,
							schema: itemSchema,
							name: propertyName,
							parentName: name,
							isRequired: required?.includes(propertyName) ?? false,
							isNested: true
						});

						return fields;
					},
					{} as Record<string, FormBuilderField>
				)
		};
	}

	if (schema.type && ["integer", "number", "boolean", "string"].includes(schema.type)) {
		return getPrimitiveFormBuilderField(formParams);
	}

	return {
		type: "object",
		direction: "vertical",
		fieldSeparator: true,
		fields: {}
	};
}

function getPrimitiveFormBuilderField(formParams: BuildFormParams): FormBuilderField {
	const { schema, name, parentName, isRequired, prefillValues } = formParams;

	const fieldType = getPrimitiveType(schema);

	const options = schema.enum?.length && schema.enum.length > 0 ? schema.enum : undefined;

	const qaId = convertToSlug(`${parentName ? `${parentName}-` : ""}${name}`);
	const validationRules = getPrimitiveRules(schema, isRequired);
	const label = {
		title: schema.title ?? "",
		iconTooltip: schema.description?.trim() ?? undefined
	};

	// If we have a list of values which can be prefilled, we convert the field into a "suggest" type
	// and send the options there
	if (fieldType === "text" && prefillValues && prefillValues.length > 0) {
		return {
			qaId,
			type: "suggest",
			suggestions: prefillValues,
			label,
			validationRules
		};
	} else if (fieldType === "select") {
		return {
			qaId,
			type: "select",

			options: options as string[],
			label,
			validationRules
		};
	}

	return {
		qaId,
		type: fieldType,
		label,
		validationRules
	};
}

function getPrimitiveType(schema: JSONSchema): "text" | "number" | "switchButton" | "select" {
	if (schema.type === "boolean") {
		return "switchButton";
	}

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	const hasEnums = schema.enum ? schema.enum.length > 0 : false;
	let type: "text" | "select" | "number" = "text";

	if (hasEnums) {
		type = "select";
	} else if (schema.type === "integer" || schema.type === "number") {
		type = "number";
	}

	return type;
}

// eslint-disable-next-line complexity
function getPrimitiveRules(schema: JSONSchema, isRequired: boolean): FormBuilderValidationRules {
	if (schema.type !== "string" && schema.type !== "integer" && schema.type !== "number") {
		return [];
	}

	let isActuallyRequired = isRequired;

	// Backend can't guarantee isRequired to be true, so we try to detect
	// if we can find it from minimum length values
	if (
		(schema.type === "string" && schema.minLength && schema.minLength > 0) ??
		((schema.type === "integer" || schema.type === "number") &&
			schema.minimum &&
			schema.minimum > 0)
	) {
		isActuallyRequired = true;
	}

	const rules: FormBuilderValidationRules = [];

	if (isActuallyRequired) {
		rules.push({ name: "required" });
	}

	if (schema.type === "string") {
		rules.push(
			{
				name: "max",
				params: { length: schema.maxLength && schema.maxLength > 0 ? schema.maxLength : Infinity }
			},
			{
				name: "min",
				params: { length: schema.minLength && schema.minLength > 0 ? schema.minLength : 0 }
			}
		);
	} else {
		rules.push(
			{
				name: "max-value",
				params: { max: schema.maximum && schema.maximum > 0 ? schema.maximum : Infinity }
			},
			{
				name: "min-value",
				params: { min: schema.minimum && schema.minimum > 0 ? schema.minimum : 0 }
			}
		);
	}

	return rules;
}

function convertToSlug(text: string): string {
	return text
		.toLowerCase()
		.replace(/ /g, "-")
		.replace(/[^\w-]+/g, "");
}
</script>
<style>
[data-qa-advanced-form-container] f-form-builder {
	width: 100%;
}
</style>
