<template>
	<PopOver
		placement="bottom-start"
		:open="showConfirmation"
		class="width-100-per"
		@overlay-click="showConfirmation = false"
	>
		<WidgetFooterContent
			:type="envWidgetType"
			:status="envWidgetStatus"
			:status-text="!envWidgetText.time ? envWidgetText.text : ''"
			:data-qa-env-wid-footer-text="env.name"
			@validate="triggerPipeline(actionType.validateOnly)"
			@deploy="triggerPipeline(actionType.ValidateAndApply)"
			@cancel="cancelPipeline"
			@view-logs="goToPipeline"
		>
			<template #status-text>
				<CustomTimeStamp
					v-if="envWidgetText.time"
					:time="envWidgetText.time"
					:prefix="envWidgetText.text"
					data-qa="env-widget-pipline-updated-date"
				/>
			</template>
		</WidgetFooterContent>
		<template #content>
			<ConfirmPipeline
				:confirm-meta="confirmActionMeta[confirmationAction]"
				:org-id="env.orgId"
				:project-id="env.projId"
				:env="env"
				:env-id="env.id"
				:action="confirmationAction"
				@close="showConfirmation = false"
			/>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { PopOver } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import ConfirmPipeline from "@/modules/env-pipeline/components/ConfirmPipeline.vue";
import { ENV_PIPELINE_CONFIRM_ACTION_META } from "@/modules/env-pipeline/constants";
import { getSortedEnvJobs, newEnvPipelineStore } from "@/modules/env-pipeline/env-pipeline-store";
import { StateInfoLoadParams } from "@/modules/env-pipeline/env-pipeline-types";
import { actionType, environment } from "@/protocol/infra";
import { CustomTimeStamp } from "@/shared/components";
import { PipelineJobStatus, PIPELINE_UNFINISHED_JOB_STATUSES } from "@/shared/pipeline-constants";

import { footerContent } from "./footerContent";
import WidgetFooterContent, {
	WidgetFooterStatus,
	WidgetFooterType
} from "./WidgetFooterContent.vue";

export default defineComponent({
	name: "EnvWidgetFooter",

	components: {
		CustomTimeStamp,
		WidgetFooterContent,
		ConfirmPipeline,
		PopOver
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		},

		isFetchingEnvJobs: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		confirmActionMeta: ENV_PIPELINE_CONFIRM_ACTION_META,
		showConfirmation: false,
		confirmationAction: actionType.no_action_type_support as actionType,
		actionType,
		isCancellingPipeline: false
	}),

	computed: {
		latestPipelineJob() {
			return getSortedEnvJobs(this.env.id)[0] ?? null;
		},

		envWidgetType(): WidgetFooterType {
			if (this.isFetchingEnvJobs) {
				return "initial-fetch";
			}

			if (this.latestPipelineJob?.action === actionType.validateOnly) {
				return "env-validation";
			}

			return this.latestPipelineJob?.action === actionType.ValidateAndApply
				? "env-deploy"
				: "env-destroy";
		},

		envWidgetStatus(): WidgetFooterStatus {
			if (this.isFetchingEnvJobs) {
				return "in-progress";
			}

			if (!this.latestPipelineJob) {
				return "default";
			}

			const action = this.latestPipelineJob.status as PipelineJobStatus;

			switch (action) {
				case "done":
					return this.latestPipelineJob.action === actionType.destroy ? "destroyed" : "done";

				case "canceled":
					return "cancelled";

				case "canceling":
					return "cancelling";

				case "failed":
					return "failed";
			}

			return this.latestPipelineJob.action === actionType.destroy ? "destroying" : "in-progress";
		},

		envWidgetText() {
			if (!this.envPipelineContent && !this.isFetchingEnvJobs) {
				return { text: "", time: "" };
			}

			if (this.isFetchingEnvJobs) {
				return { text: "Fetching pipeline status", time: "" };
			}

			const { text } = this.envPipelineContent!;

			return {
				text,
				time: this.envPipelineContent?.showTimestamp ? this.envPipelineContent.timestamp : ""
			};
		},

		envJobLoaders() {
			const { envPipelineLoaders } = newEnvPipelineStore;
			return [
				...envPipelineLoaders.ValidateAndApply,
				...envPipelineLoaders.validateOnly,
				...envPipelineLoaders.destroy
			];
		},

		isEnvJobLoading() {
			const isActivePipeline = this.envJobLoaders
				.filter(loader => loader.envId === this.env.id)
				.some(loader => loader.value);

			return (
				newEnvPipelineStore.isJobsLoading ||
				this.isFetchingEnvJobs ||
				isActivePipeline ||
				this.isCancellingPipeline ||
				PIPELINE_UNFINISHED_JOB_STATUSES.includes(
					this.latestPipelineJob?.status as PipelineJobStatus
				)
			);
		},

		envPipelineContent() {
			if (!this.latestPipelineJob) {
				return null;
			}

			const content = this.latestPipelineJob.action
				? footerContent[this.latestPipelineJob.action]?.[
						this.latestPipelineJob.status as PipelineJobStatus
				  ]
				: null;

			if (!content) {
				return null;
			}

			const timestamp = this.latestPipelineJob.updatedAt ?? this.latestPipelineJob.createdAt ?? 0;

			return {
				...content,
				timestamp: timestamp.toString()
			};
		}
	},

	watch: {
		isEnvJobLoading: {
			immediate: true,

			handler() {
				this.$emit("jobLoading", this.isEnvJobLoading);
			}
		}
	},

	methods: {
		triggerPipeline(action: actionType) {
			this.showConfirmation = true;
			this.confirmationAction = action;
		},

		setStageInfoAndLoad({ action, orgId, projectId, envId }: StateInfoLoadParams) {
			if (this.$route.name === "stageViewEnv") {
				this.$forceUpdate();
			} else {
				this.$router.push({
					name: "stageViewEnv",
					params: {
						orgId,
						projectId,
						envId,
						action
					}
				});
			}
		},

		goToPipeline() {
			if (!this.latestPipelineJob?.action) {
				return;
			}

			this.setStageInfoAndLoad({
				action: this.latestPipelineJob.action,
				orgId: this.env.orgId,
				projectId: this.env.projId,
				envId: this.env.id
			});
		},

		async cancelPipeline() {
			if (!this.latestPipelineJob) {
				return;
			}

			this.isCancellingPipeline = true;

			await newEnvPipelineStore.CANCEL_PIPELINE({
				job: this.latestPipelineJob,
				projId: this.env.projId,
				orgId: this.env.orgId
			});

			this.isCancellingPipeline = false;
		}
	}
});
</script>
