import { cloneDeep } from "lodash-es";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
	appIntegration,
	AppIntegrationCreate,
	appIntegrationDelete,
	appIntegrationListReq,
	appIntegrationUpdate,
	app as appProto,
	artifactRequest,
	deleteAppIntegrationVarsReq,
	integrationJobAbstract,
	integrationJobId,
	integrationJobListRequest,
	integrationJob as integrationJobProto,
	moduleListFilter,
	triggerIntegrationJob
} from "@/protocol/app";
import { AppIntegrationId, Artifact, ArtifactType, Provisioner } from "@/protocol/common";
import { pipelineModule, pipelineModuleReferenceV2 } from "@/protocol/pipeline";
import {
	JOB_STATUS,
	JOB_STEP_STATUS,
	PipelineEdge,
	PipelineJobStatus,
	pipelineModuleWithRefId,
	PipelineNode,
	STAGECARD_STATUS
} from "@/shared/pipeline-constants";

import { getJobStatusText } from "../env-pipeline/utils";
import { getStore } from "../store";

import {
	cancelIntegrationJob,
	createAppIntegration,
	deleteAppIntegration,
	deleteAppIntegrationVars,
	getAppIntegration,
	getJobArtifact,
	integrationJobList,
	integrationJobStatus,
	listAppIntegration,
	listAvailableModules,
	startIntegrationJob,
	updateAppIntegration
} from "./application-integration-service";

@Module({
	namespaced: true,
	dynamic: true,
	name: "applicationIntegration",
	store: getStore()
})
class ApplicationIntegrationStore extends VuexModule {
	integrations: Record<string, appIntegration[]> = {};
	integrationJobList: Record<string, integrationJobAbstract[]> = {};
	integrationJob: Record<string, integrationJobProto> = {};
	integrationLogs: Record<string, Record<string, Record<string, any>>> = {};
	pipelineModules: Record<string, pipelineModule> = {};
	moduleCatories: Record<string, pipelineModuleWithRefId[]> = {};
	currIntegrationAppId: string | null = null;

	get sortedIntegrationJobs() {
		return Object.values(this.integrationJob).sort((job1, job2) => {
			return Number(job2.createdAt) - Number(job1.createdAt);
		});
	}

	@Mutation
	RESET_APPLICATION_INTEGRATION() {
		this.integrations = {};
		this.integrationJobList = {};
		this.integrationJob = {};
		this.integrationLogs = {};
	}

	@Mutation
	SET_CURR_INTEGRATION_APPID(id: string | null) {
		this.currIntegrationAppId = id;
	}

	@Action
	async FETCH_INTEGRATIONS(request: appIntegrationListReq) {
		const integrations = (await listAppIntegration(request)).appIntegrations;

		this.SET_INTEGRATIONS({
			appId: request.appId,
			integrations
		});

		return integrations;
	}

	@Mutation
	SET_INTEGRATIONS({ appId, integrations }: { appId: string; integrations?: appIntegration[] }) {
		if (integrations) {
			this.integrations[appId] = integrations;
		}
	}

	@Action
	async FETCH_INTEGRATION_JOBS(request: integrationJobListRequest) {
		this.SET_INTEGRATION_JOBS({
			integrationId: request.integrationId,
			integrationJobs: (await integrationJobList(request)).integrationJobs
		});
	}

	@Mutation
	SET_PIPELINE_MODULES(mappedModules: Record<string, pipelineModule>) {
		this.pipelineModules = mappedModules;
	}

	@Mutation
	SET_MODULES_BY_CATEGORY(modules: pipelineModule[]) {
		const obj: Record<string, pipelineModuleWithRefId[]> = {};
		modules.forEach(module => {
			const moduleRefId = `${module.provisioner}:${module.name}@${module.version}`;
			// category with "" empty name are test modules,therefore we don't show them.
			if (
				module.category !== undefined &&
				obj[module.category] !== undefined &&
				module.category !== ""
			) {
				obj[module.category]?.push({ ...module, moduleRefId, isAdded: false });
			} else if (module.category !== undefined && module.category !== "") {
				obj[module.category] = [];
				obj[module.category]?.push({ ...module, moduleRefId, isAdded: false });
			}
		});
		this.moduleCatories = obj;
	}

	@Mutation
	RESET_MODULE_CATEGORIES() {
		this.moduleCatories = {};
	}

	@Action
	async FETCH_INTEGRATION_MODULES(request: moduleListFilter) {
		const response = (await listAvailableModules(request)).modules;

		if (response !== undefined) {
			const obj: Record<string, pipelineModule> = {};

			// set cateory wise modules
			this.SET_MODULES_BY_CATEGORY(response);

			response.forEach((module: pipelineModule) => {
				const moduleRefId = `${module.provisioner}:${module.name}@${module.version}`;
				obj[moduleRefId] = module;
			});

			this.SET_PIPELINE_MODULES(obj);
		}
	}

	@Mutation
	SET_INTEGRATION_JOBS({
		integrationId,
		integrationJobs
	}: {
		integrationId: string;
		integrationJobs?: integrationJobAbstract[];
	}) {
		if (integrationJobs) {
			this.integrationJobList[integrationId] = integrationJobs;
		}
	}

	@Action
	async FETCH_INTEGRATION_JOB(request: integrationJobId) {
		this.SET_INTEGRATION_JOB({
			jobId: request.id,
			integrationJob: await integrationJobStatus(request)
		});
	}

	@Mutation
	SET_INTEGRATION_JOB({
		jobId,
		integrationJob
	}: {
		jobId: string;
		integrationJob: integrationJobProto;
	}) {
		this.integrationJob[jobId] = integrationJob;
	}

	@Action
	async FETCH_INTEGRATION_LOG(request: Required<artifactRequest>) {
		this.SET_INTEGRATION_LOGS({
			jobId: request.job.id,
			fileName: request.artifactName,
			artifact: await getJobArtifact(request)
		});
	}

	@Mutation
	SET_INTEGRATION_LOGS({
		jobId,
		fileName,
		artifact
	}: {
		jobId: string;
		fileName: string;
		artifact: Record<string, any>;
	}) {
		if (!this.integrationLogs[jobId]) {
			this.integrationLogs[jobId] = {};
		}

		this.integrationLogs[jobId]![fileName] = artifact;
	}

	@Action
	async START_INTEGRATION_JOB(request: triggerIntegrationJob) {
		const integrationJob = await startIntegrationJob(request);

		this.SET_INTEGRATION_JOB({
			jobId: integrationJob.id,
			integrationJob
		});
		return integrationJob;
	}

	@Action
	async CANCEL_INTEGRATION_JOB(request: integrationJobId) {
		return await cancelIntegrationJob(request);
	}

	@Action
	async DELETE_APP_INTEGRATION(request: appIntegrationDelete) {
		return await deleteAppIntegration(request);
	}

	@Action
	async CREATE_APP_INTEGRATION(request: AppIntegrationCreate) {
		const integration = await createAppIntegration(request);

		const integrations = this.integrations[integration.appId] ?? [];

		this.SET_INTEGRATIONS({
			appId: integration.appId,
			integrations: [...integrations, integration]
		});

		return integration;
	}

	@Action
	async UPDATE_APP_INTEGRATION(request: appIntegrationUpdate) {
		const integrationRes = await updateAppIntegration(request);
		// update in store
		const currentIntegrations = this.integrations[request.appId];
		if (currentIntegrations) {
			const filteredIntegrations = currentIntegrations.filter(
				integration => integration.id !== integrationRes.id
			);
			filteredIntegrations.push(integrationRes);
			this.SET_INTEGRATIONS({
				appId: request.appId,
				integrations: filteredIntegrations
			});
		}
	}

	@Action
	async DELETE_APP_INTEGRATION_VAR(request: deleteAppIntegrationVarsReq) {
		await deleteAppIntegrationVars(request);
	}

	@Action
	async FETCH_APP_INTEGRATION(request: AppIntegrationId) {
		const addedIntegration = await getAppIntegration(request);
		const currentIntegrations = this.integrations[request.appId];
		if (!currentIntegrations) {
			this.SET_INTEGRATIONS({
				appId: request.appId,
				integrations: [addedIntegration]
			});
		} else {
			// see if this integration already exists, remove if exists
			const filteredIntegrations = currentIntegrations.filter(
				integration => integration.id !== addedIntegration.id
			);

			filteredIntegrations.push(addedIntegration);
			this.SET_INTEGRATIONS({
				appId: request.appId,
				integrations: filteredIntegrations
			});
		}
	}
}

const applicationIntegrationStore = getModule(ApplicationIntegrationStore);

export { applicationIntegrationStore };

export function getIntegrationDagMeta(job: integrationJobProto, selectedIdx = 0) {
	const edges: PipelineEdge[] = [];
	const nodes: PipelineNode[] = [];

	const jobState = job.jobStatus as PipelineJobStatus;
	const isJobActive = jobState === JOB_STATUS.ACTIVE;

	// Create initial steps and nodes for the app pipeline jobs.
	job.steps?.forEach((step, index) => {
		const nextStep = job.steps?.[index + 1];

		if (nextStep && step.name && nextStep.name) {
			edges.push({
				source: step.name,
				target: nextStep.name
			});
		}

		nodes.push({
			name: step.name,
			nodeNumber: [index + 1].toString(),
			type: "standby",
			statusText: isJobActive ? "Waiting..." : "",
			status: "Not Started",
			selected: index === selectedIdx,
			infoIcon: {
				remove: true
			}
		});

		// Update the nodes and edges with actual job data.
		const currentStep = job.progress ?? 0;

		for (const [idx, node] of nodes.entries()) {
			if (idx > currentStep) {
				break;
			}

			// Mark all previous step complete
			if (idx < currentStep) {
				node.type = STAGECARD_STATUS[JOB_STATUS.DONE];
				node.status = JOB_STEP_STATUS[JOB_STATUS.DONE];
				node.statusText = getJobStatusText(JOB_STATUS.DONE, job, idx);
			} else {
				// Mark the current one with respective job status
				node.type = STAGECARD_STATUS[jobState];
				node.status = JOB_STEP_STATUS[jobState];
				node.statusText = getJobStatusText(jobState, job, idx);
			}
		}
	});
	return { edges, nodes };
}

/**
 * Returns a sorted list of jobs
 */
export function getSortedIntegrationJobList(integrationId: string) {
	const integrations = applicationIntegrationStore.integrationJobList[integrationId];

	if (!integrations) {
		return [];
	}

	return [...integrations].sort((job1, job2) => {
		return Number(job2.createdAt) - Number(job1.createdAt);
	});
}

export const integrationEdgesNodes: { edges: PipelineEdge[]; nodes: PipelineNode[] } = {
	edges: [
		{
			source: "Integrate",
			target: "Finalize"
		}
	],
	nodes: [
		{
			name: "Integrate",
			nodeNumber: "1",
			type: "standby",
			statusText: "",
			status: "Not Started",
			selected: false,
			infoIcon: {
				remove: true
			}
		},
		{
			name: "Finalize",
			nodeNumber: "2",
			type: "standby",
			statusText: "",
			status: "Not Started",
			selected: false,
			infoIcon: {
				remove: true
			}
		}
	]
};
export function getPipelineModuleMeta(
	moduleRefs: pipelineModuleReferenceV2[] | undefined
): pipelineModule[] {
	const allModules = applicationIntegrationStore.pipelineModules;
	const filteredModules: pipelineModule[] = [];
	if (!moduleRefs) {
		return [];
	}
	moduleRefs.forEach(ref => {
		const pipeline = ref.moduleRefId ? allModules[ref.moduleRefId] : undefined;
		if (pipeline) {
			filteredModules.push(pipeline);
		}
	});
	return filteredModules;
}

export function getAllInputOutputArtifacts(
	integration: appIntegration | undefined | null,
	app: appProto | undefined | null,
	artifactType: ArtifactType
) {
	if (!integration && !app) {
		return [];
	} else if (!integration && app) {
		return getArtifactsFromApp(app.artifacts!, artifactType);
	} else if (integration && app) {
		const existingArtifacts: ArtifactMeta[] = [];

		// get existing app artifacts to show in the selection popover
		const appArtifacts = cloneDeep(app.artifacts);

		// Get the existing integration artifact to set isAdded flag true/false
		let integrationArtifact = integration.finalInputArtifact;
		if (artifactType === ArtifactType.ContainerImage) {
			integrationArtifact = integration.finalOutputArtifact;
		}

		/**
		 *  Loop over app artifacts and filtered required artifact type and toggle isAdded flag
		 * based on existing integration artifact
		 */
		if (appArtifacts) {
			appArtifacts.forEach(artifact => {
				const artifactMeta = getArtifactMeta(artifact);
				if (artifact.type === artifactType && artifactMeta) {
					existingArtifacts.push({
						...artifactMeta,
						name: artifact.name,
						isAdded: artifact.name === integrationArtifact?.name
					});
				}
			});
		}

		return existingArtifacts;
	}
}
export function getArtifactsFromApp(artifacts: Artifact[], artifactType: ArtifactType) {
	const arr: ArtifactMeta[] = [];
	artifacts.forEach(artifact => {
		const artifactMeta = getArtifactMeta(artifact);
		if (artifactMeta && artifact.type === artifactType) {
			arr.push(artifactMeta);
		}
	});

	return arr;
}

export type ArtifactMeta = {
	icon: string;
	name: string;
	desc: string;
	data: Artifact;
	moduleValues?: Record<string, any>;
	isAdded: boolean;
};

export function getArtifactMeta(artifact: Artifact | undefined): ArtifactMeta | null {
	if (!artifact) {
		return null;
	}

	const meta: ArtifactMeta | null = null;
	if (artifact.type === ArtifactType.GitCode) {
		return {
			icon: "p-github",
			name: artifact.name,
			desc: artifact.gitCode?.repo ?? "",
			data: cloneDeep(artifact),
			isAdded: false
		};
	} else if (artifact.type === ArtifactType.ContainerImage) {
		return {
			icon: "i-box",
			name: artifact.name,
			desc: artifact.containerImage?.repo ?? "",
			data: cloneDeep(artifact),
			isAdded: false
		};
	}
	return meta;
}

export function filterModulesByProvisioner(
	integration: appIntegration | undefined,
	provisioner?: Provisioner
) {
	const moduleCategories = { ...applicationIntegrationStore.moduleCatories };
	const filteredCategories: Record<string, pipelineModuleWithRefId[]> = {};

	for (const key in moduleCategories) {
		const filteredModules = moduleCategories[key]?.filter(module => {
			if (provisioner) {
				return module.provisioner === provisioner;
			}

			return true;
		});

		if (integration) {
			filteredModules?.forEach((module, idx) => {
				integration.pipeline?.forEach(pipeline => {
					if (pipeline.moduleRefId === module.moduleRefId) {
						filteredModules[idx] = {
							...module,
							isAdded: true
						};
					}
				});
			});
		}

		if (filteredModules && filteredModules.length > 0) {
			filteredCategories[key] = filteredModules;
		}
	}

	return filteredCategories;
}
