<template>
	<f-div padding="large" gap="large" width="100%" align="middle-left" direction="column">
		<f-div height="hug-content" gap="small" align="middle-left">
			<f-icon
				source="i-arrow-left"
				class="cursor-pointer"
				data-qa-app-connect-form-back-btn
				state="default"
				@click="goBack"
			>
			</f-icon>
			<f-div gap="small" width="hug-content" align="middle-center">
				<f-text inline variant="heading" size="small" weight="bold" class="fill-container">
					{{ controlGate.name }}
				</f-text>
				<f-icon
					source="i-info-fill"
					state="secondary"
					class="cursor-pointer"
					size="small"
					data-qa-control-gate-detail-info-icon
					tooltip="All control sets and controls"
				></f-icon>
			</f-div>
		</f-div>

		<f-div id="reportTemplate" state="default">
			<f-table variant="underlined">
				<f-trow slot="header">
					<f-tcell v-for="header in headers" :key="header">{{ header }}</f-tcell>
				</f-trow>
				<f-trow v-for="row in tableData" :key="row.id">
					<f-tcell>
						<f-text state="secondary"> {{ row.controlSet }} </f-text>
					</f-tcell>
					<f-tcell>
						<Markdown
							v-if="row?.description"
							width="450px"
							data-qa-row-description
							:text="row?.description"
							size="medium"
							state="secondary"
						/>
					</f-tcell>
					<!-- <f-tcell>
						<f-text state="secondary"> {{ row.statementId }} </f-text>
					</f-tcell>
					<f-tcell>
						<f-text state="secondary"> {{ row.statement }} </f-text>
					</f-tcell>
					<f-tcell>
						<f-text state="secondary"> {{ row.type }} </f-text>
					</f-tcell>
					<f-tcell>
						<f-text state="secondary"> {{ row.component }} </f-text>
					</f-tcell> -->
				</f-trow>
			</f-table>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import { PolicySet, policySetType } from "@/protocol/validator";
import Markdown from "@/shared/components/Markdown.vue";

import { policyListStore } from "../policy-list-store";

export default defineComponent({
	name: "ControlGateDetails",
	components: { Markdown },

	props: {
		controlGate: {
			type: Object as PropType<PolicySet>,
			required: true
		}
	},

	emits: ["back"],

	computed: {
		headers() {
			return ["Control Sets", "Description"];
		},

		tableData() {
			return this.generateTableData(this.controlGate);
		},

		policySets() {
			return policyListStore.policySets;
		}
	},

	methods: {
		goBack() {
			this.$emit("back");
		},

		getChildren(importSets: string[], filterArray: PolicySet[]): PolicySet[] {
			return importSets
				.map(setId => {
					return filterArray.find(s => s.id === setId) ?? null;
				})
				.filter(p => p !== null) as PolicySet[];
		},

		generateTableData(policy: PolicySet) {
			if (!policy.importSets) {
				return [];
			}
			const allControlSets = this.policySets.filter(
				p => p.type === policySetType.setTypeControlSet
			);
			const controlSets = this.getChildren(policy.importSets, allControlSets);
			// const allControls = this.policySets.filter(p => p.type === policySetType.setTypeControl);

			const rows: {
				id: string;
				controlSet: string;
				description?: string;
			}[] = [];

			controlSets.forEach((set, i) => {
				// const controls = this.getChildren(set.importSets ?? [], allControls);
				// controls.forEach(control => {
				rows.push({
					id: i + set.name,
					controlSet: set.name,
					description: set.description
				});
				// });
			});
			return rows;
		}
	}
});
</script>
