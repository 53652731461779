<template>
	<PopOver :open="open" placement="bottom-start" @overlay-click="open = false">
		<Wrapper height="36px" background="gray-400" border-radius="4px">
			<Container
				padding="0px 12px"
				:grow="1"
				align="space-between center"
				clickable
				@click="open = !open"
			>
				<Container :padding="0" :gap="12">
					<Icon
						v-if="value.state === 'error'"
						name="i-alert"
						state="error"
						size="small"
						:effects="false"
					/>
					<Icon
						v-if="value.state === 'running'"
						name="i-loader-success"
						animate
						size="small"
						:effects="false"
					/>
					<Icon
						v-if="value.state === 'default'"
						name="i-launch"
						color="gray-200"
						size="small"
						:effects="false"
					/>
					<Typography type="p2" color="dark" overflow="ellipsis">{{ value.label }}</Typography>
				</Container>
				<Container :padding="0" :gap="12">
					<Tag v-if="stepText" size="small" background="rgba(255, 255, 255, 0.08)">
						<Typography type="p3" color="dark" :data-qa-step-header-text="stepText">
							{{ stepText }}
						</Typography>
					</Tag>
					<Icon name="i-caret-down" :rotate="open ? 180 : 0" state="dark" size="small" />
				</Container>
			</Container>
		</Wrapper>
		<template #content>
			<Wrapper width="330px" background="gray-500">
				<Container
					v-for="option in options"
					:key="option.label"
					:grow="1"
					:padding="12"
					clickable
					align="space-between center"
				>
					<Container :grow="1" :padding="0">
						<Pictogram
							size="m"
							:state="option.state === 'error' ? 'error-light' : 'element'"
							:shape="option.shape"
							:loading="option.state === 'running'"
							:border-state="option.state === 'running' ? 'success' : 'default'"
						>
							<Typography type="p4" weight="bold" color="dark" transform="uppercase">{{
								getShortCode(option.label)
							}}</Typography>
						</Pictogram>
						<Typography type="p1" color="dark" :data-qa-option-label="option.label">{{
							option.label
						}}</Typography>
					</Container>
					<Container
						v-if="option.state === 'error'"
						:padding="0"
						:gap="12"
						:data-qa-option-error="option.label"
					>
						<Icon name="i-alert" size="x-small" :effects="false" state="error" />
						<!-- <Typography type="p2" weight="bold" transform="uppercase" link color="primary-300">
							RETRY
						</Typography> -->
					</Container>
					<Container
						v-if="option.state === 'success'"
						:padding="0"
						:gap="12"
						:data-qa-option-success="option.label"
					>
						<Icon name="i-tick" size="x-small" :effects="false" state="success" />
					</Container>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>
<script lang="ts">
import { PopOver, Container, Wrapper, Pictogram, Typography, Icon, Tag } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { getShortCode } from "@/utils";

type PictogramShape = "hexagon" | "default" | "squircle" | "circle";

export type DropdownWithStatusOptionState = "success" | "error" | "running" | "default";

export type DropdownWithStatusOption = {
	label: string;
	shape: PictogramShape;
	state: DropdownWithStatusOptionState;
};

export type DropdownWithStatusCurrentState = {
	label: string;
	state: DropdownWithStatusOptionState;
};

export default defineComponent({
	name: "DropdownWithStatus",

	components: {
		PopOver,
		Container,
		Wrapper,
		Pictogram,
		Typography,
		Icon,
		Tag
	},

	props: {
		value: {
			type: Object as PropType<DropdownWithStatusCurrentState>,
			required: true
		},

		stepText: {
			type: String
		},

		options: {
			type: Array as PropType<DropdownWithStatusOption[]>
		}
	},

	data: () => ({
		open: false,
		getShortCode
	})
});
</script>
