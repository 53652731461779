import stripAnsi from "strip-ansi";

export type DeploymentArtifactLogItem = {
	JobID: string;
	Level: number;
	Message: string;
	RequestContext: {
		request_id: string;
		userId: string;
	};
	StepName: string;
	Timestamp: string;
};

export type JSONLogArtifact = {
	type: "application/json";
	contents: DeploymentArtifactLogItem[];
};

export type PlainLogArtifact = {
	type: "text/plain";
	contents: string;
};

export function isJSONLogArtifact(artifact: unknown): artifact is JSONLogArtifact {
	if (typeof artifact !== "object" || artifact === null) {
		return false;
	}

	return "type" in artifact && "contents" in artifact && artifact.type === "application/json";
}

export function isPlainLogArtifact(artifact: unknown): artifact is PlainLogArtifact {
	if (typeof artifact !== "object" || artifact === null) {
		return false;
	}

	return "type" in artifact && "contents" in artifact && artifact.type === "text/plain";
}

export function isJSONArtifact(artifact: unknown): artifact is Record<string, any> {
	return typeof artifact === "object" && artifact !== null;
}

export function fileToString(artifact: unknown): string {
	if (isJSONLogArtifact(artifact)) {
		return artifact.contents.map(logLine => logLine.Message).join("\n");
	}

	if (isPlainLogArtifact(artifact)) {
		return artifact.contents;
	}

	if (isJSONArtifact(artifact)) {
		return JSON.stringify(artifact, null, 2);
	}

	return String(artifact);
}

export function getFileExtension(file: string) {
	try {
		JSON.parse(file);
		return "json";
	} catch (err) {
		return "log";
	}
}

export function removeFileExtension(fileName: string) {
	return fileName.replace(/\.[^/.]+$/, "");
}

export function downloadFile(fileContents: unknown, fileName: string) {
	const element = document.createElement("a");

	let stringFile = fileToString(fileContents);

	const actualFileExtension = getFileExtension(stringFile);

	// We strip ANSI from log files so make it easier to read
	if (actualFileExtension === "log") {
		stringFile = stripAnsi(stringFile);
	}

	// Server file extensions are the type of the actual file, not the contents, for example a JSON file
	// could contain a log file inside, so we remove the extension and add the correct one based on the
	// file contents
	const finalFileName = `${removeFileExtension(fileName)}.${actualFileExtension}`;

	element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(stringFile)}`);

	element.setAttribute("download", finalFileName);

	element.style.display = "none";
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

export function downloadBase64File(fileContents: string, fileName: string) {
	const element = document.createElement("a");
	element.setAttribute("href", `data:application/octet-stream;base64,${fileContents}`);
	element.setAttribute("download", fileName);
	element.style.display = "none";
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
}
