import { FSelectOptionObject } from "@cldcvr/flow-core";

export type AwsRegionType = FSelectOptionObject & {
	data: { id: string };
};

export const awsRegions: AwsRegionType[] = [
	{
		data: { id: "us-east-1" },
		title: "US East (N. Virginia)"
	},
	{
		data: { id: "us-east-2" },
		title: "US East (Ohio)"
	},
	{
		data: { id: "us-west-1" },
		title: "US West (N. California)"
	},
	{
		data: { id: "us-west-2" },
		title: "US West (Oregon)"
	},
	{
		data: { id: "ca-central-1" },
		title: "Canada (Central)"
	},
	{
		data: { id: "eu-central-1" },
		title: "EU (Frankfurt)"
	},
	{
		data: { id: "eu-west-1" },
		title: "EU (Ireland)"
	},
	{
		data: { id: "eu-west-2" },
		title: "EU (London)"
	},
	{
		data: { id: "eu-west-3" },
		title: "EU (Paris)"
	},
	{
		data: { id: "eu-north-1" },
		title: "EU (Stockholm)"
	},
	{
		data: { id: "ap-northeast-1" },
		title: "Asia Pacific (Tokyo)"
	},
	{
		data: { id: "ap-northeast-2" },
		title: "Asia Pacific (Seoul)"
	},
	{
		data: { id: "ap-northeast-3" },
		title: "Asia Pacific (Osaka-Local)"
	},
	{
		data: { id: "ap-southeast-1" },
		title: "Asia Pacific (Singapore)"
	},
	{
		data: { id: "ap-southeast-2" },
		title: "Asia Pacific (Sydney)"
	},
	{
		data: { id: "ap-south-1" },
		title: "Asia Pacific (Mumbai)"
	},
	{
		data: { id: "sa-east-1" },
		title: "South America (São Paulo)"
	},
	{
		data: { id: "us-gov-east-1" },
		title: "AWS GovCloud (US-East)"
	},
	{
		data: { id: "us-gov-west-1" },
		title: "AWS GovCloud (US)"
	}
];
