<template>
	<Container align="left top" direction="column" overflow="visible" basis="55%" :grow="1">
		<Container align="left top" direction="row" :padding="0" :gap="16">
			<!-- <Wrapper height="100%" width="40px">
				<Container padding="14px 0px" :gap="0" align="left center">
					<Icon :name="dependency.logo" type="filled" size="x-large" />
				</Container>
			</Wrapper> -->
			<Container :padding="0" :gap="5" direction="column">
				<Container :padding="0">
					<Typography
						type="h3"
						weight="bold"
						:data-qa-add-dependency-selected-name="dependency.name"
						>{{ dependency.name }}</Typography
					>
				</Container>
			</Container>
		</Container>

		<Container v-if="dependency.description" padding="0">
			<Divider />
		</Container>

		<Container
			v-if="dependency.description"
			align="left top"
			padding="8px 0px"
			direction="column"
			:gap="16"
			overflow="visible"
		>
			<Typography type="p2" color="gray-200" weight="book">DESCRIPTION</Typography>
			<Typography
				type="p2"
				color="gray-100"
				weight="thin"
				:data-qa-add-dependency-selected-description="dependency.description"
				>{{ dependency.description }}</Typography
			>
		</Container>

		<Container v-if="hasOutputs" padding="0">
			<Divider />
		</Container>

		<Container
			v-if="hasOutputs"
			align="left top"
			padding="8px 0px"
			direction="column"
			:gap="16"
			overflow="visible"
		>
			<Typography type="p2" color="gray-200" weight="book">OUTPUT</Typography>
			<Container
				v-for="(item, idx) in dependency.outputs"
				:id="idx"
				:key="idx"
				padding="0px 0px 8px 0px"
				direction="column"
				overflow="visible"
				:gap="8"
				:data-qa-add-dependency-output="idx"
			>
				<Typography type="p1" weight="medium">{{ item }}</Typography>
				<Typography type="p2" color="gray-100" family="logs">{{ idx }}</Typography>
			</Container>
		</Container>

		<Container v-if="hasInputs" padding="0">
			<Divider />
		</Container>

		<Container v-if="hasInputs" align="left top" padding="8px 0px" direction="column" :gap="16">
			<Typography type="p2" color="gray-200" weight="book">INPUT</Typography>
			<Container
				v-for="(item, idx) in dependency.inputs"
				:id="idx"
				:key="idx"
				overflow="visible"
				padding="0px 0px 8px 0px"
				direction="column"
				:gap="8"
				:data-qa-add-dependency-input="idx"
			>
				<Typography type="p1" weight="medium">{{ item }}</Typography>
				<Typography type="p2" color="gray-100" family="logs">{{ idx }}</Typography>
			</Container>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Divider, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { Dependency } from "@/protocol/infra";

export default defineComponent({
	name: "DependencyDescription",

	components: {
		Container,
		Typography,
		Divider
	},

	props: {
		dependency: {
			type: Object as PropType<Dependency>,
			required: true
		}
	},

	computed: {
		hasInputs() {
			return Object.keys(this.dependency.inputs).length > 0;
		},

		hasOutputs() {
			return Object.keys(this.dependency.outputs).length > 0;
		}
	}
});
</script>
