<template>
	<Wrapper
		border-radius="4px"
		background="gray-500"
		width="432px"
		height="316px"
		max-height="80vh"
		overflow="visible"
		data-qa-cancel-cp-wrapper
	>
		<Header>
			<Typography type="h4" color="dark" data-qa-cancel-cp-title>
				Promoting {{ app.name }} {{ job?.deployVersion }}...
			</Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Icon
					size="x-small"
					type="filled"
					name="i-close"
					data-qa-cancel-cp-close-btn
					@click="$emit('close')"
				/>
			</Container>
		</Header>
		<Container :padding="0" :gap="0" direction="column" align="left top">
			<Divider />
			<Container padding="16px" :gap="16" align="left top" direction="column" overflow="visible">
				<Typography
					v-if="!isConfirmingCancellation && !isCancelling"
					type="p1-para"
					color="dark"
					data-qa-cancel-promotion-progress-text
				>
					Promotion in progress...
				</Typography>

				<Typography
					v-if="isConfirmingCancellation && !isCancelling"
					type="p1-para"
					color="dark"
					data-qa-cancel-promotion-confirm-cancel-text
				>
					Are you sure you want to cancel the promotion of {{ app.name }} from
					{{ promoteFromEnv }} to {{ promoteToEnv }}
				</Typography>

				<Typography v-if="isCancelling" type="p1-para" color="dark" data-qa-cancelling-promo-text>
					Cancelling promotion...
				</Typography>
			</Container>
		</Container>

		<Footer>
			<Button
				type="error"
				state="full"
				size="small"
				:loading="isSubmitting"
				:data-qa-cancel-submit-btn="submitBtnTxt"
				@click="!isConfirmingCancellation ? confirmCancellation() : cancelPromotion()"
			>
				{{ submitBtnTxt }}
			</Button>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import {
	Container,
	Header,
	Footer,
	Icon,
	Typography,
	Wrapper,
	Button,
	Divider
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { app as AppProto } from "@/protocol/app";
import { jobResponse } from "@/protocol/deployment";

import { EnvDepMapping } from "../views/CodePromotionWrapper.vue";

export default defineComponent({
	name: "CancelCodePromotion",

	components: {
		Container,
		Header,
		Footer,
		Icon,
		Typography,
		Wrapper,
		Button,
		Divider
	},

	props: {
		job: {
			type: Object as PropType<jobResponse | null>
		},

		deployment: {
			type: Object as PropType<EnvDepMapping>
		},

		app: {
			type: Object as PropType<AppProto>,
			required: true
		},

		promoteFromEnv: {
			type: String,
			required: true
		},

		promoteToEnv: {
			type: String,
			required: true
		},

		isSubmitting: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		isConfirmingCancellation: false,
		isCancelling: false
	}),

	computed: {
		submitBtnTxt() {
			return `Cancel Promotion ${this.isConfirmingCancellation ? " & rollback" : ""} ? `;
		}
	},

	methods: {
		confirmCancellation() {
			this.isConfirmingCancellation = true;
		},

		cancelPromotion() {
			if (this.isConfirmingCancellation) {
				this.isCancelling = true;
				this.$emit("confirm", { dep: this.deployment, job: this.job });
				this.isConfirmingCancellation = false;
			}
		}
	}
});
</script>
