/**
 * Flow 2 components use web components and shadow DOM extensively. This causes issues with XPath or Query selectors
 * because they don't work with shadow DOM. This polyfill fixes that.
 */
export function polyfillXpath() {
	import("xpath-next").then(xpath => {
		const oldEvaluate = document.evaluate;

		// eslint-disable-next-line max-params
		document.evaluate = function (
			expression: string,
			contextNode: Node,
			resolver?: XPathNSResolver | null,
			type?: number,
			result?: XPathResult | null
		) {
			const evalResult = oldEvaluate.call(
				document,
				expression,
				contextNode,
				resolver,
				type,
				result
			);

			// If the native evaluate didn't find anything, try the polyfill
			if (
				evalResult.resultType === XPathResult.FIRST_ORDERED_NODE_TYPE &&
				!evalResult.singleNodeValue
			) {
				const nodes = xpath.parse(expression).select({ node: contextNode, isHtml: true });

				return Object.create(evalResult, {
					singleNodeValue: {
						writable: true,
						value: nodes[0] ?? null
					}
				});
			} else if (
				(evalResult.resultType === XPathResult.ORDERED_NODE_SNAPSHOT_TYPE ||
					evalResult.resultType === XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE) &&
				evalResult.snapshotLength === 0
			) {
				const nodes = xpath.parse(expression).select({ node: contextNode, isHtml: true });

				return Object.create(evalResult, {
					snapshotLength: {
						writable: true,
						value: nodes.length
					},
					snapshotItem: {
						writable: true,
						value: (index: number) => nodes[index]
					}
				});
			}

			return evalResult;
		};
	});

	import("query-selector-ponyfill");
}
