<template>
	<div @mouseenter="onMouseEnter">
		<slot name="placeholder" />
		<slot v-if="hasHovered" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Renders the default slot when the placeholder is hovered, this is useful for performance intensive component
 * which can skip rendering when not hovered.
 */
export default defineComponent({
	name: "RenderOnHover",

	data() {
		return {
			hasHovered: false
		};
	},

	methods: {
		onMouseEnter() {
			this.hasHovered = true;
		}
	}
});
</script>
