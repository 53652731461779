import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
	AppCreate,
	AppDependencies,
	AppId as appIdProto,
	app as appProto,
	appUpdate,
	ciInferRequest
} from "@/protocol/app";
import { GitDetailsRequest } from "@/protocol/identity";

import { applicationDeploymentStore } from "../application-deployment/application-deployment-store";
import { applicationIntegrationStore } from "../application-integration/application-integration-store";
import { getStore } from "../store";

import {
	createApplication,
	deleteAppEntities,
	deleteApplication,
	getApp,
	getGitLanguageList,
	inferCIPipeline,
	listAppsInProject,
	updateAppDependency,
	updateApplication
} from "./application-service";
import { AppWizardChecklistEnum } from "./views/appWizardFlowSteps";

export type AppListParams = {
	orgId: string;
	projectId: string;
};

@Module({
	namespaced: true,
	dynamic: true,
	name: "application",
	store: getStore()
})
class AppListStore extends VuexModule {
	projectApps: Record<string, appProto[]> = {};

	lastCreatedAppId: string | null = null;
	currAppWizardStep: AppWizardChecklistEnum = AppWizardChecklistEnum.NOT_SELECTED;

	@Mutation
	UPDATE_APP_WIZARD_STEP(step: AppWizardChecklistEnum) {
		this.currAppWizardStep = step;
	}

	get apps() {
		const apps: Record<string, appProto> = {};

		Object.values(this.projectApps).forEach(appList => {
			appList.forEach(app => {
				apps[app.id] = app;
			});
		});

		return apps;
	}

	@Mutation
	RESET_APPLICATION() {
		this.projectApps = {};
	}

	@Mutation
	SET_LAST_APP_ID(appId: string | null) {
		this.lastCreatedAppId = appId;
	}

	@Mutation
	UPDATE_PROJECT({ projectId, apps }: { projectId: string; apps?: appProto[] }) {
		if (apps) {
			this.projectApps[projectId] = apps;
		}
	}

	@Mutation
	UPDATE_APP(app: appProto) {
		const projectApps = this.projectApps[app.projId];

		if (projectApps) {
			const newApps = projectApps.filter(projApp => projApp.id !== app.id);
			this.projectApps[app.projId] = [...newApps, app];
		}
	}

	@Mutation
	REMOVE_APP_FROM_PROJECT({ projectId, appId }: { projectId: string; appId: string }) {
		const filteredApps = this.projectApps[projectId]?.filter(app => app.id !== appId);
		this.projectApps[projectId] = filteredApps ?? [];
	}

	@Action
	async UPDATE_DEPENDENCY(request: AppDependencies) {
		await updateAppDependency(request);

		const updatedApp = await getApp({
			...request,
			id: request.appId
		});

		this.UPDATE_APP(updatedApp);
	}

	@Action
	async FETCH_APPS_IN_PROJECT({ orgId, projectId }: AppListParams) {
		const response = await listAppsInProject({ orgId, projId: projectId });
		this.UPDATE_PROJECT({ projectId, apps: response.apps });
	}

	@Action
	GET_APP_BY_ID({ orgId, projectId, appId }: { orgId: string; projectId: string; appId: string }) {
		return getApp({ orgId, projId: projectId, id: appId });
	}

	@Action
	async DELETE_APPLICATION({ orgId, projId, id, force }: appIdProto & { force: boolean }) {
		if (force) {
			// This can potentially lead to orphaned entities in the deployments, integrations and webhooks store
			// But since the app itself is deleted there won't be a way for the user to discover those entities in the UI
			applicationIntegrationStore.RESET_APPLICATION_INTEGRATION();
			applicationDeploymentStore.RESET_APPLICATION_DEPLOYMENT();
			await deleteAppEntities({ orgId, projId, id });
		}

		const response = await deleteApplication({ orgId, projId, id });

		const projectApps = this.projectApps[projId];
		if (projectApps && response.success) {
			this.UPDATE_PROJECT({ projectId: projId, apps: projectApps.filter(app => app.id !== id) });
		}

		return response;
	}

	@Action
	async CREATE_APPLICATION(appPayload: AppCreate) {
		const app = await createApplication(appPayload);

		this.SET_LAST_APP_ID(app.id);

		const apps = this.projectApps[app.projId] ?? [];

		this.UPDATE_PROJECT({ projectId: app.projId, apps: [app, ...apps] });

		return app;
	}

	@Action
	async UPDATE_APPLICATION(appPayload: appUpdate) {
		const result = await updateApplication(appPayload);

		const updatedApp = await getApp({
			...appPayload
		});

		this.UPDATE_APP(updatedApp);

		return result;
	}

	@Action
	async INFER_CI_PIPELINE(payload: ciInferRequest) {
		return await inferCIPipeline(payload);
	}

	@Action
	async FETCH_GIT_LANGUAGES(payload: GitDetailsRequest) {
		return await getGitLanguageList(payload);
	}
}

const applicationStore = getModule(AppListStore);

export { applicationStore };
