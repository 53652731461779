<template>
	<Container direction="column" :padding="0" :gap="8">
		<Pictogram shape="squircle" size="xl">
			<Typography type="h3">{{ emoji ? emoji : shortCode }}</Typography>
		</Pictogram>
		<Typography type="p3" color="dark" :data-qa-code-promo-app-name="name">{{ name }}</Typography>
	</Container>
</template>
<script lang="ts">
import { Pictogram, Typography, Container } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { getShortCode } from "@/utils";

export default defineComponent({
	name: "CodePromoApp",

	components: {
		Pictogram,
		Typography,
		Container
	},

	props: {
		name: {
			type: String,
			required: true
		},

		emoji: {
			type: String
		}
	},

	computed: {
		shortCode() {
			return getShortCode(this.name);
		}
	}
});
</script>
