import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { PolicySet, PolicySetCreate, PolicySetId } from "@/protocol/validator";

import { getStore } from "../store";

import {
	createPolicySet,
	deletePolicySet,
	listPolicySets,
	updatePolicySet
} from "./services/policy-list-service";

@Module({
	namespaced: true,
	dynamic: true,
	name: "policyList",
	store: getStore()
})
class PolicyListStore extends VuexModule {
	policySets: PolicySet[] = [];

	@Mutation
	SET_POLICY_SETS({ policySets }: { policySets: PolicySet[] }) {
		this.policySets = policySets;
	}

	@Mutation
	RESET_POLICIES() {
		this.policySets = [];
	}

	@Action
	async GET_POLICYSETS({ orgId }: { orgId: string }) {
		const { policySets } = await listPolicySets({ orgId, privateOnly: false });

		if (policySets) {
			this.SET_POLICY_SETS({ policySets });
		}
	}

	@Action
	async CREATE_POLICYSET(payload: PolicySetCreate) {
		const res = await createPolicySet(payload);

		if (res.orgId) {
			this.GET_POLICYSETS({ orgId: res.orgId });
			return res;
		}
	}

	@Action
	async UPDATE_POLICYSET(payload: PolicySet) {
		const res = await updatePolicySet(payload);

		if (payload.orgId) {
			this.GET_POLICYSETS({ orgId: payload.orgId });
			return res;
		}
	}

	@Action
	async DELETE_POLICYSET(payload: PolicySetId) {
		const res = await deletePolicySet(payload);

		if (payload.orgId) {
			this.GET_POLICYSETS({ orgId: payload.orgId });
			return res;
		}
	}
}

const policyListStore = getModule(PolicyListStore);

export { policyListStore };
