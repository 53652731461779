<template>
	<div data-qa-int-pipeline-widget-footer>
		<!-- Failed & DONE & Cancelled-->
		<Container
			v-if="
				latestJob?.jobStatus === 'failed' ||
				latestJob?.jobStatus === 'done' ||
				latestJob?.jobStatus === 'canceled'
			"
			:padding="0"
			:gap="0"
			align="space-between stretch"
			:data-qa-field="latestJob?.jobStatus"
			:shrink="0"
		>
			<Container :padding="12" :gap="8" :shrink="0">
				<IntegrationStatusText :integration-job="latestJob" />
			</Container>

			<Container :padding="12" :gap="16" :shrink="0">
				<Button
					v-tooltip="{
						content: 'View pipeline details',
						placement: 'top-start'
					}"
					state="outlined"
					type="default"
					size="small"
					data-qa-int-pipeline-view-logs-btn
					@click="viewLogs"
					>View logs
				</Button>

				<!-- Pipeline trigger start -->
				<PopOver
					v-if="integration.finalInputArtifact"
					placement="top-start"
					:open="isRunPipelinePopoverOpen"
					@overlay-click="isRunPipelinePopoverOpen = false"
				>
					<Button
						v-tooltip="{
							content:
								latestJob?.jobStatus === 'done'
									? 'Run app integration pipeline'
									: 'Retry app integration pipeline',
							placement: 'top-start'
						}"
						state="outlined"
						:type="latestJob?.jobStatus === 'done' ? 'success' : 'error'"
						size="small"
						:data-qa-int-pipeline-trigger-text="retryBtnTxt"
						@click="isRunPipelinePopoverOpen = true"
						>{{ retryBtnTxt }}
					</Button>

					<template #content>
						<ConfirmSaveAndRun
							:input-artifact="integration.finalInputArtifact"
							:output-artifact="integration.finalOutputArtifact"
							:error-message="submitError"
							:is-submitting="isSubmitting"
							@close="isRunPipelinePopoverOpen = false"
							@confirmed="triggerPipeline"
						/>
					</template>
				</PopOver>
				<!-- pipeline trigger end -->
			</Container>
		</Container>
		<!-- SAVED -->
		<Container
			v-else-if="!latestJob"
			:padding="0"
			:gap="0"
			data-qa-no-int-job-details
			align="space-between stretch"
			:shrink="0"
		>
			<Container :padding="12" :gap="8" :shrink="0">
				<Icon name="i-tick" size="x-small" />
				<CustomTimeStamp
					v-if="integration.updatedAt"
					:time="integration.updatedAt"
					prefix="Saved"
					data-qa="int-pipeine-saved-txt"
				/>
			</Container>
			<Container :padding="12" :gap="16" :shrink="0">
				<Button
					v-tooltip="{
						content: 'View pipeline details',
						placement: 'top-start'
					}"
					state="outlined"
					type="default"
					size="small"
					data-qa-int-pipeline-edit-btn
					@click="editIntegration"
					>View details
				</Button>

				<!-- Pipeline trigger start -->
				<PopOver
					v-if="integration.finalInputArtifact"
					placement="top-start"
					:open="isRunPipelinePopoverOpen"
					@overlay-click="isRunPipelinePopoverOpen = false"
				>
					<Button
						v-tooltip="{
							content: 'Run app integration pipeline',
							placement: 'top-start'
						}"
						state="outlined"
						type="success"
						size="small"
						:data-qa-int-pipeline-trigger-btn-txt="retryBtnTxt"
						@click="isRunPipelinePopoverOpen = true"
						>{{ retryBtnTxt }}
					</Button>
					<template #content>
						<ConfirmSaveAndRun
							:input-artifact="integration.finalInputArtifact"
							:output-artifact="integration.finalOutputArtifact"
							:is-submitting="isSubmitting"
							:error-message="submitError"
							@close="isRunPipelinePopoverOpen = false"
							@confirmed="triggerPipeline"
						/>
					</template>
				</PopOver>
				<!-- pipeline trigger end -->
			</Container>
		</Container>

		<!-- ACTIVE & Canceling -->
		<Container
			v-else-if="latestJob?.jobStatus === 'active' || latestJob?.jobStatus === 'canceling'"
			:padding="0"
			:gap="0"
			align="space-between stretch"
			:data-data-int-pipeline-status="latestJob?.jobStatus"
			:shrink="0"
		>
			<Container :padding="12" :gap="8" :shrink="0">
				<Icon
					name="i-loader"
					size="x-small"
					data-qa-active-pipeline-loader-icon
					:state="latestJob?.jobStatus === 'active' ? 'primary' : 'error'"
					:animate="true"
				/>
				<CustomTimeStamp
					v-if="latestJob.updatedAt && latestJob?.jobStatus === 'active'"
					:time="latestJob.updatedAt"
					prefix="running since"
					data-qa="int-pipeline-active-status-txt"
				/>
				<Typography v-else type="p2" overflow="ellipsis" data-qa-int-pipeline-active-status-txt>
					cancelling
				</Typography>
			</Container>

			<Container :padding="12" :gap="16" :shrink="0">
				<Button
					v-tooltip="{
						content: 'View pipeline details',
						placement: 'top-start'
					}"
					state="outlined"
					type="default"
					size="small"
					data-qa-int-pipeline-edit-btn
					@click="viewLogs"
					>View logs
				</Button>

				<!-- Pipeline trigger start -->
				<PopOver
					placement="top-start"
					:open="isCancelPipelinePopoverOpen"
					@overlay-click="isCancelPipelinePopoverOpen = false"
				>
					<Button
						v-tooltip="{
							content: 'Cancel app integration pipeline',
							placement: 'top-start'
						}"
						state="outlined"
						type="error"
						size="small"
						:disabled="latestJob?.jobStatus === 'canceling'"
						data-qa-cancel-int-pipeline-btn
						@click="isCancelPipelinePopoverOpen = true"
						>{{ latestJob?.jobStatus === "active" ? "Cancel" : "Cancelling" }}
					</Button>
					<template #content>
						<ConfirmCancelPipeline
							@close="isCancelPipelinePopoverOpen = false"
							@confirmed="cancelPipeline"
						/>
					</template>
				</PopOver>
				<!-- pipeline trigger end -->
			</Container>
		</Container>
		<!-- WAITING -->
		<Container
			v-else-if="latestJob?.jobStatus === 'waiting'"
			:padding="0"
			:gap="0"
			align="space-between stretch"
			data-qa-int-pipeline-waiting-details
			:shrink="0"
		>
			<Container :padding="12" :gap="8" :shrink="0">
				<Icon name="i-loader" size="x-small" state="error" :animate="true" />
				<CustomTimeStamp
					v-if="latestJob.updatedAt"
					:time="latestJob.updatedAt"
					prefix="waiting since"
					data-qa="waiting-int-pipeline-status-txt"
				/>
			</Container>
			<Container :padding="12" :gap="16" :shrink="0">
				<Button
					v-tooltip="{
						content: 'View pipeline details',
						placement: 'top-start'
					}"
					state="outlined"
					type="default"
					size="small"
					data-qa-int-pipeline-view-logs-btn
					@click="viewLogs"
					>View logs
				</Button>
				<!-- Pipeline trigger start -->
				<PopOver
					placement="top-start"
					:open="isCancelPipelinePopoverOpen"
					@overlay-click="isCancelPipelinePopoverOpen = false"
				>
					<Button
						v-tooltip="{
							content: 'Retry app integration pipeline',
							placement: 'top-start'
						}"
						state="outlined"
						type="error"
						size="small"
						data-qa-retry-btn-int-pipeline
						@click="isCancelPipelinePopoverOpen = true"
					>
						Cancel
					</Button>
					<template #content>
						<ConfirmCancelPipeline
							@close="isCancelPipelinePopoverOpen = false"
							@confirmed="cancelPipeline"
						/>
					</template>
				</PopOver>
				<!-- pipeline trigger end -->
			</Container>
		</Container>
		<Container v-else overflow="visible" padding="8px 8px 8px">
			<Icon
				v-if="statusIcon"
				:name="statusIcon.name"
				:state="statusIcon.state"
				type="filled"
				size="small"
				:effects="false"
				:animate="statusIcon.loading"
			/>
		</Container>
	</div>
</template>
<script lang="ts">
import { Button, Container, Icon, IconState, PopOver, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import ConfirmCancelPipeline from "@/modules/application-integration/components/ConfirmCancelPipeline.vue";
import ConfirmSaveAndRun, {
	IntegrationPipelineConfirmConfig
} from "@/modules/application-integration/components/ConfirmSaveAndRun.vue";
import { appIntegration, app as appProto, integrationJobAbstract } from "@/protocol/app";
import { CustomTimeStamp } from "@/shared/components";

import IntegrationStatusText from "./IntegrationStatusText.vue";

export default defineComponent({
	name: "IntegrationsPipelineCardFooter",

	components: {
		IntegrationStatusText,
		Container,
		CustomTimeStamp,
		Typography,
		Button,
		PopOver,
		ConfirmSaveAndRun,
		ConfirmCancelPipeline,
		Icon
	},

	props: {
		latestJob: {
			type: Object as PropType<integrationJobAbstract | undefined>
		},

		isSubmitting: {
			type: Boolean,
			required: true
		},

		app: {
			type: Object as PropType<appProto>,
			required: true
		},

		integration: {
			type: Object as PropType<appIntegration>,
			required: true
		},

		submitError: {
			type: String
		}
	},

	emits: ["edit-integration", "confirmed", "cancel"],

	data: () => ({
		isRunPipelinePopoverOpen: false,
		isCancelPipelinePopoverOpen: false
	}),

	computed: {
		statusIcon(): { name: string; state: IconState; loading?: boolean } | null {
			if (!this.latestJob) {
				return null;
			}

			switch (this.latestJob.jobStatus) {
				case "in-progress": {
					return {
						name: "i-loader",
						state: "primary",
						loading: true
					};
				}
				case "done": {
					return {
						name: "i-launch",
						state: "success",
						loading: false
					};
				}
				case "cancelling":
					return { name: "i-loader-danger", state: "primary", loading: true };
				case "destroying":
					return { name: "i-loader-danger", state: "primary", loading: true };
				case "destroyed":
					return { name: "i-destroy", state: "error", loading: false };
				case "cancelled":
					return { name: "i-stop", state: "error", loading: false };
				case "failed":
					return {
						name: "warning",
						state: "warning",
						loading: false
					};
			}

			return null;
		},

		retryBtnTxt() {
			const runCancelText = this.latestJob?.jobStatus === "active" ? "cancel" : "run";
			return this.latestJob?.jobStatus === "failed" ? "retry" : runCancelText;
		}
	},

	methods: {
		editIntegration() {
			this.$emit("edit-integration");
		},

		viewLogs() {
			if (this.latestJob !== undefined) {
				this.$router.push({
					name: "stageViewIntegrationWithJob",
					params: {
						orgId: this.app.orgId,
						projectId: this.app.projId,
						appId: this.app.id,
						integrationId: this.latestJob.integrationId,
						jobId: this.latestJob.id
					}
				});
			}
		},

		cancelPipeline() {
			this.$emit("cancel");
		},

		triggerPipeline(artifactConfig: IntegrationPipelineConfirmConfig) {
			this.$emit("confirmed", artifactConfig);
		}
	}
});
</script>
