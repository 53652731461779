import FloatingVue from "floating-vue";
import { createApp } from "vue";

import { registerAppPipelineRoutes } from "@/modules/application-deployment/app-pipeline-routes";
import { registerIntegrationPipelineRoutes } from "@/modules/application-integration/application-integration-routes";
import App from "@/modules/core/components/App.vue";
import { registerCredentialRoutes } from "@/modules/credentials/credential-routes";
import { registerDebugRoutes } from "@/modules/debug/register-debug-routes";
import { registerEnvListRoutes } from "@/modules/env-list/router/env-list-routes";
import { registerEnvPipelineRoutes } from "@/modules/env-pipeline/router/env-pipeline-routes";
import { registerHomeRoutes } from "@/modules/home/register-home-routes";
import { registerOrgSettingsRoutes } from "@/modules/org/org-settings/org-settings-routes";
import { orgStore } from "@/modules/org/org-store";
import { registerProjectLandingRoutes } from "@/modules/project-landing/project-page-routes";
import routerModule from "@/modules/router/router-module";
import { getStore } from "@/modules/store";
import { registerTerraformRegistryRoutes } from "@/modules/terraform-registry/routes/terraform-registry-routes";
import { userStore } from "@/modules/user/user-store";
import ApiService from "@/services/api-service";

import { registerAuthRoutes } from "./modules/auth/router/auth-routes";
import { ENVIRONMENTS, getEnvironment, initSentry } from "./utils";

// eslint-disable-next-line max-statements
export function startApp() {
	const app = createApp(App);

	app.use(FloatingVue, {
		container: "div.flow-layout",
		boundary: "body",
		themes: {
			tooltip: {
				html: false
			}
		}
	});

	const store = getStore();

	ApiService.setStore(store);

	const { router } = routerModule;

	routerModule.install();

	userStore.INIT_USER_FROM_STORAGE();
	orgStore.INIT_ORG();

	registerAuthRoutes(router);
	registerProjectLandingRoutes(router);
	registerEnvListRoutes(router);
	registerAppPipelineRoutes(router);
	registerEnvPipelineRoutes(router);
	registerIntegrationPipelineRoutes(router);
	registerCredentialRoutes(router);
	registerOrgSettingsRoutes(router);
	registerTerraformRegistryRoutes(router);

	if (getEnvironment() !== ENVIRONMENTS.PRODUCTION) {
		registerDebugRoutes(router);
	}

	if (import.meta.env.MODE !== "development" && import.meta.env.MODE !== "test") {
		initSentry({
			app,
			router
		});
	}

	registerHomeRoutes(router);

	// Make sure all routes are registered before the wildcard redirection for vue-router.
	routerModule.addWildCardRoute();
	router.replace(router.currentRoute.value.fullPath);

	app.use(router);
	app.use(store);
	app.mount(document.body);

	return { store, router };
}
