<template>
	<Wrapper
		max-height="70vh"
		:width="showClose ? '432px' : '520px'"
		border-radius="4px"
		background="element-light"
		:data-qa-can-module-edit="false"
	>
		<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
			<Slab size="medium" type="transparent" data-qa-pipeline-module-details-header>
				<Container :padding="0">
					<Typography
						v-tooltip="{ content: pipeline.name, placement: 'auto' }"
						type="h4"
						color="dark"
						data-qa="cred-delete-modal-title"
						:data-qa-pipeline-module-name="pipeline.name"
						>{{ pipeline.name }}</Typography
					>
				</Container>
				<template #secondary-actions>
					<Icon
						v-if="showClose"
						name="i-close"
						type="filled"
						size="x-small"
						data-qa="pipeline-module-delete-modal-close-btn"
						@click="$emit('close', pipeline.name)"
					/>
					<Button v-else data-qa-open-add-module-form-btn @click="selectModule">Add</Button>
				</template>
			</Slab>
			<Divider class="flex-0" />
			<Container :padding="12" direction="column" overflow="auto" align="left top">
				<Table
					class="width-100-per"
					border="none"
					:row-gap="8"
					:data-qa-pipeline-module-details="pipeline.name"
				>
					<template #body>
						<template v-for="(obj, idx) in getArtifactKeyValuePairs(pipeline.data)" :key="idx">
							<TableRow>
								<TableCell padding="8px" :data-qa-module-details-key="obj.key">
									<Typography color="gray-200">{{ obj.key }}</Typography>
								</TableCell>
								<TableCell padding="8px" :data-qa-module-details-val="obj.value">
									<Typography
										v-if="typeof obj.value === 'string' && !isURL(obj.value)"
										:data-qa-module-detail-is-not-link="obj.value"
										color="dark"
										>{{ obj.value }}</Typography
									>
									<Typography v-else color="dark" :data-qa-module-detail-is-link="obj.value">
										<a class="fc-primary" rel="noopener" :href="obj.value" target="_blank">{{
											obj.value
										}}</a>
									</Typography>
								</TableCell>
							</TableRow>
						</template>
					</template>
				</Table>
			</Container>
		</Container>
	</Wrapper>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Icon,
	Slab,
	Table,
	TableCell,
	TableRow,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { Artifact, ArtifactType, ContainerRevision, GitRevision } from "@/protocol/common";
import { pipelineModule } from "@/protocol/pipeline";

import { ArtifactMeta } from "../application-integration-store";

import { PipelineModuleMeta } from "./AddIntegrationPipelineWrapper.vue";

export default defineComponent({
	name: "ModuleReadOnlyInfo",

	components: {
		Wrapper,
		Container,
		Typography,
		Icon,
		Table,
		TableRow,
		TableCell,
		Button,
		Slab,
		Divider
	},

	props: {
		pipeline: {
			type: Object as PropType<PipelineModuleMeta | ArtifactMeta>,
			required: true
		},

		showClose: {
			type: Boolean,
			default: () => true
		}
	},

	emits: ["close", "add-new-artifact"],

	methods: {
		getArtifactKeyValuePairs(artifactData: Artifact | pipelineModule | null) {
			if (!artifactData) {
				return [];
			}
			if ("type" in artifactData && artifactData.type === ArtifactType.ContainerImage) {
				const containerData = artifactData.containerImage!;
				return (Object.keys(containerData) as Array<keyof ContainerRevision>).map(k => {
					return { key: k, value: containerData[k] === "" ? "-" : containerData[k] };
				});
			} else if ("type" in artifactData && artifactData.type === ArtifactType.GitCode) {
				const gitData = artifactData.gitCode!;
				return (Object.keys(gitData) as Array<keyof GitRevision>).map(k => {
					return { key: k, value: gitData[k] === "" ? "-" : gitData[k] };
				});
			} else if ("provisioner" in artifactData) {
				return (Object.keys(artifactData) as Array<keyof pipelineModule>).map(k => {
					return {
						key: k,
						value: (typeof artifactData[k] === "string" ? artifactData[k] : "-") as string
					};
				});
			}
		},

		isURL(link: string) {
			if (!link) {
				return false;
			}
			return /^https?:/.test(link);
		},

		selectModule() {
			this.$emit("add-new-artifact", this.pipeline);
		}
	}
});
</script>
