<template>
	<AuthWrapper
		header="Verification Email"
		:show-back-icon="true"
		@back-click="redirectMixin({ routeName: 'login' })"
	>
		<p class="fc-normal paragraph-1 margin-bt-24">
			Lost your verification email? Happens to the best of us, enter your email address below to
			resend your verification.
		</p>
		<SendEmailVerificationForm />
	</AuthWrapper>
</template>

<script>
import { defineComponent } from "vue";

import SendEmailVerificationForm from "@/modules/auth/components/SendEmailVerificationForm.vue";
import AuthWrapper from "@/modules/auth/views/AuthWrapper.vue";
import routerMixin from "@/shared/mixins/routerMixin";

export default defineComponent({
	name: "VerificationEmail",

	components: {
		SendEmailVerificationForm,
		AuthWrapper
	},

	mixins: [routerMixin]
});
</script>
