<template>
	<CustomLoader v-if="isLoading" size="x-large" />

	<f-div
		v-else-if="!selectedProvisioner"
		align="middle-center"
		width="fill-container"
		height="100%"
		direction="column"
		padding="x-large"
		gap="large"
	>
		<f-div width="hug-content" height="hug-content" data-qa-pipeline-select-provisioner-title>
			<f-text>Select cloud for pipeline</f-text>
		</f-div>
		<f-div align="top-center" gap="large" height="hug-content">
			<f-pictogram
				data-qa-pipeline-provisioner-aws
				variant="square"
				source="p-aws-dark"
				size="x-large"
				clickable
				tooltip="AWS"
				@click="selectedProvisioner = Provisioner.aws"
			/>
			<f-pictogram
				data-qa-pipeline-provisioner-gcp
				variant="square"
				source="p-gcp"
				size="x-large"
				clickable
				tooltip="GCP"
				@click="selectedProvisioner = Provisioner.gcp"
			/>
			<f-pictogram
				data-qa-pipeline-provisioner-azure
				variant="square"
				source="p-azure"
				size="x-large"
				clickable
				tooltip="Azure"
				@click="selectedProvisioner = Provisioner.azure"
			/>
		</f-div>
	</f-div>

	<Container
		v-else
		:padding="0"
		:gap="0"
		class="height-100-per"
		direction="column"
		align="left top"
		data-qa-add-int-pipeline-wrapper
	>
		<Divider :size="3" state="secondary" class="flex-0" />

		<CreatePipelineHeader
			v-model:pipeline-config="pipelineConfig"
			:name="integrationName"
			:error-message="submitError"
			:is-submitting="isSubmitting"
			:output-artifact="outputArtifact?.data"
			:input-artifact="inputArtifact"
			:integration="integration"
			:provisioner="selectedProvisioner"
			:has-pipeline-modules="hasAnyPipelineModules"
			@vars-saved="setSavedVars"
			@create-integration="createSaveIntegration"
			@clear-err="submitError = ''"
		/>

		<Divider class="flex-0" />

		<Container
			:padding="0"
			:gap="0"
			:grow="1"
			align="left stretch"
			data-qa-add-int-pipeline-wrapper-body
		>
			<PipelineModuleLister :integration="integration">
				<PipelineModulesDragList
					v-model:pipeline-modules="pipelineModules"
					v-model:input-artifact="inputArtifact"
					v-model:output-artifact="outputArtifact"
					:provisioner="selectedProvisioner"
					:integration-id="integration?.id ?? null"
					:app-id="appId"
				>
					<template #trigger-form>
						<GitTriggerForm
							v-if="inputArtifact?.data.type === ArtifactType.GitCode"
							:artifact="inputArtifact.data"
							:integration="integration"
						/>
					</template>
				</PipelineModulesDragList>
			</PipelineModuleLister>

			<Divider direction="vertical" :size="2" :resize-siblings="true" :min-resize="320" />
			<EmptyLogsScreen />
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Divider } from "@cldcvr/flow-vue3";
import { isEqual } from "lodash-es";
import { defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { BreadCrumb, breadcrumbStore } from "@/modules/core/breadcrumb-store";
import { credentialStore } from "@/modules/credentials/credential-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { AddVariableScope } from "@/modules/variables-list/variable-list-types";
import { AppIntegrationCreate } from "@/protocol/app";
import { Artifact, ArtifactSelector, ArtifactType, Provisioner } from "@/protocol/common";
import { CredScope } from "@/protocol/identity";
import { pipelineModule } from "@/protocol/pipeline";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { CredsTypeToProvisioner } from "@/shared/constants";
import { pipelineModuleWithRefId } from "@/shared/pipeline-constants";
import { artitactToArtifactSelector, getErrorMessage, safeEntityString } from "@/utils";

import {
	applicationIntegrationStore,
	ArtifactMeta,
	getArtifactMeta,
	getPipelineModuleMeta
} from "../application-integration-store";

import CreatePipelineHeader from "./CreatePipelineHeader.vue";
import EmptyLogsScreen from "./EmptyLogsScreen.vue";
import GitTriggerForm from "./GitTriggerForm.vue";
import PipelineModuleLister from "./PipelineModuleLister.vue";
import PipelineModulesDragList from "./PipelineModulesDragList.vue";

export default defineComponent({
	name: "AddIntegrationPipelineWrapper",

	components: {
		Container,
		Divider,
		GitTriggerForm,
		CreatePipelineHeader,
		PipelineModulesDragList,
		PipelineModuleLister,
		EmptyLogsScreen,
		CustomLoader
	},

	props: {
		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		appId: {
			type: String,
			required: true
		},

		integrationId: {
			type: String
		}
	},

	data: () => ({
		Provisioner,
		ArtifactType,

		// Vars
		vars: {} as Record<string, string>,
		sensitiveVars: {} as Record<string, string>,

		// Flags
		submitError: "",
		isSubmitting: false,

		isLoading: true,

		selectedProvisioner: null as Provisioner | null,

		// modules & artifacts
		pipelineConfig: {} as Record<string, unknown>,
		pipelineModules: [] as PipelineModuleMeta[],
		inputArtifact: null as ArtifactMeta | null,
		outputArtifact: null as ArtifactMeta | null
	}),

	computed: {
		hasAnyPipelineModules() {
			return this.pipelineModules.length > 0;
		},

		app() {
			const apps = applicationStore.projectApps[this.projectId];
			return apps?.find(app => app.id === this.appId);
		},

		integrationName() {
			if (this.integration) {
				return this.integration.name;
			}

			const integrationNames =
				applicationIntegrationStore.integrations[this.appId]?.map(
					integration => integration.name
				) ?? [];

			return safeEntityString(`${this.app?.name} integration pipeline`, integrationNames);
		},

		integration() {
			return applicationIntegrationStore.integrations[this.appId]?.find(
				integration => integration.id === this.integrationId
			);
		},

		breadcrumbs(): BreadCrumb[] {
			const app = applicationStore.projectApps[this.projectId]?.find(
				app_ => app_.id === this.appId
			);

			const { integration } = this;

			if (!app || !integration) {
				return [];
			}

			return [
				{
					qaId: "projectListWithApp",
					label: app.name,
					route: {
						name: "projectListWithApp",
						props: {
							orgId: this.orgId,
							projectId: this.projectId,
							appId: this.appId
						}
					}
				},
				{
					qaId: "addIntegrationView",
					label: integration.name,
					route: {
						name: "addIntegrationView",
						props: {
							orgId: integration.orgId,
							projectId: integration.projId,
							appId: integration.appId,
							integrationId: integration.id
						}
					}
				}
			];
		},

		appCloudCredential() {
			return credentialStore.entityCredentials[this.appId]?.find(
				cred => cred.credScope?.includes(CredScope.cloud)
			);
		}
	},

	watch: {
		breadcrumbs: {
			handler() {
				breadcrumbStore.SET_ADDITIONAL_BREADCRUMBS(this.breadcrumbs);
			},

			deep: true,
			immediate: true
		},

		appCloudCredential: {
			handler() {
				if (this.appCloudCredential?.type && !this.selectedProvisioner) {
					this.selectedProvisioner = CredsTypeToProvisioner[this.appCloudCredential.type];
				}
			},

			immediate: true
		},

		app: {
			handler() {
				if (this.app) {
					applicationIntegrationStore.SET_CURR_INTEGRATION_APPID(this.appId);
				}
			},

			immediate: true
		},

		integration: {
			handler() {
				this.updateStateFromIntegration();
			}
		}
	},

	mounted() {
		(async () => {
			this.isLoading = true;

			await Promise.allSettled([
				applicationIntegrationStore.FETCH_INTEGRATIONS({
					appId: this.appId,
					orgId: this.orgId,
					projId: this.projectId
				}),
				applicationIntegrationStore.FETCH_INTEGRATION_MODULES({
					orgId: this.orgId,
					keywords: []
				})
			]);

			this.updateStateFromIntegration();

			this.isLoading = false;
		})();

		credentialStore.GET_CREDS_FOR_ENTITY({
			orgId: this.orgId,
			app: {
				id: this.appId,
				orgId: this.orgId,
				projId: this.projectId
			}
		});
	},

	methods: {
		updateStateFromIntegration() {
			if (!this.integration) {
				return;
			}

			// Seed initial values if we are editing an integration
			this.inputArtifact = getArtifactMeta(this.integration.finalInputArtifact);
			this.outputArtifact = getArtifactMeta(this.integration.finalOutputArtifact);
			this.pipelineModules = this.getPipelineModules();
			this.pipelineConfig = { ...(this.integration.config?.pipelineConfig ?? {}) };

			if (this.integration.config?.buildPlatform) {
				this.selectedProvisioner = this.integration.config.buildPlatform;
			}
		},

		getPipelineModules(): PipelineModuleMeta[] {
			if (!this.integration?.pipeline) {
				return [];
			}

			return getPipelineModuleMeta(this.integration.pipeline).map((module, idx) => {
				const moduleRefId = `${module.provisioner}:${module.name}@${module.version}`;

				return {
					order: idx,
					icon:
						module.provisioner === "gcp" || module.provisioner === "azure"
							? `p-${module.provisioner}`
							: "p-aws-dark",
					moduleValues: this.getExistingInputs(
						{
							moduleRefId,
							...module,
							isAdded: true
						},
						idx
					)?.inputs,
					name: module.displayName!,
					desc: module.description!,
					data: module
				};
			});
		},

		getExistingInputs(module: pipelineModuleWithRefId, order: number) {
			if (this.integration) {
				return this.integration.pipeline?.find(
					(m, i) => m.moduleRefId === module.moduleRefId && i === order
				);
			}
			return null;
		},

		async createSaveIntegration({
			shouldDeploy,
			name,
			inputAppArtifact,
			outputAppArtifact
		}: {
			shouldDeploy: boolean;
			name: string;
			inputAppArtifact: ArtifactSelector;
			outputAppArtifact?: ArtifactSelector;
		}) {
			try {
				this.isSubmitting = true;
				if (shouldDeploy && this.integration) {
					/**
					 * Action - deploy, integration already exists so update the integration with latest values &
					 * trigger the deployment
					 */
					await this.updateAppIntegration(name);
					await this.startIntegrationjob(inputAppArtifact, outputAppArtifact);
				} else if (shouldDeploy && !this.integration) {
					/**
					 * Action - deploy, integration does not exist so create one first
					 * and then trigger the deployment
					 */
					await this.createAppIntegration(name, shouldDeploy, inputAppArtifact, outputAppArtifact);
					await this.startIntegrationjob(inputAppArtifact, outputAppArtifact);
				} else if (!shouldDeploy && this.integration) {
					/**
					 * Action - save, just update the existing integration
					 */
					await this.updateAppIntegration(name);
					notificationsStore.ADD_TOAST({
						qaId: "toast-integration-save-success",
						title: "Integration updated successfully",
						text: `The integration pipeline ${this.integration.name} is updated.`,
						status: "success"
					});
				} else {
					/**
					 * Action - create, create new integration
					 */
					await this.createAppIntegration(name, shouldDeploy, inputAppArtifact, outputAppArtifact);

					notificationsStore.ADD_TOAST({
						qaId: "toast-integration-create-success",
						title: "Integration created successfully!",
						text: `The integration pipeline is created and saved`,
						status: "success",
						actions: [
							{
								actionType: "default",
								onAction: () => this.routeToApp(),
								text: "Go back to app",
								qaId: "data-qa-int-created-go-back-to-app-btn"
							}
						]
					});
				}
				this.isSubmitting = false;
			} catch (error) {
				this.submitError = getErrorMessage(error);
				notificationsStore.ADD_TOAST({
					qaId: "toast-integration-save-create-failed",
					title: "Something went wrong with the integration",
					text: this.submitError,
					status: "error"
				});
				this.isSubmitting = false;
			}
		},

		async updateAppIntegration(name: string) {
			if (this.integration) {
				const reqData = this.generateAppIntegrationData(name);
				await applicationIntegrationStore.UPDATE_APP_INTEGRATION({
					...reqData,
					id: this.integration.id
				});
			}
		},

		async startIntegrationjob(
			inputAppArtifact: ArtifactSelector,
			outputAppArtifact?: ArtifactSelector
		) {
			if (this.integration) {
				await applicationIntegrationStore.START_INTEGRATION_JOB({
					inputAppArtifact,
					outputAppArtifact,
					integrationId: this.integration.id,
					orgId: this.integration.orgId,
					projId: this.integration.projId,
					appId: this.integration.appId
				});
				this.$router.push({
					name: "stageViewIntegration",
					params: {
						orgId: this.integration.orgId,
						projId: this.integration.projId,
						appId: this.integration.appId,
						integrationId: this.integration.id
					}
				});
				notificationsStore.ADD_TOAST({
					qaId: "toast-integration-deploy-success",
					title: "Integration pipeline triggered successfully",
					text: `${this.integration.name} is triggered`,
					status: "success"
				});
			}
		},

		generateAppIntegrationData(name: string): AppIntegrationCreate {
			// Generate pipelineModules array with ids and values
			const pipelineModules = this.pipelineModules.map(module => ({
				moduleRefId: `${module.data.provisioner}:${module.data.name}@${module.data.version}`,
				inputs: module.moduleValues
			}));

			const variables = Object.keys(this.vars).reduce((acc: Record<string, string>, varKey) => {
				const trimmedKey = varKey.trim();
				const trimmedValue = this.vars[varKey]?.trim() ?? "";
				acc[trimmedKey] = trimmedValue;
				return acc;
			}, {});

			const sensitiveVariables = Object.keys(this.sensitiveVars).reduce(
				(acc: Record<string, string>, varKey) => {
					const trimmedKey = varKey.trim();
					const trimmedValue = this.sensitiveVars[varKey]?.trim() ?? "";
					acc[trimmedKey] = trimmedValue;
					return acc;
				},
				{}
			);

			const inputArtifact = this.inputArtifact?.data;
			const outputArtifact = this.outputArtifact?.data;

			return {
				appId: this.appId,
				name,
				orgId: this.orgId,
				projId: this.projectId,
				inputAppArtifact: getArtifactSelector(
					inputArtifact,
					this.app?.artifacts?.find(artifact => artifact.id === inputArtifact?.id)
				),

				outputAppArtifact: getArtifactSelector(
					outputArtifact,
					this.app?.artifacts?.find(artifact => artifact.id === outputArtifact?.id)
				),

				config: {
					vars: variables,
					sensitive: sensitiveVariables,
					pipelineConfig: this.pipelineConfig
				},

				pipeline: pipelineModules
			};
		},

		async createAppIntegration(
			name: string,
			shouldDeploy: boolean,
			inputAppArtifact: ArtifactSelector,
			outputAppArtifact?: ArtifactSelector
		) {
			const integration = await applicationIntegrationStore.CREATE_APP_INTEGRATION(
				this.generateAppIntegrationData(name)
			);

			if (this.app && !shouldDeploy) {
				this.$router.push({
					name: "addIntegrationView",
					params: {
						orgId: this.orgId,
						projId: this.projectId,
						appId: this.appId,
						integrationId: integration.id
					}
				});
			} else if (shouldDeploy) {
				await applicationIntegrationStore.START_INTEGRATION_JOB({
					inputAppArtifact,
					outputAppArtifact,
					integrationId: integration.id,
					orgId: integration.orgId,
					projId: integration.projId,
					appId: integration.appId
				});
				this.$router.push({
					name: "stageViewIntegration",
					params: {
						orgId: integration.orgId,
						projId: integration.projId,
						appId: integration.appId,
						integrationId: integration.id
					}
				});
			}
		},

		setSavedVars(scope: AddVariableScope) {
			scope.variables.forEach(variablesObj => {
				const { key, isMasked, value } = variablesObj;
				if (key && value) {
					if (isMasked) {
						this.sensitiveVars[key] = value;
					} else {
						this.vars[key] = value;
					}
				}
			});
		},

		routeToApp() {
			this.$router.push({
				name: "projectListWithApp",
				params: {
					orgId: this.orgId,
					projectId: this.projectId,
					appId: this.appId
				}
			});
		}
	}
});

function getArtifactSelector(artifact?: Artifact, originalArtifact?: Artifact) {
	if (!artifact) {
		return undefined;
	}

	// Server has a special logic that if the user adds an artifact unmodified
	// we can link it to the original with just an ID, so changes to original artifact
	// will reflect in future pipelines
	if (!originalArtifact || isEqual(artifact, originalArtifact)) {
		return {
			id: artifact.id
		};
	}

	return artitactToArtifactSelector(artifact);
}

export type PipelineModuleMeta = {
	icon: string;
	name: string;
	desc: string;
	data: pipelineModule;
	moduleValues?: Record<string, any>;
	order?: number;
};
</script>
