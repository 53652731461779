<template>
	<f-form-builder
		data-qa-add-variable-form
		:field.prop="formFields"
		:values.prop="scopeFormValues"
		@input="handleScopeInputValue"
		@state-change="formState = $event.detail"
	></f-form-builder>
</template>

<script lang="ts">
import { FPictogramState, FPictogramVariant, FSelectOptionObject } from "@cldcvr/flow-core";
import { FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { PropType, defineComponent } from "vue";

import { variableType } from "@/protocol/common";
import { getShortCode } from "@/utils";

import { VariableEditInfo, VariableSource } from "./variable-list-types";
import { variablesListStore } from "./variables-list-store";

export default defineComponent({
	name: "AddVariableScopeForm",

	props: {
		variableScopes: {
			type: Array as PropType<SelectedVariableScopes[]>,
			required: true
		},

		selectedVariableType: {
			type: String as PropType<variableType>
		}
	},

	emits: ["update:variableScopes"],

	data() {
		return {
			formState: null as FormBuilderState | null,
			scopeFormValues: [] as VariableFormInternalValue
		};
	},

	computed: {
		scopeOptions(): FormVariableScope[] {
			let groupNameChanged = true;
			let previousGroupName = "";

			return (
				variablesListStore.variableScopes
					// Filter out selected scopes
					.filter(scope => {
						return !this.scopeFormValues.some(formScope => formScope.data.scope.id === scope.id);
					})
					.sort((scopeA, scopeB) => {
						if (scopeB.variableFrom === "project") {
							return 1;
						}
						if (scopeB.variableFrom === "environment") {
							return 1;
						}

						return scopeA.groupName.localeCompare(scopeB.groupName);
					})
					.map(scope => {
						const scopeOption = this.getFormScopeFromVariableScope({
							id: scope.id,
							name: scope.name,
							variableFrom: scope.variableFrom
						});

						groupNameChanged = previousGroupName !== scope.groupName;
						previousGroupName = scope.groupName;

						if (groupNameChanged) {
							scopeOption.data.header = scope.groupName;
						}

						if (
							this.selectedVariableType === variableType.tf_var &&
							(scope.variableFrom === "application-deployment" ||
								scope.variableFrom === "application-integration")
						) {
							scopeOption.disabled = true;
						}

						return scopeOption;
					})
			);
		},

		formFields(): FormBuilderField {
			return {
				type: "select",
				selection: "multiple",
				qaId: "scope",
				label: { title: "Scope" },
				validationRules: [{ name: "required" }],
				//@ts-expect-error
				optionTemplate: (option: FormVariableScope) =>
					html`<f-div direction="column" gap="x-small" overflow="hidden">
						${option.data.header
							? html`<f-text ellipsis state="secondary" size="small">
									${option.data.header}
							  </f-text>`
							: ""}
						<f-div align="middle-left" direction="row" gap="small">
							<f-pictogram
								size="small"
								source="${option.data.shortCode}"
								variant="${option.data.shape}"
								state="${option.data.pictogramState}"
							></f-pictogram>
							<f-text ellipsis state="default">${option.title}</f-text>
						</f-div>
					</f-div>`,

				options: this.scopeOptions,
				placeholder: "Select scope"
			};
		}
	},

	watch: {
		variableScopes: {
			immediate: true,

			handler() {
				const { variableScopes } = this;

				this.scopeFormValues = variableScopes.map(this.getFormScopeFromVariableScope);
			}
		}
	},

	methods: {
		getFormScopeFromVariableScope(scope: SelectedVariableScopes): FormVariableScope {
			const variableScope = variablesListStore.variableScopes.find(
				scope_ => scope_.id === scope.id
			);

			return {
				data: {
					scope,
					shortCode: getShortCode(scope.name),
					shape: getPictogramVariant(variableScope?.editInfo),
					pictogramState: getPictogramState(variableScope?.editInfo),
					removeScope: this.removeScope
				},

				title: scope.name
			};
		},

		handleScopeInputValue(event: CustomEvent<VariableFormInternalValue>) {
			this.$emit(
				"update:variableScopes",
				event.detail.map(formValue => formValue.data.scope) satisfies SelectedVariableScopes[]
			);
		},

		removeScope(scopeToRemove: FormVariableScope) {
			this.$emit(
				"update:variableScopes",
				this.scopeFormValues
					.filter(scope => scope.data.id !== scopeToRemove.data.id)
					.map(formValue => formValue.data.scope)
			);
		}
	}
});

export type VariableFormInternalValue = FormVariableScope[];

export type FormVariableScope = FSelectOptionObject & {
	data: {
		scope: SelectedVariableScopes;
		shape: FPictogramVariant;
		shortCode: string;
		pictogramState: FPictogramState;
		removeScope(scopeToRemove: FormVariableScope): void;
		header?: string;
	};
};

export type SelectedVariableScopes = {
	id: string;
	name: string;
	variableFrom: VariableSource;
};

function getPictogramVariant(editInfo?: VariableEditInfo): FPictogramVariant {
	if (editInfo?.type === "application-deployment") {
		return "squircle";
	}

	if (editInfo?.type === "environment") {
		return "hexagon";
	}

	return "circle";
}

function getPictogramState(editInfo?: VariableEditInfo): FPictogramState {
	if (editInfo?.type === "application-deployment") {
		return "primary";
	}

	if (editInfo?.type === "environment") {
		return "success";
	}

	return "warning";
}
</script>
