import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
	AppDeployment,
	AppDeploymentId,
	DeploymentPromoteRequest,
	promotedVersion
} from "@/protocol/deployment";
import { environment, promotionSequenceNode } from "@/protocol/infra";

import { applicationDeploymentStore } from "../application-deployment/application-deployment-store";
import { getStore } from "../store";

import {
	getPromotableVersions,
	listPromotionSequence,
	promoteDeployment
} from "./code-promotion-service";

@Module({
	namespaced: true,
	dynamic: true,
	name: "codePromotion",
	store: getStore()
})
class CodePromotionStore extends VuexModule {
	promotionSequences: Record<string, promotionSequenceNode[]> = {};
	currentAppDeployment: AppDeployment | null = null;
	promotableVersions: Record<string, promotedVersion[]> = {};

	get projPromotionSequence() {
		return (projId: string) => {
			return this.promotionSequences[projId] ?? [];
		};
	}

	get projPromoEnvs() {
		return (projId: string) => {
			let [firstNode] = this.promotionSequences[projId] ?? [];
			if (firstNode?.this !== undefined) {
				const arr = [firstNode.this as environment];
				while (firstNode?.next && firstNode.next.length > 0) {
					[firstNode] = firstNode.next;
					if (firstNode?.this !== undefined) {
						arr.push(firstNode.this);
					}
				}
				return arr;
			}
			return [];
		};
	}

	@Mutation
	RESET_CODE_PROMOTION() {
		this.promotionSequences = {};
		this.currentAppDeployment = null;
		this.promotableVersions = {};
	}

	@Mutation
	SET_PROMOTION_SEQUENCE_PROJ(payload: {
		projId: string;
		promotionSequence: promotionSequenceNode[];
	}) {
		this.promotionSequences[payload.projId] = payload.promotionSequence;
	}

	@Mutation
	SET_CURRENT_APP_DEP_CODE_PROMOTION(payload: AppDeployment) {
		this.currentAppDeployment = payload;
	}

	@Action
	async GET_PROJ_PROMOTION_SEQUENCE(payload: { projId: string; orgId: string }) {
		const sequence = await listPromotionSequence(payload);
		if (sequence.items) {
			this.SET_PROMOTION_SEQUENCE_PROJ({
				projId: payload.projId,
				promotionSequence: sequence.items
			});
		}
	}

	@Action
	async PROMOTE_APP(payload: DeploymentPromoteRequest) {
		const job = await promoteDeployment(payload);

		await applicationDeploymentStore.LIST_ENV_APP_DEPLOYMENTS({
			envId: job.envId,
			orgId: job.orgId,
			projectId: job.projId
		});
	}

	@Action
	async GET_PROMOTABLE_VERSIONS(payload: AppDeploymentId & { appId: string }) {
		const promoVersions = await getPromotableVersions(payload);
		if (promoVersions.items) {
			this.promotableVersions[payload.id] = promoVersions.items;
		}
	}
}

const codePromotionStore = getModule(CodePromotionStore);

export { codePromotionStore };
