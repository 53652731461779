<template>
	<Wrapper
		border-radius="4px"
		background="gray-500"
		width="432px"
		max-height="80vh"
		overflow="visible"
		data-qa-webhook-delete-modal
	>
		<Header>
			<Typography type="h4" color="dark">Confirm delete</Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Icon
					v-tooltip="'Close'"
					size="x-small"
					type="filled"
					name="i-close"
					data-qa-close
					@click="$emit('close')"
				/>
			</Container>
		</Header>
		<Container
			padding="0 16px 16px 16px"
			:gap="16"
			align="left top"
			direction="column"
			overflow="visible"
		>
			<Typography type="p1-para" color="dark" data-qa-delete-webhook-confirm-message
				>Are you sure you want to delete this webhook?
			</Typography>
		</Container>

		<Footer>
			<Container direction="column" padding="0" :grow="1">
				<Container v-if="errorMessage">
					<Typography type="p2" color="error" data-qa-delete-webhook-error>{{
						errorMessage
					}}</Typography>
				</Container>
				<Button
					state="full"
					type="error"
					:loading="isDeleting"
					data-qa-delete-webhook
					@click="deleteWebHook"
					>DELETE</Button
				>
			</Container>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import { webhook } from "@/protocol/externalEvents";
import { captureError, getErrorMessage } from "@/utils";

export default defineComponent({
	name: "EnvWebHookDeleteModal",

	components: {
		Header,
		Typography,
		Container,
		Icon,
		Footer,
		Button,
		Wrapper
	},

	props: {
		webHook: {
			type: Object as PropType<webhook>,
			required: true
		}
	},

	emits: ["close"],

	data() {
		return {
			isDeleting: false,
			errorMessage: null as string | null
		};
	},

	methods: {
		async deleteWebHook() {
			this.isDeleting = true;
			this.errorMessage = null;

			try {
				await webHooksStore.DELETE_WEB_HOOK({
					orgId: this.webHook.orgId!,
					projectId: this.webHook.projectId!,
					webhookId: this.webHook.id!,
					entityId: this.webHook.entityId!
				});

				this.$emit("close");
			} catch (err) {
				captureError(err);
				this.errorMessage = getErrorMessage(err, true);
			} finally {
				this.isDeleting = false;
			}
		}
	}
});
</script>
