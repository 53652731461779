import { ApplicationId, EnvironmentId, ProjectId } from "@/protocol/common";
import { Creds, CredScope } from "@/protocol/identity";

export interface ExtraInfo {
	accountId: string;
	region: string;
}

export type CredWithAssignedEntities = Creds & { stats: ICredStats };

export type assignedProjectId = ProjectId & { credScope: CredScope[] };
export type assignedEnvironmentId = EnvironmentId & { credScope: CredScope[] };
export type assignedApplicationId = ApplicationId & { credScope: CredScope[] };
export type EntityCredScopeMap = Record<string, CredScope[]>; // define assigned credScope on a particular projectId, envId, or appId.

export interface ICredStats {
	assignedToCount: number;
	projects: assignedProjectId[];
	environments: assignedEnvironmentId[];
	apps: assignedApplicationId[];
	credScopeMap: EntityCredScopeMap;
}

export const defaultCredStats: ICredStats = Object.freeze({
	assignedToCount: 0,
	projects: [],
	environments: [],
	apps: [],
	credScopeMap: {}
});

export interface EnvProjectIdMap {
	[key: string]: {
		projId: string;
		orgId: string;
	};
}

export type CredentialGroupGroupScope = Record<CredScope, CredentialGroup>;

export interface CredentialGroup {
	title: string;
	scope: CredScope;
	list: CredWithAssignedEntities[];
	show: boolean;
	columnConfig: {
		showLastUpdated?: boolean;
		showAssignedTo?: boolean;
		showHost?: boolean;
		showClassification?: boolean;
	};
}
