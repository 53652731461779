import { Artifact } from "@/protocol/common";

export function getArtifactIdentifier(artifact: Artifact): string {
	if (artifact.gitCode) {
		return artifact.gitCode.repo;
	}
	if (artifact.containerImage) {
		return `${artifact.containerImage.repo}:${artifact.containerImage.reference}`;
	}
	if (artifact.bucketObject) {
		return artifact.bucketObject.bucketName;
	}

	return "";
}

export function getArtifactSimpleIdentifier(artifact: Artifact): string {
	if (artifact.gitCode) {
		return artifact.gitCode.repo;
	}
	if (artifact.containerImage) {
		return artifact.containerImage.repo;
	}
	if (artifact.bucketObject) {
		return artifact.bucketObject.bucketName;
	}

	return "";
}
