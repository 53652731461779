<template>
	<Container
		overflow="visible"
		align="center top"
		direction="column"
		:grow="1"
		:padding="16"
		data-qa-deployment-form-container-step
	>
		<f-form-builder
			data-qa-deployment-modal-container-step-form
			:field.prop="formFields"
			:values.prop="formValues"
			@input="handleInput"
			@state-change="formState = $event.detail"
		/>

		<DeploymentArtifactSelector
			:app-id="app.id"
			:deployment="appDeployment"
			:selected-artifact-id="selectedArtifactId"
			@artifact-info="handleArtifactInfo"
		/>

		<Divider />

		<DeploymentEntityVariables
			v-model:variable-info="variableInfo"
			:app-deployment="appDeployment"
			:prefill-values="prefillOutputList ?? undefined"
			:env="currentEnv"
		/>
	</Container>
</template>

<script lang="ts">
import { FSelectOptions } from "@cldcvr/flow-core";
import { FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { Container, Divider } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import DeploymentArtifactSelector, {
	ArtifactSelectorInfo
} from "@/modules/application/components/DeploymentArtifactSelector.vue";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { app } from "@/protocol/app";
import { ArtifactSelector, ArtifactType } from "@/protocol/common";
import { AppDeployment, DeploymentJobAction } from "@/protocol/deployment";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import { JOB_STATUS } from "@/shared/pipeline-constants";
import { getEmoji, safeEntityString } from "@/utils";

import DeploymentEntityVariables, { VariableInfo } from "./DeploymentEntityVariables.vue";

export default defineComponent({
	name: "ApplicationDeploymentModalArtifactStep",

	components: {
		Container,
		Divider,
		DeploymentEntityVariables,
		DeploymentArtifactSelector
	},

	inject: ["updateLayout"],

	props: {
		app: {
			type: Object as PropType<app>,
			required: true
		},

		appDeployment: {
			type: Object as PropType<AppDeployment>
		},

		artifactInfo: {
			type: Object as PropType<ArtifactInfo>,
			required: true
		},

		showEnvSelector: {
			type: Boolean,
			required: true
		},

		selectedArtifactId: {
			type: String
		}
	},

	emits: ["update:artifactInfo"],

	data() {
		return {
			formValues: {} as LocalFormValues,

			artifactSelectorInfo: {
				isValid: false
			} as ArtifactSelectorInfo,

			formState: null as FormBuilderState | null,

			prefillOutputList: null as string[] | null,

			variableInfo: {
				variables: [...this.artifactInfo.variables],
				isValid: false
			} as VariableInfo
		};
	},

	computed: {
		/**
		 * Commented as we might need this in the future if we decide to remove deployment feature
		 * & always promote an application
		 */
		// isEnvInPromotionSeq() {
		// 	if (!this.formValues.environment) {
		// 		return false;
		// 	}

		// 	if (codePromotionStore.projPromoEnvs(this.app.projId).length === 0) {
		// 		return false;
		// 	}

		// 	return codePromotionStore
		// 		.projPromoEnvs(this.app.projId)
		// 		.find(env => env.id === this.formValues.environment?.id);
		// },

		existingDeployedAppNames() {
			if (!this.selectedEnvironmentId) {
				return [];
			}

			let deployedApps =
				applicationDeploymentStore.deploymentsInEnv[this.selectedEnvironmentId] ?? [];

			if (this.appDeployment) {
				deployedApps = deployedApps.filter(deployment => deployment.id !== this.appDeployment?.id);
			}

			return deployedApps.map(deployedApp => deployedApp.name ?? "");
		},

		currentEnv() {
			return envListStore.envs[this.app.projId]?.filter(
				tempEnv => tempEnv.id === this.selectedEnvironmentId
			)[0];
		},

		isAppDeployed() {
			const jobType = this.appDeployment?.lastSuccessfulJob?.jobType;
			const jobStatus = this.appDeployment?.lastSuccessfulJob?.jobStatus;
			return (
				jobType === DeploymentJobAction.deploy &&
				(jobStatus === JOB_STATUS.DONE ||
					jobStatus === JOB_STATUS.ACTIVE ||
					jobStatus === JOB_STATUS.CANCELLING ||
					jobStatus === JOB_STATUS.WAITING ||
					jobStatus === JOB_STATUS.PENDING)
			);
		},

		defaultDeploymentName() {
			return safeEntityString(
				`${this.app.name} ${this.currentEnv?.name ? this.currentEnv.name : ""}`,
				this.existingDeployedAppNames
			);
		},

		environmentOptions(): FSelectOptions {
			return (
				envListStore.envs[this.app.projId]?.map(env => {
					const emoji = getEmoji(env);
					return {
						title: `${emoji ? `${emoji} ` : ""}${env.name}`,
						data: { id: env.id },
						qaId: env.id
					};
				}) ?? []
			);
		},

		selectedEnvironmentId() {
			return this.artifactInfo.environmentId ?? this.formValues.environment?.data.id;
		},

		formFields(): FormBuilderField {
			const { showEnvSelector } = this;

			const formFields: FormBuilderField = {
				type: "object",
				direction: "vertical",
				fields: {
					environment: {
						type: "select",
						id: "environment",
						label: { title: "Select environment" },
						options: this.environmentOptions,
						validationRules: [{ name: "required", message: "This cannot be blank." }],
						showWhen() {
							return showEnvSelector;
						}
					},

					deploymentName: {
						type: "text",
						suffixWhen: value => {
							return this.defaultDeploymentName === value;
						},

						suffix: "suggested",
						label: { title: "App deployment name" },
						id: "app-deployment-entity-name",
						disabled: this.isAppDeployed,
						validationRules: applyEntityNameRules2(
							this.existingDeployedAppNames,
							"This app deployment name already exists."
						),

						helperText: this.isAppDeployed
							? html`<f-text
									size="small"
									state="danger"
									data-qa-error-for="deploymentName"
									data-qa-login-error
									>App deployments can’t be renamed if they are active</f-text
							  >`
							: ""
					},

					__separator__: {
						type: "separator"
					}
				}
			};

			return formFields;
		}
	},

	watch: {
		app: {
			immediate: true,

			handler() {
				// This is needed to get existing deployment names
				applicationDeploymentStore.GET_APP_DEPLOYMENTS({
					id: this.app.id,
					orgId: this.app.orgId,
					projId: this.app.projId
				});
			}
		},

		// If the default deployment name changes and is unedited by the user, then update it
		defaultDeploymentName: {
			immediate: true,

			handler(current: string, prev: string) {
				// If the user is still using the suggested name, then update it with the new one
				if (this.formValues.deploymentName === prev) {
					this.formValues.deploymentName = current;
				}
			}
		},

		variableInfo: {
			deep: true,

			handler() {
				this.emitArtifactInfo();
			}
		},

		// Update the popover layout when the form changes significantly
		"formState.isValid": {
			immediate: true,

			handler() {
				this.emitArtifactInfo();

				this.$nextTick(() => {
					if (this.updateLayout) {
						//@ts-expect-error Vue 2.x has no way to type injects
						this.updateLayout();
					}
				});
			}
		},

		// If the environment is updated we check if the outputs are present/changed and update
		// the prefill output list
		"currentEnv.id": {
			immediate: true,

			async handler() {
				const { currentEnv } = this;

				if (!currentEnv) {
					this.prefillOutputList = null;
					return;
				}

				await envListStore.GET_ENV_OUTPUT_SUMMARY({
					orgId: currentEnv.orgId,
					projectId: currentEnv.projId,
					envId: currentEnv.id
				});

				const envOutput = envListStore.envOutputSummary[currentEnv.id];

				if (!envOutput?.outputs) {
					this.prefillOutputList = null;
					return;
				}

				this.prefillOutputList = Object.values(envOutput.outputs).map(val => String(val.value));
			}
		}
	},

	mounted() {
		this.formValues = {
			...this.formValues,
			deploymentName: this.appDeployment?.name ?? this.defaultDeploymentName
		};
	},

	methods: {
		handleArtifactInfo(artifactInfo: ArtifactSelectorInfo) {
			this.artifactSelectorInfo = artifactInfo;
			this.emitArtifactInfo();
		},

		handleInput(event: CustomEvent<LocalFormValues>) {
			this.formValues = {
				...event.detail
			};

			this.emitArtifactInfo();
		},

		emitArtifactInfo() {
			const { formValues } = this;

			const updatedInfo: ArtifactInfo = {
				...this.variableInfo,
				environmentId: formValues.environment?.data.id ?? this.artifactInfo.environmentId,
				deploymentName: formValues.deploymentName!,
				artifactType: this.artifactSelectorInfo.artifactType,
				artifactSelector: this.artifactSelectorInfo.artifactorSelector,
				isValid: Boolean(this.formState?.isValid) && this.artifactSelectorInfo.isValid
			};

			this.$emit("update:artifactInfo", updatedInfo);
		}
	}
});

type LocalFormValues = {
	environment?: { header: string; data: { id: string } };
	deploymentName?: string | null;
};

export type ArtifactInfo = VariableInfo & {
	environmentId?: string;
	deploymentName?: string;
	// artifactIdentifier?: string;
	artifactType?: ArtifactType;
	artifactSelector?: ArtifactSelector;
	// artifactId?: string;
	isValid: boolean;
};
</script>
