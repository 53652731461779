<template>
	<Wrapper width="280px" height="86px">
		<Card type="placeholder" class="cursor-pointer">
			<Container align="center" class="height-100-per">
				<Typography v-if="isEnvDeployed" type="p2" color="gray-300">
					<Typography
						type="p2"
						color="gray-100"
						weight="bold"
						:data-qa-cp-app-not-deployed-name="app.name"
						inline
						>{{ app.name }}</Typography
					>
					is not deployed yet
				</Typography>
				<Typography v-else type="p2" color="gray-300" @click="routeToEnvDetailedView">
					Deploy
					<Typography
						type="p2"
						color="primary"
						weight="bold"
						:data-qa-cp-env-not-deployed-name="dep.env.name"
						inline
					>
						{{ dep.env.name }}
					</Typography>
					to enable promotion</Typography
				>
			</Container>
			<Container v-if="dep.env.isDeployed" align="center">
				<PopOver
					:open="openPopover"
					data-qa-app-not-deployed-overlay-close-popover
					@overlay-click="closeConfirmPopover"
				>
					<Typography
						type="p2"
						color="primary"
						weight="bold"
						inline
						:data-qa-app-not-deployed-app-name="app.name"
						@click="openPopover = true"
						>DEPLOY APP {{ app.name }}</Typography
					>
					<template #content>
						<PromoteAppConfirm
							:versions="versions"
							:deployment="dep"
							:app="app"
							:promote-from-env="promoteFromEnv"
							:promote-to-env="promoteToEnv"
							:is-submitting="isSubmitting"
							:submit-error="submitError"
							@close="closeConfirmPopover"
							@confirm="triggerAppPromotion"
						/>
					</template>
				</PopOver>
			</Container>
		</Card>
	</Wrapper>
</template>
<script lang="ts">
import { Wrapper, Card, Container, Typography, PopOver } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import PromoteAppConfirm from "@/modules/code-promotion/components/PromoteAppConfirm.vue";
import { app as AppProto } from "@/protocol/app";
import { promotedVersion } from "@/protocol/deployment";

import { EnvDepMapping } from "../views/CodePromotionWrapper.vue";

export default defineComponent({
	name: "CodePromoAppNotDeployed",

	components: {
		PromoteAppConfirm,
		Wrapper,
		Card,
		Container,
		Typography,
		PopOver
	},

	props: {
		dep: {
			type: Object as PropType<EnvDepMapping>,
			required: true
		},

		app: {
			type: Object as PropType<AppProto>,
			required: true
		},

		promoteFromEnv: {
			type: String,
			required: true
		},

		promoteToEnv: {
			type: String,
			required: true
		},

		versions: {
			type: Array as PropType<promotedVersion[]>,
			required: true
		},

		isSubmitting: {
			type: Boolean
		},

		submitError: {
			type: String
		}
	},

	data: () => ({
		openPopover: false
	}),

	computed: {
		isEnvDeployed() {
			return this.dep.env.isDeployed;
		}
	},

	methods: {
		closeConfirmPopover() {
			this.openPopover = false;
			this.$emit("close");
		},

		triggerAppPromotion({ dep, version }: { dep: EnvDepMapping; version: string }) {
			this.$emit("confirm", { dep, version });
		},

		routeToEnvDetailedView() {
			this.$router.push({
				name: "envDetail",
				params: {
					orgId: this.dep.env.orgId,
					projectId: this.dep.env.projId,
					envId: this.dep.env.id
				}
			});
		}
	}
});
</script>
