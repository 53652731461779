<template>
	<div class="code-promo-table flow-add-scrollbar bar-height-6">
		<slot />
	</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "CPTable"
});
</script>
<style lang="scss">
$trigger-width: 130px;
$row-space: 50px;
div.code-promo-table {
	width: 100%;
	overflow: auto;
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;

	div.code-promo-row {
		display: flex;
		flex-wrap: nowrap;
		width: fit-content;

		div.code-promo-column {
			padding-bottom: $row-space;
			&.code-promo-trigger {
				min-width: $trigger-width;
				width: $trigger-width;
				vertical-align: top;
				div.trigger {
					display: flex;
					height: 86px;
					align-items: center;
					justify-content: center;
					position: relative;
					width: 100%;
					&:before {
						content: "";
						position: absolute;
						left: 0px;
						right: 0px;
						top: 42px;
						border-top: 2px dashed var(--dashboard-border-light);
						z-index: 0;
					}
				}
			}
			&.code-promo-app {
				width: $trigger-width;
				min-width: $trigger-width;
				vertical-align: top;
				background-color: var(--dashboard-background);
				position: sticky;
				left: 0px;
				z-index: 2;
				// z-index: 13;
				div.app {
					display: flex;
					height: 106px;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					position: relative;
					> .flow-text {
						margin-top: 8px;
					}

					&:before {
						content: "";
						position: absolute;
						left: 50%;
						right: 0px;
						top: 42px;
						border-top: 2px dashed var(--dashboard-border-light);
						z-index: 0;
					}
				}
			}
		}

		&.footer {
			position: sticky;
			// z-index: 14;
			bottom: 0px;
			height: 52px;
			flex-shrink: 0;
			margin-top: auto;
			box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.2);
			min-width: 100%;
			background-color: var(--dashboard-background);
			div.trigger {
				height: 42px !important;
				&:before {
					top: 50% !important;
				}
			}
			div.code-promo-column {
				padding-bottom: 0px;
			}
		}
		&.header {
			position: sticky;
			background-color: var(--dashboard-background);
			z-index: 3;
			top: 0px;

			div.code-promo-column {
				padding-bottom: 12px;
				&.env {
					width: 290px;
					flex-shrink: 0;
				}
			}
		}
	}
}
</style>
