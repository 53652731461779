<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		width="480px"
		max-height="80vh"
		overflow="visible"
	>
		<Header>
			<Icon
				v-if="isStepFlow"
				name="i-arrow-left"
				size="small"
				data-qa-terraform-go-back-btn
				@click="goBack"
			/>
			<Typography type="h4" color="dark"> Connect Terraform repository </Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Tag v-if="isStepFlow">
					<Typography type="p2" color="light" data-qa-step-flow-step-2>STEP 2 / 3</Typography>
				</Tag>
				<Icon
					size="x-small"
					type="filled"
					name="i-close"
					data-qa-close-connect-terraform-icon
					@click="validateAndCloseModal"
				/>
			</Container>
		</Header>

		<ClosePopoverConfirmationWarning
			ref="closeConfirmation"
			:initial-form-values="initialTerraformValues"
			:form-values="terraFormValues"
			@force-close="close"
		/>

		<Container
			padding="8px 16px 16px 16px"
			direction="column"
			overflow="auto"
			align="left top"
			class="flow-add-scrollbar"
		>
			<f-form-builder
				ref="terraformForm"
				:field.prop="terraformFields"
				:values.prop="terraFormValues"
				@input="handleTerraformInput"
				@state-change="terraformFormState = $event.detail"
			/>

			<f-text v-if="submitError" state="danger">{{ submitError }}</f-text>
		</Container>

		<Footer>
			<Button
				state="full"
				type="success"
				:loading="isSubmitting"
				data-qa-connect-terraform-submit-btn
				data-qa-terraform-next-connect-cloud-account-btn
				@click="submit"
			>
				{{ submitBtnTxt }}
			</Button>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FFormBuilder, FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	Tag,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { CREDENTIAL_TYPE_ICONS2 } from "@/modules/credentials/constants";
import { credentialStore } from "@/modules/credentials/credential-store";
import { getGitServerByRepo } from "@/modules/credentials/credential-types";
import { envListStore } from "@/modules/env-list/env-list-store";
import { orgStore } from "@/modules/org/org-store";
import { VGEntities } from "@/protocol/common";
import { CredScope, Creds, credDetails } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { EnvCreateStepFlowService } from "@/services/storage-service";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import { GIT_REVISION_OPTIONS, GitRevisionOption } from "@/shared/constants";
import { entityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import { captureError, getErrorMessage, getClassificationIcon } from "@/utils";

export default defineComponent({
	name: "ConnectTerraformRepo",

	components: {
		Icon,
		Typography,
		Container,
		ClosePopoverConfirmationWarning,
		Button,
		Footer,
		Header,
		Wrapper,
		Tag
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		},

		/**
		 * Git credential that is already assigned or inherited to the environment
		 */
		assignedGitCred: {
			type: Object as PropType<Creds | null>
		},

		/**
		 * Newly created git credential that needs to be selected in the list
		 */
		addedGitCred: {
			type: Object as PropType<Creds | null>
		}
	},

	emits: ["create-git-cred", "close", "back", "goBack", "goNext"],

	data() {
		return {
			submitError: "",
			isSubmitting: false,
			terraFormValues: {} as TerraformFormValues,
			initialTerraformValues: {} as TerraformFormValues,
			terraformFormState: null as FormBuilderState | null,
			isStepFlow: EnvCreateStepFlowService.getToggleFlow().isEnabled
		};
	},

	computed: {
		terraformFields(): FormBuilderField {
			// We need to read this property here so that Vue can register
			// it in it's dependency graph and re-render the component
			const { canAccessCreds } = this;

			return {
				type: "object",
				direction: "vertical",
				fields: {
					gitAccount: {
						type: "select",
						qaId: "git-credential",
						clear: true,
						label: {
							title: html` <f-div gap="small" align="middle-center">
								<f-text size="small" weight="medium">Git account</f-text>
								<f-icon-button
									data-qa-add-new-git-account
									tooltip="Add a new git account"
									state="primary"
									icon="i-plus"
									category="fill"
									size="x-small"
									@click=${this.toggleNewGitAcc}
								></f-icon-button>
							</f-div>`,

							subTitle: "Optional"
						},

						placeholder: "Select git account",
						helperText: "Provide this if your Terraform repository is private.",
						options: this.gitCredOptions,
						showWhen: () => {
							return canAccessCreds;
						},

						//@ts-expect-error
						optionTemplate: (option: GitCredOption) =>
							option.data.classification?.icon && option.data.classification.name
								? html`<f-div direction="column" gap="x-small">
										<f-div gap="small">
											<f-icon state="warning" source="${option.data.icon}"></f-icon>
											<f-text>${option.title}</f-text>
											<f-tag
												label="${option.data.classification.name}"
												size="medium"
												state="neutral"
												icon-left="${option.data.classification.icon}"
											/>
										</f-div>
								  </f-div>`
								: html`<f-div direction="column" gap="x-small">
										<f-div gap="small">
											<f-icon state="warning" source="${option.data.icon}"></f-icon>
											<f-text>${option.title}</f-text>
										</f-div>
								  </f-div>`
					},

					repoName: {
						type: "text",
						qaId: "env-code-source-select-repo",
						placeholder: "Enter repo URL",
						label: { title: "Repository URL" },
						disabled: this.isEnvPartOfEnvSet,
						validationRules: [{ name: "required" }, entityNameRules2.entityValidURLRule]
					},

					revisionType: {
						type: "select",
						qaId: "revisionType",
						label: { title: "Revision type" },
						placeholder: "Select revision type",
						options: GIT_REVISION_OPTIONS,
						validationRules: [{ name: "required" }],
						disabled: this.isEnvPartOfEnvSet
					},

					revisionIdentifier: {
						type: "text",
						qaId: "env-code-source-select-repo-branch",
						placeholder: "Enter revision identifier",
						label: { title: "Revision identifier" },
						validationRules: [{ name: "required" }],
						disabled: this.isEnvPartOfEnvSet
					},

					terraformPath: {
						type: "text",
						qaId: "env-code-source-select-terraform-path",
						placeholder: "Enter terraform directory path",
						label: { title: "Terraform directory path" },
						disabled: this.isEnvPartOfEnvSet
					}
				}
			};
		},

		isEnvPartOfEnvSet() {
			return !!this.env.classification?.id;
		},

		submitBtnTxt() {
			return this.isStepFlow ? "NEXT - Connect cloud Account" : "Connect Terraform";
		},

		gitCredOptions(): GitCredOption[] {
			const envClassificationId = this.env.classification?.id;
			return Object.values(credentialStore.genericCredentials)
				.filter(cred => {
					const isGitCred = cred.credScope?.includes(CredScope.git);
					if (!isGitCred) {
						return false;
					}
					return !cred.classificationId || cred.classificationId === envClassificationId;
				})
				.map(cred => {
					const iconName = (cred.type && CREDENTIAL_TYPE_ICONS2[cred.type]) ?? "i-git-branch";
					const gitOption = {
						title: cred.name,
						data: {
							id: cred.id,
							icon: iconName,
							...(cred.classificationId &&
								envClassificationId && {
									classification: {
										icon: this.getClassificationIcon(this.env.classification),
										name: this.env.classification?.name
									}
								})
						}
					};

					return gitOption;
				});
		},

		canAccessCreds() {
			return orgStore.isUserOrgAdmin;
		},

		isGitCredInherited() {
			return this.assignedGitCred?.inheritedFrom !== VGEntities.invalid_entity;
		}
	},

	mounted() {
		const { addedGitCred, assignedGitCred, env } = this;

		// Pre fill existing details if any
		if (env.revision) {
			this.terraFormValues = {
				repoName: env.revision.repo,
				revisionType: GIT_REVISION_OPTIONS.find(option => option.data.id === env.revision?.type),
				revisionIdentifier: env.revision.identifier,
				terraformPath: env.revision.dir
			};
		}

		const selectedGitAccount = this.gitCredOptions.find(cred => {
			// Newly added cred takes precedence over assigned cred
			if (addedGitCred) {
				return cred.data.id === addedGitCred.id;
			}

			if (assignedGitCred) {
				return cred.data.id === assignedGitCred.id;
			}

			return false;
		});

		if (selectedGitAccount) {
			this.terraFormValues = {
				...this.terraFormValues,
				gitAccount: selectedGitAccount
			};
		}

		this.initialTerraformValues = { ...this.terraFormValues };
	},

	methods: {
		getClassificationIcon,

		toggleNewGitAcc() {
			this.$emit("create-git-cred");
		},

		handleTerraformInput(event: CustomEvent<TerraformFormValues>) {
			this.terraFormValues = event.detail;
		},

		close() {
			EnvCreateStepFlowService.toggleFlow({ isEnabled: false });
			this.$emit("close");
		},

		validateAndCloseModal(eventType: "back" | "closeModal" = "closeModal") {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				if (eventType === "back") {
					return this.$emit("back");
				}
				return this.close();
			}
		},

		goBack() {
			this.$emit("goBack", "createEdit");
		},

		async submit() {
			(this.$refs.terraformForm as InstanceType<typeof FFormBuilder>).submit();

			if (!this.terraformFormState?.isValid) {
				return;
			}

			const terraFormValues = this.terraFormValues as Required<TerraformFormValues>;

			try {
				this.isSubmitting = true;

				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				if (terraFormValues.gitAccount && this.canAccessCreds) {
					await this.assignGitCredential();
				} else {
					await this.unassignGitCredential();
				}

				await envListStore.UPDATE_ENV({
					id: this.env.id,
					orgId: this.env.orgId,
					projId: this.env.projId,
					name: this.env.name,
					description: this.env.description,
					pipelineConfig: this.env.pipelineConfig,
					policySetId: this.env.policySetId,
					revision: {
						type: terraFormValues.revisionType.data.id,
						identifier: terraFormValues.revisionIdentifier,
						repo: terraFormValues.repoName,
						dir: terraFormValues.terraformPath,
						driver: getGitServerByRepo(terraFormValues.repoName)
					},
					variables: this.env.variables,
					metadata: this.env.metadata,
					tfVersion: this.env.tfVersion,
					replaceVariables: false
				});

				if (this.isStepFlow) {
					this.$emit("goNext", "cloudAcc");
				} else {
					this.close();
				}
			} catch (error) {
				captureError(error);
				this.submitError = getErrorMessage(error, true);
			} finally {
				this.isSubmitting = false;
			}
		},

		async unassignGitCredential() {
			// if there is an private git cred already assigned to the env, we unnassign it first and then assign the public revision details
			if (this.assignedGitCred && !this.isGitCredInherited) {
				// Entity here is environment
				const entity = { id: this.env.id, projId: this.env.projId, orgId: this.env.orgId };

				// Update requests
				await credentialStore.HANDLE_CREDENTIAL_ASSIGNMENT({
					entity,
					assignCreds: null,
					unassignCreds: {
						credId: this.assignedGitCred.id,
						credScope: this.assignedGitCred.credScope
					},
					assignTo: "environment"
				});
			}
		},

		async assignGitCredential() {
			const terraFormValues = this.terraFormValues as Required<TerraformFormValues>;

			// Create the entity we want to assign the credential to
			const entity = { id: this.env.id, projId: this.env.projId, orgId: this.env.orgId };

			// Create the unassign Credentials meta for the cred what we want to unassign
			let unassignCreds: credDetails | null = null;

			// Unassign git cred only when a git is already assigned or the cred is not inherited from Parent project
			if (this.assignedGitCred && !this.isGitCredInherited) {
				unassignCreds = { credId: this.assignedGitCred.id, credScope: [CredScope.git] };
			}

			const newGitCred = credentialStore.genericCredentials[terraFormValues.gitAccount.data.id]!;

			// Create the assign Credentials meta for the cred what we want to assign
			const assignCreds: credDetails = {
				credId: newGitCred.id,
				credScope: [CredScope.git]
			};

			// Call Vuex action to perform the assigning and unassigning of the credentials from the project
			await credentialStore.HANDLE_CREDENTIAL_ASSIGNMENT({
				entity,
				assignCreds,
				unassignCreds,
				assignTo: "environment"
			});
		}
	}
});

type GitCredOption = FSelectOptionObject & {
	data: {
		id: string;
		icon: string;
		classification?: {
			icon?: string;
			name?: string;
		};
	};
};

type TerraformFormValues = {
	gitAccount?: GitCredOption;
	repoName?: string;
	revisionType?: GitRevisionOption;
	revisionIdentifier?: string;
	terraformPath?: string;
};
</script>
