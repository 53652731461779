import { ButtonType } from "@cldcvr/flow-vue3";

import { actionType } from "@/protocol/infra";

export const ENVPIPELINE_STORE_NAME = "envPipeline";

export type PipelineCardStatus = {
	status: string;
	state: string;
	title: string;
	completedTxt: string;
	progressText: string;
	fetchingTitle: string;
	actionBackground: string;
	help: string;
};

export type ConfirmPipelineMeta = {
	title: string;
	description: string;
	buttonType?: ButtonType;
	buttonText: string;
};

export const ENV_PIPELINE_ICON_STATUS: Record<actionType, "primary" | "success" | "error"> = {
	validateOnly: "primary",
	ValidateAndApply: "success",
	destroy: "error",
	no_action_type_support: "primary"
};

export const ENV_PIPELINE_CONFIRM_ACTION_META: Record<actionType, ConfirmPipelineMeta> = {
	validateOnly: {
		title: "Validate your environment",
		description:
			"You are about to validate a build of this environment, this will trigger the Code Pipes validate pipeline which will verify if the build passes all the security and policy checks.",
		buttonType: "primary",
		buttonText: "Validate Environment"
	},
	ValidateAndApply: {
		title: "Deploy your environment",
		description:
			"You are about to validate and deploy the latest build of this environment, this will trigger the Code Pipes deployment pipeline after the build passes all the security and policy checks.",
		buttonType: "success",
		buttonText: "Deploy Environment"
	},
	destroy: {
		title: "Destroy your environment",
		description:
			"You are about to destroy this environment, this will trigger the Code Pipes destroy pipeline. ",
		buttonType: "error",
		buttonText: "Destroy Environment"
	},
	no_action_type_support: {
		title: "",
		description: "",
		buttonType: undefined,
		buttonText: ""
	}
};

export const ENVPIPELINE_CARD_STATUSES: Record<actionType, PipelineCardStatus> = {
	validateOnly: {
		status: "notStarted",
		state: "primary",
		title: "Validate",
		completedTxt: "Validated",
		progressText: "Validating...",
		fetchingTitle: "Validation",
		actionBackground: "primary-300",
		help: "Validate your Terraform code against your chosen security policy."
	},
	ValidateAndApply: {
		status: "notStarted",
		state: "success",
		title: "Deploy",
		completedTxt: "Deployed",
		progressText: "Deploying...",
		fetchingTitle: "Deployment",
		actionBackground: "success-300",
		help: "Deploy the environment's infrastructure into your cloud account."
	},
	destroy: {
		status: "notStarted",
		state: "error",
		title: "Destroy",
		completedTxt: "Destroyed",
		progressText: "Destroying...",
		fetchingTitle: "Destroy",
		actionBackground: "danger-200",
		help: "Destroy the infrastructure that your deployment created in your cloud account."
	},
	no_action_type_support: {
		status: "",
		state: "",
		title: "",
		completedTxt: "",
		progressText: "",
		fetchingTitle: "",
		actionBackground: "",
		help: ""
	}
};
