<template>
	<Container
		:key="project.id + 'header'"
		padding="18px 24px"
		direction="column"
		align="left top"
		:grow="1"
		:gap="0"
		class="height-100-per"
	>
		<BundleStatus v-if="isBundleAppliedToCurrProj" :project="project" />

		<Container :padding="0" :shrink="0" :gap="12" align="top">
			<Container :padding="0" align="center">
				<Pictogram v-if="emoji" size="xl"> {{ emoji }}</Pictogram>
			</Container>
			<ProjectAvatar
				v-if="!emoji"
				:project-name="project.name"
				:data-qa-landing-page-project-avatar-initials="project.name"
			/>
			<Container padding="0px 10px 0px 0px" :gap="0" :grow="2" overflow="visible" align="top">
				<Container
					padding="4px 50px 0px 0px"
					:gap="4"
					direction="column"
					overflow="visible"
					align="left"
				>
					<Container :padding="0" :gap="12" overflow="visible">
						<Typography
							v-tooltip="{
								content: project.name
							}"
							type="h4"
							color="dark"
							overflow="ellipsis"
							:style="{ maxWidth: '500px' }"
							:data-qa-project-name-header="project.name"
							>{{ project.name }}</Typography
						>

						<PopOver
							v-if="!hasNxPlatformFlag"
							v-model:open="isProjectSettingsOpen"
							placement="right-start"
							:overlay="true"
							@overlay-click="isProjectSettingsOpen = false"
						>
							<Icon
								v-tooltip="{
									content: 'Project settings',
									container: 'div.flow-layout',
									placement: 'right'
								}"
								name="i-setting"
								type="filled"
								state="light"
								size="small"
								data-qa-project-settings-icon
								@click="isProjectSettingsOpen = true"
							/>
							<template #content>
								<ProjectCreatePopover :project="project" @close="isProjectSettingsOpen = false" />
							</template>
						</PopOver>

						<PopOver
							v-if="!hasNxPlatformFlag"
							:open="isDeletePopoverOpen"
							@overlay-click="isDeletePopoverOpen = false"
						>
							<Icon
								v-tooltip="{
									content: 'Delete project',
									container: 'div.flow-layout',
									placement: 'right'
								}"
								name="i-delete"
								type="filled"
								state="light"
								size="small"
								data-qa-trigger-toggle-remove-project-model
								@click="toggleProjectDeletePopover()"
							/>
							<template #content>
								<ProjectRemovePopover
									v-if="isDeletePopoverOpen"
									:project="project"
									:close-project-delete-popover="toggleProjectDeletePopover"
								/>
							</template>
						</PopOver>

						<Icon
							v-tooltip="{
								content: 'Download project bundle'
							}"
							name="i-download"
							type="filled"
							state="light"
							size="small"
							data-qa-download-project-bundle
							:disabled="isDownloadingBundle"
							@click="downloadBundle"
						/>

						<!-- Project Actions -->
						<Icon name="i-separator" type="filled" state="light" size="small" />
						<ProjectActions :project="project" :project-envs-count="projectEnvsCount" />
					</Container>

					<CustomTimeStamp
						v-if="project.updatedAt"
						:time="project.updatedAt"
						prefix="Updated"
						:user="project.updatedBy"
						:data-qa="`project-created-at-date-${project.name}`"
					/>
				</Container>

				<Container
					class="height-100-per"
					align-self="center"
					basis="max-content"
					:padding="0"
					:gap="5"
					align="left top"
					overflow="visible"
				>
					<PolicySelector card-style="icon" :entity="project" entity-type="project" />
					<!-- github credential assign and unassign -->
					<AssignCredentialModal
						v-if="assignedCredToGit.length"
						:entity="project"
						git-form-ref="projectHeaderGitForm"
						entity-kind="project"
						:cred-scope="credScope.git"
					/>
					<!-- github credential assign and unassign -->
					<AssignCredentialModal
						v-if="canAccessCreds && assignedCredToDocker.length"
						:entity="project"
						entity-kind="project"
						:cred-scope="credScope.docker"
					/>
					<!-- cloud(AWS, AZURE, GCP) credential assign and unassign -->
					<AssignCredentialModal
						v-if="canAccessCreds && assignedCredToCloud.length"
						:entity="project"
						entity-kind="project"
						:cred-scope="credScope.cloud"
					/>
				</Container>
			</Container>
		</Container>

		<Container padding="0 0 0 56px" :shrink="0">
			<Markdown
				v-if="project.description"
				data-qa-project-description
				:text="project.description"
				state="secondary"
				size="small"
			/>
		</Container>

		<Container direction="column" :gap="0" :grow="1" :padding="0">
			<Tabs data-qa-project-landing-tabs>
				<!-- Settings -->
				<Tab
					:selected="selectedTab === 'settings'"
					class="width-200 flex-unset"
					data-qa-project-landing-code-promo-tab
					@click="setCurrentTab('settings')"
				>
					<Container :gap="8" :padding="0">
						<Typography
							type="p1"
							:weight="selectedTab === 'settings' ? 'medium' : 'light'"
							:color="selectedTab === 'settings' ? 'dark' : 'default'"
							data-qa-settings-tab-title
							>Settings</Typography
						>
					</Container>
				</Tab>

				<Tab
					id="applications"
					:selected="selectedTab === 'applications'"
					width="200px"
					data-qa-project-app-list-btn
					@click="setCurrentTab('applications')"
				>
					<Container :gap="8" :padding="0">
						<Typography
							type="p1"
							:weight="selectedTab === 'applications' ? 'medium' : 'light'"
							:color="selectedTab === 'applications' ? 'dark' : 'default'"
						>
							Applications
						</Typography>
						<Tag v-if="apps" size="small" shape="rounded">
							<Typography data-qa-project-app-list-count type="p3" color="light">{{
								apps && apps.length
							}}</Typography>
						</Tag>
					</Container>
				</Tab>

				<Tab
					:selected="selectedTab === 'environments'"
					width="200px"
					data-qa-project-landing-env-tab
					@click="setCurrentTab('environments')"
				>
					<Container :gap="8" :padding="0">
						<Typography
							type="p1"
							:weight="selectedTab === 'environments' ? 'medium' : 'light'"
							:color="selectedTab === 'environments' ? 'dark' : 'default'"
						>
							Environments
						</Typography>
						<Tag v-if="envs" size="small" shape="rounded">
							<Typography data-qa-project-environments-count type="p3" color="light">{{
								envs && envs.length
							}}</Typography>
						</Tag>
					</Container>
				</Tab>

				<Tab
					:selected="selectedTab === 'variables'"
					width="200px"
					data-qa-project-landing-variables-tab
					@click="setCurrentTab('variables')"
				>
					<Container :gap="8" :padding="0">
						<Typography
							type="p1"
							:weight="selectedTab === 'variables' ? 'medium' : 'light'"
							:color="selectedTab === 'variables' ? 'dark' : 'default'"
							>Variables</Typography
						>
					</Container>
				</Tab>

				<!-- Code Promotion -->
				<Tab
					:selected="selectedTab === 'code-promotion'"
					class="width-200 flex-unset"
					data-qa-project-landing-code-promo-tab
					@click="setCurrentTab('code-promotion')"
				>
					<Container :gap="8" :padding="0">
						<Typography
							type="p1"
							:weight="selectedTab === 'code-promotion' ? 'medium' : 'light'"
							:color="selectedTab === 'code-promotion' ? 'dark' : 'default'"
							data-qa-code-promo-tab-title
							>Code promotion</Typography
						>
					</Container>
				</Tab>
			</Tabs>
			<Container padding="0px" align="left top" :grow="1" overflow="auto">
				<slot />
			</Container>
			<component
				:is="onboardInfoComponent"
				v-if="onboardInfoComponent"
				:popover-target="selectedTab"
				:data-qa-project-dynamic-content="selectedTab"
			>
			</component>
		</Container>
	</Container>
</template>

<script lang="ts">
import { Container, Icon, Pictogram, PopOver, Tab, Tabs, Tag, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { bundleExport } from "@/modules/bundle/bundle-service";
import { bundleStore } from "@/modules/bundle/bundle-store";
import BundleStatus from "@/modules/bundle/components/BundleStatus.vue";
import AssignCredentialModal from "@/modules/credentials/components/credential-assign/AssignCredentialModal.vue";
import { getCredByScope } from "@/modules/credentials/credential-store";
import { credCategoryDetails } from "@/modules/credentials/credential-types";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { orgStore } from "@/modules/org/org-store";
import PolicySelector from "@/modules/policy-list/components/PolicySelector.vue";
import ProjectCreatePopover from "@/modules/project-create/ProjectCreatePopover.vue";
import ProjectRemovePopover from "@/modules/project-remove/components/ProjectRemovePopover.vue";
import { app } from "@/protocol/app";
import { CredScope, project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { CustomTimeStamp } from "@/shared/components";
import Markdown from "@/shared/components/Markdown.vue";
import ProjectAvatar from "@/shared/components/ProjectAvatar.vue";
import ProjectApplicationPopover from "@/shared/components/ProjectOnboardPopover/ProjectApplicationPopover.vue";
import { captureError, downloadBase64File, getEmoji, getErrorMessage } from "@/utils";

import { ProjectTabName } from "../project-types";

import ProjectActions from "./ProjectActions.vue";

export default defineComponent({
	name: "ProjectWrapper",

	components: {
		BundleStatus,
		Container,
		CustomTimeStamp,
		Icon,
		Pictogram,
		PopOver,
		ProjectAvatar,
		ProjectCreatePopover,
		ProjectRemovePopover,
		ProjectApplicationPopover,
		Tab,
		Tabs,
		Tag,
		Typography,
		AssignCredentialModal,
		ProjectActions,
		PolicySelector,
		Markdown
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		},

		selectedTab: {
			type: String as PropType<ProjectTabName>,
			required: true
		},

		apps: {
			type: Array as PropType<app[]>,
			required: true
		},

		envs: {
			type: Array as PropType<environment[]>,
			required: true
		}
	},

	emits: ["updateBGFilter"],

	data: () => ({
		credScope: CredScope,
		isDeletePopoverOpen: false,
		isDownloadingBundle: false,
		isProjectSettingsOpen: false,
		onboardInfoComponent: "ProjectApplicationPopover"
	}),

	computed: {
		emoji() {
			return getEmoji(this.project);
		},

		hasNxPlatformFlag() {
			return featureFlagStore.featureMap.ENABLE_NX_PLATFORM;
		},

		projectEnvsCount() {
			return this.envs.length;
		},

		assignedCredToGit() {
			return getCredByScope({
				entityId: this.project.id,
				scope: credCategoryDetails[CredScope.git].scope
			});
		},

		assignedCredToCloud() {
			return getCredByScope({
				entityId: this.project.id,
				scope: credCategoryDetails[CredScope.cloud].scope
			});
		},

		assignedCredToDocker() {
			return getCredByScope({
				entityId: this.project.id,
				scope: credCategoryDetails[CredScope.docker].scope
			});
		},

		isBundleAppliedToCurrProj() {
			return Boolean(bundleStore.appliedBundles[this.project.id]);
		},

		canAccessCreds() {
			return orgStore.isUserOrgAdmin;
		}
	},

	methods: {
		toggleProjectDeletePopover() {
			this.isDeletePopoverOpen = !this.isDeletePopoverOpen;
		},

		setCurrentTab(filter: ProjectTabName) {
			switch (filter) {
				case "applications": {
					this.onboardInfoComponent = "ProjectApplicationPopover";
					break;
				}
				default: {
					this.onboardInfoComponent = "";
					break;
				}
			}
			this.$emit("updateBGFilter", filter);
		},

		async downloadBundle() {
			this.isDownloadingBundle = true;

			try {
				const exportResult = await bundleExport({
					orgId: this.project.orgId,
					projectId: this.project.id
				});

				if (!exportResult.files) {
					throw new Error("No files in bundle");
				}

				const JSZip = await import("jszip");

				const zip = new JSZip.default();

				Object.entries(exportResult.files).forEach(([name, content]) => {
					zip.file(name, atob(content.data!));
				});

				const content = await zip.generateAsync({ type: "base64" });

				downloadBase64File(content, `${this.project.name}.zip`);
			} catch (err) {
				notificationsStore.ADD_TOAST({
					status: "error",
					qaId: "download-bundle-error",
					title: "Error",
					text: getErrorMessage(err)
				});

				captureError(err);
			} finally {
				this.isDownloadingBundle = false;
			}
		}
	}
});
</script>
