<template>
	<div class="flow-app-version-wrapper">
		<div class="flow-app-version-card">
			<slot />
			<div :style="barStyle" class="flow-app-version-bar" />
		</div>
		<div v-if="$slots['dependencies']" class="flow-app-version-dependencies">
			<slot name="dependencies" />
		</div>
		<div
			v-if="$slots['notification']"
			class="flow-app-version-notification"
			:data-type="notificationType"
		>
			<slot name="notification" />
		</div>
		<div v-if="$slots['pipeline']" class="flow-app-version-pipeline">
			<slot name="pipeline" />
		</div>
	</div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "AppVersion",

	props: {
		/**
		 * color of left border or indicator
		 */
		color: {
			type: String,
			default: "var(--dashboard-primary)"
		},

		/**
		 * notfication type displayed at bottom <br/>
		 * 'info' | 'error' | 'success' | 'warning'
		 */
		notificationType: {
			type: String,
			default: "info",

			validator(value) {
				return ["info", "error", "success", "warning"].includes(value);
			}
		}
	},

	computed: {
		barStyle() {
			return {
				borderLeft: `2px solid ${this.color}`
			};
		}
	}
});
</script>
<style lang="scss">
$notification-background-colors: (
	"info": var(--primary-400),
	"error": var(--dashboard-danger),
	"success": var(--dashboard-success),
	"warning": var(--warning-400)
);
$pipeline-state-colors: (
	"primary": var(--dashboard-primary),
	"error": var(--dashboard-danger),
	"warning": var(--dashboard-warning)
);
div.flow-app-version-wrapper {
	width: 290px;
	> * {
		z-index: inherit;
	}
	div.flow-app-version-card {
		background-color: var(--gray-500);
		border-radius: 4px;
		width: inherit;
		min-height: 86px;
		padding: 16px;
		position: relative;
		display: flex;

		div.flow-app-version-bar {
			height: 36px;
			position: absolute;
			top: 25px;
			left: 0px;
			border-radius: 0px 2px 2px 0px;
		}
	}
	.flow-app-version-dependencies {
		border-radius: 4px;
		margin-top: 2px;
		background-color: var(--gray-500);
		position: relative;
		div.container {
			z-index: inherit;
		}
	}
	.flow-app-version-notification {
		min-height: 24px;
		width: 100%;
		border-radius: 4px;
		margin-top: 2px;
		position: relative;

		@each $type, $color in $notification-background-colors {
			&[data-type="#{$type}"] {
				background-color: $color;
			}
		}

		div.container {
			z-index: inherit;
		}
	}

	div.flow-app-version-pipeline {
		margin-top: 8px;
		overflow: visible;

		div.container {
			z-index: inherit;
		}
		ul {
			z-index: 1;
			li {
				height: 24px;
				width: 24px;
				border-radius: 12px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
			}
			&[data-collapsed] {
				position: relative;
				cursor: pointer;
				height: 24px;
				width: 34px;
				li {
					border: 1px solid var(--success-400);
					position: absolute;
					background-color: var(--dashboard-background);
					display: none;
					&:nth-child(1) {
						left: 0px;
						z-index: 10;
						opacity: 1;
						display: inline-flex;
					}
					&:nth-child(2) {
						left: 6px;
						z-index: 9;
						opacity: 0.7;
						display: inline-flex;
						transform: scale(0.95);
						transform-origin: center;
					}
					&:nth-child(3) {
						left: 12px;
						z-index: 8;
						opacity: 0.5;
						display: inline-flex;
						transform: scale(0.9);
						transform-origin: center;
					}
				}
			}
			&[data-expanded] {
				flex: 0 0 auto;
				display: flex;
				li {
					background-color: var(--success-400);
					position: relative;
					.flow-picto-wrapper {
						z-index: 2;
					}

					@each $state, $color in $pipeline-state-colors {
						&[data-state="#{$state}"] {
							background-color: $color;
						}
					}

					&:not(:first-child) {
						margin-left: 8px;
						&:before {
							content: "";
							position: absolute;
							top: 12px;
							width: 8px;
							right: 100%;
							border-top: 1px dotted var(--success-400);
						}
					}
					&[data-pulse] {
						&:after {
							content: "";
							position: absolute;
							border-radius: 50%;
							z-index: 1;
							background-color: inherit;
						}
						&:after {
							width: 24px;
							height: 24px;
							animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
						}
						@keyframes pulse-ring {
							0% {
								transform: scale(1.2);
							}
							80%,
							100% {
								opacity: 0;
								transform: scale(1.6);
							}
						}
					}
				}
			}
		}
	}
	div.flow-tag[size="small"] {
		padding: 4px 4px 4px 4px;
	}

	.onhover {
		display: none;
	}
	&:hover {
		.onhover {
			display: unset;
		}
	}
}
</style>
