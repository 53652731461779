import { actionType } from "@/protocol/infra";

export const JOB_UPDATED = "JOB_UPDATED";

export const ENV_PIPELINE_ACTIONS = [
	actionType.validateOnly,
	actionType.ValidateAndApply,
	actionType.destroy
];

// depolyJobType or DeploymentJobAction ?
export const APP_PIPELINE_ACTIONS = ["deploy", "undeploy"];
