<template>
	<Tabs data-qa-int-pipeline-logs-view-screen class="height-100-per width-100-per">
		<Tab
			v-if="showLogsError"
			:selected="currentTab === 'issues'"
			width="100px"
			data-qa-issues-tab
			@click="currentTab = 'issues'"
		>
			<Typography type="p1" color="danger-200" weight="bold">Issues</Typography>
		</Tab>

		<Tab
			v-for="(file, idx) in logFiles"
			:key="idx + file"
			:selected="currentTab === file"
			width="auto"
			:data-qa-log-terminal="file"
			@click="currentTab = file"
		>
			<Typography type="p2" overflow="ellipsis">{{ formatTabName(file) }}</Typography>
		</Tab>

		<PipelineTerminalActions
			:logs="logDownloadOptions ?? undefined"
			:enable-filters="false"
			:enable-wrapping="Boolean(currentArtifactString)"
			@toggle-wrap="wrapText = $event"
		>
			<slot name="pipeline-actions"></slot>
		</PipelineTerminalActions>

		<template #content>
			<Container :padding="0" direction="column" :gap="0" align="left top">
				<PipelineLogError
					v-if="currentTab === 'issues' && showLogsError && currentJob.message"
					:errors="[currentJob.message]"
				/>

				<Container v-else padding="12px 0 0 12px" :grow="1" align="left stretch">
					<LogsView
						v-if="currentArtifactString"
						:logs="currentArtifactString"
						:wrap-text="wrapText"
						data-qa-output-terminal
					>
					</LogsView>

					<PipelineLogStatus
						v-else
						entity-name="Logs"
						:is-plural="true"
						:status="pipelineLogStatus"
						@download-logs="downloadCurrentFile"
					/>
				</Container>
			</Container>
		</template>
	</Tabs>
</template>

<script lang="ts">
import { Container, Tab, Tabs, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { integrationJob } from "@/protocol/app";
import { jobStep } from "@/protocol/infra";
import PipelineLogError from "@/shared/components/pipelines/PipelineLogError.vue";
import PipelineLogStatus from "@/shared/components/pipelines/PipelineLogStatus.vue";
import PipelineTerminalActions from "@/shared/components/pipelines/PipelineTerminalActions.vue";
import LogsView from "@/shared/LogsView.vue";
import {
	JOB_STATUS,
	PIPELINE_UNFINISHED_JOB_STATUSES,
	PipelineJobStatus
} from "@/shared/pipeline-constants";
import {
	captureError,
	downloadFile,
	fileToString,
	isJSONLogArtifact,
	isPlainLogArtifact
} from "@/utils";

import { applicationIntegrationStore } from "../application-integration-store";

export default defineComponent({
	name: "IntegrationPipelineLogsView",

	components: {
		Container,
		Tab,
		Tabs,
		Typography,
		LogsView,
		PipelineLogError,
		PipelineLogStatus,
		PipelineTerminalActions
	},

	props: {
		currentStep: {
			type: Object as PropType<jobStep>,
			required: true
		},

		currentJob: {
			type: Object as PropType<integrationJob>,
			required: true
		}
	},

	data: () => ({
		wrapText: false,
		artifactDownloadFailed: false,
		artifactLoading: false,
		currentTab: null as null | "issues" | string
	}),

	computed: {
		showLogsError() {
			return this.currentJob.jobStatus === "failed" && this.currentJob.message !== "";
		},

		logFiles() {
			return this.currentStep.logs ?? [];
		},

		logDownloadOptions() {
			return this.logFiles.map((logFile, idx) => ({
				name: logFile,
				showDivider: idx === this.logFiles.length - 1,
				downloadLog: () => this.downloadFile(logFile)
			}));
		},

		pipelineLogStatus():
			| "loading"
			| "progress"
			| "cancelled"
			| "download-failed"
			| "pipeline-failed"
			| "empty"
			| undefined {
			if (this.artifactLoading) {
				return "loading";
			}

			if (this.isRunningPipeline) {
				return "progress";
			}

			if (this.currentJob.jobStatus === JOB_STATUS.CANCELLED) {
				return "cancelled";
			}

			if (this.currentJob.jobStatus === JOB_STATUS.FAILED) {
				return "pipeline-failed";
			}

			if (this.artifactDownloadFailed) {
				return "download-failed";
			}

			if (this.currentArtifactString?.length === 0) {
				return "empty";
			}

			return undefined;
		},

		currentArtifact() {
			if (!this.currentTab || this.currentTab === "issues") {
				return null;
			}

			const artifact =
				applicationIntegrationStore.integrationLogs[this.currentJob.id]?.[this.currentTab];

			if (
				(isPlainLogArtifact(artifact) || isJSONLogArtifact(artifact)) &&
				artifact.contents.length === 0
			) {
				return null;
			}

			return artifact ?? null;
		},

		currentArtifactString() {
			if (!this.currentArtifact) {
				return null;
			}

			return fileToString(this.currentArtifact);
		},

		isRunningPipeline() {
			return PIPELINE_UNFINISHED_JOB_STATUSES.includes(
				this.currentJob.jobStatus as PipelineJobStatus
			);
		}
	},

	watch: {
		currentStep: {
			immediate: true,

			handler() {
				this.resetCurrentFile();
			}
		},

		currentTab: {
			immediate: true,

			handler() {
				this.fetchCurrentArtifact();
			}
		},

		currentJob: {
			deep: true,

			handler() {
				this.fetchCurrentArtifact();
			}
		},

		showLogsError: {
			immediate: true,

			handler() {
				if (this.showLogsError) {
					this.currentTab = "issues";
				}
			}
		}
	},

	methods: {
		// Get pretty tab names from our log files
		formatTabName(tabName: string) {
			const newTabName = tabName
				.replace(/[_-]/gi, " ")
				// All log files begin with a prefix which is not needed to render
				.replace(/^(step integration pipeline)/i, "")
				.replace(".log", "")
				.replace(".json", "")
				.trim();

			return `${newTabName.substring(0, 1).toLocaleUpperCase()}${newTabName.substring(1)}`;
		},

		resetCurrentFile() {
			this.currentTab = this.logFiles[0] ?? null;
		},

		fetchCurrentArtifact() {
			if (!this.currentTab || this.currentTab === "issues") {
				return null;
			}

			return this.fetchArtifact(this.currentTab);
		},

		async fetchArtifact(artifactName: string) {
			try {
				this.artifactDownloadFailed = false;
				this.artifactLoading = true;

				const { currentJob } = this;

				await applicationIntegrationStore.FETCH_INTEGRATION_LOG({
					job: {
						id: currentJob.id,
						integrationId: currentJob.integrationId,
						appId: currentJob.appId,
						projId: currentJob.projId,
						orgId: currentJob.orgId
					},
					artifactName
				});
			} catch (err) {
				this.artifactDownloadFailed = true;
				captureError(err);
			} finally {
				this.artifactLoading = false;
			}
		},

		async downloadCurrentFile() {
			if (this.currentTab && this.currentTab !== "issues") {
				await this.downloadFile(this.currentTab);
			}
		},

		async downloadFile(fileName: string) {
			let artifact =
				applicationIntegrationStore.integrationLogs[this.currentJob.id]?.[fileName] ?? null;

			if (!artifact) {
				await this.fetchArtifact(fileName);
			}

			artifact =
				applicationIntegrationStore.integrationLogs[this.currentJob.id]?.[fileName] ?? null;

			// Allow download only when the logs are available
			if (artifact) {
				downloadFile(artifact, fileName);
			}
		}
	}
});
</script>
