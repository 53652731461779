<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		:data-qa-add-container-img-model="app.id"
		data-qa-edit-cont-img-model
		overflow="visible"
	>
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			overflow="visible"
		>
			<Header>
				<Container align="space-between center" :padding="0" :grow="1">
					<Container :gap="12" :padding="0">
						<Icon
							v-if="!artifact"
							name="i-arrow-left"
							size="small"
							data-qa-terraform-go-back-btn
							@click="validateAndCloseModal('back')"
						/>
						<Typography type="h4" color="dark">
							{{ artifact ? "Edit container image" : "Add container image" }}
						</Typography>
					</Container>
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-edit-cont-img-close
						:data-qa-add-container-img-close-btn="app.id"
						@click.stop="validateAndCloseModal('close')"
					/>
				</Container>
			</Header>
			<ClosePopoverConfirmationWarning
				v-if="initialformValuesContent"
				ref="closeConfirmation"
				:initial-form-values="initialformValuesContent"
				:form-values="formValues"
				@force-close="$emit('close')"
			/>
			<Container
				padding="10px 16px 16px 16px"
				direction="column"
				overflow="visible"
				align="stretch top"
				:grow="1"
			>
				<ApplicationContainerForm
					ref="appContainerForm"
					v-model:form-values="formValues"
					data-qa-edit-cont-img-form
					:data-qa-add-container-img-form="app.id"
					:existing-container-repo-names="existingContainerRepoNames"
					:show-display-name="Boolean(artifact)"
					:suggested-display-name="suggestedAppName"
				/>

				<Container v-if="artifact" :padding="0" :gap="12" align="left top" :shrink="0">
					<Icon name="i-bulb" size="small" state="warning" type="filled" :effects="false" />
					<Typography type="p2" weight="thin" color="warning">
						These changes will take effect with the next deployment of the associated apps.
					</Typography>
				</Container>
			</Container>
			<Footer>
				<Container direction="column" :grow="1" :gap="0" :padding="0">
					<Container v-if="submitError">
						<p class="paragraph-2 fc-error">{{ submitError }}</p>
					</Container>
					<Container :padding="0">
						<Button
							state="full"
							:type="isSubmitting ? 'default' : 'success'"
							:disabled="isSubmitting"
							:loading="isSubmitting"
							:data-qa-add-container-img-cta-btn="app.id"
							data-qa-edit-cont-img-save-btn
							@click="addContainerImage"
						>
							{{ artifact ? "Update" : "Add container image" }}
						</Button>
					</Container>
				</Container>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { app } from "@/protocol/app";
import { Artifact } from "@/protocol/common";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import { getErrorMessage, parseUrl } from "@/utils";

import ApplicationContainerForm, {
	ApplicationContainerFormValues
} from "../ApplicationContainerForm.vue";

import { VERSION_TAGS, containerProviderMap, containerProviderOptions } from "./applicationForm";

export default defineComponent({
	name: "ContainerImageModal",

	components: {
		Typography,
		Container,
		Wrapper,
		Button,
		Header,
		Footer,
		Icon,
		ClosePopoverConfirmationWarning,
		ApplicationContainerForm
	},

	inject: ["updateLayout"],

	props: {
		app: {
			type: Object as PropType<app>,
			required: true
		},

		artifact: {
			type: Object as PropType<Artifact>
		}
	},

	data() {
		let containerProvider: ApplicationContainerFormValues["containerProvider"] | null = null;
		const { artifact } = this;

		if (artifact) {
			containerProvider = containerProviderOptions.find(
				option => option.data.id === artifact.containerImage?.provider
			);
		}

		return {
			isSubmitting: false,
			submitError: "",
			initialformValuesContent: null as ApplicationContainerFormValues | null,
			formValues: {
				containerRepo: artifact?.containerImage?.repo ?? "",
				appContainerImageName: artifact?.name,
				containerTag: artifact?.containerImage?.reference,
				appHost: artifact?.containerImage?.host,
				versionTagPattern: VERSION_TAGS.find(
					tag => tag.data?.id === artifact?.containerImage?.versionTagPattern
				),

				containerProvider
			} as ApplicationContainerFormValues
		};
	},

	computed: {
		existingContainerRepoNames(): string[] {
			return (
				this.app.artifacts
					?.map(artifact => {
						if (artifact.id === this.artifact?.id) {
							return "";
						}

						return artifact.containerImage ? artifact.containerImage.repo : "";
					})
					.filter(name => !!name) ?? []
			);
		},

		suggestedAppName() {
			const containerProvider = this.formValues.containerProvider?.data.id;
			const appPath =
				parseUrl(this.formValues.containerRepo)?.pathname.replace("/", "") ??
				this.formValues.containerRepo;

			return `${appPath}${containerProvider ? `-${containerProviderMap(containerProvider)}` : ""}`;
		}
	},

	watch: {
		formValues: {
			deep: true,

			handler() {
				if (!this.initialformValuesContent) {
					this.initialformValuesContent = this.formValues;
				}

				// Update the popover layout when the form changes significantly
				this.$nextTick(() => {
					if (this.updateLayout) {
						//@ts-expect-error Vue 2.x has no way to type injects
						this.updateLayout();
					}
				});
			}
		}
	},

	methods: {
		validateAndCloseModal(eventType: "back" | "close" = "close") {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				return this.$emit(eventType);
			}
		},

		async addContainerImage() {
			if (this.isSubmitting) {
				return;
			}

			(this.$refs.appContainerForm as InstanceType<typeof ApplicationContainerForm>).submit();

			this.submitError = "";
			this.isSubmitting = true;

			const { formValues } = this;

			const containerProviderId = this.formValues.containerProvider?.data.id;

			if (!formValues.isValid || !containerProviderId || !formValues.containerRepo) {
				this.isSubmitting = false;
				return;
			}

			try {
				let artifacts = this.app.artifacts ? [...this.app.artifacts] : [];

				// Edit flow
				const { artifact } = this;
				if (artifact) {
					artifacts = artifacts.filter(artifact_ => artifact_.id !== artifact.id);

					artifacts.push({
						id: artifact.id,
						name: formValues.appContainerImageName ?? formValues.containerRepo,
						containerImage: {
							provider: containerProviderId,
							host: formValues.appHost ?? "",
							repo: formValues.containerRepo,
							reference: formValues.containerTag,
							versionTagPattern:
								formValues.versionTagPattern?.data.id ??
								artifact.containerImage?.versionTagPattern ??
								""
						}
					});
				}
				// Add flow
				else {
					artifacts.push({
						name: this.suggestedAppName,
						containerImage: {
							provider: containerProviderId,
							host: formValues.appHost,
							repo: formValues.containerRepo,
							reference: formValues.containerTag ?? undefined,
							versionTagPattern: formValues.versionTagPattern?.data.id
						}
					});
				}

				await applicationStore.UPDATE_APPLICATION({
					id: this.app.id,
					orgId: this.app.orgId,
					projId: this.app.projId,
					name: this.app.name,
					description: this.app.description,
					artifacts,
					replaceVariables: false
				});

				this.$emit("close");
			} catch (error) {
				this.submitError = getErrorMessage(error);
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});
</script>
