<template>
	<f-div direction="column" height="100%" gap="small" padding="none large">
		<!--Header Section-->
		<f-div direction="row" gap="large" border="small solid default bottom" height="hug-content">
			<f-div direction="row" gap="x-small" width="80%" padding="none small large none">
				<f-text size="medium" variant="heading" state="custom, #ffffff">{{
					selectedComponent.title
				}}</f-text>
			</f-div>
			<f-div direction="row" gap="x-small" align="middle-right" height="hug-content">
				<f-icon-button icon="i-setting" type="transparent" state="neutral" disabled>
				</f-icon-button>
			</f-div>
		</f-div>
		<f-div
			v-if="selectedComponent.moduleId"
			direction="row"
			gap="small"
			height="hug-content"
			padding="none none large none"
		>
			<f-div direction="column" height="hug-content" gap="medium" width="100%">
				<!--Description Section-->
				<f-text size="small" variant="heading" weight="regular">Module Details</f-text>
			</f-div>
			<f-div direction="column" gap="small" height="hug-content">
				<f-div direction="row" gap="x-small" padding="none" height="hug-content">
					<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
						<f-text size="x-small" variant="heading" state="secondary" weight="regular">
							Name
						</f-text>
					</f-div>
					<f-div
						direction="row"
						gap="none"
						width="fill-container"
						padding="none"
						height="hug-content"
					>
						<f-text size="x-small" variant="para" sstate="custom, #ffffff" weight="regular">
							{{ selectedComponent.moduleId?.name }}
						</f-text>
					</f-div>
				</f-div>
				<f-div direction="row" gap="x-small" width="100%" padding="none" height="hug-content">
					<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
						<f-text size="x-small" variant="heading" state="secondary" weight="regular">
							Namespace
						</f-text>
					</f-div>
					<f-div
						direction="row"
						gap="none"
						width="fill-container"
						padding="none"
						height="hug-content"
					>
						<f-text size="x-small" variant="para" state="custom, #ffffff" weight="regular">
							{{ selectedComponent.moduleId.namespace }}
						</f-text>
					</f-div>
				</f-div>
				<f-div
					v-if="selectedComponent.moduleId?.path"
					direction="row"
					gap="x-small"
					width="100%"
					padding="none"
					height="hug-content"
				>
					<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
						<f-text size="x-small" variant="heading" state="secondary" weight="regular">
							Path</f-text
						>
					</f-div>
					<f-div
						direction="row"
						gap="none"
						width="fill-container"
						padding="none"
						height="hug-content"
					>
						<f-text size="x-small" variant="para" state="custom, #ffffff" weight="regular">
							{{ selectedComponent.moduleId.path }}
						</f-text>
					</f-div>
				</f-div>
				<f-div direction="row" gap="x-small" width="100%" padding="none" height="hug-content">
					<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
						<f-text size="x-small" variant="heading" state="secondary" weight="regular"
							>Version</f-text
						>
					</f-div>
					<f-div
						direction="row"
						gap="none"
						width="fill-container"
						padding="none"
						height="hug-content"
					>
						<f-text size="x-small" variant="para" state="custom, #ffffff" weight="regular">
							{{ selectedComponent.moduleId.version }}
						</f-text>
					</f-div>
				</f-div>
				<f-div
					v-if="selectedComponent.updatedAt"
					direction="row"
					gap="x-small"
					width="100%"
					padding="none"
					height="hug-content"
				>
					<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
						<f-text size="x-small" variant="heading" state="secondary" weight="regular">
							Last updated
						</f-text>
					</f-div>
					<f-div
						direction="row"
						gap="none"
						width="fill-container"
						padding="none"
						height="hug-content"
					>
						<f-text size="x-small" variant="para" state="custom, #ffffff" weight="regular">
							{{ timestampFormat(selectedComponent.updatedAt) }}
						</f-text>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
		<f-div v-if="componentDetails" direction="row" gap="large" height="fill-container">
			<Card :separator="false" background="default">
				<Tabs :sticky="false" size="small" class="height-100-per">
					<Tab
						:selected="selectedTabIndex === 1"
						data-qa-component-registry-input-tab
						width="125px"
						@click="selectedTabIndex = 1"
					>
						<Container :gap="8" :padding="0">
							<Typography
								type="p2"
								:weight="selectedTabIndex === 1 ? 'medium' : 'regular'"
								:color="selectedTabIndex === 1 ? 'default' : 'light'"
								data-qa-component-registry-output-tab-title
							>
								Inputs
								<Tag v-if="inputCount > 0" size="small">
									<Typography type="p3" color="light" data-qa-component-registry-output-tab-count>{{
										inputCount
									}}</Typography>
								</Tag>
							</Typography>
						</Container>
					</Tab>
					<Tab
						:selected="selectedTabIndex === 2"
						data-qa-component-registry-output-tab
						width="125px"
						@click="selectedTabIndex = 2"
					>
						<Container :gap="8" :padding="0">
							<Typography
								type="p2"
								:weight="selectedTabIndex === 2 ? 'medium' : 'regular'"
								:color="selectedTabIndex === 2 ? 'default' : 'light'"
								data-qa-component-registry-output-tab-title
							>
								Outputs
								<Tag v-if="outputCount > 0" size="small">
									<Typography type="p3" color="light" data-qa-component-registry-output-tab-count>{{
										outputCount
									}}</Typography>
								</Tag>
							</Typography>
						</Container>
					</Tab>
					<template #content>
						<!--END : Inputs Tab-->
						<TerraformInputTab
							v-if="selectedTabIndex === 1"
							:variables="componentDetails.variables ?? []"
						/>
						<!--START : Outputs Tab-->
						<DependencyInputOutputTab
							v-if="selectedTabIndex === 2"
							:list-data="componentDetails.outputs ?? {}"
							title="Outputs"
						/>
						<!--END : Outputs Tab-->
					</template>
				</Tabs>
			</Card>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { Container, Typography, Tabs, Tab, Card, Tag } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import DependencyInputOutputTab from "@/modules/dependency-registry/components/DependencyInputOutputTab.vue";
import { getComponent } from "@/modules/org/org-service";
import TerraformInputTab from "@/modules/terraform-registry/components/TerraformInputTab.vue";
import { componentListItem, infrastructureComponent } from "@/protocol/infra";
import { formatDateToRegularFormat } from "@/utils";

export default defineComponent({
	name: "TerraformDetail",

	components: {
		Container,
		Typography,
		Tabs,
		Tab,
		Card,
		Tag,
		TerraformInputTab,
		DependencyInputOutputTab
	},

	props: {
		selectedComponent: {
			type: Object as PropType<componentListItem>,
			required: true
		}
	},

	data: () => ({
		//@todo - isLoading is unused update UX when loading
		isLoading: false,
		selectedTabIndex: 1,
		componentDetails: {} as infrastructureComponent | null
	}),

	computed: {
		outputCount() {
			return this.componentDetails?.outputs ? Object.keys(this.componentDetails.outputs).length : 0;
		},

		inputCount() {
			return this.componentDetails?.variables ? this.componentDetails.variables.length : 0;
		}
	},

	mounted() {
		(async () => {
			this.isLoading = true;
			const component = await getComponent({
				id: this.selectedComponent.id,
				orgId: this.selectedComponent.orgId
			});

			this.componentDetails = component;
			this.isLoading = false;
		})();
	},

	methods: {
		timestampFormat(timestamp: string) {
			return formatDateToRegularFormat(Number(timestamp) * 1000);
		}
	}
});
</script>
