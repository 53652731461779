import { Router, RouteRecordRaw } from "vue-router";

import AppStageViewWrapper from "./components/AppStageViewWrapper.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/org/:orgId/projects/:projectId/envs/:envId/deployment/:depId/:action",
		name: "stageViewApp",
		component: AppStageViewWrapper,
		meta: {
			requiresAuth: true,
			title: "App Pipeline"
		},
		props: true
	},
	{
		path: "/org/:orgId/projects/:projectId/envs/:envId/deployment/:depId/:action/job/:jobId",
		name: "stageViewAppWithJob",
		component: AppStageViewWrapper,
		meta: {
			requiresAuth: true,
			title: "App Pipeline"
		},
		props: true
	}
];

export function registerAppPipelineRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
