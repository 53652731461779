<template>
	<Container padding="0px 8px" :gap="8" align="right center" :shrink="0" class="margin-lt-auto">
		<slot />

		<PopOver
			v-if="enableFilters"
			v-model:open="showLogFilterOptions"
			placement="bottom-end"
			:overlay="false"
			class="align-items-center"
		>
			<Pictogram
				size="m"
				:state="filterId !== null ? 'primary' : 'light'"
				effects
				@click="showLogFilterOptions = !showLogFilterOptions"
			>
				<Icon
					v-tooltip="{
						content: 'Filter logs',
						container: 'div.flow-layout',
						placement: 'left'
					}"
					name="i-filter"
					size="x-small"
					:state="filterId !== null ? 'black' : 'default'"
					color="gray-200"
					:effects="false"
				/>
			</Pictogram>
			<template #content>
				<Wrapper border-radius="4px" background="gray-400" :border="true" width="200px">
					<Container direction="column" :padding="0" :gap="0">
						<Slab
							v-for="filterOption in logFilterOptions"
							:key="filterOption.id"
							size="medium"
							:effects="true"
							class="cursor-pointer"
							:data-selected="filterId === filterOption.id"
							:disabled="filterOption.isDisabled"
							@click="toggleTerminalFilter(filterOption)"
						>
							<Typography type="p1" weight="regular" color="dark">{{
								filterOption.name
							}}</Typography>
							<template v-if="filterId === filterOption.id" #secondary-actions>
								<Icon name="i-tick" state="dark" size="small" :effects="false" />
							</template>
						</Slab>
					</Container>
				</Wrapper>
			</template>
		</PopOver>

		<PopOver
			v-if="logs && logs.length > 0"
			placement="bottom-end"
			:overlay="false"
			:open="openDownloadMenu"
			class="align-items-center"
			@overlay-click="openDownloadMenu = false"
		>
			<Pictogram
				size="m"
				:state="openDownloadMenu ? 'primary' : 'light'"
				effects
				@click="openDownloadMenu = !openDownloadMenu"
			>
				<Icon
					v-tooltip="{
						content: 'Download',
						container: 'div.flow-layout',
						placement: 'left'
					}"
					name="i-download"
					size="x-small"
					:state="openDownloadMenu ? 'black' : 'default'"
					color="gray-200"
					data-qa-download-logs
					:effects="false"
				/>
			</Pictogram>

			<template #content>
				<Wrapper border-radius="4px" background="gray-400" :border="true" width="auto">
					<Container direction="column" :padding="0" :gap="0">
						<template v-for="(log, idx) in logs" :key="idx + log.name">
							<Slab
								size="medium"
								:effects="true"
								class="cursor-pointer"
								:data-qa-download-log-btn="log.name"
								@click="log.downloadLog"
							>
								<template #primary-action>
									<Icon name="i-download" size="small" state="light" :effects="false" />
								</template>
								<Typography type="p1" weight="regular" color="dark">{{ log.name }}</Typography>
							</Slab>
							<Divider v-if="log.showDivider"> </Divider>
						</template>
					</Container>
				</Wrapper>
			</template>
		</PopOver>

		<Pictogram
			v-if="enableWrapping"
			v-tooltip="{
				content: isWrapping ? 'Unwrap text' : 'Wrap text',
				container: 'div.flow-layout',
				placement: 'left'
			}"
			size="m"
			:state="isWrapping ? 'primary' : 'light'"
			effects
			@click="
				isWrapping = !isWrapping;
				$emit('toggle-wrap', isWrapping);
			"
		>
			<Icon
				name="i-menu-alt-2"
				size="x-small"
				:state="isWrapping ? 'black' : 'default'"
				color="gray-200"
				data-qa-toggle-wrap
				:effects="false"
			/>
		</Pictogram>
	</Container>
</template>

<script lang="ts">
import {
	Container,
	Divider,
	Icon,
	Pictogram,
	PopOver,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { TerminalFilterId, TERMINAL_FILTERS } from "@/shared/pipeline-constants";

export type LogItem = {
	name: string;
	downloadLog(): void;
	showDivider: boolean;
};

const LOG_FILTER_OPTIONS = [
	TERMINAL_FILTERS.TRACE,
	TERMINAL_FILTERS.DEBUG,
	TERMINAL_FILTERS.INFO,
	TERMINAL_FILTERS.WARNING,
	TERMINAL_FILTERS.ERROR
].map(filter => ({
	...filter,
	isDisabled: false
}));

export default defineComponent({
	name: "PipelineTerminalActions",

	components: {
		Pictogram,
		Icon,
		Divider,
		Slab,
		Container,
		Typography,
		PopOver,
		Wrapper
	},

	props: {
		filterId: {
			type: String as PropType<null | TerminalFilterId>,
			default: () => null
		},

		enableFilters: {
			type: Boolean,
			default: () => true
		},

		logs: {
			type: Array as PropType<LogItem[]>
		},

		enabledFilterIds: {
			type: Array as PropType<TerminalFilterId[]>
		},

		enableWrapping: Boolean
	},

	emits: ["setPipelineHistory", "toggleTerminalFilter", "toggle-wrap"],

	data: () => ({
		isWrapping: false,
		openDownloadMenu: false,
		showLogFilterOptions: false
	}),

	computed: {
		logFilterOptions() {
			const { enabledFilterIds } = this;

			if (!enabledFilterIds) {
				return [...LOG_FILTER_OPTIONS];
			}

			return LOG_FILTER_OPTIONS.map(filter => {
				return {
					...filter,
					isDisabled: !enabledFilterIds.includes(filter.id)
				};
			});
		}
	},

	methods: {
		toggleTerminalFilter(filter: (typeof LOG_FILTER_OPTIONS)[number]) {
			if (filter.isDisabled) {
				return;
			}

			if (filter.id === this.filterId) {
				this.$emit("toggleTerminalFilter", null);
			} else {
				this.$emit("toggleTerminalFilter", filter.id);
			}

			this.showLogFilterOptions = false;
		}
	}
});
</script>
