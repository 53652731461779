import {
	externalEventsService,
	webhookRequest,
	webhook,
	deleteWebhookRequest,
	getWebhookRequest,
	listWebhookRequest,
	listWebhookResponse,
	updateWebhookRequest
} from "@/protocol/externalEvents";
import ApiService, { EXTERNAL_EVENTS } from "@/services/api-service";

// CreateWebhook
//
// Creates webhook with details provided
// results in error if invalid action is provided for infra and deployment pipeline
// @returns the webhook object
// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks","body":"*"}}]
export const createWebhook: externalEventsService["createWebhook"] = async function (
	request: webhookRequest
) {
	const response = await ApiService.post<webhook>(
		`${EXTERNAL_EVENTS}/organizations/${request.orgId}/projects/${request.projectId}/webhooks`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// DeleteWebhook
//
// Deletes the webhook with the webhookId provided
// Responds as error if the webhook is not found
// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks/{webhookId=*}"}}]
export const deleteWebhook: externalEventsService["deleteWebhook"] = async function (
	request: deleteWebhookRequest
) {
	const response = await ApiService.delete<Record<string, never>>(
		`${EXTERNAL_EVENTS}/organizations/${request.orgId}/projects/${request.projectId}/webhooks/${request.webhookId}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// GetWebhook
//
// Fetches the webhook with the webhookId provided
// Responds as error if the webhook is not found
// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks/{webhookId=*}"}}]
export const getWebhook: externalEventsService["getWebhook"] = async function (
	request: getWebhookRequest
) {
	const response = await ApiService.get<webhook>(
		`${EXTERNAL_EVENTS}/organizations/${request.orgId}/projects/${request.projectId}/webhooks/${request.webhookId}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// ListWebhook
//
// By default, List the webhooks for the given project
// Another filter option is the query param entityId
// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks"}}]
export const listWebhook: externalEventsService["listWebhook"] = async function (
	request: listWebhookRequest
) {
	const response = await ApiService.get<listWebhookResponse>(
		`${EXTERNAL_EVENTS}/organizations/${request.orgId}/projects/${request.projectId}/webhooks${
			request.entityId ? `?entityId=${request.entityId}` : ""
		}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// UpdateWebhook
//
// Action and eventTrigger can be updated
// if there is no change in the event (push to pull OR pull to push)
// github webhook will not be updated.
// In case of change in event github webhook will be updated and
// updated reference will be stored
// e.g. If the event changes from pushToBranch to pushToTag, no need to
// update github webhook as push event is already registered.
// However if the event changes from pushToBranch to pullReq the github
// webhook will be updated from push event to pull_req event
//
// Responds as error if the webhook is not found
// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks/{webhookId=*}","body":"*"}}]
export const updateWebhook: externalEventsService["updateWebhook"] = async function (
	request: updateWebhookRequest
) {
	const response = await ApiService.put<webhook>(
		`${EXTERNAL_EVENTS}/organizations/${request.orgId}/projects/${request.projectId}/webhooks/${request.webhookId}`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
