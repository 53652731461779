<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		width="480px"
		max-height="80vh"
		data-qa-create-org-popover
	>
		<Header>
			<Typography data-qa-update-org-heading type="h4" color="dark"
				>Organization Overview</Typography
			>

			<Icon
				size="x-small"
				type="filled"
				name="i-close"
				data-qa-create-org-close-button
				@click="$emit('close')"
			/>
		</Header>
		<Container :padding="16" :gap="16" align="left top" direction="column">
			<Container :padding="0" :gap="12" align="left top">
				<Icon name="i-bulb" type="filled" state="warning" :effects="false" />
				<Typography type="p1-para" color="light"
					>Organization is the place where your teams can collaborate on multiple projects at once.
					<Typography
						type="p1-para"
						color="primary"
						:inline="true"
						:link="true"
						data-qa-create-org-learn-more-link
						@click="openDocsLink"
						>Learn more</Typography
					>
				</Typography>
			</Container>

			<f-form-builder
				data-qa-create-org-form
				:field.prop="formFields"
				:values.prop="formValues"
				@input="handleInput"
				@state-change="formState = $event.detail"
			/>
		</Container>
		<Footer>
			<Container direction="column" padding="0" :grow="1">
				<Container v-if="errorMessage">
					<Typography type="p2" color="error" data-qa-edit-dependency-error>{{
						errorMessage
					}}</Typography>
				</Container>
				<Button
					state="full"
					type="success"
					:loading="loading"
					:disabled="!isOrgModified || !formState?.isValid"
					data-qa-create-org-submit-btn
					@click="updateOrg"
					>SAVE CHANGES</Button
				>
			</Container>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { organization } from "@/protocol/identity";
import { MARKDOWN_HELPER_TOOLTIP } from "@/shared/constants";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import { getErrorMessage } from "@/utils";

import { orgStore } from "./org-store";

export default defineComponent({
	name: "EditOrg",

	components: {
		Icon,
		Typography,
		Container,
		Button,
		Footer,
		Header,
		Wrapper
	},

	props: {
		editedOrg: {
			type: Object as PropType<Org>
		}
	},

	emits: ["back", "close"],

	data() {
		return {
			loading: false,

			formValues: {
				name: this.editedOrg?.name ?? "",
				description: this.editedOrg?.description ?? "",
				__showDescription__: false
			} as FormFieldValues,

			formState: null as FormBuilderState | null,

			errorMessage: null as string | null
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					name: {
						type: "text",
						qaId: "name",
						label: { title: "Name" },
						placeholder: "Enter organization name",
						validationRules: applyEntityNameRules2(
							this.existingOrgNames,
							"This organization name is already taken."
						)
					},

					addDescription: {
						type: "button",
						label: "Add description",
						iconLeft: "i-plus",
						category: "transparent",
						size: "small",
						qaId: "addDescription",
						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: true };
						},

						showWhen(values) {
							const formValues = values as FormFieldValues;
							return !formValues.__showDescription__ && !formValues.description;
						}
					},

					__showDescription__: {
						type: "hidden"
					},

					description: {
						type: "textarea",
						label: { title: "Description", iconTooltip: MARKDOWN_HELPER_TOOLTIP },
						resizable: true,
						qaId: "description",
						placeholder: "Add a brief description about your organization",
						showWhen(values) {
							const formValues = values as FormFieldValues;
							return Boolean(
								(typeof formValues.description === "string" && formValues.description.length > 0) ||
									formValues.__showDescription__
							);
						}
					},

					removeDescription: {
						type: "button",
						qaId: "removeDescription",
						label: "Remove organization description",
						iconLeft: "i-close",
						category: "transparent",
						state: "danger",
						size: "small",
						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: false, description: "" };
						},

						showWhen(values) {
							const formValues = values as FormFieldValues;
							return Boolean(formValues.__showDescription__ || formValues.description);
						}
					}
				}
			};
		},

		existingOrgNames(): string[] {
			let orgNames = orgStore.orgs.map(org => org.name);
			const { editedOrg } = this;

			if (editedOrg) {
				orgNames = orgNames.filter(orgName => orgName !== editedOrg.name);
			}

			return orgNames;
		},

		isOrgModified() {
			const { editedOrg } = this;

			if (!editedOrg) {
				return false;
			}

			return (
				editedOrg.name !== this.formValues.name ||
				editedOrg.description !== this.formValues.description
			);
		}
	},

	methods: {
		async updateOrg() {
			if (!this.editedOrg) {
				return;
			}

			try {
				this.loading = true;

				await orgStore.UPDATE_ORG({
					id: this.editedOrg.id,
					name: this.formValues.name as string,
					description: this.formValues.description ?? "",
					metadata: {}
				});

				await orgStore.GET_SET_ORGS();

				this.$emit("close");
			} catch (err) {
				this.errorMessage = getErrorMessage(err, true);
			} finally {
				this.loading = false;
			}
		},

		handleInput(input: CustomEvent<FormFieldValues>) {
			const values = { ...input.detail };
			this.formValues = values;
		},

		openDocsLink() {
			window.open("https://docs.codepipes.io/docs/glossary#organization", "_blank");
		}
	}
});

type FormFieldValues = {
	name?: string | null;
	logo?: File | null;
	description?: string | null;
	__showDescription__: boolean;
};

type Org = organization & { formatedName: string; orgInitial: string };
</script>
