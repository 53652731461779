<template>
	<Container direction="column" :padding="0" :gap="0">
		<Container direction="column" :padding="0" :gap="0" overflow="auto" :grow="1" align="left top">
			<Container :padding="0" :gap="0" :shrink="0">
				<Slab
					id="details"
					size="large"
					:type="selectedTab === projectSettingsTabValuesObj.details ? 'filled' : 'transparent'"
					:effects="!selectedTab"
					data-qa-project-landing-details-tab
					class="cursor-pointer slab-primary-padding-0"
					:selected="selectedTab === projectSettingsTabValuesObj.details"
					@click="setActiveTab(projectSettingsTabValuesObj.details)"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-project-details-tab>
							<Icon name="i-home" type="filled" size="small" />
						</Container>
					</template>
					<Container :padding="0" :gap="4" direction="column">
						<Typography type="h4" weight="light" color="dark">Details</Typography>
					</Container>
				</Slab>
			</Container>
			<Divider />
			<Container v-if="isUserOrgAdmin" :padding="0" :gap="0" :shrink="0">
				<Slab
					id="credentials-proj"
					size="large"
					:type="selectedTab === projectSettingsTabValuesObj.credentials ? 'filled' : 'transparent'"
					:effects="!selectedTab"
					class="cursor-pointer slab-primary-padding-0"
					:selected="selectedTab === projectSettingsTabValuesObj.credentials"
					data-qa-project-landing-credential-tab
					@click="setActiveTab(projectSettingsTabValuesObj.credentials)"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-project-credential-tab>
							<Icon name="i-key" type="filled" size="small" />
						</Container>
					</template>
					<Container :padding="0" :gap="4" direction="column">
						<Typography type="h4" weight="light" color="dark">Credentials</Typography>
					</Container>
				</Slab>
			</Container>
			<Divider />
			<Container :padding="0" :gap="0" :shrink="0">
				<Slab
					id="classifications"
					size="large"
					:type="
						selectedTab === projectSettingsTabValuesObj.classifications ? 'filled' : 'transparent'
					"
					:effects="!selectedTab"
					class="cursor-pointer slab-primary-padding-0"
					:selected="selectedTab === projectSettingsTabValuesObj.classifications"
					data-qa-project-landing-classification-tab
					@click="setActiveTab(projectSettingsTabValuesObj.classifications)"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-project-classification-tab>
							<Icon name="i-env-shape" type="filled" size="small" />
						</Container>
					</template>
					<Container :padding="0" :gap="4" direction="column">
						<Typography type="h4" weight="light" color="dark"
							>Environment classifications</Typography
						>
					</Container>
				</Slab>
			</Container>
			<Divider />
			<Container :padding="0" :gap="0" :shrink="0">
				<Slab
					v-if="isUserOrgAdmin"
					id="members"
					size="large"
					:type="selectedTab === projectSettingsTabValuesObj.members ? 'filled' : 'transparent'"
					:effects="!selectedTab"
					class="cursor-pointer slab-primary-padding-0"
					data-qa-project-landing-members-tab
					:selected="selectedTab === projectSettingsTabValuesObj.members"
					@click="setActiveTab(projectSettingsTabValuesObj.members)"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-project-members-list-tab>
							<Icon name="i-user-double" type="filled" size="small" />
						</Container>
					</template>
					<Container
						:padding="0"
						:gap="32"
						direction="row"
						class="width-100-per"
						align="space-between center"
					>
						<Typography type="h4" weight="light" color="dark">Members</Typography>
					</Container>
				</Slab>
			</Container>
			<Divider />
			<KeepAlive>
				<component :is="onboardInfoComponent" :data-qa-project-dynamic-content="selectedTabId">
				</component>
			</KeepAlive>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Slab, Typography, Icon, Divider, Button } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { orgStore } from "@/modules/org/org-store";
import ProjectCredentialsPopover from "@/shared/components/ProjectOnboardPopover/ProjectCredentialsPopover.vue";
import ProjectDetailsPopover from "@/shared/components/ProjectOnboardPopover/ProjectDetailsPopover.vue";
import ProjectEnvClassificationPopover from "@/shared/components/ProjectOnboardPopover/ProjectEnvClassificationPopover.vue";
import ProjectMembersPopover from "@/shared/components/ProjectOnboardPopover/ProjectMembersPopover.vue";

import { ProjectSettingsTabName, ProjectSettingsTabValues } from "../project-types";

export default defineComponent({
	name: "SettingsTab",

	components: {
		Container,
		Slab,
		Typography,
		Icon,
		Divider,
		Button,
		ProjectDetailsPopover,
		ProjectCredentialsPopover,
		ProjectMembersPopover,
		ProjectEnvClassificationPopover
	},

	props: {
		selectedTab: {
			type: String as PropType<ProjectSettingsTabName>,
			required: true
		}
	},

	data: () => ({
		projectSettingsTabValuesObj: ProjectSettingsTabValues,
		onboardInfoComponent: "ProjectDetailsPopover"
	}),

	computed: {
		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		},

		selectedTabId() {
			return `active-tab-${this.selectedTab}`;
		}
	},

	methods: {
		setActiveTab(state: ProjectSettingsTabName | "closed") {
			switch (state) {
				case ProjectSettingsTabValues.credentials: {
					this.onboardInfoComponent = "ProjectCredentialsPopover";
					break;
				}
				case ProjectSettingsTabValues.members: {
					this.onboardInfoComponent = "ProjectMembersPopover";
					break;
				}

				case ProjectSettingsTabValues.classifications: {
					this.onboardInfoComponent = "ProjectEnvClassificationPopover";
					break;
				}
				default: {
					this.onboardInfoComponent = "ProjectDetailsPopover";
					break;
				}
			}
			this.$emit("select", state);
		}
	}
});
</script>
