<template>
	<PopOver :open="isOpen" placement="bottom-start" @overlay-click="closeDialog">
		<Icon
			name="i-info-fill"
			class="cursor-pointer"
			:effects="false"
			type="filled"
			size="small"
			state="light"
			data-qa-variable-info-icon
			@click="openDialog"
		/>
		<template #content>
			<Wrapper
				v-if="isOpen"
				class="variable-info-popover"
				border-radius="4px"
				background="element-light"
				:border="true"
				width="432px"
				max-height="300px"
			>
				<Tabs size="small">
					<Tab :selected="true" class="max-width-100">
						<Typography weight="medium" type="p2" color="dark">Variable</Typography>
					</Tab>

					<Container :padding="14" class="margin-lt-auto">
						<Pictogram shape="circle" size="m" @click="copyToClipboard">
							<Icon
								v-tooltip="{ content: 'Copy to clipboard' }"
								:name="valueCopied ? 'i-tick' : 'i-copy'"
								size="x-small"
								type="filled"
							/>
						</Pictogram>

						<Icon
							name="i-close"
							type="filled"
							state="dark"
							size="x-small"
							data-qa-var-info-popover-close-icon
							@click="closeDialog"
						/>
					</Container>
				</Tabs>

				<Container :padding="16" :grow="1" align="left top" direction="column">
					<Typography
						family="logs"
						type="p2"
						color="dark"
						:data-qa-var-col-key="variableColumn.key"
						>{{ variableColumn.key }}</Typography
					>

					<Container v-if="variableColumn.value" padding="0" overflow="scroll" align="left top">
						<Typography
							class="height-100-per"
							family="logs"
							type="p2-para"
							color="light"
							:data-qa-var-col-value="variableColumn.value"
						>
							{{ variableColumn.value }}
						</Typography>
					</Container>
					<Typography v-else family="logs" type="p2-para" color="light">
						Value is not defined

						<Button
							size="x-small"
							type="primary"
							state="text"
							:disabled="false"
							:full-width="false"
							@click="editVariable"
						>
							Define new value
						</Button>
					</Typography>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Icon,
	Pictogram,
	PopOver,
	Tab,
	Tabs,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { VariableColumn } from "./variable-list-types";

export default defineComponent({
	name: "VariableListVariableInfoPopOver",

	components: {
		Tabs,
		Button,
		Tab,
		PopOver,
		Icon,
		Pictogram,
		Container,
		Typography,
		Wrapper
	},

	props: {
		variableColumn: {
			type: Object as PropType<VariableColumn>,
			required: true
		},

		isOpen: {
			type: Boolean,
			required: true
		}
	},

	emits: ["edit-variable", "open", "close"],

	data() {
		return {
			valueCopied: false
		};
	},

	methods: {
		editVariable() {
			this.$emit("edit-variable");
		},

		openDialog() {
			this.$emit("open");
		},

		closeDialog() {
			this.$emit("close");
		},

		async copyToClipboard() {
			if (this.variableColumn.value) {
				await navigator.clipboard.writeText(this.variableColumn.value);
				this.valueCopied = true;
			}
		}
	}
});
</script>

<style lang="scss" scoped>
.variable-info-popover [data-qa-var-col-value] {
	white-space: pre;
}
</style>
