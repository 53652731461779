<template>
	<div class="code-promo-row" :class="{ header }">
		<slot />
	</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "CPRow",

	props: {
		header: {
			type: Boolean,
			default: () => null
		}
	}
});
</script>
