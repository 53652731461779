<template>
	<!-- Empty State With no dependencies -->
	<EmptyPolicyListDetails v-if="!isLoading && isEmpty && searchText === ''">
	</EmptyPolicyListDetails>

	<!-- policyset list content -->
	<Container
		v-else-if="!selectedPolicySet"
		:data-qa-policyset-tab-content-loaded="!isLoading"
		direction="column"
		padding="20px 0"
		:gap="20"
		:grow="1"
		overflow="visible"
		class="height-100-per"
		align="left top"
	>
		<Container
			data-qa-policyset-tab-header
			:padding="0"
			overflow="visible"
			align="space-between top"
			class="dashboard-input"
		>
			<Container :padding="10" :gap="12" overflow="visible">
				<Typography type="h3">Control gates</Typography>
			</Container>
			<Container :padding="10" :gap="12" class="dashboard-input">
				<f-button
					label="New control gate"
					icon-left="i-plus"
					state="primary"
					variant="round"
					size="small"
					@click="onCreateControlGate"
				></f-button>
			</Container>
		</Container>
		<Container v-if="!isLoading && isEmpty && searchText !== ''" :grow="1" align="center">
			<EmptyPolicyListDetails :from-search="true" />
		</Container>

		<Container v-else padding="10" overflow="scroll" :grow="1" align="left top">
			<Table border="rows" data-qa-policyset-list-table>
				<template #header>
					<TableRow>
						<TableCellHead padding="16px 12px" width="150px">
							<Container :padding="0" :gap="12" overflow="visible">
								<f-text variant="para" state="secondary" weight="bold" size="small"
									>Control gate</f-text
								>
							</Container>
						</TableCellHead>
						<TableCellHead padding="16px 12px" width="150px">
							<Container :padding="0" :gap="12" overflow="visible">
								<f-text variant="para" state="secondary" weight="bold" size="small"
									>Description</f-text
								>
							</Container>
						</TableCellHead>
						<TableCellHead padding="16px 12px" width="150px">
							<Container :padding="0" :gap="12" overflow="visible">
								<f-text variant="para" state="secondary" weight="bold" size="small"
									>Control sets</f-text
								>
							</Container>
						</TableCellHead>
						<TableCellHead padding="16px 12px" width="150px">
							<Container :padding="0" :gap="12" overflow="visible">
								<f-text variant="para" state="secondary" weight="bold" size="small"></f-text>
							</Container>
						</TableCellHead>
					</TableRow>
				</template>
				<template v-if="isLoading" #body>
					<LoadingRows v-for="r in 5" :key="r" :show-checkbox="false" />
				</template>
				<template v-else-if="sortedPolicySets.length > 0" #body>
					<TableRow
						v-for="policy in sortedPolicySets"
						:key="policy.id"
						:data-qa-policyset-row="policy.name"
						:blink="false"
						:clickable="true"
						@click.stop="openControlGateDetails(policy)"
					>
						<TableCell padding="10px 12px">
							<Container :padding="0" overflow="visible">
								<Typography type="p2" :data-qa-policyset-row-name="policy.name">{{
									policy.name
								}}</Typography>
							</Container>
						</TableCell>
						<TableCell padding="10px 12px">
							<Container :padding="0" :gap="12">
								<Typography type="p2" color="gray-200" data-qa-policyset-category>{{
									policy.description
								}}</Typography>
							</Container>
						</TableCell>
						<TableCell padding="10px 12px">
							<f-div gap="medium" padding="x-small" direction="row">
								<f-tag
									v-for="set in getImportSetsMeta(policy)"
									:key="set.id"
									:label="set.name"
									state="custom, #202a36"
									:data-qa-policy-control-set-name="set.name"
									style="border: 1px solid #394f6a; border-radius: 4px; color: #aebdd0 !important"
								></f-tag>
							</f-div>
						</TableCell>
						<TableCell padding="10px 12px">
							<Container :padding="0" :gap="12" align="center">
								<Typography type="p2" color="gray-200">
									<f-icon
										v-if="policy.orgId === '00000000-0000-0000-0000-000000000000'"
										source="i-info-fill"
										state="secondary"
										tooltip="This is a standard control gate provided
by Code Pipes. This cannot be edited."
										class="show-on-hover"
										:data-qa-public-policy-info="policy.name"
									></f-icon>
									<f-icon
										v-if="policy.orgId !== '00000000-0000-0000-0000-000000000000'"
										source="i-setting"
										state="primary"
										:data-qa-settings="policy.name"
										class="show-on-hover"
										:data-qa-private-policy-info="policy.name"
										@click.stop="openSettings(policy)"
									></f-icon>
									<CreateControlGatePopOver
										v-if="isEditMode && editPolicySet?.id === policy.id"
										:control-gate="editPolicySet"
										:is-edit-mode="isEditMode"
										@click.stop
										@close="
											isEditMode = false;
											editPolicySet = null;
										"
									/>
								</Typography>
							</Container>
						</TableCell>
					</TableRow>
				</template>
			</Table>
		</Container>

		<InfoPopover
			title="Imported configurations"
			:show-overlay="true"
			:is-open="showInfo"
			placement="right-start"
			target="policies"
			@close="hideInfoPopover"
		>
			<f-div key="sd" padding="none none medium none" height="hug-content" gap="medium">
				<f-text variant="para" size="medium" weight="regular">
					The imported configurations via a bundle will be saved under organization settings. These
					configurations will be inherited on all the projects. This makes it quick and secure to
					manage the organization’s deployment activities. Learn more about bundles
				</f-text>
			</f-div>
		</InfoPopover>
	</Container>

	<f-div v-else-if="selectedPolicySet && !isEditMode">
		<ControlGateDetails :control-gate="selectedPolicySet" @back="selectedPolicySet = null" />
	</f-div>

	<CreateControlGatePopOver
		v-if="isCreateOpen"
		:control-gate="selectedPolicySet"
		:is-edit-mode="isEditMode"
		@close="isCreateOpen = false"
	/>
</template>
<script lang="ts">
import {
	Container,
	Table,
	TableCell,
	TableCellHead,
	TableRow,
	Typography
} from "@cldcvr/flow-vue3";
import { orderBy } from "lodash-es";
import { defineComponent } from "vue";

import { PolicySet, policySetType } from "@/protocol/validator";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";

import LoadingRows from "../members/components/LoadingRows.vue";
import ControlGateDetails from "../policy-list/components/ControlGateDetails.vue";
import CreateControlGatePopOver from "../policy-list/components/CreateControlGatePopOver.vue";
import { policyListStore } from "../policy-list/policy-list-store";
import { userStore } from "../user/user-store";

import EmptyPolicyListDetails from "./EmptyPolicyListDetails.vue";

export default defineComponent({
	name: "PolicyListTabDetails",

	components: {
		TableCell,
		Container,
		EmptyPolicyListDetails,
		Typography,
		Table,
		TableRow,
		TableCellHead,
		LoadingRows,
		InfoPopover,
		ControlGateDetails,
		CreateControlGatePopOver
	},

	data: () => ({
		isLoading: false,
		showInfo: false,
		searchText: "",
		sortDirection: "asc" as "asc" | "desc",
		selectedPolicySet: null as PolicySet | null,
		isCreateOpen: false,
		isEditMode: false,
		editPolicySet: null as PolicySet | null
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		isEmpty() {
			return this.sortedPolicySets.length === 0;
		},

		policySets() {
			return policyListStore.policySets;
		},

		sortedPolicySets() {
			return orderBy([...this.controlGates], "name", this.sortDirection).filter(policy =>
				policy.name.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
			);
		},

		infoFlags() {
			return userStore.profile?.metadata?.infoFlags;
		},

		controlGates() {
			return this.policySets.filter(policy => policy.type === policySetType.setTypeControlGate);
		}
	},

	mounted() {
		this.isLoading = true;
		this.getPolicySets();
		if (!this.infoFlags?.policySets) {
			this.showInfo = true;
		}
	},

	methods: {
		async getPolicySets(): Promise<void> {
			await policyListStore.GET_POLICYSETS({
				orgId: this.orgId
			});
			this.isLoading = false;
		},

		hideInfoPopover() {
			this.showInfo = false;
			userStore.UPDATE_USER_META({
				infoFlags: {
					...this.infoFlags,
					policySets: true
				}
			});
		},

		onCreateControlGate() {
			this.isCreateOpen = true;
		},

		getImportSetsMeta(policy: PolicySet): PolicySet[] {
			const controlSets = this.policySets.filter(p => p.type === policySetType.setTypeControlSet);

			if (!policy.importSets) {
				return [];
			}

			// return the import sets by using policy.importSets
			return policy.importSets
				.map(setId => {
					return controlSets.find(s => s.id === setId) ?? null;
				})
				.filter(p => p !== null) as PolicySet[];
		},

		openControlGateDetails(policy: PolicySet) {
			this.selectedPolicySet = policy;
		},

		openSettings(policy: PolicySet) {
			this.isEditMode = true;
			this.editPolicySet = policy;
		}
	}
});
</script>
