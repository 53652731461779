<template>
	<PopOver :open="open" placement="left" @overlay-click="validateAndClosePopover">
		<Icon
			v-tooltip="{
				content: 'edit credential',
				placement: 'left'
			}"
			name="i-setting"
			size="small"
			type="filled"
			state="primary"
			:data-qa="'cred-edit-btn-' + cred.name"
			@click="showEditModel()"
		/>
		<template #content>
			<Wrapper border-radius="4px" background="element-light" width="450px">
				<component
					:is="credentialModalName"
					v-if="credentialModalName"
					:ref="cred.type"
					:cred="cred"
					:update-cred-list="true"
					@close-modal="showEditModel()"
				/>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { Icon, PopOver, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import AwsCredentialModel from "@/modules/credentials/components/credential-form/AwsCredentialModel.vue";
import AzureCredentialModel from "@/modules/credentials/components/credential-form/AzureCredentialModel.vue";
import DockerGenricCredentialModal from "@/modules/credentials/components/credential-form/DockerGenricCredentialModal.vue";
import DockerHubCredentialModal from "@/modules/credentials/components/credential-form/DockerHubCredentialModal.vue";
import GcpCredentialModel from "@/modules/credentials/components/credential-form/GcpCredentialModel.vue";
import GitGenericCredentialModal from "@/modules/credentials/components/credential-form/GitGenericCredentialModal.vue";
import GitLabCredentialModal from "@/modules/credentials/components/credential-form/GitLabCredentialModal.vue";
import { Creds, credsType } from "@/protocol/identity";

type Refs = {
	[credsType.credsType_aws]?: InstanceType<typeof AwsCredentialModel>;
	[credsType.credsType_azure]?: InstanceType<typeof AzureCredentialModel>;
	[credsType.credsType_dockergeneric]?: InstanceType<typeof DockerGenricCredentialModal>;
	[credsType.credsType_dockerhub]?: InstanceType<typeof DockerHubCredentialModal>;
	[credsType.credsType_gcp]?: InstanceType<typeof GcpCredentialModel>;
	[credsType.credsType_no_support]?: null;
	[credsType.credsType_github]?: null;
	[credsType.credsType_gitlab]?: InstanceType<typeof GitLabCredentialModal>;
	[credsType.credsType_codecommit]?: null;
	[credsType.credsType_gitgeneric]?: InstanceType<typeof GitGenericCredentialModal>;
	[credsType.credsType_acr]?: null;
};

export default defineComponent({
	name: "CommonEditCredentialModel",

	components: {
		DockerGenricCredentialModal,
		GitGenericCredentialModal,
		DockerHubCredentialModal,
		GitLabCredentialModal,
		AzureCredentialModel,
		AwsCredentialModel,
		GcpCredentialModel,
		Wrapper,
		PopOver,
		Icon
	},

	props: {
		cred: {
			type: Object as PropType<Creds>,
			required: true
		}
	},

	data: () => ({
		open: false,
		credentialModalNames: {
			[credsType.credsType_aws]: "AwsCredentialModel",
			[credsType.credsType_gcp]: "GcpCredentialModel",
			[credsType.credsType_azure]: "AzureCredentialModel",
			[credsType.credsType_dockergeneric]: "DockerGenricCredentialModal",
			[credsType.credsType_dockerhub]: "DockerHubCredentialModal",
			[credsType.credsType_gitgeneric]: "GitGenericCredentialModal",
			[credsType.credsType_gitlab]: "GitLabCredentialModal"
		} as Partial<Record<credsType, string>>
	}),

	computed: {
		credentialModalName() {
			return this.cred.type ? this.credentialModalNames[this.cred.type] : null;
		}
	},

	methods: {
		validateAndClosePopover() {
			const refs = this.$refs as Refs;
			const ref = this.cred.type ? refs[this.cred.type] : null;

			if (ref) {
				ref.validateAndCloseModal();
			}
		},

		showEditModel() {
			this.open = !this.open;
			this.$emit("modelOpen", this.open ? this.cred.id : null);
		}
	}
});
</script>
