<template>
	<AppVersion
		:color="statusColor"
		:notification-type="notificationType ?? undefined"
		data-qa-code-promo-app-card
	>
		<Container
			:padding="0"
			:gap="4"
			direction="column"
			align="left center"
			:grow="1"
			align-self="stretch"
		>
			<Typography
				type="h4"
				:color="status === 'canceling' || status === 'canceled' ? 'danger-200' : 'dark'"
				:data-qa-app-version="version"
				>{{ version }}</Typography
			>
			<Typography type="p2" color="light" :data-qa-code-promo-sub-title="subTitle">{{
				subTitle
			}}</Typography>
		</Container>

		<template #pipeline>
			<Container :padding="0" :gap="6" align="space-between center" overflow="visible">
				<Container
					v-if="status === 'canceling' || status === 'canceled'"
					:padding="0"
					:gap="9"
					overflow="visible"
					:data-qa-code-promo-app-card-job-cancel-cancelled="status"
					@click="openAppPipelineView(job)"
				>
					<ul
						class="pipeline"
						:data-collapsed="job?.jobStatus === 'done' || undefined"
						:data-expanded="job?.jobStatus !== 'done' || undefined"
					>
						<li>
							<Pictogram state="error" shape="circle" size="x-small" :effects="true">
								<Icon
									name="i-stop"
									state="black"
									size="x-small"
									:effects="false"
									data-qa-code-promo-app-card-cancel-icon
								/>
							</Pictogram>
						</li>
					</ul>
					<Typography
						type="p3"
						color="light"
						overflow="nowrap"
						:data-qa-code-promo-card-step-status-text="stepStatustext"
						>{{ stepStatustext }}</Typography
					>
				</Container>
				<Container v-else :padding="0" :gap="9" overflow="visible">
					<ul
						:data-collapsed="job?.jobStatus === 'done' || undefined"
						:data-expanded="job?.jobStatus !== 'done' || undefined"
						data-qa-code-promo-app-card-open-app-pipeline-view
						@click="openAppPipelineView(job)"
					>
						<li
							v-for="step in chartMetaData?.nodes"
							:key="step.name"
							:data-qa-code-promo-app-card-total-nodes="chartMetaData?.nodes.length ?? 0"
						>
							<Pictogram
								v-tooltip="{ content: 'View logs' }"
								v-bind="{ ...getPictoProps(step) }"
								shape="circle"
								size="x-small"
								:effects="true"
							>
								<Icon
									v-if="step.type === 'error' || step.type === 'warning'"
									name="i-alert"
									state="black"
									size="x-small"
									:effects="false"
									data-qa-code-promo-app-card-job-step-error-warning
								/>
								<template v-else>
									<Typography
										v-if="step.type === 'success'"
										type="h5"
										weight="bold"
										color="success-300"
										:data-qa-code-promo-app-card-job-step-success="step.nodeNumber"
										>{{ step.nodeNumber }}</Typography
									>
									<Typography
										v-if="step.type === 'standby' || step.type === 'inProgress'"
										type="h5"
										weight="bold"
										:data-qa-code-promo-app-card-step-standby-progress="step.nodeNumber"
										>{{ step.nodeNumber }}</Typography
									>
								</template>
							</Pictogram>
						</li>
					</ul>
					<Typography
						type="p3"
						color="light"
						overflow="nowrap"
						:data-qa-code-promo-app-card-stepStatustext="stepStatustext"
						>{{ stepStatustext }}</Typography
					>
				</Container>
			</Container>
		</template>
		<template v-if="status === 'error' || status === 'warning'" #notification>
			<Container :padding="6" :gap="6">
				<Icon name="i-alert" size="x-small" type="filled" :effects="false" state="warning" />
				<Typography
					v-tooltip="{ content: errorMsg }"
					type="p3"
					color="white"
					weight="regular"
					overflow="ellipsis"
					:data-qa-code-promo-error-msg="errorMsg"
				>
					{{ errorMsg }}</Typography
				>
			</Container>
		</template>
	</AppVersion>
</template>
<script lang="ts">
import {
	Container,
	Typography,
	Pictogram,
	Icon,
	PictogramBorderState,
	PictogramState
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { getAppDagMeta } from "@/modules/application-deployment/application-deployment-store";
import { AppDeployment, jobResponse } from "@/protocol/deployment";
import AppVersion from "@/shared/components/code-promotion/AppVersion.vue";
import {
	PipelineJobStatus,
	PIPELINE_UNFINISHED_JOB_STATUSES,
	PIPELINE_BAD_STATUSES,
	JOB_STEP_STATUS,
	PipelineNode
} from "@/shared/pipeline-constants";
import { getRelativeTime } from "@/utils";

export default defineComponent({
	name: "CodePromoAppCard",

	components: {
		AppVersion,
		Container,
		Typography,
		Pictogram,
		Icon
	},

	props: {
		job: {
			type: Object as PropType<jobResponse | null>,
			required: true
		},

		version: {
			type: String,
			required: true
		},

		deployment: {
			type: Object as PropType<AppDeployment>,
			required: true
		}
	},

	data: () => ({
		pipelineUnfinishedJobStatuses: PIPELINE_UNFINISHED_JOB_STATUSES,
		pipelineBadStatuses: PIPELINE_BAD_STATUSES,
		pipelineUnfinishedJobStatusText: JOB_STEP_STATUS
	}),

	computed: {
		pictogramStates(): Record<PipelineStatus, PictogramStates> {
			return {
				inProgress: {
					loading: true,
					borderState: "success",
					state: "light"
				},

				success: {
					borderState: "success",
					state: "light"
				},

				warning: {
					state: "warning"
				},

				canceling: {
					loading: true,
					borderState: "error",
					state: "error"
				},

				canceled: {
					state: "error"
				},

				standby: {
					state: "default"
				},

				waiting: {
					state: "default"
				},

				stalled: {
					state: "default"
				}
			};
		},

		chartMetaData() {
			return this.job && this.deployment.envId ? getAppDagMeta(this.job) : null;
		},

		errorMsg() {
			return this.job?.jobStatus === "failed" ? this.job.message : "";
		},

		status() {
			return this.job?.jobStatus;
		},

		jobUpdatedTime() {
			return this.job?.updatedAt ? this.getFormattedTime(this.job.updatedAt) : null;
		},

		subTitle() {
			if (this.status === "canceled") {
				return "promotion cancelled";
			}
			if (this.status === "canceling") {
				return "cancelling promotion...";
			}
			if (this.pipelineUnfinishedJobStatuses.includes(this.job?.jobStatus as PipelineJobStatus)) {
				return "Promotion in progress..";
			}
			if (this.status === "failed") {
				return "Promotion failed";
			}
			return `Promotion complete`;
		},

		notificationType() {
			return this.pipelineBadStatuses.includes(this.status as PipelineJobStatus) ? "warning" : null;
		},

		stepInProgressText() {
			return this.pipelineUnfinishedJobStatuses.includes(this.status as PipelineJobStatus)
				? `${this.pipelineUnfinishedJobStatusText[this.status as PipelineJobStatus]}...`
				: this.jobUpdatedTime;
		},

		stepStatustext() {
			return this.status === "failed" ? "App deployment failed" : this.stepInProgressText;
		},

		statusColor() {
			switch (this.status) {
				case "done":
					return "var(--dashboard-success)";
				case "failed":
					return "var(--dashboard-warning)";
				case "error":
					return "var(--dashboard-warning)";
				case "canceling":
					return "var(--dashboard-danger)";
				case "canceled":
					return "var(--dashboard-danger)";
				case "waiting":
					return "var(--dashboard-primary)";
				default:
					return "var(--dashboard-success)";
			}
		}
	},

	methods: {
		getPictoProps(step: PipelineNode) {
			return this.pictogramStates[step.type as PipelineStatus];
		},

		getFormattedTime(value: string) {
			return getRelativeTime(value);
		},

		openAppPipelineView(job: jobResponse | null) {
			if (
				job?.id &&
				this.deployment.orgId &&
				this.deployment.projId &&
				this.deployment.id &&
				this.deployment.envId
			) {
				this.$router.push({
					name: "stageViewAppWithJob",
					params: {
						orgId: this.deployment.orgId,
						projectId: this.deployment.projId,
						envId: this.deployment.envId,
						depId: this.deployment.id,
						action: job.jobType,
						jobId: job.id
					}
				});
			}
		}
	}
});

export type PipelineStatus =
	| "inProgress"
	| "success"
	| "warning"
	| "canceling"
	| "canceled"
	| "standby"
	| "waiting"
	| "stalled";

export type PromoPipeline = {
	status: PipelineStatus;
	tooltip?: string;
};

export type PictogramStates = {
	loading?: boolean;
	borderState?: PictogramBorderState;
	state: PictogramState;
};
</script>
