<template>
	<Table border="rows" data-qa-members-list-table>
		<template #header>
			<TableRow>
				<TableCellHead padding="16px 12px" width="">
					<Container :padding="0" :gap="12" overflow="visible">
						<Checkbox
							:indeterminate="isAnyChecked && !isAllChecked"
							:checked="isAllChecked"
							data-qa-toggle-all-members
							@input="toggleAll"
						/>
						<Typography type="p2" color="gray-300">Member</Typography>
						<Icon
							:name="
								sortColumn === 'member' && sortDirection === 'asc' ? 'i-arrow-up' : 'i-arrow-down'
							"
							:state="sortColumn === 'member' ? 'primary' : 'light'"
							color="gray-300"
							size="x-small"
							data-qa-sort-by-member-name
							@click="toggleSort('member')"
						/>
					</Container>
				</TableCellHead>
				<TableCellHead padding="16px 12px" width="150px">
					<Container :padding="0" :gap="12" overflow="visible">
						<Typography type="p2" color="gray-300">Role</Typography>
						<Icon
							:name="
								sortColumn === 'role' && sortDirection === 'asc' ? 'i-arrow-up' : 'i-arrow-down'
							"
							:state="sortColumn === 'role' ? 'primary' : 'light'"
							color="gray-300"
							size="x-small"
							data-qa-sort-by-member-role
							@click="toggleSort('role')"
						/>
					</Container>
				</TableCellHead>
				<TableCellHead padding="16px 12px">
					<Container :padding="0" :gap="12" overflow="visible">
						<Typography type="p2" color="gray-300">Last activity</Typography>
						<Icon
							:name="
								sortColumn === 'activity' && sortDirection === 'asc' ? 'i-arrow-up' : 'i-arrow-down'
							"
							:state="sortColumn === 'activity' ? 'primary' : 'light'"
							size="x-small"
							color="gray-300"
							data-qa-sort-by-member-activity
							@click="toggleSort('activity')"
						/>
					</Container>
				</TableCellHead>
				<TableCellHead padding="16px 12px">
					<Container :padding="0" :gap="12" overflow="visible">
						<Typography type="p2" color="gray-300">Membership status</Typography>
						<Icon
							:name="
								sortColumn === 'status' && sortDirection === 'asc' ? 'i-arrow-up' : 'i-arrow-down'
							"
							:state="sortColumn === 'status' ? 'primary' : 'light'"
							size="x-small"
							color="gray-300"
							data-qa-sort-by-member-status
							@click="toggleSort('status')"
						/>
					</Container>
				</TableCellHead>
				<TableCellHead padding="16px 12px" width="50px"> </TableCellHead>
			</TableRow>
		</template>
		<template v-if="loading" #body>
			<LoadingRows v-for="r in loadingRowCount" :key="r" />
		</template>
		<template v-else-if="sortedMembers.length > 0" #body>
			<MemberRow
				v-for="member in sortedMembers"
				:key="member.id"
				:loading-activity="loadingActivity"
				:member="member"
				:org="org"
				:project="project"
				@toggle="toggle"
			/>
		</template>
	</Table>
</template>
<script lang="ts">
import {
	Table,
	TableRow,
	TableCellHead,
	Container,
	Checkbox,
	Icon,
	Typography
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { organization, project as projectProto } from "@/protocol/identity";

import LoadingRows from "./LoadingRows.vue";
import { Member } from "./member-types";
import MemberRow from "./MemberRow.vue";

export default defineComponent({
	name: "MembersTable",

	components: {
		Table,
		TableRow,
		TableCellHead,
		Container,
		Checkbox,
		Icon,
		Typography,
		LoadingRows,
		MemberRow
	},

	props: {
		org: {
			type: Object as PropType<organization>
		},

		project: {
			type: Object as PropType<projectProto>
		},

		loading: {
			type: Boolean,
			default: () => null
		},

		loadingActivity: {
			type: Boolean,
			default: () => null
		},

		loadingRowCount: {
			type: Number,
			default: () => 5
		},

		members: {
			type: Array as PropType<Member[]>,
			required: true
		}
	},

	data: () => ({
		sortColumn: "member" as SortColumnName,
		sortDirection: "asc" as "asc" | "desc"
	}),

	computed: {
		isAllChecked() {
			return this.members.filter(member => !member.isReadOnly).every(m => m.selected);
		},

		isAnyChecked() {
			return this.members.filter(member => !member.isReadOnly).some(m => m.selected);
		},

		sortedMembers() {
			return [...this.members].sort((memberA, memberB) => {
				let sortValue = 0;
				if (this.sortColumn === "member") {
					sortValue = memberA.email?.localeCompare(memberB.email ?? "") ?? 0;
				} else if (this.sortColumn === "activity") {
					sortValue = (memberA.lastActivity?.dateTime ?? 0) - (memberB.lastActivity?.dateTime ?? 0);
				} else if (this.sortColumn === "role") {
					sortValue = memberA.role?.id.localeCompare(memberB.role?.id ?? "") ?? 0;
				} else {
					if (memberA.hasJoined && !memberB.hasJoined) {
						sortValue = -1;
					} else if (!memberA.hasJoined && memberB.hasJoined) {
						sortValue = 1;
					} else {
						sortValue =
							(memberA.membershipStatus?.dateTime ?? 0) - (memberB.membershipStatus?.dateTime ?? 0);
					}
				}

				return this.sortDirection === "asc" ? sortValue : -sortValue;
			});
		}
	},

	methods: {
		toggleAll() {
			this.$emit("toggle-all", !this.isAllChecked);
		},

		toggle(member: Member) {
			this.$emit("toggle-member", member);
		},

		toggleSort(column: SortColumnName, direction?: "asc" | "desc") {
			this.sortColumn = column;
			this.sortDirection = direction ?? (this.sortDirection === "asc" ? "desc" : "asc");
		}
	}
});

type SortColumnName = "member" | "role" | "activity" | "status";
</script>
