<template>
	<Wrapper max-height="80vh" data-qa-azure-cred>
		<!--START : Container-->
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			class="height-100-per flex-shrink-0"
		>
			<!--START : PopOver header-->
			<Header>
				<Container :padding="0" :grow="1" align="left">
					<Icon
						v-if="showBackBtn && currentStep === 'azure_portal'"
						name="i-arrow-left"
						size="small"
						data-qa-azure-cred-back-btn
						@click="goback()"
					/>
					<Icon
						v-if="currentStep === 'azure_devops'"
						name="i-arrow-left"
						type="filled"
						size="small"
						data-qa-azure-cred-back-step1-btn
						@click="currentStep = 'azure_portal'"
					/>
					<Typography type="h4" color="dark" data-qa-azure-cred-title>{{ headerTxt }} </Typography>
				</Container>

				<Container :padding="0" :grow="1" align="right center">
					<Tag size="small" data-qa-azure-cred-step>
						<Typography size="small" color="light" data-qa-azure-cred-step-txt
							>STEP {{ currentStep === "azure_portal" ? 1 : 2 }} / 2</Typography
						>
					</Tag>
					<Icon
						name="i-question-filled"
						type="filled"
						size="x-small"
						data-qa-azure-cred-model-help-btn
						@click="openHelp('https://docs.codepipes.io/docs/cloud-sources#azure')"
					/>
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-azure-cred-close-btn
						@click="validateAndCloseModal"
					/>
				</Container>
			</Header>
			<!--END : PopOver header-->

			<ClosePopoverConfirmationWarning
				ref="closeConfirmation"
				:initial-form-values="initialformValues"
				:form-values="azureCredFormValues"
				@force-close="closeModal"
			/>

			<ModalNotificationBanner
				v-if="cred"
				banner-type="primary"
				banner-body="Changes made are applicable to the future deployments."
			/>

			<ModalNotificationBanner
				v-if="submitError"
				banner-type="error"
				:show-close-icon="true"
				:banner-body="submitError"
				@close="submitError = null"
			/>

			<Container
				v-show="currentStep === 'azure_portal'"
				overflow="auto"
				align="stretch top"
				direction="column"
				:grow="1"
				:padding="16"
				:gap="24"
			>
				<Container
					:padding="0"
					:gap="12"
					direction="column"
					:grow="1"
					:shrink="0"
					align="center top"
					overflow="auto"
				>
					<Typography type="h5" weight="bold" color="dark" data-qa-azure-cred-azure-portal-title>
						Azure portal details
					</Typography>
					<Wrapper border-radius="8px" background="primary-dark">
						<Container padding="12px" :grow="1" overflow="auto">
							<Icon
								name="i-bulb"
								type="filled"
								state="warning"
								:effects="false"
								data-qa-azure-cred-azure-portal-icon
							/>
							<Typography type="p2-para" color="white" data-qa-azure-cred-azure-portal-content>
								Code Pipes needs the following
								<Typography type="p2-para" color="white" weight="bold" :inline="true" :link="true">
									Azure Portal details.
								</Typography>
								<Typography
									type="p2-para"
									color="primary"
									:link="true"
									data-qa-azure-cred-azure-portal-learn-more-link
									@click="openHelp('https://docs.codepipes.io/docs/cloud-sources#azure')"
								>
									Learn more
								</Typography>
							</Typography>
						</Container>
					</Wrapper>
				</Container>

				<f-form-builder
					ref="azurePortalForm"
					data-qa-azure-cred-portal-form
					:field.prop="azurePortalFields2"
					:values.prop="azureCredFormValues"
					@input="saveFormValues"
					@state-change="portalFormState = $event.detail"
				>
					<f-tooltip id="azure_subscriptionId_tooltip">
						<f-div
							gap="medium"
							width="hug-content"
							direction="column"
							data-qa-azure-cred-field-subId-tooltip
						>
							<f-text size="small"> 1. Login to the Azure Portal </f-text>
							<f-text size="small"> 2. From the Home page, click Subscriptions. </f-text>
							<f-text size="small">
								3. Copy the subscription ID of the subscription you want to add to Code Pipes.
							</f-text>
						</f-div>
					</f-tooltip>

					<f-tooltip id="clientId_info_tootip">
						<f-div width="hug-content" gap="medium" direction="column">
							<f-text size="small" inline data-qa-azure-cred-field-clientId-tooltip
								>1. Go to the
								<f-text size="small" weight="bold" inline>
									Azure Portal Home page > All Services > Azure Active Directory > App
									Registrations.
								</f-text>
							</f-text>
							<f-text size="small"
								>2. Select an existing application or register a new application (e.g.
								CodePipes).</f-text
							>
							<f-text size="small"
								>3. Copy the Application (client) ID of the newly registered application.</f-text
							>
							<f-text size="small">
								<f-text size="small" inline weight="bold">Note: </f-text> Once you have registered
								an Application, it is required to explicitly grant it permissions in a form of a
								Role assignment to work with the current Azure subscription.
							</f-text>
						</f-div>
					</f-tooltip>

					<f-tooltip id="tenantId_info_tootip">
						<f-div width="hug-content" gap="medium" direction="column">
							<f-text size="small" data-qa-azure-cred-field-tenantId-info-1
								>1. Go to the application in the Azure Active Directory (same as the above step).
							</f-text>
							<f-text size="small" data-qa-azure-cred-field-tenantId-info-2
								>2. Copy the Directory (tenant) ID.</f-text
							>
						</f-div>
					</f-tooltip>

					<f-tooltip id="clientSecret_info_tootip">
						<f-div width="hug-content" gap="medium" direction="column">
							<f-text size="small" data-qa-azure-cred-field-clientSecret-info-1
								>1. Go to the newly registered application > Certificates & Secrets > Create new
								client secret.
							</f-text>
							<f-text size="small" data-qa-azure-cred-field-clientSecret-info-2
								>2. Fill in the required information and click Add. The client secret is
								displayed.</f-text
							>
							<f-text size="small" data-qa-azure-cred-field-clientSecret-info-3
								>3. Copy the client secret from the Value column.</f-text
							>
						</f-div>
					</f-tooltip>
				</f-form-builder>
			</Container>

			<Container
				v-show="currentStep === 'azure_devops'"
				overflow="auto"
				align="center top"
				direction="column"
				:grow="1"
				:padding="16"
				data-qa-deployment-form
			>
				<Container direction="column" :grow="1" :padding="0" align="center top" overflow="auto">
					<Typography type="h5" weight="bold" color="dark" data-qa-azure-cred-azure-devops-title>
						Azure Devops Details
					</Typography>
					<Wrapper border-radius="8px" background="primary-dark">
						<Container
							padding="12px"
							:grow="1"
							overflow="auto"
							data-qa-azure-cred-azure-devops-content
						>
							<Icon name="i-bulb" type="filled" state="warning" :effects="false" />
							<Typography type="p2-para" data-qa-azure-cred-azure-devops-content-1>
								Code Pipes needs the following
								<Typography
									type="p2-para"
									weight="bold"
									:inline="true"
									:link="true"
									data-qa-azure-cred-azure-devops-content-2
								>
									Azure DevOps details.
								</Typography>
								<Typography
									type="p2-para"
									color="primary"
									link
									@click="openHelp('https://docs.codepipes.io/docs/cloud-sources#azure')"
								>
									Learn more
								</Typography>
							</Typography>
						</Container>
					</Wrapper>
				</Container>

				<f-form-builder
					ref="azureDevopsForm"
					data-qa-azure-cred-form
					:field.prop="azureDevOpsFields"
					:values.prop="azureCredFormValues"
					@input="saveFormValues"
					@state-change="devopsFormState = $event.detail"
				>
					<f-tooltip id="organizationName_info_tootip">
						<f-div
							gap="medium"
							width="hug-content"
							direction="column"
							data-qa-azure-cred-field-orgName-info
						>
							<f-text size="small" data-qa-azure-cred-field-orgName-info-1
								>1. Login to
								<f-text size="small" :inline="true" :link="true">Azure DevOps.</f-text>
							</f-text>
							<f-text size="small" data-qa-azure-cred-field-orgName-info-2
								>2. Copy & paste the organization name from the Home page.</f-text
							>
						</f-div>
					</f-tooltip>

					<f-tooltip id="pat_info_tootip">
						<f-div
							gap="medium"
							width="hug-content"
							direction="column"
							data-qa-azure-cred-field-pat-info
						>
							<f-text size="small"
								>1. Once you log in to the Azure DevOps, click on the User settings icon in the
								top-right.
							</f-text>
							<f-text size="small">
								2. Go to the
								<f-text size="small" weight="bold" :inline="true">Personal access tokens.</f-text>
							</f-text>
							<f-text size="small"
								>3. Generate the right token with all necessary permissions.</f-text
							>
							<f-text size="small">4. Copy & paste the PAT.</f-text>
						</f-div>
					</f-tooltip>

					<f-tooltip id="projectName_info_tootip">
						<f-div
							gap="medium"
							width="hug-content"
							direction="column"
							data-qa-azure-cred-field-projectName-info
						>
							<f-text size="small"
								>1. From within Azure DevOps, select the organization you specified above.
							</f-text>
							<f-text size="small"
								>2. Copy & paste the name of Azure DevOps project you want to use.</f-text
							>
						</f-div>
					</f-tooltip>
				</f-form-builder>
			</Container>

			<Footer>
				<Button
					v-if="currentStep === 'azure_portal'"
					state="full"
					:disabled="isSubmitting"
					:loading="isSubmitting"
					:type="isSubmitting ? 'default' : 'success'"
					data-qa-azure-cred-save-btn
					@click="nextStep"
					>NEXT — ADD AZURE DEVOPS DETAILS</Button
				>
				<Button
					v-else
					state="full"
					:disabled="isSubmitting"
					:loading="isSubmitting"
					:type="isSubmitting ? 'default' : 'success'"
					data-qa-azure-cred-save-btn
					@click="saveAzureCredentials"
					>{{ ctaBtnTxt }}</Button
				>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
/* eslint-disable max-lines */

import { FSelectOptionObject } from "@cldcvr/flow-core";
import {
	FFormBuilder,
	FormBuilderCustomValidationRule,
	FormBuilderField,
	FormBuilderState,
	html
} from "@cldcvr/flow-form-builder";
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	Tag,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import regions from "@/assets/azure_regions";
import { credentialStore } from "@/modules/credentials/credential-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { Creds, CredsCreate, credsType, CredsUpdate } from "@/protocol/identity";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import {
	captureError,
	getClassificationFormField,
	getClassificationFormValue,
	getErrorMessage
} from "@/utils";

const AZURE_REGIONS = regions.map((region): AzureRegionOption => {
	return {
		title: region.header,
		data: { id: region.id }
	};
});

export default defineComponent({
	name: "AzureCredentialModel",

	components: {
		ClosePopoverConfirmationWarning,
		ModalNotificationBanner,
		Typography,
		Container,
		Wrapper,
		Button,
		Footer,
		Header,
		Icon,
		Tag
	},

	props: {
		assignCred: {
			type: Boolean
		},

		showBackBtn: {
			type: Boolean
		},

		updateCredList: {
			type: Boolean
		},

		cred: {
			type: Object as PropType<Creds>
		},

		allowedClassificationIds: {
			type: Array as PropType<string[]>
		}
	},

	data() {
		let region: AzureRegionOption | undefined;
		if (this.cred?.azure?.region) {
			region = AZURE_REGIONS.find(_region => _region.data.id === this.cred?.azure?.region);
		}

		return {
			isSubmitting: false,
			submitError: null as string | null,
			existingCredNames: [] as string[],
			currentStep: "azure_portal" as "azure_portal" | "azure_devops",
			portalFormState: null as FormBuilderState | null,
			devopsFormState: null as FormBuilderState | null,
			azureCredFormValues: {
				region
			} as LocalAzureCredFormValues,

			initialformValues: {} as LocalAzureCredFormValues
		};
	},

	computed: {
		azurePortalFields2(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					subscriptionId: {
						type: "text",
						validationRules: [
							{ name: "required" },
							getIdRule("Please enter a valid Subscription ID")
						],

						label: { title: "Subscription ID", iconTooltip: "#azure_subscriptionId_tooltip" },
						id: "subscriptionId",
						placeholder: "Enter subscription ID",
						helperText: html`
							<f-text state="primary" size="small">
								<a
									data-qa-azure-cred-field-subId-help
									rel="noopener"
									target="_blank"
									href="https://portal.azure.com/#blade/Microsoft_Azure_Billing/SubscriptionsBlade"
								>
									Get it from the Azure Portal
								</a>
							</f-text>
						`
					},

					clientId: {
						type: "text",
						validationRules: [
							{ name: "required" },
							getIdRule("Please enter a valid Application (client) ID.")
						],

						label: { title: "Application (client) ID", iconTooltip: "#clientId_info_tootip" },
						id: "clientId",
						placeholder: "Enter application (client) ID",
						helperText: html`
							<f-text
								state="secondary"
								inline
								size="small"
								data-qa-azure-cred-field-clientId-help-1
							>
								Get it from the application registered under

								<f-text inline state="primary" size="small">
									<a
										data-qa-azure-cred-field-clientId-help-2
										rel="noopener"
										target="_blank"
										href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RegisteredApps"
									>
										Azure Active Directory
									</a>
								</f-text>
							</f-text>
						`
					},

					tenantId: {
						type: "text",
						validationRules: [
							{ name: "required" },
							getIdRule("Please enter a valid Directory (tenant) ID.")
						],

						label: { title: "Directory (tenant) ID", iconTooltip: "#tenantId_info_tootip" },
						id: "tenantId",
						placeholder: "Enter directory (tenant) ID",

						helperText: html`
							<f-text
								state="secondary"
								inline
								size="small"
								data-qa-azure-cred-field-tenantId-help-1
							>
								Get it from the application registered under

								<f-text inline state="primary" size="small">
									<a
										data-qa-azure-cred-field-tenantId-help-2
										rel="noopener"
										target="_blank"
										href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RegisteredApps"
									>
										Azure Active Directory
									</a>
								</f-text>
							</f-text>
						`
					},

					clientSecret: {
						type: "password",
						validationRules: [{ name: "required" }],
						label: { title: "Client secret", iconTooltip: "#clientSecret_info_tootip" },
						id: "clientSecret",
						placeholder: "Enter client secret",

						helperText: html` <f-text
							inline
							size="small"
							state="secondary"
							data-qa-azure-cred-field-clientSecret-help-1
						>
							Get it from the

							<f-text
								inline
								size="small"
								weight="bold"
								state="secondary"
								data-qa-azure-cred-field-clientSecret-help-2
							>
								Certificates & Secrets
							</f-text>

							menu of the application under

							<f-text inline state="primary" size="small">
								<a
									data-qa-azure-cred-field-clientSecret-help-3
									rel="noopener"
									target="_blank"
									href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RegisteredApps"
								>
									Azure Active Directory
								</a>
							</f-text>
						</f-text>`
					},

					resourceGroup: {
						type: "text",
						validationRules: [{ name: "required" }],
						label: { title: "Resource group" },
						id: "resourceGroup",
						placeholder: "Enter resource group",
						helperText: html`
							<f-text state="primary" size="small">
								<a
									data-qa-azure-cred-field-resourceGroup-help-1
									rel="noopener"
									target="_blank"
									href="https://portal.azure.com/#blade/Microsoft_Azure_Billing/SubscriptionsBlade"
								>
									Get it from the Azure Portal
								</a>
							</f-text>
						`
					},

					region: {
						type: "select",
						validationRules: [{ name: "required" }],
						label: { title: "Region" },
						options: AZURE_REGIONS,
						id: "region",
						helperText: "You can change the region later.",
						placeholder: "Select region"
					}
				}
			};
		},

		azureDevOpsFields(): FormBuilderField {
			// 		<template #pat_help>
			// 			<Container padding="0px 0px 16px 0px" data-qa-azure-cred-field-pat-help>
			// 				<Typography color="light" size="small"
			// 					>Get it from the
			// 					<Typography color="light" size="small" weight="bold" inline
			// 						>User settings</Typography
			// 					>
			// 					menu on the top-right in the Azure DevOps.</Typography
			// 				>
			// 			</Container>
			// 		</template>

			return {
				type: "object",
				direction: "vertical",
				fields: {
					organizationName: {
						type: "text",
						validationRules: [{ name: "required" }],
						label: { title: "Organization name", iconTooltip: "#organizationName_info_tootip" },
						id: "organizationName",
						placeholder: "Enter the Azure DevOps organization name",
						helperText: html` <f-text state="primary" size="small">
							<a
								data-qa-azure-cred-field-orgName-help
								rel="noopener"
								target="_blank"
								href="https://dev.azure.com/"
							>
								Get it from Azure DevOps
							</a>
						</f-text>`
					},

					pat: {
						type: "password",
						validationRules: [{ name: "required" }],
						label: {
							title: "Personal access token (PAT)",
							iconTooltip: "#pat_info_tootip"
						},

						id: "pat",
						placeholder: "Enter personal access token (PAT)",
						helperText: html` <f-text
							inline
							size="small"
							state="secondary"
							data-qa-azure-cred-field-pat-help
						>
							Get it from the

							<f-text
								inline
								size="small"
								weight="bold"
								state="secondary"
								data-qa-azure-cred-field-pat-help-2
							>
								User Settings
							</f-text>

							menu on the top-right in the Azure DevOps.
						</f-text>`
					},

					projectName: {
						type: "text",
						validationRules: [{ name: "required" }],
						label: { title: "Project name", iconTooltip: "#projectName_info_tootip" },
						id: "projectName",
						placeholder: "Enter the Azure DevOps project name",
						helperText: html` <f-text state="primary" size="small">
							<a
								data-qa-azure-cred-field-projectName-help
								rel="noopener"
								target="_blank"
								href="https://dev.azure.com/"
							>
								Get it from Azure DevOps
							</a>
						</f-text>`
					},

					__separator__: {
						type: "separator"
					},

					...getClassificationFormField(this.cred, this.allowedClassificationIds),

					name: {
						type: "text",
						validationRules: [
							...applyEntityNameRules2(this.existingCredNames, "This display name already exists.")
						],

						label: { title: "Display name" },
						id: "name",
						placeholder: "Enter display name",
						helperText: "This name will be used to refer to your saved credential on Code Pipes"
					}
				}
			};
		},

		orgId() {
			return this.$route.params.orgId as string;
		},

		headerTxt() {
			if (this.assignCred) {
				return "Assign credential";
			} else if (this.cred) {
				return "Edit Azure credential";
			}
			return "Add Azure credential";
		},

		ctaBtnTxt() {
			if (this.assignCred) {
				return "Assign credential";
			}
			return "Save Azure credential";
		}
	},

	mounted() {
		this.existingCredNames = credentialStore.existingCredentialNames.filter(_credName =>
			this.cred ? this.cred.name !== _credName : true
		);

		this.preFillEditFormValues();
	},

	methods: {
		preFillEditFormValues() {
			if (!this.cred) {
				this.initialformValues = this.azureCredFormValues;
				return;
			}

			let region: AzureRegionOption | undefined;
			if (this.cred.azure?.region) {
				region = AZURE_REGIONS.find(_region => _region.data.id === this.cred?.azure?.region);
			}

			const preFillEditFormValues: LocalAzureCredFormValues = {
				region,
				name: this.cred.name,
				clientId: this.cred.azure?.azureClientSecretOpts?.clientId,
				organizationName: this.cred.azure?.azureDevOpsOpts?.organizationName,
				projectName: this.cred.azure?.azureDevOpsOpts?.projectName,
				subscriptionId: this.cred.azure?.subscriptionId,
				tenantId: this.cred.azure?.tenantId,
				resourceGroup: this.cred.azure?.resourceGroup,
				...(this.cred.classificationId && getClassificationFormValue(this.cred.classificationId))
			};

			this.initialformValues = this.saveFormValues(
				new CustomEvent("input", { detail: preFillEditFormValues })
			);
		},

		// eslint-disable-next-line max-statements
		async saveAzureCredentials() {
			if (this.isSubmitting) {
				return null;
			}

			this.submitError = "";

			(this.$refs.azureDevopsForm as FFormBuilder).submit();

			if (!this.devopsFormState?.isValid) {
				return null;
			}

			this.isSubmitting = true;

			try {
				let cred: Creds | null = null;

				if (this.cred) {
					// form builder ensures that the form always have valid values.
					const updatePayload: CredsUpdate = {
						id: this.cred.id,
						name: this.azureCredFormValues.name!,
						orgId: this.orgId,
						type: credsType.credsType_azure,
						// API doesn't update the value of displayId and isNotSensitive from the request payload, so we
						// can sent empty values.
						displayId: "",
						isNotSensitive: false,
						azure: {
							tenantId: this.azureCredFormValues.tenantId!,
							subscriptionId: this.azureCredFormValues.subscriptionId!,
							region: this.azureCredFormValues.region!.data.id,
							resourceGroup: this.azureCredFormValues.resourceGroup!,
							azureDevOpsOpts: {
								organizationName: this.azureCredFormValues.organizationName!,
								projectName: this.azureCredFormValues.projectName!,
								pat: this.azureCredFormValues.pat!
							},

							azureClientSecretOpts: {
								clientId: this.azureCredFormValues.clientId!,
								clientSecret: this.azureCredFormValues.clientSecret!
							}
						}
					};

					await credentialStore.updateCredential({ cred: updatePayload });

					// Get latest cred object and update the store.
					credentialStore.GET_UPDATE_CREDENTIAL({ orgId: this.orgId, id: this.cred.id });
				} else {
					// form builder ensures that the form always have valid values.
					let createPayload: CredsCreate = {
						name: this.azureCredFormValues.name!,
						orgId: this.orgId,
						type: credsType.credsType_azure,
						azure: {
							tenantId: this.azureCredFormValues.tenantId!,
							subscriptionId: this.azureCredFormValues.subscriptionId!,
							region: this.azureCredFormValues.region!.data.id,
							resourceGroup: this.azureCredFormValues.resourceGroup!,
							azureDevOpsOpts: {
								organizationName: this.azureCredFormValues.organizationName!,
								projectName: this.azureCredFormValues.projectName!,
								pat: this.azureCredFormValues.pat!
							},

							azureClientSecretOpts: {
								clientId: this.azureCredFormValues.clientId!,
								clientSecret: this.azureCredFormValues.clientSecret!
							}
						}
					};
					if (this.azureCredFormValues.classification) {
						createPayload = {
							...createPayload,
							classificationId: this.azureCredFormValues.classification.data.id
						};
					}

					cred = await credentialStore.createCredential({ cred: createPayload });

					// Update the credential list
					if ("id" in cred) {
						credentialStore.UPDATE_CRED_AND_STATS_BY_ID(cred);
					}
				}

				if (this.updateCredList) {
					if (this.cred) {
						notificationsStore.ADD_TOAST({
							qaId: "toast-cloud-cred-gcp-edited-successfully",
							title: "Cloud credential updated",
							text: `Credential ${this.azureCredFormValues.name} is successfully updated.`,
							status: "success"
						});
					} else {
						notificationsStore.ADD_TOAST({
							qaId: "toast-cloud-cred-gcp-added-successfully",
							title: "New credential added",
							text: `New credential ${this.azureCredFormValues.name} is successfully added.`,
							status: "success"
						});
					}
					this.closeModal();
				}

				this.$emit("onCredCreate", cred);
			} catch (err) {
				this.submitError = getErrorMessage(err);
				captureError(err);
			}

			this.isSubmitting = false;
			return null;
		},

		saveFormValues(event: CustomEvent<LocalAzureCredFormValues>) {
			const values = event.detail;

			this.azureCredFormValues = {
				subscriptionId: values.subscriptionId ?? this.azureCredFormValues.subscriptionId,
				clientId: values.clientId ?? this.azureCredFormValues.clientId,
				tenantId: values.tenantId ?? this.azureCredFormValues.tenantId,
				clientSecret: values.clientSecret ?? this.azureCredFormValues.clientSecret,
				resourceGroup: values.resourceGroup ?? this.azureCredFormValues.resourceGroup,
				region: values.region ?? this.azureCredFormValues.region,
				organizationName: values.organizationName ?? this.azureCredFormValues.organizationName,
				pat: values.pat ?? this.azureCredFormValues.pat,
				projectName: values.projectName ?? this.azureCredFormValues.projectName,
				name: values.name ?? this.azureCredFormValues.name,
				classification: values.classification
			};

			return this.azureCredFormValues;
		},

		closeModal() {
			this.$emit("closeModal");
		},

		validateAndCloseModal() {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			if (!isFormTouched) {
				this.$emit("closeModal");
			}
			return;
		},

		goback() {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			if (!isFormTouched) {
				this.$emit("back");
			}
			return;
		},

		openHelp(link: string) {
			window.open(link, "_blank");
		},

		nextStep() {
			(this.$refs.azurePortalForm as FFormBuilder).submit();

			if (this.portalFormState?.isValid) {
				this.currentStep = "azure_devops";
			}
		}
	}
});

function getIdRule(message: string): FormBuilderCustomValidationRule {
	return {
		name: "custom",
		validate(value?: string | unknown[]): boolean {
			if (typeof value !== "string" || Array.isArray(value)) {
				return false;
			}

			return value
				? /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
				: false;
		},
		message
	};
}

type LocalAzureCredFormValues = {
	subscriptionId?: string;
	clientId?: string;
	tenantId?: string;
	clientSecret?: string;
	resourceGroup?: string;
	region?: AzureRegionOption;
	organizationName?: string;
	pat?: string;
	projectName?: string;
	name?: string;
	classification?: ClassificationType;
};

type ClassificationType = FSelectOptionObject & {
	data: { id: string; icon: string };
};

type AzureRegionOption = FSelectOptionObject & {
	data: { id: string };
};
</script>
