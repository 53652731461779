<template>
	<Container
		direction="column"
		padding="20px 0"
		:gap="20"
		:grow="1"
		overflow="visible"
		class="height-100-per"
		align="left top"
	>
		<!-- Header -->
		<Container
			data-qa-inputs-tab-header
			:padding="0"
			overflow="visible"
			align="space-between top"
			class="dashboard-input"
		>
			<Container :padding="0" :gap="12" overflow="visible">
				<Typography type="h4" weight="bold" color="gray-200" transform="capitalize">{{
					selectedTab
				}}</Typography>
			</Container>
			<Container :padding="0" :gap="12" class="dashboard-input">
				<SearchInput
					v-model:value="searchString"
					data-qa-inputs-tab-search
					placeholder="Search variable names"
					class="width-250"
					size="small"
				/>
			</Container>
		</Container>
		<!-- Body -->
		<EmptyInputOutput
			v-if="isEmpty && searchString === ''"
			:from-search="false"
			:selected-tab="selectedTab"
			data-qa-empty-input-output
		/>
		<EmptyInputOutput
			v-else-if="isEmpty && searchString !== ''"
			:from-search="true"
			:selected-tab="selectedTab"
			data-qa-empty-input-output-search
		/>
		<Container
			v-else
			direction="column"
			padding="0px 0px 50px 0px"
			align="left top"
			:grow="1"
			:gap="36"
			overflow="auto"
		>
			<Table border="rows" data-qa-dependency-input-output-table>
				<template #header>
					<TableRow>
						<TableCellHead padding="16px 12px" width="250px" color="gray-300">
							<Container :padding="0" :gap="12" overflow="visible">
								<Typography type="p2" color="gray-300">Variable name</Typography>
							</Container>
						</TableCellHead>
						<TableCellHead padding="16px 12px">
							<Container :padding="0" :gap="12" overflow="visible">
								<Typography type="p2" color="gray-300">Description</Typography>
							</Container>
						</TableCellHead>
					</TableRow>
				</template>
				<template #body>
					<TableRow v-for="(item, key) in filteredKeyValues" :key="item">
						<TableCell v-tooltip="{ content: key }" padding="12px 12px">
							<Typography type="p2">{{ key }}</Typography>
						</TableCell>
						<TableCell padding="12px 12px">
							<Typography type="p2" color="gray-300">{{ item }}</Typography>
						</TableCell>
					</TableRow>
				</template>
			</Table>
		</Container>
	</Container>
</template>
<script lang="ts">
import {
	Container,
	SearchInput,
	Table,
	TableCell,
	TableCellHead,
	TableRow,
	Typography
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { Dependency } from "@/protocol/infra";

import EmptyInputOutput from "./EmptyInputOutput.vue";

export default defineComponent({
	name: "DependencyInputOutput",

	components: {
		Container,
		Typography,
		SearchInput,
		TableCellHead,
		Table,
		TableRow,
		TableCell,
		EmptyInputOutput
	},

	props: {
		selectedTab: {
			type: String,
			required: true
		},

		dependency: {
			type: Object as PropType<Dependency>,
			required: true
		}
	},

	data: () => ({
		searchString: ""
	}),

	computed: {
		keyValuePairs() {
			return this.selectedTab === "inputs" ? this.dependency.inputs : this.dependency.outputs;
		},

		filteredKeyValues() {
			if (this.searchString === "") {
				return this.keyValuePairs;
			} else {
				const filteredObj: Record<string, any> = {};

				Object.keys(this.keyValuePairs).forEach(k => {
					if (k.toLowerCase().includes(this.searchString.toLowerCase())) {
						filteredObj[k] = this.keyValuePairs[k];
					}
				});
				return filteredObj;
			}
		},

		isEmpty() {
			return Object.keys(this.filteredKeyValues).length === 0;
		}
	}
});
</script>
