<template>
	<Wrapper height="100%">
		<Container
			type="grid"
			overflow="auto"
			padding="16px 0px 0px 0px"
			:gap="16"
			:grow="1"
			item-size="minmax(408px,1fr) 384px"
		>
			<!-- env widget grid -->
			<EnvWidget v-for="env in envs" :key="env.id" :env="env" :project="project" />

			<!-- Crate new env card -->
			<Card
				v-if="!containsNoEnvSets && isUserOrgAdmin"
				type="placeholder"
				:clickable="true"
				@click="createNewEnvironment"
			>
				<template #header>
					<Container :gap="12">
						<Pictogram shape="hexagon" size="l">
							<Icon name="i-plus" type="filled" size="18px" :effects="false" />
						</Pictogram>
						<Typography type="h3" color="dark">Create new environment</Typography>
					</Container>
				</template>
				<Container
					align="center"
					direction="column"
					:gap="12"
					class="height-100-per align-items-center"
				>
					<Pictogram shape="hexagon" state="primary" size="xl" :animate="false">
						<Icon
							name="i-plus"
							class="cursor-pointer"
							type="filled"
							state="root"
							:effects="false"
							data-qa-wid-env-create-new-env-btn
						/>
					</Pictogram>
					<Typography type="p1-para" color="light" weight="light">
						Start with creating environment
					</Typography>
				</Container>
			</Card>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import { Card, Container, Icon, Pictogram, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { orgStore } from "@/modules/org/org-store";
import { project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";

import EnvWidget from "./EnvWidget.vue";

export default defineComponent({
	name: "EnvWidgetGrid",
	components: { Container, EnvWidget, Wrapper, Pictogram, Icon, Typography, Card },

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		},

		createNewEnvironment: {
			type: Function as PropType<() => void>,
			required: true
		},

		envs: {
			type: Array as PropType<environment[]>,
			required: true
		}
	},

	computed: {
		// Only org admins can create manual environments
		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		},

		containsNoEnvSets() {
			return this.envs.some(env => env.classification?.id);
		}
	}
});
</script>
