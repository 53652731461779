export const AUTH_IDENTITY_PROVIDER = Object.freeze({
	GOOGLE: {
		idpName: "google",
		icon: "p-google"
	},
	GITHUB: {
		idpName: "github",
		icon: "p-github"
	},
	AZURE: {
		idpName: "azuread",
		icon: "p-azure"
	}
});

export type AuthIdentityProviderType =
	(typeof AUTH_IDENTITY_PROVIDER)[keyof typeof AUTH_IDENTITY_PROVIDER];

export const EXCHANGE_IDENTITY_TOKEN_WITH_USER = "exchangeIdentityProviderTokenWithUser";
export const AUTH_IDENTITY_PROVIDER_REDIRECT_ROUTE = "/login";
