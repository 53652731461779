<template>
	<Container direction="column" :padding="0" :gap="0" align="left top" :shrink="0">
		<Accordion :open="isAccordionOpen">
			<template #header>
				<Container
					align="space-between center"
					overflow="visible"
					padding="20px 8px"
					:clickable="!isAccordionOpen"
					@click="isAccordionOpen = !isAccordionOpen"
				>
					<Container :padding="0" :gap="8">
						<Icon name="i-box" size="small" :effects="false" color="gray-200" />
						<Typography type="h4" weight="medium" color="dark">Integration pipelines</Typography>

						<PopOver :open="false" class="display-flex" @click.stop="() => {}">
							<Button
								v-tooltip="{
									content: 'Add new integration pipeline'
								}"
								state="icon"
								size="x-small"
								data-qa-add-new-integration-pipeline
								@click.stop="navigateAddIntegrationPipeline"
							>
								<Icon name="i-plus" color="gray-600" size="x-small" :effects="false" />
							</Button>
							<template #content>
								<!-- CONTENT -->
							</template>
						</PopOver>
					</Container>
					<Icon
						name="i-chevron-down"
						size="x-small"
						:rotate="isAccordionOpen ? 180 : 0"
						data-qa-integration-pipeline-chevron-icon
						@click.stop="isAccordionOpen = !isAccordionOpen"
					/>
				</Container>
			</template>
			<Container
				v-if="!hasIntegrationPipelines"
				type="grid"
				overflow="auto"
				padding="16px 0px 12px 0px"
				:grow="1"
				direction="column"
				class="align-items-center"
				item-size="minmax(200px,1fr) 70px"
			>
				<!-- Crate new env card -->
				<Card
					v-tooltip="{
						content: 'Add new integration pipeline'
					}"
					type="placeholder"
					:clickable="true"
					data-qa-no-integrations-add-new-card
					@click.stop="navigateAddIntegrationPipeline"
				>
					<Container
						align="center"
						direction="row"
						class="align-items-center height-100-per"
						:gap="12"
						:grow="1"
					>
						<Button state="icon" size="small">
							<Icon name="i-plus" color="gray-600" size="small" :effects="false" />
						</Button>
						<Typography type="p1-para" color="light" weight="light">
							Add new integration pipeline
						</Typography>
					</Container>
				</Card>
			</Container>
			<Container
				v-for="artifactId in Object.keys(appIntegrations)"
				:key="artifactId"
				direction="column"
				overflow="auto"
				padding="0px 0px 16px 0px"
				:grow="1"
			>
				<template v-for="integration in appIntegrations[artifactId]" :key="integration.id">
					<IntegrationsPipelineCard
						v-if="appIntegrations[artifactId]"
						:app="app"
						:project="project"
						data-qa-integration-pipeline-widget
						:integration="integration"
					/>
				</template>
			</Container>
		</Accordion>
	</Container>
</template>
<script lang="ts">
import { Accordion, Button, Card, Container, Icon, PopOver, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import {
	applicationIntegrationStore,
	getSortedIntegrationJobList
} from "@/modules/application-integration/application-integration-store";
import { appIntegration, app as appProto } from "@/protocol/app";
import { project as projectProto } from "@/protocol/identity";

import IntegrationsPipelineCard from "./IntegrationsPipelineCard.vue";

export default defineComponent({
	name: "ApplicationsTabIntegrations",

	components: {
		Accordion,
		Button,
		Container,
		Icon,
		PopOver,
		Typography,
		Card,
		IntegrationsPipelineCard
	},

	props: {
		project: {
			type: Object as PropType<projectProto>,
			required: true
		},

		app: {
			type: Object as PropType<appProto>,
			required: true
		}
	},

	data: () => ({
		isAccordionOpen: true
	}),

	computed: {
		hasIntegrationPipelines() {
			return Object.keys(this.appIntegrations).length > 0;
		},

		appIntegrations() {
			const integrations = applicationIntegrationStore.integrations[this.app.id];

			const integrationMap: Record<string, appIntegration[]> = {};

			integrations?.forEach(integration => {
				const artifactId = integration.inputAppArtifact?.id;
				if (!artifactId) {
					return;
				}

				if (!integrationMap[artifactId]) {
					integrationMap[artifactId] = [integration];
				} else {
					integrationMap[artifactId]?.push(integration);
				}
			});

			return integrationMap;
		}
	},

	watch: {
		"app.id": {
			immediate: true,

			async handler() {
				const integrations = await applicationIntegrationStore.FETCH_INTEGRATIONS({
					appId: this.app.id,
					orgId: this.app.orgId,
					projId: this.app.projId
				});

				if (!integrations) {
					return;
				}

				await Promise.all(
					integrations.map(integration =>
						applicationIntegrationStore.FETCH_INTEGRATION_JOBS({
							appId: this.app.id,
							orgId: this.app.orgId,
							projId: this.app.projId,
							integrationId: integration.id
						})
					)
				);

				await Promise.all(
					integrations.map(integration => {
						const [firstJob] = getSortedIntegrationJobList(integration.id);
						if (!firstJob) {
							return [];
						}
						return applicationIntegrationStore.FETCH_INTEGRATION_JOB({
							orgId: this.app.orgId,
							projId: this.app.projId,
							appId: this.app.id,
							integrationId: integration.id,
							id: firstJob.id
						});
					})
				);
			}
		}
	},

	methods: {
		navigateAddIntegrationPipeline() {
			applicationIntegrationStore.SET_CURR_INTEGRATION_APPID(this.app.id);
			this.$router.push({
				name: "addIntegrationView",
				params: {
					orgId: this.app.orgId,
					projId: this.app.projId,
					appId: this.app.id,
					integrationId: "create"
				}
			});
		}
	}
});
</script>
