<template>
	<AuthWrapper
		header="Success! Check your email"
		:show-back-icon="true"
		@back-click="redirectMixin({ routeName: 'verification-email' })"
	>
		<Section padding="horizontal">
			<p class="fc-normal paragraph-1">We've sent you a verification email.</p>
			<p class="fc-normal paragraph-1 margin-tp-12">
				Click on the link in your email to verify your Code Pipes account, if you can't find the
				email check your spam folder or resend the verification email
			</p>
		</Section>
		<template #alt-auth>
			<Section padding="vertical" class="center-of-container">
				<div class="center-of-container margin-tp-12 flex-gap-12 width-80-per">
					<Button
						type="primary"
						state="outlined"
						:full-width="true"
						data-qa-resend-verification
						@click="redirectMixin({ routeName: 'verification-email' })"
					>
						Resend Verification
					</Button>
				</div>
			</Section>
		</template>
	</AuthWrapper>
</template>

<script>
import { Section, Button } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import AuthWrapper from "@/modules/auth/views/AuthWrapper.vue";
import routerMixin from "@/shared/mixins/routerMixin";

export default defineComponent({
	name: "VerificationEmailSuccess",
	components: { Section, AuthWrapper, Button },
	mixins: [routerMixin]
});
</script>
