<template>
	<Toast
		:id="toast.id"
		:key="toast.id"
		:data-qa-toast="toast.qaId"
		:auto-remove="toast.autoDismiss"
		:duration="toast.duration ?? 5"
		position="relative"
		@remove="remove(toast)"
		@removed="removed(toast)"
	>
		<Container
			:padding="0"
			:shrink="1"
			:grow="1"
			:clickable="true"
			:gap="0"
			direction="column"
			:class="getToasterClass(toast)"
		>
			<Container :padding="16" align="left top" :gap="8" direction="column">
				<Container :padding="0" align="space-between center" overflow="visible">
					<Typography
						type="h4"
						weight="bold"
						:data-qa-toast-title="toast.title"
						:color="`${getToasterColor(toast)}`"
						>{{ toast.title }}</Typography
					>
				</Container>
				<Container :padding="0" align="space-between center">
					<Typography
						type="p2-para"
						weight="thin"
						color="gray-100"
						:data-qa-toast-text="toast.text ?? ''"
						>{{ toast.text }}</Typography
					>
					<NotificationActivityEquation v-if="toast.equation" :equation="toast.equation" />
				</Container>
				<Container
					v-if="toast.subText"
					align="space-between center"
					:padding="0"
					:data-qa-toast-subtext="toast.subText ?? ''"
				>
					<Typography type="p2-para" weight="thin" color="light">{{ toast.subText }}</Typography>
				</Container>
				<Container
					v-if="toast.actions && toast.actions.length > 0"
					align="left center"
					padding="4px 0px 0px 0px"
				>
					<Button
						v-for="action in toast.actions"
						:key="action.qaId"
						:data-qa-toast-action="action.qaId"
						size="small"
						state="outlined"
						:type="action.actionType || 'default'"
						@click="action.onAction"
						>{{ action.text }}</Button
					>
				</Container>
			</Container>
		</Container>
	</Toast>
</template>
<script lang="ts">
import { Button, Container, Toast, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { ToastItem } from "./../notification-types";
import NotificationActivityEquation from "./NotificationActivityEquation.vue";

export default defineComponent({
	name: "Notification",

	components: {
		Button,
		Container,
		Toast,
		Typography,
		NotificationActivityEquation
	},

	props: {
		toast: {
			type: Object as PropType<ToastItem>,
			required: true
		}
	},

	emits: ["remove", "removed"],

	methods: {
		getToasterClass(toast: ToastItem) {
			switch (toast.status) {
				case "success":
					return "alert success-alert";
				case "error":
					return "alert error-alert";
				case "warning":
					return "alert warning-alert";
				default:
					return "";
			}
		},

		getToasterColor(toast: ToastItem) {
			switch (toast.status) {
				case "success":
				case "error":
				case "warning":
					return toast.status;
				default:
					return "dark";
			}
		},

		remove(toast: ToastItem) {
			this.$emit("remove", toast);
		},

		removed(toast: ToastItem) {
			this.$emit("removed", toast);
		}
	}
});
</script>
