<template>
	<Wrapper height="300px" overflow="scroll" class="flow-add-scrollbar" data-qa-org-entities>
		<Accordion
			v-for="project in orgProjects"
			:key="project.id"
			:open="accordionOpen[project.id]"
			:data-qa-org-project-name="project.name"
		>
			<template #header>
				<Slab overflow="visible" :effects="projectHasEntities(project)">
					<template #primary-action>
						<Pictogram state="avatar" :label="getShortCode(project.name)" />
					</template>

					<template #secondary-actions>
						<PopOver :open="deletedProject === project" @overlay-click="deletedProject = null">
							<Icon
								v-tooltip="{
									content: 'Delete project'
								}"
								name="i-delete"
								type="filled"
								state="light"
								size="small"
								data-qa-trigger-toggle-remove-project-modal
								@click="() => (deletedProject = project)"
							/>
							<template #content>
								<ProjectRemovePopover
									:project="project"
									:should-navigate-on-delete="false"
									:close-project-delete-popover="() => (deletedProject = null)"
								/>
							</template>
						</PopOver>

						<Icon
							v-if="projectHasEntities(project)"
							name="i-chevron-down"
							type="filled"
							size="small"
							:rotate="accordionOpen[project.id] ? 180 : 0"
							data-qa-expand-project
							@click="toggleAccordian(project.id)"
						/>
					</template>

					<Typography color="dark">{{ project.name }}</Typography>
				</Slab>
			</template>

			<Container direction="column" padding="16px 16px 16px 30px" :gap="16">
				<Container
					v-for="(env, idx) in environments[project.id]"
					:key="env.id"
					direction="column"
					:padding="0"
					:gap="20"
					:data-qa-org-env-name="env.name"
				>
					<!-- All environments -->
					<template v-if="idx === 0">
						<Typography type="h5" color="dark" transform="uppercase">Environments</Typography>
						<Divider />
					</template>

					<Slab :no-padding="true" overflow="visible">
						<template #primary-action>
							<Pictogram shape="hexagon" state="avatar" :label="getShortCode(env.name)" />
						</template>
						<Container padding="0px 0px 0px 12px">
							<Typography color="dark">{{ env.name }}</Typography>
						</Container>
					</Slab>
				</Container>

				<Container
					v-for="(app, idx) in apps[project.id]"
					:key="app.id"
					direction="column"
					padding="12px 0 0 0"
					:gap="20"
					:data-qa-org-app-name="app.name"
				>
					<!-- All applications -->
					<template v-if="idx === 0">
						<Typography type="h5" color="dark" transform="uppercase">Applications</Typography>
						<Divider />
					</template>

					<Slab :key="app.id" :no-padding="true" overflow="visible">
						<template #primary-action>
							<Pictogram shape="squircle" state="avatar" :label="getShortCode(app.name)" />
						</template>
						<Container padding="0px 0px 0px 12px">
							<Typography color="dark">{{ app.name }}</Typography>
						</Container>
					</Slab>
				</Container>
			</Container>
		</Accordion>

		<Slab
			v-if="totalCreds.length > 0"
			overflow="visible"
			:effects="true"
			data-qa-open-org-credentials
			@click="openCredentials"
		>
			<template #primary-action>
				<Pictogram>
					<Icon name="i-key" size="medium" type="filled" state="primary" />
				</Pictogram>
			</template>
			<template #secondary-actions>
				<Icon name="i-view" size="small" type="filled" state="primary" />
			</template>

			<Container padding="0px 0px 0px 12px">
				<Typography color="dark">{{ totalCreds.length }} credentials</Typography>
			</Container>
		</Slab>
	</Wrapper>
</template>

<script lang="ts">
import {
	Accordion,
	Container,
	Divider,
	Icon,
	Pictogram,
	PopOver,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { credentialStore } from "@/modules/credentials/credential-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { projectStore } from "@/modules/project-list/project-store";
import ProjectRemovePopover from "@/modules/project-remove/components/ProjectRemovePopover.vue";
import { project } from "@/protocol/identity";
import { getShortCode } from "@/utils";

import { orgStore } from "../../org-store";

export default defineComponent({
	name: "OrgDeleteEntities",

	components: {
		Typography,
		PopOver,
		Wrapper,
		Container,
		Icon,
		Divider,
		Accordion,
		Slab,
		ProjectRemovePopover,
		Pictogram
	},

	data() {
		return {
			accordionOpen: {} as Record<string, boolean>,
			deletedProject: null as project | null
		};
	},

	computed: {
		orgProjects() {
			return Object.values(projectStore.projects);
		},

		environments() {
			return envListStore.envs;
		},

		apps() {
			return applicationStore.projectApps;
		},

		totalCreds() {
			return Object.values(credentialStore.genericCredentials);
		}
	},

	created() {
		this.orgProjects.forEach(orgProject => {
			envListStore.GET_ENVS({
				orgId: orgProject.orgId,
				projectId: orgProject.id
			});

			applicationStore.FETCH_APPS_IN_PROJECT({
				orgId: orgProject.orgId,
				projectId: orgProject.id
			});
		});
	},

	methods: {
		getShortCode,

		toggleAccordian(id: string) {
			this.accordionOpen[id] = !this.accordionOpen[id];
		},

		openCredentials() {
			this.$router
				.push({ name: "credentials-global", params: { orgId: orgStore.activeOrgId! } })
				.catch(err => {
					throw new Error(err);
				});
		},

		projectHasEntities(proj: project) {
			const envs = this.environments[proj.id];
			const apps = this.apps[proj.id];
			return (envs && envs.length > 0) ?? (apps && apps.length > 0);
		}
	}
});
</script>
