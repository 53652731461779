<template>
	<PopOver :open="isOpen" placement="bottom-start" @overlay-click="closeDialog">
		<Icon
			name="i-edit"
			class="cursor-pointer"
			:effects="false"
			type="filled"
			size="small"
			state="light"
			data-qa-variable-edit-icon
			@click="openDialog"
		/>
		<template #content>
			<Wrapper
				v-if="isOpen"
				border-radius="4px"
				background="element-light"
				:border="true"
				width="432px"
				overflow="visible"
			>
				<Container
					:padding="0"
					:gap="0"
					direction="column"
					:grow="1"
					align="start top"
					overflow="visible"
				>
					<Header>
						<Container align="space-between center" :padding="0" :grow="1">
							<Container :gap="12" :padding="0">
								<Typography type="h4" color="dark"> Edit variables: {{ name }} </Typography>
							</Container>
							<Icon
								name="i-close"
								type="filled"
								size="x-small"
								data-qa-var-edit-popover-close-icon
								@click="closeDialog"
							/>
						</Container>
					</Header>
					<Container :shrink="0" :padding="0" overflow="visible">
						<Slab state="primary" size="medium" state-type="solid">
							<template #primary-action>
								<Icon
									name="i-info-fill"
									type="filled"
									state="white"
									size="small"
									:effects="false"
								/>
							</template>
							<Container :padding="2" :grow="1">
								<Typography color="white" type="p2"
									>Changes made are applicable to the future deployments.</Typography
								>
							</Container>
						</Slab>
					</Container>

					<Container :padding="16" direction="column" overflow="visible">
						<f-form-builder
							data-qa-edit-variable-form
							:field.prop="formFields"
							:values.prop="formValues"
							@state-change="formState = $event.detail"
						/>
					</Container>
					<Footer>
						<Button
							:type="isSubmitting ? 'default' : 'success'"
							:disabled="isSubmitting"
							:loading="isSubmitting"
							state="full"
							data-qa-var-edit-button
							@click="saveVariable"
						>
							<span>Done</span>
						</Button>
					</Footer>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	PopOver,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { variableType } from "@/protocol/common";

import {
	ENV_OPTION,
	Variable,
	TF_OPTION,
	VariableColumn,
	VariableTypeFormOption
} from "./variable-list-types";
import {
	deleteDeploymentVars,
	deleteEnvironmentVars,
	deleteProjectVars,
	updateDeploymentVars,
	updateEnvironmentVars,
	updateProjectVars
} from "./variables-list-utils";

export default defineComponent({
	name: "VariableListEditVariablePopOver",

	components: {
		PopOver,
		Icon,
		Button,
		Container,
		Slab,
		Typography,
		Wrapper,
		Header,
		Footer
	},

	props: {
		variableColumn: {
			type: Object as PropType<VariableColumn>,
			required: true
		},

		name: {
			type: String,
			required: true
		},

		isOpen: {
			type: Boolean,
			required: true
		}
	},

	emits: ["open", "close"],

	data() {
		return {
			isSubmitting: false,
			formState: null as FormBuilderState | null,
			formValues: {
				isMasked: false,
				key: this.variableColumn.key,
				value: this.variableColumn.value,
				variableType: this.variableColumn.type === variableType.env_var ? ENV_OPTION : TF_OPTION
			} as VariableKeyInternalValue
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					key: {
						type: "text",
						qaId: "name",
						label: { title: "Name" },
						placeholder: "Enter Name",
						disabled: true,
						validationRules: [{ name: "required" }]
					},

					value: {
						type: "textarea",
						qaId: "value",
						maskValue: this.formValues.isMasked,
						clear: false,
						label: {
							title: "Value",
							subTitle: html`
								<f-checkbox
									size="medium"
									value="${this.formValues.isMasked ? "checked" : "unchecked"}"
									state="default"
									data-qa-id="mask-variable-name"
									@input="${(e: CustomEvent) => {
										this.formValues.isMasked = e.detail.value === "checked";
									}}"
								>
									<f-div slot="label" padding="none" data-qa-mask-for=${this.formValues.key}>
										<f-text variant="para" size="small">Mask this</f-text>
									</f-div>
								</f-checkbox>
							`
						},

						placeholder: "Enter Value",
						validationRules: [{ name: "required" }]
					},

					isMasked: {
						type: "hidden"
					},

					variableType: {
						type: "radio",
						qaId: "variableType",
						label: { title: "Variable type" },
						options: [TF_OPTION, ENV_OPTION],
						validationRules: [{ name: "required" }],
						showWhen: () => {
							return this.variableColumn.editInfo?.type !== "application-deployment";
						}
					}
				}
			};
		}
	},

	methods: {
		async saveVariable() {
			const { editInfo } = this.variableColumn;

			const { formValues } = this;

			this.isSubmitting = true;

			const variable: Variable = {
				...formValues,
				variableType:
					formValues.variableType.id === variableType.env_var
						? variableType.env_var
						: variableType.tf_var
			};

			// Delete the previous instance of the variable and add the new one
			if (editInfo?.type === "project") {
				await deleteProjectVars(this.variableColumn, true);
				await updateProjectVars(editInfo, [variable]);
			} else if (editInfo?.type === "environment") {
				await deleteEnvironmentVars(this.variableColumn, true);
				await updateEnvironmentVars(editInfo, [variable]);
			} else if (editInfo?.type === "application-deployment") {
				await deleteDeploymentVars(this.variableColumn, true);
				await updateDeploymentVars(editInfo, [variable]);
			}

			this.isSubmitting = false;

			this.closeDialog();
		},

		openDialog() {
			this.$emit("open");
		},

		closeDialog() {
			this.$emit("close");
		}
	}
});

export type VariableKeyInternalValue = {
	key: string;
	value: string;
	isMasked: boolean;
	variableType: VariableTypeFormOption;
};
</script>
