<template>
	<component :is="componentName" :params="params" />
</template>

<script>
import { defineComponent } from "vue";

import ResetPasswordSuccess from "@/modules/auth/views/ResetPasswordSuccess.vue";
import SendResetPasswordLinkSuccess from "@/modules/auth/views/SendResetPasswordLinkSuccess.vue";
import SignUpSuccess from "@/modules/auth/views/SignUpSuccess.vue";
import VerificationEmailSuccess from "@/modules/auth/views/VerificationEmailSuccess.vue";

export default defineComponent({
	name: "AuthAfterStageWrapper",

	components: {
		SignUpSuccess,
		VerificationEmailSuccess,
		SendResetPasswordLinkSuccess,
		ResetPasswordSuccess
	},

	beforeRouteEnter(to, from, next) {
		const { componentName } = to.params;
		if (!from.name || !componentName) {
			return next("login");
		}
		next();
	},

	data() {
		return {
			componentName: "",
			params: {}
		};
	},

	created() {
		this.params = this.$route.params;
		this.setComponentName(this.params);
	},

	methods: {
		setComponentName(params) {
			this.componentName = params.componentName;
		}
	}
});
</script>
