<template>
	<Wrapper height="100%" data-qa-page="ProjectPage" :data-qa-org-id="orgId">
		<ProjectWrapper
			v-if="project"
			data-qa-page="AuthenticatedLandingPage"
			:envs="envs"
			:apps="apps || []"
			:selected-tab="currentTab"
			:project="project"
			@update-b-g-filter="setCurrentTab"
		>
			<keep-alive>
				<Environments v-if="currentTab === 'environments'" :envs="envs" :project="project" />
			</keep-alive>

			<keep-alive>
				<SettingsTabWrapper
					v-if="currentTab === 'settings'"
					parent-tab-name="settings"
					:apps="apps || []"
					:project="project"
					:selected-policy="selectedPolicy"
					:envs-count="envs.length"
					:apps-count="apps.length"
					:variables-count="variablesCount"
					@update-b-g-filter="setCurrentTab"
				/>
			</keep-alive>

			<keep-alive>
				<ApplicationsTab
					v-if="currentTab === 'applications'"
					:apps="apps || []"
					:project="project"
				/>
			</keep-alive>

			<keep-alive>
				<VariablesList
					v-if="currentTab === 'variables' && project"
					:project="project"
					:envs="envs"
					:apps="apps"
				/>
			</keep-alive>

			<CodePromotionWrapper v-if="currentTab === 'code-promotion'" :project="project" />
		</ProjectWrapper>
	</Wrapper>
</template>

<script lang="ts">
import { Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import CodePromotionWrapper from "@/modules/code-promotion/views/CodePromotionWrapper.vue";
import { credentialStore } from "@/modules/credentials/credential-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { orgStore } from "@/modules/org/org-store";
import { OrgRole } from "@/modules/org/org-types";
import { policyListStore } from "@/modules/policy-list/policy-list-store";
import { projectStore } from "@/modules/project-list/project-store";
import { variablesListStore } from "@/modules/variables-list/variables-list-store";
import VariablesList from "@/modules/variables-list/VariablesList.vue";
import { PolicySet } from "@/protocol/validator";
import { ListWithRadioBtnPopoverList } from "@/shared/components/code-pipes/ListWithRadioBtnPopover.vue";

import ApplicationsTab from "../components/ApplicationsTab.vue";
import Environments from "../components/landingBody/Environments.vue";
import ProjectWrapper from "../components/ProjectWrapper.vue";
import SettingsTabWrapper from "../components/SettingsTabWrapper.vue";
import { ProjectTabName } from "../project-types";

export default defineComponent({
	name: "ProjectPageWrapper",

	components: {
		ProjectWrapper,
		Environments,
		Wrapper,
		VariablesList,
		ApplicationsTab,
		CodePromotionWrapper,
		SettingsTabWrapper
	},

	props: {
		tabName: {
			type: String as PropType<ProjectTabName | "">
		},

		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		loadingProject: false
	}),

	computed: {
		project() {
			return projectStore.detailedProjects[this.projectId];
		},

		envs() {
			return (this.projectId ? envListStore.envs[this.projectId] : []) ?? [];
		},

		apps() {
			return (this.projectId ? applicationStore.projectApps[this.projectId] : []) ?? [];
		},

		title() {
			const { orgs } = orgStore;
			const org = orgs.find(matchingOrg => matchingOrg.id === this.orgId);
			return [this.project?.name, org?.name].filter(Boolean).join(" - ");
		},

		variablesCount() {
			return variablesListStore.variableCount;
		},

		currentTab(): ProjectTabName {
			return !this.tabName ? "applications" : this.tabName;
		},

		loadingCreds() {
			return credentialStore.loadingCredentials ?? credentialStore.loadingCredentialStats;
		},

		policySets(): ListWithRadioBtnPopoverList {
			return policyListStore.policySets.map(policySet => ({
				...policySet,
				qaId: policySet.name
			}));
		},

		selectedPolicy(): PolicySet | null {
			return this.policySets.find(_policy => _policy.id === this.project?.policySetId) ?? null;
		}
	},

	watch: {
		title: {
			immediate: true,
			deep: true,

			handler(newTitle: string) {
				if ("document" in window) {
					window.document.title = newTitle;
				}
			}
		},

		loadingCreds: {
			immediate: true,
			deep: true,

			handler(creds) {
				if (creds) {
					/* Check for updated applied creds list mainly to reflect deleted or new applied creds in Child level components */
					this.updateAppliedCreds();
				}
			}
		},

		projectId: {
			immediate: true,

			async handler() {
				this.loadingProject = true;

				await projectStore.GET_PROJECT({
					orgId: this.orgId,
					projectId: this.projectId
				});

				this.loadingProject = false;
			}
		}
	},

	methods: {
		setCurrentTab(tabName: ProjectTabName) {
			this.$router.replace({
				name: "projectListWithProject",
				params: { orgId: this.orgId, projectId: this.projectId, tabName }
			});
		},

		updateAppliedCreds() {
			// Fetch credentials for the project
			if (this.project?.role === OrgRole.ADMIN) {
				credentialStore.GET_CREDS_FOR_ENTITY({
					orgId: this.orgId,
					project: {
						id: this.projectId,
						orgId: this.orgId
					}
				});
			}
		}
	}
});
</script>
