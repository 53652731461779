import {
	BundleExportResp,
	BundleInstallPlan,
	installation,
	installationId,
	installationList,
	installationStatus,
	installerService
} from "@/protocol/installer";
import ApiService from "@/services/api-service";

const INSTALLER_API = "/installer/v0";

export const parseBundle: installerService["parseBundle"] = async function (data) {
	const response = await ApiService.post<BundleInstallPlan>(`${INSTALLER_API}/parse`, data);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const bundleApply: installerService["bundleApply"] = async function (data) {
	const response = await ApiService.post<installationId>(`${INSTALLER_API}/apply`, data);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getInstallationStatus: installerService["getInstallationStatus"] = async function (
	data
) {
	const { orgId, id } = data;

	const response = await ApiService.get<installationStatus>(
		`${INSTALLER_API}/organizations/${orgId}/installations/${id}/status`
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getInstallation: installerService["getInstallation"] = async function (data) {
	const { orgId, id } = data;

	const response = await ApiService.get<installation>(
		`${INSTALLER_API}/organizations/${orgId}/installations/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const listInstallations: installerService["listInstallations"] = async function (data) {
	const { orgId } = data;

	const response = await ApiService.get<installationList>(
		`${INSTALLER_API}/organizations/${orgId}/installations${
			data.projectId ? `?projectId=${data.projectId}` : ""
		}`
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const bundleExport: installerService["bundleExport"] = async function (data) {
	const { orgId, projectId } = data;

	const response = await ApiService.get<BundleExportResp>(
		`${INSTALLER_API}/organizations/${orgId}/projects/${projectId}/export`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
