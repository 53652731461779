<template>
	<Wrapper height="100%">
		<f-div direction="column" height="100%" gap="small" padding="medium none none large">
			<!--Header Section-->
			<f-div direction="row" gap="large" height="hug-content">
				<f-div direction="row" gap="x-small" width="100%" padding="none small large none">
					<f-text size="medium" variant="heading">Project details</f-text>
				</f-div>
			</f-div>
			<f-div v-if="project" direction="column" gap="small" height="hug-content">
				<f-div
					direction="column"
					gap="small"
					height="hug-content"
					padding="none none large none"
					border="small solid subtle bottom"
				>
					<f-div
						v-if="project?.description"
						direction="row"
						gap="x-small"
						padding="none"
						height="hug-content"
					>
						<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
							<f-text size="small" variant="heading" state="secondary" weight="regular">
								Description
							</f-text>
						</f-div>
						<f-div
							direction="row"
							gap="none"
							width="fill-container"
							padding="none"
							height="hug-content"
							data-qa-policy-details-desc
						>
							<f-text size="small" variant="para" s weight="regular">
								{{ project?.description }}
							</f-text>
						</f-div>
					</f-div>

					<f-div direction="row" gap="x-small" width="100%" padding="none" height="hug-content">
						<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
							<f-text size="small" variant="heading" state="secondary" weight="regular">
								Applied policy set
							</f-text>
						</f-div>
						<f-div
							direction="column"
							gap="none"
							width="fill-container"
							padding="none"
							height="hug-content"
						>
							<f-div
								direction="row"
								gap="small"
								padding="none"
								height="hug-content"
								width="fill-container"
							>
								<f-div gap="none" padding="none" width="hug-content">
									<f-icon
										state="warning"
										source="i-shield"
										tooltip="Environments use resolvers to resolve dependencies used by applications"
									>
									</f-icon>
								</f-div>

								<f-div v-if="selectedPolicy" gap="none" padding="none" width="hug-content">
									<f-text size="small" variant="para" weight="regular" data-qa-policy-details-name>
										{{ selectedPolicy.name }}</f-text
									>
								</f-div>
								<f-div gap="none" padding="none" width="hug-content">
									<f-text
										id="policyPopoverTarget"
										size="small"
										variant="para"
										weight="regular"
										state="primary"
										class="cursor-pointer"
										data-qa-policy-change-action-btn
										@click="actionState = 'assign-policy-set'"
									>
										CHANGE
									</f-text>
									<f-popover
										target="#policyPopoverTarget"
										:open="actionState === 'assign-policy-set'"
										style="max-height: unset"
										@overlay-click="actionState = 'closed'"
									>
										<PolicySelectorModal
											:entity="project"
											entity-type="project"
											:selected-policy="selectedPolicy"
											:new-policy-set-id="newPolicySetId"
											:selected-policy-set-id="selectedPolicySetId"
											@toggle-list-popover="toggleListPopover"
											@select-policy="selectPolicy"
										/>
									</f-popover>
								</f-div>
							</f-div>
						</f-div>
					</f-div>
				</f-div>

				<f-div
					direction="column"
					gap="small"
					height="hug-content"
					padding="none none large none"
					border="small solid subtle bottom"
				>
					<f-div
						direction="row"
						gap="x-small"
						width="100%"
						padding="medium none none none"
						height="hug-content"
					>
						<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
							<f-text size="small" variant="heading" state="secondary" weight="regular">
								Created on</f-text
							>
						</f-div>
						<f-div
							v-if="project.createdAt"
							direction="row"
							gap="none"
							width="fill-container"
							padding="none"
							height="hug-content"
						>
							<f-text
								size="small"
								variant="para"
								weight="regular"
								data-qa-policy-details-created-date
							>
								{{ timestampFormat(project.createdAt) }}
							</f-text>
						</f-div>
					</f-div>
				</f-div>

				<f-div
					direction="column"
					gap="small"
					height="hug-content"
					padding="none none large none"
					border="small solid subtle bottom"
				>
					<f-div
						direction="row"
						gap="x-small"
						width="100%"
						padding="medium none none none"
						height="hug-content"
					>
						<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
							<f-text size="small" variant="heading" state="secondary" weight="regular">
								Total environments</f-text
							>
						</f-div>
						<f-div
							direction="row"
							gap="none"
							width="fill-container"
							padding="none"
							height="hug-content"
						>
							<f-text
								size="small"
								variant="para"
								weight="regular"
								data-qa-policy-details-total-env-count
							>
								{{ envsCount }}
							</f-text>
						</f-div>
					</f-div>
					<f-div
						direction="row"
						gap="x-small"
						width="100%"
						padding="medium none none none"
						height="hug-content"
					>
						<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
							<f-text size="small" variant="heading" state="secondary" weight="regular">
								Total applications</f-text
							>
						</f-div>
						<f-div
							direction="row"
							gap="none"
							width="fill-container"
							padding="none"
							height="hug-content"
						>
							<f-text
								size="small"
								variant="para"
								weight="regular"
								data-qa-policy-details-total-apps-count
							>
								{{ appsCount }}
							</f-text>
						</f-div>
					</f-div>
					<f-div
						direction="row"
						gap="x-small"
						width="100%"
						padding="medium none none none"
						height="hug-content"
					>
						<f-div direction="row" gap="none" width="20%" padding="none" height="hug-content">
							<f-text size="small" variant="heading" state="secondary" weight="regular">
								Total variables</f-text
							>
						</f-div>
						<f-div
							direction="row"
							gap="none"
							width="fill-container"
							padding="none"
							height="hug-content"
						>
							<f-text
								size="small"
								variant="para"
								weight="regular"
								data-qa-policy-details-total-variables-count
							>
								{{ variablesCount }}
							</f-text>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
	</Wrapper>
</template>

<script lang="ts">
import { Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import PolicySelectorModal from "@/modules/policy-list/components/PolicySelectorModal.vue";
import { project } from "@/protocol/identity";
import { PolicySet } from "@/protocol/validator";
import { formatDateToRegularFormat } from "@/utils";

export default defineComponent({
	name: "ProjectDetails",

	components: {
		Wrapper,
		PolicySelectorModal
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		},

		selectedPolicy: {
			type: Object as PropType<PolicySet | null>
		},

		envsCount: {
			type: Number,
			required: true
		},

		appsCount: {
			type: Number,
			required: true
		},

		variablesCount: {
			type: Number,
			required: true
		}
	},

	data: () => ({
		policyPopOver: false,
		newPolicySetId: null as string | null,
		actionState: "closed" as "closed" | "open" | "assign-policy-set"
	}),

	computed: {
		selectedPolicySetId() {
			return this.newPolicySetId ?? this.project.policySetId ?? "";
		}
	},

	methods: {
		timestampFormat(timestamp: string) {
			return formatDateToRegularFormat(Number(timestamp) * 1000);
		},

		selectPolicy(policyId: string) {
			this.newPolicySetId = policyId;
		},

		toggleListPopover() {
			this.policyPopOver = !this.policyPopOver;
			this.actionState = "closed";
			if (!this.policyPopOver) {
				this.newPolicySetId = null;
			}
		}
	}
});
</script>
