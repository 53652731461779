<template>
	<PopOver
		:open="isOpen"
		placement="left-start"
		data-qa-deploy-multiple-apps-popover
		@overlay-click="closePopover"
	>
		<Container :gap="12" :padding="0" @click="isOpen = !isOpen">
			<slot v-if="$slots.default" />
			<Button
				v-else
				type="default"
				:disabled="deployableApps.length === 0"
				data-qa-toggle-multiple-apps-toggle-btn
			>
				<Typography type="p1" weight="bold" color="white">DEPLOY APPS</Typography>
				<Icon
					v-tooltip="{
						content: 'Select applications that you want to deploy together.',
						container: 'div.flow-layout',
						placement: 'bottom-end'
					}"
					name="i-question-filled"
					size="small"
					color="gray-200"
					:effects="false"
				/>
			</Button>
		</Container>
		<template #content>
			<Wrapper
				border-radius="4px"
				background="gray-500"
				width="480px"
				max-height="80vh"
				overflow="visible"
			>
				<Header>
					<Typography type="h4" color="dark"> Deploy applications </Typography>
					<Container :padding="0" :grow="1" align="right center" :gap="10">
						<Pictogram
							size="m"
							shape="circle"
							:type="showSearch ? 'solid' : 'transparent'"
							data-qa-search-toggle-btn
							@click="showSearch = !showSearch"
						>
							<Icon size="small" name="i-search" :state="showSearch ? 'primary' : 'dark'" />
						</Pictogram>

						<PopOver
							:open="showFilter"
							:overlay="false"
							placement="bottom-end"
							data-qa-filter-overlay-btn
							@overlay-click="showFilter = false"
						>
							<Pictogram
								size="m"
								shape="circle"
								:type="showFilter ? 'solid' : 'transparent'"
								data-qa-filter-toggle-btn
								@click="showFilter = !showFilter"
							>
								<Icon size="small" name="i-filter" :state="showFilter ? 'primary' : 'dark'" />
							</Pictogram>
							<template #content>
								<Wrapper border-radius="4px" background="element" :border="true" width="255px">
									<Container direction="column" :padding="0" :gap="0">
										<Container
											v-for="op in filterOptions"
											:key="op.title"
											:class="shouldDisableFilter(op) ? 'disable-element' : ''"
											align="space-between"
											:padding="12"
											clickable
											:data-qa-filter-option="op"
											@click="selectFilter(op)"
										>
											<Container
												:padding="0"
												direction="column"
												:gap="2"
												:class="{ 'margin-rt-24': !op.selected }"
											>
												<Typography type="h5" weight="medium" color="dark">{{
													op.title
												}}</Typography>
												<Typography
													type="p3-para"
													weight="medium"
													color="gray-200"
													overflow="wrap"
													class="text-wrap"
													data-qa-filter-option-description
												>
													{{ op.subTitle }}
												</Typography>
											</Container>
											<Icon
												v-if="op.selected"
												data-qa-filter-option-selected-icon
												name="i-tick"
												size="x-small"
												state="dark"
												:effects="false"
											/>
										</Container>
									</Container>
								</Wrapper>
							</template>
						</PopOver>

						<Icon size="x-small" name="i-close" data-qa-filter-close-icon @click="closePopover" />
					</Container>
				</Header>
				<Container padding="10px 14px" :gap="0" align="left top" direction="column">
					<Container v-if="showSearch" padding="0px 0px 16px 0px">
						<SearchInput v-model:value="searchString" data-qa-search-input />
					</Container>
					<Container :gap="12" padding="2px 0px 12px 2px" :shrink="0">
						<Checkbox
							:checked="isAllChecked"
							:indeterminate="isAnyChecked"
							data-qa-checkbox-all
							@click="toggleAll(isAllChecked)"
						/>
						<Typography type="h5" weight="regular" color="gray-200">All applications</Typography>
					</Container>
					<Divider />
					<Container
						padding="8px 0px 0px 2px"
						:gap="0"
						direction="column"
						overflow="auto"
						align="left top"
					>
						<Container
							v-for="app in deployableApps"
							:key="app.name"
							:shrink="0"
							padding="12px 0px"
							overflow="visible"
						>
							<Checkbox
								:checked="app.selected"
								:data-qa-app-selected="app.selected"
								@click="app.selected = !app.selected"
							/>
							<Pictogram
								size="xl"
								shape="squircle"
								state="avatar"
								:label="app.label"
								:border-state="app.state"
								:data-qa-app-selected="app.selected"
								@click="app.selected = !app.selected"
							/>
							<Container
								:padding="0"
								:gap="4"
								direction="column"
								:data-qa-app-selected="app.selected"
								@click="app.selected = !app.selected"
							>
								<Typography type="h4" color="dark" :data-qa-app-dep-name="app.name">
									{{ app.name }}</Typography
								>
								<Typography type="p2" color="gray-200" :data-qa-app-dep-desc="app.subText">{{
									app.subText
								}}</Typography>
							</Container>
						</Container>
					</Container>
				</Container>

				<Footer>
					<Button
						state="full"
						type="success"
						:loading="loading"
						data-qa-deploy-multiple-apps-btn
						@click="startDeploy"
					>
						DEPLOY APPLICATIONS
					</Button>
				</Footer>
			</Wrapper>
		</template>
	</PopOver>
</template>
<script lang="ts">
import {
	Container,
	Button,
	Typography,
	Icon,
	PopOver,
	Wrapper,
	Header,
	Footer,
	Checkbox,
	Divider,
	Pictogram,
	SearchInput,
	PictogramBorderState
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { AppDeployment, DeploymentJobAction, jobId } from "@/protocol/deployment";
import { environment as EnvProto } from "@/protocol/infra";
import { getRelativeTime, getShortCode } from "@/utils";

type DeployableApp = AppDeployment & {
	name: string;
	subText: string;
	state: PictogramBorderState;
	selected: boolean;
	label: string;
};

type FilterOption = {
	title: string;
	subTitle: string;
	selected: boolean;
	id: "all" | "saved" | "deployed";
};
export default defineComponent({
	name: "DeployMultipleApps",

	components: {
		Container,
		Button,
		Typography,
		Icon,
		PopOver,
		Wrapper,
		Header,
		Footer,
		Checkbox,
		Divider,
		Pictogram,
		SearchInput
	},

	props: {
		env: {
			type: Object as PropType<EnvProto>,
			required: true
		},

		appDepEntities: {
			type: Array as PropType<AppDeployment[]>,
			required: true
		}
	},

	data: () => ({
		isOpen: false,
		showSearch: false,
		showFilter: false,
		deployableApps: [] as DeployableApp[],
		loading: false,
		searchString: "",

		filterOptions: [
			{
				title: "All apps",
				subTitle: "Select and deploy all applications.",
				selected: true,
				id: "all"
			},
			{
				title: "Already deployed apps",
				subTitle: "Select and redeploy already deployed apps.",
				selected: false,
				id: "deployed"
			},
			{
				title: "Saved applications",
				subTitle: "Select and deploy applications that are not deployed yet.",
				selected: false,
				id: "saved"
			}
		] as FilterOption[]
	}),

	computed: {
		deployableAppsList() {
			const selectedFilter = this.filterOptions.find(filter => filter.selected);

			const filteredBySearchStringDeps = this.appDepEntities.filter(
				appDep => appDep.name?.toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase())
			);

			if (selectedFilter?.id === "deployed") {
				return filteredBySearchStringDeps.filter(
					appDep => appDep.lastSuccessfulJob?.jobType === DeploymentJobAction.deploy
				);
			} else if (selectedFilter?.id === "saved") {
				return filteredBySearchStringDeps.filter(appDep => !appDep.lastSuccessfulJob);
			}

			return filteredBySearchStringDeps;
		},

		isAllChecked(): boolean {
			return this.deployableApps.every(a => a.selected);
		},

		isAnyChecked(): boolean {
			return !this.isAllChecked && this.deployableApps.some(a => a.selected);
		}
	},

	watch: {
		deployableAppsList(val: AppDeployment[] | []) {
			this.setDeployableApps(val);
		}
	},

	mounted() {
		this.setDeployableApps(this.deployableAppsList);
	},

	methods: {
		toggleAll(currentValue: boolean) {
			if (currentValue || this.isAnyChecked) {
				this.deployableApps.forEach(a => (a.selected = false));
			} else {
				this.deployableApps.forEach(a => (a.selected = true));
			}
		},

		setDeployableApps(deps: AppDeployment[]) {
			this.deployableApps = deps.map(app => ({
				...app,
				name: app.name ?? "",
				subText:
					app.lastSuccessfulJob && app.updatedAt
						? `last deployed ${this.timestampFormat(app.updatedAt)}`
						: "Never Deployed",
				state: "default",
				selected: false,
				label: getShortCode(app.name)
			}));
		},

		async startDeploy() {
			const appsToDeploy = this.deployableApps.filter(app => app.selected);

			if (appsToDeploy.length === 0) {
				return;
			}

			this.loading = true;
			const promises: Promise<jobId>[] = [];

			appsToDeploy.forEach(app => {
				if (!app.orgId || !app.projId || !app.id) {
					return;
				}

				promises.push(
					applicationDeploymentStore.START_APP_PIPELINE({
						orgId: app.orgId,
						projectId: app.projId,
						envId: this.env.id,
						depId: app.id,
						jobType: DeploymentJobAction.deploy
					})
				);
			});

			const response: jobId[] = await Promise.all(promises);

			response.forEach(obj => {
				applicationDeploymentStore.GET_APP_DEPLOYMENT({
					orgId: obj.orgId,
					projId: obj.projId,
					envId: obj.envId,
					id: obj.depId
				});
			});
			this.resetSelection();
			this.loading = false;
			this.isOpen = false;
			this.filterOptions.forEach(op => (op.selected = false));
			notificationsStore.ADD_TOAST({
				qaId: "toast-container-multiple-apps-deployed-successfully",
				title: `Deploying ${appsToDeploy.length} ${
					appsToDeploy.length === 1 ? "application" : "applications"
				} in ${this.env.name}`,
				text: `Started deploying ${appsToDeploy.map(app => app.name).join(", ")}`,
				status: "success"
			});
		},

		resetSelection() {
			this.deployableApps.forEach(appDep => (appDep.selected = false));
		},

		timestampFormat(value: string) {
			return getRelativeTime(value);
		},

		selectFilter(option: FilterOption) {
			this.filterOptions.forEach(op => (op.selected = false));

			option.selected = true;

			this.showFilter = false;
		},

		shouldDisableFilter(op: FilterOption) {
			if (op.id === "deployed") {
				return this.deployableAppsList.every(
					app => app.lastSuccessfulJob?.jobType !== DeploymentJobAction.deploy
				);
			} else if (op.id === "saved") {
				return this.deployableAppsList.every(
					app => app.lastSuccessfulJob?.jobType === DeploymentJobAction.deploy
				);
			}

			return false;
		},

		closePopover() {
			this.isOpen = false;
			this.filterOptions.forEach(op => (op.selected = false));
		}
	}
});
</script>
