<template>
	<Wrapper
		width="450px"
		border-radius="4px"
		background="element-light"
		overflow="visible"
		data-qa-remove-project-model
	>
		<Container :padding="0" :gap="0" direction="column" :grow="1">
			<Header>
				<Typography type="h4" color="dark"> Remove {{ project.name }} Project </Typography>
				<Icon name="i-close" type="filled" size="x-small" @click="closeProjectDeletePopover()" />
			</Header>

			<ModalNotificationBanner
				v-if="submitError"
				banner-type="error"
				:banner-body="submitError"
				:show-close-icon="true"
				@close="submitError = null"
			/>

			<Container
				v-if="isLoading"
				direction="column"
				padding="0"
				:shrink="0"
				:gap="16"
				align="left stretch"
				data-qa-project-remove-loader
			>
				<Container :padding="0" :gap="4">
					<CustomLoader />
				</Container>
			</Container>

			<Container
				v-if="!isLoading && !hasDeployedEntities"
				direction="column"
				:shrink="0"
				data-qa-remove-project-confirmation-message
			>
				<Typography type="p1" color="light">
					Are you sure you want to remove
					<span class="fc-dark fw-bold" :style="{ whiteSpace: 'pre-wrap' }">{{ project.name }}</span
					>?
				</Typography>
			</Container>

			<Container
				v-if="!isLoading && hasDeployedEntities"
				direction="column"
				:shrink="0"
				:gap="16"
				padding="16px 16px 16px 16px"
				align="left stretch"
			>
				<div>
					<Typography type="p1" weight="regular" inline>This project has </Typography>

					<template v-if="deployedEnvironments.length > 0">
						<Typography type="p1" color="error" weight="bold" inline>
							{{ deployedEnvironments.length }} deployed environment{{
								deployedEnvironments.length > 1 ? "s" : ""
							}}</Typography
						>
					</template>

					<template v-if="deployedApps.length > 0 && deployedEnvironments.length > 0">
						and
					</template>

					<template v-if="deployedApps.length > 0">
						<Typography type="p1" color="error" weight="bold" inline
							>{{ deployedApps.length }} deployed application{{
								deployedApps.length > 1 ? "s" : ""
							}}.</Typography
						>
					</template>
				</div>
				<Container :padding="0" :gap="4">
					<Typography type="p2-para" color="light">Please undeploy them to proceed.</Typography>
				</Container>
			</Container>

			<Container
				direction="column"
				:grow="1"
				:gap="16"
				padding="0px 16px 16px 16px"
				overflow="auto"
				align="left stretch"
			>
				<div
					v-if="!isLoading && envs.length > 0"
					class="display-contents"
					data-qa-remove-project-environment-list
				>
					<Slab
						v-for="env in envs"
						:key="env.id"
						align-items="start"
						:no-padding="true"
						overflow="visible"
					>
						<Container direction="column" padding="0px 8px 0px 0px" :gap="4">
							<Container :padding="0" :gap="12">
								<Typography type="h4">{{ env.name }}</Typography>
							</Container>
							<Container :padding="0" :gap="4">
								<Typography v-if="env.appsCount < 1" type="p2" color="light"
									>No applications deployed</Typography
								>
								<Typography v-else type="p2" color="light"
									>{{ env.appsCount }} deployed application{{
										env.appsCount > 1 ? "s" : ""
									}}</Typography
								>
							</Container>
						</Container>
						<template #secondary-actions>
							<Icon
								name="i-view"
								size="small"
								type="filled"
								state="primary"
								@click="openEnv(env)"
							/>
						</template>
					</Slab>
				</div>

				<div
					v-if="!isLoading && deployedApps.length > 0"
					class="display-contents"
					data-qa-remove-project-apps-list
				>
					<Slab
						v-for="appDeployment in deployedApps"
						:key="appDeployment.id"
						align-items="start"
						:no-padding="true"
						overflow="visible"
					>
						<Container direction="column" padding="0px 8px 0px 0px" :gap="4">
							<Container :padding="0" :gap="12">
								<Typography type="h4">{{ appDeployment.name }}</Typography>
							</Container>
						</Container>
						<template #secondary-actions>
							<Icon
								name="i-view"
								size="small"
								type="filled"
								state="primary"
								@click="openDeployment(appDeployment)"
							/>
						</template>
					</Slab>
				</div>
			</Container>

			<Footer>
				<Container :padding="0" align="center stretch" :grow="1" role="button">
					<Button
						state="full"
						type="error"
						:disabled="hasDeployedEntities || deleting"
						data-qa-trigger-remove-project-btn
						@click="removeProjectAndDependents(project)"
					>
						<Icon v-if="deleting" name="i-history" size="small" />
						<span>Remove Project</span>
					</Button>
				</Container>
			</Footer>
		</Container>
	</Wrapper>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { projectStore } from "@/modules/project-list/project-store";
import { AppDeployment, DeploymentJobAction } from "@/protocol/deployment";
import { project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import { captureError } from "@/utils";
import { getErrorMessage } from "@/utils/get-error-message";

export default defineComponent({
	name: "ProjectRemovePopover",

	components: {
		ModalNotificationBanner,
		Typography,
		Container,
		Wrapper,
		Header,
		Button,
		Icon,
		Slab,
		Footer,
		CustomLoader
	},

	props: {
		closeProjectDeletePopover: {
			type: Function as PropType<() => void>,
			required: true
		},

		project: {
			type: Object as PropType<project>,
			required: true
		},

		shouldNavigateOnDelete: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		isLoading: true,
		deleting: false,
		submitError: null as string | null
	}),

	computed: {
		hasDeployedEntities() {
			return this.deployedEnvironments.length > 0 || this.deployedApps.length > 0;
		},

		deployedApps() {
			return Object.values(applicationDeploymentStore.allDeployments).filter(deployment => {
				return (
					deployment.projId === this.project.id &&
					deployment.lastSuccessfulJob?.jobType === DeploymentJobAction.deploy
				);
			});
		},

		deployedEnvironments() {
			return envListStore.envs[this.project.id]?.filter(env => env.isDeployed) ?? [];
		},

		envs() {
			return this.deployedEnvironments.map(env => {
				const envId = env.id;
				const envApps = this.deployedApps.filter(app => app.envId === envId);

				return {
					...env,
					appsCount: envApps.length,
					envApps
				};
			});
		}
	},

	mounted() {
		this.fetchEnvPipelineDetails();
	},

	methods: {
		async fetchEnvPipelineDetails() {
			this.isLoading = true;

			await applicationDeploymentStore.LIST_PROJECT_APP_DEPLOYMENTS({
				orgId: this.project.orgId,
				projectId: this.project.id
			});

			this.isLoading = false;
		},

		async removeProjectAndDependents(projectToRemove: project) {
			try {
				if (this.deleting) {
					return;
				}

				this.submitError = "";
				this.deleting = true;

				// removing project.
				await projectStore.DELETE_PROJECT({
					project: projectToRemove,
					force: true
				});

				if (this.shouldNavigateOnDelete) {
					await this.$router.replace({
						name: "home",
						params: { orgId: projectToRemove.orgId }
					});
				}

				this.deleting = false;
				this.closeProjectDeletePopover();
			} catch (error) {
				this.submitError = getErrorMessage(error);
				captureError(error);
				this.deleting = false;
				throw error;
			}
		},

		openDeployment(deployment: AppDeployment) {
			this.$router.push({
				name: "envDetail",
				params: {
					orgId: deployment.orgId!,
					projectId: deployment.projId!,
					envId: deployment.envId!
				}
			});
		},

		openEnv(env: environment) {
			this.$router.push({
				name: "envDetail",
				params: {
					orgId: env.orgId,
					projectId: env.projId,
					envId: env.id
				}
			});
		}
	}
});
</script>
