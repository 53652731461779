<template>
	<InfoPopover
		v-if="showInfo"
		title="Credentials on project"
		placement="right-start"
		target="credentials-proj"
		:is-open="showInfo"
		:show-overlay="true"
		@close="closePopover"
	>
		<f-div>
			<f-text variant="para" size="medium">
				Assign credentials to this project to add, build and deploy applications and their
				environments.
			</f-text>
		</f-div>
		<f-div padding="large none none none">
			<f-text> You can manage cloud, git or container credentials for this project here. </f-text>
		</f-div>
	</InfoPopover>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { userStore } from "@/modules/user/user-store";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";

export default defineComponent({
	name: "ProjectCredentialsPopover",

	components: {
		InfoPopover
	},

	data: () => ({
		showInfo: false as boolean
	}),

	computed: {
		infoFlags() {
			return userStore.profile?.metadata?.infoFlags;
		}
	},

	mounted() {
		if (!this.infoFlags?.hasProjectCredentialsPopover) {
			this.showInfo = true;
		}
	},

	methods: {
		closePopover() {
			this.showInfo = false;
			userStore.UPDATE_USER_META({
				infoFlags: {
					...this.infoFlags,
					hasProjectCredentialsPopover: true
				}
			});
		}
	}
});
</script>
