<template>
	<Container :padding="0" :gap="0" :shrink="0">
		<Slab
			size="medium"
			:type="selected ? 'filled' : 'transparent'"
			:effects="!selected"
			class="cursor-pointer slab-primary-padding-0"
			:data-qa-field="name"
			@click="$emit('select')"
		>
			<template #primary-action>
				<Container padding="0px 0px 0px 12px">
					<Pictogram shape="squircle">
						<Typography type="h3">{{ emoji ? emoji : shortCode }}</Typography>
					</Pictogram>
				</Container>
			</template>
			<Container :padding="0" :gap="4" direction="column">
				<Typography
					v-tooltip="{
						content: name
					}"
					type="h4"
					weight="medium"
					color="dark"
					:data-qa-app-name="name"
					overflow="ellipsis"
					>{{ name }}</Typography
				>
				<Typography type="p3" color="gray-200" :data-qa-app-subtitle="subTitle">{{
					subTitle
				}}</Typography>
			</Container>
		</Slab>
	</Container>
</template>
<script lang="ts">
import { Container, Slab, Pictogram, Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { getShortCode } from "@/utils";

export default defineComponent({
	name: "ApplicationEntity",

	components: {
		Container,
		Slab,
		Pictogram,
		Typography
	},

	props: {
		/**
		 * Name of deployment
		 */
		name: {
			type: String,
			required: true
		},

		/**
		 * SubTitle of deployment
		 */
		subTitle: {
			type: String,
			required: true
		},

		/**
		 * emoji if present
		 */
		emoji: {
			type: String
		},

		/**
		 * Name of deployment
		 */
		selected: {
			type: Boolean,
			default: () => null
		}
	},

	computed: {
		shortCode() {
			return getShortCode(this.name);
		}
	}
});
</script>
