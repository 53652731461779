/* eslint-disable */
import {
	GitRevision,
	variable,
	Provisioner,
	JSONSchema,
	GitRevisionOverride,
	StepHistory,
	ArtifactConfig,
	EnvironmentId
} from "./common";
import { HealthCheckRequest, HealthCheckResponse } from "./health";
import { UserRoleList } from "./user";
import { EvalResponse } from "./validator";

export enum actionType {
	no_action_type_support = "no_action_type_support",
	validateOnly = "validateOnly",
	ValidateAndApply = "ValidateAndApply",
	destroy = "destroy"
}

export enum tfLogLevel {
	default = "default",
	trace = "trace",
	debug = "debug",
	info = "info",
	warn = "warn",
	error = "error",
	json = "json"
}

export enum infrastructureProvider {
	undefined = "undefined",
	component = "component",
	environment_provider = "environment_provider",
	envTemplate_provider = "envTemplate_provider"
}

export interface environmentCreate {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	revision?: GitRevision;
	variables?: variable[];
	policySetId?: string;
	metadata?: Record<string, unknown>;
	tfVersion?: string;
	pipelineConfig?: Record<string, unknown>;
}

export interface environmentUpdate {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	revision?: GitRevision;
	variables?: variable[];
	policySetId?: string;
	metadata?: Record<string, unknown>;
	tfVersion?: string;
	// replaceVariables
	//
	// If false, the variables will not be updated. If true, all the existing
	// variables will be replaced with the given set.
	replaceVariables?: boolean;
	pipelineConfig?: Record<string, unknown>;
}

export interface environment {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	revision?: GitRevision;
	variables?: variable[];
	policySetId?: string;
	role?: string;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
	metadata?: Record<string, unknown>;
	isDeployed?: boolean;
	tfVersion?: string;
	templateId?: string;
	classification?: classification;
	encryptedSecrets?: string;
	pipelineConfig?: Record<string, unknown>;
}

export interface envPipelineConfigSchemaReq {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Optional. cloud type is needed, which can be explicitly set. or derived from cred assignment on project or env.
	// Options: [{"(validate.rules)":{"string":{"uuid":true,"ignore_empty":true}}}]
	envId: string;
	cloudType?: Provisioner;
}

export interface envPipelineConfigSchemaResp {
	cloudType?: Provisioner;
	inputs?: JSONSchema;
}

export interface template {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	revision?: GitRevision;
	tfVersion?: string;
	metadata?: Record<string, unknown>;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface createTemplateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	revision?: GitRevision;
	tfVersion?: string;
	metadata?: Record<string, unknown>;
}

export interface updateTemplateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	revision?: GitRevision;
	tfVersion?: string;
	metadata?: Record<string, unknown>;
}

export interface getTemplateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface deleteTemplateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface listTemplatesResponse {
	templates?: template[];
}

export interface listTemplatesRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface environments {
	envs?: environment[];
}

export interface listEnvRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	detailed?: boolean;
}

export interface updateVariablesRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	variables?: variable[];
}

export interface deleteVariablesRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	variables?: variable[];
}

export interface updateClassificationVariablesRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	id: string;
	variables?: variable[];
}

export interface deleteClassificationVariablesRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	id: string;
	variables?: variable[];
}

export interface environmentDelete {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	force?: boolean;
}

export interface envEntitiesDeleteRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	force?: boolean;
}

export interface envBeforeCredChange {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	force?: boolean;
}

export interface envAccessGrant {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	userId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	role: string;
}

export interface envAccessRevoke {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	userId: string;
}

export interface success {
	success?: boolean;
}

export interface resourceChanges {
	create?: number;
	update?: number;
	delete?: number;
}

export interface triggerRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	action: actionType;
	waitForApproval?: boolean;
	gitRevision?: GitRevisionOverride;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true}}}]
	logVerbosity: tfLogLevel;
}

export interface jobId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	envId?: string;
	orgId?: string;
	projId?: string;
}

export interface jobShortResponse {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	status?: string;
	totalSteps?: number;
	progress?: number;
	message?: string;
	action?: actionType;
	tags?: string[];
	createdAt?: string;
	updatedAt?: string;
	resourceChanges?: resourceChanges;
	tfVersion?: string;
	history?: Record<string, StepHistory>;
	resolvedCommitHash?: string;
	updatedBy?: string;
}

export interface jobLongResponse {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	status?: string;
	totalSteps?: number;
	progress?: number;
	message?: string;
	action?: actionType;
	tags?: string[];
	createdAt?: string;
	updatedAt?: string;
	steps?: jobStep[];
	resourceChanges?: resourceChanges;
	tfVersion?: string;
	resolvedCommitHash?: string;
	waitingFor?: string[];
	updatedBy?: string;
}

export interface jobStep {
	name?: string;
	description?: string;
	logs?: string[];
	outputs?: string[];
	history?: StepHistory;
}

export interface jobListRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true}}}]
	action: actionType;
	// Options: [{"(validate.rules)":{"int64":{"gte":0}}}]
	limit: string;
}

export interface jobs {
	jobs?: jobShortResponse[];
}

export interface artifactRequest {
	job?: jobId;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	artifactName: string;
}

export interface logFiles {
	files?: string[];
}

export interface logRequest {
	job?: jobId;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	logFile: string;
}

export interface logResponse {
	logFile?: string;
	logData?: string;
}

export interface service {
	name?: string;
	type?: string;
	provider?: string;
	icon?: string;
}

export interface servicesListRequest {
	jobId?: string;
	envId?: string;
	orgId?: string;
	projId?: string;
}

export interface services {
	provider?: string;
	services?: service[];
}

export interface EnvStateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	filter?: string;
}

export interface stateOutput {
	sensitive?: boolean;
	type?: string;
	value?: unknown;
}

export interface resource {
	id?: string;
}

export interface EnvStateResponse {
	outputs?: Record<string, stateOutput>;
	resources?: resource[];
}

export interface uploadEnvStateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// base 64 encoded string corresponding to file
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	data: string;
}

export interface servicesListResponse {
	servicesList?: services[];
}

export interface promotionSequenceListRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface promotionSequenceNodeList {
	items?: promotionSequenceNode[];
}

export interface promotionSequenceNode {
	this?: promotionSequenceItem;
	next?: promotionSequenceNode[];
	prev?: promotionSequenceNode;
}

export interface promotionSequenceItem {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	policySetId?: string;
	isDeployed?: boolean;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface promotionLinkRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	ancestorId: string;
	append?: boolean;
}

export interface moduleId {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	namespace: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	provider: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	version: string;
	path?: string;
}

export interface infrastructureComponent {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	title: string;
	moduleId?: moduleId;
	variables?: variable[];
	outputs?: Record<string, string>;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface createComponentRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	title: string;
	moduleId?: moduleId;
	variables?: variable[];
}

export interface updateComponentRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	title: string;
	moduleId?: moduleId;
	variables?: variable[];
}

export interface listComponentsRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface componentsListResponse {
	items?: componentListItem[];
}

export interface componentListItem {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	title: string;
	moduleId?: moduleId;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface ComponentId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
}

export interface deleteComponentRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
}

export interface infrastructureComponentInstance {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	componentId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	label: string;
	variables?: variable[];
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface createComponentInstanceRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	componentId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	label: string;
	variables?: variable[];
}

export interface listComponentInstancesRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
}

export interface componentInstancesListResponse {
	items?: componentInstanceListItem[];
}

export interface componentInstanceListItem {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	componentId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	label: string;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface ComponentInstanceId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
}

export interface deleteComponentInstanceRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
}

export interface registeredComponentInstance {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	componentInstanceId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	registeredBy: string;
	usage?: string;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface registerComponentInstanceRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	componentInstanceId: string;
	registeredBy?: string;
	usage?: string;
}

export interface listComponentInstanceRegistrationsRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
}

export interface componentInstanceRegistrationsListResponse {
	items?: componentInstanceRegistrationListItem[];
}

export interface componentInstanceRegistrationListItem {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	componentInstanceId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	registeredBy: string;
	usage?: string;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface ComponentInstanceRegistrationId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
}

export interface deRegisterComponentInstanceRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
}

export interface createEnvsFromTemplateReq_env {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	classificationId: string;
	// Optional. Default will be Classification name
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	// Optional. Default will be Template name and description
	description?: string;
	// Optional. Default nil
	variables?: variable[];
	// Optional. Default nil
	metadata?: Record<string, unknown>;
}

export interface createEnvsFromTemplateReq {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	templateId: string;
	// envs is an array of envs to create as a set
	// the sequence of this array defines the promotion sequence
	// if left empty, the backend tries to automatically infer this
	// based on the cloud credentials applied on the project
	// with classifications.
	envs?: createEnvsFromTemplateReq_env[];
}

export interface ciCheck {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	moduleRefID: string;
	// optional
	expectOutput?: Record<string, unknown>;
}

export interface createClassificationRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	// For now we only accept non-sensitive variables. Application code has check for this.
	variables?: variable[];
	policySetId?: string;
	ancestorId?: string;
	metadata?: Record<string, unknown>;
	ciChecks?: ciCheck[];
}

export interface updateClassificationRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	variables?: variable[];
	policySetId?: string;
	ancestorId?: string;
	// replaceVariables
	//
	// If false, the variables will not be updated. If true, all the existing
	// variables will be replaced with the given set.
	replaceVariables?: boolean;
	metadata?: Record<string, unknown>;
	ciChecks?: ciCheck[];
}

export interface deleteClassificationRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	orgId: string;
}

export interface listClassificationsRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	orgId: string;
}

export interface getClassificationsRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	orgId: string;
}

export interface classification {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	variables?: variable[];
	policySetId?: string;
	ancestorId?: string;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
	metadata?: Record<string, unknown>;
	encryptedSecrets?: string;
	ciChecks?: ciCheck[];
}

export interface classifications {
	classifications?: classification[];
}

export interface classificationId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface DependencyCreateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	group?: string;
	// map of: VariableKey: Variable description
	// Options: [{"(validate.rules)":{"map":{"keys":{"string":{"pattern":"^[a-zA-Z]([_a-zA-Z0-9]){0,52}$"}}}}}]
	inputs: Record<string, string>;
	// map of: VariableKey: Variable description
	// Options: [{"(validate.rules)":{"map":{"min_pairs":1,"keys":{"string":{"pattern":"^[a-zA-Z]([_a-zA-Z0-9]){0,52}$"}}}}}]
	outputs: Record<string, string>;
	metadata?: Record<string, unknown>;
}

export interface DependencyUpdateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	group?: string;
	// map of: VariableKey: Variable description
	// Options: [{"(validate.rules)":{"map":{"keys":{"string":{"pattern":"^[a-zA-Z]([_a-zA-Z0-9]){0,52}$"}}}}}]
	inputs: Record<string, string>;
	// map of: VariableKey: Variable description
	// Options: [{"(validate.rules)":{"map":{"min_pairs":1,"keys":{"string":{"pattern":"^[a-zA-Z]([_a-zA-Z0-9]){0,52}$"}}}}}]
	outputs: Record<string, string>;
	metadata?: Record<string, unknown>;
}

export interface Dependency {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	// map of: VariableKey: Variable description
	// Options: [{"(validate.rules)":{"map":{"keys":{"string":{"pattern":"^[a-zA-Z]([_a-zA-Z0-9]){0,52}$"}}}}}]
	inputs: Record<string, string>;
	// map of: VariableKey: Variable description
	// Options: [{"(validate.rules)":{"map":{"min_pairs":1,"keys":{"string":{"pattern":"^[a-zA-Z]([_a-zA-Z0-9]){0,52}$"}}}}}]
	outputs: Record<string, string>;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
	group?: string;
	metadata?: Record<string, unknown>;
}

export interface DependencyId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface DependencyListRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Optional. filter by name
	name?: string;
}

export interface DependencyListResponse {
	items?: Dependency[];
}

export interface DependencyResolveRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	dependencyId: string;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	infraProviderKind: infrastructureProvider;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	infraProviderId: string;
	inputMap?: Record<string, string>;
	outputMap?: Record<string, string>;
}

export interface UpdateDependencyResolverRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	dependencyId: string;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	infraProviderKind: infrastructureProvider;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	infraProviderId: string;
	inputMap?: Record<string, string>;
	outputMap?: Record<string, string>;
}

export interface DependencyResolver {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	dependencyId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	infraProviderKind: infrastructureProvider;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	infraProviderId: string;
	inputMap?: Record<string, string>;
	outputMap?: Record<string, string>;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface DependencyResolverList {
	items?: DependencyResolver[];
}

export interface DependencyResolverId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	dependencyId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
}

export interface LibraryExportRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface LibraryImportRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// currently, only git artifact is supported.
	// Options: [{"(validate.rules)":{"message":{"required":true}}}]
	artifact: ArtifactConfig;
	// optional; credential ID to access the artifact
	artifactCredId?: string;
	// optional; name of the library index file [default: index.yaml]
	indexFileName?: string;
}

export interface JobUpdateEventData {
	view?: jobLongResponse;
	environment?: environment;
}

export interface infraService {
	// Service health check
	// Options: [{"(google.api.http)":{"get":"/v0/healthcheck/*"}}]
	healthCheck(request: HealthCheckRequest): Promise<HealthCheckResponse>;

	// Get Pipeline Config Schema
	//
	// Get the pipeline config schema for infra service pipeline
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/env-pipeline-config-schema"}}]
	pipelineConfigSchemaGet(
		request: envPipelineConfigSchemaReq
	): Promise<envPipelineConfigSchemaResp>;

	// Create an environment
	//
	// An environment is a part of a project. An environment holds the infrastructure that a project's applications will use, and specifies the location where a project's applications are deployed.<br><br>
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs","body":"*"}}]
	createEnv(request: environmentCreate): Promise<EnvironmentId>;

	// Get an environment
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}"}}]
	getEnv(request: EnvironmentId): Promise<environment>;

	// List environments
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs"}}]
	listEnv(request: listEnvRequest): Promise<environments>;

	// Update an environment
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}","body":"*"}}]
	updateEnv(request: environmentUpdate): Promise<Record<string, never>>;

	// Delete an environment
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}"}}]
	deleteEnv(request: environmentDelete): Promise<success>;

	// Delete all child entities of this environment
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}/delete-all"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Delete all entities owned by this environment."}}]
	deleteEnvEntities(request: envEntitiesDeleteRequest): Promise<success>;

	// BeforeEnvCredChange runs cleanup tasks before creds get changed on this environment
	beforeEnvCredChange(request: envBeforeCredChange): Promise<success>;

	// Grant a user access to an environment
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/users/{userId=*}","body":"*"}}]
	grantEnvAccess(request: envAccessGrant): Promise<success>;

	// Revoke a user's access from an environment
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/users/{userId=*}"}}]
	revokeEnvAccess(request: envAccessRevoke): Promise<success>;

	// List who has access to an environment
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}/users"}}]
	listEnvAccess(request: EnvironmentId): Promise<UserRoleList>;

	// Start an environment pipeline
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs","body":"*"}}]
	start(request: triggerRequest): Promise<jobLongResponse>;

	// Resume an environment pipeline
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs/{id=*}/actions/resume","body":"*"}}]
	resume(request: jobId): Promise<success>;

	// Cancel an environment pipeline
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs/{id=*}/actions/cancel","body":"*"}}]
	cancel(request: jobId): Promise<success>;

	// Get the status of an environment pipeline
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs/{id=*}"}}]
	status(request: jobId): Promise<jobLongResponse>;

	// List pipelines for an environment
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs"}}]
	list(request: jobListRequest): Promise<jobs>;

	// Get an artifact from an environment pipeline
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{job.orgId=*}/projects/{job.projId=*}/envs/{job.envId=*}/jobs/{job.id=*}/artifacts/{artifactName=*}"}}]
	getJobArtifact(request: artifactRequest): Promise<Record<string, any>>;

	// Get the validation results
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs/{id=*}/validationresult"}}]
	validationResult(request: jobId): Promise<EvalResponse>;

	// Get the plan output
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs/{id=*}/plan"}}]
	plan(request: jobId): Promise<Record<string, any>>;

	// Get the environment outputs
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs/{id=*}/output"}}]
	output(request: jobId): Promise<Record<string, any>>;

	// List the available pipeline logs
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs/{id=*}/logs"}}]
	logs(request: jobId): Promise<logFiles>;

	// Get a pipleline log
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{job.orgId=*}/projects/{job.projId=*}/envs/{job.envId=*}/jobs/{job.id=*}/logs/{logFile=*}"}}]
	log(request: logRequest): Promise<logResponse>;

	// Get the environment state info
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}/output"}}]
	envState(request: EnvironmentId): Promise<Record<string, any>>;

	// Get the environment output summary
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}/outputSummary"}}]
	envStateSummary(request: EnvStateRequest): Promise<EnvStateResponse>;

	// Get the infrastructure services list
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/jobs/{jobId=*}/services"}}]
	getServicesList(request: servicesListRequest): Promise<servicesListResponse>;

	// Get an environment in the promotion sequence
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}/promotion-sequence"}}]
	getPromotionSequence(request: EnvironmentId): Promise<promotionSequenceNode>;

	// List the promotion sequence
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/promotion-sequence"}}]
	listPromotionSequence(request: promotionSequenceListRequest): Promise<promotionSequenceNodeList>;

	// Add an environment to a promotion sequence
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}/promotion-sequence","body":"*"}}]
	linkToPromotionSequence(request: promotionLinkRequest): Promise<promotionSequenceNode>;

	// Remove an environment from a promotion sequence
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}/promotion-sequence"}}]
	unLinkFromPromotionSequence(request: EnvironmentId): Promise<Record<string, never>>;

	// Upload infra state to an environment
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{id=*}/state","body":"*"}}]
	uploadEnvState(request: uploadEnvStateRequest): Promise<success>;

	// Update one or more environment variables
	// Options: [{"(google.api.http)":{"patch":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/vars","body":"*"}}]
	updateVariables(request: updateVariablesRequest): Promise<success>;

	// Delete one or more environment variables
	// Options: [{"(google.api.http)":{"patch":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/vars/delete","body":"*"}}]
	deleteVariables(request: deleteVariablesRequest): Promise<success>;

	// CreateComponent declare an infrastructure component
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/components","body":"*"}}]
	createComponent(request: createComponentRequest): Promise<infrastructureComponent>;

	// UpdateComponent declare an infrastructure component
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/components/{id=*}","body":"*"}}]
	updateComponent(request: updateComponentRequest): Promise<success>;

	// ListComponents return a list of existing components declarations
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/components"}}]
	listComponents(request: listComponentsRequest): Promise<componentsListResponse>;

	// Get return a components declaration
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/components/{id=*}"}}]
	getComponent(request: ComponentId): Promise<infrastructureComponent>;

	// DeleteComponent deletes a component declaration
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/components/{id=*}"}}]
	deleteComponent(request: deleteComponentRequest): Promise<success>;

	// CreateComponentInstance create a reusable infrastructure component instance
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/component-instances","body":"*"}}]
	createComponentInstance(
		request: createComponentInstanceRequest
	): Promise<infrastructureComponentInstance>;

	// UpsertComponentInstance creates a new infrastructure component instance or updates the instance with matching label
	upsertComponentInstance(
		request: createComponentInstanceRequest
	): Promise<infrastructureComponentInstance>;

	// ListComponentInstances return a list of existing component instances
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/component-instances"}}]
	listComponentInstances(
		request: listComponentInstancesRequest
	): Promise<componentInstancesListResponse>;

	// Get return a component instance
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/component-instances/{id=*}"}}]
	getComponentInstance(request: ComponentInstanceId): Promise<infrastructureComponentInstance>;

	// DeleteComponentInstance deletes a component instance
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/component-instances/{id=*}"}}]
	deleteComponentInstance(request: deleteComponentInstanceRequest): Promise<success>;

	// RegisterComponentInstance register use of an infrastructure component with an environment
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/component-registrations","body":"*"}}]
	registerComponentInstance(
		request: registerComponentInstanceRequest
	): Promise<registeredComponentInstance>;

	// ListComponentInstanceRegistrations return a list of components registered with an environment
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/component-registrations"}}]
	listComponentInstanceRegistrations(
		request: listComponentInstanceRegistrationsRequest
	): Promise<componentInstanceRegistrationsListResponse>;

	// Get return a component instance registration
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/component-registrations/{id=*}"}}]
	getComponentInstanceRegistration(
		request: ComponentInstanceRegistrationId
	): Promise<registeredComponentInstance>;

	// DeRegisterComponentInstance unregister use of an infrastructure component with an environment
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/component-registrations/{id=*}"}}]
	deRegisterComponentInstance(request: deRegisterComponentInstanceRequest): Promise<success>;

	// CreateEnvsFromTemplate
	//
	// This API can be used to create multiple environments at once
	// using env template and classes.
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/templates/{templateId=*}/envs","body":"*"}}]
	createEnvsFromTemplate(request: createEnvsFromTemplateReq): Promise<environments>;

	// DependencyCreate
	//
	// This API can be used to create a new dependency interface
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/dependencies","body":"*"}}]
	dependencyCreate(request: DependencyCreateRequest): Promise<Dependency>;

	// DependencyUpdate
	//
	// This API can be used to update an existing dependency interface
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/dependencies/{id=*}","body":"*"}}]
	dependencyUpdate(request: DependencyUpdateRequest): Promise<Dependency>;

	// DependencyList
	//
	// This API can be used to list all the dependencies available to the organization
	// It also includes public dependencies that are not associated with the given organization ID
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/dependencies"}}]
	dependencyList(request: DependencyListRequest): Promise<DependencyListResponse>;

	// DependencyGet
	//
	// This API can be used to get a dependency interface by ID
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/dependencies/{id=*}"}}]
	dependencyGet(request: DependencyId): Promise<Dependency>;

	// DependencyDelete
	//
	// This API can be used to delete a dependency interface by ID
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/dependencies/{id=*}"}}]
	dependencyDelete(request: DependencyId): Promise<Record<string, never>>;

	// Create a Template
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/templates","body":"*"}}]
	createTemplate(request: createTemplateRequest): Promise<template>;

	// Get a Template
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/templates/{id=*}"}}]
	getTemplate(request: getTemplateRequest): Promise<template>;

	// Update a Template
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/templates/{id=*}","body":"*"}}]
	updateTemplate(request: updateTemplateRequest): Promise<success>;

	// Delete a Template
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/templates/{id=*}"}}]
	deleteTemplate(request: deleteTemplateRequest): Promise<success>;

	// ListTemplates
	//
	// This API can be used to list all the templates available in the
	// organization.
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/templates"}}]
	listTemplates(request: listTemplatesRequest): Promise<listTemplatesResponse>;

	// Create a Classification
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/classifications","body":"*"}}]
	createClassification(request: createClassificationRequest): Promise<classification>;

	// Get a Classification
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/classifications/{id=*}"}}]
	getClassification(request: getClassificationsRequest): Promise<classification>;

	// List Classifications
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/classifications"}}]
	listClassification(request: listClassificationsRequest): Promise<classifications>;

	// Update a Classification
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/classifications/{id=*}","body":"*"}}]
	updateClassification(request: updateClassificationRequest): Promise<success>;

	// Delete a Classification
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/classifications/{id=*}"}}]
	deleteClassification(request: deleteClassificationRequest): Promise<success>;

	// Update one or more classification variables
	// Options: [{"(google.api.http)":{"patch":"/v0/organizations/{orgId=*}/classifications/{id=*}/vars","body":"*"}}]
	updateClassificationVariables(request: updateClassificationVariablesRequest): Promise<success>;

	// Delete one or more classification variables
	// Options: [{"(google.api.http)":{"patch":"/v0/organizations/{orgId=*}/classifications/{id=*}/vars/delete","body":"*"}}]
	deleteClassificationVariables(request: deleteClassificationVariablesRequest): Promise<success>;

	// Resolve dependency by linking it to an infrastructure provider
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/dependencies/{dependencyId=*}/resolvers","body":"*"}}]
	resolveDependency(request: DependencyResolveRequest): Promise<DependencyResolver>;

	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/dependencies/{dependencyId=*}/resolvers/{id=*}","body":"*"}}]
	updateDependencyResolvers(request: UpdateDependencyResolverRequest): Promise<DependencyResolver>;

	// List resolved dependencies
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/dependencies/{id=*}/resolvers"}}]
	listDependencyResolvers(request: DependencyId): Promise<DependencyResolverList>;

	// Get resolved dependency
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/dependencies/{dependencyId=*}/resolvers/{id=*}"}}]
	getDependencyResolver(request: DependencyResolverId): Promise<DependencyResolver>;

	// Unlink dependency from an infrastructure provider
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/dependencies/{dependencyId=*}/resolvers/{id=*}"}}]
	unResolveDependency(request: DependencyResolverId): Promise<success>;

	// Export reusable infrastructure entities
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/infrastructure-library"}}]
	exportInfrastructureLibrary(request: LibraryExportRequest): Promise<Record<string, any>>;

	// Import reusable infrastructure entities
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/infrastructure-library","body":"*"}}]
	importInfrastructureLibrary(request: LibraryImportRequest): Promise<Record<string, any>>;
}
