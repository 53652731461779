/* eslint-disable */

export enum ArtifactType {
	artifact_undefined = "artifact_undefined",
	GitCode = "GitCode",
	ContainerImage = "ContainerImage",
	BucketObject = "BucketObject",
	Files = "Files"
}

export enum CloudBucketProvider {
	no_bucket_provider = "no_bucket_provider",
	google_bucket_provider = "google_bucket_provider"
}

export enum ContainerRegistryProvider {
	custom_registry = "custom_registry",
	google_container_registry = "google_container_registry",
	dockerhub_container_registry = "dockerhub_container_registry",
	gitlab_container_registry = "gitlab_container_registry",
	elastic_container_registry = "elastic_container_registry",
	public_elastic_container_registry = "public_elastic_container_registry",
	azure_container_registry = "azure_container_registry"
}

export enum GitRevisionType {
	no_git_revision_support = "no_git_revision_support",
	branch = "branch",
	tag = "tag",
	commit = "commit"
}

export enum GitServer {
	gitgeneric = "gitgeneric",
	github = "github",
	gitlab = "gitlab",
	codecommit = "codecommit"
}

export enum sourceEnum {
	source_no = "source_no",
	source_go = "source_go",
	source_node = "source_node",
	source_java = "source_java",
	source_dotnet = "source_dotnet",
	source_csharp = "source_csharp",
	source_html = "source_html",
	source_js = "source_js",
	source_ts = "source_ts"
}

export enum DataType {
	no_data_type_support = "no_data_type_support",
	string = "string",
	integer = "integer",
	float = "float",
	bool = "bool",
	list = "list",
	map = "map"
}

export enum Provisioner {
	no_provisioner = "no_provisioner",
	gcp = "gcp",
	aws = "aws",
	azure = "azure"
}

export enum BuildStatus {
	Unknown = "Unknown",
	Queued = "Queued",
	Working = "Working",
	Success = "Success",
	Failure = "Failure",
	InternalError = "InternalError",
	Timeout = "Timeout",
	Cancelled = "Cancelled",
	Expired = "Expired"
}

export enum VGEntities {
	invalid_entity = "invalid_entity",
	organization = "organization",
	project = "project",
	environment = "environment",
	application = "application",
	deployment = "deployment"
}

export enum defaultSource {
	deploymentTemplate = "deploymentTemplate",
	environmentTemplate = "environmentTemplate"
}

export enum variableType {
	no_var_type_support = "no_var_type_support",
	env_var = "env_var",
	tf_var = "tf_var"
}

export enum varProviders {
	var_invalid = "var_invalid",
	var_organization = "var_organization",
	var_project = "var_project",
	var_environment = "var_environment",
	var_application = "var_application",
	var_integration = "var_integration",
	var_deployment = "var_deployment",
	var_classification = "var_classification",
	var_credentials = "var_credentials"
}

export interface ArtifactConfig {
	gitCode?: GitRevision;
	containerImage?: ContainerRevision;
	bucketObject?: CloudBucket;
	files?: RawArtifact;
}

export interface Artifact {
	id?: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	gitCode?: GitRevision;
	containerImage?: ContainerRevision;
	bucketObject?: CloudBucket;
	files?: RawArtifact;
	// this is read only field
	type?: ArtifactType;
}

export interface RawArtifact {
	// map of fileName:isSensitive
	files?: Record<string, boolean>;
}

export interface CloudBucket {
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	provider: CloudBucketProvider;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	bucketName: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	objectsPath: string;
}

export interface ContainerRevision {
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true}}}]
	provider: ContainerRegistryProvider;
	host?: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	repo: string;
	reference?: string;
	digest?: string;
	versionTagPattern?: string;
}

export interface GitRevision {
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	type: GitRevisionType;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	identifier: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	repo: string;
	dir?: string;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true}}}]
	driver: GitServer;
	resolvedCommitHash?: string;
}

export interface Git {
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true}}}]
	driver: GitServer;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	authToken: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	authType: string;
}

export interface ArtifactSelector {
	id?: string;
	gitCode?: GitRevisionOverride;
	containerImage?: ContainerRevisionOverride;
	bucketObject?: CloudBucketOverride;
	files?: RawArtifactOverride;
}

export interface GitRevisionOverride {
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	type: GitRevisionType;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	identifier: string;
}

export interface ContainerRevisionOverride {
	reference?: string;
	digest?: string;
}

export interface CloudBucketOverride {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	objectsPath: string;
}

export interface RawArtifactOverride {}

export interface ApplicationId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
}

export interface AppIntegrationId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	appId: string;
}

export interface StepHistory {
	duration?: string;
	startTime?: string;
}

export interface JobStep {
	name?: string;
	description?: string;
	history?: StepHistory;
	logs?: string[];
}

export interface Dependency {
	// name of the dependency in org's dependency interface collection. used to identify the interface
	dependencyName?: string;
	// Inputs for the interface.
	// The key is the input variable name and value has different interpretation
	// based on the context.
	// 1. for dependency interface metadata, it'll have description of each input field
	// 2. for app & app deployment, it'll have value the user provided input values
	inputs?: Record<string, string>;
	// Outputs expected from the interface
	// key-value interpretation will change based on the context
	// 1. for dependency interface, it'll be the output variable name and value
	// 2. for app & app-deployment, its the mapping of original key to a renamed key
	outputs?: Record<string, string>;
}

export interface DependencyDiff {
	added?: Record<string, Dependency>;
	updated?: Record<string, Dependency>;
	removed?: Record<string, Dependency>;
}

export interface OrganizationId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
}

export interface ProjectId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface JSONSchema {
	title?: string;
	description?: string;
	// string, number, integer, boolean, object, array, null
	type?: string;
	// the default value
	default?: unknown;
	// string + number + integer
	examples?: unknown[];
	enum?: unknown[];
	// string. ref: https://json-schema.org/understanding-json-schema/reference/string.html
	minLength?: number;
	maxLength?: number;
	pattern?: string;
	format?: string;
	// number + integer. ref: https://json-schema.org/understanding-json-schema/reference/numeric.html
	minimum?: number;
	maximum?: number;
	exclusiveMinimum?: number;
	exclusiveMaximum?: number;
	multipleOf?: number;
	// array. ref: https://json-schema.org/understanding-json-schema/reference/array.html
	items?: JSONSchema;
	additionalItems?: boolean;
	minItems?: number;
	maxItems?: number;
	uniqueItems?: boolean;
	// object. ref: https://json-schema.org/understanding-json-schema/reference/object.html
	properties?: Record<string, JSONSchema>;
	required?: string[];
	// custom fields
	internal?: string[];
	// source of the the default value
	defaultSource?: defaultSource;
	// help find relevant infra output keys
	infraOutputRegex?: string;
}

export interface variable {
	// Options: [{"(validate.rules)":{"string":{"pattern":"^[^0-9\\s][^\\s]*$"}}}]
	key: string;
	value?: string;
	sensitive?: boolean;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	type: variableType;
}

export interface variableIndexView {
	value?: string;
	sensitive?: boolean;
	inheritedFromType?: varProviders;
	inheritedFromId?: string;
	override?: variableIndexView;
}

export interface entityVariableViewIndex {
	// Entities value map: Entity_ID: Variable_View
	entities?: Record<string, variableIndexView>;
}

// allVariableViewIndex this type provides a index to access the variables in user friendly index view
// the index focuses on providing a view that works best for comparing variable values across entities
export interface AllVariableViewIndex {
	// Environment variables map: Variable_Key: Entity_Variable_View_Index
	envVariables?: Record<string, entityVariableViewIndex>;
	// Terraform variables map: Variable_Key: Entity_Variable_View_Index
	tfVariables?: Record<string, entityVariableViewIndex>;
}

export interface DeploymentId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	EnvId: string;
}

export interface EnvironmentId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
}
