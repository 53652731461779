<template>
	<Container direction="column">
		<Container align="center" padding="0 0 20px 0">
			<Icon
				:name="googleProvider.icon"
				:disabled="isAuthenticating"
				data-qa-login-google
				type="filled"
				@click="signInUsingIdentityProvider(googleProvider)"
			/>
			<div class="vert-line-36"></div>
			<Icon
				:name="azureProvider.icon"
				:disabled="isAuthenticating"
				data-qa-login-azure-sso
				type="filled"
				@click="signInUsingIdentityProvider(azureProvider)"
			/>
			<div class="vert-line-36"></div>
			<Icon
				:name="githubProvider.icon"
				:disabled="isAuthenticating"
				data-qa-login-github
				type="filled"
				@click="signInUsingIdentityProvider(githubProvider)"
			/>
		</Container>
		<Container v-if="errorMessage" align="center">
			<f-text state="danger">{{ errorMessage }}</f-text>
		</Container>
	</Container>
</template>

<script lang="ts">
import { Container, Icon } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import {
	AUTH_IDENTITY_PROVIDER,
	AUTH_IDENTITY_PROVIDER_REDIRECT_ROUTE,
	AuthIdentityProviderType
} from "@/modules/auth/constants";
import { userStore } from "@/modules/user/user-store";
import { captureError, getErrorMessage, reportLogin } from "@/utils";

import { authStore } from "../auth-store";
import { getUserFromIdentityCode, signInUsingIdentityProvider } from "../services/auth-service";

export default defineComponent({
	name: "WrapperSignInIdentityProvider",

	components: {
		Icon,
		Container
	},

	data() {
		return {
			errorMessage: null as string | null,
			isAuthenticating: false,
			googleProvider: AUTH_IDENTITY_PROVIDER.GOOGLE,
			githubProvider: AUTH_IDENTITY_PROVIDER.GITHUB,
			azureProvider: AUTH_IDENTITY_PROVIDER.AZURE
		};
	},

	created() {
		this.handleIdentityProviderCode();
	},

	methods: {
		async signInUsingIdentityProvider({ idpName }: AuthIdentityProviderType) {
			const appRedirect = this.$route.query.redirect
				? `?redirect=${this.$route.query.redirect}&`
				: "";

			const clientRedirect = `${window.VUE_APP_SITE_URL}${AUTH_IDENTITY_PROVIDER_REDIRECT_ROUTE}${appRedirect}`;

			if (!clientRedirect) {
				return false;
			}

			try {
				this.isAuthenticating = true;
				const response = await signInUsingIdentityProvider({
					sso: { idpName, clientRedirect }
				});

				if (response.isRedirect && response.redirectURL) {
					window.open(response.redirectURL, "_self");
					return;
				}
			} catch (error) {
				this.errorMessage = getErrorMessage(error);
				captureError(error);
			}

			this.isAuthenticating = false;
		},

		async handleIdentityProviderCode() {
			const queryCode = this.$route.query.code ?? this.$route.query["?code"];
			const identityProviderCode = typeof queryCode === "string" ? queryCode : null;

			if (!identityProviderCode) {
				return;
			}

			try {
				this.isAuthenticating = true;

				const authResponse = await getUserFromIdentityCode({ code: identityProviderCode });

				const user = await authStore.LOGIN_SUCCESS();

				// Update user name from Oauth data if missing
				if (!user.name && authResponse.metadata?.name) {
					await userStore.UPDATE_USER({
						name: authResponse.metadata.name,
						metadata: user.metadata
					});
				}

				if (user.id) {
					const { redirect } = this.$route.query;

					if (typeof redirect === "string") {
						await this.$router.push(redirect);
					} else {
						await this.$router.push({
							name: "home",
							params: { orgId: user.id }
						});
					}

					if (authResponse.signinMethod) {
						reportLogin(authResponse.signinMethod);
					}
				}
			} catch (error) {
				this.errorMessage = getErrorMessage(error);
				captureError(error);
			} finally {
				this.isAuthenticating = false;
			}
		}
	}
});
</script>
