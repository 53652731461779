import { ChecklistStep, InfoContent } from "@/modules/org/orgCreateFlowSteps";

export const APP_WIZARD_FLOW_INFO_POPOVERS: InfoContent[] = [
	{
		stepNo: 1,
		stepName: "connectApp",
		target: "connectAppPopover",
		infoContent: {
			title: "Add app",
			paragraphs: [
				"Add your apps from git or container platforms and easily manage its build and deployment pipelines."
			],
			link: {
				label: "Learn more about app management",
				url: "https://docs.codepipes.io/docs/whats-an-application"
			}
		}
	},
	{
		stepNo: 2,
		stepName: "selectInfra",
		target: "selectInfraDeps",
		infoContent: {
			title: "Infrastructure dependencies",
			paragraphs: [
				"Select infrastructure dependencies to ensure that your app has access to the necessary resources. Code Pipes will provision these on cloud automatically."
			],
			link: {
				label: "Learn more about dependencies",
				url: "https://docs.codepipes.io/docs/application-dependencies"
			}
		}
	},
	{
		stepNo: 4,
		stepName: "setupBuild",
		target: "selectBuildStep",
		infoContent: {
			title: "Preconfigured build pipelines",
			paragraphs: [
				"These are the preconfigured build pipelines and git webhooks based on the app configurations."
			],
			link: {
				label: "Learn more about build pipelines",
				url: "https://docs.codepipes.io/docs/what-is-application-integration"
			}
		}
	}
];

export const APP_WIZARD_FLOW_CHECKLIST_STEPS: ChecklistStep[] = [
	{
		stepNo: 1,
		stepName: "connectApp",
		stepLabel: "Connect application source",
		desc: "Connect your application source from git or container platform.",
		disabled: false,
		completed: false
	},
	{
		stepNo: 2,
		stepName: "selectInfra",
		stepLabel: "Select infrastructure dependencies",
		desc: "Select infrastructure dependencies your app requires to get deployed",
		disabled: false,
		completed: false
	},
	{
		stepNo: 3,
		stepName: "setupBuild",
		stepLabel: "Setup build pipeline",
		desc: "Setup build pipelines and its webhooks with preconfigured integration modules.",
		disabled: false,
		completed: false
	}
];

export enum AppWizardChecklistEnum {
	NOT_SELECTED = 0,
	CONNECT_APP = 1,
	SELECT_INFRA = 2,
	DEPLOY_CONFIG = 3,
	SETUP_BUILD = 4
}
