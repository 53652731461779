const GITHUB_URL_REGEX =
	/^(?<protocol>https?:\/\/(www\.)?)?(?<repo>github\.com\/[^/]+\/[^/]+)(\/tree\/(?<identifier>[^/]+))?(?<path>.*)/;

const GITLAB_URL_REGEX =
	/^(?<protocol>https?:\/\/(www\.)?)?(?<repo>gitlab\.com\/[^/]+\/[^/]+)(\/-\/tree\/(?<identifier>[^/]+))?(?<path>.*)/;

export function parseGitUrl(url: string) {
	const match = url.match(GITHUB_URL_REGEX) ?? url.match(GITLAB_URL_REGEX);

	if (!match?.groups) {
		return null;
	}

	const { protocol, repo, path, identifier } = match.groups;

	const protocolRepo = protocol ? `${protocol}${repo}` : `https://${repo}`;

	return { protocolRepo, repo, path, identifier };
}
