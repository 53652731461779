<template>
	<!-- Connect app form -->
	<f-div
		v-if="currentStep === checkListStepEnum.CONNECT_APP || isOauthComplete"
		id="connectAppPopover"
		width="100%"
		direction="column"
		align="top-left"
		position="relative"
	>
		<AddAppConnectSource
			:is-oauth-complete="isOauthComplete"
			:app="newApp"
			:is-editing="isEditingApp"
			@back="setPriviousStep(checkListStepEnum.CONNECT_APP)"
			@toggle-info="$emit('toggle-info')"
			@close="$emit('close')"
		/>
	</f-div>
	<!-- Select infra form -->
	<f-div
		v-if="currentStep === checkListStepEnum.SELECT_INFRA && newApp"
		id="selectInfraDeps"
		width="100%"
		direction="column"
		align="top-left"
		position="relative"
	>
		<AddAppSelectInfraDependencies
			:app-id="newApp.id"
			@back="setPriviousStep(checkListStepEnum.SELECT_INFRA)"
			@deps-updated="setNextStep(checkListStepEnum.DEPLOY_CONFIG)"
			@toggle-info="$emit('toggle-info')"
			@close="$emit('close')"
		/>
	</f-div>
	<!-- Setup deploy config step -->
	<f-div
		v-if="currentStep === checkListStepEnum.DEPLOY_CONFIG && newApp"
		id="selectDeployConfigStep"
		width="100%"
		direction="column"
		align="top-left"
		overflow="hidden"
		style="max-height: 80vh"
	>
		<AddAppDeployConfigStep
			:app-id="newApp.id"
			:provisioner="provisioner"
			@app-updated="setNextStep(checkListStepEnum.SETUP_BUILD)"
			@back="setPriviousStep(checkListStepEnum.DEPLOY_CONFIG)"
			@set-inferred-pipeline="setInferredPipeline"
			@toggle-info="$emit('toggle-info')"
			@close="$emit('close')"
		/>
	</f-div>

	<!-- Select build step -->
	<f-div
		v-if="currentStep === checkListStepEnum.SETUP_BUILD && newApp"
		id="selectBuildStep"
		width="100%"
		direction="column"
		align="top-left"
		position="relative"
	>
		<AddAppBuildSetupStep
			:app-id="newApp.id"
			:provisioner="provisioner"
			:inferred-pipeline="inferredPipeline"
			@back="setPriviousStep(checkListStepEnum.SETUP_BUILD)"
			@toggle-info="$emit('toggle-info')"
			@close="$emit('close')"
		/>
	</f-div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import { getCredByScope } from "@/modules/credentials/credential-store";
import { credsTypeToProvisionerMap } from "@/modules/credentials/credential-types";
import { userStore } from "@/modules/user/user-store";
import { app as appProto, ciInferResponse } from "@/protocol/app";
import { Provisioner } from "@/protocol/common";
import { CredScope } from "@/protocol/identity";

import { applicationStore } from "../application-store";
import { AppWizardChecklistEnum } from "../views/appWizardFlowSteps";

import AddAppBuildSetupStep from "./AddAppBuildSetupStep.vue";
import AddAppConnectSource from "./AddAppConnectSource.vue";
import AddAppDeployConfigStep from "./AddAppDeployConfigStep.vue";
import AddAppSelectInfraDependencies from "./AddAppSelectInfraDependencies.vue";

export default defineComponent({
	name: "AppFlowStepComponents",

	components: {
		AddAppConnectSource,
		AddAppSelectInfraDependencies,
		AddAppDeployConfigStep,
		AddAppBuildSetupStep
	},

	props: {
		isOauthComplete: {
			type: Boolean,
			default: () => false
		}
	},

	emits: ["back", "toggle-info", "close", "new-app-created"],

	data: () => ({
		checkListStepEnum: AppWizardChecklistEnum,
		newApp: null as appProto | null,
		isEditingApp: false,

		credsTypeToProvisioner: {
			credsType_aws: Provisioner.aws,
			credsType_gcp: Provisioner.gcp,
			credsType_azure: Provisioner.azure
		} as credsTypeToProvisionerMap,

		inferredPipeline: null as ciInferResponse | null
	}),

	computed: {
		currentStep() {
			return applicationStore.currAppWizardStep;
		},

		projectId() {
			return this.$route.params.projectId as string;
		},

		provisioner() {
			if (!this.projectCred?.type) {
				if (this.newApp) {
					const userMeta = userStore.profile?.metadata?.appWizardMeta?.[this.newApp.id];
					return userMeta?.provisioner ?? undefined;
				}
				return undefined;
			}
			return this.credsTypeToProvisioner[this.projectCred.type];
		},

		projectCred() {
			return getCredByScope({
				entityId: this.projectId,
				scope: CredScope.cloud
			})[0];
		},

		apps() {
			return applicationStore.apps;
		},

		newAppId() {
			return applicationStore.lastCreatedAppId;
		}
	},

	watch: {
		newAppId: {
			immediate: true,

			handler() {
				if (this.newAppId !== null) {
					/**
					 * If the app length is 1 the last created app id matches to it then the user has just created a new app
					 */
					this.newApp = this.apps[this.newAppId] ?? null;
					this.$emit("new-app-created");
					applicationStore.UPDATE_APP_WIZARD_STEP(AppWizardChecklistEnum.SELECT_INFRA);
					applicationStore.SET_LAST_APP_ID(null);
				}
			}
		}
	},

	methods: {
		setPriviousStep(step: AppWizardChecklistEnum) {
			applicationStore.UPDATE_APP_WIZARD_STEP(step - 1);

			// App is already created, so we open the edit app feature in the first step now.
			if (step === AppWizardChecklistEnum.SELECT_INFRA && this.newApp) {
				this.isEditingApp = true;
			} else {
				this.isEditingApp = false;
			}
		},

		setInferredPipeline(pipeline: ciInferResponse) {
			this.inferredPipeline = pipeline;
		},

		setNextStep(step: AppWizardChecklistEnum) {
			applicationStore.UPDATE_APP_WIZARD_STEP(step);
		}
	}
});
</script>
