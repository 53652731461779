<template>
	<Container align="space-between" data-qa-create-int-pipeline-header>
		<Container :padding="0" :shrink="0" :grow="1" overlay="auto">
			<f-icon :source="provisionerIcon" :data-qa-pipeline-provisioner="provisioner"></f-icon>
			<InputText
				v-model:value="integrationName"
				v-tooltip="{ content: integrationName }"
				:style="{ width: '300px' }"
				prefix="Pipeline name"
				:data-qa-int-pipeline-name-input="integrationName"
				:state="integrationName === '' ? 'error' : 'success'"
			/>

			<Container :gap="12" :padding="0">
				<PopOver :open="isVariableModalOpen" @overlay-click="isVariableModalOpen = false">
					<f-button
						id="variables-list-add-variable-primary-integrations"
						icon-left="i-variable"
						:counter="totalVars ? String(totalVars) : undefined"
						label="Variables"
						:data-qa-int-var-count="totalVars"
						data-qa-add-integration-variables
						@click="isVariableModalOpen = true"
					>
					</f-button>
					<template #content>
						<IntegrationVariableContent
							v-model:scope="variableScope"
							:integration="integration"
							@close-dialog="reloadVars"
							@vars-saved="variablesAdded"
						/>
					</template>
				</PopOver>

				<PopOver :open="isConfigModalOpen" placement="right-start">
					<f-button
						id="configure-pipeline"
						icon-left="i-setting"
						label="Configure"
						:data-qa-int-var-count="totalVars"
						data-qa-configure-integration-pipeline
						@click="isConfigModalOpen = true"
					></f-button>
					<template #content>
						<PipelineConfigurationContent
							v-model:pipeline-config="pipelineConfigInternal"
							:integration="integration"
							:provisioner="provisioner"
							@close="isConfigModalOpen = false"
						/>
					</template>
				</PopOver>
			</Container>
		</Container>

		<Container
			v-tooltip="{
				content:
					isSubmitting || !hasPipelineModules
						? 'Please add at least one module to the pipeline'
						: ''
			}"
			:padding="0"
			:shrink="0"
			overflow="auto"
			:grow="0"
			align="space-between"
		>
			<Button
				:disabled="isSubmitting || !hasPipelineModules"
				type="default"
				state="curved"
				overflow="nowrap"
				data-qa-save-integration-btn
				@click.stop="saveIntegration"
			>
				<Icon name="i-project-shape" color="gray-100" type="filled" size="small" />
				<Typography type="p2" color="gray-100" weight="bold">Save</Typography>
			</Button>

			<PopOver
				v-if="inputArtifact"
				placement="bottom-start"
				:open="openSaveRun"
				@overlay-click="
					openSaveRun = false;
					$emit('clear-err');
				"
			>
				<Button
					:disabled="isSubmitting || !hasPipelineModules"
					type="success"
					state="curved"
					overflow="nowrap"
					data-qa-save-and-run-btn
					@click="openSaveRun = true"
				>
					<Icon name="i-play" color="gray-600" type="filled" size="small" />
					<Typography type="p2" color="gray-600" weight="bold">Save & Run</Typography>
				</Button>
				<template #content>
					<ConfirmSaveAndRun
						:input-artifact="inputArtifact.data"
						:output-artifact="outputArtifact"
						:is-submitting="isSubmitting"
						:error-message="errorMessage"
						@close="
							openSaveRun = false;
							$emit('clear-err');
						"
						@confirmed="triggerPipeline"
					/>
				</template>
			</PopOver>
		</Container>
	</Container>
</template>

<script lang="ts">
import { Button, Container, Icon, InputText, PopOver, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { AddVariableScope, Variable } from "@/modules/variables-list/variable-list-types";
import { appIntegration } from "@/protocol/app";
import { Artifact, Provisioner, variableType } from "@/protocol/common";
import { getProvisionerIcon, getUniqueStringId } from "@/utils";

import { applicationIntegrationStore, ArtifactMeta } from "../application-integration-store";

import ConfirmSaveAndRun, { IntegrationPipelineConfirmConfig } from "./ConfirmSaveAndRun.vue";
import IntegrationVariableContent from "./IntegrationVariableContent.vue";
import PipelineConfigurationContent from "./PipelineConfigurationContent.vue";

export default defineComponent({
	name: "CreatePipelineHeader",

	components: {
		Icon,
		Button,
		PopOver,
		Container,
		Typography,
		InputText,
		ConfirmSaveAndRun,
		IntegrationVariableContent,
		PipelineConfigurationContent
	},

	props: {
		name: {
			type: String,
			default: () => ""
		},

		errorMessage: {
			type: String,
			required: true
		},

		isSubmitting: {
			type: Boolean,
			required: true
		},

		pipelineConfig: {
			type: Object as PropType<Record<string, unknown>>,
			required: true
		},

		provisioner: {
			type: String as PropType<Provisioner>,
			required: true
		},

		outputArtifact: {
			type: Object as PropType<Artifact>
		},

		inputArtifact: {
			type: Object as PropType<ArtifactMeta | null>
		},

		integration: {
			type: Object as PropType<appIntegration>
		},

		hasPipelineModules: Boolean
	},

	emits: ["create-integration", "clear-err", "vars-saved", "update:pipelineConfig"],

	data() {
		const integrationScopeId = this.integration?.id ?? getUniqueStringId();

		return {
			integrationName: "",
			openSaveRun: false,

			isVariableModalOpen: false,
			isConfigModalOpen: false,
			variableScope: {
				id: integrationScopeId,
				name: this.integration?.name ?? "Variables",
				variableFrom: "application-integration",
				variables: []
			} as AddVariableScope
		};
	},

	computed: {
		pipelineConfigInternal: {
			get() {
				return this.pipelineConfig;
			},

			set(pipelineConfig: Record<string, unknown>) {
				this.$emit("update:pipelineConfig", pipelineConfig);
			}
		},

		totalVars() {
			if (!this.integration) {
				const localVarsLength = this.variableScope.variables.length;
				return localVarsLength > 0 ? localVarsLength : null;
			}

			const configVarsLength =
				Object.keys(this.integration.config?.sensitive ?? {}).length +
				Object.keys(this.integration.config?.vars ?? {}).length;

			return configVarsLength > 0 ? configVarsLength : null;
		},

		provisionerIcon() {
			return getProvisionerIcon(this.provisioner);
		}
	},

	watch: {
		name() {
			this.integrationName = this.name;
		},

		integration: {
			deep: true,
			immediate: true,

			handler() {
				if (!this.integration) {
					return;
				}

				const arr: Variable[] = [];

				if (this.integration.config?.vars && Object.keys(this.integration.config.vars).length > 0) {
					const { vars } = this.integration.config;
					Object.keys(vars).forEach(key => {
						const val = vars[key] ?? "";
						arr.push({
							key,
							value: val,
							isMasked: false,
							variableType: variableType.env_var
						});
					});
				}

				if (
					this.integration.config?.sensitive &&
					Object.keys(this.integration.config.sensitive).length > 0
				) {
					const { sensitive } = this.integration.config;
					Object.keys(sensitive).forEach(key => {
						const val = sensitive[key] ?? "";
						arr.push({
							key,
							value: val,
							isMasked: true,
							variableType: variableType.env_var
						});
					});
				}

				this.variableScope.id = this.integration.id;
				this.variableScope.name = this.integration.name;
				this.variableScope.variables = arr;
			}
		}
	},

	mounted() {
		this.integrationName = this.name;
	},

	methods: {
		triggerPipeline(artifactConfig: IntegrationPipelineConfirmConfig) {
			this.$emit("create-integration", {
				shouldDeploy: true,
				name: this.integrationName,
				inputAppArtifact: artifactConfig.inputArtifact,
				outputAppArtifact: artifactConfig.outputArtifact ?? undefined
			});
		},

		saveIntegration() {
			this.$emit("create-integration", { shouldDeploy: false, name: this.integrationName });
		},

		reloadVars() {
			this.isVariableModalOpen = false;

			if (this.integration) {
				applicationIntegrationStore.FETCH_APP_INTEGRATION({
					appId: this.integration.appId,
					id: this.integration.id,
					orgId: this.integration.orgId,
					projId: this.integration.projId
				});
			}
		},

		variablesAdded() {
			this.$emit("vars-saved", this.variableScope);
		}
	}
});
</script>
