<template>
	<Wrapper
		border-radius="4px"
		background="gray-500"
		width="442px"
		max-height="80vh"
		overflow="visible"
		data-qa-undeploy-application
	>
		<Header>
			<Icon size="small" type="filled" name="i-arrow-left" @click="$emit('back')" />
			<Typography type="h4" color="dark">Remove {{ depEntity.name }}</Typography>
			<Container :padding="0" :shrink="0" :grow="1" align="right center">
				<Icon
					data-qa-delete-deployment-close
					size="x-small"
					type="filled"
					name="i-close"
					@click="closeDeleteDepPopover()"
				/>
			</Container>
		</Header>
		<Container
			:padding="16"
			:gap="20"
			align="left top"
			direction="column"
			overflow="visible"
			data-qa-delete-deployment-confirmation-text
		>
			<Typography type="p1-para" color="dark">
				Are you sure you want to remove
				<Typography
					type="p1"
					weight="medium"
					color="dark"
					inline
					:style="{ whiteSpace: 'pre-wrap' }"
				>
					{{ depEntity.name }} </Typography
				>?
			</Typography>
			<Divider />
			<Container direction="column" :padding="0" :gap="8" overflow="visible">
				<Typography type="p2" weight="medium" color="dark"
					>To confirm this action, please enter the app deployment name below</Typography
				>
				<InputText v-model:value="enteredName" placeholder="Enter app deployment name to confirm">
				</InputText>
			</Container>
			<f-text v-if="submitError" size="small" variant="para" state="danger" data-qa-submit-err-text>
				{{ submitError }}</f-text
			>
		</Container>

		<Footer
			v-tooltip="{
				content: depEntity.name,
				container: 'div.flow-layout',
				placement: 'bottom-end'
			}"
		>
			<Button
				state="full"
				type="error"
				:disabled="depEntity.name !== enteredName"
				@click="triggerDepDelete()"
			>
				<Icon v-if="isDepDeleting" name="i-history" state="light" :effects="false" />
				<Typography
					type="p2"
					weight="bold"
					color="gray-600"
					overflow="ellipsis"
					transform="uppercase"
				>
					Delete {{ depEntity.name }}
				</Typography>
			</Button>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	InputText,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { AppDeployment } from "@/protocol/deployment";
import { captureError, getErrorMessage } from "@/utils";

export default defineComponent({
	name: "DelDepPopover",

	components: {
		Button,
		Header,
		Footer,
		Container,
		Typography,
		Icon,
		Wrapper,
		InputText,
		Divider
	},

	props: {
		depEntity: {
			type: Object as PropType<AppDeployment>,
			required: true
		},

		isDelDepPopoverOpen: {
			type: Boolean,
			required: true
		},

		closeDeleteDepPopover: {
			type: Function as PropType<() => void>,
			required: true
		}
	},

	data: () => ({
		enteredName: "",
		submitError: "",
		isDepDeleting: false
	}),

	watch: {
		isDelDepPopoverOpen(value: boolean) {
			if (!value) {
				this.enteredName = "";
			}
		}
	},

	methods: {
		async triggerDepDelete() {
			try {
				this.isDepDeleting = true;

				if (
					this.depEntity.orgId &&
					this.depEntity.projId &&
					this.depEntity.envId &&
					this.depEntity.id
				) {
					await applicationDeploymentStore.DELETE_APPLICATION_DEPLOYMENT({
						orgId: this.depEntity.orgId,
						projectId: this.depEntity.projId,
						envId: this.depEntity.envId,
						depId: this.depEntity.id
					});
				}

				this.closeDeleteDepPopover();
			} catch (err) {
				captureError(err);
				this.submitError = getErrorMessage(err, true);
			} finally {
				this.isDepDeleting = false;
			}
		}
	}
});
</script>
