<script lang="ts">
import { Accordion, Button, Container, Icon, PopOver, Tag, Typography } from "@cldcvr/flow-vue3";
import { PropType, ref, toRefs, watch } from "vue";

import AddVariableContent from "@/modules/variables-list/AddVariableContent.vue";
import VariableListEmptyState from "@/modules/variables-list/VariableListEmptyState.vue";
import VariableListSingleDeploymentTable from "@/modules/variables-list/VariableListSingleDeploymentTable.vue";
import { AppDeployment } from "@/protocol/deployment";

export default {
	name: "DeploymentsListVariableTable",
	components: { Tag }
};
</script>

<script lang="ts" setup>
const props = defineProps({
	deployment: {
		type: Object as PropType<AppDeployment>,
		required: true
	}
});

const { deployment } = toRefs(props);

const addVariableModalOpen = ref(false);
const isVariablesAccordionOpen = ref(true);
const variableRowCount = ref(0);

// Watch and close variable and output accordion if they are empty
watch(
	[variableRowCount],
	() => {
		isVariablesAccordionOpen.value = variableRowCount.value > 0;
	},
	{
		immediate: true
	}
);
</script>

<template>
	<Accordion :open="isVariablesAccordionOpen" data-qa-deployment-variables>
		<template #header>
			<Container
				align="space-between center"
				overflow="visible"
				padding="20px 8px"
				:clickable="true"
				@click="isVariablesAccordionOpen = !isVariablesAccordionOpen"
			>
				<Container :padding="0" :gap="8">
					<Typography type="h4" weight="medium" color="dark">Variables</Typography>
					<Tag
						size="small"
						shape="rounded"
						:data-qa-deployment-variables-count="variableRowCount"
						>{{ variableRowCount }}</Tag
					>

					<PopOver v-if="deployment?.id" :open="addVariableModalOpen">
						<Button
							v-tooltip="{
								content: 'Add variables'
							}"
							state="icon"
							size="x-small"
							data-qa-app-widget-add-variable
							data-qa-deployment-add-variable
							@click.stop="addVariableModalOpen = true"
						>
							<Icon name="i-plus" color="gray-600" size="x-small" :effects="false" />
						</Button>

						<template #content>
							<AddVariableContent
								:pre-selected-ids="[deployment.id]"
								@close-dialog="addVariableModalOpen = false"
							/>
						</template>
					</PopOver>
				</Container>
				<Icon
					name="i-chevron-down"
					size="x-small"
					:rotate="isVariablesAccordionOpen ? 180 : 0"
					@click.stop="isVariablesAccordionOpen = !isVariablesAccordionOpen"
				/>
			</Container>
		</template>

		<Container
			v-if="variableRowCount === 0"
			direction="column"
			:grow="1"
			align="left top"
			data-qa-variables-list-empty
		>
			<VariableListEmptyState
				:entity-ids="[String(deployment.id)]"
				:has-search="false"
				:has-filter="false"
			/>
		</Container>

		<Container padding="0 12px">
			<VariableListSingleDeploymentTable
				:deployment="deployment"
				@variable-row-count="variableRowCount = $event"
			/>
		</Container>
	</Accordion>
</template>
