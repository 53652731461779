import { AxiosResponse } from "axios";

import {
	AppDeploymentId,
	DeploymentPromoteRequest,
	deploymentService,
	jobId,
	promotedVersions
} from "@/protocol/deployment";
import { infraService, promotionSequenceNodeList } from "@/protocol/infra";
import ApiService, { DEPLOYMENT_API, INFRA_API } from "@/services/api-service";

export const listPromotionSequence: infraService["listPromotionSequence"] = async function ({
	projId,
	orgId
}: {
	projId: string;
	orgId: string;
}) {
	const response = await ApiService.get<promotionSequenceNodeList>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/promotion-sequence`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const promoteDeployment: deploymentService["promoteDeployment"] = async function ({
	envId,
	id,
	orgId,
	projId,
	version
}: DeploymentPromoteRequest) {
	const response: AxiosResponse<jobId> = await ApiService.post(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${id}/promotions`,
		{
			version
		}
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getPromotableVersions: deploymentService["getPromotableVersions"] = async function ({
	envId,
	id,
	orgId,
	projId
}: AppDeploymentId) {
	const response: AxiosResponse<promotedVersions> = await ApiService.get(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${id}/promotions`
	);
	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
