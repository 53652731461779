export function isLink(url: unknown): url is string {
	if (typeof url !== "string") {
		return false;
	}

	if (!url.startsWith("http")) {
		return false;
	}

	try {
		new URL(url);
		return true;
	} catch (e) {
		return false;
	}
}
