import { Router } from "vue-router";

import { ArtifactSelector } from "@/protocol/common";
import { AppDeployment, DeploymentJobAction } from "@/protocol/deployment";

import { applicationDeploymentStore } from "../application-deployment-store";

type AppDepWithJobType = {
	depEntity: AppDeployment;
	jobType: DeploymentJobAction;
	artifact?: ArtifactSelector;
};

export function startApplicationPipeline({ depEntity, jobType, artifact }: AppDepWithJobType) {
	if (!depEntity.orgId || !depEntity.projId || !depEntity.envId || !depEntity.id) {
		return null;
	}

	return applicationDeploymentStore.START_APP_PIPELINE({
		orgId: depEntity.orgId,
		projectId: depEntity.projId,
		envId: depEntity.envId,
		depId: depEntity.id,
		jobType: jobType === "deploy" ? DeploymentJobAction.deploy : DeploymentJobAction.undeploy,
		artifact
	});
}

export function routerToAppPipeline(
	router: Router,
	dep: AppDeployment,
	action: DeploymentJobAction
) {
	if (!dep.orgId || !dep.projId || !dep.envId || !dep.id) {
		return;
	}

	router.push({
		name: "stageViewApp",
		params: {
			orgId: dep.orgId,
			projectId: dep.projId,
			envId: dep.envId,
			depId: dep.id,
			action
		}
	});
}
