<template>
	<!-- Header -->
	<f-div
		id="selectInfraDeps"
		padding="medium"
		height="hug-content"
		gap="large"
		state="secondary"
		width="100%"
	>
		<f-icon
			source="i-arrow-left"
			class="cursor-pointer"
			data-qa-app-connect-form-back-btn
			state="default"
			@click="$emit('back')"
		>
		</f-icon>
		<f-text variant="para" size="small" weight="bold"> Select infrastructure dependencies </f-text>
		<f-div align="middle-right" gap="small" width="10%">
			<f-icon
				source="i-question-filled"
				class="cursor-pointer"
				data-qa-app-connect-info-toggle-btn
				@click="$emit('toggle-info')"
			>
			</f-icon>
			<f-icon
				source="i-close"
				size="small"
				class="cursor-pointer"
				data-qa-app-connect-close-btn
				@click="$emit('close')"
			>
			</f-icon>
		</f-div>
	</f-div>
	<!-- Header end -->
	<f-divider direction="horizontal" height="fill-container" size="medium" />
	<!-- Dependency selection -->
	<f-div padding="none" height="500px" gap="medium" state="secondary" width="100%" align="top-left">
		<f-div
			padding="medium none none medium"
			height="hug-content"
			gap="large"
			width="30%"
			state="secondary"
			direction="column"
		>
			<!-- Dependency selector dropdown -->
			<f-div width="100%" padding="none" align="top-center">
				<f-form-builder
					:key="dependencyFieldKey"
					category="fill"
					size="small"
					class="width-100-per"
					:field.prop="formFields"
					@input="addNewDependency"
					@state-change="formState = $event.detail"
				/>
			</f-div>
			<!-- Empty selection screen -->
			<f-div v-if="!selectedDepList.length" gap="small" state="secondary">
				<f-icon
					source="i-question-filled"
					class="cursor-pointer"
					data-qa-app-connect-form-back-btn
					state="secondary"
				>
				</f-icon>
				<f-div
					direction="column"
					height="hug-content"
					gap="small"
					state="secondary"
					width="fill-container"
				>
					<f-text variant="para" size="small" weight="medium" state="secondary"> Example </f-text>
					<f-text variant="para" size="small" weight="regular" state="secondary">
						You may want to select AWS Lambda and S3 bucket for a serverless app.
					</f-text>
				</f-div>
			</f-div>

			<!-- Selected dependency names -->
			<f-div
				v-for="(dep, i) in selectedDepList"
				:key="dep.id + i"
				padding="none none none none"
				width="100%"
				overflow="scroll"
				:selected="dep === selectedDependency?.data ? 'background' : undefined"
				gap="small"
			>
				<f-div width="100%" direction="column" padding="none" class="cursor-pointer">
					<f-div direction="row" padding="small">
						<f-div direction="column" gap="medium" @click.stop="selectDependency(i)">
							<f-text state="default">{{ dep.name }}</f-text>
							<f-text state="secondary" size="small">{{ dep.description }}</f-text>
						</f-div>
						<f-icon
							class="show-on-hover cursor-pointer"
							source="i-close"
							size="x-small"
							state="subtle"
							data-qa-app-connect-close-btn
							@click="removeSelection(i)"
						>
						</f-icon>
					</f-div>
					<f-divider state="subtle" size="large" variant="solid"> </f-divider>
				</f-div>
				<f-div width="100%" padding="small none none none">
					<Divider direction="horizontal" height="fill-container" :size="1" />
				</f-div>
			</f-div>
		</f-div>
		<f-divider direction="vertical" height="fill-container" size="large" />
		<!-- Selection end -->

		<!-- Details -->
		<f-div v-if="!selectedDependency">
			<EmptyDependencyList
				:from-search="false"
				title="No dependencies available"
				desc="Select dependencies to view its configuration"
			/>
		</f-div>
		<!-- </Container> -->
		<f-div
			v-if="selectedDependency"
			padding="none"
			height="hug-content"
			gap="large"
			state="secondary"
			align="middle-right"
		>
			<f-div
				padding="medium small none none"
				height="hug-content"
				gap="small"
				state="secondary"
				width="100%"
			>
				<f-form-builder
					:key="dependencyFieldKey"
					category="fill"
					class="width-100-per"
					:field.prop="aliasFormField"
					:values.prop="aliasFormValue"
					@input="handleAliasInput"
					@state-change="formState = $event.detail"
				>
				</f-form-builder>

				<f-text v-if="isDupAlias" size="small" weight="regular" state="warning" variant="para"
					>This alias is used by one or more applications. Please use different alias if you wish to
					you use for this app only.</f-text
				>
			</f-div>
			<!-- Tabs for dependency view -->
			<f-div padding="none">
				<DependencyDetails :dependency="selectedDependency.data" :app-wizard-flow="true" />
			</f-div>
		</f-div>
	</f-div>
	<f-divider direction="horizontal" height="fill-container" size="medium" />
	<f-div state="secondary" align="middle-center" padding="medium">
		<f-div gap="x-small" data-qa-no-of-deps-selected-text>
			<f-text size="small" inline weight="bold"> {{ depSelectionTxt }}</f-text>
			<f-text size="small" inline> selected</f-text>
		</f-div>
		<f-div align="middle-right" direction="row" gap="small">
			<f-button
				tooltip="Skip if no dependencies are required"
				label="Skip"
				state="neutral"
				align="right"
				category="outline"
				variant="round"
				data-qa-skip-infra-btn
				@click="skipToNext"
			></f-button>
			<f-button
				label="Next - Setup build pipeline"
				state="success"
				align="right"
				:loading="isSubmitting"
				:disabled="isDisabled"
				data-qa-update-infra-btn
				@click="updateDependency"
			></f-button>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Divider } from "@cldcvr/flow-vue3";
import { cloneDeep } from "lodash-es";
import { defineComponent, PropType } from "vue";

import DependencyDetails from "@/modules/dependency-registry/components/DependencyDetails.vue";
import EmptyDependencyList from "@/modules/dependency-registry/components/EmptyDependencyList.vue";
import { orgStore } from "@/modules/org/org-store";
import { app as AppProto } from "@/protocol/app";
import { Dependency as UpdateDependencyProto } from "@/protocol/common";
import { Dependency } from "@/protocol/infra";

import { updateAppDependency } from "../application-service";
import { applicationStore } from "../application-store";
import { AppWizardChecklistEnum } from "../views/appWizardFlowSteps";

type FormValues = {
	dependency: {
		title: string;
		data: Dependency;
	};
	alias: string;
};
export default defineComponent({
	name: "AddAppSelectInfraDependencies",
	components: { Divider, DependencyDetails, EmptyDependencyList },

	props: {
		appId: {
			type: String as PropType<string>,
			required: true
		}
	},

	emits: ["back", "toggle-info", "close", "update:aliasFormValue", "deps-updated"],

	data: () => ({
		formState: null as FormBuilderState | null,
		aliasFormValue: {} as Partial<FormValues>,
		allAliases: {} as Record<number, string>,
		dependencyFieldKey: 0,
		app: null as AppProto | null,

		selectedDepList: [] as Dependency[],
		finalDepList: [] as Dependency[],
		selectedDependency: null as {
			index: number;
			data: Dependency;
		} | null,

		isDupAlias: false,

		isSubmitting: false
	}),

	computed: {
		projectId() {
			return this.$route.params.projectId as string;
		},

		orgId() {
			return orgStore.activeOrgId;
		},

		allApps() {
			return applicationStore.projectApps[this.projectId] ?? [];
		},

		existingAppDepNames(): string[] {
			return (
				this.allApps
					.map(app => app.dependsOn ?? [])
					// d being the string from Record<string, Dependency>, its also the alias used
					.map(d => Object.keys(d))
					.flat()
			);
		},

		depSelectionTxt() {
			const { length } = this.finalDepList;
			// eslint-disable-next-line no-nested-ternary
			return length === 0 || !length
				? "No dependencies"
				: length === 1
				? `1 dependency`
				: `${length} dependencies`;
		},

		allDependencies() {
			return orgStore.dependencyList;
		},

		dependencyListOptions() {
			return this.allDependencies.map(dependency => ({
				title: dependency.name,
				data: {
					...dependency
				}
			}));
		},

		aliasFormField(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					alias: {
						type: "text",
						label: {
							title: `Dependency alias  ${
								this.selectedDependency?.data.name ? `for ${this.selectedDependency.data.name}` : ""
							}`
						},

						qaId: "dependency-alias",
						placeholder: "Dependency alias",
						validationRules: [{ name: "required" }]
					}
				}
			};
		},

		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",

				fields: {
					dependency: {
						type: "select",
						selection: "single",
						placeholder: "Search dependencies",
						qaId: "select-dependencies",
						label: { title: "Search dependencies" },
						searchable: true,
						options: this.dependencyListOptions
					}
				}
			};
		},

		isDisabled() {
			return this.selectedDepList.length === 0;
		}
	},

	watch: {
		appId: {
			immediate: true,

			handler() {
				this.getApp();
			}
		}
	},

	methods: {
		async getApp() {
			if (this.appId && this.orgId) {
				this.app = await applicationStore.GET_APP_BY_ID({
					appId: this.appId,
					orgId: this.orgId,
					projectId: this.projectId
				});
			}
		},

		handleAliasInput(event: CustomEvent<FormValues>) {
			this.aliasFormValue = { ...event.detail };

			if (this.aliasFormValue.alias !== undefined && this.selectedDependency !== null) {
				// If the alias name already is used in any of the apps, show the warning to the user
				this.checkDuplicateDeps(this.aliasFormValue.alias);

				this.allAliases[this.selectedDependency.index] = this.aliasFormValue.alias;
				// update the new name for respective dependency
				this.finalDepList.forEach(dep => {
					if (dep.id === this.selectedDependency?.data.id) {
						dep.name = this.aliasFormValue.alias!;
					}
				});
			}
		},

		checkDuplicateDeps(name: string) {
			this.isDupAlias = this.existingAppDepNames.includes(name);
		},

		setDefaultAliasValues() {
			this.finalDepList.forEach((dep, i) => {
				this.allAliases[i] = this.allAliases[i] ? this.allAliases[i]! : dep.name;
			});
		},

		addNewDependency(event: CustomEvent<FormValues>) {
			const newDependency = { ...event.detail };
			const { dependency } = newDependency;
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (!dependency) {
				return;
			}
			// add the dependency to the selectedDepList
			this.selectedDepList.push(dependency.data);

			// get the index of the dependency in the selectedDepList
			const index = this.selectedDepList.length - 1;
			this.selectedDependency = {
				index,
				data: dependency.data
			};

			// add the dependency to the finalDepList
			this.finalDepList.push(cloneDeep(dependency.data));
			this.allAliases[index] = dependency.data.name;

			// set the default alias name for the dependency form value
			this.aliasFormValue.alias = dependency.data.name;

			// generate a random value for the dependencyFieldKey to force re-render the form
			this.dependencyFieldKey++;

			// Check if there are duplicate alias names for the newly added dependency
			this.checkDuplicateDeps(dependency.data.name);
		},

		selectDependency(index: number) {
			// get dependency from selectedDepList using the given index
			const dependency = this.selectedDepList[index];

			if (dependency) {
				this.selectedDependency = { index, data: dependency };

				this.aliasFormValue.alias = this.allAliases[index];
				this.dependencyFieldKey++;

				this.checkDuplicateDeps(this.allAliases[index]!);
			}
		},

		removeSelection(index: number) {
			this.selectedDepList.splice(index, 1);
			this.finalDepList.splice(index, 1);
			delete this.allAliases[index];
			this.setDefaultAliasValues();
			this.selectedDependency = null;
		},

		async updateDependency() {
			try {
				if (!this.appId || !this.orgId) {
					return;
				}
				this.isSubmitting = true;

				const dependsOn = {} as Record<string, UpdateDependencyProto>;
				this.finalDepList.forEach(dep => {
					dependsOn[dep.name] = {
						dependencyName: dep.name,
						inputs: dep.inputs,
						outputs: dep.outputs
					};
				});
				await updateAppDependency({
					appId: this.appId,
					orgId: this.orgId,
					projId: this.projectId,
					dependsOn
				});
				this.isSubmitting = false;

				this.$emit("deps-updated");
			} catch (error) {
				this.isSubmitting = false;
			}
		},

		skipToNext() {
			applicationStore.UPDATE_APP_WIZARD_STEP(AppWizardChecklistEnum.DEPLOY_CONFIG);
		}
	}
});
</script>
