import { createNewFogbender, Token } from "fogbender";

import { organization } from "@/protocol/identity";

import { getSupportToken } from "./user-support-service";

class UserSupport {
	// private widgetId = process.env.FOGBENDER_WIDGET_ID ?? "";
	// private widgetId = "dzAwMzkyNTgwODc5Mjc0MjE3NDcy";
	private clientURL = "https://client.fogbender.com";
	private token: Token | null = null;

	private async generateToken({
		userId,
		emailId,
		org,
		widgetId
	}: {
		emailId: string;
		userId: string;
		org: organization;
		widgetId: string;
	}): Promise<Token> {
		const customerId = `${org.name}::${org.id}`;
		const userJWT = await getSupportToken({ userId, customerId });

		return {
			// The Widget ID is specific to each environment
			widgetId,
			// The customerId and customerName determine the Admin Dashboard channels
			customerId,
			customerName: emailId,
			// User specific fields
			userJWT,
			userId,
			userEmail: emailId,
			userName: emailId
		};
	}

	async renderIFrame({
		rootEl,
		emailId,
		userId,
		org,
		widgetId
	}: {
		rootEl: HTMLElement;
		emailId: string;
		userId: string;
		org: organization;
		widgetId: string;
	}) {
		const fogbender = createNewFogbender();
		fogbender.setClientUrl(this.clientURL);

		this.token =
			this.token ??
			(await this.generateToken({
				userId,
				emailId,
				org,
				widgetId
			}));
		fogbender.setToken(this.token);

		fogbender.renderIframe({ rootEl });
	}
}

export const SupportService = new UserSupport();
