<template>
	<Wrapper border-radius="4px" background="gray-500" width="430px" max-height="80vh">
		<Header>
			<Container :grow="1" :padding="0" align="space-between center">
				<Typography type="h4">Welcome to {{ env && env.name }} 🎉</Typography>
			</Container>
		</Header>
		<Container direction="column" padding="0px 16px 16px 16px" :gap="20">
			<Typography type="p1-para" color="gray-100">
				To get started, we have created an environment for you. You can connect your terraform and
				cloud to deploy this environment.
			</Typography>
			<Button
				:loading="isSubmitting"
				size="small"
				:class="isSubmitting ? 'width-150' : 'width-100'"
				data-qa-env-welcome-acceptance-button
				@click="goNext"
			>
				👍 GOT IT
			</Button>
		</Container>
	</Wrapper>
</template>
<script lang="ts">
import { Button, Wrapper, Container, Typography, Header } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { userStore } from "@/modules/user/user-store";
import { environment } from "@/protocol/infra";
import { EnvCreateStepFlowService } from "@/services/storage-service";

export default defineComponent({
	name: "EnvWelcomePopover",

	components: {
		Header,
		Wrapper,
		Typography,
		Container,
		Button
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		}
	},

	data: () => ({
		isSubmitting: false
	}),

	computed: {
		isStepFlow() {
			return EnvCreateStepFlowService.getToggleFlow().isEnabled;
		}
	},

	beforeUnmount() {
		if (this.isStepFlow) {
			this.$emit("gonext", "createEdit");
		}
	},

	methods: {
		async goNext() {
			if (this.isSubmitting) {
				return false;
			}
			this.isSubmitting = true;
			// If this popover is shown, it means the user has created the env for the first time, we flag that in the user's metadata
			await userStore.UPDATE_USER_META({
				hasCreatedEnvs: true
			});
			this.isSubmitting = false;
		}
	}
});
</script>
