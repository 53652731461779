<template>
	<CreateApplicationWizard
		v-if="!isEmptyFromSearch && currentProject"
		:open="openAddNewAppPopover"
		:project="currentProject"
		data-qa-empty-applications
		@overlay-click="toggleAddNewApp"
		@close="toggleAddNewApp"
		@app-created="toggleAddNewApp"
	>
		<EmptyState
			:icon="icon"
			:message="emptyMessage"
			:subtitle="emptySubtitle"
			:shape="shape"
			:action="action"
			@actions="toggleAddNewApp"
		/>
	</CreateApplicationWizard>

	<EmptyState
		v-else
		:icon="icon"
		message="No Results Found"
		subtitle="No Results found for the search term that you entered"
		:shape="shape"
		data-qa-empty-applications-from-search
		@actions="toggleAddNewApp"
	/>
</template>
<script lang="ts">
import { EmptyState, EmptyStateIconProp, EmptyStateShape } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import CreateApplicationWizard from "@/modules/application/components/CreateApplicationWizard.vue";
import { projectStore } from "@/modules/project-list/project-store";

export default defineComponent({
	name: "EmptyApplications",

	components: {
		EmptyState,
		CreateApplicationWizard
	},

	props: {
		isEmptyFromSearch: {
			type: Boolean
		}
	},

	data: () => ({
		icon: {
			name: "i-plus",
			type: "filled",
			state: "default",
			size: "medium",
			color: "gray-200"
		} as EmptyStateIconProp,

		emptyMessage: "Add Application",
		emptySubtitle:
			"Add your application to Code Pipes and choose environment template to deploy it.",

		shape: "squircle" as EmptyStateShape,
		action: "ADD APPLICATION",
		openAddNewAppPopover: false
	}),

	computed: {
		currentProject() {
			return projectStore.currentProject;
		}
	},

	methods: {
		toggleAddNewApp() {
			this.openAddNewAppPopover = !this.openAddNewAppPopover;
		}
	}
});
</script>
