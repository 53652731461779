<template>
	<div class="display-contents">
		<Container
			v-if="hasNoVars"
			align="center"
			data-qa-app-widget-variables
			data-qa-app-widget-variables-empty
			direction="column"
			class="align-items-center height-100-per"
			:gap="12"
			:grow="1"
		>
			<Button state="icon" size="small">
				<Icon
					name="i-plus"
					type="filled"
					size="small"
					:effects="false"
					data-qa-app-widget-variables-button
					@click="openAddVariableModel()"
				/>
			</Button>
			<Typography type="p1" color="light">Add variables</Typography>
		</Container>
		<Container
			v-else
			data-qa-env-wid-variable-content
			overflow="auto"
			align="stretch top"
			:padding="16"
		>
			<table
				class="key-value"
				data-key-size="25"
				data-row-gutter="16px"
				aria-label="variables table"
			>
				<thead>
					<tr>
						<th id="key">
							<Container align="left center" :padding="0" :gap="4">
								<Typography type="p4" transform="uppercase" color="light">name</Typography>
								<Icon
									:name="sortKeysAscending ? 'i-arrow-down' : 'i-arrow-up'"
									type="filled"
									size="xx-small"
									state="light"
									data-qa-app-widget-variables-toggle-sort
									@click="sortKeysAscending = !sortKeysAscending"
								/>
							</Container>
						</th>
						<th id="value">
							<Container align="left center" :padding="0">
								<Typography type="p4" transform="uppercase" weight="light" color="light">
									value
								</Typography>
							</Container>
						</th>
						<th id="tag" class="width-100"></th>
					</tr>
				</thead>
				<tbody>
					<template v-for="sortedVar in groupedVariables" :key="sortedVar.title">
						<tr>
							<td colspan="3">
								<Typography type="p3" color="light">{{ sortedVar.title }}</Typography>
							</td>
						</tr>
						<tr
							v-for="variable in sortedVar.vars"
							:key="variable.key + variable.value + variable.type"
							data-qa-env-wid-variable-list
						>
							<td>
								<Typography type="p2" color="dark">{{ variable.key }}</Typography>
							</td>
							<td>
								<Container :gap="12" :padding="0">
									<Typography type="p2" color="light">{{
										variable.sensitive ? "••••••••••" : variable.value
									}}</Typography>
								</Container>
							</td>
							<td>
								<Container align="right center" :padding="0" overflow="visible">
									<Tag v-if="variable.type == variableType.tf_var" type="primary" size="small"
										>TF var</Tag
									>
									<Tag v-if="variable.type == variableType.env_var" type="warning" size="small"
										>Env var</Tag
									>
								</Container>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</Container>
	</div>
</template>

<script lang="ts">
import { Typography, Container, Icon, Tag, Button } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { variable, variableType } from "@/protocol/common";
import { project } from "@/protocol/identity";

export default defineComponent({
	name: "EnvWidgetVariableContent",
	components: { Typography, Container, Icon, Tag, Button },

	props: {
		projectVariables: {
			type: Array as PropType<variable[]>,
			required: true
		},

		envVariables: {
			type: Array as PropType<variable[]>,
			required: true
		},

		project: {
			type: Object as PropType<project>,
			required: true
		},

		openAddVariableModel: {
			type: Function as PropType<() => void>,
			required: true
		}
	},

	data: () => ({
		sortKeysAscending: true,
		variableType
	}),

	computed: {
		hasNoVars() {
			return this.projectVariables.length === 0 && this.envVariables.length === 0;
		},

		groupedVariables() {
			const envEntry: SortedVar = {
				title: `Defined on this environment`,
				vars: [...this.envVariables]
			};

			const projectEntry: SortedVar = {
				title: `Inherited from ${this.project.name}`,
				vars: [...this.projectVariables]
			};

			const output: SortedVar[] = [];

			[envEntry, projectEntry].forEach(entry => {
				if (entry.vars.length > 0) {
					entry.vars.sort((varA, varB) => {
						return this.sortKeysAscending
							? varA.key.localeCompare(varB.key)
							: varB.key.localeCompare(varA.key);
					});
					output.push(entry);
				}
			});

			return output;
		}
	}
});

type SortedVar = {
	title: string;
	vars: variable[];
};
</script>
