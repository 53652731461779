<template>
	<PipelineWrapper :show-history="showHistory" :is-loading="isLoadingPipeline">
		<template #header>
			<PipelineHeader
				v-if="currentJob"
				:job-start="String(currentJob.createdAt)"
				:resource-changes="currentJob.resourceChanges"
				:pipeline-name="pipelineName"
				:started-by="currentJob?.updatedBy"
			/>
		</template>

		<template #stage-view>
			<EnvDagView
				v-if="currentJob && env"
				id="env-dag-click"
				:job="currentJob"
				:nodes="(chartMetaData && chartMetaData.nodes) || []"
				:edges="(chartMetaData && chartMetaData.edges) || []"
				@step-changed="stepChanged"
			/>
		</template>

		<template #logs-view>
			<EnvLogsView
				v-if="currentJob && currentStep"
				:current-step="currentStep"
				:current-job="currentJob"
				:project-id="projectId"
				:env-id="envId"
				:org-id="orgId"
			>
				<template #pipeline-actions>
					<PopOver
						class="align-items-center"
						placement="bottom-start"
						:open="showConfirmation"
						@overlay-click="showConfirmation = false"
					>
						<!-- Cancel pipeline -->
						<Button
							v-if="isRunningPipeline"
							type="error"
							state="curved"
							size="small"
							:disabled="isCancellingJob"
							data-qa-cancel-pipeline
							@click="cancelJob"
						>
							<Icon name="i-stop" color="gray-600" type="filled" size="x-small" />
							<Typography
								type="p2"
								color="gray-600"
								transform="uppercase"
								weight="bold"
								overflow="nowrap"
								size="small"
								>Cancel</Typography
							>
						</Button>

						<!-- Re trigger action -->
						<Button
							v-else-if="retryButtonText"
							:type="retryButtonType"
							state="curved"
							:disabled="isLoadingPipeline"
							data-qa-retry-pipeline
							size="small"
							@click="retryPipeline"
						>
							<Icon name="i-tick" color="gray-600" type="filled" size="x-small" />
							<Typography
								type="p2"
								color="gray-600"
								transform="uppercase"
								weight="bold"
								overflow="nowrap"
								>{{ retryButtonText }}</Typography
							>
						</Button>

						<template #content>
							<ConfirmPipeline
								v-if="env"
								:confirm-meta="confirmActionMeta[action]"
								:env="env"
								:action="action"
								@close="showConfirmation = false"
							/>
						</template>
					</PopOver>

					<Button
						:type="showHistory ? 'secondary' : 'default'"
						state="curved"
						size="small"
						overflow="nowrap"
						data-qa-toggle-pipeline-history
						@click="setPipelineHistory(!showHistory)"
					>
						<Icon
							name="i-clock-outline"
							:color="showHistory ? 'gray-600' : 'gray-100'"
							type="filled"
							size="small"
						/>
						<Typography type="p2" :color="showHistory ? 'gray-600' : 'gray-100'" weight="bold">
							Pipeline history
						</Typography>
					</Button>
				</template>
			</EnvLogsView>
		</template>

		<template #pipeline-history>
			<EnvPipelineHistory
				:project-id="projectId"
				:env-id="envId"
				:org-id="orgId"
				:action="action"
				:selected-pipeline-id="jobId ?? undefined"
				@set-pipeline-history="setPipelineHistory"
			/>
		</template>
	</PipelineWrapper>
</template>

<script lang="ts">
import { PopOver, Icon, Typography, Button } from "@cldcvr/flow-vue3";
import { upperFirst } from "lodash-es";
import { PropType, defineComponent } from "vue";

import { breadcrumbStore } from "@/modules/core/breadcrumb-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { orgStore } from "@/modules/org/org-store";
import { projectStore } from "@/modules/project-list/project-store";
import { actionType } from "@/protocol/infra";
import PipelineHeader from "@/shared/components/pipelines/PipelineHeader.vue";
import PipelineWrapper from "@/shared/components/pipelines/PipelineWrapper.vue";
import {
	PIPELINE_UNFINISHED_JOB_STATUSES,
	PipelineJobStatus,
	JOB_STATUS
} from "@/shared/pipeline-constants";

import { ENV_PIPELINE_CONFIRM_ACTION_META } from "../constants";
import { getSortedEnvJobs, newEnvPipelineStore } from "../env-pipeline-store";

import ConfirmPipeline from "./ConfirmPipeline.vue";
import EnvDagView from "./EnvDagView.vue";
import EnvLogsView from "./EnvLogsView.vue";
import EnvPipelineHistory from "./EnvPipelineHistory.vue";

const PIPELINE_TEXT: Record<actionType, string> = {
	[actionType.validateOnly]: "validate",
	[actionType.ValidateAndApply]: "deploy",
	[actionType.no_action_type_support]: "",
	[actionType.destroy]: "destroy"
};

export default defineComponent({
	name: "EnvStageViewWrapper",

	components: {
		PipelineHeader,
		Icon,
		Typography,
		Button,
		PopOver,
		PipelineWrapper,
		EnvLogsView,
		EnvPipelineHistory,
		EnvDagView,
		ConfirmPipeline
	},

	props: {
		action: {
			type: String as PropType<actionType>,
			required: true
		},

		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		envId: {
			type: String,
			required: true
		},

		jobId: {
			type: String as PropType<string | null>,
			default: () => null
		}
	},

	data: () => ({
		isLoadingPipeline: false,
		currentStepIndex: 0,
		showHistory: false,
		showConfirmation: false,
		isAttemptingCancellation: false,
		retryAttempted: false,
		confirmActionMeta: ENV_PIPELINE_CONFIRM_ACTION_META
	}),

	computed: {
		pipelineName() {
			return `Environment ${PIPELINE_TEXT[this.action]} pipeline`;
		},

		currentStep() {
			return this.currentJob?.steps?.[this.currentStepIndex];
		},

		chartMetaData() {
			return (
				newEnvPipelineStore.envDagMetaList.find(
					dagMeta => dagMeta.action === this.action && dagMeta.envId === this.envId
				) ?? null
			);
		},

		currentJob() {
			if (this.jobId) {
				return getSortedEnvJobs(this.envId).find(job => job.id === this.jobId) ?? null;
			}

			return getSortedEnvJobs(this.envId, this.action)[0] ?? null;
		},

		currentProject() {
			return projectStore.currentProject;
		},

		env() {
			const envs = envListStore.envs[this.projectId];
			const foundEnvironment = envs?.find(env => env.id === this.envId);
			return foundEnvironment ?? null;
		},

		title() {
			const { orgs } = orgStore;
			const currentOrgId = this.currentProject?.orgId;
			const org = orgs.find(matchingOrg => matchingOrg.id === currentOrgId);
			return [upperFirst(this.action), this.env?.name, this.currentProject?.name, org?.name]
				.filter(Boolean)
				.join(" - ");
		},

		isRunningPipeline() {
			return this.currentJob
				? PIPELINE_UNFINISHED_JOB_STATUSES.includes(this.currentJob.status as PipelineJobStatus)
				: false;
		},

		retryButtonText() {
			if (this.currentJob && (this.currentJob.action === "destroy" || this.isRunningPipeline)) {
				return null;
			}

			if (this.currentJob?.status === JOB_STATUS.DONE) {
				return `Re-${PIPELINE_TEXT[this.action]}`;
			} else if (
				this.currentJob?.status === JOB_STATUS.FAILED ||
				this.currentJob?.status === JOB_STATUS.CANCELLED
			) {
				return "Retry";
			}

			return null;
		},

		isCancellingJob() {
			return this.isAttemptingCancellation || this.currentJob?.status === JOB_STATUS.CANCELLING;
		},

		retryButtonType() {
			if (this.isLoadingPipeline) {
				return "default";
			}

			if (
				this.currentJob?.status === JOB_STATUS.FAILED ||
				this.currentJob?.status === JOB_STATUS.CANCELLED
			) {
				return "error";
			} else if (this.currentJob?.status === "done") {
				return "success";
			}

			return "primary";
		}
	},

	watch: {
		title: {
			immediate: true,
			deep: true,

			handler(newTitle: string) {
				if ("document" in window) {
					window.document.title = newTitle;
				}
			}
		},

		jobId: {
			immediate: true,

			handler() {
				if (this.jobId) {
					this.showHistory = true;
				}

				this.loadEnvPipelineData();

				// Add breadCrumb
				this.addBreadCrumbs();
			}
		},

		currentStep() {
			if (this.env) {
				newEnvPipelineStore.SET_ACTIVE_STEP({
					step: this.currentStep?.name ?? "",
					action: this.action,
					envId: this.env.id
				});
			}
		}
	},

	methods: {
		async cancelJob() {
			if (!this.currentJob) {
				return;
			}

			this.isAttemptingCancellation = true;

			await newEnvPipelineStore.CANCEL_PIPELINE({
				job: this.currentJob,
				projId: this.projectId,
				orgId: this.orgId
			});

			this.isAttemptingCancellation = false;
		},

		retryPipeline() {
			this.retryAttempted = true;

			this.showConfirmation = true;

			this.setPipelineHistory(false);
		},

		async addBreadCrumbs() {
			// Get the selected environment
			let currentEnv = this.env;

			// If no environment is selected, get all environments for the current project
			if (!currentEnv) {
				await envListStore.GET_ENVS({
					orgId: this.orgId,
					projectId: this.projectId
				});
				const envs = envListStore.envs[this.projectId];
				currentEnv = envs?.find(e => e.id === this.envId) ?? null;
			}

			// Get the action type
			const envAction = this.action;

			// Get the action text
			let actionText = "Validation";
			if (envAction === actionType.ValidateAndApply) {
				actionText = "Deployment";
			} else if (envAction === actionType.destroy) {
				actionText = "Destroy";
			}

			// If an environment is selected, add breadcrumbs
			if (currentEnv) {
				breadcrumbStore.SET_ADDITIONAL_BREADCRUMBS([
					{
						qaId: "environment",
						label: currentEnv.name,
						route: {
							name: "envDetail",
							props: {
								orgId: currentEnv.orgId,
								projectId: currentEnv.projId,
								envId: currentEnv.id
							}
						}
					},
					{
						qaId: "stageViewEnv",
						label: `${actionText} pipeline`,
						route: {
							name: "stageViewEnv",
							props: {
								orgId: currentEnv.orgId,
								projectId: currentEnv.projId,
								envId: currentEnv.id,
								action: this.action
							}
						}
					}
				]);
			}
		},

		async loadEnvPipelineData() {
			this.isLoadingPipeline = true;

			if (this.jobId) {
				await newEnvPipelineStore.LIST_JOB_FOR_ENV({
					orgId: this.orgId,
					projId: this.projectId,
					envId: this.envId,
					jobId: this.jobId
				});
			} else {
				await newEnvPipelineStore.LIST_JOBS_FOR_ENV({
					orgId: this.orgId,
					projId: this.projectId,
					envId: this.envId,
					action: this.action,
					limit: 1
				});
			}

			const { currentJob } = this;

			if (currentJob) {
				newEnvPipelineStore.UPDATE_STEPS_FOR_JOB(currentJob);
			}

			this.currentStepIndex = currentJob?.progress ? currentJob.progress - 1 : 0;

			this.isLoadingPipeline = false;
		},

		setPipelineHistory(showHistory: boolean) {
			this.showHistory = showHistory;
		},

		stepChanged(stepName: string) {
			this.currentStepIndex =
				this.currentJob?.steps?.findIndex(step => step.name === stepName) ?? 0;
		}
	}
});
</script>
