<template>
	<TableRow :data-qa-member-row="member.email" :blink="member.isNew" @click="toggle">
		<TableCell padding="16px 12px">
			<Container :padding="0" overflow="visible">
				<Checkbox
					data-qa-select-member-row
					:checked="member.selected"
					:disabled="member.isReadOnly"
				/>
				<Pictogram shape="circle" state="avatar" size="m" :label="member.email" />
				<Typography type="p1" color="gray-100" :data-qa-member-row-email="member.email">{{
					member.email
				}}</Typography>
			</Container>
		</TableCell>
		<TableCell padding="16px 12px">
			<Container :padding="0" :gap="12">
				<Typography type="p1" color="gray-100" data-qa-member-row-role>{{
					member.role?.name
				}}</Typography>

				<Icon
					v-if="member.role?.id === 'admin'"
					name="i-crown"
					state="warning"
					size="small"
					type="filled"
					:effects="false"
				/>

				<PopOver :open="showModifyRole" placement="bottom-start">
					<Icon
						v-if="!member.isReadOnly"
						v-tooltip="'Modify role'"
						class="show-on-hover"
						name="i-edit"
						color="primary-300"
						size="x-small"
						data-qa-modify-member-role
						@click="showModifyRole = !showModifyRole"
					/>
					<template #content>
						<ModifyRole
							:members="[member]"
							:org="org"
							:project="project"
							@close="showModifyRole = false"
						/>
					</template>
				</PopOver>
			</Container>
		</TableCell>
		<TableCell padding="16px 12px" data-qa-member-row-activity>
			<Shimmer v-if="loadingActivity" />
			<Typography v-else-if="!member.lastActivity" type="p1" color="gray-100"
				>No activity</Typography
			>
			<Typography v-else type="p1" color="gray-100">{{
				member.lastActivity.relativeTime
			}}</Typography>
		</TableCell>
		<TableCell padding="16px 12px" data-qa-member-row-status>
			<Typography v-if="!member.membershipStatus" type="p1" color="gray-100">-</Typography>
			<Typography v-else type="p1" color="gray-100">{{
				member.membershipStatus.relativeTime
			}}</Typography>
		</TableCell>
		<TableCell padding="16px 16px" width="50px">
			<PopOver
				v-if="!member.isReadOnly"
				:open="showRemove"
				placement="top"
				class="flex-1"
				strategy="absolute"
			>
				<Icon
					v-tooltip="{
						content: 'Remove User',
						placement: 'left'
					}"
					name="i-delete"
					size="small"
					color="danger-200"
					class="show-on-hover"
					data-qa-remove-member
					@click="showRemove = !showRemove"
				/>
				<template #content>
					<RemoveMemberConfirm
						:org="org"
						:project="project"
						:members="[member]"
						@close="showRemove = false"
					/>
				</template>
			</PopOver>
		</TableCell>
	</TableRow>
</template>
<script lang="ts">
import {
	Checkbox,
	Container,
	Icon,
	Pictogram,
	PopOver,
	Shimmer,
	TableCell,
	TableRow,
	Typography
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { organization, project as projectProto } from "@/protocol/identity";

import { Member } from "./member-types";
import ModifyRole from "./ModifyRole.vue";
import RemoveMemberConfirm from "./RemoveMemberConfirm.vue";

export default defineComponent({
	name: "MemberRow",

	components: {
		TableRow,
		TableCell,
		Checkbox,
		Container,
		Pictogram,
		Typography,
		Icon,
		PopOver,
		Shimmer,
		RemoveMemberConfirm,
		ModifyRole
	},

	props: {
		org: {
			type: Object as PropType<organization>
		},

		project: {
			type: Object as PropType<projectProto>
		},

		member: {
			type: Object as PropType<Member>,
			required: true
		},

		loadingActivity: {
			type: Boolean,
			default: () => null
		}
	},

	data: () => ({
		showRemove: false,
		showModifyRole: false
	}),

	methods: {
		toggle() {
			if (this.member.isReadOnly) {
				return;
			}

			this.$emit("toggle", this.member);
		}
	}
});
</script>
