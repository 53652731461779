<template>
	<InfoPopover
		v-if="showInfo"
		title="Project settings"
		placement="right-start"
		target="details"
		:is-open="showInfo"
		:show-overlay="true"
		@close="closePopover"
	>
		<f-div>
			<f-text variant="para" size="medium"> This is your project settings tab. </f-text>
		</f-div>
		<f-div padding="large none none none">
			<f-text>
				Here you can manage the projects, environments, required credentials and project members.
			</f-text>
		</f-div>
	</InfoPopover>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { userStore } from "@/modules/user/user-store";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";

export default defineComponent({
	name: "ProjectDetailsPopover",

	components: {
		InfoPopover
	},

	data: () => ({
		showInfo: false as boolean
	}),

	computed: {
		infoFlags() {
			return userStore.profile?.metadata?.infoFlags;
		}
	},

	mounted() {
		if (!this.infoFlags?.hasProjectDetailsPopover) {
			this.showInfo = true;
		}
	},

	methods: {
		closePopover() {
			this.showInfo = false;
			userStore.UPDATE_USER_META({
				infoFlags: {
					...this.infoFlags,
					hasProjectDetailsPopover: true
				}
			});
		}
	}
});
</script>
