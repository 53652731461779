<template>
	<Slab
		v-if="showWarning"
		state="warning"
		state-type="solid"
		size="large"
		data-qa="prevent-accidental-modal-close-notification"
	>
		<template #primary-action>
			<Typography color="dark" type="p1" weight="bold" data-qa="prevent-accidental-modal-txt-1"
				>The changes will be lost.
				<Typography inline color="dark" type="p1" data-qa="prevent-accidental-modal-txt-2"
					>Are you sure to close this?</Typography
				>
			</Typography>
		</template>
		<template #secondary-actions>
			<Button
				size="small"
				type="warning"
				state="outlined"
				:full-width="false"
				data-qa="prevent-accidental-modal-cta"
				@click="$emit('force-close')"
				>Yes, close</Button
			>
		</template>
	</Slab>
</template>

<script lang="ts">
import { Slab, Button, Typography } from "@cldcvr/flow-vue3";
import { isEqual } from "lodash-es";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "ClosePopoverConfirmationWarning",
	components: { Slab, Button, Typography },

	props: {
		formValues: {
			type: Object as PropType<Record<string, unknown>>,
			required: true
		},

		initialFormValues: {
			type: Object as PropType<Record<string, unknown>>,
			required: true
		}
	},

	data: () => ({
		showWarning: false
	}),

	methods: {
		// used by parent component to reset.
		// eslint-disable-next-line vue/no-unused-properties
		isFormTouched() {
			const isFormValuesEqual =
				Object.keys(this.formValues).length > 0
					? isEqual(this.formValues, this.initialFormValues)
					: true;
			if (!isFormValuesEqual) {
				this.showWarning = true;
			}
			return !isFormValuesEqual;
		}
	}
});
</script>
