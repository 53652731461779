<template>
	<table
		v-if="variableRows.length > 0"
		:id="`table-${deployment.id}`"
		class="flow-table border-bottom width-100-per"
		:class="{ 'margin-bt-32': isOpen }"
	>
		<template v-if="isOpen">
			<tbody
				v-for="variableRow in variableRows"
				:key="`${variableRow.key}-${deployment.id}`"
				class="flow-table-body"
			>
				<tr
					class="flow-table-row highlight-on-hover"
					:data-qa-variable-row-key="variableRow.key"
					:data-qa-variable-row-value="variableRow.value"
				>
					<td class="flow-table-cell cell-padding-12 padding-rt-40 sticky-horizontal" width="10%">
						<Typography type="p2" color="gray-200" overflow="ellipsis" family="logs">{{
							variableRow.key
						}}</Typography>
					</td>

					<VariableListColumn
						:show-values="true"
						:variable-column="variableRow"
						:column-name="deployment.name ?? ''"
					/>
				</tr>
			</tbody>
		</template>
	</table>
</template>

<script lang="ts">
import { Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { AppDeployment } from "@/protocol/deployment";

import { envListStore } from "../env-list/env-list-store";
import { projectStore } from "../project-list/project-store";

import VariableListColumn from "./VariableListColumn.vue";
import { getVariableColumnForDeployment } from "./variables-list-store";

export default defineComponent({
	name: "VariableListSingleDeploymentTable",

	components: {
		Typography,
		VariableListColumn
	},

	props: {
		deployment: {
			type: Object as PropType<AppDeployment>,
			required: true
		}
	},

	emits: ["variable-row-count"],

	data() {
		return {
			isOpen: false
		};
	},

	computed: {
		env() {
			return envListStore.envList.find(env => env.id === this.deployment.envId);
		},

		project() {
			return projectStore.projects[this.deployment.projId!];
		},

		variableRows() {
			const { deployment, env, project } = this;

			if (!env || !project) {
				return [];
			}

			const variableKeys: Set<string> = new Set();

			[
				...Object.keys(this.deployment.deploymentConfig?.env ?? {}),
				...Object.keys(this.deployment.deploymentConfig?.sensitive ?? {})
			].forEach(varKey => variableKeys.add(varKey));

			return Array.from(variableKeys)
				.map(variableKey => {
					return getVariableColumnForDeployment({
						variableKey,
						deployment,
						env,
						project
					});
				})
				.sort((listA, listB) => {
					return (listA.key ?? "").localeCompare(listB.key ?? "");
				});
		}
	},

	watch: {
		variableRows: {
			immediate: true,

			handler() {
				this.$emit("variable-row-count", this.variableRows.length);
			}
		}
	},

	mounted() {
		// Application tables can be particularly slow to load and large, so we use idle callbacks to render them
		window.requestIdleCallback(
			() => {
				this.isOpen = true;
			},
			{ timeout: 5000 }
		);
	}
});
</script>
