<template>
	<Container
		padding="0"
		overflow="scroll"
		:grow="1"
		align="left top"
		:data-qa-dependency-details="dependency.name"
	>
		<Tabs :sticky="false" size="small" class="height-100-per width-100-per">
			<Tab
				width="150px"
				:selected="selectedTab === 'inputs'"
				data-qa-details-input-tab
				@click="selectedTab = 'inputs'"
			>
				<Typography type="p2" color="gray-300">Inputs</Typography>
			</Tab>
			<Tab
				width="150px"
				:selected="selectedTab === 'outputs'"
				data-qa-details-output-tab
				@click="selectedTab = 'outputs'"
			>
				<Typography type="p2" color="gray-300">Outputs</Typography>
			</Tab>
			<Tab
				width="150px"
				:selected="selectedTab === 'resolvers'"
				data-qa-details-resolvers-tab
				@click="selectedTab = 'resolvers'"
			>
				<Typography type="p2" color="gray-300">Resolvers</Typography>
			</Tab>
			<Tab
				v-if="!appWizardFlow"
				width="150px"
				:selected="selectedTab === 'used'"
				data-qa-details-used-tab
				@click="selectedTab = 'used'"
			>
				<Typography type="p2" color="gray-300">Used in apps</Typography>
			</Tab>
			<Tab
				width="150px"
				:selected="selectedTab === 'about'"
				data-qa-details-about-tab
				@click="selectedTab = 'about'"
			>
				<Typography type="p2" color="gray-300">About</Typography>
			</Tab>
			<template #content>
				<!-- Input and output tabs -->
				<DependencyInputOutput
					v-if="selectedTab === 'inputs' || selectedTab === 'outputs'"
					:selected-tab="selectedTab"
					:dependency="dependency"
				/>
				<!-- resolvers tab -->
				<DependencyResolvers
					v-if="selectedTab === 'resolvers'"
					:selected-tab="selectedTab"
					:resolvers="resolvers"
					:dependency="dependency"
				/>
				<!-- About tab -->
				<DependencyAbout
					v-if="selectedTab === 'about'"
					:selected-tab="selectedTab"
					:dependency="dependency"
				/>

				<!-- Used in -->
				<DependencyUsedIn v-if="selectedTab === 'used'" :selected-tab="selectedTab" />
			</template>
		</Tabs>
	</Container>
</template>
<script lang="ts">
import { Container, Tab, Tabs, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { orgStore } from "@/modules/org/org-store";
import { Dependency } from "@/protocol/infra";

import DependencyAbout from "./DependencyAbout.vue";
import DependencyInputOutput from "./DependencyInputOutput.vue";
import DependencyResolvers from "./DependencyResolvers.vue";
import DependencyUsedIn from "./DependencyUsedIn.vue";

export default defineComponent({
	name: "DependencyDetails",

	components: {
		Container,
		Tabs,
		Tab,
		Typography,
		DependencyInputOutput,
		DependencyResolvers,
		DependencyAbout,
		DependencyUsedIn
	},

	props: {
		dependency: {
			type: Object as PropType<Dependency>,
			required: true
		},

		appWizardFlow: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		selectedTab: "inputs" as DependencyDetailsTabs
	}),

	computed: {
		resolvers() {
			return orgStore.dependencyResolversList[this.dependency.id];
		}
	},

	mounted() {
		(async () => {
			await orgStore.FETCH_DEPENDENCY_RESOLVERS_LIST({
				id: this.dependency.id,
				orgId: this.dependency.orgId
			});
		})();
	}
});
export type DependencyDetailsTabs = "inputs" | "outputs" | "resolvers" | "used" | "about";
</script>
