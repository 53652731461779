<template>
	<PopOver
		:open="isOpen"
		:target="target ?? undefined"
		placement="top-end"
		@overlay-click="closeDialog"
	>
		<slot />
		<template #content>
			<AddVariableContent :pre-selected-ids="preSelectedIds" @close-dialog="closeDialog" />
		</template>
	</PopOver>
</template>

<script lang="ts">
import { PopOver } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import AddVariableContent from "./AddVariableContent.vue";

export default defineComponent({
	name: "AddVariableModal",

	components: {
		AddVariableContent,
		PopOver
	},

	data: () => ({
		target: null as string | null,
		isOpen: false,
		preSelectedIds: null as string[] | null
	}),

	methods: {
		closeDialog() {
			this.isOpen = false;
			this.target = null;
		},

		// eslint-disable-next-line vue/no-unused-properties
		openDialog(target: string, ids: string[]) {
			this.target = target || null;
			this.isOpen = true;
			this.preSelectedIds = ids;
		}
	}
});
</script>
