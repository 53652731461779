<template>
	<EmptyState :icon="emptyIcon" :message="emptyStateMessage" shape="none">
		<Wrapper width="400px" max-height="100px" class="overflow-visible">
			<Container
				:padding="0"
				direction="column"
				:gap="24"
				class="align-items-center"
				overflow="visible"
			>
				<Typography v-if="hasSearch" type="p1" color="light" class="text-align-center">
					No results found for the search term that you entered!<br />Try something else...
				</Typography>

				<Typography v-else-if="hasFilter" type="p1" color="light" class="text-align-center">
					No results found for this filter, try something else.
				</Typography>

				<Typography
					v-if="!hasSearch && !hasFilter && projectNameWithLabel"
					type="p1"
					color="light"
					class="text-align-center"
				>
					All the variables assigned to or being used under {{ projectNameWithLabel }}, its
					environments and applications, will be listed here.
				</Typography>

				<Typography
					v-else-if="!projectNameWithLabel"
					type="p1"
					color="light"
					class="text-align-center"
				>
					All the variables assigned will be listed here.
				</Typography>

				<PopOver
					v-if="currentEntityIds.length > 0"
					v-model:open="showVariablesPopover"
					placement="right-start"
					class="align-items-center"
					@overlay-click="toggleAddVariablePopOver"
				>
					<Button
						v-if="!hasSearch && !hasFilter"
						type="primary"
						class="width-250"
						state="outlined"
						@click="toggleAddVariablePopOver"
						>+ ADD NEW VARIABLE</Button
					>
					<template #content>
						<AddVariableContent
							v-if="showVariablesPopover"
							:pre-selected-ids="currentEntityIds"
							@close-dialog="toggleAddVariablePopOver"
						/>
					</template>
				</PopOver>
			</Container>
		</Wrapper>
	</EmptyState>
</template>

<script lang="ts">
import {
	PopOver,
	Button,
	Container,
	EmptyState,
	Typography,
	Wrapper,
	EmptyStateIconProp
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import AddVariableContent from "@/modules/variables-list/AddVariableContent.vue";
import { project as projectProto } from "@/protocol/identity";
import { getEmoji } from "@/utils";

export default defineComponent({
	name: "VariableListEmptyState",

	components: {
		AddVariableContent,
		PopOver,
		EmptyState,
		Button,
		Container,
		Typography,
		Wrapper
	},

	props: {
		project: {
			type: Object as PropType<projectProto>
		},

		entityIds: {
			type: Array as PropType<string[]>
		},

		hasSearch: {
			type: Boolean,
			required: true
		},

		hasFilter: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		emptyIcon: {
			name: "i-variable",
			type: "filled",
			state: "light",
			size: "xx-large"
		} as EmptyStateIconProp,

		showVariablesPopover: false
	}),

	computed: {
		currentEntityIds() {
			const ids: string[] = [];

			if (this.project) {
				ids.push(this.project.id);
			} else if (this.entityIds) {
				ids.push(...this.entityIds);
			}

			return ids;
		},

		projectNameWithLabel() {
			if (!this.project) {
				return null;
			}

			const emoji = getEmoji(this.project);
			const emojiString = emoji ? `${emoji} ` : "";
			return `${emojiString}${this.project.name}`;
		},

		emptyStateMessage() {
			if (this.hasSearch || this.hasFilter) {
				return "No results found";
			}

			if (this.projectNameWithLabel) {
				return `Variables of ${this.projectNameWithLabel}`;
			}

			return "No variables defined";
		}
	},

	methods: {
		toggleAddVariablePopOver() {
			this.showVariablesPopover = !this.showVariablesPopover;
		}
	}
});
</script>
