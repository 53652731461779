<template>
	<Container direction="column" align="stretch top" :padding="0" :gap="0" :shrink="0">
		<Divider />

		<SidebarItem>
			<Slab
				v-if="isUserOrgAdmin && !hasNxPlatformFlag"
				:selected="terraformPageActive"
				:type="terraformPageActive ? 'filled' : 'transparent'"
				:effects="true"
				data-qa-show-dependency-button
				@click="showTerraform"
			>
				<Pictogram
					v-tooltip="{
						content: 'Terraform component registry',
						container: 'div.flow-layout',
						placement: 'right-start'
					}"
					:effects="true"
					size="small"
					type="transparent"
				>
					<Icon name="p-terraform" color="gray-300" type="filled" size="20px" :effects="true" />
				</Pictogram>
				<p v-if="openMobileSidebar" class="paragraph-1 fc-normal">Terraform</p>
			</Slab>

			<Slab
				v-if="isUserOrgAdmin"
				:selected="credentialPageActive"
				:type="credentialPageActive ? 'filled' : 'transparent'"
				:effects="true"
				data-qa-show-creds-button
				@click="showCredentials"
			>
				<Pictogram
					v-tooltip="{
						content: 'Credentials',
						container: 'div.flow-layout',
						placement: 'right-start'
					}"
					:effects="true"
					size="small"
					type="transparent"
				>
					<Icon name="i-key" color="gray-300" type="filled" size="20px" :effects="true" />
				</Pictogram>
				<p v-if="openMobileSidebar" class="paragraph-1 fc-normal">Credentials</p>
			</Slab>
		</SidebarItem>
		<SidebarItem v-if="!isCurrentOrgDefault && !hasNxPlatformFlag">
			<Slab
				:selected="orgSettingsPageActive"
				:type="orgSettingsPageActive ? 'filled' : 'transparent'"
				:effects="true"
				data-qa-org-settings
				class="cursor-pointer"
				@click="showOrgSettings"
			>
				<Pictogram
					v-tooltip="{
						content: 'Organization settings',
						container: 'div.flow-layout',
						placement: 'right-start'
					}"
					:effects="true"
					size="small"
					type="transparent"
				>
					<Icon name="i-setting" color="gray-300" type="filled" size="20px" :effects="true" />
				</Pictogram>
				<p v-if="openMobileSidebar" class="paragraph-1 fc-normal">Organization Settings</p>
			</Slab>
		</SidebarItem>
	</Container>
</template>

<script lang="ts">
import { Pictogram, Icon, Slab, SidebarItem, Container, Divider } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { orgStore } from "@/modules/org/org-store";
import { userStore } from "@/modules/user/user-store";

export default defineComponent({
	name: "SidebarBottomSection",
	components: { Pictogram, Icon, Slab, SidebarItem, Container, Divider },

	props: {
		openMobileSidebar: {
			type: Boolean,
			required: true
		}
	},

	computed: {
		hasNxPlatformFlag() {
			return featureFlagStore.featureMap.ENABLE_NX_PLATFORM;
		},

		terraformPageActive() {
			return this.$route.name === "terraform-registry-global";
		},

		credentialPageActive() {
			return this.$route.name === "credentials-global";
		},

		orgSettingsPageActive() {
			return this.$route.name === "org-settings-global";
		},

		isCurrentOrgDefault() {
			return this.$route.params.orgId === userStore.profile?.id;
		},

		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		}
	},

	methods: {
		showTerraform() {
			if (this.$route.name === "terraform-registry-global") {
				return null;
			}

			const { orgId } = this.$route.params;

			if (!orgId) {
				return;
			}

			this.$router.push({ name: "terraform-registry-global", params: { orgId } }).catch(err => {
				throw new Error(err);
			});
		},

		showCredentials() {
			if (this.$route.name === "credentials-global") {
				return null;
			}

			const { orgId } = this.$route.params;

			if (!orgId) {
				return;
			}

			this.$router.push({ name: "credentials-global", params: { orgId } }).catch(err => {
				throw new Error(err);
			});
		},

		showOrgSettings() {
			if (this.$route.name === "org-settings-global") {
				return null;
			}

			const { orgId } = this.$route.params;

			if (!orgId) {
				return;
			}

			this.$router.push({ name: "org-settings-global", params: { orgId } }).catch(err => {
				throw new Error(err);
			});
		}
	}
});
</script>
