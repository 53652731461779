<template>
	<f-div width="100%" gap="large" height="100%" padding="none large">
		<f-div v-if="isLoading" align="middle-center">
			<CustomLoader />
		</f-div>
		<EmptyState
			v-else-if="!classifications.length"
			:icon="{
				name: 'i-plus',
				type: 'filled',
				state: 'default',
				size: 'medium',
				color: 'gray-200'
			}"
			message="No environment classification"
			subtitle="No environment classification available for this project"
			shape="squircle"
		/>
		<f-div v-else>
			<f-grid :min-cell-width="300">
				<f-div
					v-for="item in classifications"
					:id="item.id"
					:key="item.id"
					height="100%"
					width="fill-container"
					state="secondary"
					variant="curved"
					padding="large medium medium large"
					gap="small"
				>
					<f-div direction="column" width="fill-container" height="hug-content">
						<f-div border="small solid subtle bottom" padding="none none medium none">
							<f-div width="hug-content">
								<f-icon
									:source="getClassificationIcon(item)"
									:state="getClassificationIcon(item) === 'i-prod' ? 'warning' : 'default'"
									size="small"
								/>
							</f-div>
							<f-div width="hug-content" padding="none none none medium">
								<f-text
									variant="heading"
									size="small"
									weight="regular"
									data-qa-classification-details-name
									>{{ item.name }}</f-text
								>
							</f-div>
						</f-div>
						<f-div
							border="small solid subtle bottom"
							padding="medium none medium none"
							gap="none"
							direction="column"
						>
							<f-div
								width="fill-container"
								align="middle-center"
								gap="auto"
								height="fill-container"
								padding="small none"
							>
								<f-div width="fill-container" align="top-left">
									<f-text variant="para" size="small" weight="regular" state="secondary"
										>Applied policy set
									</f-text>
								</f-div>
								<f-div width="fill-container" align="top-left" padding="none medium">
									<f-div width="hug-content">
										<f-icon source="i-shield" size="x-small" state="warning"> </f-icon>
									</f-div>
									<f-div width="hug-content" padding="none none none medium">
										<f-text
											variant="para"
											size="small"
											data-qa-classification-details-applied-policy
											>{{ getAppliedPolicySet(item?.policySetId) }}
										</f-text>
									</f-div>
								</f-div>
							</f-div>
						</f-div>
						<f-div padding="medium none none none">
							<f-button
								:id="getAdvanceConfigTarget(item.id)"
								:key="item.id"
								size="small"
								variant="round"
								category="transparent"
								label="VIEW ADVANCE CONFIG"
								icon-right="i-chevron-right"
								state="primary"
								padding="0px"
								data-qa-env-classification-view-advance-config
								@click="selectedClassification = item"
							/>
						</f-div>
					</f-div>
				</f-div>
			</f-grid>

			<EnvClassificationConfig
				v-if="selectedClassification"
				:icon="getClassificationIcon(selectedClassification)"
				:popover-target="getAdvanceConfigTarget(selectedClassification.id)"
				:applied-policy-set="getAppliedPolicySet(selectedClassification?.policySetId)"
				:classification="selectedClassification"
				@close="selectedClassification = null"
			/>
			<!-- Info Popover -->
			<InfoPopover
				title="Environment classifications"
				:is-open="showInfo"
				placement="right-start"
				target="classifications"
				:show-overlay="true"
				@close="hideInfoPopover"
			>
				<f-div>
					<f-text variant="para" size="small">
						Code Pipes classifies environments into 3 types. Dev, Staging and Production (Prod)
					</f-text>
				</f-div>
				<f-div padding="large none none none">
					<f-text>
						These classifications have default configurations that are configured on an organization
						level.
					</f-text>
				</f-div>
				<f-div padding="large none none none">
					<f-text>
						On this tab, you can enable what type of classifications are allowed by toggling them on
						or off.
					</f-text>
				</f-div>
			</InfoPopover>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { EmptyState } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { credentialStore } from "@/modules/credentials/credential-store";
import { classification } from "@/protocol/infra";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";
import { getClassificationIcon } from "@/utils";

import { orgStore } from "../org/org-store";
import { NULL_STR_POLICY_UUID } from "../policy-list/constants";
import { policyListStore } from "../policy-list/policy-list-store";
import { userStore } from "../user/user-store";

import EnvClassificationConfig from "./EnvClassificationConfig.vue";

export default defineComponent({
	name: "EnvClassification",

	components: {
		CustomLoader,
		EmptyState,
		EnvClassificationConfig,
		InfoPopover
	},

	data: () => ({
		isLoading: false,
		showInfo: false,
		selectedClassification: null as classification | null
	}),

	computed: {
		orgId() {
			return orgStore.activeOrgId ?? (this.$route.params.orgId as string);
		},

		classifications() {
			return credentialStore.classificationList;
		},

		infoFlags() {
			return userStore.profile?.metadata?.infoFlags;
		}
	},

	mounted() {
		(async () => {
			this.isLoading = true;
			if (!this.infoFlags?.envClassification) {
				this.showInfo = true;
			}
			if (this.orgId) {
				await credentialStore.GET_CLASSIFICATIONS({ orgId: this.orgId });
			}
			this.isLoading = false;
		})();
	},

	methods: {
		getClassificationIcon,

		getAdvanceConfigTarget(id: string) {
			return `ad-${id}`;
		},

		getAppliedPolicySet(policyId: string | undefined) {
			const noPolicyExist = policyId === NULL_STR_POLICY_UUID;
			let policyName = "No policy exists";
			if (!noPolicyExist) {
				policyName =
					policyListStore.policySets.find(_policy => _policy.id === policyId)?.name ?? "NA";
			}

			return policyName;
		},

		hideInfoPopover() {
			this.showInfo = false;
			userStore.UPDATE_USER_META({
				infoFlags: {
					...this.infoFlags,
					envClassification: true
				}
			});
		}
	}
});
</script>
