<template>
	<Section>
		<CustomLoader />
	</Section>
</template>

<script lang="ts">
import { Section } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import CustomLoader from "@/shared/components/CustomLoader.vue";

export default defineComponent({
	name: "EnvCreateLoaderScreen",

	components: {
		Section,
		CustomLoader
	}
});
</script>
