<script lang="ts">
import { Icon } from "@cldcvr/flow-vue3";
import { PropType } from "vue";

import { integrationJobAbstract } from "@/protocol/app";
import { CustomTimeStamp } from "@/shared/components";

export default {
	name: "IntegrationStatusText"
};
</script>
<script setup lang="ts">
defineProps({
	integrationJob: {
		type: Object as PropType<integrationJobAbstract>
	}
});
</script>

<template>
	<template v-if="integrationJob?.jobStatus === 'failed'">
		<Icon name="i-stop" size="x-small" state="error" />
		<CustomTimeStamp
			v-if="integrationJob.updatedAt"
			:time="integrationJob.updatedAt"
			prefix="Failed"
			data-qa="int-pipeline-failed-txt"
		/>
	</template>
	<template v-if="integrationJob?.jobStatus === 'done'">
		<Icon name="i-tick" size="x-small" state="success" />
		<CustomTimeStamp
			v-if="integrationJob.updatedAt"
			:time="integrationJob.updatedAt"
			prefix="Last run"
			data-qa="int-pipeline-last-run-txt"
		/>
	</template>
	<template v-if="integrationJob?.jobStatus === 'canceled'">
		<Icon name="i-stop" size="x-small" state="error" />
		<CustomTimeStamp
			v-if="integrationJob.updatedAt"
			:time="integrationJob.updatedAt"
			prefix="Cancelled"
			data-qa="int-pipeine-cancelled-txt"
		/>
	</template>
</template>
