<template>
	<Tabs data-qa-env-pipeline-logs-view-screen class="width-100-per height-100-per">
		<Tab
			v-if="showLogsError"
			:selected="currentTab === 'issues'"
			width="100px"
			data-qa-issues-tab
			@click="currentTab = 'issues'"
		>
			<Typography type="p1" color="danger-200" weight="bold">Issues</Typography>
		</Tab>

		<Tab
			v-for="(file, idx) in filesList"
			:key="idx + file"
			:selected="currentTab === file"
			width="auto"
			:data-qa-log-terminal="file"
			@click="currentTab = file"
		>
			<Typography type="p2">{{ formatTabName(file) }}</Typography>
		</Tab>

		<PipelineTerminalActions
			:logs="logDownloadOptions"
			:enable-filters="enablePipelineFilters"
			:enable-wrapping="Boolean(filteredArtifactString)"
			:filter-id="terminalFilter?.id"
			:enabled-filter-ids="enabledFilterIds"
			@toggle-wrap="wrapText = $event"
			@toggle-terminal-filter="toggleTerminalFilter"
		>
			<slot name="pipeline-actions"></slot>
		</PipelineTerminalActions>

		<template #content>
			<Container :padding="0" direction="column" :gap="0" align="left top">
				<PipelineLogError
					v-if="currentTab === 'issues' && showLogsError && currentJob.message"
					:errors="[currentJob.message]"
				/>

				<Container v-else padding="12px 0 0 12px" :grow="1" align="left stretch">
					<PipelineLogStatus
						v-if="currentTab && pipelineLogStatus !== 'loaded'"
						:entity-name="formatTabName(currentTab)"
						:status="pipelineLogStatus"
						:is-plural="currentTab === 'Logs'"
						@download-logs="fetchCurrentArtifact"
					/>

					<LogsView
						v-else-if="filteredArtifactString"
						:logs="filteredArtifactString"
						:wrap-text="wrapText"
						data-qa-terminal
					>
					</LogsView>
				</Container>
			</Container>
		</template>
	</Tabs>
</template>

<script lang="ts">
import { Container, Tab, Tabs, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { envListStore } from "@/modules/env-list/env-list-store";
import { jobStep } from "@/protocol/infra";
import PipelineLogError from "@/shared/components/pipelines/PipelineLogError.vue";
import PipelineLogStatus from "@/shared/components/pipelines/PipelineLogStatus.vue";
import PipelineTerminalActions, {
	LogItem
} from "@/shared/components/pipelines/PipelineTerminalActions.vue";
import LogsView from "@/shared/LogsView.vue";
import {
	JOB_STATUS,
	PIPELINE_UNFINISHED_JOB_STATUSES,
	PipelineJobStatus,
	TERMINAL_FILTERS,
	TERMINAL_FILTER_ITEMS,
	TerminalFilter,
	TerminalFilterId
} from "@/shared/pipeline-constants";
import {
	captureError,
	downloadFile,
	fileToString,
	isJSONLogArtifact,
	isPlainLogArtifact
} from "@/utils";

import { newEnvPipelineStore } from "../env-pipeline-store";
import { EnvPipelineViewedJob } from "../env-pipeline-types";

export default defineComponent({
	name: "EnvLogsView",

	components: {
		Container,
		PipelineTerminalActions,
		PipelineLogError,
		PipelineLogStatus,
		Tab,
		Tabs,
		Typography,
		LogsView
	},

	props: {
		currentStep: {
			type: Object as PropType<jobStep>,
			required: true
		},

		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		envId: {
			type: String,
			required: true
		},

		currentJob: {
			type: Object as PropType<EnvPipelineViewedJob>,
			required: true
		}
	},

	data: () => ({
		wrapText: false,
		terminalFilter: null as TerminalFilter | null,
		artifactLoading: false,
		artifactDownloadFailed: false,
		currentTab: null as null | "issues" | string
	}),

	computed: {
		showLogsError() {
			// Don't show log errors when pipeline is not finished
			if (this.isRunningPipeline) {
				return false;
			}

			return this.currentJob.message !== "";
		},

		filesList() {
			return [...this.logFiles, ...this.outputFiles];
		},

		logFiles() {
			return this.currentStep.logs ?? [];
		},

		outputFiles() {
			return this.currentStep.outputs ?? [];
		},

		logDownloadOptions() {
			const logs: LogItem[] = this.logFiles.map((logFile, idx) => ({
				name: logFile,
				showDivider: idx === this.logFiles.length - 1,
				downloadLog: () => this.downloadFile(logFile)
			}));

			logs.push(
				...this.outputFiles.map((outputFile, idx) => ({
					name: outputFile,
					showDivider: idx === this.outputFiles.length - 1,
					downloadLog: () => this.downloadFile(outputFile)
				}))
			);

			logs.push({
				name: "Terraform state",
				showDivider: false,
				downloadLog: () => this.downloadTerraformState()
			});

			return logs;
		},

		enabledFilterIds() {
			const enabledFilterIds: Set<TerminalFilterId> = new Set();

			const artifactString = this.currentArtifactString ?? "";

			artifactString.split("\n").forEach(line => {
				TERMINAL_FILTER_ITEMS.forEach(filter => {
					if (line.includes(filter.filterString)) {
						enabledFilterIds.add(filter.id);
					}
				});
			});

			return Array.from(enabledFilterIds);
		},

		enablePipelineFilters() {
			return this.logFiles.length > 0 && this.pipelineLogStatus === "loaded";
		},

		isRunningPipeline() {
			return PIPELINE_UNFINISHED_JOB_STATUSES.includes(this.currentJob.status as PipelineJobStatus);
		},

		pipelineLogStatus():
			| "loading"
			| "progress"
			| "cancelled"
			| "download-failed"
			| "pipeline-failed"
			| "loaded"
			| "empty" {
			if (this.currentArtifact) {
				return "loaded";
			}

			if (this.artifactLoading) {
				return "loading";
			}

			if (this.isRunningPipeline) {
				return "progress";
			}

			if (this.currentJob.status === JOB_STATUS.CANCELLED) {
				return "cancelled";
			}

			if (this.currentJob.status === JOB_STATUS.FAILED) {
				return "pipeline-failed";
			}

			if (this.artifactDownloadFailed) {
				return "download-failed";
			}

			if (this.currentArtifactString?.length === 0) {
				return "empty";
			}

			return "loaded";
		},

		currentArtifact() {
			if (!this.currentTab || this.currentTab === "issues") {
				return null;
			}

			const artifact = newEnvPipelineStore.artifacts[this.currentJob.id]?.[this.currentTab];

			if (
				(isPlainLogArtifact(artifact) || isJSONLogArtifact(artifact)) &&
				artifact.contents.length === 0
			) {
				return null;
			}

			return artifact ?? null;
		},

		currentArtifactString() {
			if (!this.currentArtifact) {
				return null;
			}

			return fileToString(this.currentArtifact);
		},

		filteredArtifactString() {
			const { currentArtifactString, terminalFilter } = this;

			if (currentArtifactString && terminalFilter) {
				return currentArtifactString
					.split("\n")
					.filter(logLine => {
						return logLine.includes(terminalFilter.filterString);
					})
					.join("\n");
			}

			return currentArtifactString;
		}
	},

	watch: {
		currentStep() {
			this.resetCurrentFile();
		},

		currentTab: {
			immediate: true,

			handler() {
				if (!this.currentTab) {
					this.resetCurrentFile();
				}
				this.fetchCurrentArtifact();
			}
		},

		currentJob: {
			deep: true,

			handler() {
				if (!this.currentArtifact && !this.artifactLoading) {
					this.fetchCurrentArtifact();
				}
			}
		},

		showLogsError: {
			immediate: true,

			handler() {
				if (this.showLogsError) {
					this.currentTab = "issues";
				}
			}
		}
	},

	methods: {
		// Get pretty tab names from our log files
		formatTabName(tabName: string) {
			if (tabName === "terraform.tfplan.json") {
				return "Output";
			}

			if (tabName === "terraform.tfstate") {
				return "Terraform state";
			}

			const newTabName = tabName
				.replace(/[_-]/gi, " ")
				// All log files begin with their step name which is not necessary to render
				.replace(/^(Plan|Validate|Apply|Sync)/i, "")
				.replace(".log", "")
				.replace(".json", "")
				.trim();

			return `${newTabName.substring(0, 1).toLocaleUpperCase()}${newTabName.substring(1)}`;
		},

		resetCurrentFile() {
			this.currentTab = this.filesList[0] ?? null;
		},

		fetchCurrentArtifact() {
			if (!this.currentTab || this.currentTab === "issues") {
				return null;
			}

			return this.fetchArtifact(this.currentTab);
		},

		async fetchArtifact(fileName: string) {
			try {
				this.artifactDownloadFailed = false;
				this.artifactLoading = true;

				await newEnvPipelineStore.FETCH_ARTIFACT({
					fileName,
					job: this.currentJob
				});
			} catch (err) {
				this.artifactDownloadFailed = true;
				captureError(err);
			} finally {
				this.artifactLoading = false;
			}
		},

		async downloadFile(fileName: string) {
			let artifact = newEnvPipelineStore.artifacts[this.currentJob.id]?.[fileName] ?? null;

			if (!artifact) {
				await this.fetchArtifact(fileName);
			}

			artifact = newEnvPipelineStore.artifacts[this.currentJob.id]?.[fileName] ?? null;

			if (artifact) {
				downloadFile(artifact, fileName);
			}
		},

		async downloadTerraformState() {
			const content = await envListStore.GET_OUTPUT_FOR_ENV({
				orgId: this.orgId,
				projectId: this.projectId,
				envId: this.envId
			});

			downloadFile(content, `${this.currentJob.id}.tfstate`);
		},

		toggleTerminalFilter(filter: TerminalFilterId) {
			this.terminalFilter = TERMINAL_FILTERS[filter];
		}
	}
});
</script>
