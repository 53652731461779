<template>
	<PipelineStageView
		v-if="chartMetaData?.nodes.length"
		data-qa-app-dag-view
		:nodes="nodes"
		:edges="chartMetaData.edges"
		title="Stages"
		@select-stage="stepClick"
	/>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { jobResponse } from "@/protocol/deployment";
import PipelineStageView from "@/shared/components/pipelines/PipelineStageView.vue";

import { getAppDagMeta } from "../application-deployment-store";

export default defineComponent({
	name: "AppDagView",

	components: {
		PipelineStageView
	},

	props: {
		job: {
			type: Object as PropType<jobResponse>,
			required: true
		},

		currentStepIndex: {
			type: Number,
			required: true
		}
	},

	emits: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		stepChanged: (_step: number) => {
			return true;
		}
	},

	computed: {
		chartMetaData() {
			return getAppDagMeta(this.job);
		},

		nodes() {
			const { steps } = this.job;
			const jobStep = steps?.[this.currentStepIndex];

			return [...this.chartMetaData.nodes].map(node => {
				return {
					...node,
					selected: node.name === jobStep?.name
				};
			});
		}
	},

	methods: {
		stepClick(nodeName: string) {
			const { steps, progress } = this.job;

			if (!steps) {
				return;
			}

			const jobStepIdx = steps.findIndex(step_ => step_.name === nodeName);
			const jobStep = steps[jobStepIdx];
			const currentStep = progress ?? 0;

			if (jobStep && jobStepIdx <= currentStep) {
				this.$emit("stepChanged", jobStepIdx);
			}
		}
	}
});
</script>
