<template>
	<Container :padding="0" :grow="1" align="center" align-self="center">
		<EmptyState :icon="emptyIcon" shape="squircle">
			<Container
				data-qa-empty-policy-set-list
				align="center"
				direction="column"
				:gap="24"
				:padding="0"
				align-self="center"
			>
				<!-- <Container
					v-if="fromSearch"
					align="center"
					:gap="8"
					direction="column"
					:padding="0"
					data-qa-empty-search-policy-set
				>
					<Typography type="h4" weight="bold" color="dark">No control gates available</Typography>
					<Typography type="p2-para" color="gray-200" class="width-350 text-align-center"
						>No results found for the search term you entered.</Typography
					>
					<Typography type="p2-para" color="gray-200" class="width-350 text-align-center"
						>Try something else.</Typography
					>
				</Container> -->

				<Container
					align="center"
					:gap="8"
					direction="column"
					:shrink="0"
					:padding="0"
					data-qa-empty-policy-set
				>
					<Typography type="h4" weight="bold" color="dark" data-qa-empty-policy-set-text
						>No control gates available</Typography
					>
					<Typography type="p2-para" color="gray-100" class="width-350 text-align-center"
						>There are no control gates available in this organization.</Typography
					>
				</Container>

				<slot />
			</Container>
		</EmptyState>
	</Container>
</template>
<script lang="ts">
import { Container, EmptyState, EmptyStateIconProp, Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyPolicyListDetails",
	components: { Container, EmptyState, Typography },

	data: () => ({
		emptyIcon: {
			name: "i-shield",
			type: "filled",
			state: "light",
			size: "large"
		} as EmptyStateIconProp
	})
});
</script>
