<template>
	<div class="stage-node dag-node" :data-selected="selected || null" :data-status="status">
		<Container :padding="0" align="space-between center" :grow="1">
			<Container :padding="0" :gap="4" direction="column">
				<Typography type="p2" weight="bold" color="dark" transform="uppercase">{{
					name
				}}</Typography>
				<Typography type="p3" color="light">{{ statusText }}</Typography>
			</Container>
			<PipelineIconStatus :status="status" />
		</Container>
	</div>
</template>
<script lang="ts">
import { Container, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { PipelineJobStepStatus } from "@/shared/pipeline-constants";

import PipelineIconStatus from "./PipelineIconStatus.vue";

export default defineComponent({
	name: "PipelineStageNode",

	components: {
		Container,
		PipelineIconStatus,
		Typography
	},

	props: {
		name: String,
		statusText: String,

		status: {
			type: String as PropType<PipelineJobStepStatus>,
			default: () => "Not Started"
		},

		selected: Boolean
	}
});
</script>
<style lang="scss">
div.stage-node {
	border-radius: 4px;
	background-color: var(--gray-500) !important;
	display: flex;
	padding: 16px;
	align-items: flex-start;
	// Disabled until we get duration support
	// height: 60px;
	width: 250px;
	cursor: pointer;
	&[data-selected] {
		box-shadow: inset 0 0 0 2px var(--primary-300);
		outline: none;
	}

	&[data-status="Failed"] {
		background: linear-gradient(90deg, rgba(242, 66, 66, 0.16) 0%, rgba(242, 66, 66, 0) 22.62%);
	}
}
</style>
