<template>
	<td
		v-if="variableColumn"
		:data-qa-variable-column-name="variableColumn.name"
		class="flow-table-cell cell-padding-12 position-relative"
	>
		<RenderOnHover :padding="0">
			<template #placeholder>
				<Container
					:padding="0"
					:gap="12"
					class="cursor-pointer position-relative"
					overflow="visible"
				>
					<f-icon
						v-if="variableColumn.state === 'error'"
						source="i-close"
						size="x-small"
						state="warning"
						tooltip="Variable is not declared"
					/>

					<f-icon
						v-else-if="variableColumn.state === 'secret'"
						source="i-lock"
						size="small"
						state="subtle"
						tooltip="Variable is masked"
					/>

					<VariableListVariableInheritancePopOver
						v-else-if="variableColumn.state === 'success'"
						:variable-column="variableColumn"
						:show-value="showValues && variableColumn.value ? variableColumn.value : undefined"
					/>
				</Container>
			</template>

			<Container
				v-if="canShowAnyAction"
				:class="{ 'show-on-hover': currentActionDialog === null, 'overlay-actions': true }"
				:gap="16"
				overflow="visible"
			>
				<VariableListVariableInfoPopOver
					v-if="canShowInfo"
					:is-open="currentActionDialog === 'info'"
					:variable-column="variableColumn"
					@edit-variable="currentActionDialog = 'edit'"
					@open="currentActionDialog = 'info'"
					@close="currentActionDialog = null"
				/>

				<VariableListEditVariablePopOver
					v-if="canEditVariable"
					:is-open="currentActionDialog === 'edit'"
					:variable-column="variableColumn"
					:name="columnName"
					@open="currentActionDialog = 'edit'"
					@close="currentActionDialog = null"
				/>

				<VariableListVariableDeletePopOver
					v-if="canDeleteVariable"
					:is-open="currentActionDialog === 'delete'"
					:variable-column="variableColumn"
					:name="columnName"
					@open="currentActionDialog = 'delete'"
					@close="currentActionDialog = null"
				/>
			</Container>
		</RenderOnHover>
	</td>
</template>

<script lang="ts">
import { Container } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { envListStore } from "@/modules/env-list/env-list-store";
import RenderOnHover from "@/shared/components/RenderOnHover.vue";

import { VariableColumn } from "./variable-list-types";
import VariableListEditVariablePopOver from "./VariableListEditVariablePopOver.vue";
import VariableListVariableDeletePopOver from "./VariableListVariableDeletePopOver.vue";
import VariableListVariableInfoPopOver from "./VariableListVariableInfoPopOver.vue";
import VariableListVariableInheritancePopOver from "./VariableListVariableInheritancePopOver.vue";
import { canModifyVariable } from "./variables-list-utils";

export default defineComponent({
	name: "VariableListColumn",

	components: {
		Container,
		RenderOnHover,
		VariableListEditVariablePopOver,
		VariableListVariableDeletePopOver,
		VariableListVariableInfoPopOver,
		VariableListVariableInheritancePopOver
	},

	props: {
		variableColumn: {
			type: Object as PropType<VariableColumn>
		},

		showValues: {
			type: Boolean,
			required: true
		},

		columnName: {
			type: String,
			required: true
		}
	},

	data: () => ({
		currentActionDialog: null as "info" | "edit" | "delete" | null
	}),

	computed: {
		canShowAnyAction() {
			return this.canDeleteVariable || this.canShowInfo || this.canEditVariable;
		},

		canShowInfo() {
			return this.variableColumn?.state === "success";
		},

		canEditVariable() {
			return (
				this.variableColumn &&
				this.variableColumn.state !== "secret" &&
				canModifyVariable(this.variableColumn.editInfo)
			);
		},

		canDeleteVariable() {
			const { variableColumn } = this;

			if (!variableColumn) {
				return false;
			}

			const { editInfo } = variableColumn;

			// Environments inherit project values, make sure that it's not inherting before showing the delete button
			if (editInfo?.type === "environment") {
				const env = envListStore.envs[editInfo.projectId]?.find(
					envs_ => envs_.id === editInfo.envId
				);
				const hasVariableInEnv = env?.variables?.some(
					variable => variable.key === variableColumn.key
				);

				if (!hasVariableInEnv) {
					return false;
				}
			}

			return canModifyVariable(variableColumn.editInfo) && variableColumn.value !== null;
		}
	}
});
</script>
