<template>
	<Container padding="12px 0" align="left top" direction="column">
		<Container :shrink="0" padding="0 12px" align="space-between center">
			<Typography type="p2" weight="bold" color="dark" transform="uppercase">{{
				title
			}}</Typography>

			<Container v-if="!showAccordionView" :gap="12" :padding="0">
				<RadioGroup
					v-if="selectedView && selectedView.key === 'chart'"
					:options="zoomOptions"
					@input="zoom($event)"
				>
					<template #option="{ option }">
						<Icon
							v-tooltip="{
								content: option.tooltip,
								placement: 'bottom',
								classes: ['black']
							}"
							:name="option.icon"
							size="x-small"
							type="filled"
							:data-qa-stage-zoom-type="option.key"
							:state="option.selected ? 'dark' : 'light'"
							:effects="false"
						/>
					</template>
				</RadioGroup>

				<Divider
					v-if="selectedView && selectedView.key === 'chart'"
					direction="vertical"
					class="height-20"
					:size="2"
				/>

				<RadioGroup :options="viewTypes">
					<template #option="{ option }">
						<Icon
							v-tooltip="{
								content: option.tooltip,
								placement: 'bottom',
								classes: ['black']
							}"
							:name="option.icon"
							size="small"
							type="filled"
							:data-qa-stage-view-type="option.key"
							:state="option.selected ? 'dark' : 'light'"
							:effects="false"
						/>
					</template>
				</RadioGroup>
			</Container>
		</Container>

		<AccordionStagesView
			v-if="showAccordionView"
			:selected-view="selectedView"
			:nodes="nodes"
			:pipeline-modules="pipelineModules"
			@select-stage="selectStage"
		/>

		<Container
			v-if="selectedView && selectedView.key === 'list' && !showAccordionView"
			:padding="0"
			direction="column"
			:gap="0"
		>
			<Slab
				v-for="(node, idx) in nodes"
				:key="node.name"
				:type="node.selected ? 'filled' : 'bottom-border'"
				size="large"
				:effects="!node.selected"
				:selected="node.selected"
				:state="node.status === 'Failed' ? 'error' : 'default'"
				:state-type="node.selected ? 'solid' : 'gradient'"
				:data-stage-state="node.status"
				:data-qa-stage-node="idx + 1"
				:data-qa-stage-status-name="node.name"
				:data-qa-stage-status-text="node.statusText"
				:data-qa-stage-status-type="node.status"
				class="cursor-pointer"
				@click="selectStage(node.name)"
			>
				<template #primary-action>
					<PipelineIconStatus :status="node.status" />
				</template>
				<Typography
					type="h4"
					weight="regular"
					:data-qa-stage-status-name="node.name"
					:color="node.status !== 'Waiting' ? 'dark' : 'gray-200'"
					>{{ node.name }}</Typography
				>
				<template #secondary-actions>
					<Typography type="p2" color="gray-300" :data-qa-stage-status-text="node.statusText">{{
						node.statusText
					}}</Typography>
				</template>
			</Slab>
		</Container>
		<DagChart
			v-if="selectedView && selectedView.key === 'chart'"
			ref="dagChart"
			direction="vertical"
			:show-controls="false"
			:nodes="nodes"
			:edges="edges"
			:node-config="{ width: 250, height: 30 }"
			:layout-config="{ ranksep: 60, marginx: 30 }"
			@click="selectStage"
		>
			<template #default="{ nodeId, data }">
				<Teleport :to="`#${nodeId}`">
					<PipelineStageNode v-bind="data" />
				</Teleport>
			</template>
		</DagChart>
	</Container>
</template>
<script lang="ts">
import {
	Container,
	DagChart,
	Divider,
	Icon,
	RadioGroup,
	Slab,
	Typography
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { PipelineModuleMeta } from "@/modules/application-integration/components/AddIntegrationPipelineWrapper.vue";
import { PipelineEdge, PipelineNode } from "@/shared/pipeline-constants";

import AccordionStagesView from "./AccordionStagesView.vue";
import PipelineIconStatus from "./PipelineIconStatus.vue";
import PipelineStageNode from "./PipelineStageNode.vue";

export type Radio = {
	key: string;
	icon: string;
	selected: boolean;
	tooltip?: string;
};

export default defineComponent({
	name: "PipelineStageView",

	components: {
		Container,
		Typography,
		RadioGroup,
		Icon,
		Divider,
		DagChart,
		Slab,
		PipelineIconStatus,
		PipelineStageNode,
		AccordionStagesView
	},

	props: {
		edges: {
			type: Array as PropType<PipelineEdge[]>,
			required: true
		},

		nodes: {
			type: Array as PropType<PipelineNode[]>,
			required: true
		},

		title: {
			type: String,
			required: true
		},

		showAccordionView: {
			type: Boolean,
			default: false
		},

		pipelineModules: {
			type: Array as PropType<PipelineModuleMeta[]>
		}
	},

	emits: ["select-stage"],

	data: () => ({
		viewTypes: [
			{
				key: "list",
				icon: "i-hamburger",
				selected: true
			},
			{
				key: "chart",
				icon: "i-tree",
				selected: false
			}
		] as Radio[],

		zoomOptions: [
			{
				key: "zoomin",
				icon: "i-plus",
				selected: false,
				tooltip: "Zoom in"
			},
			{
				key: "zoomout",
				icon: "i-minus",
				selected: false,
				tooltip: "Zoom out"
			}
		] as Radio[],

		zoomIndex: 0
	}),

	computed: {
		selectedView() {
			return this.viewTypes.find(v => v.selected);
		}
	},

	methods: {
		zoom(option: Radio) {
			if (option.key === "zoomin") {
				this.zoomIndex += 1;
				//@ts-expect-error DagChart component is not typed fully
				this.$refs.dagChart.zoomIn();
			}

			if (option.key === "zoomout") {
				this.zoomIndex -= 1;
				//@ts-expect-error DagChart component is not typed fully
				this.$refs.dagChart.zoomOut();
			}

			if (this.zoomIndex === 0) {
				this.zoomOptions.forEach(z => (z.selected = false));
			}
		},

		// DagChart events are not fully typed
		selectStage(nodeName?: unknown) {
			if (typeof nodeName === "string") {
				this.$emit("select-stage", nodeName);
			}
		}
	}
});
</script>
<style lang="scss">
div[data-stage-state="error"] {
	background: linear-gradient(90deg, rgba(242, 66, 66, 0.16) 0%, rgba(242, 66, 66, 0) 22.62%);
}
</style>
