<template>
	<Container :padding="0" align="left top" direction="column" overflow="auto" :grow="1">
		<Accordion
			v-for="groupedComponent in groupedComponents"
			:key="groupedComponent.groupName"
			:open="!closedGroups[groupedComponent.groupName]"
		>
			<template #header>
				<Container
					:clickable="true"
					align="space-between center"
					padding="10px 16px"
					:grow="1"
					overflow="visible"
					:data-qa-add-terraform-group="groupedComponent.groupName"
					@click="$emit('setClosedGroups', groupedComponent.groupName)"
				>
					<Container :gap="12" :padding="0">
						<Typography transform="uppercase" weight="book" type="h5" color="gray-200">{{
							groupedComponent.groupName
						}}</Typography>
					</Container>
					<Icon
						name="i-chevron-down"
						size="x-small"
						:rotate="!closedGroups[groupedComponent.groupName] ? 180 : 0"
						color="gray-300"
					/>
				</Container>
			</template>
			<Container
				v-for="(component, idx) in groupedComponent.componentsList"
				:id="idx"
				:key="component.id"
				:padding="0"
				overflow="auto"
				:background="
					component.id === selectedTerraform.id ? 'element-light' : 'dashboard-background'
				"
				:clickable="true"
				:data-qa-add-terraform-name="component.title"
				@click="$emit('selectTerraform', component)"
			>
				<Container :padding="16">
					<Container :gap="16" :padding="0" align="left center">
						<Container padding="0" align="left center">
							<Pictogram size="l" :label="getShortCode(component.title)" state="avatar">
							</Pictogram>
						</Container>
						<Container :padding="0" :gap="4" direction="column" align="left center">
							<Typography data-color="dark" type="p1" weight="bold">{{
								component.title
							}}</Typography>
						</Container>
					</Container>
				</Container>
			</Container>
		</Accordion>
	</Container>
</template>
<script lang="ts">
import { Container, Pictogram, Typography, Accordion, Icon } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { GroupComponents } from "@/modules/terraform-registry/terraform-registry-types";
import { getShortCode } from "@/utils";

export default defineComponent({
	name: "TerraformEntity",

	components: {
		Container,
		Accordion,
		Icon,
		Pictogram,
		Typography
	},

	props: {
		groupedComponents: {
			type: Array as PropType<GroupComponents[]>,
			required: true
		},

		closedGroups: {
			type: Object,
			required: true
		},

		selectedTerraform: {
			type: Object,
			required: true
		}
	},

	methods: {
		getShortCode
	}
});
</script>
