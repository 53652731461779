<template>
	<!-- Empty State With no dependencies -->
	<EmptyDependencyList v-if="!isLoading && isEmpty && searchText === ''"> </EmptyDependencyList>

	<!-- Dependency list content -->
	<Container
		v-else-if="!selectedDependency"
		:data-qa-dependency-tab-content-loaded="!isLoading"
		direction="column"
		padding="20px 0"
		:gap="20"
		:grow="1"
		overflow="visible"
		class="height-100-per"
		align="left top"
	>
		<Container
			data-qa-dependency-tab-header
			:padding="0"
			overflow="visible"
			align="space-between top"
			class="dashboard-input"
		>
			<Container :padding="10" :gap="12" overflow="visible">
				<Typography type="h2">Dependencies</Typography>
			</Container>
			<Container :padding="10" :gap="12" class="dashboard-input">
				<SearchInput
					v-model:value="searchText"
					data-qa-dependency-tab-search
					placeholder="Search Dependencies"
					class="width-250"
				/>
			</Container>
		</Container>
		<Container v-if="!isLoading && isEmpty && searchText !== ''" :grow="1" align="center">
			<EmptyDependencyList :from-search="true" />
		</Container>

		<Container v-else padding="10" overflow="scroll" :grow="1" align="left top">
			<DependencyListTable
				v-model:sort-direction="sortDirection"
				:sorted-dependency-list="sortedDependencyList"
				:loading="isLoading"
				:selected-categories="selectedCategories"
				:categories="categories"
				@select-category="updateSelection"
				@reset="selectedCategories = []"
				@toggle-details="viewDetails"
			/>
		</Container>
	</Container>

	<!-- Selected Dependency content -->
	<Container
		v-else-if="selectedDependency"
		data-qa-selected-dependency-details
		direction="column"
		padding="20px 0"
		:gap="20"
		:grow="1"
		overflow="visible"
		class="height-100-per"
		align="left top"
	>
		<Container
			data-qa-selected-dependency-tab-header
			:padding="0"
			overflow="visible"
			align="space-between top"
			class="dashboard-input"
		>
			<Container :padding="10" :gap="12" overflow="visible">
				<Icon name="i-arrow-left" @click="selectedDependency = null" />
				<Typography type="h4" weight="bold">{{ selectedDependency.name }}</Typography>
			</Container>
		</Container>

		<Container padding="10" overflow="scroll" :grow="1" align="left top">
			<DependencyDetails :dependency="selectedDependency" />
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Icon, SearchInput, Typography } from "@cldcvr/flow-vue3";
import { orderBy } from "lodash-es";
import { defineComponent } from "vue";

import { orgStore } from "@/modules/org/org-store";
import { Dependency } from "@/protocol/infra";

import DependencyDetails from "./DependencyDetails.vue";
import DependencyListTable from "./DependencyListTable.vue";
import EmptyDependencyList from "./EmptyDependencyList.vue";

export default defineComponent({
	name: "DependencyListTab",

	components: {
		Container,
		EmptyDependencyList,
		Typography,
		SearchInput,
		DependencyListTable,
		Icon,
		DependencyDetails
	},

	data: () => ({
		isLoading: false,
		searchText: "",
		sortDirection: "asc" as "asc" | "desc",
		selectedCategories: [] as string[],
		selectedDependency: null as Dependency | null
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		isEmpty() {
			return this.sortedDependencyList.length === 0;
		},

		dependencyList() {
			return orgStore.dependencyList;
		},

		categories(): string[] {
			const categories: Set<string> = new Set();
			this.dependencyList.forEach(dependency => {
				if (dependency.group) {
					categories.add(dependency.group);
				}
			});
			return Array.from(categories);
		},

		sortedDependencyList() {
			return orderBy([...this.dependencyList], "name", this.sortDirection)
				.filter(dependency =>
					dependency.name.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
				)
				.filter(dependency =>
					this.selectedCategories.length
						? this.selectedCategories.includes(dependency.group!)
						: dependency
				);
		}
	},

	mounted() {
		this.isLoading = true;
		this.getDepedencyList();
	},

	methods: {
		async getDepedencyList(): Promise<void> {
			await orgStore.FETCH_DEPENDENCY_LIST({
				orgId: this.orgId
			});
			this.isLoading = false;
		},

		updateSelection(category: string) {
			if (this.selectedCategories.includes(category)) {
				this.selectedCategories.splice(this.selectedCategories.indexOf(category), 1);
			} else {
				this.selectedCategories.push(category);
			}
		},

		viewDetails(dependency: Dependency) {
			this.selectedDependency = dependency;
		}
	}
});
</script>
