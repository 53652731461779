<template>
	<Container direction="column" :padding="0" :gap="0" align="left top" :shrink="0">
		<Accordion :open="isAccordionOpen">
			<template #header>
				<Container
					align="space-between center"
					overflow="visible"
					padding="20px 8px"
					:clickable="!isAccordionOpen"
					@click="isAccordionOpen = !isAccordionOpen"
				>
					<Container :padding="0" :gap="8">
						<Icon name="i-box" size="small" :effects="false" color="gray-200" />
						<Typography type="h4" weight="medium" color="dark">App artifacts</Typography>
						<PopOver
							class="display-flex"
							:open="modalState !== 'closed'"
							@overlay-click="validateAndClosePopover"
						>
							<Button
								v-tooltip="{
									content: 'New artifact'
								}"
								state="icon"
								size="x-small"
								data-qa-add-artifact
								@click.stop="modalState = 'add-new'"
							>
								<Icon name="i-plus" color="gray-600" size="x-small" :effects="false" />
							</Button>
							<template #content>
								<!-- Renders the dropdown which allows user to select which container to add -->
								<!--Start : Widget + button options-->
								<Wrapper
									v-if="modalState === 'add-new'"
									border-radius="4px"
									background="element"
									:border="true"
									width="255px"
								>
									<Container direction="column" :padding="0" :gap="0">
										<Slab size="large" :effects="false" border-type="solid">
											<Typography type="p2" weight="medium" color="dark"
												>What would you like to add?</Typography
											>
											<template #secondary-actions>
												<Icon
													name="i-close"
													type="filled"
													size="x-small"
													@click.stop="modalState = 'closed'"
												/>
											</template>
										</Slab>
										<Slab
											size="large"
											type="transparent"
											:effects="true"
											class="cursor-pointer"
											data-qa-add-git-source
											@click.stop="modalState = 'git-source'"
										>
											<template #primary-action>
												<Icon
													name="i-git"
													type="filled"
													state="light"
													size="small"
													:effects="false"
												/>
											</template>
											<Typography type="h5" weight="regular">App source code repo</Typography>
										</Slab>
										<Slab
											size="large"
											type="transparent"
											:effects="true"
											class="cursor-pointer"
											data-qa-add-container-image
											@click.stop="modalState = 'container-image'"
										>
											<template #primary-action>
												<Icon
													name="i-box"
													type="filled"
													state="light"
													size="small"
													:effects="false"
												/>
											</template>
											<Typography type="h5" weight="regular">App container repo</Typography>
										</Slab>
									</Container>
								</Wrapper>
								<!--END : Widget + button options-->

								<ContainerImageModal
									v-else-if="modalState == 'container-image'"
									ref="containerForm"
									:app="app"
									:artifact="editedArtifact ?? undefined"
									@close="toggleAddContainerImage"
									@back="modalState = 'add-new'"
								/>

								<GitRevisionWrapper
									v-else-if="modalState === 'git-source'"
									ref="gitForm"
									:app="app"
									:edit-data="editedArtifact ?? undefined"
									@go-back="modalState = 'add-new'"
									@close="toggleAddContainerImage"
								/>
							</template>
						</PopOver>
					</Container>
					<Icon
						name="i-chevron-down"
						size="x-small"
						:rotate="isAccordionOpen ? 180 : 0"
						@click.stop="isAccordionOpen = !isAccordionOpen"
					/>
				</Container>
			</template>

			<Container padding="0" direction="column" :gap="0">
				<Container
					v-if="artifacts && artifacts.length < 1"
					align="center"
					direction="column"
					class="align-items-center height-100-per"
					:gap="12"
					:grow="1"
					data-qa-empty-artifacts
				>
					<Icon name="i-launch" type="filled" state="light" :effects="false" />
					<Typography type="p1" color="light">No artifacts available</Typography>
				</Container>
				<Container
					v-else
					direction="column"
					:padding="0"
					overflow="auto"
					align="left top"
					:shrink="0"
					:gap="0"
				>
					<Container padding="0 0 0 16px">
						<Divider />
					</Container>
					<template v-for="(artifact, artifactIdx) in artifacts" :key="artifact.id">
						<Container
							padding="0 0 0 16px"
							:data-qa-artifact="artifact.name"
							:data-qa-artifact-id="artifact.id"
						>
							<Slab
								size="large"
								type="transparent"
								border-type="dashed"
								:align-items="getArtifactSubtitle(artifact) ? 'start' : 'center'"
							>
								<template #primary-action>
									<Icon :name="getArtifactIcon(artifact)" size="small" />
								</template>
								<Container :gap="4" :padding="0" direction="column">
									<Typography data-qa-env-wid-apps-list-item-name type="p2" color="dark">{{
										artifact.name
									}}</Typography>
								</Container>
								<Typography
									v-if="getArtifactSubtitle(artifact)"
									type="p2"
									color="light"
									weight="regular"
									:data-qa-env-wid-apps-list-item-name="getArtifactSubtitle(artifact)"
									>{{ getArtifactSubtitle(artifact) }}</Typography
								>
								<template #secondary-actions>
									<Container
										class="show-on-hover"
										:gap="16"
										:grow="1"
										:padding="0"
										overflow="visible"
										align="right center"
									>
										<Icon
											v-if="canEditArtifact(artifact)"
											v-tooltip="{
												content: 'Edit artifact',
												container: 'div.flow-layout',
												placement: 'left'
											}"
											name="i-edit"
											size="small"
											:data-qa-edit-artifact="artifact.name"
											@click="openEditForm(artifact)"
										/>

										<Icon
											v-tooltip="{
												content: 'Delete artifact'
											}"
											name="i-delete"
											size="small"
											:data-qa-delete-artifact="artifact.name"
											@click="openDeleteConfirmation(artifact)"
										/>

										<Button
											v-if="canDeployArtifact"
											type="success"
											size="small"
											:data-qa-deploy-artifact="artifact.name"
											@click="$emit('deploy', artifact)"
											>deploy</Button
										>
									</Container>
								</template>
							</Slab>
						</Container>
						<Container v-if="artifacts && artifactIdx < artifacts.length - 1" padding="0 0 0 16px">
							<Divider type="dashed" />
						</Container>
					</template>
				</Container>
			</Container>
		</Accordion>
		<Divider />

		<PopOver
			v-if="deletedArtifact"
			:open="Boolean(deletedArtifact)"
			:target="`[data-qa-artifact-id='${deletedArtifact.id}']`"
			@overlay-click="deletedArtifact = null"
		>
			<template #content>
				<Wrapper border-radius="4px" background="gray-500" width="432px" data-qa-delete-artifact>
					<Container padding="20px 16px">
						<Typography type="p1-para" color="dark" data-qa-delete-dependency-confirm-message
							>Are you sure you want to delete {{ deletedArtifact.name }}?
						</Typography>
					</Container>

					<Footer>
						<Container direction="column" padding="0" :grow="1">
							<Button
								state="full"
								type="error"
								:loading="isDeleting"
								data-qa-delete-dependency-confirm-submit
								@click="deleteArtifact"
								>DELETE {{ deletedArtifact.name }}</Button
							>
						</Container>
					</Footer>
				</Wrapper>
			</template>
		</PopOver>
	</Container>
</template>

<script lang="ts">
import {
	Accordion,
	Button,
	Container,
	Divider,
	Icon,
	PopOver,
	Slab,
	Typography,
	Wrapper,
	Footer
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import ContainerImageModal from "@/modules/application/components/app-widget/ContainerImageModal.vue";
import { envListStore } from "@/modules/env-list/env-list-store";
import { app as appProto } from "@/protocol/app";
import { Artifact, ArtifactType } from "@/protocol/common";
import { project as projectProto } from "@/protocol/identity";
import GitRevisionWrapper from "@/shared/components/Form/GitRevisionWrapper.vue";
import { captureError, getArtifactIcon } from "@/utils";

export default defineComponent({
	name: "ApplicationsTabArtifacts",

	components: {
		Accordion,
		Button,
		Container,
		Divider,
		Icon,
		PopOver,
		Typography,
		Slab,
		Wrapper,
		ContainerImageModal,
		GitRevisionWrapper,
		Footer
	},

	props: {
		project: {
			type: Object as PropType<projectProto>,
			required: true
		},

		app: {
			type: Object as PropType<appProto>,
			required: true
		}
	},

	data: () => ({
		isDeleting: false,
		isAccordionOpen: true,
		editedArtifact: null as Artifact | null,
		deletedArtifact: null as Artifact | null,
		modalState: "closed" as "git-source" | "container-image" | "add-new" | "closed",
		getArtifactIcon
	}),

	computed: {
		canDeployArtifact() {
			const projectEnvs = envListStore.envs[this.project.id];
			return projectEnvs ? projectEnvs.length > 0 : false;
		},

		artifacts() {
			return [...(this.app.artifacts ?? [])].sort((artifact1, artifact2) => {
				return artifact1.name.toLocaleLowerCase().localeCompare(artifact2.name.toLocaleLowerCase());
			});
		}
	},

	methods: {
		async deleteArtifact() {
			const { deletedArtifact } = this;

			if (!deletedArtifact) {
				return;
			}

			try {
				this.isDeleting = true;
				await applicationStore.UPDATE_APPLICATION({
					id: this.app.id,
					orgId: this.app.orgId,
					projId: this.app.projId,
					name: this.app.name,
					description: this.app.description,
					artifacts:
						this.app.artifacts?.filter(artifact_ => artifact_.id !== deletedArtifact.id) ?? [],
					replaceVariables: false
				});
			} catch (err) {
				captureError(err);
			} finally {
				this.isDeleting = false;
				this.deletedArtifact = null;
			}
		},

		openDeleteConfirmation(artifact: Artifact) {
			this.deletedArtifact = artifact;
		},

		getContainerFormRef() {
			return this.$refs.containerForm as InstanceType<typeof ContainerImageModal> | undefined;
		},

		getGitFormRef() {
			return this.$refs.gitForm as InstanceType<typeof GitRevisionWrapper> | undefined;
		},

		openEditForm(artifact: Artifact) {
			this.editedArtifact = artifact;

			if (artifact.type === "GitCode") {
				this.modalState = "git-source";
			} else if (artifact.type === ArtifactType.ContainerImage) {
				this.modalState = "container-image";
			}
		},

		canEditArtifact(artifact: Artifact) {
			return (
				artifact.type === ArtifactType.ContainerImage || artifact.type === ArtifactType.GitCode
			);
		},

		toggleAddContainerImage(type = "closed") {
			this.modalState = type === "back" ? "add-new" : "closed";
			this.editedArtifact = null;
		},

		validateAndClosePopover() {
			if (this.modalState === "git-source") {
				this.getGitFormRef()?.validateAndCloseModal();
			} else if (this.modalState === "container-image") {
				this.getContainerFormRef()?.validateAndCloseModal();
			} else {
				this.toggleAddContainerImage();
			}
		},

		getArtifactSubtitle(artifact: Artifact) {
			if (artifact.gitCode) {
				return artifact.gitCode.repo;
			} else if (artifact.containerImage) {
				return artifact.containerImage.repo;
			}

			return null;
		}
	}
});
</script>
