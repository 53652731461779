/**
 * This module handles the lifecycle of realtime updates with dispatcher microservice.
 * All the functions dealing with the dispatcher SDK should be implemented here only.
 * @module DispatcherService
 */

import eventDispatcher from "@/shared/proto/events/event_dispatcher_grpc_web_pb";
import eventsPb from "@/shared/proto/events/events_pb";
import { TokenService } from "@/services/storage-service";
import { ENVIRONMENTS, getEnvironment } from "@/utils/get-environment";

/** @const {any} [enables the grpc-web to start recording grpc-stream] */
const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

let instance = null;

const { EventDispatcherClient } = eventDispatcher;
const { SubscribeEventRequest, ServiceName } = eventsPb;

/** DispatcherService  */
export default class DispatcherService {
	/**
	 * Create a dispatcher service.
	 * Calls the disapatcherSDK and sets the client to instance const.
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;
		this.client = new EventDispatcherClient(window.VUE_APP_DISPATCHER_URL, null, null);
		//enable dev tools to debug grpc request, enabled them on staging for debugging purpose
		if (
			getEnvironment() === ENVIRONMENTS.STAGING ||
			getEnvironment() === ENVIRONMENTS.DEVELOPMENT
		) {
			enableDevTools([this.client]);
		}
	}

	deleteClient() {
		if (instance) {
			instance = null;
		}
	}
	/**
	 * subscribeEvents function which connects to dispatcher stream and returns realtime updates
	 * @async
	 * @param {string} resourceId    [Id of the resource to monitor realtime update]
	 * @param {Object} onStream     [binds to data error stream ]
	 * @param {Object} onError       [binds to the error stream]
	 * @return {Object} stream      [A stream object from dispatcher client, only to be used to cancel a stream from handler]
	 */
	subscribeEvents(resourceId, onStream, onError) {
		try {
			const request = new SubscribeEventRequest();
			request.setResourcepath(resourceId);
			request.setService(ServiceName.VANGUARD);
			const token = TokenService.getToken();
			const metadata = {
				authorization: token
			};
			const stream = instance.client.subscribeEvents(request, metadata);
			stream.on("data", response => onStream(response.toObject()));
			stream.on("error", err => onError(err));
			stream.on("status", function (status) {
				console.log(status.code); // eslint-disable-line no-use-before-define
				console.log(status.details); // eslint-disable-line no-use-before-define
				console.log(status.metadata); // eslint-disable-line no-use-before-define
			});
			return stream;
		} catch (err) {
			console.log("Error in creating the grpc stream ", err); // eslint-disable-line no-use-before-define
		}
	}
}
