<template>
	<PopOver :open="isOpen" placement="bottom-start" @overlay-click="closeDialog">
		<Icon
			name="i-delete"
			class="cursor-pointer"
			:effects="false"
			type="filled"
			size="small"
			state="error"
			data-qa-variable-delete-icon
			@click="openDialog"
		/>
		<template #content>
			<Wrapper
				v-if="isOpen"
				border-radius="4px"
				background="element-light"
				:border="true"
				width="432px"
				height="216px"
			>
				<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
					<Header>
						<Container align="space-between center" :padding="0" :grow="1">
							<Container :gap="12" :padding="0">
								<Typography type="h4" color="dark"> Remove variable </Typography>
							</Container>
							<Icon
								name="i-close"
								type="filled"
								size="x-small"
								data-qa-var-delete-popover-icon
								@click="closeDialog"
							/>
						</Container>
					</Header>
					<Container :shrink="0" :padding="0">
						<Slab state="primary" size="medium" state-type="solid">
							<template #primary-action>
								<Icon
									name="i-info-fill"
									type="filled"
									state="white"
									size="small"
									:effects="false"
								/>
							</template>
							<Container :padding="2" :grow="1">
								<Typography color="white" type="p2"
									>Changes made are applicable to the future deployments.</Typography
								>
							</Container>
						</Slab>
					</Container>
					<Container
						padding="10px 16px 16px 16px"
						direction="column"
						overflow="auto"
						data-qa-delete-variable-confirmation-text
					>
						<Typography type="p2" weight="thin"
							>Are you sure you want to remove
							<Typography
								type="p2"
								weight="bold"
								color="dark"
								family="logs"
								:inline="true"
								:style="{ whiteSpace: 'pre-wrap' }"
								>{{ variableColumn.key }}</Typography
							>
							from
							<Typography type="p2" weight="bold" color="dark" :inline="true">{{ name }}</Typography
							>?</Typography
						>
					</Container>
					<Footer>
						<Button
							state="full"
							:type="isSubmitting ? 'default' : 'error'"
							:disabled="isSubmitting"
							:loading="isSubmitting"
							@click="deleteVariable"
						>
							<span>remove</span>
						</Button>
					</Footer>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	PopOver,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { VariableColumn } from "./variable-list-types";
import {
	deleteDeploymentVars,
	deleteEnvironmentVars,
	deleteProjectVars
} from "./variables-list-utils";

export default defineComponent({
	name: "VariableListVariableDeletePopOver",

	components: {
		PopOver,
		Icon,
		Button,
		Container,
		Slab,
		Typography,
		Wrapper,
		Header,
		Footer
	},

	props: {
		variableColumn: {
			type: Object as PropType<VariableColumn>,
			required: true
		},

		name: {
			type: String,
			required: true
		},

		isOpen: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		isSubmitting: false
	}),

	methods: {
		async deleteVariable() {
			const { editInfo } = this.variableColumn;

			this.isSubmitting = true;

			if (editInfo?.type === "project") {
				await deleteProjectVars(this.variableColumn);
			} else if (editInfo?.type === "environment") {
				await deleteEnvironmentVars(this.variableColumn);
			} else if (editInfo?.type === "application-deployment") {
				await deleteDeploymentVars(this.variableColumn);
			}

			this.isSubmitting = false;

			this.closeDialog();
		},

		openDialog() {
			this.$emit("open");
		},

		closeDialog() {
			this.$emit("close");
		}
	}
});
</script>
