import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { CredScope } from "@/protocol/identity";
import {
	appDeploymentStatus,
	appIntegrationStatus,
	applicationStatus,
	BundleParseReq,
	environmentStatus,
	installation as installationProto,
	installationId,
	installationStatus as installationStatusProto,
	projectStatus,
	installationListRequest
} from "@/protocol/installer";
import { PipelineJobStatus, PIPELINE_FINISHED_JOB_STATUSES } from "@/shared/pipeline-constants";

import { applicationStore } from "../application/application-store";
import { applicationDeploymentStore } from "../application-deployment/application-deployment-store";
import { envListStore } from "../env-list/env-list-store";
import { newEnvPipelineStore } from "../env-pipeline/env-pipeline-store";
import { projectStore } from "../project-list/project-store";
import { getStore } from "../store";

import {
	bundleApply,
	getInstallation,
	getInstallationStatus,
	listInstallations,
	parseBundle
} from "./bundle-service";

export type AppliedBundle = {
	bundleRequest: BundleParseReq | null;
	installationStatus: installationStatusProto;
	installation: installationProto;
	shouldDeploy?: boolean;
	shouldUpdateConfig?: boolean;
};

@Module({
	namespaced: true,
	dynamic: true,
	name: "bundle",
	store: getStore()
})
class BundleStore extends VuexModule {
	appliedBundles: Record<string, AppliedBundle | null> = {};
	bundleAppliedFor: "org" | "project" | null = null;
	bundleWatchers: Record<string, number | null> = {};

	@Mutation
	SET_BUNDLE_APPLIED_FOR(val: "org" | "project" | null) {
		this.bundleAppliedFor = val;
	}

	@Mutation
	SET_APPLIED_BUNDLE({
		orgId,
		projId,
		appliedBundle
	}: {
		orgId?: string;
		projId?: string;
		appliedBundle: AppliedBundle | null;
	}) {
		if (orgId) {
			this.appliedBundles[orgId] = appliedBundle;
		} else if (projId) {
			this.appliedBundles[projId] = appliedBundle;
		}
	}

	@Mutation
	RESET_APPLIED_BUNDLE(id: string) {
		this.appliedBundles[id] = null;
		this.bundleAppliedFor = null;
	}

	@Mutation
	SET_WATCHER_TIMEOUT({ bundleId, timeout }: { bundleId: string; timeout: number | null }) {
		this.bundleWatchers[bundleId] = timeout;
	}

	@Action
	async FETCH_AND_WATCH_LATEST_BUNDLE({ projId, orgId }: { projId?: string; orgId: string }) {
		let listInstallationsPayload: installationListRequest = {
			orgId
		};
		if (projId) {
			listInstallationsPayload = { ...listInstallationsPayload, projectId: projId };
		}
		const installationStatuses = await listInstallations(listInstallationsPayload);

		const recentInstallStatus = installationStatuses.installations?.[0];

		if (!recentInstallStatus) {
			return;
		}

		const installation = await getInstallation({
			id: recentInstallStatus.id,
			orgId
		});

		// Bundle was deployed, no need to watch for changes
		if (isBundleSuccessful(installation)) {
			return;
		}

		this.SET_APPLIED_BUNDLE({
			orgId,
			projId,
			appliedBundle: {
				installation,
				installationStatus: recentInstallStatus,
				bundleRequest: null,
				shouldDeploy: true
			}
		});

		// Watch for bundle if the bundle is not completed yet
		if (recentInstallStatus.progress !== recentInstallStatus.totalSteps) {
			await this.WATCH_BUNDLE_FOR_CHANGES({ id: recentInstallStatus.id, orgId });
		}
	}

	@Action
	async APPLY_BUNDLE({
		bundleRequest,
		shouldDeploy,
		shouldUpdateConfig,
		bundleAppliedFor
	}: {
		bundleRequest: BundleParseReq;
		shouldDeploy: boolean;
		shouldUpdateConfig: boolean;
		bundleAppliedFor: "org" | "project" | null;
	}) {
		const appliedBundleId = await applyBundle({
			bundleRequest,
			shouldDeploy,
			shouldUpdateConfig,
			bundleAppliedFor
		});
		if (appliedBundleId) {
			await this.WATCH_BUNDLE_FOR_CHANGES(appliedBundleId);
		}

		this.SET_BUNDLE_APPLIED_FOR(bundleAppliedFor);
		return appliedBundleId;
	}

	// We don't have realtime updates for bundles so we do our own polling and actions
	@Action
	async WATCH_BUNDLE_FOR_CHANGES(bundleId: installationId) {
		const bundleTimeout = this.bundleWatchers[bundleId.id];
		if (bundleTimeout) {
			clearTimeout(bundleTimeout);
			this.SET_WATCHER_TIMEOUT({ bundleId: bundleId.id, timeout: null });
		}

		// We only want to poll for bundles in the current project or the current org
		const appliedBundle =
			this.appliedBundles[String(projectStore.currentProject?.id)] ??
			this.appliedBundles[String(bundleId.orgId)];

		if (!appliedBundle) {
			return;
		}

		const { shouldDeploy, installationStatus: appliedInstallationStatus } = appliedBundle;

		// Get the initial installation status
		const installationStatus = await waitForBundleSetup(bundleId);
		const installation = await getInstallation(bundleId);

		const projectId = installation.project?.projectId;
		const { orgId } = installation;

		if (!projectId || !orgId) {
			return;
		}

		// Update bundle progress
		this.SET_APPLIED_BUNDLE({
			orgId,
			projId: projectId,
			appliedBundle: {
				...appliedBundle,
				installation,
				installationStatus
			}
		});

		const hasStepChanged = appliedInstallationStatus.progress !== installationStatus.progress;

		// @todo - VAN-2467
		// We need to fetch the env/dep statuses here
		// This is because server doesn't send realtime update for initial deploys
		if (hasStepChanged) {
			// If environments are deploying, then fetch their first status
			// Env deploys are step 1 and 2
			if (installationStatus.progress === 1 || installationStatus.progress === 2) {
				await Promise.allSettled(
					installation.environments?.map(envStatus => {
						if (!envStatus.environmentId) {
							return null;
						}

						return newEnvPipelineStore.LIST_JOBS_FOR_ENV({
							orgId,
							projId: projectId,
							envId: envStatus.environmentId,
							limit: 1
						});
					}) ?? []
				);
				// App deploys are step 5 and 6
			} else if (installationStatus.progress === 5 || installationStatus.progress === 6) {
				await applicationDeploymentStore.LIST_PROJECT_APP_DEPLOYMENTS({
					orgId,
					projectId
				});
			}
		}

		// We keep polling until bundle deploy is finished
		if (!PIPELINE_FINISHED_JOB_STATUSES.includes(installationStatus.status as PipelineJobStatus)) {
			const timeout = window.setTimeout(() => {
				this.WATCH_BUNDLE_FOR_CHANGES(bundleId);
			}, 5000);

			this.SET_WATCHER_TIMEOUT({ bundleId: bundleId.id, timeout });
			return;
		}

		// Bundle has been deployed, we can reset the entity
		if (shouldDeploy && isBundleSuccessful(installation)) {
			this.RESET_APPLIED_BUNDLE(projectId);
			this.RESET_APPLIED_BUNDLE(orgId);
		}
	}
}

const bundleStore = getModule(BundleStore);

export { bundleStore };

/**
 * Server doesn't give a definite way to know if a bundle install was successful
 * So we must go through all the properties in a bundle installation and check
 * if they were created and deployed.
 */
function isBundleSuccessful(installation: installationProto) {
	return getBundleErrors(installation).length === 0;
}

export function getBundleErrors(installation: installationProto) {
	const errors: string[] = [];

	[
		...(installation.applications ?? []),
		installation.project,
		...(installation.environments ?? []),
		...(installation.deployments ?? []),
		...(installation.integrations ?? [])
	].forEach(entity => {
		if (entity?.message) {
			errors.push(entity.message);
		}
	});

	return errors;
}

type BundleEntityDeploymentStatus = "success" | "error" | "running" | "default";

/**
 * The way to get bundle status for entity from the API is a bit weird
 * We don't use enums and have to use heuristics to determine
 */
export function getBundleEntityDeploymentStatus(
	status:
		| environmentStatus
		| appDeploymentStatus
		| appIntegrationStatus
		| projectStatus
		| applicationStatus
): BundleEntityDeploymentStatus {
	// Entity is not of deployment type (e.g. project, app)
	if (!("jobSuccess" in status)) {
		return status.crudSuccess ? "success" : "error";
	}

	// Entity has succeeded, just mark it as success
	if (status.jobSuccess) {
		return "success";
	}

	// There is a message from the server, it must be an error
	if (status.message) {
		return "error";
	}

	// There is a job id present and excludes above conditions, it must be running
	if (status.jobId) {
		return "running";
	}

	// in default state
	return "default";
}

async function applyBundle({
	bundleRequest,
	shouldDeploy,
	shouldUpdateConfig,
	bundleAppliedFor
}: {
	bundleRequest: BundleParseReq;
	shouldDeploy: boolean;
	shouldUpdateConfig: boolean;
	bundleAppliedFor: "org" | "project" | null;
}) {
	// First get the plan from the bundle
	const bundlePlan = await parseBundle(bundleRequest);

	// NOTE- There might be a better plan to do this. But for now, we are throwing an error
	if (bundleAppliedFor === "org" && "Kind" in bundlePlan && bundlePlan.Kind === 400) {
		throw bundlePlan.Kind;
	}
	const bundleCredId = bundleRequest.artifactCredId;

	// Then apply the bundle to the project
	const bundleId = await bundleApply({
		plan: bundlePlan,
		envConfig: {},
		appConfig: {},
		integrationConfig: {},
		deploymentConfig: {},

		// For open source we don't need to send creds
		creds: bundleCredId
			? [
					{
						credId: bundleCredId,
						credScope: [CredScope.git]
					}
			  ]
			: [],
		flags: {
			skipPipelines: !shouldDeploy,
			uninstall: false,
			skipConfigUpdates: !shouldUpdateConfig
		}
	});
	// Wait until we can get a bundle installation info
	const installationStatus = await waitForBundleSetup(bundleId);

	// Get installation info
	const installation = await getInstallation(bundleId);

	const projectId = bundleRequest.baseProjId;
	const orgId = bundleRequest.baseOrgId;

	if (!projectId && !orgId) {
		return;
	}

	bundleStore.SET_APPLIED_BUNDLE({
		orgId: bundleAppliedFor === "project" ? undefined : orgId,
		projId: projectId,
		appliedBundle: {
			installation,
			installationStatus,
			bundleRequest,
			shouldDeploy,
			shouldUpdateConfig
		}
	});
	// Update project and envs in the project as the bundle would have created them by now
	if (projectId) {
		await Promise.allSettled([
			projectStore.GET_PROJECT({
				orgId,
				projectId
			}),
			envListStore.GET_ENVS({
				orgId,
				projectId
			}),
			applicationStore.FETCH_APPS_IN_PROJECT({
				projectId,
				orgId
			}),
			applicationDeploymentStore.LIST_PROJECT_APP_DEPLOYMENTS({
				orgId,
				projectId
			})
		]);
	}
	return bundleId;
}

/**
 * The way bundle API works is that it has seven steps
 *
 * 0. Setup
 * 1. Deploy Environments
 * 2. Watch Environments
 * 3. Run App integrations
 * 4. Watch App integrations
 * 5. Deploy App
 * 6. Watch App deployments
 *
 * We must wait for at least the first setup step before we can start fetching
 * details on the bundle. So in this function we make sure that the first step
 * has been achieved before doing anything. It usually takes around 5-10 seconds
 * so the impact on UX is not much
 */
async function waitForBundleSetup(bundleId: installationId) {
	let installationStatus = await getInstallationStatus(bundleId);

	while (installationStatus.progress !== undefined && installationStatus.progress < 1) {
		await new Promise(resolve => setTimeout(resolve, 2000));
		installationStatus = await getInstallationStatus(bundleId);
	}

	return installationStatus;
}
