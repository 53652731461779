<template>
	<Container align="center" direction="column" class="text-align-center" padding="50px 12px">
		<Pictogram size="xl" shape="hexagon">
			<Icon name="i-plus" :effects="false" />
		</Pictogram>
		<Typography type="p1-para" color="gray-100"
			>Create an environment to deploy this application</Typography
		>
		<Button state="outlined" :loading="isLoading" size="small" @click="$emit('create')"
			>CREATE ENVIRONMENT</Button
		>
	</Container>
</template>
<script lang="ts">
import { Container, Pictogram, Typography, Button, Icon } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyEnvironments",

	components: {
		Container,
		Pictogram,
		Typography,
		Button,
		Icon
	},

	props: {
		isLoading: {
			type: Boolean
		}
	}
});
</script>
