<template>
	<table
		v-if="
			variableTable.rows.length > 0 &&
			currentFilter !== 'equal' &&
			currentFilter !== 'notequal' &&
			currentFilter !== 'error'
		"
		:id="`table-${variableTable.id}`"
		class="flow-table border-bottom fixed-layout width-100-per"
		:class="{ 'margin-bt-32': isOpen }"
	>
		<thead class="flow-table-head">
			<tr class="flow-table-row element-light rounded-corners add-var-realtive-trigger">
				<th
					id="project-add-variable-trigger"
					class="text-align-start v-align-middle sticky-horizontal width-400"
					:style="{ zIndex: rowZIndex }"
				>
					<Container :padding="12" :gap="20">
						<Icon
							:rotate="isOpen ? 0 : 180"
							name="i-chevron-up"
							type="filled"
							size="small"
							state="light"
							@click="isOpen = !isOpen"
						/>
						<Container :gap="10" :padding="0">
							<Typography type="p1" color="light" overflow="ellipsis">{{
								variableTable.title
							}}</Typography>
							<Tag shape="rounded" size="small">{{ sortedVariables.length }}</Tag>
							<Icon
								:name="sorting === 'desc' ? 'i-arrow-down' : 'i-arrow-up'"
								type="filled"
								size="x-small"
								state="light"
								@click="toggleSorting()"
							/>
							<Button
								state="icon"
								size="x-small"
								class="show-on-hover"
								@click="showAddVariableModalById('#project-add-variable-trigger')"
							>
								<Icon
									v-tooltip="{
										content: `Add new variable to ${variableTable.title}`,
										placement: 'bottom-start'
									}"
									name="i-plus"
									state="dark"
									size="xx-small"
									type="filled"
									:effects="false"
								/>
							</Button>
						</Container>
					</Container>
				</th>
				<th class="text-align-start v-align-middle width-500">
					<Container padding="12px 12px 12px 0px">
						<Typography type="p2" color="light" transform="uppercase" overflow="nowrap"
							>Value</Typography
						>
						<Button
							state="icon"
							size="x-small"
							class="show-on-hover"
							@click="showAddVariableModalById('#project-add-variable-trigger')"
						>
							<Icon
								v-tooltip="{
									content: `Add new variable to ${variableTable.title}`,
									placement: 'bottom-start'
								}"
								name="i-plus"
								state="dark"
								size="xx-small"
								type="filled"
								:effects="false"
							/>
						</Button>
					</Container>
				</th>
				<!--This is empty th to acquire remaining space in table-->
				<th></th>
			</tr>
		</thead>
		<tbody v-if="isOpen" class="flow-table-body">
			<tr
				v-for="variableRow in sortedVariables"
				:key="variableRow.key + variableRow.varType"
				:data-qa-variables-list-row="variableRow.key"
				class="flow-table-row highlight-on-hover"
			>
				<td class="flow-table-cell cell-padding-12 sticky-horizontal">
					<Container padding="0 0 0 12px" :gap="16">
						<Typography type="p1" color="dark" family="logs">{{ variableRow.key }}</Typography>

						<Tag v-if="variableRow.varType === 'tf_var'" size="small" background="#924AEE">{{
							variableRow.varType
						}}</Tag>
					</Container>
				</td>
				<VariableListColumn
					:show-values="showValues"
					:variable-column="variableRow.column"
					:column-name="variableTable.title"
				/>
				<!--This is empty td to acquire remaining space in table-->
				<td class="flow-table-cell"></td>
			</tr>
		</tbody>
	</table>
</template>

<script lang="ts">
import { Button, Container, Icon, Tag, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { QuickFilter, VariableProjectTable } from "./variable-list-types";
import VariableListColumn from "./VariableListColumn.vue";

export default defineComponent({
	name: "VariableListProjectTable",

	components: {
		Container,
		Button,
		Icon,
		Tag,
		Typography,
		VariableListColumn
	},

	props: {
		variableTable: {
			type: Object as PropType<VariableProjectTable>,
			required: true
		},

		showValues: {
			type: Boolean,
			required: true
		},

		currentFilter: {
			type: String as PropType<QuickFilter>,
			required: true
		}
	},

	emits: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		"add-variable": (_target: string, _scopeIds?: string[]) => {
			return true;
		}
	},

	data: () => ({
		sorting: "asc" as Sorting,
		isOpen: true,
		openAddVariable: false
	}),

	computed: {
		//@todo - connect variable list z-index with add variable modal `openAddVariable`
		rowZIndex() {
			return this.openAddVariable ? 2 : 1;
		},

		sortedVariables() {
			return this.variableTable.rows.slice().sort((listA, listB) => {
				if (this.sorting === "desc") {
					return listB.key.localeCompare(listA.key);
				}
				return listA.key.localeCompare(listB.key);
			});
		}
	},

	methods: {
		showAddVariableModalById(id: string) {
			const projectId = this.variableTable.rows[0]?.column.editInfo?.projectId;
			this.$emit("add-variable", id, projectId ? [projectId] : undefined);
		},

		toggleSorting() {
			this.sorting = this.sorting === "asc" ? "desc" : "asc";
		}
	}
});

type Sorting = "asc" | "desc";
</script>
