<template>
	<Wrapper
		border-radius="4px"
		background="pop-over"
		width="432px"
		overflow="visible"
		data-qa-deploy-application-list
		max-height="570px"
	>
		<Header>
			<Icon
				name="i-arrow-left"
				type="filled"
				size="x-small"
				data-qa-env-wid-add-variable-close
				@click.stop="$emit('back')"
			/>
			<Typography type="h4" color="dark">Select Application</Typography>
			<Icon
				name="i-close"
				type="filled"
				size="x-small"
				data-qa-deploy-application-close
				@click.stop="$emit('close')"
			/>
		</Header>

		<Container
			v-if="apps.length > 0 || searchInput !== ''"
			:shrink="0"
			padding="0px 16px 16px 16px"
		>
			<SearchInput
				v-model:value="searchInput"
				placeholder="Search applications"
				data-qa-deploy-application-search
			/>
			<Button
				v-if="showAddButton"
				state="icon"
				size="small"
				data-qa-add-application
				@click="$emit('add-application')"
			>
				<Icon name="i-plus" size="small" type="filled" :effects="false" />
			</Button>
		</Container>

		<Container
			v-if="apps.length > 0"
			type="grid"
			item-size="100% 200px"
			overflow="auto"
			padding="0px 16px 16px 16px"
		>
			<Card
				v-for="app in apps"
				:key="app.id"
				background="element-light"
				:data-qa-deploy-applicaton-name="app.name"
			>
				<template #header>
					<Container>
						<Slab :no-padding="true" overflow="visible">
							<Container :padding="0">
								<Pictogram shape="squircle" state="avatar" :label="getShortCode(app.name)">
								</Pictogram>
								<Container direction="column" :gap="0" :padding="0">
									<Container :gap="4" :padding="0">
										<Typography type="h3" color="dark">{{ app.name }} &nbsp; </Typography>
										<InfoPopover v-if="app.description">
											<Icon name="i-info-fill" size="small" type="filled" :effects="false" />
											<template #body>
												<Typography type="p2">{{ app.description }}</Typography>
											</template>
										</InfoPopover>
									</Container>
									<Typography type="p2" color="light"
										>{{ app.appConfig && app.appConfig.language }} ({{
											app.appConfig && app.appConfig.version
										}})<template v-if="app.createdAt"
											>• Created on {{ timestampFormat(app.createdAt) }}</template
										>
									</Typography>
								</Container>
							</Container>
							<template #secondary-actions>
								<!--
									We are not building advanced deploy flow yet
									<Icon name="i-setting" size="small" type="filled"/>
								-->
							</template>
						</Slab>
					</Container>
				</template>
				<Container direction="column" :gap="0">
					<table class="key-value" data-key-size="50" data-row-gutter="8px">
						<caption>
							<Container :gap="8" :padding="0">
								<Typography type="h5" color="dark">Artifacts</Typography>
								<Typography type="p2" color="light"
									>({{ app.artifacts && app.artifacts.length }})</Typography
								>
							</Container>
						</caption>
						<tbody>
							<tr v-for="artifact in getArtifacts(app)" :key="artifact.id">
								<td>
									<Typography
										v-tooltip="{
											content: artifact.name,
											container: 'div.flow-layout',
											placement: 'top'
										}"
										type="p2"
										color="dark"
										overflow="ellipsis"
										>{{ artifact.name }}</Typography
									>
								</td>
								<td>
									<Typography
										v-if="artifact.isLink"
										v-tooltip="{
											content: artifact.identifier,
											container: 'div.flow-layout',
											placement: 'top'
										}"
										:link="true"
										type="p2"
										color="light"
										overflow="ellipsis"
									>
										<a
											class="fc-primary"
											rel="noopener"
											:href="artifact.identifier"
											target="_blank"
											>{{ artifact.identifier }}</a
										>
									</Typography>
									<Typography
										v-else
										v-tooltip="{
											content: artifact.identifier,
											container: 'div.flow-layout',
											placement: 'top'
										}"
										type="p2"
										color="light"
										overflow="ellipsis"
										>{{ artifact.identifier }}</Typography
									>
								</td>
							</tr>
						</tbody>
					</table>
				</Container>
				<template #footer>
					<Container align="center">
						<Button size="small" data-qa-deploy-application-deploy @click="startAppDeploy(app)"
							>deploy</Button
						>
					</Container>
				</template>
			</Card>
		</Container>
		<Container v-else overflow="visible" align="center" :grow="1">
			<EmptyApplications :is-empty-from-search="searchInput.length > 0" />
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Button,
	Card,
	Container,
	Header,
	Icon,
	InfoPopover,
	Pictogram,
	SearchInput,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import EmptyApplications from "@/modules/project-landing/views/EmptyApplications.vue";
import { app } from "@/protocol/app";
import { Artifact } from "@/protocol/common";
import {
	formatDateToRegularFormat,
	getArtifactIdentifier,
	getCamelShortCode,
	isLink,
	normalizeUnixTime,
	searchObject
} from "@/utils";

export default defineComponent({
	name: "AppListPopover",

	components: {
		Button,
		Card,
		Container,
		EmptyApplications,
		Header,
		Icon,
		InfoPopover,
		Pictogram,
		SearchInput,
		Slab,
		Typography,
		Wrapper
	},

	props: {
		projectId: {
			type: String,
			required: true
		},

		showAddButton: {
			type: Boolean
		}
	},

	emits: {
		"add-application": null,
		close: null,
		back: null,

		"deploy-application": (application: app) => {
			return Boolean(application.id);
		}
	},

	data: () => ({
		searchInput: ""
	}),

	computed: {
		apps() {
			const appsList = applicationStore.projectApps[this.projectId] ?? [];

			if (!this.searchInput) {
				return appsList;
			}

			return appsList.filter(application => {
				return searchObject(application, this.searchInput);
			});
		},

		getShortCode() {
			return getCamelShortCode;
		}
	},

	methods: {
		timestampFormat(value: string) {
			return formatDateToRegularFormat(normalizeUnixTime(value));
		},

		getArtifacts(app_: app) {
			return (
				app_.artifacts?.map(artifact => {
					const identifier = getArtifactIdentifier(artifact);
					return {
						...artifact,
						identifier,
						isLink: isLink(identifier)
					} as RenderedArtifact;
				}) ?? []
			);
		},

		startAppDeploy(application: app) {
			this.$emit("deploy-application", application);
		}
	}
});

type RenderedArtifact = Artifact & {
	isLink: boolean;
	identifier: string;
};
</script>
