<template>
	<Container
		data-qa-app-creation-deployment-template-step
		overflow="visible"
		:grow="0"
		direction="column"
		padding="0"
		align="left top"
	>
		<Typography type="p2" weight="medium" color="white">Select deployment template</Typography>

		<Multiselect
			v-model="template"
			data-qa-app-creation-template-selector
			:options="templateOptions"
			:loading="fetchingData"
			:disabled="fetchingData"
			:searchable="false"
			:allow-empty="false"
			:custom-label="customLabel"
		>
			<template #singleLabel="props">
				<Container padding="12px 0" align="left top">
					<Icon :name="props.option.icon" size="small" />
					<Typography>{{ props.option.title }}</Typography>
				</Container>
			</template>

			<template #option="props">
				<Container padding="12px 4px" align="left top">
					<Container padding="0">
						<Icon :name="props.option.icon" size="small" />
					</Container>
					<Container direction="column" padding="0" :gap="4">
						<Typography>{{ props.option.title }}</Typography>
						<Typography type="p2" color="gray-200">{{ props.option.description }}</Typography>
					</Container>
				</Container>
			</template>
		</Multiselect>

		<JSONSchemaFormBuilder2
			v-if="selectedTemplate && selectedTemplate.inputs"
			:fields="selectedTemplate.inputs"
			:prefill-values="prefillOutputList ?? undefined"
			:embeddable-view="true"
			:default-values="values.inputs"
			@info="handleTemplateInfo"
		/>

		<Divider />

		<DeploymentEntityVariables
			v-model:variable-info="variableInfo"
			:prefill-values="prefillOutputList ?? undefined"
		/>
	</Container>
</template>

<script lang="ts">
import { FormBuilderValues } from "@cldcvr/flow-form-builder";
import { Container, Divider, Icon, Multiselect, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { DeploymentVar } from "@/modules/application-deployment/store-types";
import { envListStore } from "@/modules/env-list/env-list-store";
import { ArtifactType, Provisioner } from "@/protocol/common";
import { project } from "@/protocol/identity";
import { pipelineModule } from "@/protocol/pipeline";
import JSONSchemaFormBuilder2 from "@/shared/components/JSONSchemaFormBuilder2.vue";
import { getProvisionerIcon } from "@/utils";

import DeploymentEntityVariables, { VariableInfo } from "./DeploymentEntityVariables.vue";

export default defineComponent({
	name: "DeploymentInputsAndVariables",

	components: {
		JSONSchemaFormBuilder2,
		DeploymentEntityVariables,
		Divider,
		Container,
		Typography,
		Multiselect,
		Icon
	},

	props: {
		artifactSource: {
			type: String as PropType<ArtifactType>
		},

		project: {
			type: Object as PropType<project>,
			required: true
		},

		values: {
			type: Object as PropType<DeploymentInputsAndVariablesValues>,
			required: true
		}
	},

	data() {
		return {
			templateInfo: {
				inputs: this.values.inputs ?? {},
				isValid: false
			} as TemplateInfo,

			variableInfo: {
				variables: this.values.variables,
				isValid: false
			} as VariableInfo,

			fetchingData: false,

			template: this.values.template ? getTemplateInfoFromModule(this.values.template) : null,

			deploymentTemplates: [] as pipelineModule[],

			prefillOutputList: null as string[] | null
		};
	},

	computed: {
		selectedTemplate() {
			return this.deploymentTemplates.find(
				template =>
					template.displayName === this.template?.title &&
					template.provisioner === this.template?.provisioner
			);
		},

		templateOptions(): TemplateOption[] {
			const artifactType = this.artifactSource;

			return this.deploymentTemplates
				.filter(template => {
					if (!artifactType) {
						return true;
					}

					return (
						template.expectedArtifactType?.length === 0 ||
						template.expectedArtifactType?.includes(artifactType)
					);
				})
				.map(getTemplateInfoFromModule);
		}
	},

	watch: {
		variableInfo: {
			deep: true,

			handler({ variables, isValid }: VariableInfo) {
				this.$emit("update:values", {
					...this.values,
					variables,
					isValid: isValid && this.templateInfo.isValid,
					template: this.selectedTemplate
				});
			}
		}
	},

	mounted() {
		(async () => {
			this.fetchingData = true;
			let environments = envListStore.envs[this.project.id];

			if (!environments) {
				await envListStore.GET_ENVS({
					orgId: this.project.orgId,
					projectId: this.project.id
				});

				environments = envListStore.envs[this.project.id];
			}

			const environment = environments?.[0];

			if (!environment || !environments) {
				this.fetchingData = false;
				throw new Error("No environments in project");
			}

			const templates = await applicationDeploymentStore.FETCH_ORG_APP_DEPLOYMENT_TEMPLATES({
				orgId: this.project.orgId,
				provisioner: Provisioner.no_provisioner
			});

			if (templates === undefined) {
				this.fetchingData = false;
				throw new Error("No templates in project");
			}

			this.deploymentTemplates = templates;
			this.fetchingData = false;

			// Fetch output variables in background
			const firstValidEnv = environments.find(env => env.isDeployed);

			if (!firstValidEnv) {
				// eslint-disable-next-line no-console
				console.error("No deployed environment found");
				return;
			}

			let envOutput = envListStore.envOutputSummary[firstValidEnv.id];

			if (!envOutput) {
				await envListStore.GET_ENV_OUTPUT_SUMMARY({
					orgId: this.project.orgId,
					projectId: this.project.id,
					envId: firstValidEnv.id
				});

				envOutput = envListStore.envOutputSummary[firstValidEnv.id];
			}

			if (!envOutput?.outputs) {
				// eslint-disable-next-line no-console
				console.error("No environment output found for deployed environment");
				return;
			}

			this.prefillOutputList = Object.values(envOutput.outputs).map(val => String(val.value));
		})();
	},

	methods: {
		customLabel(option: TemplateOption) {
			return option.title;
		},

		handleTemplateInfo(templateInfo: TemplateInfo) {
			this.templateInfo = templateInfo;

			this.$emit("update:values", {
				...this.values,
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				variables: this.values.variables ?? [],
				inputs: templateInfo.inputs,
				isValid: templateInfo.isValid && this.variableInfo.isValid,
				template: this.selectedTemplate
			});
		}
	}
});

function getTemplateInfoFromModule(template: pipelineModule): TemplateOption {
	return {
		title: template.displayName ?? "",
		description: `${template.provisioner}:${template.name}@${template.version}`,
		icon: getProvisionerIcon(template.provisioner),
		provisioner: template.provisioner
	};
}

type TemplateInfo = { inputs: FormBuilderValues; isValid: boolean };

type TemplateOption = {
	title: string;
	description: string;
	icon: string;
	provisioner: string;
};

export type DeploymentInputsAndVariablesValues = {
	isValid: boolean;
	variables: DeploymentVar[];
	inputs?: FormBuilderValues;
	template: pipelineModule | null;
};
</script>
