<template>
	<f-popover
		:key="classification.id"
		size="medium"
		placement="auto"
		:target="'#' + popoverTarget"
		:overlay="true"
		:open="true"
	>
		<f-div
			state="tertiary"
			padding="medium medium x-large medium"
			width="fill-container"
			gap="none"
			direction="column"
		>
			<f-div border="small solid default bottom" padding="none none medium none">
				<f-div width="100%">
					<f-div width="hug-content">
						<f-icon
							:source="icon"
							:state="icon === 'i-prod' ? 'warning' : 'default'"
							size="small"
						/>
					</f-div>
					<f-div width="hug-content" padding="none none none medium">
						<f-text variant="heading" size="small" data-qa-classification-config-name
							>Advance config • {{ classification.name }}</f-text
						>
					</f-div>
					<f-div width="hug-content" padding="none none none medium" align="middle-right">
						<f-icon source="i-close" size="small" :clickable="true" @click="$emit('close')" />
					</f-div>
				</f-div>
			</f-div>
			<f-div padding="none none large none" gap="none" direction="column">
				<f-div
					border="small solid default bottom"
					padding="medium none medium none"
					gap="none"
					direction="column"
				>
					<f-div
						width="fill-container"
						align="middle-center"
						gap="auto"
						height="fill-container"
						padding="small none"
					>
						<f-div width="fill-container" align="top-left">
							<f-text variant="para" size="small">Classification name </f-text>
						</f-div>
						<f-div width="fill-container" align="top-left" padding="none medium">
							<f-text variant="para" size="small"> {{ classification.name }} </f-text>
						</f-div>
					</f-div>

					<f-div
						v-if="appliedPolicySet"
						width="fill-container"
						align="middle-center"
						gap="auto"
						height="fill-container"
						padding="small none"
					>
						<f-div width="fill-container" align="top-left">
							<f-text variant="para" size="small">Applied policy set </f-text>
						</f-div>
						<f-div width="fill-container" align="top-left" padding="none medium">
							<f-text variant="para" size="small" data-qa-classification-applied-policy
								>{{ appliedPolicySet }}
							</f-text>
						</f-div>
					</f-div>
				</f-div>

				<f-div
					border="small solid default bottom"
					padding="medium none medium none"
					gap="none"
					direction="column"
				>
					<f-div
						v-if="classification.description"
						width="fill-container"
						align="middle-center"
						gap="auto"
						height="fill-container"
						padding="small none"
					>
						<f-div width="fill-container" align="top-left">
							<f-text variant="para" size="small">Description </f-text>
						</f-div>
						<f-div width="fill-container" align="top-left" padding="none medium">
							<f-text variant="para" size="small" data-qa-classification-config-description>
								{{ classification.description }}
							</f-text>
						</f-div>
					</f-div>
					<f-div
						v-if="classification.ancestorId"
						width="fill-container"
						align="middle-center"
						gap="auto"
						height="fill-container"
						padding="small none"
					>
						<f-div width="fill-container" align="top-left">
							<f-text variant="para" size="small">Ancestor environment </f-text>
						</f-div>
						<f-div width="fill-container" align="top-left" padding="none medium">
							<f-text variant="para" size="small" data-qa-classification-config-ancestorId>{{
								classification.ancestorId
							}}</f-text>
						</f-div>
					</f-div>
				</f-div>

				<f-div
					v-if="classification.variables?.length"
					border="small solid default bottom"
					padding="medium none medium none"
					gap="none"
					direction="column"
				>
					<f-div padding="none none medium none">
						<f-text variant="heading" size="small">VARIABLES</f-text>
					</f-div>
					<f-div v-for="item in classification.variables" :key="item.key">
						<f-div
							width="fill-container"
							align="middle-center"
							gap="auto"
							height="fill-container"
							padding="small none"
						>
							<f-div width="fill-container" align="top-left">
								<f-text
									variant="para"
									size="small"
									:data-qa-classification-variables-key="item.key"
									>{{ item.key }}</f-text
								>
							</f-div>
							<f-div width="fill-container" align="top-left" padding="none medium">
								<f-text
									variant="para"
									size="small"
									:data-qa-classification-variables-value="item.value"
								>
									{{ item.value }}
								</f-text>
							</f-div>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { credentialStore } from "@/modules/credentials/credential-store";
import { classification } from "@/protocol/infra";

import { orgStore } from "../org/org-store";

export default defineComponent({
	name: "EnvClassificationConfig",

	props: {
		icon: {
			type: String,
			default: "i-dev"
		},

		popoverTarget: {
			type: String,
			default: "popoverTarget"
		},

		classification: {
			type: Object as PropType<classification>,
			required: true
		},

		appliedPolicySet: {
			type: String
		}
	},

	computed: {
		orgId() {
			return orgStore.activeOrgId ?? (this.$route.params.orgId as string);
		}
	},

	mounted() {
		(async () => {
			if (this.orgId) {
				await credentialStore.GET_CLASSIFICATIONS({ orgId: this.orgId });
			}
		})();
	}
});
</script>
