<template>
	<OrgBundleImportWrapper
		v-if="template === 'createOrg'"
		:is-oauth-complete="isOauthComplete"
		:open-org-switcher="openOrgSwitcher"
		@close-app-flow="closeAppFirstFlow"
	/>

	<PopOver
		v-else
		v-model:open="openOrgSwitcher"
		placement="bottom-end"
		@overlay-click="toggleOrgSwitcher"
	>
		<Container
			v-tooltip="{
				content: 'Switch organization',
				container: 'div.flow-layout',
				placement: 'bottom-end'
			}"
			:padding="0"
			:gap="0"
		>
			<Icon
				v-if="isDefaultOrg"
				name="i-org"
				state="light"
				size="20px"
				type="filled"
				data-qa-toggle-org-switcher-btn
				@click="toggleOrgSwitcher"
			/>
			<Pictogram
				v-else
				state="avatar"
				:label="orgInitial"
				shape="circle"
				size="m"
				:effects="true"
				data-qa-toggle-org-switcher-btn
				@click="toggleOrgSwitcher"
			/>
		</Container>

		<template #content>
			<OrgList
				v-if="template === 'orgList'"
				:orgs="organizations"
				:active-org-id="activeOrgId"
				@switch-org="switchOrg"
				@create-org="openAppFirstFlow()"
			/>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { Pictogram, PopOver, Icon, Container } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { userStore } from "@/modules/user/user-store";
import { organization } from "@/protocol/identity";
import { GithubOauthStorageService } from "@/services/storage-service";
import { captureError } from "@/utils";

import { orgStore } from "./org-store";
import OrgBundleImportWrapper from "./OrgBundleImportWrapper.vue";
import OrgList from "./OrgList.vue";

export default defineComponent({
	name: "OrgSwitcher",

	components: {
		Pictogram,
		PopOver,
		Icon,
		OrgList,
		Container,
		OrgBundleImportWrapper
	},

	props: {
		activeOrgId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		openOrgSwitcher: false,
		isOauthComplete: false,
		template: "orgList" as "createOrg" | "orgList"
	}),

	computed: {
		organizations() {
			return orgStore.GET_ORGS;
		},

		orgInitial() {
			const { activeOrgId } = orgStore;
			return orgStore.GET_ORGS.filter(org => org.id === activeOrgId)[0]?.orgInitial;
		},

		isDefaultOrg() {
			return orgStore.activeOrgId === userStore.profile?.id;
		},

		showAppFirstFlow() {
			return orgStore.showAppFirstFlow;
		}
	},

	watch: {
		showAppFirstFlow: {
			immediate: true,

			handler(should: boolean) {
				if (should) {
					this.openOrgSwitcher = true;
					this.template = "createOrg";
				} else {
					this.closeAppFirstFlow();
				}
			}
		}
	},

	mounted() {
		this.githubModalObserver();
	},

	methods: {
		async switchOrg(org: organization) {
			if (this.activeOrgId === org.id) {
				return false;
			}

			try {
				await orgStore.SWITCH_ORG({ orgId: org.id });
			} catch (err) {
				captureError(err);
			} finally {
				this.$router
					.push({
						name: "home",
						params: { orgId: org.id }
					})
					.catch(captureError);

				if (this.openOrgSwitcher) {
					this.openOrgSwitcher = false;
				}
			}
		},

		toggleOrgSwitcher() {
			this.template = "orgList";
			this.openOrgSwitcher = !this.openOrgSwitcher;
		},

		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === "appFirstFlow") {
				// render github form
				this.openAppFirstFlow();
				this.isOauthComplete = true;
			}
		},

		openAppFirstFlow() {
			orgStore.SET_APP_FIRST_FLOW(true);
		},

		async closeAppFirstFlow() {
			this.openOrgSwitcher = false;
			this.isOauthComplete = false;
			this.template = "orgList";
			orgStore.SET_APP_FIRST_FLOW(false);
			await userStore.UPDATE_USER_META({
				isAppFirstFlowShown: true
			});
		}
	}
});
</script>
