import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { FeatureFlagService } from "@/services/storage-service";
import { captureError } from "@/utils";

import { getStore } from "../store";

import { ClientFeatureKey, FeatureMapFull, getFeatureMap } from "./feature-map";

@Module({
	namespaced: true,
	dynamic: true,
	name: "featureFlag",
	store: getStore()
})
class FeatureFlagStore extends VuexModule {
	featureMapFull: FeatureMapFull;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(mod: any) {
		super(mod);
		this.featureMapFull = getFeatureMap();

		// Read persisted features from disk if they exist
		const storedFeatures: FeatureMapFull = FeatureFlagService.getFeatures() || {};
		(Object.keys(storedFeatures) as ClientFeatureKey[]).forEach(key => {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (this.featureMapFull[key]) {
				this.featureMapFull[key].isEnabled = storedFeatures[key].isEnabled;
			}
		});
	}

	get featureMap() {
		const featureMap: Record<string, boolean> = {};

		(Object.keys(this.featureMapFull) as ClientFeatureKey[]).forEach(key => {
			featureMap[key] = this.featureMapFull[key].isEnabled;
		});

		return featureMap as Record<ClientFeatureKey, boolean>;
	}

	@Mutation
	setClientFeature({
		featureKey,
		isEnabled
	}: {
		featureKey: ClientFeatureKey | string;
		isEnabled: boolean;
	}) {
		// Mutations only have access to store state
		const featureKeys = Object.keys(this.featureMapFull) as ClientFeatureKey[];

		if (featureKeys.includes(featureKey as ClientFeatureKey)) {
			this.featureMapFull[featureKey as ClientFeatureKey].isEnabled = isEnabled;
			FeatureFlagService.setFeatures(this.featureMapFull);
		} else {
			captureError(new Error(`Feature key ${featureKey} does not exist`));
		}
	}
}

const featureFlagStore = getModule(FeatureFlagStore);

export { featureFlagStore };
