import { credsType } from "@/protocol/identity";

export const CREDENTIAL_TYPE_ICONS: Record<credsType, string> = Object.freeze({
	credsType_github: "p-github",
	credsType_gcp: "p-gcp",
	credsType_aws: "p-aws-dark",
	credsType_docker: "p-docker",
	credsType_no_support: "",
	credsType_dockerhub: "p-docker",
	credsType_gitlab: "p-gitlab",
	credsType_azure: "p-azure",
	credsType_dockergeneric: "p-docker",
	credsType_codecommit: "l-aws-small",
	credsType_gitgeneric: "i-git-branch",
	credsType_acr: "p-azure"
});

// Flow 2 icons
export const CREDENTIAL_TYPE_ICONS2: Record<credsType, string> = Object.freeze({
	credsType_github: "p-github",
	credsType_gcp: "p-google",
	credsType_aws: "p-aws-dark",
	credsType_docker: "p-docker",
	credsType_no_support: "",
	credsType_dockerhub: "p-docker",
	credsType_gitlab: "p-gitlab",
	credsType_azure: "p-azure",
	credsType_dockergeneric: "p-docker",
	credsType_codecommit: "p-aws-dark",
	credsType_gitgeneric: "i-git-branch",
	credsType_acr: "p-azure"
});

export const EMPTY_SCREEN_TITLE2 =
	"Using these credentials, you can deploy your infrastructure and applications on Code Pipes.";
export const EMPTY_SCREEN_TITLE1 =
	"Credentials on Code Pipes represent an existing account that you have with any cloud, git or container registry platforms.";
export const EMPTY_SCREEN_TITLE_PROJECT_PART1 = "Assign cloud, git or container credentials to be";
export const EMPTY_SCREEN_TITLE_PROJECT_PART2 = "used to setup environment or applications";
export const EMPTY_SCREEN_HEADING = "No credentials assigned";
export const EMPTY_SCREEN_HEADING_PROJECT = "Credentials of ";
export const EMPTY_SCREEN_ADD_NEW_TEXT = "+ ADD NEW CREDENTIAL";
