import { AxiosResponse } from "axios";

import ApiService from "@/services/api-service";
/**
 * Example for FOGBENDER_BASE_URL: https://asia-southeast1-vanguard-prod.cloudfunctions.net
 */

export const getSupportToken = async function ({
	customerId,
	userId
}: {
	userId: string;
	customerId: string;
}) {
	const response: AxiosResponse<string> = await ApiService.get(
		`${window.VUE_APP_FOGBENDER_BASE_URL}/customerSupportToken?userId=${userId}&customerId=${customerId}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
