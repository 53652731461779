<template>
	<InfoPopover
		v-if="showInfo"
		title="What are environment classifications?"
		placement="right-start"
		target="classifications"
		:is-open="showInfo"
		:show-overlay="true"
		@close="closePopover"
	>
		<f-div>
			<f-text variant="para" size="medium">
				Environment classifications in Code Pipes are predefined configurations that assist in
				effectively managing important aspects of infrastructure such as availability, scalability,
				security, and access control.
			</f-text>
		</f-div>
		<f-div padding="large none none none">
			<f-text>
				These classifications are typically made for different environment types like Development,
				Staging, or Production, based on the specific needs of the organization.
			</f-text>
		</f-div>
		<f-div padding="large none none none">
			<f-text>
				<Typography
					type="p2-para"
					color="primary"
					:link="true"
					data-qa-project-env-classification-info-popover-link
					@click="openHelp(' https://docs.codepipes.io/docs/classification')"
				>
					Learn more about classification
				</Typography>
			</f-text>
		</f-div>
	</InfoPopover>
</template>

<script lang="ts">
import { Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { userStore } from "@/modules/user/user-store";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";

export default defineComponent({
	name: "ProjectEnvClassificationPopover",

	components: {
		InfoPopover,
		Typography
	},

	data: () => ({
		showInfo: false as boolean
	}),

	computed: {
		infoFlags() {
			return userStore.profile?.metadata?.infoFlags;
		}
	},

	mounted() {
		if (!this.infoFlags?.hasProjectClassificationPopover) {
			this.showInfo = true;
		}
	},

	methods: {
		openHelp(link: string) {
			window.open(link, "_blank");
		},

		closePopover() {
			this.showInfo = false;
			userStore.UPDATE_USER_META({
				infoFlags: {
					...this.infoFlags,
					hasProjectClassificationPopover: true
				}
			});
		}
	}
});
</script>
