<template>
	<!-- Header -->
	<f-div padding="medium" height="hug-content" gap="large" state="secondary" width="100%">
		<f-icon
			source="i-arrow-left"
			class="cursor-pointer"
			data-qa-app-connect-form-back-btn
			state="default"
			@click="goBack"
		>
		</f-icon>
		<f-text variant="para" size="small" weight="bold" data-qa-deploy-config-title>
			{{ title }}
		</f-text>
		<f-div align="middle-right" gap="small" width="10%">
			<f-icon
				source="i-close"
				size="small"
				class="cursor-pointer"
				data-qa-app-connect-close-btn
				@click="$emit('close')"
			>
			</f-icon>
		</f-div>
	</f-div>
	<!-- Header end -->
	<f-divider direction="horizontal" height="fill-container" size="medium" />

	<f-div
		padding="small"
		gap="medium"
		state="secondary"
		width="fill-container"
		align="top-left"
		overflow="scroll"
	>
		<f-div
			v-if="!isOutputArtifactFormShown"
			padding="medium"
			height="hug-content"
			gap="large"
			state="secondary"
			direction="column"
		>
			<!-- Dependency selector dropdown -->
			<f-div padding="none" align="top-center">
				<f-form-builder
					category="fill"
					size="small"
					class="width-100-per"
					:field.prop="formFields"
					:values="formValues"
					@input="handleInputs"
					@state-change="formState = $event.detail"
				/>
			</f-div>
			<f-divider
				v-if="templateFields"
				direction="horizontal"
				height="fill-container"
				size="medium"
			/>
			<JSONSchemaFormBuilder2
				v-if="templateFields"
				ref="templateForm"
				:fields="templateFields"
				:default-values="defaultValues ?? undefined"
				class="width-100-per"
				@info="handleTemplateInfo"
			/>
		</f-div>
		<!-- <f-div  padding="small" height="hug-content" state="secondary"> -->
		<Container
			v-if="isOutputArtifactFormShown"
			padding="10px 16px 16px 16px"
			direction="column"
			overflow="visible"
			align="stretch top"
			:grow="1"
		>
			<ApplicationContainerForm
				ref="appContainerForm"
				v-model:form-values="outputArtifactValues"
				:existing-container-repo-names="[]"
				:show-display-name="true"
				:suggested-display-name="suggestedAppName"
			/>
		</Container>
		<!-- </f-div> -->
	</f-div>
	<f-div
		v-if="submitError"
		padding="medium"
		height="hug-content"
		gap="medium"
		direction="row"
		width="100%"
		state="danger"
	>
		<f-text size="small" weight="regular" variant="para" color="danger" data-qa-submit-err-text>
			{{ submitError }}</f-text
		>
		<f-div align="top-right" width="hug-content">
			<f-icon
				class="cursor-pointer"
				source="i-close"
				size="x-small"
				data-qa-error-close-icon
				@click="submitError = ''"
			>
			</f-icon>
		</f-div>
	</f-div>
	<f-div height="hug-content">
		<f-button
			:label="btnText"
			state="success"
			variant="block"
			align="right"
			:loading="isSubmitting"
			:disabled="isDisabled"
			:data-qa-deploy-config-submit-btn-text="btnText"
			@click="submit"
		></f-button>
	</f-div>
</template>
<script lang="ts">
import { FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { Container } from "@cldcvr/flow-vue3";
import { cloneDeep } from "lodash-es";
import { PropType } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { orgStore } from "@/modules/org/org-store";
import { userStore } from "@/modules/user/user-store";
import { app as AppProto } from "@/protocol/app";
import { ArtifactType, JSONSchema, Provisioner, sourceEnum } from "@/protocol/common";
import { pipelineModule } from "@/protocol/pipeline";
import JSONSchemaFormBuilder2 from "@/shared/components/JSONSchemaFormBuilder2.vue";
import { getErrorMessage, getProvisionerIcon, parseUrl } from "@/utils";

import { applicationStore } from "../application-store";

import { containerProviderMap } from "./app-widget/applicationForm";
import ApplicationContainerForm, {
	ApplicationContainerFormValues
} from "./ApplicationContainerForm.vue";
import { getPipelineModuleId, TemplateOption } from "./ApplicationDeploymentModalTemplateStep.vue";

type FormValues = {
	cloudPlatform: {
		title: string;
		data: {
			id: Provisioner;
		};
	};
	buildType: {
		title: string;
		data: {
			id: string;
			icon: string;
			description: string;
		};
	} | null;
};

export default {
	name: "AddAppDeployConfigStep",

	components: {
		JSONSchemaFormBuilder2,
		ApplicationContainerForm,
		Container
	},

	props: {
		appId: {
			type: String as PropType<string | undefined>,
			required: true
		},

		provisioner: {
			type: String as PropType<Provisioner | undefined>
		}
	},

	emits: ["back", "toggle-info", "close", "set-inferred-pipeline", "app-updated"],

	data: () => ({
		isFetchBuildType: false,
		isSubmitting: false,
		formState: null as FormBuilderState | null,
		formValues: {} as FormValues,
		submitError: "",
		isOutputArtifactFormShown: false,
		app: null as AppProto | null,

		outputArtifactValues: {} as ApplicationContainerFormValues,
		selectedTemplate: null as TemplateOption | null,
		templateFields: null as JSONSchema | null,
		deploymentTemplateInfo: null as {
			template: pipelineModule | undefined;
			inputs: unknown;
			isValid: boolean;
		} | null,

		defaultValues: null as Record<string, any> | null,
		fetchTemplates: true
	}),

	computed: {
		title() {
			return !this.isOutputArtifactFormShown
				? "How would you like to deploy this app?"
				: "Setup build pipeline output type";
		},

		orgId() {
			return orgStore.activeOrgId as string;
		},

		isDisabled(): boolean {
			// @todo - temp code to show the form
			// return false;
			return !this.formState?.isValid;
		},

		btnText(): string {
			return this.isOutputArtifactFormShown
				? "NEXT - CONFIGURE BUILD PIPELINE"
				: "NEXT - SETUP BUILD PIPELINE TYPE";
		},

		cloudPlatformOptions() {
			return [
				{
					title: "AWS",
					data: {
						id: Provisioner.aws
					}
				},
				{
					title: "Azure",
					data: {
						id: Provisioner.azure
					}
				},
				{
					title: "GCP",
					data: {
						id: Provisioner.gcp
					}
				}
			];
		},

		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					cloudPlatform: {
						type: "select",
						selection: "single",
						placeholder: "Select cloud platform",
						qaId: "select-cloud-platform",
						label: { title: "Select cloud platform" },
						searchable: false,
						options: this.cloudPlatformOptions
					},

					buildType: {
						type: "select",
						selection: "single",
						placeholder: "Select cloud platform",
						qaId: "select-cloud-platform",
						label: { title: "What type of build output would you like to generate?" },
						searchable: false,
						className: "loading",
						iconLeft: this.isFetchBuildType ? "i-loader-success" : undefined,
						disabled: this.isFetchBuildType,
						options: this.deploymentTemplateOptions,
						validationRules: [{ name: "required" }],
						//@ts-expect-error
						optionTemplate: (option: TemplateOption) =>
							html` <f-div gap="medium" overflow="hidden" align="middle-left">
								<f-div width="hug-content">
									<f-icon state="warning" source="${option.data.icon}"> </f-icon>
								</f-div>

								<f-div direction="column">
									<f-text ellipsis="true" size="small">${option.title}</f-text>
									<f-text size="x-small" state="secondary">${option.data.description}</f-text>
								</f-div>
							</f-div>`
					}
				}
			};
		},

		orgAppDeploymentTemplates() {
			return applicationDeploymentStore.orgAppDeploymentTemplates[this.orgId] ?? [];
		},

		deploymentTemplateOptions() {
			return this.orgAppDeploymentTemplates.map(module => {
				const pipelineId = getPipelineModuleId(module);
				return {
					title: module.displayName ?? "",
					data: {
						id: pipelineId,
						description: pipelineId,
						icon: getProvisionerIcon(module.provisioner)
					}
				};
			});
		},

		suggestedAppName() {
			const containerProvider = this.outputArtifactValues.containerProvider?.data.id;
			const appPath =
				parseUrl(this.outputArtifactValues.containerRepo)?.pathname.replace("/", "") ??
				this.outputArtifactValues.containerRepo;

			return appPath
				? `${appPath}${containerProvider ? `-${containerProviderMap(containerProvider)}` : ""}`
				: `${this.app?.name}-build`;
		},

		projectId() {
			return this.$route.params.projectId as string;
		},

		deploymentMeta() {
			return userStore.profile?.metadata?.appWizardMeta?.[this.appId ?? ""] ?? null;
		}
	},

	watch: {
		"formValues.cloudPlatform": {
			deep: true,
			immediate: false,

			handler(
				val: FormValues["cloudPlatform"] | undefined,
				old: FormValues["cloudPlatform"] | undefined
			) {
				if (val?.data.id !== old?.data.id && this.fetchTemplates) {
					this.templateFields = null;
					this.selectedTemplate = null;
					this.formValues.buildType = null;
					this.defaultValues = null;
					this.fetchDeploymentTemplates(val?.data.id);
				}
			}
		},

		appId: {
			immediate: true,

			handler() {
				this.getApp();
			}
		}
	},

	mounted() {
		if (this.provisioner) {
			const cloudPlatform = this.cloudPlatformOptions.find(
				option => option.data.id === this.provisioner
			);
			if (cloudPlatform && this.deploymentMeta === null) {
				this.formValues.cloudPlatform = cloudPlatform;
				this.fetchDeploymentTemplates(cloudPlatform.data.id);
			}
		}

		// if the user has already configurated deployment template for the current app we show the fields pre-filled.
		this.loadExistingValues();
	},

	methods: {
		loadExistingValues() {
			if (this.deploymentMeta?.template) {
				this.fetchTemplates = false;
				const cloudPlatform = this.cloudPlatformOptions.find(
					c => c.data.id === this.deploymentMeta?.template?.provisioner
				);
				const { template } = this.deploymentMeta;
				const buildType = this.deploymentTemplateOptions.find(
					t => t.data.id === getPipelineModuleId(template)
				);

				if (cloudPlatform) {
					this.formValues.cloudPlatform = { ...cloudPlatform };
				}
				if (buildType) {
					this.formValues.buildType = { ...buildType };
					const deploymentTemplate = this.orgAppDeploymentTemplates.find(
						t => getPipelineModuleId(t) === buildType?.data.id
					);

					// Default values | user meta
					this.defaultValues = this.deploymentMeta?.inputs ?? null;
					this.templateFields = deploymentTemplate?.inputs ?? null;
					this.selectedTemplate = buildType;
				}
			}
		},

		async getApp() {
			if (this.appId && this.orgId) {
				this.app = await applicationStore.GET_APP_BY_ID({
					appId: this.appId,
					orgId: this.orgId,
					projectId: this.projectId
				});
			}
		},

		updateLayout() {
			const popOver = this.$refs.templateForm as
				| InstanceType<typeof JSONSchemaFormBuilder2>
				| undefined;
			popOver?.updateModalLayout();
		},

		handleInputs(event: CustomEvent<FormValues>) {
			const input = { ...event.detail };

			this.fetchTemplates = true;
			this.formValues = cloneDeep(input);

			if (input.buildType) {
				this.selectedTemplate = input.buildType;
				const deploymentTemplate = this.orgAppDeploymentTemplates.find(
					template => getPipelineModuleId(template) === input.buildType?.data.id
				);

				this.templateFields = deploymentTemplate?.inputs ?? null;
			}
		},

		async submit() {
			// If the artifact form is shown then we update the app with output artifact
			// else we update the app with deployment template info
			if (this.isOutputArtifactFormShown) {
				await this.updateAppWithOutputArtifact();
			} else {
				await this.updateAppWithDeploymentTemplateInfo();
			}
		},

		async updateAppWithOutputArtifact() {
			(
				this.$refs.appContainerForm as InstanceType<typeof ApplicationContainerForm> | undefined
			)?.submit();

			if (!this.app) {
				this.submitError = "Something went wrong. App not found.";
				return;
			}

			this.submitError = "";
			this.isSubmitting = true;
			const { outputArtifactValues } = this;

			const containerProviderId = this.outputArtifactValues.containerProvider?.data.id;
			if (
				!outputArtifactValues.isValid ||
				!containerProviderId ||
				!outputArtifactValues.containerRepo
			) {
				this.isSubmitting = false;
				return;
			}

			try {
				const artifacts = this.app.artifacts ? [...this.app.artifacts] : [];

				artifacts.push({
					name: this.outputArtifactValues.appContainerImageName ?? "Container Image",
					containerImage: {
						provider: containerProviderId,
						host: outputArtifactValues.appHost,
						repo: outputArtifactValues.containerRepo,
						reference: outputArtifactValues.containerTag ?? undefined,
						versionTagPattern: outputArtifactValues.versionTagPattern?.data.id
					}
				});

				// Update the application with the artifact
				await applicationStore.UPDATE_APPLICATION({
					id: this.app.id,
					orgId: this.app.orgId,
					projId: this.app.projId,
					name: this.app.name,
					description: this.app.description,
					artifacts,
					replaceVariables: false
				});

				// Update the app wizard step
				this.$emit("app-updated");
			} catch (error) {
				this.submitError = getErrorMessage(error);
			} finally {
				this.isSubmitting = false;
			}
		},

		async updateAppWithDeploymentTemplateInfo() {
			if (!this.app) {
				return;
			}

			try {
				this.isSubmitting = true;

				// We update the template info in the user meta
				await userStore.UPDATE_USER_META({
					appWizardMeta: {
						[this.app.id]: {
							...this.deploymentTemplateInfo,
							projId: this.app.projId,
							orgId: this.app.orgId,
							provisioner: this.formValues.cloudPlatform?.data.id
						}
					}
				});

				// get inferred pipeline for the integration step

				const pipeline = await applicationStore.INFER_CI_PIPELINE({
					appId: this.app.id,
					orgId: this.app.orgId,
					projId: this.app.projId,
					outputType: ArtifactType.ContainerImage,
					cloud: this.formValues.cloudPlatform?.data.id,
					language: this.app.appConfig?.language ?? sourceEnum.source_no
				});

				this.$emit("set-inferred-pipeline", pipeline);

				// check if the app.artifacts has a container image
				const containerImage = this.app.artifacts?.find(artifact => artifact.containerImage);

				// if containerImage then we don't need to show the outputArtifact form
				if (containerImage) {
					// Update the app wizard step
					this.$emit("app-updated");
				} else {
					// We show the output artifact form
					this.isOutputArtifactFormShown = true;
				}
			} catch (error) {
				this.submitError = getErrorMessage(error);
			} finally {
				this.isSubmitting = false;
			}
		},

		async fetchDeploymentTemplates(provisioner: Provisioner | undefined) {
			if (!this.orgId || !provisioner) {
				return;
			}
			this.isFetchBuildType = true;
			await applicationDeploymentStore.FETCH_ORG_APP_DEPLOYMENT_TEMPLATES({
				orgId: this.orgId,
				provisioner
			});
			this.isFetchBuildType = false;
		},

		handleTemplateInfo({ inputs, isValid }: { inputs: unknown; isValid: boolean }) {
			const selectedTemplateId = this.selectedTemplate?.data.id;
			const deploymentTemplate = this.orgAppDeploymentTemplates.find(template => {
				const id = getPipelineModuleId(template);
				if (id === selectedTemplateId) {
					return template;
				}
				return null;
			});
			this.deploymentTemplateInfo = {
				template: deploymentTemplate,
				inputs,
				isValid
			};
			this.$nextTick(() => {
				this.updateLayout();
			});
		},

		goBack() {
			if (this.isOutputArtifactFormShown) {
				this.isOutputArtifactFormShown = false;
				return;
			} else {
				this.$nextTick(() => {
					this.updateLayout();
				});
				this.$emit("back");
			}
		}
	}
};
</script>
