<template>
	<AuthContainer>
		<div class="flow-stages">
			<div class="flow-stage active">
				<Section padding="top">
					<div class="center-of-container margin-bt-12 position-relative">
						<Icon
							v-if="showBackIcon"
							name="i-arrow-left"
							state="dark"
							class="position-absolute left-24"
							size="small"
							data-qa-back-icon
							@click="backClick()"
						/>
						<h2 data-qa-auth-header class="fc-dark">{{ header }}</h2>
					</div>
				</Section>
				<Section padding="vertical" class="margin-tp-24">
					<slot />
				</Section>

				<Section padding="vertical">
					<div class="center-of-container margin-tp-24">
						<p class="paragraph-2 fc-dark">{{ alternateAuthText }}</p>
					</div>
				</Section>

				<Section padding="vertical">
					<slot name="alt-auth" />
				</Section>
			</div>
		</div>
	</AuthContainer>
</template>

<script>
import { Section, AuthContainer, Icon } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "AuthWrapper",

	components: {
		AuthContainer,
		Section,
		Icon
	},

	props: {
		header: {
			type: String,
			default: ""
		},

		alternateAuthText: {
			type: String,
			default: ""
		},

		showBackIcon: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		backClick() {
			this.$emit("backClick");
		}
	}
});
</script>
