<template>
	<Wrapper
		width="450px"
		height="450px"
		max-height="50vh"
		border-radius="4px"
		background="element-light"
		overflow="scroll"
	>
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			class="height-100-per"
			overflow="visible"
		>
			<Header>
				<Icon
					v-if="options.showBackBtn"
					name="i-arrow-left"
					size="small"
					:effects="true"
					data-qa="assign-cred-back-btn"
					@click="$emit('back')"
				/>
				<Typography type="h4" color="dark"> {{ headerTitle }} </Typography>
				<Icon size="x-small" type="filled" name="i-close" @click="$emit('close')" />
			</Header>
			<Container
				padding="16px 16px 20px 16px"
				align="left top"
				direction="column"
				:gap="20"
				overflow="visible"
			>
				<Container
					v-if="credToUnassign.length && !credToAssign.length"
					align="left top"
					:padding="0"
					direction="column"
				>
					<Typography type="p1-para" color="dark"
						>The following credentials will be
						<Typography type="p1-para" color="error" weight="bold" inline>unassigned</Typography>
						from
						<Typography type="p1-para" color="dark" weight="bold" inline>{{
							entity.name
						}}</Typography>
					</Typography>
					<Typography
						v-for="cred in credToUnassign"
						:key="cred.id"
						type="p1-para"
						color="dark"
						:data-qa="`unassign-cred-${cred.name}`"
					>
						&#8226; {{ cred.name }}
						<ClassificationTag
							v-if="cred.classificationId"
							:classification-id="cred.classificationId"
						/>
					</Typography>
				</Container>
				<Container
					v-if="credToAssign.length && !credToUnassign.length"
					align="left top"
					:padding="0"
					direction="column"
				>
					<Typography type="p1-para" color="dark"
						>The following credentials will be
						<Typography type="p1-para" color="success" weight="bold" inline>assigned</Typography> to
						<Typography
							type="p1-para"
							color="dark"
							weight="bold"
							inline
							:data-qa="`entity-name-${entity.name}`"
							>{{ entity.name }}</Typography
						></Typography
					>
					<Typography
						v-for="cred in credToAssign"
						:key="cred.id"
						type="p1-para"
						color="dark"
						:data-qa="`assign-cred-${cred.name}`"
					>
						&#8226; {{ cred.name }}
						<ClassificationTag
							v-if="cred.classificationId"
							:classification-id="cred.classificationId"
						/>
					</Typography>
				</Container>

				<Container
					v-if="credToAssign.length && credToUnassign.length"
					align="left top"
					:padding="0"
					direction="column"
				>
					<Typography type="p1-para" color="dark"
						>The following changes will be applied to
						<Typography
							type="p1-para"
							color="dark"
							weight="bold"
							inline
							:data-qa="`entity-name-${entity.name}`"
							>{{ entity.name }}</Typography
						></Typography
					>
					<Container padding="16px 0px 16px 0px" :gap="1" direction="column">
						<Container padding="0px 0px 16px 0px" direction="column">
							<Container :padding="0" direction="row">
								<Icon name="i-minus-2" color="gray-200" />
								<Typography type="p1-para" color="dark"
									>Credentials to be
									<Typography type="p1-para" color="error" weight="bold" inline
										>unassigned</Typography
									></Typography
								>
							</Container>
							<Container padding="0px 0px 0px 40px" direction="column">
								<Typography
									v-for="cred in credToUnassign"
									:key="cred.id"
									type="p1-para"
									color="dark"
									:data-qa="`unassign-cred-${cred.name}`"
								>
									&#8226; {{ cred.name }}
									<ClassificationTag
										v-if="cred.classificationId"
										:classification-id="cred.classificationId"
									/>
								</Typography>
							</Container>
						</Container>

						<Container padding="0px 0px 16px 0px" direction="column">
							<Container :padding="0" direction="row">
								<Icon name="i-tick-2" color="gray-200" />
								<Typography type="p1-para" color="dark"
									>Credentials to be
									<Typography type="p1-para" color="success" weight="bold" inline
										>assigned</Typography
									>
								</Typography>
							</Container>
							<Container padding="0px 0px 0px 40px" direction="column">
								<Typography
									v-for="cred in credToAssign"
									:key="cred.id"
									type="p1-para"
									color="dark"
									:data-qa="`assign-cred-${cred.name}`"
								>
									&#8226; {{ cred.name }}
									<ClassificationTag
										v-if="cred.classificationId"
										:classification-id="cred.classificationId"
									/>
								</Typography>
							</Container>
						</Container>
					</Container>
				</Container>
			</Container>

			<Footer>
				<Button
					state="full"
					:loading="isSubmitting"
					type="success"
					data-qa="confirm-assign-cred"
					@click="confirmChanges"
				>
					Confirm
				</Button>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import { Typography, Container, Icon, Wrapper, Button, Header, Footer } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import ClassificationTag from "@/modules/credentials/components/credential-assign/ClassificationTag.vue";
import { AssignCredToEntities } from "@/modules/credentials/credential-types";
import { Creds } from "@/protocol/identity";

export default defineComponent({
	name: "ConfirmCredAssignChanges",

	components: {
		ClassificationTag,
		Typography,
		Container,
		Wrapper,
		Button,
		Header,
		Footer,
		Icon
	},

	props: {
		entity: {
			type: Object as PropType<AssignCredToEntities>,
			required: true
		},

		headerTitle: {
			type: String,
			required: true
		},

		credToAssign: {
			type: Array as PropType<Creds[]>,
			required: true
		},

		credToUnassign: {
			type: Array as PropType<Creds[]>,
			required: true
		},

		isSubmitting: {
			type: Boolean
		},

		options: {
			type: Object,
			default: () => ({ showBackBtn: true })
		}
	},

	methods: {
		confirmChanges() {
			this.$emit("onConfirm");
		}
	}
});
</script>
