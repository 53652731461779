<script lang="ts">
import {
	Accordion,
	Button,
	Container,
	Icon,
	PopOver,
	Slab,
	Tag,
	Typography
} from "@cldcvr/flow-vue3";
import { PropType, computed, ref, toRefs, watch } from "vue";
import { useRouter } from "vue-router";

import AppDepWebHookDeleteModal from "@/modules/application-deployment/components/AppDepWebHookDeleteModal.vue";
import AppDepWebHookModal from "@/modules/application-deployment/components/AppDepWebHookModal.vue";
import {
	applicationIntegrationStore,
	getSortedIntegrationJobList
} from "@/modules/application-integration/application-integration-store";
import IntegrationStatusText from "@/modules/project-landing/components/IntegrationStatusText.vue";
import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import { appIntegration, integrationJobAbstract } from "@/protocol/app";
import { AppDeployment } from "@/protocol/deployment";
import { webhook } from "@/protocol/externalEvents";
import { environment as EnvironmentProto } from "@/protocol/infra";

export default {
	name: "DeploymentListWebhooks"
};
</script>

<script lang="ts" setup>
const props = defineProps({
	deployment: {
		type: Object as PropType<AppDeployment>,
		required: true
	},

	env: {
		type: Object as PropType<EnvironmentProto>,
		required: true
	}
});

const { deployment } = toRefs(props);

const webHooks = computed(() => {
	const webHooksList = webHooksStore.webHooksList[deployment.value.id!];
	return webHooksList && webHooksList.length > 0 ? webHooksList : [];
});

const isWebhookAccordionOpen = ref(false);
const addWebhookModalOpen = ref(false);
const webHookToDelete = ref<webhook | null>(null);

const router = useRouter();

const appIntegrationsArray = computed(() => {
	const integrations = deployment.value.appId
		? applicationIntegrationStore.integrations[deployment.value.appId]
		: [];

	const integrationMap: Record<string, appIntegration[]> = {};

	integrations?.forEach(integration => {
		const artifactId = integration.inputAppArtifact?.id;
		if (!artifactId) {
			return;
		}

		if (!integrationMap[artifactId]) {
			integrationMap[artifactId] = [integration];
		} else {
			integrationMap[artifactId]?.push(integration);
		}
	});

	return Object.values(integrationMap).flat();
});

const integrationsWithWebhooks = computed(() => {
	return webHooks.value.map(webHook => {
		const appIntId = webHook.codepipesTrigger?.appInt?.id;
		const integration = appIntegrationsArray.value.find(integration => integration.id === appIntId);

		if (!integration || !appIntId) {
			return null;
		}

		return {
			integration,
			latestJob: getLatestJob(appIntId),
			webHook
		};
	});
});

watch(
	[webHooks],
	() => {
		isWebhookAccordionOpen.value = webHooks.value.length > 0;
	},
	{ immediate: true }
);

// Webhook component gets unmounted before it's close event triggers, so we manually close the modal here
watch(
	[integrationsWithWebhooks],
	() => {
		webHookToDelete.value = null;

		integrationsWithWebhooks.value.forEach(integrationsWithWebhook => {
			const integrationId = integrationsWithWebhook?.integration.id;

			if (integrationId && !applicationIntegrationStore.integrationJobList[integrationId]) {
				applicationIntegrationStore.FETCH_INTEGRATION_JOBS({
					appId: deployment.value.appId!,
					orgId: deployment.value.orgId!,
					projId: deployment.value.projId!,
					integrationId
				});
			}
		});
	},
	{ immediate: true }
);

watch(
	() => deployment.value.appId,
	() => {
		const { orgId, projId, appId } = deployment.value;
		const existingIntegrations = appId ? applicationIntegrationStore.integrations[appId] : null;
		const hasIntegrations = existingIntegrations && existingIntegrations.length > 0;

		if (!hasIntegrations) {
			applicationIntegrationStore.FETCH_INTEGRATIONS({
				appId: appId!,
				orgId: orgId!,
				projId: projId!
			});
		}
	},
	{ immediate: true }
);

watch(
	() => deployment.value.id,
	() => {
		const { orgId, projId, id } = deployment.value;

		webHooksStore.LIST_WEB_HOOKS({
			orgId: orgId!,
			projectId: projId!,
			entityId: id!
		});
	},
	{ immediate: true }
);

function getLatestJob(integrationId: string) {
	const [firstJob] = getSortedIntegrationJobList(integrationId);
	return firstJob;
}

function viewLogs(integrationJob: integrationJobAbstract) {
	router.push({
		name: "stageViewIntegrationWithJob",
		params: {
			appId: deployment.value.appId!,
			orgId: deployment.value.orgId!,
			projectId: deployment.value.projId!,
			integrationId: integrationJob.integrationId,
			jobId: integrationJob.id
		}
	});
}
</script>

<template>
	<Accordion :open="isWebhookAccordionOpen" data-qa-deployment-triggers>
		<template #header>
			<Container
				align="space-between center"
				overflow="visible"
				padding="20px 8px"
				:clickable="true"
				@click="isWebhookAccordionOpen = !isWebhookAccordionOpen"
			>
				<Container :padding="0" :gap="8">
					<Typography type="h4" weight="medium" color="dark">Webhooks</Typography>
					<Tag size="small" shape="rounded" :data-qa-deployment-triggers-count="webHooks.length">{{
						webHooks.length
					}}</Tag>

					<PopOver v-if="deployment?.id" :open="addWebhookModalOpen">
						<Button
							v-tooltip="{
								content: 'Add variables'
							}"
							state="icon"
							size="x-small"
							data-qa-deployment-add-trigger
							@click.stop="addWebhookModalOpen = true"
						>
							<Icon name="i-plus" color="gray-600" size="x-small" :effects="false" />
						</Button>

						<template #content>
							<AppDepWebHookModal
								:env="env"
								:app-dep-entity="deployment"
								:integration-pipelines="appIntegrationsArray"
								@close="addWebhookModalOpen = false"
								@back="addWebhookModalOpen = false"
							/>
						</template>
					</PopOver>
				</Container>
				<Icon
					name="i-chevron-down"
					size="x-small"
					:rotate="isWebhookAccordionOpen ? 180 : 0"
					@click.stop="isWebhookAccordionOpen = !isWebhookAccordionOpen"
				/>
			</Container>
		</template>

		<Container
			v-if="!webHooks || webHooks.length === 0"
			align="center"
			padding="10px 0 40px 0"
			data-qa-app-widget-output
			data-qa-app-widget-output-empty
			direction="column"
			class="align-items-center"
			:gap="12"
			:grow="1"
		>
			<Icon name="i-webhook" type="filled" state="light" />
			<Typography type="p1" color="light">No webhooks added</Typography>
		</Container>

		<Container
			v-else
			data-qa-app-widget-output
			padding="0 0 16px 0"
			overflow="auto"
			align="stretch top"
		>
			<template v-for="(intWebHook, i) in integrationsWithWebhooks" :key="i">
				<Slab
					v-if="intWebHook"
					size="large"
					:data-qa-webhook-name="intWebHook.integration.name"
					type="transparent"
					effects
				>
					<template #primary-action>
						<f-icon source="i-webhook"></f-icon>
					</template>

					<template #secondary-actions>
						<Button
							v-if="intWebHook.latestJob"
							state="outlined"
							type="default"
							size="small"
							class="show-on-hover"
							data-qa-int-pipeline-view-logs-btn
							@click="viewLogs(intWebHook.latestJob)"
							>View logs
						</Button>

						<PopOver :open="webHookToDelete !== null">
							<f-icon
								source="i-delete"
								state="danger"
								class="show-on-hover"
								data-qa-delete-webhook
								@click="webHookToDelete = intWebHook.webHook"
							></f-icon>

							<template #content>
								<AppDepWebHookDeleteModal
									v-if="webHookToDelete"
									:web-hook="webHookToDelete"
									@close="webHookToDelete = null"
									@back="webHookToDelete = null"
								/>
							</template>
						</PopOver>
					</template>

					<f-text size="medium">{{ intWebHook.integration.name }}</f-text>
					<Container padding="0" :gap="4">
						<IntegrationStatusText :integration-job="intWebHook.latestJob" />
					</Container>
				</Slab>
			</template>
		</Container>
	</Accordion>
</template>
