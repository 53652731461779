import { AxiosError, AxiosResponse } from "axios";

import { ServerError } from "./get-error-message";

export function getErrorCode(error: unknown) {
	if (typeof error === "string") {
		return error;
	}

	const axiosError = (error as AxiosError<ServerError>).response?.data;
	const axiosResponse = (error as AxiosResponse<ServerError>).data;

	if (axiosError) {
		return axiosError.Code;
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	} else if (axiosResponse) {
		return axiosResponse.Code;
	} else if ("message" in (error as Error)) {
		return (error as Error).message;
	}

	return JSON.stringify(error);
}
