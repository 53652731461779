import {
	Policies,
	Policy,
	PolicySet,
	PolicySets,
	success,
	validatorService
} from "@/protocol/validator";
import ApiService, { IDENTITY_API, VALIDATION_API } from "@/services/api-service";

export const listPolicySets: validatorService["listPolicySet"] = async function ({ orgId }) {
	const response = await ApiService.get<PolicySets>(
		`${VALIDATION_API}/organizations/${orgId}/policysets`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getPolicySet: validatorService["getPolicySet"] = async function ({ orgId, id }) {
	const response = await ApiService.get<PolicySet>(
		`${IDENTITY_API}/organizations/${orgId}/policysets/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listPoliciesInSet: validatorService["listPolicies"] = async function ({
	orgId,
	setId
}) {
	const response = await ApiService.get<Policies>(
		`${IDENTITY_API}/organizations/${orgId}/policysets/${setId}/policies`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getPolicy: validatorService["getPolicy"] = async function ({ orgId, id, setId }) {
	const response = await ApiService.get<Policy>(
		`${IDENTITY_API}/organizations/${orgId}/policysets/${setId}/policies/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const createPolicySet: validatorService["createPolicySet"] = async function (policySet) {
	const response = await ApiService.post<PolicySet>(
		`${VALIDATION_API}/organizations/${policySet.orgId}/policysets`,
		policySet
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updatePolicySet: validatorService["updatePolicySet"] = async function (policySet) {
	const response = await ApiService.put<success>(
		`${VALIDATION_API}/organizations/${policySet.orgId}/policysets/${policySet.id}`,
		policySet
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deletePolicySet: validatorService["deletePolicySet"] = async function ({ orgId, id }) {
	const response = await ApiService.delete<success>(
		`${VALIDATION_API}/organizations/${orgId}/policysets/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
