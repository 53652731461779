<script setup lang="ts">
import { FTextStateProp } from "@cldcvr/flow-core";
import { Lexer } from "marked";
import { PropType, computed, toRefs } from "vue";

import MarkdownTokensRenderer from "./MarkdownTokensRenderer.vue";

const props = defineProps({
	text: {
		type: String,
		required: true
	},

	state: {
		type: String as PropType<FTextStateProp>
	},

	size: {
		type: String as PropType<"x-large" | "large" | "medium" | "small" | "x-small">
	}
});

const { text, state } = toRefs(props);

const markdown = computed(() => {
	const lexer = new Lexer({
		gfm: true
	});
	return lexer.lex(text.value);
});

const fTextSizes = ["x-large", "large", "medium", "small", "x-small"] as const;

function getSizeFromHeading(heading: number) {
	const size = fTextSizes[heading - 1];

	if (!size) {
		return "x-small";
	}

	return size;
}
</script>

<template>
	<f-div
		class="flow-add-scrollbar"
		direction="column"
		overflow="scroll"
		max-height="100px"
		height="hug-content"
		data-qa-markdown-rendered
	>
		<template v-for="blockelement in markdown" :key="blockelement.raw">
			<template v-if="blockelement.type === 'heading' && blockelement.tokens">
				<f-text :state="state" variant="heading" :size="getSizeFromHeading(blockelement.depth)">
					<MarkdownTokensRenderer :tokens="blockelement.tokens" :state="state" />
				</f-text>
			</template>

			<template v-else-if="blockelement.type === 'paragraph' && blockelement.tokens">
				<p>
					<MarkdownTokensRenderer :tokens="blockelement.tokens" :state="state" :size="size" />
				</p>
			</template>

			<template v-else-if="blockelement.type === 'list' && blockelement.items">
				<ol v-if="blockelement.ordered" class="Markdown-list">
					<li v-for="listItem in blockelement.items" :key="listItem.raw">
						<MarkdownTokensRenderer :tokens="listItem.tokens" :state="state" :size="size" />
					</li>
				</ol>
				<ul v-else class="Markdown-list">
					<li v-for="listItem in blockelement.items" :key="listItem.raw">
						<MarkdownTokensRenderer :tokens="listItem.tokens" :state="state" :size="size" />
					</li>
				</ul>
			</template>
		</template>
	</f-div>
</template>

<style lang="scss">
.Markdown-list {
	margin-left: 20px;
}

ol.Markdown-list li {
	list-style: decimal;
}

ul.Markdown-list li {
	list-style: disc;
}
</style>
