<template>
	<EmptyState
		:icon="iconProp"
		:message="title"
		data-qa-creds-search-empty-screen
		:subtitle="subTitle"
		position="relative"
	/>
</template>

<script lang="ts">
import { EmptyState, EmptyStateIconProp } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyFilterResult",
	components: { EmptyState },

	props: {
		title: {
			type: String,
			required: true
		},

		subTitle: {
			type: String,
			required: true
		},

		icon: {
			type: String,
			required: true
		}
	},

	computed: {
		iconProp(): EmptyStateIconProp {
			return {
				name: this.icon,
				type: "filled",
				state: "light",
				size: "large"
			};
		}
	}
});
</script>
