<template>
	<f-icon v-if="!showValue" source="i-tick" size="small" state="success" />

	<f-text v-else size="small" variant="code" ellipsis color="light" data-qa-variable-value inline>
		{{ showValue }}
	</f-text>

	<InfoPopover v-if="inheritedFrom" :data-qa-variable-inheritance="variableColumn.key">
		<Pictogram
			v-if="inheritedFrom.valueFrom === 'environment'"
			size="x-small"
			state="avatar"
			shape="hexagon"
			:label="inheritedFrom.title"
		/>
		<Pictogram
			v-else-if="inheritedFrom.valueFrom === 'project' && inheritedFrom.icon"
			size="x-small"
			shape="squircle"
			>{{ inheritedFrom.icon }}</Pictogram
		>
		<Pictogram
			v-else-if="inheritedFrom.valueFrom === 'project' && !inheritedFrom.icon"
			size="x-small"
			state="avatar"
			shape="squircle"
			:label="inheritedFrom.title"
		/>

		<template #body>
			<Container
				direction="column"
				:gap="20"
				:padding="0"
				:data-qa-inheritance-content-for="variableColumn.name"
			>
				<Container
					v-for="(value, idx) in variableColumn.inheritedValues"
					:key="value.title + value.variable.key + value.variable.value + idx"
					direction="column"
					:shrink="0"
					:gap="6"
					:padding="0"
				>
					<Typography type="p3" color="light">{{ value.title }}</Typography>
					<Container :gap="4" :padding="0">
						<Typography
							type="p3"
							color="dark"
							:decoration="idx === 0 ? '' : 'line-through'"
							weight="medium"
							>{{ value.variable.key }}</Typography
						>
						<Typography type="p3" color="light" :decoration="idx === 0 ? '' : 'line-through'">{{
							value.variable.sensitive ? "••••••••••" : value.variable.value
						}}</Typography>
					</Container>
				</Container>
			</Container>
		</template>
	</InfoPopover>
</template>

<script lang="ts">
import { Container, InfoPopover, Pictogram, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { VariableColumn } from "./variable-list-types";

export default defineComponent({
	name: "VariableListVariableInheritancePopOver",

	components: {
		Pictogram,
		Container,
		Typography,
		InfoPopover
	},

	props: {
		variableColumn: {
			type: Object as PropType<VariableColumn>,
			required: true
		},

		showValue: {
			type: String as PropType<undefined | string>
		}
	},

	computed: {
		inheritedFrom() {
			const { inheritedValues } = this.variableColumn;
			return hasInheritedValues(inheritedValues) && inheritedValues[1];
		}
	}
});

function hasInheritedValues(
	inheritedValues?: VariableColumn["inheritedValues"]
): inheritedValues is Exclude<VariableColumn["inheritedValues"], undefined> {
	return Boolean(inheritedValues && inheritedValues.length > 1);
}
</script>
