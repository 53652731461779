import {
	FormBuilderCustomValidationRule,
	FormBuilderGenericValidationRule,
	FormBuilderValidationRules
} from "@cldcvr/flow-form-builder";

import { variableType } from "@/protocol/common";

// Server allows unlimited number of chars for entity names but for
// having a consistent experience across the app, we are limiting it.
export const MAX_ENTITY_NAME_LENGTH = 128;

export function entityNameMaxLengthRule(value?: string | unknown[]): boolean {
	if (typeof value !== "string" || Array.isArray(value)) {
		return false;
	}

	if (value.length > MAX_ENTITY_NAME_LENGTH) {
		return false;
	}
	return true;
}

export function entityValidURLRule(value?: string | unknown[]): boolean {
	try {
		if (Array.isArray(value)) {
			return false;
		}

		if (!value?.length) {
			return false;
		}

		const url = new URL(/http(s?):\/\//.test(value) ? value : `https://${value}`);

		// Github and Gitlab urls need to have /org/project format
		const isGithubOrGitlab =
			url.hostname.includes("github.com") || url.hostname.includes("gitlab.com");
		if (isGithubOrGitlab && !/^\/.+?\/.+/.test(url.pathname)) {
			return false;
		}

		const isResolvableHostName = /[^.]+\.[^.]+/.test(url.hostname);

		return isResolvableHostName && !value.includes(" ");
	} catch (err) {
		return false;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function duplicateEntityName(value: string, values: any[] | Record<string, any>) {
	const lowercaseValues = values.map((str: string) => str.toLowerCase());
	return lowercaseValues.indexOf(value.toLowerCase()) < 0;
}

// Validate creator for Flow 2
export function duplicateEntityNameRule2(
	existingEntityNames: string[],
	customError?: string
): FormBuilderGenericValidationRule {
	return {
		name: "custom",
		validate(value?: string | unknown[]): boolean {
			if (typeof value !== "string" || Array.isArray(value)) {
				return false;
			}

			const lowercaseValues = existingEntityNames.map((str: string) => str.toLowerCase());
			return !lowercaseValues.includes(value.toLowerCase());
		},
		message: customError ?? duplicateEntityNameRuleMessage
	};
}

const entityValidURLRuleMessage = "This must be a valid URL.";
const entityNameMaxLengthRuleMessage = `This must be between 1 to ${MAX_ENTITY_NAME_LENGTH} characters long.`;
const duplicateEntityNameRuleMessage = "This name already exists.";

// Rules for Flow 2
export const entityNameRules2 = {
	entityValidURLRule: {
		name: "custom",
		validate: entityValidURLRule,
		message: entityValidURLRuleMessage
	},

	entityNameMaxLengthRule: {
		name: "custom",
		validate: entityNameMaxLengthRule,
		message: entityNameMaxLengthRuleMessage
	}
} satisfies Record<string, FormBuilderCustomValidationRule>;

export const applyEntityNameRules = (existingEntityNames: string[]) => {
	return {
		duplicateEntityName: existingEntityNames,
		entityNameMaxLengthRule: true,
		required: true
	};
};

// Name rules for Flow 2
export function applyEntityNameRules2(
	existingEntityNames: string[],
	customError?: string
): FormBuilderValidationRules {
	return [
		entityNameRules2.entityNameMaxLengthRule,
		duplicateEntityNameRule2(existingEntityNames, customError),
		{
			name: "required"
		}
	];
}

const ARN_REGEX = new RegExp("^(arn:aws:iam::[0-9]+:role/[0-9a-zA-Z_./-]+)$");

export function isValidAwsArn(value?: string | unknown[]): boolean {
	if (typeof value !== "string" || Array.isArray(value)) {
		return false;
	}

	return value ? ARN_REGEX.test(value) : false;
}

export const exactLengthRuleGenerator = function (
	length: number,
	name = "value"
): FormBuilderCustomValidationRule {
	return {
		name: "custom",
		validate(value?: string | unknown[]): boolean {
			if (typeof value !== "string" || Array.isArray(value)) {
				return false;
			}

			return value ? value.length === length : false;
		},
		message: `The ${name} has to be ${length} characters long.`
	};
};

export const awsArnRule: FormBuilderCustomValidationRule = {
	name: "custom",
	validate: isValidAwsArn,
	message:
		"ARN should follow the following format: arn:aws:iam::&lt;account_ID&gt;:role/&lt;role-name&gt;."
};

export const variableNameRule = function (varType: variableType): FormBuilderCustomValidationRule {
	const matchRegex =
		varType === variableType.tf_var ? /^[a-zA-Z_][a-zA-Z0-9_-]*$/ : /^[a-zA-Z_][a-zA-Z0-9_]*$/;
	const errrorMessage =
		varType === variableType.tf_var
			? "Name must start with an alphabet and contain alphanumeric characters, underscores or hyphens."
			: "Name must start with an alphabet and contain alphanumeric characters or underscores.";
	return {
		name: "custom",
		validate(value?: string | unknown[]): boolean {
			if (typeof value !== "string" || Array.isArray(value)) {
				return false;
			}

			return value ? matchRegex.test(value) : false;
		},
		message: errrorMessage
	};
};
