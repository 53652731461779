<template>
	<Wrapper
		border-radius="4px"
		background="pop-over"
		max-height="90vh"
		width="432px"
		data-qa-deployment-entity-configuration-form
	>
		<Header>
			<Icon
				v-if="!disableBackButton || step === 2"
				name="i-arrow-left"
				type="filled"
				size="small"
				data-qa-back
				@click="goBack"
			/>
			<Typography type="h4" color="dark">Configure app deployment</Typography>
			<Container :padding="0" align="right center" :grow="1">
				<Tag :data-qa-deployment-step="step" size="small">STEP {{ step }} / 2</Tag>
				<Icon
					name="i-close"
					type="filled"
					size="x-small"
					data-qa-close-deployment-entity-configuration
					@click.stop="closePopover"
				/>
			</Container>
		</Header>

		<Container :padding="0" overflow="scroll" align="left top" class="flow-add-scrollbar">
			<KeepAlive>
				<ApplicationDeploymentModalArtifactStep
					v-if="step === 1"
					v-model:artifact-info="artifactInfo"
					:app="app"
					:app-deployment="appDeployment"
					:selected-artifact-id="selectedArtifactId"
					:show-env-selector="!Boolean(environmentId)"
				/>

				<ApplicationDeploymentModalTemplateStep
					v-else-if="step === 2 && artifactInfo.environmentId && artifactInfo.artifactType"
					v-model:template-info="templateInfo"
					:artifact-type="artifactInfo.artifactType"
					:app-deployment="appDeployment"
					:environment-id="artifactInfo.environmentId"
				/>
			</KeepAlive>
		</Container>

		<Container v-if="submitError">
			<p class="paragraph-2 fc-error" data-qa-deployment-submit-error>{{ submitError }}</p>
		</Container>

		<Footer v-if="!isSubmitting">
			<template v-if="step === 1">
				<Button
					state="full"
					type="default"
					:disabled="!artifactInfo.isValid"
					data-qa-next-step
					@click="goToTemplateStep"
					>Next - Deployment Configuration</Button
				>
			</template>
			<template v-else>
				<Button
					:loading="isSubmitting"
					state="full"
					type="default"
					data-qa-save-deployment
					:disabled="!templateInfo.isValid"
					@click="saveDeployment"
					>{{ isEditing ? "Update" : "Save" }} deployment</Button
				>
				<Button
					state="full"
					:loading="isSubmitting"
					:type="isSubmitting ? 'default' : 'success'"
					data-qa-save-and-deploy
					:disabled="!templateInfo.isValid"
					@click="saveAndDeploy"
					>{{ isEditing ? "Update" : "Save" }} and deploy</Button
				>
			</template>
		</Footer>

		<Footer v-if="isSubmitting">
			<CustomLoader />
		</Footer>
	</Wrapper>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	Tag,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import {
	applicationDeploymentStore,
	getEnvMapFromVariables
} from "@/modules/application-deployment/application-deployment-store";
import { app } from "@/protocol/app";
import {
	AppDeployment,
	AppDeploymentCreateRequest,
	DeploymentJobAction
} from "@/protocol/deployment";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { getErrorMessage } from "@/utils";

import ApplicationDeploymentModalArtifactStep, {
	ArtifactInfo
} from "./ApplicationDeploymentModalArtifactStep.vue";
import ApplicationDeploymentModalTemplateStep, {
	TemplateInfo
} from "./ApplicationDeploymentModalTemplateStep.vue";

export default defineComponent({
	name: "ApplicationDeploymentModal",

	components: {
		Button,
		Container,
		Footer,
		Header,
		Icon,
		CustomLoader,
		Typography,
		Wrapper,
		Tag,
		ApplicationDeploymentModalArtifactStep,
		ApplicationDeploymentModalTemplateStep
	},

	props: {
		app: {
			type: Object as PropType<app>,
			required: true
		},

		appDeployment: {
			type: Object as PropType<AppDeployment>
		},

		environmentId: {
			type: String
		},

		selectedArtifactId: {
			type: String
		},

		disableBackButton: {
			type: Boolean,
			default: () => null
		}
	},

	emits: ["back", "close"],

	data() {
		return {
			submitError: "",
			isSubmitting: false,

			artifactInfo: {
				environmentId: this.environmentId,
				variables: [],
				isValid: false
			} as ArtifactInfo,

			templateInfo: {
				isValid: false
			} as TemplateInfo,

			step: 1 as 1 | 2
		};
	},

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		isEditing() {
			return this.appDeployment !== undefined;
		}
	},

	methods: {
		goToTemplateStep() {
			if (this.artifactInfo.isValid) {
				this.step = 2;
			}
		},

		async saveDeployment(shouldClosePopever = true) {
			if (this.isSubmitting) {
				return null;
			}

			this.submitError = "";
			this.isSubmitting = true;

			const { variables, environmentId, deploymentName } = this.artifactInfo;
			const { template, inputs } = this.templateInfo;
			const {
				orgId,
				app: { id: appId, projId }
			} = this;

			const artifact = this.artifactInfo.artifactSelector;

			if (!environmentId || !deploymentName || !template || !artifact) {
				this.submitError = "Please fill all the required fields";
				this.isSubmitting = false;
				return null;
			}

			try {
				let deployedAppId: string;

				const payload: AppDeploymentCreateRequest = {
					orgId,
					projId,
					envId: environmentId,
					name: deploymentName,
					appId,
					deploymentConfig: {
						artifact,
						template: {
							moduleRefId: `${template.provisioner}:${template.name}@${template.version}`,
							inputs: inputs as Record<string, unknown>
						},

						pipelineConfig: this.appDeployment?.deploymentConfig?.pipelineConfig,
						configFiles: this.appDeployment?.deploymentConfig?.configFiles,
						summary: this.appDeployment?.deploymentConfig?.summary,

						...getEnvMapFromVariables(variables)
					}
				};

				// Editing an existing deployment
				if (this.appDeployment?.id) {
					deployedAppId = this.appDeployment.id;

					await applicationDeploymentStore.UPDATE_DEPLOYMENT_VARIABLES({
						deployment: this.appDeployment,
						variables,
						fetchDeployment: false
					});

					await applicationDeploymentStore.UPDATE_APPLICATION_DEPLOYMENT({
						id: deployedAppId,
						replaceVariables: false,
						...payload
					});
				} else {
					const response = await applicationDeploymentStore.CREATE_APPLICATION_DEPLOYMENT(payload);

					deployedAppId = response.id;
				}

				if (shouldClosePopever) {
					this.isSubmitting = false;
					this.closePopover();
				}

				return { environmentId, deployedAppId };
			} catch (err) {
				this.submitError = getErrorMessage(err, true);
			}

			this.isSubmitting = false;
			return null;
		},

		async saveAndDeploy() {
			try {
				const response = await this.saveDeployment(false);

				if (response) {
					const { environmentId, deployedAppId } = response;

					await applicationDeploymentStore.START_APP_PIPELINE({
						orgId: this.orgId,
						projectId: this.app.projId,
						envId: environmentId,
						depId: deployedAppId,
						jobType: DeploymentJobAction.deploy
					});

					this.closePopover();
				}
			} catch (err) {
				this.submitError = getErrorMessage(err);
			} finally {
				this.isSubmitting = false;
			}
		},

		closePopover() {
			this.$emit("close");
		},

		goBack() {
			if (this.step === 2) {
				this.step = 1;
			} else {
				this.$emit("back");
			}
		}
	}
});
</script>
