import {
	actionType,
	artifactRequest,
	envPipelineConfigSchemaResp,
	infraService,
	jobLongResponse,
	jobs,
	success
} from "@/protocol/infra";
import ApiService, { INFRA_API } from "@/services/api-service";

export const startPipeline: infraService["start"] = async function (request) {
	const { envId, orgId, projId } = request;

	const response = await ApiService.post<jobLongResponse>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/jobs`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listEnvPipelineJobs = function ({
	envId,
	orgId,
	projId,
	limit,
	action = actionType.no_action_type_support
}: {
	envId: string;
	orgId: string;
	projId: string;
	action?: actionType;
	limit: number;
}) {
	return listEnvPipelineJobsSrv({
		envId,
		orgId,
		projId,
		limit: String(limit),
		action
	});
};

export const listEnvPipelineJobsSrv: infraService["list"] = async function ({
	envId,
	orgId,
	projId,
	limit,
	action
}) {
	let path = `${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/jobs`;

	if (limit) {
		path = `${path}?limit=${limit}`;
	}

	if (action !== actionType.no_action_type_support) {
		path = `${path}&action=${action}`;
	}

	const response = await ApiService.get<jobs>(path);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getAJob: infraService["status"] = async function ({ id, orgId, projId, envId }) {
	const response = await ApiService.get<jobLongResponse>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/jobs/${id}`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const fetchArtifact = async function ({ job, artifactName }: artifactRequest) {
	const response = await ApiService.get(
		`${INFRA_API}/organizations/${job?.orgId}/projects/${job?.projId}/envs/${job?.envId}/jobs/${job?.id}/artifacts/${artifactName}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const pipelineConfigSchemaGet: infraService["pipelineConfigSchemaGet"] = async function (
	request
) {
	const response = await ApiService.get<envPipelineConfigSchemaResp>(
		`${INFRA_API}/organizations/${request.orgId}/projects/${
			request.projId
		}/env-pipeline-config-schema${request.envId ? `?envId=${request.envId}` : ""}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const cancelEnvJob: infraService["cancel"] = async function (request) {
	const response = await ApiService.post<success>(
		`${INFRA_API}/organizations/${request.orgId}/projects/${request.projId}/envs/${request.envId}/jobs/${request.id}/actions/cancel`,
		{}
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
