<template>
	<PopOver
		:style="{ height: 'auto' }"
		:open="!!modalState"
		@overlay-click="validateAndCloseWebhookModal"
	>
		<f-button
			:label="webhooksList.length === 0 ? 'Add webhooks' : 'Manage webhooks'"
			icon-left="i-webhook"
			size="x-small"
			category="outline"
			:disabled="!integration || !artifact || artifact.type !== ArtifactType.GitCode"
			data-qa-add-webook-app-int
			:counter="webhooksList.length === 0 ? undefined : String(webhooksList.length)"
			:tooltip="integration ? undefined : 'Please save the pipeline before adding webhooks'"
			@click="modalState = webhooksList.length === 0 ? 'create-webhook' : 'manage-webhook'"
		></f-button>

		<template #content>
			<AppIntegrationWebhookModal
				v-if="modalState === 'create-webhook' || editingWebhook"
				ref="AppIntegrationWebhookModal"
				:git-code="artifact.gitCode"
				:app-integration="integration!"
				:edited-hook="editingWebhook ?? undefined"
				:show-back-icon="true"
				@back="handleBackAction"
				@close="handleCloseAction"
			/>

			<AppIntegrationDeleteWebhookModal
				v-else-if="deletingWebhook"
				:web-hook="deletingWebhook"
				@back="handleBackAction"
				@close="handleCloseAction"
			/>

			<Wrapper
				v-else
				border-radius="4px"
				background="gray-500"
				width="332px"
				overflow="visible"
				data-qa-manage-webhooks
			>
				<Header>
					<f-icon
						tooltip="Back"
						source="i-arrow-left"
						type="filled"
						size="small"
						data-qa-back
						@click.stop="modalState = null"
					/>
					<Typography type="h4" color="dark">Manage webhooks</Typography>

					<Container padding="0" :grow="1" align="right center">
						<f-icon-button
							icon="i-plus"
							size="x-small"
							tooltip="Create webhook"
							data-qa-create-webhook
							@click="modalState = 'create-webhook'"
						>
						</f-icon-button>
					</Container>
				</Header>

				<Container padding="0 12px 12px">
					<f-text size="small" state="secondary" data-qa-manage-webhooks-description
						>This pipeline runs when the following webhooks are triggered</f-text
					>
				</Container>

				<Container
					v-for="webhook in webhooksList"
					:key="webhook.id"
					:gap="10"
					:grow="1"
					align="left center"
					:data-qa-webhook-id="webhook.id"
				>
					<Icon size="x-small" type="filled" :name="webhookIcon" :effects="false" />

					<Typography type="p2" color="gray-100" data-qa-automation-webhook-text>{{
						getWebhookMessage(webhook)
					}}</Typography>

					<f-icon
						v-if="webhook.customTrigger"
						source="i-info-fill"
						state="subtle"
						:tooltip="webhook.customTrigger?.hookURL"
					></f-icon>

					<Container padding="0" :grow="1" align="right center" :shrink="0">
						<f-icon
							v-if="'customTrigger' in webhook && webhookIdCopied !== webhook.id"
							size="x-small"
							state="default"
							source="i-copy"
							tooltip="Copy webhook URL"
							clickable
							data-qa-copy-webhook-url
							@click="copyWebhookUrl(webhook)"
						></f-icon>

						<f-icon
							v-if="'customTrigger' in webhook && webhookIdCopied === webhook.id"
							size="x-small"
							state="primary"
							source="i-tick"
							tooltip="Copied"
							data-qa-webhook-copied-confirmation
						></f-icon>

						<f-icon
							v-if="!('customTrigger' in webhook)"
							source="i-edit"
							size="x-small"
							data-qa-automation-edit-btn
							tooltip="Edit webhook"
							clickable
							@click="editingWebhook = webhook"
						/>
						<f-icon
							size="x-small"
							type="filled"
							source="i-delete"
							tooltip="Delete webhook"
							clickable
							data-qa-automation-delete-btn
							@click="deletingWebhook = webhook"
						/>
					</Container>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { Container, Header, Icon, PopOver, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import { appIntegration } from "@/protocol/app";
import { Artifact, ArtifactType } from "@/protocol/common";
import { webhook } from "@/protocol/externalEvents";
import { getWebhookMessage } from "@/shared/constants";
import { webhookIcon } from "@/shared/icons";

import AppIntegrationDeleteWebhookModal from "./AppIntegrationDeleteWebhookModal.vue";
import AppIntegrationWebhookModal from "./AppIntegrationWebhookModal.vue";

export default defineComponent({
	name: "GitTriggerForm",

	components: {
		AppIntegrationDeleteWebhookModal,
		AppIntegrationWebhookModal,
		Container,
		Header,
		Icon,
		PopOver,
		Typography,
		Wrapper
	},

	props: {
		artifact: {
			type: Object as PropType<Artifact>,
			required: true
		},

		integration: {
			type: Object as PropType<appIntegration>
		}
	},

	data() {
		return {
			ArtifactType,
			webhookIcon,
			deletingWebhook: null as webhook | null,
			editingWebhook: null as webhook | null,
			webhookIdCopied: null as string | null,
			modalState: null as null | "manage-webhook" | "create-webhook" | "delete-webhook"
		};
	},

	computed: {
		webhooksList() {
			const { integration } = this;
			if (!integration) {
				return [];
			}

			return webHooksStore.webHooksList[integration.id] ?? [];
		}
	},

	watch: {
		integration: {
			immediate: true,

			async handler() {
				const { integration } = this;
				if (integration) {
					await webHooksStore.LIST_WEB_HOOKS({
						orgId: integration.orgId,
						projectId: integration.projId,
						entityId: integration.id
					});
				}
			}
		}
	},

	methods: {
		getWebhookMessage,

		async copyWebhookUrl(hook: webhook) {
			if (hook.customTrigger?.hookURL) {
				await navigator.clipboard.writeText(hook.customTrigger.hookURL);
				this.webhookIdCopied = hook.id!;
				await new Promise(resolve => setTimeout(resolve, 4000));
				this.webhookIdCopied = null;
			}
		},

		handleBackAction() {
			// We have no webhooks to list, so just close the modal
			if (this.webhooksList.length === 0) {
				this.modalState = null;
			} else {
				this.modalState = "manage-webhook";
				this.editingWebhook = null;
				this.deletingWebhook = null;
			}
		},

		handleCloseAction() {
			this.modalState = null;
			this.editingWebhook = null;
			this.deletingWebhook = null;
		},

		validateAndCloseWebhookModal() {
			const appIntWebhookRef = this.$refs.AppIntegrationWebhookModal as InstanceType<
				typeof AppIntegrationWebhookModal
			>;

			if (appIntWebhookRef) {
				appIntWebhookRef.validateAndCloseModal();
			}
		}
	}
});
</script>
