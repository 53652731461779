import { identityService, success } from "@/protocol/identity";
import { UserProfile, UserProfilesList } from "@/protocol/user";
import ApiService from "@/services/api-service";
import { IDENTITY_API } from "@/shared/constants";

import { UserMetadata } from "../user-store";

export const updateProfile: identityService["updateProfile"] = async function (data) {
	const response = await ApiService.put<success>(`${IDENTITY_API}/users/self/profile`, data);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const listUsers: identityService["listUsers"] = async function (data) {
	const uniqueIds = Array.from(new Set(data.ids));
	const idsQuery = `ids=${uniqueIds.join("&ids=")}`;
	const response = await ApiService.get<UserProfilesList>(`${IDENTITY_API}/users?${idsQuery}`);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export type UserProfileWithMeta = UserProfile & {
	metadata?: UserMetadata;
};

export const getUser = async function () {
	const response = await ApiService.get<UserProfileWithMeta>(`${IDENTITY_API}/users/self/profile`);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};
