export function getFeatureMap(): FeatureMapFull {
	return {
		ENABLE_NX_PLATFORM: {
			// Check for global flags set by build system, or default to false
			isEnabled: window.VUE_ENABLE_NX_PLATFORM === "true",
			description: "Enables features for the NX Platform",
			ticket: "VN-3176"
		},
		ENABLE_APP_WIZARD_FLOW: {
			isEnabled: false,
			description: "Enables the new app wizard flow",
			ticket: "VN-4193"
		}
	};
}

export type ClientFeatureKey = "ENABLE_NX_PLATFORM" | "ENABLE_APP_WIZARD_FLOW";

export type FeatureMapFull = Record<ClientFeatureKey, ClientFeatureConfig>;

export type ClientFeatureConfig = {
	isEnabled: boolean;
	description: string;
	ticket: string;
};
