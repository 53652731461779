export const codepipesFullIcon = `<svg id="a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 362 83">
<defs>
    <style>
        .c{fill:url(#b);}.d{fill:none;}.e{fill:#fff;}.f{fill:#7539d3;}.g{fill:#0698ff;}
    </style>
    <linearGradient id="b" x1="8.17" y1="65.5" x2="74.63" y2="17.21" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#2aafff">
        </stop>
        <stop offset=".12" stop-color="#31a4fd">
        </stop>
        <stop offset=".32" stop-color="#4688fa">
        </stop>
        <stop offset=".58" stop-color="#695bf4">
        </stop>
        <stop offset=".88" stop-color="#971eec">
        </stop>
        <stop offset="1" stop-color="#ab05e9">
        </stop>
    </linearGradient>
</defs>
<path class="d" d="M46.37,36.48l-.06-4.33c0,1.46,0,2.91,.06,4.33Z">
</path>
<path class="e" d="M46.3,31.4v.74c.01-.25,0-.49,.01-.74h-.01Z">
</path>
<path class="c" d="M21.31,75.33c-2.12-.57-4.21-1.47-6.27-2.75-7.25-4.51-10.62-13.37-8.32-21.56,1.93-6.89,7.69-12.21,14.6-13.91v10.5c-.83,.45-1.61,1.02-2.32,1.74-3.1,3.1-3.77,7.81-1.64,11.6,.94,1.67,2.33,2.99,3.96,3.87v10.52ZM61.33,7.36v10.4c3.21,1.66,5.39,4.94,5.41,8.69,0,3.78-2.16,7.13-5.41,8.77v10.38c8.73-2.02,15.26-9.55,15.51-18.55,.27-9.42-6.33-17.55-15.51-19.69Zm-9.82,10.53V7.42c-8.58,2.13-15.11,9.57-15.19,18.73-.09,9.63,.08,19.27,.13,28.91,0,.68,0,1.36-.05,2.03-.29,3.33-2.33,6.23-5.27,7.78v10.48c1.57-.41,3.13-1.01,4.69-1.82,7.04-3.67,10.59-9.66,10.73-17.44,.06-3.22-.03-6.45-.06-9.83l-.13-9.79c-.05-1.42-.07-2.87-.06-4.33v-.75h0c.03-2.08,.1-4.17,.25-6.19,.25-3.22,2.22-5.85,4.95-7.32">
</path>
<rect class="f" x="54.3" y="34.88" width="4.25" height="13.14" rx="1" ry="1" transform="translate(112.84 82.91) rotate(180)">
</rect>
<rect class="f" x="54.3" y="5.55" width="4.25" height="13.14" rx=".99" ry=".99" transform="translate(112.84 24.24) rotate(-180)">
</rect>
<rect class="g" x="24.1" y="34.95" width="4.25" height="13.18" rx="1.02" ry="1.02">
</rect>
<rect class="g" x="24.1" y="64.31" width="4.25" height="13.14" rx="1" ry="1">
</rect>
<g>
    <path class="e" d="M96.9,41.85v-.1c0-9.64,7.27-17.54,17.69-17.54,6.4,0,10.23,2.13,13.38,5.23l-4.75,5.48c-2.62-2.38-5.28-3.83-8.68-3.83-5.72,0-9.84,4.75-9.84,10.57v.1c0,5.82,4.02,10.66,9.84,10.66,3.88,0,6.25-1.55,8.92-3.97l4.75,4.8c-3.49,3.73-7.37,6.06-13.91,6.06-9.98,0-17.4-7.71-17.4-17.45Z">
    </path>
    <path class="e" d="M130.44,45.88v-.1c0-7.46,6.01-13.52,14.1-13.52s14.01,5.96,14.01,13.43v.1c0,7.46-6.01,13.52-14.1,13.52s-14.01-5.96-14.01-13.43Zm20.84,0v-.1c0-3.83-2.76-7.17-6.83-7.17s-6.74,3.25-6.74,7.08v.1c0,3.83,2.76,7.17,6.83,7.17s6.74-3.25,6.74-7.08Z">
    </path>
    <path class="e" d="M161.5,45.78v-.1c0-8.68,5.67-13.43,11.83-13.43,3.93,0,6.35,1.79,8.09,3.88v-12.8h7.37V58.72h-7.37v-3.73c-1.79,2.42-4.27,4.22-8.09,4.22-6.06,0-11.83-4.75-11.83-13.43Zm20.02,0v-.1c0-4.31-2.86-7.17-6.3-7.17s-6.35,2.81-6.35,7.17v.1c0,4.31,2.91,7.17,6.35,7.17s6.3-2.86,6.3-7.17Z">
    </path>
    <path class="e" d="M192.91,45.88v-.1c0-7.42,5.28-13.52,12.84-13.52,8.68,0,12.65,6.74,12.65,14.1,0,.58-.05,1.26-.1,1.94h-18.08c.73,3.34,3.05,5.09,6.35,5.09,2.47,0,4.26-.78,6.3-2.67l4.22,3.73c-2.42,3.01-5.91,4.85-10.61,4.85-7.8,0-13.57-5.48-13.57-13.43Zm18.32-2.18c-.44-3.3-2.38-5.53-5.48-5.53s-5.04,2.18-5.62,5.53h11.1Z">
    </path>
    <path class="e" d="M233.14,24.79h13.86c8.09,0,12.99,4.8,12.99,11.73v.1c0,7.85-6.11,11.92-13.72,11.92h-5.67v10.18h-7.46V24.79Zm13.38,17.11c3.73,0,5.91-2.23,5.91-5.14v-.1c0-3.34-2.33-5.14-6.06-5.14h-5.77v10.37h5.91Z">
    </path>
    <path class="e" d="M263.67,23.34h7.75v6.54h-7.75v-6.54Zm.19,9.4h7.37v25.98h-7.37v-25.98Z">
    </path>
    <path class="e" d="M276.95,32.74h7.37v3.73c1.79-2.42,4.26-4.22,8.09-4.22,6.06,0,11.83,4.75,11.83,13.43v.1c0,8.68-5.67,13.43-11.83,13.43-3.93,0-6.35-1.79-8.09-3.88v11.15h-7.37V32.74Zm19.92,13.04v-.1c0-4.31-2.91-7.17-6.35-7.17s-6.3,2.86-6.3,7.17v.1c0,4.31,2.86,7.17,6.3,7.17s6.35-2.81,6.35-7.17Z">
    </path>
    <path class="e" d="M307.19,45.88v-.1c0-7.42,5.28-13.52,12.84-13.52,8.68,0,12.65,6.74,12.65,14.1,0,.58-.05,1.26-.1,1.94h-18.08c.73,3.34,3.05,5.09,6.35,5.09,2.47,0,4.26-.78,6.3-2.67l4.22,3.73c-2.42,3.01-5.91,4.85-10.61,4.85-7.8,0-13.57-5.48-13.57-13.43Zm18.32-2.18c-.44-3.3-2.38-5.53-5.48-5.53s-5.04,2.18-5.62,5.53h11.1Z">
    </path>
    <path class="e" d="M334.92,55.28l3.15-4.85c2.81,2.04,5.77,3.1,8.19,3.1,2.13,0,3.1-.78,3.1-1.94v-.1c0-1.6-2.52-2.13-5.38-3-3.64-1.07-7.75-2.76-7.75-7.8v-.1c0-5.28,4.27-8.24,9.5-8.24,3.3,0,6.88,1.11,9.69,3.01l-2.81,5.09c-2.57-1.5-5.14-2.42-7.03-2.42s-2.71,.78-2.71,1.79v.1c0,1.45,2.47,2.13,5.28,3.1,3.64,1.21,7.85,2.96,7.85,7.71v.1c0,5.77-4.31,8.38-9.94,8.38-3.63,0-7.71-1.21-11.15-3.93Z">
    </path>
</g>
</svg>
`;

export const codepipesSmallIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4111 9.07953V9.2935C13.414 9.22122 13.4111 9.15182 13.414 9.07953H13.4111Z" fill="white" />
<path d="M6.18507 21.7822C5.57206 21.6174 4.96772 21.3571 4.37206 20.987C2.27567 19.6829 1.30121 17.121 1.96627 14.7528C2.52435 12.7605 4.18989 11.2222 6.18796 10.7306V13.7668C5.94796 13.8969 5.72242 14.0617 5.51712 14.2699C4.62073 15.1663 4.427 16.5282 5.0429 17.6241C5.31471 18.107 5.71663 18.4887 6.18796 18.7431V21.7851L6.18507 21.7822ZM17.7571 2.1282V5.13543C18.6853 5.61543 19.3157 6.56387 19.3215 7.6482C19.3215 8.74122 18.6969 9.70989 17.7571 10.1841V13.1856C20.2815 12.6015 22.1696 10.4241 22.2419 7.8217C22.32 5.09784 20.4116 2.747 17.7571 2.1282ZM14.9176 5.17302V2.14555C12.4366 2.76146 10.5484 4.91278 10.5253 7.56146C10.4993 10.346 10.5484 13.1335 10.5629 15.921C10.5629 16.1176 10.5629 16.3142 10.5484 16.508C10.4646 17.4709 9.87471 18.3094 9.02459 18.7576V21.788C9.47856 21.6694 9.92965 21.4959 10.3807 21.2617C12.4164 20.2005 13.4429 18.4684 13.4834 16.2188C13.5007 15.2877 13.4747 14.3537 13.466 13.3764L13.4284 10.5456C13.414 10.135 13.4082 9.71567 13.4111 9.2935V9.07664C13.4198 8.4723 13.44 7.87085 13.4834 7.28387C13.5557 6.35278 14.1253 5.5923 14.9147 5.16724" fill="url(#paint0_linear_1519_79)" />
<path d="M16.0135 13.8882H16.6641C16.8238 13.8882 16.9532 13.7587 16.9532 13.599V10.3778C16.9532 10.2181 16.8238 10.0887 16.6641 10.0887H16.0135C15.8538 10.0887 15.7243 10.2181 15.7243 10.3778V13.599C15.7243 13.7587 15.8538 13.8882 16.0135 13.8882Z" fill="#7539D3" />
<path d="M16.0106 5.40434H16.667C16.8251 5.40434 16.9532 5.27618 16.9532 5.11808V1.89109C16.9532 1.73299 16.8251 1.60482 16.667 1.60482H16.0106C15.8525 1.60482 15.7243 1.73299 15.7243 1.89109V5.11808C15.7243 5.27618 15.8525 5.40434 16.0106 5.40434Z" fill="#7539D3" />
<path d="M7.92287 10.106H7.28383C7.12094 10.106 6.98889 10.2381 6.98889 10.401V13.6222C6.98889 13.7851 7.12094 13.9171 7.28383 13.9171H7.92287C8.08576 13.9171 8.21781 13.7851 8.21781 13.6222V10.401C8.21781 10.2381 8.08576 10.106 7.92287 10.106Z" fill="#0698FF" />
<path d="M7.92865 18.5957H7.27805C7.11835 18.5957 6.98889 18.7251 6.98889 18.8848V22.106C6.98889 22.2657 7.11835 22.3952 7.27805 22.3952H7.92865C8.08835 22.3952 8.21781 22.2657 8.21781 22.106V18.8848C8.21781 18.7251 8.08835 18.5957 7.92865 18.5957Z" fill="#0698FF" />
<defs>
    <linearGradient id="paint0_linear_1519_79" x1="2.38266" y1="18.9398" x2="21.6029" y2="4.97639" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2AAFFF" />
        <stop offset="0.12" stop-color="#31A4FD" />
        <stop offset="0.32" stop-color="#4688FA" />
        <stop offset="0.58" stop-color="#695BF4" />
        <stop offset="0.88" stop-color="#971EEC" />
        <stop offset="1" stop-color="#AB05E9" />
    </linearGradient>
</defs>
</svg>
`;

export const webhookIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M11.2069 10.3309C10.2122 12.0031 9.25922 13.6223 8.28637 15.2294C8.03653 15.642 7.91287 15.9781 8.11247 16.5025C8.66353 17.9513 7.88616 19.3612 6.42516 19.7439C5.04731 20.1049 3.70491 19.1994 3.43153 17.7243C3.18928 16.4186 4.20253 15.1386 5.64216 14.9345C5.76272 14.9173 5.88591 14.9153 6.08859 14.9001L8.27841 11.2281C6.90112 9.85863 6.08137 8.25785 6.26278 6.2741C6.39103 4.87188 6.94247 3.66007 7.95028 2.66688C9.88059 0.764884 12.8255 0.456916 15.0983 1.91679C17.2812 3.3191 18.281 6.0506 17.4288 8.38845C16.7862 8.21426 16.1392 8.03857 15.4279 7.84563C15.6954 6.54579 15.4975 5.3786 14.6209 4.37867C14.0417 3.71848 13.2984 3.37245 12.4534 3.24495C10.7591 2.98901 9.09572 4.07745 8.60212 5.74038C8.04187 7.62757 8.88984 9.16929 11.2069 10.331V10.3309Z" fill="white" />
	<path d="M14.0476 8.35343C14.7484 9.58971 15.46 10.8446 16.1652 12.0881C19.7295 10.9853 22.417 12.9584 23.3811 15.0709C24.5457 17.6227 23.7495 20.645 21.4625 22.2193C19.115 23.8354 16.1462 23.5593 14.0664 21.4833C14.5964 21.0396 15.1291 20.5939 15.6983 20.1177C17.7525 21.4482 19.5493 21.3856 20.8831 19.8101C22.0206 18.466 21.9959 16.4619 20.8255 15.146C19.4747 13.6275 17.6654 13.5812 15.4784 15.0388C14.5712 13.4293 13.6483 11.8327 12.7697 10.2116C12.4735 9.66518 12.1465 9.34812 11.4788 9.23252C10.3638 9.03921 9.64401 8.08174 9.60079 7.00905C9.55832 5.94818 10.1834 4.98921 11.1602 4.61562C12.1279 4.2454 13.2635 4.54418 13.9144 5.36693C14.4464 6.03912 14.6154 6.79568 14.3354 7.62471C14.2576 7.8559 14.1569 8.07959 14.0476 8.35352V8.35343Z" fill="white" />
	<path d="M15.7226 18.4598H11.4316C11.0203 20.1514 10.132 21.5171 8.60131 22.3856C7.41134 23.0606 6.12884 23.2895 4.76384 23.0691C2.25059 22.6638 0.195587 20.4013 0.01465 17.8538C-0.1901 14.9678 1.79356 12.4025 4.43759 11.8263C4.62012 12.4892 4.80462 13.1585 4.98715 13.8198C2.56127 15.0575 1.72165 16.6169 2.40059 18.5669C2.99824 20.2829 4.69596 21.2235 6.53937 20.8598C8.42187 20.4883 9.37099 18.9238 9.25512 16.413C11.0397 16.413 12.8259 16.3945 14.6107 16.4221C15.3076 16.4331 15.8456 16.3608 16.3706 15.7464C17.235 14.7354 18.8261 14.8266 19.757 15.7814C20.7084 16.7573 20.6628 18.3274 19.656 19.2621C18.6847 20.164 17.1501 20.1158 16.2413 19.1439C16.0545 18.9437 15.9073 18.7059 15.7226 18.4598Z" fill="white" />
</svg>
`;
