<template>
	<Wrapper
		data-qa-modify-members-role
		border-radius="4px"
		background="gray-500"
		width="480px"
		max-height="80vh"
		overflow="visible"
	>
		<Header>
			<Typography type="h4" color="dark">Modify role</Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Icon size="x-small" type="filled" name="i-close" @click="$emit('close')" />
			</Container>
		</Header>

		<Slab
			v-for="role in roles"
			:key="role.label"
			size="large"
			:effects="true"
			@click="selectRole(role)"
		>
			<Container :gap="10" :padding="0">
				<Typography type="h4" color="dark" weight="medium" :data-qa-members-role="role.id">{{
					role.label
				}}</Typography>
				<Icon
					v-if="role.isAdmin"
					name="i-crown"
					state="warning"
					size="small"
					type="filled"
					:effects="false"
				/>
			</Container>
			<template v-if="selectedRole === role.id" #secondary-actions>
				<Icon data-qa-members-role-selected name="i-tick" color="success-300" size="small" />
			</template>
		</Slab>

		<Footer>
			<Button
				data-qa-modify-members-role-button
				:loading="isSubmitting"
				state="full"
				type="success"
				@click="modifyRole"
				>MODIFY ROLE</Button
			>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { membersStore } from "@/modules/members/members-store";
import { organization, project as projectProto } from "@/protocol/identity";
import { invite as inviteProto } from "@/protocol/user";

import { Member, MEMBER_ROLES_ARR } from "./member-types";

type Role = {
	id: string;
	label: string;
	isAdmin: boolean;
	selected?: boolean;
};
export default defineComponent({
	name: "ModifyRole",

	components: {
		Wrapper,
		Header,
		Footer,
		Typography,
		Container,
		Button,
		Icon,
		Slab
	},

	props: {
		org: {
			type: Object as PropType<organization>
		},

		project: {
			type: Object as PropType<projectProto>
		},

		members: {
			type: Array as PropType<Member[]>,
			required: true
		}
	},

	data: () => ({
		isSubmitting: false,
		selectedRole: null as string | null
	}),

	computed: {
		roles(): Role[] {
			return MEMBER_ROLES_ARR.filter(role => role.id !== "limited").map(role => ({
				id: role.id,
				label: role.name,
				selected: this.selectedRole === role.id,
				isAdmin: role.isAdmin
			}));
		},

		orgId() {
			return this.project?.orgId ?? this.org?.id ?? null;
		}
	},

	mounted() {
		const [firstMember] = this.members;

		if (firstMember?.role?.id) {
			this.selectedRole = firstMember.role.id;
		}
	},

	methods: {
		selectRole(role: Role) {
			this.selectedRole = role.id;
		},

		async modifyRole() {
			const { selectedRole } = this;

			if (!selectedRole) {
				return;
			}

			this.isSubmitting = true;

			// Grant access to members who joined
			const usersWithUpdatedPermissions = this.members
				.filter(member => member.hasJoined && member.id)
				.map(member => member.id) as string[];

			if (usersWithUpdatedPermissions.length > 0) {
				if (this.org) {
					await membersStore.GRANT_ORG_ACCESS({
						orgId: this.org.id,
						userIds: usersWithUpdatedPermissions,
						role: selectedRole
					});
				} else if (this.project) {
					await membersStore.GRANT_PROJECT_ACCESS({
						orgId: this.project.orgId,
						projId: this.project.id,
						userIds: usersWithUpdatedPermissions,
						role: selectedRole
					});
				}
			}

			// Update invited for members who didn't
			const usersWithUpdatedInvites = this.members.filter(member => !member.hasJoined);
			const role = usersWithUpdatedInvites[0]?.role?.id;

			if (this.orgId && role && usersWithUpdatedInvites.length > 0) {
				const invite: inviteProto = {
					role
				};

				if (this.org) {
					invite.org = { id: this.org.id };
				} else if (this.project) {
					invite.project = { id: this.project.id, orgId: this.project.orgId };
				}

				await membersStore.USER_INVITATION_ACTION({
					orgId: this.orgId,
					requests: usersWithUpdatedInvites.map(member => ({ email: member.email ?? "", invite })),
					actionType: "update"
				});

				await membersStore.FETCH_USERS({
					orgId: this.orgId,
					invite
				});
			}
			this.isSubmitting = false;

			this.$emit("close");
		}
	}
});
</script>
