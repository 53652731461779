import { RouteRecordRaw, Router } from "vue-router";

import HomePageWrapper from "@/modules/home/components/HomePageWrapper.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/org/:orgId/:tabName?",
		name: "home",
		component: HomePageWrapper,
		meta: {
			title: "Home",
			requiresAuth: true,
			reuse: true
		},
		props: true
	}
];

export function registerHomeRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
