<template>
	<EmptyMembers v-if="!isLoading && isEmpty && !hasFilters">
		<PopOver v-model:open="showInviteModal" placement="right-end">
			<Button
				data-qa-members-tab-invite-members
				state="outlined"
				@click="showInviteModal = !showInviteModal"
				>INVITE MEMBERS</Button
			>
			<template #content>
				<InviteMembers
					:project="project"
					:members="members"
					@close="showInviteModal = false"
					@invited="sortByInviteStatus"
				/>
			</template>
		</PopOver>
	</EmptyMembers>

	<Container
		v-else
		:data-qa-members-tab-content-loaded="!isLoading"
		direction="column"
		:padding="0"
		:gap="40"
		:grow="1"
		overflow="visible"
		class="height-100-per"
		align="left top"
	>
		<MembersTabHeader
			:members="mergedMembers"
			:project="project"
			:role-availability="roleAvailability"
			@filter-role="filterRole"
			@filter-member="filterMember"
			@invited="sortByInviteStatus"
		/>

		<Container v-if="!isLoading && isEmpty && hasFilters" :grow="1" align="center">
			<EmptyMembers :from-search="true" />
		</Container>

		<Container
			v-else
			padding="0"
			overflow="scroll"
			:grow="1"
			align="left top"
			class="flow-add-scrollbar"
		>
			<MembersTable
				ref="membersTable"
				:members="mergedMembers"
				:project="project"
				:loading="isLoading"
				:loading-activity="isLoadingActivity"
				@toggle-all="toggleAllMembers"
				@toggle-member="toggleMember"
			/>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Button, Container, PopOver } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { activityStore, getLastActivityForUser } from "@/modules/activity/activity-store";
import { getActivityDetails } from "@/modules/activity/activity-utils";
import EmptyMembers from "@/modules/members/components/EmptyMembers.vue";
import InviteMembers from "@/modules/members/components/InviteMembers.vue";
import { Member, MemberRole, MEMBER_ROLES } from "@/modules/members/components/member-types";
import MembersTable from "@/modules/members/components/MembersTable.vue";
import { membersStore } from "@/modules/members/members-store";
import { orgStore } from "@/modules/org/org-store";
import { userStore } from "@/modules/user/user-store";
import { project as projectProto } from "@/protocol/identity";
import { captureError, getRelativeTime } from "@/utils";

import MembersTabHeader from "./MembersTabHeader.vue";

export default defineComponent({
	name: "ProjectMembersTab",

	components: {
		EmptyMembers,
		MembersTabHeader,
		MembersTable,
		Container,
		PopOver,
		Button,
		InviteMembers
	},

	props: {
		project: {
			type: Object as PropType<projectProto>,
			required: true
		}
	},

	data: () => ({
		roleFilter: null as MemberRole | null,
		memberFilter: "",
		showInviteModal: false,
		selectedMembers: {} as Record<string, boolean>,
		isLoading: true,
		isLoadingActivity: true
	}),

	computed: {
		currentUserId() {
			return userStore.profile?.id;
		},

		highlightedMemberEmails() {
			return membersStore.lastInvitedBatch ?? [];
		},

		storeMembers() {
			return membersStore.members[this.project.id] ?? [];
		},

		members(): Member[] {
			return this.storeMembers.map((member): Member => {
				const role = MEMBER_ROLES[member.role as MemberRole];
				return {
					...member,
					hasJoined: true,
					role: { id: role.id, name: role.name },
					selected: member.id ? this.selectedMembers[member.id] : false,
					isNew: member.email ? this.highlightedMemberEmails.includes(member.email) : false,
					lastActivity: getActivityDetails(
						getLastActivityForUser({ orgId: this.project.orgId, userId: member.id ?? "" })
					),
					membershipStatus: {
						dateTime: -1,
						relativeTime: "Member"
					},
					isReadOnly: this.currentUserId === member.id
				};
			});
		},

		invitedUsers(): Member[] {
			const invitedMembers = membersStore.invitedUser[this.project.id] ?? [];

			return invitedMembers.map((member): Member => {
				const role = MEMBER_ROLES[member.role as MemberRole];

				return {
					id: member.email,
					email: member.email,
					hasJoined: false,
					role: { id: role.id, name: role.name },
					selected: member.email ? this.selectedMembers[member.email] : false,
					isNew: member.email ? this.highlightedMemberEmails.includes(member.email) : false,
					membershipStatus: {
						dateTime: Number(member.invitedOn) * 1000,
						relativeTime: member.invitedOn ? `Invited ${getRelativeTime(member.invitedOn)}` : ""
					},
					isReadOnly: false
				};
			});
		},

		mergedMembers() {
			return [...this.invitedUsers, ...this.members].filter(member => {
				const hasFilteredRole = this.roleFilter ? member.role?.id === this.roleFilter : true;
				const hasFilteredEmail =
					this.memberFilter.length > 0 ? member.email?.includes(this.memberFilter) : true;

				return hasFilteredRole && hasFilteredEmail;
			});
		},

		isEmpty() {
			return this.mergedMembers.length === 0;
		},

		roleAvailability() {
			const roleAvailability: Record<MemberRole, boolean> = {
				admin: false,
				editor: false,
				viewer: false,
				limited: false
			};

			[...this.invitedUsers, ...this.members].forEach(member => {
				if (member.role?.id) {
					roleAvailability[member.role.id] = true;
				}
			});

			return roleAvailability;
		},

		hasFilters() {
			return this.roleFilter !== null || this.memberFilter.length > 0;
		},

		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		}
	},

	watch: {
		highlightedMemberEmails: {
			deep: true,

			handler() {
				window.setTimeout(() => membersStore.SET_LAST_INVITED_BATCH({ users: null }), 8000);
			}
		}
	},

	mounted() {
		(async () => {
			try {
				this.isLoading = true;

				await membersStore.FETCH_USERS({
					orgId: this.project.orgId,
					invite: {
						project: {
							orgId: this.project.orgId,
							id: this.project.id
						}
					}
				});

				this.isLoading = false;

				if (this.storeMembers.length > 0 && this.isUserOrgAdmin) {
					this.isLoadingActivity = true;

					await activityStore.FETCH_USERS_ACTIVITIY({
						orgId: this.project.orgId,
						userIds: this.storeMembers.map(member => member.id ?? "")
					});
				}
			} catch (err) {
				captureError(err);
			} finally {
				this.isLoading = false;
				this.isLoadingActivity = false;
			}
		})();
	},

	methods: {
		toggleAllMembers(select: boolean) {
			this.mergedMembers
				.filter(member => !member.isReadOnly)
				.forEach(member => {
					if (member.id) {
						this.selectedMembers[member.id] = select;
					}
				});
		},

		toggleMember(member: Member) {
			if (member.id) {
				this.selectedMembers[member.id] = !this.selectedMembers[member.id];
			}
		},

		filterRole(role: MemberRole) {
			this.roleFilter = role;
		},

		filterMember(member: string) {
			this.memberFilter = member;
		},

		sortByInviteStatus() {
			(this.$refs.membersTable as InstanceType<typeof MembersTable> | undefined)?.toggleSort(
				"status",
				"desc"
			);
		}
	}
});
</script>
