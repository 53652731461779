<template>
	<Wrapper
		:width="isCardType ? '250px' : '50px'"
		height="50px"
		background="gray-500"
		border-radius="4px"
		:data-qa="`cred-card-modal-target-${credScope}`"
	>
		<Card
			v-if="isCardType"
			v-tooltip="{
				content: credCategoryDetail.tooltipTxt,
				container: 'div.flow-layout',
				placement: 'right'
			}"
			type="placeholder"
			:clickable="true"
			:data-qa="`no-assigned-cred-${credScope}-card-btn`"
			@click="$emit('onClick')"
		>
			<Container
				:padding="8"
				:gap="12"
				align="space-between center"
				class="height-100-per"
				:data-qa="`project-no-assigned-cred-${credScope}-card`"
			>
				<Container :padding="0" :gap="12" align="left center">
					<Icon
						:name="credCategoryDetail.icon"
						type="filled"
						state="light"
						size="small"
						:effects="false"
						:data-qa="`project-no-assigned-cred-${credScope}-card-icon`"
					/>
					<Shimmer v-if="isLoading" height="16px" width="120px" />
					<Typography
						v-else
						type="p2"
						color="dark"
						:data-qa="`project-no-assigned-cred-${credScope}-card-title`"
					>
						{{ credCategoryDetail.ctaTitle }}</Typography
					>
				</Container>
				<Button
					:data-qa="`project-no-assigned-cred-${credScope}-plus-btn`"
					type="primary"
					state="icon"
					size="x-small"
				>
					<Icon name="i-plus" type="filled" size="x-small" :effects="false" />
				</Button>
			</Container>
		</Card>

		<EntityCard
			v-if="isPictogramType"
			v-tooltip="{
				content: credCategoryDetail.tooltipTxt,
				container: 'div.flow-layout',
				placement: 'right'
			}"
			:icon="isLoading ? 'i-loader' : credCategoryDetail.icon"
			type="empty"
			:data-qa="`env-no-assigned-cred-${credScope}-card`"
			@click="$emit('onClick')"
		/>
	</Wrapper>
</template>

<script lang="ts">
import { Typography, Container, Card, Icon, Button, Wrapper, Shimmer } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import {
	SupportedCredScope,
	credCategoryDetail,
	entityKind
} from "@/modules/credentials/credential-types";
import EntityCard from "@/shared/components/code-pipes/EntityCard.vue";

export default defineComponent({
	name: "NoAssignedCredCard",
	components: { Typography, Container, Card, Icon, Button, Wrapper, EntityCard, Shimmer },

	props: {
		entityKind: {
			type: String as PropType<entityKind>,
			required: true
		},

		credScope: {
			type: String as PropType<SupportedCredScope>,
			required: true
		},

		credCategoryDetail: {
			type: Object as PropType<credCategoryDetail>,
			required: true
		},

		isLoading: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		isCardType() {
			return this.entityKind === "project";
		},

		isPictogramType() {
			return this.entityKind === "environment" || this.entityKind === "app";
		}
	}
});
</script>
