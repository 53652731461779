<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		:data-qa-edit-app-model="app.id"
	>
		<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
			<Header>
				<Container align="space-between center" :padding="0" :grow="1">
					<Container :gap="12" :padding="0">
						<Typography :data-qa-edit-app-model-header-txt="app.id" type="h4" color="dark">
							{{ app.name }} settings</Typography
						>
					</Container>
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-edit-app-model-close
						@click.stop="toggleAppEditModel"
					/>
				</Container>
			</Header>
			<Container
				padding="10px 16px 16px 16px"
				direction="column"
				overflow="auto"
				align="stretch top"
			>
				<f-form-builder
					data-qa-edit-app-model-form
					:field.prop="formFields"
					:values.prop="formValues"
					@input="handleInput"
					@state-change="formState = $event.detail"
				/>
			</Container>
			<Footer>
				<Container direction="column" :grow="1" :gap="0" :padding="0">
					<Container v-if="submitError">
						<p class="paragraph-2 fc-error">{{ submitError }}</p>
					</Container>
					<Container :padding="0">
						<Button
							state="full"
							:type="isSubmitting ? 'default' : 'success'"
							:disabled="isSubmitting"
							:loading="isSubmitting"
							data-qa-edit-app-btn
							@click="editApp"
						>
							Update
						</Button>
					</Container>
				</Container>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import { FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { app } from "@/protocol/app";
import { DeploymentJobAction } from "@/protocol/deployment";
import { MARKDOWN_HELPER_TOOLTIP } from "@/shared/constants";
import {
	MAX_ENTITY_NAME_LENGTH,
	applyEntityNameRules2
} from "@/shared/custom-validation-rules/entityNameRules";
import { JOB_STATUS } from "@/shared/pipeline-constants";
import { getErrorMessage } from "@/utils";

export default defineComponent({
	name: "AppEditModel",

	components: {
		Typography,
		Container,
		Wrapper,
		Button,
		Header,
		Footer,
		Icon
	},

	props: {
		app: {
			type: Object as PropType<app>,
			required: true
		},

		toggleAppEditModel: {
			type: Function as PropType<() => void>,
			required: true
		}
	},

	data() {
		return {
			formState: null as FormBuilderState | null,

			formValues: {
				name: this.app.name,
				description: this.app.description
			} as LocalFormValues,

			isSubmitting: false,
			submitError: ""
		};
	},

	computed: {
		isAppDeployed() {
			const hasSuccessfulAppDeployment = Object.values(
				applicationDeploymentStore.allDeployments
			).some(obj => {
				const jobType = obj.lastSuccessfulJob?.jobType;
				const jobStatus = obj.lastSuccessfulJob?.jobStatus;
				return (
					obj.appId === this.app.id &&
					(jobStatus === JOB_STATUS.DONE ||
						jobStatus === JOB_STATUS.ACTIVE ||
						jobStatus === JOB_STATUS.CANCELLING ||
						jobStatus === JOB_STATUS.WAITING ||
						jobStatus === JOB_STATUS.PENDING) &&
					jobType === DeploymentJobAction.deploy
				);
			});
			return hasSuccessfulAppDeployment;
		},

		formFields(): FormBuilderField {
			const charLength = this.formValues.name.length;
			const characterCount = MAX_ENTITY_NAME_LENGTH - charLength;

			return {
				type: "object",
				direction: "vertical",
				fields: {
					name: {
						type: "text",
						id: "appName",
						label: { title: "Application name" },
						placeholder: "Enter application name",
						disabled: this.isAppDeployed,
						validationRules: applyEntityNameRules2(
							this.projectAppNames,
							"This application name already exists."
						),

						helperText: this.isAppDeployed
							? html`<f-text
									size="small"
									state="danger"
									data-qa-error-for="name"
									data-qa-login-error
									>Application can’t be renamed if they have active deployments</f-text
							  >`
							: `${characterCount < 1 ? 0 : characterCount} / ${MAX_ENTITY_NAME_LENGTH}`
					},

					description: {
						id: "appDescription",
						type: "textarea",
						label: {
							title: "Application description",
							subTitle: "Optional",
							iconTooltip: MARKDOWN_HELPER_TOOLTIP
						},

						placeholder: "Enter application description"
					}
				}
			};
		},

		projectAppNames() {
			const projectApps = applicationStore.projectApps[this.app.projId];
			return (
				projectApps
					?.map(tempApp => tempApp.name)
					.filter(tempAppName => tempAppName !== this.app.name) ?? []
			);
		}
	},

	methods: {
		async editApp() {
			if (this.isSubmitting) {
				return;
			}

			this.submitError = "";
			this.isSubmitting = true;

			if (!this.formState?.isValid || !this.formValues.name) {
				this.isSubmitting = false;
				return;
			}

			try {
				await applicationStore.UPDATE_APPLICATION({
					id: this.app.id,
					orgId: this.app.orgId,
					projId: this.app.projId,
					name: this.formValues.name,
					description: this.formValues.description,
					artifacts: this.app.artifacts,
					replaceVariables: false
				});

				this.isSubmitting = false;
				this.toggleAppEditModel();
			} catch (error) {
				this.isSubmitting = false;
				this.submitError = getErrorMessage(error);
			}
		},

		handleInput(event: CustomEvent<LocalFormValues>) {
			this.formValues = {
				...event.detail
			};
		}
	}
});

type LocalFormValues = {
	name: string;
	description: string;
};
</script>
