<template>
	<Container class="align-items-center" direction="column" align="center" :grow="1">
		<template v-if="status === 'loading'">
			<Icon name="i-loader" animate />
			<Typography>Loading...</Typography>
			<Typography>{{ entityName }} will be available soon</Typography>
		</template>

		<template v-if="status === 'progress'">
			<Icon name="i-loader" animate />
			<Typography>Pipeline is in progress...</Typography>
			<Typography>{{ entityName }} will be available soon</Typography>
		</template>

		<template v-if="status === 'pipeline-failed'">
			<Icon name="i-alert" state="error" />
			<Typography weight="bold" color="error">Pipeline has failed</Typography>
			<Typography>{{ entityName }} will not be available</Typography>
		</template>

		<template v-if="status === 'download-failed'">
			<Icon name="i-alert" state="error" />
			<Typography weight="bold" color="error">{{ entityName }} could not be downloaded</Typography>
			<Button size="small" state="outlined" type="success" @click="downloadLogs">Retry</Button>
		</template>

		<template v-if="status === 'cancelled'">
			<Icon name="i-alert" state="error" />
			<Typography weight="bold" color="error">Pipeline was cancelled</Typography>
			<Typography>{{ entityName }} will not be available</Typography>
		</template>

		<template v-if="status === 'empty'">
			<Icon name="i-alert" state="warning" />
			<Typography weight="bold">{{ entityName }} {{ isPlural ? "are" : "is" }} empty</Typography>
		</template>
	</Container>
</template>
<script lang="ts">
import { Icon, Button, Container, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "PipelineLogStatus",

	components: {
		Icon,
		Button,
		Container,
		Typography
	},

	props: {
		status: {
			type: String as PropType<
				"loading" | "progress" | "cancelled" | "download-failed" | "pipeline-failed" | "empty"
			>
		},

		entityName: {
			type: String,
			required: true
		},

		isPlural: {
			type: Boolean,
			required: true
		}
	},

	methods: {
		downloadLogs() {
			this.$emit("downloadLogs");
		}
	}
});
</script>
