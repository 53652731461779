<template>
	<Container
		id="pipelineModuleLister"
		:style="{ width: '520px' }"
		:padding="0"
		:gap="0"
		align="left top"
		direction="column"
		:shrink="0"
		overflow="scroll"
		data-qa-pipeline-module-lister
	>
		<Container padding="0" :shrink="0" :gap="0" align="space-between center" direction="column">
			<Slab
				v-if="isEditWarningOpen"
				size="small"
				state="warning"
				state-type="solid"
				align-items="center"
				:disabled="false"
				:selected="false"
				data-qa-edit-integration-warning-header
			>
				<template #default>
					<Container :padding="0">
						<Icon name="i-info-fill" color="warning-200" size="small" />
						<Typography weight="regular" type="p1"
							>Any new changes will be applicable only from the next time this pipeline runs.
						</Typography>
					</Container>
				</template>
				<template #secondary-actions>
					<Icon
						name="i-close"
						size="x-small"
						data-qa-edit-integration-warning-header-close-icon
						@click="showEditWarning = false"
					/>
				</template>
			</Slab>
			<slot />
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Icon, Slab, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { appIntegration } from "@/protocol/app";

export default defineComponent({
	name: "PipelineModuleLister",

	components: {
		Typography,
		Container,
		Icon,
		Slab
	},

	props: {
		integration: {
			type: Object as PropType<appIntegration>
		}
	},

	data() {
		return {
			showEditWarning: true
		};
	},

	computed: {
		isEditWarningOpen() {
			return this.integration?.id && this.showEditWarning;
		}
	}
});
</script>
