<template>
	<Container
		align="left top"
		:gap="0"
		:grow="1"
		padding="0px"
		overflow="auto"
		data-qa-pipeline-module-details
	>
		<Container
			padding="20px 0px"
			direction="column"
			align="right top"
			:grow="1"
			:shrink="0"
			basis="45%"
		>
			<Container padding="0px 12px 0px 12px" align="left top">
				<Slab size="medium" type="transparent" data-qa-pipeline-module-details-header>
					<template #primary-action>
						<Icon type="filled" name="i-alarm" />
					</template>
					<Container :padding="0">
						<Typography
							type="h4"
							class="fw-bold upper-case"
							:data-qa-pipeline-module-display-name="module.displayName"
							>{{ module.displayName }}</Typography
						>
						<Tag :data-qa-pipeline-module-version="module.version">v{{ module.version }}</Tag>
					</Container>
					<Typography type="p1" color="dark" :data-qa-pipeline-module-author="module.author"
						>by {{ module.author }}</Typography
					>
					<template #secondary-actions>
						<Button data-qa-open-add-module-form-btn @click="openAddModuleForm">Add</Button>
					</template>
				</Slab>
			</Container>
			<Divider />
			<Container padding="0px 12px 0px 12px" align="left top">
				<Slab size="medium" type="transparent" data-qa-selected-module-desc>
					<Typography type="h6" class="fw-normal upper-case fc-light">Description</Typography>
					<p class="paragraph-1 fc-normal text-wrap">
						{{ module.description }}
					</p>
				</Slab>
			</Container>
			<Divider />
			<Container
				padding="10px 12px 0px 24px"
				align="left top"
				class="overflow-auto"
				item-size="minmax(200px,1fr) 50px"
				:gap="1"
				direction="column"
			>
				<Typography type="p3" color="gray-200" transform="uppercase">Inputs</Typography>
				<PipelineModuleInputList :inputs="module.inputs || {}" />
			</Container>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Button, Container, Divider, Icon, Slab, Tag, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { pipelineModuleWithRefId } from "@/shared/pipeline-constants";

import PipelineModuleInputList from "./PipelineModuleInputList.vue";

export default defineComponent({
	name: "PipelineModuleDetails",

	components: {
		Button,
		Container,
		Divider,
		Icon,
		Slab,
		PipelineModuleInputList,
		Tag,
		Typography
	},

	props: {
		module: {
			type: Object as PropType<pipelineModuleWithRefId>,
			required: true
		}
	},

	methods: {
		openAddModuleForm() {
			this.$emit("add-new-module", this.module);
		}
	}
});
</script>
