<template>
	<Table aria-label="service table" class="creds-table" border="none" data-qa-creds-table>
		<template #header>
			<TableRow>
				<TableCellHead width="350px" data-qa-cred-list-column-names>
					<Container :gap="10" :padding="0">
						<Icon
							v-tooltip="{
								content: isGroupCollapsed ? 'Expand' : 'Collapse',
								placement: 'bottom-start'
							}"
							:rotate="isGroupCollapsed ? 0 : 180"
							name="i-chevron-up"
							type="filled"
							size="x-small"
							state="light"
							:data-qa="`expand-collapse-group-${groupName}`"
							@click="isGroupCollapsed = !isGroupCollapsed"
						/>
						<Typography type="p2" family="logs" color="light" data-qa-cred-title>{{
							groupName
						}}</Typography>
						<Icon
							v-tooltip="{
								content: 'Sort by name',
								placement: 'bottom-start'
							}"
							:name="sortByField === 'name' && orderBy === 'desc' ? 'i-arrow-down' : 'i-arrow-up'"
							type="filled"
							size="x-small"
							:data-qa="`sort-by-name-group-${groupName}`"
							:state="sortByField === 'name' ? 'primary' : 'light'"
							@click="sort('name')"
						/>
					</Container>
				</TableCellHead>
				<TableCellHead width="250px">
					<Container v-if="columnConfig.showClassification" :gap="10" :padding="0">
						<Typography type="p2" color="light" data-qa="classification-column"
							>Classification</Typography
						>
						<FilterCredByClassificationMenu
							v-if="hasClassifications()"
							v-model:selected-classification-id="selectedClassificationId"
						/>
					</Container>
				</TableCellHead>
				<TableCellHead width="400px">
					<Container v-if="columnConfig.showAssignedTo" :gap="10" :padding="0">
						<Typography type="p2" family="logs" color="light" data-qa="cred-assigned-to-column"
							>Assigned to</Typography
						>
						<Icon
							v-tooltip="{
								content: 'Sort by assigned to',
								placement: 'bottom-start'
							}"
							:name="
								sortByField === 'assignedTo' && orderBy === 'desc' ? 'i-arrow-down' : 'i-arrow-up'
							"
							type="filled"
							size="x-small"
							:data-qa="`sort-by-assigned-to-${groupName}`"
							:state="sortByField === 'assignedTo' ? 'primary' : 'light'"
							@click="sort('assignedTo')"
						/>
					</Container>
				</TableCellHead>
				<TableCellHead width="200px">
					<Container v-if="columnConfig.showLastUpdated" :gap="10" :padding="0">
						<Typography type="p2" color="light" data-qa="last-updated-column"
							>Last updated</Typography
						>
						<Icon
							v-tooltip="{
								content: 'Sort by last updated',
								placement: 'bottom-start'
							}"
							:name="
								sortByField === 'updatedAt' && orderBy === 'desc' ? 'i-arrow-down' : 'i-arrow-up'
							"
							type="filled"
							:data-qa="`sort-by-last-updated-${groupName}`"
							size="x-small"
							:state="sortByField === 'updatedAt' ? 'primary' : 'light'"
							@click="sort('updatedAt')"
						/>
					</Container>
				</TableCellHead>
				<TableCellHead width="250px">
					<Typography v-if="columnConfig.showHost" type="p2" color="light">Host</Typography>
				</TableCellHead>
				<TableCellHead> </TableCellHead>
			</TableRow>
		</template>
		<template v-if="!isGroupCollapsed" #body>
			<TableRow v-for="cred in filteredCredentials" :id="cred.id" :key="cred.id">
				<TableCell width="350px">
					<Slab type="transparent">
						<template #primary-action>
							<Icon
								:name="getCredPlatformIcon(cred.type)"
								type="filled"
								:effects="false"
								:data-qa="`cred-row-icon-${cred.name}`"
							/>
						</template>
						<Container :gap="10" :padding="0">
							<Typography
								type="p2"
								family="logs"
								color="dark"
								:data-qa="`cred-row-name-${cred.name}`"
							>
								{{ cred.name }}
							</Typography>
							<Icon
								v-if="
									groupScope !== CredScope.no_support && cred.credScope && cred.credScope.length > 1
								"
								v-tooltip="{
									content: 'This credential supports multiple scopes',
									placement: 'bottom-start'
								}"
								name="i-link"
								size="x-small"
								:data-qa="`cred-row-multiple-scope-icon-${cred.name}`"
								:effects="false"
							/>
						</Container>

						<Typography
							type="h5"
							family="logs"
							weight="light"
							color="gray-300"
							:data-qa="`cred-row-display-id-${cred.name}`"
						>
							{{ cred.displayId }}
						</Typography>
					</Slab>
				</TableCell>
				<TableCell width="250px">
					<Container
						v-if="columnConfig.showClassification && hasClassifications()"
						:padding="0"
						:gap="0"
					>
						<ClassificationTag
							v-if="cred.classificationId"
							:data-qa-id="cred.name"
							:classification-id="cred.classificationId"
						/>
						<Typography
							v-else
							type="p2"
							weight="regular"
							color="gray-300"
							:data-qa="`cred-row-no-classification-${cred.name}`"
							>No Classification</Typography
						>
					</Container>
				</TableCell>
				<TableCell width="400px">
					<AssignedToEntityCounts :cred="cred" :scope="groupScope" />
				</TableCell>
				<TableCell width="200px">
					<CustomTimeStamp
						v-if="cred.updatedAt && columnConfig.showLastUpdated"
						:time="cred.updatedAt"
						:data-qa="`cred-row-updated-at-${cred.name}`"
					/>
				</TableCell>
				<TableCell width="250px">
					<Typography
						v-if="columnConfig.showHost"
						type="p2"
						family="logs"
						color="light"
						:data-qa="`cred-row-host-${cred.name}`"
						>{{ cred.dockergeneric?.host ?? "-" }}</Typography
					>
				</TableCell>
				<TableCell width="250px">
					<Container align="right" overflow="auto">
						<CommonEditCredentialModel
							v-if="cred.type !== localCredType.credsType_github"
							:cred="cred"
							:class="{ 'show-on-hover': showSettingOption !== cred.id }"
							@model-open="showSettingOption = $event"
						/>
						<EditGithubCredentialModal
							v-if="cred.type === localCredType.credsType_github"
							:cred="cred"
							:class="{ 'show-on-hover': showSettingOption !== cred.id }"
							@model-open="showSettingOption = $event"
						/>
						<AssignCredentialModal
							v-if="groupScope !== CredScope.no_support && project && isCredAssignedToProject(cred)"
							:entity="project"
							entity-kind="project"
							:cred-scope="groupScope"
							:unassign-credential="cred"
							:class="{ 'show-on-hover': showSettingOption !== cred.id }"
						>
							<template #assignCredBtn="{ showCredAssignModal }">
								<Icon
									v-tooltip="{
										content: 'unassign credential from project',
										placement: 'left'
									}"
									name="i-minus-2"
									size="small"
									type="filled"
									state="error"
									:data-qa="'cred-unassign-btn-' + cred.name"
									@click="showCredAssignModal"
								/>
							</template>
						</AssignCredentialModal>
						<DeleteCredentialModal
							:cred="cred"
							:class="{ 'show-on-hover': showSettingOption !== cred.id }"
							@model-open="showSettingOption = $event"
						/>
					</Container>
				</TableCell>
			</TableRow>
			<TableRow v-if="selectedClassificationId && filteredCredentials.length < 1" :hover="false">
				<TableCell colspan="6">
					<Container :padding="20" align="center" direction="column" overflow="auto">
						<EmptyState
							:icon="{
								name: 'i-filter',
								type: 'filled',
								state: 'default',
								size: 'small',
								color: 'gray-200'
							}"
							message="No credential found"
							subtitle="No credentials found matching the filter"
							shape="squircle"
						/>
					</Container>
				</TableCell>
			</TableRow>
		</template>
	</Table>
</template>
<script lang="ts">
import {
	Container,
	Icon,
	Typography,
	Slab,
	Table,
	TableCell,
	TableRow,
	EmptyState,
	TableCellHead
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import AssignCredentialModal from "@/modules/credentials/components/credential-assign/AssignCredentialModal.vue";
import ClassificationTag from "@/modules/credentials/components/credential-assign/ClassificationTag.vue";
import CommonEditCredentialModel from "@/modules/credentials/components/credential-form/CommonEditCredentialModel.vue";
import DeleteCredentialModal from "@/modules/credentials/components/credential-form/DeleteCredentialModal.vue";
import EditGithubCredentialModal from "@/modules/credentials/components/credential-form/EditGithubCredentialModel.vue";
import AssignedToEntityCounts from "@/modules/credentials/components/credential-list/AssignedToEntityCounts.vue";
import FilterCredByClassificationMenu from "@/modules/credentials/components/credential-list/FilterCredByClassificationMenu.vue";
import { CREDENTIAL_TYPE_ICONS } from "@/modules/credentials/constants";
import { credentialStore } from "@/modules/credentials/credential-store";
import { CredWithAssignedEntities } from "@/modules/credentials/store-types";
import { CredScope, credsType, project } from "@/protocol/identity";
import { CustomTimeStamp } from "@/shared/components";

export default defineComponent({
	name: "CredentialTable",

	components: {
		FilterCredByClassificationMenu,
		CommonEditCredentialModel,
		CustomTimeStamp,
		EditGithubCredentialModal,
		AssignedToEntityCounts,
		AssignCredentialModal,
		DeleteCredentialModal,
		ClassificationTag,
		TableCellHead,
		Typography,
		EmptyState,
		TableCell,
		Container,
		TableRow,
		Table,
		Slab,
		Icon
	},

	props: {
		credentials: {
			type: Array as PropType<CredWithAssignedEntities[]>,
			required: true
		},

		groupName: {
			type: String,
			required: true
		},

		columnConfig: {
			type: Object,
			required: true
		},

		groupScope: {
			type: String as PropType<CredScope>,
			required: true
		},

		project: {
			type: Object as PropType<project>
		}
	},

	data: () => ({
		CredScope,
		orderBy: "asc" as "asc" | "desc",
		sortByField: "name" as SortByField,
		localCredType: credsType,
		isGroupCollapsed: false,
		showSettingOption: null as string | null,
		selectedClassificationId: null as string | null
	}),

	computed: {
		sortedCredentials() {
			switch (this.sortByField) {
				case "name":
					return this.sortByName(this.orderBy);
				case "updatedAt":
					return this.sortByUpdatedAt(this.orderBy);
				case "assignedTo":
					return this.sortByAssignedTo(this.orderBy);
				default:
					return this.credentials;
			}
		},

		filteredCredentials() {
			if (this.selectedClassificationId !== null) {
				return this.filterByClassification;
			}
			return this.sortedCredentials;
		},

		filterByClassification() {
			return this.sortedCredentials.filter(
				cred => cred.classificationId === this.selectedClassificationId
			);
		}
	},

	methods: {
		sortByName(orderBy: string) {
			const creds = [...this.credentials].sort((credA, credB) =>
				credA.name.localeCompare(credB.name)
			);
			if (orderBy === "desc") {
				creds.reverse();
			}
			return creds;
		},

		sortByUpdatedAt(orderBy: string) {
			const creds = [...this.credentials].sort((credA, credB) => {
				const timeA = new Date(Number(credA.updatedAt)).getTime();
				const timeB = new Date(Number(credB.updatedAt)).getTime();
				return timeA - timeB;
			});
			if (orderBy === "desc") {
				creds.reverse();
			}
			return creds;
		},

		sortByAssignedTo(orderBy: string) {
			const creds = [...this.credentials].sort(
				(credA, credB) => credA.stats.assignedToCount - credB.stats.assignedToCount
			);
			if (orderBy === "desc") {
				creds.reverse();
			}
			return creds;
		},

		getCredPlatformIcon(type?: keyof typeof CREDENTIAL_TYPE_ICONS) {
			return type ? CREDENTIAL_TYPE_ICONS[type] : "";
		},

		hasClassifications() {
			return credentialStore.classificationList.length > 0;
		},

		sort(sortBy: SortByField) {
			this.sortByField = sortBy;
			this.orderBy = this.orderBy === "desc" ? "asc" : "desc";
		},

		isCredAssignedToProject(cred: CredWithAssignedEntities) {
			if (this.project) {
				return cred.stats.projects.find(projectId => projectId.id === this.project?.id);
			}

			return false;
		}
	}
});

type SortByField = "name" | "updatedAt" | "assignedTo";
</script>
<style lang="scss">
div.flow-table-component.creds-table table thead {
	position: relative;
}
</style>
