<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		class="overflow-visible"
		data-qa-int-pipeline-confirm-delete-details
	>
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			class="height-100-per"
			overflow="visible"
		>
			<Header>
				<Typography type="h4" color="dark" data-qa-confirm-pipeline-title>
					Confirm delete
				</Typography>
				<Icon
					size="x-small"
					type="filled"
					name="i-close"
					data-qa-confirm-pipeline-close-btn
					@click.stop="$emit('close')"
				/>
			</Header>
			<Container :padding="16" align="left top" direction="column" :gap="20" overflow="visible">
				<Container align="left top" :padding="0" direction="column" :gap="6">
					<Typography type="p1-para" color="dark" data-qa-confirm-pipeline-delete-desc>
						Are you sure you want to delete
						<strong :style="{ whiteSpace: 'pre-wrap' }">{{ integration.name }}</strong> ?
					</Typography>
				</Container>
				<Divider />
				<Container
					v-if="!isSubmitting"
					:padding="0"
					direction="column"
					overflow="auto"
					align="left top"
				>
					<Typography type="p1-para" color="dark">
						Please type in the integration pipeline name
					</Typography>
					<InputText
						v-model:value="enteredIntegrationName"
						placeholder="Enter integration name to confirm"
						data-qa-del-integration-delete-input-name
					>
					</InputText>
				</Container>
			</Container>
			<!-- Show error if any -->
			<Container v-if="showError" direction="column" padding="15px 15px 15px 15px" :gap="0">
				<Typography type="p2" color="error" data-qa-confirm-pipeline-delete-error-msg>
					{{ submitError }}
				</Typography>
			</Container>
			<Footer>
				<Button
					state="full"
					:loading="isSubmitting"
					:disabled="disableDeleteBtn"
					type="error"
					data-qa-confirm-delete-pipeline-submit-btn
					@click="confirm()"
					>delete</Button
				>
			</Footer>
		</Container>
	</Wrapper>
</template>
<script lang="ts">
import {
	Wrapper,
	Container,
	Divider,
	Typography,
	Footer,
	Button,
	Header,
	Icon,
	InputText
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { appIntegration } from "@/protocol/app";
import { getErrorMessage } from "@/utils";

import { applicationIntegrationStore } from "../application-integration-store";

export default defineComponent({
	name: "ConfirmDeleteIntegration",

	components: {
		Wrapper,
		Container,
		Divider,
		Typography,
		Button,
		Footer,
		Header,
		Icon,
		InputText
	},

	props: {
		integration: {
			type: Object as PropType<appIntegration>,
			required: true
		}
	},

	data: () => ({
		isSubmitting: false,
		showError: false,
		enteredIntegrationName: "",
		submitError: null as string | null
	}),

	computed: {
		disableDeleteBtn() {
			return (
				this.isSubmitting ||
				this.enteredIntegrationName === "" ||
				this.enteredIntegrationName !== this.integration.name
			);
		}
	},

	methods: {
		async confirm() {
			try {
				this.isSubmitting = true;
				await applicationIntegrationStore.DELETE_APP_INTEGRATION({
					id: this.integration.id,
					appId: this.integration.appId,
					orgId: this.integration.orgId,
					projId: this.integration.projId,
					force: true
				});

				await applicationIntegrationStore.FETCH_INTEGRATIONS({
					appId: this.integration.appId,
					orgId: this.integration.orgId,
					projId: this.integration.projId
				});
				this.$emit("close");
				this.showError = false;
			} catch (error) {
				this.showError = true;
				this.submitError = getErrorMessage(error, true);
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});
</script>
