<template>
	<table
		v-if="variableTable.rows.length > 0"
		:id="`table-${variableTable.id}`"
		class="flow-table fixed-layout border-bottom width-100-per"
		:class="{ 'margin-bt-32': isOpen }"
	>
		<thead class="flow-table-head">
			<tr class="flow-table-row element-light rounded-corners">
				<th
					:id="`app-add-variable-${variableTable.id}`"
					class="text-align-start v-align-middle width-400 sticky-horizontal sticky-vertical add-var-realtive-trigger"
					:style="{ zIndex: 3 }"
				>
					<Container padding="12px 12px 12px 16px" :gap="20">
						<Icon
							:rotate="isOpen ? 0 : 180"
							name="i-chevron-up"
							type="filled"
							size="small"
							state="light"
							@click="isOpen = !isOpen"
						/>
						<Container :gap="10" :padding="0">
							<Typography type="p1" overflow="ellipsis" color="light">{{
								variableTable.title
							}}</Typography>
							<Tag shape="rounded" size="small">{{ sortedVariables.length }}</Tag>
							<Icon
								:name="sorting === 'desc' ? 'i-arrow-down' : 'i-arrow-up'"
								type="filled"
								size="x-small"
								state="light"
								@click="toggleSorting()"
							/>
							<Button state="icon" size="x-small" class="show-on-hover">
								<Icon
									v-tooltip="{
										content: `Add new variable to deployments`,
										placement: 'right-start'
									}"
									name="i-plus"
									state="dark"
									size="xx-small"
									type="filled"
									:effects="false"
									@click="showAddVariableModalById(`#app-add-variable-${variableTable.id}`)"
								/>
							</Button>
						</Container>
					</Container>
				</th>

				<th
					v-for="(deployment, deploymentId) in filteredDeployments"
					:id="`app-${variableTable.id}-${deployment.name}`"
					:key="`env-${deployment.name}-${deploymentId}`"
					class="text-align-start v-align-middle sticky-vertical add-var-realtive-trigger"
				>
					<Container padding="12px 12px 12px 0px">
						<Pictogram
							shape="hexagon"
							size="m"
							state="avatar"
							:label="envMap[deployment.envId!]?.env.name"
						/>

						<Typography type="p2" color="light" transform="uppercase" overflow="ellipsis">{{
							`${envMap[deployment.envId!]?.env.name} &nbsp; / &nbsp; ${deployment.name}`
						}}</Typography>

						<Button state="icon" size="x-small" class="show-on-hover">
							<Icon
								v-tooltip="{
									content: `Add new variable to ${deployment.name}`,
									placement: 'top-start'
								}"
								name="i-plus"
								state="dark"
								size="xx-small"
								type="filled"
								:effects="false"
								@click="
									showAddVariableModalById(
										`#app-${variableTable.id}-${deployment.name}`,
										deployment.id
									)
								"
							/>
						</Button>
					</Container>
				</th>
			</tr>
		</thead>

		<tbody v-if="isOpen" class="flow-table-body">
			<tr
				v-for="variableRow in sortedVariables"
				:key="`${variableRow.key}-${variableTable.id}`"
				:data-qa-variables-list-row="variableRow.key"
				:class="{
					'flow-table-row': true,
					'highlight-on-hover': true
				}"
			>
				<td class="flow-table-cell cell-padding-12 sticky-horizontal">
					<Container :padding="0" :gap="16">
						<template v-if="!isSingleEnvironment">
							<Container v-if="variableRow.state === 'error'" padding="0px 0px 0px 14px">
								<f-icon
									source="i-close"
									size="x-small"
									state="warning"
									tooltip="Variable is not declared"
								/>
							</Container>
							<Container v-if="variableRow.state === 'equal'" padding="0px 0px 0px 12px">
								<f-icon source="i-equal" size="small" state="subtle" />
							</Container>
							<Container v-if="variableRow.state === 'secret'" padding="0px 0px 0px 12px">
								<f-icon source="i-lock" size="small" state="subtle" tooltip="Value is masked" />
							</Container>
							<Container v-if="variableRow.state === 'notequal'" padding="0px 0px 0px 12px">
								<f-icon source="i-not-equal" size="small" state="subtle" />
							</Container>
						</template>

						<Container v-else padding="0px 0px 0px 12px">&nbsp;</Container>

						<Typography
							type="p1"
							:color="!isSingleEnvironment && variableRow.state === 'error' ? 'warning' : 'dark'"
							family="logs"
							>{{ variableRow.key }}</Typography
						>
					</Container>
				</td>

				<VariableListColumn
					v-for="(column, colIdx) in filterColumns(variableRow.columns)"
					:key="`${colIdx}-${variableRow.key}-${variableTable.id}`"
					:show-values="showValues"
					:variable-column="column"
					:column-name="getColumnName(column) ?? variableRow.key"
				/>
			</tr>
		</tbody>
	</table>
</template>

<script lang="ts">
import { Button, Container, Icon, Pictogram, Tag, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationDeploymentStore } from "../application-deployment/application-deployment-store";

import { RenderedEnvironment, VariableAppTable, VariableColumn } from "./variable-list-types";
import VariableListColumn from "./VariableListColumn.vue";

export default defineComponent({
	name: "VariableListApplicationTable",

	components: {
		Button,
		Container,
		Icon,
		Pictogram,
		Tag,
		Typography,
		VariableListColumn
	},

	props: {
		variableTable: {
			type: Object as PropType<VariableAppTable>,
			required: true
		},

		filteredEnvironments: {
			type: Array as PropType<RenderedEnvironment[]>,
			required: true
		},

		showValues: {
			type: Boolean,
			required: true
		}
	},

	emits: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		"add-variable": (_target: string, _scopeIds?: string[]) => {
			return true;
		}
	},

	data: () => ({
		sorting: "asc" as Sorting,
		isOpen: false
	}),

	computed: {
		envMap() {
			return this.filteredEnvironments.reduce(
				(acc, env) => {
					acc[env.env.id] = env;
					return acc;
				},
				{} as Record<string, RenderedEnvironment>
			);
		},

		// Filter deployments based on environments
		filteredDeployments() {
			return Object.values(applicationDeploymentStore.allDeployments).filter(deployment => {
				return (
					this.filteredEnvironments.some(env => env.env.id === deployment.envId) &&
					deployment.appId === this.variableTable.id
				);
			});
		},

		isSingleEnvironment() {
			return this.filteredEnvironments.length === 1;
		},

		sortedVariables() {
			return this.variableTable.rows.slice().sort((listA, listB) => {
				if (this.sorting === "desc") {
					return listB.key.localeCompare(listA.key);
				}
				return listA.key.localeCompare(listB.key);
			});
		}
	},

	mounted() {
		// Application tables can be particularly slow to load and large, so we use idle callbacks to render them
		window.requestIdleCallback(
			() => {
				this.isOpen = true;
			},
			{ timeout: 5000 }
		);
	},

	methods: {
		getColumnName(column: VariableColumn) {
			const { editInfo } = column;
			if (editInfo?.type === "application-deployment" && editInfo.applicationDeploymentId) {
				return applicationDeploymentStore.allDeployments[editInfo.applicationDeploymentId]?.name;
			}
		},

		filterColumns(columns: VariableColumn[]) {
			return columns.filter(column => {
				const { editInfo } = column;

				if (editInfo?.type === "application-deployment") {
					return this.filteredEnvironments.some(env => env.env.id === editInfo.envId);
				}

				return false;
			});
		},

		showAddVariableModalById(eleId: string, envId?: string) {
			const ids = envId ? [envId] : this.filteredDeployments.map(deployment => deployment.id!);
			this.$emit("add-variable", eleId, ids);
		},

		toggleSorting() {
			this.sorting = this.sorting === "asc" ? "desc" : "asc";
		}
	}
});

type Sorting = "asc" | "desc";
</script>
