<template>
	<Pictogram
		v-tooltip="{
			content: projectName,
			container: 'div.flow-layout',
			placement: 'right-start'
		}"
		:label="getProjectInitial"
		state="avatar"
		:effects="true"
	>
	</Pictogram>
</template>

<script lang="ts">
import { Pictogram } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { getShortCode } from "@/utils";

export default defineComponent({
	name: "ProjectAvatar",

	components: {
		Pictogram
	},

	props: {
		projectName: {
			type: String,
			required: true
		}
	},

	computed: {
		getProjectInitial() {
			return getShortCode(this.projectName);
		}
	}
});
</script>
