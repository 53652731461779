<template>
	<div />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { orgStore } from "@/modules/org/org-store";
import { userStore } from "@/modules/user/user-store";
import { GithubOauthStorageService } from "@/services/storage-service";

export default defineComponent({
	name: "AppFirstFlowCredListener",

	data: () => ({
		isOauthComplete: false
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		user() {
			return userStore.profile;
		},

		organizations() {
			return orgStore.GET_ORGS;
		},

		userMetaData() {
			return this.user?.metadata;
		},

		showAppfirstFlow() {
			const { userMetaData, isOauthComplete, organizations } = this;

			const shouldShowAppFirstFlow = !userMetaData?.isAppFirstFlowShown && !isOauthComplete;
			const shouldShowCheckListFlow = !userMetaData?.checkListFlow;
			const shouldShowAppFirstFlowAfterOauth = userMetaData?.isAppFirstFlowShown && isOauthComplete;
			const shouldShowAppFirstFlowWithOneOrg =
				!userMetaData?.isAppFirstFlowShown && organizations.length <= 1;
			const shouldShowAppFirstFlowDefault = !userMetaData?.isAppFirstFlowShown;

			return (
				shouldShowAppFirstFlow ||
				shouldShowCheckListFlow ||
				// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
				shouldShowAppFirstFlowAfterOauth ||
				shouldShowAppFirstFlowWithOneOrg ||
				shouldShowAppFirstFlowDefault
			);
		}
	},

	watch: {
		"organizations.length": {
			immediate: false,

			handler() {
				this.listenForFirstFlowCred();
			}
		}
	},

	methods: {
		async listenForFirstFlowCred() {
			if (await orgStore.IS_USER_ORG_ADMIN({ orgId: this.orgId })) {
				// enable github modal observer
				this.githubModalObserver();

				/**
				 * Check if the user has ever accessed app first flow.
				 * If not then show the app first flow and set the flag to true
				 */
				if (this.showAppfirstFlow) {
					// set default checklist flags if not present
					await userStore.UPDATE_USER_META({
						isAppFirstFlowShown: true,
						checkListFlow: {
							orgFlow: false,
							credFlow: false,
							projFlow: false
						}
					});
				}
			}
		},

		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === "appFirstFlow") {
				// render github form
				this.isOauthComplete = true;
			}
		}
	}
});
</script>
