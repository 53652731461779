<template>
	<Container :padding="0" direction="column" :gap="0" data-qa-accordion-stage-view>
		<Accordion v-for="(node, idx) in nodes" :key="idx" :open="getAccordionState(node?.name)">
			<template #header>
				<Container
					:clickable="true"
					align="space-between center"
					padding="10px 16px"
					:grow="1"
					overflow="visible"
					:background="node.selected ? 'element-light' : 'dashboard-background'"
					:data-qa-accordion-node-toggle="node?.name"
					@click="toggleAccordion(node?.name)"
				>
					<Container :gap="12" :padding="8">
						<PipelineIconStatus :status="node.status" />
						<Typography
							weight="bold"
							type="h4"
							color="default"
							:data-qa-accordion-node-name="node?.name"
							>{{ node.name }}</Typography
						>
					</Container>
					<Icon
						v-if="idx !== nodes.length - 1"
						name="i-chevron-down"
						size="x-small"
						color="gray-300"
						:rotate="!getAccordionState(node?.name) ? 0 : 180"
					/>
				</Container>
			</template>
			<Container
				v-if="idx === 0 && pipelineModules && pipelineModules?.length > 0"
				:padding="0"
				overflow="auto"
				background="dashboard-background"
				:clickable="true"
				data-qa-acc-step-childrens
			>
				<Container
					padding="10px 16px 12px 10px"
					:gap="16"
					align="left"
					direction="column"
					overflow="visible"
				>
					<Table border="none" :hover="false">
						<template #body>
							<TableRow v-for="(module, i) in pipelineModules" :key="i" :hover="false">
								<Container padding="4px 16px" data-qa-acc-step-child>
									<Slab direction="column" type="transparent">
										<template #primary-action>
											<Icon
												size="small"
												type="filled"
												:name="module.icon"
												:data-qa-acc-step-child-icon="module.icon"
											/>
										</template>
										<Typography type="h4" :data-qa-acc-step-child-name="module.name">{{
											module.name
										}}</Typography>
										<Typography
											type="p2"
											color="gray-200"
											:data-qa-acc-step-child-desc="module.desc"
											>{{ module.desc }}</Typography
										>
									</Slab>
								</Container>
								<Divider />
							</TableRow>
						</template>
					</Table>
				</Container>
			</Container>
		</Accordion>
	</Container>
</template>
<script lang="ts">
import {
	Container,
	Divider,
	Icon,
	Slab,
	Typography,
	Accordion,
	Table,
	TableRow
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { PipelineModuleMeta } from "@/modules/application-integration/components/AddIntegrationPipelineWrapper.vue";
import { PipelineNode } from "@/shared/pipeline-constants";

import PipelineIconStatus from "./PipelineIconStatus.vue";

export default defineComponent({
	name: "AccordionStagesView",

	components: {
		Container,
		Typography,
		Icon,
		Divider,
		Slab,
		Accordion,
		Table,
		TableRow,
		PipelineIconStatus
	},

	props: {
		nodes: {
			type: Array as PropType<PipelineNode[]>,
			required: true
		},

		pipelineModules: {
			type: Array as PropType<PipelineModuleMeta[]>
		}
	},

	emits: ["select-stage"],

	data: () => ({
		stageGroups: {} as Record<string, boolean>
	}),

	methods: {
		getAccordionState(name: string | undefined) {
			if (!name) {
				return false;
			}
			return this.stageGroups[name];
		},

		toggleAccordion(name: string | undefined) {
			if (!name) {
				return;
			}
			this.stageGroups[name] = !this.stageGroups[name];
			this.$emit("select-stage", name);
		}
	}
});
</script>
