<template>
	<NotificationsWrapper :toast-count="toasts.length">
		<Notification
			v-for="toast in toasts"
			:key="toast.id"
			:toast="toast"
			@remove="removeToast(toast)"
		>
		</Notification>
	</NotificationsWrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Notification from "@/modules/notifications/components/Notification.vue";
import NotificationsWrapper from "@/modules/notifications/components/NotificationsWrapper.vue";
import { ToastItem } from "@/modules/notifications/notification-types";
import { notificationsStore } from "@/modules/notifications/notifications-store";

export default defineComponent({
	name: "ToastManager",

	components: {
		Notification,
		NotificationsWrapper
	},

	computed: {
		toasts() {
			return notificationsStore.toasts;
		}
	},

	methods: {
		async removeToast(toast: ToastItem) {
			// Toast has an internal timer of 500ms before destroying, we need to follow that
			// otherwise the element will be removed from DOM before it has a chance to finish animating.
			await new Promise(resolve => setTimeout(resolve, 500));

			notificationsStore.REMOVE_TOAST(toast);
		}
	}
});
</script>
