import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
	project as projectProto,
	ProjectCreate,
	projectUpdate,
	project_entityCounts
} from "@/protocol/identity";

import { orgStore } from "../org/org-store";
import { getStore } from "../store";

import {
	createProject,
	deleteProject,
	deleteProjectEntities,
	editProject,
	getProjectById,
	listProjects
} from "./project-service";

@Module({
	namespaced: true,
	dynamic: true,
	name: "project",
	store: getStore()
})
class ProjectStore extends VuexModule {
	projects: ProjectMap = {};

	detailedProjects: ProjectMap = {};
	isLoadingProjects: boolean = false;
	// Projects loaded using detailed=true flag
	projectCounts: Record<string, project_entityCounts> = {};

	currentProjectId: string | null = null;

	get currentProject() {
		return this.currentProjectId ? this.detailedProjects[this.currentProjectId] ?? null : null;
	}

	@Action
	CREATE_NEW_PROJECT(payload: ProjectCreate) {
		return createProject(payload);
	}

	@Action
	async EDIT_PROJECT(payload: projectUpdate) {
		await editProject(payload);
		await this.GET_PROJECT({ orgId: payload.orgId, projectId: payload.id });
	}

	@Action
	async DELETE_PROJECT({
		project: proj,
		force = false
	}: {
		project: projectProto;
		force: boolean;
	}) {
		if (force) {
			await deleteProjectEntities({ orgId: proj.orgId, id: proj.id });
		}

		await deleteProject({ orgId: proj.orgId, id: proj.id });

		this.REMOVE_PROJECT(proj.id);
	}

	@Mutation
	SET_PROJECTS({ projects }: { projects: ProjectMap }) {
		this.projects = projects;
	}

	@Mutation
	UPDATE_PROJECT({ project }: { project: projectProto }) {
		this.projects[project.id] = project;
	}

	@Mutation
	SET_DETAILED_PROJECT({ project }: { project: projectProto }) {
		this.detailedProjects[project.id] = project;
	}

	@Mutation
	SET_PROJECT_COUNTS({ projects }: { projects: projectProto[] }) {
		projects.forEach(project => {
			if (project.counts) {
				this.projectCounts[project.id] = project.counts;
			}
		});
	}

	@Mutation
	SET_CURRENT_PROJECT_ID(projectId: string | null) {
		this.currentProjectId = projectId ? projectId : null;
	}

	@Mutation
	RESET_PROJECTS() {
		this.currentProjectId = null;
		this.projects = {};
		this.projectCounts = {};
		this.detailedProjects = {};
	}

	@Mutation
	REMOVE_PROJECT(projectId: string) {
		if (this.currentProjectId === projectId) {
			this.currentProjectId = null;
		}

		delete this.projects[projectId];
		delete this.detailedProjects[projectId];
		delete this.projectCounts[projectId];
	}

	@Mutation
	TOGGLE_PROJECT_LOADER() {
		this.isLoadingProjects = !this.isLoadingProjects;
	}

	@Action
	async GET_PROJECTS({ orgId, detailed = false }: { orgId: string; detailed?: boolean }) {
		this.TOGGLE_PROJECT_LOADER();
		const { projs } = await listProjects({
			orgId,
			detailed
		});

		// List projects call can be extremely slow, by the time it loads the user could have switched orgs
		// So we recheck for current org once it comes back
		const firstProject = projs?.[0];
		if (firstProject && firstProject.orgId !== orgStore.activeOrgId) {
			this.TOGGLE_PROJECT_LOADER();
			return [];
		}

		if (firstProject) {
			const projectMap = projs.reduce<ProjectMap>((projects, project) => {
				projects[project.id] = project;
				return projects;
			}, {});

			this.SET_PROJECTS({ projects: projectMap });
			this.SET_PROJECT_COUNTS({ projects: projs });

			if (detailed) {
				projs.forEach(project => {
					this.SET_DETAILED_PROJECT({ project });
				});
			}
		}

		this.TOGGLE_PROJECT_LOADER();
		return projs;
	}

	@Action
	async GET_PROJECT({ orgId, projectId }: { orgId: string; projectId: string }) {
		const project = await getProjectById({
			orgId,
			id: projectId,
			detailed: true
		});

		this.UPDATE_PROJECT({ project });
		this.SET_DETAILED_PROJECT({ project });

		return project;
	}
}

export const projectStore = getModule(ProjectStore);

type ProjectMap = Record<string, projectProto>;
