<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		class="overflow-visible"
		data-qa-webhook-modal
	>
		<Header>
			<Icon
				v-if="showBackIcon"
				v-tooltip="'Back'"
				name="i-arrow-left"
				type="filled"
				size="small"
				data-qa-back
				@click.stop="$emit('back')"
			/>
			<Typography type="h4" color="dark">{{ isEditing ? "Edit" : "Add" }} webhook</Typography>
			<Container align="right center" :grow="1" :padding="0">
				<Icon
					v-tooltip="'Close'"
					size="x-small"
					type="filled"
					name="i-close"
					data-qa-close
					@click="validateAndCloseModal"
				/>
			</Container>
		</Header>
		<ClosePopoverConfirmationWarning
			ref="closeConfirmation"
			:initial-form-values="initialformValues"
			:form-values="formValues"
			@force-close="closeModal"
		/>

		<Container
			overflow="visible"
			:shrink="1"
			align="left top"
			:padding="16"
			:gap="16"
			direction="column"
		>
			<Accordion :open="openTerraformSource" class="width-100-per">
				<template #header>
					<Container align="space-between center" :padding="0" :shrink="0" :gap="4">
						<Typography type="p3" transform="uppercase" color="gray-200"
							>APPLICATION SOURCE</Typography
						>
						<Icon
							name="i-chevron-down"
							:rotate="openTerraformSource ? 180 : 0"
							type="filled"
							state="light"
							size="small"
							data-qa-toggle-terraform-source
							@click="openTerraformSource = !openTerraformSource"
						/>
					</Container>
				</template>

				<Table aria-label="service table" border="none">
					<template #body>
						<TableRow>
							<TableCell width="30%" vertical-align="top" padding="12px 12px 12px 0px">
								<Typography type="p2" color="gray-200">Repository URL</Typography>
							</TableCell>
							<TableCell vertical-align="top">
								<Typography type="p2" color="primary" :link="true" :data-qa-app-repo-url="gitRepo">
									<a class="fc-primary" rel="noopener" :href="gitRepo" target="_blank">{{
										gitRepo
									}}</a>
								</Typography>
							</TableCell>
						</TableRow>
					</template>
				</Table>
			</Accordion>

			<Divider />

			<Container align="stretch top" direction="column" :grow="1" :padding="0" overflow="visible">
				<template v-if="customTriggerUrl">
					<f-text variant="heading" size="small">Webhook URL</f-text>
					<f-div align="middle-left" state="primary" padding="small">
						<f-text variant="code" size="small" ellipsis>{{ customTriggerUrl }}</f-text>

						<f-icon
							v-if="!urlCopied"
							size="small"
							state="default"
							source="i-copy"
							tooltip="Copy webhook URL"
							clickable
							@click="copyUrl"
						></f-icon>

						<f-icon
							v-else
							size="small"
							state="primary"
							source="i-tick"
							tooltip="Copied"
							data-qa-webhook-copied-confirmation
						></f-icon>
					</f-div>
				</template>

				<f-form-builder
					v-else
					ref="webHookForm"
					data-qa-app-integration-webhook-form
					:field.prop="formFields"
					:values.prop="formValues"
					@input="onInput"
					@state-change="formState = $event.detail"
				/>
			</Container>

			<Slab v-if="!editedHook && formState?.isValid && !errorMessage" :no-padding="true">
				<template #primary-action>
					<Container padding="0 12px 0 0">💡</Container>
				</template>
				<Typography type="p2" color="warning-200" data-qa-webhook-help-text>{{
					helpString
				}}</Typography>
			</Slab>

			<template v-if="editedHook && editedHook.updatedAt">
				<Divider />
				<Table aria-label="service table" border="none">
					<template #body>
						<TableRow>
							<TableCell width="30%" vertical-align="top" padding="12px 12px 12px 0px">
								<Typography type="p2" color="gray-200">Last updated by</Typography>
							</TableCell>
							<TableCell vertical-align="top">
								<Typography type="p2" data-qa-webhook-created-by>
									<UserNameEmail :user-id="editedHook.updatedBy" />
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell width="30%" vertical-align="top" padding="12px 12px 12px 0px">
								<Typography type="p2" color="gray-200">Last updated</Typography>
							</TableCell>
							<TableCell vertical-align="top">
								<Typography type="p2" data-qa-webhook-updated-at>{{
									getRelativeTime(editedHook?.updatedAt)
								}}</Typography>
							</TableCell>
						</TableRow>
					</template>
				</Table>
			</template>
		</Container>

		<Footer v-if="!customTriggerUrl">
			<Container direction="column" padding="0" :grow="1">
				<Container v-if="errorMessage" padding="0 16px 8px 16px">
					<Icon name="i-alert" size="x-small" color="danger-200" />
					<Typography type="p2" color="error" data-qa-webhook-error>{{ errorMessage }}</Typography>
				</Container>
				<Button
					state="full"
					type="success"
					:loading="isSaving"
					:disabled="!formState?.isValid"
					data-qa-add-webhook
					@click="isEditing ? updateWebHook() : addWebHook()"
					>{{ isEditing ? "Update" : "Add" }} webhook</Button
				>
			</Container>
		</Footer>
	</Wrapper>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import {
	Accordion,
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	Slab,
	Table,
	TableCell,
	TableRow,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import { appIntegration } from "@/protocol/app";
import { GitRevision } from "@/protocol/common";
import { triggerEvent, triggerType, webhook, webhookRequest } from "@/protocol/externalEvents";
import { UserNameEmail } from "@/shared/components";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import {
	CUSTOM_WEBHOOK_OPTION,
	GIT_WEBHOOK_OPTION,
	TRIGGER_EVENT_TO_STRING,
	WEBHOOK_OPTIONS,
	WebhookOptionType,
	webhookOptionTemplate
} from "@/shared/constants";
import { captureError, getErrorMessage, getRelativeTime } from "@/utils";

export default defineComponent({
	name: "AppIntegrationWebhookModal",

	components: {
		ClosePopoverConfirmationWarning,
		Accordion,
		Button,
		Container,
		Divider,
		Footer,
		Header,
		Icon,
		Slab,
		Table,
		TableCell,
		TableRow,
		Typography,
		Wrapper,
		UserNameEmail
	},

	props: {
		appIntegration: {
			type: Object as PropType<appIntegration>,
			required: true
		},

		gitCode: {
			type: Object as PropType<GitRevision>
		},

		editedHook: {
			type: Object as PropType<webhook>
		},

		showBackIcon: Boolean
	},

	emits: ["close", "back"],

	data() {
		const gitAction = this.editedHook?.githubTrigger?.triggerEvent;
		const webhookOption =
			!this.editedHook || this.editedHook.githubTrigger?.identifier
				? GIT_WEBHOOK_OPTION
				: CUSTOM_WEBHOOK_OPTION;

		return {
			customTriggerUrl: null as string | null,
			urlCopied: false,
			formState: null as FormBuilderState | null,
			getRelativeTime,
			isSaving: false,
			openTerraformSource: false,
			errorMessage: null as null | string,
			formValues: {
				webhookOption,
				gitEvent: gitAction
					? { data: { id: gitAction }, title: TRIGGER_EVENT_TO_STRING[gitAction]() }
					: undefined,

				eventIdentifier: this.editedHook?.githubTrigger?.identifier
			} as FormValues,

			initialformValues: {} as FormValues
		};
	},

	computed: {
		gitRepo() {
			return this.gitCode?.repo ?? "";
		},

		isEditing() {
			return this.editedHook !== undefined;
		},

		helpString() {
			if (this.formValues.webhookOption.data.id === CUSTOM_WEBHOOK_OPTION.data.id) {
				return "This pipeline will be triggered on each POST message sent to the webhook URL.";
			}

			const gitAction = this.formValues.gitEvent?.data.id;
			const branchText = gitAction === triggerEvent.pushToTag ? "tag" : "branch";
			const triggerText = gitAction === triggerEvent.pullReq ? "pull request" : "push";

			return `This pipeline will be triggered on
					each ${triggerText} to the ${this.formValues.eventIdentifier} ${branchText}.`;
		},

		formFields(): FormBuilderField {
			const { webhookOption } = this.formValues;
			const { editedHook } = this;

			const triggerFields: FormBuilderField = {
				type: "object",
				direction: "vertical",
				fields: {
					webhookOption: {
						type: "select",
						label: {
							title: "Webhook type",
							iconTooltip: "The type of webhook you want to create."
						},

						qaId: "webhookOption",
						placeholder: "Select webhook type",
						options: WEBHOOK_OPTIONS,
						disabled: Boolean(),
						validationRules: [{ name: "required" }],
						optionTemplate: webhookOptionTemplate,

						// Only show this option when adding a webhook
						showWhen() {
							return !editedHook;
						}
					},

					gitEvent: {
						type: "select",
						label: {
							title: "Git event",
							iconTooltip: "A Git event on which you want to trigger the pipeline."
						},

						qaId: "gitEvent",
						placeholder: "Select git action",
						options: [
							{
								data: { id: triggerEvent.pullReq },
								title: TRIGGER_EVENT_TO_STRING[triggerEvent.pullReq]()
							},
							{
								data: { id: triggerEvent.pushToBranch },
								title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToBranch]()
							},
							{
								data: { id: triggerEvent.pushToTag },
								title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToTag]()
							}
						],

						validationRules: [{ name: "required" }],

						showWhen() {
							return webhookOption.data.id === GIT_WEBHOOK_OPTION.data.id;
						}
					},

					eventIdentifier: {
						type: "text",
						qaId: "eventIdentifier",
						label: {
							title: "Event identifier",
							iconTooltip:
								"Event identifier is based on the selected Git event. It can be a branch, tag or a base branch name."
						},

						placeholder: "Enter event identifier",
						validationRules: [{ name: "required" }],

						showWhen() {
							return webhookOption.data.id === GIT_WEBHOOK_OPTION.data.id;
						}
					}
				}
			};

			return triggerFields;
		}
	},

	mounted() {
		this.initialformValues = { ...this.formValues };
	},

	methods: {
		async copyUrl() {
			if (this.customTriggerUrl) {
				await navigator.clipboard.writeText(this.customTriggerUrl);
				this.urlCopied = true;
				await new Promise(resolve => setTimeout(resolve, 2000));
				this.urlCopied = false;
				this.customTriggerUrl = null;
				this.closeModal();
			}
		},

		async addWebHook() {
			this.errorMessage = null;
			(this.$refs.webHookForm as InstanceType<typeof FFormBuilder>).submit();

			const { eventIdentifier, gitEvent, webhookOption } = this.formValues;

			if (!this.formState?.isValid) {
				return;
			}

			try {
				this.isSaving = true;
				const appInt = this.appIntegration;

				const hasCustomTrigger = webhookOption.data.id === CUSTOM_WEBHOOK_OPTION.data.id;

				const payload: webhookRequest = {
					orgId: appInt.orgId,
					projectId: appInt.projId,
					entityId: appInt.id,
					triggerType: triggerType.appInt,
					appInt: {
						id: appInt.id,
						appId: appInt.appId,
						orgId: appInt.orgId,
						projId: appInt.projId
					}
				};

				if (hasCustomTrigger) {
					payload.customTrigger = {};
				} else if (eventIdentifier && gitEvent?.data.id) {
					payload.githubTrigger = {
						identifier: eventIdentifier,
						triggerEvent: gitEvent.data.id
					};
				}

				const createdHook = await webHooksStore.CREATE_WEB_HOOK(payload);

				notificationsStore.ADD_TOAST({
					qaId: "toast-env-hook-added",
					title: "Webhook added",
					text: `Webhook is added on ${appInt.name}. ${this.helpString}`,
					status: "success"
				});

				if (hasCustomTrigger && createdHook.customTrigger?.hookURL) {
					this.customTriggerUrl = createdHook.customTrigger.hookURL;
					this.isSaving = false;
				} else {
					this.closeModal();
				}
			} catch (err) {
				captureError(err);
				this.errorMessage = getErrorMessage(err, true);
			} finally {
				this.isSaving = false;
			}
		},

		async updateWebHook() {
			this.errorMessage = null;

			(this.$refs.webHookForm as InstanceType<typeof FFormBuilder>).submit();

			const webHookId = this.editedHook?.id;
			const appInt = this.appIntegration;

			const { eventIdentifier, gitEvent } = this.formValues;

			if (!eventIdentifier || !gitEvent || !webHookId || !this.formState?.isValid) {
				return;
			}

			try {
				this.isSaving = true;

				await webHooksStore.UPDATE_WEB_HOOK({
					orgId: appInt.orgId,
					projectId: appInt.projId,
					entityId: appInt.id,
					webhookId: webHookId,
					githubTrigger: {
						identifier: eventIdentifier,
						triggerEvent: gitEvent.data.id
					}
				});

				notificationsStore.ADD_TOAST({
					qaId: "toast-env-hook-updated",
					title: "Webhook updated",
					text: `Webhook is updated on ${appInt.name}. ${this.helpString}`,
					status: "success"
				});

				this.closeModal();
			} catch (err) {
				captureError(err);
				this.errorMessage = getErrorMessage(err);
			} finally {
				this.isSaving = false;
			}
		},

		onInput(event: CustomEvent<FormValues>) {
			this.formValues = event.detail;
		},

		closeModal() {
			this.$emit("close");
		},

		validateAndCloseModal() {
			if (this.customTriggerUrl) {
				return this.closeModal();
			}

			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				return this.closeModal();
			}
		}
	}
});

type FormValues = {
	webhookOption: WebhookOptionType;
	gitEvent?: { data: { id: triggerEvent } };
	eventIdentifier?: string;
};
</script>
