<template>
	<!--Default Policy card and Popover-->
	<PopOver
		v-if="policySets.length"
		:open="policyPopOver"
		placement="bottom-start"
		data-qa-project-policy-popover
		@overlay-click="toggleListPopover"
	>
		<!-- empty container is needed for popover traget-->
		<Container :padding="0" :gap="0" align="center bottom">
			<AssignedPolicySetCard
				v-if="selectedPolicy || envInheritedPolicy"
				:inherited-policy="envInheritedPolicy"
				:card-style="cardStyle"
				:selected-policy="selectedPolicy"
				@on-policy-click="toggleListPopover"
			/>
			<EmptyStatePolicyStateCard
				v-else
				:card-style="cardStyle"
				@on-policy-click="toggleListPopover"
			/>
		</Container>

		<template #content>
			<PolicySelectorModal
				v-if="isUserAdmin"
				data-qa-list-with-radio-btn-popover-modal
				:entity="entity"
				:entity-type="entityType"
				:policy-sets="policySets"
				:selected-policy="selectedPolicy"
				:selected-policy-set-id="selectedPolicySetId"
				:env-inherited-policy="envInheritedPolicy"
				@toggle-list-popover="toggleListPopover"
				@select-policy="selectPolicy"
			/>
		</template>
	</PopOver>
</template>
<script lang="ts">
import { PopOver, Container } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { OrgRole } from "@/modules/org/org-types";
import { policyListStore } from "@/modules/policy-list/policy-list-store";
import { projectStore } from "@/modules/project-list/project-store";
import { project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { PolicySet } from "@/protocol/validator";
import { ListWithRadioBtnPopoverList } from "@/shared/components/code-pipes/ListWithRadioBtnPopover.vue";

import { NULL_STR_POLICY_UUID } from "../constants";

import AssignedPolicySetCard from "./AssignedPolicySetCard.vue";
import EmptyStatePolicyStateCard from "./EmptyStatePolicyStateCard.vue";
import PolicySelectorModal from "./PolicySelectorModal.vue";

export default defineComponent({
	name: "PolicySelector",

	components: {
		EmptyStatePolicyStateCard,
		AssignedPolicySetCard,
		Container,
		PopOver,
		PolicySelectorModal
	},

	props: {
		entity: {
			type: Object as PropType<project | environment>,
			required: true
		},

		entityType: {
			type: String as PropType<"project" | "environment">,
			required: true
		},

		cardStyle: {
			type: String as PropType<"detailed" | "icon">,
			default: "detailed"
		}
	},

	data: () => ({
		policyPopOver: false,
		newPolicySetId: null as string | null,
		isLoading: false
	}),

	computed: {
		isUserAdmin() {
			return this.entity.role === OrgRole.ADMIN;
		},

		selectedPolicy(): PolicySet | null {
			return this.policySets.find(_policy => _policy.id === this.selectedPolicySetId) ?? null;
		},

		policySets(): ListWithRadioBtnPopoverList {
			return policyListStore.policySets.map(policySet => ({
				...policySet,
				qaId: policySet.name
			}));
		},

		selectedPolicySetId() {
			return this.newPolicySetId ?? this.entity.policySetId ?? "";
		},

		envProject() {
			if (this.entityType === "environment") {
				const env = this.entity as environment;
				return projectStore.projects[env.projId];
			}
			return null;
		},

		envInheritedPolicy() {
			if (this.entityType === "environment") {
				const envPolicyId = (this.entity as environment).policySetId ?? "";
				const projectPolicyId = this.envProject?.policySetId ?? "";
				const noPolicyExistOnEnv = envPolicyId === NULL_STR_POLICY_UUID;
				if (noPolicyExistOnEnv) {
					return policyListStore.policySets.find(_policy => _policy.id === projectPolicyId) ?? null;
				}
				return null;
			}
			return null;
		}
	},

	methods: {
		selectPolicy(policyId: string) {
			this.newPolicySetId = policyId;
		},

		toggleListPopover() {
			if (this.isUserAdmin) {
				this.policyPopOver = !this.policyPopOver;
				if (!this.policyPopOver) {
					this.newPolicySetId = null;
					this.isLoading = false;
				}
			}
		}
	}
});
</script>
