import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FormBuilderField, FormBuilderObjectField, html } from "@cldcvr/flow-form-builder";

import { ContainerRegistryProvider } from "@/protocol/common";
import { duplicateEntityNameRule2 } from "@/shared/custom-validation-rules/entityNameRules";

const containerProviderOptions: Array<
	FSelectOptionObject & { data: { id: ContainerRegistryProvider } }
> = [
	{
		data: { id: ContainerRegistryProvider.dockerhub_container_registry },
		title: "DockerHub"
	},
	{
		data: { id: ContainerRegistryProvider.google_container_registry },
		title: "Google container registry (GCR)"
	},
	{
		data: { id: ContainerRegistryProvider.elastic_container_registry },
		title: "Elastic container registry (ECR - private)"
	},
	{
		data: { id: ContainerRegistryProvider.public_elastic_container_registry },
		title: "Elastic container registry (ECR - public)"
	},
	{
		data: { id: ContainerRegistryProvider.azure_container_registry },
		title: "Azure container registry"
	},
	{
		data: { id: ContainerRegistryProvider.custom_registry },
		title: "Custom"
	}
];

const HASH_VERSION_TAG: FSelectOptionObject = {
	title: "Hash",
	data: { id: "[a-f0-9]+", details: "[a-f0-9]+" }
};

export const VERSION_TAGS: FSelectOptionObject[] = [
	HASH_VERSION_TAG,
	{
		title: `Two Digits`,
		data: { id: "\\d+\\.\\d+", details: "\\d+\\.\\d+" }
	},
	{
		title: "Semantic",
		data: { id: "v\\d+\\.\\d+\\.\\d+", details: "v\\d+\\.\\d+\\.\\d+" }
	},
	{
		title: "Semantic without V",
		data: { id: "\\d+\\.\\d+\\.\\d+", details: "\\d+\\.\\d+\\.\\d+" }
	},
	{
		title: "Number Text",
		data: { id: "v?\\d+(\\.\\d){0,2}(\\-\\w+)*", details: "v?\\d+(\\.\\d){0,2}(\\-\\w+)*" }
	},
	{
		title: "UUID",
		data: { id: "[a-z0-9\\-]+", details: "[a-z0-9\\-]+" }
	}
];

function applicationCreateUpdateFormFields({
	suggestedRepoValue,
	containerRepoValue,
	existingContainerRepoNames,
	suggestedDisplayName,
	showDisplayName
}: ApplicationFormParams): FormBuilderObjectField {
	return {
		type: "object",
		direction: "vertical",
		fields: {
			containerProvider: {
				type: "select",
				id: "containerProvider",
				label: { title: "Container provider" },
				options: containerProviderOptions,
				validationRules: [{ name: "required" }],
				placeholder: "Select container provider"
			},
			containerRepo: {
				type: "text",
				id: "containerRepo",
				label: { title: "Container repository", iconTooltip: "#containerRepo_info_tootip" },
				placeholder: "Enter container repository",
				suffixWhen(value) {
					return value === suggestedRepoValue;
				},
				helperText:
					typeof containerRepoValue === "string" &&
					containerRepoValue.length > 0 &&
					containerRepoValue === suggestedRepoValue
						? `<f-text size="small" state="warning">Please ensure that you have created the repository with your container provider.</f-text>`
						: undefined,
				validationRules: [
					{
						name: "required"
					},
					duplicateEntityNameRule2(
						existingContainerRepoNames ?? [],
						"This container repository name already exists."
					)
				]
			},
			containerTag: {
				type: "text",
				id: "containerTag",
				label: { title: "Default container tag" },
				placeholder: "Enter default container tag",
				suffix: "suggested",
				suffixWhen(value) {
					return value === "latest";
				},
				validationRules: [
					{
						name: "required"
					}
				]
			},
			versionTagPattern: {
				type: "select",
				id: "versionTagPattern",
				options: VERSION_TAGS,
				label: { title: "Version tag pattern", subTitle: "Optional" },

				//@ts-expect-error
				optionTemplate: (option: FSelectOptionObject) =>
					html` <f-div direction="column" gap="x-small">
						<f-text>${option.title}</f-text>
						<f-text state="secondary" size="small">${option.data?.details}</f-text>
					</f-div>`,

				placeholder: "Select version tag pattern",
				validationRules: [
					{
						name: "required"
					}
				]
			},
			appContainerImageName: {
				id: "appContainerImageName",
				type: "text",
				label: { title: "Display name" },
				placeholder: "Enter display name",
				suffix: "suggested",
				suffixWhen(value) {
					return value === suggestedDisplayName;
				},
				showWhen() {
					return Boolean(showDisplayName);
				},
				helperText: "Name your container image to easily identify it."
			}
		}
	};
}

function applicationHostnameField({ suggestion }: ApplicationHostnameParams): FormBuilderField {
	return {
		id: "appHostname",
		type: "text",
		label: { title: "Hostname" },
		placeholder: "Enter hostname",
		suffix: "suggested",
		suffixWhen(inputVal) {
			return inputVal === suggestion;
		},
		validationRules: [{ name: "required" }]
	};
}

type ApplicationFormParams = {
	containerRepoValue?: string;
	suggestedRepoValue?: string;
	suggestedDisplayName?: string;
	existingContainerRepoNames?: string[];
	showDisplayName?: boolean;
};

type ApplicationHostnameParams = {
	suggestion?: string;
};

function containerProviderMap(provider: ContainerRegistryProvider): string {
	let providerMap = "";
	switch (provider) {
		case ContainerRegistryProvider.google_container_registry:
			providerMap = "gcr";
			break;
		case ContainerRegistryProvider.dockerhub_container_registry:
			providerMap = "dcr";
			break;
		case ContainerRegistryProvider.azure_container_registry:
			providerMap = "acr";
			break;
		case ContainerRegistryProvider.elastic_container_registry:
		case ContainerRegistryProvider.public_elastic_container_registry:
			providerMap = "ecr";
			break;
		case ContainerRegistryProvider.custom_registry:
			providerMap = "custom";
			break;
	}
	return providerMap;
}

export {
	applicationCreateUpdateFormFields,
	applicationHostnameField,
	containerProviderOptions,
	containerProviderMap
};
