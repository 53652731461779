<template>
	<Container :padding="24" overflow="visible" align="space-between top">
		<Container :padding="0" overflow="visible">
			<Container padding="0px 12px 0px 0px" direction="column" :gap="6" overflow="visible">
				<Container :padding="0">
					<Typography
						type="p2"
						weight="medium"
						color="dark"
						overflow="nowrap"
						data-qa="env-set-slideout-env-temp"
						>Environment templates</Typography
					>
				</Container>
				<Container :padding="0" overflow="visible">
					<Wrapper width="300px" class="overflow-visible">
						<Shimmer v-if="!templates && isLoadingTemplates" height="20px" />
						<Multiselect
							v-if="templates && !isLoadingTemplates"
							v-model="selectedTemplate"
							data-qa="env-set-slideout-env-temp-select"
							:options="templates"
							:searchable="true"
							:close-on-select="true"
							:show-labels="false"
							placeholder="Environment Templates"
							track-by="id"
							:preselect-first="true"
							@select="onTemplateSelect"
						>
							<template #singleLabel="props">
								<Container padding="8px 8px 8px 0px" :gap="12">
									<Typography type="p2" data-qa="env-set-slideout-env-temp-selected-item">{{
										props.option.name
									}}</Typography>
									<Icon
										v-if="props.option.icon"
										:name="props.option.icon.name"
										type="filled"
										state="warning"
										size="small"
									/>
								</Container>
							</template>
							<template #option="props">
								<Container padding="8px 8px 8px 12px" :gap="12">
									<Icon
										v-if="props.option.icon"
										:name="props.option.icon.name"
										type="filled"
										state="warning"
										size="small"
									/>
									<Container padding="8px 8px 8px 12px" :gap="12">
										<Typography type="h4" data-qa="env-set-slideout-env-temp-selected-opt">{{
											props.option.name
										}}</Typography>
									</Container>
								</Container>
							</template>
						</Multiselect>
					</Wrapper>
				</Container>
			</Container>
			<Container :padding="0" :grow="1" align-self="stretch">
				<Divider direction="vertical" />
			</Container>
			<Container padding="0px 0px 0px 12px" direction="column" :gap="6">
				<Container :padding="0">
					<Typography type="h3" weight="bold" color="dark" data-qa="env-set-slideout-header-txt"
						>Create environments set</Typography
					>
				</Container>
				<Container :padding="0">
					<Typography type="p2" weight="light" data-qa="env-set-slideout-txt"
						>Based on the selected environment classes below, a code promotion pipeline is
						decided.</Typography
					>
				</Container>
			</Container>
		</Container>
		<Container :padding="0" :gap="12">
			<Button
				type="success"
				:loading="isCreatingEnvSet"
				:disabled="!templates.length"
				data-qa="env-set-slideout-create-env-btn"
				@click="$emit('onCreateEnvSet')"
				>CREATE SET</Button
			>
		</Container>
	</Container>
</template>

<script lang="ts">
import {
	Container,
	Multiselect,
	Icon,
	Typography,
	Divider,
	Button,
	Shimmer,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { template } from "@/protocol/infra";

export default defineComponent({
	name: "CreateEnvSetHeader",

	components: {
		Multiselect,
		Typography,
		Container,
		Divider,
		Shimmer,
		Wrapper,
		Button,
		Icon
	},

	props: {
		templates: {
			type: Array as PropType<template[]>,
			required: true
		},

		isLoadingTemplates: {
			type: Boolean,
			required: true
		},

		isCreatingEnvSet: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		selectedTemplate: null as template | null
	}),

	methods: {
		onTemplateSelect(selectedOption: template) {
			this.selectedTemplate = selectedOption;
			this.$emit("onTemplateSelect", selectedOption);
		}
	}
});
</script>
