<template>
	<PopOver :open="open" placement="bottom-end" @overlay-click="validateAndClosePopover">
		<Button type="primary" data-qa-add-new-cred-model @click="open = true">{{ btnTxt }}</Button>
		<template #content>
			<Wrapper
				v-if="open"
				border-radius="4px"
				background="element-light"
				:width="showAddNewCredTypeForm ? '450px' : '260px'"
			>
				<Container
					v-if="!showAddNewCredTypeForm"
					:padding="0"
					:gap="0"
					direction="column"
					:grow="1"
				>
					<Header>
						<Typography type="h4" weight="medium" color="dark" data-qa-add-new-cred-model-title
							>Add new</Typography
						>
						<Icon
							name="i-close"
							type="filled"
							size="x-small"
							data-qa-add-new-cred-model-close-btn
							@click="closePopover"
						/>
					</Header>
					<Slab
						type="transparent"
						size="medium"
						:effects="true"
						data-qa-add-new-cred-model-gcp-btn
						@click="showAddNewCredTypeForm = localCredsType.credsType_gcp"
					>
						<template #primary-action>
							<Icon
								name="p-gcp"
								type="filled"
								:effects="false"
								data-qa-add-new-cred-model-gcp-icon
								size="small"
							/>
						</template>
						<Typography type="p1" color="dark" data-qa-add-new-cred-model-gcp-title
							>Google Cloud Platform</Typography
						>
					</Slab>
					<Slab
						type="transparent"
						size="medium"
						:effects="true"
						data-qa-add-new-cred-model-aws
						@click="showAddNewCredTypeForm = localCredsType.credsType_aws"
					>
						<template #primary-action>
							<Icon
								name="p-aws-dark"
								type="filled"
								:effects="false"
								size="small"
								data-qa-add-new-cred-model-aws-icon
							/>
						</template>
						<Typography type="p1" color="dark" data-qa-add-new-cred-model-aws-title
							>Amazon Web Services</Typography
						>
					</Slab>
					<Slab
						type="transparent"
						size="medium"
						:effects="true"
						data-qa-add-new-cred-model-azure
						@click="showAddNewCredTypeForm = localCredsType.credsType_azure"
					>
						<template #primary-action>
							<Icon
								name="p-azure"
								type="filled"
								:effects="false"
								size="small"
								data-qa-add-new-cred-model-azure-icon
							/>
						</template>
						<Typography type="p1" color="dark" data-qa-add-new-cred-model-azure-title
							>Azure</Typography
						>
					</Slab>
					<Slab
						type="transparent"
						size="medium"
						:effects="true"
						data-qa-add-new-cred-model-github
						@click="showAddNewCredTypeForm = localCredsType.credsType_github"
					>
						<template #primary-action>
							<Icon
								name="p-github"
								type="filled"
								:effects="false"
								size="small"
								data-qa-add-new-cred-model-github-icon
							/>
						</template>
						<Typography type="p1" color="dark" data-qa-add-new-cred-model-github-title
							>GitHub</Typography
						>
					</Slab>
					<Slab
						type="transparent"
						size="medium"
						:effects="true"
						data-qa-add-new-cred-model-git-generic
						@click="showAddNewCredTypeForm = localCredsType.credsType_gitgeneric"
					>
						<template #primary-action>
							<Icon
								name="i-git-branch"
								type="filled"
								:effects="false"
								size="small"
								data-qa-add-new-cred-model-git-generic-icon
							/>
						</template>
						<Typography type="p1" color="dark" data-qa-add-new-cred-model-git-generic-title
							>Git</Typography
						>
					</Slab>
					<Slab
						type="transparent"
						size="medium"
						:effects="true"
						data-qa-add-new-cred-model-gitlab
						@click="showAddNewCredTypeForm = localCredsType.credsType_gitlab"
					>
						<template #primary-action>
							<Icon
								name="p-gitlab"
								type="filled"
								:effects="false"
								size="small"
								data-qa-add-new-cred-model-gitlab-icon
							/>
						</template>
						<Typography type="p1" color="dark" data-qa-add-new-cred-model-gitlab-title
							>GitLab</Typography
						>
					</Slab>
					<Slab
						type="transparent"
						size="medium"
						:effects="true"
						data-qa-add-new-cred-model-dockergeneric
						@click="showAddNewCredTypeForm = localCredsType.credsType_dockergeneric"
					>
						<template #primary-action>
							<Icon
								name="p-docker"
								type="filled"
								:effects="false"
								size="small"
								data-qa-add-new-cred-model-dockergeneric-icon
							/>
						</template>
						<Typography type="p1" color="dark" data-qa-add-new-cred-model-dockergeneric-title
							>Docker</Typography
						>
					</Slab>
					<Slab
						type="transparent"
						size="medium"
						:effects="true"
						data-qa-add-new-cred-model-dockerhub
						@click="showAddNewCredTypeForm = localCredsType.credsType_dockerhub"
					>
						<template #primary-action>
							<Icon
								name="p-docker"
								type="filled"
								:effects="false"
								size="small"
								data-qa-add-new-cred-model-dockerhub-icon
							/>
						</template>
						<Typography type="p1" color="dark" data-qa-add-new-cred-model-dockerhub-title
							>Docker Hub</Typography
						>
					</Slab>
				</Container>
				<AwsCredentialModel
					v-if="showAddNewCredTypeForm === localCredsType.credsType_aws"
					:ref="localCredsType.credsType_aws"
					:show-back-btn="true"
					:update-cred-list="true"
					@back="showAddNewCredTypeForm = null"
					@close-modal="closePopover"
				/>
				<GcpCredentialModel
					v-if="showAddNewCredTypeForm === localCredsType.credsType_gcp"
					:ref="localCredsType.credsType_gcp"
					:show-back-btn="true"
					:update-cred-list="true"
					@back="showAddNewCredTypeForm = null"
					@close-modal="closePopover"
				/>
				<AzureCredentialModel
					v-if="showAddNewCredTypeForm === localCredsType.credsType_azure"
					:ref="localCredsType.credsType_azure"
					:show-back-btn="true"
					:update-cred-list="true"
					@back="showAddNewCredTypeForm = null"
					@close-modal="closePopover"
				/>
				<GithubCredentialModal
					v-if="showAddNewCredTypeForm === localCredsType.credsType_github"
					:ref="localCredsType.credsType_github"
					:show-back-btn="true"
					:update-cred-list="true"
					:git-form-ref="gitFormRef"
					@back="showAddNewCredTypeForm = null"
					@close-modal="closePopover"
				/>
				<DockerHubCredentialModal
					v-if="showAddNewCredTypeForm === localCredsType.credsType_dockerhub"
					:ref="localCredsType.credsType_dockerhub"
					:show-back-btn="true"
					:update-cred-list="true"
					@back="showAddNewCredTypeForm = null"
					@close-modal="closePopover"
				/>
				<DockerGenricCredentialModal
					v-if="showAddNewCredTypeForm === localCredsType.credsType_dockergeneric"
					:ref="localCredsType.credsType_dockergeneric"
					:show-back-btn="true"
					:update-cred-list="true"
					@back="showAddNewCredTypeForm = null"
					@close-modal="closePopover"
				/>
				<GitGenericCredentialModal
					v-if="showAddNewCredTypeForm === localCredsType.credsType_gitgeneric"
					:ref="localCredsType.credsType_gitgeneric"
					:show-back-btn="true"
					:update-cred-list="true"
					@back="showAddNewCredTypeForm = null"
					@close-modal="closePopover"
				/>
				<GitLabCredentialModal
					v-if="showAddNewCredTypeForm === localCredsType.credsType_gitlab"
					:ref="localCredsType.credsType_gitlab"
					:show-back-btn="true"
					:update-cred-list="true"
					@back="showAddNewCredTypeForm = null"
					@close-modal="closePopover"
				/>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Header,
	Icon,
	PopOver,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import AwsCredentialModel from "@/modules/credentials/components/credential-form/AwsCredentialModel.vue";
import AzureCredentialModel from "@/modules/credentials/components/credential-form/AzureCredentialModel.vue";
import DockerGenricCredentialModal from "@/modules/credentials/components/credential-form/DockerGenricCredentialModal.vue";
import DockerHubCredentialModal from "@/modules/credentials/components/credential-form/DockerHubCredentialModal.vue";
import GcpCredentialModel from "@/modules/credentials/components/credential-form/GcpCredentialModel.vue";
import GitGenericCredentialModal from "@/modules/credentials/components/credential-form/GitGenericCredentialModal.vue";
import GithubCredentialModal from "@/modules/credentials/components/credential-form/GithubCredentialModal.vue";
import GitLabCredentialModal from "@/modules/credentials/components/credential-form/GitLabCredentialModal.vue";
import { showAddNewCredTypeForm } from "@/modules/credentials/credential-types";
import { credsType } from "@/protocol/identity";
import { GithubOauthStorageService } from "@/services/storage-service";

export default defineComponent({
	name: "AddNewCred",

	components: {
		DockerGenricCredentialModal,
		GitGenericCredentialModal,
		DockerHubCredentialModal,
		GithubCredentialModal,
		GitLabCredentialModal,
		AzureCredentialModel,
		AwsCredentialModel,
		GcpCredentialModel,
		Typography,
		Container,
		PopOver,
		Wrapper,
		Header,
		Button,
		Icon,
		Slab
	},

	props: {
		btnTxt: {
			type: String,
			default: () => "+ ADD NEW"
		},

		gitFormRef: {
			type: String,
			required: true
		}
	},

	data: () => ({
		open: false,
		showAddNewCredTypeForm: null as showAddNewCredTypeForm,
		localCredsType: credsType,
		validateAndCloseModal: false
	}),

	mounted() {
		this.githubModalObserver();
	},

	methods: {
		closePopover() {
			this.open = false;
			this.showAddNewCredTypeForm = null;
			this.validateAndCloseModal = false;
		},

		validateAndClosePopover() {
			if (this.showAddNewCredTypeForm) {
				const ref = this.$refs[this.showAddNewCredTypeForm] as
					| InstanceType<typeof AwsCredentialModel>
					| InstanceType<typeof GcpCredentialModel>
					| InstanceType<typeof AzureCredentialModel>
					| InstanceType<typeof DockerGenricCredentialModal>
					| InstanceType<typeof DockerHubCredentialModal>
					| InstanceType<typeof GitGenericCredentialModal>
					| InstanceType<typeof GitLabCredentialModal>
					| InstanceType<typeof GithubCredentialModal>;

				if (ref) {
					ref.validateAndCloseModal();
				}
			} else {
				this.closePopover();
			}
		},

		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === this.gitFormRef) {
				this.open = true;
				this.showAddNewCredTypeForm = this.localCredsType.credsType_github;
			}
		}
	}
});
</script>
