import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { variable } from "@/protocol/common";
import { environment, environmentUpdate, EnvStateResponse } from "@/protocol/infra";

import { getStore } from "../store";

import {
	getEnvironmentById,
	getEnvOutputSummary,
	getOutputByEnvId,
	listEnvs,
	updateEnv
} from "./env-list-service";

export type IVariable = variable & {
	tag: string;
};

@Module({
	namespaced: true,
	dynamic: true,
	name: "envList",
	store: getStore()
})
class EnvList extends VuexModule {
	envs: Record<string, Array<environment>> = {};
	envOutputSummary: Record<string, EnvStateResponse> = {};

	get envList() {
		const envList: Array<environment> = [];

		Object.values(this.envs).forEach(envs => {
			envList.push(...envs);
		});

		return envList;
	}

	@Mutation
	SET_ENVS(payload: { envs: Array<environment>; projectId: string }) {
		const { envs, projectId } = payload;
		this.envs[projectId] = envs;
	}

	@Mutation
	RESET_ENVLIST() {
		this.envs = {};
		this.envOutputSummary = {};
	}

	@Mutation
	DELETE_ENV({ envId, projectId }: { envId: string; projectId: string }) {
		const filteredList = this.envs[projectId]?.filter(env => env.id !== envId);
		this.envs[projectId] = filteredList ?? [];
	}

	@Mutation
	PUSH_ENV_IN_STORE_ENVS({ env, projectId }: { env: environment; projectId: string }) {
		const currentEnvs = { ...this.envs };
		let foundEnvs = currentEnvs[projectId];

		if (!foundEnvs) {
			this.envs[projectId] = [env];
		} else {
			foundEnvs = foundEnvs.filter(e => e.id !== env.id);
			foundEnvs.push(env);
			this.envs[projectId] = foundEnvs;
		}
	}

	//Actions
	@Action
	async GET_ENVS({ orgId, projectId }: { orgId: string; projectId: string }) {
		const response = await listEnvs({ orgId, projId: projectId, detailed: true });

		if (response.envs) {
			this.SET_ENVS({ envs: response.envs, projectId });
		}
	}

	@Action
	async GET_ENV_BY_ID({
		orgId,
		projectId,
		envId
	}: {
		orgId: string;
		projectId: string;
		envId: string;
	}) {
		const env = await getEnvironmentById({ orgId, projId: projectId, id: envId });

		if (env.id) {
			this.PUSH_ENV_IN_STORE_ENVS({
				env,
				projectId
			});
		}

		return env;
	}

	@Action
	GET_OUTPUT_FOR_ENV({
		orgId,
		projectId,
		envId
	}: {
		orgId: string;
		projectId: string;
		envId: string;
	}) {
		return getOutputByEnvId({ orgId, projId: projectId, id: envId });
	}

	@Mutation
	SET_ENV_OUTPUT_SUMMARY({ summary, envId }: { summary: EnvStateResponse; envId: string }) {
		const envOutputSummaryCopy = { ...this.envOutputSummary };
		envOutputSummaryCopy[envId] = summary;
		this.envOutputSummary = { ...envOutputSummaryCopy };
	}

	@Action
	async GET_ENV_OUTPUT_SUMMARY({
		orgId,
		projectId,
		envId
	}: {
		orgId: string;
		projectId: string;
		envId: string;
	}) {
		const response = await getEnvOutputSummary({ orgId, projId: projectId, id: envId, filter: "" });
		this.SET_ENV_OUTPUT_SUMMARY({ summary: response, envId });
	}

	@Action
	async UPDATE_ENV(payload: environmentUpdate) {
		// First update the environment
		await updateEnv(payload);

		await this.GET_ENV_BY_ID({
			orgId: payload.orgId,
			projectId: payload.projId,
			envId: payload.id
		});
	}
}

const envListStore = getModule(EnvList);

export { envListStore };
