<template>
	<f-popover
		size="small"
		:overlay="showOverlay"
		:open="isOpen"
		:placement="placement"
		:target="'#' + target"
		height="hug-content"
		:data-qa-landing-popover="target"
		:align="align"
	>
		<f-div state="tertiary" padding="medium" width="fill-container" gap="none" direction="column">
			<f-div padding="none none large none">
				<f-text variant="heading" size="medium" :data-qa-info-title="title">{{ title }}</f-text>
			</f-div>
			<f-div padding="none none large none" gap="none" direction="column">
				<slot />
			</f-div>
			<f-div>
				<f-button
					:label="buttonLabel"
					variant="round"
					category="fill"
					size="medium"
					state="primary"
					align="bottom-left"
					data-qa-info-popover-close-btn
					icon-left="👍"
					@click.stop="$emit('close')"
				>
				</f-button>
			</f-div>
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { FPopoverPlacement } from "@cldcvr/flow-core";
import { PropType, defineComponent } from "vue";

export default defineComponent({
	name: "InfoPopover",

	props: {
		title: {
			type: String,
			required: true
		},

		buttonLabel: {
			type: String,
			default: "GOT IT"
		},

		showOverlay: {
			type: Boolean,
			default: false
		},

		isOpen: {
			type: Boolean,
			required: true
		},

		placement: {
			type: String as PropType<FPopoverPlacement>,
			default: "auto"
		},

		target: {
			type: String,
			default: "popoverTarget"
		},

		align: {
			type: String,
			default: "middle-center"
		}
	},

	emits: ["close"],

	mounted() {
		this.$forceUpdate();
	}
});
</script>
