<template>
	<InfoPopover
		v-if="showInfo"
		title="Project members"
		placement="right-start"
		target="members"
		:is-open="showInfo"
		:show-overlay="true"
		@close="closePopover"
	>
		<f-div>
			<f-text variant="para" size="medium">
				Add Developers, DevOps engineers or Project managers here to collaborate on deployment
				activities.
			</f-text>
		</f-div>
		<f-div padding="large none none none">
			<f-text>
				<Typography
					type="p2-para"
					color="primary"
					:link="true"
					data-qa-project-env-classification-info-popover-link
					@click="
						openHelp(
							'https://docs.codepipes.io/docs/credentials-cloud-account-and-code-source#azure'
						)
					"
				>
					Learn more about project members
				</Typography>
			</f-text>
		</f-div>
	</InfoPopover>
</template>

<script lang="ts">
import { Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { userStore } from "@/modules/user/user-store";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";

export default defineComponent({
	name: "ProjectMembersPopover",

	components: {
		InfoPopover,
		Typography
	},

	data: () => ({
		showInfo: false as boolean
	}),

	computed: {
		infoFlags() {
			return userStore.profile?.metadata?.infoFlags;
		}
	},

	mounted() {
		if (!this.infoFlags?.hasProjectApplicationPopover) {
			this.showInfo = true;
		}
	},

	methods: {
		openHelp(link: string) {
			window.open(link, "_blank");
		},

		closePopover() {
			this.showInfo = false;
			userStore.UPDATE_USER_META({
				infoFlags: {
					...this.infoFlags,
					hasProjectMembersPopover: true
				}
			});
		}
	}
});
</script>
