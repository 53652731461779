<template>
	<PipelineWrapper
		:show-history="showHistory"
		:is-loading="isLoadingPipeline"
		:side-bar-width="350"
	>
		<template #header>
			<PipelineHeader
				v-if="currentJob"
				:job-start="String(currentJob.createdAt)"
				:pipeline-name="pipelineName"
				:started-by="currentJob.updatedBy"
			/>
		</template>

		<template #stage-view>
			<AppDagView
				v-if="currentJob"
				id="app-dag-click"
				:job="currentJob"
				:current-step-index="currentStepIndex"
				@step-changed="stepChanged"
			/>
		</template>

		<template #logs-view>
			<AppLogsView
				v-if="currentJob"
				:current-job="currentJob"
				:current-step="currentStep ?? undefined"
				:project-id="projectId"
				:env-id="envId"
				:org-id="orgId"
				:dep-id="depId"
			>
				<template #pipeline-actions>
					<Button
						v-if="isRunningPipeline"
						data-qa-env-dag-cancel-pipeline
						type="error"
						state="curved"
						size="small"
						:disabled="isAttemptingCancellation || isCancellingJob"
						@click="cancelDeployment"
					>
						<Icon name="i-stop" color="gray-600" type="filled" size="x-small" />
						<Typography
							type="p2"
							color="gray-600"
							transform="uppercase"
							weight="bold"
							overflow="nowrap"
							>Cancel</Typography
						>
					</Button>

					<Button
						v-else
						:type="retryButtonType"
						state="curved"
						:disabled="isRunningPipeline || showDeploymentConfirmation"
						data-qa-retry-pipeline
						size="small"
						@click="showDeploymentConfirmation = true"
					>
						<Icon name="i-tick" color="gray-600" type="filled" size="x-small" />
						<Typography
							type="p2"
							color="gray-600"
							transform="uppercase"
							weight="bold"
							overflow="nowrap"
							>{{ retryButtonText }}</Typography
						>
					</Button>

					<Button
						:type="showHistory ? 'secondary' : 'default'"
						state="curved"
						size="small"
						overflow="nowrap"
						data-qa-toggle-pipeline-history
						@click="setPipelineHistory(!showHistory)"
					>
						<Icon
							name="i-clock-outline"
							:color="showHistory ? 'gray-600' : 'gray-100'"
							type="filled"
							size="small"
						/>
						<Typography type="p2" :color="showHistory ? 'gray-600' : 'gray-100'" weight="bold">
							Pipeline history
						</Typography>
					</Button>

					<!-- Deployment confirmation popover -->
					<AppDeploymentConfirmationPopover
						v-if="deployment && showDeploymentConfirmation"
						target="[data-qa-retry-pipeline]"
						:deployment="deployment"
						:should-redirect-to-pipeline="false"
						@close="() => (showDeploymentConfirmation = false)"
						@back="() => (showDeploymentConfirmation = false)"
					/>
				</template>
			</AppLogsView>
		</template>

		<template #pipeline-history>
			<AppPipelineHistory
				:project-id="projectId"
				:env-id="envId"
				:org-id="orgId"
				:dep-id="depId"
				:selected-pipeline-id="jobId ?? undefined"
				@set-pipeline-history="setPipelineHistory"
			/>
		</template>
	</PipelineWrapper>
</template>

<script lang="ts">
import { Button, Icon, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { BreadCrumb, breadcrumbStore } from "@/modules/core/breadcrumb-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { orgStore } from "@/modules/org/org-store";
import { projectStore } from "@/modules/project-list/project-store";
import { DeploymentJobAction } from "@/protocol/deployment";
import PipelineHeader from "@/shared/components/pipelines/PipelineHeader.vue";
import PipelineWrapper from "@/shared/components/pipelines/PipelineWrapper.vue";
import {
	JOB_STATUS,
	JOB_STEP_STATUS,
	PIPELINE_UNFINISHED_JOB_STATUSES,
	PipelineJobStatus
} from "@/shared/pipeline-constants";

import { applicationDeploymentStore } from "../application-deployment-store";

import AppDagView from "./AppDagView.vue";
import AppDeploymentConfirmationPopover from "./AppDeploymentConfirmationPopover.vue";
import AppLogsView from "./AppLogsView.vue";
import AppPipelineHistory from "./AppPipelineHistory.vue";

const PIPELINE_TEXT: Record<DeploymentJobAction, string> = {
	[DeploymentJobAction.invalid_job]: "",
	[DeploymentJobAction.deploy]: "deploy",
	[DeploymentJobAction.undeploy]: "undeploy"
};

export default defineComponent({
	name: "AppStageViewWrapper",

	components: {
		Button,
		Icon,
		Typography,
		PipelineHeader,
		AppDeploymentConfirmationPopover,
		PipelineWrapper,
		AppPipelineHistory,
		AppLogsView,
		AppDagView
	},

	props: {
		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		envId: {
			type: String,
			required: true
		},

		depId: {
			type: String,
			default: () => ""
		},

		jobId: {
			type: String as PropType<string | null>,
			default: () => null
		}
	},

	data: () => ({
		currentStepIndex: 0,
		isLoadingPipeline: false,
		showHistory: false,

		isAttemptingCancellation: false,
		showDeploymentConfirmation: false
	}),

	computed: {
		currentStep() {
			return this.currentJob?.steps?.[this.currentStepIndex];
		},

		currentJob() {
			if (this.jobId) {
				return applicationDeploymentStore.allAppJobs.find(job => job.id === this.jobId) ?? null;
			}

			return applicationDeploymentStore.allDeployments[this.depId]?.lastJob ?? null;
		},

		currentProject() {
			return projectStore.currentProject;
		},

		env() {
			const envs = envListStore.envs[this.projectId];
			return envs?.find(env => env.id === this.envId);
		},

		envName() {
			return this.env?.name ?? null;
		},

		app() {
			const deployment = applicationDeploymentStore.allDeployments[this.depId];
			const projectApps = applicationStore.projectApps[this.projectId];

			return projectApps?.find(app => app.id === deployment?.appId);
		},

		title() {
			const { orgs } = orgStore;
			const currentOrgId = this.currentProject?.orgId;
			const org = orgs.find(matchingOrg => matchingOrg.id === currentOrgId);
			return [this.envName, this.currentProject?.name, org?.name].filter(Boolean).join(" - ");
		},

		breadcrumbs(): BreadCrumb[] {
			const { env } = this;

			if (!env) {
				return [];
			}

			const actionText =
				this.currentJob?.jobType === DeploymentJobAction.undeploy ? "undeployment" : "deployment";

			return [
				{
					qaId: "environment",
					label: env.name,
					route: {
						name: "envDetail",
						props: {
							orgId: env.orgId,
							projectId: env.projId,
							envId: env.id
						}
					}
				},
				{
					qaId: "stageViewApp",
					label: `${this.app?.name} ${actionText} pipeline`,
					route: {
						name: "stageViewApp",
						props: {
							orgId: env.orgId,
							projectId: env.projId,
							envId: env.id,
							depId: this.depId,
							// To preserve old urls
							action: "-"
						}
					}
				}
			];
		},

		retryButtonType() {
			if (this.isRunningPipeline) {
				return "default";
			}

			if (
				this.jobStepStatus === JOB_STEP_STATUS.failed ||
				this.jobStepStatus === JOB_STEP_STATUS.canceled
			) {
				return "error";
			} else if (this.jobStepStatus === JOB_STEP_STATUS.done) {
				return "success";
			}

			return "primary";
		},

		retryButtonText() {
			if (
				this.jobStepStatus === JOB_STEP_STATUS.failed ||
				this.jobStepStatus === JOB_STEP_STATUS.canceled
			) {
				return "Retry";
			} else {
				return "Re-deploy";
			}
		},

		pipelineName() {
			return this.currentJob?.jobType
				? `Application ${PIPELINE_TEXT[this.currentJob.jobType]} pipeline`
				: "Application pipeline";
		},

		jobStepStatus() {
			return this.currentJob
				? JOB_STEP_STATUS[this.currentJob.jobStatus as PipelineJobStatus]
				: null;
		},

		isRunningPipeline() {
			return PIPELINE_UNFINISHED_JOB_STATUSES.includes(
				this.currentJob?.jobStatus as PipelineJobStatus
			);
		},

		isCancellingJob() {
			return (this.currentJob?.jobStatus as PipelineJobStatus) === JOB_STATUS.CANCELLING;
		},

		deployment() {
			const depId = this.currentJob?.depId;
			return depId ? applicationDeploymentStore.allDeployments[depId] : null;
		}
	},

	watch: {
		title: {
			immediate: true,
			deep: true,

			handler(newTitle: string) {
				if ("document" in window) {
					window.document.title = newTitle;
				}
			}
		},

		breadcrumbs: {
			handler() {
				breadcrumbStore.SET_ADDITIONAL_BREADCRUMBS(this.breadcrumbs);
			},

			deep: true,
			immediate: true
		},

		jobId: {
			immediate: true,

			handler() {
				if (this.jobId) {
					this.showHistory = true;
				}

				this.loadAppPipeline();
			}
		},

		currentJob: {
			immediate: true,

			handler() {
				this.currentStepIndex = this.currentJob?.progress ? this.currentJob.progress - 1 : 0;
			}
		}
	},

	methods: {
		async loadAppPipeline() {
			this.isLoadingPipeline = true;

			// Check if app deployment exists, if not fetch it
			const appDeployment = applicationDeploymentStore.allDeployments[this.depId];

			if (!appDeployment) {
				await applicationDeploymentStore.GET_APP_DEPLOYMENT({
					orgId: this.orgId,
					envId: this.envId,
					projId: this.projectId,
					id: this.depId
				});
			}

			if (this.jobId) {
				await applicationDeploymentStore.GET_APP_JOB({
					orgId: this.orgId,
					projId: this.projectId,
					envId: this.envId,
					depId: this.depId,
					id: this.jobId
				});
			}

			this.isLoadingPipeline = false;
		},

		setPipelineHistory(showHistory: boolean) {
			this.showHistory = showHistory;
		},

		stepChanged(stepIndex: number) {
			this.currentStepIndex = stepIndex;
		},

		async cancelDeployment() {
			if (!this.currentJob) {
				return;
			}

			this.isAttemptingCancellation = true;

			const deployment = applicationDeploymentStore.allDeployments[this.currentJob.depId];

			if (!deployment) {
				throw new Error(`No application deployment found for ${this.currentJob.depId}`);
			}

			await applicationDeploymentStore.CANCEL_DEPLOYMENT({
				id: this.currentJob.id,
				envId: deployment.envId!,
				orgId: deployment.orgId!,
				projId: deployment.projId!,
				depId: this.currentJob.depId
			});

			this.isAttemptingCancellation = false;
		}
	}
});
</script>
