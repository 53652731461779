<template>
	<EmptyState
		v-if="!isEmptyFromSearch"
		:icon="icon"
		:message="emptyMessage"
		:subtitle="emptySubtitle"
		:shape="shape"
		:action="action"
		data-qa-empty-applications-screen
		@actions="toggleAddNewApp"
	/>
	<EmptyState
		v-else
		:icon="icon"
		message="No Results Found"
		subtitle="No Results found for the search term that you entered"
		:shape="shape"
		data-qa-empty-applications-from-search
		@actions="toggleAddNewApp"
	/>
</template>
<script lang="ts">
import { EmptyState, EmptyStateIconProp, EmptyStateShape } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "NewEmptyApplications",

	components: {
		EmptyState
	},

	props: {
		isEmptyFromSearch: {
			type: Boolean,
			default: () => false
		}
	},

	emits: ["toggle-add-app"],

	data: () => ({
		shape: "squircle" as EmptyStateShape,
		icon: {
			name: "i-app",
			type: "filled",
			state: "default",
			size: "medium",
			color: "gray-200"
		} as EmptyStateIconProp,

		emptyMessage: "No apps deployed",
		emptySubtitle: "Add your application to build and deploy with Code Pipes",

		action: "ADD & DEPLOY APPLICATION"
	}),

	methods: {
		toggleAddNewApp() {
			this.$emit("toggle-add-app");
		}
	}
});
</script>
