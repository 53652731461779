import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ArtifactType, Provisioner, sourceEnum } from "@/protocol/common";
import { pipelineModule } from "@/protocol/pipeline";
import {
	AuthCodeResponse,
	LoginResponse,
	UserProfile,
	UserProfileUpdateRequest,
	UserPublicProfile
} from "@/protocol/user";
import { TokenService } from "@/services/storage-service";
import { captureError } from "@/utils";
import { reportLogin } from "@/utils/analytics";

import { CheckListFlags, InfoFlags } from "../org/orgCreateFlowSteps";
import { getStore } from "../store";

import { ColorScheme } from "./constants";
import { getUser, listUsers, updateProfile, UserProfileWithMeta } from "./services/user-service";

export const USER_PROFILE = "user_profile_2";

@Module({
	namespaced: true,
	dynamic: true,
	name: "user",
	store: getStore()
})
class UserStore extends VuexModule {
	profile: UserProfileWithMeta | null = null;

	users: Record<string, UserPublicProfile> = {};

	userIdsPendingFetch: string[] = [];

	userPendingTimeout: number | null = null;

	get colorScheme() {
		return (
			getMetaDataFromUser(this.profile).colorScheme ?? { theme: "dark-theme", mode: "dark-mode" }
		);
	}

	get fogBenderWidgetId() {
		return window.VUE_APP_FOGBENDER_WIDGET_ID;
	}

	get isUserLoggedIn() {
		return Boolean(this.profile?.id);
	}

	@Mutation
	SET_USER(user: UserProfile) {
		this.profile = user as UserProfileWithMeta;
	}

	@Mutation
	RESET_USER() {
		this.profile = null;
	}

	@Mutation
	SET_PUBLIC_USERS(users: UserPublicProfile[]) {
		users.forEach(user => {
			this.users[user.id!] = user;
		});
	}

	@Mutation
	SET_USERS_FETCH_TIMEOUT(timeout: number | null) {
		this.userPendingTimeout = timeout;
	}

	@Action
	async UPDATE_USER_META(metadata: { [key: string]: unknown }) {
		await this.UPDATE_USER({
			name: this.profile?.name ?? "",
			metadata: { ...this.profile?.metadata, ...metadata }
		});
	}

	@Action
	async UPDATE_USER(updatedProfile: UserProfileUpdateRequest) {
		const metadata = updatedProfile.metadata ?? this.profile?.metadata ?? {};

		// There is a recursive field on backend which we need to delete
		// https://cldcvr.slack.com/archives/G013A596198/p1683186790316309
		if ("render_meta" in metadata) {
			delete metadata.render_meta;
		}

		await updateProfile({
			...updatedProfile,
			metadata
		});

		await this.GET_USER();
	}

	@Action
	async INIT_USER_FROM_STORAGE() {
		try {
			const storageProfile = localStorage.getItem(USER_PROFILE);
			const userProfile: UserProfileWithMeta | null = storageProfile
				? JSON.parse(storageProfile)
				: null;

			if (userProfile?.id) {
				this.SET_USER_PROFILE({ userProfile });
			}
		} catch (err) {
			// Do nothing, finally will fetch the real user profile from the server
		} finally {
			// We always fetch the real user profile from the server
			if (TokenService.getToken()) {
				await this.GET_USER();
			}
		}
	}

	@Action
	async UPDATE_COLOR_SCHEME(colorScheme?: ColorScheme) {
		if (!colorScheme) {
			return;
		}

		await this.UPDATE_USER_META({ ...this.profile?.metadata, colorScheme });
	}

	@Action
	SET_USER_PROFILE({ userProfile }: { userProfile: UserProfile }) {
		this.SET_USER(userProfile);
		localStorage.setItem(USER_PROFILE, JSON.stringify(userProfile));
	}

	@Action
	async GET_USER() {
		try {
			const userProfile = await getUser();
			this.SET_USER_PROFILE({ userProfile });
			return userProfile;
		} catch (err) {
			captureError(err);
			throw err;
		}
	}

	@Action
	SET_USER_SIGNIN_METHOD(authResponse: LoginResponse | AuthCodeResponse) {
		const metadataSignInMethod = authResponse.signinMethod;

		if (metadataSignInMethod) {
			reportLogin(metadataSignInMethod);
		}
	}

	@Action
	QUEUE_PUBLIC_PROFILE_FETCH({ userId }: { userId: string }) {
		this.userIdsPendingFetch.push(userId);

		if (this.userPendingTimeout) {
			return;
		}

		// We need to fetch profiles in batches to avoid creating several API calls at once
		// Because these calls are usually made at the point when we find out about the user ID in the
		// UI component
		const timeout = window.setTimeout(async () => {
			const response = await listUsers({
				ids: this.userIdsPendingFetch
			});

			this.SET_PUBLIC_USERS(response.users ?? []);
			this.SET_USERS_FETCH_TIMEOUT(null);
		}, 500);

		this.SET_USERS_FETCH_TIMEOUT(timeout);
	}
}

export type UserMetadata = {
	colorScheme?: { theme: "dark-theme" | "light-theme"; mode: "dark-mode" | "light-mode" };
	signInMethod?: string;
	DismissableBanner?: Record<string, boolean>;
	checkListFlow?: CheckListFlags;
	infoFlags?: InfoFlags;
	isAppFirstFlowShown?: boolean;
	appWizardMeta?: AppWizardMetaType;
};

export type AppWizardMetaType = {
	[key: string]: {
		provisioner?: Provisioner;
		language?: sourceEnum;
		outputType?: ArtifactType;
		orgId: string;
		projId: string;
		template: pipelineModule | undefined;
		inputs: unknown;
		isValid: boolean;
		credId?: string;
	};
};
function getMetaDataFromUser(user: UserProfile | LoginResponse | null | undefined) {
	const metaData = user?.metadata;

	if (!metaData) {
		return {};
	}

	return user.metadata as UserMetadata;
}

const userStore = getModule(UserStore);

export { userStore };
