<template>
	<Container
		:padding="project ? 0 : '0px 16px'"
		direction="column"
		align="left top"
		class="height-100-per width-100-per"
		:full-width="true"
		data-qa-credentials-wrapper
	>
		<Container v-if="loadingCreds" :padding="project ? '20% 0px' : 0">
			<CustomLoader :position="project ? 'relative' : 'absolute'" />
		</Container>
		<EmptyCredentialsView v-else-if="!loadingCreds && !hasCreds" :project="project" />
		<Container
			v-else
			direction="column"
			:padding="project ? '0px' : '16px 0px 16px 0px'"
			align="left top"
			:grow="emptyFilterResult ? 1 : 0"
			data-qa-global-creds-container
		>
			<!-- title and filter -->
			<CredentialHeader
				v-model:search-text="searchString"
				v-model:filters="credTypeFilters"
				:project-view="!!project"
				:project="project"
				:credential-group="credentialGroupByScope"
			/>
			<EmptyFilterResult v-if="emptyFilterResult" v-bind="emptyFilterResult" />
			<Container
				v-else
				direction="column"
				padding="0px 0px 50px 0px"
				align="left top"
				:grow="1"
				:gap="36"
				overflow="auto"
			>
				<template v-for="group in credentialGroupByScope" :key="group.title">
					<CredentialTable
						v-if="group.list.length > 0 && group.show"
						:project="project"
						:column-config="group.columnConfig"
						:credentials="group.list"
						:group-scope="group.scope"
						:group-name="group.title"
					/>
				</template>
			</Container>
		</Container>

		<InfoPopover
			v-if="isOrgLevel && showInfo"
			title="Credentials"
			:show-overlay="true"
			:is-open="isOrgLevel && showInfo"
			placement="right-start"
			target="credentials-org"
			@close="hideInfoPopover"
		>
			<f-div key="sd" padding="none none medium none" height="hug-content" gap="medium">
				<f-text variant="para" size="small" weight="regular">
					Add credentials to this organization to add, build and deploy applications and their
					environments.
				</f-text>
			</f-div>
			<f-div key="sd" padding="none none medium none" height="hug-content" gap="medium">
				<f-text variant="para" size="small" weight="regular">
					You can manage cloud, git or container credentials here.
				</f-text>
			</f-div>
			<f-div key="sds" padding="none none medium none" height="hug-content" gap="medium">
				<Typography
					type="p2"
					color="primary"
					:inline="true"
					:link="true"
					@click="openPermissionsLink"
				>
					What access permissions does Code Pipes need?
				</Typography>
				<Typography type="p2" color="primary" :inline="true" :link="true" @click="openCPsecureLink">
					How secure is adding my credentials to Code Pipes?
				</Typography>
			</f-div>
		</InfoPopover>
	</Container>
</template>
<script lang="ts">
import { Container, Typography } from "@cldcvr/flow-vue3";
import { cloneDeep } from "lodash-es";
import { defineComponent, PropType } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { breadcrumbStore } from "@/modules/core/breadcrumb-store";
import CredentialHeader from "@/modules/credentials/components/credential-list/CredentialHeader.vue";
import CredentialTable from "@/modules/credentials/components/credential-list/CredentialTable.vue";
import { credentialStore } from "@/modules/credentials/credential-store";
import { CredFilters } from "@/modules/credentials/credential-types";
import { envListStore } from "@/modules/env-list/env-list-store";
import { orgStore } from "@/modules/org/org-store";
import { userStore } from "@/modules/user/user-store";
import { CredScope, project } from "@/protocol/identity";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";
import { captureError, findCredBySearchTxt } from "@/utils";

import { CredWithAssignedEntities, defaultCredStats, EntityCredScopeMap } from "../store-types";

import EmptyCredentialsView from "./EmptyCredentialsView.vue";
import EmptyFilterResult from "./EmptyFilterResult.vue";

export default defineComponent({
	name: "CredentialsWrapper",

	components: {
		EmptyCredentialsView,
		EmptyFilterResult,
		CustomLoader,
		CredentialHeader,
		CredentialTable,
		Container,
		InfoPopover,
		Typography
	},

	props: {
		project: {
			type: Object as PropType<project>
		},

		isOrgLevel: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		searchString: "",
		isLoading: true,
		credTypeFilters: {
			[CredScope.cloud]: false,
			[CredScope.git]: false,
			[CredScope.docker]: false,
			[CredScope.no_support]: false
		} as CredFilters,

		showInfo: false
	}),

	computed: {
		loadingCreds() {
			return (
				this.isLoading ||
				credentialStore.loadingCredentials === true ||
				credentialStore.loadingCredentialStats === true
			);
		},

		hasCreds() {
			return this.credAssignedToProjectAndDependents.length > 0;
		},

		credentials(): CredWithAssignedEntities[] {
			return Object.values(credentialStore.genericCredentials).map(cred => {
				const stats = Object.assign(
					{ ...defaultCredStats },
					credentialStore.credentialStats[cred.id]
				);
				return {
					...cred,
					stats
				};
			});
		},

		/**
		 * finds all credentials that are assigned to the current project and its dependents
		 * update the assigned counts based on project and dependent entities
		 */
		credAssignedToProjectAndDependents() {
			if (!this.project?.id) {
				return this.credentials;
			}

			const projectEnvIds = envListStore.envs[this.project.id]?.map(env => env.id) ?? [];
			const projectAppIds = applicationStore.projectApps[this.project.id]?.map(app => app.id) ?? [];
			const projectAndDependentIds = [...projectEnvIds, ...projectAppIds, this.project.id];

			return this.credentials
				.filter(cred => {
					const { credScopeMap } = cred.stats;
					return projectAndDependentIds.some(id => Object.keys(credScopeMap).includes(id));
				})
				.map(cred => {
					const projects = cred.stats.projects.filter(proj => proj.id === this.project?.id);
					const environments = cred.stats.environments.filter(env =>
						projectEnvIds.includes(env.id)
					);
					const apps = cred.stats.apps.filter(app => projectAppIds.includes(app.id));
					const entityCredScopeMap = cred.stats.credScopeMap;

					// re-contruct the entity & credScope map with project and dependents id.
					const credScopeMap = Object.keys(entityCredScopeMap)
						// only keep project and decentend entities
						.filter(entityId => projectAndDependentIds.includes(entityId))
						.reduce<EntityCredScopeMap>((scopeMap, entityId) => {
							const credScope = entityCredScopeMap[entityId];
							if (credScope) {
								scopeMap[entityId] = credScope;
							}
							return scopeMap;
						}, {});

					return {
						...cred,
						stats: {
							...cred.stats,
							assignedToCount: projects.length + environments.length + apps.length,
							environments,
							credScopeMap,
							projects,
							apps
						}
					};
				});
		},

		filteredCreds() {
			const { searchString, credAssignedToProjectAndDependents } = this;

			if (searchString && searchString.length > 3) {
				return credAssignedToProjectAndDependents.filter(cred =>
					findCredBySearchTxt(searchString, cred)
				);
			}
			return credAssignedToProjectAndDependents;
		},

		credentialGroupByScope() {
			return {
				[CredScope.cloud]: {
					title: "Cloud credentials",
					scope: CredScope.cloud,
					show: this.isFilterApplied ? this.credTypeFilters.cloud : true,
					columnConfig: {
						showLastUpdated: true,
						showAssignedTo: true,
						showClassification: true
					},

					list: this.filterAssignedCredByScope(this.filteredCreds, CredScope.cloud)
				},

				[CredScope.git]: {
					title: "Git credentials",
					scope: CredScope.git,
					show: this.isFilterApplied ? this.credTypeFilters.git : true,
					columnConfig: {
						showLastUpdated: true,
						showAssignedTo: true,
						showClassification: true
					},

					list: this.filterAssignedCredByScope(this.filteredCreds, CredScope.git)
				},

				[CredScope.docker]: {
					title: "Container credentials",
					scope: CredScope.docker,
					show: this.isFilterApplied ? this.credTypeFilters.docker : true,
					columnConfig: {
						showLastUpdated: true,
						showAssignedTo: true,
						showHost: true,
						showClassification: true
					},

					list: this.filterAssignedCredByScope(this.filteredCreds, CredScope.docker)
				},

				[CredScope.no_support]: {
					title: "Not assigned",
					scope: CredScope.no_support,
					show: this.isFilterApplied ? this.credTypeFilters.no_support : true,
					columnConfig: {
						showLastUpdated: true,
						showHost: true,
						showClassification: true
					},

					list: this.filteredCreds.filter(cred => cred.stats.assignedToCount < 1)
				}
			};
		},

		isFilterApplied(): boolean {
			return Object.values(this.credTypeFilters).includes(true);
		},

		emptyFilterResult() {
			const filterByCredentialGroup = Object.values(this.credentialGroupByScope).filter(
				group => group.show && group.list.length
			);
			if (this.isFilterApplied && !filterByCredentialGroup.length) {
				return {
					icon: "i-filter",
					title: "No results found",
					subTitle: "No results found for the filter! Try something else..."
				};
			}
			if (this.searchString !== "" && !this.filteredCreds.length) {
				return {
					icon: "i-search",
					title: "No results found",
					subTitle: "No results found for the search term that you entered! Try something else"
				};
			}
			return null;
		},

		orgId() {
			return orgStore.activeOrgId ?? (this.$route.params.orgId as string);
		},

		infoFlags() {
			return userStore.profile?.metadata?.infoFlags;
		}
	},

	mounted() {
		if (!this.infoFlags?.creds) {
			this.showInfo = true;
		}

		this.loadCredentials();

		breadcrumbStore.SET_ADDITIONAL_BREADCRUMBS([
			{
				qaId: "credentials",
				label: "Organization Credentials",
				route: {
					name: "credentials-global"
				}
			}
		]);
	},

	methods: {
		async loadCredentials() {
			this.isLoading = true;
			try {
				await credentialStore.GET_CLASSIFICATIONS({ orgId: this.orgId });
				if (this.orgId && (await orgStore.IS_USER_ORG_ADMIN({ orgId: this.orgId }))) {
					await credentialStore.GET_ORG_CREDENTIALS({
						orgId: this.orgId
					});
					await Promise.allSettled([
						credentialStore.FETCH_ORG_CREDENTIAL_STATS({ orgId: this.orgId })
					]);
				}
			} catch (err) {
				captureError(err);
			} finally {
				this.isLoading = false;
			}
		},

		filterAssignedCredByScope(creds: CredWithAssignedEntities[], credScope: CredScope) {
			return creds
				.filter(cred => {
					const assignedAsScopes = Object.values(cred.stats.credScopeMap).flat();
					return cred.stats.assignedToCount > 0 && assignedAsScopes.includes(credScope);
				})
				.map(cred => {
					const stats = cloneDeep(cred.stats);
					stats.apps = stats.apps
						.filter(app => app.credScope.includes(credScope))
						.map(app => ({ ...app, credScope: [credScope] }));
					stats.environments = stats.environments
						.filter(env => env.credScope.includes(credScope))
						.map(env => ({ ...env, credScope: [credScope] }));
					stats.projects = stats.projects
						.filter(proj => proj.credScope.includes(credScope))
						.map(proj => ({ ...proj, credScope: [credScope] }));
					stats.assignedToCount =
						stats.apps.length + stats.environments.length + stats.projects.length;

					return {
						...cred,
						stats
					};
				});
		},

		hideInfoPopover() {
			this.showInfo = false;
			userStore.UPDATE_USER_META({
				infoFlags: {
					...this.infoFlags,
					creds: true
				}
			});
		},

		openPermissionsLink() {
			window.open(
				"https://docs.codepipes.io/docs/permission-requirements-for-your-cloud-account",
				"_blank"
			);
		},

		openCPsecureLink() {
			window.open(
				"https://docs.codepipes.io/docs/code-pipes-use-of-customer-cloud-accounts",
				"_blank"
			);
		}
	}
});
</script>
