<template>
	<PopOver :open="open" placement="bottom" @overlay-click="showModel()">
		<Icon
			v-tooltip="{
				content: 'delete credential from organization',
				placement: 'left'
			}"
			name="i-delete"
			size="small"
			type="filled"
			:data-qa="`delete-cred-btn-${cred.name}`"
			state="error"
			@click="showModel()"
		/>
		<template #content>
			<Wrapper
				max-height="70vh"
				width="432px"
				border-radius="4px"
				background="element-light"
				data-qa="cred-delete-modal"
			>
				<!--START : Container-->
				<!--START : PopOver header-->
				<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
					<Header>
						<Typography
							type="h4"
							:color="isSubmitting ? 'gray-300' : 'dark'"
							data-qa="cred-delete-modal-title"
							>{{ modalTitle }}</Typography
						>
						<Container :padding="0" :grow="1" align="right center">
							<Icon
								name="i-close"
								:disabled="isSubmitting"
								type="filled"
								size="x-small"
								data-qa="cred-delete-modal-close-btn"
								@click="showModel()"
							/>
						</Container>
					</Header>
					<!--END : PopOver header-->
					<ModalNotificationBanner
						v-if="submitError"
						banner-type="error"
						:banner-body="submitError"
						:show-close-icon="true"
						@close="submitError = null"
					/>
					<Container
						:padding="16"
						:gap="24"
						direction="column"
						overflow="auto"
						:shrink="0"
						align="left top"
					>
						<Typography
							v-if="isCredAssignedToAnyEntity && !isSubmitting"
							type="p1"
							color="error"
							data-qa="cred-delete-modal-caution-msg"
						>
							<Typography
								type="p1"
								weight="bold"
								color="error"
								data-qa="cred-delete-modal-cred-name"
								inline
								>{{ cred.name }}</Typography
							>
							is assigned to the following entities. If deleted, the following entities will not
							have
							<Typography
								type="p1"
								weight="bold"
								color="error"
								data-qa="cred-delete-modal-cred-scopes"
								inline
								>{{ credScopes }}</Typography
							>
							access through Code Pipes.
						</Typography>
						<Typography
							v-else-if="isCredAssignedToAnyEntity && isSubmitting"
							type="p1"
							color="dark"
							data-qa="cred-delete-modal-unassign-msg"
						>
							<Typography
								type="p1"
								weight="bold"
								color="dark"
								data-qa="cred-delete-modal-cred-name-2"
								inline
							>
								{{ cred.name }}
							</Typography>
							is being unassigned from the entities. It will be deleted once unassigning is
							completed
						</Typography>
						<Typography
							v-else-if="!isSubmitting"
							type="p1"
							color="error"
							data-qa="cred-delete-modal-confirmation-msg"
							>Are you sure you want to delete
							<Typography
								type="p1"
								weight="bold"
								color="error"
								data-qa="cred-delete-modal-title"
								inline
								:style="{ whiteSpace: 'pre-wrap' }"
							>
								{{ cred.name }}
							</Typography>
							?
						</Typography>
					</Container>
					<Divider v-if="isCredAssignedToAnyEntity && isSubmitting" />

					<Container
						v-if="isCredAssignedToAnyEntity && isSubmitting"
						:padding="16"
						:gap="24"
						direction="column"
						overflow="auto"
						align="left top"
					>
						<Typography type="p1" color="error" data-qa="cred-delete-modal-cred-unassigning"
							>Unassigned from {{ removedEntities }} / {{ totalEntities }} entities...
						</Typography>
					</Container>

					<AssignedList
						v-if="isCredAssignedToAnyEntity && !isSubmitting"
						:cred="credToDelete"
						:supported-env-ids="supportedEnvIds"
						:mounted-inside-modal="true"
					/>

					<Footer>
						<Container :padding="0" :gap="0" :grow="1" :shrink="0" direction="column">
							<Container
								v-if="!isSubmitting"
								:padding="16"
								:gap="24"
								direction="column"
								overflow="auto"
								align="left top"
							>
								<f-form-builder
									ref="formBuilder"
									data-qa="cred-delete-modal-cred-form"
									:field.prop="formFields"
									:values.prop="formValue"
									@input="handleInput"
									@state-change="formState = $event.detail"
								/>
							</Container>
							<Button
								state="full"
								:disabled="isSubmitting"
								:loading="isSubmitting"
								type="error"
								data-qa="cred-delete-modal-cred-delete-btn"
								@click="deleteCredential"
								>DELETE CREDENTIAL</Button
							>
						</Container>
					</Footer>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import {
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	PopOver,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import AssignedList from "@/modules/credentials/components/credential-list/AssignedToEntityModal.vue";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { AppId } from "@/protocol/app";
import { EnvironmentId, VGEntities } from "@/protocol/common";
import { CredScope, Creds, CredsUnassignmentRequest, ProjectId } from "@/protocol/identity";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import { captureError } from "@/utils/capture-error";
import { getErrorMessage } from "@/utils/get-error-message";

import { credentialStore } from "../../credential-store";
import { listCredEntities, unassignCredsScope } from "../../credentials-service";
import { CredWithAssignedEntities, defaultCredStats } from "../../store-types";

export default defineComponent({
	name: "DeleteCredentialModal",

	components: {
		ModalNotificationBanner,
		AssignedList,
		Typography,
		Container,
		PopOver,
		Divider,
		Wrapper,
		Button,
		Footer,
		Header,
		Icon
	},

	props: {
		cred: {
			type: Object as PropType<Creds>,
			required: true
		}
	},

	data: () => ({
		isSubmitting: false,
		submitError: null as string | null,
		formState: null as FormBuilderState | null,
		formValue: "",
		removedEntities: 0,
		open: false,
		notificationDetails: {
			credName: "",
			notificationBody: ""
		}
	}),

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "text",
				qaId: "deleteCredName",
				label: { title: "Please type in the display name" },
				placeholder: "Enter display name",
				validationRules: [
					{ name: "required" },
					{
						name: "custom",
						validate: value => {
							return value === this.cred.name;
						},

						message: "The display name does not match"
					}
				]
			};
		},

		modalTitle() {
			if (this.isSubmitting) {
				return "Deletion in progress";
			}
			return `Delete ${this.cred.name} credential`;
		},

		credStats() {
			return credentialStore.credentialStats[this.cred.id];
		},

		credToDelete(): CredWithAssignedEntities {
			return {
				...this.cred,
				stats: this.credStats ?? Object.assign({}, defaultCredStats)
			};
		},

		totalEntities(): number {
			return (
				Number(this.credStats?.projects.length ?? 0) +
				Number(this.credStats?.environments.length ?? 0) +
				Number(this.credStats?.apps.length ?? 0)
			);
		},

		isCredAssignedToAnyEntity() {
			return (
				!!this.credStats?.projects.length ||
				!!this.credStats?.environments.length ||
				!!this.credStats?.apps.length
			);
		},

		supportedEnvIds() {
			return this.credStats?.environments ?? [];
		},

		credScopes() {
			const scopes = this.cred.credScope;
			return scopes
				?.map(scope => (scope === CredScope.docker ? "container" : scope))
				.join(", ")
				.replace(/, ([^,]*)$/, " and $1");
		}
	},

	methods: {
		async deleteCredential() {
			if (this.isSubmitting) {
				return;
			}

			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();

			if (!this.formState?.isValid) {
				return;
			}

			this.submitError = "";
			this.isSubmitting = true;
			this.setNotificationDetails();

			try {
				const credAssignStats = await listCredEntities({
					orgId: this.cred.orgId,
					id: this.cred.id
				});

				const promises = credAssignStats.entities?.map(entity => {
					const credsUnassignmentRequest: CredsUnassignmentRequest = {
						orgId: this.cred.orgId,
						creds: [
							{
								credId: this.cred.id,
								credScope: entity.credScope
							}
						],
						// remove cred assignment from project
						...(entity.entityType === VGEntities.project && {
							project: entity.project as ProjectId
						}),
						// remove cred assignment from env
						...(entity.entityType === VGEntities.environment && {
							environment: entity.environment as EnvironmentId
						}),
						// remove cred assignment from app
						...(entity.entityType === VGEntities.application && { app: entity.app as AppId })
					};

					return unassignCredsScope(credsUnassignmentRequest).then(() => {
						this.removedEntities = this.removedEntities + 1;
					});
				});

				if (promises?.length) {
					await Promise.allSettled(promises);
				}

				await credentialStore.deleteCredential({ cred: this.cred });

				notificationsStore.ADD_TOAST({
					qaId: `toast-${this.notificationDetails.credName}-deleted`,
					title: "credential is deleted",
					text: this.notificationDetails.notificationBody,
					status: "error"
				});
				this.$emit("close");
			} catch (error) {
				this.submitError = getErrorMessage(error);
				captureError(error);
			} finally {
				this.isSubmitting = false;
			}
		},

		setNotificationDetails() {
			let notificationBody = `credential ${this.cred.name} is successfully deleted.`;
			if (this.isCredAssignedToAnyEntity) {
				const entities = [];
				const projCount = this.credStats?.projects.length ?? 0;
				const envCount = this.credStats?.environments.length ?? 0;
				const appCount = this.credStats?.apps.length ?? 0;

				if (projCount > 0) {
					entities.push(`${projCount} project${projCount > 1 ? "s" : ""}`);
				}
				if (envCount > 0) {
					entities.push(`${envCount} evnironment${envCount > 1 ? "s" : ""}`);
				}
				if (appCount > 0) {
					entities.push(`${appCount} app${appCount > 1 ? "s" : ""}`);
				}
				if (entities.length) {
					notificationBody = `${this.cred.name} is deleted. This will affect ${entities.join(
						", "
					)} and its entities.`;
				}
			}

			this.notificationDetails = {
				credName: this.cred.name,
				notificationBody
			};
		},

		handleInput(event: CustomEvent<string>) {
			this.formValue = event.detail;
		},

		showModel() {
			this.open = !this.open;
			this.$emit("modelOpen", this.open ? this.cred.id : null);
		}
	}
});
</script>
