export function extractGitHubRepoPath(url: string | undefined) {
	if (!url) {
		return undefined;
	}

	const match = url.match(/^(https?:\/\/(www\.)?)?github.com\/(?<owner>[\w.-]+)\/(?<name>[\w.-]+)/);

	if (!match || !(match.groups?.owner && match.groups.name)) {
		return undefined;
	}

	return `${match.groups.owner}/${match.groups.name}`;
}
