import { AxiosError, AxiosResponse } from "axios";

export type ServerError = {
	Code: string;
	Kind: number;
	Message: string;
	Causes: unknown[];
	Nested: ServerError | null;
};

export function getErrorMessage(error: unknown, mergeNested = false) {
	if (typeof error === "string") {
		return error;
	}

	const axiosError = (error as AxiosError<ServerError>).response?.data;
	const axiosResponse = (error as AxiosResponse<ServerError>).data;

	if (axiosError) {
		let message = axiosError.Message;
		let nested: ServerError["Nested"] = axiosError.Nested;

		if (mergeNested) {
			while (nested) {
				message += `\n${nested.Message}`;
				nested = nested.Nested;
			}
		}

		return message;
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	} else if (axiosResponse) {
		return axiosResponse.Message;
	} else if ("message" in (error as Error)) {
		return (error as Error).message;
	}

	return JSON.stringify(error);
}
