<template>
	<f-div
		id="checklistMain"
		width="432px"
		height="hug-content"
		direction="row"
		align="middle-left"
		position="relative"
		data-qa-checklist-popover
	>
		<f-div state="secondary">
			<f-div padding="large" height="hug-content" direction="column" gap="medium">
				<!-- Header -->
				<f-div padding="none" height="hug-content" gap="medium">
					<f-text variant="para" size="small" weight="bold" data-qa-checklist-popover-title
						>{{ popoverContent.title }}
					</f-text>
					<f-div align="middle-right" gap="small">
						<f-icon
							source="i-info-fill"
							class="cursor-pointer"
							data-qa-checklist-info-toggle
							@click.stop="$emit('open-welcome')"
						>
						</f-icon>
						<f-icon
							source="i-close"
							class="cursor-pointer"
							data-qa-checklist-flow-close-btn
							@click.stop="$emit('close-flow')"
						>
						</f-icon>
					</f-div>
				</f-div>

				<!-- Enable when images are available -->
				<!-- <f-div padding="none" height="165px" direction="column" state="primary"></f-div> -->

				<f-div v-if="popoverContent.desc" padding="none" height="hug-content" gap="medium">
					<f-text variant="para" size="medium" weight="regular" data-qa-checklist-desc-text>
						{{ popoverContent.desc }}
					</f-text>
				</f-div>
				<f-divider state="subtle" size="medium" variant="dashed"> </f-divider>
				<!-- Checklist items -->
				<template v-for="(step, index) in steps" :key="index">
					<f-div
						padding="medium"
						direction="row"
						:disabled="step.disabled"
						class="cursor-pointer"
						:data-qa-checklist-org-step-container="step.stepName"
						@click.stop="startStep(step.stepNo)"
					>
						<f-div padding="none" align="middle-left" width="30px">
							<f-icon-button
								size="x-small"
								icon="i-tick"
								:category="step.completed ? 'fill' : 'outline'"
								:state="step.completed ? 'success' : 'neutral'"
							/>
						</f-div>
						<f-div padding="none" direction="column">
							<f-text size="large" weight="medium" data-qa-org-step-title>{{
								step.stepLabel
							}}</f-text>
							<f-text size="medium" variant="para" class="padding-tp-16" data-qa-org-step-desc>{{
								step.desc
							}}</f-text>
						</f-div>
						<f-div
							padding="none"
							align="top-center"
							width="30px"
							data-qa-checklist-step-org-chevron
						>
							<f-icon size="small" source="i-chevron-right" />
						</f-div>
					</f-div>

					<f-divider
						v-if="index !== steps.length - 1"
						state="subtle"
						size="medium"
						variant="dashed"
					>
					</f-divider>
				</template>
			</f-div>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { OrgChecklistFlowSteps } from "./OrgBundleImportWrapper.vue";
import { ChecklistStep } from "./orgCreateFlowSteps";

export default defineComponent({
	name: "ChecklistSteps",

	props: {
		steps: {
			type: Array as PropType<ChecklistStep[]>,
			required: true
		},

		popoverContent: {
			type: Object as PropType<Record<string, string>>,
			required: true
		}
	},

	emits: ["step-clicked", "open-welcome", "close-flow"],

	methods: {
		startStep(step: OrgChecklistFlowSteps) {
			this.$emit("step-clicked", step);
		}
	}
});
</script>
