<template>
	<Container
		v-if="canAccessCreds"
		direction="column"
		:padding="0"
		:gap="24"
		class="align-items-center"
		overflow="visible"
	>
		<Container padding="0px" :gap="25" :shrink="0" overflow="visible">
			<!-- cloud(AWS, AZURE, GCP) credential assign and unassign -->
			<AssignCredentialModal
				v-if="!assignedCredToCloud.length"
				:entity="project"
				entity-kind="project"
				:cred-scope="credScope.cloud"
			>
				<template #assignCredBtn="{ showCredAssignModal }">
					<f-icon-button
						icon="i-cloud"
						category="outline"
						size="large"
						data-qa-show-assign-cloud-cred-modal-btn
						@click="showCredAssignModal"
					>
					</f-icon-button>
				</template>
			</AssignCredentialModal>

			<!-- github credential assign and unassign -->
			<AssignCredentialModal
				v-if="!assignedCredToGit.length"
				:entity="project"
				git-form-ref="projectHeaderGitForm"
				entity-kind="project"
				:cred-scope="credScope.git"
			>
				<template #assignCredBtn="{ showCredAssignModal }">
					<f-icon-button
						icon="i-git"
						category="outline"
						size="large"
						data-qa-show-assign-git-cred-modal-btn
						@click="showCredAssignModal"
					>
					</f-icon-button>
				</template>
			</AssignCredentialModal>
			<!-- github credential assign and unassign -->
			<AssignCredentialModal
				v-if="!assignedCredToDocker.length"
				:entity="project"
				entity-kind="project"
				:cred-scope="credScope.docker"
			>
				<template #assignCredBtn="{ showCredAssignModal }">
					<f-icon-button
						icon="i-box"
						category="outline"
						size="large"
						data-qa-show-assign-git-container-modal-btn
						@click="showCredAssignModal"
					>
					</f-icon-button>
				</template>
			</AssignCredentialModal>
		</Container>
	</Container>
</template>

<script lang="ts">
import { Container } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import AssignCredentialModal from "@/modules/credentials/components/credential-assign/AssignCredentialModal.vue";
import { orgStore } from "@/modules/org/org-store";
import { project, CredScope } from "@/protocol/identity";

import { getCredByScope } from "../../credential-store";

export default defineComponent({
	name: "PlaceholderAssignCredentialIcons",

	components: {
		Container,
		AssignCredentialModal
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		}
	},

	data: () => ({
		credScope: CredScope
	}),

	computed: {
		canAccessCreds() {
			return orgStore.isUserOrgAdmin;
		},

		assignedCredToGit() {
			return getCredByScope({
				entityId: this.project.id,
				scope: CredScope.git
			});
		},

		assignedCredToCloud() {
			return getCredByScope({
				entityId: this.project.id,
				scope: CredScope.cloud
			});
		},

		assignedCredToDocker() {
			return getCredByScope({
				entityId: this.project.id,
				scope: CredScope.docker
			});
		}
	}
});
</script>
