<template>
	<Container
		direction="column"
		class="flex-shrink-0"
		:grow="1"
		:padding="0"
		:gap="0"
		overflow="auto"
		data-qa="assignable-cred-list"
		align="left top"
	>
		<EmptyState
			v-if="notFoundPlaceholderOption"
			:icon="notFoundPlaceholderOption.icon"
			:message="notFoundPlaceholderOption.message"
			:subtitle="notFoundPlaceholderOption.subtitle"
			:shape="notFoundPlaceholderOption.shape"
			data-qa="no-credential-exists"
			:action="notFoundPlaceholderOption.ctaText"
			@actions="$emit('openCredAddFormList')"
		/>
		<Container v-else :padding="0" :gap="0" direction="column" align="left top" overflow="auto">
			<AssignableCredItem
				v-for="cred in filterCredentials"
				:key="cred.id"
				:cred="cred"
				:assigned-creds="assignedCreds"
				:show-cred-icon="true"
				:selected-creds="selectedCredByClassification(cred)"
				@on-remove="onRemove"
				@on-add="onAdd"
			/>
		</Container>
	</Container>
</template>

<script lang="ts">
import { Container, EmptyState } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { entityKind, SupportedCredScope } from "@/modules/credentials/credential-types";
import { VGEntities } from "@/protocol/common";
import { CredScope, Creds } from "@/protocol/identity";
import { findCredBySearchTxt } from "@/utils";

import AssignableCredItem from "./AssignableCredItem.vue";

const DEFAULT_CLASSIFICATION_ID = "does-not-belong-to-classification";
const MAX_CRED_PER_CLASSIFICATION = 1;

export default defineComponent({
	name: "AssignableCredList",

	components: {
		AssignableCredItem,
		EmptyState,
		Container
	},

	props: {
		assignedCreds: {
			type: Array as PropType<Creds[]>,
			required: true
		},

		selectedCreds: {
			type: Array as PropType<Creds[]>,
			required: true
		},

		credentials: {
			type: Array as PropType<Creds[]>,
			required: true
		},

		credScope: {
			type: String as PropType<SupportedCredScope>,
			required: true
		},

		searchText: {
			type: String,
			required: true
		},

		entityKind: {
			type: String as PropType<entityKind>,
			required: true
		}
	},

	data: () => ({
		localSelectedCreds: new Map<string, Creds[]>(),
		isWatching: true
	}),

	computed: {
		notFoundPlaceholderOption() {
			if (!this.hasCredentials) {
				return {
					message: "Add Credential",
					ctaText: "ADD CREDENTIAL",
					subtitle: `Credentials on Code Pipes represent an existing account that you have with any ${
						this.credScope === CredScope.docker ? "Container" : this.credScope
					} platforms.`,

					shape: "squircle",
					icon: {
						name: "i-plus",
						type: "filled",
						state: "default",
						size: "medium",
						color: "gray-200"
					}
				} as const;
			}

			if (this.noSearchResults) {
				return {
					message: "No Results Found",
					subtitle: "No Results found for the search term that you entered",
					shape: "squircle",
					icon: {
						name: "i-search",
						type: "filled",
						state: "default",
						size: "medium",
						color: "gray-200"
					}
				} as const;
			}
			return null;
		},

		noSearchResults() {
			return this.searchText.length > 0 && this.filterCredentials.length < 1;
		},

		hasCredentials() {
			return !!this.credentials.length;
		},

		filterCredentials() {
			const searchText = this.searchText.trim();
			if (searchText) {
				return this.credentials.filter(cred => findCredBySearchTxt(searchText, cred));
			}
			return this.credentials;
		},

		flattenSelectedCreds() {
			return [...this.localSelectedCreds.values()].flat();
		}
	},

	watch: {
		assignedCreds: {
			deep: true,
			immediate: true,

			handler(assignedCreds: Creds[]) {
				if (assignedCreds.length && this.isWatching) {
					assignedCreds
						.filter(assigned => assigned.inheritedFrom !== VGEntities.project)
						.forEach(assignCred => this.onAdd(assignCred));
					this.isWatching = false;
				}
			}
		}
	},

	mounted() {
		if (this.selectedCreds.length) {
			this.selectedCreds.forEach(selectedCred => {
				this.onAdd(selectedCred);
			});
		}
	},

	methods: {
		getClassificationId(cred: Creds) {
			return cred.classificationId ? cred.classificationId : DEFAULT_CLASSIFICATION_ID;
		},

		onRemove(cred: Creds) {
			const classificationId = this.getClassificationId(cred);
			const creds = this.selectedCredByClassification(cred).filter(
				selected => selected.id !== cred.id
			);

			this.update(classificationId, creds);
		},

		onAdd(cred: Creds) {
			/**
			 * If the entity is environment or app, then the user
			 * can only select one cred regardless of the classification.
			 */
			if (this.entityKind === "environment" || this.entityKind === "app") {
				const selectedCredSize = this.localSelectedCreds.size;
				if (selectedCredSize > 0) {
					this.localSelectedCreds.clear();
				}
			}
			/**
			 * If the entity is project, then the user can select multiple creds
			 * but only one cred per classification.
			 */
			const classificationId = this.getClassificationId(cred);
			const creds = this.selectedCredByClassification(cred);

			if (creds.length < MAX_CRED_PER_CLASSIFICATION) {
				creds.push(cred);
			} else {
				creds.splice(creds.length - 1, 1, cred);
			}

			this.update(classificationId, creds);
		},

		update(classificationId: string, creds: Creds[]) {
			this.localSelectedCreds.set(classificationId, [...creds]);
			this.localSelectedCreds = new Map(this.localSelectedCreds);
			this.$emit("update:selectedCreds", this.flattenSelectedCreds);
		},

		selectedCredByClassification(cred: Creds) {
			const classificationId = this.getClassificationId(cred);
			return this.localSelectedCreds.get(classificationId) ?? [];
		}
	}
});
</script>
