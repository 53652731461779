import {
	AppDeployment,
	AppDeploymentId,
	AppDeployments,
	artifactRequest,
	deploymentService,
	jobId,
	jobResponse,
	jobs,
	success
} from "@/protocol/deployment";
import { pipelineModules } from "@/protocol/pipeline";
import ApiService, { DEPLOYMENT_API } from "@/services/api-service";

export const listAppDeploymentsForApp: deploymentService["listDeployments"] = async function ({
	orgId,
	projId,
	appId
}) {
	const response = await ApiService.get<AppDeployments>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/apps/${appId}/deployments`
	);
	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listAppDeploymentsForEnv: deploymentService["listAppDeployments"] = async function ({
	orgId,
	projId,
	envId
}) {
	const response = await ApiService.get<AppDeployments>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments`
	);
	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listAppDeploymentsForProject: deploymentService["listProjectDeployments"] =
	async function ({ orgId, projId }) {
		const response = await ApiService.get<AppDeployments>(
			`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/deployments`
		);

		if (response.status === 200) {
			return response.data;
		}

		throw response;
	};

export const startAppDeployment: deploymentService["startDeployment"] = async function (request) {
	const { orgId, projId, envId, id } = request;

	const response = await ApiService.put<jobId>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${id}/jobs`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getDeploymentTemplates: deploymentService["listDeploymentTemplates"] =
	async function ({ orgId, projId, envId, target }) {
		let targetStr = "";

		if (target) {
			targetStr = `?target=${target.join("&target=")}`;
		}

		const response = await ApiService.get<pipelineModules>(
			`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/templates${targetStr}`
		);

		if (response.status === 200) {
			return response.data;
		}

		throw response;
	};

export const getAppDeploymentStatus: deploymentService["deploymentStatus"] = async function ({
	orgId,
	projId,
	envId,
	depId,
	id
}) {
	const response = await ApiService.get<jobResponse>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${depId}/jobs/${id}`
	);
	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getDeploymentHistory: deploymentService["deploymentHistory"] = async function ({
	orgId,
	projId,
	envId,
	id,
	limit
}) {
	const response = await ApiService.get<jobs>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${id}/jobs?limit=${limit}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getJobArtifact = async function ({ job, artifactName }: artifactRequest) {
	const response = await ApiService.get(
		`${DEPLOYMENT_API}/organizations/${job?.orgId}/projects/${job?.projId}/envs/${job?.envId}/deployments/${job?.depId}/jobs/${job?.id}/artifacts/${artifactName}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteAppDeployment: deploymentService["deleteAppDeployment"] = async function ({
	orgId,
	projId,
	envId,
	id
}) {
	const response = await ApiService.delete<success>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getAppDeployment: deploymentService["getAppDeployment"] = async function ({
	orgId,
	projId,
	envId,
	id
}) {
	const response = await ApiService.get<AppDeployment>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateAppVariables: deploymentService["updateVariables"] = async function (payload) {
	const { orgId, projId, envId, id } = payload;
	const response = await ApiService.patch<success>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${id}/vars`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteAppVariables: deploymentService["deleteVariables"] = async function (payload) {
	const { orgId, projId, envId, id } = payload;

	const response = await ApiService.patch<success>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${id}/vars/delete`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateApplicationDeployment: deploymentService["updateAppDeployment"] =
	async function (request) {
		const { orgId, projId, envId, id } = request;

		const response = await ApiService.put<success>(
			`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${id}`,
			request
		);

		if (response.status === 200) {
			return response.data;
		}

		throw response;
	};

export const createApplicationDeployment: deploymentService["createAppDeployment"] =
	async function (request) {
		const { orgId, projId, envId } = request;

		const response = await ApiService.post<AppDeploymentId>(
			`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments`,
			request
		);

		if (response.status === 200) {
			return response.data;
		}

		throw response;
	};

export const cancelDeployment: deploymentService["cancelDeployment"] = async function (request) {
	const { orgId, projId, envId, depId, id } = request;

	const response = await ApiService.post<success>(
		`${DEPLOYMENT_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/deployments/${depId}/jobs/${id}/actions/cancel`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listOrgDeploymentTemplates: deploymentService["listOrgDeploymentTemplates"] =
	async function ({ orgId, provisioner }) {
		const response = await ApiService.get<pipelineModules>(
			`${DEPLOYMENT_API}/organizations/${orgId}/templates?provisioner=${provisioner}`
		);

		if (response.status === 200) {
			return response.data;
		}

		throw response;
	};
