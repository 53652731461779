<template>
	<span v-if="user" :data-qa-user-id="userId" data-qa-user-id-loaded>{{ user.email }}</span>

	<!-- Some pipeline triggers use ids like "vanguard-service-user" which don't have a real codepipes user -->
	<span v-else-if="!user && userId && !isUUID" :data-qa-user-id="userId" data-qa-user-id-loaded>{{
		userId
	}}</span>

	<Shimmer
		v-else-if="userId"
		data-qa-shimmer
		:data-qa-user-id="userId"
		data-qa-user-email-loading
		width="100px"
		height="1em"
	/>
</template>

<script lang="ts">
import { Shimmer } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { userStore } from "@/modules/user/user-store";
import { isUUID } from "@/utils";

export default defineComponent({
	name: "UserNameEmail",
	components: { Shimmer },

	props: {
		userId: {
			type: String
		}
	},

	computed: {
		user() {
			if (!this.userId) {
				return null;
			}

			return userStore.users[this.userId];
		},

		isUUID() {
			return this.userId && isUUID(this.userId);
		}
	},

	watch: {
		userId: {
			immediate: true,

			handler() {
				if (!this.user && this.userId && this.isUUID) {
					userStore.QUEUE_PUBLIC_PROFILE_FETCH({ userId: this.userId });
				}
			}
		}
	}
});
</script>

<style lang="scss" #scoped>
div.flow-shimmer[data-qa-shimmer] {
	display: inline-block;
	border-radius: 2px;
	vertical-align: bottom;
}
</style>
