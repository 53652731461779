<template>
	<Container direction="row" :gap="0" overflow="visible">
		<Container :padding="0" direction="column" :grow="1" align="left top" overflow="visible">
			<Typography
				data-qa-pipeline-log-error
				data-qa-job-error-message
				type="h5"
				weight="regular"
				color="danger-200"
				family="logs"
				>{{ errorText }}</Typography
			>
		</Container>

		<Button state="icon" size="small" @click="copyErrorToClipboard">
			<Icon
				v-tooltip="{ content: 'Copy to clipboard' }"
				:name="errorCopied ? 'i-tick' : 'i-copy'"
				color="gray-600"
				size="xx-small"
			/>
		</Button>
	</Container>
</template>
<script lang="ts">
import { Button, Container, Icon, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

export default defineComponent({
	name: "PipelineLogError",

	components: {
		Container,
		Typography,
		Icon,
		Button
	},

	props: {
		errors: {
			type: Array as PropType<string[]>,
			required: true
		}
	},

	data: () => ({
		errorCopied: false
	}),

	computed: {
		errorText() {
			return this.errors.join("\n");
		}
	},

	methods: {
		async copyErrorToClipboard() {
			await navigator.clipboard.writeText(this.errorText);
			this.errorCopied = true;
		}
	}
});
</script>
<style lang="scss">
.flow-text[data-qa-pipeline-log-error] {
	white-space: pre-wrap;
}

.flow-text[data-overflow="wrap"][data-qa-pipeline-log-error] {
	white-space: pre-wrap;
}
</style>
