import { jobLongResponse, jobStep } from "@/protocol/infra";
import {
	PipelineJobStatus,
	JOB_STATUS,
	JOB_STEP_STATUS,
	STAGECARD_STATUS,
	PipelineNode
} from "@/shared/pipeline-constants";
import { formatDateToRegularFormat, milliSecsToMinutesAndSeconds } from "@/utils/time-operations";

// See infra-service/service/job/common.go
export const ENVPIPELINE_STEPTITLES = {
	Plan: "plan",
	Validate: "validate",
	Apply: "apply",
	Destroy: "destroy",
	Sync: "sync"
};

export const updateJobSteps = ({
	nodes,
	job
}: {
	nodes: PipelineNode[];
	job: jobLongResponse;
}): PipelineNode[] => {
	const currentStep = job.progress ?? 0;
	const jobState = job.status as PipelineJobStatus;

	for (const [idx, node] of nodes.entries()) {
		if (idx > currentStep) {
			break;
		}

		node.infoIcon = { remove: true };

		// Previous steps must have been completed for the current one to be active
		if (idx < currentStep) {
			node.type = STAGECARD_STATUS[JOB_STATUS.DONE];
			node.status = JOB_STEP_STATUS[JOB_STATUS.DONE];
			node.statusText = getJobStatusText(JOB_STATUS.DONE, job, idx);
		} else {
			node.type = STAGECARD_STATUS[jobState];
			node.status = JOB_STEP_STATUS[jobState];
			node.statusText = getJobStatusText(jobState, job, idx);
		}
	}

	return nodes;
};

function getCompletedTime(jobState: PipelineJobStatus, jobDuration: number) {
	const duration = milliSecsToMinutesAndSeconds(jobDuration / 1000000);
	return `${jobState === JOB_STATUS.FAILED ? "Failed " : "Completed "} in ${duration}`;
}

export function getJobStatusText(jobState: PipelineJobStatus, job: jobLongResponse, idx: number) {
	const { steps } = job;
	const step = steps?.[idx];
	let doneFailedStatusText = "";

	if (step?.history?.duration) {
		doneFailedStatusText = getCompletedTime(jobState, Number(step.history.duration));
	} else {
		const subText = jobState === JOB_STATUS.FAILED ? "Failed at " : "Completed on ";
		doneFailedStatusText = job.updatedAt
			? `${subText}  ${formatDateToRegularFormat(Number(job.updatedAt) * 1000)}`
			: "";
	}

	if (jobState === JOB_STATUS.ACTIVE) {
		return job.createdAt
			? `Processing since ${formatDateToRegularFormat(Number(job.createdAt) * 1000)}`
			: "";
	} else if (jobState === JOB_STATUS.CANCELLED) {
		return job.updatedAt
			? `Cancelled at ${formatDateToRegularFormat(Number(job.updatedAt) * 1000)}`
			: "";
	} else if (jobState === JOB_STATUS.FAILED) {
		return doneFailedStatusText;
	} else if (jobState === JOB_STATUS.DONE) {
		return doneFailedStatusText;
	} else if (jobState === JOB_STATUS.WAITING) {
		return "Waiting...";
	}

	return "";
}

export const fetchOutputFilesForJob = (steps?: jobStep[]) => {
	const fileObj: Record<string, string> = {};

	steps?.forEach(step => {
		const stepName = ENVPIPELINE_STEPTITLES[step.name as keyof typeof ENVPIPELINE_STEPTITLES];

		if (!stepName) {
			return;
		}

		step.outputs?.forEach(fileName => {
			if (fileName.includes(".json")) {
				fileObj[stepName] = fileName;
			}
		});
	});

	return fileObj;
};
