<template>
	<Wrapper
		border-radius="4px"
		background="gray-500"
		width="432px"
		max-height="80vh"
		overflow="visible"
	>
		<Header>
			<Typography type="h4" color="dark">Confirm cancelation </Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Icon size="x-small" type="filled" name="i-close" @click="$emit('close')" />
			</Container>
		</Header>
		<Container :padding="16" :gap="16" align="left top" direction="column" overflow="visible">
			<Typography v-if="environment" type="p1-para" color="dark">
				Are you sure you want to cancel deploying {{ name }} in {{ environment }}?
			</Typography>
			<Typography v-else type="p1-para" color="dark">
				Are you sure you want to cancel deploying {{ name }}?
			</Typography>
		</Container>

		<Footer>
			<Button state="full" type="error" @click="$emit('confirm')"> CANCEL DEPLOYMENT </Button>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { Header, Typography, Container, Icon, Footer, Button, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "ConfirmCancel",

	components: {
		Header,
		Typography,
		Container,
		Icon,
		Footer,
		Button,
		Wrapper
	},

	props: {
		/**
		 * Name of deployment
		 */
		name: {
			type: String,
			required: true
		},

		/**
		 * Env Name of deployment
		 */
		environment: {
			type: String
		}
	}
});
</script>
