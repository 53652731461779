<template>
	<Container :padding="0" :gap="0" class="height-100-per" direction="column" align="left top">
		<Divider v-if="showHistory" :size="3" state="secondary" class="flex-0" />

		<Container :padding="0" :gap="0" :grow="1" align="left stretch">
			<Wrapper :style="{ minWidth: `${sideBarWidth}px`, width: `${sideBarWidth}px` }">
				<Container padding="0" direction="column" align="left top" :gap="0">
					<slot name="header" />
					<Divider />
					<slot v-if="!isLoading" name="stage-view" />
				</Container>
			</Wrapper>

			<Divider direction="vertical" :size="2" :resize-siblings="true" :min-resize="sideBarWidth" />

			<Container padding="0" :grow="1" overflow="hidden">
				<CustomLoader v-if="isLoading" />
				<slot v-else name="logs-view" />
			</Container>

			<Divider v-if="showHistory" direction="vertical" :size="2" />

			<Transition name="slide-left">
				<Wrapper
					v-if="showHistory"
					:style="{ minWidth: `${sideBarWidth}px`, width: `${sideBarWidth}px` }"
				>
					<slot name="pipeline-history" />
				</Wrapper>
			</Transition>
		</Container>
	</Container>
</template>

<script lang="ts">
import { Wrapper, Container, Divider } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import CustomLoader from "@/shared/components/CustomLoader.vue";

export default defineComponent({
	name: "PipelineWrapper",
	components: { Wrapper, Container, Divider, CustomLoader },

	props: {
		showHistory: Boolean,
		isLoading: Boolean,

		sideBarWidth: {
			type: Number,
			default: 320
		}
	}
});
</script>
