import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
	cancelDeployment,
	createApplicationDeployment,
	deleteAppDeployment,
	deleteAppVariables,
	getAppDeployment,
	getAppDeploymentStatus,
	getDeploymentHistory,
	getDeploymentTemplates,
	getJobArtifact,
	listAppDeploymentsForApp,
	listAppDeploymentsForEnv,
	listAppDeploymentsForProject,
	startAppDeployment,
	updateApplicationDeployment,
	updateAppVariables,
	listOrgDeploymentTemplates
} from "@/modules/application-deployment/services/application-deployment-service";
import { appBuild } from "@/protocol/app";
import { ArtifactSelector } from "@/protocol/common";
import {
	AppDeployment,
	AppDeploymentCreateRequest,
	AppDeploymentId,
	AppDeploymentUpdateRequest,
	DeploymentJobAction,
	jobId as jobIdProto,
	jobResponse,
	ListOrgDeploymentTemplateRequest,
	TemplateTarget
} from "@/protocol/deployment";
import { pipelineModule } from "@/protocol/pipeline";
import {
	JOB_STATUS,
	JOB_STEP_STATUS,
	PipelineEdge,
	PipelineJobStatus,
	PipelineNode,
	STAGECARD_STATUS
} from "@/shared/pipeline-constants";
import { captureError, ENVIRONMENTS, getEnvironment } from "@/utils";

import { listAppBuilds } from "../application/application-service";
import { getJobStatusText } from "../env-pipeline/utils";
import { getStore } from "../store";

import { CurrentAppJob, DeploymentVar, IAppPipelineLogs } from "./store-types";

@Module({
	namespaced: true,
	dynamic: true,
	name: "applicationDeployment",
	store: getStore()
})
class ApplicationDeploymentStore extends VuexModule {
	appDeploymentTemplates: Record<string, pipelineModule[]> = {};
	orgAppDeploymentTemplates: Record<string, pipelineModule[]> = {};
	allDeployments: Record<string, AppDeployment> = {};
	allAppJobs: CurrentAppJob[] = [];
	logs: IAppPipelineLogs | null = null;
	appBuilds: Record<string, appBuild[]> = {};

	get deploymentsInEnv() {
		const deploymentsInEnv: Record<string, AppDeployment[]> = {};

		Object.values(this.allDeployments).forEach(deployment => {
			if (!deployment.envId) {
				return;
			}

			if (!deploymentsInEnv[deployment.envId]) {
				deploymentsInEnv[deployment.envId] = [];
			}

			deploymentsInEnv[deployment.envId]?.push(deployment);
		});

		return deploymentsInEnv;
	}

	get deploymentsInApp() {
		const deploymentsInApp: Record<string, AppDeployment[]> = {};

		Object.values(this.allDeployments).forEach(deployment => {
			if (!deployment.appId) {
				return;
			}

			if (!deploymentsInApp[deployment.appId]) {
				deploymentsInApp[deployment.appId] = [];
			}

			deploymentsInApp[deployment.appId]?.push(deployment);
		});

		return deploymentsInApp;
	}

	@Mutation
	RESET_APPLICATION_DEPLOYMENT() {
		this.appDeploymentTemplates = {};
		this.allDeployments = {};
		this.allAppJobs = [];
		this.logs = null;
	}

	@Mutation
	SET_APP_DEPLOYMENTS(deployments?: AppDeployment[]) {
		deployments?.forEach(deployment => {
			if (!deployment.id) {
				return;
			}

			this.allDeployments[deployment.id] = deployment;

			if (deployment.lastJob && deployment.envId) {
				// Can't use SET_APP_JOB mutation here because vuex-module-decorators doesn't seem
				// to allow mutations to be called from within a mutation.
				this.allAppJobs = this.allAppJobs.filter(vJob => vJob.id !== deployment.lastJob?.id);
				this.allAppJobs.push({
					...deployment.lastJob,
					envId: deployment.envId,
					appId: deployment.appId
				});
			}
		});
	}

	@Mutation
	SET_APP_JOB(appJob: CurrentAppJob) {
		this.allAppJobs = this.allAppJobs.filter(vJob => vJob.id !== appJob.id);
		this.allAppJobs.push({ ...appJob });
	}

	@Mutation
	SET_APP_DEPLOYMENTS_TEMPLATES({
		envId,
		templates
	}: {
		envId: string;
		templates?: pipelineModule[];
	}) {
		if (!templates) {
			return;
		}

		this.appDeploymentTemplates[envId] = templates;
	}

	@Mutation
	SET_ORG_APP_DEPLOYMENTS_TEMPLATES({
		orgId,
		templates
	}: {
		orgId: string;
		templates?: pipelineModule[];
	}) {
		if (!templates) {
			return;
		}

		this.orgAppDeploymentTemplates[orgId] = templates;
	}
	@Mutation
	SET_APP_DEPLOYMENT_LOGS({
		logs,
		artifactName,
		jobId
	}: {
		logs?: unknown;
		artifactName: string;
		jobId: string;
	}) {
		if (!logs) {
			return;
		}

		if (!this.logs) {
			this.logs = {};
		}

		const existingLogs = this.logs[jobId] ?? {};

		this.logs[jobId] = {
			...existingLogs,
			[artifactName]: logs
		};
	}

	@Mutation
	REMOVE_APP_DEPLOYMENT_FROM_STORE(id: string) {
		delete this.allDeployments[id];
	}

	@Action
	async LIST_ENV_APP_DEPLOYMENTS({
		orgId,
		projectId,
		envId
	}: {
		orgId: string;
		projectId: string;
		envId: string;
	}) {
		const res = await listAppDeploymentsForEnv({ orgId, projId: projectId, envId });
		this.SET_APP_DEPLOYMENTS(res.AppDeployments);
	}

	@Action
	async LIST_PROJECT_APP_DEPLOYMENTS({ orgId, projectId }: { orgId: string; projectId: string }) {
		const res = await listAppDeploymentsForProject({ orgId, projId: projectId });
		this.SET_APP_DEPLOYMENTS(res.AppDeployments);
	}

	@Action
	async CREATE_APPLICATION_DEPLOYMENT(payload: AppDeploymentCreateRequest) {
		const response = await createApplicationDeployment(payload);
		await this.GET_APP_DEPLOYMENT(response);
		return response;
	}

	@Action
	async UPDATE_DEPLOYMENT_VARIABLES({
		deployment,
		variables,
		fetchDeployment = true
	}: DeploymentUpdateVariablesRequest) {
		const { orgId, projId, envId, id: deploymentId, deploymentConfig } = deployment;

		if (!orgId || !projId || !envId || !deploymentId || !deploymentConfig) {
			return;
		}

		const { env, sensitive } = deploymentConfig;

		const envArr = Object.entries(env ?? {});
		const sensitiveArr = Object.entries(sensitive ?? {});

		const enteredVariableKeys = variables.map(variable => variable.key);

		const deletedEnvVariables = envArr
			.filter(([key]) => !enteredVariableKeys.includes(key))
			.map(([key]) => key);

		const deletedSensitiveVariables = sensitiveArr
			.filter(([key]) => !enteredVariableKeys.includes(key))
			.map(([key]) => key);

		if (deletedEnvVariables.length > 0 || deletedSensitiveVariables.length > 0) {
			await deleteAppVariables({
				orgId,
				projId,
				envId,
				id: deploymentId,
				env: deletedEnvVariables,
				sensitive: deletedSensitiveVariables
			});
		}

		const editableVariables = variables.filter(variable => variable.canEdit);

		if (editableVariables.length > 0) {
			await updateAppVariables({
				orgId,
				projId,
				envId,
				id: deploymentId,
				...getEnvMapFromVariables(variables)
			});
		}

		if (fetchDeployment) {
			await this.GET_APP_DEPLOYMENT({
				id: deploymentId,
				envId,
				orgId,
				projId
			});
		}
	}

	@Action
	async UPDATE_APPLICATION_DEPLOYMENT(payload: AppDeploymentUpdateRequest) {
		const { orgId, projId, envId, appId, id } = payload;

		const application = this.deploymentsInApp[appId]?.find(deployment => deployment.id === id);

		if (!application) {
			throw new Error(`No application found with ${id}`);
		}

		// Update config finally
		const response = await updateApplicationDeployment(payload);

		await this.GET_APP_DEPLOYMENT({
			id,
			envId,
			orgId,
			projId
		});

		return response;
	}

	@Action
	async GET_APP_DEPLOYMENTS({ orgId, projId, id }: { orgId: string; projId: string; id: string }) {
		const res = await listAppDeploymentsForApp({ orgId, projId, appId: id });

		this.SET_APP_DEPLOYMENTS(res.AppDeployments);

		return res.AppDeployments;
	}

	@Action
	async GET_APP_BUILDS({ orgId, projId, id }: { orgId: string; projId: string; id: string }) {
		const builds = await listAppBuilds({
			appId: id,
			orgId,
			projId
		});

		this.SET_APP_BUILDS({ appId: id, builds: builds.builds });
	}

	@Mutation
	SET_APP_BUILDS({ appId, builds }: { appId: string; builds?: appBuild[] }) {
		if (builds) {
			this.appBuilds[appId] = builds;
		} else {
			delete this.appBuilds[appId];
		}
	}

	@Action
	async START_APP_PIPELINE({
		orgId,
		projectId,
		envId,
		depId,
		jobType,
		artifact
	}: {
		orgId: string;
		projectId: string;
		envId: string;
		depId: string;
		jobType: DeploymentJobAction;
		artifact?: ArtifactSelector;
	}) {
		const jobId = await startAppDeployment({
			orgId,
			projId: projectId,
			envId,
			id: depId,
			jobType,
			artifact
		});

		await this.GET_APP_JOB(jobId);

		await this.GET_APP_DEPLOYMENT({
			id: depId,
			envId,
			orgId,
			projId: projectId
		});

		return jobId;
	}

	@Action
	async GET_APP_DEPLOYMENT(request: AppDeploymentId) {
		const deployment = await getAppDeployment(request);
		this.SET_APP_DEPLOYMENTS([deployment]);
		return deployment;
	}

	@Action
	async GET_APP_DEPLOYMENT_HISTORY({
		orgId,
		projId,
		envId,
		depId,
		limit = 1
	}: {
		orgId: string;
		projId: string;
		envId: string;
		depId: string;
		limit?: number;
	}) {
		const { jobs } = await getDeploymentHistory({ orgId, projId, envId, id: depId, limit });

		let deployment = Object.values(this.allDeployments).find(
			deployment_ => deployment_.id === depId && deployment_.envId === envId
		);

		if (!deployment) {
			deployment = await this.GET_APP_DEPLOYMENT({ orgId, projId, envId, id: depId });
		}

		const { appId } = deployment;

		return jobs?.map(job => {
			const appJob: CurrentAppJob = {
				...job,
				envId,
				appId
			};

			this.SET_APP_JOB(appJob);

			return appJob;
		});
	}

	@Action
	async GET_APP_JOB(params: jobIdProto) {
		const { orgId, projId, envId, depId } = params;
		const job = await getAppDeploymentStatus(params);

		let deployment = Object.values(this.allDeployments).find(
			deployment_ => deployment_.id === depId && deployment_.envId === envId
		);

		if (!deployment) {
			deployment = await this.GET_APP_DEPLOYMENT({ orgId, projId, envId, id: depId });
		}

		const appJob: CurrentAppJob = {
			...job,
			envId,
			appId: deployment.appId
		};

		this.SET_APP_JOB(appJob);

		return job;
	}

	@Action
	async GET_APP_DEPLOYMENT_LOGS({
		orgId,
		projId,
		envId,
		depId,
		jobId,
		artifactName
	}: {
		orgId: string;
		projId: string;
		envId: string;
		depId: string;
		jobId: string;
		artifactName: string;
	}) {
		try {
			const logs = await getJobArtifact({
				artifactName,
				job: { orgId, projId, envId, depId, id: jobId }
			});

			this.SET_APP_DEPLOYMENT_LOGS({
				logs,
				artifactName,
				jobId
			});
		} catch (error) {
			captureError(error);
		}
	}

	@Action
	async FETCH_DEPLOYMENT_TEMPLATES({
		orgId,
		projId,
		envId
	}: {
		orgId: string;
		projId: string;
		envId: string;
	}) {
		const target: TemplateTarget[] = [TemplateTarget.deploymentTemplate];

		if (getEnvironment() !== ENVIRONMENTS.PRODUCTION) {
			target.push(TemplateTarget.test);
		}

		const res = await getDeploymentTemplates({ orgId, projId, envId, target });
		this.SET_APP_DEPLOYMENTS_TEMPLATES({ envId, templates: res.modules });

		return res.modules;
	}

	@Action
	async DELETE_APPLICATION_DEPLOYMENT({
		orgId,
		projectId,
		envId,
		depId
	}: {
		orgId: string;
		projectId: string;
		envId: string;
		depId: string;
	}) {
		const res = await deleteAppDeployment({
			orgId,
			projId: projectId,
			envId,
			id: depId,
			force: false
		});

		const isDeleted = res.success;

		if (isDeleted) {
			const depEntitesInEnv = [...(this.deploymentsInEnv[envId] ?? [])];
			const foundIndex = depEntitesInEnv.findIndex((dep: AppDeployment) => dep.id === depId);
			depEntitesInEnv.splice(foundIndex, 1);

			this.SET_APP_DEPLOYMENTS(depEntitesInEnv);
			this.REMOVE_APP_DEPLOYMENT_FROM_STORE(depId);
		}

		return res;
	}

	@Action
	async CANCEL_DEPLOYMENT({ id, envId, orgId, projId, depId }: jobIdProto) {
		const response = await cancelDeployment({
			id,
			envId,
			orgId,
			projId,
			depId
		});

		if (response.success) {
			await this.GET_APP_DEPLOYMENT({
				id: depId,
				envId,
				orgId,
				projId
			});
		}
	}

	@Action
	async FETCH_ORG_APP_DEPLOYMENT_TEMPLATES({
		orgId,
		provisioner
	}: ListOrgDeploymentTemplateRequest) {
		const res = await listOrgDeploymentTemplates({ orgId, provisioner });
		this.SET_ORG_APP_DEPLOYMENTS_TEMPLATES({ orgId, templates: res.modules });
		return res.modules;
	}
}

const applicationDeploymentStore = getModule(ApplicationDeploymentStore);

export type DeploymentUpdateVariablesRequest = {
	deployment: AppDeployment;
	variables: DeploymentVar[];
	fetchDeployment?: boolean;
};

export { applicationDeploymentStore };

export function getAppDagMeta(job: jobResponse) {
	const edges: PipelineEdge[] = [];
	const nodes: PipelineNode[] = [];

	const jobState = job.jobStatus as PipelineJobStatus;
	const isJobActive = jobState === JOB_STATUS.ACTIVE;

	// Create initial steps and nodes for the app pipeline jobs.
	job.steps?.forEach((step, index) => {
		const nextStep = job.steps?.[index + 1];

		if (nextStep && step.name && nextStep.name) {
			edges.push({
				source: step.name,
				target: nextStep.name
			});
		}

		nodes.push({
			name: step.name,
			nodeNumber: [index + 1].toString(),
			type: "standby",
			statusText: isJobActive ? "Waiting..." : "",
			status: "Not Started",
			selected: index === 0 ? true : false,
			infoIcon: {
				remove: true
			}
		});

		// Update the nodes and edges with actual job data.
		const currentStep = job.progress ?? 0;

		for (const [idx, node] of nodes.entries()) {
			if (idx > currentStep) {
				break;
			}

			// Mark all previous step complete
			if (idx < currentStep) {
				node.type = STAGECARD_STATUS[JOB_STATUS.DONE];
				node.status = JOB_STEP_STATUS[JOB_STATUS.DONE];
				node.statusText = getJobStatusText(JOB_STATUS.DONE, job, idx);
			} else {
				// Mark the current one with respective job status
				node.type = STAGECARD_STATUS[jobState];
				node.status = JOB_STEP_STATUS[jobState];
				node.statusText = getJobStatusText(jobState, job, idx);
			}
		}
	});
	return { edges, nodes };
}

export function getEnvMapFromVariables(variables: DeploymentVar[]) {
	const env: Record<string, string> = {};
	const sensitive: Record<string, string> = {};

	variables.forEach(({ key, value, sensitive: isSensitive, canEdit }) => {
		if (!canEdit) {
			return;
		}

		const trimmedKey = key.trim();
		const trimmedValue = value.trim();
		if (isSensitive) {
			sensitive[trimmedKey] = trimmedValue;
		} else {
			env[trimmedKey] = trimmedValue;
		}
	});

	return { env, sensitive };
}
