<template>
	<AuthWrapper
		header="Thank you for signing up!"
		:show-back-icon="true"
		@back-click="redirectMixin({ routeName: 'signup' })"
	>
		<Section padding="bottom">
			<p class="fc-normal paragraph-1 line-h-24 margin-tp-12">
				To complete your sign-up, we need to verify your email address. We've sent you a
				verification email on
				<span class="fc-dark fw-bold">
					{{ params.email }}
				</span>
			</p>
			<p class="fc-normal paragraph-1 line-h-24 margin-tp-12">
				If you can't find the email, check your spam folder or
				<span class="cursor-pointer fc-primary">
					<a @click="redirectMixin({ routeName: 'verification-email' })"
						>resend the verification email.</a
					>
				</span>
			</p>
			<p class="fc-normal paragraph-1 margin-tp-24">
				Already verified?
				<span class="cursor-pointer fc-primary">
					<a data-qa-verify-login @click="redirectMixin({ routeName: 'login' })">Log in.</a>
				</span>
			</p>
		</Section>
	</AuthWrapper>
</template>

<script>
import { Section } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import AuthWrapper from "@/modules/auth/views/AuthWrapper.vue";
import routerMixin from "@/shared/mixins/routerMixin";

export default defineComponent({
	name: "SignUpSuccess",
	components: { Section, AuthWrapper },
	mixins: [routerMixin],

	props: {
		params: {
			type: Object,
			required: true
		}
	}
});
</script>
