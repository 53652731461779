<template>
	<div class="display-contents">
		<form>
			<f-form-builder
				ref="formBuilder"
				:field.prop="formFields"
				:values.prop="formValues"
				@input="handleInput"
				@state-change="formState = $event.detail"
			/>
		</form>

		<Container padding="16px 24px 0px 24px" align="center">
			<f-button
				data-qa-reset-password
				label="Reset password"
				:loading="isSubmitting"
				@click="resetPassword"
			/>
		</Container>
	</div>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { Container } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { getErrorMessage } from "@/utils";

import { resetPassword } from "../services/auth-service";

export default defineComponent({
	name: "ResetPasswordForm",

	components: {
		Container
	},

	props: {
		resetCode: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			isSubmitting: false,
			submitError: "",
			formValues: {} as FormValues,
			formState: null as FormBuilderState | null
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					password: {
						type: "password",
						qaId: "password",
						label: { title: "New Password" },
						autocomplete: "new-password",
						placeholder: "Enter new password",
						validationRules: [
							{ name: "required" },
							{ name: "min", params: { length: 7 } },
							{
								name: "custom",
								validate: (value?: string | unknown[]) => {
									return this.formValues.confirmPassword === value;
								},

								message: "The New Password field confirmation does not match"
							}
						],

						helperText: this.submitError
							? html`<f-text
									size="small"
									state="danger"
									data-qa-error-for="password"
									data-qa-reset-password-error
									>${this.submitError}</f-text
							  >`
							: ""
					},

					confirmPassword: {
						type: "password",
						autocomplete: "new-password",
						qaId: "confirmPassword",
						label: { title: "Confirm Password" },
						placeholder: "Enter confirm password",
						validationRules: [{ name: "required" }]
					}
				}
			};
		}
	},

	methods: {
		handleInput(event: CustomEvent<FormValues>) {
			this.submitError = "";
			this.formValues = event.detail;
		},

		async resetPassword() {
			this.submitError = "";
			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();

			if (this.isSubmitting || !this.formState?.isValid) {
				return;
			}

			this.isSubmitting = true;

			try {
				await resetPassword({
					password: this.formValues.password,
					code: this.resetCode
				});

				this.$router.push({
					name: "authAfterStage",
					params: {
						// Email param is required in route but not needed for the
						// ResetPasswordSuccess component
						email: "-",
						componentName: "ResetPasswordSuccess"
					}
				});
			} catch (error: unknown) {
				this.submitError = getErrorMessage(error, true);
			} finally {
				this.isSubmitting = false;
			}

			return false;
		}
	}
});

type FormValues = {
	password: string;
	confirmPassword: string;
};
</script>
