/* eslint-disable */
import {
	Artifact,
	ArtifactSelector,
	Dependency,
	Provisioner,
	BuildStatus,
	JobStep,
	DependencyDiff,
	AllVariableViewIndex
} from "./common";
import { HealthCheckRequest, HealthCheckResponse } from "./health";
import { pipelineModules } from "./pipeline";

export enum TemplateTarget {
	deploymentTemplate = "deploymentTemplate",
	test = "test",
	config = "config"
}

export enum DeploymentJobAction {
	invalid_job = "invalid_job",
	deploy = "deploy",
	undeploy = "undeploy"
}

export interface environment {
	id?: string;
}

export interface JobUpdateEventData {
	view?: jobResponse;
	environment?: environment;
}

export interface pipelineModuleReference {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	moduleRefId: string;
	inputs?: Record<string, unknown>;
}

export interface ConfigFile {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	mount: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	isSensitive?: boolean;
}

export interface Summary {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	pattern?: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	command: string;
}

export interface ViewDeploymentConfig {
	artifact?: Artifact;
	template?: pipelineModuleReference;
	env?: Record<string, string>;
	sensitive?: Record<string, string>;
	configFiles?: ConfigFile[];
	summary?: Summary[];
	pipelineConfig?: Record<string, unknown>;
}

export interface DeploymentConfig {
	artifact?: ArtifactSelector;
	template?: pipelineModuleReference;
	env?: Record<string, string>;
	sensitive?: Record<string, string>;
	configFiles?: ConfigFile[];
	summary?: Summary[];
	pipelineConfig?: Record<string, unknown>;
}

export interface AppDeploymentCreateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	appId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	deploymentConfig?: DeploymentConfig;
}

export interface AppDeploymentUpdateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	appId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	deploymentConfig?: DeploymentConfig;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// replaceVariables
	//
	// If false, the variables will not be updated. If true, all the existing
	// variables will be replaced with the given set.
	replaceVariables?: boolean;
}

export interface AppDeploymentDeleteRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	force?: boolean;
}

export interface AppDeploymentEntitiesDeleteRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	force?: boolean;
}

export interface AppDeploymentBeforeCredChange {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	force?: boolean;
}

export interface AppDeploymentId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
}

export interface AppDeployment {
	orgId?: string;
	projId?: string;
	envId?: string;
	appId?: string;
	name?: string;
	description?: string;
	deploymentConfig?: ViewDeploymentConfig;
	id?: string;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
	lastJob?: jobResponse;
	lastSuccessfulJob?: jobResponse;
	// map of dependency given name -> dependency detail including resolution id
	dependencies?: Record<string, Dependency>;
}

export interface ListAppDeploymentsRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
}

export interface ListProjectDeploymentsRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
}

export interface ListDeploymentTemplateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	target?: TemplateTarget[];
}

export interface ListOrgDeploymentTemplateRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// envTemplateId will be used to fetch the deployment defaults
	// and will incorporate those while listing the templates
	// optional
	envTemplateId?: string;
	// provisioner value will be used to fetch the cloud specific templates
	// optional
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true}}}]
	provisioner: Provisioner;
}

export interface AppDeployments {
	AppDeployments?: AppDeployment[];
}

export interface success {
	success?: boolean;
}

export interface StartDeploymentRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	jobType: DeploymentJobAction;
	artifact?: ArtifactSelector;
}

export interface DeploymentPromoteRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	version: string;
}

export interface promotedVersion {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	appId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	deploymentId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	version: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	deploymentJobId: string;
	updatedBy?: string;
	createdAt?: string;
	updatedAt?: string;
	versionAliases?: string[];
}

export interface promotedVersions {
	items?: promotedVersion[];
}

export interface ListDeploymentHistoryRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	limit?: number;
	action?: DeploymentJobAction;
}

export interface jobId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	depId: string;
}

export interface DeleteConfigFileRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	filename: string;
}

export interface jobResponse {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	message?: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	depId: string;
	pipelineStatus?: BuildStatus;
	createdAt?: string;
	updatedAt?: string;
	jobStatus?: string;
	buildId?: string;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	jobType: DeploymentJobAction;
	summary?: Record<string, unknown>;
	totalSteps?: number;
	progress?: number;
	steps?: JobStep[];
	deployVersion?: string;
	dependencyDiff?: DependencyDiff;
	waitingFor?: string[];
	artifactOverride?: ArtifactSelector;
	updatedBy?: string;
}

export interface jobListRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	depId: string;
}

export interface ListDeploymentsRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	appId: string;
}

export interface jobs {
	jobs?: jobResponse[];
}

export interface logResponse {
	logData?: string;
}

export interface uploadDepCfgFileRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	mount: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	isSensitive?: boolean;
	// base 64 encoded string corresponding to file
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	data: string;
}

export interface downloadDepCfgFileRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	mount: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
}

export interface downloadDepCfgFileResponse {
	// base 64 encoded string corresponding to file
	data?: string;
}

export interface updateVariablesRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	env?: Record<string, string>;
	sensitive?: Record<string, string>;
}

export interface deleteVariablesRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	env?: string[];
	sensitive?: string[];
}

export interface artifactRequest {
	job?: jobId;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	artifactName: string;
}

export interface createDeploymentDefaults {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envTemplateId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	moduleRefId: string;
	templateVars?: Record<string, unknown>;
}

export interface updateDeploymentDefaults {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	envTemplateId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	moduleRefId: string;
	templateVars?: Record<string, unknown>;
}

export interface deploymentDefaults {
	id?: string;
	orgId?: string;
	envTemplateId?: string;
	moduleRefId?: string;
	templateVars?: Record<string, unknown>;
	// audit info
	// User ID of the last person to update the webhook.
	updatedBy?: string;
	// Date the webhook was created, expressed as an integer in epoch timestamp format. Example:  `1607065223`
	createdAt?: string;
	// Date the webhook was updated, expressed as an integer in epoch timestamp format. Example: `1607065223`
	updatedAt?: string;
}

export interface deploymentDefaultsId {
	id?: string;
	orgId?: string;
	envTemplateId?: string;
	moduleRefId?: string;
}

export interface listDeploymentDefaults {
	deploymentDefaults?: deploymentDefaults[];
}

export interface deploymentService {
	// Service health check
	// Options: [{"(google.api.http)":{"get":"/v0/healthcheck/*"}}]
	healthCheck(request: HealthCheckRequest): Promise<HealthCheckResponse>;

	// Create a deployment
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments","body":"*"}}]
	createAppDeployment(request: AppDeploymentCreateRequest): Promise<AppDeploymentId>;

	// Update a deployment
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}","body":"*"}}]
	updateAppDeployment(request: AppDeploymentUpdateRequest): Promise<success>;

	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}"}}]
	getAppDeployment(request: AppDeploymentId): Promise<AppDeployment>;

	// Delete a deployment
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}"}}]
	deleteAppDeployment(request: AppDeploymentDeleteRequest): Promise<success>;

	// Delete all child entities of this deployment
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/delete-all"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Delete all entities owned by this deployment."}}]
	deleteAppDeploymentEntities(request: AppDeploymentEntitiesDeleteRequest): Promise<success>;

	// BeforeAppDeploymentCredChange runs cleanup tasks before creds get changed on this deployment
	beforeAppDeploymentCredChange(request: AppDeploymentBeforeCredChange): Promise<success>;

	// List deployment templates
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/templates"}}]
	listDeploymentTemplates(request: ListDeploymentTemplateRequest): Promise<pipelineModules>;

	// List deployment templates at org level
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/templates"}}]
	listOrgDeploymentTemplates(request: ListOrgDeploymentTemplateRequest): Promise<pipelineModules>;

	// Start a deployment pipeline
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/jobs","body":"*"}}]
	startDeployment(request: StartDeploymentRequest): Promise<jobId>;

	// Cancel a deployment pipeline
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{depId=*}/jobs/{id=*}/actions/cancel","body":"*"}}]
	cancelDeployment(request: jobId): Promise<success>;

	// Promote a deployment
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/promotions","body":"*"}}]
	promoteDeployment(request: DeploymentPromoteRequest): Promise<jobId>;

	// Get promotable version of a deployment
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/promotions"}}]
	getPromotableVersions(request: AppDeploymentId): Promise<promotedVersions>;

	// Get status of a deployment pipeline
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{depId=*}/jobs/{id=*}"}}]
	deploymentStatus(request: jobId): Promise<jobResponse>;

	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/jobs"}}]
	deploymentHistory(request: ListDeploymentHistoryRequest): Promise<jobs>;

	// Get the logs from a deployment pipeline
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{depId=*}/jobs/{id=*}/logs"}}]
	getDeploymentLogs(request: jobId): Promise<logResponse>;

	// Get an artifact from a pipeline
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{job.orgId=*}/projects/{job.projId=*}/envs/{job.envId=*}/deployments/{job.depId=*}/jobs/{job.id=*}/artifacts/{artifactName=*}"}}]
	getJobArtifact(request: artifactRequest): Promise<Record<string, any>>;

	// Delete a configuration file
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/files/{filename=*}"}}]
	deleteConfigFile(request: DeleteConfigFileRequest): Promise<success>;

	// Upload a deployment configuration
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/depConfig","body":"*"}}]
	uploadDeploymentConfig(request: uploadDepCfgFileRequest): Promise<success>;

	// Download a deployment configuration
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/depConfig"}}]
	downloadDeploymentConfig(request: downloadDepCfgFileRequest): Promise<downloadDepCfgFileResponse>;

	// Update one or more environment variables
	// Options: [{"(google.api.http)":{"patch":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/vars","body":"*"}}]
	updateVariables(request: updateVariablesRequest): Promise<success>;

	// Delete one or more environment variables
	// Options: [{"(google.api.http)":{"patch":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/vars/delete","body":"*"}}]
	deleteVariables(request: deleteVariablesRequest): Promise<success>;

	// List deployments by application
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/apps/{appId=*}/deployments"}}]
	listDeployments(request: ListDeploymentsRequest): Promise<AppDeployments>;

	// List deployments by environment
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments"}}]
	listAppDeployments(request: ListAppDeploymentsRequest): Promise<AppDeployments>;

	// List deployments by project
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/deployments"}}]
	listProjectDeployments(request: ListProjectDeploymentsRequest): Promise<AppDeployments>;

	// Create a deploymentDefault
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/deploymentdefaults","body":"*"}}]
	createDeploymentDefaults(request: createDeploymentDefaults): Promise<deploymentDefaultsId>;

	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/deploymentdefaults/{id=*}"}}]
	getDeploymentDefaults(request: deploymentDefaultsId): Promise<deploymentDefaults>;

	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/deploymentdefaults"}}]
	listDeploymentDefaults(request: deploymentDefaultsId): Promise<listDeploymentDefaults>;

	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/deploymentdefaults/{id=*}"}}]
	deleteDeploymentDefaults(request: deploymentDefaultsId): Promise<success>;

	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/deploymentdefaults/{id=*}","body":"*"}}]
	updateDeploymentDefaults(request: updateDeploymentDefaults): Promise<deploymentDefaults>;

	// GetDeploymentVariables get index of all variables upto app-deployment level by deployment id.
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/envs/{envId=*}/deployments/{id=*}/vars"}}]
	getDeploymentVariables(request: AppDeploymentId): Promise<AllVariableViewIndex>;

	// GetProjectVariables get index of all variables upto app-deployment level by project id.
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projId=*}/vars"}}]
	getProjectVariables(request: ListProjectDeploymentsRequest): Promise<AllVariableViewIndex>;
}
