<script lang="ts">
import { Accordion, Container, Icon, Tag, Typography } from "@cldcvr/flow-vue3";
import { PropType, computed, ref, toRefs } from "vue";

import { AppDeployment } from "@/protocol/deployment";
import { isLink } from "@/utils";

export default {
	name: "DeploymentsListOutputs",
	components: { Tag }
};
</script>

<script lang="ts" setup>
const props = defineProps({
	deployment: {
		type: Object as PropType<AppDeployment>,
		required: true
	}
});

const { deployment } = toRefs(props);

const isOutputAccordionOpen = ref(true);

const selectedSummary = computed(
	() => deployment.value.lastJob?.summary as Record<string, string> | undefined
);

const selectedSummaryCount = computed(() => {
	return selectedSummary.value ? Object.keys(selectedSummary.value).length : 0;
});
</script>

<template>
	<Accordion :open="isOutputAccordionOpen" data-qa-deployment-outputs>
		<template #header>
			<Container
				align="space-between center"
				overflow="visible"
				padding="20px 8px"
				:clickable="true"
				@click="isOutputAccordionOpen = !isOutputAccordionOpen"
			>
				<Container :padding="0" :gap="8">
					<Typography type="h4" weight="medium" color="dark">Output</Typography>
					<Tag
						size="small"
						shape="rounded"
						:data-qa-deployment-outputs-count="selectedSummaryCount"
						>{{ selectedSummaryCount }}</Tag
					>
				</Container>
				<Icon
					name="i-chevron-down"
					size="x-small"
					:rotate="isOutputAccordionOpen ? 180 : 0"
					@click.stop="isOutputAccordionOpen = !isOutputAccordionOpen"
				/>
			</Container>
		</template>

		<Container
			v-if="!selectedSummary || !Object.keys(selectedSummary).length"
			align="center"
			padding="10px 0 40px 0"
			data-qa-app-widget-output
			data-qa-app-widget-output-empty
			direction="column"
			class="align-items-center"
			:gap="12"
			:grow="1"
		>
			<Icon name="i-launch" type="filled" state="light" />
			<Typography type="p1" color="light">No output available</Typography>
		</Container>

		<Container v-else data-qa-app-widget-output :padding="16" overflow="auto" align="stretch top">
			<table class="key-value" data-key-size="25" data-row-gutter="16px">
				<thead>
					<tr>
						<th>
							<Container align="left center" :padding="0" :gap="4">
								<Typography type="p4" transform="uppercase" color="light">key</Typography>
							</Container>
						</th>
						<th>
							<Container align="left center" :padding="0">
								<Typography type="p4" transform="uppercase" weight="light" color="light"
									>value</Typography
								>
							</Container>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(key, i) in Object.keys(selectedSummary)"
						:key="i"
						:data-qa-deployment-output-key="key"
						:data-qa-deployment-output-value="selectedSummary[key]"
					>
						<td>
							<Typography type="p2" color="dark" weight="medium">{{ key }}</Typography>
						</td>
						<td>
							<Typography
								v-if="isLink(selectedSummary[key])"
								type="p2"
								color="primary"
								:link="true"
							>
								<a class="fc-primary" rel="noopener" :href="selectedSummary[key]" target="_blank">{{
									selectedSummary[key]
								}}</a>
							</Typography>
							<Typography
								v-if="!isLink(selectedSummary[key])"
								type="p2"
								color="primary"
								:link="true"
							>
								{{ selectedSummary[key] }}
							</Typography>
						</td>
					</tr>
				</tbody>
			</table>
		</Container>
	</Accordion>
</template>
