<template>
	<Wrapper>
		<CustomLoader />
	</Wrapper>
</template>

<script lang="ts">
import { Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { userStore } from "@/modules/user/user-store";
import { TokenService } from "@/services/storage-service";
import CustomLoader from "@/shared/components/CustomLoader.vue";

import { authStore } from "../auth-store";

export default defineComponent({
	name: "LoginWithToken",

	components: {
		CustomLoader,
		Wrapper
	},

	async mounted() {
		const { token, enableFlags, disableFlags, orgId, refreshToken } = this.$route.query;
		if (typeof enableFlags === "string") {
			enableFlags.split(",").forEach(flag => {
				featureFlagStore.setClientFeature({ featureKey: flag, isEnabled: true });
			});
		}

		if (typeof disableFlags === "string") {
			disableFlags.split(",").forEach(flag => {
				featureFlagStore.setClientFeature({ featureKey: flag, isEnabled: false });
			});
		}

		if (typeof token !== "string") {
			this.$router.push({ name: "login" });
			return;
		}

		const existingToken = TokenService.getToken();
		const redirectOrgId = typeof orgId === "string" ? orgId : userStore.profile?.id;

		if (token !== existingToken && typeof refreshToken === "string") {
			await authStore.USER_LOGOUT({
				forceLogout: false,
				isRedirect: false
			});

			await authStore.AUTH_WITH_TOKEN({
				token,
				userRefreshToken: refreshToken
			});
		}
		if (redirectOrgId) {
			this.$router.replace({
				name: "home",
				params: {
					orgId: redirectOrgId
				}
			});
		} else {
			this.$router.replace({ path: "/" });
		}
	}
});
</script>
