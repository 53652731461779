<template>
	<Card type="placeholder" clickable @click="$emit('create')">
		<Container align="center" direction="column" class="text-align-center" padding="24px 12px">
			<Pictogram size="l" shape="squircle" state="primary">
				<Icon name="i-plus" size="20px" color="gray-600" />
			</Pictogram>
			<Typography type="p1-para" color="gray-100">
				Deploy this application to {{ environment }}
			</Typography>
		</Container>
	</Card>
</template>
<script lang="ts">
import { Container, Pictogram, Typography, Icon, Card } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyDeployments",

	components: {
		Container,
		Pictogram,
		Typography,
		Icon,
		Card
	},

	props: {
		/**
		 * Env Name of deployment
		 */
		environment: {
			type: String,
			required: true
		}
	}
});
</script>
