import { Router, RouteRecordRaw } from "vue-router";

import EnvCreateLoaderScreen from "@/modules/env-list/views/EnvCreateLoaderScreen.vue";
import EnvDetailedView from "@/modules/env-list/views/EnvDetailedView.vue";

export const envListRoutes: RouteRecordRaw[] = [
	{
		path: "/org/:orgId/projects/:projectId/envs/:envId/details/:tabName?",
		name: "envDetail",
		component: EnvDetailedView,
		meta: {
			title: "Environment Details",
			requiresAuth: true,
			reuse: true
		},
		props: true
	},
	{
		path: "/org/:orgId/projects/:projectId/envs/:envId/details/applications/:deploymentId",
		name: "envDetailWithDepId",
		component: EnvDetailedView,
		meta: {
			title: "Environment Details",
			requiresAuth: true,
			reuse: true
		},
		props: true
	},
	{
		path: "/org/:orgId/projects/:projectId/envs/create-new-env",
		name: "createEnvLoaderScreen",
		component: EnvCreateLoaderScreen,
		meta: {
			title: "Creating Environment",
			requiresAuth: true,
			reuse: true
		},
		props: true
	}
];

export function registerEnvListRoutes(router: Router) {
	envListRoutes.forEach(route => {
		router.addRoute(route);
	});
}
