import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { getStore } from "../store";

import { ActivityResponse, getActivityPerUserId } from "./activity-utils";

export const DEFAULT_ORG_IDENTIFIER = "default";

@Module({
	namespaced: true,
	dynamic: true,
	name: "activities",
	store: getStore()
})
class ActivityStore extends VuexModule {
	userActivities: Record<string, ActivityResponse[]> = {};

	@Mutation
	SET_ACTIVITIES_FOR_ORG({ activities }: { activities: ActivityResponse[] }) {
		const localActivities = { ...this.userActivities };

		activities.forEach(activity => {
			localActivities[`${activity.OrgID}-${activity.UserID}`] = [activity];
		});

		this.userActivities = localActivities;
	}

	@Action
	async FETCH_USERS_ACTIVITIY(request: { orgId: string; userIds: string[] }) {
		const activities = await getActivityPerUserId(request.orgId, request.userIds);

		this.SET_ACTIVITIES_FOR_ORG({
			activities: Object.values(activities)
		});
	}
}

export function getLastActivityForUser({ orgId, userId }: { orgId: string; userId: string }) {
	return activityStore.userActivities[`${orgId}-${userId}`]?.[0];
}

const activityStore = getModule(ActivityStore);

export { activityStore };
