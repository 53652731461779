export default {
	methods: {
		async redirectMixin({ routeName, params }) {
			const link = this.$router.resolve({ name: routeName });
			if (link.matched.length > 0) {
				return await this.$router.push({ name: routeName, params });
			}
			return false;
		}
	}
};
