<template>
	<Container
		direction="column"
		padding="20px 0"
		:gap="20"
		:grow="1"
		overflow="visible"
		class="height-100-per"
		align="left top"
	>
		<!-- Header -->
		<Container
			data-qa-inputs-tab-header
			:padding="0"
			overflow="visible"
			align="space-between top"
			class="dashboard-input"
		>
			<Container :padding="0" :gap="12" overflow="visible">
				<Typography type="h4" weight="bold" color="gray-200" transform="capitalize">{{
					selectedTab
				}}</Typography>
				<f-icon
					source="i-info-fill"
					tooltip="Environments use resolvers to resolve dependencies used by applications"
				>
				</f-icon>
			</Container>
		</Container>

		<EmptyInputOutput
			v-if="isEmpty"
			:from-search="false"
			:selected-tab="selectedTab"
			data-qa-empty-resolvers
		/>
		<!-- body -->
		<Container
			v-else
			direction="column"
			padding="0px 0px 50px 0px"
			align="left top"
			:grow="1"
			:gap="36"
			overflow="auto"
		>
			<Table border="rows" data-qa-dependency-input-output-table>
				<template #body>
					<TableRow v-for="resolver in resolversWithComponent" :key="resolver.id">
						<TableCell>
							<Container :padding="4" :gap="12" overflow="visible">
								<Typography type="p2" weight="bold">{{ resolver.component.title }}</Typography>
								<Tag size="small" color="gray-200">
									<Typography type="p3" color="dark">
										{{ resolver.component.moduleId?.version ?? "-" }}
									</Typography>
								</Tag>
								<ResolverDetailsPopover :resolver="resolver" />
							</Container>
						</TableCell>
					</TableRow>
				</template>
			</Table>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Table, TableCell, TableRow, Tag, Typography } from "@cldcvr/flow-vue3";
import { cloneDeep } from "lodash-es";
import { defineComponent, PropType } from "vue";

import { getComponent } from "@/modules/org/org-service";
import { Dependency, DependencyResolver } from "@/protocol/infra";

import { DependencyResolverWithComponent } from "../dependency-registry-types";

import EmptyInputOutput from "./EmptyInputOutput.vue";
import ResolverDetailsPopover from "./ResolverDetailsPopover.vue";

export default defineComponent({
	name: "DependencyResolvers",

	components: {
		Container,
		Typography,
		Table,
		TableRow,
		TableCell,
		Tag,
		ResolverDetailsPopover,
		EmptyInputOutput
	},

	props: {
		resolvers: {
			type: Array as PropType<DependencyResolver[]> | undefined
		},

		selectedTab: {
			type: String
		},

		dependency: {
			type: Object as PropType<Dependency>,
			required: true
		}
	},

	data: () => ({
		resolversWithComponent: [] as DependencyResolverWithComponent[]
	}),

	computed: {
		isEmpty() {
			return this.resolversWithComponent.length === 0;
		}
	},

	watch: {
		resolvers: {
			immediate: true,

			async handler(value: DependencyResolver[] | undefined) {
				if (value) {
					const resolversData: DependencyResolverWithComponent[] = [];
					const promises = cloneDeep(value).map(async (resolver: DependencyResolver) => {
						const component = await getComponent({
							id: resolver.infraProviderId,
							orgId: this.dependency.orgId
						});

						resolversData.push({
							...resolver,
							component
						});
					});

					await Promise.all(promises);
					this.resolversWithComponent = resolversData;
				}
			}
		}
	}
});
</script>
