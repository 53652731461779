import { Router, RouteRecordRaw } from "vue-router";

import OrgSettingsWrapper from "@/modules/org/org-settings/views/OrgSettingsWrapper.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/org/:orgId/org-settings/:tabName?/",
		name: "org-settings-global",
		component: OrgSettingsWrapper,
		meta: {
			title: "Organization Settings",
			requiresAuth: true,
			reuse: true
		},
		props: true
	}
];

export function registerOrgSettingsRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
