<template>
	<PipelineHistory
		:selected-filter="selectedFilter"
		:is-loading-history="isLoading"
		:history-list="historyList"
		@close-pipeline-history="closePipelineHistory"
		@select-pipeline-filter="selectPipelineFilter"
		@select-pipeline-history="selectPipelineHistory"
	/>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import PipelineHistory, {
	HistoryFilterOption,
	PipelineHistorySchema
} from "@/shared/components/pipelines/PipelineHistory.vue";
import {
	JOB_STATUS,
	JOB_STEP_STATUS,
	PipelineJobStatus,
	PIPELINE_UNFINISHED_JOB_STATUSES
} from "@/shared/pipeline-constants";
import { formatDateToRegularFormat, getDuration, getElapsedTimeString } from "@/utils";

import { getSortedIntegrationJobList } from "../application-integration-store";

export default defineComponent({
	name: "IntegrationPipelineHistory",

	components: {
		PipelineHistory
	},

	props: {
		integrationId: {
			type: String,
			required: true
		},

		selectedPipelineId: String,
		isLoading: Boolean
	},

	emits: ["selectPipelineHistory", "setPipelineHistory"],

	data: () => ({
		selectedFilter: null as HistoryFilterOption | null
	}),

	computed: {
		historyList(): PipelineHistorySchema[] {
			return getSortedIntegrationJobList(this.integrationId)
				.filter(appJob => {
					// If no filters are selected then send all
					if (this.selectedFilter === null) {
						return true;
					}

					// Else filter the jobs
					const jobStatus = appJob.jobStatus as PipelineJobStatus;

					if (this.selectedFilter.id === "completed") {
						return jobStatus === JOB_STATUS.DONE;
					} else if (this.selectedFilter.id === "failed") {
						return jobStatus === JOB_STATUS.FAILED;
					} else if (this.selectedFilter.id === "cancelled") {
						return jobStatus === JOB_STATUS.CANCELLED;
					}

					return PIPELINE_UNFINISHED_JOB_STATUSES.includes(jobStatus);
				})
				.sort((jobA, jobB) => Number(jobB.createdAt ?? 0) - Number(jobA.createdAt ?? 0))
				.map((job): PipelineHistorySchema => {
					return {
						id: job.id,
						date: job.createdAt ? formatDateToRegularFormat(Number(job.createdAt) * 1000) : null,
						state: JOB_STEP_STATUS[job.jobStatus as PipelineJobStatus],
						isSelected: job.id === this.selectedPipelineId,
						duration: getDuration(Number(job.updatedAt), Number(job.createdAt)),
						triggeredBy: job.updatedBy,
						elapsedTime: job.createdAt
							? getElapsedTimeString(Date.now(), Number(job.createdAt) * 1000)
							: null
					};
				});
		}
	},

	methods: {
		closePipelineHistory() {
			this.$emit("setPipelineHistory", false);
		},

		selectPipelineFilter(filter: HistoryFilterOption | null) {
			if (filter?.id === this.selectedFilter?.id) {
				this.selectedFilter = null;
			} else {
				this.selectedFilter = filter;
			}
		},

		selectPipelineHistory(pipelineId: string) {
			this.$emit("selectPipelineHistory", pipelineId);
		}
	}
});
</script>
