<template>
	<Container align="center" class="height-100-per text-align-center">
		<Wrapper height="150px">
			<Container :grow="1" align="center top" :gap="52" :padding="0">
				<Container
					direction="column"
					padding="0px 12px"
					align="center"
					:gap="24"
					:shrink="0"
					class="width-400"
				>
					<Container :padding="0" direction="column" :gap="24">
						<Typography type="h2" weight="bold" color="dark"
							>No promotion sequence available</Typography
						>
					</Container>
					<Typography type="p1-para">
						Apps can’t be promoted without having a promotion sequence. Promotion sequence is having
						multiple environments arranged in a sequence to promote apps.
					</Typography>
				</Container>
			</Container>
		</Wrapper>
	</Container>
</template>
<script lang="ts">
import { Container, Wrapper, Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyCodePromotionSequence",

	components: {
		Container,
		Wrapper,
		Typography
	}
});
</script>
