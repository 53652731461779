<template>
	<div class="display-contents">
		<ToggleBox
			v-tooltip="{
				content: 'Cloud credentials',
				placement: 'bottom-start'
			}"
			:selected="filters.cloud"
			:class="credCounts.cloud === 0 ? 'disabled cursor-pointer' : ''"
			data-qa-cred-glb-filter-cloud-cred
			@click="toggleFilter(credScope.cloud)"
		>
			<Icon name="i-cloud" type="filled" size="small" state="dark" :effects="false" />
			<Tag
				size="small"
				shape="rounded"
				:background="filters.cloud ? 'var(--primary-100)' : undefined"
				:color="filters.cloud ? 'var(--gray-500)' : 'var(--gray-100)'"
				data-qa-cred-glb-filter-cloud-cred-count
				>{{ credCounts.cloud }}</Tag
			>
		</ToggleBox>
		<ToggleBox
			v-tooltip="{
				content: 'Git credentials',
				placement: 'bottom-start'
			}"
			:selected="filters.git"
			:class="credCounts.git === 0 ? 'disabled cursor-pointer' : ''"
			data-qa-cred-glb-filter-git-cred
			@click="toggleFilter(credScope.git)"
		>
			<Icon name="i-git" type="filled" size="small" state="dark" :effects="false" />
			<Tag
				size="small"
				shape="rounded"
				:background="filters.git ? 'var(--primary-100)' : undefined"
				:color="filters.git ? 'var(--gray-500)' : 'var(--gray-100)'"
				data-qa-cred-glb-filter-git-cred-count
				>{{ credCounts.git }}</Tag
			>
		</ToggleBox>
		<ToggleBox
			v-tooltip="{
				content: 'Container credentials',
				placement: 'bottom-start'
			}"
			:selected="filters.docker"
			:class="credCounts.docker === 0 ? 'disabled cursor-pointer' : ''"
			data-qa-cred-glb-filter-container-cred
			@click="toggleFilter(credScope.docker)"
		>
			<Icon name="i-box" type="filled" size="small" state="dark" :effects="false" />
			<Tag
				size="small"
				shape="rounded"
				:background="filters.docker ? 'var(--primary-100)' : undefined"
				:color="filters.docker ? 'var(--gray-500)' : 'var(--gray-100)'"
				data-qa-cred-glb-filter-container-cred-count
				>{{ credCounts.docker }}</Tag
			>
		</ToggleBox>
		<ToggleBox
			v-tooltip="{
				content: 'Not assigned credentials',
				placement: 'bottom-start'
			}"
			:selected="filters.no_support"
			:class="credCounts.no_support < 1 ? 'disabled cursor-pointer' : ''"
			data-qa-cred-glb-filter-not-assigned-creds
			@click="toggleFilter(credScope.no_support)"
		>
			<Icon name="i-minus" type="filled" size="small" state="dark" :effects="false" />
			<Tag
				size="small"
				shape="rounded"
				:background="filters.no_support ? 'var(--primary-100)' : undefined"
				:color="filters.no_support ? 'var(--gray-500)' : 'var(--gray-100)'"
				data-qa-cred-glb-filter-not-assigned-creds-count
				>{{ credCounts.no_support }}</Tag
			>
		</ToggleBox>
	</div>
</template>

<script lang="ts">
import { Icon, Tag, ToggleBox } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { CredScope } from "@/protocol/identity";

import { CredFilterCounts, CredFilters } from "../../credential-types";
import { CredentialGroupGroupScope } from "../../store-types";

export default defineComponent({
	name: "FilterCredByScope",

	components: {
		ToggleBox,
		Tag,
		Icon
	},

	props: {
		credentialGroup: {
			type: Object as PropType<CredentialGroupGroupScope>,
			required: true
		}
	},

	data: () => ({
		credScope: CredScope,
		filters: {
			[CredScope.cloud]: false,
			[CredScope.git]: false,
			[CredScope.docker]: false,
			[CredScope.no_support]: false
		} as CredFilters
	}),

	computed: {
		credCounts(): CredFilterCounts {
			return {
				[CredScope.cloud]: this.credentialGroup.cloud.list.length,
				[CredScope.git]: this.credentialGroup.git.list.length,
				[CredScope.docker]: this.credentialGroup.docker.list.length,
				[CredScope.no_support]: this.credentialGroup.no_support.list.length
			};
		}
	},

	methods: {
		toggleFilter(filter: CredScope) {
			this.filters[filter] = !this.filters[filter];
			this.$emit("onCredFilterChange", this.filters);
		}
	}
});
</script>
<style lang="scss">
div.flow-toggle-box.disabled {
	background: var(gray-500);
	opacity: 0.4;
	pointer-events: none;
}
</style>
