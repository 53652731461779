<template>
	<Wrapper
		width="450px"
		height="auto"
		border-radius="4px"
		background="element-light"
		data-qa-delete-env-model
	>
		<Container :padding="0" :gap="0" direction="column" :grow="1" data-qa-env-delete-model-header>
			<Header>
				<Typography type="h4" color="dark"> Delete {{ env && env.name }} environment </Typography>
				<Icon
					name="i-close"
					type="filled"
					size="small"
					data-qa-close-environmaent-delete-popup
					@click="$emit('close')"
				/>
			</Header>
			<Container
				v-if="isLoading || hasTriggeredPipelineJob"
				direction="column"
				:shrink="0"
				:gap="16"
				padding="16px 16px 12px 16px"
				align="left stretch"
			>
				<Container :padding="0" :gap="4">
					<CustomLoader />
				</Container>
			</Container>
			<Container
				v-if="!isLoading"
				direction="column"
				:shrink="0"
				:gap="16"
				padding="16px 16px 12px 16px"
				align="left stretch"
			>
				<Container :padding="0" :gap="2">
					<h4 class="fc-dark line-h-14 upper-case fc-error" data-qa-env-delete-header-title>
						Important!
					</h4>
				</Container>
				<Container v-if="!hasDepDelJob" :padding="0" :gap="2">
					<Typography data-qa-env-delete-message>
						<p class="fc-normal paragraph-1 margin-bt-16">
							You are about to <span class="fc-error">delete this environment</span>.
						</p>
						<p class="fc-normal paragraph-1 margin-bt-16">
							If this environment contains any deployed infrastructure, that infrastructure will
							remain but will no longer be managed by Code Pipes.
						</p>
					</Typography>
				</Container>
				<Container v-if="tfStateFile && !hasDepDelJob" :padding="0" :gap="2">
					<Typography data-qa-env-delete-download-tfstate-msg
						>If you would like a copy of the Terraform State, you can download it here.</Typography
					>
				</Container>
				<Container
					v-if="hasDepDelJob"
					:padding="0"
					:gap="2"
					data-qa-env-delete-deployment-in-process
				>
					<p class="paragraph-1 line-h-20 fw-400 fc-dark">
						There is a
						<span class="fc-error">{{
							hasDepDelJob && hasDepDelJob.action === "ValidateAndApply" ? "deploy" : "destroy"
						}}</span>
						pipeline in process, please wait for that pipeline to complete before deleting the
						environment
					</p>
				</Container>
				<Container v-if="tfStateFile && !hasDepDelJob" :padding="0">
					<div class="margin-bt-16">
						<Slab
							size="small"
							data-qa-env-delete-download-tfstate-file
							@click="downloadTerraform()"
						>
							<template #secondary-actions>
								<Icon size="small" type="filled" name="i-download" state="dark" />
							</template>
							<p class="paragraph-1 line-h-20 fw-300 fc-dark">{{ env && env.name }}.tfstate</p>
						</Slab>
					</div>
				</Container>
			</Container>
			<Container v-if="errorMessage" direction="column" :gap="8" :padding="24">
				<Container :padding="0" :gap="12">
					<Icon name="i-alert" type="filled" size="small" state="error" :effects="false" />
					<Typography type="h4" color="error" data-qa-env-delete-failed-env-name
						>Delete {{ env && env.name }} failed</Typography
					>
				</Container>
				<Typography type="p2" color="error" data-qa-env-delete-failed-content
					>{{ errorMessage }}
				</Typography>
			</Container>
			<Footer>
				<Container :padding="0" align="center stretch" :grow="1" role="button">
					<Button
						:disabled="isLoading || hasDepDelJob ? true : false"
						state="full"
						type="error"
						data-qa-delete-environment-button
						@click="deleteEnv"
					>
						<Icon v-if="isDeleting" name="loading" size="small" />
						<span>{{ deleteBtnText }}</span>
					</Button>
				</Container>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { actionType, environment } from "@/protocol/infra";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { PipelineJobStatus, PIPELINE_UNFINISHED_JOB_STATUSES } from "@/shared/pipeline-constants";
import { captureError, downloadFile, getErrorMessage } from "@/utils";

import { envListStore } from "../env-list/env-list-store";
import { getSortedEnvJobs, newEnvPipelineStore } from "../env-pipeline/env-pipeline-store";
import { userStore } from "../user/user-store";

import { envDeleteStore } from "./env-delete-store";

export default defineComponent({
	name: "EnvDeletePopover",

	components: {
		Wrapper,
		Container,
		Typography,
		Header,
		Icon,
		Footer,
		Button,
		Slab,
		CustomLoader
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		}
	},

	data: () => ({
		isLoading: false,
		isDeleting: false,
		tfStateFile: null as Record<string, any> | null,
		deleteBtnText: "Delete Environment",
		errorMessage: null as string | null
	}),

	computed: {
		envToDelete() {
			return envDeleteStore.envToDelete;
		},

		hasTriggeredPipelineJob() {
			const loaders = newEnvPipelineStore.envPipelineLoaders;
			const value = Object.values(loaders).map(obj => obj.map(j => j.value === true));
			return value.flat().includes(true);
		},

		hasDepDelJob() {
			const [latestJob] = getSortedEnvJobs(this.env.id);

			// User can delete the env if there is no pipeline job in progress OR if the pipeline is of ValidateOnly type
			if (
				latestJob &&
				(latestJob.action === actionType.ValidateAndApply ||
					latestJob.action === actionType.destroy)
			) {
				return PIPELINE_UNFINISHED_JOB_STATUSES.includes(latestJob.status as PipelineJobStatus)
					? latestJob
					: null;
			}

			return null;
		},

		hasEnvs() {
			return Object.keys(envListStore.envs)
				.map(key => envListStore.envs[key]!.length > 0)
				.includes(true);
		}
	},

	mounted() {
		this.fetchLatestJobs();
		this.getOutputForEnv();
	},

	methods: {
		async fetchLatestJobs() {
			this.isLoading = true;
			await newEnvPipelineStore.LIST_JOBS_FOR_ENV({
				orgId: this.env.orgId,
				projId: this.env.projId,
				envId: this.env.id,
				limit: 1,
				action: actionType.ValidateAndApply
			});
			await newEnvPipelineStore.LIST_JOBS_FOR_ENV({
				orgId: this.env.orgId,
				projId: this.env.projId,
				envId: this.env.id,
				limit: 1,
				action: actionType.destroy
			});
			this.isLoading = false;
		},

		async getOutputForEnv() {
			this.tfStateFile = await envListStore.GET_OUTPUT_FOR_ENV({
				orgId: this.env.orgId,
				projectId: this.env.projId,
				envId: this.env.id
			});
		},

		downloadTerraform() {
			downloadFile(this.tfStateFile, `${this.env.name}.tfstate`);
		},

		async deleteEnv() {
			try {
				this.errorMessage = null;
				this.isDeleting = true;
				this.deleteBtnText = "Deleting environment...";
				if (this.envToDelete) {
					const res = await envDeleteStore.DELETE_ENV({
						orgId: this.envToDelete.orgId,
						projId: this.envToDelete.projId,
						id: this.envToDelete.id,
						force: false
					});
					if (res.success) {
						this.deleteBtnText = "Deleted";
						// if this was the last env, we set the user meta that he has not created the envs ever for us to show the welcome env popover
						if (!this.hasEnvs) {
							await userStore.UPDATE_USER_META({
								hasCreatedEnvs: false
							});
						}
						this.$router.push({
							name: "projectListWithProject",
							params: { orgId: this.envToDelete.orgId, projectId: this.envToDelete.projId }
						});
						this.$emit("close");
					}
				}
			} catch (error) {
				captureError(error);
				this.deleteBtnText = "Failed";
				this.errorMessage = getErrorMessage(error);
			} finally {
				this.isDeleting = false;
			}
		}
	}
});
</script>
