import { getRelativeTime } from "@/utils/time-operations";

import { activityGraphqlQuery } from "./activity-service";

// Only a subset of activity APIs are relevant to the rendering of the UI
const RENDERABLE_ACTIVITY_API_IDS = [
	"dep-create",
	"dep-delete",
	"dep-update",
	"dep-job-start",
	"dep-job-cancel",
	"dep-promote",
	"dep-config-delete",
	"dep-config-upload",
	"dep-variables-update",
	"dep-variables-delete"
] as const;

/**
 * Normally we would be generating GraphQL schema from backend APIs however there is an ongoing
 * issue in the graphjin API which prevents us from doing that
 * https://github.com/dosco/graphjin/issues/336
 */
export type ActivityResponse = {
	ApiID: keyof typeof RENDERABLE_ACTIVITY_API_IDS;
	OrgID: string;
	ProjID: string;
	Deployments: string;
	Envs: string;
	Organizations: string;
	Projects: string;
	RequestID: string;
	Success: true;
	Timestamp: string;
	UserID: string;
	// @todo - VN-2639 - We are not implementing the full activity API yet
	// Properties: {
	// 	Deployments: string;
	// 	Envs: string;
	// 	Organizations: string;
	// 	Projects: string;
	// };
	// Request: {message: Record<string, unknown>};
	// Response: {message: Record<string, unknown>};
};

export async function getActivityPerUserId(orgId: string, userIds: string[]) {
	const responses = await activityGraphqlQuery<Record<string, ActivityResponse[]>>({
		orgId,
		query: `query {
			${userIds.map(getActivityQueryFragment).join(",")}
		}`
	});

	const activityRecord: Record<string, ActivityResponse> = {};

	Object.values(responses).forEach(responseItems => {
		const [responseItem] = responseItems;

		if (responseItem) {
			activityRecord[responseItem.UserID] = responseItem;
		}
	});

	return activityRecord;
}

export type ActivityDetails = {
	dateTime: number;
	relativeTime: string;
};

export function getActivityDetails(activity?: ActivityResponse): ActivityDetails | undefined {
	if (!activity) {
		return undefined;
	}

	const activityTime = new Date(activity.Timestamp).getTime();

	return {
		dateTime: activityTime,
		relativeTime: getRelativeTime(activityTime)
	};
}

/**
 * Uses a GraphQL query to get the activity for a user
 * We only return one activity for a given user, and we have a whitelist of activity types that can be rendered in the UI
 */
function getActivityQueryFragment(userId: string, idx: number) {
	return `activity${idx}: Activities(limit: 1, order_by: { timestamp: desc }, where: {
		and: [
			{ UserId: "${userId}" },
			{ Success: true },
			{ ApiID: [${RENDERABLE_ACTIVITY_API_IDS.map(api => `"${api}"`).join(",")}] }
		]
	}) {
		OrgID
		ProjID
		UserID
		RequestID
		Timestamp
		Success
		ApiID
		# Properties
		# Request
		# Response
	}`;
}
