<template>
	<Container padding="16px 20px" direction="column" :gap="0">
		<Container direction="column" :gap="20" :padding="0">
			<Container :padding="0" :gap="16" overflow="visible">
				<Typography type="h3" weight="bold" color="dark" data-qa-org-overview
					>Organization Overview</Typography
				>

				<PopOver
					v-if="activeOrg.role === 'admin'"
					v-model:open="openSettings"
					placement="bottom-start"
				>
					<Icon
						v-if="activeOrg.role === 'admin'"
						name="i-setting"
						size="20px"
						data-qa-org-update
						@click="toggleOrgSwitcher()"
					/>

					<template #content>
						<EditOrg
							:edited-org="activeOrg"
							data-qa-create-edit-org
							@back="toggleOrgSwitcher"
							@close="toggleOrgSwitcher"
						/>
					</template>
				</PopOver>
			</Container>

			<OrgPrimaryDetails :details="orgPrimaryDetails" data-qa-basic-org-info />

			<Divider />

			<Container :padding="0" align="left center">
				<Typography type="h3" weight="bold" color="dark" data-qa-org-primary-details
					>Other Details</Typography
				>
			</Container>
			<Container padding="0px 0px 16px 0px" direction="column" :gap="0">
				<Table data-qa-detailed-org-info border="none">
					<template #body>
						<TableRow :hover="false" data-qa-key="Created By" :data-qa-value="activeOrg.updatedBy">
							<TableCell padding="8px 8px 8px 0px" width="200px">
								<Typography type="p2" color="gray-200">Created By</Typography>
							</TableCell>
							<TableCell padding="8px 8px 8px 0px">
								<UserNameEmail v-if="!loading" :user-id="activeOrg.updatedBy" />
								<Shimmer v-else height="20px" width="100px" />
							</TableCell>
						</TableRow>

						<TableRow
							v-for="([item, val], idx) in Object.entries(orgSecondaryDetails)"
							:key="idx"
							:hover="false"
							:data-qa-key="item"
							:data-qa-value="val"
						>
							<TableCell padding="8px 8px 8px 0px" width="200px">
								<Typography type="p2" color="gray-200">{{ item }}</Typography>
							</TableCell>
							<TableCell padding="8px 8px 8px 0px">
								<Typography v-if="!loading" type="p2">{{ val }}</Typography>
								<Shimmer v-else height="20px" width="100px" />
							</TableCell>
						</TableRow>
					</template>
				</Table>
			</Container>
			<Divider />
			<OrgDelete :has-child-entities="hasChildEntities" />
		</Container>
	</Container>
</template>

<script lang="ts">
import {
	Container,
	Divider,
	Icon,
	PopOver,
	Shimmer,
	Table,
	TableCell,
	TableRow,
	Typography
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { credentialStore } from "@/modules/credentials/credential-store";
import EditOrg from "@/modules/org/EditOrg.vue";
import OrgDelete from "@/modules/org/org-settings/components/OrgDelete.vue";
import { projectStore } from "@/modules/project-list/project-store";
import { organization } from "@/protocol/identity";
import { UserNameEmail } from "@/shared/components";
import OrgPrimaryDetails from "@/shared/components/org-settings/OrgPrimaryDetails.vue";
import { formatDateToRegularFormat } from "@/utils";

type Org = organization & { formatedName: string; orgInitial: string };

const ORG_INFO_KEYS = {
	CREATE_DATE: "Created On",
	PROJECTS: "Total Projects",
	ENVIRONMENTS: "Total Environments",
	APPLICATIONS: "Total Applications"
} as const;

type SecondaryDetailsTypes = {
	[ORG_INFO_KEYS.CREATE_DATE]: string;
	[ORG_INFO_KEYS.PROJECTS]: string;
	[ORG_INFO_KEYS.ENVIRONMENTS]: string;
	[ORG_INFO_KEYS.APPLICATIONS]: string;
};

export default defineComponent({
	name: "OrgDetails",

	components: {
		Container,
		EditOrg,
		Divider,
		Icon,
		OrgDelete,
		OrgPrimaryDetails,
		PopOver,
		Shimmer,
		Table,
		TableCell,
		TableRow,
		Typography,
		UserNameEmail
	},

	props: {
		activeOrg: {
			type: Object as PropType<Org>,
			required: true
		}
	},

	data: () => ({
		loading: false,
		openSettings: false
	}),

	computed: {
		orgSecondaryDetails(): SecondaryDetailsTypes {
			return {
				[ORG_INFO_KEYS.CREATE_DATE]: this.activeOrg.createdAt
					? this.timestampFormat(this.activeOrg.createdAt)
					: "",

				[ORG_INFO_KEYS.PROJECTS]: String(this.projects.length),
				[ORG_INFO_KEYS.ENVIRONMENTS]: String(this.totalEnvironments),
				[ORG_INFO_KEYS.APPLICATIONS]: String(this.totalApplications)
			};
		},

		hasChildEntities() {
			return (
				this.projects.length > 0 ||
				this.totalEnvironments > 0 ||
				this.totalApplications > 0 ||
				this.totalCreds > 0
			);
		},

		orgPrimaryDetails() {
			return {
				orgName: this.activeOrg.name,
				orgDescription: this.activeOrg.description ?? "",
				logo: this.activeOrg.orgInitial
			};
		},

		projects() {
			return Object.values(projectStore.projects);
		},

		projectCounts() {
			return projectStore.projectCounts;
		},

		totalEnvironments() {
			return this.projects.reduce((total: number, project) => {
				return total + (this.projectCounts[project.id]?.environments ?? 0);
			}, 0);
		},

		totalApplications() {
			return this.projects.reduce((total, project) => {
				return total + (this.projectCounts[project.id]?.apps ?? 0);
			}, 0);
		},

		totalCreds() {
			return Object.values(credentialStore.genericCredentials).length;
		}
	},

	mounted() {
		(async () => {
			this.loading = true;

			// Detailed project list API call takes a long time so we run it separately from the main render
			await projectStore.GET_PROJECTS({
				orgId: this.activeOrg.id,
				detailed: true
			});

			this.loading = false;
		})();
	},

	methods: {
		timestampFormat(timestamp: string) {
			return formatDateToRegularFormat(Number(timestamp) * 1000);
		},

		toggleOrgSwitcher() {
			this.openSettings = !this.openSettings;
		}
	}
});
</script>
