import { cloneDeep } from "lodash-es";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { projectId as ProjectIdProto, AppId as app, AppId } from "@/protocol/app";
import { EnvironmentId, VGEntities } from "@/protocol/common";
import {
	AppliedCredsRequest,
	credDetails,
	CredsAssignmentRequest,
	credDetailsOptionalScope,
	Creds,
	CredScope,
	CredsCreate,
	CredsUpdate,
	project,
	CredsUnassignmentRequest,
	ProjectId
} from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { captureError } from "@/utils";

import { classification } from "../../protocol/infra";
import { getStore } from "../store";

import {
	appliedCreds,
	assignCredsScope,
	createCreds,
	createGithubCreds,
	createGithubOauthCreds,
	deleteCred,
	getCred,
	getUserFromGithubOauthCode,
	listClassifications,
	listCredEntities,
	listCreds,
	unassignCredsScope,
	updateCreds
} from "./credentials-service";
import { defaultCredStats, ICredStats } from "./store-types";

@Module({
	namespaced: true,
	dynamic: true,
	name: "credentials",
	store: getStore()
})
class CredentialStore extends VuexModule {
	genericCredentials: Record<string, Creds> = {};
	entityCredentials: AssignedCredOnEntityMap = {};
	classificationList: classification[] = [];
	credentialStats = {} as Record<string, ICredStats>;

	// We do not have a good way to find out where a credential is inherited from
	// So if we change the project credential, and an app inherits it we don't have a way to update it
	// unless we keep a map of the entity id with it's payload
	// https://cldcvr.atlassian.net/browse/VAN-4303
	entityFetchMap: Record<string, AppliedCredsRequest> = {};

	loadingCredentials: boolean | null = null;
	loadingCredentialStats: boolean | null = null;
	loadingEntityCredentials: Record<string, boolean> = {};

	get existingCredentialNames() {
		const creds = Object.values(this.genericCredentials);
		return creds.map(cred_ => cred_.name);
	}

	// Mutations
	@Mutation
	RESET_CREDENTIALS() {
		this.genericCredentials = {};
		this.entityCredentials = {};
		this.classificationList = [];

		this.loadingCredentials = null;
		this.loadingCredentialStats = null;
		this.loadingEntityCredentials = {};

		this.credentialStats = {};
	}

	@Mutation
	SET_GENERIC_CREDENTIALS({ credentials }: { credentials: Record<string, Creds> }) {
		this.genericCredentials = credentials;
	}

	@Mutation
	REMOVE_CREDENTIAL({ credId }: { credId: string }) {
		delete this.genericCredentials[credId];
		delete this.credentialStats[credId];
	}

	@Mutation
	SET_CLASSIFICATIONS({ classifications }: { classifications: classification[] }) {
		this.classificationList = classifications;
	}

	@Mutation
	SET_ENTITY_CREDENTIALS({ entities }: { entities: AssignedCredOnEntityMap }) {
		this.entityCredentials = entities;
	}

	@Mutation
	UPDATE_ASSIGNED_CREDENTIALS_ON_ENTITY({
		entityId,
		payload,
		creds
	}: {
		entityId: string;
		payload: AppliedCredsRequest;
		creds: Creds[];
	}) {
		this.entityFetchMap[entityId] = payload;
		this.entityCredentials[entityId] = creds;
	}

	@Mutation
	SET_GENERIC_CREDENTIAL(cred: Creds) {
		this.genericCredentials[cred.id] = cred;
	}

	@Mutation
	SET_CREDENTIAL_STATS_BY_ID({ credId, credStats }: { credId: string; credStats: ICredStats }) {
		this.credentialStats[credId] = credStats;
	}

	@Mutation
	SET_LOADING_CREDENTIALS(loadingCredentials: boolean) {
		this.loadingCredentials = loadingCredentials;
	}

	@Mutation
	SET_LOADING_CREDENTIAL_STATS(loadingCredentialStats: boolean) {
		this.loadingCredentialStats = loadingCredentialStats;
	}

	@Mutation
	SET_LOADING_ENTITY_CREDENTIAL({ entityId, isLoading }: { entityId: string; isLoading: boolean }) {
		this.loadingEntityCredentials[entityId] = isLoading;
	}

	// Actions
	@Action
	UPDATE_CRED_AND_STATS_BY_ID(cred: Creds) {
		if (!this.credentialStats[cred.id]) {
			this.SET_CREDENTIAL_STATS_BY_ID({
				credId: cred.id,
				credStats: {
					assignedToCount: 0,
					apps: [],
					environments: [],
					projects: [],
					credScopeMap: {}
				}
			});
		}

		this.SET_GENERIC_CREDENTIAL(cred);
	}

	@Action
	async GET_ORG_CREDENTIALS({ orgId }: { orgId: string }) {
		try {
			this.SET_LOADING_CREDENTIALS(true);

			const response = await listCreds({ id: orgId, projId: "" });

			if (response.creds?.length) {
				const credentialObjs: Record<string, Creds> = {};

				response.creds.forEach(cred => {
					credentialObjs[cred.id] = cred;
				});

				this.SET_GENERIC_CREDENTIALS({ credentials: credentialObjs });
			}
		} catch (error) {
			captureError(error);
			throw error;
		} finally {
			this.SET_LOADING_CREDENTIALS(false);
		}
	}

	// GET stats for each credential
	@Action
	async GET_CREDENTIAL_STATS({ orgId, credId }: { orgId: string; credId: string }) {
		try {
			const { genericCredentials } = this;

			if (!genericCredentials[credId]) {
				return false;
			}

			const defaultStats = cloneDeep(defaultCredStats);

			const stats = await listCredEntities({ orgId, id: credId });

			const localStats = stats.entities?.reduce<ICredStats>((internalStats, entity) => {
				switch (entity.entityType) {
					case VGEntities.project: {
						if (entity.project?.id && entity.credScope) {
							internalStats.credScopeMap[entity.project.id] = entity.credScope;
							internalStats.projects.push({ ...entity.project, credScope: entity.credScope });
						}
						break;
					}
					case VGEntities.environment: {
						if (entity.environment?.id && entity.credScope) {
							internalStats.credScopeMap[entity.environment.id] = entity.credScope;
							internalStats.environments.push({
								...entity.environment,
								credScope: entity.credScope
							});
						}
						break;
					}
					case VGEntities.application: {
						if (entity.app?.id && entity.credScope) {
							internalStats.credScopeMap[entity.app.id] = entity.credScope;
							internalStats.apps.push({ ...entity.app, credScope: entity.credScope });
						}
						break;
					}
				}

				internalStats.assignedToCount =
					internalStats.projects.length +
					internalStats.environments.length +
					internalStats.apps.length;
				return internalStats;
			}, defaultStats);

			this.SET_CREDENTIAL_STATS_BY_ID({
				credId,
				credStats: localStats ?? defaultStats
			});
		} catch (error) {
			captureError(error);
			throw error;
		}
	}

	// Trigger GET stats for all Credentials
	@Action
	async FETCH_ORG_CREDENTIAL_STATS({ orgId }: { orgId: string }) {
		const creds = Object.values(this.genericCredentials);
		if (!creds.length) {
			return false;
		}

		this.SET_LOADING_CREDENTIAL_STATS(true);

		const promises: Promise<unknown>[] = [];
		creds.forEach(cred => {
			promises.push(
				this.GET_CREDENTIAL_STATS({
					orgId,
					credId: cred.id
				})
			);
		});

		await Promise.allSettled(promises);

		this.SET_LOADING_CREDENTIAL_STATS(false);
	}

	@Action
	async GET_UPDATE_CREDENTIAL({ orgId, id }: { orgId: string; id: string }) {
		const cred = await getCred({ id, orgId });
		this.SET_GENERIC_CREDENTIAL(cred);
	}

	// Assign credentials to project or environment
	@Action
	async HANDLE_CREDENTIAL_ASSIGNMENT({
		entity,
		assignCreds,
		unassignCreds,
		assignTo
	}: {
		entity: EnvironmentId | ProjectIdProto;
		assignCreds?: credDetails | null;
		unassignCreds?: credDetailsOptionalScope | null;
		assignTo: "environment" | "project";
	}) {
		if (assignTo === "environment") {
			// First unassign the creds if any
			if (unassignCreds) {
				await unassignCredsScope({
					environment: entity as EnvironmentId,
					creds: [unassignCreds],
					orgId: entity.orgId
				});
			}

			// Then assign the creds if any
			if (assignCreds) {
				await assignCredsScope({
					environment: entity as EnvironmentId,
					creds: [assignCreds],
					orgId: entity.orgId
				});
			}

			await credentialStore.GET_CREDS_FOR_ENTITY({
				environment: entity as EnvironmentId,
				orgId: entity.orgId
			});
		} else {
			// First unassign the creds if any
			if (unassignCreds) {
				await unassignCredsScope({
					project: entity,
					creds: [unassignCreds],
					orgId: entity.orgId
				});
			}

			// Then assign the creds if any
			if (assignCreds) {
				await assignCredsScope({
					project: entity,
					creds: [assignCreds],
					orgId: entity.orgId
				});
			}

			await credentialStore.GET_CREDS_FOR_ENTITY({
				project: entity as ProjectIdProto,
				orgId: entity.orgId
			});
		}

		// Credential was assigned/unassigned, update it's stats
		if (unassignCreds?.credId) {
			await this.GET_CREDENTIAL_STATS({
				orgId: entity.orgId,
				credId: unassignCreds.credId
			});
		}

		if (assignCreds?.credId) {
			await this.GET_CREDENTIAL_STATS({
				orgId: entity.orgId,
				credId: assignCreds.credId
			});
		}
	}

	@Action
	SET_GITHUB_PAT_CREDENTIAL({
		orgId,
		name,
		username,
		personalAccessToken
	}: {
		orgId: string;
		name: string;
		username: string;
		personalAccessToken: string;
	}) {
		return createGithubCreds({
			orgId,
			name,
			username,
			personalAccessToken
		});
	}

	/**
	 * @param payload AppliedCredRequest
	 * @description Get all applied creds for the given entity and update each cred and its respective stats
	 * for the given entity
	 */
	@Action
	async GET_CREDS_FOR_ENTITY(payload: AppliedCredsRequest) {
		const entityId = payload.project?.id ?? payload.environment?.id ?? payload.app?.id ?? "-";
		this.SET_LOADING_ENTITY_CREDENTIAL({ entityId, isLoading: true });
		const response = await appliedCreds(payload);

		if (response.creds) {
			this.UPDATE_ASSIGNED_CREDENTIALS_ON_ENTITY({ entityId, payload, creds: response.creds });
		}

		this.SET_LOADING_ENTITY_CREDENTIAL({ entityId, isLoading: false });
	}

	@Action
	GET_USER_FROM_GIT_OAUTH_TOKEN({ identityProviderToken }: { identityProviderToken: string }) {
		return getUserFromGithubOauthCode({ code: identityProviderToken });
	}

	@Action
	SET_GITHUB_OAUTH_CREDENTIAL({
		name,
		orgId,
		token
	}: {
		name: string;
		orgId: string;
		token: string;
	}) {
		return createGithubOauthCreds({
			name,
			orgId,
			token
		});
	}

	@Action
	createCredential({ cred }: { cred: CredsCreate }) {
		return createCreds(cred);
	}

	@Action
	async GET_CLASSIFICATIONS({ orgId }: { orgId: string }) {
		try {
			const response = await listClassifications({ orgId });

			this.SET_CLASSIFICATIONS({ classifications: response.classifications ?? [] });
		} catch (error) {
			captureError(error);
			throw error;
		}
	}

	// update credential
	@Action
	updateCredential({ cred }: { cred: CredsUpdate }) {
		return updateCreds(cred);
	}

	// delete credential
	@Action
	async deleteCredential({ cred }: { cred: Creds }) {
		const resp = await deleteCred({
			id: cred.id,
			orgId: cred.orgId
		});
		const credStats = this.credentialStats[cred.id];
		if (credStats) {
			const entityCredentials = Object.keys(credStats.credScopeMap).reduce<AssignedCredOnEntityMap>(
				(entities, entityId) => {
					const assignedCredsOnEntity = entities[entityId];
					if (assignedCredsOnEntity?.length) {
						entities[entityId] = assignedCredsOnEntity.filter(
							assignedCred => assignedCred.id !== cred.id
						);
					}
					return entities;
				},
				{ ...this.entityCredentials }
			);
			this.SET_ENTITY_CREDENTIALS({ entities: entityCredentials });
		}
		this.REMOVE_CREDENTIAL({ credId: cred.id });
		return resp;
	}

	// Assign credentials to project or environment
	@Action
	async applyCredentialScopeToEntity({
		entity,
		credToAssign,
		credToUnassign,
		applyCredScope,
		assignTo
	}: {
		entity: project | environment | app;
		credToAssign?: Creds[];
		credToUnassign?: Creds[];
		applyCredScope: CredScope[];
		assignTo: "environment" | "project" | "app";
	}) {
		/**
		 * Creating common payload for both assign and unassign
		 */
		const entityId: ProjectIdProto | EnvironmentId | AppId = {
			id: entity.id,
			orgId: entity.orgId,
			...(assignTo === "environment" && { projId: (entity as environment).projId }),
			...(assignTo === "app" && { projId: (entity as app).projId })
		};

		/**
		 * Unassinging credentials from entity if credToUnassign is set.
		 * Unassignment has to done before the assignment since with backend cannot override the assignment.
		 */
		if (credToUnassign?.length) {
			const credsUnassignmentRequest: CredsUnassignmentRequest = {
				orgId: entity.orgId,
				creds: credToUnassign.map(unassignCred => ({
					credId: unassignCred.id,
					credScope: applyCredScope
				})),
				...(assignTo === "environment" && { environment: entityId as EnvironmentId }),
				...(assignTo === "project" && { project: entityId }),
				...(assignTo === "app" && { app: entityId as app })
			};

			await unassignCredsScope(credsUnassignmentRequest);
			credToUnassign.forEach(unassign =>
				this.GET_CREDENTIAL_STATS({ credId: unassign.id, orgId: entity.orgId })
			);
		}

		if (credToAssign?.length) {
			const credsAssignmentRequest: CredsAssignmentRequest = {
				orgId: entity.orgId,
				creds: credToAssign.map(assignCred => ({
					credId: assignCred.id,
					credScope: applyCredScope
				})),
				...(assignTo === "environment" && { environment: entityId as EnvironmentId }),
				...(assignTo === "project" && { project: entityId }),
				...(assignTo === "app" && { app: entityId as app })
			};
			await assignCredsScope(credsAssignmentRequest);
			credToAssign.forEach(assign =>
				this.GET_CREDENTIAL_STATS({ credId: assign.id, orgId: entity.orgId })
			);
		}
	}

	/**
	 * @param payload AppliedCredRequest
	 * @description Get all applied creds for the given entity and update each cred and its respective stats
	 * for the given entity
	 */
	@Action
	async getSetAppliedCredOnEntity({
		entity,
		entityType
	}: {
		entity: project | environment;
		entityType: "environment" | "project" | "app";
	}) {
		const appliedCredOnEntityRequest: AppliedCredsRequest = {
			orgId: entity.orgId,
			...(entityType === "environment" && {
				environment: { id: entity.id, orgId: entity.orgId, projId: (entity as environment).projId }
			}),
			...(entityType === "project" && { project: { id: entity.id, orgId: entity.orgId } }),
			...(entityType === "app" && {
				app: { id: entity.id, orgId: entity.orgId, projId: (entity as environment).projId }
			})
		};

		this.SET_LOADING_ENTITY_CREDENTIAL({ entityId: entity.id, isLoading: true });

		const response = await appliedCreds(appliedCredOnEntityRequest);

		if (response.creds) {
			this.UPDATE_ASSIGNED_CREDENTIALS_ON_ENTITY({
				entityId: entity.id,
				payload: appliedCredOnEntityRequest,
				creds: response.creds
			});
		}

		const loadingPromises: Array<Promise<unknown>> = [];

		// Find out all the previously loaded entities and check if they have a matching
		// project or environment. See `entityFetchMap` for more details
		if (entityType === "project") {
			Object.entries(this.entityFetchMap).forEach(([, payload]) => {
				if (payload.app?.projId === entity.id || payload.environment?.projId === entity.id) {
					loadingPromises.push(this.GET_CREDS_FOR_ENTITY(payload));
				}
			});
		}

		await Promise.allSettled(loadingPromises);

		this.SET_LOADING_ENTITY_CREDENTIAL({ entityId: entity.id, isLoading: false });
	}
}

const credentialStore = getModule(CredentialStore);

export { credentialStore };

export function getCredByScope({
	entityId,
	scope
}: {
	entityId: string;
	scope: CredScope;
}): Creds[] {
	return (
		credentialStore.entityCredentials[entityId]?.filter(cred => cred.credScope?.includes(scope)) ??
		[]
	);
}

type AssignedCredOnEntityMap = Record<string, ExtendedCreds[]>;

export type ExtendedCreds = Creds & {
	project?: ProjectId;
	environment?: EnvironmentId;
	app?: AppId;
};
