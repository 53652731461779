<template>
	<f-popover
		placement="auto"
		size="medium"
		:open="true"
		:show-overlay="true"
		direction="column"
		align="middle-center"
	>
		<f-div
			padding="large"
			height="52px"
			gap="large"
			state="secondary"
			width="100%"
			align="middle-center"
		>
			<f-icon
				v-if="showDelete"
				source="i-arrow-left"
				size="small"
				class="cursor-pointer"
				data-qa-delete-popover-back-icon
				@click.stop="showDelete = false"
			>
			</f-icon>
			<f-text
				variant="heading"
				size="small"
				weight="medium"
				data-qa-add-new-control-gate-modal-title
			>
				{{ modalTitle }}
			</f-text>
			<f-div align="middle-right" gap="small" width="30%" height="hug-content">
				<f-icon
					v-if="!showDelete && isEditMode"
					source="i-delete"
					size="small"
					state="danger"
					class="cursor-pointer"
					:data-qa-delete-control-gate-icon="controlGate?.name"
					@click.stop="showDelete = true"
				>
				</f-icon>
				<f-icon
					source="i-close"
					size="small"
					class="cursor-pointer"
					data-qa-close-control-gate-popover
					@click.stop="$emit('close')"
				>
				</f-icon>
			</f-div>
		</f-div>

		<f-divider direction="horizontal" height="fill-container" size="large" state="secondary" />

		<f-div direction="column">
			<!-- CREATE form -->
			<f-div
				v-if="!showDelete"
				width="100%"
				height="100%"
				padding="large"
				state="secondary"
				align="top-center"
			>
				<f-form-builder
					:field.prop="formFields"
					:values.prop="formValues"
					@input="handleInput"
					@state-change="formState = $event.detail"
				/>
			</f-div>
			<!-- DELETE form -->
			<f-div
				v-if="showDelete"
				width="100%"
				height="100%"
				padding="large"
				state="secondary"
				align="top-center"
				direction="row"
				gap="large"
				data-qa-control-gate-delete-info
			>
				<f-div direction="column" gap="medium" width="100%">
					<f-text size="medium" weight="medium" state="default">Are you sure?</f-text>

					<f-text
						size="medium"
						variant="para"
						weight="regular"
						state="secondary"
						:data-qa-delete-control-gate-para="controlGate?.name"
					>
						You are about to delete
						<f-text size="medium" variant="para" weight="bold" inline>{{
							controlGate?.name
						}}</f-text>
						It includes the following control sets.
					</f-text>
				</f-div>

				<f-div direction="column" width="100%" padding="small" gap="small">
					<f-div v-for="name in getControlSetNames()" :key="name" direction="column" gap="small">
						<f-text
							size="medium"
							state="secondary"
							variant="para"
							:data-qa-del-control-gate-control-set-name="name"
						>
							{{ name }}</f-text
						>
						<f-divider></f-divider>
					</f-div>
				</f-div>
			</f-div>

			<!-- Error message -->
			<f-div
				v-if="submitError"
				padding="medium"
				height="hug-content"
				gap="medium"
				direction="row"
				width="100%"
				state="danger"
			>
				<f-text size="small" weight="regular" variant="para" color="danger" data-qa-submit-err-text>
					{{ submitError }}</f-text
				>
				<f-div align="top-right" width="hug-content">
					<f-icon
						class="cursor-pointer"
						source="i-close"
						size="x-small"
						data-qa-error-close-icon
						data-qa-close-control-gate-popover-error-popover
						@click="submitError = ''"
					>
					</f-icon>
				</f-div>
			</f-div>

			<!-- footer -->
			<f-button
				:label="btnTitle"
				category="fill"
				size="medium"
				:state="showDelete ? 'danger' : 'success'"
				variant="block"
				:disabled="disableBtn"
				:loading="isLoading"
				data-qa-add-new-control-gate-btn
				@click="createEditControlGate"
			></f-button>
		</f-div>
	</f-popover>
</template>
<script lang="ts">
import { FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { PropType, defineComponent } from "vue";

import { TemplateOption } from "@/modules/application/components/ApplicationDeploymentModalTemplateStep.vue";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { orgStore } from "@/modules/org/org-store";
import { PolicySet, policySetType } from "@/protocol/validator";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import { getErrorMessage } from "@/utils";

import { policyListStore } from "../policy-list-store";

type FormValues = {
	name: string;
	description?: string;
	__showDescription__: boolean;
	controlSets?: TemplateOption[];
};
export default defineComponent({
	name: "CreateControlGatePopOver",

	props: {
		isEditMode: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: () => false
		},

		controlGate: {
			type: Object as PropType<PolicySet | null>,
			required: true
		}
	},

	emits: ["close"],

	data: () => ({
		formState: null as FormBuilderState | null,
		formValues: {
			name: "",
			description: "",
			__showDescription__: false,
			controlSets: []
		} as FormValues,

		submitError: "",
		isLoading: false,

		showDelete: false
	}),

	computed: {
		disableBtn() {
			const isFormInvalid = !this.formState?.isValid && !this.formState?.isChanged;
			return !this.showDelete ? isFormInvalid : false;
		},

		modalTitle() {
			if (this.isEditMode && !this.showDelete) {
				return `Edit ${this.controlGate?.name}`;
			}
			if (this.showDelete) {
				return "Deleting control gate";
			}
			return "Add new control gate";
		},

		btnTitle() {
			if (this.isEditMode && !this.showDelete) {
				return "Edit control gate";
			}
			if (this.showDelete) {
				return "Delete control gate";
			}
			return "Add control gate";
		},

		activeOrgId() {
			return orgStore.activeOrgId;
		},

		controlSets() {
			return policyListStore.policySets.filter(
				policy => policy.type === policySetType.setTypeControlSet
			);
		},

		controlSetOptions() {
			return this.controlSets.map(set => {
				return {
					title: set.name,
					data: {
						id: set.id,
						totalStatements: set.importSets?.length ?? 0
					}
				};
			});
		},

		existingControlGateNames() {
			return policyListStore.policySets.map(policy => policy.name);
		},

		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					name: {
						type: "text",
						qaId: "name",
						label: { title: "Name" },
						placeholder: "Enter control gate name",
						validationRules: applyEntityNameRules2(
							this.existingControlGateNames,
							"This name already exists."
						)
					},

					addDescription: {
						type: "button",
						label: "Add description",
						iconLeft: "i-plus",
						category: "transparent",
						size: "x-small",
						qaId: "addDescription",

						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: true };
						},

						showWhen(values) {
							const formValues = values as FormValues;
							return !formValues.__showDescription__ && !formValues.description;
						}
					},

					__showDescription__: {
						type: "hidden"
					},

					description: {
						type: "textarea",
						label: { title: "Description" },
						qaId: "description",
						placeholder: "Add a brief description about the control gate",
						showWhen(values) {
							const formValues = values as FormValues;
							return Boolean(
								(typeof formValues.description === "string" && formValues.description.length > 0) ||
									formValues.__showDescription__
							);
						}
					},

					removeDescription: {
						type: "button",
						qaId: "removeDescription",
						label: "Remove description",
						iconLeft: "i-close",
						category: "transparent",
						state: "danger",
						size: "x-small",
						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: false, description: "" };
						},

						showWhen(values) {
							const formValues = values as FormValues;
							return Boolean(formValues.__showDescription__ || formValues.description);
						}
					},

					_divider: {
						type: "separator"
					},

					controlSets: {
						type: "select",
						selection: "multiple",
						label: { title: "Select control sets" },
						qaId: "selectControlSets",
						placeholder: "Search control sets",
						searchable: true,
						id: "controlSets",
						options: this.controlSetOptions,
						//@ts-expect-error
						optionTemplate: (option: TemplateOption) =>
							html` <f-div gap="medium" overflow="visible" align="middle-left">
								<f-div width="hug-content" align="middle-left">
									<f-text size="small">${option.title}</f-text>
								</f-div>

								<f-div direction="column" align="middle-right">
									<f-text ellipsis="true" size="small" state="primary"
										>${option.data.totalStatements} statements
										<f-icon source="i-new-tab" state="primary" size="x-small"></f-icon>
									</f-text>
								</f-div>
							</f-div>`
					}
				}
			};
		}
	},

	mounted() {
		if (this.isEditMode && this.controlGate !== null) {
			const controlSetsValue = this.controlGate.importSets?.map(setId => {
				const controlSet = this.controlSets.find(s => s.id === setId);
				return {
					title: controlSet?.name ?? "",

					data: {
						id: controlSet?.id,
						totalStatements: controlSet?.importSets?.length ?? 0
					}
					// We don't have custom type for `totalStatements` in `FSelectOptions`
				} as unknown as TemplateOption;
			});

			this.formValues = {
				name: this.controlGate.name,
				controlSets: controlSetsValue ?? [],

				description: this.controlGate.description,
				__showDescription__: Boolean(this.controlGate.description)
			};
		}
	},

	methods: {
		handleInput(event: CustomEvent<FormValues>) {
			const values = { ...event.detail };
			this.formValues = values;
		},

		async createEditControlGate(): Promise<void> {
			if (!this.activeOrgId) {
				return;
			}

			try {
				this.isLoading = true;
				if (!this.isEditMode) {
					// Create new
					const policy = await policyListStore.CREATE_POLICYSET({
						name: this.formValues.name,
						description: this.formValues.description,
						type: policySetType.setTypeControlGate,
						importSets: this.formValues.controlSets?.map(set => set.data.id),
						orgId: this.activeOrgId
					});

					// Show notification for successful creation
					notificationsStore.ADD_TOAST({
						text: `Control gate ${policy?.name} created successfully`,
						title: "Success",
						qaId: "control-gate-created-notification",
						status: "success"
					});
				} else {
					if (!this.showDelete) {
						// Edit existing
						await policyListStore.UPDATE_POLICYSET({
							id: this.controlGate?.id,
							name: this.formValues.name,
							description: this.formValues.description,
							importSets: this.formValues.controlSets?.map(set => set.data.id),
							orgId: this.controlGate?.orgId
						});

						notificationsStore.ADD_TOAST({
							text: `Control gate ${this.controlGate?.name} updated successfully`,
							title: "Success",
							qaId: "control-gate-update-notification",
							status: "success"
						});
					} else {
						// Delete existing
						if (this.controlGate?.id) {
							await policyListStore.DELETE_POLICYSET({
								orgId: this.activeOrgId,
								id: this.controlGate.id
							});

							notificationsStore.ADD_TOAST({
								text: `Control gate ${this.controlGate.name} deleted successfully`,
								title: "Control gate is deleted",
								qaId: "control-gate-delete-notification",
								status: "error"
							});
						}
					}
				}

				this.$emit("close");
			} catch (error) {
				this.submitError = getErrorMessage(error, true);
			} finally {
				this.isLoading = false;
			}
		},

		getControlSetNames(): string[] {
			if (this.controlGate?.importSets) {
				return this.controlGate.importSets.map(setId => {
					const controlSet = this.controlSets.find(s => s.id === setId);
					return controlSet?.name ?? "";
				});
			}
			return [];
		}
	}
});
</script>
