import { AxiosResponse } from "axios";

import ApiService, { ACTIVITY_API } from "@/services/api-service";

export const activityGraphqlQuery = async function <T = unknown>(request: {
	orgId: string;
	query: string;
}) {
	const response: AxiosResponse<{ data: string }> = await ApiService.post(
		`${ACTIVITY_API}/organizations/${request.orgId}/activity/query`,
		{
			query: request.query
		}
	);

	if (response.status === 200) {
		return JSON.parse(response.data.data) as T;
	}

	throw response;
};
