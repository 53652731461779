import { ENVIRONMENTS, getEnvironment } from ".";

let hasSetupGa = false;

/**
 * In Google analytics by default page views contain the full page URL
 * In our case the page urls contains a lot of ids for projects, orgs etc
 * This will make the analytics useless because we won't be able to analyse
 * any user flows because all pages will be considered unique.
 *
 * So instead of reporting real URL we report the page component name we are on
 * @param {String} pagePath
 * @param {String} pageTitle
 */
export function reportPageView(pagePath: string, pageTitle = document.title) {
	let sanitisedPath = pagePath;

	if (!sanitisedPath) {
		sanitisedPath = "/";
	}

	if (!sanitisedPath.startsWith("/")) {
		sanitisedPath = `/${sanitisedPath}`;
	}

	// We defer the setup of google analytics until we have the user id and other page information
	if (!hasSetupGa) {
		window.gtag?.("config", getGtagId(), {
			page_title: pageTitle,
			page_path: sanitisedPath,
			page_location: window.location.href,
			user_agent: navigator.userAgent
		});

		window.gtag?.("set", {
			send_page_view: false
		});

		// Set initial gtag/js?id=<first ID> script to <head>
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.async = true;
		script.src = `//www.googletagmanager.com/gtag/js?id=${getGtagId()}`;
		document.getElementsByTagName("head")[0]?.appendChild(script);
		hasSetupGa = true;
	} else {
		window.gtag?.("event", "page_view", {
			page_title: pageTitle,
			page_path: sanitisedPath,
			page_location: window.location.href,
			user_agent: navigator.userAgent
		});
	}
}

export function reportLogin(method: string) {
	window.gtag?.("event", "login", {
		method
	});
}

export function setAnalyticsUserId(userId: string | null) {
	window.gtag?.("set", {
		user_id: userId
	});
}

export function getGtagId() {
	const environment = getEnvironment();
	const isProduction = environment === ENVIRONMENTS.PRODUCTION;

	// GA works for both staging and production
	return isProduction ? "G-XWETDSCEQF" : "G-FPYVNHJW2W";
}

export function setupMSClarity() {
	/* prettier-ignore */
	/* eslint-disable */
	/* NOSONAR */
	try {
		(function(c,l,a,r,i,t,y){
			//@ts-expect-error
			c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
			//@ts-expect-error
			t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; //NOSONAR
			//@ts-expect-error
			y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); //NOSONAR
		})(window, document, "clarity", "script", "616suyg2rt");
	}
	catch (err) {
		// do nothing
	}
	/* eslint-enable */
}
