<template>
	<f-div direction="column" height="100%" gap="large" padding="x-large">
		<f-div direction="column" gap="x-small" height="hug-content">
			<f-text size="medium" variant="heading">Terraform component registry</f-text>
			<f-text size="small" variant="para">{{ org?.name }}</f-text>
		</f-div>

		<f-div direction="row">
			<f-div direction="column" border="small solid default right" width="320px" gap="large">
				<f-div direction="row" gap="x-small" height="hug-content">
					<SearchInput
						v-model:value="searchString"
						data-qa-field="data-qa-components-registry-tab-search-input"
						placeholder="Search components"
						:disabled="componentRecords.length === 0"
						@input="resetManualSelected"
					/>

					<f-div gap="none" padding-top="x-small" width="hug-content">
						<PopOver
							v-model:open="showFilterPopup"
							placement="bottom-end"
							:overlay="false"
							class="align-items-baseline"
						>
							<f-icon-button
								category="fill"
								variant="round"
								state="neutral"
								icon="i-filter"
								size="small"
								:disabled="componentRecords.length === 0"
								@click="showFilterPopup = !showFilterPopup"
							>
							</f-icon-button>
							<template #content>
								<Wrapper
									border-radius="4px"
									background="element-light"
									:border="true"
									width="240px"
								>
									<Container direction="column" :padding="0" :gap="0">
										<Slab
											v-for="(filterOption, idx) in filterOptions"
											:key="filterOption.text"
											size="medium"
											:effects="true"
											:type="idx === filterOptions.length - 1 ? 'filled' : 'bottom-border'"
											class="cursor-pointer"
											:data-qa-terraform-regitry-filter-option="filterOption.text"
											@click="selectFilter(filterOption)"
										>
											<Typography transform="capitalize" type="p1" weight="regular" color="dark">{{
												filterOption.text
											}}</Typography>
											<template v-if="filterOption.selected" #secondary-actions>
												<Icon name="i-tick" state="dark" size="small" :effects="false" />
											</template>
										</Slab>
									</Container>
								</Wrapper>
							</template>
						</PopOver>
					</f-div>
					<f-div gap="none" padding-top="x-small">
						<f-icon-button category="fill" variant="round" icon="i-plus" size="small" disabled>
						</f-icon-button>
					</f-div>
				</f-div>
				<f-div direction="row" gap="none">
					<TerraformEntity
						v-if="selectedTerraform"
						:grouped-components="groupedComponents"
						:closed-groups="closedGroups"
						:selected-terraform="selectedTerraform"
						@set-closed-groups="
							groupName => {
								closedGroups[groupName] = !closedGroups[groupName];
							}
						"
						@select-terraform="selectTerraform"
					/>
					<f-text v-else-if="!isLoading" size="medium" variant="heading">
						No component exists
					</f-text>
				</f-div>
			</f-div>
			<f-div>
				<TerraformDetail v-if="selectedTerraform" :selected-component="selectedTerraform" />
			</f-div>
			<f-div border="small solid default left" width="320px"> </f-div>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import {
	Container,
	Icon,
	PopOver,
	SearchInput,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { orgStore } from "@/modules/org/org-store";
import TerraformDetail from "@/modules/terraform-registry/components/TerraformDetail.vue";
import TerraformEntity from "@/modules/terraform-registry/components/TerraformEntity.vue";
import {
	CommonProviders,
	FilterOption,
	GroupComponents
} from "@/modules/terraform-registry/terraform-registry-types";
import { componentListItem } from "@/protocol/infra";

export default defineComponent({
	name: "TerraformRegistryWrapper",

	components: {
		TerraformEntity,
		TerraformDetail,
		Typography,
		Container,
		SearchInput,
		PopOver,
		Icon,
		Slab,
		Wrapper
	},

	data: () => ({
		isLoading: false,
		searchString: "",
		showFilterPopup: false,
		closedGroups: {} as Record<string, boolean>,
		manuallySelectedTerraform: null as componentListItem | null,
		selectedFilter: null as FilterOption | null
	}),

	computed: {
		componentRecords() {
			return orgStore.componentsList;
		},

		org() {
			return orgStore.activeOrg;
		},

		filteredComponents() {
			return orgStore.componentsList
				.filter(component => {
					if (this.selectedFilter?.id) {
						return component.moduleId?.provider.includes(this.selectedFilter.id);
					}
					return component;
				})
				.filter(component => {
					if (this.searchString) {
						return component.title
							.toLocaleLowerCase()
							.includes(this.searchString.toLocaleLowerCase());
					}
					return component;
				})
				.sort((scopeA: any, scopeB: any) => {
					return scopeA.moduleId?.provider.localeCompare(scopeB.moduleId?.provider);
				});
		},

		groupedComponents() {
			return this.filteredComponents.reduce(
				(acc: GroupComponents[], component: componentListItem) => {
					const provider = this.filterOptions.find(
						prvd => prvd.id === component.moduleId?.provider
					);
					const providerGroup = provider?.text;

					if (!providerGroup) {
						return acc;
					}
					const groupIdx = acc.findIndex(_group => _group.groupName === providerGroup);
					const componentGroup = acc[groupIdx] ?? {
						groupName: providerGroup,
						componentsList: []
					};
					componentGroup.componentsList.push(component);
					if (groupIdx > -1) {
						acc[groupIdx] = componentGroup;
					} else {
						acc.push(componentGroup);
					}
					return acc;
				},
				[]
			);
		},

		selectedTerraform() {
			return this.manuallySelectedTerraform ?? this.filteredComponents[0] ?? null;
		},

		filterOptions(): FilterOption[] {
			const uniqueProviders = [
				...new Set(orgStore.componentsList.map(terraform => terraform.moduleId?.provider).sort())
			];
			if (!uniqueProviders.length) {
				return [];
			}

			return uniqueProviders.map(provider => {
				/**
				 * The proto for the terraform component is not defined yet.
				 * For the non grouped components. the provider is null.
				 * We are changing the null provider and providers other than ["azurerm", "azure", "aws", "google"] to 'Other platforms'.
				 */
				const providerGroupName =
					provider && !CommonProviders.includes(provider) ? "Other platforms" : provider;
				return {
					id: provider,
					text: providerGroupName,
					selected: this.selectedFilter?.id === provider
				};
			});
		},

		orgId() {
			return orgStore.activeOrgId ?? (this.$route.params.orgId as string);
		}
	},

	mounted() {
		(async () => {
			if (this.orgId) {
				this.isLoading = true;
				await orgStore.FETCH_COMPONENT_LIST({
					orgId: this.orgId
				});

				this.isLoading = false;
			}
		})();
	},

	methods: {
		selectFilter(filter: FilterOption) {
			if (filter.id === this.selectedFilter?.id) {
				this.selectedFilter = null;
			} else {
				this.selectedFilter = filter;
			}
			this.resetManualSelected();
			this.searchString = "";
		},

		resetManualSelected() {
			this.manuallySelectedTerraform = null;
		},

		selectTerraform(dep: componentListItem) {
			this.manuallySelectedTerraform = dep;
		}
	}
});
</script>
