<template>
	<div />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { orgStore } from "@/modules/org/org-store";
import { projectStore } from "@/modules/project-list/project-store";
import RealtimeDataHandler from "@/shared/grpc-setup/RealtimeDataHandler";

export default defineComponent({
	name: "RealtimeUpdateListener",

	data: () => ({
		lastOrgId: null as string | null,
		lastProjectId: null as string | null,
		RealtimeDataHandler: null as InstanceType<typeof RealtimeDataHandler> | null
	}),

	computed: {
		orgAndProjectId() {
			return { orgId: orgStore.activeOrgId, projectId: projectStore.currentProject?.id };
		}
	},

	watch: {
		orgAndProjectId: {
			immediate: true,

			handler() {
				const { orgId, projectId } = this.orgAndProjectId;

				// Vue doesn't guarantee that watch is only triggered for differing values
				if (this.lastOrgId === orgId && this.lastProjectId === projectId) {
					return;
				}

				this.lastOrgId = orgId;
				this.lastProjectId = projectId ?? null;

				this.destroyHandler();

				// Realtime updates are passed from org level down to the job level
				// we start listening from the project level to avoid notification spam
				// because a user can't view multiple projects at once with the current UI
				if (orgId && projectId) {
					this.RealtimeDataHandler = new RealtimeDataHandler({
						orgId,
						projectId
					});
				}
			}
		}
	},

	methods: {
		destroyHandler() {
			if (this.RealtimeDataHandler) {
				this.RealtimeDataHandler.deleteInstance();
			}
		}
	}
});
</script>
