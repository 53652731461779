<template>
	<Container direction="column" :padding="0" :gap="0" align="left top">
		<Container
			padding="16px 0px 16px 20px"
			:gap="8"
			align="left center"
			overflow="visible"
			:shrink="0"
		>
			<Typography type="h3" color="dark" data-qa="app-deployment-title">App deployments</Typography>
			<Tag shape="rounded" size="small" data-qa-deployments-count>{{
				appDeployments.length ? appDeployments.length : 0
			}}</Tag>

			<Container
				v-if="hasEnvironments"
				padding="0px"
				:gap="8"
				align="right center"
				overflow="visible"
				:grow="1"
			>
				<PopOver
					placement="right-start"
					:open="openAddNewDeploymentPopover"
					class="align-items-center"
				>
					<Button
						v-tooltip="{
							content: 'New deployment'
						}"
						state="icon"
						size="small"
						data-qa-add-deployment
						@click="openAddNewDeploymentPopover = true"
					>
						<Icon size="small" name="i-plus" color="gray-600" :effects="false" />
					</Button>
					<template #content>
						<ApplicationDeploymentModal
							:app="app"
							:project-id="project.id"
							:disable-back-button="true"
							@close="() => (openAddNewDeploymentPopover = false)"
						/>
					</template>
				</PopOver>
			</Container>
		</Container>

		<EmptyEnvironments
			v-if="!hasEnvironments"
			:is-loading="isCreatingEnv"
			@create="createFirstEnvironment"
		/>

		<Container
			v-else
			data-qa-deployment-card-container
			direction="column"
			padding="0px 0px 56px 0px"
			:grow="1"
			:gap="0"
			align="left top"
			overflow="auto"
		>
			<Accordion v-for="env in environments" :key="env.id" :open="getIsEnvCollapsed(env.id)">
				<template #header>
					<Container
						padding="8px 8px 8px 20px"
						:gap="0"
						align="space-between center"
						overflow="visible"
						:shrink="0"
						:clickable="true"
						:data-qa-deployments-env-card-header="env.name"
						@click="isEnvCollapsed[env.id] = !getIsEnvCollapsed(env.id)"
					>
						<Container :gap="12" :padding="0" @click.stop="openEnv(env)">
							<Pictogram size="m" shape="hexagon">
								<Typography type="p3" weight="medium" class="cursor-pointer">
									<template v-if="getEmoji(env)">{{ getEmoji(env) }}</template>
									<template v-else>{{ getShortCode(env.name) }}</template>
								</Typography>
							</Pictogram>
							<Typography
								v-tooltip="{
									content: env.name
								}"
								type="h4"
								weight="medium"
								color="dark"
								overflow="ellipsis"
								>{{ env.name }}</Typography
							>
						</Container>
						<Icon
							name="i-chevron-up"
							size="x-small"
							color="gray-200"
							:rotate="getIsEnvCollapsed(env.id) ? 0 : 180"
						/>
					</Container>
				</template>
				<Container
					v-if="deploymentsByEnvId[env.id]"
					direction="column"
					padding="0px 0px 16px 20px"
					:shrink="0"
					:gap="12"
				>
					<template v-for="deployment in deploymentsByEnvId[env.id]" :key="deployment.id">
						<DeploymentCard
							v-if="deployment.name"
							:data-qa-deployment-card-id="deployment.id"
							:data-qa-deployment-card-name="deployment.name"
							:name="deployment.name"
							:details="deployment.details"
							:outputs="deployment.lastJob?.summary"
							:environment="deployment.envName"
							:status="deployment.jobStatus"
							:deployed-date="deployment.deployedTime"
							:is-deployed="deployment.isDeployed"
							@deploy="startDeploy(deployment)"
							@undeploy="startUndeploy(deployment)"
							@cancel="cancelDeployment(deployment)"
							@remove="removeDeployment(deployment)"
							@edit="editDeployment(deployment)"
							@view-logs="viewLogs(deployment)"
						/>
					</template>
				</Container>

				<Container
					v-else
					:data-qa-deploy-app-in-env="selectedDeploymentEnvironment?.id"
					direction="column"
					padding="8px 0px 16px 20px"
					:shrink="0"
					:gap="12"
				>
					<EmptyDeployments :environment="env.name" @create="createDeploymentForEnv(env)" />
				</Container>
			</Accordion>

			<!-- Undeploy popover -->
			<PopOver
				v-if="deploymentToUndeploy"
				:target="`[data-qa-deployment-card-id='${deploymentToUndeploy?.id}']`"
				:open="Boolean(deploymentToUndeploy)"
				@overlay-click="deploymentToUndeploy = null"
			>
				<template #content>
					<DepUndeployPopver
						:dep-entity="deploymentToUndeploy"
						:close-un-deploy-popover="() => (deploymentToUndeploy = null)"
						@back="() => (deploymentToUndeploy = null)"
					/>
				</template>
			</PopOver>

			<!-- Delete deployment popover -->
			<PopOver
				v-if="deploymentToDelete"
				:target="`[data-qa-deployment-card-id='${deploymentToDelete?.id}']`"
				:open="Boolean(deploymentToDelete)"
				@overlay-click="deploymentToDelete = null"
			>
				<template #content>
					<DelDepPopover
						:dep-entity="deploymentToDelete"
						:is-del-dep-popover-open="Boolean(deploymentToDelete)"
						:close-delete-dep-popover="() => (deploymentToDelete = null)"
						@back="() => (deploymentToDelete = null)"
					/>
				</template>
			</PopOver>

			<!-- Edit deployment popover -->
			<PopOver
				v-if="deploymentToEdit"
				:target="`[data-qa-deployment-card-id='${deploymentToEdit?.id}']`"
				:open="Boolean(deploymentToEdit)"
			>
				<template #content>
					<ApplicationDeploymentModal
						:app="app"
						:project-id="project.id"
						:environment-id="deploymentToEdit.envId"
						:disable-back-button="true"
						:app-deployment="deploymentToEdit"
						@close="() => (deploymentToEdit = null)"
					/>
				</template>
			</PopOver>

			<!-- Deploy to new environment popover -->
			<PopOver
				v-if="selectedDeploymentEnvironment"
				:target="`[data-qa-deploy-app-in-env='${selectedDeploymentEnvironment?.id}']`"
				:open="Boolean(selectedDeploymentEnvironment)"
			>
				<template #content>
					<ApplicationDeploymentModal
						:app="app"
						:project-id="project.id"
						:environment-id="selectedDeploymentEnvironment.id"
						:disable-back-button="true"
						@close="() => (selectedDeploymentEnvironment = null)"
					/>
				</template>
			</PopOver>

			<!-- Deployment confirmation popover -->
			<AppDeploymentConfirmationPopover
				v-if="deploymentToDeploy && Boolean(deploymentToDeploy)"
				:target="`[data-qa-deployment-card-id='${deploymentToDeploy?.id}']`"
				:deployment="deploymentToDeploy"
				:should-redirect-to-pipeline="true"
				@close="() => (deploymentToDeploy = null)"
				@back="() => (deploymentToDeploy = null)"
			>
			</AppDeploymentConfirmationPopover>
		</Container>
	</Container>
</template>

<script lang="ts">
import {
	Accordion,
	Button,
	Container,
	Icon,
	Pictogram,
	PopOver,
	Tag,
	Typography
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import ApplicationDeploymentModal from "@/modules/application/components/ApplicationDeploymentModal.vue";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import AppDeploymentConfirmationPopover from "@/modules/application-deployment/components/AppDeploymentConfirmationPopover.vue";
import DelDepPopover from "@/modules/application-deployment/components/DelDepPopover.vue";
import DepUndeployPopver from "@/modules/application-deployment/components/DepUndeployPopver.vue";
import { envCreateStore } from "@/modules/env-create/env-create-store";
import { EnvRoute } from "@/modules/env-list/components/env-widget/env-header/EnvWidgetHeader.vue";
import { envListStore } from "@/modules/env-list/env-list-store";
import { app as appProto } from "@/protocol/app";
import { AppDeployment, DeploymentJobAction, jobResponse } from "@/protocol/deployment";
import { project as projectProto } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import DeploymentCard from "@/shared/components/application/DeploymentCard.vue";
import EmptyDeployments from "@/shared/components/application/EmptyDeployments.vue";
import EmptyEnvironments from "@/shared/components/application/EmptyEnvironments.vue";
import { JOB_STATUS, PipelineJobStatus } from "@/shared/pipeline-constants";
import { getEmoji, getShortCode } from "@/utils";
import { KeyValue, getArtifactDetails } from "@/utils/get-artifact-details";

export default defineComponent({
	name: "ApplicationsTabDeployments",

	components: {
		Container,
		Button,
		Icon,
		Typography,
		Tag,
		Accordion,
		Pictogram,
		PopOver,
		DeploymentCard,
		EmptyEnvironments,
		EmptyDeployments,
		DelDepPopover,
		DepUndeployPopver,
		ApplicationDeploymentModal,
		AppDeploymentConfirmationPopover
	},

	props: {
		project: {
			type: Object as PropType<projectProto>,
			required: true
		},

		app: {
			type: Object as PropType<appProto>,
			required: true
		}
	},

	data: () => ({
		deploymentToUndeploy: null as DeploymentCardInfo | null,
		deploymentToDelete: null as DeploymentCardInfo | null,
		deploymentToDeploy: null as DeploymentCardInfo | null,
		deploymentToEdit: null as DeploymentCardInfo | null,
		selectedDeploymentEnvironment: null as environment | null,
		openAddNewDeploymentPopover: false,
		isEnvCollapsed: {} as Record<string, boolean>,
		getShortCode,
		isCreatingEnv: false
	}),

	computed: {
		environments() {
			return envListStore.envs[this.project.id];
		},

		envNameMap() {
			const envNameMap: Record<string, string> = {};

			this.environments?.forEach(env => {
				envNameMap[env.id] = env.name;
			});

			return envNameMap;
		},

		hasEnvironments() {
			return this.environments ? this.environments.length > 0 : false;
		},

		appDeployments() {
			return (
				applicationDeploymentStore.deploymentsInApp[this.app.id]?.sort((job1, job2) => {
					return Number(job2.createdAt) - Number(job1.createdAt);
				}) ?? []
			);
		},

		deploymentsByEnvId() {
			const deploymentsMap: Record<string, DeploymentCardInfo[]> = {};
			this.appDeployments.forEach(deployment => {
				if (!deployment.envId) {
					return;
				}

				if (!deploymentsMap[deployment.envId]) {
					deploymentsMap[deployment.envId] = [];
				}

				const { lastJob } = deployment;
				const lastJobTime = lastJob ? lastJob.updatedAt : deployment.lastJob?.updatedAt;
				const artifactDetails = getArtifactDetails(deployment.deploymentConfig?.artifact);
				const lastJobStatus = lastJob?.jobStatus as PipelineJobStatus;
				let isDeployed = false;

				// Last job was successful, use that to determine deployed status
				if (lastJobStatus === JOB_STATUS.DONE) {
					isDeployed = lastJob?.jobType === DeploymentJobAction.deploy;
				}
				// fall back to deployment for status
				else {
					isDeployed = deployment.lastSuccessfulJob?.jobType === DeploymentJobAction.deploy;
				}

				if (isDeployed && deployment.lastSuccessfulJob?.deployVersion) {
					artifactDetails.push({
						key: "Deployed Version",
						value: { text: deployment.lastSuccessfulJob.deployVersion }
					});
				}

				deploymentsMap[deployment.envId]?.push({
					...deployment,
					lastJob,
					deployedTime: lastJobTime ?? lastJobTime,
					details: artifactDetails,
					envName: this.envNameMap[deployment.envId] ?? "",
					jobStatus: lastJobStatus,
					isDeployed
				});
			});

			return deploymentsMap;
		}
	},

	methods: {
		getEmoji,

		getIsEnvCollapsed(envId: string) {
			if (typeof this.isEnvCollapsed[envId] === "boolean") {
				return this.isEnvCollapsed[envId];
			}

			if (!this.deploymentsByEnvId[envId]) {
				return false;
			}

			return true;
		},

		openEnv(env: environment) {
			const params: EnvRoute = {
				orgId: env.orgId,
				projectId: env.projId,
				envId: env.id
			};
			this.$router.push({
				name: "envDetail",
				params
			});
		},

		async createFirstEnvironment() {
			this.isCreatingEnv = true;

			await envCreateStore.CREATE_NEW_ENV({
				orgId: this.project.orgId,
				projId: this.project.id,
				name: "Environment-1",
				description: "",
				variables: [],
				metadata: {
					emojiObj: {
						native: "🌐"
					}
				},
				tfVersion: ""
			});

			await envListStore.GET_ENVS({
				orgId: this.project.orgId,
				projectId: this.project.id
			});

			this.isCreatingEnv = false;
		},

		createDeploymentForEnv(env: environment) {
			this.selectedDeploymentEnvironment = env;
		},

		startDeploy(deployment: DeploymentCardInfo) {
			this.deploymentToDeploy = deployment;
		},

		cancelDeployment(deployment: DeploymentCardInfo) {
			const { lastJob } = deployment;

			if (!lastJob || !deployment.orgId || !deployment.projId) {
				return;
			}

			applicationDeploymentStore.CANCEL_DEPLOYMENT({
				id: lastJob.id,
				depId: lastJob.depId,
				envId: deployment.envId!,
				orgId: deployment.orgId,
				projId: deployment.projId
			});
		},

		viewLogs(deployment: DeploymentCardInfo) {
			if (!deployment.orgId || !deployment.projId || !deployment.envId || !deployment.id) {
				return;
			}

			const lastJobType = deployment.lastJob?.jobType ?? DeploymentJobAction.deploy;

			this.$router.push({
				name: "stageViewApp",
				params: {
					orgId: deployment.orgId,
					projectId: deployment.projId,
					envId: deployment.envId,
					depId: deployment.id,
					action: lastJobType as string
				}
			});
		},

		startUndeploy(deployment: DeploymentCardInfo) {
			this.deploymentToUndeploy = deployment;
		},

		removeDeployment(deployment: DeploymentCardInfo) {
			this.deploymentToDelete = deployment;
		},

		editDeployment(deployment: DeploymentCardInfo) {
			this.deploymentToEdit = deployment;
		}
	}
});

type DeploymentCardInfo = AppDeployment & {
	lastJob?: jobResponse;
	details: KeyValue[];
	envName: string;
	jobStatus?: PipelineJobStatus;
	deployedTime: string | undefined;
	isDeployed: boolean;
};
</script>
