<template>
	<f-div
		direction="row"
		state="danger"
		gap="large"
		padding="medium"
		width="fill-container"
		height="hug-content"
		data-qa-org-delete-panel
	>
		<f-div
			direction="column"
			gap="large"
			padding="none"
			width="fill-container"
			height="hug-content"
		>
			<f-text
				variant="heading"
				size="medium"
				weight="bold"
				state="danger"
				align="left"
				data-qa-del-org-panel-header
				>Delete organization</f-text
			>
			<f-text
				variant="para"
				size="large"
				weight="regular"
				state="default"
				align="left"
				data-qa-del-org-panel-desc
				>Once the organization is deleted, it will be gone forever. You won't be able to manage
				deployments from Code Pipes.</f-text
			>
		</f-div>
		<PopOver
			:open="isPopoverOpen"
			:overlay="true"
			placement="right"
			data-qa-del-org-popover
			@overlay-click="closePopover"
		>
			<f-button
				label="Delete Organization"
				variant="round"
				category="fill"
				size="medium"
				state="danger"
				data-qa-delete-org-btn
				:disabled="!allowDeleteOrg"
				align="bottom-left"
				data-qa-del-org-popover-toggle
				@click="isPopoverOpen = true"
			/>
			<template #content>
				<Wrapper
					border-radius="4px"
					background="element-light"
					:border="true"
					width="450px"
					data-qa-delete-org-popover-content
				>
					<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
						<Header>
							<Container align="space-between center" :padding="0" :grow="1">
								<Container :gap="12" :padding="0">
									<Typography type="h4" color="dark" data-qa-del-popover-title
										>Delete this organization</Typography
									>
								</Container>
								<Container
									align="right center"
									:grow="1"
									:padding="0"
									data-qa-del-org-popover-close-icon
								>
									<Icon
										name="i-close"
										type="filled"
										size="x-small"
										data-qa-delete-org-close
										@click.stop="closePopover"
									/>
								</Container>
							</Container>
						</Header>

						<Wrapper>
							<Container
								padding="24px 16px 16px"
								:gap="16"
								align="left top"
								direction="column"
								overflow="visible"
								data-qa-delete-dependency-confirm-message="hasChildEntities"
							>
								<Typography v-if="!hasChildEntities" type="p1-para" color="dark"
									>This will permanently delete the
									<Typography
										type="p1-para"
										color="dark"
										weight="bold"
										inline
										:style="{ whiteSpace: 'pre-wrap' }"
										>{{ org?.name }}</Typography
									>
									organization. You won’t be able to manage deployments from Code Pipes. Once
									deleted, it will be gone forever.
								</Typography>
								<Typography v-else type="p1-para" color="dark"
									>The organization needs to be empty before it can be deleted. Please delete all
									the entities under the following categories before deleting the organization.
								</Typography>
							</Container>

							<Divider v-if="hasChildEntities" />

							<OrgDeleteEntities v-if="hasChildEntities" />
						</Wrapper>

						<Container padding="0px 10px 0 10px">
							<Divider />
						</Container>

						<Container
							v-if="errorMessage"
							direction="column"
							:gap="8"
							:padding="24"
							data-qa-del-org-err-section
						>
							<Container :padding="0" :gap="12">
								<Icon name="i-alert" type="filled" size="small" state="error" :effects="false" />
								<Typography type="h4" color="error" data-qa-org-delete-failed-org-name
									>Delete {{ org && org.name }} failed</Typography
								>
							</Container>
							<Typography type="p2" color="error" data-qa-org-delete-failed-content
								>{{ errorMessage }}
							</Typography>
						</Container>

						<Container
							v-if="!hasChildEntities"
							:padding="16"
							align="left top"
							direction="column"
							height="50px"
						>
							<Typography type="p2" weight="medium" color="dark"
								>To confirm this action, please enter the organization name below</Typography
							>
							<InputText
								v-model:value="enteredOrgName"
								placeholder="Enter organization name to confirm"
								data-qa-del-org-input-name
							/>
						</Container>

						<Footer>
							<Container :padding="0" align="center stretch" :grow="1" role="button">
								<f-button
									data-qa-delete-org-button
									label="Delete this organization"
									:disabled="disableDeleteBtn"
									variant="block"
									category="fill"
									size="medium"
									state="danger"
									:loading="isDeleting"
									@click="deleteOrg"
								>
								</f-button>
							</Container>
						</Footer>
					</Container>
				</Wrapper>
			</template>
		</PopOver>
	</f-div>
</template>
<script lang="ts">
import {
	Container,
	Divider,
	Header,
	Icon,
	InputText,
	PopOver,
	Typography,
	Wrapper,
	Footer
} from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { orgStore } from "@/modules/org/org-store";
import { userStore } from "@/modules/user/user-store";
import { captureError, getErrorMessage } from "@/utils";

import OrgDeleteEntities from "./OrgDeleteEntities.vue";

export default defineComponent({
	name: "OrgDelete",

	components: {
		Typography,
		PopOver,
		Wrapper,
		InputText,
		Header,
		Container,
		Icon,
		Divider,
		OrgDeleteEntities,
		Footer
	},

	props: {
		hasChildEntities: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		isPopoverOpen: false,
		errorMessage: null as string | null,
		isDeleting: false,
		enteredOrgName: ""
	}),

	computed: {
		org() {
			return orgStore.activeOrg;
		},

		disableDeleteBtn() {
			return (
				this.isDeleting ||
				this.enteredOrgName === "" ||
				this.enteredOrgName !== this.org?.name ||
				this.hasChildEntities
			);
		},

		activeOrg() {
			return orgStore.activeOrg;
		},

		allowDeleteOrg() {
			return this.activeOrg?.id !== userStore.profile?.id && this.activeOrg?.role === "admin";
		}
	},

	methods: {
		closePopover() {
			this.isPopoverOpen = false;
			this.errorMessage = null;
		},

		async deleteOrg() {
			try {
				if (!this.org?.id) {
					return;
				}

				this.isDeleting = true;

				await orgStore.DELETE_ORG({ id: this.org.id });
				this.errorMessage = null;

				orgStore.SET_ACTIVE_ORG_ID(userStore.profile?.id ?? "");

				await orgStore.GET_SET_ORGS();

				// Route the user to the default org
				this.$router
					.push({
						name: "home",
						params: { orgId: userStore.profile?.id ?? "" }
					})
					.catch(captureError);
			} catch (error) {
				this.errorMessage = getErrorMessage(error);
			} finally {
				this.isDeleting = false;
			}
		}
	}
});
</script>
