<template>
	<Container direction="column" :padding="0" align="left center" :gap="0">
		<Container align="left center" padding="0px 0px 6px 0px">
			<Pictogram
				state="avatar"
				:label="details.logo"
				shape="circle"
				size="medium"
				:effects="true"
			/>
			<Typography type="h4" weight="bold">{{ details.orgName }}</Typography>
		</Container>
		<Container v-if="details.orgDescription" align="left center" padding="0px 0px 5px 0px">
			<Markdown :text="details.orgDescription" />
		</Container>
	</Container>
</template>

<script lang="ts">
import { Container, Typography, Pictogram } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { OrgPrimaryDetailsTypes } from "@/modules/org/org-settings/org-settings-types";

import Markdown from "../Markdown.vue";

export default defineComponent({
	name: "OrgPrimaryDetails",

	components: {
		Container,
		Typography,
		Pictogram,
		Markdown
	},

	props: {
		details: {
			type: Object as PropType<OrgPrimaryDetailsTypes>,
			required: true
		}
	}
});
</script>
