<template>
	<PopOver :open="isOpen" @overlay-click="isOpen = false">
		<Button
			size="small"
			state="outlined"
			:class="!isOpen ? 'show-on-hover gray-300' : 'gray-300'"
			type="default"
			@click="isOpen = true"
			>View Details</Button
		>
		<template #content>
			<Wrapper
				border-radius="4px"
				background="gray-500"
				width="400px"
				max-height="80vh"
				overflow="visible"
				data-qa-resolver-details-popover
			>
				<Header>
					<Typography type="h4" color="dark">{{ resolver.component.title }}</Typography>
					<Container :padding="0" :shrink="0" :grow="1" align="right center">
						<Icon
							data-qa-resolver-popover-close-btn
							size="x-small"
							type="filled"
							name="i-close"
							@click="isOpen = false"
						/>
					</Container>
				</Header>
				<Container
					:gap="20"
					align="left top"
					direction="column"
					overflow="visible"
					data-qa-delete-deployment-confirmation-text
				>
					<Tabs :sticky="false" size="small" class="height-100-per width-100-per">
						<Tab
							width="100px"
							:selected="selectedTab === 'inputs'"
							data-qa-resolver-popover-input-tab
							@click="selectedTab = 'inputs'"
						>
							<Typography type="p2" color="gray-300">Inputs</Typography>
						</Tab>
						<Tab
							width="100px"
							:selected="selectedTab === 'outputs'"
							data-qa-resolver-popover-input-tab
							@click="selectedTab = 'outputs'"
						>
							<Typography type="p2" color="gray-300">Outputs</Typography>
						</Tab>
						<template #content>
							<f-div direction="column" padding="none" width="fill-container">
								<f-div
									width="fill-container"
									direction="row"
									padding="none"
									align="middle-center"
									style="margin-bottom: 15px; margin-top: 15px"
								>
									<f-div align="top-left" width="fill-container">
										<Typography type="p4" transform="uppercase">Name</Typography>
									</f-div>
									<f-div align="middle-left" width="fill-container">
										<Typography type="p4" transform="uppercase">Value</Typography>
									</f-div>

									<f-div v-if="!showSearch" width="fill-container" align="top-right">
										<f-icon
											variant="round"
											source="i-search-fill"
											size="small"
											class="cursor-pointer"
											:state="showSearch ? 'primary' : 'neutral'"
											@click="showSearchField"
										>
										</f-icon>
									</f-div>
									<f-div v-show="showSearch" width="fill-container" align="top-right">
										<SearchInput
											ref="search-input"
											v-model:value="searchString"
											size="small"
											:show-search-icon="false"
											data-qa-field="data-qa-resolver-popover-search-popover"
											placeholder=" Search"
											@update:value="handleInput"
										/>
									</f-div>
								</f-div>
								<f-div
									v-for="(item, key) in filteredKeyValuePairs"
									:key="key"
									width="fill-container"
									align="middle-center"
									gap="auto"
									height="fill-container"
									:clickable="true"
									padding="small none"
								>
									<f-div width="fill-container" align="top-left">
										<Typography type="p2" weight="medium">{{ key }}</Typography>
									</f-div>
									<f-div width="fill-container" align="top-left">
										<Typography type="p2-para">{{ item }}</Typography>
									</f-div>
									<f-div width="fill-container" align="top-right">
										<f-icon
											:tooltip="key"
											source="i-info-fill"
											size="small"
											class="show-on-over cursor-pointer"
											:state="showSearch ? 'primary' : 'neutral'"
										>
										</f-icon>
									</f-div>
								</f-div>
							</f-div>
						</template>
					</Tabs>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Header,
	Icon,
	PopOver,
	SearchInput,
	Tab,
	Tabs,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { DependencyResolverWithComponent } from "../dependency-registry-types";

export default defineComponent({
	name: "ResolverDetailsPopover",

	components: {
		Wrapper,
		Header,
		Icon,
		Typography,
		Container,
		Tabs,
		Tab,
		PopOver,
		Button,
		SearchInput
	},

	props: {
		resolver: {
			type: Object as PropType<DependencyResolverWithComponent>,
			required: true
		}
	},

	data: () => ({
		selectedTab: "inputs" as "inputs" | "outputs",
		isOpen: false,
		searchString: "",
		showSearch: false
	}),

	computed: {
		keyValuePairs() {
			return this.selectedTab === "inputs" ? this.resolver.inputMap : this.resolver.outputMap;
		},

		filteredKeyValuePairs() {
			if (this.searchString === "") {
				return this.keyValuePairs;
			} else {
				if (!this.keyValuePairs) {
					return {};
				}
				const filteredObj: Record<string, any> = {};

				Object.keys(this.keyValuePairs).forEach(k => {
					if (k.toLowerCase().includes(this.searchString.toLowerCase())) {
						filteredObj[k] = this.keyValuePairs![k];
					}
				});
				return filteredObj;
			}
		}
	},

	methods: {
		handleInput() {
			// This is just used to show the search icon.
			if (this.searchString === "") {
				this.showSearch = false;
			}
		},

		showSearchField() {
			this.showSearch = !this.showSearch;
			if (this.showSearch) {
				this.$nextTick(() => {
					//@ts-expect-error
					this.$refs["search-input"].focusSearch();
				});
			}
		}
	}
});
</script>
