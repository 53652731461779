<template>
	<Wrapper data-qa-applications-tab>
		<Container
			v-if="hasAppsInProject"
			:gap="0"
			:padding="0"
			:grow="1"
			class="flex-shrink-0"
			align="stretch"
		>
			<Wrapper width="320px">
				<Container direction="column" :padding="0" :gap="0">
					<Container padding="16px 12px 16px 0px" :shrink="0">
						<SearchInput v-model:value="searchText" data-qa-applications-tab-search-input />
						<ApplicationWizardWrapper
							v-if="isAppWizardEnabled"
							:open-add-new-app-popover="openAddNewAppPopover"
							@quit-flow="openAddNewAppPopover = false"
							@toggle-add-app="openAddNewAppPopover = !openAddNewAppPopover"
						>
							<Button
								v-tooltip="{
									content: 'New application'
								}"
								state="icon"
								data-qa-applications-tab-add-application
								@click="openAddNewAppPopover = true"
							>
								<Icon name="i-plus" color="gray-600" size="18px" :effects="false" />
							</Button>
						</ApplicationWizardWrapper>
						<CreateApplicationWizard
							v-if="!isAppWizardEnabled"
							v-model:open="openAddNewAppPopover"
							placement="right-start"
							:project="project"
							@close="() => (openAddNewAppPopover = false)"
							@app-created="onAppCreated"
						>
							<Button
								v-tooltip="{
									content: 'New application'
								}"
								state="icon"
								data-qa-applications-tab-add-application
								@click="openAddNewAppPopover = true"
							>
								<Icon name="i-plus" color="gray-600" size="18px" :effects="false" />
							</Button>
						</CreateApplicationWizard>
					</Container>
					<Container
						v-if="filteredApps.length > 0"
						class="flow-add-scrollbar bar-width-2"
						data-qa-applications-tab-applications-list
						direction="column"
						:padding="0"
						:gap="0"
						overflow="auto"
						:grow="1"
						align="left top"
					>
						<ApplicationEntity
							v-for="app in filteredApps"
							:key="app.id"
							:data-qa-app-id="app.id"
							data-qa-applications-tab-applications-list-item
							:data-qa-recently-created-app="app.id === lastCreatedAppId"
							:name="app.name"
							:sub-title="appDeploymentsCount[app.id] ?? 'Not deployed'"
							:selected="selectedApp?.id === app.id"
							@select="selectApplication(app)"
						/>
					</Container>
					<EmptyApplications
						v-else
						data-qa-applications-tab-empty-search
						:is-empty-from-search="true"
					/>
				</Container>
			</Wrapper>

			<Divider direction="vertical" state="border-light" :resize-siblings="true" :size="3" />

			<Container
				padding="16px 20px"
				direction="column"
				:gap="0"
				:grow="1"
				align="left top"
				overflow="auto"
				class="flow-add-scrollbar"
			>
				<Container direction="column" :gap="4" :shrink="0" :padding="0" align="left top">
					<Container :padding="0" :shrink="0" :gap="0" align="left center" overflow="visible">
						<Typography
							v-tooltip="{
								content: selectedApp?.name
							}"
							type="h3"
							color="dark"
							overflow="ellipsis"
							:data-qa-applications-tab-current-app-name="selectedApp?.name"
							>{{ selectedApp?.name }}</Typography
						>
						<Container :padding="0" :grow="1" align="right center" overflow="visible">
							<PopOver
								:open="popupState === 'open'"
								placement="left-start"
								@overlay-click="popupState = null"
							>
								<Icon
									v-tooltip="{
										content: 'Application settings'
									}"
									name="i-setting"
									size="20px"
									color="gray-100"
									data-qa-application-setting
									@click="popupState = 'open'"
								/>
								<template #content>
									<Wrapper
										border-radius="4px"
										background="element-light"
										:border="true"
										width="255px"
									>
										<Container direction="column" :padding="0" :gap="0">
											<Slab
												size="large"
												type="transparent"
												:effects="true"
												class="cursor-pointer"
												data-qa-field="edit-application"
												@click="popupState = 'edit-details'"
											>
												<template #primary-action>
													<Icon name="i-edit" color="gray-200" size="small" :effects="false" />
												</template>
												<Typography type="h5" weight="regular">Edit details</Typography>
											</Slab>

											<Slab
												size="large"
												type="transparent"
												:effects="true"
												class="cursor-pointer"
												data-qa-field="delete-application"
												@click="popupState = 'remove-application'"
											>
												<template #primary-action>
													<Icon name="i-delete" size="small" :effects="false" />
												</template>
												<Typography type="h5" weight="regular" color="danger-200"
													>Remove application</Typography
												>
											</Slab>
										</Container>
									</Wrapper>
								</template>
							</PopOver>
						</Container>
						<PopOver
							v-if="popupState === 'edit-details'"
							:open="popupState === 'edit-details'"
							target="[data-qa-application-setting]"
							@overlay-click="popupState = null"
						>
							<template #content>
								<AppEditModel
									v-if="selectedApp"
									:app="selectedApp"
									:toggle-app-edit-model="() => (popupState = null)"
									:close-modal="() => (popupState = null)"
								/>
							</template>
						</PopOver>

						<PopOver
							v-if="popupState === 'remove-application'"
							:open="popupState === 'remove-application'"
							target="[data-qa-application-setting]"
							@overlay-click="popupState = null"
						>
							<template #content>
								<AppDeletePopover
									v-if="selectedApp"
									:app="selectedApp"
									:close-app-delete-popover="() => (popupState = null)"
									:is-delete-popover-open="popupState === 'remove-application'"
								/>
							</template>
						</PopOver>
					</Container>

					<Markdown
						v-if="selectedApp?.description"
						data-qa-applications-tab-app-description
						:text="selectedApp.description"
						state="secondary"
						size="small"
					/>

					<Container padding="10px 0 0 0" :gap="12" align="left" overflow="visible">
						<AssignCredentialModal
							v-if="selectedApp && isUserOrgAdmin"
							:entity="selectedApp"
							git-form-ref="appGitForm"
							:cred-scope="credScope.git"
							entity-kind="app"
						/>
						<AssignCredentialModal
							v-if="selectedApp && isUserOrgAdmin"
							:entity="selectedApp"
							entity-kind="app"
							:cred-scope="credScope.docker"
						/>
						<AssignCredentialModal
							v-if="selectedApp && isUserOrgAdmin"
							:entity="selectedApp"
							entity-kind="app"
							:cred-scope="credScope.cloud"
						/>
					</Container>
				</Container>

				<ApplicationsTabArtifacts
					v-if="selectedApp"
					data-qa-applications-tab-artifacts
					:project="project"
					:app="selectedApp"
					@deploy="openDeploymentForArtifact"
				/>

				<ApplicationsTabDependencies
					v-if="selectedApp"
					data-qa-applications-tab-dependencies
					:app="selectedApp"
				/>

				<ApplicationsTabIntegrations
					v-if="selectedApp"
					:project="project"
					:app="selectedApp"
					data-qa-applications-integration-pipelines
				/>
				<PopOver
					v-if="Boolean(selectedDeploymentArtifact)"
					target="[data-qa-application-setting]"
					:open="Boolean(selectedDeploymentArtifact)"
				>
					<template #content>
						<ApplicationDeploymentModal
							v-if="selectedDeploymentArtifact && selectedApp"
							:app="selectedApp"
							:project-id="project.id"
							:selected-artifact-id="selectedDeploymentArtifact.id"
							:disable-back-button="true"
							@close="() => (selectedDeploymentArtifact = null)"
							@back="() => (selectedDeploymentArtifact = null)"
						/>
					</template>
				</PopOver>
			</Container>

			<Divider direction="vertical" state="border-light" :resize-siblings="true" :size="3" />

			<Wrapper width="380px" height="inherit">
				<ApplicationsTabDeployments v-if="selectedApp" :project="project" :app="selectedApp" />
			</Wrapper>
		</Container>

		<Container v-else :grow="1" align="center" data-qa-applications-tab-empty>
			<!-- show NewEmptyApplications component for app wizard feature flag -->
			<ApplicationWizardWrapper
				v-if="isAppWizardEnabled"
				:open-add-new-app-popover="openAddNewAppPopover"
				@quit-flow="openAddNewAppPopover = false"
				@toggle-add-app="openAddNewAppPopover = !openAddNewAppPopover"
			>
				<NewEmptyApplications @toggle-add-app="openAddNewAppPopover = !openAddNewAppPopover" />
			</ApplicationWizardWrapper>
			<EmptyApplications v-else :project="project" />
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Icon,
	PopOver,
	SearchInput,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import AppEditModel from "@/modules/application/components/app-widget/app-header/AppEditModel.vue";
import ApplicationDeploymentModal from "@/modules/application/components/ApplicationDeploymentModal.vue";
import CreateApplicationWizard from "@/modules/application/components/CreateApplicationWizard.vue";
import ApplicationWizardWrapper from "@/modules/application/views/ApplicationWizardWrapper.vue";
import NewEmptyApplications from "@/modules/application/views/NewEmptyApplications.vue";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { applicationIntegrationStore } from "@/modules/application-integration/application-integration-store";
import { breadcrumbStore } from "@/modules/core/breadcrumb-store";
import AssignCredentialModal from "@/modules/credentials/components/credential-assign/AssignCredentialModal.vue";
import { credentialStore } from "@/modules/credentials/credential-store";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { orgStore } from "@/modules/org/org-store";
import { OrgRole } from "@/modules/org/org-types";
import AppDeletePopover from "@/modules/project-landing/components/landingBody/AppDeletePopover.vue";
import EmptyApplications from "@/modules/project-landing/views/EmptyApplications.vue";
import { app as appProto } from "@/protocol/app";
import { Artifact } from "@/protocol/common";
import { CredScope, project as projectProto } from "@/protocol/identity";
import ApplicationEntity from "@/shared/components/application/ApplicationEntity.vue";
import Markdown from "@/shared/components/Markdown.vue";

import ApplicationsTabArtifacts from "./ApplicationsTabArtifacts.vue";
import ApplicationsTabDependencies from "./ApplicationsTabDependencies.vue";
import ApplicationsTabDeployments from "./ApplicationsTabDeployments.vue";
import ApplicationsTabIntegrations from "./ApplicationsTabIntegrations.vue";

export default defineComponent({
	name: "ApplicationsTab",

	components: {
		Button,
		Container,
		Divider,
		Icon,
		PopOver,
		SearchInput,
		Slab,
		Typography,
		Wrapper,
		ApplicationEntity,
		EmptyApplications,
		AppDeletePopover,
		AppEditModel,
		CreateApplicationWizard,
		ApplicationsTabDependencies,
		ApplicationsTabArtifacts,
		ApplicationsTabDeployments,
		ApplicationDeploymentModal,
		ApplicationsTabIntegrations,
		AssignCredentialModal,
		ApplicationWizardWrapper,
		NewEmptyApplications,
		Markdown
	},

	props: {
		project: {
			type: Object as PropType<projectProto>,
			required: true
		},

		apps: {
			type: Array as PropType<appProto[]>,
			required: true
		}
	},

	data: () => ({
		searchText: "",
		popupState: null as "open" | "edit-details" | "remove-application" | null,
		openAddNewAppPopover: false,
		selectedDeploymentArtifact: null as Artifact | null,
		credScope: CredScope
	}),

	computed: {
		appId() {
			return this.$route.params.appId;
		},

		lastCreatedAppId() {
			return applicationStore.lastCreatedAppId;
		},

		selectedApp() {
			return this.filteredApps.find(app => app.id === this.appId) ?? this.filteredApps[0];
		},

		filteredApps() {
			return this.apps
				.filter(app => app.name.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()))
				.sort((app1, app2) => {
					// Last created app should be at the top of the list
					if (app1.id === this.lastCreatedAppId) {
						return -1;
					} else if (app2.id === this.lastCreatedAppId) {
						return 1;
					}

					return app1.name.toLocaleLowerCase().localeCompare(app2.name.toLocaleLowerCase());
				});
		},

		appDeploymentsCount() {
			const appDeploymentsCount: Record<string, string> = {};

			const appDepEntities = applicationDeploymentStore.deploymentsInApp;

			Object.entries(appDepEntities).forEach(([appId, appDeployments]) => {
				const deploymentLength = appDeployments.length;

				if (deploymentLength === 0) {
					appDeploymentsCount[appId] = "No deploymnents";
				} else if (deploymentLength === 1) {
					appDeploymentsCount[appId] = "1 deployment";
				} else {
					appDeploymentsCount[appId] = `${appDeployments.length} deployments`;
				}
			});

			return appDeploymentsCount;
		},

		hasAppsInProject() {
			return this.apps.length > 0;
		},

		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		},

		isAppWizardEnabled() {
			return featureFlagStore.featureMap.ENABLE_APP_WIZARD_FLOW;
		}
	},

	watch: {
		"selectedApp.id": {
			deep: true,
			immediate: true,

			handler(currId: string) {
				const app = this.selectedApp;

				if (!credentialStore.entityCredentials[currId] && app?.role === OrgRole.ADMIN) {
					credentialStore.GET_CREDS_FOR_ENTITY({
						orgId: app.orgId,
						app: {
							id: app.id,
							orgId: app.orgId,
							projId: app.projId
						}
					});
				}

				// We only want to show breadcrumbs for apps selected via a route
				const routeApp = this.filteredApps.find(app_ => app_.id === this.appId);
				if (routeApp) {
					breadcrumbStore.SET_ADDITIONAL_BREADCRUMBS([
						{
							qaId: "projectListWithApp",
							label: routeApp.name,
							route: {
								name: "projectListWithApp",
								props: {
									orgId: routeApp.orgId,
									projectId: routeApp.projId,
									appId: routeApp.id
								}
							}
						}
					]);
				}
			}
		}
	},

	mounted() {
		applicationDeploymentStore.LIST_PROJECT_APP_DEPLOYMENTS({
			orgId: this.project.orgId,
			projectId: this.project.id
		});
		applicationIntegrationStore.FETCH_INTEGRATION_MODULES({
			orgId: this.project.orgId,
			keywords: []
		});
		// Reset the appId as there are no selected Apps/integrations here.
		applicationIntegrationStore.SET_CURR_INTEGRATION_APPID(null);
	},

	methods: {
		onAppCreated(app: appProto) {
			this.openAddNewAppPopover = false;
			this.selectApplication(app);
		},

		selectApplication(app: appProto) {
			this.$router.push({
				name: "projectListWithApp",
				params: {
					orgId: app.orgId,
					projectId: app.projId,
					appId: app.id
				}
			});
		},

		openDeploymentForArtifact(artifact: Artifact) {
			this.selectedDeploymentArtifact = artifact;
		}
	}
});
</script>
