<template>
	<InfoPopover
		title="Welcome to your first project!"
		placement="bottom-start"
		target="applications"
		:is-open="showInfo"
		:show-overlay="true"
		@close="closePopover"
	>
		<f-div>
			<f-text variant="para" size="small">
				This is your application tab. Here you can add, build and deploy your applications.
			</f-text>
		</f-div>
		<f-div padding="large none none none">
			<f-text>
				Note: You will need to configure the project before adding any applications.
			</f-text>
		</f-div>
	</InfoPopover>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { userStore } from "@/modules/user/user-store";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";

export default defineComponent({
	name: "ProjectApplicationPopover",

	components: {
		InfoPopover
	},

	data: () => ({
		showInfo: false as boolean
	}),

	computed: {
		infoFlags() {
			return userStore.profile?.metadata?.infoFlags;
		}
	},

	mounted() {
		if (!this.infoFlags?.hasProjectApplicationPopover) {
			this.showInfo = true;
		}
	},

	methods: {
		closePopover() {
			this.showInfo = false;
			userStore.UPDATE_USER_META({
				infoFlags: {
					...this.infoFlags,
					hasProjectApplicationPopover: true
				}
			});
		}
	}
});
</script>
