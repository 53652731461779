<template>
	<table class="flow-table width-100-per" border="none">
		<template v-for="([name, field], idx) in arrayFields" :key="field.id">
			<template v-if="isPlainField(field)">
				<TableRow
					:hover="false"
					:data-qa-table-field-name="name"
					:data-qa-table-field-value="getFieldValue(name)"
				>
					<TableCell padding="0 40px 0 0" width="1%">
						<f-text size="small" ellipsis>{{ getFieldLabel(field) }}</f-text>
					</TableCell>

					<TableCell padding="0">
						<f-icon
							v-if="getFieldValue(name) === null"
							source="i-minus-sm"
							state="subtle"
							size="x-small"
						></f-icon>

						<f-text v-else size="small" ellipsis :tooltip="String(getFieldValue(name))">{{
							getFieldValue(name)
						}}</f-text>
					</TableCell>
				</TableRow>

				<TableRow v-if="idx < arrayFields.length - 1" :hover="false">
					<TableCell padding="8px 0" colspan="2"> <Divider /> </TableCell>
				</TableRow>
			</template>

			<template v-else-if="field.type === 'array'">
				<Container padding="10px 0">
					<f-text transform="uppercase" ellipsis weight="bold">{{
						getFieldLabel(field).toLocaleUpperCase()
					}}</f-text>
				</Container>

				<template v-for="(value, idx_) in getFieldValue(name)" :key="String(idx_) + name + 'row'">
					<TableRow v-if="value" :hover="false">
						<TableCell padding="5px 0 15px 20px" colspan="2">
							<FormFieldTable :fields="field.field" :values="value" />
						</TableCell>
					</TableRow>
				</template>
			</template>
		</template>
	</table>
</template>

<script lang="ts">
import { FormBuilderField, FormBuilderValues } from "@cldcvr/flow-form-builder";
import { Container, Divider, TableCell, TableRow } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

/**
 * Renders a FormBuilderField array as a table
 */
export default defineComponent({
	name: "FormFieldTable",
	components: { Container, TableRow, Divider, TableCell },

	props: {
		fields: {
			type: Object as PropType<FormBuilderField>,
			required: true
		},

		values: {
			type: null as unknown as PropType<unknown>,
			required: true
		}
	},

	computed: {
		arrayFields(): [string, FormBuilderField][] {
			// We only support object and array fields
			const { fields } = this;

			if ("fields" in fields) {
				return Object.entries(fields.fields);
			}

			return [["field", fields]];
		}
	},

	methods: {
		isPlainField(field: FormBuilderField) {
			return (
				field.type === "text" ||
				field.type === "number" ||
				field.type === "textarea" ||
				field.type === "select" ||
				field.type === "radio" ||
				field.type === "password" ||
				field.type === "checkbox" ||
				field.type === "email" ||
				field.type === "tel" ||
				field.type === "url" ||
				field.type === "switchButton" ||
				field.type === "emoji"
				// These fields are not supported by f-form-builder yet
				// field.type === "emoji" ||
				// field.type === "date" ||
			);
		},

		getFieldValue(field: string): FormBuilderValues {
			//@ts-expect-error
			const value = this.values[field];

			if (typeof value === "boolean") {
				return value ? "Yes" : "No";
			}

			return value || null;
		},

		getFieldLabel(field: FormBuilderField): string {
			const fieldLabel = "label" in field ? field.label : null;

			if (typeof fieldLabel === "string") {
				return fieldLabel;
			}

			if (fieldLabel && "title" in fieldLabel && fieldLabel.title) {
				return String(fieldLabel.title);
			}

			if ("name" in field) {
				return String(field.name);
			}

			return "";
		}
	}
});
</script>
