<template>
	<Container align="left top" direction="column" :padding="0" data-qa-env-wid-output-content>
		<Container
			v-if="isLoading"
			align="center"
			direction="column"
			class="align-items-center height-100-per"
			:gap="12"
			:grow="1"
			data-qa-env-wid-output-content-loading
		>
			<Icon name="i-history" type="outlined" state="primary" size="small" :effects="false" />
		</Container>
		<Container v-if="outputNotFound" :padding="90" :grow="1" align="center" align-self="center">
			<EmptyState
				:icon="outputNotFound.icon"
				:message="outputNotFound.message"
				:subtitle="outputNotFound.subtitle"
				:shape="outputNotFound.shape"
				data-qa="no-env-widget-output-exists"
			/>
		</Container>
		<Container
			v-if="isEnvDeployed && envOutputSummary"
			overflow="auto"
			direction="column"
			:gap="16"
			:padding="16"
			align="stretch top"
			class="flow-add-scrollbar env-out-accordions"
		>
			<Accordion :open="openVariables">
				<template #header>
					<Container
						align="space-between center"
						:padding="0"
						:gap="4"
						data-qa-env-wid-accordian-variables
					>
						<Typography type="p3" color="light" transform="uppercase">VARIABLES</Typography>
						<Container :padding="0">
							<Tag v-if="envOutputSummary.outputs" shape="rounded" size="small">
								{{ Object.keys(envOutputSummary.outputs).length }}
							</Tag>
							<Icon
								name="i-chevron-down"
								:rotate="openVariables ? 180 : 0"
								type="filled"
								state="light"
								size="x-small"
								@click="openVariables = !openVariables"
							/>
						</Container>
					</Container>
				</template>
				<Container padding="10px 0px 0px 0px" align="left top">
					<table
						class="key-value"
						data-key-size="20"
						data-row-gutter="8px"
						aria-label="key variables table"
					>
						<thead>
							<th id="empty"></th>
						</thead>
						<tbody>
							<tr
								v-for="(output, index) in envOutputSummary.outputs"
								:key="index"
								data-qa-env-wid-output-content-variable-list
							>
								<td>
									<Typography type="p2" color="dark" weight="medium">
										{{ index }}
									</Typography>
								</td>
								<td>
									<Typography v-if="isLink(output.value)" type="p2" color="primary" :link="true">
										<a class="fc-primary" rel="noopener" :href="output.value" target="_blank">{{
											output.value
										}}</a>
									</Typography>
									<Typography v-if="!isLink(output.value)" type="p2" color="primary">
										{{ output.sensitive ? "••••••••••" : output.value }}
									</Typography>
								</td>
							</tr>
						</tbody>
					</table>
				</Container>
			</Accordion>
			<Divider />
			<Accordion :open="openServices">
				<template #header>
					<Container
						align="space-between center"
						:padding="0"
						:gap="4"
						data-qa-env-wid-accordian-services
					>
						<Typography type="p3" color="light" transform="uppercase">services</Typography>
						<Container :padding="0">
							<Tag v-if="envOutputSummary.resources" shape="rounded" size="small">
								{{ Object.keys(envOutputSummary.resources).length }}
							</Tag>
							<Icon
								name="i-chevron-down"
								:rotate="openServices ? 180 : 0"
								type="filled"
								state="light"
								size="x-small"
								@click="openServices = !openServices"
							/>
						</Container>
					</Container>
				</template>
				<Container padding="10px 0px 0px 0px">
					<table
						class="key-value"
						data-key-size="20"
						data-row-gutter="8px"
						aria-label="service table"
					>
						<thead>
							<th id="empty"></th>
						</thead>
						<tbody>
							<tr
								v-for="resource in envOutputSummary.resources"
								:key="resource.id"
								data-qa-env-wid-output-content-service-list
							>
								<td>
									<Typography type="p2" color="dark">{{ resource.id }}</Typography>
								</td>
							</tr>
						</tbody>
					</table>
				</Container>
			</Accordion>
		</Container>
	</Container>
</template>

<script lang="ts">
import {
	Typography,
	Container,
	Icon,
	Accordion,
	Divider,
	Tag,
	EmptyState
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { envListStore } from "@/modules/env-list/env-list-store";
import { environment } from "@/protocol/infra";
import { isLink } from "@/utils";

export default defineComponent({
	name: "EnvWidgetEmptyOutputContent",
	components: { Typography, Container, Icon, Accordion, Divider, Tag, EmptyState },

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		}
	},

	data: () => ({
		isLoading: true,
		openVariables: true,
		openServices: true
	}),

	computed: {
		isEnvDeployed() {
			return !this.isLoading && this.env.isDeployed;
		},

		envOutputSummary() {
			return envListStore.envOutputSummary[this.env.id];
		},

		outputNotFound() {
			if (
				(!this.isEnvDeployed && !this.isLoading) ||
				(this.isEnvDeployed && !this.envOutputSummary)
			) {
				return {
					message: "No output available",
					subtitle:
						!this.isEnvDeployed && !this.isLoading
							? `Please deploy ${this.env.name} to see it’s output.`
							: `${this.env.name} does not have any output.`,

					shape: "squircle",
					icon: {
						name: "i-launch",
						type: "filled",
						state: "default",
						size: "medium",
						color: "gray-200"
					}
				} as const;
			}

			return null;
		}
	},

	mounted() {
		this.getEnvDepSummary();
	},

	methods: {
		async getEnvDepSummary() {
			this.isLoading = true;
			await envListStore.GET_ENV_OUTPUT_SUMMARY({
				orgId: this.env.orgId,
				projectId: this.env.projId,
				envId: this.env.id
			});
			this.isLoading = false;
		},

		isLink
	}
});
</script>
<style lang="scss">
.env-out-accordions .accordion-body {
	overflow-y: hidden !important;
}
</style>
