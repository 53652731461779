<template>
	<Container
		overflow="visible"
		align="center top"
		direction="column"
		:grow="1"
		padding="16px"
		data-qa-deployment-form-template-step
		:data-qa-templates-loaded="!isLoadingTemplates"
	>
		<f-form-builder
			data-qa-edit-dependency-form
			:field.prop="formFields"
			:values.prop="selectedTemplate as FormBuilderValues"
			@input="handleInput"
		/>

		<f-divider />

		<JSONSchemaFormBuilder2
			v-if="templateFields"
			ref="templateValues"
			:fields="templateFields"
			:default-values="defaultTemplateValues ?? undefined"
			@info="handleTemplateInfo"
		/>

		<CustomLoader v-if="isLoadingTemplates" data-qa="data-qa-deployment-templates-loader" />
	</Container>
</template>

<script lang="ts">
import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FormBuilderField, FormBuilderValues, html } from "@cldcvr/flow-form-builder";
import { Container } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { ArtifactType, JSONSchema, Provisioner } from "@/protocol/common";
import { AppDeployment } from "@/protocol/deployment";
import { pipelineModule } from "@/protocol/pipeline";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import JSONSchemaFormBuilder2 from "@/shared/components/JSONSchemaFormBuilder2.vue";
import { getProvisionerIcon } from "@/utils";

export default defineComponent({
	name: "ApplicationDeploymentModalTemplateStep",

	components: {
		JSONSchemaFormBuilder2,
		Container,
		CustomLoader
	},

	inject: ["updateLayout"],

	props: {
		appDeployment: {
			type: Object as PropType<AppDeployment>
		},

		// used as v-model sync
		// eslint-disable-next-line vue/no-unused-properties
		templateInfo: {
			type: Object as PropType<TemplateInfo>,
			required: true
		},

		artifactType: {
			type: String as PropType<ArtifactType>,
			required: true
		},

		environmentId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		selectedTemplate: null as TemplateOption | null,
		isLoadingTemplates: false,
		templateFields: null as JSONSchema | null,
		defaultTemplateValues: null as Record<string, any> | null
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		deploymentTemplates() {
			const { orgId, artifactType } = this;
			const templates = applicationDeploymentStore.orgAppDeploymentTemplates[orgId] ?? [];

			const filteredTemplates = templates.filter(deploymentTemplate => {
				return (
					deploymentTemplate.expectedArtifactType?.length === 0 ||
					deploymentTemplate.expectedArtifactType?.some(type => type === artifactType)
				);
			});

			const provisionerMap: Record<string, pipelineModule> = {};

			filteredTemplates.forEach(template => {
				provisionerMap[getPipelineModuleId(template)] = template;
			});

			return provisionerMap;
		},

		deploymentTemplateOptions(): TemplateOption[] {
			return Object.values(this.deploymentTemplates).map(pipelineModuleToTemplateOption);
		},

		formFields(): FormBuilderField {
			return {
				type: "select",
				id: "app-deployment-configure-template",
				label: { title: "Deployment template" },
				placeholder: "Select deployment template",
				options: this.deploymentTemplateOptions,
				validationRules: [
					{
						name: "required"
					}
				],

				//@ts-expect-error
				optionTemplate: (option: TemplateOption) =>
					html` <f-div gap="medium" overflow="hidden" align="middle-left">
						<f-div width="hug-content">
							<f-icon state="warning" source="${option.data.icon}"> </f-icon>
						</f-div>

						<f-div direction="column">
							<f-text ellipsis="true">${option.title}</f-text>
							<f-text size="x-small" state="secondary">${option.data.description}</f-text>
						</f-div>
					</f-div>`,

				showWhen: () => {
					return !this.isLoadingTemplates;
				}
			};
		}
	},

	watch: {
		environmentId: {
			immediate: true,

			async handler() {
				if (!this.environmentId) {
					return;
				}

				const templatePromise = applicationDeploymentStore.FETCH_ORG_APP_DEPLOYMENT_TEMPLATES({
					orgId: this.orgId,
					provisioner: Provisioner.no_provisioner
				});

				// Wait for templates only when we don't have any defined
				if (Object.keys(this.deploymentTemplates).length === 0) {
					this.isLoadingTemplates = true;
					await templatePromise;
				}

				// If we have an existing deployment then find the matching template from it
				if (this.appDeployment) {
					const templateFromRefId =
						this.deploymentTemplates[
							this.appDeployment.deploymentConfig?.template?.moduleRefId ?? ""
						];

					if (templateFromRefId) {
						this.selectedTemplate = pipelineModuleToTemplateOption(templateFromRefId);
					}
				}

				this.isLoadingTemplates = false;
			}
		},

		selectedTemplate: {
			immediate: true,
			deep: true,

			handler() {
				this.$nextTick(() => {
					this.emitUpdateInfo();
				});

				const selectedTemplateId = this.selectedTemplate?.data.id;
				const deploymentTemplate = this.deploymentTemplates[selectedTemplateId ?? ""];

				const deploymentTemplateFields = deploymentTemplate?.inputs;

				// When there is no template found then reset the form
				if (!deploymentTemplateFields) {
					this.templateFields = null;
					this.defaultTemplateValues = null;
					return;
				}

				this.templateFields = deploymentTemplateFields;

				// If we have an app deployment, then update the form builder with it's values
				const appDeploymentTemplate = this.appDeployment?.deploymentConfig?.template;
				const deploymentInputs = appDeploymentTemplate?.inputs;

				// We are editing the deployment template if we have an input in the artifact
				// and we are also using the same template
				if (appDeploymentTemplate?.moduleRefId === selectedTemplateId && deploymentInputs) {
					this.defaultTemplateValues = deploymentInputs;
				} else {
					this.defaultTemplateValues = null;
				}
			}
		}
	},

	methods: {
		handleInput(event: CustomEvent<TemplateOption>) {
			this.selectedTemplate = event.detail;
		},

		handleTemplateInfo({ inputs, isValid }: { inputs: unknown; isValid: boolean }) {
			const selectedTemplateId = this.selectedTemplate?.data.id;
			const deploymentTemplate = this.deploymentTemplates[selectedTemplateId ?? ""];

			this.$emit("update:templateInfo", {
				template: deploymentTemplate,
				inputs,
				isValid
			});

			// Update the popover layout when the form changes significantly
			this.$nextTick(() => {
				if (this.updateLayout) {
					//@ts-expect-error Vue 2.x has no way to type injects
					this.updateLayout();
				}
			});
		},

		emitUpdateInfo() {
			const deploymentTemplate = this.deploymentTemplates[this.selectedTemplate?.data.id ?? ""];

			const deploymentTemplateFields = deploymentTemplate?.inputs;

			// When there is no template found then reset the form
			if (!deploymentTemplateFields) {
				this.$emit("update:templateInfo", {
					template: undefined,
					inputs: undefined,
					isValid: false
				});
				return;
			}

			const formBuilder = this.$refs.templateValues as InstanceType<typeof JSONSchemaFormBuilder2>;
			const { inputs, isValid } = formBuilder.getFormInfo();

			this.$emit("update:templateInfo", {
				template: deploymentTemplate,
				inputs,
				isValid
			});
		}
	}
});

// Server returns a limited amount of information in an application deployment which makes
// it harder to determine which template it belongs to. So we need this logic to determine that
export function getPipelineModuleId(module: pipelineModule) {
	return `${module.provisioner}:${module.name}@${module.version}`;
}

function pipelineModuleToTemplateOption(module: pipelineModule): TemplateOption {
	const pipelineId = getPipelineModuleId(module);
	return {
		title: module.displayName ?? "",
		data: {
			id: pipelineId,
			description: pipelineId,
			icon: getProvisionerIcon(module.provisioner)
		}
	};
}

export type TemplateInfo = {
	template?: pipelineModule;
	inputs?: FormBuilderValues;
	isValid: boolean;
};

export type TemplateOption = FSelectOptionObject & {
	data: {
		id: string;
		description: string;
		icon: string;
	};
};
</script>
