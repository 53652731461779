/* eslint-disable */
import { EnvironmentId, AppIntegrationId, DeploymentId } from "./common";
import { HealthCheckRequest, HealthCheckResponse } from "./health";

export enum triggerType {
	no_trigger_type_support = "no_trigger_type_support",
	environment = "environment",
	appInt = "appInt",
	appDep = "appDep"
}

export enum triggerEvent {
	no_trigger_event_support = "no_trigger_event_support",
	pushToBranch = "pushToBranch",
	pushToTag = "pushToTag",
	pullReq = "pullReq"
}

export interface webhookRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projectId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	entityId: string;
	environment?: EnvironmentId;
	appInt?: AppIntegrationId;
	appDep?: DeploymentId;
	// environment, appInt or appDep
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	triggerType: triggerType;
	// job name in correspondance with entity
	// for infra pipeline => ValidateOnly, ValidateAndApply and Destroy
	// for deployment pipeline => deploy and undeploy
	// for integration pipeline => no value required
	action?: string;
	githubTrigger?: GithubTrigger;
	codecommitTrigger?: CodecommitTrigger;
	// other artifact triggers e.g. DockerHub
	codepipesTrigger?: CodepipesTrigger;
	// custom webhook
	customTrigger?: CustomTrigger;
}

export interface GithubTrigger {
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	triggerEvent: triggerEvent;
	// identifier is corresponding to the trigger event, as the trigger event is met
	// corresponding github webhook will be called
	// If trigger event is pushToBranch, identifier is the branch/pattern to be monitored for commits push
	// If trigger event is pushToTag, identifier is the tag/pattern to be monitored for commits push
	// If trigger event is pullReq, identifier is the base branch/pattern against which pull request is raised
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	identifier: string;
}

export interface CodecommitTrigger {
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	triggerEvent: triggerEvent;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	identifier: string;
}

export interface CodepipesTrigger {
	appInt?: AppIntegrationId;
}

export interface CustomTrigger {
	// we dont need any trigger informaton as request since
	// the webhook will be triggered upon invoking the URL
	// which is prepared as part of webhook create flow.
	//
	// output attribute only
	hookURL?: string;
}

export interface deleteWebhookRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	webhookId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projectId: string;
}

export interface webhook {
	orgId?: string;
	projectId?: string;
	entityId?: string;
	triggerType?: triggerType;
	// job name in correspondance with entity
	// for infra pipeline => ValidateOnly, ValidateAndApply and Destroy
	// for deployment pipeline => deploy and undeploy
	// for integration pipeline => no value required
	action?: string;
	githubTrigger?: GithubTrigger;
	codecommitTrigger?: CodecommitTrigger;
	// other artifact triggers e.g. DockerHub
	codepipesTrigger?: CodepipesTrigger;
	customTrigger?: CustomTrigger;
	id?: string;
	environment?: EnvironmentId;
	appInt?: AppIntegrationId;
	appDep?: DeploymentId;
	// audit info
	// User ID of the last person to update the webhook.
	updatedBy?: string;
	// Date the webhook was created, expressed as an integer in epoch timestamp format. Example:  `1607065223`
	createdAt?: string;
	// Date the webhook was updated, expressed as an integer in epoch timestamp format. Example: `1607065223`
	updatedAt?: string;
}

export interface getWebhookRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	webhookId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projectId: string;
}

export interface listWebhookRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projectId: string;
	// optional query param
	// to be used as another filter
	entityId?: string;
	// optional
	// webhook reference ID stored at source
	// for github it will the github's webhook ID
	// for codecommit it will be subscription ARN
	// for codepipes it will be integration ID
	webhookRef?: string;
}

export interface listWebhookResponse {
	webhooks?: webhook[];
}

export interface updateWebhookRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	webhookId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projectId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	entityId: string;
	// updatable fields for webhook [action and eventTrigger]
	//
	// job name in correspondance with entity
	// for infra pipeline => ValidateOnly, ValidateAndApply and Destroy
	// for deployment pipeline => deploy and undeploy
	// for integration pipeline => no value required
	action?: string;
	githubTrigger?: GithubTrigger;
	codecommitTrigger?: CodecommitTrigger;
	// other artifact triggers e.g. DockerHub
	codepipesTrigger?: CodepipesTrigger;
}

// API to triggerCodepipes webhook
export interface triggerCodepipesWebhookRequest {
	appInt?: AppIntegrationId;
	// if the webhook was configured for deploy action
	// it should represent the container revision
	// override reference
	//
	// Further, if the webhook was configured for promote action
	// it should represent the version input for promote
	eventRef?: string;
}

export interface success {
	success?: boolean;
}

export interface externalEventsService {
	// Options: [{"(google.api.http)":{"get":"/v0/healthcheck/*"}}]
	healthCheck(request: HealthCheckRequest): Promise<HealthCheckResponse>;

	// CreateWebhook
	//
	// Creates webhook with details provided
	// results in error if invalid action is provided for infra and deployment pipeline
	// @returns the webhook object
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks","body":"*"}}]
	createWebhook(request: webhookRequest): Promise<webhook>;

	// DeleteWebhook
	//
	// Deletes the webhook with the webhookId provided
	// Responds as error if the webhook is not found
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks/{webhookId=*}"}}]
	deleteWebhook(request: deleteWebhookRequest): Promise<Record<string, never>>;

	// GetWebhook
	//
	// Fetches the webhook with the webhookId provided
	// Responds as error if the webhook is not found
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks/{webhookId=*}"}}]
	getWebhook(request: getWebhookRequest): Promise<webhook>;

	// ListWebhook
	//
	// By default, List the webhooks for the given project
	// Another filter option is the query param entityId
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks"}}]
	listWebhook(request: listWebhookRequest): Promise<listWebhookResponse>;

	// UpdateWebhook
	//
	// Action and eventTrigger can be updated
	// if there is no change in the event (push to pull OR pull to push)
	// github webhook will not be updated.
	// In case of change in event github webhook will be updated and
	// updated reference will be stored
	// e.g. If the event changes from pushToBranch to pushToTag, no need to
	// update github webhook as push event is already registered.
	// However if the event changes from pushToBranch to pullReq the github
	// webhook will be updated from push event to pull_req event
	//
	// Responds as error if the webhook is not found
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/projects/{projectId=*}/webhooks/{webhookId=*}","body":"*"}}]
	updateWebhook(request: updateWebhookRequest): Promise<webhook>;

	triggerCodepipesWebhook(request: triggerCodepipesWebhookRequest): Promise<success>;
}
