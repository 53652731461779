<script lang="ts">
import { Container, Divider } from "@cldcvr/flow-vue3";
import { PropType, computed, toRefs } from "vue";
import { useRoute } from "vue-router";

import { applicationStore } from "@/modules/application/application-store";
import { pipelineStatusMessageWithIcon } from "@/modules/application-deployment/constants";
import { projectStore } from "@/modules/project-list/project-store";
import { AppDeployment } from "@/protocol/deployment";
import { environment as EnvironmentProto } from "@/protocol/infra";
import Markdown from "@/shared/components/Markdown.vue";

import DeploymentListWebhooks from "./DeploymentListWebhooks.vue";
import DeploymentsListConfiguration from "./DeploymentsListConfiguration.vue";
import DeploymentsListHeader from "./DeploymentsListHeader.vue";
import DeploymentsListOutputs from "./DeploymentsListOutputs.vue";
import DeploymentsListSidebar from "./DeploymentsListSidebar.vue";
import DeploymentsListVariableTable from "./DeploymentsListVariableTable.vue";

export default {
	name: "DeploymentsList"
};
</script>

<script lang="ts" setup>
const props = defineProps({
	deployments: {
		type: Array as PropType<AppDeployment[]>,
		required: true
	},

	projectId: {
		type: String,
		required: true
	},

	env: {
		type: Object as PropType<EnvironmentProto>,
		required: true
	}
});

const { projectId, deployments } = toRefs(props);

const route = useRoute();

const sortedDeployments = computed(() => {
	return [...deployments.value].sort((dep1, dep2) => {
		return Number(dep2.updatedAt) - Number(dep1.updatedAt);
	});
});

const selectedDeployment = computed(() => {
	if (route.params.deploymentId) {
		const deploymentIdx = sortedDeployments.value.findIndex(
			dep => dep.id === route.params.deploymentId
		);
		return sortedDeployments.value[deploymentIdx];
	}

	return sortedDeployments.value[0];
});

const selectedDeploymentIdx = computed(() => {
	return selectedDeployment.value ? sortedDeployments.value.indexOf(selectedDeployment.value) : 0;
});

const currentProject = computed(() => projectStore.projects[projectId.value]);

const statusTextWithIcons = computed(() => {
	return sortedDeployments.value.map(deployment => pipelineStatusMessageWithIcon(deployment));
});

const selectedStatusTextAndIcon = computed(() => {
	return statusTextWithIcons.value[selectedDeploymentIdx.value];
});

const app = computed(() => {
	const projectApps = applicationStore.projectApps[projectId.value];
	return projectApps?.find(app => app.id === selectedDeployment.value?.appId);
});
</script>

<template>
	<Container :gap="0" :padding="0" :grow="1" class="flex-shrink-0" align="stretch">
		<DeploymentsListSidebar
			v-if="currentProject"
			:status-text-with-icons="statusTextWithIcons"
			:project="currentProject"
			:env="env"
			:deployments="sortedDeployments"
			:deployment="selectedDeployment"
		/>

		<Divider direction="vertical" state="border-light" :resize-siblings="true" :size="3" />

		<Container
			v-if="selectedDeployment"
			padding="16px 20px"
			direction="column"
			:grow="1"
			align="left top"
		>
			<DeploymentsListHeader
				:status-text-with-icon="selectedStatusTextAndIcon"
				:project-id="projectId"
				:deployment="selectedDeployment"
			/>

			<Container :padding="0" direction="column" :gap="0" align="left top" overflow="scroll">
				<Markdown v-if="app?.description" :text="app?.description" size="small" state="secondary" />
				<DeploymentsListOutputs :deployment="selectedDeployment" />
				<Divider />
				<DeploymentListWebhooks :deployment="selectedDeployment" :env="env" />
				<Divider />
				<DeploymentsListVariableTable :deployment="selectedDeployment" />
				<Divider />
				<DeploymentsListConfiguration :deployment="selectedDeployment" />
			</Container>
		</Container>
	</Container>
</template>
