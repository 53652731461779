<template>
	<Wrapper width="280px" height="86px">
		<Card type="placeholder">
			<Container align="center" class="height-100-per">
				<Typography type="p2" color="gray-300"
					>Promote app to
					<Typography
						type="p2"
						color="gray-100"
						weight="bold"
						:data-qa-empty-cp-envname="envName"
						inline
						>{{ envName }}</Typography
					>
				</Typography>
			</Container>
		</Card>
	</Wrapper>
</template>
<script lang="ts">
import { Wrapper, Card, Container, Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyCodePromoCard",

	components: {
		Wrapper,
		Card,
		Container,
		Typography
	},

	props: {
		envName: {
			type: String,
			required: true
		}
	}
});
</script>
