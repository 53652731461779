<template>
	<!-- Header -->
	<f-div padding="medium" height="hug-content" gap="large" state="secondary" width="100%">
		<f-icon
			source="i-arrow-left"
			class="cursor-pointer"
			data-qa-app-connect-form-back-btn
			state="default"
			@click="goBack"
		>
		</f-icon>
		<f-text variant="para" size="small" weight="bold" data-qa-connect-source-title>
			{{ !isAddingNewCred ? "Where's your application?" : "Add new credential" }}
		</f-text>
		<f-div align="middle-right" gap="small" width="10%">
			<f-icon
				source="i-question-filled"
				class="cursor-pointer"
				data-qa-app-connect-info-toggle-btn
				@click="$emit('toggle-info')"
			>
			</f-icon>
			<f-icon
				source="i-close"
				size="small"
				class="cursor-pointer"
				data-qa-app-connect-close-btn
				@click="$emit('close')"
			>
			</f-icon>
		</f-div>
	</f-div>
	<f-div
		v-if="isAuthSuccesful"
		padding="medium"
		height="50%"
		gap="medium"
		state="success"
		direction="row"
		width="100%"
	>
		<f-text
			size="small"
			weight="regular"
			data-qa-auth-success-title
			width="100%"
			data-qa-credential-success-title
		>
			New credential added successfully</f-text
		>
		<f-div align="middle-right" gap="small" width="10%">
			<f-icon
				class="cursor-pointer"
				source="i-close"
				size="x-small"
				data-qa-auth-success-close-icon
				@click="isAuthSuccesful = false"
			>
			</f-icon>
		</f-div>
	</f-div>
	<!-- Form -->
	<f-div direction="column" padding="none" overflow="scroll" style="max-height: 70vh">
		<f-div
			v-if="!isAddingNewCred"
			padding="medium"
			width="100%"
			height="hug-content"
			gap="medium"
			state="secondary"
			direction="column"
			class="description-field"
		>
			<!-- Git input artifact form -->
			<f-form-builder
				v-if="artifactType === 'GitCode'"
				:key="formValueKey"
				class="width-100-per"
				:field.prop="artifactFields"
				:values.prop="artifactFieldValues"
				@input="handleInput"
				@state-change="formState = $event.detail"
			/>

			<!-- Container input artifact form -->
			<ApplicationContainerForm
				v-if="artifactType == 'ContainerImage'"
				v-model:form-values="containerArtifactformValues"
				:existing-container-repo-names="[]"
				:show-display-name="true"
				:suggested-display-name="suggestedContainerAppName"
			/>
		</f-div>

		<!-- Git credential form -->
		<GithubCredentialModal
			v-else
			ref="appWizardGitCredModal"
			git-form-ref="appWizardFlow"
			:update-cred-list="true"
			:is-resusable-state="true"
			:new-org-id="orgId ?? ''"
			@authenticated="setAuthenticated"
			@on-cred-create="onCredCreate"
		/>
	</f-div>
	<!-- Error panel -->
	<f-div
		v-if="submitError"
		padding="medium"
		height="hug-content"
		gap="medium"
		direction="row"
		width="100%"
		state="danger"
	>
		<f-text size="small" weight="regular" variant="para" color="danger" data-qa-submit-err-text>
			{{ submitError }}</f-text
		>
		<f-div align="top-right" width="hug-content">
			<f-icon
				class="cursor-pointer"
				source="i-close"
				size="x-small"
				data-qa-error-close-icon
				@click="submitError = ''"
			>
			</f-icon>
		</f-div>
	</f-div>
	<f-button
		state="success"
		:disabled="isBtnDisabled"
		data-qa-create-org-submit-btn
		:loading="isSubmitting"
		category="fill"
		variant="block"
		label="Next - Select infrastructure dependencies"
		@click="createApp"
	></f-button>
</template>
<script lang="ts">
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { defineComponent, PropType } from "vue";

import GithubCredentialModal from "@/modules/credentials/components/credential-form/GithubCredentialModal.vue";
import { CREDENTIAL_TYPE_ICONS2 } from "@/modules/credentials/constants";
import { credentialStore } from "@/modules/credentials/credential-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { orgStore } from "@/modules/org/org-store";
import { userStore } from "@/modules/user/user-store";
import { app as AppProto } from "@/protocol/app";
import { Artifact, ArtifactType, GitRevisionType, GitServer, sourceEnum } from "@/protocol/common";
import { Creds, SourceWithLines } from "@/protocol/identity";
import {
	AppFirstFlowFormStorageService,
	GithubOauthStorageService
} from "@/services/storage-service";
import { APPLICATION_LANGUAGE_MAP2 } from "@/shared/constants";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import {
	extractGitHubRepoPath,
	getErrorMessage,
	parseGitUrl,
	parseUrl,
	safeEntityString
} from "@/utils";

import { applicationStore } from "../application-store";

import { containerProviderMap, containerProviderOptions } from "./app-widget/applicationForm";
import ApplicationContainerForm, {
	ApplicationContainerFormValues
} from "./ApplicationContainerForm.vue";

type CredSelectOption = {
	title: string;
	data:
		| {
				id: string;
				icon?: string;
				qaId?: string;
		  }
		| undefined;
};
type ArtifactFieldValues = {
	sourceURL: string;
	branch: string;
	directory: string;
	appName: string;
	appLanguage: {
		title: string;
		data: {
			id: sourceEnum;
		};
	} | null;
	cred: CredSelectOption | null;
};

export default defineComponent({
	name: "AddAppConnectSource",
	components: { GithubCredentialModal, ApplicationContainerForm },

	props: {
		isOauthComplete: {
			type: Boolean,
			default: false
		},

		app: {
			type: Object as PropType<AppProto | null>
		},

		isEditing: {
			type: Boolean,
			default: false
		}
	},

	emits: ["back", "toggle-info", "close", "update:artifactFieldValues", "app-created"],

	data: () => ({
		formValueKey: "",
		isSubmitting: false,
		submitError: "",
		formState: null as FormBuilderState | null,

		artifactFieldValues: {
			sourceURL: "",
			branch: "",
			directory: "/",
			appName: "",
			appLanguage: {
				title: "",
				data: {
					id: sourceEnum.source_no
				}
			},

			cred: null
		} as ArtifactFieldValues,

		artifactType: ArtifactType.GitCode,

		availableLanguages: [] as SourceWithLines[],
		isLoadingLanguages: false,
		isLanguageError: "",

		// Cred vars
		isAddingNewCred: false,
		isAuthSuccesful: false,
		newCred: null as Creds | null,

		// container artifact formValues
		containerArtifactformValues: {} as ApplicationContainerFormValues
	}),

	computed: {
		isBtnDisabled() {
			// @todo: keeping this for faster travel to next steps
			// return false;
			return !this.formState?.isValid || Boolean(!this.artifactFieldValues.cred?.data?.id);
		},

		orgId() {
			return orgStore.activeOrgId;
		},

		appLanguageOptions() {
			return this.availableLanguages.map(item => {
				const languageOption = APPLICATION_LANGUAGE_MAP2[item.language!];
				return {
					title: languageOption.title,
					data: languageOption.data
				};
			});
		},

		credOptions() {
			return (
				Object.values(credentialStore.genericCredentials)
					// .filter(cred => cred.credScope?.includes(CredScope.git))
					.map(cred => {
						const iconName = (cred.type && CREDENTIAL_TYPE_ICONS2[cred.type]) ?? "i-git-branch";
						const gitOption = {
							title: cred.name,
							data: {
								id: cred.id,
								icon: iconName,
								qaId: cred.id
							}
						};
						return gitOption;
					})
			);
		},

		artifactFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					sourceURL: {
						type: "text",
						qaId: "add-app-source-url",
						placeholder: "Enter source URL",
						helperText: "Add your app from git platform or container registry.",
						label: { title: "Application source URL" },
						prefix: "https://",
						validationRules: [{ name: "required" }]
					},

					cred: {
						type: "select",
						id: "cred",
						qaId: "add-app-git-cred",
						placeholder: "Select credentials",
						label: { title: "Select credential" },
						validationRules: [{ name: "required" }],
						clear: true,
						options: this.credOptions
					},

					addNewCred: {
						type: "button",
						className: "slab-primary-padding-0",
						label: "Add new credential",
						category: "transparent",
						size: "x-small",
						qaId: "add-cred-text-btn",
						onClick: () => {
							this.isAddingNewCred = true;
						},

						showWhen: () => !this.isAddingNewCred && !this.artifactFieldValues.cred?.data?.id
					},

					branch: {
						type: "text",
						id: "branch",
						qaId: "add-app-branch",
						label: { title: "Base branch" },
						placeholder: "Enter base branch name",
						validationRules: [{ name: "required" }],

						showWhen: () => Boolean(this.artifactFieldValues.cred?.data?.id)
					},

					directory: {
						type: "text",
						id: "directory",
						qaId: "add-app-directory",
						label: { title: "Directory path" },
						placeholder: "Enter directory path",
						validationRules: [{ name: "required" }],

						showWhen: () => Boolean(this.artifactFieldValues.cred?.data?.id)
					},

					appLanguage: {
						type: "select",
						id: "appLanguage",
						qaId: "add-app-language",
						label: { title: "App's primary language", subTitle: "Optional" },
						placeholder: "Select app's primary language",
						disabled: this.isLoadingLanguages,
						className: "loading",
						iconLeft: this.isLoadingLanguages ? "i-loader-success" : undefined,
						options: this.appLanguageOptions,
						showWhen: () => Boolean(this.artifactFieldValues.cred?.data?.id),
						state: this.isLanguageError ? "danger" : undefined,
						helperText: this.isLanguageError
					},

					appName: {
						type: "text",
						id: "appName",
						qaId: "add-app-name",
						label: { title: "App name" },
						placeholder: "Enter app name",
						suffix: "suggested",
						validationRules: [
							...applyEntityNameRules2(
								this.isEditing
									? this.existingAppNames.filter(name => name !== this.app?.name)
									: this.existingAppNames,
								"This application name is already taken."
							)
						],

						showWhen: () => Boolean(this.artifactFieldValues.cred?.data?.id)
					}
				}
			};
		},

		projectId() {
			return this.$route.params.projectId as string;
		},

		existingAppNames() {
			return applicationStore.projectApps[this.projectId]?.map(app => app.name) ?? [];
		},

		userProfile() {
			return userStore.profile;
		},

		suggestedContainerAppName() {
			const containerProvider = this.containerArtifactformValues.containerProvider?.data.id;
			const appPath =
				parseUrl(this.containerArtifactformValues.containerRepo)?.pathname.replace("/", "") ??
				this.containerArtifactformValues.containerRepo;

			return `${appPath}${
				containerProvider ? `-${containerProviderMap(containerProvider)}` : "container image"
			}`;
		}
	},

	watch: {
		// watcher on sourceURL and cred, trigger updateLanguages when both the fields are filled/selected
		"artifactFieldValues.sourceURL": {
			immediate: false,

			handler(newUrl: string, oldUrl: string) {
				if (newUrl !== oldUrl && this.artifactFieldValues.cred?.data?.id) {
					this.updateLanguages();
				}
			}
		},

		"artifactFieldValues.cred": {
			deep: true,
			immediate: false,

			handler(newCred: CredSelectOption | null, oldCred: CredSelectOption | null) {
				if (newCred?.data?.id !== oldCred?.data?.id && this.artifactFieldValues.sourceURL) {
					this.updateLanguages();
				}
			}
		},

		isEditing: {
			immediate: true,

			handler() {
				this.updateEditAppForm();
			}
		}
	},

	mounted() {
		this.initializeModal();
	},

	methods: {
		async updateLanguages() {
			try {
				if (this.artifactFieldValues.sourceURL && this.artifactFieldValues.cred?.data?.id) {
					// Check if the sourceURL is a git URL or a container registry URL
					const isGitUrl = parseGitUrl(this.artifactFieldValues.sourceURL);

					if (isGitUrl === null) {
						this.artifactType = ArtifactType.ContainerImage;
						this.containerArtifactformValues.containerRepo = this.artifactFieldValues.sourceURL;
					} else {
						this.artifactType = ArtifactType.GitCode;
						this.isLoadingLanguages = true;
						const response = await applicationStore.FETCH_GIT_LANGUAGES({
							id: this.artifactFieldValues.cred.data.id,
							repoUrl: `https://${this.artifactFieldValues.sourceURL}`,
							orgId: this.orgId ?? ""
						});
						this.availableLanguages = response.sortedLanguages ?? [];
					}
				}
				this.isLanguageError = "";
			} catch (error) {
				this.isLanguageError = getErrorMessage(error);
				this.artifactFieldValues.appLanguage = null;
			} finally {
				this.isLoadingLanguages = false;
			}
		},

		initializeModal() {
			// Flow to check if the user has already authenticated the github credentials
			const existingForm = AppFirstFlowFormStorageService.getAppFirstFlowForm();
			if (
				// Check if the existing form data is related to the first flow of the application
				!(
					Object.keys(existingForm).length &&
					this.isOauthComplete &&
					existingForm.gitFormRef === "appWizardFlow"
				)
			) {
				return;
			}

			if (existingForm.cred) {
				this.isAddingNewCred = true;
				this.isAuthSuccesful = true;
				const iconName =
					(existingForm.cred.type && CREDENTIAL_TYPE_ICONS2[existingForm.cred.type]) ??
					"i-git-branch";

				this.artifactFieldValues = {
					...this.artifactFieldValues,
					cred: {
						title: existingForm.cred.name,
						data: {
							id: existingForm.cred.id,
							icon: iconName,
							qaId: existingForm.cred.id
						}
					}
				};

				this.newCred = existingForm.cred;
				this.formValueKey = Math.random().toString();
			}
		},

		async updateEditAppForm() {
			// Flow to check if the user is editing the application
			if (
				this.isEditing &&
				this.app?.artifacts?.[0] &&
				this.userProfile?.metadata &&
				this.userProfile.metadata.appWizardMeta
			) {
				// get the existing cred
				const credId = this.userProfile.metadata.appWizardMeta[this.app.id]?.credId;
				if (credId) {
					// get the cred user to fetch the repo/image details
					await credentialStore.GET_UPDATE_CREDENTIAL({ id: credId, orgId: this.app.orgId });
					const cred = credentialStore.genericCredentials[credId]!;

					// Check if the artifact is of type Git or ContainerImage
					const [artifact] = this.app.artifacts;

					if (artifact.gitCode) {
						this.artifactFieldValues = {
							...this.artifactFieldValues,
							sourceURL: artifact.gitCode.repo,
							branch: artifact.gitCode.identifier,
							directory: artifact.gitCode.dir === "" ? "/" : artifact.gitCode.dir!,
							appName: this.app.name,
							appLanguage: {
								title:
									APPLICATION_LANGUAGE_MAP2[this.app.appConfig?.language ?? sourceEnum.source_no]
										.title,

								data: {
									id: this.app.appConfig?.language ?? sourceEnum.source_no
								}
							},

							cred: {
								title: cred.name,
								data: {
									id: cred.id,
									icon: cred.type ? CREDENTIAL_TYPE_ICONS2[cred.type] : "i-git-branch",
									qaId: cred.id
								}
							}
						};
					} else if (artifact.containerImage) {
						// fill the container artifact form values
						this.containerArtifactformValues = {
							appContainerImageName: this.suggestedContainerAppName,
							appHost: artifact.containerImage.host,
							containerProvider: containerProviderOptions.find(
								p => p.data.id === artifact.containerImage?.provider
							),

							versionTagPattern: {
								title: artifact.containerImage.versionTagPattern ?? "",
								data: {
									id: artifact.containerImage.versionTagPattern ?? ""
								}
							},

							isValid: true
						};

						this.artifactFieldValues = {
							...this.artifactFieldValues,
							sourceURL: artifact.containerImage.repo,
							cred: {
								title: cred.name,
								data: {
									id: cred.id,
									icon: cred.type ? CREDENTIAL_TYPE_ICONS2[cred.type] : "i-git-branch",
									qaId: cred.id
								}
							}
						};
					}
				}
			}
		},

		handleInput(event: CustomEvent<ArtifactFieldValues>) {
			const input = { ...event.detail };
			// if the sourceURL field is filled, we then parse the GitURL and populate the other fields
			if (input.sourceURL) {
				const parsedRepo = parseGitUrl(input.sourceURL);
				if (parsedRepo?.repo) {
					input.sourceURL = parsedRepo.repo;
				}
				if (parsedRepo?.identifier) {
					input.branch = parsedRepo.identifier;
				}
			}
			this.artifactFieldValues = { ...input };
			const extractGitRepo = extractGitHubRepoPath(input.sourceURL);

			if (input.appName === "" && extractGitRepo) {
				this.artifactFieldValues.appName = safeEntityString(extractGitRepo, this.existingAppNames);
			}
		},

		/**
		 * This method is called when a new credential is created
		 * @param isAuthenticated returns true if the user has authenticated the github credentials
		 */
		async setAuthenticated(isAuthenticated: boolean) {
			if (isAuthenticated) {
				await (
					this.$refs.appWizardGitCredModal as InstanceType<typeof GithubCredentialModal>
				).saveGithubCredentials();
			}
		},

		onCredCreate(cred: Creds) {
			this.newCred = cred;
			this.isAddingNewCred = false;
			const iconName = (cred.type && CREDENTIAL_TYPE_ICONS2[cred.type]) ?? "i-git-branch";

			this.artifactFieldValues = {
				...this.artifactFieldValues,
				cred: {
					title: cred.name,
					data: {
						id: cred.id,
						icon: iconName,
						qaId: cred.id
					}
				}
			};
			this.formValueKey = Math.random().toString();
		},

		async createApp() {
			// @todo - temp code to route directly to infra deps
			// applicationStore.UPDATE_APP_WIZARD_STEP(AppWizardChecklistEnum.SELECT_INFRA);
			// return;
			try {
				this.submitError = "";

				if (!this.orgId || !this.projectId) {
					this.isSubmitting = false;
					return;
				}

				const artifacts: Artifact[] = [];
				let applicationName = "";

				// check if the artifact is a container image
				if (this.artifactType === ArtifactType.ContainerImage) {
					// add the container image artifact to the artifacts array

					const { containerArtifactformValues } = this;
					const containerProviderId = this.containerArtifactformValues.containerProvider?.data.id;

					if (
						!containerArtifactformValues.isValid ||
						!containerProviderId ||
						!containerArtifactformValues.containerRepo
					) {
						this.submitError = "Please fill all the required fields";
						return;
					}

					artifacts.push({
						name: safeEntityString(this.containerArtifactformValues.appContainerImageName ?? ""),
						containerImage: {
							provider: containerProviderId,
							host: containerArtifactformValues.appHost,
							repo: containerArtifactformValues.containerRepo,
							reference: containerArtifactformValues.containerTag ?? undefined,
							versionTagPattern: containerArtifactformValues.versionTagPattern?.data.id
						}
					});

					applicationName = safeEntityString(
						containerArtifactformValues.containerRepo,
						this.existingAppNames
					);
				} else if (this.artifactType === ArtifactType.GitCode) {
					// add the git artifact to the artifacts array
					artifacts.push({
						name: safeEntityString(this.artifactFieldValues.appName),
						gitCode: {
							type: GitRevisionType.branch,
							identifier: this.artifactFieldValues.branch,
							dir: this.artifactFieldValues.directory,
							repo: this.artifactFieldValues.sourceURL,
							driver: GitServer.github
						}
					});

					applicationName = this.artifactFieldValues.appName;
				}

				this.isSubmitting = true;

				if (this.isEditing && this.app) {
					await applicationStore.UPDATE_APPLICATION({
						id: this.app.id,
						orgId: this.app.orgId,
						projId: this.app.projId,
						name: this.app.name,
						description: this.app.description,
						artifacts,
						replaceVariables: false
					});

					applicationStore.SET_LAST_APP_ID(this.app.id);
				} else {
					const app = await applicationStore.CREATE_APPLICATION({
						orgId: this.orgId,
						projId: this.projectId,
						name: applicationName,
						artifacts,
						appConfig: {
							language: this.artifactFieldValues.appLanguage?.data.id ?? sourceEnum.source_no
						}
					});

					applicationStore.SET_LAST_APP_ID(app.id);

					await userStore.UPDATE_USER_META({
						appWizardMeta: {
							[app.id]: {
								projId: app.projId,
								orgId: app.orgId,
								credId: this.artifactFieldValues.cred?.data?.id ?? ""
							}
						}
					});

					notificationsStore.ADD_TOAST({
						qaId: "toast-app-flow-app-created-",
						title: "Application created",
						text: `Application ${app.name} has been created & added to your project.`,
						status: "success"
					});
				}
			} catch (error) {
				this.submitError = getErrorMessage(error);
			} finally {
				this.isSubmitting = false;
				AppFirstFlowFormStorageService.removeAppFirstFlowForm();
				GithubOauthStorageService.removeGithubUserOauth();
			}
		},

		goBack() {
			if (this.isAddingNewCred) {
				this.isAddingNewCred = false;
			} else {
				this.$emit("back");
			}
		}
	}
});
</script>
