export function addProtocol(url: string, protocol = "https") {
	if (!url.startsWith(`${protocol}://`)) {
		return `${protocol}://${url}`;
	}

	return url;
}

export function parseUrl(url?: string): URL | null {
	if (!url) {
		return null;
	}

	try {
		return new URL(addProtocol(url));
	} catch (error) {
		return null;
	}
}
