import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { getStore } from "../store";

@Module({
	namespaced: true,
	dynamic: true,
	name: "debug",
	store: getStore()
})
class DebugMenuStore extends VuexModule {
	isDebugMenuVisible = false;

	@Mutation
	SHOW_DEBUG_MENU() {
		this.isDebugMenuVisible = true;
	}

	@Mutation
	HIDE_DEBUG_MENU() {
		this.isDebugMenuVisible = false;
	}
}

const debugMenuStore = getModule(DebugMenuStore);

export { debugMenuStore };
