<template>
	<PopOver
		placement="right-start"
		:open="actionState !== 'closed'"
		class="align-items-center"
		@overlay-click="closeProjectAssignModal"
	>
		<Button
			v-tooltip="{
				content: 'Add more to project',
				placement: 'right'
			}"
			type="primary"
			state="icon"
			size="x-small"
			data-qa-project-actions-btn
			@click="actionState = 'open'"
		>
			<Icon name="i-plus" type="filled" size="x-small" :effects="false" />
		</Button>
		<template #content>
			<Wrapper
				v-if="actionState === 'open' && showAssignCredModal === null"
				border-radius="4px"
				background="element-light"
				:border="true"
				width="275px"
				data-qa-project-actions-list
			>
				<Container direction="column" :padding="0" :gap="0">
					<Header>
						<Typography type="h4" color="dark"> Actions </Typography>
						<Icon name="i-close" type="filled" size="x-small" @click="actionState = 'closed'" />
					</Header>

					<Slab
						v-if="isUserOrgAdmin && !selectedPolicy"
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-assign-policy-action-btn
						@click="actionState = 'assign-policy-set'"
					>
						<template #primary-action>
							<Icon name="i-shield" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default"> Assign policy set </Typography>
					</Slab>

					<Slab
						v-if="isUserOrgAdmin && !assignedCredToCloud.length"
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-assign-cloud-creds-action-btn
						@click="showAssignCredModal = credScope.cloud"
					>
						<template #primary-action>
							<Icon name="i-cloud" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default">
							Assign cloud credential
						</Typography>
					</Slab>

					<Slab
						v-if="isUserOrgAdmin && !assignedCredToGit.length"
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-assign-git-creds-action-btn
						@click="showAssignCredModal = credScope.git"
					>
						<template #primary-action>
							<Icon name="i-git" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default">
							Assign git credential
						</Typography>
					</Slab>

					<Slab
						v-if="isUserOrgAdmin && !assignedCredToDocker.length"
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-assign-container-creds-action-btn
						@click="showAssignCredModal = credScope.docker"
					>
						<template #primary-action>
							<Icon name="i-box" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default">
							Assign container credential
						</Typography>
					</Slab>

					<Slab
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-create-vars-plus-btn
						@click="actionState = 'add-variables'"
					>
						<template #primary-action>
							<Icon name="i-variable" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default">New variables</Typography>
					</Slab>

					<Slab
						v-if="isUserOrgAdmin"
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-create-env-plus-btn
						@click="createNewEnvironment"
					>
						<template #primary-action>
							<Icon name="i-env-shape" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default">
							Create new environment
						</Typography>
					</Slab>

					<Slab
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-create-app-plus-btn
						@click="actionState = 'create-app'"
					>
						<template #primary-action>
							<Icon name="i-app" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default">
							Add new application
						</Typography>
					</Slab>

					<Slab
						v-if="!hasNxPlatformFlag"
						size="large"
						type="transparent"
						:effects="true"
						class="cursor-pointer"
						data-qa-add-bundle-plus-btn
						@click="actionState = 'create-bundle'"
					>
						<template #primary-action>
							<Icon name="i-app" type="filled" size="small" :effects="false" />
						</template>
						<Typography type="h4" weight="regular" color="default"> Add new bundle </Typography>
					</Slab>
				</Container>
			</Wrapper>

			<!-- github credential assign and unassign -->
			<AssignCredentialList
				v-if="showAssignCredModal && project && canAccessCreds"
				ref="AssignCredentialList"
				:entity="project"
				:git-form-ref="gitFormRef"
				entity-kind="project"
				:cred-scope="showAssignCredModal"
				:show-back-button="true"
				:outside-click="outsideClicked"
				@back-button-action="showAssignCredModal = null"
				@on-close="closeProjectAssignModal"
				@force-close="forceClose"
			/>

			<PolicySelectorModal
				v-if="actionState === 'assign-policy-set'"
				:entity="project"
				entity-type="project"
				:selected-policy="selectedPolicy"
				:new-policy-set-id="newPolicySetId"
				:selected-policy-set-id="selectedPolicySetId"
				@toggle-list-popover="toggleListPopover"
				@select-policy="selectPolicy"
			/>
			<AddVariableContent
				v-if="actionState === 'add-variables'"
				:pre-selected-ids="[project.id]"
				@close-dialog="actionState = 'closed'"
			/>
			<template v-if="actionState === 'create-app'">
				<CreateApplicationWizard
					:open="true"
					:project="project"
					target="[data-qa-project-actions-btn]"
					@close="() => (actionState = 'closed')"
					@app-created="() => (actionState = 'closed')"
				>
					<div></div>
				</CreateApplicationWizard>
			</template>

			<BundleAddDialog
				v-if="actionState === 'create-bundle'"
				:show-back-btn="true"
				:project="project"
				:toggle-create-bundle="() => (actionState = 'closed')"
				@go-back="actionState = 'open'"
			/>
		</template>
	</PopOver>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Header,
	Icon,
	PopOver,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import CreateApplicationWizard from "@/modules/application/components/CreateApplicationWizard.vue";
import BundleAddDialog from "@/modules/bundle/components/BundleAddDialog.vue";
import AssignCredentialList from "@/modules/credentials/components/credential-assign/AssignCredentialList.vue";
import { getCredByScope } from "@/modules/credentials/credential-store";
import { envCreateStore } from "@/modules/env-create/env-create-store";
import { EnvRoute } from "@/modules/env-list/components/env-widget/env-header/EnvWidgetHeader.vue";
import { envListStore } from "@/modules/env-list/env-list-store";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { orgStore } from "@/modules/org/org-store";
import PolicySelectorModal from "@/modules/policy-list/components/PolicySelectorModal.vue";
import { policyListStore } from "@/modules/policy-list/policy-list-store";
import AddVariableContent from "@/modules/variables-list/AddVariableContent.vue";
import { CredScope, project } from "@/protocol/identity";
import { PolicySet } from "@/protocol/validator";
import { EnvCreateStepFlowService, GithubOauthStorageService } from "@/services/storage-service";

export default defineComponent({
	name: "ProjectActions",

	components: {
		CreateApplicationWizard,
		AssignCredentialList,
		PolicySelectorModal,
		AddVariableContent,
		BundleAddDialog,
		Typography,
		Container,
		Wrapper,
		PopOver,
		Button,
		Header,
		Icon,
		Slab
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		},

		projectEnvsCount: {
			type: Number as PropType<number>,
			required: true
		}
	},

	data: () => ({
		credScope: CredScope,
		policyPopOver: false,
		gitFormRef: "projectActionGitForm",
		newPolicySetId: null as string | null,
		isLoading: false,
		outsideClicked: false,
		showAssignCredModal: null as null | CredScope.cloud | CredScope.git | CredScope.docker,
		actionState: "closed" as
			| "closed"
			| "open"
			| "add-variables"
			| "create-app"
			| "create-bundle"
			| "assign-policy-set"
	}),

	computed: {
		hasNxPlatformFlag() {
			return featureFlagStore.featureMap.ENABLE_NX_PLATFORM;
		},

		// Only org admins can create manual environments
		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		},

		selectedPolicy(): PolicySet | null {
			return (
				policyListStore.policySets.find(_policy => _policy.id === this.selectedPolicySetId) ?? null
			);
		},

		selectedPolicySetId() {
			return this.newPolicySetId ?? this.project.policySetId ?? "";
		},

		canAccessCreds() {
			return orgStore.isUserOrgAdmin;
		},

		assignedCredToGit() {
			return getCredByScope({
				entityId: this.project.id,
				scope: CredScope.git
			});
		},

		assignedCredToCloud() {
			return getCredByScope({
				entityId: this.project.id,
				scope: CredScope.cloud
			});
		},

		assignedCredToDocker() {
			return getCredByScope({
				entityId: this.project.id,
				scope: CredScope.docker
			});
		}
	},

	mounted() {
		this.githubModalObserver();
	},

	methods: {
		forceClose() {
			this.showAssignCredModal = null;
			this.actionState = "closed";
		},

		closeProjectAssignModal() {
			if (this.showAssignCredModal) {
				const ref = this.$refs.AssignCredentialList as InstanceType<typeof AssignCredentialList>;
				if (ref) {
					ref.safelyCloseModal();
				}
			}
			if (this.actionState === "open" && !this.showAssignCredModal) {
				this.forceClose();
			}
		},

		selectPolicy(policyId: string) {
			this.newPolicySetId = policyId;
		},

		toggleListPopover() {
			this.policyPopOver = !this.policyPopOver;
			this.actionState = "closed";
			if (!this.policyPopOver) {
				this.newPolicySetId = null;
				this.isLoading = false;
			}
		},

		createNewEnvironment() {
			// Navigate the user to the new environment creation loader screen
			this.$router
				.push({
					name: "createEnvLoaderScreen"
				})
				.then(async () => {
					// First we create a sandbox environment based on the config of the parent project
					const { activeOrgId } = orgStore;

					const newEnv = await envCreateStore.CREATE_NEW_ENV({
						orgId: activeOrgId as string,
						projId: this.project.id,
						name: `Environment-${this.projectEnvsCount + 1}`,
						description: "",
						variables: [],
						metadata: {
							emojiObj: {
								native: "🌐"
							}
						},
						tfVersion: ""
					});

					// Show the steps popovers for newly created environment
					EnvCreateStepFlowService.toggleFlow({ isEnabled: true });
					// Then we assign the project creds to the new env if any
					// Create assign creds object
					await envListStore.GET_ENVS({
						orgId: this.project.orgId,
						projectId: this.project.id
					});

					// Route to the env detailed view
					const params: EnvRoute = {
						orgId: newEnv.orgId,
						projectId: newEnv.projId,
						envId: newEnv.id
					};
					this.$router.push({
						name: "envDetail",
						params
					});
				});
		},

		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === this.gitFormRef) {
				// render github form
				this.showAssignCredModal = CredScope.git;
				this.actionState = "open";
			}
		}
	}
});
</script>
