import { App } from "vue";
import { Router } from "vue-router";

import { getCodepipesVersion, getEnvironment } from ".";

export async function initSentry({ app, router }: { app: App; router: Router }) {
	const [Sentry, { Integrations }] = await Promise.all([
		import("@sentry/vue"),
		import("@sentry/tracing")
	]);

	Sentry.init({
		release: getCodepipesVersion(),
		environment: getEnvironment(),
		app,
		dsn: "https://ae1e1f8e92f94c0cbf938f3b3309ce16@o351621.ingest.sentry.io/6116340",
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router)
			})
		],
		tracesSampleRate: 1.0
	});
}
