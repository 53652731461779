<template>
	<Container
		data-qa-members-tab-header
		:padding="0"
		overflow="visible"
		align="space-between top"
		class="dashboard-input"
	>
		<Container :padding="0" :gap="12" overflow="visible">
			<Multiselect
				v-model="selectedRole"
				data-qa-members-tab-role-filter
				class="width-200"
				:options="roles"
				:searchable="true"
				:close-on-select="true"
				:show-labels="false"
				placeholder="Filter by role"
				track-by="title"
			>
				<template #singleLabel="props">
					<Container
						padding="8px 8px 8px 0px"
						:gap="12"
						:data-qa-selected-role="props.option.title"
					>
						<Typography type="p2">{{ props.option.title }}</Typography>
						<Icon
							v-if="props.option.icon"
							:name="props.option.icon.name"
							type="filled"
							state="warning"
							size="small"
						/>
					</Container>
				</template>
				<template #option="props">
					<Container padding="8px 8px 8px 12px" :gap="12" :data-qa-select-role="props.option.title">
						<Typography type="p2">{{ props.option.title }}</Typography>
						<Icon
							v-if="props.option.icon"
							:name="props.option.icon.name"
							type="filled"
							state="warning"
							size="small"
						/>
					</Container>
				</template>
			</Multiselect>
			<Container v-if="isAnyChecked" :padding="0" :shrink="0">
				<Divider direction="vertical" :size="2" />
				<Typography type="p1" color="gray-300" data-qa-members-tab-selected-count
					>{{ checkedCount }} members selected</Typography
				>

				<PopOver :open="showRemove" placement="bottom-start" class="flex-1" strategy="absolute">
					<Wrapper
						background="gray-500"
						width="36px"
						height="36px"
						border-radius="4px"
						@click="showRemove = !showRemove"
					>
						<Container
							:padding="10"
							align="center"
							:shrink="0"
							clickable
							data-qa-members-tab-remove-members
						>
							<Icon name="i-delete" type="filled" size="small" />
						</Container>
					</Wrapper>
					<template #content>
						<RemoveMemberConfirm
							:org="org"
							:project="project"
							:members="selectedMembers"
							@close="showRemove = false"
						/>
					</template>
				</PopOver>

				<PopOver :open="showModifyRole" placement="bottom-start">
					<Wrapper
						background="gray-500"
						width="100px"
						height="36px"
						border-radius="4px"
						@click="showModifyRole = !showModifyRole"
					>
						<Container
							:padding="10"
							align="center"
							:shrink="0"
							clickable
							data-qa-members-tab-modify-members
						>
							<Typography type="h5" weight="medium">Modify role</Typography>
						</Container>
					</Wrapper>
					<template #content>
						<ModifyRole
							:org="org"
							:project="project"
							:members="selectedMembers"
							@close="showModifyRole = false"
						/>
					</template>
				</PopOver>
			</Container>
		</Container>
		<Container :padding="0" :gap="12" class="dashboard-input">
			<SearchInput
				v-model:value="searchInputText"
				data-qa-members-tab-search
				placeholder="Search Members"
				class="width-250"
			/>

			<PopOver v-if="!hasNxPlatformFlag" v-model:open="showInvite" placement="left-start">
				<Button
					v-tooltip="{
						content: 'Invite Members'
					}"
					data-qa-members-tab-invite-members
					type="primary"
					state="icon"
					size="small"
					@click="showInvite = !showInvite"
				>
					<Icon name="i-plus" state="dark" size="small" type="filled" :effects="false" />
				</Button>
				<template #content>
					<InviteMembers
						:org="org"
						:members="members"
						:project="project"
						@close="showInvite = false"
						@invited="$emit('invited')"
					/>
				</template>
			</PopOver>
		</Container>
	</Container>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Icon,
	Multiselect,
	PopOver,
	SearchInput,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import InviteMembers from "@/modules/members/components/InviteMembers.vue";
import { Member, MemberRole, MEMBER_ROLES_ARR } from "@/modules/members/components/member-types";
import ModifyRole from "@/modules/members/components/ModifyRole.vue";
import RemoveMemberConfirm from "@/modules/members/components/RemoveMemberConfirm.vue";
import { organization, project as projectProto } from "@/protocol/identity";

export default defineComponent({
	name: "MembersTabHeader",

	components: {
		Container,
		Typography,
		Multiselect,
		SearchInput,
		Button,
		Icon,
		InviteMembers,
		PopOver,
		Divider,
		Wrapper,
		RemoveMemberConfirm,
		ModifyRole
	},

	props: {
		members: {
			type: Array as PropType<Member[]>,
			default: () => []
		},

		org: {
			type: Object as PropType<organization>
		},

		project: {
			type: Object as PropType<projectProto>
		},

		roleAvailability: {
			type: Object as PropType<Record<MemberRole, boolean>>,
			required: true
		}
	},

	data: () => ({
		showInvite: false,
		showRemove: false,
		showModifyRole: false,
		selectedRole: null as RoleFilter | null,
		searchInputText: ""
	}),

	computed: {
		hasNxPlatformFlag() {
			return featureFlagStore.featureMap.ENABLE_NX_PLATFORM;
		},

		roles(): RoleFilter[] {
			return [
				{
					id: "all-roles",
					title: "All roles",
					bottomBorder: true,
					icon: null
				},
				...MEMBER_ROLES_ARR.map(role => ({
					id: role.id,
					title: role.name,
					bottomBorder: false,
					$isDisabled: !this.roleAvailability[role.id],
					icon: role.isAdmin
						? {
								name: "i-crown"
						  }
						: null
				}))
			];
		},

		isAnyChecked() {
			return this.members.some(m => m.selected);
		},

		checkedCount() {
			return this.members.filter(m => m.selected).length;
		},

		selectedMembers() {
			return this.members.filter(member => member.selected);
		}
	},

	watch: {
		selectedRole() {
			if (this.selectedRole) {
				this.$emit(
					"filter-role",
					this.selectedRole.id === "all-roles" ? null : this.selectedRole.id
				);
			}
		},

		searchInputText() {
			this.$emit("filter-member", this.searchInputText);
		}
	},

	mounted() {
		const [firstRole] = this.roles;
		if (firstRole) {
			this.selectedRole = firstRole;
		}
	}
});

type RoleFilter = {
	id: MemberRole | "all-roles";
	title: string;
	bottomBorder: boolean;
	icon: {
		name: string;
	} | null;
};
</script>
