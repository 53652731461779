<template>
	<div class="display-contents">
		<form>
			<f-form-builder
				ref="formBuilder"
				:field.prop="formFields"
				:values.prop="formValues"
				@input="handleInput"
				@state-change="formState = $event.detail"
			/>
		</form>

		<Container padding="16px 24px 0px 24px" align="center">
			<f-button data-qa-submit-button label="Sign Up" :loading="isSubmitting" @click="signupUser" />
		</Container>
	</div>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { Container } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { getErrorMessage } from "@/utils";

import { signUpUsingEmailPassword } from "../services/auth-service";

export default defineComponent({
	name: "SignUpEmailPasswordForm",

	components: {
		Container
	},

	data() {
		return {
			isSubmitting: false,
			submitError: "",
			formValues: {} as FormValues,
			formState: null as FormBuilderState | null
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					email: {
						type: "email",
						qaId: "email",
						label: { title: "Email" },
						autocomplete: "username",
						placeholder: "Enter email address",
						helperText: this.submitError
							? html`<f-text
									size="small"
									state="danger"
									data-qa-error-for="email"
									data-qa-login-error
									>${this.submitError}</f-text
							  >`
							: "",

						validationRules: [{ name: "required" }, { name: "email" }]
					},

					password: {
						type: "password",
						qaId: "password",
						autocomplete: "new-password",
						label: { title: "Password" },
						validationRules: [{ name: "required" }, { name: "min", params: { length: 7 } }],
						placeholder: "Enter password",
						onKeyPress: event => {
							if (event.key === "Enter") {
								this.signupUser();
							}
						}
					}
				}
			};
		}
	},

	methods: {
		handleInput(event: CustomEvent<FormValues>) {
			this.submitError = "";
			this.formValues = event.detail;
		},

		async signupUser() {
			this.submitError = "";
			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();

			if (this.isSubmitting || !this.formState?.isValid) {
				return;
			}

			this.isSubmitting = true;

			try {
				await signUpUsingEmailPassword({
					email: this.formValues.email,
					password: this.formValues.password
				});

				this.$router.push({
					name: "authAfterStage",
					params: {
						email: this.formValues.email,
						componentName: "SignUpSuccess"
					}
				});
			} catch (error: unknown) {
				this.submitError = getErrorMessage(error, true);
			} finally {
				this.isSubmitting = false;
			}

			return false;
		}
	}
});

type FormValues = {
	email: string;
	password: string;
};
</script>
