<template>
	<Accordion :open="isOpen" :data-qa-scope-name="scope.name">
		<template #header>
			<Container align="space-between center" :padding="10" :grow="1">
				<Container :gap="12" :padding="0">
					<Typography
						weight="medium"
						type="h4"
						:data-qa-variable-scope-name="scope.name"
						overflow="ellipsis"
						>{{ scope.name }}</Typography
					>
				</Container>
				<Icon
					:disabled="editedVariableIdx !== null"
					name="i-chevron-down"
					type="filled"
					size="x-small"
					:rotate="isOpen ? 180 : 0"
					color="gray-300"
					@click="isOpen = !isOpen"
				/>
			</Container>
		</template>

		<table class="variable-table" border="none" :style="{ tableLayout: 'fixed', width: '100%' }">
			<tr
				v-for="(variable, idx) in scope.variables"
				:key="variable.key"
				class="show-on-hover-parent"
				:data-qa-variable-row-key="variable.key"
				:hover="editedVariableIdx !== idx"
			>
				<template v-if="editedVariableIdx === idx && editedVariable">
					<TableCell padding="0" colspan="2">
						<f-div direction="column" padding="medium" gap="medium">
							<AddVariableKeyValueForm
								v-model:variable="editedVariable"
								data-qa-edited-variable
								:store-variable-scopes="storeVariableScopes"
								:selected-variable-scopes="[
									{ id: scope.id, name: scope.name, variableFrom: scope.variableFrom }
								]"
								:hide-variable-type="hideVariableType"
								:added-variables="addedVariables"
								@validity-change="isCurrentVariableFormValid = $event"
							/>

							<f-button
								:style="{ width: '100%' }"
								label="Update"
								data-qa-edit-var-icon
								size="small"
								:disabled="!isCurrentVariableFormValid"
								@click="updateVariable"
							></f-button>
						</f-div>
					</TableCell>
				</template>
				<template v-else>
					<TableCell vertical-align="top" padding="16px 12px" width="150px">
						<Typography
							type="p2"
							color="light"
							family="logs"
							:data-qa-variable-name="variable.key"
							overflow="ellipsis"
							>{{ variable.key }}</Typography
						>
					</TableCell>
					<TableCell vertical-align="top" padding="16px 12px">
						<f-div align="middle-left" direction="row" gap="medium" width="fill-container">
							<f-icon
								v-if="variable.isMasked"
								source="i-lock"
								size="small"
								state="subtle"
								tooltip="Value is masked"
								data-qa-variable-value-masked
							/>

							<f-text
								v-else
								variant="code"
								size="small"
								:data-qa-variable-value="variable.value"
								ellipsis
								>{{ variable.value }}</f-text
							>

							<f-div align="middle-right" gap="medium">
								<!-- Secret variables without a value mean they come from server -->
								<Icon
									v-tooltip="{
										content: 'Edit',
										placement: 'bottom'
									}"
									:disabled="
										editedVariableIdx !== null || (variable.isMasked && variable.value === '')
									"
									state="primary"
									name="i-edit"
									type="filled"
									size="small"
									class="show-on-hover"
									data-qa-edit-var-type-icon
									@click="
										editedVariableIdx = idx;
										editedVariable = variable;
									"
								/>

								<Tag
									:data-qa-variable-type="getVarTypeTag(variable.variableType)"
									:background="getVarTypeBackgroundColor(variable.variableType)"
									size="small"
								>
									<Typography weight="medium" type="p3">{{
										getVarTypeTag(variable.variableType)
									}}</Typography>
								</Tag>

								<Icon
									v-tooltip="{
										content: 'Remove',
										placement: 'bottom'
									}"
									name="i-delete"
									type="filled"
									color="gray-300"
									size="small"
									data-qa-remove-var-btn
									@click="deleteVariable(variable)"
								/>
							</f-div>
						</f-div>
					</TableCell>
				</template>
			</tr>
		</table>
	</Accordion>
</template>

<script lang="ts">
import { Accordion, Container, Icon, TableCell, Tag, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import AddVariableKeyValueForm from "@/modules/variables-list/AddVariableKeyValueForm.vue";
import { AddVariableScope, Variable } from "@/modules/variables-list/variable-list-types";
import { variableType } from "@/protocol/common";

import { variablesListStore } from "./variables-list-store";

export default defineComponent({
	name: "ScopeVariablesAccordion",

	components: {
		AddVariableKeyValueForm,
		Typography,
		Container,
		Icon,
		Accordion,
		Tag,
		TableCell
	},

	props: {
		scope: {
			type: Object as PropType<AddVariableScope>,
			required: true
		},

		addedVariables: {
			type: Array as PropType<AddVariableScope[]>
		},

		hideVariableType: Boolean
	},

	emits: ["update:scope"],

	data() {
		return {
			isOpen: true,
			editedVariableIdx: null as number | null,
			isCurrentVariableFormValid: false,
			editedVariable: null as Variable | null
		};
	},

	computed: {
		storeVariableScopes() {
			return variablesListStore.variableScopes;
		}
	},

	methods: {
		getVarTypeBackgroundColor(type: variableType) {
			// custom color not used in any theme, hence variable not used
			if (type === variableType.env_var) {
				return "#21A475";
			}

			return "#924AEE";
		},

		getVarTypeTag(type: variableType) {
			if (type === variableType.env_var) {
				return "ENV";
			}

			return "TF";
		},

		updateVariable() {
			const { editedVariableIdx, editedVariable } = this;

			this.$emit("update:scope", {
				...this.scope,
				variables: this.scope.variables.map((scopeVar, idx) => {
					if (idx === editedVariableIdx && editedVariable) {
						return editedVariable;
					}

					return scopeVar;
				})
			} satisfies AddVariableScope);

			this.editedVariableIdx = null;
			this.editedVariable = null;
		},

		deleteVariable(variable: Variable) {
			this.$emit("update:scope", {
				...this.scope,
				variables: this.scope.variables.filter(scopeVar => {
					return scopeVar !== variable;
				})
			} satisfies AddVariableScope);
		}
	}
});
</script>

<style lang="scss">
div.flow-table-component.variable-table {
	tr[data-hover] {
		&:hover {
			> * {
				background-color: var(--gray-400);
			}
		}
	}
	.selected-edit-row {
		background-color: var(--gray-400);
	}
}
</style>
