<template>
	<template v-if="env">
		<PipelineAction
			:show-validate-popover="showValidatePopover"
			:org-id="orgId"
			:project-id="projectId"
			:is-loading="isLoadingPipeline"
			:env-id="envId"
			:env="env"
			:click-action="setStageInfoAndLoad"
			:action-type="actionType.validateOnly"
			@close="$emit('close-flow')"
			@job-loading="setIsEnvJobLoading"
		/>
		<PipelineAction
			:org-id="orgId"
			:project-id="projectId"
			:is-loading="isLoadingPipeline"
			:env-id="envId"
			:env="env"
			:click-action="setStageInfoAndLoad"
			:action-type="actionType.ValidateAndApply"
			@job-loading="setIsEnvJobLoading"
		/>
		<PipelineAction
			:org-id="orgId"
			:project-id="projectId"
			:is-loading="isLoadingPipeline"
			:env-id="envId"
			:env="env"
			:click-action="setStageInfoAndLoad"
			:action-type="actionType.destroy"
			@job-loading="setIsEnvJobLoading"
		/>

		<!-- show initial fetching pipelines loader -->
		<Container
			v-if="isLoadingPipeline"
			padding="0"
			data-qa-env-pipeline-fetching
			align="center center"
			overflow="visible"
		>
			<CustomLoader
				position="relative"
				:style="{ flexShrink: '1', height: '50px' }"
				size="small"
				message="Fetching Pipelines"
			/>
		</Container>
	</template>
</template>

<script lang="ts">
import { Container } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import PipelineAction from "@/modules/env-pipeline/components/PipelineAction.vue";
import { actionType, environment } from "@/protocol/infra";
import CustomLoader from "@/shared/components/CustomLoader.vue";

export default defineComponent({
	name: "EnvPipelineCards",

	components: {
		PipelineAction,
		Container,
		CustomLoader
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		},

		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		envId: {
			type: String,
			required: true
		},

		isLoadingPipeline: {
			type: Boolean,
			required: true
		},

		showValidatePopover: {
			type: Boolean,
			required: true
		}
	},

	emits: ["jobLoading", "close-flow"],

	data: () => ({
		actionType
	}),

	methods: {
		setIsEnvJobLoading({ isRunning, action }: { isRunning: boolean; action: actionType }) {
			this.$emit("jobLoading", { isRunning, action });
		},

		setStageInfoAndLoad({ action }: { action: actionType }) {
			if (this.$route.name === "stageViewEnv") {
				this.$forceUpdate();
			} else {
				this.$router.push({
					name: "stageViewEnv",
					params: {
						orgId: this.orgId,
						projectId: this.projectId,
						envId: this.envId,
						action
					}
				});
			}
		}
	}
});
</script>
