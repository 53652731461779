//@ts-nocheck
// source: events/events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.event_dispatcher.AlarmMeta', null, global);
goog.exportSymbol('proto.event_dispatcher.EventName', null, global);
goog.exportSymbol('proto.event_dispatcher.JobCreatedEventData', null, global);
goog.exportSymbol('proto.event_dispatcher.JobStatsUpdatedEventData', null, global);
goog.exportSymbol('proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats', null, global);
goog.exportSymbol('proto.event_dispatcher.OrganizationAlarmEventData', null, global);
goog.exportSymbol('proto.event_dispatcher.PipelineCreatedEventData', null, global);
goog.exportSymbol('proto.event_dispatcher.PipelineStatsUpdatedEventData', null, global);
goog.exportSymbol('proto.event_dispatcher.PublishEventRequest', null, global);
goog.exportSymbol('proto.event_dispatcher.PublishEventResponse', null, global);
goog.exportSymbol('proto.event_dispatcher.PublishedMessage', null, global);
goog.exportSymbol('proto.event_dispatcher.ResourceLevelAlarmEventData', null, global);
goog.exportSymbol('proto.event_dispatcher.ResourceType', null, global);
goog.exportSymbol('proto.event_dispatcher.ServiceName', null, global);
goog.exportSymbol('proto.event_dispatcher.StageUpdatedEventData', null, global);
goog.exportSymbol('proto.event_dispatcher.SubscribeEventRequest', null, global);
goog.exportSymbol('proto.event_dispatcher.SubscriptionEventResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.PublishEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event_dispatcher.PublishEventRequest.repeatedFields_, null);
};
goog.inherits(proto.event_dispatcher.PublishEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.PublishEventRequest.displayName = 'proto.event_dispatcher.PublishEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.PublishedMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.PublishedMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.PublishedMessage.displayName = 'proto.event_dispatcher.PublishedMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.PublishEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.PublishEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.PublishEventResponse.displayName = 'proto.event_dispatcher.PublishEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.PipelineCreatedEventData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.PipelineCreatedEventData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.PipelineCreatedEventData.displayName = 'proto.event_dispatcher.PipelineCreatedEventData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.JobCreatedEventData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.JobCreatedEventData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.JobCreatedEventData.displayName = 'proto.event_dispatcher.JobCreatedEventData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.StageUpdatedEventData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.StageUpdatedEventData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.StageUpdatedEventData.displayName = 'proto.event_dispatcher.StageUpdatedEventData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.PipelineStatsUpdatedEventData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.PipelineStatsUpdatedEventData.displayName = 'proto.event_dispatcher.PipelineStatsUpdatedEventData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.JobStatsUpdatedEventData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.event_dispatcher.JobStatsUpdatedEventData.repeatedFields_, null);
};
goog.inherits(proto.event_dispatcher.JobStatsUpdatedEventData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.JobStatsUpdatedEventData.displayName = 'proto.event_dispatcher.JobStatsUpdatedEventData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.displayName = 'proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.SubscriptionEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.SubscriptionEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.SubscriptionEventResponse.displayName = 'proto.event_dispatcher.SubscriptionEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.SubscribeEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.SubscribeEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.SubscribeEventRequest.displayName = 'proto.event_dispatcher.SubscribeEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.AlarmMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.AlarmMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.AlarmMeta.displayName = 'proto.event_dispatcher.AlarmMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.OrganizationAlarmEventData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.OrganizationAlarmEventData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.OrganizationAlarmEventData.displayName = 'proto.event_dispatcher.OrganizationAlarmEventData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.event_dispatcher.ResourceLevelAlarmEventData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.event_dispatcher.ResourceLevelAlarmEventData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.event_dispatcher.ResourceLevelAlarmEventData.displayName = 'proto.event_dispatcher.ResourceLevelAlarmEventData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event_dispatcher.PublishEventRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.PublishEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.PublishEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.PublishEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PublishEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    service: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventname: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eventdata: jspb.Message.getFieldWithDefault(msg, 4, ""),
    resourcepathList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.PublishEventRequest}
 */
proto.event_dispatcher.PublishEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.PublishEventRequest;
  return proto.event_dispatcher.PublishEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.PublishEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.PublishEventRequest}
 */
proto.event_dispatcher.PublishEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.event_dispatcher.ServiceName} */ (reader.readEnum());
      msg.setService(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {!proto.event_dispatcher.EventName} */ (reader.readEnum());
      msg.setEventname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventdata(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addResourcepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.PublishEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.PublishEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.PublishEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PublishEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEventname();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEventdata();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResourcepathList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional ServiceName service = 1;
 * @return {!proto.event_dispatcher.ServiceName}
 */
proto.event_dispatcher.PublishEventRequest.prototype.getService = function() {
  return /** @type {!proto.event_dispatcher.ServiceName} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.event_dispatcher.ServiceName} value
 * @return {!proto.event_dispatcher.PublishEventRequest} returns this
 */
proto.event_dispatcher.PublishEventRequest.prototype.setService = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.event_dispatcher.PublishEventRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PublishEventRequest} returns this
 */
proto.event_dispatcher.PublishEventRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional EventName eventName = 3;
 * @return {!proto.event_dispatcher.EventName}
 */
proto.event_dispatcher.PublishEventRequest.prototype.getEventname = function() {
  return /** @type {!proto.event_dispatcher.EventName} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.event_dispatcher.EventName} value
 * @return {!proto.event_dispatcher.PublishEventRequest} returns this
 */
proto.event_dispatcher.PublishEventRequest.prototype.setEventname = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string eventData = 4;
 * @return {string}
 */
proto.event_dispatcher.PublishEventRequest.prototype.getEventdata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PublishEventRequest} returns this
 */
proto.event_dispatcher.PublishEventRequest.prototype.setEventdata = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string resourcePath = 5;
 * @return {!Array<string>}
 */
proto.event_dispatcher.PublishEventRequest.prototype.getResourcepathList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.event_dispatcher.PublishEventRequest} returns this
 */
proto.event_dispatcher.PublishEventRequest.prototype.setResourcepathList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.event_dispatcher.PublishEventRequest} returns this
 */
proto.event_dispatcher.PublishEventRequest.prototype.addResourcepath = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event_dispatcher.PublishEventRequest} returns this
 */
proto.event_dispatcher.PublishEventRequest.prototype.clearResourcepathList = function() {
  return this.setResourcepathList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.PublishedMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.PublishedMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.PublishedMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PublishedMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventname: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventdata: jspb.Message.getFieldWithDefault(msg, 3, ""),
    resourcepath: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.PublishedMessage}
 */
proto.event_dispatcher.PublishedMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.PublishedMessage;
  return proto.event_dispatcher.PublishedMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.PublishedMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.PublishedMessage}
 */
proto.event_dispatcher.PublishedMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {!proto.event_dispatcher.EventName} */ (reader.readEnum());
      msg.setEventname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventdata(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourcepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.PublishedMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.PublishedMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.PublishedMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PublishedMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEventname();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEventdata();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResourcepath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.event_dispatcher.PublishedMessage.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PublishedMessage} returns this
 */
proto.event_dispatcher.PublishedMessage.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EventName eventName = 2;
 * @return {!proto.event_dispatcher.EventName}
 */
proto.event_dispatcher.PublishedMessage.prototype.getEventname = function() {
  return /** @type {!proto.event_dispatcher.EventName} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.event_dispatcher.EventName} value
 * @return {!proto.event_dispatcher.PublishedMessage} returns this
 */
proto.event_dispatcher.PublishedMessage.prototype.setEventname = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string eventData = 3;
 * @return {string}
 */
proto.event_dispatcher.PublishedMessage.prototype.getEventdata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PublishedMessage} returns this
 */
proto.event_dispatcher.PublishedMessage.prototype.setEventdata = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string resourcePath = 4;
 * @return {string}
 */
proto.event_dispatcher.PublishedMessage.prototype.getResourcepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PublishedMessage} returns this
 */
proto.event_dispatcher.PublishedMessage.prototype.setResourcepath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.PublishEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.PublishEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.PublishEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PublishEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.PublishEventResponse}
 */
proto.event_dispatcher.PublishEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.PublishEventResponse;
  return proto.event_dispatcher.PublishEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.PublishEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.PublishEventResponse}
 */
proto.event_dispatcher.PublishEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.PublishEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.PublishEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.PublishEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PublishEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.event_dispatcher.PublishEventResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.event_dispatcher.PublishEventResponse} returns this
 */
proto.event_dispatcher.PublishEventResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.PipelineCreatedEventData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.PipelineCreatedEventData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PipelineCreatedEventData.toObject = function(includeInstance, msg) {
  var f, obj = {
    pipelineid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pipelinename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientpipelineid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdby: jspb.Message.getFieldWithDefault(msg, 6, ""),
    parentwidgetid: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.PipelineCreatedEventData}
 */
proto.event_dispatcher.PipelineCreatedEventData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.PipelineCreatedEventData;
  return proto.event_dispatcher.PipelineCreatedEventData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.PipelineCreatedEventData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.PipelineCreatedEventData}
 */
proto.event_dispatcher.PipelineCreatedEventData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPipelineid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPipelinename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientpipelineid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedby(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentwidgetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.PipelineCreatedEventData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.PipelineCreatedEventData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PipelineCreatedEventData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPipelineid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPipelinename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientpipelineid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreatedby();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getParentwidgetid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string pipelineId = 1;
 * @return {string}
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.getPipelineid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PipelineCreatedEventData} returns this
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.setPipelineid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pipelineName = 2;
 * @return {string}
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.getPipelinename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PipelineCreatedEventData} returns this
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.setPipelinename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string clientPipelineId = 3;
 * @return {string}
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.getClientpipelineid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PipelineCreatedEventData} returns this
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.setClientpipelineid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PipelineCreatedEventData} returns this
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 createdAt = 5;
 * @return {number}
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PipelineCreatedEventData} returns this
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string createdBy = 6;
 * @return {string}
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.getCreatedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PipelineCreatedEventData} returns this
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.setCreatedby = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string parentWidgetId = 7;
 * @return {string}
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.getParentwidgetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PipelineCreatedEventData} returns this
 */
proto.event_dispatcher.PipelineCreatedEventData.prototype.setParentwidgetid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.JobCreatedEventData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.JobCreatedEventData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.JobCreatedEventData.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    jobname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    jobdescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientjobid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    noofstages: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdby: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pipelineid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    startedat: jspb.Message.getFieldWithDefault(msg, 10, 0),
    endedat: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.JobCreatedEventData}
 */
proto.event_dispatcher.JobCreatedEventData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.JobCreatedEventData;
  return proto.event_dispatcher.JobCreatedEventData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.JobCreatedEventData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.JobCreatedEventData}
 */
proto.event_dispatcher.JobCreatedEventData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobdescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientjobid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoofstages(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedby(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPipelineid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedat(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.JobCreatedEventData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.JobCreatedEventData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.JobCreatedEventData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJobname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJobdescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientjobid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNoofstages();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCreatedby();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPipelineid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStartedat();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getEndedat();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional string jobId = 1;
 * @return {string}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getJobid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setJobid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string jobName = 2;
 * @return {string}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getJobname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setJobname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string jobDescription = 3;
 * @return {string}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getJobdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setJobdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string clientJobId = 4;
 * @return {string}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getClientjobid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setClientjobid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string noOfStages = 6;
 * @return {string}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getNoofstages = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setNoofstages = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 createdAt = 7;
 * @return {number}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string createdBy = 8;
 * @return {string}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getCreatedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setCreatedby = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string pipelineId = 9;
 * @return {string}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getPipelineid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setPipelineid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 startedAt = 10;
 * @return {number}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getStartedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setStartedat = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 endedAt = 11;
 * @return {number}
 */
proto.event_dispatcher.JobCreatedEventData.prototype.getEndedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.JobCreatedEventData} returns this
 */
proto.event_dispatcher.JobCreatedEventData.prototype.setEndedat = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.StageUpdatedEventData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.StageUpdatedEventData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.StageUpdatedEventData.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientstageid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startedat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    endedat: jspb.Message.getFieldWithDefault(msg, 6, 0),
    jobid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pipelineid: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.StageUpdatedEventData}
 */
proto.event_dispatcher.StageUpdatedEventData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.StageUpdatedEventData;
  return proto.event_dispatcher.StageUpdatedEventData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.StageUpdatedEventData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.StageUpdatedEventData}
 */
proto.event_dispatcher.StageUpdatedEventData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStageid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientstageid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndedat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPipelineid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.StageUpdatedEventData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.StageUpdatedEventData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.StageUpdatedEventData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientstageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartedat();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getEndedat();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getJobid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPipelineid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string stageId = 1;
 * @return {string}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.getStageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.StageUpdatedEventData} returns this
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.setStageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string clientStageId = 2;
 * @return {string}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.getClientstageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.StageUpdatedEventData} returns this
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.setClientstageid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.StageUpdatedEventData} returns this
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.StageUpdatedEventData} returns this
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 startedAt = 5;
 * @return {number}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.getStartedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.StageUpdatedEventData} returns this
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.setStartedat = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 endedAt = 6;
 * @return {number}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.getEndedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.StageUpdatedEventData} returns this
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.setEndedat = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string jobId = 7;
 * @return {string}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.getJobid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.StageUpdatedEventData} returns this
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.setJobid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string pipelineId = 8;
 * @return {string}
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.getPipelineid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.StageUpdatedEventData} returns this
 */
proto.event_dispatcher.StageUpdatedEventData.prototype.setPipelineid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.PipelineStatsUpdatedEventData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.PipelineStatsUpdatedEventData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.toObject = function(includeInstance, msg) {
  var f, obj = {
    pipelineid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totaljobs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completedjobs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    standbyjobs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    failedjobs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdat: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedat: jspb.Message.getFieldWithDefault(msg, 7, 0),
    startedat: jspb.Message.getFieldWithDefault(msg, 8, 0),
    endedat: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.PipelineStatsUpdatedEventData;
  return proto.event_dispatcher.PipelineStatsUpdatedEventData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.PipelineStatsUpdatedEventData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPipelineid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotaljobs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedjobs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStandbyjobs(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFailedjobs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedat(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.PipelineStatsUpdatedEventData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.PipelineStatsUpdatedEventData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPipelineid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotaljobs();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCompletedjobs();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStandbyjobs();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFailedjobs();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedat();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getStartedat();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getEndedat();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string pipelineId = 1;
 * @return {string}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.getPipelineid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.setPipelineid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 totalJobs = 2;
 * @return {number}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.getTotaljobs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.setTotaljobs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 completedJobs = 3;
 * @return {number}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.getCompletedjobs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.setCompletedjobs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 standbyJobs = 4;
 * @return {number}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.getStandbyjobs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.setStandbyjobs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 failedJobs = 5;
 * @return {number}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.getFailedjobs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.setFailedjobs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 createdAt = 6;
 * @return {number}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updatedAt = 7;
 * @return {number}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.getUpdatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 startedAt = 8;
 * @return {number}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.getStartedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.setStartedat = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 endedAt = 9;
 * @return {number}
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.getEndedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.PipelineStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.PipelineStatsUpdatedEventData.prototype.setEndedat = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.event_dispatcher.JobStatsUpdatedEventData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.JobStatsUpdatedEventData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.JobStatsUpdatedEventData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.JobStatsUpdatedEventData.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    processstatsList: jspb.Message.toObjectList(msg.getProcessstatsList(),
    proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.JobStatsUpdatedEventData;
  return proto.event_dispatcher.JobStatsUpdatedEventData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.JobStatsUpdatedEventData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobid(value);
      break;
    case 2:
      var value = new proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats;
      reader.readMessage(value,proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.deserializeBinaryFromReader);
      msg.addProcessstats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.JobStatsUpdatedEventData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.JobStatsUpdatedEventData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.JobStatsUpdatedEventData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProcessstatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    noofstages: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completedstages: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processtype: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats;
  return proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoofstages(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedstages(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcesstype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoofstages();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCompletedstages();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcesstype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 noOfStages = 1;
 * @return {number}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.getNoofstages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats} returns this
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.setNoofstages = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 completedStages = 2;
 * @return {number}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.getCompletedstages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats} returns this
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.setCompletedstages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats} returns this
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string processType = 4;
 * @return {string}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.getProcesstype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats} returns this
 */
proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats.prototype.setProcesstype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string jobId = 1;
 * @return {string}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.prototype.getJobid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.JobStatsUpdatedEventData.prototype.setJobid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ProcessStats processStats = 2;
 * @return {!Array<!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats>}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.prototype.getProcessstatsList = function() {
  return /** @type{!Array<!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats, 2));
};


/**
 * @param {!Array<!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats>} value
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData} returns this
*/
proto.event_dispatcher.JobStatsUpdatedEventData.prototype.setProcessstatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats}
 */
proto.event_dispatcher.JobStatsUpdatedEventData.prototype.addProcessstats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.event_dispatcher.JobStatsUpdatedEventData.ProcessStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.event_dispatcher.JobStatsUpdatedEventData} returns this
 */
proto.event_dispatcher.JobStatsUpdatedEventData.prototype.clearProcessstatsList = function() {
  return this.setProcessstatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.SubscriptionEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.SubscriptionEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.SubscriptionEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourcepath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventdata: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.SubscriptionEventResponse}
 */
proto.event_dispatcher.SubscriptionEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.SubscriptionEventResponse;
  return proto.event_dispatcher.SubscriptionEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.SubscriptionEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.SubscriptionEventResponse}
 */
proto.event_dispatcher.SubscriptionEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourcepath(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.SubscriptionEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.SubscriptionEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.SubscriptionEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourcepath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEventname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventdata();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string resourcePath = 1;
 * @return {string}
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.getResourcepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.SubscriptionEventResponse} returns this
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.setResourcepath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.SubscriptionEventResponse} returns this
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string eventName = 3;
 * @return {string}
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.getEventname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.SubscriptionEventResponse} returns this
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.setEventname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string eventData = 4;
 * @return {string}
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.getEventdata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.SubscriptionEventResponse} returns this
 */
proto.event_dispatcher.SubscriptionEventResponse.prototype.setEventdata = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.SubscribeEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.SubscribeEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.SubscribeEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.SubscribeEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    service: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resourcepath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.SubscribeEventRequest}
 */
proto.event_dispatcher.SubscribeEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.SubscribeEventRequest;
  return proto.event_dispatcher.SubscribeEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.SubscribeEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.SubscribeEventRequest}
 */
proto.event_dispatcher.SubscribeEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.event_dispatcher.ServiceName} */ (reader.readEnum());
      msg.setService(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourcepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.SubscribeEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.SubscribeEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.SubscribeEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.SubscribeEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getResourcepath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ServiceName service = 1;
 * @return {!proto.event_dispatcher.ServiceName}
 */
proto.event_dispatcher.SubscribeEventRequest.prototype.getService = function() {
  return /** @type {!proto.event_dispatcher.ServiceName} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.event_dispatcher.ServiceName} value
 * @return {!proto.event_dispatcher.SubscribeEventRequest} returns this
 */
proto.event_dispatcher.SubscribeEventRequest.prototype.setService = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string resourcePath = 2;
 * @return {string}
 */
proto.event_dispatcher.SubscribeEventRequest.prototype.getResourcepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.SubscribeEventRequest} returns this
 */
proto.event_dispatcher.SubscribeEventRequest.prototype.setResourcepath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.AlarmMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.AlarmMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.AlarmMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.AlarmMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    threshold: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.AlarmMeta}
 */
proto.event_dispatcher.AlarmMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.AlarmMeta;
  return proto.event_dispatcher.AlarmMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.AlarmMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.AlarmMeta}
 */
proto.event_dispatcher.AlarmMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreshold(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.AlarmMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.AlarmMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.AlarmMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.AlarmMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreshold();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string threshold = 1;
 * @return {string}
 */
proto.event_dispatcher.AlarmMeta.prototype.getThreshold = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.AlarmMeta} returns this
 */
proto.event_dispatcher.AlarmMeta.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operator = 2;
 * @return {string}
 */
proto.event_dispatcher.AlarmMeta.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.AlarmMeta} returns this
 */
proto.event_dispatcher.AlarmMeta.prototype.setOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string data = 3;
 * @return {string}
 */
proto.event_dispatcher.AlarmMeta.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.AlarmMeta} returns this
 */
proto.event_dispatcher.AlarmMeta.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.OrganizationAlarmEventData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.OrganizationAlarmEventData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.OrganizationAlarmEventData.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dashboardname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    widgetname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    widgettype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    metricname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    metricunit: jspb.Message.getFieldWithDefault(msg, 6, ""),
    alarmid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    eventtimestamp: jspb.Message.getFieldWithDefault(msg, 9, 0),
    meta: (f = msg.getMeta()) && proto.event_dispatcher.AlarmMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData}
 */
proto.event_dispatcher.OrganizationAlarmEventData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.OrganizationAlarmEventData;
  return proto.event_dispatcher.OrganizationAlarmEventData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.OrganizationAlarmEventData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData}
 */
proto.event_dispatcher.OrganizationAlarmEventData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDashboardid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDashboardname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgettype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetricname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetricunit(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlarmid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventtimestamp(value);
      break;
    case 10:
      var value = new proto.event_dispatcher.AlarmMeta;
      reader.readMessage(value,proto.event_dispatcher.AlarmMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.OrganizationAlarmEventData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.OrganizationAlarmEventData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.OrganizationAlarmEventData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDashboardname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWidgetname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWidgettype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMetricname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMetricunit();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAlarmid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEventtimestamp();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.event_dispatcher.AlarmMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string dashboardId = 1;
 * @return {string}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getDashboardid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setDashboardid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dashboardName = 2;
 * @return {string}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getDashboardname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setDashboardname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string widgetName = 3;
 * @return {string}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getWidgetname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setWidgetname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string widgetType = 4;
 * @return {string}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getWidgettype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setWidgettype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string metricName = 5;
 * @return {string}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getMetricname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setMetricname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string metricUnit = 6;
 * @return {string}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getMetricunit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setMetricunit = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string alarmId = 7;
 * @return {string}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getAlarmid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setAlarmid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 eventTimestamp = 9;
 * @return {number}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getEventtimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setEventtimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional AlarmMeta meta = 10;
 * @return {?proto.event_dispatcher.AlarmMeta}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.getMeta = function() {
  return /** @type{?proto.event_dispatcher.AlarmMeta} */ (
    jspb.Message.getWrapperField(this, proto.event_dispatcher.AlarmMeta, 10));
};


/**
 * @param {?proto.event_dispatcher.AlarmMeta|undefined} value
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
*/
proto.event_dispatcher.OrganizationAlarmEventData.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.event_dispatcher.OrganizationAlarmEventData} returns this
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.event_dispatcher.OrganizationAlarmEventData.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.prototype.toObject = function(opt_includeInstance) {
  return proto.event_dispatcher.ResourceLevelAlarmEventData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.event_dispatcher.ResourceLevelAlarmEventData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.event_dispatcher.ResourceLevelAlarmEventData}
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.event_dispatcher.ResourceLevelAlarmEventData;
  return proto.event_dispatcher.ResourceLevelAlarmEventData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.event_dispatcher.ResourceLevelAlarmEventData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.event_dispatcher.ResourceLevelAlarmEventData}
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.event_dispatcher.ResourceLevelAlarmEventData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.event_dispatcher.ResourceLevelAlarmEventData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entityId = 1;
 * @return {string}
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.prototype.getEntityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.ResourceLevelAlarmEventData} returns this
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.prototype.setEntityid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.event_dispatcher.ResourceLevelAlarmEventData} returns this
 */
proto.event_dispatcher.ResourceLevelAlarmEventData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.event_dispatcher.EventName = {
  INVALID_EVENT: 0,
  PIPELINE_CREATED: 1,
  JOB_CREATED: 2,
  STAGE_UPDATED: 3,
  PIPELINE_STATS_UPDATED: 4,
  JOB_STATS_UPDATED: 5,
  PIPELINE_UPDATED: 6,
  JOB_UPDATED: 7,
  TIMESERIES_METRIC_GET: 8,
  TIMESERIES_ALARM: 9,
  DASHBOARD_ALARM: 10,
  ORGANIZATION_ALARM: 11
};

/**
 * @enum {number}
 */
proto.event_dispatcher.ServiceName = {
  INVALID_SERVICE: 0,
  STRATO: 1,
  VANGUARD: 2
};

/**
 * @enum {number}
 */
proto.event_dispatcher.ResourceType = {
  INVALID_RESOURCE: 0,
  PIPELINE: 1,
  JOB: 2,
  STAGE: 3,
  ORGANIZATION: 4,
  TIMESERIES: 5,
  DASHBOARD: 6
};

// goog.object.extend(exports, proto.event_dispatcher);

export default proto.event_dispatcher;

// module.exports = proto.event_dispatcher;
