<template>
	<div class="display-contents">
		<Container
			v-if="!hasDeployments"
			align="center"
			direction="column"
			class="align-items-center height-100-per"
			:gap="12"
			:grow="1"
			:data-qa-env-wid-no-apps="env.name"
		>
			<div
				v-if="
					env.classification &&
					(env.classification.name === 'staging' || env.classification.name === 'production')
				"
				align="center"
			>
				<Icon name="i-arrow-right" type="filled" state="light" :effects="false" />
				<Container v-if="env.classification.name === 'staging'">
					<Typography type="p1" color="light"> Promote app from Development </Typography>
					<Icon
						v-tooltip="{
							content:
								'This environment is a part of promotion pipeline. The apps on this environment need to be promoted from the previous environment.',
							placement: 'auto'
						}"
						name="i-info-fill"
						size="small"
						type="filled"
						state="light"
						:padding="10"
					/>
				</Container>
				<Container v-if="env.classification.name === 'production'">
					<Typography type="p1" color="light"> Promote app from Staging </Typography>
					<Icon
						v-tooltip="{
							content:
								'This environment is a part of promotion pipeline. The apps on this environment need to be promoted from the previous environment.',
							placement: 'auto'
						}"
						name="i-info-fill"
						size="small"
						type="filled"
						state="light"
						:padding="10"
					/>
				</Container>
			</div>
			<div v-else align="center">
				<Icon name="i-app" type="filled" state="light" :effects="false" />
				<Typography
					v-tooltip="{
						content: env.classification
							? 'App deployment will be allowed only after this environment is deployed. Please deploy this environment.'
							: '',
						placement: 'auto'
					}"
					type="p1"
					color="light"
					>No apps deployed</Typography
				>
			</div>

			<CreateAndDeployApp
				v-if="project"
				:project="project"
				:environment="env"
				:open="deployModalOpen"
				@close="deployModalOpen = false"
				@back="deployModalOpen = false"
			>
				<span
					v-tooltip="
						env.isDeployed
							? null
							: {
									container: 'div.flow-layout',
									placement: 'bottom-end',
									content:
										'App deployment will be allowed only after this environment is deployed. Please deploy this environment.'
							  }
					"
				>
					<Button
						state="outlined"
						size="small"
						:disabled="!env.isDeployed"
						@click="showDeployModal"
						>{{ hasApps ? "Deploy App" : "Add new app" }}</Button
					>
				</span>
			</CreateAndDeployApp>
		</Container>
		<Container
			v-if="hasDeployments"
			direction="column"
			align="start stretch"
			overflow="auto"
			:padding="16"
			:gap="20"
			:data-qa-env-wid-apps-list="env.name"
		>
			<EnvWidgetAppsList
				v-for="deployment in envDeployments"
				:key="deployment.id"
				:deployment="deployment"
			/>
		</Container>
	</div>
</template>

<script lang="ts">
import { Button, Container, Icon, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import CreateAndDeployApp from "@/modules/env-list/components/CreateAndDeployApp.vue";
import { projectStore } from "@/modules/project-list/project-store";
import { environment } from "@/protocol/infra";

import EnvWidgetAppsList from "./EnvWidgetAppsList.vue";

export default defineComponent({
	name: "EnvWidgetAppsTabContent",
	components: { EnvWidgetAppsList, Typography, Container, Icon, Button, CreateAndDeployApp },

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		}
	},

	data: () => ({
		loading: true,
		deployModalOpen: false
	}),

	computed: {
		envDeployments() {
			return applicationDeploymentStore.deploymentsInEnv[this.env.id] ?? [];
		},

		hasDeployments() {
			return this.loading ? true : !!this.envDeployments.length;
		},

		hasApps() {
			return applicationStore.projectApps[this.env.projId]?.length ?? 0;
		},

		project() {
			return projectStore.projects[this.env.projId];
		}
	},

	mounted() {
		this.fetchEnvDeployments();
	},

	methods: {
		async fetchEnvDeployments() {
			this.loading = true;
			await applicationDeploymentStore.LIST_ENV_APP_DEPLOYMENTS({
				orgId: this.env.orgId,
				projectId: this.env.projId,
				envId: this.env.id
			});
			this.loading = false;
		},

		showDeployModal() {
			this.deployModalOpen = true;
		}
	}
});
</script>
