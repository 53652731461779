import * as Vue from "vue";

import { GitRevisionType } from "@/protocol/common";
import { Creds } from "@/protocol/identity";

const GITHUB_USER_OAUTH = "github_user_oauth";
const REFRESH_TOKEN_KEY = "refresh_token";
const APP_FIRST_FLOW_FORM = "app_first_flow_form";
const ENV_CREATE_FLOW = "env_create_flow";
// Bust cache of user profile because we used the wrong data before
const TOKEN_KEY = "access_token";
export const FEATURE_FLAGS = "client_features";

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 **/
const TokenService = Vue.reactive({
	token: null as null | string,
	refreshToken: null as null | string,

	getToken() {
		if (!this.token) {
			this.token = localStorage.getItem(TOKEN_KEY);
		}

		return this.token;
	},

	saveToken(accessToken: string) {
		this.token = accessToken;
		localStorage.setItem(TOKEN_KEY, accessToken);
	},

	removeToken() {
		this.token = null;
		localStorage.removeItem(TOKEN_KEY);
	},

	getRefreshToken() {
		if (!this.refreshToken) {
			this.refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
		}

		return this.refreshToken;
	},

	saveRefreshToken(refreshToken: string) {
		this.refreshToken = refreshToken;
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},

	removeRefreshToken() {
		this.refreshToken = null;
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	}
});

const GithubOauthStorageService = {
	setGithubUserOauth(githubUserOauth: GithubUserOauthStorage) {
		sessionStorage.setItem(GITHUB_USER_OAUTH, JSON.stringify(githubUserOauth));
	},

	getGithubUserOauth(): GithubUserOauthStorage {
		const githubUserOauth = sessionStorage.getItem(GITHUB_USER_OAUTH);
		if (githubUserOauth) {
			return JSON.parse(githubUserOauth);
		}
		return {
			isUserOauthActive: false,
			gitFormRef: ""
		};
	},

	removeGithubUserOauth() {
		return sessionStorage.removeItem(GITHUB_USER_OAUTH);
	}
};

// App first flow form storage service
const AppFirstFlowFormStorageService = {
	setAppFirstFlowForm(appFirstFlowForm: AppFirstFlowFormStorage) {
		localStorage.setItem(APP_FIRST_FLOW_FORM, JSON.stringify(appFirstFlowForm));
	},

	getAppFirstFlowForm(): AppFirstFlowFormStorage {
		const appFirstFlowForm = localStorage.getItem(APP_FIRST_FLOW_FORM);
		if (appFirstFlowForm) {
			return JSON.parse(appFirstFlowForm);
		}
		return {};
	},

	removeAppFirstFlowForm() {
		return localStorage.removeItem(APP_FIRST_FLOW_FORM);
	}
};

// Env step flow flags
const EnvCreateStepFlowService = {
	toggleFlow(envCreateFlow: EnvCreateStepFlow) {
		localStorage.setItem(ENV_CREATE_FLOW, JSON.stringify(envCreateFlow));
	},

	getToggleFlow(): EnvCreateStepFlow {
		return JSON.parse(localStorage.getItem(ENV_CREATE_FLOW) ?? "{}");
	},

	removeToggleFlow() {
		return localStorage.removeItem(ENV_CREATE_FLOW);
	}
};

export type EnvCreateStepFlow = {
	isEnabled: boolean;
};
export type AppFirstFlowFormStorage = {
	cred?: Creds | null;
	baseOrgId?: string;
	bundleField?: string;
	gitFormRef?: string;
	branch?: GitRevisionType;
	directory?: string;
	name?: string;
};

export type GithubUserOauthStorage = {
	isUserOauthActive: boolean;
	idpCode?: string;
	idpError?: string;
	gitFormRef?: string;
};

const FeatureFlagService = {
	setFeatures(featureFlags: Record<string, unknown>) {
		try {
			localStorage.setItem(FEATURE_FLAGS, JSON.stringify(featureFlags));
		} catch (err) {
			// do nothing
		}
	},

	getFeatures() {
		try {
			const flagsStorage = localStorage.getItem(FEATURE_FLAGS);
			return flagsStorage ? JSON.parse(flagsStorage) : {};
		} catch (err) {
			return {};
		}
	}
};

export {
	TokenService,
	GithubOauthStorageService,
	FeatureFlagService,
	AppFirstFlowFormStorageService,
	EnvCreateStepFlowService
};
