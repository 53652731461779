<script setup lang="ts">
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { PropType, computed, ref, toRefs, watch } from "vue";

import { getDeploymentTemplates } from "@/modules/application-deployment/services/application-deployment-service";
import { envListStore } from "@/modules/env-list/env-list-store";
import { AppDeployment, TemplateTarget } from "@/protocol/deployment";
import { buildForm } from "@/shared/components/JSONSchemaFormBuilder2.vue";
import { JSONSchemaToObject } from "@/utils";

const props = defineProps({
	deployment: {
		type: Object as PropType<AppDeployment>,
		required: true
	}
});

const { deployment } = toRefs(props);

const env = computed(() => {
	return envListStore.envList.find(env_ => env_.id === deployment.value.envId);
});

const pipelineConfigForm = ref<FormBuilderField | null>(null);
const pipelineConfigValues = ref(deployment.value.deploymentConfig?.pipelineConfig ?? {});
const pipelineFormState = ref<FormBuilderState | null>(null);

watch(
	env,
	async () => {
		// This should never happen
		if (!env.value) {
			return;
		}

		const templatePromise = await getDeploymentTemplates({
			orgId: env.value.orgId,
			projId: env.value.projId,
			envId: env.value.id,
			target: [TemplateTarget.config]
		});

		const config = templatePromise.modules?.find(module => module.name === "pipeline-config");

		if (config?.inputs) {
			pipelineConfigForm.value = buildForm({
				schema: config.inputs,
				name: "",
				parentName: "",
				isNested: false,
				isRequired: true
			});

			// Merge default values
			pipelineConfigValues.value = JSONSchemaToObject(
				config.inputs,
				pipelineConfigValues.value
			) as Record<string, unknown>;
		}
	},
	{
		immediate: true
	}
);

const emit = defineEmits(["pipeline-config"]);

function handleInput() {
	emit("pipeline-config", {
		isValid: Boolean(pipelineFormState.value?.isValid),
		value: pipelineConfigValues.value
	} as DeploymentPipelineConfigurationEvent);
}

watch(pipelineFormState, handleInput, { immediate: true });

export type DeploymentPipelineConfigurationEvent = {
	isValid: boolean;
	value: Record<string, unknown>;
};
</script>

<template>
	<f-form-builder
		v-if="pipelineConfigForm"
		data-qa-env-pipeline-configuration-form
		:field.prop="pipelineConfigForm"
		:values.prop="pipelineConfigValues"
		@input="handleInput"
		@state-change="pipelineFormState = $event.detail"
	/>
</template>
