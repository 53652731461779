<template>
	<Container :padding="0">
		<Wrapper
			v-if="isEnvClassificationExists"
			width="450px"
			max-height="80vh"
			border-radius="4px"
			background="element-light"
			data-qa-policy-set-with-restricted-wrapper
		>
			<Header>
				<Typography type="h4" color="dark" data-qa-list-with-restricted-modal-title>
					Assigned policy set</Typography
				>
				<Container :padding="0" :grow="1" align="right center">
					<Icon
						v-tooltip="{
							content:
								'With policies, Code Pipes evaluates your Terraform for potential security misconfigurations and compliance violations prior to deployment.'
						}"
						name="i-question-filled"
						size="x-small"
						type="filled"
						state="light"
						:effects="true"
						data-qa-policy-set-with-restricted-modal-details
					/>
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-policy-set-with-restricted-modal-close-icon
						@click="toggleList"
					/>
				</Container>
			</Header>
			<Container
				:padding="15"
				align="left top"
				overflow="auto"
				direction="column"
				:gap="16"
				class="flow-add-scrollbar"
			>
				<Typography
					type="h5"
					color="dark"
					weight="medium"
					data-qa-policy-set-with-restricted-modal-details-name
					>{{ envPolicySetDetails && envPolicySetDetails.name }}</Typography
				>

				<Typography
					v-if="envPolicySetDetails?.description"
					type="p2-para"
					color="light"
					data-qa-policy-set-with-restricted-modal-details-desc
				>
					{{ envPolicySetDetails.description }}.
				</Typography>
			</Container>
		</Wrapper>
		<ListWithRadioBtnPopover
			v-else
			title="Assigned control gates"
			:list="policySets"
			button-title="Save changes"
			:selected-list-item="selectedPolicySetId"
			:pre-selected-list-item="{ id: entity.policySetId ?? '' }"
			:btn-disable-flag="isLoading"
			:button-loading="isLoading"
			data-qa-list-with-radio-btn-popover-modal
			@toggle-popover="toggleList"
			@footer-button-click="updatePolicySet"
			@toggle-list-selection="changeSelectPolicy"
		/>
	</Container>
</template>
<script lang="ts">
import { Container, Wrapper, Header, Typography, Icon } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { envListStore } from "@/modules/env-list/env-list-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { policyListStore } from "@/modules/policy-list/policy-list-store";
import { projectStore } from "@/modules/project-list/project-store";
import { project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { PolicySet, policySetType } from "@/protocol/validator";
import ListWithRadioBtnPopover from "@/shared/components/code-pipes/ListWithRadioBtnPopover.vue";

export default defineComponent({
	name: "PolicySelectorModal",

	components: {
		ListWithRadioBtnPopover,
		Container,
		Wrapper,
		Header,
		Typography,
		Icon
	},

	props: {
		entity: {
			type: Object as PropType<project | environment>,
			required: true
		},

		entityType: {
			type: String as PropType<"project" | "environment">,
			required: true
		},

		selectedPolicySetId: {
			type: String,
			required: true
		},

		selectedPolicy: {
			type: Object as PropType<PolicySet | null>
		},

		envInheritedPolicy: {
			type: Object as PropType<PolicySet | null>
		}
	},

	emits: ["toggle-list-popover", "select-policy"],

	data: () => ({
		isLoading: false
	}),

	computed: {
		policySets() {
			return policyListStore.policySets
				.map(policySet => ({
					...policySet,
					qaId: policySet.name
				}))
				.filter(policy => policy.type === policySetType.setTypeControlGate);
		},

		isEnvClassificationExists() {
			return this.entityType === "environment" && (this.entity as environment).classification;
		},

		envPolicySetDetails() {
			const envPolicyId = (this.entity as environment).policySetId ?? "";
			return this.envInheritedPolicy
				? this.envInheritedPolicy
				: policyListStore.policySets.find(_policy => _policy.id === envPolicyId);
		}
	},

	methods: {
		toggleList() {
			this.$emit("toggle-list-popover");
		},

		changeSelectPolicy(policyId: string) {
			this.$emit("select-policy", policyId);
		},

		async updatePolicySet() {
			this.isLoading = true;

			if (this.entityType === "project") {
				await projectStore.EDIT_PROJECT({
					id: this.entity.id,
					orgId: this.entity.orgId,
					name: this.entity.name,
					description: this.entity.description,
					variables: this.entity.variables,
					policySetId: this.selectedPolicySetId,
					metadata: this.entity.metadata,
					replaceVariables: false
				});
			}

			if (this.entityType === "environment") {
				const env = this.entity as environment;

				await envListStore.UPDATE_ENV({
					id: env.id,
					orgId: env.orgId,
					projId: env.projId,
					name: env.name,
					description: env.description,
					revision: env.revision,
					variables: env.variables,
					policySetId: this.selectedPolicySetId,
					pipelineConfig: env.pipelineConfig,
					metadata: env.metadata,
					tfVersion: env.tfVersion,
					replaceVariables: false
				});
			}

			notificationsStore.ADD_TOAST({
				qaId: "toast-project-policy-changed",
				title: "Control gate changed successfully",
				text: `Successfully changed the default control gate of the ${this.entity.name} to ${this.selectedPolicy?.name}.`,
				status: "success"
			});

			this.isLoading = false;
			this.toggleList();
		}
	}
});
</script>
