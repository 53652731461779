/* eslint-disable max-nested-callbacks */
import { getModule, Module, VuexModule } from "vuex-module-decorators";

import { app, appIntegration } from "@/protocol/app";
import { variable as variableProto, variableType } from "@/protocol/common";
import { AppDeployment } from "@/protocol/deployment";
import { project as projectProto } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { getEmoji } from "@/utils";

import { applicationStore } from "../application/application-store";
import { applicationDeploymentStore } from "../application-deployment/application-deployment-store";
import { applicationIntegrationStore } from "../application-integration/application-integration-store";
import { envListStore } from "../env-list/env-list-store";
import { projectStore } from "../project-list/project-store";
import { getStore } from "../store";

import {
	VariableAppRow,
	VariableColumn,
	VariableEnvRow,
	VariableProjectRow,
	VariableScope,
	VariableValueState
} from "./variable-list-types";

@Module({
	namespaced: true,
	dynamic: true,
	name: "variablesList",
	store: getStore()
})
class VariablesListStore extends VuexModule {
	get project(): projectProto | null {
		return projectStore.currentProject;
	}

	get envs() {
		return (this.project && envListStore.envs[this.project.id]) ?? [];
	}

	get appDeployments() {
		let appDeployments: AppDeployment[] = [];

		this.envs.forEach(env => {
			const deployments = applicationDeploymentStore.deploymentsInEnv[env.id];
			if (deployments) {
				appDeployments = appDeployments.concat(deployments);
			}
		});

		return appDeployments;
	}

	get apps() {
		const { project } = this;
		return project ? applicationStore.projectApps[project.id] : [];
	}

	get appIntegrations(): Record<string, appIntegration[]> | null {
		return this.project && applicationIntegrationStore.integrations;
	}

	get currIntegrationsAppId(): string | null {
		return this.project && applicationIntegrationStore.currIntegrationAppId;
	}
	get variablesForProject() {
		const { project } = this;

		if (!project) {
			return [];
		}

		return getVariablesForProject(project);
	}

	get variablesForEnvironment() {
		const { project, envs } = this;

		if (!project) {
			return [];
		}

		return getVariablesForEnvironment(project, envs);
	}

	get variablesForAppDeployment() {
		const { project, envs, apps } = this;

		const variablesForApp: Map<string, { app: app; variables: VariableAppRow[] }> = new Map();

		if (!project || !apps) {
			return variablesForApp;
		}

		this.apps?.forEach(app_ => {
			variablesForApp.set(app_.id, {
				app: app_,
				variables: getVariablesForAppDeployment(app_, this.appDeployments, project, envs)
			});
		});

		return variablesForApp;
	}

	get variableCount() {
		const uniqueVars: Set<string> = new Set();

		[...this.variablesForProject, ...this.variablesForEnvironment].forEach(variable => {
			uniqueVars.add(variable.key);
		});

		Array.from(this.variablesForAppDeployment.values()).forEach(appVariables => {
			appVariables.variables.forEach(variable => {
				uniqueVars.add(variable.key);
			});
		});

		return uniqueVars.size;
	}

	get variableScopes(): VariableScope[] {
		const { project, envs, apps, appIntegrations, currIntegrationsAppId } = this;

		if (!project || !apps || !appIntegrations) {
			return [];
		}

		return [
			getVariableScopeForProject(project),
			...getVariableScopeForEnvironment(project, envs),
			...getVariableScopeForApps(this.appDeployments, project, envs),
			...getVariableScopeForAppIntegrations(project, currIntegrationsAppId, appIntegrations)
		];
	}
}

const variablesListStore = getModule(VariablesListStore);

export { variablesListStore };

/**
 * Beloe we have functions which get variable lists for project, environment and applications.
 * Although some parts of the code look similar there are many subtle differences between how inheritance works
 * in those entites. So to keep the code maintainable we are not forcing DRYness here.
 */

function getVariablesForProject(project: projectProto): VariableProjectRow[] {
	return (
		project.variables?.map(variable => {
			const out: VariableProjectRow = {
				state: "equal",
				key: variable.key,
				isSensitive: false,
				varType: variable.type,
				column: {
					name: project.name,
					state: variable.sensitive ? "secret" : "success",
					value: variable.value ?? "",
					key: variable.key,
					type: variable.type,
					isSensitive: false,
					editInfo: {
						type: "project",
						orgId: project.orgId,
						projectId: project.id
					}
				}
			};

			if (variable.sensitive) {
				out.isSensitive = true;
				out.column.isSensitive = true;
				out.state = "secret";
			}

			return out;
		}) ?? []
	);
}

function getVariableScopeForProject(project: projectProto): VariableScope {
	return {
		id: project.id,
		variableFrom: "project",
		groupName: "Project",
		name: project.name,
		editInfo: {
			type: "project",
			orgId: project.orgId,
			projectId: project.id
		},
		variables:
			project.variables?.map(variable => ({
				key: variable.key,
				variableType: variable.type
			})) ?? []
	};
}

function getVariablesForEnvironment(project: projectProto, envs: environment[]): VariableEnvRow[] {
	const envVarMap: Map<string, variableProto> = new Map();

	envs.forEach(env => {
		env.variables?.forEach(envVar => {
			envVarMap.set(envVar.key, envVar);
		});
	});

	const variables = Array.from(envVarMap.values());

	return variables.map(variable => {
		let valueIsEqual = true;
		let hasError = false;

		const out: VariableEnvRow = {
			state: "equal",
			key: variable.key,
			isSensitive: false,
			varType: variable.type,
			columns: {}
		};

		envs.forEach(env => {
			const matchingProjectVar = project.variables?.find(pVar => pVar.key === variable.key);
			let matchingEnvVar = env.variables?.find(envVar => envVar.key === variable.key);
			let state: VariableValueState = "success";

			const inheritedValues: VariableColumn["inheritedValues"] = [];

			if (matchingEnvVar) {
				inheritedValues.push({
					valueFrom: "environment",
					title: env.name,
					variable
				});
			}

			if (
				matchingProjectVar &&
				(matchingProjectVar.value !== variable.value || matchingProjectVar.type !== variable.type)
			) {
				inheritedValues.push({
					valueFrom: "project",
					title: project.name,
					variable: matchingProjectVar,
					icon: getEmoji(project) ?? undefined
				});
			}

			// Environments inherit variables from projects, so if one is missing
			// we just use the project one as-is
			if (!matchingEnvVar) {
				matchingEnvVar = matchingProjectVar;
			}

			if (!matchingEnvVar) {
				hasError = true;
				state = "error";
			} else if (matchingEnvVar.value !== variable.value) {
				valueIsEqual = false;
			}

			if (matchingEnvVar?.sensitive) {
				state = "secret";
				out.isSensitive = true;
			}

			out.columns[env.name] = {
				name: env.name,
				state,
				value: matchingEnvVar?.value ?? null,
				key: matchingEnvVar?.key ?? variable.key,
				type: matchingEnvVar?.type ?? null,
				isSensitive: out.isSensitive,
				inheritedValues,
				editInfo: {
					type: "environment",
					envId: env.id,
					orgId: project.orgId,
					projectId: project.id
				}
			};
		});

		// Secrets can't be compared
		if (out.isSensitive) {
			out.state = "secret";
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		} else if (hasError) {
			out.state = "error";
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		} else if (!valueIsEqual) {
			out.state = "notequal";
		}

		return out;
	});
}

function getVariableScopeForEnvironment(
	project: projectProto,
	envs: environment[]
): VariableScope[] {
	return envs.map(env => {
		return {
			id: env.id,
			variableFrom: "environment",
			groupName: "Infrastructures",
			name: env.name,
			editInfo: {
				type: "environment",
				envId: env.id,
				orgId: project.orgId,
				projectId: project.id
			},
			variables:
				env.variables?.map(variable => ({
					key: variable.key,
					variableType: variable.type
				})) ?? []
		};
	});
}

function getVariablesForAppDeployment(
	application: app,
	applicationDeployments: AppDeployment[],
	project: projectProto,
	envs: environment[]
): VariableAppRow[] {
	const variableKeys: Set<string> = new Set();

	const deploymentsForApp = applicationDeployments.filter(
		deployment => deployment.appId === application.id
	);

	deploymentsForApp.forEach(deploymentForApp => {
		[
			...Object.keys(deploymentForApp.deploymentConfig?.env ?? {}),
			...Object.keys(deploymentForApp.deploymentConfig?.sensitive ?? {})
		].forEach(varKey => variableKeys.add(varKey));
	});

	return Array.from(variableKeys).map(variableKey => {
		const out: VariableAppRow = {
			key: variableKey,
			isSensitive: false as boolean,
			state: "equal",
			columns: []
		};

		let isSensitive = false;
		let valueIsEqual = true;
		let lastValue: string | null = null;
		let hasErrors = false;

		deploymentsForApp.forEach(deploymentForApp => {
			const env = envs.find(env_ => env_.id === deploymentForApp.envId);

			const column = getVariableColumnForDeployment({
				variableKey,
				deployment: deploymentForApp,
				env: env!,
				project
			});

			out.columns.push(column);

			if (column.isSensitive) {
				isSensitive = true;
			}

			// Check for mismatching values
			if (lastValue !== null && lastValue !== column.value) {
				valueIsEqual = false;
			}
			lastValue = column.value;

			// Check for missing values
			if (column.state === "error") {
				hasErrors = true;
			}
		});

		out.isSensitive = isSensitive;

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (hasErrors) {
			out.state = "error";
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		} else if (!valueIsEqual) {
			out.state = "notequal";
		}

		return out;
	});
}

export function getVariableColumnForDeployment({
	variableKey,
	deployment,
	env,
	project
}: {
	project: projectProto;
	variableKey: string;
	deployment: AppDeployment;
	env: environment;
}): VariableColumn {
	let isSensitive = false;

	// Get the value of the deployed application
	let deployedVarValue = deployment.deploymentConfig?.env?.[variableKey];
	if (deployedVarValue === undefined) {
		deployedVarValue = deployment.deploymentConfig?.sensitive?.[variableKey];

		if (deployedVarValue !== undefined) {
			isSensitive = true;
		}
	}

	// Inherited from environment
	const inheritedValues: VariableColumn["inheritedValues"] = [];

	if (deployedVarValue !== undefined) {
		inheritedValues.push({
			valueFrom: "application-deployment",
			title: deployment.name,
			variable: {
				sensitive: isSensitive,
				value: deployedVarValue,
				key: variableKey,
				type: variableType.env_var
			}
		});
	}

	const matchingEnvVar = env.variables?.find(
		envVar => envVar.key === variableKey && envVar.type === variableType.env_var
	);

	if (matchingEnvVar !== undefined && matchingEnvVar.value !== deployedVarValue) {
		inheritedValues.push({
			valueFrom: "environment",
			title: env.name,
			variable: matchingEnvVar
		});
	}

	// Inherited from project
	const matchingProjectVar = project.variables?.find(
		pVar => pVar.key === variableKey && pVar.type === variableType.env_var
	);

	if (matchingProjectVar !== undefined && matchingProjectVar.value !== deployedVarValue) {
		inheritedValues.push({
			valueFrom: "project",
			title: project.name,
			variable: matchingProjectVar,
			icon: getEmoji(project) ?? undefined
		});
	}

	let state: VariableValueState = "success";
	if (isSensitive) {
		state = "secret";
	} else if (!deployedVarValue) {
		state = "error";
	}

	return {
		name: deployment.name!,
		state,
		value: deployedVarValue ?? null,
		key: variableKey,
		type: variableType.env_var,
		inheritedValues,
		isSensitive,
		editInfo: {
			type: "application-deployment",
			envId: env.id,
			projectId: project.id,
			orgId: project.orgId,
			applicationId: deployment.appId!,
			applicationDeploymentId: deployment.id
		}
	};
}

function getVariableScopeForApps(
	applicationDeployments: AppDeployment[],
	project: projectProto,
	envs: environment[]
): VariableScope[] {
	return applicationDeployments.map(deployment => {
		const env = envs.find(env_ => env_.id === deployment.envId);

		if (!env) {
			throw new Error(`No matching environment found for application ${deployment.name}`);
		}

		const variables = [
			...Object.keys(deployment.deploymentConfig?.env ?? {}),
			...Object.keys(deployment.deploymentConfig?.sensitive ?? {})
		];

		return {
			id: deployment.id!,
			variableFrom: "application-deployment",
			name: deployment.name!,
			groupName: env.name,
			editInfo: {
				type: "application-deployment",
				envId: env.id,
				projectId: project.id,
				orgId: project.orgId,
				applicationId: deployment.appId!,
				applicationDeploymentId: deployment.id
			},
			variables: variables.map(variable => ({
				key: variable,
				variableType: variableType.env_var
			}))
		};
	});
}

function getVariableScopeForAppIntegrations(
	project: projectProto,
	currIntegrationsAppId: string | null,
	appIntegrations: Record<string, appIntegration[]>
): VariableScope[] {
	if (!currIntegrationsAppId) {
		return [];
	}

	return appIntegrations[currIntegrationsAppId]!.map(integration => {
		const variables = [
			...Object.keys(integration.config?.sensitive ?? {}),
			...Object.keys(integration.config?.vars ?? {})
		];

		return {
			id: integration.id,
			variableFrom: "application-integration",
			name: integration.name,
			groupName: project.name,
			editInfo: {
				type: "application-integration",
				projectId: project.id,
				orgId: project.orgId,
				applicationId: integration.appId,
				applicationintegrationId: integration.id
			},
			variables: variables.map(variable => ({
				key: variable,
				variableType: variableType.env_var
			}))
		};
	});
}
