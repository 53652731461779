<template>
	<Container
		direction="column"
		padding="20px 0"
		:gap="20"
		:grow="1"
		overflow="visible"
		class="height-100-per"
		align="left top"
	>
		<!-- Header -->
		<Container
			data-qa-inputs-tab-header
			:padding="0"
			overflow="visible"
			align="space-between top"
			class="dashboard-input"
		>
			<Container :padding="0" :gap="12" overflow="visible">
				<Typography type="h4" weight="bold" color="gray-200" transform="capitalize">{{
					selectedTab
				}}</Typography>
			</Container>
		</Container>

		<!-- body -->
		<Container
			direction="column"
			padding="0px 0px 50px 0px"
			align="left top"
			:grow="1"
			:gap="36"
			overflow="auto"
		>
			<Table border="rows" data-qa-dependency-input-output-table>
				<template #header>
					<TableRow>
						<TableCell padding="16px 12px" width="250px">
							<Container :padding="0" :gap="12" overflow="visible">
								<Typography type="p2">Description</Typography>
							</Container>
						</TableCell>
						<TableCell padding="16px 12px">
							<Typography type="p2" color="gray-300">{{ dependency?.description }}</Typography>
						</TableCell>
					</TableRow>
				</template>
				<template #body>
					<TableRow>
						<TableCell>
							<Container :padding="0" :gap="12" overflow="visible">
								<Typography type="p2">Published on</Typography>
							</Container>
						</TableCell>
						<TableCell padding="12px 12px">
							<Typography type="p2" color="gray-300">{{
								getFormattedTime(dependency?.createdAt)
							}}</Typography>
						</TableCell>
					</TableRow>
				</template>
			</Table>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Table, TableCell, TableRow, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { Dependency } from "@/protocol/infra";
import { formatDateToRegularFormat, normalizeUnixTime } from "@/utils";

export default defineComponent({
	name: "DependencyAbout",
	components: { Container, Typography, Table, TableRow, TableCell },

	props: {
		dependency: {
			type: Object as PropType<Dependency>
		},

		selectedTab: {
			type: String
		}
	},

	methods: {
		getFormattedTime(timestamp: string | undefined) {
			if (!timestamp) {
				return "";
			}
			return formatDateToRegularFormat(normalizeUnixTime(timestamp));
		}
	}
});
</script>
