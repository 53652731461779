<template>
	<f-div direction="column" gap="small">
		<f-div padding="large none none none">
			<f-text size="small" variant="heading" state="secondary">Inputs</f-text>
		</f-div>
		<f-div
			v-for="variable in variables"
			:key="variable.key"
			direction="row"
			gap="large"
			height="hug-content"
		>
			<f-div direction="row" gap="x-small" width="80%" padding="none">
				<f-icon v-if="variable.sensitive" source="i-lock" state="secondary"> </f-icon>
				<f-text size="small" variant="para" state="custom, #ffffff">{{ variable.key }}</f-text>
				<f-text size="small" variant="para" state="secondary">{{ variable.value }}</f-text>
			</f-div>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { variable } from "@/protocol/common";

export default defineComponent({
	name: "TerraformInputTab",

	props: {
		variables: {
			type: Array as PropType<variable[]>,
			required: true
		}
	}
});
</script>
