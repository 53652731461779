import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
	cancelEnvJob,
	fetchArtifact,
	getAJob,
	listEnvPipelineJobs,
	pipelineConfigSchemaGet,
	startPipeline
} from "@/modules/env-pipeline/env-pipeline-service";
import { GitRevisionOverride } from "@/protocol/common";
import {
	actionType,
	envPipelineConfigSchemaReq,
	envPipelineConfigSchemaResp,
	jobShortResponse,
	tfLogLevel
} from "@/protocol/infra";
import { JOB_STATUS, PipelineEdge, PipelineNode } from "@/shared/pipeline-constants";
import { normalizeUnixTime } from "@/utils";

import { getStore } from "../store";

import { EnvPipelineDagMeta, EnvPipelineViewedJob } from "./env-pipeline-types";
import { updateJobSteps } from "./utils";

export interface EnvPipelineLoader {
	envId: string;
	action: actionType;
	value: boolean;
}
@Module({
	dynamic: true,
	namespaced: true,
	name: "newEnvPipeline",
	store: getStore()
})
class EnvPipelineStore extends VuexModule {
	isJobsLoading = false;
	userConfirmationForPipelineAction = false;

	envPipelineLoaders: Record<actionType, EnvPipelineLoader[]> = {
		validateOnly: [],
		ValidateAndApply: [],
		destroy: [],
		no_action_type_support: []
	};

	envDagMetaList: EnvPipelineDagMeta = [];
	artifacts: Record<string, Record<string, unknown>> = {};
	allEnvJobs: EnvPipelineViewedJob[] = [];
	pipelineHistory: Record<string, jobShortResponse[]> = {};
	pipelineConfig: Record<string, envPipelineConfigSchemaResp> = {};

	@Mutation
	RESET_ENV_PIPELINE_STORE() {
		this.userConfirmationForPipelineAction = false;
		this.envPipelineLoaders = {
			validateOnly: [],
			ValidateAndApply: [],
			destroy: [],
			no_action_type_support: []
		};
		this.isJobsLoading = false;
		this.envDagMetaList = [];
		this.artifacts = {};
	}

	@Mutation
	SET_JOB_FOR_PIPELINE(job: EnvPipelineViewedJob) {
		this.allEnvJobs = this.allEnvJobs.filter(vJob => vJob.id !== job.id);
		this.allEnvJobs.push({ ...job });
	}

	@Mutation
	SET_ENV_PIPELINE_LOADER({
		action,
		envId,
		value
	}: {
		action?: actionType;
		envId: string;
		value: boolean;
	}) {
		if (!action) {
			return;
		}

		this.envPipelineLoaders[action] = this.envPipelineLoaders[action].filter(
			loader => loader.envId !== envId
		);
		this.envPipelineLoaders[action].push({ envId, action, value });
	}

	@Mutation
	SET_USER_CONFIRMATION_FOR_PIPELINE_ACTION(value: boolean) {
		this.userConfirmationForPipelineAction = value;
	}

	@Mutation
	SET_JOBS_LOADING(value: boolean) {
		this.isJobsLoading = value;
	}

	@Mutation
	UPDATE_STEPS_FOR_JOB(job: EnvPipelineViewedJob) {
		const edges: PipelineEdge[] = [];
		const nodes: PipelineNode[] = [];

		const isPiplineActive = job.status === JOB_STATUS.ACTIVE;

		job.steps?.forEach((step, index) => {
			const nextStep = job.steps?.[index + 1];

			if (nextStep && step.name && nextStep.name) {
				edges.push({
					source: step.name,
					target: nextStep.name
				});
			}

			nodes.push({
				name: step.name,
				nodeNumber: [index + 1].toString(),
				type: "standby",
				statusText: isPiplineActive ? "Waiting..." : "",
				status: "Not Started",
				selected: index === 0 ? true : false,
				infoIcon: {
					remove: true
				}
			});
		});

		const filteredMeta = this.envDagMetaList.filter(
			envDagMeta => envDagMeta.action !== job.action || envDagMeta.envId !== job.envId
		);

		this.envDagMetaList = [
			...filteredMeta,
			{
				action: job.action,
				envId: job.envId,
				edges,
				nodes: updateJobSteps({ nodes, job })
			}
		];
	}

	@Mutation
	RESET_ARTIFACTS() {
		this.artifacts = {};
	}

	@Mutation
	SET_ACTIVE_STEP({ step, action, envId }: { step: string; action: actionType; envId: string }) {
		const envDagMeta = this.envDagMetaList.find(
			dagMeta => dagMeta.envId === envId && dagMeta.action === action
		);

		if (envDagMeta) {
			envDagMeta.nodes.forEach(node => {
				node.selected = false;

				if (node.name === step) {
					node.selected = true;
				}
			});
		}
	}

	@Mutation
	SET_PIPELINE_HISTORY({ envId, jobs }: { envId: string; jobs?: jobShortResponse[] }) {
		if (jobs) {
			this.pipelineHistory[envId] = jobs;
		}
	}

	@Mutation
	SET_ENV_PIPELINE_CONFIG({
		envId,
		config
	}: {
		envId: string;
		config: envPipelineConfigSchemaResp;
	}) {
		this.pipelineConfig[envId] = config;
	}

	@Action
	async GET_ENV_PIPELINE_CONFIG(request: envPipelineConfigSchemaReq) {
		const pipelineConfig = await pipelineConfigSchemaGet(request);
		this.SET_ENV_PIPELINE_CONFIG({ envId: request.envId, config: pipelineConfig });
		return pipelineConfig;
	}

	@Action
	async LIST_JOB_FOR_ENV({
		orgId,
		projId,
		envId,
		jobId
	}: {
		orgId: string;
		projId: string;
		envId: string;
		jobId: string;
	}) {
		this.SET_JOBS_LOADING(true);

		const job = await getAJob({
			id: jobId,
			orgId,
			projId,
			envId
		});

		const viewedJob: EnvPipelineViewedJob = { ...job, envId, projId, orgId };

		this.SET_JOB_FOR_PIPELINE(viewedJob);
		this.UPDATE_STEPS_FOR_JOB(viewedJob);

		this.SET_JOBS_LOADING(false);
	}

	@Action
	async LIST_JOBS_FOR_ENV({
		orgId,
		projId,
		envId,
		limit = 1,
		action
	}: {
		orgId: string;
		projId: string;
		envId: string;
		limit: number;
		action?: actionType;
	}) {
		if (action) {
			this.SET_ENV_PIPELINE_LOADER({ envId, action, value: true });
		}

		const response = await listEnvPipelineJobs({
			orgId,
			projId,
			envId,
			limit,
			action
		});

		if (!response.jobs) {
			this.SET_ENV_PIPELINE_LOADER({ envId, action, value: false });
			this.SET_JOBS_LOADING(false);
			return;
		}

		const jobs = await Promise.all(
			response.jobs.map(job =>
				getAJob({
					id: job.id,
					orgId,
					projId,
					envId
				})
			)
		);

		if (action) {
			this.SET_ENV_PIPELINE_LOADER({ envId, action, value: false });
		}

		jobs.forEach(job => {
			const viewedJob: EnvPipelineViewedJob = { ...job, envId, projId, orgId };

			this.SET_JOB_FOR_PIPELINE(viewedJob);
			this.UPDATE_STEPS_FOR_JOB(viewedJob);
		});

		this.SET_JOBS_LOADING(false);
	}

	@Action
	async START_ENV_PIPELINE({
		orgId,
		projId,
		envId,
		action,
		gitRevision,
		logVerbosity
	}: {
		orgId: string;
		projId: string;
		envId: string;
		action: actionType;
		logVerbosity: tfLogLevel;
		gitRevision?: GitRevisionOverride;
	}): Promise<EnvPipelineViewedJob> {
		try {
			this.RESET_ARTIFACTS();

			// Show loader
			this.SET_ENV_PIPELINE_LOADER({ action, envId, value: true });

			// Call start pipeline api
			const newJob = await startPipeline({
				orgId,
				projId,
				envId,
				action,
				logVerbosity,
				gitRevision,
				waitForApproval: false
			});

			const viewedJob: EnvPipelineViewedJob = { ...newJob, envId, projId, orgId };

			newEnvPipelineStore.SET_JOB_FOR_PIPELINE(viewedJob);
			newEnvPipelineStore.UPDATE_STEPS_FOR_JOB(viewedJob);
			return viewedJob;
		} finally {
			// hide the loader
			this.SET_ENV_PIPELINE_LOADER({ action, envId, value: false });
		}
	}

	@Action
	async FETCH_ARTIFACT({ fileName, job }: { fileName: string; job: EnvPipelineViewedJob }) {
		const artifact = await fetchArtifact({
			job: { envId: job.envId, id: job.id, projId: job.projId, orgId: job.orgId },
			artifactName: fileName
		});

		this.SAVE_ARTIFACT({
			jobId: job.id,
			artifact,
			fileName
		});
	}

	@Mutation
	SAVE_ARTIFACT({
		jobId,
		fileName,
		artifact
	}: {
		jobId: string;
		artifact: unknown;
		fileName: string;
	}) {
		const artifacts = this.artifacts[jobId] ?? {};
		artifacts[fileName] = artifact;
		this.artifacts[jobId] = artifacts;
	}

	@Action
	async CANCEL_PIPELINE({
		job,
		projId,
		orgId
	}: {
		job: EnvPipelineViewedJob;
		projId: string;
		orgId: string;
	}) {
		this.SET_ENV_PIPELINE_LOADER({ envId: job.envId, action: job.action, value: true });

		const result = await cancelEnvJob({
			envId: job.envId,
			id: job.id,
			projId,
			orgId
		});

		if (result.success) {
			await this.LIST_JOBS_FOR_ENV({
				orgId,
				projId,
				envId: job.envId,
				limit: 1,
				action: job.action
			});
		}

		this.SET_ENV_PIPELINE_LOADER({ envId: job.envId, action: job.action, value: false });
	}

	@Action
	async FETCH_PIPELINE_HISTORY({
		orgId,
		projId,
		envId,
		limit,
		action
	}: {
		orgId: string;
		projId: string;
		envId: string;
		limit: number;
		action: actionType;
	}) {
		const jobs = await listEnvPipelineJobs({
			envId,
			orgId,
			projId,
			limit,
			action
		});

		this.SET_PIPELINE_HISTORY({
			envId,
			jobs: jobs.jobs
		});
	}
}

const newEnvPipelineStore = getModule(EnvPipelineStore);

export { newEnvPipelineStore };

/**
 * Returns a sorted list of jobs for an action and an environment ID,
 * if no action is provided then all jobs for that environment are returned
 */
export function getSortedEnvJobs(envId: string, action?: actionType) {
	return newEnvPipelineStore.allEnvJobs
		.filter(job => {
			const hasAction = action ? job.action === action : true;
			return hasAction && job.envId === envId;
		})
		.sort((job1, job2) => {
			return normalizeUnixTime(job2.createdAt) - normalizeUnixTime(job1.createdAt);
		});
}
