<template>
	<Container padding="18px 24px 0px 0px" :gap="12" align="left top">
		<Pictogram size="xl" shape="hexagon" v-bind="pictogramState" data-qa-env-header-pictogram>{{
			emoji
		}}</Pictogram>
		<Container :padding="0" :gap="6" direction="column" flex-wrap>
			<Container :padding="0" :gap="12">
				<Typography
					v-tooltip="{
						content: name,
						placement: 'right'
					}"
					type="h3"
					color="dark"
					class="max-width-250"
					overflow="ellipsis"
					data-qa-env-header-name
					>{{ name }}</Typography
				>

				<Icon
					v-tooltip="{
						content: 'Environment settings',
						placement: 'right'
					}"
					name="i-setting"
					color="gray-200"
					size="small"
					data-qa-env-detail-view-env-setting-btn
					@click="$emit('settings')"
				/>

				<template v-if="isDeployed">
					<Icon
						v-tooltip="{
							content: 'Download .tfstate file',
							placement: 'right'
						}"
						data-qa-env-detail-download-terraform
						name="i-download"
						color="gray-200"
						size="small"
						@click="$emit('download-tf')"
					/>
				</template>

				<Icon
					v-if="!hasNxPlatformFlag"
					v-tooltip="{
						content: 'Delete environment',
						placement: 'right'
					}"
					name="i-delete"
					color="gray-200"
					size="small"
					data-qa-delete-environment
					@click="$emit('delete')"
				/>
			</Container>

			<slot name="status-text" />
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Pictogram, Icon, Typography, PictogramBorderState } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";

export default defineComponent({
	name: "EnvironmentState",

	components: {
		Container,
		Pictogram,
		Icon,
		Typography
	},

	props: {
		name: {
			type: String,
			required: true
		},

		emoji: {
			type: String,
			default: () => "🌐"
		},

		status: {
			type: String as PropType<EnvironmentStatus>,
			default: () => "default"
		},

		isDeployed: Boolean
	},

	emits: ["info", "settings", "delete", "download-tf"],

	computed: {
		hasNxPlatformFlag() {
			return featureFlagStore.featureMap.ENABLE_NX_PLATFORM;
		},

		pictogramState(): { borderState: PictogramBorderState; loading: boolean } {
			if (this.status === "cancelling" || this.status === "destroying") {
				return {
					borderState: "error",
					loading: true
				};
			} else if (this.status === "deploying") {
				return {
					borderState: "success",
					loading: true
				};
			} else if (this.status === "validating") {
				return {
					borderState: "primary",
					loading: true
				};
			}

			return {
				borderState: "default",
				loading: false
			};
		}
	}
});

export type EnvironmentStatus =
	| "cancelling"
	| "validating"
	| "default"
	| "deploying"
	| "destroying";
</script>
