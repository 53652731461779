import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import {
	deleteProjectVariables,
	updateProjectVariables
} from "@/modules/project-list/project-service";
import { projectStore } from "@/modules/project-list/project-store";
import { variable } from "@/protocol/common";

import {
	deleteAppVariables,
	updateAppVariables
} from "../application-deployment/services/application-deployment-service";
import { updateAppIntegrationVars } from "../application-integration/application-integration-service";
import { applicationIntegrationStore } from "../application-integration/application-integration-store";
import { deleteEnvVariables, updateEnvVariables } from "../env-list/env-list-service";

//@todo - fix form builder width issue

import {
	AddVariableScope,
	Variable,
	VariableColumn,
	VariableEditInfo
} from "./variable-list-types";
import { variablesListStore } from "./variables-list-store";

export async function updateProjectVars(editInfo: VariableEditInfo, formValues: Variable[]) {
	const variables: variable[] = [];

	formValues.forEach(({ key, value, variableType, isMasked }) => {
		variables.push({
			key: key.trim(),
			value: value.trim(),
			sensitive: isMasked,
			type: variableType
		});
	});

	await updateProjectVariables({
		id: editInfo.projectId,
		orgId: editInfo.orgId,
		variables
	});

	await projectStore.GET_PROJECT({ orgId: editInfo.orgId, projectId: editInfo.projectId });
}

export async function deleteProjectVars(variableColumn: VariableColumn, skipUpdate = false) {
	const projectId = variableColumn.editInfo?.projectId;
	if (!projectId) {
		throw new Error("Project Id not found");
	}
	const project = projectStore.detailedProjects[projectId];

	if (!project) {
		throw new Error("Project not found");
	}

	await deleteProjectVariables({
		id: project.id,
		orgId: project.orgId,
		variables: project.variables?.filter(
			projVar => projVar.key === variableColumn.key && projVar.type === variableColumn.type
		)
	});

	if (!skipUpdate) {
		await projectStore.GET_PROJECT({ orgId: project.orgId, projectId: project.id });
	}
}

export async function updateEnvironmentVars(editInfo: VariableEditInfo, formValues: Variable[]) {
	const env = getEnvFromEditInfo(editInfo);

	const variables: variable[] = [];

	formValues.forEach(({ key, value, variableType, isMasked }) => {
		variables.push({
			key: key.trim(),
			value: value.trim(),
			sensitive: isMasked || false,
			type: variableType
		});
	});

	await updateEnvVariables({
		envId: env.id,
		projId: env.projId,
		orgId: env.orgId,
		variables
	});

	// Update env store
	await envListStore.GET_ENVS({
		orgId: env.orgId,
		projectId: env.projId
	});
}

export async function deleteEnvironmentVars(variableColumn: VariableColumn, skipUpdate = false) {
	const env = getEnvFromEditInfo(variableColumn.editInfo);

	await deleteEnvVariables({
		envId: env.id,
		projId: env.projId,
		orgId: env.orgId,
		variables: env.variables?.filter(
			envVar => envVar.key === variableColumn.key && envVar.type === variableColumn.type
		)
	});

	// Update env store
	if (!skipUpdate) {
		await envListStore.GET_ENVS({
			orgId: env.orgId,
			projectId: env.projId
		});
	}
}

export async function getDeploymentFromEditInfo(editInfo: VariableEditInfo | null) {
	if (editInfo?.type !== "application-deployment") {
		throw new Error("Not a deployment variable");
	}

	const deployments = await applicationDeploymentStore.GET_APP_DEPLOYMENTS({
		orgId: editInfo.orgId,
		projId: editInfo.projectId,
		id: editInfo.applicationId
	});

	const deployment = deployments?.find(
		deployment_ => deployment_.id === editInfo.applicationDeploymentId
	);

	if (!deployment) {
		throw new Error(`Deployment ${editInfo.applicationDeploymentId} not found`);
	}

	return deployment;
}

export async function updateDeploymentVars(editInfo: VariableEditInfo, formValues: Variable[]) {
	const deployment = await getDeploymentFromEditInfo(editInfo);

	if (!deployment.orgId || !deployment.projId || !deployment.id || !deployment.envId) {
		return;
	}

	const envVars: Record<string, string> = {};
	const sensitiveVars: Record<string, string> = {};

	formValues.forEach(({ key, value, isMasked }) => {
		const trimmedKey = key.trim();
		const trimmedValue = value.trim();

		if (isMasked) {
			sensitiveVars[trimmedKey] = trimmedValue;
		} else {
			envVars[trimmedKey] = trimmedValue;
		}
	});

	await updateAppVariables({
		orgId: deployment.orgId,
		projId: deployment.projId,
		envId: deployment.envId,
		id: deployment.id,
		env: envVars,
		sensitive: sensitiveVars
	});

	await applicationDeploymentStore.LIST_ENV_APP_DEPLOYMENTS({
		orgId: deployment.orgId,
		projectId: deployment.projId,
		envId: deployment.envId
	});
}

export async function deleteDeploymentVars(variableColumn: VariableColumn, skipUpdate = false) {
	const { key, state } = variableColumn;
	const deployment = await getDeploymentFromEditInfo(variableColumn.editInfo);

	if (!key || !deployment.orgId || !deployment.projId || !deployment.id || !deployment.envId) {
		return;
	}

	const env: string[] = [];
	const sensitive: string[] = [];

	if (state === "secret") {
		sensitive.push(key);
	} else {
		env.push(key);
	}

	await deleteAppVariables({
		orgId: deployment.orgId,
		projId: deployment.projId,
		envId: deployment.envId,
		id: deployment.id,
		env,
		sensitive
	});

	if (!skipUpdate) {
		await applicationDeploymentStore.LIST_ENV_APP_DEPLOYMENTS({
			orgId: deployment.orgId,
			projectId: deployment.projId,
			envId: deployment.envId
		});
	}
}

export function getIntegrationFromEditInfo(editInfo: VariableEditInfo | null) {
	if (editInfo?.type !== "application-integration") {
		throw new Error("Not an application integration variable");
	}

	const integrations = Object.values(applicationIntegrationStore.integrations)
		.map(i => i)
		.flat();

	const integration = integrations.find(
		integration_ => integration_.id === editInfo.applicationintegrationId
	);

	if (!integration) {
		throw new Error(
			`Application Integration with id: ${editInfo.applicationintegrationId} not found!`
		);
	}

	return integration;
}
export async function updateAppIntegrationVariables(
	editInfo: VariableEditInfo,
	formValues: Variable[]
) {
	const integration = getIntegrationFromEditInfo(editInfo);

	if (!integration.orgId || !integration.projId || !integration.id || !integration.appId) {
		return;
	}

	const vars: Record<string, string> = {};
	const sensitiveVars: Record<string, string> = {};

	formValues.forEach(({ key, value, isMasked }) => {
		const trimmedKey = key.trim();
		const trimmedValue = value.trim();

		if (isMasked) {
			sensitiveVars[trimmedKey] = trimmedValue;
		} else {
			vars[trimmedKey] = trimmedValue;
		}
	});

	await updateAppIntegrationVars({
		appId: integration.appId,
		orgId: integration.orgId,
		id: integration.id,
		projId: integration.projId,
		env: vars,
		sensitive: sensitiveVars
	});

	await applicationIntegrationStore.FETCH_APP_INTEGRATION({
		id: integration.id,
		orgId: integration.orgId,
		projId: integration.projId,
		appId: integration.appId
	});
}

export function canModifyVariable(editInfo: VariableEditInfo | null) {
	if (!editInfo) {
		return false;
	}

	if (editInfo.type === "application-deployment") {
		const envDeployments = applicationDeploymentStore.deploymentsInEnv[editInfo.envId];
		return envDeployments
			? Boolean(
					envDeployments.find(deployment_ => deployment_.id === editInfo.applicationDeploymentId)
			  )
			: false;
	}

	return true;
}

function getEnvFromEditInfo(editInfo: VariableEditInfo | null) {
	if (editInfo?.type !== "environment") {
		throw new Error("Not an environment variable");
	}

	const env = envListStore.envs[editInfo.projectId]?.find(env_ => env_.id === editInfo.envId);

	if (!env) {
		throw new Error(`Environment ${editInfo.envId} not found`);
	}

	return env;
}

export function updateVariablesFromForms(selectedScopes: AddVariableScope[]) {
	const scopes = variablesListStore.variableScopes;

	// We need to group API calls to update specific project/environments/apps because
	// otherwise we run into the risk of one API call overwriting the previous one's results
	const projectUpdateMap = getUpdateMap();
	const envUpdateMap = getUpdateMap();
	const appUpdateMap = getUpdateMap();
	const appIntegrationUpdateMap = getUpdateMap();

	selectedScopes.forEach(formScope => {
		const matchingScope = scopes.find(scope_ => scope_.id === formScope.id);
		const { variables } = formScope;
		if (!matchingScope) {
			throw new Error(`Scope ${formScope.id}, ${formScope.name} not found`);
		}

		if (matchingScope.editInfo.type === "project") {
			let projectUpdate = projectUpdateMap.get(matchingScope.editInfo.projectId);

			if (!projectUpdate) {
				projectUpdate = {
					editInfo: matchingScope.editInfo,
					values: []
				};

				projectUpdateMap.set(matchingScope.editInfo.projectId, projectUpdate);
			}

			// Push form variables inside project update
			variables.forEach((variablesObj: Variable) => {
				const { key, isMasked, value, variableType } = variablesObj;
				if (key && value) {
					projectUpdate?.values.push({
						key,
						value,
						isMasked: isMasked || false,
						variableType
					});
				}
			});
		} else if (matchingScope.editInfo.type === "environment") {
			let envUpdate = envUpdateMap.get(matchingScope.editInfo.envId);

			if (!envUpdate) {
				envUpdate = {
					editInfo: matchingScope.editInfo,
					values: []
				};

				envUpdateMap.set(matchingScope.editInfo.envId, envUpdate);
			}

			// Push form variables inside project update
			variables.forEach((variablesObj: Variable) => {
				const { key, isMasked, value, variableType } = variablesObj;
				if (key && value) {
					envUpdate?.values.push({
						key,
						value,
						isMasked: isMasked || false,
						variableType
					});
				}
			});
		} else if (matchingScope.editInfo.type === "application-deployment") {
			const deploymentId = matchingScope.editInfo.applicationDeploymentId;
			let appUpdate = deploymentId ? appUpdateMap.get(deploymentId) : null;

			if (!appUpdate && deploymentId) {
				appUpdate = {
					editInfo: matchingScope.editInfo,
					values: []
				};

				appUpdateMap.set(deploymentId, appUpdate);
			}

			// Push form variables inside project update
			variables.forEach((variablesObj: Variable) => {
				const { key, isMasked, value, variableType } = variablesObj;
				if (key && value) {
					appUpdate?.values.push({
						key,
						value,
						isMasked: isMasked || false,
						variableType
					});
				}
			});
		} else {
			// Application Integration
			const integrationId = matchingScope.editInfo.applicationintegrationId;
			let appIntegrationUpdate = integrationId ? appIntegrationUpdateMap.get(integrationId) : null;

			if (!appIntegrationUpdate && integrationId) {
				appIntegrationUpdate = {
					editInfo: matchingScope.editInfo,
					values: []
				};

				appIntegrationUpdateMap.set(integrationId, appIntegrationUpdate);
			}

			// Push form variables inside project update
			variables.forEach((variablesObj: Variable) => {
				const { key, isMasked, value, variableType } = variablesObj;
				if (key && value) {
					appIntegrationUpdate?.values.push({
						key,
						value,
						isMasked: isMasked || false,
						variableType
					});
				}
			});
		}
	});

	const updatePromises: Promise<void>[] = [];

	Array.from(projectUpdateMap.values()).forEach(projectUpdate => {
		updatePromises.push(updateProjectVars(projectUpdate.editInfo, projectUpdate.values));
	});

	Array.from(envUpdateMap.values()).forEach(envUpdate => {
		updatePromises.push(updateEnvironmentVars(envUpdate.editInfo, envUpdate.values));
	});

	Array.from(appUpdateMap.values()).forEach(appUpdate => {
		updatePromises.push(updateDeploymentVars(appUpdate.editInfo, appUpdate.values));
	});

	Array.from(appIntegrationUpdateMap.values()).forEach(appIntegrationUpdate => {
		updatePromises.push(
			updateAppIntegrationVariables(appIntegrationUpdate.editInfo, appIntegrationUpdate.values)
		);
	});

	return updatePromises;
}

export function getUpdateMap() {
	return new Map<
		string,
		{
			editInfo: VariableEditInfo;
			values: Variable[];
		}
	>();
}
