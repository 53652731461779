<template>
	<Wrapper>
		<Section v-if="isLoadingEnvs">
			<CustomLoader />
		</Section>
		<CodePromotionSequence
			v-if="!isLoadingEnvs && containsPromotionEnvs"
			:promotion-seq-envs="orderedEnvsBypromotionSeq"
		/>
		<EnvWidgetGrid
			v-if="!isLoadingEnvs && envs && envs.length"
			:project="project"
			:envs="orderedEnvs"
			:create-new-environment="createNewEnvironment"
			@on-create-env-set="showCreateEnvSet = true"
		/>
		<Container
			v-if="!isLoadingEnvs && !envs.length"
			:gap="0"
			:padding="0"
			separator
			:grow="1"
			align="center"
		>
			<EnvSetEmptyView
				:proj-id="project.id"
				:create-env-with-radio="true"
				@toggle-slideout="showCreateEnvSet = true"
				@create-single-env="createNewEnvironment"
			/>
		</Container>
		<CreateEnvSetSlideout
			v-if="showCreateEnvSet"
			:proj-id="project.id"
			@close="showCreateEnvSet = false"
		/>
	</Wrapper>
</template>

<script lang="ts">
import { Container, Section, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { codePromotionStore } from "@/modules/code-promotion/code-promotion-store";
import CodePromotionSequence from "@/modules/code-promotion/components/CodePromotionSequence.vue";
import CreateEnvSetSlideout from "@/modules/env-create/components/CreateEnvSetSlideout.vue";
import { envCreateStore } from "@/modules/env-create/env-create-store";
import { EnvRoute } from "@/modules/env-list/components/env-widget/env-header/EnvWidgetHeader.vue";
import EnvWidgetGrid from "@/modules/env-list/components/env-widget/EnvWidgetGrid.vue";
import { envListStore } from "@/modules/env-list/env-list-store";
import { orgStore } from "@/modules/org/org-store";
import { project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { EnvCreateStepFlowService } from "@/services/storage-service";
import EnvSetEmptyView from "@/shared/components/create-env-set/EnvSetEmptyView.vue";
import CustomLoader from "@/shared/components/CustomLoader.vue";

export default defineComponent({
	name: "Environments",

	components: {
		CodePromotionSequence,
		CreateEnvSetSlideout,
		EnvSetEmptyView,
		EnvWidgetGrid,
		Container,
		Section,
		Wrapper,
		CustomLoader
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		},

		envs: {
			type: Array as PropType<environment[]>,
			required: true
		}
	},

	data: () => ({
		isLoadingEnvs: false,
		showCreateEnvSet: false
	}),

	computed: {
		projectEnvCount() {
			return this.envs.length;
		},

		promotionSequenceArr() {
			let [firstNode] = codePromotionStore.promotionSequences[this.project.id] ?? [];
			if (firstNode?.this !== undefined) {
				const arr = [firstNode.this];
				while (firstNode?.next && firstNode.next.length > 0) {
					[firstNode] = firstNode.next;
					if (firstNode?.this !== undefined) {
						arr.push(firstNode.this);
					}
				}
				return arr;
			}
			return [];
		},

		orderedEnvsBypromotionSeq() {
			const envs: environment[] = [];
			this.promotionSequenceArr.forEach(seq => {
				const env = this.envs.find(_env => _env.id === seq.id);
				if (env) {
					envs.push(env);
				}
			});
			return envs;
		},

		containsPromotionEnvs() {
			return this.orderedEnvsBypromotionSeq.some(env => env.classification);
		},

		orderedEnvs() {
			if (this.containsPromotionEnvs) {
				return this.orderedEnvsBypromotionSeq;
			}
			return this.envs;
		}
	},

	watch: {
		"project.id": {
			handler() {
				this.fetchProjectEnvs();
				this.getPromotionSequence();
			},

			immediate: true
		}
	},

	methods: {
		async fetchProjectEnvs() {
			if (this.envs.length === 0) {
				this.isLoadingEnvs = true;
			}

			await envListStore.GET_ENVS({
				orgId: this.project.orgId,
				projectId: this.project.id
			});

			this.isLoadingEnvs = false;
		},

		getPromotionSequence() {
			codePromotionStore.GET_PROJ_PROMOTION_SEQUENCE({
				orgId: this.project.orgId,
				projId: this.project.id
			});
		},

		createNewEnvironment() {
			// Navigate the user to the new environment creation loader screen
			this.$router
				.push({
					name: "createEnvLoaderScreen"
				})
				.then(async () => {
					// First we create a sandbox environment based on the config of the parent project
					const { activeOrgId } = orgStore;

					const newEnv = await envCreateStore.CREATE_NEW_ENV({
						orgId: activeOrgId as string,
						projId: this.project.id,
						name: `Environment-${this.projectEnvCount + 1}`,
						description: "",
						variables: [],
						metadata: {
							emojiObj: {
								native: "🌐"
							}
						},
						tfVersion: ""
					});

					// Show the steps popovers for newly created environment
					EnvCreateStepFlowService.toggleFlow({ isEnabled: true });

					// Then we assign the project creds to the new env if any
					// Create assign creds object
					await envListStore.GET_ENVS({
						orgId: this.project.orgId,
						projectId: this.project.id
					});

					// Route to the env detailed view
					const params: EnvRoute = {
						orgId: newEnv.orgId,
						projectId: newEnv.projId,
						envId: newEnv.id
					};

					this.$router.push({
						name: "envDetail",
						params
					});
				});
		}
	}
});
</script>
