/**
 * @fileoverview gRPC-Web generated client stub for event_dispatcher
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck

import grpcweb from 'grpc-web'
import events_events_pb from '../events/events_pb'
import event_dispatcher from './event_dispatcher_pb'


const grpc = {};
grpc.web = grpcweb;


// var events_events_pb = require('../events/events_pb.js')

// var google_api_annotations_pb = require('../google/api/annotations_pb.js')
const proto = {};
proto.event_dispatcher = event_dispatcher;

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.event_dispatcher.EventDispatcherClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.event_dispatcher.EventDispatcherPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.event_dispatcher.SubscribeEventRequest,
 *   !proto.event_dispatcher.SubscriptionEventResponse>}
 */
const methodDescriptor_EventDispatcher_SubscribeEvents = new grpc.web.MethodDescriptor(
  '/event_dispatcher.EventDispatcher/SubscribeEvents',
  grpc.web.MethodType.SERVER_STREAMING,
  events_events_pb.SubscribeEventRequest,
  events_events_pb.SubscriptionEventResponse,
  /**
   * @param {!proto.event_dispatcher.SubscribeEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  events_events_pb.SubscriptionEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.event_dispatcher.SubscribeEventRequest,
 *   !proto.event_dispatcher.SubscriptionEventResponse>}
 */
const methodInfo_EventDispatcher_SubscribeEvents = new grpc.web.AbstractClientBase.MethodInfo(
  events_events_pb.SubscriptionEventResponse,
  /**
   * @param {!proto.event_dispatcher.SubscribeEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  events_events_pb.SubscriptionEventResponse.deserializeBinary
);


/**
 * @param {!proto.event_dispatcher.SubscribeEventRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.event_dispatcher.SubscriptionEventResponse>}
 *     The XHR Node Readable Stream
 */
proto.event_dispatcher.EventDispatcherClient.prototype.subscribeEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/event_dispatcher.EventDispatcher/SubscribeEvents',
      request,
      metadata || {},
      methodDescriptor_EventDispatcher_SubscribeEvents);
};


/**
 * @param {!proto.event_dispatcher.SubscribeEventRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.event_dispatcher.SubscriptionEventResponse>}
 *     The XHR Node Readable Stream
 */
proto.event_dispatcher.EventDispatcherPromiseClient.prototype.subscribeEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/event_dispatcher.EventDispatcher/SubscribeEvents',
      request,
      metadata || {},
      methodDescriptor_EventDispatcher_SubscribeEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.event_dispatcher.PublishEventRequest,
 *   !proto.event_dispatcher.PublishEventResponse>}
 */
const methodDescriptor_EventDispatcher_PublishEvent = new grpc.web.MethodDescriptor(
  '/event_dispatcher.EventDispatcher/PublishEvent',
  grpc.web.MethodType.UNARY,
  events_events_pb.PublishEventRequest,
  events_events_pb.PublishEventResponse,
  /**
   * @param {!proto.event_dispatcher.PublishEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  events_events_pb.PublishEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.event_dispatcher.PublishEventRequest,
 *   !proto.event_dispatcher.PublishEventResponse>}
 */
const methodInfo_EventDispatcher_PublishEvent = new grpc.web.AbstractClientBase.MethodInfo(
  events_events_pb.PublishEventResponse,
  /**
   * @param {!proto.event_dispatcher.PublishEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  events_events_pb.PublishEventResponse.deserializeBinary
);


/**
 * @param {!proto.event_dispatcher.PublishEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.event_dispatcher.PublishEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.event_dispatcher.PublishEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.event_dispatcher.EventDispatcherClient.prototype.publishEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/event_dispatcher.EventDispatcher/PublishEvent',
      request,
      metadata || {},
      methodDescriptor_EventDispatcher_PublishEvent,
      callback);
};


/**
 * @param {!proto.event_dispatcher.PublishEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.event_dispatcher.PublishEventResponse>}
 *     A native promise that resolves to the response
 */
proto.event_dispatcher.EventDispatcherPromiseClient.prototype.publishEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/event_dispatcher.EventDispatcher/PublishEvent',
      request,
      metadata || {},
      methodDescriptor_EventDispatcher_PublishEvent);
};


export default proto.event_dispatcher;

