<template>
	<PipelineHistory
		:selected-filter="selectedFilter"
		:is-loading-history="isLoadingHistory"
		:history-list="historyList"
		@close-pipeline-history="closePipelineHistory"
		@select-pipeline-filter="selectPipelineFilter"
		@select-pipeline-history="selectPipelineHistory"
	/>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import PipelineHistory, {
	HistoryFilterOption,
	PipelineHistorySchema
} from "@/shared/components/pipelines/PipelineHistory.vue";
import {
	JOB_STATUS,
	JOB_STEP_STATUS,
	PIPELINE_UNFINISHED_JOB_STATUSES,
	PipelineJobStatus
} from "@/shared/pipeline-constants";
import {
	formatDateToRegularFormat,
	getArtifactSubtitle,
	getDuration,
	getElapsedTimeString
} from "@/utils";

import { applicationDeploymentStore } from "../application-deployment-store";

export default defineComponent({
	name: "AppPipelineHistory",

	components: {
		PipelineHistory
	},

	props: {
		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		envId: {
			type: String,
			required: true
		},

		depId: {
			type: String,
			required: true
		},

		selectedPipelineId: {
			type: String
		}
	},

	emits: ["setPipelineHistory"],

	data: () => ({
		isLoadingHistory: false,
		selectedFilter: null as HistoryFilterOption | null
	}),

	computed: {
		historyList(): PipelineHistorySchema[] {
			return getSortedAppJobs()
				.filter(appJob => {
					// Remove jobs that do not belong to the env or job type
					if (appJob.envId !== this.envId || appJob.depId !== this.depId) {
						return false;
					}

					// If no filters are selected then send all
					if (this.selectedFilter === null) {
						return true;
					}

					// Else filter the jobs
					const jobStatus = appJob.jobStatus as PipelineJobStatus;

					if (this.selectedFilter.id === "completed") {
						return jobStatus === JOB_STATUS.DONE;
					} else if (this.selectedFilter.id === "failed") {
						return jobStatus === JOB_STATUS.FAILED;
					} else if (this.selectedFilter.id === "cancelled") {
						return jobStatus === JOB_STATUS.CANCELLED;
					}

					return PIPELINE_UNFINISHED_JOB_STATUSES.includes(jobStatus);
				})
				.sort((jobA, jobB) => Number(jobB.createdAt ?? 0) - Number(jobA.createdAt ?? 0))
				.map((job): PipelineHistorySchema => {
					return {
						id: job.id,
						date: job.createdAt ? formatDateToRegularFormat(Number(job.createdAt) * 1000) : null,
						state: JOB_STEP_STATUS[job.jobStatus as PipelineJobStatus],
						isSelected: job.id === this.selectedPipelineId,
						duration: getDuration(Number(job.updatedAt ?? 0), Number(job.createdAt ?? 0)),
						triggeredBy: job.updatedBy,

						subTitle: getArtifactSubtitle(job.artifactOverride),
						elapsedTime: job.createdAt
							? getElapsedTimeString(Date.now(), Number(job.createdAt) * 1000)
							: null
					};
				});
		}
	},

	async mounted() {
		this.isLoadingHistory = true;

		await applicationDeploymentStore.GET_APP_DEPLOYMENT_HISTORY({
			envId: this.envId,
			orgId: this.orgId,
			projId: this.projectId,
			depId: this.depId,
			limit: 20
		});

		this.isLoadingHistory = false;
	},

	methods: {
		closePipelineHistory() {
			this.$emit("setPipelineHistory", false);
		},

		selectPipelineFilter(filter: HistoryFilterOption | null) {
			if (filter?.id === this.selectedFilter?.id) {
				this.selectedFilter = null;
			} else {
				this.selectedFilter = filter;
			}
		},

		selectPipelineHistory(pipelineId: string) {
			this.$router.replace({
				name: "stageViewAppWithJob",
				params: {
					orgId: this.orgId,
					projectId: this.projectId,
					envId: this.envId,
					depId: this.depId,
					// To preserve old urls
					action: "-",
					jobId: pipelineId
				}
			});
		}
	}
});

/**
 * Returns a sorted list of jobs
 */
function getSortedAppJobs() {
	return [...applicationDeploymentStore.allAppJobs].sort((job1, job2) => {
		return Number(job2.createdAt) - Number(job1.createdAt);
	});
}
</script>
