import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { orgStore } from "@/modules/org/org-store";
import { getStore } from "@/modules/store";
import ApiService from "@/services/api-service";
import { TokenService } from "@/services/storage-service";
import { captureError } from "@/utils";

import { credentialStore } from "../credentials/credential-store";
import { envCreateStore } from "../env-create/env-create-store";
import { envListStore } from "../env-list/env-list-store";
import { newEnvPipelineStore } from "../env-pipeline/env-pipeline-store";
import { policyListStore } from "../policy-list/policy-list-store";
import { projectStore } from "../project-list/project-store";
import routerModule from "../router/router-module";
import { userStore, USER_PROFILE } from "../user/user-store";

import { logoutUser, refreshToken, resetUserAndHeaders } from "./services/auth-service";

@Module({
	namespaced: true,
	dynamic: true,
	name: "auth",
	store: getStore()
})
class Auth extends VuexModule {
	refreshTokenPromise: ReturnType<typeof refreshToken> | null = null;

	@Mutation
	SET_REFRESH_TOKEN_PROMISE(promise: ReturnType<typeof refreshToken> | null) {
		this.refreshTokenPromise = promise;
	}

	@Action
	async LOGIN_SUCCESS() {
		const [user] = await Promise.allSettled([userStore.GET_USER(), orgStore.GET_SET_ORGS()]);

		if (user.status === "fulfilled") {
			return user.value;
		}

		// Throw an error if user could not be fetched
		throw user.reason;
	}

	@Action
	async USER_LOGOUT({ forceLogout, isRedirect }: { forceLogout: boolean; isRedirect: boolean }) {
		try {
			if (forceLogout) {
				await logoutUser();
			}
		} finally {
			// Reset axios headers and localStorage user sessions.
			resetUserAndHeaders();

			userStore.RESET_USER();
			localStorage.removeItem(USER_PROFILE);
			orgStore.SET_ACTIVE_ORG_ID(null);

			/**
			 * Redirecting user before reset store for project and other modules.
			 */
			if (isRedirect) {
				await routerModule.router.push({ name: "login" });
			}

			envListStore.RESET_ENVLIST();
			projectStore.RESET_PROJECTS();
			envCreateStore.RESET_ENV_EDIT();
			policyListStore.RESET_POLICIES();
			newEnvPipelineStore.RESET_ENV_PIPELINE_STORE();
			credentialStore.RESET_CREDENTIALS();
		}
	}

	@Action
	async REFRESH_TOKEN() {
		const refreshTokenStr = TokenService.getRefreshToken();
		const accessToken = TokenService.getToken();

		// If this is the first time the refreshToken has been called, make a request
		// otherwise return the same promise to the caller
		if (!this.refreshTokenPromise && refreshTokenStr && accessToken) {
			const promise = refreshToken({
				refreshToken: refreshTokenStr,
				accessToken
			});

			try {
				this.SET_REFRESH_TOKEN_PROMISE(promise);

				// Wait for the UserService.refreshToken() to resolve. On success set the token and clear promise
				// Clear the promise on error as well.
				await promise;

				this.SET_REFRESH_TOKEN_PROMISE(null);
				await this.LOGIN_SUCCESS();
			} catch (err) {
				this.SET_REFRESH_TOKEN_PROMISE(null);
				captureError(err);
				throw err;
			}
		}

		return await this.refreshTokenPromise;
	}

	@Action
	async AUTH_WITH_TOKEN({ token, userRefreshToken }: { token: string; userRefreshToken: string }) {
		TokenService.saveToken(token);
		TokenService.saveRefreshToken(userRefreshToken);
		ApiService.mount401Interceptor();
		await this.LOGIN_SUCCESS();
	}
}

const authStore = getModule(Auth);

export { authStore };
