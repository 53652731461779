import { Router, RouteRecordRaw } from "vue-router";

import AuthAfterStageWrapper from "@/modules/auth/views/AuthAfterStageWrapper.vue";
import Login from "@/modules/auth/views/Login.vue";
import LoginWithToken from "@/modules/auth/views/LoginWithToken.vue";
import ResetPassword from "@/modules/auth/views/ResetPassword.vue";
import SendResetPasswordLink from "@/modules/auth/views/SendResetPasswordLink.vue";
import Signup from "@/modules/auth/views/Signup.vue";
import VerificationEmail from "@/modules/auth/views/VerificationEmail.vue";
import VerifyUserEmail from "@/modules/auth/views/VerifyUserEmail.vue";

const authRoutes: RouteRecordRaw[] = [
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			transition: "trans-flip",
			title: "Login",
			onlyWhenLoggedOut: true,
			showCldCvrLogo: true,
			requiresAuth: false
		}
	},
	{
		path: "/signup",
		name: "signup",
		component: Signup,
		meta: {
			transition: "trans-flip",
			title: "Signup",
			onlyWhenLoggedOut: true,
			showCldCvrLogo: true,
			requiresAuth: false
		}
	},
	{
		path: "/auth-stage/:email/:componentName",
		name: "authAfterStage",
		component: AuthAfterStageWrapper,
		meta: {
			transition: "trans-flip",
			title: "Code Pipes",
			onlyWhenLoggedOut: true,
			showCldCvrLogo: true,
			requiresAuth: false
		}
	},
	{
		path: "/callback/verifyemail",
		name: "verify-email",
		component: VerifyUserEmail,
		meta: {
			transition: "trans-flip",
			forceLogout: true,
			title: "Verify email",
			onlyWhenLoggedOut: true,
			showCldCvrLogo: true,
			requiresAuth: false
		}
	},
	{
		path: "/verification-email",
		name: "verification-email",
		component: VerificationEmail,
		meta: {
			transition: "trans-flip",
			title: "Verification Email",
			onlyWhenLoggedOut: true,
			showCldCvrLogo: true,
			requiresAuth: false
		}
	},
	{
		path: "/reset-password",
		name: "reset-password",
		component: SendResetPasswordLink,
		meta: {
			transition: "trans-flip",
			title: "Reset Password",
			onlyWhenLoggedOut: true,
			showCldCvrLogo: true,
			requiresAuth: false
		}
	},
	{
		path: "/callback/forgotpassword",
		name: "callback-forgot-password",
		component: ResetPassword,
		meta: {
			transition: "trans-flip",
			forceLogout: true,
			title: "Reset Password",
			onlyWhenLoggedOut: true,
			showCldCvrLogo: true,
			requiresAuth: false
		}
	},
	{
		path: "/login-with-token",
		name: "loginWithToken",
		component: LoginWithToken
	}
];

export function registerAuthRoutes(router: Router) {
	authRoutes.forEach(route => {
		router.addRoute(route);
	});
}
