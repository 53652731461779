<template>
	<PipelineStageView
		v-if="nodes.length > 0"
		data-qa-env-dag-view
		:nodes="nodes"
		:edges="edges"
		title="Stages"
		@select-stage="stepClick"
	/>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { EnvPipelineViewedJob } from "@/modules/env-pipeline/env-pipeline-types";
import PipelineStageView from "@/shared/components/pipelines/PipelineStageView.vue";
import {
	JOB_STATUS,
	JOB_STEP_STATUS,
	PipelineEdge,
	PipelineNode
} from "@/shared/pipeline-constants";

export default defineComponent({
	name: "EnvDagView",
	components: { PipelineStageView },

	props: {
		edges: {
			type: Array as PropType<PipelineEdge[]>,
			required: true
		},

		nodes: {
			type: Array as PropType<PipelineNode[]>,
			required: true
		},

		job: {
			type: Object as PropType<EnvPipelineViewedJob>,
			required: true
		}
	},

	emits: ["stepChanged"],

	methods: {
		stepClick(nodeName: string) {
			const { steps } = this.job;
			const matchingStep = steps?.find(sObj => sObj.name === nodeName);
			const hasLogsOutput =
				(matchingStep?.logs?.length ?? 0) > 0 || (matchingStep?.outputs?.length ?? 0) > 0;

			if (!hasLogsOutput) {
				return;
			}

			const [firstNode] = this.nodes.filter(obj => obj.name === nodeName);

			if (!firstNode) {
				return;
			}

			const isStepComplete =
				firstNode.status === JOB_STEP_STATUS[JOB_STATUS.DONE] ||
				firstNode.status === JOB_STEP_STATUS[JOB_STATUS.CANCELLED] ||
				firstNode.status === JOB_STEP_STATUS[JOB_STATUS.FAILED];

			if (!isStepComplete) {
				return;
			}

			this.$emit("stepChanged", nodeName);
		}
	}
});
</script>
