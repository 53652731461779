import { Artifact, ContainerRegistryProvider, Provisioner } from "@/protocol/common";
import { Creds, credsType } from "@/protocol/identity";

export function getArtifactIcon(artifact: Artifact) {
	if (artifact.gitCode) {
		if (artifact.gitCode.repo.includes("github")) {
			return "p-github";
		}
		if (artifact.gitCode.repo.includes("gitlab")) {
			return "p-gitlab";
		}

		return "i-git";
	} else if (artifact.containerImage) {
		switch (artifact.containerImage.provider) {
			case ContainerRegistryProvider.dockerhub_container_registry:
				return "p-docker";
			case ContainerRegistryProvider.azure_container_registry:
				return "p-azure";
			case ContainerRegistryProvider.gitlab_container_registry:
				return "p-gitlab";
			case ContainerRegistryProvider.google_container_registry:
				return "p-gcp";
			case ContainerRegistryProvider.elastic_container_registry:
			case ContainerRegistryProvider.public_elastic_container_registry:
				return "p-aws-dark";
			default:
				return "i-box";
		}
	}

	return "i-box";
}

export function getCredentialIcon(credential: Creds) {
	switch (credential.type) {
		case credsType.credsType_github:
			return "p-github";
		case credsType.credsType_gcp:
			return "p-gcp";
		case credsType.credsType_aws:
			return "p-aws-dark";
		case credsType.credsType_dockergeneric:
		case credsType.credsType_dockerhub:
			return "p-docker";
		case credsType.credsType_gitlab:
			return "p-gitlab";
		case credsType.credsType_azure:
			return "p-azure";
	}

	return "i-box";
}

export function getProvisionerIcon(provisioner: Provisioner) {
	switch (provisioner) {
		case Provisioner.aws:
			return "p-aws-dark";
		case Provisioner.azure:
			return "p-azure";
		case Provisioner.gcp:
			return "p-gcp";
	}

	return "i-box";
}

export function getProvisionerName(provisioner: Provisioner) {
	switch (provisioner) {
		case Provisioner.aws:
			return "AWS";
		case Provisioner.azure:
			return "Azure";
		case Provisioner.gcp:
			return "GCP";
	}

	return null;
}

// Icons for Flow 2
export function getCredentialIcon2(credential: Creds) {
	switch (credential.type) {
		case credsType.credsType_github:
			return "p-github";
		case credsType.credsType_gcp:
			return "p-gcp";
		case credsType.credsType_aws:
			return "p-aws-dark";
		case credsType.credsType_dockergeneric:
		case credsType.credsType_dockerhub:
			return "p-docker";
		case credsType.credsType_gitlab:
			return "p-gitlab";
		case credsType.credsType_azure:
			return "p-azure";
	}

	return "i-box";
}
