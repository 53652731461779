let counter = 0;
export function getUniqueId() {
	return counter++;
}

export function getUniqueStringId() {
	return `id-${counter++}`;
}

export function simplifyUUID(uuid: string) {
	return uuid.substring(0, 6);
}
