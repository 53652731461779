<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		max-height="60vh"
		height="30vh"
		width="432px"
		data-qa-assigned-list-content
	>
		<Container :padding="0" :gap="0" direction="column" align="left top">
			<!-- PopOver header -->
			<Header v-if="!mountedInsideModal">
				<Container align="left center" :padding="0" :grow="1">
					<Container :gap="12" :padding="0">
						<Icon
							:name="getTypeIcon(cred.type)"
							type="filled"
							:effects="false"
							data-qa="assigned-list-modal-cred-icon"
						/>
						<Typography
							v-tooltip="{
								content: cred.name,
								container: 'div.flow-layout',
								placement: 'bottom-end'
							}"
							type="h4"
							color="dark"
							overflow="ellipsis"
							data-qa="assigned-list-modal-cred-title"
						>
							{{ cred.name.length < 24 ? cred.name : cred.name.substring(0, 22) + "..." }}
							<Typography
								v-tooltip="{
									content: cred.name,
									container: 'div.flow-layout',
									placement: 'bottom-end'
								}"
								type="h4"
								color="dark"
								overflow="ellipsis"
								data-qa="assigned-list-modal-cred-title"
								inline
							>
								is assigned to
							</Typography>
						</Typography>
					</Container>
					<Container :padding="0" align="right center" :grow="1">
						<Icon
							data-qa="assigned-list-modal-close-btn"
							name="i-close"
							type="filled"
							size="x-small"
							@click="$emit('close')"
						/>
					</Container>
				</Container>
			</Header>
			<Container v-if="isLoading" :gap="0" :padding="0" overflow="auto">
				<Tabs>
					<Tab>
						<Shimmer />
					</Tab>
					<template #content>
						<Container :gap="20" :padding="12" direction="column">
							<Shimmer height="18px" />
							<Shimmer height="18px" />
							<Shimmer height="18px" />
						</Container>
					</template>
				</Tabs>
			</Container>
			<Container v-else :gap="0" :padding="0" overflow="auto">
				<Tabs>
					<Tab
						v-if="projectCount"
						:selected="localSelectedTab === 'projs'"
						data-qa-assigned-list-proj-tab
						@click="localSelectedTab = 'projs'"
					>
						<Container :padding="0" :gap="8">
							<Typography
								:weight="localSelectedTab === 'projs' ? 'medium' : 'thin'"
								type="p1"
								:color="localSelectedTab === 'projs' ? 'dark' : 'default'"
								data-qa="assigned-list-modal-project-title"
								>Project{{ projectCount > 1 ? "s" : "" }}</Typography
							>
							<Tag size="small" shape="rounded" data-qa="assigned-list-modal-project-count">{{
								projectCount
							}}</Tag>
						</Container>
					</Tab>
					<Tab
						v-if="envCount"
						:selected="localSelectedTab === 'envs'"
						data-qa-assigned-list-envs-tab
						@click="localSelectedTab = 'envs'"
					>
						<Container :padding="0" :gap="8">
							<Typography
								:weight="localSelectedTab === 'envs' ? 'medium' : 'thin'"
								type="p1"
								:color="localSelectedTab === 'envs' ? 'dark' : 'default'"
								data-qa="assigned-list-modal-env-title"
								>Environment{{ envCount > 1 ? "s" : "" }}</Typography
							>
							<Tag size="small" shape="rounded" data-qa="assigned-list-modal-env-count">{{
								envCount
							}}</Tag>
						</Container>
					</Tab>
					<Tab
						v-if="appCount"
						:selected="localSelectedTab === 'apps'"
						data-qa-assigned-list-apps-tab
						@click="localSelectedTab = 'apps'"
					>
						<Container :padding="0" :gap="8">
							<Typography
								:weight="localSelectedTab === 'apps' ? 'medium' : 'thin'"
								type="p1"
								:color="localSelectedTab === 'apps' ? 'dark' : 'default'"
								>App{{ appCount > 1 ? "s" : "" }}</Typography
							>
							<Tag size="small" shape="rounded">{{ appCount }}</Tag>
						</Container>
					</Tab>
					<template #content>
						<Container
							v-if="localSelectedTab === 'projs'"
							:shrink="0"
							direction="column"
							overflow="auto"
							padding="12px 0px"
							align="left top"
							:gap="0"
							class="flex-shrink-0"
							data-qa-assigned-list-proj-content
						>
							<Slab v-for="proj in projects" :key="proj.id" align-items="start" :effects="true">
								<template #primary-action>
									<Pictogram size="s" shape="default" state="avatar" :label="proj.name" />
								</template>
								<Container direction="column" :gap="4" :padding="0">
									<Typography
										type="h4"
										color="dark"
										weight="thin"
										data-qa-assigned-list-proj-name
										>{{ proj.name }}</Typography
									>
									<Typography
										v-if="proj.updatedAt"
										type="p2"
										color="light"
										weight="thin"
										data-qa-assigned-list-proj-updatedAt
										>Last updated {{ humanReadableTime(proj.updatedAt) }}</Typography
									>
								</Container>
								<template #secondary-actions>
									<Icon
										name="i-view"
										size="20px"
										type="filled"
										class="show-on-hover"
										state="primary"
										:data-qa="'assigned-list-project-eye-btn' + proj.id"
										@click="openProject(proj)"
									/>
								</template>
							</Slab>
						</Container>
						<Container
							v-if="localSelectedTab === 'envs'"
							:shrink="0"
							direction="column"
							overflow="auto"
							padding="12px 0px"
							align="left top"
							:gap="0"
							class="flex-shrink-0"
							data-qa-assigned-list-envs-content
						>
							<Slab v-for="env in envs" :key="env.id" align-items="start" :effects="true">
								<template #primary-action>
									<Pictogram
										size="s"
										shape="hexagon"
										state="avatar"
										:label="getShortCode(env.name)"
									>
									</Pictogram>
								</template>
								<Container direction="column" :gap="4" :padding="0">
									<Typography
										type="h4"
										color="dark"
										weight="thin"
										data-qa="assigned-list-env-name"
										>{{ env.name }}</Typography
									>
									<Typography
										v-if="env.updatedAt"
										type="p2"
										color="light"
										weight="thin"
										data-qa="assigned-list-env-last-updated"
										>Last updated {{ humanReadableTime(env.updatedAt) }}</Typography
									>
								</Container>
								<template #secondary-actions>
									<Icon
										name="i-view"
										size="20px"
										type="filled"
										class="show-on-hover"
										state="primary"
										:data-qa="'assigned-list-env-eye-btn' + env.id"
										@click="openEnv(env)"
									/>
								</template>
							</Slab>
						</Container>
						<Container
							v-if="localSelectedTab === 'apps'"
							:shrink="0"
							direction="column"
							overflow="auto"
							padding="12px 0px"
							align="left top"
							:gap="0"
							class="flex-shrink-0"
							data-qa-assigned-list-apps-content
						>
							<Slab v-for="app in apps" :key="app.id" align-items="start" :effects="true">
								<template #primary-action>
									<Pictogram
										size="s"
										shape="squircle"
										state="default"
										:label="getShortCode(app.name)"
									/>
								</template>
								<Container direction="column" :gap="4" :padding="0">
									<Typography
										type="h4"
										color="dark"
										weight="thin"
										data-qa="assigned-list-env-name"
										>{{ app.name }}</Typography
									>
									<Typography
										v-if="app.updatedAt"
										type="p2"
										color="light"
										weight="thin"
										data-qa="assigned-list-env-last-updated"
										>Last updated {{ humanReadableTime(app.updatedAt) }}</Typography
									>
								</Container>
								<template #secondary-actions>
									<Icon
										name="i-view"
										size="20px"
										type="filled"
										class="show-on-hover"
										state="primary"
										:data-qa="'assigned-list-env-eye-btn' + app.id"
										@click="openApp(app)"
									/>
								</template>
							</Slab>
						</Container>
					</template>
				</Tabs>
			</Container>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Container,
	Header,
	Icon,
	Pictogram,
	Shimmer,
	Slab,
	Tab,
	Tabs,
	Tag,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { CREDENTIAL_TYPE_ICONS } from "@/modules/credentials/constants";
import {
	CredWithAssignedEntities,
	assignedEnvironmentId,
	assignedApplicationId
} from "@/modules/credentials/store-types";
import { envListStore } from "@/modules/env-list/env-list-store";
import { projectStore } from "@/modules/project-list/project-store";
import { app as appProto } from "@/protocol/app";
import { project as projectProto } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { getRelativeTime, getShortCode } from "@/utils";

export type SelectedTab = "projs" | "envs" | "apps";

export default defineComponent({
	name: "AssignedToEntityModal",

	components: {
		Typography,
		Container,
		Pictogram,
		Wrapper,
		Shimmer,
		Header,
		Icon,
		Slab,
		Tabs,
		Tag,
		Tab
	},

	props: {
		cred: {
			type: Object as PropType<CredWithAssignedEntities>,
			required: true
		},

		mountedInsideModal: {
			type: Boolean,
			default: false
		},

		forceSelectTab: {
			type: String as PropType<SelectedTab | null>,
			default: null
		}
	},

	data: () => ({
		localSelectedTab: "projs" as SelectedTab,
		projects: [] as projectProto[],
		envs: [] as environment[],
		apps: [] as appProto[],
		isLoading: false
	}),

	computed: {
		envCount() {
			return this.cred.stats.environments.length;
		},

		appCount() {
			return this.cred.stats.apps.length;
		},

		projectCount() {
			return this.cred.stats.projects.length;
		}
	},

	mounted() {
		this.fetchAssignedEntities();
	},

	methods: {
		getTypeIcon(type?: keyof typeof CREDENTIAL_TYPE_ICONS) {
			return type ? CREDENTIAL_TYPE_ICONS[type] : "";
		},

		setDefaultSelectedTab() {
			if (this.forceSelectTab) {
				this.localSelectedTab = this.forceSelectTab;
				return;
			}
			const { projects, environments, apps } = this.cred.stats;

			const hasProjects = projects.length;
			const hasEnvs = environments.length;
			const hasApps = apps.length;

			if (hasProjects) {
				this.localSelectedTab = "projs";
			} else if (hasEnvs) {
				this.localSelectedTab = "envs";
			} else if (hasApps) {
				this.localSelectedTab = "apps";
			}
		},

		async fetchAssignedEntities() {
			this.isLoading = true;
			this.setDefaultSelectedTab();

			const { projects, environments, apps: appstats } = this.cred.stats;

			const projectIds = projects.map(proj => proj.id);
			this.projects = Object.values(projectStore.projects).filter(proj =>
				projectIds.includes(proj.id)
			);

			const [envs, apps] = await Promise.all([
				this.getEnvsFromIds(environments),
				this.getAppsFromIds(appstats)
			]);

			this.envs = envs;
			this.apps = apps;
			this.isLoading = false;
		},

		humanReadableTime(updatedAt: string) {
			return getRelativeTime(updatedAt);
		},

		getEnvsFromIds(envIds: assignedEnvironmentId[]) {
			return Promise.all(
				envIds.map(({ orgId, projId, id }) =>
					envListStore.GET_ENV_BY_ID({ orgId, projectId: projId, envId: id })
				)
			);
		},

		getAppsFromIds(appIds: assignedApplicationId[]) {
			return Promise.all(
				appIds.map(({ orgId, projId, id }) =>
					applicationStore.GET_APP_BY_ID({
						orgId,
						projectId: projId,
						appId: id
					})
				)
			);
		},

		openProject(project: projectProto) {
			this.$router.push({
				name: "projectListWithProject",
				params: { orgId: project.orgId, projectId: project.id }
			});
		},

		openEnv(env: environment) {
			this.$router.push({
				name: "envDetail",
				params: { orgId: env.orgId, projectId: env.projId, envId: env.id }
			});
		},

		openApp(application: appProto) {
			this.$router.push({
				name: "projectListWithApp",
				params: {
					orgId: application.orgId,
					projectId: application.projId,
					appId: application.id
				}
			});
		},

		getShortCode(name: string) {
			return getShortCode(name);
		}
	}
});
</script>
