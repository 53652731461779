<template>
	<Wrapper
		:width="cardStyle === 'detailed' ? '250px' : '50px'"
		height="50px"
		background="gray-600"
		border-radius="4px"
	>
		<InfoPopover>
			<Card
				v-if="cardStyle === 'detailed'"
				type="outlined"
				:clickable="true"
				data-qa-policy-selector
				@click="$emit('onPolicyClick')"
			>
				<Container :padding="8" :gap="12" align="left top">
					<Icon name="i-shield" type="filled" state="warning" size="small" :effects="false" />
					<Container :padding="0" :grow="1" :gap="8" align="space-between center">
						<Container :padding="0" :gap="4" direction="column">
							<Typography type="h6" color="light" transform="uppercase" weight="regular"
								>Default Policy</Typography
							>
							<Typography type="p2" color="dark">{{ selectedPolicy?.name }}</Typography>
						</Container>
						<Icon name="i-question-filled" state="light" type="filled" size="20px" />
					</Container>
				</Container>
			</Card>
			<EntityCard v-else icon="i-shield" @click="$emit('onPolicyClick')" />
			<template #body>
				<Container :padding="0" direction="column" :gap="8">
					<Typography type="h5" color="dark">Control Gate</Typography>
					<Typography v-if="inheritedPolicy" type="p2" color="dark">
						{{ inheritedPolicy?.name }} is inherited from project
					</Typography>
					<Typography v-else type="p2" color="dark">{{ selectedPolicy?.name }}</Typography>
					<Typography
						type="h5"
						color="primary"
						link
						@click="openLink('https://docs.codepipes.io/docs/policies')"
						>Learn more</Typography
					>
				</Container>
			</template>
		</InfoPopover>
	</Wrapper>
</template>
<script lang="ts">
import { Card, Container, Icon, Typography, InfoPopover, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { PolicySet } from "@/protocol/validator";
import EntityCard from "@/shared/components/code-pipes/EntityCard.vue";

export default defineComponent({
	name: "AssignedPolicySetCard",

	components: {
		InfoPopover,
		Typography,
		EntityCard,
		Container,
		Wrapper,
		Icon,
		Card
	},

	props: {
		selectedPolicy: {
			type: Object as PropType<PolicySet | null>
		},

		cardStyle: {
			type: String as PropType<"detailed" | "icon">,
			required: true
		},

		inheritedPolicy: {
			type: Object as PropType<PolicySet | null>
		}
	},

	methods: {
		openLink(link: string) {
			window.open(link, "_blank");
		}
	}
});
</script>
