<template>
	<slot />
	<f-popover
		:open="openAddNewAppPopover"
		padding="none"
		gap="x-large"
		direction="column"
		align="middle-center"
		:style="{
			width: popoverWidth
		}"
	>
		<InfoPopover
			v-if="showInfo && currentStep"
			:title="infoText?.title ?? ''"
			:show-overlay="false"
			:is-open="showInfo"
			placement="left-start"
			:target="currentStep?.target ?? ''"
			@close="toggleInfo"
		>
			<template v-for="text in infoText?.paragraphs" :key="text">
				<f-div padding="none none medium none" height="hug-content" gap="medium">
					<f-text variant="para" size="medium" weight="regular" data-qa-welcome-popover-text>
						{{ text }}
					</f-text>
				</f-div>
			</template>
			<Typography
				type="p2"
				color="primary"
				:inline="true"
				:link="true"
				:data-qa-welcome-popover-link="infoText?.link?.label ?? ''"
				@click="openLink(infoText?.link?.url)"
			>
				{{ infoText?.link?.label }}
			</Typography>
		</InfoPopover>
		<ChecklistSteps
			v-if="selectedStep === checklistStateEnum.NOT_SELECTED"
			:checklist-flags="checklistFlags"
			:popover-content="popoverContent"
			:steps="steps"
			@step-clicked="stepClicked"
			@open-welcome="toggleInfo"
			@close-flow="quitFlow"
		/>
		<AppFlowStepComponents
			v-if="selectedStep !== checklistStateEnum.NOT_SELECTED"
			:is-oauth-complete="isOauthComplete"
			@toggle-info="toggleInfo"
			@close="quitFlow"
			@new-app-created="
				isOauthComplete = false;
				showInfo = true;
			"
		/>
	</f-popover>
</template>
<script lang="ts">
import { Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import ChecklistSteps from "@/modules/org/ChecklistSteps.vue";
import {
	AppFirstFlowFormStorageService,
	GithubOauthStorageService
} from "@/services/storage-service";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";

import { applicationStore } from "../application-store";
import AppFlowStepComponents from "../components/AppFlowStepComponents.vue";

import {
	APP_WIZARD_FLOW_INFO_POPOVERS,
	APP_WIZARD_FLOW_CHECKLIST_STEPS,
	AppWizardChecklistEnum
} from "./appWizardFlowSteps";

export default defineComponent({
	name: "ApplicationWizardWrapper",

	components: {
		InfoPopover,
		ChecklistSteps,
		AppFlowStepComponents,
		Typography
	},

	props: {
		openAddNewAppPopover: {
			type: Boolean,
			default: false
		}
	},

	emits: ["toggle-add-app", "quit-flow"],

	data: () => ({
		stepsMeta: APP_WIZARD_FLOW_INFO_POPOVERS,
		defaultChecklistSteps: APP_WIZARD_FLOW_CHECKLIST_STEPS,
		checklistStateEnum: AppWizardChecklistEnum,

		showInfo: false,
		isOauthComplete: false,

		popperTarget: "checklistMain",

		popoverContent: {
			title: "Add and deploy application",
			desc: ""
		}
	}),

	computed: {
		popoverWidth() {
			const buildStepWidth =
				this.selectedStep === this.checklistStateEnum.SETUP_BUILD ? "600px" : "";
			return this.selectedStep === this.checklistStateEnum.SELECT_INFRA ? "750px" : buildStepWidth;
		},

		selectedStep() {
			return applicationStore.currAppWizardStep;
		},

		currentStep() {
			return this.stepsMeta.find(step => step.stepNo === this.selectedStep);
		},

		infoText() {
			return this.currentStep?.infoContent;
		},

		checklistFlags() {
			return {};
		},

		/**
		 * @description - This is the main checklist steps that are rendered in the popover
		 * @returns {ChecklistStep[]}
		 */
		steps() {
			return [...this.defaultChecklistSteps].slice().map(step => {
				return {
					...step,
					disabled: step.stepNo !== 1 // @todo - Add remaining logic based on user metadata API
				};
			});
		}
	},

	mounted() {
		this.githubModalObserver();
	},

	methods: {
		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === "appWizardFlow") {
				this.isOauthComplete = true;
				applicationStore.UPDATE_APP_WIZARD_STEP(AppWizardChecklistEnum.CONNECT_APP);
				this.$emit("toggle-add-app");
			}
		},

		toggleInfo() {
			this.showInfo = !this.showInfo;
		},

		stepClicked(num: AppWizardChecklistEnum) {
			applicationStore.UPDATE_APP_WIZARD_STEP(num);
			this.showInfo = true;
			setTimeout(() => {
				this.popperTarget = this.currentStep?.target ?? "#";
			}, 10);
		},

		quitFlow() {
			applicationStore.UPDATE_APP_WIZARD_STEP(AppWizardChecklistEnum.NOT_SELECTED);
			AppFirstFlowFormStorageService.removeAppFirstFlowForm();
			GithubOauthStorageService.removeGithubUserOauth();
			this.isOauthComplete = false;
			this.$emit("quit-flow");
		},

		openLink(link: string | null | undefined) {
			if (link) {
				window.open(link, "_blank");
			}
		}
	}
});
</script>
