import { infraService, success } from "@/protocol/infra";
import ApiService from "@/services/api-service";
import { INFRA_API } from "@/shared/constants";

export const deleteEnv: infraService["deleteEnv"] = async function ({ orgId, projId, id }) {
	const response = await ApiService.delete<success>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
