<template>
	<AuthWrapper
		header="Sign Up"
		alternate-auth-text="Or, sign up with"
		:show-back-icon="true"
		@back-click="redirectMixin({ routeName: 'login' })"
	>
		<SignUpEmailPasswordForm />
		<template #alt-auth>
			<WrapperSignInIdentityProvider />
		</template>
	</AuthWrapper>
</template>

<script>
import { defineComponent } from "vue";

import SignUpEmailPasswordForm from "@/modules/auth/components/SignUpEmailPasswordForm.vue";
import WrapperSignInIdentityProvider from "@/modules/auth/components/WrapperSignInIdentityProvider.vue";
import AuthWrapper from "@/modules/auth/views/AuthWrapper.vue";
import routerMixin from "@/shared/mixins/routerMixin";

export default defineComponent({
	name: "Signup",

	components: {
		WrapperSignInIdentityProvider,
		SignUpEmailPasswordForm,
		AuthWrapper
	},

	mixins: [routerMixin]
});
</script>
