<template>
	<PopOver :open="open" placement="left" @overlay-click="open = false">
		<Icon
			v-tooltip="'Edit Module'"
			:class="open ? '' : 'show-on-hover'"
			data-qa-edit-pipeline-module
			name="i-edit"
			size="small"
			type="filled"
			state="default"
			@click="open = !open"
		/>
		<template #content>
			<Wrapper
				border-radius="4px"
				background="element-light"
				width="480px"
				max-height="80vh"
				overflow="visible"
			>
				<Header>
					<Typography type="h4" color="dark"> {{ getHeaderText }} </Typography>
					<Container :padding="0" :grow="1" align="right center">
						<Icon
							size="x-small"
							type="filled"
							name="i-close"
							data-qa-close-connect-terraform-icon
							@click="validateAndCloseModal"
						/>
					</Container>
				</Header>
				<ClosePopoverConfirmationWarning
					ref="closeConfirmation"
					:initial-form-values="initialFormValues"
					:form-values="formValues"
					@force-close="closeForm"
				/>
				<Container
					:padding="16"
					direction="column"
					:gap="0"
					overflow="auto"
					align="left top"
					class="flow-add-scrollbar"
				>
					<f-form-builder
						ref="gitForm"
						data-qa-artifact-override-form
						:field.prop="formFields"
						:values.prop="formValues"
						@input="handleInput"
						@state-change="formState = $event.detail"
					/>
				</Container>

				<Footer>
					<Button
						state="full"
						type="success"
						data-qa-connect-terraform-submit-btn
						data-qa-terraform-next-connect-cloud-account-btn
						@click="updateArtifact"
					>
						Submit
					</Button>
				</Footer>
			</Wrapper>
		</template>
	</PopOver>
</template>
<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	PopOver,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import {
	ArtifactType,
	ContainerRevisionOverride,
	GitRevisionOverride,
	GitRevisionType
} from "@/protocol/common";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";

import { ArtifactMeta } from "../application-integration-store";

type FormValues = { __isGitCode__: boolean } & (GitRevisionOverride | ContainerRevisionOverride);

export default defineComponent({
	name: "ArtifactOverrideForm",

	components: {
		Icon,
		Typography,
		Container,
		ClosePopoverConfirmationWarning,
		Button,
		Footer,
		Header,
		Wrapper,
		PopOver
	},

	props: {
		artifactMeta: {
			type: Object as PropType<ArtifactMeta>,
			required: true
		}
	},

	data(thisValue) {
		let formValues: FormValues;

		const artifactMeta = thisValue.artifactMeta.data;

		if (artifactMeta.gitCode) {
			const gitRevision = artifactMeta.gitCode;
			formValues = {
				__isGitCode__: true,
				type: gitRevision.type,
				identifier: gitRevision.identifier
			};
		} else if (artifactMeta.containerImage) {
			const containerRevision = artifactMeta.containerImage;
			formValues = {
				__isGitCode__: false,
				reference: containerRevision.reference,
				digest: containerRevision.digest
			};
		} else {
			throw new Error(`Artifact type is not supported: ${artifactMeta.type}}`);
		}

		return {
			formState: null as FormBuilderState | null,
			open: false,
			formValues,
			initialFormValues: { ...formValues }
		};
	},

	computed: {
		artifactType(): ArtifactType | undefined {
			return this.artifactMeta.data.type;
		},

		getHeaderText() {
			return this.artifactType === ArtifactType.GitCode
				? "Edit app source code repo"
				: "Edit container image";
		},

		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					__isGitCode__: {
						type: "hidden"
					},

					type: {
						type: "select",
						qaId: "revisionType",
						label: { title: "Revision type" },
						placeholder: "Select revision type",
						options: [GitRevisionType.branch, GitRevisionType.tag, GitRevisionType.commit],
						validationRules: [{ name: "required" }],
						//@ts-expect-error
						showWhen(values: FormValues) {
							return values.__isGitCode__;
						}
					},

					identifier: {
						type: "text",
						qaId: "revisionIdentifier",
						placeholder: "Enter revision identifier",
						label: { title: "Revision identifier" },
						validationRules: [{ name: "required" }],
						//@ts-expect-error
						showWhen(values: FormValues) {
							return values.__isGitCode__;
						}
					},

					reference: {
						type: "text",
						qaId: "containerReference",
						placeholder: "Enter container tag",
						label: { title: "Container tag" },
						validationRules: [{ name: "required" }],
						//@ts-expect-error
						showWhen(values: FormValues) {
							return !values.__isGitCode__;
						}
					}
				}
			};
		}
	},

	methods: {
		validateAndCloseModal() {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				this.open = false;
			}
			return;
		},

		handleInput(event: CustomEvent<FormValues>) {
			this.formValues = event.detail;
		},

		updateArtifact() {
			(this.$refs.gitForm as InstanceType<typeof FFormBuilder>).submit();

			if (!this.formState?.isValid) {
				return;
			}

			this.$emit("update", { values: this.formValues, type: this.artifactType });
			this.open = false;
		},

		closeForm() {
			this.open = false;

			// On close form reset the form values to initial values
			if (this.artifactMeta.data.gitCode) {
				const gitRevision = this.artifactMeta.data.gitCode;
				this.formValues = {
					__isGitCode__: true,
					type: gitRevision.type,
					identifier: gitRevision.identifier
				};
			} else if (this.artifactMeta.data.containerImage) {
				const containerRevision = this.artifactMeta.data.containerImage;
				this.formValues = {
					__isGitCode__: false,
					reference: containerRevision.reference,
					digest: containerRevision.digest
				};
			}
		}
	}
});
</script>
