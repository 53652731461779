<script setup lang="ts">
import { FPictogramState } from "@cldcvr/flow-core";
import { EmptyState } from "@cldcvr/flow-vue3";
import { PropType, computed, ref, toRefs } from "vue";

import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import { triggerEvent, webhook } from "@/protocol/externalEvents";
import { actionType, environment } from "@/protocol/infra";
import {
	ACTION_TYPE_ICONS,
	EnvironmentActionTextMap
} from "@/shared/components/environment/EnvironmentActionConstants";
import { simplifyUUID } from "@/utils";

import EnvWebHookDeleteModal from "./EnvWebHookDeleteModal.vue";
import EnvWebHookModal from "./EnvWebHookModal.vue";

const props = defineProps({
	env: { type: Object as PropType<environment>, required: true }
});

const { env } = toRefs(props);

const webHooks = computed(() => {
	return webHooksStore.webHooksList[env.value.id];
});

const missingGitDetails = computed(() => {
	return !env.value.revision;
});

const editedHook = ref<webhook | null>(null);

const webhookIdCopied = ref<string | null>(null);

const modalState = ref<"add-webhook" | "edit-webook" | "delete-webhook" | null>(null);

const popoverTarget = computed(() => {
	if (!webHooks.value || webHooks.value.length === 0) {
		return `[data-qa-empty-webhooks] [data-qa-empty-state-action]`;
	}

	if (editedHook.value) {
		return `[data-qa-webhook-id='${editedHook.value.id}']`;
	}

	return "[data-qa-create-web-hook]";
});

function getTriggerFromWebHook(hook: webhook) {
	if (hook.customTrigger) {
		return ` pipeline webhook URL #${simplifyUUID(hook.id!)}`;
	}

	if (hook.githubTrigger?.triggerEvent === triggerEvent.pullReq) {
		return " on pull request to branch ";
	}

	if (hook.githubTrigger?.triggerEvent === triggerEvent.pushToTag) {
		return " on push to tag ";
	}

	return " on push to branch ";
}

function getIconFromWebHook(hook: webhook) {
	const action = hook.action as actionType;
	return ACTION_TYPE_ICONS[action];
}

function getStateFromWebhook(hook: webhook): FPictogramState {
	const action = hook.action as actionType;

	switch (action) {
		case actionType.validateOnly:
			return "primary";
		case actionType.ValidateAndApply:
			return "success";
		case actionType.destroy:
			return "danger";
	}

	return "default";
}

function getActionFromWebHook(hook: webhook) {
	const action = hook.action as actionType;
	return EnvironmentActionTextMap[action];
}

function editWebHook(hook: webhook) {
	editedHook.value = hook;
	modalState.value = "edit-webook";
}

function addWebHook() {
	editedHook.value = null;
	modalState.value = "add-webhook";
}

function deleteWebHook(hook: webhook) {
	editedHook.value = hook;
	modalState.value = "delete-webhook";
}

function closeModal() {
	editedHook.value = null;
	modalState.value = null;
}

async function copyWebhookUrl(hook: webhook) {
	if (hook.customTrigger?.hookURL) {
		await navigator.clipboard.writeText(hook.customTrigger.hookURL);
		webhookIdCopied.value = hook.id!;
		await new Promise(resolve => setTimeout(resolve, 4000));
		webhookIdCopied.value = null;
	}
}

defineEmits(["open-terraform-dialog"]);
</script>

<template>
	<f-div direction="column" height="fill-container" align="top-left" padding="large">
		<!-- VN-4382 - f-form-builder and empty state do not play well together with z-index so we need to set it's width -->
		<EmptyState
			v-if="!webHooks || webHooks.length === 0"
			:style="{ zIndex: 200 }"
			data-qa-empty-webhooks
			action="Add webhook"
			message="No webhooks present"
			subtitle="No webhooks have been added to the environment yet."
			icon="i-webhook"
			@actions="addWebHook"
		/>

		<EmptyState
			v-else-if="missingGitDetails && webHooks.length < 1"
			data-qa-no-git-details
			action="Connect terraform repository"
			message="No repository connected"
			subtitle="You need to connect your terraform repository to be able to add webhooks to this environment."
			icon="i-webhook"
			@actions="$emit('open-terraform-dialog')"
		/>

		<f-div v-else align="middle-left" width="hug-content" height="hug-content" gap="medium">
			<f-text size="small" variant="heading" data-qa-web-hook-title>Webhooks</f-text>
			<f-icon-button
				data-qa-create-web-hook
				size="x-small"
				icon="i-plus"
				tooltip="Add webhooks"
				@click="addWebHook"
			></f-icon-button>
		</f-div>

		<f-spacer size="small"></f-spacer>

		<template v-for="webHook in webHooks" :key="webHook.id">
			<f-div
				class="show-on-hover-parent"
				height="hug-content"
				gap="large"
				width="fill-container"
				:data-qa-webhook-id="webHook.id"
				data-qa-webhook-list-item
				align="middle-left"
				padding="large none"
				max-width="500px"
			>
				<f-div gap="medium" width="hug-content" align="middle-left">
					<f-pictogram
						size="small"
						:source="getIconFromWebHook(webHook)"
						:state="getStateFromWebhook(webHook)"
					></f-pictogram>
					<f-text inline state="secondary">
						<f-text state="default" inline data-qa-webhook-trigger-action>{{
							getActionFromWebHook(webHook)
						}}</f-text>
						{{ getTriggerFromWebHook(webHook) }}
						<f-icon
							v-if="webHook.customTrigger"
							source="i-info-fill"
							state="subtle"
							:tooltip="webHook.customTrigger?.hookURL"
						></f-icon>
						<f-text
							v-if="webHook.githubTrigger"
							inline
							state="primary"
							weight="bold"
							data-qa-webhook-trigger-entity-name
							>{{ webHook.githubTrigger?.identifier }}</f-text
						>
					</f-text>
				</f-div>

				<f-div align="middle-right" gap="medium" class="show-on-hover">
					<f-icon
						v-if="'customTrigger' in webHook && webhookIdCopied !== webHook.id"
						state="default"
						source="i-copy"
						tooltip="Copy webhook URL"
						clickable
						data-qa-copy-webhook-url
						@click="copyWebhookUrl(webHook)"
					></f-icon>

					<f-icon
						v-if="'customTrigger' in webHook && webhookIdCopied === webHook.id"
						state="primary"
						source="i-tick"
						tooltip="Copied"
						data-qa-webhook-copied-confirmation
					></f-icon>

					<f-icon
						v-if="webHook.githubTrigger"
						clickable
						source="i-edit"
						data-qa-edit-webhook
						@click="editWebHook(webHook)"
					></f-icon>

					<f-icon
						clickable
						source="i-delete"
						state="danger"
						data-qa-delete-webhook
						@click="deleteWebHook(webHook)"
					></f-icon>
				</f-div>
			</f-div>
			<f-divider></f-divider>
		</template>
	</f-div>

	<f-popover
		:target="popoverTarget"
		:open="modalState === 'add-webhook' || modalState === 'edit-webook'"
	>
		<!-- f-popover renders children by default, this causes issues with data in the modal -->
		<EnvWebHookModal
			v-if="modalState === 'add-webhook' || modalState === 'edit-webook'"
			:env="env"
			:edited-hook="editedHook ?? undefined"
			@close="closeModal"
			@back="closeModal"
		/>
	</f-popover>

	<f-popover :target="popoverTarget" :open="modalState === 'delete-webhook'">
		<!-- f-popover renders children by default, this causes issues with data in the modal -->
		<EnvWebHookDeleteModal
			v-if="editedHook && modalState === 'delete-webhook'"
			:web-hook="editedHook"
			@close="closeModal"
		/>
	</f-popover>
</template>
