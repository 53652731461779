<template>
	<Container direction="column" :padding="0" :gap="0">
		<Container direction="column" :padding="0" :gap="0" overflow="auto" :grow="1" align="left top">
			<Container :padding="0" :gap="0" :shrink="0">
				<Slab
					size="large"
					:type="selectedTab === 'overview' ? 'filled' : 'transparent'"
					:effects="!selectedTab"
					class="cursor-pointer slab-primary-padding-0"
					:selected="selectedTab === 'overview'"
					@click="$emit('select', 'overview')"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-org-overview-tab>
							<Icon name="i-home" type="filled" size="small" />
						</Container>
					</template>
					<Container :padding="0" :gap="4" direction="column">
						<Typography type="h4" weight="light" color="dark">Overview</Typography>
					</Container>
				</Slab>
			</Container>
			<Divider />
			<Container :padding="0" :gap="0" :shrink="0">
				<Slab
					v-if="isUserOrgAdmin"
					size="large"
					:type="selectedTab === 'members' ? 'filled' : 'transparent'"
					:effects="!selectedTab"
					class="cursor-pointer slab-primary-padding-0"
					:selected="selectedTab === 'members'"
					@click="$emit('select', 'members')"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-org-member-management-tab>
							<Icon name="i-user-double" type="filled" size="small" />
						</Container>
					</template>
					<template #secondary-actions>
						<Container v-if="membersCount" align="right" :padding="0" data-qa-members-count>
							<Button state="icon" size="small" type="default" class="margin-lt-auto">
								{{ membersCount }}
							</Button>
						</Container>
					</template>
					<Container
						:padding="0"
						:gap="32"
						direction="row"
						class="width-100-per"
						align="space-between center"
					>
						<Typography type="h4" weight="light" color="dark">Members</Typography>
					</Container>
				</Slab>
			</Container>
			<Divider />
			<Container :padding="0" :gap="0" :shrink="0">
				<Slab
					size="large"
					:type="selectedTab === 'dependencies' ? 'filled' : 'transparent'"
					:effects="!selectedTab"
					class="cursor-pointer slab-primary-padding-0"
					:selected="selectedTab === 'dependencies'"
					data-qa-dependency-tab
					@click="$emit('select', 'dependencies')"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-org-overview-tab>
							<Icon name="i-file-code" type="filled" size="small" />
						</Container>
					</template>
					<Container :padding="0" :gap="4" direction="column">
						<Typography type="h4" weight="light" color="dark">Dependencies</Typography>
					</Container>
				</Slab>
			</Container>
			<Divider />
			<Container :padding="0" :gap="0" :shrink="0">
				<Slab
					id="policies"
					size="large"
					:type="selectedTab === 'policy' ? 'filled' : 'transparent'"
					:effects="!selectedTab"
					class="cursor-pointer slab-primary-padding-0"
					:selected="selectedTab === 'policy'"
					data-qa-policy-tab
					@click="$emit('select', 'policy')"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-org-overview-tab>
							<Icon name="i-shield" type="filled" size="small" />
						</Container>
					</template>
					<Container :padding="0" :gap="4" direction="column">
						<Typography type="h4" weight="light" color="dark">Control gates</Typography>
					</Container>
				</Slab>
			</Container>
			<Divider />
			<Container :padding="0" :gap="0" :shrink="0">
				<Slab
					id="classifications"
					size="large"
					:type="selectedTab === 'env-classifications' ? 'filled' : 'transparent'"
					:effects="!selectedTab"
					class="cursor-pointer slab-primary-padding-0"
					:selected="selectedTab === 'env-classifications'"
					data-qa-env-classification-tab
					@click="$emit('select', 'env-classifications')"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-org-overview-tab>
							<Icon name="i-env-shape" type="filled" size="small" />
						</Container>
					</template>
					<Container :padding="0" :gap="4" direction="column">
						<Typography type="h4" weight="light" color="dark"
							>Environment classifications</Typography
						>
					</Container>
				</Slab>
			</Container>
			<Divider />
			<!-- No data from backend available for this, so commenting -->
			<Container :padding="0" :gap="0" :shrink="0">
				<Slab
					id="credentials-org"
					size="large"
					:type="selectedTab === 'credentials' ? 'filled' : 'transparent'"
					:effects="!selectedTab"
					class="cursor-pointer slab-primary-padding-0"
					:selected="selectedTab === 'credentials'"
					data-qa-credentials-tab
					@click="$emit('select', 'credentials')"
				>
					<template #primary-action>
						<Container padding="8px 0px 8px 12px" data-qa-org-overview-tab>
							<Icon name="i-icon" type="filled" size="small" />
						</Container>
					</template>
					<Container :padding="0" :gap="4" direction="column">
						<Typography type="h4" weight="light" color="dark">Credentials</Typography>
					</Container>
				</Slab>
			</Container>
			<Divider />
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Slab, Typography, Icon, Divider, Button } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { orgStore } from "../../org-store";
import { SelectedTabType } from "../org-settings-types";

export default defineComponent({
	name: "MenuOptions",

	components: {
		Container,
		Slab,
		Typography,
		Icon,
		Divider,
		Button
	},

	props: {
		selectedTab: {
			type: String as PropType<SelectedTabType>,
			required: true
		},

		membersCount: {
			type: Number
		}
	},

	emits: ["select"],

	computed: {
		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		}
	}
});
</script>
