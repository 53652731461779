import { AxiosResponse } from "axios";

import { ProjectId } from "@/protocol/common";
import {
	identityService,
	orgAccessGrant,
	orgAccessRevoke,
	orgId as OrganizationId,
	projAccessGrant,
	projAccessRevoke,
	success,
	projects
} from "@/protocol/identity";
import {
	Email,
	InvitationRequest,
	ListInvitedUsersRequest,
	ListInvitedUsersResponse,
	UserPublicProfile,
	UserRoleList
} from "@/protocol/user";
import ApiService from "@/services/api-service";
import { IDENTITY_API } from "@/shared/constants";

export const listProjAccess: identityService["listProjAccess"] = async function (
	request: ProjectId
) {
	const response: AxiosResponse<UserRoleList> = await ApiService.get(
		`${IDENTITY_API}/organizations/${request.orgId}/projects/${request.id}/users`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listOrgAccess: identityService["listOrgAccess"] = async function (
	request: OrganizationId
) {
	const response: AxiosResponse<UserRoleList> = await ApiService.get(
		`${IDENTITY_API}/organizations/${request.id}/users`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const grantOrgAccess: identityService["grantOrgAccess"] = async function (
	request: orgAccessGrant
) {
	const response: AxiosResponse<success> = await ApiService.post(
		`${IDENTITY_API}/organizations/${request.orgId}/users/${request.userId}`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const grantProjAccess: identityService["grantProjAccess"] = async function (
	request: projAccessGrant
) {
	const response: AxiosResponse<success> = await ApiService.post(
		`${IDENTITY_API}/organizations/${request.orgId}/projects/${request.projId}/users/${request.userId}`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const revokeProjAccess: identityService["revokeProjAccess"] = async function (
	request: projAccessRevoke
) {
	const response: AxiosResponse<success> = await ApiService.delete(
		`${IDENTITY_API}/organizations/${request.orgId}/projects/${request.projId}/users/${request.userId}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listProjects: identityService["listProject"] = async function ({ orgId, detailed }) {
	const response = await ApiService.get<projects>(
		`${IDENTITY_API}/organizations/${orgId}/projects?detailed=${detailed}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const revokeOrgAccess: identityService["revokeOrgAccess"] = async function (
	request: orgAccessRevoke
) {
	const response: AxiosResponse<success> = await ApiService.delete(
		`${IDENTITY_API}/organizations/${request.orgId}/users/${request.userId}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const findUser: identityService["findUser"] = async function (request: Email) {
	const response: AxiosResponse<UserPublicProfile> = await ApiService.get(
		`${IDENTITY_API}/users/${request.email}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listInvitedUsers: identityService["listInvitedUsers"] = async function (
	request: ListInvitedUsersRequest
) {
	const response: AxiosResponse<ListInvitedUsersResponse> = await ApiService.post(
		`${IDENTITY_API}/organizations/${request.orgId}/invites/users`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const userInvite: identityService["userInvite"] = async function (
	request: InvitationRequest
) {
	const response: AxiosResponse<success> = await ApiService.post(
		`${IDENTITY_API}/organizations/${request.orgId}/invites`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateUserInvite: identityService["updateUserInvite"] = async function (
	request: InvitationRequest
) {
	const response: AxiosResponse<success> = await ApiService.post(
		`${IDENTITY_API}/organizations/${request.orgId}/invites/update`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteUserInvite: identityService["deleteUserInvite"] = async function (
	request: InvitationRequest
) {
	const response: AxiosResponse<success> = await ApiService.post(
		`${IDENTITY_API}/organizations/${request.orgId}/invites/delete`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
