// Recursively remove empty values from an object or array
export function removeEmptyValues(obj: unknown, options: Options = {}): any {
	if (Array.isArray(obj)) {
		return obj
			.map(item => removeEmptyValues(item, options))
			.filter(item => isValueValid(item, options));
	}

	if (obj && typeof obj === "object") {
		const finalObj: Record<string, unknown> = {};

		Object.keys(obj).forEach(key => {
			const objValue = removeEmptyValues((obj as Record<string, unknown>)[key], options);

			if (isValueValid(objValue, options)) {
				finalObj[key] = objValue;
			}
		});

		return finalObj;
	}

	if (isValueValid(obj, options)) {
		return obj;
	}

	return null;
}

function isValueValid(value: unknown, options: Options) {
	const { removeZeros = false, removeEmptyStrings = false } = options;

	if (removeZeros && value === 0) {
		return false;
	}

	if (removeEmptyStrings && value === "") {
		return false;
	}

	return value !== null && value !== undefined;
}

type Options = {
	removeZeros?: boolean;
	removeEmptyStrings?: boolean;
};
