<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		max-height="90vh"
		width="950px"
		data-qa-add-variable-model
	>
		<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
			<Header>
				<Container align="space-between" :padding="0" :grow="1">
					<Typography type="h4" color="dark" data-qa-add-variable-popup-heading>
						Add new variables
					</Typography>
					<Icon
						v-tooltip="{
							content: 'Close',
							placement: 'bottom'
						}"
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-env-wid-add-variable-close
						@click.stop="closeDialog()"
					/>
				</Container>
			</Header>
			<Slab
				v-if="showClosingWarning"
				size="medium"
				state="warning"
				state-type="solid"
				align-items="center"
				:disabled="false"
				:selected="false"
			>
				<template #default>
					<Typography weight="regular" type="p1">
						<strong>The changes will be lost.</strong> Are you sure to close this?
					</Typography>
				</template>

				<template #secondary-actions>
					<Button
						size="small"
						type="warning"
						state="outlined"
						:disabled="false"
						:full-width="false"
						:loading="false"
						@click.stop="closeDialogWarning"
					>
						Yes, Close
					</Button>
				</template>
			</Slab>

			<Container align="left stretch" :separator="true" :gap="0" padding="0px">
				<Container
					class="popover-input"
					padding="0 16px"
					direction="column"
					overflow="auto"
					align="left top"
					:grow="1"
					:shrink="0"
					basis="45%"
				>
					<Wrapper height="628px">
						<f-div padding="none" direction="column" width="fill-container" gap="large">
							<AddVariableScopeForm
								v-if="!hasPreSelectedIds"
								v-model:variable-scopes="currentVariableScopes"
								:selected-variable-type="currentVariable.variableType"
							/>

							<AddVariableKeyValueForm
								v-model:variable="currentVariable"
								:selected-variable-scopes="currentVariableScopes"
								:store-variable-scopes="storeVariableScopes"
								:added-variables="stagedVariables"
								@validity-change="isCurrentVariableFormValid = $event"
							/>

							<f-button
								:style="{ width: '100%' }"
								label="Add to list"
								data-qa-id="add-to-list"
								size="small"
								:disabled="disableAddVariableButton"
								@click="onAddVariable"
							></f-button>
						</f-div>
					</Wrapper>
				</Container>
				<Container
					v-if="stagedVariables.length"
					class="popover-input"
					direction="column"
					overflow="auto"
					align="left top"
					:grow="1"
					:shrink="0"
					basis="55%"
				>
					<ScopeVariablesAccordion
						v-for="(scope, idx) in stagedVariables"
						:key="scope.id"
						:scope="stagedVariables[idx]!"
						:added-variables="stagedVariables"
						@update:scope="stagedVariables[idx] = $event"
					/>
				</Container>
				<Container
					v-else
					class="popover-input"
					padding="16px 30px 16px 16px"
					direction="column"
					overflow="auto"
					align="center"
					:grow="1"
					:shrink="0"
					basis="55%"
				>
					<Container :gap="12" :padding="0" align="center">
						<Icon name="i-variable" type="filled" size="large" color="gray-300" :effects="false" />
					</Container>
					<Container :gap="12" :padding="0" align="center">
						<Typography weight="medium" type="h4">Add Variable to the list here</Typography>
					</Container>
				</Container>
			</Container>
			<Divider />
			<Footer>
				<Container v-if="submitError" direction="row" :grow="1">
					<Typography color="danger-300">{{ submitError }}</Typography>
				</Container>
				<Container align="right" :grow="1" position="fixed" overflow="visible">
					<Button
						:type="isSubmitting || stagedVariables.length === 0 ? 'default' : 'success'"
						:disabled="isSubmitting || stagedVariables.length === 0"
						:loading="isSubmitting"
						data-qa-env-wid-add-variable-btn
						@click="saveVariables"
						>Add Variables</Button
					>
				</Container>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	Typography,
	Wrapper,
	Divider,
	Slab
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { variableType } from "@/protocol/common";
import { getErrorMessage } from "@/utils";

import AddVariableKeyValueForm from "./AddVariableKeyValueForm.vue";
import AddVariableScopeForm, { SelectedVariableScopes } from "./AddVariableScopeForm.vue";
import ScopeVariablesAccordion from "./ScopeVariablesAccordion.vue";
import { Variable, AddVariableScope } from "./variable-list-types";
import { variablesListStore } from "./variables-list-store";
import { updateVariablesFromForms } from "./variables-list-utils";

export default defineComponent({
	name: "AddVariableContent",

	components: {
		AddVariableScopeForm,
		AddVariableKeyValueForm,
		Typography,
		Container,
		Divider,
		Wrapper,
		Button,
		Header,
		Footer,
		Icon,
		Slab,
		ScopeVariablesAccordion
	},

	props: {
		preSelectedIds: {
			type: Array as PropType<string[] | null>,
			default: () => null
		}
	},

	emits: ["vars-saved", "vars-deleted", "close-dialog"],

	data() {
		return {
			currentVariable: getInitialFormValue(),
			currentVariableScopes: [] as SelectedVariableScopes[],
			showClosingWarning: false,
			isCurrentVariableFormValid: false,
			stagedVariables: [] as AddVariableScope[],
			submitError: "",
			isSubmitting: false
		};
	},

	computed: {
		hasPreSelectedIds() {
			return this.preSelectedIds ? this.preSelectedIds.length > 0 : false;
		},

		disableAddVariableButton() {
			if (!this.isCurrentVariableFormValid) {
				return true;
			}

			// We have pre-selected scope ids, we don't need to validate further
			if (this.hasPreSelectedIds) {
				return false;
			}

			return this.currentVariableScopes.length === 0;
		},

		storeVariableScopes() {
			return variablesListStore.variableScopes;
		}
	},

	watch: {
		preSelectedIds: {
			immediate: true,

			handler() {
				const { preSelectedIds } = this;

				// Compute scopes manually if we have pre-selected ids
				// because scope selection is disabled
				if (preSelectedIds) {
					this.currentVariableScopes = variablesListStore.variableScopes
						.filter(scope => preSelectedIds.includes(scope.id))
						.map(scope => ({
							id: scope.id,
							name: scope.name,
							variableFrom: scope.variableFrom
						}));
				}
			}
		}
	},

	methods: {
		onAddVariable() {
			this.currentVariableScopes.forEach(item => {
				const matchingScope = this.stagedVariables.find(scope_ => scope_.id === item.id);

				if (matchingScope) {
					matchingScope.variables.push(this.currentVariable);
				} else {
					this.stagedVariables.push({
						id: item.id,
						name: item.name,
						variableFrom: item.variableFrom,
						variables: [this.currentVariable]
					});
				}
			});

			this.currentVariable = getInitialFormValue();
		},

		async saveVariables() {
			if (this.isSubmitting) {
				return;
			}

			this.isSubmitting = true;

			try {
				await Promise.all(updateVariablesFromForms(this.stagedVariables));
			} catch (error) {
				this.isSubmitting = false;
				this.submitError = getErrorMessage(error);
				return;
			}

			this.closeDialogWarning();
			this.isSubmitting = false;
		},

		closeDialog() {
			if (this.stagedVariables.length === 0) {
				this.$emit("close-dialog", true);
			} else {
				this.showClosingWarning = true;
			}
		},

		closeDialogWarning() {
			this.$emit("close-dialog", true);
		}
	}
});

function getInitialFormValue(): Variable {
	return {
		key: "",
		value: "",
		isMasked: false,
		variableType: variableType.env_var
	};
}
</script>
