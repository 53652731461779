import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { getUniqueId } from "@/utils";

import { getStore } from "../store";

import { ToastItem } from "./notification-types";

@Module({
	namespaced: true,
	dynamic: true,
	name: "notifications",
	store: getStore()
})
class NotificationsStore extends VuexModule {
	toasts: ToastItem[] = [];

	@Mutation
	ADD_TOAST(toast: ToastItem) {
		if (!toast.id) {
			toast.id = `toast-${getUniqueId()}`;
		}

		if (!("autoDismiss" in toast)) {
			toast.autoDismiss = true;
		}

		this.toasts.push(toast);
	}

	@Mutation
	REMOVE_TOAST(toast: ToastItem) {
		this.toasts = this.toasts.filter(t => t.id !== toast.id);
	}

	@Mutation
	DISMISS_ALL() {
		this.toasts = [];
	}
}

const notificationsStore = getModule(NotificationsStore);

export { notificationsStore };
