/**
 * This module prevents same GET requests from executing in parallel
 * This prevents race conditions in our code and improves performance
 */
import axios from "axios";

export default class AxiosDuplicatePreventionAdapter {
	requestCache = {};

	constructor() {
		this.adapter = this.adapter.bind(this);
	}

	adapter(req) {
		if (req.method !== "get") {
			return axios.defaults.adapter(req);
		}

		// Try to find the request promise in the cache or else create one
		const reqUrl = req.url;
		if (!this.requestCache[reqUrl]) {
			this.requestCache[reqUrl] = axios.defaults.adapter(req);

			this.requestCache[reqUrl].finally(() => {
				// Promise has resolved, we can get rid of the cache entry
				delete this.requestCache[reqUrl];
			});
		}

		const response = this.requestCache[reqUrl];

		return response;
	}
}
