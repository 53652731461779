<script setup lang="ts">
import { Slab, Typography } from "@cldcvr/flow-vue3";
import { PropType, computed, onMounted, ref, toRefs } from "vue";

import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import { environment } from "@/protocol/infra";

import EnvPipelineConfiguration from "./EnvPipelineConfiguration.vue";
import EnvWebHookContent from "./EnvWebHookContent.vue";

const props = defineProps({
	env: { type: Object as PropType<environment>, required: true }
});

const { env } = toRefs(props);

const selectedTab = ref<"pipeline-config" | "webhooks">("pipeline-config");

function setActiveTab(tab: (typeof selectedTab)["value"]) {
	selectedTab.value = tab;
}

const webHooks = computed(() => {
	return webHooksStore.webHooksList[env.value.id];
});

onMounted(() => {
	webHooksStore.LIST_WEB_HOOKS({
		orgId: env.value.orgId,
		projectId: env.value.projId,
		entityId: env.value.id
	});
});

defineEmits(["open-terraform-dialog"]);
</script>

<template>
	<f-div>
		<f-div width="260px" align="top-left" height="hug-content">
			<Slab
				effects
				size="large"
				:type="selectedTab === 'pipeline-config' ? 'filled' : 'transparent'"
				data-qa-env-settings-pipeline-config
				:selected="selectedTab === 'pipeline-config'"
				@click="setActiveTab('pipeline-config')"
			>
				<template #primary-action>
					<f-icon source="i-setting" type="filled" size="small" />
				</template>
				<Typography type="h4" weight="light" color="dark">Pipeline Configuration</Typography>
			</Slab>

			<Slab
				effects
				size="large"
				:type="selectedTab === 'webhooks' ? 'filled' : 'transparent'"
				data-qa-env-settings-triggers
				:selected="selectedTab === 'webhooks'"
				@click="setActiveTab('webhooks')"
			>
				<template #primary-action>
					<f-icon source="i-webhook" type="filled" size="small" />
				</template>
				<Typography type="h4" weight="light" color="dark">Webhooks</Typography>
				<template #secondary-actions>
					<f-tag :label="String(webHooks?.length ?? 0)"></f-tag>
				</template>
			</Slab>
		</f-div>

		<f-divider />

		<f-div overflow="scroll" height="fill-container">
			<EnvWebHookContent
				v-if="selectedTab === 'webhooks'"
				:env="env"
				@open-terraform-dialog="$emit('open-terraform-dialog')"
			/>
			<EnvPipelineConfiguration v-if="selectedTab === 'pipeline-config'" :env="env" />
		</f-div>
	</f-div>
</template>
