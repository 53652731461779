<template>
	<Container :padding="0" :grow="1" align="center" align-self="center">
		<EmptyState :icon="emptyIcon" shape="squircle">
			<Container
				data-qa-empty-dependency-list
				align="center"
				direction="column"
				:gap="24"
				:padding="0"
				align-self="center"
			>
				<Container
					v-if="fromSearch"
					align="center"
					:gap="8"
					direction="column"
					:padding="0"
					data-qa-empty-search-dependency
				>
					<Typography type="h4" weight="bold" color="dark">{{ title }}</Typography>
					<Typography type="p2-para" color="gray-200" class="width-350 text-align-center">{{
						desc
					}}</Typography>
					<Typography type="p2-para" color="gray-200" class="width-350 text-align-center"
						>Try something else.</Typography
					>
				</Container>

				<Container
					v-else
					align="center"
					:gap="8"
					direction="column"
					:shrink="0"
					:padding="0"
					data-qa-empty-dependency
				>
					<Typography type="h4" weight="bold" color="dark" data-qa-empty-dependency-text
						>No dependencies available</Typography
					>
					<Typography type="p2-para" color="gray-100" class="width-350 text-align-center"
						>There are no dependencies available in this organization</Typography
					>
				</Container>

				<slot />
			</Container>
		</EmptyState>
	</Container>
</template>
<script lang="ts">
import { Container, EmptyState, EmptyStateIconProp, Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyDependencyList",
	components: { Container, EmptyState, Typography },

	props: {
		fromSearch: {
			type: Boolean
		},

		title: {
			type: String,
			default: () => "No dependencies found"
		},

		desc: {
			type: String,
			default: () => "No dependencies found"
		}
	},

	data: () => ({
		emptyIcon: {
			name: "i-file",
			type: "filled",
			state: "light",
			size: "large"
		} as EmptyStateIconProp
	})
});
</script>
