<template>
	<PopOver :open="open" placement="left" @overlay-click="open = false">
		<Icon
			v-tooltip="'Remove Module'"
			:class="open ? '' : 'show-on-hover'"
			name="i-delete"
			size="small"
			type="filled"
			data-qa-remove-pipeline-delete-icon
			state="error"
			@click="open = !open"
		/>
		<template #content>
			<Wrapper max-height="70vh" width="432px" border-radius="4px" background="element-light">
				<!--START : Container-->
				<!--START : PopOver header-->
				<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
					<Header>
						<Typography
							type="h4"
							:color="isSubmitting ? 'gray-300' : 'dark'"
							data-qa="cred-delete-modal-title"
							>Confirm removing</Typography
						>
						<Container :padding="0" :grow="1" align="right center">
							<Icon
								name="i-close"
								:disabled="isSubmitting"
								type="filled"
								size="x-small"
								data-qa="cred-delete-modal-close-btn"
								@click="open = false"
							/>
						</Container>
					</Header>
					<!--END : PopOver header-->
					<Container :padding="16" :gap="24" direction="column" overflow="auto" align="left top">
						<Typography
							v-if="!isSubmitting"
							type="p1"
							color="dark"
							data-qa="cred-delete-modal-caution-msg"
						>
							Are you sure you want to remove
							<Typography
								type="p1"
								weight="bold"
								color="dark"
								data-qa="cred-delete-modal-pipeline-module-name"
								inline
								:style="{ whiteSpace: 'pre-wrap' }"
								>{{ pipeline.name }}</Typography
							>
							from the pipeline?
						</Typography>
					</Container>
					<Divider />

					<Footer>
						<Button
							state="full"
							:disabled="isSubmitting"
							:loading="isSubmitting"
							type="error"
							data-qa="cred-delete-modal-pipeline-module-delete-btn"
							@click="removeModule"
							>Remove Module</Button
						>
					</Footer>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import {
	Divider,
	Button,
	Container,
	PopOver,
	Footer,
	Header,
	Icon,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { ArtifactMeta } from "../application-integration-store";

import { PipelineModuleMeta } from "./AddIntegrationPipelineWrapper.vue";

export default defineComponent({
	name: "RemovePipelineModule",

	components: {
		Typography,
		Container,
		PopOver,
		Divider,
		Wrapper,
		Button,
		Footer,
		Header,
		Icon
	},

	props: {
		pipeline: {
			type: Object as PropType<PipelineModuleMeta | ArtifactMeta>,
			required: true
		}
	},

	emits: ["remove"],

	data: () => ({
		isSubmitting: false,
		open: false
	}),

	methods: {
		removeModule() {
			if (this.isSubmitting) {
				return;
			}
			this.$emit("remove", this.pipeline);
			this.open = false;
		}
	}
});
</script>
