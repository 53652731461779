<template>
	<Wrapper
		border-radius="4px"
		background="gray-500"
		width="442px"
		max-height="80vh"
		overflow="visible"
		data-qa-undeploy-application
	>
		<Header>
			<Icon size="small" type="filled" name="i-arrow-left" @click="$emit('back')" />
			<Typography type="h4" color="dark">Undeploy {{ depEntity.name }}</Typography>
			<Container :padding="0" :shrink="0" :grow="1" align="right center">
				<Icon
					data-qa-undeploy-deployment-close
					size="x-small"
					type="filled"
					name="i-close"
					@click="closeUnDeployPopover()"
				/>
			</Container>
		</Header>
		<Container :padding="16" :gap="20" align="left top" direction="column" overflow="visible">
			<Typography type="p1-para" color="dark">
				Are you sure you want to undeploy
				<Typography
					type="p1"
					weight="medium"
					color="dark"
					inline
					:style="{ whiteSpace: 'pre-wrap' }"
					>{{ depEntity.name }}</Typography
				>?
			</Typography>
			<Divider />
			<Container direction="column" :padding="0" :gap="8" overflow="visible">
				<Typography type="p2" weight="medium" color="dark"
					>To confirm this action, please enter the app deployment name below</Typography
				>
				<InputText
					v-model:value="enteredAppDeploymentName"
					placeholder="Enter app deployment name to confirm"
				>
				</InputText>
			</Container>
		</Container>

		<Footer
			v-tooltip="{
				content: depEntity.name,
				container: 'div.flow-layout',
				placement: 'top-start'
			}"
		>
			<f-div direction="column" width="100%">
				<f-div v-if="errorMessage" padding="medium">
					<f-text state="danger">{{ errorMessage }}</f-text>
				</f-div>
				<Button
					state="full"
					type="error"
					full-width
					:disabled="depEntity.name !== enteredAppDeploymentName"
					data-qa-undeploy-application-button
					@click="triggerAppUndeployment()"
				>
					<Icon v-if="isAppUndeployLoading" name="i-history" state="light" :effects="false" />
					<Typography
						type="p2"
						weight="bold"
						color="gray-600"
						overflow="ellipsis"
						transform="uppercase"
						>Undeploy {{ depEntity.name }}</Typography
					>
				</Button>
			</f-div>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	InputText,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { AppDeployment, DeploymentJobAction } from "@/protocol/deployment";
import { captureError, getErrorMessage } from "@/utils";

import { routerToAppPipeline, startApplicationPipeline } from "../mixins/AppCommonMixin";

export default defineComponent({
	name: "DepUndeployPopver",

	components: {
		Button,
		Header,
		Footer,
		Container,
		Typography,
		Icon,
		Wrapper,
		InputText,
		Divider
	},

	props: {
		depEntity: {
			type: Object as PropType<AppDeployment>,
			required: true
		},

		closeUnDeployPopover: {
			type: Function as PropType<() => void>,
			required: true
		}
	},

	data: () => ({
		enteredAppDeploymentName: "",
		isAppUndeployLoading: false,
		errorMessage: null as string | null
	}),

	methods: {
		async triggerAppUndeployment() {
			try {
				this.errorMessage = null;
				this.isAppUndeployLoading = true;

				await startApplicationPipeline({
					depEntity: this.depEntity,
					jobType: DeploymentJobAction.undeploy
				});

				this.closeUnDeployPopover();
				routerToAppPipeline(this.$router, this.depEntity, DeploymentJobAction.undeploy);
			} catch (err) {
				this.errorMessage = getErrorMessage(err, true);
				captureError(err);
			} finally {
				this.isAppUndeployLoading = false;
			}
		}
	}
});
</script>
