<template>
	<InfoPopover
		v-if="showInfo"
		title="What is project?"
		placement="left-start"
		:target="target"
		:is-open="showInfo"
		:show-overlay="true"
		@close="closePopover"
	>
		<f-div>
			<f-text variant="para" size="medium"> A project is like a directory or a folder. </f-text>
		</f-div>
		<f-div padding="large none none none">
			<f-text>
				With a project, you can manage your applications, their deployments and environments.
			</f-text>
		</f-div>
		<f-div padding="large none none none">
			<f-text>
				<Typography
					type="p2-para"
					color="primary"
					:link="true"
					data-qa-project-env-classification-info-popover-link
					@click="openLink()"
				>
					Learn more about projects
				</Typography>
			</f-text>
		</f-div>
	</InfoPopover>
</template>

<script lang="ts">
import { Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { userStore } from "@/modules/user/user-store";
import InfoPopover from "@/shared/components/InfoPopover/InfoPopover.vue";

export default defineComponent({
	name: "ProjectHomePagePopover",

	components: {
		InfoPopover,
		Typography
	},

	props: {
		target: {
			type: String,
			default: "popoverTarget"
		}
	},

	data: () => ({
		showInfo: false as boolean
	}),

	computed: {
		infoFlags() {
			return userStore.profile?.metadata?.infoFlags;
		}
	},

	mounted() {
		if (!this.infoFlags?.hasProjectHomePagePopover) {
			this.showInfo = true;
		}
	},

	methods: {
		closePopover() {
			this.showInfo = false;
			userStore.UPDATE_USER_META({
				infoFlags: {
					...this.infoFlags,
					hasProjectHomePagePopover: true
				}
			});
		},

		openLink() {
			window.open("https://docs.codepipes.io/docs/projects", "_blank");
		}
	}
});
</script>
