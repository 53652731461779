import { IconColor } from "@cldcvr/flow-vue3";

import { actionType } from "@/protocol/infra";

import { EnvironmentActionState, EnvironmentSubAction } from "./EnvironmentAction.vue";

export const ACTION_TYPE_ICONS: Record<actionType, string> = {
	[actionType.validateOnly]: "i-tick",
	[actionType.destroy]: "i-destroy",
	[actionType.ValidateAndApply]: "i-launch",
	[actionType.no_action_type_support]: ""
};

export const ACTION_TYPE_ICON_COLORS: Record<actionType, IconColor | undefined> = {
	[actionType.validateOnly]: "primary-300",
	[actionType.destroy]: "danger-200",
	[actionType.ValidateAndApply]: "success-300",
	[actionType.no_action_type_support]: undefined
};

export const EnvironmentActionTextMap: Record<actionType, string> = {
	[actionType.validateOnly]: "Validate",
	[actionType.ValidateAndApply]: "Deploy",
	[actionType.destroy]: "Destroy",
	[actionType.no_action_type_support]: ""
};

export const ACTION_STATE_TEXT: Record<EnvironmentActionState, Record<actionType, string>> = {
	loading: EnvironmentActionTextMap,
	default: {
		[actionType.validateOnly]: "Validate",
		[actionType.ValidateAndApply]: "Deploy",
		[actionType.destroy]: "Destroy",
		[actionType.no_action_type_support]: ""
	},
	done: {
		[actionType.validateOnly]: "Validated",
		[actionType.ValidateAndApply]: "Deployed",
		[actionType.destroy]: "Destroyed",
		[actionType.no_action_type_support]: ""
	},
	failed: {
		[actionType.validateOnly]: "Failed",
		[actionType.ValidateAndApply]: "Failed",
		[actionType.destroy]: "Failed",
		[actionType.no_action_type_support]: ""
	},
	canceled: {
		[actionType.validateOnly]: "Cancelled",
		[actionType.ValidateAndApply]: "Cancelled",
		[actionType.destroy]: "Cancelled",
		[actionType.no_action_type_support]: ""
	},
	active: {
		[actionType.validateOnly]: "Validating...",
		[actionType.ValidateAndApply]: "Deploying...",
		[actionType.destroy]: "Destroying...",
		[actionType.no_action_type_support]: ""
	},
	canceling: {
		[actionType.validateOnly]: "Cancelling...",
		[actionType.ValidateAndApply]: "Cancelling...",
		[actionType.destroy]: "Cancelling...",
		[actionType.no_action_type_support]: ""
	},
	stalled: {
		[actionType.validateOnly]: "Stalled",
		[actionType.ValidateAndApply]: "Stalled",
		[actionType.destroy]: "Stalled",
		[actionType.no_action_type_support]: ""
	},
	waiting: {
		[actionType.validateOnly]: "Waiting",
		[actionType.ValidateAndApply]: "Waiting",
		[actionType.destroy]: "Waiting",
		[actionType.no_action_type_support]: ""
	},
	pending: {
		[actionType.validateOnly]: "Pending",
		[actionType.ValidateAndApply]: "Pending",
		[actionType.destroy]: "Pending",
		[actionType.no_action_type_support]: ""
	}
};

export const ACTION_STATE_TEXT_PIPELINE: Record<
	EnvironmentActionState,
	Record<actionType, string>
> = {
	loading: {
		[actionType.validateOnly]: "Validate",
		[actionType.ValidateAndApply]: "Deploy",
		[actionType.destroy]: "Destroy",
		[actionType.no_action_type_support]: ""
	},
	default: {
		[actionType.validateOnly]: "",
		[actionType.ValidateAndApply]: "",
		[actionType.destroy]: "",
		[actionType.no_action_type_support]: ""
	},
	done: {
		[actionType.validateOnly]: "Validated",
		[actionType.ValidateAndApply]: "Deployed",
		[actionType.destroy]: "Destroyed",
		[actionType.no_action_type_support]: ""
	},
	failed: {
		[actionType.validateOnly]: "Validation failed",
		[actionType.ValidateAndApply]: "Deployment failed",
		[actionType.destroy]: "Destroy failed",
		[actionType.no_action_type_support]: ""
	},
	canceled: {
		[actionType.validateOnly]: "Validation canceled",
		[actionType.ValidateAndApply]: "Deployment canceled",
		[actionType.destroy]: "Destroy canceled",
		[actionType.no_action_type_support]: ""
	},
	active: {
		[actionType.validateOnly]: "Validating...",
		[actionType.ValidateAndApply]: "Deploying...",
		[actionType.destroy]: "Destroying...",
		[actionType.no_action_type_support]: ""
	},
	canceling: {
		[actionType.validateOnly]: "Cancelling validation...",
		[actionType.ValidateAndApply]: "Cancelling deployment...",
		[actionType.destroy]: "Cancelling destroy...",
		[actionType.no_action_type_support]: ""
	},
	stalled: {
		[actionType.validateOnly]: "Stalled",
		[actionType.ValidateAndApply]: "Stalled",
		[actionType.destroy]: "Stalled",
		[actionType.no_action_type_support]: ""
	},
	waiting: {
		[actionType.validateOnly]: "Validating...",
		[actionType.ValidateAndApply]: "Deploying...",
		[actionType.destroy]: "Destroying...",
		[actionType.no_action_type_support]: ""
	},
	pending: {
		[actionType.validateOnly]: "Pending",
		[actionType.ValidateAndApply]: "Pending",
		[actionType.destroy]: "Pending",
		[actionType.no_action_type_support]: ""
	}
};

export const ACTION_STATE_ICONS: Record<
	EnvironmentActionState,
	Record<
		actionType,
		{
			name: string;
			animate: boolean;
			color: IconColor;
		} | null
	>
> = {
	loading: {
		[actionType.validateOnly]: null,
		[actionType.ValidateAndApply]: null,
		[actionType.destroy]: null,
		[actionType.no_action_type_support]: null
	},
	default: {
		[actionType.validateOnly]: null,
		[actionType.ValidateAndApply]: null,
		[actionType.destroy]: null,
		[actionType.no_action_type_support]: null
	},
	done: {
		[actionType.validateOnly]: {
			name: "i-tick-fill",
			animate: false,
			color: "primary-300"
		},
		[actionType.ValidateAndApply]: {
			name: "i-tick-fill",
			animate: false,
			color: "success-300"
		},
		[actionType.destroy]: {
			name: "i-tick-fill",
			animate: false,
			color: "danger-200"
		},
		[actionType.no_action_type_support]: null
	},
	failed: {
		[actionType.validateOnly]: { name: "i-alert", animate: false, color: "danger-200" },
		[actionType.ValidateAndApply]: { name: "i-alert", animate: false, color: "danger-200" },
		[actionType.destroy]: { name: "i-alert", animate: false, color: "danger-200" },
		[actionType.no_action_type_support]: null
	},
	stalled: {
		[actionType.validateOnly]: { name: "i-alert", animate: false, color: "warning-300" },
		[actionType.ValidateAndApply]: { name: "i-alert", animate: false, color: "warning-300" },
		[actionType.destroy]: { name: "i-alert", animate: false, color: "warning-300" },
		[actionType.no_action_type_support]: null
	},
	canceled: {
		[actionType.validateOnly]: {
			name: "i-stop",
			animate: false,
			color: "danger-200"
		},
		[actionType.ValidateAndApply]: {
			name: "i-stop",
			animate: false,
			color: "danger-200"
		},
		[actionType.destroy]: {
			name: "i-stop",
			animate: false,
			color: "danger-200"
		},
		[actionType.no_action_type_support]: null
	},
	active: {
		[actionType.validateOnly]: {
			name: "i-loader",
			animate: true,
			color: "gray-300"
		},
		[actionType.ValidateAndApply]: {
			name: "i-loader-success",
			animate: true,
			color: "gray-300"
		},
		[actionType.destroy]: {
			name: "i-loader-danger",
			animate: true,
			color: "gray-300"
		},
		[actionType.no_action_type_support]: null
	},
	canceling: {
		[actionType.validateOnly]: {
			name: "i-loader-danger",
			animate: true,
			color: "gray-300"
		},
		[actionType.ValidateAndApply]: {
			name: "i-loader-danger",
			animate: true,
			color: "gray-300"
		},
		[actionType.destroy]: {
			name: "i-loader-danger",
			animate: true,
			color: "gray-300"
		},
		[actionType.no_action_type_support]: null
	},
	pending: {
		[actionType.validateOnly]: {
			name: "i-loader",
			animate: true,
			color: "gray-300"
		},
		[actionType.ValidateAndApply]: {
			name: "i-loader-success",
			animate: true,
			color: "gray-300"
		},
		[actionType.destroy]: {
			name: "i-loader-danger",
			animate: true,
			color: "gray-300"
		},
		[actionType.no_action_type_support]: null
	},
	waiting: {
		[actionType.validateOnly]: {
			name: "i-loader",
			animate: true,
			color: "gray-300"
		},
		[actionType.ValidateAndApply]: {
			name: "i-loader-success",
			animate: true,
			color: "gray-300"
		},
		[actionType.destroy]: {
			name: "i-loader-danger",
			animate: true,
			color: "gray-300"
		},
		[actionType.no_action_type_support]: null
	}
};
const CANCEL_SUB_ACTION: EnvironmentSubAction = {
	icon: "i-stop",
	iconColor: "danger-200",
	background: "danger-500"
};

const CANCELLING_SUB_ACTION: EnvironmentSubAction = {
	icon: "i-stop",
	iconColor: "danger-200",
	background: "gray-500",
	disabled: true
};

export const SUB_ACTION_STATE: Record<
	EnvironmentActionState,
	Record<actionType, EnvironmentSubAction | null> | null
> = {
	loading: null,
	default: null,
	active: {
		[actionType.validateOnly]: { ...CANCEL_SUB_ACTION, tooltip: "Cancel Validation" },
		[actionType.ValidateAndApply]: { ...CANCEL_SUB_ACTION, tooltip: "Cancel Validation" },
		[actionType.destroy]: { ...CANCEL_SUB_ACTION, tooltip: "Cancel Validation" },
		[actionType.no_action_type_support]: null
	},
	pending: {
		[actionType.validateOnly]: { ...CANCEL_SUB_ACTION, tooltip: "Cancel" },
		[actionType.ValidateAndApply]: { ...CANCEL_SUB_ACTION, tooltip: "Cancel" },
		[actionType.destroy]: { ...CANCEL_SUB_ACTION, tooltip: "Cancel" },
		[actionType.no_action_type_support]: null
	},
	waiting: {
		[actionType.validateOnly]: { ...CANCEL_SUB_ACTION, tooltip: "Cancel" },
		[actionType.ValidateAndApply]: { ...CANCEL_SUB_ACTION, tooltip: "Cancel" },
		[actionType.destroy]: { ...CANCEL_SUB_ACTION, tooltip: "Cancel" },
		[actionType.no_action_type_support]: null
	},
	stalled: {
		[actionType.validateOnly]: {
			background: "primary-300",
			icon: "i-tick",
			iconColor: "gray-600",
			tooltip: "Re-validate environment"
		},
		[actionType.ValidateAndApply]: {
			background: "success-300",
			icon: "i-launch",
			iconColor: "gray-600",
			tooltip: "Re-deploy environment"
		},
		[actionType.destroy]: {
			background: "danger-200",
			icon: "i-reload",
			iconColor: "gray-600",
			tooltip: "Re-destroy environment"
		},
		[actionType.no_action_type_support]: null
	},
	done: {
		[actionType.validateOnly]: {
			background: "primary-300",
			icon: "i-tick",
			iconColor: "gray-600",
			tooltip: "Re-validate environment"
		},
		[actionType.ValidateAndApply]: {
			background: "success-300",
			icon: "i-launch",
			iconColor: "gray-600",
			tooltip: "Re-deploy environment"
		},
		[actionType.destroy]: {
			background: "gray-500",
			icon: "i-destroy",
			iconColor: "danger-200",
			disabled: true
		},
		[actionType.no_action_type_support]: null
	},
	failed: {
		[actionType.validateOnly]: {
			background: "primary-300",
			icon: "i-reload",
			iconColor: "gray-600",
			tooltip: "Retry validation"
		},
		[actionType.ValidateAndApply]: {
			background: "success-300",
			icon: "i-reload",
			iconColor: "gray-600",
			tooltip: "Retry deployment"
		},
		[actionType.destroy]: {
			background: "danger-200",
			icon: "i-reload",
			iconColor: "gray-600",
			tooltip: "Retry destroy"
		},
		[actionType.no_action_type_support]: null
	},
	canceled: {
		[actionType.validateOnly]: {
			background: "primary-300",
			icon: "i-tick",
			iconColor: "gray-600",
			tooltip: "Re-validate environment"
		},
		[actionType.ValidateAndApply]: {
			background: "success-300",
			icon: "i-launch",
			iconColor: "gray-600",
			tooltip: "Re-deploy environment"
		},
		[actionType.destroy]: {
			background: "danger-200",
			icon: "i-destroy",
			iconColor: "gray-600",
			tooltip: "Destroy environment"
		},
		[actionType.no_action_type_support]: null
	},
	canceling: {
		[actionType.validateOnly]: CANCELLING_SUB_ACTION,
		[actionType.ValidateAndApply]: CANCELLING_SUB_ACTION,
		[actionType.destroy]: CANCELLING_SUB_ACTION,
		[actionType.no_action_type_support]: null
	}
};
