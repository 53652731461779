//@ts-nocheck
// source: events/event_dispatcher.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

// var jspb = require('google-protobuf');
import jspb from 'google-protobuf';
import events_events_pb from '../events/events_pb'
var goog = jspb;
var global = Function('return this')();

// var events_events_pb = require('../events/events_pb.js');
goog.object.extend(proto, events_events_pb);

export default events_events_pb;
// var google_api_annotations_pb = require('../google/api/annotations_pb.js');
// goog.object.extend(proto, google_api_annotations_pb);
