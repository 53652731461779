<template>
	<Slab
		:key="deployment.id"
		type="transparent"
		:no-padding="true"
		overflow="visible"
		align-items="start"
		:data-qa-env-wid-apps-list-item="deployment.name"
	>
		<template #primary-action>
			<Pictogram
				:loading="deploymentStateConfig.loading"
				:border-state="deploymentStateConfig.pictogramBorderState"
				shape="squircle"
				:state="deploymentStateConfig.pictogramState"
				size="xl"
				:effects="false"
			>
				<Typography
					v-if="!deploymentStateConfig.showAlertIcon"
					type="h2"
					weight="bold"
					color="dark"
					transform="uppercase"
				>
					{{ deploymentInitials }}
				</Typography>
				<Icon v-else name="i-alert" type="filled" state="white" :effects="false" />
			</Pictogram>
		</template>
		<Container direction="column" :gap="4" padding="0px 0px 0px 12px">
			<Container :gap="12" :padding="0">
				<Typography
					data-qa-env-wid-apps-list-item-name
					type="h4"
					color="dark"
					weight="bold"
					overflow="ellipsis"
					>{{ deployment.name }}</Typography
				>
			</Container>

			<Typography v-if="deploymentStateConfig.showDeploymentFailedMsg" type="p2" color="light">
				{{ deploymentStateConfig.failedMsg }}
			</Typography>
			<CustomTimeStamp
				v-else-if="deployment.updatedAt"
				:time="deployment.updatedAt"
				prefix="Updated"
				data-qa="env-widget-list-updated-date"
			/>

			<Typography v-if="isAppDeployed && deployedAppVersion" type="p2" color="light">
				Version: {{ deployedAppVersion }}
			</Typography>
		</Container>
		<template #secondary-actions>
			<Icon
				name="i-arrow-right"
				type="filled"
				state="primary"
				size="20px"
				@click="routeToCodePromotion(deployment)"
			/>
			<Icon
				v-if="deploymentStateConfig.showViewLog"
				name="i-pipe"
				type="filled"
				:state="deploymentStateConfig.viewLogState"
				size="20px"
				:class="deploymentStateConfig.viewLogClasses"
				@click="routerToAppPipeline(deployment)"
			/>
		</template>
	</Slab>
</template>

<script lang="ts">
import {
	Container,
	Icon,
	IconState,
	Pictogram,
	PictogramBorderState,
	PictogramState,
	Slab,
	Typography
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { codePromotionStore } from "@/modules/code-promotion/code-promotion-store";
import { AppDeployment, DeploymentJobAction } from "@/protocol/deployment";
import { CustomTimeStamp } from "@/shared/components";
import {
	PipelineJobStatus,
	PIPELINE_UNFINISHED_JOB_STATUSES,
	JOB_STATUS
} from "@/shared/pipeline-constants";

export default defineComponent({
	name: "EnvWidgetAppsList",
	components: { CustomTimeStamp, Typography, Pictogram, Container, Icon, Slab },

	props: {
		deployment: {
			type: Object as PropType<AppDeployment>,
			required: true
		}
	},

	data(): {
		deploymentStates: Record<"failed" | "success" | "notDeployed" | "deploying", AppStatusConfig>;
	} {
		return {
			deploymentStates: {
				failed: {
					showViewLog: true,
					viewLogClasses: "",
					pictogramState: "warning",
					viewLogState: "warning",
					pictogramBorderState: "default",
					showAlertIcon: true,
					showDeploymentFailedMsg: true,
					failedMsg: ""
				},

				success: {
					showViewLog: true,
					viewLogClasses: "show-on-hover",
					viewLogState: "success",
					pictogramState: "default",
					pictogramBorderState: "success",
					showAlertIcon: false,
					showDeploymentFailedMsg: false,
					failedMsg: ""
				},

				notDeployed: {
					showViewLog: false,
					pictogramState: "default",
					viewLogClasses: "",
					viewLogState: "default",
					pictogramBorderState: "default",
					showAlertIcon: false,
					showDeploymentFailedMsg: false,
					failedMsg: ""
				},

				deploying: {
					showViewLog: true,
					pictogramState: "default",
					viewLogClasses: "",
					viewLogState: "default",
					pictogramBorderState: "primary",
					showAlertIcon: false,
					showDeploymentFailedMsg: false,
					failedMsg: "",
					loading: true
				}
			}
		};
	},

	computed: {
		deployedAppVersion() {
			return this.deployment.lastSuccessfulJob?.deployVersion ?? null;
		},

		deploymentInitials() {
			if (this.deployment.name && this.deployment.name.length > 2) {
				return this.deployment.name.substring(0, 2);
			}
			return this.deployment.name;
		},

		currentAppJob() {
			return this.deployment.lastJob;
		},

		isCurrentJobActive() {
			const { currentAppJob } = this;

			if (!currentAppJob) {
				return false;
			}

			return PIPELINE_UNFINISHED_JOB_STATUSES.includes(
				currentAppJob.jobStatus as PipelineJobStatus
			);
		},

		isAppDeployed() {
			if (this.deployment.lastSuccessfulJob) {
				return this.deployment.lastSuccessfulJob.jobType === "deploy";
			} else if (this.currentAppJob) {
				const lastJobStatus = this.currentAppJob.jobStatus as PipelineJobStatus;
				if (lastJobStatus === JOB_STATUS.DONE) {
					return this.currentAppJob.jobType === DeploymentJobAction.deploy;
				}
			}

			return false;
		},

		deploymentStateConfig() {
			let config = this.deploymentStates.notDeployed;

			const latestJobType = this.deployment.lastJob?.jobType;
			const lastestJobSuccessful =
				(this.deployment.lastJob?.jobStatus as PipelineJobStatus) === JOB_STATUS.DONE;

			switch (true) {
				case this.isCurrentJobActive:
					config = this.deploymentStates.deploying;
					break;
				case this.isAppDeployed:
					config = this.deploymentStates.success;
					break;
				case latestJobType === "deploy" && !lastestJobSuccessful:
					config = this.deploymentStates.failed;
					config.failedMsg = "App Deployment failed";
					break;
				case latestJobType === "undeploy" && lastestJobSuccessful:
					config = this.deploymentStates.notDeployed;
					break;
				case latestJobType === "undeploy" && !lastestJobSuccessful:
					config = this.deploymentStates.failed;
					config.failedMsg = "App Undeployment failed";
					break;
				default:
					break;
			}

			return config;
		}
	},

	methods: {
		routeToCodePromotion(dep: AppDeployment) {
			if (dep.orgId && dep.projId) {
				this.$router.replace({
					name: "projectListWithProject",
					params: { orgId: dep.orgId, projectId: dep.projId, tabName: "code-promotion" }
				});
			}
			codePromotionStore.SET_CURRENT_APP_DEP_CODE_PROMOTION(dep);
		},

		routerToAppPipeline(dep: AppDeployment) {
			if (!dep.orgId || !dep.projId || !dep.envId || !dep.id || !this.currentAppJob) {
				return;
			}

			this.$router.push({
				name: "stageViewApp",
				params: {
					orgId: dep.orgId,
					projectId: dep.projId,
					envId: dep.envId,
					depId: dep.id,
					action: this.currentAppJob.jobType
				}
			});
		}
	}
});

type AppStatusConfig = {
	showViewLog: boolean;
	viewLogClasses: string;
	pictogramState: PictogramState;
	viewLogState: IconState;
	pictogramBorderState: PictogramBorderState;
	showAlertIcon: boolean;
	showDeploymentFailedMsg: boolean;
	failedMsg: string;
	loading?: boolean;
};
</script>
