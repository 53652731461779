<template>
	<TableRow :data-qa-dependency-row="dependency.name" :blink="false">
		<TableCell padding="20px 12px">
			<Container :padding="0" overflow="visible">
				<!-- Icon not available -->
				<Typography type="p2" :data-qa-dependency-row-="dependency.name">{{
					dependency.name
				}}</Typography>
			</Container>
		</TableCell>
		<TableCell padding="20px 12px">
			<Container :padding="0" :gap="12">
				<Typography type="p2" color="gray-200" data-qa-dependency-category>{{
					dependency.group === "" ? "-" : dependency.group
				}}</Typography>
			</Container>
		</TableCell>
		<!-- TEMP solution until we get https://cldcvr.atlassian.net/browse/VAN-3788 done from backend -->
		<TableCell v-if="!dependency.id" padding="20px 12px" data-qa-dependency-row-used>
			<Icon name="i-tick" size="small" color="success-200" />
		</TableCell>
		<TableCell v-else padding="20px 12px" data-qa-dependency-row-used> -- </TableCell>
		<!-- TEMP solution end -->

		<TableCell padding="20px 12px" data-qa-dependency-row-last-updated>
			<Typography type="p2" color="gray-200" data-qa-dependency-last-updated>{{
				getFormattedTime(dependency.updatedAt)
			}}</Typography>
		</TableCell>

		<TableCell padding="12px 12px" :data-qa-dependency-row-view-details="dependency.name">
			<Button
				state="outlined"
				type="default"
				class="show-on-hover"
				:data-qa-dependency-view-details-btn="dependency.name"
				@click="$emit('view-details', dependency)"
				>View Details</Button
			>
		</TableCell>
	</TableRow>
</template>
<script lang="ts">
import { Button, Container, Icon, TableCell, TableRow, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { Dependency } from "@/protocol/infra";
import { getRelativeTime } from "@/utils";

export default defineComponent({
	name: "DependencyRow",

	components: {
		TableRow,
		TableCell,
		Container,
		Typography,
		Icon,
		Button
	},

	props: {
		dependency: {
			type: Object as PropType<Dependency>,
			required: true
		}
	},

	methods: {
		getFormattedTime(value: string | undefined) {
			if (!value) {
				return "";
			}
			return getRelativeTime(value);
		}
	}
});
</script>
