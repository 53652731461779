<template>
	<PopOver
		v-model:open="openActions"
		placement="right-start"
		:overlay="false"
		class="align-items-center"
		@overlay-click="openActions = false"
	>
		<Icon
			v-tooltip="{
				content: 'Filter',
				placement: 'bottom-start'
			}"
			name="i-filter"
			type="filled"
			size="x-small"
			data-qa="filter-by-classification"
			:state="selectedClassificationId === null ? 'light' : 'primary'"
			@click="openActions = true"
		/>
		<template #content>
			<Wrapper border-radius="4px" background="gray-500" :border="true" width="255px">
				<Container direction="column" :padding="0" :gap="0">
					<Slab
						v-for="classification in classifications"
						:key="classification.id"
						size="large"
						:type="selectedClassificationId === classification.id ? 'filled' : 'transparent'"
						:effects="true"
						class="cursor-pointer"
						:data-qa="`classification-filter-${classification.name}`"
						@click="updatedSelectedClassification(classification.id)"
					>
						<template #primary-action>
							<Icon
								:name="getClassificationIcon(classification)"
								type="filled"
								size="small"
								:effects="false"
							/>
						</template>
						<Typography type="h4" weight="regular" color="default">{{
							classification.name
						}}</Typography>
						<template #secondary-actions>
							<Icon
								v-if="selectedClassificationId === classification.id"
								name="i-tick"
								type="filled"
								size="small"
								:effects="false"
							/>
						</template>
					</Slab>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { Icon, PopOver, Typography, Wrapper, Slab, Container } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { credentialStore } from "@/modules/credentials/credential-store";
import { getClassificationIcon } from "@/utils";

export default defineComponent({
	name: "FilterCredByClassificationMenu",

	components: {
		Icon,
		PopOver,
		Typography,
		Wrapper,
		Slab,
		Container
	},

	props: {
		selectedClassificationId: {
			type: String as PropType<string | null>,
			default: null
		}
	},

	data: () => ({
		openActions: false
	}),

	computed: {
		classifications() {
			return credentialStore.classificationList;
		}
	},

	methods: {
		getClassificationIcon,

		updatedSelectedClassification(classificationId: string) {
			if (this.selectedClassificationId === classificationId) {
				this.$emit("update:selectedClassificationId", null);
			} else {
				this.$emit("update:selectedClassificationId", classificationId);
			}
			this.openActions = false;
		}
	}
});
</script>
