<template>
	<PopOver
		:target="target"
		:open="open"
		:overlay="true"
		placement="right-end"
		@overlay-click="$emit('close')"
	>
		<slot />
		<template #content>
			<Wrapper
				border-radius="4px"
				background="element-light"
				:border="true"
				max-height="556px"
				width="432px"
				data-qa-edit-dependency
			>
				<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
					<Header>
						<Container align="space-between center" :padding="0" :grow="1">
							<Container :gap="12" :padding="0">
								<Typography type="h4" color="dark">Edit app dependency</Typography>
							</Container>
							<Container align="right center" :grow="1" :padding="0">
								<Icon
									name="i-close"
									type="filled"
									size="x-small"
									data-qa-edit-dependency-close
									@click.stop="$emit('close')"
								/>
							</Container>
						</Container>
					</Header>

					<Container
						direction="column"
						overflow="scroll"
						align="stretch top"
						:grow="1"
						class="flow-add-scrollbar"
					>
						<f-form-builder
							ref="editDepFormBuilderRef"
							data-qa-edit-dependency-form
							:field.prop="formFields ?? undefined"
							:values.prop="formValues as FormBuilderValues"
							@input="handleInput"
							@state-change="formState = $event.detail"
						/>
					</Container>

					<Footer>
						<Container direction="column" padding="0" :grow="1">
							<Container v-if="errorMessage">
								<Typography type="p2" color="error" data-qa-edit-dependency-error>{{
									errorMessage
								}}</Typography>
							</Container>
							<Button
								type="success"
								state="full"
								:loading="isSubmitting"
								:full-width="true"
								data-qa-edit-dependency-submit
								@click="saveDependency"
								>Save Changes</Button
							>
						</Container>
					</Footer>
				</Container>
			</Wrapper>
		</template>
	</PopOver>
</template>

<script lang="ts">
import {
	FFormBuilder,
	FormBuilderField,
	FormBuilderState,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	FormBuilderValues
} from "@cldcvr/flow-form-builder";
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	PopOver,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { app as appProto } from "@/protocol/app";
import { captureError, getErrorMessage } from "@/utils";

import { applicationStore } from "../application-store";

export default defineComponent({
	name: "EditDependencyPopover",

	components: {
		Button,
		Container,
		Footer,
		Header,
		Icon,
		PopOver,
		Typography,
		Wrapper
	},

	props: {
		open: Boolean,

		app: {
			type: Object as PropType<appProto>,
			required: true
		},

		dependencyName: {
			type: String,
			required: true
		},

		target: String
	},

	emits: ["close"],

	data() {
		return {
			formValues: null as EditDependencyValues | null,
			formState: null as FormBuilderState | null,
			isSubmitting: false,
			errorMessage: null as string | null
		};
	},

	computed: {
		dependency() {
			return this.app.dependsOn?.[this.dependencyName] ?? null;
		},

		dependencyInputs() {
			return Object.entries(this.dependency?.inputs ?? {});
		},

		dependencyOutputs() {
			return Object.entries(this.dependency?.outputs ?? {});
		},

		formFields(): FormBuilderField | null {
			if (!this.dependency) {
				return null;
			}

			const fields: FormBuilderField = {
				type: "object",
				direction: "vertical",
				fields: {
					dependencyName: {
						qaId: "dependencyName",
						type: "text",
						label: { title: "Dependency name" },
						validationRules: [{ name: "required" }]
					}
				}
			};

			if (this.dependencyInputs.length > 0) {
				fields.fields.inputs = {
					type: "object",
					direction: "vertical",
					label: { title: "Input variables" },
					fields: this.dependencyInputs.reduce(
						(out, [key]) => {
							out[key] = {
								qaId: key,
								type: "text",
								label: { title: key },
								validationRules: [{ name: "required" }]
							};

							return out;
						},
						{} as Record<string, FormBuilderField>
					)
				};
			}

			if (this.dependencyOutputs.length > 0) {
				fields.fields.outputs = {
					type: "object",
					direction: "vertical",
					label: { title: "Output variable map" },
					fields: this.dependencyOutputs.reduce(
						(out, [key]) => {
							out[key] = {
								qaId: key,
								type: "text",
								label: { title: key },
								validationRules: [{ name: "required" }]
							};

							return out;
						},
						{} as Record<string, FormBuilderField>
					)
				};
			}

			return fields;
		}
	},

	mounted() {
		this.formValues = {
			dependencyName: this.dependencyName,
			inputs: this.dependencyInputs.reduce(
				(out, [key, val]) => {
					out[key] = val;
					return out;
				},
				{} as Record<string, string>
			),

			outputs: this.dependencyOutputs.reduce(
				(out, [key, val]) => {
					out[key] = val;
					return out;
				},
				{} as Record<string, string>
			)
		};
	},

	methods: {
		async saveDependency() {
			this.isSubmitting = true;
			this.errorMessage = null;

			(this.$refs.editDepFormBuilderRef as InstanceType<typeof FFormBuilder>).submit();

			const { formValues, formState } = this;

			if (!formState?.isValid || !formValues) {
				this.isSubmitting = false;
				return;
			}

			try {
				const { outputs, inputs, dependencyName } = formValues;

				// Get existing dependencies
				const appDependsOn = { ...this.app.dependsOn };

				// Delete the current dependency entry
				delete appDependsOn[this.dependencyName];

				// Map the user inputs with the keys from existing dependency and name
				appDependsOn[dependencyName] = {
					dependencyName: this.dependency?.dependencyName,
					inputs,
					outputs
				};

				// Save the form
				await applicationStore.UPDATE_DEPENDENCY({
					orgId: this.app.orgId,
					projId: this.app.projId,
					appId: this.app.id,
					dependsOn: appDependsOn
				});

				this.$emit("close");
			} catch (err) {
				captureError(err);
				this.errorMessage = getErrorMessage(err);
			} finally {
				this.isSubmitting = false;
			}
		},

		handleInput(event: CustomEvent) {
			this.formValues = event.detail;
		}
	}
});

type EditDependencyValues = {
	dependencyName: string;
	inputs?: Record<string, string>;
	outputs?: Record<string, string>;
};
</script>
