import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { environment, environmentDelete } from "@/protocol/infra";

import { envListStore } from "../env-list/env-list-store";
import { getStore } from "../store";

import { deleteEnv } from "./services/env-delete-service";

@Module({
	namespaced: true,
	dynamic: true,
	name: "envDelete",
	store: getStore()
})
class EnvDeleteStore extends VuexModule {
	envToDelete: environment | null = null;

	@Mutation
	SET_ENV_TO_DELETE(env: environment | null) {
		this.envToDelete = env;
	}

	@Action
	async DELETE_ENV({ orgId, projId, id }: environmentDelete) {
		const response = await deleteEnv({ orgId, projId, id, force: false });
		if (response.success) {
			envListStore.DELETE_ENV({ projectId: projId, envId: id });
		}
		return response;
	}
}

const envDeleteStore = getModule(EnvDeleteStore);

export { envDeleteStore };
