<template>
	<div id="toast-manager" data-qa-toast-list :data-toast-count="toastCount">
		<!--add notifications in slot-->
		<slot />
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "NotificationsWrapper",

	props: {
		toastCount: {
			type: Number,
			default: () => 0
		}
	}
});
</script>
<style lang="scss">
#toast-manager {
	position: fixed;
	overflow: auto;
	top: 52px;
	right: 0px;
	max-height: calc(100vh - 66px);
	z-index: 107;
	width: 438px;

	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: flex-end;
	// justify-content: flex-end;
	padding: 12px;

	> * {
		flex-shrink: 0;
	}

	&[data-toast-count="0"] {
		pointer-events: none;
		padding: 0px;
	}
}
</style>
