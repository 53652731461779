<template>
	<Wrapper
		data-qa-remove-members-confirm-dialog
		border-radius="4px"
		background="gray-500"
		width="480px"
		max-height="80vh"
		overflow="visible"
	>
		<Header>
			<Typography type="h4" color="dark" data-qa-remove-members-title>{{ removeTitle }}</Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Icon size="x-small" type="filled" name="i-close" @click="$emit('close')" />
			</Container>
		</Header>
		<Container padding="16px" :gap="30" align="left top" direction="column" overflow="visible">
			<Typography type="p1-para" color="dark"
				>Removing the {{ memberText }} will prevent them from interacting with {{ entityName }} on
				Code Pipes.</Typography
			>
		</Container>

		<Footer>
			<Button
				data-qa-remove-members-button
				type="error"
				size="small"
				state="full"
				:loading="isSubmitting"
				@click="removeMembers"
				>REMOVE {{ memberText }}</Button
			>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { membersStore } from "@/modules/members/members-store";
import { organization, project as projectProto } from "@/protocol/identity";
import { invite as inviteProto } from "@/protocol/user";

import { Member } from "./member-types";

export default defineComponent({
	name: "RemoveMemberConfirm",

	components: {
		Wrapper,
		Header,
		Footer,
		Container,
		Icon,
		Typography,
		Button
	},

	props: {
		org: {
			type: Object as PropType<organization>
		},

		project: {
			type: Object as PropType<projectProto>
		},

		members: {
			type: Array as PropType<Member[]>,
			required: true
		}
	},

	data: () => ({
		isSubmitting: false
	}),

	computed: {
		entityName() {
			return this.project?.name ?? this.org?.name ?? "";
		},

		removeTitle() {
			if (this.members.length === 1) {
				return `Remove ${this.members[0]!.email}`;
			}

			return `Remove ${this.members.length} members`;
		},

		memberText() {
			if (this.members.length === 1) {
				return "member";
			}

			return "members";
		},

		orgId() {
			return this.project?.orgId ?? this.org?.id ?? null;
		}
	},

	methods: {
		async removeMembers() {
			this.isSubmitting = true;

			// Grant access to members who joined
			const usersWithUpdatedPermissions = this.members
				.filter(member => member.hasJoined && member.id)
				.map(member => member.id) as string[];

			if (usersWithUpdatedPermissions.length > 0) {
				if (this.org) {
					await membersStore.REVOKE_ORG_ACCESS({
						orgId: this.org.id,
						userIds: usersWithUpdatedPermissions
					});
				} else if (this.project) {
					await membersStore.REVOKE_PROJECT_ACCESS({
						orgId: this.project.orgId,
						projId: this.project.id,
						userIds: usersWithUpdatedPermissions
					});
				}
			}

			// Update invited for members who didn't
			const usersWithUpdatedInvites = this.members.filter(member => !member.hasJoined);

			if (this.orgId && usersWithUpdatedInvites.length > 0) {
				const invite: Omit<inviteProto, "role"> = {};

				if (this.org) {
					invite.org = { id: this.org.id };
				} else if (this.project) {
					invite.project = { id: this.project.id, orgId: this.project.orgId };
				}

				await membersStore.USER_INVITATION_ACTION({
					orgId: this.orgId,
					requests: usersWithUpdatedInvites.map(({ email, role }) => {
						if (!email || !role) {
							throw new Error("No email or role found for removal of member");
						}

						return {
							email,
							invite: {
								...invite,
								role: role.id
							}
						};
					}),
					actionType: "remove"
				});

				await membersStore.FETCH_USERS({
					orgId: this.orgId,
					invite
				});
			}

			this.isSubmitting = false;

			this.$emit("close");
		}
	}
});
</script>
