<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		width="480px"
		max-height="80vh"
		data-qa-project-create-popover
	>
		<Header>
			<Typography type="h4" color="dark">{{
				project ? "Edit project" : "Create new project"
			}}</Typography>
			<Container align="right center" :grow="1" :padding="0">
				<Icon
					v-tooltip="{
						content:
							'A project is like a directory or a folder. With a project, you can manage your applications, their deployments and environments.'
					}"
					name="i-question-filled"
					color="primary-100"
					size="small" />
				<Icon
					size="x-small"
					type="filled"
					name="i-close"
					data-qa-close-create-project-popover
					@click="closeCreateProject()"
			/></Container>
		</Header>
		<Container :padding="16" :gap="16" align="left top" direction="column">
			<f-form-builder
				ref="CreateProjectFormBuilder"
				data-qa-add-bundle-model-form
				:field.prop="formFields"
				:values.prop="formValues"
				@input="handleInput"
				@state-change="formState = $event.detail"
			/>
			<f-text v-if="submitError" state="danger">{{ submitError }}</f-text>
		</Container>

		<Footer>
			<Button
				state="full"
				type="success"
				:disabled="!formState?.isValid"
				data-qa-create-project-submit-btn
				:loading="createProjInProgress"
				@click="createOrEditProject()"
			>
				{{ project ? "UPDATE PROJECT" : "CREATE PROJECT" }}
			</Button>
		</Footer>
	</Wrapper>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { project as projectProto } from "@/protocol/identity";
import { MARKDOWN_HELPER_TOOLTIP } from "@/shared/constants";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import { captureError, getEmoji, getErrorMessage } from "@/utils";

import { orgStore } from "../org/org-store";
import { policyListStore } from "../policy-list/policy-list-store";
import { projectStore } from "../project-list/project-store";

type FormFieldValues = {
	projectnameAndIcon: { name?: string | null; emoji?: string | null };
	__showDescription__: boolean;
	description?: string | null;
};

export default defineComponent({
	name: "ProjectCreatePopover",

	components: {
		Icon,
		Typography,
		Container,
		Button,
		Footer,
		Header,
		Wrapper
	},

	props: {
		project: {
			type: Object as PropType<projectProto | undefined>,
			default: () => null
		}
	},

	data() {
		return {
			createProjInProgress: false,

			submitError: "",

			formValues: {
				projectnameAndIcon: {
					name: this.project?.name ?? null,
					emoji: getEmoji(this.project, "🗂️")
				},

				description: this.project?.description
			} as FormFieldValues,

			formState: null as FormBuilderState | null
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					projectnameAndIcon: {
						type: "object",
						direction: "horizontal",
						fields: {
							name: {
								type: "text",
								label: { title: "Project name" },
								qaId: "projectname",
								placeholder: "Enter project name",
								validationRules: applyEntityNameRules2(this.existingProjNames)
							},

							emoji: {
								type: "emoji",
								label: { title: "Icon" },
								qaId: "emoji",
								placeholder: "🗂️",
								clear: true
							}
						}
					},

					addDescription: {
						type: "button",
						label: "Project description",
						iconLeft: "i-plus",
						category: "transparent",
						size: "small",
						qaId: "addDescription",
						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: true };
						},

						showWhen(values) {
							const formValues = values as FormFieldValues;
							return !formValues.__showDescription__ && !formValues.description;
						}
					},

					__showDescription__: {
						type: "hidden"
					},

					description: {
						type: "textarea",
						label: { title: "Project description", iconTooltip: MARKDOWN_HELPER_TOOLTIP },
						qaId: "projectdescription",
						placeholder: "Add a brief description about your project",
						showWhen(values) {
							const formValues = values as FormFieldValues;
							return Boolean(
								(typeof formValues.description === "string" && formValues.description.length > 0) ||
									formValues.__showDescription__
							);
						}
					},

					removeDescription: {
						type: "button",
						qaId: "removeDescription",
						label: "Remove project description",
						iconLeft: "i-close",
						category: "transparent",
						state: "danger",
						size: "small",
						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: false, description: "" };
						},

						showWhen(values) {
							const formValues = values as FormFieldValues;
							return Boolean(formValues.__showDescription__ || formValues.description);
						}
					}
				}
			};
		},

		activeOrgId() {
			return orgStore.activeOrgId;
		},

		policySets() {
			return policyListStore.policySets;
		},

		existingProjNames(): string[] {
			const { project } = this;
			if (project) {
				return Object.values(projectStore.projects)
					.filter(p => p.id !== project.id)
					.map(p => p.name);
			}

			return Object.values(projectStore.projects).map((proj: projectProto) => proj.name);
		}
	},

	methods: {
		async createOrEditProject() {
			this.createProjInProgress = true;

			(this.$refs.CreateProjectFormBuilder as FFormBuilder).submit();

			if (!this.formState?.isValid) {
				this.createProjInProgress = false;
				return;
			}

			try {
				/**
				 * We set the default control gate whichever has the default flag set to true.
				 */
				const bestPracticePolicy = this.policySets.find(policySet => policySet.metadata?.default);

				const defaultPolicySet = bestPracticePolicy ?? this.policySets[0];

				if (!defaultPolicySet) {
					throw new Error("defaultPolicySet not found");
				}

				const { project } = this;

				// Create a project
				if (!project) {
					const payload = {
						orgId: this.activeOrgId as string,
						name: this.formValues.projectnameAndIcon.name as string,
						description: this.formValues.description ?? "",
						policySetId: defaultPolicySet.id,
						metadata: {
							emojiObj: {
								native: this.formValues.projectnameAndIcon.emoji
							}
						},

						variables: []
					};

					const projectObj = await projectStore.CREATE_NEW_PROJECT(payload);

					await this.$router.push({
						name: "projectListWithProject",
						params: { orgId: this.activeOrgId as string, projectId: projectObj.id }
					});
					// Edit a project
				} else {
					const payload = {
						orgId: project.orgId,
						name: this.formValues.projectnameAndIcon.name ?? project.name,
						description: this.formValues.description ?? project.description ?? "",
						policySetId: project.policySetId ?? defaultPolicySet.id ?? "",
						metadata: {
							emojiObj: {
								native: this.formValues.projectnameAndIcon.emoji
							}
						},

						variables: []
					};

					await projectStore.EDIT_PROJECT({
						...payload,
						replaceVariables: false,
						id: project.id
					});
				}

				this.$emit("close");
			} catch (err) {
				captureError(err);
				this.submitError = getErrorMessage(err);
			} finally {
				this.createProjInProgress = false;
			}
		},

		handleInput(input: CustomEvent<FormFieldValues>) {
			const value = input.detail;
			this.formValues = { ...this.formValues, ...value };
		},

		closeCreateProject() {
			this.$emit("close", true);
		}
	}
});
</script>
