<template>
	<Container direction="column" :padding="0" :gap="0" align="left top" :shrink="0">
		<Accordion :open="isAccordionOpen">
			<template #header>
				<Container
					align="space-between center"
					overflow="visible"
					padding="20px 8px"
					:clickable="!isAccordionOpen"
					@click="isAccordionOpen = !isAccordionOpen"
				>
					<Container :padding="0" :gap="8">
						<Icon name="i-file-code" size="small" :effects="false" color="gray-200" />
						<Typography type="h4" weight="medium" color="dark">App dependencies</Typography>

						<AddDependencyPopover :app="app" :open="showAddDependency" @close="toggleAddDependency">
							<Button
								v-tooltip="{
									content: 'New dependency'
								}"
								state="icon"
								size="x-small"
								data-qa-add-dependency
								@click.stop="toggleAddDependency"
							>
								<Icon name="i-plus" color="gray-600" size="x-small" :effects="false" />
							</Button>
						</AddDependencyPopover>

						<EditDependencyPopover
							v-if="editedDependencyName"
							:app="app"
							:dependency-name="editedDependencyName"
							:open="Boolean(editedDependencyName)"
							:target="`[data-qa-edit-dependency='${editedDependencyName}']`"
							@close="toggleEditDependency"
						/>

						<DeleteDependencyPopover
							v-if="deletedDependencyName"
							:app="app"
							:dependency-name="deletedDependencyName"
							:open="Boolean(deletedDependencyName)"
							:target="`[data-qa-delete-dependency='${deletedDependencyName}']`"
							@close="toggleDeleteDependency"
						/>
					</Container>
					<Icon
						name="i-chevron-down"
						size="x-small"
						:rotate="isAccordionOpen ? 180 : 0"
						@click.stop="isAccordionOpen = !isAccordionOpen"
					/>
				</Container>
			</template>

			<Container padding="0" direction="column" :gap="0">
				<Container
					v-if="dependencies && dependencies.length < 1"
					align="center"
					direction="column"
					class="align-items-center height-100-per"
					:gap="12"
					:grow="1"
					data-qa-empty-dependencies
				>
					<Icon name="i-launch" type="filled" state="light" :effects="false" />
					<Typography type="p1" color="light">No dependencies available</Typography>
				</Container>
				<Container
					v-else
					direction="column"
					:padding="0"
					overflow="auto"
					align="left top"
					:shrink="0"
					:gap="0"
				>
					<Container padding="0 0 0 16px"> <Divider type="dashed" /> </Container>
					<template v-for="dependency in dependencies" :key="dependency.name">
						<Accordion
							:open="(dependency.name && toggledAccordions[dependency.name]) || false"
							:data-qa-dependency="dependency.name"
						>
							<template #header>
								<Container
									padding="0 0 0 16px"
									align="left center"
									:clickable="true"
									@click="toggledAccordions[dependency.name] = !toggledAccordions[dependency.name]"
								>
									<Slab
										size="large"
										type="transparent"
										border-type="dashed"
										:align-items="true ? 'start' : 'center'"
									>
										<Container :gap="4" direction="column">
											<Typography
												:data-qa-dependency-name="dependency.name"
												type="p2"
												color="dark"
												>{{ dependency.name }}</Typography
											>
										</Container>
										<template #secondary-actions>
											<Container
												:class="
													editedDependencyName || deletedDependencyName ? '' : 'show-on-hover'
												"
											>
												<Icon
													v-tooltip="{
														content: 'Edit dependency'
													}"
													name="i-edit"
													size="small"
													:data-qa-edit-dependency="dependency.name"
													@click.stop="toggleEditDependency(dependency.name)"
												/>

												<Icon
													v-tooltip="{
														content: 'Delete dependency'
													}"
													type="filled"
													name="i-delete"
													size="small"
													:data-qa-delete-dependency="dependency.name"
													@click.stop="toggleDeleteDependency(dependency.name)"
												/>

												<Icon
													name="i-chevron-down"
													size="xx-small"
													state="dark"
													:effects="false"
													:rotate="toggledAccordions[dependency.name] ? 180 : 0"
												/>
											</Container>
										</template>
									</Slab>
								</Container>
							</template>

							<Container padding="0px 16px 16px 46px" direction="column">
								<template v-if="dependency.outputs.length > 0 || dependency.inputs.length > 0">
									<Typography v-if="dependency.outputs.length > 0">Outputs</Typography>
									<Container
										v-for="output in dependency.outputs"
										:key="'output' + output"
										:data-qa-dependency-output="output"
										padding="0"
									>
										<Typography
											family="logs"
											:data-qa-dependency-name="output"
											type="p2"
											color="gray-200"
											>{{ output[0] }} -> {{ output[1] }}</Typography
										>
									</Container>

									<Container padding="16px 0 0 0">
										<Typography v-if="dependency.inputs.length > 0">Inputs</Typography>
									</Container>
									<Container
										v-for="input in dependency.inputs"
										:key="'input' + input"
										:data-qa-dependency-input="input"
										padding="0"
									>
										<Typography
											v-if="input[1]"
											family="logs"
											:data-qa-dependency-name="input"
											type="p2"
											color="gray-200"
											>{{ input[0] }} = {{ input[1] }}</Typography
										>
										<Typography
											v-else
											family="logs"
											:data-qa-dependency-name="input"
											type="p2"
											color="gray-200"
											>{{ input[0] }}</Typography
										>
									</Container>
								</template>
							</Container>
						</Accordion>
					</template>
				</Container>
			</Container>
		</Accordion>
		<Divider />
	</Container>
</template>

<script lang="ts">
import { Accordion, Button, Container, Divider, Icon, Slab, Typography } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import AddDependencyPopover from "@/modules/application/components/AddDependencyPopover.vue";
import DeleteDependencyPopover from "@/modules/application/components/DeleteDependencyPopover.vue";
import EditDependencyPopover from "@/modules/application/components/EditDependencyPopover.vue";
import { app as appProto } from "@/protocol/app";

export default defineComponent({
	name: "ApplicationsTabDependencies",

	components: {
		AddDependencyPopover,
		EditDependencyPopover,
		DeleteDependencyPopover,
		Accordion,
		Button,
		Container,
		Divider,
		Icon,
		Typography,
		Slab
	},

	props: {
		app: {
			type: Object as PropType<appProto>,
			required: true
		}
	},

	data: () => ({
		isAccordionOpen: false,
		showAddDependency: false,
		editedDependencyName: null as string | null,
		deletedDependencyName: null as string | null,
		toggledAccordions: {} as Record<string, boolean>
	}),

	computed: {
		dependencies() {
			return Object.entries(this.app.dependsOn ?? {}).map(([key, value]) => {
				return {
					name: key,
					inputs: Object.entries(value.inputs ?? {}),
					outputs: Object.entries(value.outputs ?? {})
				};
			});
		}
	},

	methods: {
		toggleAddDependency() {
			this.showAddDependency = !this.showAddDependency;
		},

		toggleEditDependency(dependencyName?: string) {
			this.editedDependencyName = dependencyName ?? null;
		},

		toggleDeleteDependency(dependencyName?: string) {
			this.deletedDependencyName = dependencyName ?? null;
		}
	}
});
</script>
