<template>
	<Container padding="20px 0px 0px 0px" separator :grow="1" align-self="stretch">
		<Wrapper height="100%">
			<Container direction="row" :padding="0" :gap="30">
				<Container :padding="0">
					<Typography weight="light" type="p3">Promotion pipeline</Typography>
					<Icon
						v-tooltip="{
							content:
								'Promotion pipeline lets you connect multiple environments and promote apps between them.',
							placement: 'bottom-start'
						}"
						name="i-info-fill"
						type="filled"
						size="x-small"
						:effects="false"
						color="gray-300"
					/>
				</Container>
				<Container v-for="(env, idx) of promotionSeqEnvs" :key="env.id" :padding="0" :gap="18">
					<Container :padding="0">
						<Pictogram shape="hexagon" state="default" size="x-small">
							<Icon
								:name="getClassificationIcon(env.classification)"
								type="filled"
								:effects="false"
								size="xx-small"
							/>
						</Pictogram>
						<Typography weight="light" type="p2">{{ env.name }}</Typography>
					</Container>
					<Container v-if="idx < promotionSeqEnvs.length - 1" :padding="0">
						<Icon
							name="i-arrow-right"
							type="filled"
							size="x-small"
							:effects="false"
							color="gray-300"
						/>
					</Container>
				</Container>
			</Container>
		</Wrapper>
	</Container>
</template>

<script lang="ts">
import { Container, Icon, Pictogram, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { environment } from "@/protocol/infra";
import { getClassificationIcon } from "@/utils";

export default defineComponent({
	name: "CodePromotionSequence",

	components: {
		Pictogram,
		Container,
		Typography,
		Wrapper,
		Icon
	},

	props: {
		promotionSeqEnvs: {
			type: Array as PropType<environment[]>,
			required: true
		}
	},

	methods: {
		getClassificationIcon
	}
});
</script>
