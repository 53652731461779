<template>
	<f-popover
		:open="openOrgSwitcher"
		size="stretch"
		placement="left-start"
		style="max-height: unset; background: transparent"
	>
		<f-div direction="row" width="fill-container" height="100vh" gap="large" align="middle-center">
			<f-div
				v-if="showInfo"
				width="320px"
				state="tertiary"
				padding="medium"
				height="hug-content"
				gap="none"
				direction="column"
			>
				<f-div padding="none none large none">
					<f-text variant="heading" size="medium" :data-qa-info-title="infoText?.title ?? ''">{{
						infoText?.title ?? ""
					}}</f-text>
				</f-div>
				<f-div padding="none none large none" gap="none" direction="column">
					<template v-for="text in infoText?.paragraphs" :key="text">
						<f-div padding="none none medium none" height="hug-content" gap="medium">
							<f-text variant="para" size="medium" weight="regular" data-qa-welcome-popover-text>
								{{ text }}
							</f-text>
						</f-div>
					</template>
				</f-div>
				<f-div>
					<f-button
						label="Got it"
						variant="round"
						category="fill"
						size="medium"
						state="primary"
						align="bottom-left"
						data-qa-info-popover-close-btn
						icon-left="👍"
						@click.stop="toggleWelcome"
					>
					</f-button>
				</f-div>
			</f-div>

			<!-- Checklist content -->
			<ChecklistSteps
				v-if="selectedStep === checkListFlowEnum.NOT_SELECTED"
				:checklist-flags="checklistFlags"
				:popover-content="popoverContent"
				:steps="steps"
				@step-clicked="stepClicked"
				@open-welcome="toggleWelcome"
				@close-flow="closeAppFirstFlow()"
			/>

			<ImportOrgBundle
				v-if="selectedStep === checkListFlowEnum.ORG"
				:is-oauth-complete="isOauthComplete"
				@back="selectedStep = checkListFlowEnum.NOT_SELECTED"
				@toggle-welcome="toggleWelcome"
				@close-flow="closeAppFirstFlow()"
			/>
		</f-div>
	</f-popover>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import { userStore } from "../user/user-store";

import ChecklistSteps from "./ChecklistSteps.vue";
import ImportOrgBundle from "./ImportOrgBundle.vue";
import { orgStore } from "./org-store";
import {
	APP_FIRST_FLOW_CHECKLIST_STEPS,
	APP_FIRST_FLOW_ORG_CREATE_INFO_POPOVERS
} from "./orgCreateFlowSteps";

export enum OrgChecklistFlowSteps {
	NOT_SELECTED = 0,
	ORG = 1,
	CRED = 2,
	PROJECT = 3
}

export default defineComponent({
	name: "OrgBundleImportWrapper",
	components: { ChecklistSteps, ImportOrgBundle },

	props: {
		isOauthComplete: {
			type: Boolean,
			default: false
		},

		openOrgSwitcher: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		showInfo: true,

		stepsMeta: APP_FIRST_FLOW_ORG_CREATE_INFO_POPOVERS,
		defaultChecklistSteps: APP_FIRST_FLOW_CHECKLIST_STEPS,
		popoverContent: {
			title: "Account setup",
			desc: "To begin, we need to have an organization. This allows you to easily manage projects, credentials, environments, and teams. Learn more about organization."
		},

		popperTarget: "checklistMain",

		selectedStep: OrgChecklistFlowSteps.NOT_SELECTED,
		checkListFlowEnum: OrgChecklistFlowSteps
	}),

	computed: {
		currentStep() {
			return this.stepsMeta[this.selectedStep];
		},

		infoText() {
			return this.currentStep?.infoContent;
		},

		checklistFlags() {
			return userStore.profile?.metadata?.checkListFlow;
		},

		orgId() {
			return this.$route.params.orgId as string;
		},

		hasCreatedOrgs() {
			return orgStore.GET_ORGS.length > 1;
		},

		/**
		 * @description - This is the main checklist steps that are rendered in the popover
		 * @returns {ChecklistStep[]}
		 */
		steps() {
			// clone the default steps to avoid mutating the original array
			return [...this.defaultChecklistSteps].slice().map(step => {
				return {
					...step,
					disabled: step.stepNo !== 1 && !this.hasCreatedOrgs, // disable all steps except the first one if the user has not created any orgs
					completed: Boolean(this.checklistFlags?.[step.stepName]) // check if the step is completed
				};
			});
		}
	},

	mounted() {
		this.initializeModal();
	},

	methods: {
		initializeModal() {
			// If the user has completed the oauth flow, then we need to skip the first step
			if (this.isOauthComplete) {
				this.selectedStep = OrgChecklistFlowSteps.ORG;
				this.showInfo = false;
			}
			// set the popper target as the modal is dynamically rendered and the target is not available on mount
			setTimeout(() => {
				this.popperTarget = "checklistMain";
			}, 100);
		},

		stepClicked(stepNo: OrgChecklistFlowSteps) {
			this.selectedStep = stepNo;
			switch (stepNo) {
				case OrgChecklistFlowSteps.ORG:
					setTimeout(() => {
						this.popperTarget = this.currentStep?.target ?? "#";
						this.showInfo = true;
					}, 10);
					break;
				case OrgChecklistFlowSteps.CRED:
					orgStore.SET_APP_FIRST_FLOW(false);
					this.$router
						.push({ name: "org-settings-global", params: { orgId: this.orgId } })
						.catch(err => {
							throw new Error(err);
						});
					break;
				case OrgChecklistFlowSteps.PROJECT:
					orgStore.SET_APP_FIRST_FLOW(false);
					if (this.$route.name !== "home") {
						this.$router.push({ name: "home", params: { orgId: this.orgId } }).catch(err => {
							throw new Error(err);
						});
					}
					break;
				default:
					break;
			}
		},

		// Toggle welcome popover
		toggleWelcome() {
			this.showInfo = !this.showInfo;

			if (this.currentStep?.stepNo === 1) {
				this.popperTarget = "checklistMain";
			} else {
				// as the main popover changes we need to use setTimeout to wait for the DOM to update
				setTimeout(() => {
					this.popperTarget = this.currentStep?.target ?? "#";
				}, 100);
			}
		},

		closeAppFirstFlow() {
			this.selectedStep = OrgChecklistFlowSteps.NOT_SELECTED;
			this.$emit("close-app-flow");
		}
	}
});
</script>
