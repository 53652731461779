<template>
	<Wrapper max-height="70vh" data-qa-aws-cred-model>
		<!--START : PopOver header-->
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			class="height-100-per flex-shrink-0"
		>
			<Header>
				<Icon
					v-if="showBackBtn"
					name="i-arrow-left"
					size="small"
					data-qa-aws-cred-model-back-icon-btn
					@click="validateAndCloseModal('back')"
				/>
				<Typography type="h4" color="dark" data-qa-aws-cred-model-title>{{ headerTxt }}</Typography>
				<Container :padding="0" :grow="1" align="right center">
					<Icon
						name="i-question-filled"
						type="filled"
						size="x-small"
						data-qa-aws-cred-model-help-btn
						@click="openHelp"
					/>
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-aws-cred-model-close-btn
						@click="validateAndCloseModal"
					/>
				</Container>
			</Header>
			<ClosePopoverConfirmationWarning
				ref="closeConfirmation"
				:initial-form-values="initialformValues"
				:form-values="awsCredFormValues"
				@force-close="closeModal"
			/>

			<ModalNotificationBanner
				v-if="cred"
				banner-type="primary"
				banner-body="Changes made are applicable to the future deployments."
			/>

			<ModalNotificationBanner
				v-if="submitError"
				banner-type="error"
				:banner-body="submitError"
				:show-close-icon="true"
				@close="submitError = null"
			/>

			<!--END : PopOver header-->

			<Container overflow="auto" align="center top" direction="column" :grow="1" :padding="16">
				<f-form-builder
					ref="formBuilder"
					data-qa-aws-cred-form
					:field.prop="awsCredentialTypeForm"
					:values.prop="awsCredFormValues"
					@input="saveFormValues"
					@state-change="formState = $event.detail"
				/>
			</Container>

			<Footer>
				<Button
					state="full"
					:disabled="isSubmitting"
					:loading="isSubmitting"
					:type="isSubmitting ? 'default' : 'success'"
					data-qa-aws-cred-model-save-btn
					@click="saveAwsCredentials"
					>{{ ctaBtnTxt }}</Button
				>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FFormBuilder, FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { awsRegions, AwsRegionType } from "@/assets/aws_regions";
import { credentialStore } from "@/modules/credentials/credential-store";
import { ClassificationType } from "@/modules/credentials/credential-types";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { Creds, CredsCreate, credsType, awsCreds, CredsUpdate } from "@/protocol/identity";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import {
	applyEntityNameRules2,
	awsArnRule,
	exactLengthRuleGenerator
} from "@/shared/custom-validation-rules/entityNameRules";
import {
	captureError,
	getClassificationFormField,
	getClassificationFormValue,
	getErrorMessage
} from "@/utils";

export default defineComponent({
	name: "AwsCredentialModel",

	components: {
		ClosePopoverConfirmationWarning,
		ModalNotificationBanner,
		Typography,
		Container,
		Wrapper,
		Footer,
		Header,
		Button,
		Icon
	},

	props: {
		assignCred: {
			type: Boolean
		},

		showBackBtn: {
			type: Boolean
		},

		updateCredList: {
			type: Boolean
		},

		cred: {
			type: Object as PropType<Creds>
		},

		allowedClassificationIds: {
			type: Array as PropType<string[]>
		}
	},

	data() {
		const addAwsWithArn: AddCredentialWithItem = {
			data: { id: "arn" },
			title: "role ARN"
		};

		const addAwsWithKeySecret: AddCredentialWithItem = {
			data: { id: "keySecret" },
			title: "key and secret"
		} as AddCredentialWithItem;

		let addCredentialWith: AddCredentialWithItem | undefined;

		if (this.cred?.aws?.awsSecretKeyOpts) {
			addCredentialWith = addAwsWithKeySecret;
		}

		let region: AwsRegionType | undefined;
		if (this.cred?.aws?.region) {
			region = awsRegions.find(_region => _region.data.id === this.cred?.aws?.region);
		}

		const awsCredFormValues: LocalAwsCredFormValues = {
			addCredentialWith: addCredentialWith ? addCredentialWith : addAwsWithArn,
			region
		};

		return {
			formState: null as FormBuilderState | null,
			isSubmitting: false,
			submitError: null as string | null,
			existingCredNames: [] as string[],
			// awsArnRule,
			// regions,
			addAwsWithArn,
			addAwsWithKeySecret,
			addCredentialWithOptions: [addAwsWithArn, addAwsWithKeySecret],
			awsCredFormValues,
			initialformValues: awsCredFormValues
		};
	},

	computed: {
		ctaBtnTxt() {
			if (this.assignCred) {
				return "Assign credential";
			}
			return "Save AWS credential";
		},

		headerTxt() {
			if (this.assignCred) {
				return "Assign credential";
			} else if (this.cred) {
				return "Edit AWS credential";
			}
			return "Add AWS credential";
		},

		orgId() {
			return this.$route.params.orgId as string;
		},

		awsCredentialTypeForm(): FormBuilderField {
			const showArn = this.awsCredFormValues.addCredentialWith.data.id === "arn";

			return {
				type: "object",
				direction: "vertical",
				fields: {
					addCredentialWith: {
						type: "select",
						id: "aws-cred-type-select-field",
						options: this.addCredentialWithOptions,
						validationRules: [{ name: "required" }],
						label: { title: " " },
						helperText: this.cred ? "You cannot change the credential type while editting" : "",
						disabled: this.cred ? true : false,

						//@ts-expect-error
						optionTemplate: (option: FSelectOptionObject) => html`
							<f-text inline data-qa-aws-cred-form-add-cred-with-selected-option="${option.title}"
								>Add with <f-text inline weight="bold">${option.title}</f-text></f-text
							>
						`
					},

					__separator__: {
						type: "separator"
					},

					arn: {
						validationRules: [{ name: "required" }, awsArnRule],
						label: { title: "AWS resource name" },
						type: "text",
						id: "arn",
						placeholder: "Enter ARN",
						showWhen: () => showArn,
						helperText: html`
							<f-text state="secondary"
								>ARN should follow the following format:
								<f-text state="secondary" variant="code">
									arn:aws:iam::&lt;account_ID&gt;:role/&lt;role-name&gt;
								</f-text>
							</f-text>
						`
					},

					key: {
						type: "text",
						// @todo VN-2728 - This breaks Safari as it doesn't support look-ahead and behind regex yet
						// regex: /(?<![A-Z0-9])[A-Z0-9]{20}(?![A-Z0-9])/
						validationRules: [{ name: "required" }, exactLengthRuleGenerator(20, "AWS access key")],

						label: {
							title: "AWS Key",
							subTitle: `${this.awsCredFormValues.key?.length ?? 0} / 20`
						},

						id: "key",
						placeholder: "Enter AWS access key",
						showWhen: () => !showArn
					},

					secret: {
						type: "password",
						// @todo VN-2728 - This breaks Safari as it doesn't support look-ahead and behind regex yet
						// regex: /(?<![A-Za-z0-9/+=])[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=])/
						validationRules: [{ name: "required" }, exactLengthRuleGenerator(40, "AWS secret key")],
						label: {
							title: "AWS Secret",
							subTitle: `${this.awsCredFormValues.secret?.length ?? 0} / 40`
						},

						id: "secret",
						placeholder: "Enter AWS secret key",
						showWhen: () => !showArn
					},

					region: {
						type: "select",
						validationRules: [{ name: "required" }],
						label: { title: "Region", iconTooltip: "You can change the region later." },
						options: awsRegions,
						id: "region",
						placeholder: "Select region",

						//@ts-expect-error
						optionTemplate: (option: AwsRegionType) => html`
							<f-text data-qa-option="${option.data.id}">${option.title}</f-text>
						`
					},

					__separator2__: {
						type: "separator"
					},

					...getClassificationFormField(this.cred, this.allowedClassificationIds),

					name: {
						type: "text",
						validationRules: [
							...applyEntityNameRules2(this.existingCredNames, "This display name already exists.")
						],

						label: {
							title: "Display name",
							iconTooltip: "This name will be used to refer to your saved credential on Code Pipes"
						},

						id: "name",
						placeholder: "Enter display name"
					},

					__separator3__: {
						type: "separator",
						showWhen: () => showArn
					},

					checkbox: {
						type: "checkbox",
						label: {
							title: html`<f-text>
								<a
									rel="noopener"
									href="https://docs.codepipes.io/docs/configure-role-arn-credentials"
									target="_blank"
								>
									Learn how to configure your ARN credentials.
								</a>
							</f-text>`
						},

						options: [
							{
								id: "aws-arn-checkbox-terms",
								title: html`<f-text size="small" data-qa-id="aws-arn-checkbox-term"
									>I have designated my AWS account with Code Pipes Account ID and Organization
									ID.</f-text
								>`
							}
						],

						validationRules: [
							{
								name: "required",
								message:
									"Please make sure you have designated your AWS account with Code Pipes Account ID and Organization ID."
							}
						],

						id: "checkbox",
						showWhen: () => showArn
					}
				}
			};
		}
	},

	mounted() {
		this.existingCredNames = credentialStore.existingCredentialNames.filter(_credName =>
			this.cred ? this.cred.name !== _credName : true
		);

		this.preFillEditFormValues();
	},

	methods: {
		preFillEditFormValues() {
			if (!this.cred) {
				this.initialformValues = this.awsCredFormValues;
				return;
			}

			// finding the region object from the stored list.
			const region: AwsRegionType = awsRegions.find(
				_region => _region.data.id === this.cred?.aws?.region
			) ?? { data: { id: "" }, title: "" };

			let preFilledValues: LocalAwsCredFormValues = {
				addCredentialWith: this.addAwsWithArn,
				name: this.cred.name,
				region
			};

			// if cred type is arn then pre-filling the
			//addCredentialWith field arn.
			if (this.cred.aws?.awsRoleOpts) {
				preFilledValues = {
					...preFilledValues,
					arn: this.cred.aws.awsRoleOpts.roleARN
				};
			}

			// if cred type is keySecret then pre-filling the
			//addCredentialWith field.
			if (this.cred.aws?.awsSecretKeyOpts) {
				preFilledValues = {
					...preFilledValues,
					addCredentialWith: this.addAwsWithKeySecret,
					key: this.cred.aws.awsSecretKeyOpts.accessKey
				};
			}
			preFilledValues = {
				...preFilledValues,
				...(this.cred.classificationId && getClassificationFormValue(this.cred.classificationId))
			};

			this.initialformValues = this.saveFormValues(
				new CustomEvent("input", { detail: preFilledValues })
			);
		},

		// eslint-disable-next-line max-statements
		async saveAwsCredentials() {
			if (this.isSubmitting) {
				return null;
			}
			this.submitError = "";
			this.isSubmitting = true;

			(this.$refs.formBuilder as FFormBuilder).submit();

			if (!this.formState?.isValid) {
				this.isSubmitting = false;
				return null;
			}

			try {
				let cred: Creds | null = null;
				/**
				 * form builder validateForm ensures that the form always have valid values.
				 */
				const awsPayload: awsCreds = {
					region: this.awsCredFormValues.region!.data.id,
					// API doesn't update the value of accountId from the request payload, so we
					// can sent empty values.
					accountId: ""
				};
				if (this.awsCredFormValues.addCredentialWith.data.id === "keySecret") {
					awsPayload.awsSecretKeyOpts = {
						accessKey: this.awsCredFormValues.key!,
						secretKey: this.awsCredFormValues.secret!
					};
				}

				if (this.awsCredFormValues.addCredentialWith.data.id === "arn") {
					awsPayload.awsRoleOpts = {
						roleARN: this.awsCredFormValues.arn!,
						// API doesn't update the value of externalId from the request payload, so we
						// can sent empty values.
						externalId: ""
					};
				}

				if (this.cred) {
					const payload: CredsUpdate = {
						id: this.cred.id,
						name: this.awsCredFormValues.name!,
						orgId: this.orgId,
						type: credsType.credsType_aws,
						aws: awsPayload,
						// API doesn't update the value of displayId and isNotSensitive from the request payload, so we
						// can sent empty values.
						displayId: "",
						isNotSensitive: false
					};

					await credentialStore.updateCredential({ cred: payload });

					// Get latest cred object and update the store.
					credentialStore.GET_UPDATE_CREDENTIAL({ orgId: this.orgId, id: this.cred.id });
				} else {
					let payload: CredsCreate = {
						name: this.awsCredFormValues.name!,
						orgId: this.orgId,
						type: credsType.credsType_aws,
						aws: awsPayload
					};
					if (this.awsCredFormValues.classification) {
						payload = {
							...payload,
							classificationId: this.awsCredFormValues.classification.data.id
						};
					}

					cred = await credentialStore.createCredential({ cred: payload });

					// Update the credential list
					if ("id" in cred) {
						credentialStore.UPDATE_CRED_AND_STATS_BY_ID(cred);
					}
				}

				if (this.updateCredList) {
					if (this.cred) {
						notificationsStore.ADD_TOAST({
							qaId: "toast-cloud-cred-aws-edited-successfully",
							title: "Cloud credential updated",
							text: `Credential ${this.awsCredFormValues.name} is successfully updated.`,
							status: "success"
						});
					} else {
						notificationsStore.ADD_TOAST({
							qaId: "toast-cloud-cred-aws-added-successfully",
							title: "New credential added",
							text: `New credential ${this.awsCredFormValues.name} is successfully added.`,
							status: "success"
						});
					}

					this.closeModal();
				}
				this.$emit("onCredCreate", cred);
			} catch (err) {
				this.submitError = getErrorMessage(err);
				captureError(err);
			}

			this.isSubmitting = false;
			return null;
		},

		saveFormValues(event: CustomEvent<LocalAwsCredFormValues>) {
			const values = event.detail;

			this.awsCredFormValues = {
				addCredentialWith: values.addCredentialWith,
				region: values.region,
				name: values.name,
				key: values.key ?? this.awsCredFormValues.key,
				secret: values.secret ?? this.awsCredFormValues.secret,
				arn: values.arn ?? this.awsCredFormValues.arn,
				classification: values.classification
			};

			return this.awsCredFormValues;
		},

		closeModal() {
			this.$emit("closeModal");
		},

		validateAndCloseModal(eventType: "back" | "closeModal" = "closeModal") {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				if (eventType === "back") {
					return this.$emit("back");
				}
				return this.closeModal();
			}
			return;
		},

		openHelp() {
			window.open("https://docs.codepipes.io/docs/cloud-sources#aws", "_blank");
		}
	}
});

type AddCredentialWithItem = FSelectOptionObject & {
	data: { id: string }; //"arn" | "keySecret";
};

type LocalAwsCredFormValues = {
	addCredentialWith: AddCredentialWithItem;
	arn?: string;
	key?: string;
	secret?: string;
	region?: AwsRegionType;
	name?: string;
	classification?: ClassificationType;
};
</script>
