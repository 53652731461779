<template>
	<Container
		direction="column"
		padding="20px 0"
		:gap="20"
		:grow="1"
		overflow="visible"
		class="height-100-per"
		align="left top"
	>
		<Container
			data-qa-inputs-tab-header
			:padding="0"
			overflow="visible"
			align="space-between top"
			class="dashboard-input"
		>
			<Container :padding="0" :gap="12" overflow="visible">
				<Typography type="h4" weight="bold" color="gray-200" transform="capitalize">{{
					selectedTab
				}}</Typography>
			</Container>
			<Container :padding="0" :gap="12" class="dashboard-input">
				<SearchInput
					v-model:value="searchString"
					data-qa-used-tab-search
					placeholder="Search apps"
					class="width-250"
					size="small"
				/>
			</Container>
		</Container>
		<EmptyInputOutput v-if="isEmpty" :from-search="false" selected-tab="apps" />
		<Container
			v-else
			direction="column"
			padding="0px 0px 50px 0px"
			align="left top"
			:grow="1"
			:gap="36"
			overflow="auto"
		>
			<Table border="header">
				<template #header>
					<TableCell :hover="false">
						<Typography type="p2" color="gray-300">Apps</Typography>
					</TableCell>
					<TableCell :hover="false">
						<Typography type="p2" color="gray-300">Environments</Typography>
					</TableCell>
					<TableCell :hover="false">
						<Typography type="p2" color="gray-300">Projects</Typography>
					</TableCell>
				</template>
				<template #body>
					<!-- DEPENDS on  https://cldcvr.atlassian.net/browse/VAN-3788 -->
					<!-- <TableCell>
						<Container padding="0">
							<Typography type="p1">asda</Typography>
						</Container>
					</TableCell>
					<TableCell>
						<Container padding="0">
							<Typography type="p1">asda</Typography>
						</Container>
					</TableCell>
					<TableCell>
						<Container padding="0">
							<Typography type="p1">asda</Typography>
						</Container>
					</TableCell> -->
				</template>
			</Table>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, SearchInput, Table, TableCell, Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import EmptyInputOutput from "./EmptyInputOutput.vue";

export default defineComponent({
	name: "DependencyUsedIn",
	components: { Container, Table, TableCell, Typography, SearchInput, EmptyInputOutput },

	props: {
		selectedTab: {
			type: String
		}
	},

	data: () => ({
		searchString: ""
	}),

	computed: {
		// TEMP till backend fixes https://cldcvr.atlassian.net/browse/VAN-3788
		isEmpty() {
			return true;
		}
	}
});
</script>
