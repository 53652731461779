<template>
	<Container
		direction="column"
		align="left top"
		:grow="1"
		:gap="20"
		padding="20px"
		class="height-100-per"
	>
		<Container v-if="currentOrg" :padding="0" :shrink="0">
			<Container :padding="0" align="center">
				<Pictogram
					state="avatar"
					:label="currentOrg.orgInitial"
					shape="circle"
					size="m"
					:effects="true"
				/>
			</Container>

			<Typography data-qa-current-org-name type="h1" color="dark" weight="bold">{{
				currentOrg && currentOrg.name
			}}</Typography>

			<Icon
				v-tooltip="{
					content: 'Org settings',
					container: 'div.flow-layout',
					placement: 'right'
				}"
				name="i-setting"
				type="filled"
				state="light"
				size="small"
				data-qa-home-org-settings-icon
				@click="routeToOrg"
			/>
		</Container>

		<Container v-if="currentOrg?.description" :padding="0" :shrink="0">
			<Markdown :text="currentOrg.description" />
		</Container>

		<Container direction="column" :gap="20" :grow="1" :padding="0" align="left top">
			<Tabs data-qa-home-page-landing-tabs>
				<!-- Settings -->
				<Tab
					id="projects-tab"
					:selected="selectedTab === 'projects'"
					class="width-100 flex-unset"
					data-qa-home-page-projects-tab-btn
					@click="setCurrentTab('projects')"
				>
					<Container :gap="8" :padding="0">
						<Typography
							type="p1"
							:weight="selectedTab === 'projects' ? 'medium' : 'light'"
							:color="selectedTab === 'projects' ? 'dark' : 'default'"
							data-qa-home-tab-title
							>Projects</Typography
						>
					</Container>
				</Tab>

				<Tab
					id="activity-tab"
					:selected="selectedTab === 'activity'"
					width="100px"
					data-qa-home-page-activity-tab-btn
					@click="setCurrentTab('activity')"
				>
					<Container :gap="8" :padding="0">
						<Typography
							type="p1"
							:weight="selectedTab === 'activity' ? 'medium' : 'light'"
							:color="selectedTab === 'activity' ? 'dark' : 'default'"
						>
							Activity
						</Typography>
					</Container>
				</Tab>
			</Tabs>
			<!-- content here-->
			<Container
				v-if="selectedTab === 'activity'"
				align="center center"
				overflow="visible"
				:padding="0"
				class="height-100-per"
			>
				<EmptyState
					:icon="{
						name: 'i-filter',
						type: 'filled',
						state: 'default',
						size: 'small',
						color: 'gray-200'
					}"
					message="No activity found"
					subtitle="No activity found for selected org"
					shape="squircle"
				/>
			</Container>

			<Container
				v-else-if="selectedTab === 'projects'"
				direction="column"
				:grow="1"
				:padding="0"
				align="left top"
			>
				<Container
					v-if="!showEmpty"
					data-qa-home-page
					:padding="0"
					:gap="10"
					direction="column"
					align="left top"
					class="height-100-per"
				>
					<Container
						direction="row"
						align="left center"
						:gap="16"
						padding="0"
						:shrink="0"
						flex-wrap
					>
						<Container :padding="8" :gap="28" align="stretch">
							<Container :padding="0" direction="column" :gap="8">
								<Typography type="h5" weight="regular" color="light">Total projects</Typography>
								<Typography
									v-if="!loadingProjects"
									type="h1"
									weight="medium"
									color="dark"
									data-qa-total-projects-count
								>
									{{ projects.length }}
								</Typography>
								<Shimmer v-if="loadingProjects" data-qa="home-page-project-loader" height="20px" />
							</Container>
							<Divider direction="vertical" :size="2" class="height-inherit" />
							<Container :padding="0" direction="column" :gap="8">
								<Typography type="h5" weight="regular" color="light">Cloud accounts</Typography>
								<Typography
									v-if="!loadingProjects"
									type="h1"
									weight="medium"
									color="dark"
									data-qa-total-cloud-accounts-count
								>
									{{ totalCloudAccounts }}
								</Typography>
								<Shimmer v-if="loadingProjects" data-qa="home-page-cred-loader" height="20px" />
							</Container>
							<Divider direction="vertical" :size="2" class="height-inherit" />
							<Container :padding="0" direction="column" :gap="8">
								<Typography type="h5" weight="regular" color="light">Environments</Typography>
								<Typography
									v-if="!loadingExpensiveStats || totalEnvironments"
									type="h1"
									weight="medium"
									color="dark"
									data-qa-total-environments-count
									>{{ totalEnvironments }}</Typography
								>
								<Shimmer
									v-else-if="loadingExpensiveStats"
									data-qa="home-page-env-loader"
									height="20px"
								/>
							</Container>
							<Divider direction="vertical" :size="2" class="height-inherit" />
							<Container :padding="0" direction="column" :gap="8">
								<Typography type="h5" weight="regular" color="light">Applications</Typography>
								<Typography
									v-if="!loadingExpensiveStats || totalApplications"
									type="h1"
									weight="medium"
									color="dark"
									data-qa-total-applications-count
									>{{ totalApplications }}</Typography
								>
								<Shimmer
									v-else-if="loadingExpensiveStats"
									data-qa="home-page-app-loader"
									height="20px"
								/>
							</Container>
						</Container>

						<Container :padding="0" :grow="1" align="right center">
							<SearchInput
								v-model:value="searchInputText"
								data-qa-filter-projects
								class="max-width-300"
								placeholder="Search projects"
								data-qa-search-input-projects
							/>
							<!-- Add new project popover -->
							<PopOver
								v-if="!hasNxPlatformFlag"
								v-model:open="openCreateProject"
								:overlay="true"
								@overlay-click="openCreateProject = false"
							>
								<Button
									v-tooltip="{
										content: 'Add new project',
										container: 'div.flow-layout',
										placement: 'right-start'
									}"
									state="icon"
									data-qa-homepage-proj-create-btn
									@click="openCreateProject = true"
								>
									<Icon name="i-plus" type="filled" state="dark" size="small" :effects="false" />
								</Button>
								<template #content>
									<ProjectCreatePopover @close="openCreateProject = false" />
								</template>
							</PopOver>
						</Container>
					</Container>

					<Container direction="column" align="left top" padding="0" overflow="auto">
						<table class="flow-table fixed-layout border-bottom width-100-per">
							<thead class="flow-table-head">
								<tr class="flow-table-row element-light rounded-corners">
									<th
										class="text-align-start v-align-middle width-400 sticky-horizontal sticky-vertical"
										:style="{ zIndex: 3 }"
									>
										<Container
											clickable
											:gap="10"
											:padding="12"
											:grow="1"
											@click="toggleSort('projectName')"
										>
											<Typography type="p2" overflow="nowrap" color="light">Project</Typography>
											<Icon
												data-qa-sort-by-project
												:name="
													sortColumn === 'projectName' && sortDirection === 'asc'
														? 'i-arrow-up'
														: 'i-arrow-down'
												"
												:state="sortColumn === 'projectName' ? 'primary' : 'light'"
												type="filled"
												size="x-small"
											/>
										</Container>
									</th>
									<th
										class="text-align-start v-align-middle width-250 sticky-horizontal sticky-vertical"
									>
										<Container
											clickable
											:gap="10"
											:padding="12"
											@click="toggleSort('environments')"
										>
											<Typography type="p2" overflow="nowrap" color="light"
												>Environments</Typography
											>
											<Icon
												data-qa-sort-by-environment
												:name="
													sortColumn === 'environments' && sortDirection === 'asc'
														? 'i-arrow-up'
														: 'i-arrow-down'
												"
												type="filled"
												size="x-small"
												:state="sortColumn === 'environments' ? 'primary' : 'light'"
											/>
										</Container>
									</th>
									<th
										class="text-align-start v-align-middle width-250 sticky-horizontal sticky-vertical"
									>
										<Container
											clickable
											:gap="10"
											:padding="12"
											@click="toggleSort('applications')"
										>
											<Typography type="p2" overflow="nowrap" color="light"
												>Applications</Typography
											>
											<Icon
												data-qa-sort-by-applications
												:name="
													sortColumn === 'applications' && sortDirection === 'asc'
														? 'i-arrow-up'
														: 'i-arrow-down'
												"
												type="filled"
												size="x-small"
												:state="sortColumn === 'applications' ? 'primary' : 'light'"
											/>
										</Container>
									</th>
									<th
										class="text-align-start v-align-middle width-250 sticky-horizontal sticky-vertical"
									>
										<Container clickable :gap="10" :padding="12" @click="toggleSort('members')">
											<Typography type="p2" overflow="nowrap" color="light">Members</Typography>
											<Icon
												data-qa-sort-by-members
												:name="
													sortColumn === 'members' && sortDirection === 'asc'
														? 'i-arrow-up'
														: 'i-arrow-down'
												"
												type="filled"
												size="x-small"
												:state="sortColumn === 'members' ? 'primary' : 'light'"
											/>
										</Container>
									</th>

									<th
										class="text-align-start v-align-middle width-250 sticky-horizontal sticky-vertical"
									>
										<Container clickable :gap="10" :padding="12" @click="toggleSort('lastUpdated')">
											<Typography type="p2" overflow="nowrap" color="light"
												>Last updated</Typography
											>
											<Icon
												data-qa-sort-by-last-update
												:name="
													sortColumn === 'lastUpdated' && sortDirection === 'asc'
														? 'i-arrow-up'
														: 'i-arrow-down'
												"
												type="filled"
												size="x-small"
												:state="sortColumn === 'lastUpdated' ? 'primary' : 'light'"
											/>
										</Container>
									</th>
								</tr>
							</thead>
							<tbody v-if="loadingProjects" class="flow-table-body">
								<tr v-for="idx in Array.from(Array(4).keys())" :key="idx" class="flow-table-row">
									<td class="flow-table-cell sticky-horizontal v-align-middle">
										<Shimmer height="20px" />
									</td>
									<td class="flow-table-cell v-align-middle">
										<Shimmer height="20px" />
									</td>
									<td class="flow-table-cell v-align-middle">
										<Shimmer height="20px" />
									</td>
									<td class="flow-table-cell v-align-middle">
										<Shimmer height="20px" />
									</td>
								</tr>
							</tbody>
							<tbody v-else class="flow-table-body">
								<tr
									v-for="project in visibleProjects"
									:key="project.id"
									class="flow-table-row highlight-on-hover cursor-pointer"
									:data-qa-project-name="project.name"
									@click="goToProject(project)"
								>
									<td class="flow-table-cell sticky-horizontal v-align-middle">
										<Container padding="0px 16px" :gap="12" :shrink="0" data-qa-project-info>
											<Pictogram v-if="getEmoji(project)" size="xl" state="border" :effects="true">
												{{ getEmoji(project) }}
											</Pictogram>
											<Pictogram
												v-else
												size="xl"
												state="avatar"
												:label="getShortCode(project.name)"
												:effects="true"
											>
											</Pictogram>

											<Container
												padding="4px 0px 0px 0px"
												:gap="4"
												direction="column"
												overflow="visible"
											>
												<Typography type="h4" color="dark" data-qa-project-name-field>{{
													project.name
												}}</Typography>

												<Typography v-if="project.updatedAt" type="p2" color="light"
													>Last updated {{ getRelativeTime(project.updatedAt) }}
												</Typography>
											</Container>
										</Container>
									</td>
									<td
										class="flow-table-cell v-align-middle"
										@click.stop="goToProject(project, 'environments')"
									>
										<Shimmer
											v-if="loadingExpensiveStats && !projectCounts[project.id]"
											height="20px"
										/>
										<Container v-else padding="0px 16px">
											<Pictogram
												effects
												size="l"
												shape="hexagon"
												data-qa-navigate-project-environments
											>
												<Typography
													type="h4"
													color="dark"
													:data-qa="`project-${project.name}-env-count`"
													>{{ projectCounts[project.id]?.environments }}</Typography
												>
											</Pictogram>
										</Container>
									</td>
									<td
										class="flow-table-cell v-align-middle"
										@click.stop="goToProject(project, 'applications')"
									>
										<Shimmer
											v-if="loadingExpensiveStats && !projectCounts[project.id]"
											height="20px"
										/>
										<Container v-else padding="0px 16px">
											<Pictogram
												effects
												size="l"
												shape="squircle"
												data-qa-navigate-project-applications
											>
												<Typography type="h4" color="dark">{{
													projectCounts[project.id]?.apps
												}}</Typography>
											</Pictogram>
										</Container>
									</td>
									<td class="flow-table-cell v-align-middle">
										<Shimmer
											v-if="loadingExpensiveStats && !projectCounts[project.id]"
											height="20px"
										/>
										<Container v-else padding="0px 16px" :gap="8" :grow="0" align="left center">
											<Icon
												name="i-user-double"
												size="20px"
												type="filled"
												state="light"
												:effects="false"
											/>
											<Typography type="p1" color="light" data-qa-members-count>{{
												projectCounts[project.id]?.users
											}}</Typography>
										</Container>
									</td>

									<td class="flow-table-cell v-align-middle">
										<Container
											v-if="project.updatedAt"
											padding="0px 16px"
											:gap="8"
											:grow="0"
											align="left center"
											data-qa-last-updated
										>
											<Typography type="p1" color="light">
												{{ getRelativeTime(project.updatedAt) }}
											</Typography>
										</Container>
									</td>
								</tr>
							</tbody>
						</table>
					</Container>
				</Container>

				<Container
					v-else-if="showEmpty && !hasNxPlatformFlag"
					data-qa-empty-home-page
					:padding="0"
					:gap="12"
					direction="column"
					align="center center"
					overflow="visible"
					class="height-100-per"
				>
					<PopOver
						v-model:open="openCreateProject"
						placement="right-start"
						:overlay="true"
						target="[data-qa-add-new-project]"
					>
						<Container :padding="0" align="center" :grow="1">
							<EmptyState
								:icon="emptyState.emptyIcon"
								:message="emptyState.emptyMessage"
								shape="none"
							>
								<Typography
									type="p1"
									color="light"
									class="text-align-center"
									:class="[emptyState.helpWidthClass]"
									>{{ emptyState.help }}</Typography
								>
								<Button
									id="create-project-homepage-info"
									data-qa-add-new-project
									type="success"
									@click="openCreateProject = true"
								>
									<Icon name="i-plus" size="small" type="filled" state="dark" :effects="false" />
									{{ emptyState.buttonText }}
								</Button>

								<ProjectHomePagePopover target="create-project-homepage-info" />
							</EmptyState>
						</Container>

						<template #content>
							<ProjectCreatePopover @close="openCreateProject = false" />
						</template>
					</PopOver>
				</Container>
			</Container>
		</Container>
	</Container>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Divider,
	EmptyState,
	Icon,
	Pictogram,
	PopOver,
	SearchInput,
	Shimmer,
	Typography,
	Tabs,
	Tab,
	EmptyStateIconProp
} from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { credentialStore } from "@/modules/credentials/credential-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { orgStore } from "@/modules/org/org-store";
import ProjectCreatePopover from "@/modules/project-create/ProjectCreatePopover.vue";
import { ProjectTabName } from "@/modules/project-landing/project-types";
import { projectStore } from "@/modules/project-list/project-store";
import { project as projectProto, organization, CredScope } from "@/protocol/identity";
import Markdown from "@/shared/components/Markdown.vue";
import ProjectHomePagePopover from "@/shared/components/ProjectOnboardPopover/ProjectHomePagePopover.vue";
import { getEmoji, getRelativeTime, getShortCode } from "@/utils";

type Org = organization & { formatedName: string; orgInitial: string };

export default defineComponent({
	name: "HomePage",

	components: {
		Button,
		Container,
		Divider,
		EmptyState,
		Icon,
		Pictogram,
		PopOver,
		ProjectCreatePopover,
		SearchInput,
		Shimmer,
		Typography,
		Tabs,
		Tab,
		ProjectHomePagePopover,
		Markdown
	},

	data: () => ({
		// Some metrics are expensive to calculate and need a long running API
		// so we run them here
		loadingExpensiveStats: true,

		openCreateProject: false,

		emptyState: {
			emptyIcon: {
				name: "i-folder",
				type: "filled",
				state: "error",
				size: "xxx-large"
			} as EmptyStateIconProp,

			helpWidthClass: "width-500",
			emptyMessage: "Welcome to Codepipes!",
			help: "You can create projects, add environments and deploy applications. To begin, let's set up your first project.",
			buttonText: "CREATE First Project"
		},

		searchInputText: "",
		sortColumn: "lastUpdated" as ColumName,
		sortDirection: "desc" as "asc" | "desc",
		getShortCode,
		getRelativeTime,
		getEmoji,

		selectedTab: "overview" as HomePageTabName
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		title() {
			const { orgs } = orgStore;
			const org = orgs.find(matchingOrg => matchingOrg.id === this.orgId);
			return org?.name;
		},

		loadingProjects() {
			return projectStore.isLoadingProjects;
		},

		showEmpty() {
			return !this.loadingProjects && this.projects.length === 0;
		},

		projects() {
			return Object.values(projectStore.projects);
		},

		projectCounts() {
			return projectStore.projectCounts;
		},

		visibleProjects() {
			const visibleProjects = this.projects.filter(project =>
				this.searchInputText.length > 0
					? project.name.toLocaleLowerCase().includes(this.searchInputText.toLocaleLowerCase())
					: true
			);

			visibleProjects.sort((projectA, projectB) => {
				let sortValue = 0;
				if (this.sortColumn === "projectName") {
					sortValue = projectA.name.localeCompare(projectB.name);
				} else if (this.sortColumn === "environments") {
					sortValue = (projectA.counts?.environments ?? 0) - (projectB.counts?.environments ?? 0);
				} else if (this.sortColumn === "applications") {
					sortValue = (projectA.counts?.apps ?? 0) - (projectB.counts?.apps ?? 0);
				} else if (this.sortColumn === "lastUpdated") {
					sortValue = Number(projectA.updatedAt ?? 0) - Number(projectB.updatedAt ?? 0);
				} else {
					sortValue = (projectA.counts?.users ?? 0) - (projectB.counts?.users ?? 0);
				}

				return this.sortDirection === "asc" ? sortValue : -sortValue;
			});

			return visibleProjects;
		},

		organizations() {
			return orgStore.GET_ORGS;
		},

		currentOrg(): Org | undefined {
			const { orgId } = this.$route.params;
			return this.organizations.find(matchingOrg => matchingOrg.id === orgId);
		},

		totalCloudAccounts() {
			return Object.values(credentialStore.genericCredentials).filter(
				cred => cred.credScope?.includes(CredScope.cloud)
			).length;
		},

		totalEnvironments() {
			return this.projects.reduce((total, project) => {
				return total + (this.projectCounts[project.id]?.environments ?? 0);
			}, 0);
		},

		totalApplications() {
			return this.projects.reduce((total, project) => {
				return total + (this.projectCounts[project.id]?.apps ?? 0);
			}, 0);
		},

		hasNxPlatformFlag() {
			return featureFlagStore.featureMap.ENABLE_NX_PLATFORM;
		}
	},

	watch: {
		title: {
			immediate: true,

			handler() {
				if ("document" in window && this.title) {
					window.document.title = this.title;
				}
			}
		}
	},

	async mounted() {
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
		this.selectedTab = (this.$route.params.tabName || "projects") as HomePageTabName;

		this.loadingExpensiveStats = true;

		// As projects are removed, we also need to reset the environments
		envListStore.RESET_ENVLIST();

		if (await orgStore.IS_USER_ORG_ADMIN({ orgId: this.orgId })) {
			await credentialStore.GET_ORG_CREDENTIALS({
				orgId: this.orgId
			});
		}

		// Detailed project list API call takes a long time so we run it separately from the main render
		await projectStore.GET_PROJECTS({
			orgId: this.orgId,
			detailed: true
		});

		this.loadingExpensiveStats = false;
	},

	methods: {
		routeToOrg() {
			this.$router
				.push({ name: "org-settings-global", params: { orgId: this.orgId } })
				.catch(err => {
					throw new Error(err);
				});
		},

		async setCurrentTab(tabName: HomePageTabName) {
			if (this.selectedTab !== tabName) {
				this.selectedTab = tabName;

				await this.$router
					.replace({
						name: "home",
						params: { orgId: this.orgId, tabName }
					})
					.catch();
			}
		},

		toggleSort(column: ColumName) {
			this.sortColumn = column;
			this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
		},

		async goToProject(project: projectProto, tabName?: ProjectTabName) {
			await this.$router
				.push({
					name: "projectListWithProject",
					params: { orgId: this.orgId, projectId: project.id, tabName: tabName as string }
				})
				.catch();
		}
	}
});

type ColumName = "projectName" | "environments" | "applications" | "members" | "lastUpdated";
type HomePageTabName = "activity" | "projects";
</script>
<style lang="scss">
.height-inherit {
	height: inherit !important;
}
</style>
