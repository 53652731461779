/* eslint-disable */
import { HealthCheckRequest, HealthCheckResponse } from "./health";

export enum policySetType {
	setTypeUndefined = "setTypeUndefined",
	setTypeControl = "setTypeControl",
	setTypeControlSet = "setTypeControlSet",
	setTypeControlGate = "setTypeControlGate"
}

export interface success {
	success?: boolean;
}

export interface PolicySetListReq {
	orgId?: string;
	privateOnly?: boolean;
	type?: policySetType;
	parentId?: string;
}

export interface PolicySetId {
	id?: string;
	orgId?: string;
}

export interface PolicySetCreate {
	orgId?: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1,"max_len":64}}}]
	name: string;
	description?: string;
	importSets?: string[];
	type?: policySetType;
	metadata?: Record<string, unknown>;
}

export interface PolicySet {
	id?: string;
	orgId?: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1,"max_len":64}}}]
	name: string;
	description?: string;
	importSets?: string[];
	type?: policySetType;
	metadata?: Record<string, unknown>;
}

export interface UpdatePolicySetRequest {
	id?: string;
	orgId?: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1,"max_len":64}}}]
	name: string;
	description?: string;
	importSets?: string[];
	metadata?: Record<string, unknown>;
}

export interface PolicySets {
	policySets?: PolicySet[];
}

export interface PolicyId {
	id?: string;
	orgId?: string;
	setId?: string;
}

export interface PolicyCreate {
	orgId?: string;
	setId?: string;
	definition?: string;
}

export interface PolicyUpdate {
	id?: string;
	orgId?: string;
	setId?: string;
	definition?: string;
}

export interface Policy {
	id?: string;
	setId?: string;
	definition?: string;
}

export interface PolicyListReq {
	orgId?: string;
	setId?: string;
	fetchImports?: boolean;
}

export interface Policies {
	policies?: Policy[];
}

export interface EvalRequest {
	policySetId?: string;
	orgId?: string;
	validationInput?: string;
}

export interface EvalResponse {
	summary?: PolicySummaryOutput;
	ruleResults?: PolicyRuleOutput[];
	message?: string;
}

export interface PolicyRuleOutput_SourceLocation {
	path?: string;
	line?: number;
	column?: number;
}

export interface PolicyRuleOutput {
	controls?: string[];
	filepath?: string;
	inputType?: string;
	provider?: string;
	resourceId?: string;
	resourceType?: string;
	ruleDescription?: string;
	ruleId?: string;
	ruleMessage?: string;
	ruleName?: string;
	ruleRawResult?: boolean;
	ruleRemediationDoc?: string;
	ruleResult?: string;
	ruleSeverity?: string;
	ruleSummary?: string;
	sourceLocation?: PolicyRuleOutput_SourceLocation[];
}

export interface PolicySummaryOutput_Severities {
	Critical?: number;
	High?: number;
	Informational?: number;
	Low?: number;
	Medium?: number;
	Unknown?: number;
}

export interface PolicySummaryOutput_Rule {
	FAIL?: number;
	PASS?: number;
	WAIVED?: number;
}

export interface PolicySummaryOutput {
	valid?: boolean;
	ruleResults?: PolicySummaryOutput_Rule;
	severities?: PolicySummaryOutput_Severities;
	filepaths?: string[];
}

export interface validatorService {
	// Service health check
	// Options: [{"(google.api.http)":{"get":"/v0/healthcheck/*"}}]
	healthCheck(request: HealthCheckRequest): Promise<HealthCheckResponse>;

	// Create a policy set
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/policysets","body":"*"}}]
	createPolicySet(request: PolicySetCreate): Promise<PolicySet>;

	// Update a policy set
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/policysets/{id=*}","body":"*"}}]
	updatePolicySet(request: UpdatePolicySetRequest): Promise<success>;

	// Delete a policy set
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/policysets/{id=*}"}}]
	deletePolicySet(request: PolicySetId): Promise<success>;

	// Get a policy set
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/policysets/{id=*}"}}]
	getPolicySet(request: PolicySetId): Promise<PolicySet>;

	// List policy sets
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/policysets"}}]
	listPolicySet(request: PolicySetListReq): Promise<PolicySets>;

	// Add a policy
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/policysets/{setId=*}/policies","body":"*"}}]
	addPolicy(request: PolicyCreate): Promise<Policy>;

	// Update a policy
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/policysets/{setId=*}/policies/{id=*}","body":"*"}}]
	updatePolicy(request: PolicyUpdate): Promise<success>;

	// Delete a policy
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/policysets/{setId=*}/policies/{id=*}"}}]
	deletePolicy(request: PolicyId): Promise<success>;

	// Get a policy
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/policysets/{setId=*}/policies/{id=*}"}}]
	getPolicy(request: PolicyId): Promise<Policy>;

	// List policies
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/policysets/{setId=*}/policies"}}]
	listPolicies(request: PolicyListReq): Promise<Policies>;

	// Evaluate against a policy set
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/policysets/{policySetId=*}/eval","body":"*"}}]
	evalInput(request: EvalRequest): Promise<EvalResponse>;
}
