import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
	classification as classificationProto,
	createTemplateRequest,
	createClassificationRequest,
	environmentCreate,
	template,
	createEnvsFromTemplateReq,
	createEnvsFromTemplateReq_env
} from "@/protocol/infra";

import { getStore } from "../store";

import {
	createEnvironment,
	listEnvTemplates,
	listEnvClassifications,
	createClassification,
	createTemplate,
	createEnvsFromTemplate
} from "./env-create-update-service";

@Module({
	namespaced: true,
	dynamic: true,
	name: "envCreate",
	store: getStore()
})
class EnvCreateStore extends VuexModule {
	projectId: string | null = null;
	editEnvId: string | null = null;
	editMode = false;
	isNewEnvCreationInProgress = false;
	envSetTemplates: template[] = [];
	envSetClassifications: classificationProto[] = [];
	isLoadingTemplates = false;
	isLoadingClassifications = false;

	@Mutation
	RESET_ENV_CREATE() {
		this.projectId = null;
		this.editEnvId = null;
		this.editMode = false;
		this.isNewEnvCreationInProgress = false;
		this.envSetTemplates = [];
		this.envSetClassifications = [];
		this.isLoadingTemplates = false;
		this.isLoadingClassifications = false;
	}

	@Mutation
	TOGGLE_NEW_ENV_CREATION_IN_PROGRESS() {
		this.isNewEnvCreationInProgress = !this.isNewEnvCreationInProgress;
	}

	@Mutation
	SET_ORG_ENV_SET_TEMPLATES(templates: template[]) {
		this.envSetTemplates = templates;
	}

	@Mutation
	SET_ORG_ENV_SET_CLASSIFICATIONS(classifications: classificationProto[]) {
		this.envSetClassifications = classifications;
	}

	@Mutation
	SET_ENV_EDIT_MODE({ editMode, envId }: { editMode: boolean; envId: string }) {
		this.editMode = editMode;
		this.editEnvId = envId;
	}

	@Mutation
	RESET_ENV_EDIT() {
		this.projectId = null;
		this.editEnvId = null;
		this.editMode = false;
	}

	@Mutation
	SET_TEMPLATE_LOADING() {
		this.isLoadingTemplates = !this.isLoadingTemplates;
	}

	@Mutation
	SET_CLASSIFICATION_LOADING() {
		this.isLoadingClassifications = !this.isLoadingClassifications;
	}

	@Action
	CREATE_NEW_ENV(payload: environmentCreate) {
		return createEnvironment(payload);
	}

	@Action
	async GET_ORG_ENV_SET_TEMPLATES({ orgId }: { orgId: string }) {
		this.SET_TEMPLATE_LOADING();

		const listTemplatesResponse = await listEnvTemplates({ orgId });

		if (listTemplatesResponse.templates) {
			this.SET_ORG_ENV_SET_TEMPLATES(listTemplatesResponse.templates);
		}

		this.SET_TEMPLATE_LOADING();
	}

	@Action
	async GET_ORG_ENV_SET_CLASSIFICATIONS({ orgId }: { orgId: string }) {
		this.SET_CLASSIFICATION_LOADING();
		const listClassificationResponse = await listEnvClassifications({ orgId });
		const { classifications } = listClassificationResponse;

		if (!classifications) {
			return [];
		}

		const parentClassificationIdx = classifications.findIndex(
			classification => classification.ancestorId === ""
		);
		const parentNode = classifications[parentClassificationIdx];
		if (!parentNode) {
			return [];
		}

		const orderedClassifications: classificationProto[] = [parentNode];
		const remainingClassifications = classifications.filter(
			classification => parentNode.id !== classification.id
		);

		let nextNode = parentNode;
		while (remainingClassifications.length > 0) {
			const childNodeIdx = remainingClassifications.findIndex(
				classification => classification.ancestorId === nextNode.id
			);
			const childNode = remainingClassifications[childNodeIdx];
			if (!childNode) {
				break;
			}
			orderedClassifications.push(childNode);
			nextNode = childNode;
			remainingClassifications.splice(childNodeIdx, 1);
		}

		this.SET_ORG_ENV_SET_CLASSIFICATIONS(orderedClassifications);
		this.SET_CLASSIFICATION_LOADING();
	}

	@Action
	async CREATE_CLASSIFICATIONS_SET({
		classifications
	}: {
		classifications: createClassificationRequest[];
	}) {
		for (
			let classificationIdx = 0;
			classificationIdx < classifications.length;
			classificationIdx++
		) {
			const classification = await createClassification(classifications[classificationIdx]!);
			const nextClassification = classifications[classificationIdx + 1];
			if (nextClassification) {
				nextClassification.ancestorId = classification.id;
				classifications[classificationIdx + 1] = nextClassification;
			}
		}
	}

	@Action
	CREATE_ENV_FROM_TEMPLATE({
		templateId,
		projId,
		orgId,
		classifications
	}: {
		templateId: string;
		projId: string;
		orgId: string;
		classifications: classificationProto[];
	}) {
		const envs: createEnvsFromTemplateReq_env[] = classifications.map(classification => {
			return {
				classificationId: classification.id,
				/**
				 * backend will generate a random name if name is not provided
				 * or default to classification name.
				 */
				name: ""
			};
		});

		const payload: createEnvsFromTemplateReq = {
			templateId,
			projId,
			orgId,
			envs
		};

		return createEnvsFromTemplate(payload);
	}

	@Action
	CREATE_TEMPLATE({ payload }: { payload: createTemplateRequest }) {
		return createTemplate(payload);
	}
}

const envCreateStore = getModule(EnvCreateStore);

export { envCreateStore };
