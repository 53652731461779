<script lang="ts">
import { Accordion, Container, Icon, PopOver, Typography } from "@cldcvr/flow-vue3";
import { PropType, computed, onMounted, ref, toRefs } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import ApplicationDeploymentModal from "@/modules/application/components/ApplicationDeploymentModal.vue";
import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { Provisioner } from "@/protocol/common";
import { AppDeployment } from "@/protocol/deployment";
import { pipelineModule } from "@/protocol/pipeline";
import FormFieldTable from "@/shared/components/FormFieldTable.vue";
import { buildForm } from "@/shared/components/JSONSchemaFormBuilder2.vue";

export default {
	name: "DeploymentsListConfiguration"
};
</script>

<script lang="ts" setup>
const props = defineProps({
	deployment: {
		type: Object as PropType<AppDeployment>,
		required: true
	}
});

const { deployment } = toRefs(props);

const isAccordionOpen = ref(true);
const isEditingDeployment = ref(false);

const currentDeploymentTemplate = computed(() => {
	const templates = applicationDeploymentStore.orgAppDeploymentTemplates[deployment.value.orgId!];

	if (!templates) {
		return null;
	}

	return (
		templates.find(template => {
			const templateId = getPipelineModuleId(template);
			return templateId === deployment.value.deploymentConfig?.template?.moduleRefId;
		}) ?? null
	);
});

const formFields = computed(() => {
	if (!currentDeploymentTemplate.value?.inputs) {
		return null;
	}

	return buildForm({
		schema: currentDeploymentTemplate.value.inputs,
		name: "",
		parentName: "",
		isNested: false,
		isRequired: true
	});
});

const app = computed(() => {
	return deployment.value.appId ? applicationStore.apps[deployment.value.appId] : null;
});

onMounted(() => {
	const existingTemplates =
		applicationDeploymentStore.orgAppDeploymentTemplates[deployment.value.orgId!];

	if (!existingTemplates) {
		applicationDeploymentStore.FETCH_ORG_APP_DEPLOYMENT_TEMPLATES({
			orgId: deployment.value.orgId!,
			provisioner: Provisioner.no_provisioner
		});
	}
});

// Server returns a limited amount of information in an application deployment which makes
// it harder to determine which template it belongs to. So we need this logic to determine that
function getPipelineModuleId(module: pipelineModule) {
	return `${module.provisioner}:${module.name}@${module.version}`;
}
</script>

<template>
	<Accordion :open="isAccordionOpen">
		<template #header>
			<Container
				align="space-between center"
				overflow="visible"
				padding="20px 8px"
				:clickable="true"
				@click="isAccordionOpen = !isAccordionOpen"
			>
				<Container :padding="0" :gap="8">
					<Typography type="h4" weight="medium" color="dark">Configuration</Typography>

					<!-- Edit deployment popover -->
					<PopOver :open="Boolean(isEditingDeployment)">
						<f-icon
							data-qa-edit-deployment
							tooltip="Edit deployment"
							source="i-setting"
							color="gray-600"
							size="small"
							clickable
							@click="isEditingDeployment = true"
						></f-icon>

						<template #content>
							<ApplicationDeploymentModal
								v-if="app"
								:app="app"
								:project-id="deployment.projId"
								:environment-id="deployment.envId"
								:disable-back-button="true"
								:app-deployment="deployment"
								@close="() => (isEditingDeployment = false)"
							/>
						</template>
					</PopOver>
				</Container>
				<Icon
					name="i-chevron-down"
					size="x-small"
					:rotate="isAccordionOpen ? 180 : 0"
					@click.stop="isAccordionOpen = !isAccordionOpen"
				/>
			</Container>
		</template>

		<Container data-qa-deployment-configuration padding="12px" overflow="auto" align="stretch top">
			<FormFieldTable
				v-if="formFields"
				data-qa-advanced-form-table
				:fields="formFields"
				:values="deployment.deploymentConfig?.template?.inputs"
			/>
		</Container>
	</Accordion>
</template>
