<template>
	<section class="support-section">
		<PopOver
			id="support-popover"
			placement="top-start"
			:overlay="false"
			:open="isOpen"
			:style="{ display: 'contents' }"
			@overlay-click="toggle"
		>
			<Container :padding="0">
				<Icon
					name="i-chat-support"
					class="cursor-pointer"
					size="medium"
					type="filled"
					state="light"
					:effects="false"
					tooltip="Toggle Chat"
					@click="toggle"
				/>
			</Container>
			<template #content>
				<Wrapper width="500px" background="element-light" border-radius="5px" overflow="scroll">
					<div id="fogbender">
						<template v-if="isLoading">
							<Container direction="column">
								<Shimmer height="40px" />
								<Shimmer />
								<Shimmer />
								<Shimmer height="300px" />
								<Shimmer />
								<Shimmer height="80px" />
							</Container>
						</template>
					</div>
				</Wrapper>
			</template>
		</PopOver>
	</section>
</template>
<script lang="ts">
import { Container, Icon, PopOver, Shimmer, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { SupportService } from "@/modules/customer-success/UserSupportClass";

import { orgStore } from "../org/org-store";
import { userStore } from "../user/user-store";

export default defineComponent({
	name: "FogBender",
	components: { PopOver, Icon, Wrapper, Container, Shimmer },

	data: () => ({
		isOpen: false,
		isLoading: false
	}),

	computed: {
		activeOrg() {
			return orgStore.activeOrg;
		}
	},

	methods: {
		toggle() {
			this.isOpen = !this.isOpen;
			if (!this.isOpen) {
				return;
			}
			this.isLoading = true;

			setTimeout(() => {
				this.renderIFrame();
				this.isLoading = false;
			}, 200);
		},

		async renderIFrame() {
			const rootEl = document.getElementById("fogbender");
			if (rootEl) {
				const { profile, fogBenderWidgetId } = userStore;
				if (profile?.email && profile.id && this.activeOrg !== undefined) {
					await SupportService.renderIFrame({
						rootEl,
						emailId: profile.email,
						userId: profile.id,
						org: this.activeOrg,
						widgetId: fogBenderWidgetId
					});
				}
			}
		}
	}
});
</script>
<style lang="scss">
#fogbender {
	iframe {
		height: 550px !important;
	}
}
</style>
