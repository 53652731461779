<template>
	<Wrapper height="100%">
		<Container padding="0px 24px" direction="column" :gap="0" :grow="1" align="start top">
			<Container padding="12px 0 0 0" overflow="visible" align="left top">
				<Container padding="0" align="start bottom" overflow="visible">
					<PopOver
						v-if="env"
						placement="bottom-start"
						:open="showStepsFlow"
						@overlay-click="resetStepFlow"
					>
						<EnvironmentState
							:emoji="emoji"
							:name="env ? env.name : ''"
							:status="environmentStateStatus"
							:is-deployed="env.isDeployed"
							@settings="toggleEnvUpdate"
							@delete="toggleDeleteEnv"
							@download-tf="getTerraformState"
						>
							<template #status-text>
								<CustomTimeStamp
									v-if="environmentStateStatusText.time"
									:time="environmentStateStatusText.time"
									:prefix="environmentStateStatusText.prefix"
									:user="env.updatedBy"
									data-qa="env-last-updated-date"
								/>
								<CustomTimeStamp
									v-if="environmentJobStatusText?.time"
									:time="environmentJobStatusText.time"
									:prefix="environmentJobStatusText.prefix"
									data-qa="env-job-last-updated-date"
								/>
							</template>
						</EnvironmentState>
						<template #content>
							<EnvWelcomePopover
								v-if="showWelcomePopover && env"
								:env="env"
								@gonext="envAcknowledged"
							/>
							<EnvEditPopOver
								v-if="showCreateEditPopover"
								:env="env"
								@go-next="goToNextStep"
								@close="resetStepFlow"
							/>
							<EnvDeletePopover v-if="isEnvDelPopoverOpen" :env="env" @close="toggleDeleteEnv" />
						</template>
					</PopOver>
				</Container>

				<Container padding="25px 0 0 0" align="start bottom" basis="max-content" overflow="visible">
					<PolicySelector
						v-if="env"
						card-style="icon"
						:entity="env"
						data-qa-env-policy-card
						entity-type="environment"
					/>

					<!-- Git account popover -->
					<PopOver :open="steps.terraform" placement="bottom-start" @overlay-click="resetStepFlow">
						<InfoPopover>
							<Container align="center bottom" padding="0">
								<Card
									:clickable="true"
									data-qa-toggle-assign-git-acc
									:style="{ height: 'auto' }"
									@click="toggleConnectTerraform"
								>
									<EntityCard
										:icon="!assignedGitCred && !env?.revision ? 'i-git' : 'p-github'"
										:type="!assignedGitCred && !env?.revision ? 'empty' : 'default'"
									/>
								</Card>
							</Container>
							<template #body>
								<Typography v-if="!assignedGitCred && !env?.revision" type="p2-para" color="dark"
									>Connect Terraform repository</Typography
								>

								<Container v-else :padding="0" direction="column" :gap="8">
									<Typography v-if="assignedGitCredTooltip" type="h5" color="dark">{{
										assignedGitCredTooltip
									}}</Typography>
									<Container :padding="0" direction="column" :gap="4">
										<Container
											v-if="assignedGitCred && assignedGitCred.createdAt"
											:padding="0"
											:gap="4"
										>
											<Typography type="p3-para" color="gray-200"> Date added: </Typography>
											<Typography type="p3-para" color="dark">{{
												changeDateTimeFormat(assignedGitCred.createdAt)
											}}</Typography>
										</Container>
										<Container v-if="env?.revision?.dir" :padding="0" :gap="4">
											<Typography type="p3-para" color="gray-200"> TF directory: </Typography>
											<Typography type="p3-para" color="primary" link>{{
												env?.revision?.dir
											}}</Typography>
										</Container>
										<Container v-if="env?.tfVersion" :padding="0" :gap="4">
											<Typography type="p3-para" color="gray-200"> TF version: </Typography>
											<Typography type="p3-para" color="dark">
												{{ env?.tfVersion }}
											</Typography>
										</Container>
									</Container>
								</Container>
							</template>
						</InfoPopover>

						<template #content>
							<ConnectTerraformRepo
								v-if="env && !isAddingNewGitAcc"
								:env="env"
								:assigned-git-cred="assignedGitCred"
								:added-git-cred="addedGitCred"
								@create-git-cred="toggleNewGitAcc"
								@close="resetStepFlow"
								@go-next="goToNextStep"
								@go-back="goBack"
							/>

							<GithubCredentialModal
								v-if="isAddingNewGitAcc"
								:show-back-btn="true"
								:update-cred-list="true"
								:git-form-ref="githubOauthFormRef"
								:show-close-btn="false"
								:allowed-classification-ids="allowedClassificationIds"
								@back="toggleNewGitAcc"
								@close-modal="toggleNewGitAcc"
								@on-cred-create="gitCredCreated"
							/>
						</template>
					</PopOver>

					<AssignCredentialModal
						v-if="env && canAccessCreds"
						ref="AssignCredentialModal"
						:entity="env"
						entity-kind="environment"
						:cred-scope="credScope.cloud"
						@closed="resetStepFlow"
						@on-credential-assigned="goToNextStep('validate')"
					>
						<template #header-item>
							<Icon
								v-if="isStepFlow"
								name="i-arrow-left"
								size="small"
								@click="goBack('terraform')"
							/>
						</template>
						<template #header-step>
							<Tag v-if="isStepFlow">
								<Typography type="p2" color="light" data-qa-step-flow-step-3>STEP 3 / 3</Typography>
							</Tag>
						</template>
					</AssignCredentialModal>

					<!-- Container account popover -->
					<AssignCredentialModal
						v-if="env && canAccessCreds"
						:entity="env"
						entity-kind="environment"
						:cred-scope="credScope.docker"
					/>

					<!-- Env pipeline cards -->
					<EnvPipelineCards
						v-if="env"
						:org-id="orgId"
						:project-id="projectId"
						:show-validate-popover="isStepFlow && steps.validate"
						:env-id="envId"
						:env="env"
						:is-loading-pipeline="isLoadingPipeline"
						@close-flow="isStepFlow = false"
						@job-loading="showEnvPictogramLoader"
					/>
				</Container>
			</Container>

			<Container padding="0 0 0 56px" :shrink="0">
				<Markdown
					v-if="env?.description"
					data-qa-env-description
					:text="env.description"
					size="small"
					state="secondary"
				/>
			</Container>

			<f-spacer size="small"></f-spacer>

			<EnvDetailedViewTabs
				v-if="env"
				:env="env"
				:current-tab="currentTab"
				:apps-count="apps.length"
				:variables-count="variablesCount"
				:output-count="outputCount"
				@change-tab="setCurrentTab"
			/>

			<keep-alive>
				<EnvSettings
					v-if="env && currentTab === 'settings'"
					:env="env"
					@open-terraform-dialog="toggleConnectTerraform"
				/>
			</keep-alive>

			<keep-alive>
				<AppSection
					v-if="currentTab === 'applications'"
					:meta-keys="{ orgId, projectId, envId }"
					:env="env || {}"
				/>
			</keep-alive>

			<keep-alive>
				<EnvWidgetEmptyOutputContent v-if="currentTab === 'output' && env" :env="env" />
			</keep-alive>

			<keep-alive>
				<VariablesList
					v-if="currentTab === 'variables' && env"
					:project="currentProject ?? undefined"
					:envs="[env]"
					:apps="envApps"
					:skip-project-for-empty-state="true"
				/>
			</keep-alive>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Card,
	Container,
	Icon,
	InfoPopover,
	PopOver,
	Tag,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { breadcrumbStore } from "@/modules/core/breadcrumb-store";
import AssignCredentialModal from "@/modules/credentials/components/credential-assign/AssignCredentialModal.vue";
import GithubCredentialModal from "@/modules/credentials/components/credential-form/GithubCredentialModal.vue";
import { credentialStore, getCredByScope } from "@/modules/credentials/credential-store";
import ConnectTerraformRepo from "@/modules/env-create/components/ConnectTerraformRepo.vue";
import EnvEditPopOver from "@/modules/env-create/components/EnvEditPopOver.vue";
import { envDeleteStore } from "@/modules/env-delete/env-delete-store";
import EnvDeletePopover from "@/modules/env-delete/EnvDeletePopover.vue";
import AppSection from "@/modules/env-list/components/AppSection.vue";
import EnvWidgetEmptyOutputContent from "@/modules/env-list/components/env-widget/env-tab-content/EnvWidgetEmptyOutputContent.vue";
import EnvPipelineCards from "@/modules/env-pipeline/components/EnvPipelineCards.vue";
import { getSortedEnvJobs, newEnvPipelineStore } from "@/modules/env-pipeline/env-pipeline-store";
import { orgStore } from "@/modules/org/org-store";
import PolicySelector from "@/modules/policy-list/components/PolicySelector.vue";
import { projectStore } from "@/modules/project-list/project-store";
import { userStore } from "@/modules/user/user-store";
import { variablesListStore } from "@/modules/variables-list/variables-list-store";
import VariablesList from "@/modules/variables-list/VariablesList.vue";
import { Creds, CredScope, organization } from "@/protocol/identity";
import { actionType } from "@/protocol/infra";
import { EnvCreateStepFlowService, GithubOauthStorageService } from "@/services/storage-service";
import { CustomTimeStamp } from "@/shared/components";
import EntityCard from "@/shared/components/code-pipes/EntityCard.vue";
import { ACTION_STATE_TEXT_PIPELINE } from "@/shared/components/environment/EnvironmentActionConstants";
import EnvironmentState, {
	EnvironmentStatus
} from "@/shared/components/environment/EnvironmentState.vue";
import Markdown from "@/shared/components/Markdown.vue";
import { PIPELINE_FINISHED_JOB_STATUSES, PipelineJobStatus } from "@/shared/pipeline-constants";
import { downloadFile, formatDateToRegularFormat, normalizeUnixTime } from "@/utils";
import { getEmoji } from "@/utils/get-metadata";

import { ProjectTabName } from "../../project-landing/project-types";
import EnvDetailedViewTabs from "../components/EnvDetailedViewTabs.vue";
import EnvSettings from "../components/EnvSettings.vue";
import EnvWelcomePopover from "../components/EnvWelcomePopover.vue";
import { envListStore } from "../env-list-store";

type PopOverSteps = {
	welcome: boolean;
	createEdit: boolean;
	terraform: boolean;
	cloudAcc: boolean;
	validate: boolean;
};

export default defineComponent({
	name: "EnvDetailedView",

	components: {
		EnvWidgetEmptyOutputContent,
		GithubCredentialModal,
		AssignCredentialModal,
		CustomTimeStamp,
		ConnectTerraformRepo,
		EnvDetailedViewTabs,
		EnvWelcomePopover,
		EnvironmentState,
		EnvPipelineCards,
		EnvEditPopOver,
		EnvSettings,
		EnvDeletePopover,
		PolicySelector,
		VariablesList,
		InfoPopover,
		Typography,
		AppSection,
		EntityCard,
		Container,
		Wrapper,
		PopOver,
		Card,
		Icon,
		Tag,
		Markdown
	},

	props: {
		orgId: {
			type: String,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		envId: {
			type: String,
			required: true
		},

		tabName: {
			type: String as PropType<ProjectTabName>
		}
	},

	data: () => ({
		githubOauthFormRef: "EnvDetailedView",
		isEnvDelPopoverOpen: false,
		createdGitCred: null as Creds | null,
		steps: {
			welcome: false,
			createEdit: false,
			terraform: false,
			cloudAcc: false,
			validate: false
		} as PopOverSteps,

		isLoadingPipeline: false,
		isAddingNewGitAcc: false,
		isPipelineRunning: false,
		envIconBorderState: "default",
		credScope: CredScope,

		isStepFlow: false
	}),

	computed: {
		environmentStateStatusText() {
			const createdAt = this.env?.createdAt;
			const updatedAt = this.env?.updatedAt;
			const differenceOfTime = Math.abs(Number(createdAt) * 1000 - Number(updatedAt) * 1000) / 1000;
			const minutes = Number(Math.floor(differenceOfTime / 60) % 60);
			return {
				prefix: `${minutes > 0 ? "Updated" : "Created"}`,
				time: minutes > 0 ? updatedAt : createdAt
			};
		},

		environmentJobStatusText() {
			let actionText = "";
			let time: string | undefined = "";
			if (this.currentJobStatus && this.currentJob) {
				actionText = `${
					ACTION_STATE_TEXT_PIPELINE[this.currentJobStatus][this.currentJob.action as actionType]
				} `;
				time = this.currentJob.createdAt;

				if (this.isCurrentJobFinished && this.currentJob.updatedAt) {
					time = this.currentJob.updatedAt;
				}
			}
			return { prefix: actionText, time };
		},

		currentProject() {
			return projectStore.detailedProjects[this.projectId];
		},

		envApps() {
			return (this.projectId ? applicationStore.projectApps[this.projectId] : []) ?? [];
		},

		addedGitCred() {
			const { createdGitCred } = this;

			if (createdGitCred) {
				return createdGitCred;
			}

			return undefined;
		},

		assignedGitCred() {
			const [assignedGitCred] = getCredByScope({ entityId: this.envId, scope: CredScope.git });
			if (assignedGitCred) {
				return assignedGitCred;
			}
			return null;
		},

		assignedGitCredTooltip() {
			return this.assignedGitCred ? this.assignedGitCred.name : "No git credentials assigned";
		},

		emoji() {
			return getEmoji(this.env, "🌐");
		},

		showWelcomePopover() {
			return !userStore.profile?.metadata?.hasCreatedEnvs || false;
		},

		showCreateEditPopover() {
			return this.steps.createEdit;
		},

		showStepsFlow() {
			return (
				this.showWelcomePopover ||
				this.steps.welcome ||
				this.steps.createEdit ||
				this.isEnvDelPopoverOpen
			);
		},

		env() {
			const projectEnvs = envListStore.envs[this.projectId] ?? [];
			return projectEnvs.find(env => env.id === this.envId);
		},

		title() {
			const { orgs }: { orgs: organization[] } = orgStore;
			const org = orgs.find(matchingOrg => matchingOrg.id === this.orgId);

			return [this.env?.name, projectStore.currentProject?.name, org?.name]
				.filter(Boolean)
				.join(" - ");
		},

		currentTab(): ProjectTabName {
			// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
			return this.tabName || "applications";
		},

		variablesCount() {
			return variablesListStore.variableCount;
		},

		outputCount() {
			let count = 0;
			const outputSummary = this.env ? envListStore.envOutputSummary[this.env.id] : null;
			if (outputSummary?.outputs) {
				count = Object.keys(outputSummary.outputs).length;
			}
			return count;
		},

		apps() {
			return (this.projectId && applicationStore.projectApps[this.projectId]) ?? [];
		},

		currentJob() {
			const [currentJob] = getSortedEnvJobs(this.envId);

			return currentJob ?? null;
		},

		currentJobStatus() {
			return this.currentJob?.status as PipelineJobStatus | undefined;
		},

		isCurrentJobFinished() {
			return (
				this.currentJobStatus && PIPELINE_FINISHED_JOB_STATUSES.includes(this.currentJobStatus)
			);
		},

		environmentStateStatus(): EnvironmentStatus {
			if (this.isCurrentJobFinished ?? !this.currentJobStatus) {
				return "default";
			}

			if (this.currentJobStatus === "canceling") {
				return "cancelling";
			}

			switch (this.currentJob?.action) {
				case actionType.validateOnly:
					return "validating";
				case actionType.destroy:
					return "destroying";
				case actionType.ValidateAndApply:
					return "deploying";
			}

			return "default";
		},

		canAccessCreds() {
			return orgStore.isUserOrgAdmin;
		},

		allowedClassificationIds() {
			const classificationId = this.env?.classification?.id;
			return classificationId ? [classificationId] : [];
		}
	},

	watch: {
		title: {
			immediate: true,
			deep: true,

			handler(newTitle: string) {
				document.title = newTitle;
			}
		},

		env: {
			deep: true,
			immediate: true,

			handler() {
				const { env } = this;
				if (!env) {
					return;
				}

				breadcrumbStore.SET_ADDITIONAL_BREADCRUMBS([
					{
						qaId: "environment",
						label: env.name,
						route: {
							name: "envDetail",
							props: {
								orgId: env.orgId,
								projectId: env.projId,
								envId: env.id
							}
						}
					}
				]);
			}
		}
	},

	mounted() {
		(async () => {
			// get step flow current status
			const envStepFlow = EnvCreateStepFlowService.getToggleFlow();
			if (envStepFlow.isEnabled) {
				this.isStepFlow = true;
			}

			if (this.isStepFlow && !this.showWelcomePopover) {
				this.steps.createEdit = true;
			}

			this.loadEnvDetailedData({
				orgId: this.orgId,
				projectId: this.projectId,
				envId: this.envId
			});

			envListStore.GET_ENV_OUTPUT_SUMMARY({
				orgId: this.orgId,
				projectId: this.projectId,
				envId: this.envId
			});

			// Get creds for the current environment
			if (await orgStore.IS_USER_ORG_ADMIN({ orgId: this.orgId })) {
				await credentialStore.GET_CREDS_FOR_ENTITY({
					orgId: this.orgId,
					environment: {
						id: this.envId,
						orgId: this.orgId,
						projId: this.projectId
					}
				});
			}

			this.handleCreateEditPopoverVisibility();

			this.handleGithubOauthRedirect();
		})();
	},

	methods: {
		async getTerraformState() {
			const content = await envListStore.GET_OUTPUT_FOR_ENV({
				orgId: this.orgId,
				projectId: this.projectId,
				envId: this.envId
			});

			downloadFile(content, `${this.envId}.tfstate`);
		},

		changeDateTimeFormat(timestamp: string) {
			return formatDateToRegularFormat(normalizeUnixTime(timestamp));
		},

		setCurrentTab(tabName: ProjectTabName) {
			this.$router.push({
				name: "envDetail",
				params: { orgId: this.orgId, projectId: this.projectId, envId: this.envId, tabName }
			});
		},

		handleCreateEditPopoverVisibility() {
			if (!this.showWelcomePopover && this.isStepFlow) {
				this.steps.createEdit = true;
			}
		},

		handleGithubOauthRedirect() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (
				githubOauthResult.isUserOauthActive &&
				githubOauthResult.gitFormRef === this.githubOauthFormRef
			) {
				this.isAddingNewGitAcc = true;
				this.resetSteps();
				this.steps.terraform = true;
			}
		},

		showEnvPictogramLoader({ isRunning, action }: { isRunning: boolean; action: actionType }) {
			this.isPipelineRunning = isRunning;
			const iconState = action === actionType.ValidateAndApply ? "success" : "error";
			this.envIconBorderState = action === actionType.validateOnly ? "primary" : iconState;
		},

		toggleNewGitAcc() {
			this.isAddingNewGitAcc = !this.isAddingNewGitAcc;
		},

		gitCredCreated(cred: Creds) {
			this.createdGitCred = cred;
		},

		resetSteps() {
			(Object.keys(this.steps) as Array<keyof PopOverSteps>).forEach(
				(step: keyof PopOverSteps) => (this.steps[step] = false)
			);
		},

		markEnvCreation() {
			if (this.showWelcomePopover) {
				userStore.UPDATE_USER_META({
					hasCreatedEnvs: true
				});
			}
		},

		envAcknowledged(step: keyof PopOverSteps) {
			this.markEnvCreation();
			this.goToNextStep(step);
		},

		goToNextStep(step: keyof PopOverSteps) {
			if (this.isStepFlow) {
				this.resetSteps();
				this.steps[step] = true;
				if (step === "cloudAcc") {
					(
						this.$refs.AssignCredentialModal as InstanceType<typeof AssignCredentialModal>
					).showCredAssignModal();
				}
			} else {
				this.steps[step] = !this.steps[step];
			}
		},

		resetStepFlow() {
			this.markEnvCreation();
			this.resetSteps();
			this.isEnvDelPopoverOpen = false;
			this.isAddingNewGitAcc = false;
			EnvCreateStepFlowService.toggleFlow({ isEnabled: false });
			this.isStepFlow = false;
		},

		toggleEnvUpdate() {
			this.steps.createEdit = !this.steps.createEdit;
		},

		toggleDeleteEnv() {
			this.isEnvDelPopoverOpen = !this.isEnvDelPopoverOpen;
			if (!this.isEnvDelPopoverOpen) {
				envDeleteStore.SET_ENV_TO_DELETE(null);
			} else {
				envDeleteStore.SET_ENV_TO_DELETE(this.env ?? null);
			}
		},

		toggleConnectTerraform() {
			this.steps.terraform = !this.steps.terraform;
			if (!this.steps.terraform) {
				this.resetStepFlow();
			}
		},

		goBack(step: keyof PopOverSteps) {
			if (this.isAddingNewGitAcc) {
				this.isAddingNewGitAcc = false;
			} else if (this.isStepFlow) {
				this.resetSteps();
				if (step === "terraform") {
					(
						this.$refs.AssignCredentialModal as InstanceType<typeof AssignCredentialModal>
					).closeCredAssignModal({ closeSilently: true });
				}
				this.steps[step] = true;
			}
		},

		async loadEnvDetailedData({
			envId,
			projectId,
			orgId
		}: {
			envId: string;
			projectId: string;
			orgId: string;
		}) {
			await envListStore.GET_ENV_BY_ID({
				orgId,
				projectId,
				envId
			});

			// Fetch jobs for the current env
			newEnvPipelineStore.SET_JOBS_LOADING(true);

			const promises: Promise<void>[] = [];
			const pipelines = ["validateOnly", "ValidateAndApply", "destroy"] as actionType[];

			this.isLoadingPipeline = true;

			pipelines.forEach((value: actionType) => {
				newEnvPipelineStore.SET_ENV_PIPELINE_LOADER({ envId, value: true, action: value });
				promises.push(
					newEnvPipelineStore.LIST_JOBS_FOR_ENV({
						orgId,
						projId: projectId,
						envId,
						limit: 1,
						action: value
					})
				);
			});

			await Promise.all(promises);

			this.isLoadingPipeline = false;

			pipelines.forEach((value: actionType) => {
				newEnvPipelineStore.SET_ENV_PIPELINE_LOADER({ envId, value: false, action: value });
			});

			newEnvPipelineStore.SET_JOBS_LOADING(false);
		}
	}
});
</script>

<style lang="scss">
div.flow-wrapper.disabled {
	background: var(gray-500);
	opacity: 0.4;
	pointer-events: none;
}

// Need to overlap bullet styling to display white color of the bullet in info tooltip
.white-bullet {
	li {
		margin-left: 1rem;
		list-style-type: disc;
		&::marker {
			color: white;
		}
	}
}
</style>
