<template>
	<f-form-builder
		ref="keyVarForm"
		data-qa-add-variable-form
		:field.prop="formFields"
		:values.prop="internalValue"
		@input="handleInputValue"
		@state-change="formState = $event.detail"
	>
	</f-form-builder>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { PropType, defineComponent } from "vue";

import { variableType } from "@/protocol/common";
import { variableNameRule } from "@/shared/custom-validation-rules/entityNameRules";

import { SelectedVariableScopes } from "./AddVariableScopeForm.vue";
import {
	ENV_OPTION,
	Variable,
	AddVariableScope,
	TF_OPTION,
	VariableTypeFormOption,
	VariableScope
} from "./variable-list-types";

export default defineComponent({
	name: "AddVariableKeyValueForm",

	props: {
		variable: {
			type: Object as PropType<Variable>,
			required: true
		},

		selectedVariableScopes: {
			type: Array as PropType<SelectedVariableScopes[]>
		},

		addedVariables: {
			type: Array as PropType<AddVariableScope[]>
		},

		storeVariableScopes: {
			type: Array as PropType<VariableScope[]>
		},

		hideVariableType: Boolean
	},

	emits: ["update:variable", "validity-change"],

	data() {
		return {
			initialKey: this.variable.key,
			initialType: this.variable.variableType,
			isMasked: false,
			formState: null as FormBuilderState | null,
			internalValue: variableModelToInternalValue(this.variable)
		};
	},

	computed: {
		duplicateVariableError() {
			const variableName = this.internalValue.key;

			if (!variableName) {
				return null;
			}

			const duplicateScopes = new Set([
				...this.getDuplicateScopes(this.addedVariables),
				...this.getDuplicateScopes(this.storeVariableScopes)
			]);

			if (duplicateScopes.size !== 0) {
				return `${variableName} is already present in ${Array.from(duplicateScopes).join(",")}`;
			}

			return null;
		},

		hasOnlyEnvVariables() {
			const formScope = this.selectedVariableScopes;

			const hasAnyAppsOrIntegrations = formScope?.some(
				scope =>
					scope.variableFrom === "application-deployment" ||
					scope.variableFrom === "application-integration"
			);

			return hasAnyAppsOrIntegrations;
		},

		formFields(): FormBuilderField {
			const { duplicateVariableError, hideVariableType } = this;

			return {
				type: "object",
				direction: "vertical",
				fields: {
					key: {
						type: "text",
						label: { title: "Name" },
						placeholder: "Enter name",
						id: "key",
						validationRules: [
							{ name: "required" },
							{
								name: "custom",
								validate() {
									return duplicateVariableError === null;
								},

								message: duplicateVariableError ?? "Variable with same name exists already"
							},
							variableNameRule(this.variable.variableType)
						]
					},

					value: {
						type: "textarea",
						rows: "2",
						resizable: true,
						maskValue: this.variable.isMasked,
						id: "value",
						label: {
							title: "Value",
							subTitle: html`
								<f-checkbox
									size="medium"
									value="${this.variable.isMasked ? "checked" : "unchecked"}"
									state="default"
									data-qa-mask-variable-checkbox
									@input="${(e: CustomEvent) => {
										this.isMasked = e.detail.value === "checked";

										this.$emit("update:variable", {
											...this.variable,
											isMasked: this.isMasked
										} satisfies Variable);
									}}"
								>
									<f-div slot="label" padding="none">
										<f-text variant="para" size="small">Mask this</f-text>
									</f-div>
								</f-checkbox>
							`
						},

						placeholder: "Enter value",
						validationRules: [{ name: "required" }]
					},

					variableType: {
						type: "radio",
						qaId: "variableType",
						label: {
							title: "Variable type",
							iconTooltip:
								"For each variable, you need to specify whether the variable is a Terraform variable or an environment variable. Terraform variables define characteristics of application. Environment variables define aspects of the environment into which you are deploying the application."
						},

						options: this.hasOnlyEnvVariables ? [] : [TF_OPTION, ENV_OPTION],
						helperText: this.hasOnlyEnvVariables
							? html`<f-text data-qa-disabled-variable-warning state="warning" size="small"
									>Variable type selection is only available on projects and environments</f-text
							  >`
							: undefined,

						validationRules: [{ name: "required" }],

						showWhen() {
							return !hideVariableType;
						}
					}
				}
			};
		}
	},

	watch: {
		"formState.isValid": {
			immediate: true,

			handler() {
				this.emitValidityChange();
			}
		},

		duplicateVariableError: {
			immediate: true,

			handler() {
				// When we have a custom error we re-validate the form to show it
				// but only after it has re-rendered
				this.$nextTick(() => {
					if (this.duplicateVariableError !== null) {
						(this.$refs.keyVarForm as FFormBuilder | undefined)?.validateForm();
					}
				});

				this.emitValidityChange();
			}
		},

		variable: {
			immediate: true,

			handler() {
				this.internalValue = variableModelToInternalValue(this.variable);
				this.isMasked = this.variable.isMasked;
			}
		}
	},

	methods: {
		getDuplicateScopes(scopes?: Array<AddVariableScope | VariableScope>) {
			const {
				selectedVariableScopes,
				variable: { key }
			} = this;

			if (!scopes || !selectedVariableScopes || !key) {
				return [];
			}

			const duplicateScopes: string[] = [];
			const existingScopeIds = selectedVariableScopes.map(scope => scope.id);
			const varType = this.variable.variableType;

			scopes
				.filter(scope => {
					return existingScopeIds.includes(scope.id);
				})
				.forEach(scope => {
					scope.variables.forEach(variable => {
						if (
							variable.key === key &&
							variable.variableType === varType &&
							// Make sure we don't complain about the variable we are already editing
							(variable.key !== this.initialKey || variable.variableType !== this.initialType)
						) {
							duplicateScopes.push(scope.name);
						}
					});
				});

			return duplicateScopes;
		},

		emitValidityChange() {
			this.$emit(
				"validity-change",
				Boolean(this.formState?.isValid && !this.duplicateVariableError)
			);
		},

		handleInputValue(event: CustomEvent<VariableKeyInternalValue>) {
			const varType = this.hasOnlyEnvVariables
				? variableType.env_var
				: formOptionToVariableType(event.detail.variableType);

			this.$emit(
				"validity-change",
				Boolean(this.formState?.isValid && !this.duplicateVariableError)
			);

			this.$emit("update:variable", {
				...event.detail,
				isMasked: this.isMasked,
				variableType: varType
			} satisfies Variable);
		}
	}
});

export type VariableKeyInternalValue = {
	key: string;
	value: string;
	isMasked: boolean;
	variableType: VariableTypeFormOption;
};

function variableModelToInternalValue(modal: Variable): VariableKeyInternalValue {
	return {
		...modal,
		variableType: modal.variableType === variableType.env_var ? ENV_OPTION : TF_OPTION
	};
}

function formOptionToVariableType(option: VariableTypeFormOption) {
	return option.id === ENV_OPTION.id ? variableType.env_var : variableType.tf_var;
}
</script>
