<template>
	<Container :padding="0" :gap="12" align="center" :shrink="0">
		<Pictogram :size="entitySize" v-bind="leftProps">
			<Typography v-if="equation.leftTerm.emoji" type="h3" weight="bold" color="dark">{{
				equation.leftTerm.emoji
			}}</Typography>
			<Typography v-else type="p3" weight="bold" color="dark">{{
				getShortCode(equation.leftTerm.label)
			}}</Typography>
		</Pictogram>
		<Icon :name="equation.operator.name" :state="operatorState" size="small" :effects="false" />
		<Pictogram :size="entitySize" v-bind="rightProps">
			<Typography v-if="equation.rightTerm.emoji" type="p1" weight="bold" color="dark">{{
				equation.rightTerm.emoji
			}}</Typography>
			<Typography v-else type="p3" weight="bold" color="dark">{{
				getShortCode(equation.rightTerm.label)
			}}</Typography>
		</Pictogram>
	</Container>
</template>
<script lang="ts">
import {
	Container,
	Pictogram,
	Icon,
	Typography,
	PictogramShape,
	PictogramState
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { getShortCode } from "@/utils";

import { ActivityEquation, ActivityEquationTerm } from "./../notification-types";

export default defineComponent({
	name: "NotificationActivityEquation",

	components: {
		Container,
		Pictogram,
		Icon,
		Typography
	},

	props: {
		equation: {
			type: Object as PropType<ActivityEquation>,
			required: true
		},

		entitySize: {
			type: String as PropType<"s" | "m" | "l">,
			default: () => "m"
		}
	},

	computed: {
		leftProps() {
			const { leftTerm } = this.equation;
			return {
				shape: this.getShape(leftTerm),
				state: this.getState(leftTerm)
			};
		},

		rightProps() {
			const { rightTerm } = this.equation;
			return {
				shape: this.getShape(rightTerm),
				state: this.getState(rightTerm)
			};
		},

		operatorState() {
			return this.equation.operator.state ? this.equation.operator.state : "default";
		}
	},

	methods: {
		getShortCode(value: string) {
			return getShortCode(value);
		},

		getShape(term: ActivityEquationTerm): PictogramShape {
			if (term.type === "application") {
				return "squircle";
			} else if (term.type === "environment") {
				return "hexagon";
			}
			return "default";
		},

		getState(term: ActivityEquationTerm): PictogramState {
			if (term.state === "error") {
				return "error-light";
			} else if (term.state === "success") {
				return "success-light";
			} else if (term.state === "warning") {
				return "warning-light";
			} else if (term.state === "default") {
				return "default";
			}
			return "light";
		}
	}
});
</script>
