<template>
	<Wrapper border-radius="4px" background="gray-500" width="480px" max-height="80vh">
		<Header>
			<Typography type="h4" color="dark"> Name your environment </Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Tag v-if="isStepFlow">
					<Typography type="p2" color="light">STEP 1 / 3</Typography>
				</Tag>
				<Icon
					size="x-small"
					type="filled"
					name="i-close"
					data-qa-close-create-environment-popup
					@click.stop="closeCreateEnv()"
				/>
			</Container>
		</Header>
		<Container :padding="16" :gap="16" align="left top" direction="column" overflow="auto">
			<f-form-builder
				data-qa-create-env-form
				:field.prop="formFields"
				:values.prop="formValues"
				@input="handleInput"
				@state-change="formState = $event.detail"
			/>
		</Container>
		<Container v-if="errorMessage" direction="column" padding="15px 15px 15px 15px" :gap="0">
			<Typography type="p2" color="error" data-qa-env-create-edit-error-msg>
				{{ errorMessage }}
			</Typography>
		</Container>
		<Footer>
			<Button
				state="full"
				type="success"
				:disabled="disableSubmitBtn"
				data-qa-create-env-submit-button
				:loading="createUpdateEnvInPrgress"
				@click="goToNextStep"
			>
				{{ isStepFlow ? "NEXT - CONNECT TERRAFORM REPOSITORY" : "UPDATE ENVIRONMENT" }}
			</Button>
		</Footer>
	</Wrapper>
</template>

<script lang="ts">
import { FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	Tag,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { DeploymentJobAction } from "@/protocol/deployment";
import { environment } from "@/protocol/infra";
import { EnvCreateStepFlowService } from "@/services/storage-service";
import { MARKDOWN_HELPER_TOOLTIP } from "@/shared/constants";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import { JOB_STATUS } from "@/shared/pipeline-constants";
import { captureError, getEmoji, getErrorMessage } from "@/utils";

export default defineComponent({
	name: "EnvEditPopOver",

	components: {
		Button,
		Container,
		Footer,
		Header,
		Icon,
		Tag,
		Typography,
		Wrapper
	},

	props: {
		env: {
			type: Object as PropType<environment>,
			required: true
		}
	},

	data() {
		return {
			anyAppDeployed: false,
			loading: false,
			formValues: {
				envNameAndIcon: {
					name: this.env.name,
					emoji: getEmoji(this.env, "🌐")
				},

				description: this.env.description ?? "",
				__showDescription__: false
			} as FormFieldValues,

			createUpdateEnvInPrgress: false,
			formState: null as FormBuilderState | null,
			errorMessage: ""
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					envNameAndIcon: {
						type: "object",
						direction: "horizontal",
						helperText:
							!this.loading && this.anyAppDeployed
								? html`<f-text
										size="small"
										state="danger"
										data-qa-error-for="envNameAndIcon"
										data-qa-login-error
										>Environment can’t be renamed if they have active deployments</f-text
								  >`
								: "",

						fields: {
							name: {
								type: "text",
								label: { title: "Environment name" },
								qaId: "name",
								disabled: !this.loading && this.anyAppDeployed,
								placeholder: "Enter environment name",
								validationRules: applyEntityNameRules2(
									this.existingEnvNames,
									"This environment name is already taken."
								)
							},

							emoji: {
								type: "emoji",
								label: { title: "Icon" },
								qaId: "emoji",
								placeholder: "🌐",
								validationRules: [{ name: "required" }]
							}
						}
					},

					addDescription: {
						type: "button",
						label: "Add description",
						iconLeft: "i-plus",
						category: "transparent",
						size: "small",
						qaId: "addDescription",
						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: true };
						},

						showWhen(values) {
							const formValues = values as FormFieldValues;
							return !formValues.__showDescription__ && !formValues.description;
						}
					},

					__showDescription__: {
						type: "hidden"
					},

					description: {
						type: "textarea",
						label: {
							title: "Environment Description",
							subTitle: "Optional",
							iconTooltip: MARKDOWN_HELPER_TOOLTIP
						},

						qaId: "description",
						placeholder: "Add a brief description about your environment",
						showWhen(values) {
							const formValues = values as FormFieldValues;
							return Boolean(
								(typeof formValues.description === "string" && formValues.description.length > 0) ||
									formValues.__showDescription__
							);
						}
					},

					removeDescription: {
						type: "button",
						qaId: "removeDescription",
						label: "Remove environment description",
						iconLeft: "i-close",
						category: "transparent",
						state: "danger",
						size: "small",
						onClick: () => {
							this.formValues = { ...this.formValues, __showDescription__: false, description: "" };
						},

						showWhen(values) {
							const formValues = values as FormFieldValues;
							return Boolean(formValues.__showDescription__ || formValues.description);
						}
					}
				}
			};
		},

		existingEnvNames() {
			return (
				envListStore.envs[this.env.projId]
					?.map(env => env.name)
					.filter(envName => (this.env.name ? this.env.name !== envName : true)) ?? []
			);
		},

		isStepFlow() {
			return EnvCreateStepFlowService.getToggleFlow().isEnabled;
		},

		isEnvModified() {
			return (
				this.formValues.envNameAndIcon.name !== this.env.name ||
				this.formValues.envNameAndIcon.emoji !== getEmoji(this.env, "🌐") ||
				this.formValues.description !== this.env.description
			);
		},

		disableSubmitBtn() {
			if (this.createUpdateEnvInPrgress || !this.formState?.isValid) {
				return true;
			}

			// If we are in normal editing flow, then disable the button if the user didn't make any changes
			if (!this.isStepFlow) {
				return !this.isEnvModified;
			}

			return false;
		}
	},

	mounted() {
		this.fetchEnvDeployments();
	},

	methods: {
		async fetchEnvDeployments() {
			this.loading = true;
			try {
				await applicationDeploymentStore.LIST_ENV_APP_DEPLOYMENTS({
					orgId: this.env.orgId,
					projectId: this.env.projId,
					envId: this.env.id
				});
				const deployedApps = applicationDeploymentStore.deploymentsInEnv[this.env.id] ?? [];
				this.anyAppDeployed = deployedApps.some(app => {
					const jobType = app.lastSuccessfulJob?.jobType;
					const jobStatus = app.lastSuccessfulJob?.jobStatus;
					return (
						jobType === DeploymentJobAction.deploy &&
						(jobStatus === JOB_STATUS.DONE ||
							jobStatus === JOB_STATUS.ACTIVE ||
							jobStatus === JOB_STATUS.CANCELLING ||
							jobStatus === JOB_STATUS.WAITING ||
							jobStatus === JOB_STATUS.PENDING)
					);
				});
			} catch (error) {
				captureError(error);
			} finally {
				this.loading = false;
			}
		},

		handleInput(input: CustomEvent<FormFieldValues>) {
			this.formValues = {
				...input.detail,
				envNameAndIcon: {
					...input.detail.envNameAndIcon
				}
			};
		},

		async goToNextStep() {
			// No changes
			if (!this.isEnvModified && this.isStepFlow) {
				this.$emit("goNext", "terraform");
			} else {
				try {
					this.createUpdateEnvInPrgress = true;

					await envListStore.UPDATE_ENV({
						id: this.env.id,
						orgId: this.env.orgId,
						projId: this.env.projId,
						name: this.formValues.envNameAndIcon.name,
						description: this.formValues.description,
						revision: this.env.revision,
						variables: this.env.variables,
						pipelineConfig: this.env.pipelineConfig,
						policySetId: this.env.policySetId,
						metadata: {
							emojiObj: {
								native: this.formValues.envNameAndIcon.emoji
							}
						},
						tfVersion: this.env.tfVersion,
						replaceVariables: false
					});

					this.createUpdateEnvInPrgress = false;

					if (this.isStepFlow) {
						this.$emit("goNext", "terraform");
					} else {
						this.$emit("close");
					}

					this.errorMessage = "";
				} catch (error) {
					this.createUpdateEnvInPrgress = false;
					const msg = getErrorMessage(error);
					this.errorMessage = msg;
				}
			}
		},

		closeCreateEnv() {
			this.$emit("close");
		}
	}
});

type FormFieldValues = {
	envNameAndIcon: { name: string; emoji: string };
	description: string;
	__showDescription__: boolean;
};
</script>
