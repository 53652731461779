import {
	environment,
	environments,
	EnvStateResponse,
	infraService,
	success
} from "@/protocol/infra";
import ApiService, { INFRA_API } from "@/services/api-service";

export const listEnvs: infraService["listEnv"] = async function ({
	projId,
	orgId,
	detailed = true
}) {
	const response = await ApiService.get<environments>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs?detailed=${detailed}`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getEnvironmentById: infraService["getEnv"] = async function ({ id, orgId, projId }) {
	const response = await ApiService.get<environment>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${id}`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getOutputByEnvId: infraService["envState"] = async function ({ orgId, projId, id }) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const response = await ApiService.get<Record<string, any>>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${id}/output`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getEnvOutputSummary: infraService["envStateSummary"] = async function ({
	id,
	orgId,
	projId
}) {
	const response = await ApiService.get<EnvStateResponse>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${id}/outputSummary`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const updateEnv: infraService["updateEnv"] = async function (payload) {
	const { orgId, projId, id } = payload;
	const response = await ApiService.put<Record<string, never>>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${id}`,
		payload
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const updateEnvVariables: infraService["updateVariables"] = async function (payload) {
	const { orgId, projId, envId } = payload;
	const response = await ApiService.patch<success>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/vars`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteEnvVariables: infraService["deleteVariables"] = async function (payload) {
	const { orgId, projId, envId } = payload;

	const response = await ApiService.patch<success>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${envId}/vars/delete`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
