import { CrumbItem } from "@cldcvr/flow-vue3";
import { Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

import { orgStore } from "../org/org-store";
import { projectStore } from "../project-list/project-store";
import { getStore } from "../store";

@Module({
	namespaced: true,
	dynamic: true,
	name: "breadcrumb",
	store: getStore()
})
class BreadcrumbStore extends VuexModule {
	additionalCrumbs: BreadCrumb[] = [];

	// Base breadcrumbs for org and project are automatically set to make dev easier
	get breadcrumbs(): BreadCrumb[] {
		const breadcrumbs: BreadCrumb[] = [];

		if (this.currentOrgCrumb) {
			breadcrumbs.push(this.currentOrgCrumb);
		}

		if (this.currentProjectCrumb) {
			breadcrumbs.push(this.currentProjectCrumb);
		}

		breadcrumbs.push(...this.additionalCrumbs);

		return breadcrumbs;
	}

	get currentOrgCrumb(): BreadCrumb | null {
		const orgId = orgStore.activeOrgId;

		if (!orgId) {
			return null;
		}

		return {
			// it's meant to be org-home but we don't want to break automation
			qaId: "project-home",
			label: orgStore.activeOrg?.name ?? "Home",
			route: {
				name: "home",
				props: { orgId }
			}
		};
	}

	get currentProjectCrumb(): BreadCrumb | null {
		if (!projectStore.currentProjectId) {
			return null;
		}

		const project = projectStore.detailedProjects[projectStore.currentProjectId];

		if (!project) {
			return null;
		}

		return {
			qaId: "project-home-alt",
			label: project.name,
			route: {
				name: "projectListWithProject",
				props: {
					orgId: project.orgId,
					projectId: project.id
				}
			}
		};
	}

	@Mutation
	RESET_BREADCRUMBS() {
		this.additionalCrumbs = [];
	}

	@Mutation
	SET_ADDITIONAL_BREADCRUMBS(additionalCrumbs: BreadCrumb[]) {
		this.additionalCrumbs = additionalCrumbs;
	}
}

export type BreadCrumb = CrumbItem & {
	route: {
		name: string;
		props?: Record<string, string>;
	};
};

const breadcrumbStore = getModule(BreadcrumbStore);

export { breadcrumbStore };
