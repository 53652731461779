<template>
	<Wrapper
		background="element-light"
		width="250px"
		height="100px"
		border-radius="4px"
		class="cursor-pointer"
		:border="true"
		@mouseover="$emit('selectOnHover', envClassification, true)"
		@mouseleave="$emit('selectOnHover', envClassification, false)"
	>
		<Card background="element-light">
			<Container :padding="16" align="space-between center" class="height-100-per width-100-per">
				<Container :padding="0">
					<Icon size="small" :name="envTypeIcon" type="filled" :effects="false" color="gray-200" />
					<Typography
						type="p1"
						weight="bold"
						color="dark"
						:data-qa="`env-set-slideout-card-${envClassification.name}`"
						>{{ envClassification.name }}</Typography
					>
				</Container>
			</Container>
		</Card>
	</Wrapper>
</template>

<script lang="ts">
import { Container, Wrapper, Icon, Typography, Card } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { classification } from "@/protocol/infra";
import { getClassificationIcon } from "@/utils";

export default defineComponent({
	name: "EnvElementCard",

	components: {
		Container,
		Wrapper,
		Icon,
		Typography,
		Card
	},

	props: {
		envClassification: {
			type: Object as PropType<classification>,
			default: () => null
		}
	},

	computed: {
		envTypeIcon() {
			return getClassificationIcon(this.envClassification);
		}
	}
});
</script>
