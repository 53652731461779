import { Store, createStore } from "vuex";
import { config } from "vuex-module-decorators";

config.rawError = true;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRootState {}
export type CodePipesStore = Store<IRootState>;

const store = createStore<CodePipesStore>({});

export function getStore() {
	return store;
}
