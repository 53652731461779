<template>
	<Wrapper
		max-height="70vh"
		border-radius="4px"
		background="element-light"
		data-qa-gitlab-cred-model
	>
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			class="height-100-per flex-shrink-0"
		>
			<Header>
				<Icon
					v-if="showBackBtn"
					name="i-arrow-left"
					size="small"
					data-qa-gitlab-cred-model-back-btn
					@click="validateAndCloseModal('back')"
				/>
				<Typography type="h4" color="dark" data-qa-gitlab-cred-model-title>{{
					headerTxt
				}}</Typography>
				<Container :padding="0" :grow="1" align="right center">
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-gitlab-cred-model-close-btn
						@click="validateAndCloseModal"
					/>
				</Container>
			</Header>
			<!--END : PopOver header-->

			<ClosePopoverConfirmationWarning
				ref="closeConfirmation"
				:initial-form-values="initialformValues"
				:form-values="gitlabCredFormValues"
				@force-close="closeModal"
			/>

			<ModalNotificationBanner
				v-if="cred"
				banner-type="primary"
				banner-body="Changes made are applicable to the future deployments."
			/>

			<ModalNotificationBanner
				v-if="submitError"
				banner-type="error"
				:banner-body="submitError"
				:show-close-icon="true"
				@close="submitError = null"
			/>

			<Container :padding="16" :gap="24" direction="column" overflow="auto" align="left top">
				<f-form-builder
					ref="formBuilder"
					data-qa-gitlab-cred-model-form
					:field.prop="formFields"
					:values.prop="gitlabCredFormValues"
					@input="saveFormValues"
					@state-change="formState = $event.detail"
				/>
			</Container>

			<Footer>
				<Button
					state="full"
					:disabled="isSubmitting"
					:loading="isSubmitting"
					:type="isSubmitting ? 'default' : 'success'"
					data-qa-gitlab-cred-model-save-btn
					@click="saveGitLabCredentials"
					>{{ ctaBtnTxt }}</Button
				>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { credentialStore } from "@/modules/credentials/credential-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { Creds, CredsCreate, credsType, CredsUpdate, gitlabCreds } from "@/protocol/identity";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import {
	captureError,
	getClassificationFormField,
	getClassificationFormValue,
	getErrorMessage
} from "@/utils";

import { ClassificationType } from "../../credential-types";

export default defineComponent({
	name: "GitLabCredentialModal",

	components: {
		ClosePopoverConfirmationWarning,
		ModalNotificationBanner,
		Typography,
		Container,
		Wrapper,
		Button,
		Footer,
		Header,
		Icon
	},

	props: {
		assignCred: {
			type: Boolean
		},

		showBackBtn: {
			type: Boolean
		},

		updateCredList: {
			type: Boolean
		},

		cred: {
			type: Object as PropType<Creds>
		},

		allowedClassificationIds: {
			type: Array as PropType<string[]>
		}
	},

	data: () => {
		return {
			formState: null as FormBuilderState | null,
			gitlabCredFormValues: {} as LocalGitLabCredFormValues,
			initialformValues: {} as LocalGitLabCredFormValues,
			isSubmitting: false,
			submitError: null as string | null,
			existingCredNames: [] as string[]
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					username: {
						validationRules: [{ name: "required" }],
						label: { title: "Username" },
						type: "text",
						id: "username",
						placeholder: "Enter username"
					},

					password: {
						type: "password",
						validationRules: [{ name: "required" }],
						label: { title: "Enter password", iconTooltip: "Enter access token or password" },
						id: "password",
						placeholder: "Enter password"
					},

					__separator__: {
						type: "separator"
					},

					...getClassificationFormField(this.cred, this.allowedClassificationIds),

					name: {
						type: "text",
						validationRules: [
							...applyEntityNameRules2(this.existingCredNames, "This display name already exists.")
						],

						label: {
							title: "Display name",
							iconTooltip: "This name will be used to refer to your saved credential on Code Pipes"
						},

						id: "name",
						placeholder: "Enter display name"
					}
				}
			};
		},

		orgId() {
			return this.$route.params.orgId as string;
		},

		headerTxt() {
			if (this.assignCred) {
				return "Assign credential";
			} else if (this.cred) {
				return "Edit GitLab credential";
			}
			return "Add GitLab credential";
		},

		ctaBtnTxt() {
			if (this.assignCred) {
				return "Assign credential";
			}
			return "Save GitLab credential";
		}
	},

	mounted() {
		this.existingCredNames = credentialStore.existingCredentialNames.filter(_credName =>
			this.cred ? this.cred.name !== _credName : true
		);

		if (this.cred) {
			this.gitlabCredFormValues = {
				name: this.cred.name,
				username: this.cred.gitlab?.username,
				...(this.cred.classificationId && getClassificationFormValue(this.cred.classificationId))
			};

			this.initialformValues = this.gitlabCredFormValues;
		}
	},

	methods: {
		// eslint-disable-next-line max-statements
		async saveGitLabCredentials() {
			if (this.isSubmitting) {
				return null;
			}

			this.submitError = "";
			this.isSubmitting = true;

			(this.$refs.formBuilder as FFormBuilder).submit();

			if (!this.formState?.isValid) {
				this.isSubmitting = false;
				return null;
			}

			try {
				let cred: Creds | null = null;

				// FormBuilder ensures that the form is not submitted without required fields.
				const gitlabPayload: gitlabCreds = {
					username: this.gitlabCredFormValues.username!,
					password: this.gitlabCredFormValues.password!
				};

				if (this.cred) {
					const updateCredPayload: CredsUpdate = {
						name: this.gitlabCredFormValues.name!,
						id: this.cred.id,
						orgId: this.cred.orgId,
						type: credsType.credsType_gitlab,
						gitlab: gitlabPayload
					};

					await credentialStore.updateCredential({ cred: updateCredPayload });

					// Get latest cred object and update the store.
					credentialStore.GET_UPDATE_CREDENTIAL({ orgId: this.orgId, id: this.cred.id });
				} else {
					const createCredpayload: CredsCreate = {
						name: this.gitlabCredFormValues.name!,
						orgId: this.orgId,
						type: credsType.credsType_gitlab,
						gitlab: gitlabPayload,
						...(this.gitlabCredFormValues.classification && {
							classificationId: this.gitlabCredFormValues.classification.data.id
						})
					};

					cred = await credentialStore.createCredential({ cred: createCredpayload });

					// Update the credential list
					if ("id" in cred) {
						credentialStore.UPDATE_CRED_AND_STATS_BY_ID(cred);
					}
				}

				if (this.updateCredList) {
					if (this.cred) {
						notificationsStore.ADD_TOAST({
							qaId: "toast-git-cred-gitlab-edited-successfully",
							title: "Git credential updated",
							text: `Git credential ${this.gitlabCredFormValues.name} is successfully updated.`,
							status: "success"
						});
					} else {
						notificationsStore.ADD_TOAST({
							qaId: "toast-git-cred-gitlab-added-successfully",
							title: "New Git credential added",
							text: `New Git credential ${this.gitlabCredFormValues.name} is successfully added.`,
							status: "success"
						});
					}
					this.closeModal();
				}

				this.$emit("onCredCreate", cred);
			} catch (err) {
				this.submitError = getErrorMessage(err);
				captureError(err);
			}
			this.isSubmitting = false;
			return null;
		},

		saveFormValues(event: CustomEvent<LocalGitLabCredFormValues>) {
			this.gitlabCredFormValues = event.detail;
		},

		closeModal() {
			this.$emit("closeModal");
		},

		validateAndCloseModal(eventType: "back" | "closeModal" = "closeModal") {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				if (eventType === "back") {
					return this.$emit("back");
				}
				return this.closeModal();
			}

			return;
		}
	}
});

type LocalGitLabCredFormValues = {
	name?: string;
	username?: string;
	password?: string;
	classification?: ClassificationType;
};
</script>
