<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		width="432px"
		data-qa-int-pipeline-confirm-save-and-run-popover
	>
		<Header>
			<Typography type="h4" color="dark" data-qa-confirm-pipeline-title> Confirm </Typography>
			<Icon
				size="x-small"
				type="filled"
				name="i-close"
				data-qa-confirm-pipeline-close-btn
				@click="$emit('close')"
			/>
		</Header>

		<Container
			padding="0 16px 16px 16px"
			align="left top"
			direction="column"
			:gap="20"
			overflow="visible"
		>
			<Container align="left top" :padding="0" direction="column" :gap="12">
				<Typography type="p1-para" color="dark" data-qa-confirm-pipeline-desc>
					You are about to run an application build pipeline. This action might take a while
					depending on your configuration.
				</Typography>

				<Divider />

				<f-form-builder
					data-qa-git-revision-form
					:field.prop="formFields"
					:values.prop="formValues"
					@state-change="formState = $event.detail"
				/>

				<f-tooltip id="revisionIdentifier_info_tootip">
					<Container :padding="0" direction="column">
						<Typography type="p2" weight="medium" color="white"> Revision idenifier </Typography>
						<Container :padding="0" :gap="0" direction="column">
							<Typography type="p2-para" color="white">
								Name of the branch / tag / git commit hash
							</Typography>
						</Container>
					</Container>
				</f-tooltip>

				<f-tooltip id="revisionType_info_tootip">
					<Container :padding="0" direction="column">
						<Typography type="p2" weight="medium" color="white"> Revision type </Typography>
						<Container :padding="0" :gap="0" direction="column">
							<Typography type="p2-para" color="white">
								Revision of source control to checkout. (Branch / Tag / git commit hash)
							</Typography>
						</Container>
					</Container>
				</f-tooltip>
			</Container>
		</Container>

		<!-- Show error if any -->
		<Container
			v-if="errorMessage && errorMessage !== ''"
			direction="column"
			padding="15px 15px 15px 15px"
			:gap="0"
		>
			<Typography type="p2" color="error" data-qa-confirm-pipeline-error-msg>
				{{ errorMessage }}
			</Typography>
		</Container>

		<Footer>
			<Button
				state="full"
				full-width
				:loading="isSubmitting"
				:disabled="!formState?.isValid"
				type="success"
				data-qa-confirm-pipeline-save-and-run-btn
				@click="triggerEvent"
				>Confirm & run</Button
			>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import {
	Wrapper,
	Container,
	Divider,
	Typography,
	Footer,
	Button,
	Header,
	Icon
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { Artifact, ArtifactSelector, ArtifactType, GitRevisionType } from "@/protocol/common";
import {
	BRANCH_GIT_REVISION_OPTION,
	COMMIT_GIT_REVISION_OPTION,
	GIT_REVISION_OPTIONS,
	GitRevisionOption,
	TAG_GIT_REVISION_OPTION
} from "@/shared/constants";

export default defineComponent({
	name: "ConfirmSaveAndRun",

	components: {
		Wrapper,
		Container,
		Divider,
		Typography,
		Footer,
		Button,
		Header,
		Icon
	},

	props: {
		isSubmitting: {
			type: Boolean,
			required: true
		},

		errorMessage: {
			type: String
		},

		inputArtifact: {
			type: Object as PropType<Artifact>,
			required: true
		},

		outputArtifact: {
			type: Object as PropType<Artifact>
		}
	},

	emits: ["close", "confirmed"],

	data() {
		let revisionType;
		let revisionIdentifier = "";
		let outputArtifactIdentifier = "";

		if (this.inputArtifact.type === ArtifactType.GitCode) {
			if (this.inputArtifact.gitCode?.type === GitRevisionType.tag) {
				revisionType = TAG_GIT_REVISION_OPTION;
			} else if (this.inputArtifact.gitCode?.type === GitRevisionType.commit) {
				revisionType = COMMIT_GIT_REVISION_OPTION;
			} else {
				revisionType = BRANCH_GIT_REVISION_OPTION;
			}

			revisionIdentifier = this.inputArtifact.gitCode?.identifier ?? "main";
		} else if (this.inputArtifact.type === ArtifactType.ContainerImage) {
			revisionIdentifier = this.inputArtifact.containerImage?.reference ?? "latest";
		}

		if (this.outputArtifact?.type === ArtifactType.ContainerImage) {
			outputArtifactIdentifier = this.outputArtifact.containerImage?.reference ?? "latest";
		}

		return {
			formState: null as FormBuilderState | null,

			formValues: {
				revisionType,
				revisionIdentifier,
				outputArtifactIdentifier
			} as {
				revisionType?: GitRevisionOption;
				revisionIdentifier: string;
				outputArtifactIdentifier?: string;
			}
		};
	},

	computed: {
		formFields(): FormBuilderField {
			const revisionType = this.formValues.revisionType?.data.id;

			const revisionIdentifierText =
				revisionType === GitRevisionType.branch
					? {
							label: "Base branch",
							placeholder: "Enter base branch"
					  }
					: {
							label: "Revision identifier",
							placeholder: "Enter revision identifier"
					  };

			return {
				type: "object",
				direction: "vertical",
				fields: {
					revisionType: {
						type: "select",
						qaId: "revisionType",
						label: { title: "Revision type", iconTooltip: "#revisionType_info_tootip" },
						placeholder: "Select revision type",
						options: GIT_REVISION_OPTIONS,
						validationRules: [{ name: "required" }],
						showWhen: () => {
							return this.inputArtifact.type === ArtifactType.GitCode;
						}
					},

					revisionIdentifier: {
						type: "text",
						qaId: "revisionIdentifier",
						placeholder: revisionIdentifierText.placeholder,
						label: {
							title: revisionIdentifierText.label,
							iconTooltip: "#revisionIdentifier_info_tootip"
						},

						validationRules: [{ name: "required" }]
					},

					__separator__: {
						type: "separator",
						showWhen: () => {
							return this.outputArtifact?.type === ArtifactType.ContainerImage;
						}
					},

					outputArtifactIdentifier: {
						type: "text",
						qaId: "outputArtifactIdentifier",
						placeholder: "Enter container image tag",
						label: {
							title: "Container image tag"
						},

						validationRules: [{ name: "required" }],

						// We only support container artifact for now
						showWhen: () => {
							return this.outputArtifact?.type === ArtifactType.ContainerImage;
						}
					}
				}
			};
		}
	},

	methods: {
		triggerEvent() {
			const inputArtifact: ArtifactSelector = {
				id: this.inputArtifact.id
			};

			let outputArtifact: ArtifactSelector | null = null;

			if (this.inputArtifact.type === ArtifactType.GitCode) {
				inputArtifact.gitCode = {
					type: this.formValues.revisionType?.data.id as GitRevisionType,
					identifier: this.formValues.revisionIdentifier
				};
			} else if (this.inputArtifact.type === ArtifactType.ContainerImage) {
				inputArtifact.containerImage = {
					reference: this.formValues.revisionIdentifier
				};
			}

			if (this.outputArtifact?.type === ArtifactType.ContainerImage) {
				outputArtifact = {
					id: this.outputArtifact.id,
					containerImage: {
						reference: this.formValues.outputArtifactIdentifier
					}
				};
			}

			this.$emit("confirmed", {
				inputArtifact,
				outputArtifact
			} satisfies IntegrationPipelineConfirmConfig);
		}
	}
});

export type IntegrationPipelineConfirmConfig = {
	inputArtifact: ArtifactSelector;
	outputArtifact: ArtifactSelector | null;
};
</script>
