<script setup lang="ts">
import { FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { PropType, computed, ref, toRefs, watch } from "vue";

import { appIntegration } from "@/protocol/app";
import { Provisioner } from "@/protocol/common";
import { buildForm } from "@/shared/components/JSONSchemaFormBuilder2.vue";
import { JSONSchemaToObject, captureError, getErrorMessage, removeEmptyValues } from "@/utils";

import { applicationIntegrationStore } from "../application-integration-store";

const props = defineProps({
	pipelineConfig: {
		type: Object as PropType<Record<string, unknown>>,
		required: true
	},

	provisioner: {
		type: String as PropType<Provisioner>,
		required: true
	},

	integration: {
		type: Object as PropType<appIntegration>
	}
});

const { integration, provisioner, pipelineConfig } = toRefs(props);

const emit = defineEmits(["close", "update:pipelineConfig"]);

const formState = ref<FormBuilderState | null>(null);

const configModule = computed(() =>
	Object.values(applicationIntegrationStore.pipelineModules).find(pipelineModule => {
		return (
			// This value is hardcoded from server
			pipelineModule.name === "pipeline-config" && pipelineModule.provisioner === provisioner.value
		);
	})
);

const artifactFields = computed<FormBuilderField | null>(() => {
	if (configModule.value?.inputs) {
		const form = buildForm({
			schema: configModule.value.inputs,
			name: "",
			parentName: "",
			isNested: false,
			isRequired: true
		});

		// We only support object types pipeline configuration
		if (form.type === "object") {
			return {
				type: "object",
				direction: "vertical",
				fields: form.fields
			};
		}
	}

	return null;
});

const artifactFieldValues = ref({
	...pipelineConfig.value
});

// Watch for changes in the pipeline config and update the form values based on it's defaults
watch(
	[configModule],
	() => {
		if (!configModule.value) {
			return;
		}

		artifactFieldValues.value = JSONSchemaToObject(
			configModule.value.inputs,
			pipelineConfig.value
		) as Record<string, unknown>;
	},
	{ immediate: true }
);

const isSavingIntegration = ref(false);
const errorMessage = ref("");

async function saveConfiguration() {
	const integrationValue = integration?.value;

	// If we are not editing an integration we just need to emit the values and exit
	if (!integrationValue) {
		emit("close");
		return;
	}

	// Save updated integration
	try {
		isSavingIntegration.value = true;

		await applicationIntegrationStore.UPDATE_APP_INTEGRATION({
			id: integrationValue.id,
			appId: integrationValue.appId,
			projId: integrationValue.projId,
			orgId: integrationValue.orgId,
			name: integrationValue.name,
			description: integrationValue.description,
			inputAppArtifact: integrationValue.inputAppArtifact,
			outputAppArtifact: integrationValue.outputAppArtifact,
			pipeline: integrationValue.pipeline,
			config: {
				...integrationValue.config,
				pipelineConfig: removeEmptyValues(artifactFieldValues.value, {
					removeEmptyStrings: true
				})
			}
		});

		isSavingIntegration.value = false;
		emit("close");
	} catch (err) {
		captureError(err);
		errorMessage.value = getErrorMessage(err, true);
	} finally {
		isSavingIntegration.value = false;
	}
}
</script>

<template>
	<f-div
		width="432px"
		state="secondary"
		direction="column"
		variant="curved"
		padding="none"
		gap="none"
	>
		<f-div align="middle-center" padding="medium">
			<f-div height="hug-content">
				<f-text variant="para" size="small" weight="bold">Configure pipeline</f-text>
			</f-div>
			<f-div width="hug-content" height="hug-content">
				<f-icon-button
					icon="i-close"
					variant="block"
					category="packed"
					size="small"
					state="inherit"
					data-qa-pipeline-configurator-close
					@click="$emit('close')"
				>
				</f-icon-button>
			</f-div>
		</f-div>

		<f-div
			class="flow-add-scrollbar"
			padding="large"
			gap="large"
			max-height="400px"
			overflow="scroll"
		>
			<f-form-builder
				v-if="artifactFields"
				data-qa-pipeline-configuration-form
				variant="block"
				:field.prop="artifactFields"
				:values.prop="artifactFieldValues"
				@input="emit('update:pipelineConfig', $event.detail)"
				@state-change="formState = $event.detail"
			/>

			<f-text v-else-if="errorMessage" size="small" state="danger">{{ errorMessage }}</f-text>
			<f-text v-else size="small" state="danger">No pipeline configuration found.</f-text>
		</f-div>

		<f-button
			data-qa-pipeline-configurator-done
			:loading="isSavingIntegration"
			:label="integration ? 'Update' : 'Done'"
			:state="integration ? 'success' : 'primary'"
			variant="block"
			:disabled="!formState?.isValid"
			@click="saveConfiguration"
		></f-button>
	</f-div>
</template>
