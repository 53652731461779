<template>
	<AuthWrapper header="Success!" :show-back-icon="false">
		<Section padding="horizontal">
			<p class="fc-normal paragraph-1">We have successfully reset your account password</p>
			<p class="fc-normal paragraph-1 margin-tp-12">You can now login using your new password</p>
		</Section>
		<template #alt-auth>
			<Section padding="vertical" class="center-of-container">
				<div class="center-of-container margin-tp-12 flex-gap-12 width-80-per">
					<Button
						state="filled"
						type="primary"
						:full-width="true"
						@click="redirectMixin({ routeName: 'login' })"
					>
						LOG IN
					</Button>
				</div>
			</Section>
		</template>
	</AuthWrapper>
</template>

<script>
import { Section, Button } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import AuthWrapper from "@/modules/auth/views/AuthWrapper.vue";
import routerMixin from "@/shared/mixins/routerMixin";

export default defineComponent({
	name: "ResetPasswordSuccess",
	components: { Section, AuthWrapper, Button },
	mixins: [routerMixin]
});
</script>
