<template>
	<Wrapper
		border-radius="4px"
		background="element-light"
		:border="true"
		max-height="90vh"
		width="950px"
		data-qa-add-variable-model
	>
		<Container :padding="0" :gap="0" direction="column" :grow="1" align="start top">
			<Header>
				<Container align="space-between" :padding="0" :grow="1">
					<Typography type="h4" color="dark" data-qa-add-variable-popup-heading>
						Add new variables
					</Typography>
					<Icon
						v-tooltip="{
							content: 'Close',
							placement: 'bottom'
						}"
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-env-wid-add-variable-close
						@click.stop="closeDialog()"
					/>
				</Container>
			</Header>
			<Slab
				v-if="showClosingWarning"
				size="medium"
				state="warning"
				state-type="solid"
				align-items="center"
				:disabled="false"
				:selected="false"
			>
				<template #default>
					<Typography weight="regular" type="p1">
						<strong>The changes will be lost.</strong> Are you sure to close this?
					</Typography>
				</template>

				<template #secondary-actions>
					<Button
						size="small"
						type="warning"
						state="outlined"
						:disabled="false"
						:full-width="false"
						:loading="false"
						@click.stop="closeDialogWarning"
					>
						Yes, Close
					</Button>
				</template>
			</Slab>

			<Container align="left stretch" :separator="true" :gap="0" padding="0px">
				<Container
					class="popover-input"
					padding="0 16px"
					direction="column"
					overflow="auto"
					align="left top"
					:grow="1"
					:shrink="0"
					basis="45%"
				>
					<Wrapper height="628px">
						<f-div padding="none" direction="column" width="fill-container" gap="large">
							<AddVariableKeyValueForm
								v-model:variable="currentVariable"
								:added-variables="[scope]"
								:selected-variable-scopes="[scope]"
								:store-variable-scopes="[]"
								:hide-variable-type="true"
								@validity-change="isCurrentVariableFormValid = $event"
							/>

							<f-button
								:style="{ width: '100%' }"
								label="Add to list"
								data-qa-id="add-to-list"
								size="small"
								:disabled="disableAddVariableButton"
								@click="onAddVariable"
							></f-button>
						</f-div>
					</Wrapper>
				</Container>
				<Container
					class="popover-input"
					direction="column"
					overflow="auto"
					align="left top"
					:grow="1"
					:shrink="0"
					basis="55%"
				>
					<ScopeVariablesAccordion
						:key="scope.id"
						v-model:scope="_internalScope"
						:added-variables="[scope]"
						:hide-variable-type="true"
					/>
				</Container>
			</Container>
			<Divider />
			<Footer>
				<Container v-if="submitError" direction="row" :grow="1">
					<Typography color="danger-300">{{ submitError }}</Typography>
				</Container>
				<Container align="right" :grow="1" position="fixed" overflow="visible">
					<Button
						:type="isSubmitting ? 'default' : 'success'"
						:disabled="isSubmitting"
						:loading="isSubmitting"
						data-qa-env-wid-add-variable-btn
						@click="saveVariables"
						>{{ submitButtonText }}</Button
					>
				</Container>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	Slab,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { isEqual } from "lodash-es";
import { PropType, defineComponent } from "vue";

import AddVariableKeyValueForm from "@/modules/variables-list/AddVariableKeyValueForm.vue";
import ScopeVariablesAccordion from "@/modules/variables-list/ScopeVariablesAccordion.vue";
import { Variable, AddVariableScope } from "@/modules/variables-list/variable-list-types";
import { updateVariablesFromForms } from "@/modules/variables-list/variables-list-utils";
import { appIntegration } from "@/protocol/app";
import { variableType } from "@/protocol/common";
import { getErrorMessage } from "@/utils";

import { deleteAppIntegrationVars } from "../application-integration-service";

export default defineComponent({
	name: "IntegrationVariableContent",

	components: {
		AddVariableKeyValueForm,
		Typography,
		Container,
		Divider,
		Wrapper,
		Button,
		Header,
		Footer,
		Icon,
		Slab,
		ScopeVariablesAccordion
	},

	props: {
		scope: {
			type: Object as PropType<AddVariableScope>,
			required: true
		},

		integration: {
			type: Object as PropType<appIntegration>
		}
	},

	emits: ["vars-saved", "close-dialog", "update:scope"],

	data() {
		return {
			initialScope: { ...this.scope },
			currentVariable: getInitialFormValue(),
			showClosingWarning: false,
			isCurrentVariableFormValid: false,
			submitError: "",
			isSubmitting: false
		};
	},

	computed: {
		disableAddVariableButton() {
			return !this.isCurrentVariableFormValid;
		},

		submitButtonText() {
			return this.integration ? "Update Variables" : "Add Variables";
		},

		_internalScope: {
			get() {
				return this.scope;
			},

			set(newScope: AddVariableScope) {
				this.$emit("update:scope", newScope);
			}
		}
	},

	methods: {
		onAddVariable() {
			this.$emit("update:scope", {
				...this.scope,
				variables: [...this.scope.variables, this.currentVariable]
			});

			this.currentVariable = getInitialFormValue();
		},

		async saveVariables() {
			// We are not editing variables just emit them and be done with it
			if (!this.integration) {
				this.$emit("vars-saved");
				this.closeDialogWarning();
				return;
			}

			if (this.isSubmitting) {
				return;
			}

			this.isSubmitting = true;

			const { integration } = this;

			const scopeVars = this.scope.variables.map(var_ => var_.key);
			const deletedEnvVars: string[] = [];
			const deletedSensitiveVars: string[] = [];

			// Detect deleted variables
			this.initialScope.variables.forEach(var_ => {
				if (!scopeVars.includes(var_.key)) {
					if (var_.isMasked) {
						deletedSensitiveVars.push(var_.key);
					} else {
						deletedEnvVars.push(var_.key);
					}
				}
			});

			if (deletedEnvVars.length > 0 || deletedSensitiveVars.length > 0) {
				await deleteAppIntegrationVars({
					id: integration.id,
					orgId: integration.orgId,
					projId: integration.projId,
					appId: integration.appId,
					env: deletedEnvVars,
					sensitive: deletedSensitiveVars
				});
			}

			try {
				await Promise.all(updateVariablesFromForms([this.scope]));
			} catch (error) {
				this.isSubmitting = false;
				this.submitError = getErrorMessage(error);
				return;
			}

			this.$emit("vars-saved");
			this.closeDialogWarning();
			this.isSubmitting = false;
		},

		closeDialog() {
			if (isEqual(this.scope, this.initialScope)) {
				this.$emit("close-dialog", true);
			} else {
				this.showClosingWarning = true;
			}
		},

		closeDialogWarning() {
			this.$emit("close-dialog", true);
		}
	}
});

export function getInitialFormValue(): Variable {
	return {
		key: "",
		value: "",
		isMasked: false,
		variableType: variableType.env_var
	};
}
</script>
