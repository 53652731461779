import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ProjectId } from "@/protocol/common";
import { orgId as orgIdProto, success } from "@/protocol/identity";
import {
	InvitationRequest,
	InvitedUser,
	invite as inviteProto,
	ListInvitedUsersRequest,
	UserRole
} from "@/protocol/user";

import { orgStore } from "../org/org-store";
import { getStore } from "../store";

import {
	deleteUserInvite,
	grantOrgAccess,
	grantProjAccess,
	listInvitedUsers,
	listOrgAccess,
	listProjAccess,
	revokeOrgAccess,
	revokeProjAccess,
	updateUserInvite,
	userInvite
} from "./members-service";

export const DEFAULT_ORG_IDENTIFIER = "default";

@Module({
	namespaced: true,
	dynamic: true,
	name: "members",
	store: getStore()
})
class MembersStore extends VuexModule {
	members: Record<string, UserRole[]> = {};
	lastInvitedBatch: string[] | null = null;

	invitedUser: Record<string, InvitedUser[]> = {};

	@Mutation
	RESET_MEMBER() {
		this.members = {};
		// this.projects = {};
		this.lastInvitedBatch = null;
		this.invitedUser = {};
	}

	@Mutation
	SET_MEMBERS_LIST({ id, members }: { id: string; members: UserRole[] }) {
		this.members[id] = members;
	}

	@Mutation
	SET_LAST_INVITED_BATCH({ users }: { users: string[] | null }) {
		this.lastInvitedBatch = users;
	}

	@Mutation
	SET_INVITED_USERS({ id, users }: { id: string; users: InvitedUser[] }) {
		this.invitedUser[id] = users;
	}

	@Action
	async FETCH_PROJECT_ACCESS_LIST(request: ProjectId) {
		const { users } = await listProjAccess(request);

		if (users) {
			this.SET_MEMBERS_LIST({ id: request.id, members: users });
		}
	}

	@Action
	async FETCH_ORG_ACCESS_LIST(request: orgIdProto) {
		const { users } = await listOrgAccess(request);

		if (users) {
			this.SET_MEMBERS_LIST({ id: request.id, members: users });
		}
	}

	@Action
	async USER_INVITATION_ACTION({
		orgId,
		requests,
		actionType
	}: {
		orgId: string;
		requests: Pick<InvitationRequest, "invite" | "email">[];
		actionType: "invite" | "update" | "remove";
	}) {
		const invitePromises: Promise<success>[] = [];

		let actionFn = userInvite;
		if (actionType === "update") {
			actionFn = updateUserInvite;
		} else if (actionType === "remove") {
			actionFn = deleteUserInvite;
		}

		requests.forEach(request => {
			invitePromises.push(
				actionFn({
					orgId,
					email: request.email,
					invite: request.invite,
					skipInviteNotification: false
				})
			);
		});

		const responses = await Promise.allSettled(invitePromises);

		const errors: Error[] = [];

		responses.forEach(response => {
			if (response.status === "rejected") {
				errors.push(response.reason);
			}
		});

		if (errors.length > 0) {
			throw {
				inviteErrors: errors
			} as InviteError;
		}

		if (actionType === "invite") {
			this.SET_LAST_INVITED_BATCH({ users: requests.map(request => request.email) });
		}
	}

	@Action
	async FETCH_USERS({ orgId, invite }: { orgId: string; invite: Omit<inviteProto, "role"> }) {
		const promises: Promise<unknown>[] = [];

		if (orgStore.isUserOrgAdmin) {
			promises.push(
				this.FETCH_INVITED_USERS({
					orgId,
					org: invite.org,
					project: invite.project,
					app: invite.app,
					env: invite.env
				})
			);
		}

		if (invite.org) {
			promises.push(this.FETCH_ORG_ACCESS_LIST(invite.org));
		}

		if (invite.project) {
			promises.push(this.FETCH_PROJECT_ACCESS_LIST(invite.project));
		}

		await Promise.all(promises);
	}

	@Action
	async GRANT_PROJECT_ACCESS(request: {
		orgId: string;
		projId: string;
		userIds: string[];
		role: string;
	}) {
		const promises = request.userIds.map(userId =>
			grantProjAccess({
				orgId: request.orgId,
				projId: request.projId,
				userId,
				role: request.role
			})
		);

		await Promise.all(promises);

		await this.FETCH_PROJECT_ACCESS_LIST({
			id: request.projId,
			orgId: request.orgId
		});
	}

	@Action
	async REVOKE_PROJECT_ACCESS(request: { orgId: string; projId: string; userIds: string[] }) {
		const promises = request.userIds.map(userId =>
			revokeProjAccess({
				orgId: request.orgId,
				projId: request.projId,
				userId
			})
		);

		await Promise.all(promises);

		await this.FETCH_PROJECT_ACCESS_LIST({
			id: request.projId,
			orgId: request.orgId
		});
	}

	@Action
	async GRANT_ORG_ACCESS(request: { orgId: string; userIds: string[]; role: string }) {
		const promises = request.userIds.map(userId =>
			grantOrgAccess({
				orgId: request.orgId,
				userId,
				role: request.role
			})
		);

		await Promise.all(promises);

		await this.FETCH_ORG_ACCESS_LIST({
			id: request.orgId
		});
	}

	@Action
	async REVOKE_ORG_ACCESS(request: { orgId: string; userIds: string[] }) {
		const promises = request.userIds.map(userId =>
			revokeOrgAccess({
				orgId: request.orgId,
				userId
			})
		);

		await Promise.all(promises);

		await this.FETCH_ORG_ACCESS_LIST({
			id: request.orgId
		});
	}

	@Action
	async FETCH_INVITED_USERS(request: ListInvitedUsersRequest) {
		const { invitedUsers } = await listInvitedUsers(request);

		const requestId = request.project?.id ?? request.org?.id ?? request.app?.id ?? request.env?.id;

		if (requestId && invitedUsers) {
			this.SET_INVITED_USERS({
				id: requestId,
				users: invitedUsers
			});
		}
	}
}

export type InviteError = {
	inviteErrors: Error[];
};

const membersStore = getModule(MembersStore);

export { membersStore };
