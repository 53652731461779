<template>
	<PopOver :target="target" data-qa-create-deploy-app :open="open">
		<slot />
		<template #content>
			<KeepAlive>
				<AppListPopover
					v-if="currentStep === 'list-app'"
					:show-add-button="true"
					:project-id="project.id"
					@back="goBack"
					@close="closeDialog"
					@deploy-application="handleNewApp"
					@add-application="currentStep = 'create-app'"
				/>

				<template v-else-if="currentStep === 'create-app'">
					<CreateApplicationWizard
						:open="open"
						:project="project"
						:target="target"
						@close="closeDialog"
						@app-created="handleNewApp"
					>
						<div></div>
					</CreateApplicationWizard>
				</template>

				<ApplicationDeploymentModal
					v-else-if="currentStep === 'deploy-app' && app"
					:app="app"
					:environment-id="environment?.id"
					:project-id="project.id"
					@close="closeDialog"
					@back="goBack"
				/>
			</KeepAlive>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { PopOver } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import ApplicationDeploymentModal from "@/modules/application/components/ApplicationDeploymentModal.vue";
import CreateApplicationWizard from "@/modules/application/components/CreateApplicationWizard.vue";
import AppListPopover from "@/modules/env-list/components/AppListPopover.vue";
import { app as appProto } from "@/protocol/app";
import { project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";

export default defineComponent({
	name: "CreateAndDeployApp",

	components: {
		PopOver,
		AppListPopover,
		ApplicationDeploymentModal,
		CreateApplicationWizard
	},

	props: {
		project: {
			type: Object as PropType<project>,
			required: true
		},

		environment: {
			type: Object as PropType<environment>
		},

		open: {
			type: Boolean,
			required: true
		},

		target: {
			type: String
		}
	},

	emits: ["close", "back"],

	data: () => ({
		currentStep: "create-app" as "list-app" | "create-app" | "deploy-app",
		app: null as appProto | null
	}),

	computed: {
		projectApps() {
			return applicationStore.projectApps[this.project.id] ?? [];
		}
	},

	mounted() {
		this.currentStep = this.projectApps.length > 0 ? "list-app" : "create-app";
	},

	methods: {
		handleNewApp(app: appProto) {
			this.app = app;
			this.currentStep = "deploy-app";
		},

		goBack() {
			const hasApps = this.projectApps.length > 0;

			if (this.currentStep === "deploy-app") {
				this.currentStep = hasApps ? "list-app" : "create-app";
				this.app = null;
			} else if (this.currentStep === "create-app") {
				if (hasApps) {
					this.currentStep = "list-app";
				} else {
					this.$emit("close");
				}
			} else {
				this.$emit("back");
			}
		},

		closeDialog() {
			this.currentStep = this.projectApps.length > 0 ? "list-app" : "create-app";
			this.$emit("close");
		}
	}
});
</script>

<style lang="scss">
div.flow-popover[data-qa-create-deploy-app] {
	height: auto;
}
</style>
