import { MAX_ENTITY_NAME_LENGTH } from "@/shared/custom-validation-rules/entityNameRules";

import { getUniqueId } from "./get-unique-id";

// Ensures that a string matches our entity naming convention
export function safeEntityString(str: string, existingEntities?: string[]) {
	let finalStr = str;

	if (finalStr.length === 0) {
		finalStr = `codepipes-${getUniqueId()}`;
	}

	if (finalStr.length > MAX_ENTITY_NAME_LENGTH) {
		finalStr = finalStr.substring(0, MAX_ENTITY_NAME_LENGTH);
	}

	let finalStrWhichMightExist = finalStr;
	if (existingEntities) {
		while (existingEntities.includes(finalStrWhichMightExist)) {
			const uniqueId = `-${getUniqueId()}`;

			// Add unique id to the end of the string but keeping in mind to reduce the total
			// length by the length of the unique id string if it exeeceds the max allowed length
			const charsToRemove = Math.max(0, finalStr.length + uniqueId.length - MAX_ENTITY_NAME_LENGTH);
			const reducedLength = finalStr.length - charsToRemove;

			finalStrWhichMightExist = `${finalStr.substring(0, reducedLength)}${uniqueId}`;
		}
	}

	return finalStrWhichMightExist;
}
