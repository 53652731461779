import axios, { AxiosResponse } from "axios";

import {
	organizations,
	identityService,
	orgId,
	signingResponse,
	success,
	organization
} from "@/protocol/identity";
import {
	Dependency,
	DependencyCreateRequest,
	DependencyId,
	DependencyListRequest,
	DependencyListResponse,
	DependencyUpdateRequest,
	infraService,
	DependencyResolverList,
	infrastructureComponent,
	componentsListResponse
} from "@/protocol/infra";
import { UserRoleList } from "@/protocol/user";
import ApiService, { INFRA_API } from "@/services/api-service";
import { IDENTITY_API } from "@/shared/constants";

export const listOrg = async function () {
	const response = await ApiService.get<organizations>(`${IDENTITY_API}/organizations`);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getOrg: identityService["getOrg"] = async function (request: orgId) {
	const response: AxiosResponse<organization> = await ApiService.get(
		`${IDENTITY_API}/organizations/${request.id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listOrgAccess: identityService["listOrgAccess"] = async function (request: orgId) {
	const response: AxiosResponse<UserRoleList> = await ApiService.get(
		`${IDENTITY_API}/organizations/${request.id}/users`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const createOrg: identityService["createOrg"] = async function ({
	name,
	description,
	metadata
}: {
	name: string;
	description: string;
	metadata?: { [key: string]: unknown };
}) {
	const response: AxiosResponse<orgId> = await ApiService.post(
		`${IDENTITY_API}/organizations`,
		{
			name,
			description,
			metadata
		},
		{}
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const updateOrg: identityService["updateOrg"] = async function ({
	id,
	name,
	description,
	metadata
}: {
	id: string;
	name: string;
	description: string;
	metadata?: { [key: string]: unknown };
}) {
	const response: AxiosResponse<Record<string, never>> = await ApiService.put(
		`${IDENTITY_API}/organizations/${id}`,
		{ id, name, description, metadata }
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const generateSignedURL = async function ({
	path,
	action,
	type,
	size
}: {
	path: string;
	action: string;
	type: string;
	size: number;
}) {
	const response = await ApiService.get<signingResponse>(
		`${IDENTITY_API}/getSignedURL?path=${path}&action=${action}&type=${type}&size=${size}`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const uploadFile = async function ({
	contentType,
	signedURL,
	file
}: {
	contentType: string;
	signedURL: string;
	file: File;
}) {
	return await axios.put(signedURL, file, {
		headers: {
			"Content-Type": contentType
		}
	});
};

// This API can be used to create a new dependency interface
export const dependencyCreate: infraService["dependencyCreate"] = async function (
	request: DependencyCreateRequest
) {
	const response: AxiosResponse<Dependency> = await ApiService.post(
		`${INFRA_API}/organizations/${request.orgId}`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// This API can be used to update an existing dependency interface
export const dependencyUpdate: infraService["dependencyUpdate"] = async function (
	request: DependencyUpdateRequest
) {
	const response: AxiosResponse<Dependency> = await ApiService.put(
		`${INFRA_API}/organizations/${request.orgId}/dependencies/${request.id}`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// This API can be used to list all the dependencies available to the organization
// It also includes public dependencies that are not associated with the given organization ID
export const dependencyList: infraService["dependencyList"] = async function (
	request: DependencyListRequest
) {
	const response: AxiosResponse<DependencyListResponse> = await ApiService.get(
		`${INFRA_API}/organizations/${request.orgId}/dependencies`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// This API can be used to get a dependency interface by ID
export const dependencyGet: infraService["dependencyGet"] = async function (request: DependencyId) {
	const response: AxiosResponse<Dependency> = await ApiService.get(
		`${INFRA_API}/organizations/${request.orgId}/dependencies/${request.id}`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

// This API can be used to delete a dependency interface by ID
export const dependencyDelete: infraService["dependencyDelete"] = async function (
	request: DependencyId
) {
	const response: AxiosResponse<Record<string, never>> = await ApiService.delete(
		`${INFRA_API}/organizations/${request.orgId}/dependencies/${request.id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const deleteOrg: identityService["deleteOrg"] = async function ({ id }: orgId) {
	const response: AxiosResponse<success> = await ApiService.delete(
		`${IDENTITY_API}/organizations/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// This API can be used to list all the resolvers available to the dependency registry
// It also includes public dependencies that are not associated with the given organization ID
export const dependencyResolversList: infraService["listDependencyResolvers"] = async function ({
	orgId: organizationId,
	id
}: {
	orgId: string;
	id: string;
}) {
	const response: AxiosResponse<DependencyResolverList> = await ApiService.get(
		`${INFRA_API}/organizations/${organizationId}/dependencies/${id}/resolvers`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getComponent: infraService["getComponent"] = async function ({
	orgId: organizationId,
	id
}: {
	orgId: string;
	id: string;
}) {
	const response: AxiosResponse<infrastructureComponent> = await ApiService.get(
		`${INFRA_API}/organizations/${organizationId}/components/${id}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listComponent: infraService["listComponents"] = async function ({
	orgId: organizationId
}: {
	orgId: string;
}) {
	const response: AxiosResponse<componentsListResponse> = await ApiService.get(
		`${INFRA_API}/organizations/${organizationId}/components`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const importInfrastructureLibrary: infraService["importInfrastructureLibrary"] =
	async function (payload) {
		const response: AxiosResponse<Record<string, any>> = await ApiService.put(
			`${INFRA_API}/organizations/${payload.orgId}/infrastructure-library`,
			payload
		);

		if (response.status === 200) {
			return response.data;
		}

		throw response;
	};
