<template>
	<f-div
		id="selectBuildStep"
		padding="large"
		height="52px"
		gap="large"
		state="secondary"
		width="100%"
	>
		<f-icon
			source="i-arrow-left"
			class="cursor-pointer"
			data-qa-app-connect-form-back-btn
			state="default"
			@click="goBack"
		>
		</f-icon>
		<f-text variant="heading" size="small" weight="bold">
			Setup integration pipeline and webhooks
		</f-text>
		<f-div align="middle-right" gap="small" width="10%">
			<f-icon
				source="i-close"
				size="small"
				class="cursor-pointer"
				data-qa-app-connect-close-btn
				@click="$emit('close')"
			>
			</f-icon>
		</f-div>
	</f-div>

	<!-- Integration pipeline UI component -->
	<f-div direction="column" state="secondary" padding="large">
		<template v-for="(pipeline, index) in integrationPipelines" :key="index">
			<f-div
				v-if="pipeline.show"
				state="secondary"
				gap="medium"
				direction="column"
				align="middle-center"
			>
				<f-div
					height="hug-content"
					gap="large"
					padding="small none none none"
					state="secondary"
					width="100%"
					direction="row"
				>
					<f-div gap="small" align="middle-left" height="hug-content">
						<f-text
							v-if="!pipeline.edit"
							size="medium"
							variant="para"
							weight="bold"
							:data-qa-pipeline-name="pipeline.name"
							inline
							>{{ pipeline.name }}
						</f-text>
						<f-input
							v-if="pipeline.edit"
							class="width-50-per"
							:value="pipeline.name"
							placeholder="Enter"
							icon-right=""
							:data-qa-edit-field="index"
							:state="pipeline.name !== '' ? 'default' : 'danger'"
							@input="e => (pipeline.name = e.detail.value)"
							@blur="e => onBlur(e, pipeline)"
						></f-input>

						<f-icon
							v-if="!pipeline.edit"
							source="i-edit"
							size="x-small"
							class="cursor-pointer"
							state="primary"
							:data-qa-edit-icon="index"
							@click.stop="() => (pipeline.edit = true)"
						></f-icon>
					</f-div>
					<f-icon
						source="i-close"
						size="x-small"
						class="cursor-pointer"
						:data-qa-remove-pipeline-btn="pipeline.name"
						@click="pipeline.show = false"
					></f-icon>
				</f-div>
				<f-div padding="large none" direction="column" gap="x-large">
					<f-div
						height="hug-content"
						gap="large"
						state="secondary"
						width="100%"
						direction="row"
						align="middle-center"
					>
						<f-div align="middle-left" direction="row" gap="medium">
							<f-text inline>Run on</f-text>
							<f-select
								v-model="pipeline.triggerEvent"
								placeholder="select"
								:options="options"
							></f-select>
							<f-text inline>to</f-text>
						</f-div>
						<f-div align="middle-right" direction="row" gap="medium">
							<f-div width="75%">
								<f-input v-model="pipeline.identifier" placeholder="Enter identifier"></f-input>
							</f-div>
							<f-text align="right">branch.</f-text>
						</f-div>
					</f-div>
					<f-div height="hug-content" gap="large" align="middle-left">
						Included modules are
						<f-div
							v-for="(module, i) in pipeline.modules"
							:key="i"
							align="middle-left"
							border="medium solid default around"
							variant="curved"
							padding="x-small"
							:data-qa-module-icon="module.name"
							width="hug-content"
						>
							<f-icon :source="module.icon" :tooltip="module.name"> </f-icon>
						</f-div>

						<f-text
							:id="pipeline.name"
							state="primary"
							weight="bold"
							align="right"
							size="small"
							variant="para"
							class="cursor-pointer"
							:data-qa-advance-config="pipeline.name"
							@click="showAdvanceConfigFor = pipeline.name"
							>ADVANCE CONFIG</f-text
						>
						<PipelineModuleAdvanceConfig
							v-if="showAdvanceConfigFor === pipeline.name"
							:pipeline="pipeline"
							target="selectBuildStep"
							:is-edit-mode="true"
							:app-id="appId"
							:modules="pipeline.modules"
							@save-module="saveModules"
							@add-module="addNewModule"
							@close="showAdvanceConfigFor = ''"
							@variable-change="updateVariables"
							@update-triggers="updateGithubTriggers"
							@set-modules="setModules"
						/>
					</f-div>
				</f-div>
				<f-divider
					v-if="index < integrationPipelines.length - 1"
					direction="horizontal"
					height="fill-container"
					size="large"
				/>
			</f-div>
		</template>

		<!-- Add new pipeline popover -->
		<PipelineModuleAdvanceConfig
			v-if="isAddNewPipelineOpen"
			:is-edit-mode="false"
			:pipeline="newPipeline"
			target="selectBuildStep"
			:app-id="appId"
			:modules="newPipeline.modules"
			@save-module="saveModules"
			@add-module="addNewModuleToNewPipeline"
			@close="closeAndResetAddForm"
			@variable-change="updateVariables"
			@create-new-pipeline="createNewPipeline"
			@set-modules="setModules"
		/>
		<f-div
			id="add-new-pipeline"
			padding="x-large none"
			height="hug-content"
			gap="small"
			state="secondary"
			direction="row"
			width="100%"
		>
			<f-button
				state="primary"
				variant="round"
				category="outline"
				label="+ Add new pipeline"
				@click="isAddNewPipelineOpen = true"
			></f-button>
		</f-div>
	</f-div>

	<!-- Error message -->
	<f-div
		v-if="submitError"
		padding="medium"
		height="hug-content"
		gap="medium"
		direction="row"
		width="100%"
		state="danger"
	>
		<f-text size="small" weight="regular" variant="para" color="danger" data-qa-submit-err-text>
			{{ submitError }}</f-text
		>
		<f-div align="top-right" width="hug-content">
			<f-icon
				class="cursor-pointer"
				source="i-close"
				size="x-small"
				data-qa-error-close-icon
				@click="submitError = ''"
			>
			</f-icon>
		</f-div>
	</f-div>

	<f-div
		padding="none"
		gap="none"
		state="secondary"
		align="middle-center"
		direction="row"
		width="100%"
	>
		<f-button
			label="Add App"
			class="add-button"
			state="neutral"
			variant="block"
			:loading="isAdding"
			@click.stop="add"
		></f-button>
		<!-- We are disabling this button till we have a new designs & story created for env and app dep entity creation. -->
		<f-button
			label="Add & Deploy App"
			class="add-deploy-button"
			state="success"
			variant="block"
			:disabled="true"
			:loading="isDeploying"
			align="right"
			@click.stop="addAndDeploy"
		></f-button>
	</f-div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { applicationIntegrationStore } from "@/modules/application-integration/application-integration-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { orgStore } from "@/modules/org/org-store";
import { userStore } from "@/modules/user/user-store";
import { Variable } from "@/modules/variables-list/variable-list-types";
import { webHooksStore } from "@/modules/web-hooks/web-hooks-store";
import {
	app as AppProto,
	appIntegration,
	AppIntegrationCreate,
	ciInferResponse
} from "@/protocol/app";
import { triggerEvent, triggerType } from "@/protocol/externalEvents";
import { pipelineModule, pipelineModuleReferenceV2 } from "@/protocol/pipeline";
import { TRIGGER_EVENT_TO_STRING } from "@/shared/constants";
import { getErrorMessage, getProvisionerIcon, JSONSchemaToObject } from "@/utils";
import { artitactToArtifactSelector } from "@/utils/artifact-to-artifactselector";

import { applicationStore } from "../application-store";

import PipelineModuleAdvanceConfig from "./PipelineModuleAdvanceConfig.vue";

export type WizardFlowPipelineModules = pipelineModuleReferenceV2 & {
	icon: string;
	name: string;
	order: number;
	data: pipelineModule;
};
export type IntegrationObj = {
	show: boolean;
	name: string;
	identifier: string;
	triggerEvent: WebhookTriggerEvent;
	edit: boolean;
	modules: WizardFlowPipelineModules[];
	variables: Variable[];
};

export type WebhookTriggerEvent = {
	data: { id: triggerEvent };
	title: string;
};

export default defineComponent({
	name: "AddAppBuildSetupStep",
	components: { PipelineModuleAdvanceConfig },

	props: {
		appId: {
			type: String as PropType<string | undefined>,
			required: true
		},

		inferredPipeline: {
			type: Object as PropType<ciInferResponse | null>,
			reqquired: true
		}
	},

	emits: ["back", "close", "toggle-info"],

	data: () => ({
		showScanPipeline: true,
		showScanAndBuildPipeline: true,
		submitError: "",
		isAdding: false,
		isDeploying: false,
		app: null as AppProto | null,
		showAdvanceConfigFor: "",
		options: [
			{
				data: { id: triggerEvent.pullReq },
				title: TRIGGER_EVENT_TO_STRING[triggerEvent.pullReq]()
			},
			{
				data: { id: triggerEvent.pushToBranch },
				title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToBranch]()
			},
			{
				data: { id: triggerEvent.pushToTag },
				title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToTag]()
			}
		],

		isAddNewPipelineOpen: false,

		integrationPipelines: [] as IntegrationObj[],

		newPipeline: {
			show: true,
			name: "",
			identifier: "",
			triggerEvent: {
				data: { id: triggerEvent.pushToBranch },
				title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToBranch]()
			},

			edit: false,
			modules: [] as WizardFlowPipelineModules[],
			variables: [] as Variable[]
		} as IntegrationObj
	}),

	computed: {
		projectId() {
			return this.$route.params.projectId as string;
		},

		orgId() {
			return orgStore.activeOrgId;
		}
	},

	watch: {
		appId: {
			immediate: true,

			handler() {
				this.getApp();
			}
		},

		inferredPipeline: {
			immediate: true,

			handler() {
				// generate IntegrationObj data for CI pipeline
				if (this.inferredPipeline?.scan) {
					const modules = this.inferredPipeline.scan.map((module, index) => {
						const moduleRefId = `${module.provisioner}:${module.name}@${module.version}`;
						const inputs = JSONSchemaToObject(module.inputs);
						return {
							moduleRefId,
							inputs: inputs as Record<string, unknown>,
							icon: getProvisionerIcon(module.provisioner),
							name: module.displayName!,
							data: module,
							order: index
						};
					});
					this.integrationPipelines.push({
						name: "scan-pipeline",
						show: true,
						identifier: "",
						triggerEvent: {
							data: { id: triggerEvent.pushToBranch },
							title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToBranch]()
						},
						edit: false,
						modules,
						variables: []
					});
				}
				// generate IntegrationObj data for CD pipeline
				if (this.inferredPipeline?.build) {
					const modules = this.inferredPipeline.build.map((module, index) => {
						const moduleRefId = `${module.provisioner}:${module.name}@${module.version}`;
						const inputs = JSONSchemaToObject(module.inputs);
						return {
							moduleRefId,
							inputs: inputs as Record<string, unknown>,
							icon: getProvisionerIcon(module.provisioner),
							name: module.displayName!,
							data: module,
							order: index
						};
					});
					this.integrationPipelines.push({
						name: "publish-pipeline",
						show: true,
						identifier: "",
						triggerEvent: {
							data: { id: triggerEvent.pushToBranch },
							title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToBranch]()
						},
						edit: false,
						modules,
						variables: []
					});
				}
			}
		}
	},

	mounted() {
		if (this.inferredPipeline?.scan?.length === 0) {
			this.showScanPipeline = false;
		}
		if (this.inferredPipeline?.build?.length === 0) {
			this.showScanAndBuildPipeline = false;
		}
	},

	methods: {
		async getApp() {
			if (this.appId && this.orgId) {
				this.app = await applicationStore.GET_APP_BY_ID({
					appId: this.appId,
					orgId: this.orgId,
					projectId: this.projectId
				});
			}
		},

		async goBack() {
			// The data before this step is stored in user metadata, therefore we refetch it for the latest values
			await userStore.GET_USER();
			this.$emit("back");
		},

		async createIntegrationsAndWebhooks() {
			if (!this.app) {
				this.submitError = "Something went wrong with the application.";
				return;
			}
			if (this.integrationPipelines.length === 0) {
				this.submitError = "Please add atleast one integration pipeline.";
				return;
			}
			/**
			 * As the user can now add a container image as an input artifact, assuming the user just wants
			 * to create a CD pipeline
			 */
			const inputArtifactId = this.app.artifacts?.find(artifact => !!artifact.gitCode)?.id ?? "";
			const outputArtifactId =
				this.app.artifacts?.find(artifact => !!artifact.containerImage)?.id ?? "";
			const integrations = await this.createIntegrations(inputArtifactId, outputArtifactId);
			await this.createWebhooks(integrations);
			return integrations;
		},

		async createIntegrations(inputArtifactId: string | undefined, outputArtifactId: string) {
			const promises = this.integrationPipelines
				.filter(i => i.show)
				.map(integration => {
					// filter out sensitive variables and convert them to key value pair
					const sensitiveVars = integration.variables
						.filter(variable => variable.isMasked)
						.reduce(
							(acc, curr) => {
								acc[curr.key] = curr.value;
								return acc;
							},
							{} as Record<string, string>
						);

					// filter out non-sensitive variables and convert them to key value pair
					const variables = integration.variables
						.filter(variable => !variable.isMasked)
						.reduce(
							(acc, curr) => {
								acc[curr.key] = curr.value;
								return acc;
							},
							{} as Record<string, string>
						);

					// create initial object
					let payload: AppIntegrationCreate = {
						appId: this.app!.id,
						name: integration.name,
						orgId: this.app!.orgId,
						projId: this.app!.projId,
						config: {
							sensitive: sensitiveVars,
							vars: variables
						},
						pipeline: integration.modules.map(module => {
							return { moduleRefId: module.moduleRefId, inputs: module.inputs };
						})
					};
					/**
					 * if input artifact is given, then we need to create a pipeline with input and output artifact
					 * .i.e: Input = GitCode, Output = ContainerImage
					 * This means the user wants to just use CI & CD pipelines
					 */
					if (inputArtifactId) {
						payload = {
							...payload,
							inputAppArtifact: {
								id: inputArtifactId
							},
							outputAppArtifact: {
								id: outputArtifactId
							}
						};
					} else {
						/**
						 * Else we need to create a pipeline with only output artifact
						 * .i.e: Input = ContainerImage
						 * This means the user wants to just use CD pipeline
						 */
						payload = {
							...payload,
							inputAppArtifact: {
								id: outputArtifactId
							}
						};
					}
					return applicationIntegrationStore.CREATE_APP_INTEGRATION(payload);
				});
			return await Promise.all(promises);
		},

		async createWebhooks(integrations: appIntegration[]) {
			const webhookPromises = integrations.map(integration => {
				const webhookData = this.integrationPipelines.find(i => i.name === integration.name);
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				if (webhookData?.identifier && webhookData.triggerEvent) {
					return webHooksStore.CREATE_WEB_HOOK({
						orgId: integration.orgId,
						projectId: integration.projId,
						entityId: integration.id,
						triggerType: triggerType.appInt,
						appInt: {
							id: integration.id,
							appId: integration.appId,
							orgId: integration.orgId,
							projId: integration.projId
						},
						githubTrigger: {
							identifier: webhookData.identifier,
							triggerEvent: webhookData.triggerEvent.data.id
						}
					});
				}
				return Promise.resolve();
			});
			await Promise.all(webhookPromises);
		},

		async add() {
			// We create an application integration entity with the app and inferred pipeline modules
			try {
				this.isAdding = true;
				await this.createIntegrationsAndWebhooks();
			} catch (error) {
				this.submitError = getErrorMessage(error, true);
			} finally {
				this.isAdding = false;
				this.$emit("close");
			}
		},

		async addAndDeploy() {
			if (!this.app) {
				this.submitError = "Something went wrong with the application.";
				return;
			}
			try {
				this.isDeploying = true;
				const integrations = await this.createIntegrationsAndWebhooks();
				if (!integrations || integrations.length === 0) {
					this.submitError = "Something went wrong with the application integration.";
					return;
				}
				const inputArtifact = this.app.artifacts?.find(artifact => !!artifact.gitCode);
				const outputArtifact = this.app.artifacts?.find(artifact => !!artifact.containerImage);
				await Promise.all(
					integrations.map(integration => {
						if (inputArtifact && outputArtifact) {
							applicationIntegrationStore.START_INTEGRATION_JOB({
								inputAppArtifact: artitactToArtifactSelector(inputArtifact),
								outputAppArtifact: artitactToArtifactSelector(outputArtifact),
								integrationId: integration.id,
								orgId: integration.orgId,
								projId: integration.projId,
								appId: integration.appId
							});
						}
						return Promise.resolve();
					})
				);
				// take the last integartion and route to the pipeline view
				const integration = integrations.at(-1);
				if (integration) {
					this.$router.push({
						name: "stageViewIntegration",
						params: {
							orgId: integration.orgId,
							projId: integration.projId,
							appId: integration.appId,
							integrationId: integration.id
						}
					});
				}
				notificationsStore.ADD_TOAST({
					qaId: "toast-integration-deploy-success",
					title: "Integration pipeline triggered successfully",
					text: `All the pipelines are triggered successfully`,
					status: "success"
				});
				this.$emit("close");
			} catch (error) {
				this.submitError = getErrorMessage(error, true);
			} finally {
				this.isDeploying = false;
			}
		},

		onBlur(e: CustomEvent, pipeline: IntegrationObj) {
			if (pipeline.edit && e.type === "blur") {
				pipeline.edit = false;
			}
		},

		saveModules({
			moduleRefId,
			inputs,
			order,
			identifier,
			webhookTriggerEvent
		}: {
			moduleRefId: string;
			inputs: Record<string, unknown>;
			order: number;
			identifier: string | undefined;
			webhookTriggerEvent: WebhookTriggerEvent | undefined;
		}) {
			const pipeline = this.integrationPipelines.find(
				integration => integration.name === this.showAdvanceConfigFor
			);
			if (pipeline?.modules) {
				pipeline.modules.forEach(module => {
					if (module.moduleRefId === moduleRefId && module.order === order) {
						module.inputs = inputs;
					}
				});

				// set triggers if any
				if (webhookTriggerEvent && identifier) {
					pipeline.identifier = identifier;
					pipeline.triggerEvent = webhookTriggerEvent;
				}

				this.integrationPipelines.splice(
					this.integrationPipelines.findIndex(
						integration => integration.name === this.showAdvanceConfigFor
					),
					1,
					pipeline
				);
			}
		},

		addNewModule(module: WizardFlowPipelineModules) {
			const pipeline = this.integrationPipelines.find(
				integration => integration.name === this.showAdvanceConfigFor
			);

			if (pipeline?.modules) {
				pipeline.modules.push(module);

				this.integrationPipelines.splice(
					this.integrationPipelines.findIndex(
						integration => integration.name === this.showAdvanceConfigFor
					),
					1,
					pipeline
				);
			}
		},

		addNewModuleToNewPipeline(module: WizardFlowPipelineModules) {
			this.newPipeline.modules.push(module);
		},

		updateVariables({ variables, pipelineName }: { variables: Variable[]; pipelineName: string }) {
			const pipeline = this.integrationPipelines.find(
				integration => integration.name === pipelineName
			);
			if (pipeline) {
				pipeline.variables = variables;
			}
		},

		createNewPipeline({
			name,
			variables,
			modules,
			webhook
		}: {
			name: string;
			variables: Variable[];
			modules: WizardFlowPipelineModules[];
			webhook: {
				identifier: string;
				triggerEvent: WebhookTriggerEvent;
			};
		}) {
			this.integrationPipelines.push({
				name,
				show: true,
				identifier: webhook.identifier,
				triggerEvent: {
					data: webhook.triggerEvent.data,
					title: webhook.triggerEvent.title
				},
				edit: false,
				modules,
				variables
			});
			this.closeAndResetAddForm();
		},

		closeAndResetAddForm() {
			this.isAddNewPipelineOpen = false;
			this.newPipeline = {
				show: true,
				name: "",
				identifier: "",
				triggerEvent: {
					data: { id: triggerEvent.pushToBranch },
					title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToBranch]()
				},

				edit: false,
				modules: [] as WizardFlowPipelineModules[],
				variables: [] as Variable[]
			} as IntegrationObj;
		},

		setModules({
			modules,
			pipelineName,
			isEditMode
		}: {
			modules: WizardFlowPipelineModules[];
			pipelineName: string;
			isEditMode: boolean;
		}) {
			if (isEditMode) {
				this.integrationPipelines.forEach(pipeline => {
					if (pipeline.name === pipelineName) {
						pipeline.modules = modules;
					}
				});
			} else {
				this.newPipeline.modules = modules;
			}
		},

		updateGithubTriggers({
			pipelineName,
			identifier,
			githubTriggerEvent
		}: {
			pipelineName: string;
			identifier: string;
			githubTriggerEvent: WebhookTriggerEvent;
		}) {
			this.integrationPipelines.forEach(pipeline => {
				if (pipeline.name === pipelineName) {
					pipeline.identifier = identifier;
					pipeline.triggerEvent = githubTriggerEvent;
				}
			});
		}
	}
});
</script>
<style lang="scss">
.add-button,
.add-deploy-button {
	width: 45% !important;
}
</style>
