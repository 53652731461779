<template>
	<Wrapper max-height="70vh" border-radius="4px" background="element-light" data-qa-gcp-cred-model>
		<!--START : Container-->
		<!--START : PopOver header-->
		<Container
			:padding="0"
			:gap="0"
			direction="column"
			:grow="1"
			align="start top"
			class="height-100-per flex-shrink-0"
		>
			<Header>
				<Icon
					v-if="showBackBtn"
					name="i-arrow-left"
					size="small"
					data-qa-gcp-cred-model-back-btn
					@click="validateAndCloseModal('back')"
				/>
				<Typography type="h4" color="dark" data-qa-gcp-cred-model-title>{{ headerTxt }}</Typography>
				<Container :padding="0" :grow="1" align="right center">
					<Icon
						name="i-question-filled"
						type="filled"
						size="x-small"
						data-qa-gcp-cred-model-help-btn
						@click="openHelp"
					/>
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						data-qa-gcp-cred-model-close-btn
						@click="validateAndCloseModal"
					/>
				</Container>
			</Header>
			<!--END : PopOver header-->

			<ClosePopoverConfirmationWarning
				ref="closeConfirmation"
				:initial-form-values="initialformValues"
				:form-values="gcpCredFormValues"
				@force-close="closeModal"
			/>

			<ModalNotificationBanner
				v-if="cred"
				banner-type="primary"
				banner-body="Changes made are applicable to the future deployments."
			/>

			<ModalNotificationBanner
				v-if="submitError"
				banner-type="error"
				:banner-body="submitError"
				:show-close-icon="true"
				@close="submitError = null"
			/>

			<Container :padding="16" :gap="24" direction="column" overflow="auto" align="left top">
				<f-form-builder
					ref="formBuilder"
					data-qa-gcp-cred-model-form
					:field.prop="formFields"
					:values.prop="gcpCredFormValues"
					@input="saveFormValues"
					@state-change="formState = $event.detail"
				/>
			</Container>

			<Footer>
				<Button
					state="full"
					:disabled="isSubmitting"
					:loading="isSubmitting"
					:type="isSubmitting ? 'default' : 'success'"
					data-qa-gcp-cred-model-save-btn
					@click="saveGcpCredentials"
					>{{ ctaBtnTxt }}</Button
				>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { credentialStore } from "@/modules/credentials/credential-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { Creds, CredsCreate, credsType, CredsUpdate } from "@/protocol/identity";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import { applyEntityNameRules2 } from "@/shared/custom-validation-rules/entityNameRules";
import {
	captureError,
	getClassificationFormField,
	getClassificationFormValue,
	getErrorMessage,
	readUploadedFileAsText
} from "@/utils";

export default defineComponent({
	name: "GcpCredentialModel",

	components: {
		ClosePopoverConfirmationWarning,
		ModalNotificationBanner,
		Typography,
		Container,
		Wrapper,
		Button,
		Footer,
		Header,
		Icon
	},

	props: {
		assignCred: {
			type: Boolean
		},

		showBackBtn: {
			type: Boolean
		},

		updateCredList: {
			type: Boolean
		},

		cred: {
			type: Object as PropType<Creds>
		},

		allowedClassificationIds: {
			type: Array as PropType<string[]>
		}
	},

	data: () => ({
		formState: null as FormBuilderState | null,
		gcpCredFormValues: {} as LocalGcpCredFormValues,
		isSubmitting: false,
		submitError: null as string | null,
		existingCredNames: [] as string[],
		initialformValues: {} as LocalGcpCredFormValues
	}),

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					serviceAccount: {
						type: "file",
						multiple: false,
						validationRules: [{ name: "required" }],
						label: {
							title: "Upload service account key"
						},

						id: "serviceAccount",
						fileType: ".json"
					},

					__separator__: {
						type: "separator"
					},

					...getClassificationFormField(this.cred, this.allowedClassificationIds),

					name: {
						type: "text",
						validationRules: [
							...applyEntityNameRules2(this.existingCredNames, "This display name already exists.")
						],

						label: { title: "Display name" },
						id: "name",
						placeholder: "Enter display name",
						helperText: "This name will be used to refer to your saved credential on Code Pipes"
					}
				}
			};
		},

		orgId() {
			return this.$route.params.orgId as string;
		},

		headerTxt() {
			if (this.assignCred) {
				return "Assign credential";
			} else if (this.cred) {
				return "Edit GCP credential";
			}
			return "Add GCP credential";
		},

		ctaBtnTxt() {
			if (this.assignCred) {
				return "Assign credential";
			}
			return "Save GCP credential";
		}
	},

	mounted() {
		this.existingCredNames = credentialStore.existingCredentialNames.filter(_credName =>
			this.cred ? this.cred.name !== _credName : true
		);

		if (this.cred) {
			this.gcpCredFormValues = {
				name: this.cred.name,
				...(this.cred.classificationId && getClassificationFormValue(this.cred.classificationId))
			};

			this.initialformValues = this.gcpCredFormValues;
		}
	},

	methods: {
		// eslint-disable-next-line max-statements
		async saveGcpCredentials() {
			if (this.isSubmitting) {
				return null;
			}

			this.submitError = "";
			this.isSubmitting = true;

			(this.$refs.formBuilder as FFormBuilder).submit();

			if (!this.formState?.isValid) {
				this.isSubmitting = false;
				return null;
			}

			// reading file as json string. formBuilder validateForm check if user has uploaded the file.
			const ServiceAccountBlobFile = this.gcpCredFormValues.serviceAccount!;
			const stringFormatJson = await readUploadedFileAsText(ServiceAccountBlobFile);

			// Try to get project_id from the GCP JSON
			let projectId: null | string = null;
			try {
				projectId = JSON.parse(stringFormatJson).project_id;

				if (!projectId) {
					throw projectId;
				}
			} catch (err) {
				this.submitError = "Invalid GCP Project JSON file (no project_id found)";
				this.isSubmitting = false;
				return null;
			}

			// converting string to base 64.
			const serviceAccountB64 = window.btoa(stringFormatJson);

			try {
				let cred: Creds | null = null;

				if (this.cred) {
					const updateCredPayload: CredsUpdate = {
						name: this.gcpCredFormValues.name!,
						id: this.cred.id,
						orgId: this.cred.orgId,
						type: credsType.credsType_gcp,
						// API doesn't update the value of displayId and isNotSensitive from the request payload, so we
						// can sent empty values.
						displayId: "",
						isNotSensitive: false,
						gcp: {
							serviceAccount: serviceAccountB64,
							project: projectId
						}
					};

					await credentialStore.updateCredential({ cred: updateCredPayload });

					// Get latest cred object and update the store.
					credentialStore.GET_UPDATE_CREDENTIAL({ orgId: this.orgId, id: this.cred.id });
				} else {
					let createCredpayload: CredsCreate = {
						name: this.gcpCredFormValues.name!,
						orgId: this.orgId,
						type: credsType.credsType_gcp,
						gcp: {
							serviceAccount: serviceAccountB64,
							project: projectId
						}
					};
					if (this.gcpCredFormValues.classification) {
						createCredpayload = {
							...createCredpayload,
							classificationId: this.gcpCredFormValues.classification.data.id
						};
					}

					cred = await credentialStore.createCredential({ cred: createCredpayload });

					// Update the credential list
					if ("id" in cred) {
						credentialStore.UPDATE_CRED_AND_STATS_BY_ID(cred);
					}
				}
				if (this.updateCredList) {
					if (this.cred) {
						notificationsStore.ADD_TOAST({
							qaId: "toast-cloud-cred-gcp-edited-successfully",
							title: "Cloud credential updated",
							text: `Credential ${this.gcpCredFormValues.name} is successfully updated.`,
							status: "success"
						});
					} else {
						notificationsStore.ADD_TOAST({
							qaId: "toast-cloud-cred-gcp-added-successfully",
							title: "New credential added",
							text: `New credential ${this.gcpCredFormValues.name} is successfully added.`,
							status: "success"
						});
					}
					this.closeModal();
				}

				this.$emit("onCredCreate", cred);
			} catch (err) {
				this.submitError = getErrorMessage(err);
				captureError(err);
			}
			this.isSubmitting = false;
			return null;
		},

		saveFormValues(event: CustomEvent<LocalGcpCredFormValues>) {
			this.gcpCredFormValues = event.detail;
		},

		closeModal() {
			this.$emit("closeModal");
		},

		validateAndCloseModal(eventType: "back" | "closeModal" = "closeModal") {
			const isFormTouched = (
				this.$refs.closeConfirmation as InstanceType<typeof ClosePopoverConfirmationWarning>
			).isFormTouched();

			// safely closing form since user hasn't touched the form.
			if (!isFormTouched) {
				if (eventType === "back") {
					return this.$emit("back");
				}
				return this.closeModal();
			}
			return;
		},

		openHelp() {
			window.open("https://docs.codepipes.io/docs/cloud-sources#gcp", "_blank");
		}
	}
});

type LocalGcpCredFormValues = {
	serviceAccount?: File;
	name?: string;
	classification?: ClassificationType;
};

type ClassificationType = FSelectOptionObject & {
	data: { id: string; icon: string };
};
</script>
