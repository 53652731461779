<template>
	<TableRow data-qa-members-list-loading>
		<TableCell padding="16px 12px">
			<Container :padding="0">
				<Checkbox v-if="showCheckbox" />
				<Shimmer />
			</Container>
		</TableCell>
		<TableCell padding="16px 12px">
			<Shimmer />
		</TableCell>
		<TableCell padding="16px 12px">
			<Shimmer />
		</TableCell>
		<TableCell padding="16px 12px">
			<Shimmer />
		</TableCell>
	</TableRow>
</template>
<script lang="ts">
import { TableRow, TableCell, Shimmer, Checkbox, Container } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "LoadingRows",

	components: {
		TableRow,
		TableCell,
		Shimmer,
		Checkbox,
		Container
	},

	props: {
		showCheckbox: {
			type: Boolean,
			default: () => true
		}
	}
});
</script>
