/* eslint-disable no-redeclare */
import { organization, project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";

// This overload guaranteed that if a fallback is provided we will return a string
export function getEmoji(
	entity: project | environment | organization | undefined | null,
	fallback: string
): string;

export function getEmoji(
	entity: project | environment | organization | undefined | null
): string | null;

export function getEmoji(
	entity: project | environment | organization | undefined | null,
	fallback?: string
) {
	const emojiObj = entity?.metadata?.emojiObj as { native: string } | undefined;

	if (!emojiObj?.native) {
		return fallback ?? null;
	}

	return emojiObj.native;
}
