<template>
	<Slab
		:state="bannerState.slabState"
		state-type="solid"
		size="medium"
		data-qa-container-cred-acc-popover-header-status-slab
	>
		<template #primary-action>
			<Icon
				v-if="bannerState.icon"
				:name="bannerState.icon"
				type="filled"
				color="primary-100"
				size="small"
			/>
		</template>
		<slot>
			<Typography color="white" type="p2">{{ bannerBody }}</Typography>
		</slot>
		<template #secondary-actions>
			<Icon
				v-if="showCloseIcon"
				name="i-close"
				size="x-small"
				state="dark"
				data-qa-modal-close-button
				@click="$emit('close')"
			/>
		</template>
	</Slab>
</template>

<script lang="ts">
import { Slab, Typography, Icon, SlabState } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { InotificationBannerType } from "./modalNotification-types";

export default defineComponent({
	name: "ModalNotificationBanner",
	components: { Slab, Typography, Icon },

	props: {
		bannerType: {
			type: String as PropType<InotificationBannerType>,
			required: true
		},

		bannerBody: {
			type: String
		},

		showCloseIcon: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		bannerState(): { slabState: SlabState; icon: string } {
			if (this.bannerType === "success") {
				return {
					slabState: "success",
					icon: "i-tick"
				};
			}
			if (this.bannerType === "primary") {
				return {
					slabState: "primary",
					icon: "i-info-fill"
				};
			}
			if (this.bannerType === "warning") {
				return {
					slabState: "warning",
					icon: "i-alert-2"
				};
			}
			return {
				slabState: "error",
				icon: "i-alert-2"
			};
		}
	}
});
</script>
