<template>
	<Wrapper
		data-qa-invite-members
		border-radius="4px"
		background="element-light"
		width="452px"
		max-height="80vh"
		class="overflow-visible"
	>
		<Header>
			<Typography type="h4" color="dark">Invite new members</Typography>
			<Icon size="x-small" type="filled" name="i-close" @click="closeInviteMembers()" />
		</Header>
		<Container :padding="16" :gap="16" align="left top" direction="column" overflow="visible">
			<f-form-builder
				ref="inviteMemberForm"
				data-qa-edit-dependency-form
				:field.prop="formFields"
				:values.prop="formValues"
				@input="handleInput"
				@submit="onSubmit"
				@state-change="formState = $event.detail"
			/>
		</Container>

		<Container v-if="submitError" padding="0 16px 16px" align="left top">
			<Typography size="p4" color="error" family="logs" data-qa-invite-members-error>{{
				submitError
			}}</Typography>
		</Container>

		<Footer>
			<Button
				data-qa-invite-members-button
				:loading="isSubmitting"
				state="full"
				type="primary"
				@click="submitForm()"
				>INVITE MEMBERS</Button
			>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { Button, Container, Footer, Header, Icon, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { InviteError, membersStore } from "@/modules/members/members-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { organization, project as projectProto } from "@/protocol/identity";
import { invite as inviteProto } from "@/protocol/user";
import { captureError, getEmoji, getErrorMessage } from "@/utils";

import { MEMBER_ROLES_ARR, Member } from "./member-types";

const MEMBER_ROLES = MEMBER_ROLES_ARR.filter(role => role.id !== "limited").map(role => {
	return {
		data: { id: role.id, isAdmin: role.isAdmin },
		title: role.name
	};
});

export default defineComponent({
	name: "InviteMembers",

	components: {
		Icon,
		Typography,
		Container,
		Button,
		Footer,
		Header,
		Wrapper
	},

	props: {
		org: {
			type: Object as PropType<organization>
		},

		project: {
			type: Object as PropType<projectProto>
		},

		members: {
			type: Array as PropType<Member[]>,
			required: true
		}
	},

	emits: ["close", "invited"],

	data: () => ({
		isSubmitting: false,
		submitError: "",

		formValues: {
			email: "",
			role: MEMBER_ROLES[0]
		} as FormValues,

		formState: null as FormBuilderState | null
	}),

	computed: {
		existingMemberEmails() {
			return this.members.map(member => member.email);
		},

		formFields(): FormBuilderField {
			const { existingMemberEmails } = this;
			return {
				type: "object",
				direction: "vertical",
				fields: {
					email: {
						type: "textarea",
						id: "email",
						label: { title: "Email" },
						qaId: "email",
						placeholder: "Enter multiple email addresses separated by a comma",
						validationRules: [
							{ name: "required" },
							{
								name: "custom",
								validate(value?: string | unknown[]) {
									if (typeof value !== "string") {
										return false;
									}

									return /^\s*(([\w.+\-*]+@\w+\.\w+(\.\w+)*)[\s,]*)+\s*$/.test(value);
								},

								message: "Enter valid emails separated by a comma."
							},
							{
								name: "custom",
								validate: (value?: string | unknown[]) => {
									if (typeof value !== "string") {
										return false;
									}

									const duplicateEmails: string[] = [];
									const addedMembers = value.split(",").map(email => email.trim());

									existingMemberEmails.forEach(email => {
										if (email && addedMembers.includes(email)) {
											duplicateEmails.push(email);
										}
									});

									if (duplicateEmails.length > 0) {
										this.submitError = `Existing members: ${duplicateEmails.join(", ")}`;
									} else {
										this.submitError = "";
									}

									return duplicateEmails.length === 0;
								},

								message: "Can't invite existing members."
							}
						]
					},

					role: {
						type: "select",
						qaId: "role",
						label: { title: "Member role", iconTooltip: "Select role for the new member(s)." },
						options: MEMBER_ROLES,
						validationRules: [{ name: "required" }],

						//@ts-expect-error
						optionTemplate: (option: (typeof MEMBER_ROLES)[number]) =>
							html`<f-div direction="column" gap="x-small">
								<f-div gap="small">
									${option.title === "Admin"
										? html`<f-icon state="warning" source="i-crown"></f-icon>`
										: ""}
									<f-text>${option.title}</f-text>
								</f-div>
							</f-div>`
					}
				}
			};
		}
	},

	methods: {
		closeInviteMembers() {
			this.$emit("close", true);
		},

		submitForm() {
			(this.$refs.inviteMemberForm as InstanceType<typeof FFormBuilder>).submit();
		},

		handleInput(event: CustomEvent<FormValues>) {
			this.formValues = event.detail;
		},

		getInviteText(numUsers: number) {
			const entity = this.project ?? this.org;

			if (!entity) {
				return "";
			}

			const emojiStr = getEmoji(entity, "");

			const memberStr = numUsers === 1 ? "1 member" : `${numUsers} members`;

			return `Successfully added ${memberStr} to ${emojiStr}${entity.name}.`;
		},

		async onSubmit(event: CustomEvent<FormValues>) {
			const values = event.detail;
			const entity = this.project ?? this.org;
			const orgId = this.project?.orgId ?? this.org?.id;

			this.submitError = "";

			if (!entity || !orgId) {
				return;
			}

			try {
				this.isSubmitting = true;

				const userEmails = values.email.split(",").map(email => email.trim());

				const invite: inviteProto = {
					role: values.role.data.id
				};

				const invitedProjectId = this.project?.id;

				if (invitedProjectId) {
					invite.project = { orgId, id: invitedProjectId };
				} else {
					invite.org = { id: orgId };
				}

				await membersStore.USER_INVITATION_ACTION({
					orgId,
					requests: userEmails.map(email => ({ email, invite })),
					actionType: "invite"
				});

				await membersStore.FETCH_USERS({
					orgId,
					invite
				});

				notificationsStore.ADD_TOAST({
					status: "success",
					qaId: "invite-members-success-toast",
					title: "Success",
					text: this.getInviteText(userEmails.length),
					subText: "Just now"
				});

				this.closeInviteMembers();

				this.$emit("invited");
			} catch (err) {
				const inviteErrors = err as InviteError;

				if ("inviteErrors" in inviteErrors) {
					this.submitError = inviteErrors.inviteErrors
						.map(err_ => getErrorMessage(err_))
						.join("\n");
					captureError(inviteErrors.inviteErrors[0]);
				} else {
					captureError(err);
					this.submitError = getErrorMessage(err, true);
				}
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});

type FormValues = {
	email: string;
	role: (typeof MEMBER_ROLES)[number];
};
</script>
