<template>
	<div :class="'code-promo-column ' + columnClass">
		<div v-if="type === 'app' && $slots['default']" class="app">
			<slot />
		</div>
		<div v-else-if="type === 'trigger' && $slots['default']" class="trigger">
			<slot />
		</div>
		<slot v-else />
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

type CPColumnType = "app" | "env" | "trigger" | "placeholder";
export default defineComponent({
	name: "CPColumn",

	props: {
		type: {
			type: String as PropType<CPColumnType>,
			default: () => null
		}
	},

	computed: {
		columnClass() {
			if (this.type === "app") {
				return "code-promo-app";
			} else if (this.type === "env") {
				return "env";
			} else if (this.type === "trigger") {
				return "code-promo-trigger";
			}
			return null;
		}
	}
});
</script>
