import { PipelineJobStatus, JOB_STATUS } from "@/shared/pipeline-constants";

export type PipelineContent = {
	text?: string;
	showIcon?: boolean;
	iconName?: string;
	iconState?: string;
	iconType?: string;
	buttonState: "cancelling" | "cancellable" | "actionable" | "non-actionable";
	showTimestamp?: boolean;
	actionName?: string;
};

export type PipelineState = Record<PipelineJobStatus, PipelineContent>;

export type IActionType = {
	validateOnly: PipelineState;
	ValidateAndApply: PipelineState;
	destroy: PipelineState;
	no_action_type_support?: null;
};

// https://www.figma.com/file/2oN20Ehxy1k9LrEIwHbCD1/Cancel-pipeline?node-id=2%3A387
const footerContent: IActionType = {
	validateOnly: {
		[JOB_STATUS.FAILED]: {
			text: "Validate failed",
			iconName: "i-alert",
			iconState: "warning",
			showIcon: true,
			iconType: "filled",
			buttonState: "actionable",
			actionName: "validate",
			showTimestamp: false
		},
		[JOB_STATUS.DONE]: {
			text: "Validated",
			iconName: "i-tick",
			iconState: "primary",
			showIcon: true,
			iconType: "filled",
			buttonState: "actionable",
			actionName: "validate",
			showTimestamp: true
		},
		[JOB_STATUS.ACTIVE]: {
			text: "Validating...",
			showIcon: true,
			iconName: "loading",
			iconState: "primary",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "validate",
			showTimestamp: false
		},
		[JOB_STATUS.CANCELLING]: {
			text: "Cancelling...",
			showIcon: true,
			iconName: "loading",
			iconState: "error",
			iconType: "outlined",
			buttonState: "cancelling",
			actionName: "validate",
			showTimestamp: false
		},
		[JOB_STATUS.CANCELLED]: {
			text: "Cancelled",
			showIcon: true,
			iconName: "i-stop",
			iconState: "error",
			iconType: "filled",
			buttonState: "actionable",
			actionName: "validate",
			showTimestamp: true
		},
		[JOB_STATUS.PENDING]: {
			text: "Env Pending...",
			showIcon: true,
			iconName: "loading",
			iconState: "primary",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "validate",
			showTimestamp: false
		},
		[JOB_STATUS.WAITING]: {
			text: "Existing jobs in progress...",
			showIcon: true,
			iconName: "loading",
			iconState: "primary",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "validate",
			showTimestamp: false
		},
		[JOB_STATUS.STALLED]: {
			text: "Env Stalled...",
			showIcon: true,
			iconName: "loading",
			iconState: "error",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "validate",
			showTimestamp: false
		}
	},
	ValidateAndApply: {
		[JOB_STATUS.FAILED]: {
			text: "Deploy failed",
			iconName: "i-alert",
			iconState: "warning",
			showIcon: true,
			iconType: "filled",
			buttonState: "actionable",
			actionName: "validate & deploy",
			showTimestamp: false
		},
		[JOB_STATUS.DONE]: {
			text: "Deployed",
			iconName: "i-launch",
			iconState: "success",
			showIcon: true,
			iconType: "filled",
			buttonState: "actionable",
			actionName: "validate & deploy",
			showTimestamp: true
		},
		[JOB_STATUS.ACTIVE]: {
			text: "Deploying...",
			showIcon: true,
			iconName: "loading",
			iconState: "success",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "validate & deploy",
			showTimestamp: false
		},
		[JOB_STATUS.CANCELLING]: {
			text: "Cancelling...",
			showIcon: true,
			iconName: "loading",
			iconState: "error",
			iconType: "outlined",
			buttonState: "cancelling",
			actionName: "validate & deploy",
			showTimestamp: false
		},
		[JOB_STATUS.CANCELLED]: {
			text: "Cancelled",
			showIcon: true,
			iconName: "i-stop",
			iconState: "error",
			iconType: "filled",
			buttonState: "actionable",
			actionName: "validate & deploy",
			showTimestamp: true
		},
		[JOB_STATUS.PENDING]: {
			text: "Env Pending...",
			showIcon: true,
			iconName: "loading",
			iconState: "primary",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "validate & deploy",
			showTimestamp: false
		},
		[JOB_STATUS.WAITING]: {
			text: "Existing jobs in progress...",
			showIcon: true,
			iconName: "loading",
			iconState: "primary",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "validate & deploy",
			showTimestamp: false
		},
		[JOB_STATUS.STALLED]: {
			text: "Env Stalled...",
			showIcon: true,
			iconName: "loading",
			iconState: "error",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "validate & deploy",
			showTimestamp: false
		}
	},
	destroy: {
		[JOB_STATUS.FAILED]: {
			text: "Destroy failed",
			iconName: "i-alert",
			iconState: "warning",
			showIcon: true,
			iconType: "filled",
			buttonState: "actionable",
			actionName: "destroy",
			showTimestamp: false
		},
		[JOB_STATUS.DONE]: {
			text: "Destroyed",
			iconName: "i-launch",
			iconState: "success",
			showIcon: true,
			iconType: "filled",
			buttonState: "actionable",
			actionName: "destroy",
			showTimestamp: true
		},
		[JOB_STATUS.ACTIVE]: {
			text: "Destroying...",
			showIcon: true,
			iconName: "loading",
			iconState: "error",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "destroy",
			showTimestamp: false
		},
		[JOB_STATUS.CANCELLED]: {
			text: "Cancelled",
			showIcon: true,
			iconName: "i-stop",
			iconState: "error",
			iconType: "filled",
			buttonState: "actionable",
			actionName: "destroy",
			showTimestamp: true
		},
		[JOB_STATUS.CANCELLING]: {
			text: "Cancelling...",
			showIcon: true,
			iconName: "loading",
			iconState: "error",
			iconType: "outlined",
			buttonState: "cancelling",
			actionName: "destroy",
			showTimestamp: false
		},
		[JOB_STATUS.PENDING]: {
			text: "Env Pending...",
			showIcon: true,
			iconName: "loading",
			iconState: "primary",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "destroy",
			showTimestamp: false
		},
		[JOB_STATUS.WAITING]: {
			text: "Existing jobs in progress...",
			showIcon: true,
			iconName: "loading",
			iconState: "primary",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "destroy",
			showTimestamp: false
		},
		[JOB_STATUS.STALLED]: {
			text: "Env Stalled...",
			showIcon: true,
			iconName: "loading",
			iconState: "error",
			iconType: "outlined",
			buttonState: "cancellable",
			actionName: "destroy",
			showTimestamp: false
		}
	}
};
export { footerContent };
