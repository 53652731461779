<template>
	<PopOver :open="true" :target="target" @overlay-click="$emit('close')">
		<template #content>
			<Wrapper
				border-radius="4px"
				background="gray-500"
				width="442px"
				max-height="80vh"
				data-qa-deploy-application-modal
			>
				<Header>
					<Icon
						data-qa-back
						size="small"
						type="filled"
						name="i-arrow-left"
						@click="$emit('back')"
					/>
					<Typography type="h4" color="dark">Deploy {{ deployment.name }}</Typography>
					<Container :padding="0" :shrink="0" :grow="1" align="right center">
						<Icon
							data-qa-deploy-application-modal-close
							size="x-small"
							type="filled"
							name="i-close"
							@click="$emit('close')"
						/>
					</Container>
				</Header>
				<Container padding="16px" :gap="20" align="left top" direction="column" overflow="visible">
					<Typography type="p1-para" color="dark">
						You are about to deploy
						<Typography
							type="p1"
							weight="medium"
							color="dark"
							inline
							data-qa-deploy-application-modal-name
							>{{ deployment.name }}</Typography
						>. Confirm by clicking on the button below.
					</Typography>
				</Container>

				<Divider direction="horizontal" :size="1" />

				<Container
					:padding="12"
					:gap="12"
					direction="column"
					align="left top"
					overflow="scroll"
					class="flow-add-scrollbar"
				>
					<VersionSelector
						v-if="isEnvInPromotionSeq"
						:versions="getCurrentVersion()"
						:tag-pattern="versionTagPattern"
						@version-selected="setVersion"
					/>

					<DeploymentArtifactSelector
						v-else-if="deployment.appId"
						:app-id="deployment.appId"
						:deployment="deployment"
						@artifact-info="handleArtifactInfo"
					/>

					<f-accordion
						icon-size="small"
						icon-placement="right"
						header-padding="none"
						body-padding="none"
					>
						<f-div padding="medium none" gap="x-small">
							<f-icon source="i-setting" state="primary"></f-icon>
							<f-text variant="para" size="small" state="primary" weight="bold"
								>PIPELINE CONFIGURATION</f-text
							>
						</f-div>

						<f-div slot="body" direction="column">
							<DeploymentPipelineConfiguration
								:deployment="deployment"
								@pipeline-config="handlePipelineConfig"
							/>
						</f-div>
					</f-accordion>
				</Container>

				<Container v-if="submitError">
					<p class="paragraph-2 fc-error" data-qa-promotion-submit-error>{{ submitError }}</p>
				</Container>

				<Footer>
					<Button
						id="trigger-deploy-btn"
						type="success"
						state="full"
						:disabled="isDisabled"
						:loading="isDeployingApp"
						data-qa-deploy-application-modal-btn
						@click="triggerDeployAppDeploymentEntity"
					>
						<Icon name="i-launch" color="gray-900" type="filled" size="small" :effects="false" />
						<span>Deploy</span>
					</Button>
				</Footer>
			</Wrapper>
		</template>
	</PopOver>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	PopOver,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import DeploymentArtifactSelector, {
	ArtifactSelectorInfo
} from "@/modules/application/components/DeploymentArtifactSelector.vue";
import {
	routerToAppPipeline,
	startApplicationPipeline
} from "@/modules/application-deployment/mixins/AppCommonMixin";
import { codePromotionStore } from "@/modules/code-promotion/code-promotion-store";
import VersionSelector from "@/modules/code-promotion/components/VersionSelector.vue";
import { app as appProto } from "@/protocol/app";
import { AppDeployment, DeploymentJobAction, promotedVersion } from "@/protocol/deployment";
import { artitactToArtifactSelector, captureError, getErrorMessage } from "@/utils";

import { applicationDeploymentStore } from "../application-deployment-store";

import DeploymentPipelineConfiguration, {
	DeploymentPipelineConfigurationEvent
} from "./DeploymentPipelineConfiguration.vue";

export default defineComponent({
	name: "AppDeploymentConfirmationPopover",

	components: {
		DeploymentArtifactSelector,
		VersionSelector,
		DeploymentPipelineConfiguration,
		Button,
		Header,
		PopOver,
		Footer,
		Container,
		Typography,
		Icon,
		Wrapper,
		Divider
	},

	props: {
		deployment: {
			type: Object as PropType<AppDeployment>,
			required: true
		},

		target: {
			type: String
		},

		shouldRedirectToPipeline: {
			type: Boolean
		}
	},

	emits: ["close", "back"],

	data() {
		return {
			artifactInfo: {
				isValid: false
			} as ArtifactSelectorInfo,

			updatedPipelineConfig: {
				isValid: false
			} as DeploymentPipelineConfigurationEvent,

			isDeployingApp: false,
			selectedVersion: "",
			isVersionSelected: false,
			submitError: ""
		};
	},

	computed: {
		isDisabled() {
			if (!this.updatedPipelineConfig.isValid) {
				return true;
			}

			if (this.isEnvInPromotionSeq) {
				return !this.isVersionSelected;
			} else {
				return !this.artifactInfo.isValid;
			}
		},

		promotableVersions() {
			return codePromotionStore.promotableVersions;
		},

		isEnvInPromotionSeq() {
			if (!this.deployment.projId) {
				return false;
			}

			return codePromotionStore
				.projPromoEnvs(this.deployment.projId)
				.find(env => env.id === this.deployment.envId);
		},

		versionTagPattern() {
			if (!this.deployment.projId) {
				return "";
			}

			const app = applicationStore.projectApps[this.deployment.projId]?.find(
				(application: appProto) => application.id === this.deployment.appId
			);

			return app?.artifacts?.[0]?.containerImage?.versionTagPattern ?? "";
		}
	},

	mounted() {
		this.getPromotableVersion();
	},

	methods: {
		handleArtifactInfo(artifactInfo: ArtifactSelectorInfo) {
			this.artifactInfo = artifactInfo;
		},

		async triggerDeployAppDeploymentEntity() {
			try {
				this.isDeployingApp = true;

				if (this.updatedPipelineConfig) {
					const artifact = this.deployment.deploymentConfig?.artifact;

					await applicationDeploymentStore.UPDATE_APPLICATION_DEPLOYMENT({
						id: this.deployment.id!,
						orgId: this.deployment.orgId!,
						projId: this.deployment.projId!,
						appId: this.deployment.appId!,
						envId: this.deployment.envId!,
						name: this.deployment.name!,
						description: this.deployment.description!,
						deploymentConfig: {
							...this.deployment.deploymentConfig,
							artifact: artifact ? artitactToArtifactSelector(artifact) : undefined,
							pipelineConfig: this.updatedPipelineConfig.value
						}
					});
				}

				if (!this.isEnvInPromotionSeq) {
					await startApplicationPipeline({
						depEntity: this.deployment,
						jobType: DeploymentJobAction.deploy,
						artifact: this.artifactInfo.artifactorSelector
					});
				} else {
					await codePromotionStore.PROMOTE_APP({
						envId: this.deployment.envId!,
						id: this.deployment.id!,
						orgId: this.deployment.orgId!,
						projId: this.deployment.projId!,
						version: this.selectedVersion
					});
				}

				this.isDeployingApp = false;

				if (this.shouldRedirectToPipeline) {
					routerToAppPipeline(this.$router, this.deployment, DeploymentJobAction.deploy);
				}
				this.$emit("close");
			} catch (error) {
				captureError(error);
				this.isDeployingApp = false;
				this.submitError = getErrorMessage(error);
			}
		},

		getCurrentVersion(): promotedVersion[] {
			return this.promotableVersions[this.deployment.id as string] ?? [];
		},

		setVersion(val: string) {
			this.selectedVersion = val;
			if (val !== "") {
				this.isVersionSelected = true;
			} else {
				this.isVersionSelected = false;
			}
		},

		handlePipelineConfig(pipelineConfig: DeploymentPipelineConfigurationEvent) {
			this.updatedPipelineConfig = pipelineConfig;
		},

		async getPromotableVersion() {
			if (
				this.deployment.envId &&
				this.deployment.id &&
				this.deployment.orgId &&
				this.deployment.projId &&
				this.deployment.appId
			) {
				await codePromotionStore.GET_PROMOTABLE_VERSIONS({
					envId: this.deployment.envId,
					id: this.deployment.id,
					orgId: this.deployment.orgId,
					projId: this.deployment.projId,
					appId: this.deployment.appId
				});
			}
		}
	}
});
</script>
