/* eslint-disable @typescript-eslint/no-this-alias */
/**
 * This data handler is dedicated to handle the real-time updates for env pipeline only.
 * The env pipeline data is currently shown on 3 different routes in the application.
 * 1. Project landing page where we see all the envs, we can expand the env rows and
 * see the real-time updates for that env.
 * 2. Env detailed view, here we see the real-time updates only for the selected env. The real-time
 * updates show will be for all the 3 types of pipeline => Validate, Deploy & Destroy.
 * 3. Stage view, here we will see real-time updates for one type of pipeline at a time.
 */

// Import the Dispatcher service which talks to the grpc-server
import { ClientReadableStream } from "grpc-web";

import { applicationDeploymentStore } from "@/modules/application-deployment/application-deployment-store";
import { applicationIntegrationStore } from "@/modules/application-integration/application-integration-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { newEnvPipelineStore } from "@/modules/env-pipeline/env-pipeline-store";
import { EnvPipelineViewedJob } from "@/modules/env-pipeline/env-pipeline-types";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { jobResponse } from "@/protocol/deployment";
import { jobLongResponse } from "@/protocol/infra";
import DispatcherService from "@/shared/grpc-setup/DispatcherService";
import { captureError } from "@/utils";

import {
	JOB_STATUS,
	PIPELINE_FINISHED_JOB_STATUSES,
	PipelineJobStatus
} from "../pipeline-constants";

import { APP_PIPELINE_ACTIONS, ENV_PIPELINE_ACTIONS, JOB_UPDATED } from "./contants";

let instance: RealtimeDataHandler | null = null;
export interface StreamEventObject {
	eventname: string;
	eventdata: string;
}
export type onStreamFn = (streamResponse: StreamEventObject, cancel: () => void) => void;
export type StreamError = (_error: Error) => void;

export default class RealtimeDataHandler {
	orgId!: string;
	projectId!: string;

	envId?: string;
	jobId?: string;
	depId?: string;

	stream?: ClientReadableStream<unknown>;

	constructor({
		orgId,
		projectId,
		envId,
		jobId,
		depId
	}: {
		orgId: string;
		projectId: string;
		envId?: string;
		jobId?: string;
		depId?: string;
	}) {
		if (instance) {
			return instance;
		}

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		instance = this;
		this.orgId = orgId;
		this.envId = envId;
		this.jobId = jobId;
		this.depId = depId;
		this.projectId = projectId;
		this.generateResourceIdAndInit(undefined);

		return instance;
	}

	generateResourceIdAndInit(jobId: string | undefined): void {
		if (jobId) {
			this.jobId = jobId;
		}

		// Realtime updates are passed from org level down to the job level
		let resourceId = `organizations/${this.orgId}/projects/${this.projectId}`;

		if (this.envId && !this.jobId) {
			resourceId += `/envs/${this.envId}`;
		} else if (this.jobId && !this.depId) {
			resourceId += `/envs/${this.envId}/jobs/${this.jobId}`;
		} else if (this.jobId && this.depId) {
			resourceId += `/envs/${this.envId}/deployments/${this.depId}/jobs/${this.jobId}`;
		}

		this.initRealtimeData(resourceId);
	}

	initRealtimeData(resourceId: string) {
		if (resourceId && instance) {
			instance.subscribeResourceEvents(
				resourceId,
				this.pipelineStreamHandler.bind(this),
				this.eventErrorHandler
			);
		}
	}

	subscribeResourceEvents(resourceId: string, onStream: onStreamFn, OnError: StreamError) {
		const ds = new DispatcherService();
		this.stream = ds.subscribeEvents(
			resourceId,
			onStream,
			OnError
		) as ClientReadableStream<unknown>;
	}

	setJobAndStepDetails(job: jobResponse | jobLongResponse, envId: string) {
		if ("action" in job && job.action && ENV_PIPELINE_ACTIONS.includes(job.action)) {
			const env = envListStore.envList.find(env_ => env_.id === envId);

			if (!env) {
				captureError(new Error(`Environment not found for push env: ${envId}`));
				return;
			}

			const envPipelineJob: EnvPipelineViewedJob = {
				...job,
				envId,
				orgId: env.orgId,
				projId: env.projId
			};

			// push to respective pipeline jobs
			newEnvPipelineStore.SET_JOB_FOR_PIPELINE(envPipelineJob);

			// Update steps for job
			newEnvPipelineStore.UPDATE_STEPS_FOR_JOB(envPipelineJob);

			// Push messages don't return the full job response, so we fetch it again once it's marked as done
			if (PIPELINE_FINISHED_JOB_STATUSES.includes(envPipelineJob.status as PipelineJobStatus)) {
				// Update the job
				newEnvPipelineStore.LIST_JOB_FOR_ENV({
					jobId: envPipelineJob.id,
					envId,
					orgId: env.orgId,
					projId: env.projId
				});

				// Update the environment
				envListStore.GET_ENV_BY_ID({
					envId,
					orgId: env.orgId,
					projectId: env.projId
				});
			}

			// Send the toast for env deployment success or failure
			this.showEnvPipelineCompleteNotification({ ...envPipelineJob, envName: env.name });
		} else if ("jobType" in job && APP_PIPELINE_ACTIONS.includes(job.jobType)) {
			const deployment = applicationDeploymentStore.allDeployments[job.depId];

			if (deployment) {
				// Fetch the deployment and it's status when the job is finished so that the UI is always correct
				if (PIPELINE_FINISHED_JOB_STATUSES.includes(job.jobStatus as PipelineJobStatus)) {
					applicationDeploymentStore.GET_APP_DEPLOYMENT({
						envId: deployment.envId!,
						orgId: deployment.orgId!,
						projId: deployment.projId!,
						id: deployment.id!
					});
				}

				applicationDeploymentStore.SET_APP_JOB({
					...job,
					appId: deployment.appId,
					envId
				});

				this.showAppPipelineNotification(job, "deployment");
			}
		}
	}

	pipelineStreamHandler(streamResponse: StreamEventObject) {
		const { eventname, eventdata } = streamResponse;
		const parsedEvent = JSON.parse(eventdata);

		const job = parsedEvent.view || null;
		const env = parsedEvent.environment || null;
		if (job && env.id && eventname === JOB_UPDATED) {
			// Set job details, initialize job stages
			this.setJobAndStepDetails(job, env.id as string);
		} else if (parsedEvent.integrationId && eventname === JOB_UPDATED) {
			/**
			 * For App integration the parsedEvent.view is always null.
			 * parseEvent object itself is the job object, therefore we check for .integrationId in the object
			 * to verify if this job is an app int pipeline job.
			 * We show the notification only for Done/Failed.
			 * Start notification is already shown from the component.
			 */
			applicationIntegrationStore.SET_INTEGRATION_JOB({
				integrationJob: parsedEvent,
				jobId: parsedEvent.id
			});

			// Also update the job in the job list for the current integrationID
			const allJobs = applicationIntegrationStore.integrationJobList[parsedEvent.integrationId];
			if (allJobs !== undefined) {
				applicationIntegrationStore.SET_INTEGRATION_JOBS({
					integrationId: parsedEvent.integrationId,
					integrationJobs: allJobs.filter(job_ => job_.id !== parsedEvent.id).concat(parsedEvent)
				});
			}
			this.showAppPipelineNotification(parsedEvent, "integration");
		}
	}

	deleteInstance() {
		if (instance) {
			instance.unsubscribeResourceEvents();
			instance = null;
		}
	}

	eventErrorHandler(error: Error) {
		/**
		 * This method will handle the errors occured in the event updates..
		 */
		if (error.message === "stream timeout" && instance) {
			instance.generateResourceIdAndInit(undefined);
		}
	}

	unsubscribeResourceEvents() {
		if (this.stream) {
			this.stream.cancel();
		}
	}

	showAppPipelineNotification(job: jobResponse, jobType: "deployment" | "integration") {
		switch (job.jobStatus) {
			case JOB_STATUS.DONE: {
				notificationsStore.ADD_TOAST({
					qaId: `app-${jobType}-pipeline-toast-success`,
					title: `App ${jobType} pipeline complete`,
					text: `App ${jobType} pipeline with jobId ${job.id} is complete`,
					status: "success"
				});
				break;
			}
			case JOB_STATUS.FAILED: {
				notificationsStore.ADD_TOAST({
					qaId: `app-${jobType}-pipeline-toast-failed`,
					title: `App ${jobType} pipeline failed`,
					text: `Error: ${job.message}`,
					status: "error"
				});
				break;
			}
			default:
				break;
		}
	}

	showEnvPipelineCompleteNotification(job: EnvPipelineViewedJob & { envName: string }) {
		switch (job.status) {
			case JOB_STATUS.DONE: {
				notificationsStore.ADD_TOAST({
					qaId: `env-pipeline-toast-success`,
					title: `Env ${job.envName} pipeline complete`,
					text: `Env deployment is complete with job id ${job.id}`,
					status: "success"
				});
				break;
			}
			case JOB_STATUS.FAILED: {
				notificationsStore.ADD_TOAST({
					qaId: `Env-pipeline-toast-failed`,
					title: `Env ${job.envName} pipeline failed`,
					text: `Error: ${job.message}`,
					status: "error"
				});
				break;
			}
			default:
				break;
		}
	}
}
