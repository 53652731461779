<template>
	<EmptyState :icon="emptyIcon" :message="screenHeading" shape="none" data-qa-creds-empty-screen>
		<Wrapper width="400px" max-height="150px" class="overflow-visible">
			<Container
				direction="column"
				:padding="0"
				:gap="24"
				class="align-items-center"
				overflow="visible"
			>
				<Typography
					type="p1"
					color="light"
					class="text-align-center"
					data-qa-creds-empty-screen-title-1
				>
					{{ screenTitle1 }}
				</Typography>
				<Typography
					v-if="!project"
					type="p1"
					color="light"
					class="text-align-center"
					data-qa-creds-empty-screen-title-2
				>
					{{ screenTitle2 }}
				</Typography>
				<AddNewCred v-if="!project" :btn-txt="addNewBtnTxt" git-form-ref="emptyStateGitForm" />

				<PlaceholderAssignCredentialIcons v-if="project" :project="project" entity-type="project" />
			</Container>
		</Wrapper>
	</EmptyState>
</template>

<script lang="ts">
import { Container, EmptyState, EmptyStateIconProp, Typography, Wrapper } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import PlaceholderAssignCredentialIcons from "@/modules/credentials/components/credential-assign/PlaceholderAssignCredentialIcons.vue";
import AddNewCred from "@/modules/credentials/components/credential-form/AddNewCred.vue";
import {
	EMPTY_SCREEN_ADD_NEW_TEXT,
	EMPTY_SCREEN_HEADING,
	EMPTY_SCREEN_TITLE1,
	EMPTY_SCREEN_TITLE2,
	EMPTY_SCREEN_TITLE_PROJECT_PART1,
	EMPTY_SCREEN_TITLE_PROJECT_PART2
} from "@/modules/credentials/constants";
import { project } from "@/protocol/identity";

export default defineComponent({
	name: "EmptyCredentialsView",

	components: {
		PlaceholderAssignCredentialIcons,
		EmptyState,
		Wrapper,
		Container,
		Typography,
		AddNewCred
	},

	props: {
		project: {
			type: Object as PropType<project>
		}
	},

	data: () => ({
		emptyIcon: {
			name: "i-key",
			type: "filled",
			state: "light",
			size: "xx-large"
		} as EmptyStateIconProp
	}),

	computed: {
		screenHeading(): string {
			return EMPTY_SCREEN_HEADING;
		},

		screenTitle1(): string {
			if (this.project) {
				return `${EMPTY_SCREEN_TITLE_PROJECT_PART1} ${EMPTY_SCREEN_TITLE_PROJECT_PART2}`;
			}
			return EMPTY_SCREEN_TITLE1;
		},

		screenTitle2(): string {
			return EMPTY_SCREEN_TITLE2;
		},

		addNewBtnTxt(): string {
			return EMPTY_SCREEN_ADD_NEW_TEXT;
		}
	}
});
</script>
