<template>
	<SlideoutContainer size="large">
		<Slideout>
			<template #header>
				<Slab size="medium">
					<template #primary-action>
						<Icon
							size="small"
							type="outlined"
							state="dark"
							name="i-arrow-left"
							data-qa="env-set-slideout-back"
							@click="$emit('close')"
						/>
					</template>
					Create environment set
				</Slab>
			</template>
			<Container
				:padding="0"
				overflow="visible"
				align="stretch top"
				class="width-100-per"
				direction="column"
				:gap="150"
			>
				<CreateEnvSetHeader
					:templates="envSetTemplates"
					:is-loading-templates="isLoadingTemplates"
					:is-creating-env-set="isCreatingEnvSet"
					@on-create-env-set="createEnvSet"
					@on-template-select="onTemplateSelect"
				/>
				<EnvSetElements
					:classifications="envSetClassifications"
					:is-loading="isLoadingClassifications"
				/>
			</Container>
		</Slideout>
	</SlideoutContainer>
</template>
<script lang="ts">
import { Container, Icon, Slab, Slideout, SlideoutContainer } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

import { codePromotionStore } from "@/modules/code-promotion/code-promotion-store";
import { envListStore } from "@/modules/env-list/env-list-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { template } from "@/protocol/infra";
import { captureError, getErrorMessage } from "@/utils";

import { envCreateStore } from "../env-create-store";

import CreateEnvSetHeader from "./CreateEnvSetHeader.vue";
import EnvSetElements from "./EnvSetElements.vue";

export default defineComponent({
	name: "CreateEnvSetSlideout",

	components: {
		CreateEnvSetHeader,
		SlideoutContainer,
		EnvSetElements,
		Container,
		Slideout,
		Icon,
		Slab
	},

	props: {
		projId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		selectedTemplate: null as template | null,
		isCreatingEnvSet: false,
		submitError: null as string | null
	}),

	computed: {
		orgId() {
			return this.$route.params.orgId as string;
		},

		envSetTemplates() {
			return envCreateStore.envSetTemplates;
		},

		envSetClassifications() {
			return envCreateStore.envSetClassifications;
		},

		isLoadingTemplates() {
			return envCreateStore.isLoadingTemplates;
		},

		isLoadingClassifications() {
			return envCreateStore.isLoadingClassifications;
		}
	},

	mounted() {
		this.getEnvSets();
	},

	methods: {
		async getEnvSets() {
			await Promise.all([
				envCreateStore.GET_ORG_ENV_SET_TEMPLATES({
					orgId: this.orgId
				}),
				envCreateStore.GET_ORG_ENV_SET_CLASSIFICATIONS({
					orgId: this.orgId
				})
			]);
		},

		async createEnvSet() {
			if (!this.selectedTemplate || this.isCreatingEnvSet) {
				return;
			}

			this.isCreatingEnvSet = true;

			try {
				await envCreateStore.CREATE_ENV_FROM_TEMPLATE({
					orgId: this.orgId,
					projId: this.projId,
					templateId: this.selectedTemplate.id,
					classifications: this.envSetClassifications
				});

				await Promise.all([
					envListStore.GET_ENVS({
						orgId: this.orgId,
						projectId: this.projId
					}),
					codePromotionStore.GET_PROJ_PROMOTION_SEQUENCE({
						orgId: this.orgId,
						projId: this.projId
					})
				]);

				const envLength = this.envSetClassifications.length;
				const envSetNames = this.envSetClassifications.map(c => c.name);
				let envNamesStr = "";

				if (envLength > 2) {
					const lastEnvName = envSetNames[envSetNames.length - 1];
					envSetNames.splice(envSetNames.length - 1, 1);
					envNamesStr = `${envSetNames.join(", ")} and ${lastEnvName}`;
				} else {
					envNamesStr = `${envSetNames}`;
				}

				notificationsStore.ADD_TOAST({
					status: "info",
					title: "Creating environment set",
					text: `As part of the set, ${envNamesStr} environment${
						envLength > 2 ? "s" : ""
					} will be created.`,
					subText: "Just now",
					qaId: "env-set-created"
				});

				this.isCreatingEnvSet = false;
				this.$emit("close");
			} catch (err) {
				captureError(err);
				this.submitError = getErrorMessage(err, true);
				notificationsStore.ADD_TOAST({
					status: "error",
					title: "Error while creating environment set",
					text: this.submitError,
					qaId: "error-env-set"
				});
			}
		},

		onTemplateSelect(selectedOption: template) {
			this.selectedTemplate = selectedOption;
		}
	}
});
</script>
