<template>
	<f-popover
		:target="target"
		:open="true"
		:auto-height="true"
		size="custom(500px, 100vh)"
		placement="right"
		:style="{
			width: '45% !important',
			left: 'unset',
			right: '0px !important',
			height: '100vh !important'
		}"
	>
		<f-div
			padding="large"
			height="52px"
			gap="large"
			state="secondary"
			width="100%"
			align="middle-center"
		>
			<f-text
				variant="heading"
				size="small"
				weight="medium"
				data-qa-pipeline-advance-config-modal-title
			>
				{{ isEditMode ? pipeline?.name : "Add new pipeline" }}
			</f-text>
			<f-div align="middle-right" gap="small" width="30%" height="hug-content">
				<f-button
					v-if="!isEditMode"
					:disabled="!pipelineName"
					size="small"
					label="Add pipeline"
					@click="createNewPipeline"
				></f-button>
				<f-icon
					source="i-close"
					size="small"
					class="cursor-pointer"
					data-qa-app-connect-close-btn
					@click="$emit('close')"
				>
				</f-icon>
			</f-div>
		</f-div>

		<f-divider direction="horizontal" height="fill-container" size="large" state="secondary" />

		<f-div
			padding="large"
			state="secondary"
			gap="large"
			height="100%"
			direction="column"
			align="top-center"
		>
			<!-- pipeline name in add new mode -->
			<f-div
				v-if="!isEditMode"
				state="secondary"
				height="hug-content"
				width="100%"
				direction="column"
				gap="small"
			>
				<f-text variant="para" size="small" weight="regular">Pipeline Name</f-text>
				<f-input v-model="pipelineName" placeholder="Enter pipeline name" />
			</f-div>

			<f-div
				v-if="!isEditMode"
				state="secondary"
				height="hug-content"
				width="100%"
				direction="column"
				style="margin-top: 10px"
			>
				<f-divider direction="horizontal" height="fill-container" size="medium" state="secondary" />
			</f-div>

			<f-div
				direction="column"
				state="secondary"
				width="100%"
				height="hug-content"
				padding="medium none"
				gap="medium"
			>
				<f-div
					direction="row"
					align="middle-left"
					height="hug-content"
					width="hug-content"
					gap="medium"
				>
					<f-text size="x-small" variant="para" weight="medium" state="secondary"
						>PIPELINE MODULES</f-text
					>
					<f-icon-button
						category="fill"
						icon="i-plus"
						state="primary"
						variant="round"
						size="x-small"
						@click.stop="openAddModuleOption"
					/>
				</f-div>

				<!-- Add new modules START -->
				<f-div
					v-if="showAddModuleOption"
					state="secondary"
					height="hug-content"
					width="100%"
					gap="small"
					align="middle-center"
					direction="column"
				>
					<f-div
						direction="row"
						width="100%"
						height="hug-content"
						gap="small"
						align="middle-center"
					>
						<!-- Dropdowns to select category and module to add & edit -->
						<f-div padding="none" align="middle-left">
							<f-select
								v-model="selectedCategory"
								type="single"
								placeholder="Select module category"
								:searchable="true"
								:options="categoryOptions"
							>
								<f-div slot="label" padding="none" gap="none">Select module category</f-div>
							</f-select>
						</f-div>
						<f-div padding="none">
							<f-select
								v-model="selectedModule"
								:disabled="!selectedCategory"
								type="single"
								placeholder="select pipeline module"
								:searchable="true"
								:options="moduleOptions"
							>
								<f-div slot="label" padding="none" gap="none">Select pipeline module</f-div>
							</f-select>
						</f-div>
					</f-div>
					<f-divider v-if="selectedModule" style="margin-top: 10px" variant="dashed"></f-divider>
					<!-- Module information & edit functionality -->
					<f-div v-if="selectedModule !== null" direction="column" width="100%">
						<f-div height="hug-content" width="100%" direction="column" gap="medium" padding="none">
							<!-- below form will be shown when the user clicks on edit module icon -->
							<JSONSchemaFormBuilder2
								:key="selectedModule.title"
								overflow="visible"
								:fields="formFields[selectedModule.title + pipeline.modules.length]!"
								:style="{ width: '100%' }"
								@info="e => handleFieldInfo(e, selectedModule!.title + pipeline.modules.length)"
							/>
							<f-div width="100%" align="middle-left" gap="small">
								<f-button
									category="fill"
									state="primary"
									label="Save Module"
									size="small"
									@click.stop="addModule()"
								></f-button>
							</f-div>
						</f-div>
					</f-div>
				</f-div>
				<!-- Add new modules END -->
				<DragDropList
					:key="dragKey"
					item-key="name"
					:list="pipelineModules"
					data-qa-pipeline-module-drag-component
					:style="{
						width: '100%'
					}"
					@drag-end="dragEnd"
				>
					<template #default="{ element, index }">
						<f-div :data-qa-pipeline-module-item="element.name + index" align="middle-left">
							<Icon
								size="small"
								type="filled"
								name="i-drag-vertical"
								class="handle"
								:data-qa-pipeline-module-drag-icon="element.name + index"
							/>
							<f-div
								tooltip="Module configuration"
								direction="column"
								state="secondary"
								padding="none medium"
								:data-qa-pipeline-module-info-text="element.name + index"
							>
								<f-accordion
									:key="element.order"
									icon-size="x-small"
									icon-placement="right"
									header-padding="none"
									:open="openFlags[element.name + element.order]"
									@toggle="e => (openFlags[element.name + element.order] = e.detail.value)"
								>
									<f-div gap="medium" align="middle-left" padding="large none">
										<f-icon size="small" type="filled" :source="element.icon" />
										<f-text variant="para" size="small" weight="bold">{{ element.name }}</f-text>
										<f-icon
											v-if="!editFlags[element.name + element.order]"
											source="i-edit"
											state="primary"
											size="x-small"
											align="right"
											@click.stop="toggleEdit(element.name + element.order)"
										></f-icon>
										<f-icon
											source="i-delete"
											state="danger"
											size="x-small"
											align="right"
											@click.stop="deleteModule(element.name, element.order)"
										></f-icon>
										<f-button
											v-if="editFlags[element.name + element.order]"
											category="outline"
											state="primary"
											label="Save"
											size="small"
											align="right"
											@click.stop="saveModule(element.name, element.order)"
										></f-button>
									</f-div>
									<!-- Edit Pipeline Module -->
									<f-div slot="body" direction="column">
										<f-div
											height="hug-content"
											width="100%"
											direction="column"
											gap="medium"
											padding="none none small small"
										>
											<!-- below form will be shown when the user clicks on edit module icon -->
											<f-div
												v-if="
													editFlags[element.name + element.order] &&
													formFields[element.name + element.order]
												"
												width="100%"
												align="middle-left"
											>
												<JSONSchemaFormBuilder2
													:key="element.name"
													overflow="visible"
													:fields="formFields[element.name + element.order]!"
													:default-values="defaultValues[element.name + element.order] ?? undefined"
													:style="{
														width: '100%'
													}"
													@info="e => handleFieldInfo(e, element.name + element.order)"
												/>
											</f-div>
											<Table
												v-else
												aria-label="service table"
												border="none"
												:style="{ width: '100%' }"
											>
												<template v-if="readOnlyValues[element.name + element.order]" #body>
													<TableRow
														v-for="(key, i) in Object.keys(
															readOnlyValues[element.name + element.order]
														)"
														:key="i"
														width="100%"
														style="margin-bottom: 10px"
													>
														<TableCell width="50%" vertical-align="top" padding="small">
															<f-text
																variant="para"
																state="secondary"
																weight="regular"
																size="small"
																>{{ key.toLocaleUpperCase() }}</f-text
															>
														</TableCell>
														<TableCell vertical-align="top" width="50%" style="background: #344356">
															<f-text variant="code" color="default" size="small" weight="regular">
																{{ readOnlyValues[element.name + element.order][key] ?? "-" }}
															</f-text>
														</TableCell>
													</TableRow>
												</template>
											</Table>
										</f-div>
									</f-div>
								</f-accordion>
								<f-divider
									v-if="index !== pipelineModules.length - 1"
									direction="horizontal"
									height="fill-container"
									size="medium"
								/>
							</f-div>
						</f-div>
					</template>
				</DragDropList>
			</f-div>

			<f-div
				state="secondary"
				height="hug-content"
				width="100%"
				direction="column"
				style="margin-top: 5px"
			>
				<f-divider direction="horizontal" height="fill-container" size="medium" state="secondary" />
			</f-div>
			<!-- Pipeline variables -->
			<f-div state="secondary" height="hug-content" width="100%" direction="column">
				<f-div
					direction="row"
					align="middle-left"
					height="hug-content"
					width="hug-content"
					gap="medium"
				>
					<f-icon v-if="isEditMode" source="i-variable" size="small"></f-icon>
					<f-text size="x-small" variant="para" weight="medium" state="secondary"
						>PIPELINE VARIABLES</f-text
					>
					<f-icon-button
						category="fill"
						icon="i-plus"
						state="primary"
						variant="round"
						size="x-small"
						@click.stop="showAddVarForm = !showAddVarForm"
					/>
				</f-div>
				<f-div direction="column" width="100%" class="width-100-per">
					<f-div
						v-if="showAddVarForm"
						height="hug-content"
						direction="column"
						gap="medium"
						padding="medium none medium large"
					>
						<AddVariableKeyValueForm
							v-model:variable="currentVariable"
							:added-variables="[variableScope]"
							:selected-variable-scopes="[variableScope]"
							:store-variable-scopes="storeVariableScopes"
							:hide-variable-type="true"
							@validity-change="isVariableFormValid = $event"
						/>

						<f-button
							:style="{ width: '20%' }"
							label="Add"
							data-qa-id="add-to-list"
							size="small"
							:disabled="!isVariableFormValid"
							@click="onAddVariable"
						></f-button>
					</f-div>
					<f-div padding="none none medium large">
						<ScopeVariablesAccordion
							:key="variableScope.id"
							v-model:scope="variableScope"
							:added-variables="[variableScope]"
							:hide-variable-type="true"
						/>
					</f-div>
				</f-div>
				<f-divider direction="horizontal" height="fill-container" size="medium" state="secondary" />
			</f-div>

			<!-- Pipeline Triggers -->
			<f-div state="secondary" height="hug-content" width="100%" direction="column">
				<f-div
					direction="row"
					align="middle-left"
					height="hug-content"
					width="hug-content"
					gap="medium"
				>
					<f-icon v-if="isEditMode" source="i-variable" size="small"></f-icon>
					<f-text size="x-small" variant="para" weight="medium" state="secondary"
						>PIPELINE TRIGGER</f-text
					>
				</f-div>
				<f-div direction="column" width="100%" class="width-100-per">
					<f-div
						height="hug-content"
						direction="column"
						gap="medium"
						padding="medium none medium none"
					>
						<f-div
							height="hug-content"
							gap="large"
							state="secondary"
							width="100%"
							direction="row"
							align="middle-center"
						>
							<f-div align="middle-left" direction="row" gap="medium">
								<f-text size="medium" inline>Run on</f-text>
								<f-select
									v-model="githubTriggerEvent"
									placeholder="select"
									:options="triggerOptions"
								></f-select>
								<f-text size="medium" inline>to</f-text>
							</f-div>
							<f-div align="middle-right" direction="row" gap="medium">
								<f-div width="75%">
									<f-input v-model="triggerIdentifier" placeholder="Enter identifier"></f-input>
								</f-div>
								<f-text size="medium" align="right">branch.</f-text>
							</f-div>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
	</f-popover>
</template>
<script lang="ts">
import { DragDropList, Icon, Table, TableCell, TableRow } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { filterModulesByProvisioner } from "@/modules/application-integration/application-integration-store";
import { PipelineModuleMeta } from "@/modules/application-integration/components/AddIntegrationPipelineWrapper.vue";
import { getInitialFormValue } from "@/modules/application-integration/components/IntegrationVariableContent.vue";
import { ModuleItems } from "@/modules/application-integration/components/SelectArtifactModulePopover.vue";
import { userStore } from "@/modules/user/user-store";
import AddVariableKeyValueForm from "@/modules/variables-list/AddVariableKeyValueForm.vue";
import ScopeVariablesAccordion from "@/modules/variables-list/ScopeVariablesAccordion.vue";
import { AddVariableScope } from "@/modules/variables-list/variable-list-types";
import { variablesListStore } from "@/modules/variables-list/variables-list-store";
import { JSONSchema } from "@/protocol/common";
import { triggerEvent } from "@/protocol/externalEvents";
import JSONSchemaFormBuilder2 from "@/shared/components/JSONSchemaFormBuilder2.vue";
import { TRIGGER_EVENT_TO_STRING } from "@/shared/constants";
import { pipelineModuleWithRefId } from "@/shared/pipeline-constants";
import { getProvisionerIcon, getUniqueId, getUniqueStringId, JSONSchemaToObject } from "@/utils";

import { IntegrationObj, WebhookTriggerEvent } from "./AddAppBuildSetupStep.vue";

export type CategoryOption = {
	title: string;
	data: {
		id: string;
		modules: pipelineModuleWithRefId[];
	};
};

export type ModuleOption = {
	title: string;
	data: {
		id: string;
		module: pipelineModuleWithRefId;
	};
};

export default defineComponent({
	name: "PipelineModuleAdvanceConfig",

	components: {
		DragDropList,
		Icon,
		Table,
		TableRow,
		TableCell,
		JSONSchemaFormBuilder2,
		AddVariableKeyValueForm,
		ScopeVariablesAccordion
	},

	props: {
		target: {
			type: String,
			required: true
		},

		pipeline: {
			type: Object as PropType<IntegrationObj>,
			required: true
		},

		appId: {
			type: String as PropType<string | undefined>,
			required: true
		},

		isEditMode: {
			type: Boolean as PropType<boolean>,
			required: true
		}
	},

	emits: [
		"close",
		"save-module",
		"variable-change",
		"add-module",
		"create-new-pipeline",
		"set-modules",
		"update-triggers"
	],

	data: () => ({
		dragKey: 0,
		editFlags: {} as Record<string, boolean>,
		formFields: {} as Record<string, JSONSchema>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		defaultValues: {} as Record<string, any>,
		formValues: {} as Record<string, JSONSchema>,

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		readOnlyValues: {} as Record<string, any>,
		openFlags: {} as Record<string, boolean>,
		showAddVarForm: false,
		currentVariable: getInitialFormValue(),

		variableScope: {
			id: getUniqueStringId(),
			name: "variable list",
			variableFrom: "application-integration",
			variables: []
		} as AddVariableScope,

		isVariableFormValid: false,

		selectedCategory: null as CategoryOption | null,
		selectedModule: null as ModuleOption | null,

		showAddModuleOption: false,
		pipelineName: "",

		triggerOptions: [
			{
				data: { id: triggerEvent.pullReq },
				title: TRIGGER_EVENT_TO_STRING[triggerEvent.pullReq]()
			},
			{
				data: { id: triggerEvent.pushToBranch },
				title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToBranch]()
			},
			{
				data: { id: triggerEvent.pushToTag },
				title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToTag]()
			}
		],

		githubTriggerEvent: {
			data: { id: triggerEvent.pushToBranch },
			title: TRIGGER_EVENT_TO_STRING[triggerEvent.pushToBranch]()
		} as WebhookTriggerEvent,

		triggerIdentifier: ""
	}),

	computed: {
		storeVariableScopes() {
			return variablesListStore.variableScopes;
		},

		pipelineModules(): PipelineModuleMeta[] {
			if (!this.pipeline) {
				return [];
			}

			return this.pipeline.modules.map((module, index) => {
				return {
					data: module.data,
					desc: module.data?.description ?? "",
					icon: module.data ? getProvisionerIcon(module.data?.provisioner) : "",
					name: module.data?.name ?? "",
					moduleValues: module.data?.inputs,
					order: index
				};
			});
		},

		provisioner() {
			if (!this.appId) {
				return undefined;
			}
			return userStore.profile?.metadata?.appWizardMeta?.[this.appId]?.provisioner ?? undefined;
		},

		allModules(): ModuleItems {
			return filterModulesByProvisioner(undefined, this.provisioner);
		},

		categoryOptions() {
			return Object.keys(this.allModules).map(category => {
				return {
					title: category,
					data: {
						id: category,
						modules: this.allModules[category]
					}
				};
			});
		},

		moduleOptions() {
			if (!this.selectedCategory) {
				return [];
			}

			return this.allModules[this.selectedCategory.title]!.map(module_ => {
				return {
					title: module_.name!,
					data: {
						id: module_.name!,
						module: module_
					}
				};
			});
		}
	},

	watch: {
		variableScope: {
			handler() {
				this.$emit("variable-change", {
					variables: this.variableScope.variables,
					pipelineName: this.pipeline?.name ?? this.pipelineName
				});
			},

			deep: true
		},

		selectedModule: {
			deep: true,

			handler() {
				const pipelineModule = this.selectedModule?.data.module;
				const existingModules = this.pipeline.modules;
				if (pipelineModule?.name) {
					// we need to maintain the order
					this.formFields[pipelineModule.name + existingModules.length] = pipelineModule.inputs!;
				}
			}
		},

		selectedCategory: {
			deep: true,

			handler() {
				this.selectedModule = null;
			}
		},

		githubTriggerEvent: {
			deep: true,

			handler() {
				if (this.isEditMode) {
					this.$emit("update-triggers", {
						pipelineName: this.pipeline.name,
						identifier: this.triggerIdentifier,
						triggerEvent: this.githubTriggerEvent
					});
				}
			}
		},

		triggerIdentifier: {
			deep: true,

			handler() {
				if (this.isEditMode) {
					this.$emit("update-triggers", {
						pipelineName: this.pipeline.name,
						identifier: this.triggerIdentifier,
						githubTriggerEvent: this.githubTriggerEvent
					});
				}
			}
		}
	},

	mounted() {
		this.initializeFormValues();
	},

	methods: {
		initializeFormValues() {
			if (this.isEditMode && this.pipeline) {
				this.pipeline.modules.forEach(module => {
					if (module.data.name && module.data.inputs) {
						const keyName = module.data.name + module.order;
						this.editFlags[keyName] = false;
						this.formFields[keyName] = module.data.inputs;
						this.defaultValues[keyName] = module.data.inputs;

						// set read only values
						const fieldValues = JSONSchemaToObject(
							module.data.inputs,
							this.defaultValues[keyName]
						) as Record<string, unknown>;

						this.readOnlyValues[keyName] = Object.keys(fieldValues).reduce(
							(acc, key) => {
								if (fieldValues[key] !== undefined) {
									acc[key] = fieldValues[key];
								}
								return acc;
							},
							{} as Record<string, any>
						);
						// set accordion flags
						this.openFlags[keyName] = false;
					}
				});
				// set trigger values if any
				if (this.pipeline.identifier && this.pipeline.triggerEvent.data.id) {
					this.triggerIdentifier = this.pipeline.identifier;
					this.githubTriggerEvent = this.pipeline.triggerEvent;
				}
			}
		},

		dragEnd() {
			// generate random value for dragKey to force re-render
			this.dragKey = getUniqueId();
		},

		toggleEdit(moduleName: string) {
			this.editFlags[moduleName] = !this.editFlags[moduleName];
			// this.openFlags[moduleName] = !this.openFlags[moduleName];
			if (this.editFlags[moduleName]) {
				this.openFlags[moduleName] = true;
			}
		},

		handleFieldInfo({ inputs }: { inputs: JSONSchema }, name: string) {
			this.formValues[name] = inputs;
		},

		saveModule(moduleName: string, index: number) {
			const [module] = this.pipeline.modules.filter(
				(m, i) => m.data.name === moduleName && i === index
			);
			if (module) {
				this.$emit("save-module", {
					moduleRefId: module.moduleRefId,
					inputs: this.formValues[moduleName],
					order: module.order,
					identifier: this.triggerIdentifier,
					webhookTriggerEvent: this.githubTriggerEvent
				});

				this.defaultValues[moduleName + index] = this.formValues[moduleName + index]!;
				this.readOnlyValues[moduleName + index] = this.formValues[moduleName + index];
				this.editFlags[moduleName + index] = false;
			}
		},

		onAddVariable() {
			this.variableScope.variables = [...this.variableScope.variables, this.currentVariable];

			this.currentVariable = getInitialFormValue();
		},

		openAddModuleOption() {
			this.selectedCategory = null;
			this.selectedModule = null;
			this.showAddModuleOption = !this.showAddModuleOption;
		},

		addModule() {
			if (this.selectedModule) {
				const { module } = this.selectedModule.data;
				const order = this.pipeline?.modules.length;
				this.$emit("add-module", {
					icon: getProvisionerIcon(module.provisioner),
					name: module.name,
					data: module,
					moduleRefId: `${module.provisioner}:${module.name}@${module.version}`,
					inputs: this.formValues[module.name! + order],
					order
				});

				if (module.name) {
					this.defaultValues[module.name + order] = this.formValues[module.name + order]!;
					this.readOnlyValues[module.name + order] = this.formValues[module.name + order];
					this.editFlags[module.name + order] = false;
					this.openFlags = {
						...this.openFlags,
						[module.name + order]: true
					};
				}

				// reset the dropdowns
				this.selectedModule = null;
				this.selectedCategory = null;
				this.showAddModuleOption = false;
			}
		},

		createNewPipeline() {
			this.$emit("create-new-pipeline", {
				name: this.pipelineName,
				variables: this.variableScope.variables,
				modules: this.pipeline?.modules ?? [],
				webhook: {
					identifier: this.triggerIdentifier,
					triggerEvent: this.githubTriggerEvent
				}
			});
		},

		deleteModule(name: string, index: number) {
			delete this.formFields[name + index];
			delete this.formValues[name + index];
			delete this.readOnlyValues[name + index];
			delete this.openFlags[name + index];
			delete this.editFlags[name + index];
			const newModules = this.pipeline.modules.filter(
				(module, i) => module.data.name !== name && i !== index
			);
			this.$emit("set-modules", {
				modules: newModules,
				pipelineName: this.pipeline.name ?? this.pipelineName,
				isEditMode: this.isEditMode
			});
		}
	}
});
</script>
