// To update use the az CLI (brew install azure-cli jq && az login)
// Then run: az account list-locations | jq 'del(.[] .metadata, .[] .subscriptionId, .[] .id)' | pbcopy
export default [
	{
		header: "East US",
		id: "eastus"
	},
	{
		header: "East US 2",
		id: "eastus2"
	},
	{
		header: "South Central US",
		id: "southcentralus"
	},
	{
		header: "West US 2",
		id: "westus2"
	},
	{
		header: "West US 3",
		id: "westus3"
	},
	{
		header: "Australia East",
		id: "australiaeast"
	},
	{
		header: "Southeast Asia",
		id: "southeastasia"
	},
	{
		header: "North Europe",
		id: "northeurope"
	},
	{
		header: "Sweden Central",
		id: "swedencentral"
	},
	{
		header: "UK South",
		id: "uksouth"
	},
	{
		header: "West Europe",
		id: "westeurope"
	},
	{
		header: "Central US",
		id: "centralus"
	},
	{
		header: "North Central US",
		id: "northcentralus"
	},
	{
		header: "West US",
		id: "westus"
	},
	{
		header: "South Africa North",
		id: "southafricanorth"
	},
	{
		header: "Central India",
		id: "centralindia"
	},
	{
		header: "East Asia",
		id: "eastasia"
	},
	{
		header: "Japan East",
		id: "japaneast"
	},
	{
		header: "Jio India West",
		id: "jioindiawest"
	},
	{
		header: "Korea Central",
		id: "koreacentral"
	},
	{
		header: "Canada Central",
		id: "canadacentral"
	},
	{
		header: "France Central",
		id: "francecentral"
	},
	{
		header: "Germany West Central",
		id: "germanywestcentral"
	},
	{
		header: "Norway East",
		id: "norwayeast"
	},
	{
		header: "Switzerland North",
		id: "switzerlandnorth"
	},
	{
		header: "UAE North",
		id: "uaenorth"
	},
	{
		header: "Brazil South",
		id: "brazilsouth"
	},
	{
		header: "Central US (Stage)",
		id: "centralusstage"
	},
	{
		header: "East US (Stage)",
		id: "eastusstage"
	},
	{
		header: "East US 2 (Stage)",
		id: "eastus2stage"
	},
	{
		header: "North Central US (Stage)",
		id: "northcentralusstage"
	},
	{
		header: "South Central US (Stage)",
		id: "southcentralusstage"
	},
	{
		header: "West US (Stage)",
		id: "westusstage"
	},
	{
		header: "West US 2 (Stage)",
		id: "westus2stage"
	},
	{
		header: "Asia",
		id: "asia"
	},
	{
		header: "Asia Pacific",
		id: "asiapacific"
	},
	{
		header: "Australia",
		id: "australia"
	},
	{
		header: "Brazil",
		id: "brazil"
	},
	{
		header: "Canada",
		id: "canada"
	},
	{
		header: "Europe",
		id: "europe"
	},
	{
		header: "France",
		id: "france"
	},
	{
		header: "Germany",
		id: "germany"
	},
	{
		header: "Global",
		id: "global"
	},
	{
		header: "India",
		id: "india"
	},
	{
		header: "Japan",
		id: "japan"
	},
	{
		header: "Korea",
		id: "korea"
	},
	{
		header: "Norway",
		id: "norway"
	},
	{
		header: "South Africa",
		id: "southafrica"
	},
	{
		header: "Switzerland",
		id: "switzerland"
	},
	{
		header: "United Arab Emirates",
		id: "uae"
	},
	{
		header: "United Kingdom",
		id: "uk"
	},
	{
		header: "United States",
		id: "unitedstates"
	},
	{
		header: "United States EUAP",
		id: "unitedstateseuap"
	},
	{
		header: "East Asia (Stage)",
		id: "eastasiastage"
	},
	{
		header: "Southeast Asia (Stage)",
		id: "southeastasiastage"
	},
	{
		header: "Central US EUAP",
		id: "centraluseuap"
	},
	{
		header: "East US 2 EUAP",
		id: "eastus2euap"
	},
	{
		header: "West Central US",
		id: "westcentralus"
	},
	{
		header: "South Africa West",
		id: "southafricawest"
	},
	{
		header: "Australia Central",
		id: "australiacentral"
	},
	{
		header: "Australia Central 2",
		id: "australiacentral2"
	},
	{
		header: "Australia Southeast",
		id: "australiasoutheast"
	},
	{
		header: "Japan West",
		id: "japanwest"
	},
	{
		header: "Jio India Central",
		id: "jioindiacentral"
	},
	{
		header: "Korea South",
		id: "koreasouth"
	},
	{
		header: "South India",
		id: "southindia"
	},
	{
		header: "West India",
		id: "westindia"
	},
	{
		header: "Canada East",
		id: "canadaeast"
	},
	{
		header: "France South",
		id: "francesouth"
	},
	{
		header: "Germany North",
		id: "germanynorth"
	},
	{
		header: "Norway West",
		id: "norwaywest"
	},
	{
		header: "Switzerland West",
		id: "switzerlandwest"
	},
	{
		header: "UK West",
		id: "ukwest"
	},
	{
		header: "UAE Central",
		id: "uaecentral"
	},
	{
		header: "Brazil Southeast",
		id: "brazilsoutheast"
	}
];
