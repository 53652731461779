/* eslint-disable */
import { variable, VGEntities, EnvironmentId, sourceEnum } from "./common";
import { AppId } from "./app";
import { HealthCheckRequest, HealthCheckResponse } from "./health";
import {
	LoginRequest,
	LoginResponse,
	SignupRequest,
	AuthCodeRequest,
	AuthCodeResponse,
	InvitationRequest,
	ListInvitedUsersRequest,
	ListInvitedUsersResponse,
	ListUsersRequest,
	UserProfilesList,
	Email,
	UserPublicProfile,
	UserProfileUpdateRequest,
	UserProfile,
	UserRoleList,
	TokenRequest
} from "./user";

export enum orgEncryptionType {
	orgEncryptionType_not_defined = "orgEncryptionType_not_defined",
	orgEncryptionType_org_shared_key = "orgEncryptionType_org_shared_key",
	orgEncryptionType_org_private_key = "orgEncryptionType_org_private_key",
	orgEncryptionType_org_custom_key = "orgEncryptionType_org_custom_key"
}

export enum iacDriver {
	no_iac_driver_support = "no_iac_driver_support",
	terraform = "terraform"
}

export enum credsType {
	credsType_no_support = "credsType_no_support",
	credsType_github = "credsType_github",
	credsType_gcp = "credsType_gcp",
	credsType_aws = "credsType_aws",
	credsType_dockerhub = "credsType_dockerhub",
	credsType_gitlab = "credsType_gitlab",
	credsType_azure = "credsType_azure",
	credsType_dockergeneric = "credsType_dockergeneric",
	credsType_codecommit = "credsType_codecommit",
	credsType_gitgeneric = "credsType_gitgeneric",
	credsType_acr = "credsType_acr"
}

export enum CredScope {
	no_support = "no_support",
	git = "git",
	docker = "docker",
	cloud = "cloud"
}

export interface success {
	success?: boolean;
}

export interface orgId {
	// The orgId you got from POST Create Organization or GET List Organizations.
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field)":{"default":"2222"}}]
	id: string;
}

export interface orgCreate {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	metadata?: Record<string, unknown>;
	encryptionType?: orgEncryptionType;
}

export interface orgUpdate {
	// Id of the organization you want to update. You can get this id from GET List Organizations.
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field)":{"default":"2222"}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	metadata?: Record<string, unknown>;
}

export interface organization {
	// orgId of one of the organizations in your system.
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Name of the organization.
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	// Description of the organization.
	description?: string;
	// The role (permissions) that you have for this organization. For more information, see [Roles](https://docs.codepipes.io/docs/roles).
	role?: string;
	metadata?: Record<string, unknown>;
	encryptionType?: orgEncryptionType;
	// User ID or the last person who updated the organization.
	updatedBy?: string;
	// Date when created.
	createdAt?: string;
	// Date when updated.
	updatedAt?: string;
}

export interface organizations {
	orgs?: organization[];
}

export interface ProjectCreate {
	// Options: [{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field)":{"field_configuration":{"path_param_name":"orgId"},"description":"The Id of the organization that will own the project","default":"1bc19425-15fb-4d1f-a517-eced06442707"}},{"(validate.rules)":{"string":{"min_len":1}}}]
	orgId: string;
	// Name that you want to give the project.
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	// Description of the project.
	description?: string;
	// Repeated variable (list)
	//
	// Lets you set environment or Terraform variables for the project.<br><br>
	//
	// * If you are setting a TF variable, set type to `tf_var`.<br><br>
	// * If you are setting an environment value, set type to `env_var`.<br><br>
	//
	// By default, the variable values you set here (for the project) get passed down to the project's children:<br><br>
	//
	// * Environment<br><br>
	// * Application<br><br>
	// * Deployment<br><br>
	//
	// However, you can override these default project-set values by setting different values in the project's children.<br><br>
	variables?: variable[];
	// Policy Set ID
	//
	// ID of the default policy set for this project. Unless you override this setting in an environment that is a child of this project, Code Pipes uses this policy set to validate the Terraform file in the environment's associated repo. You can get the policySetId from GET List Policy Sets.<br><br>
	// For more information on policy sets, see [policies](https://docs.codepipes.io/docs/policies).
	policySetId?: string;
	metadata?: Record<string, unknown>;
}

export interface projectUpdate {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1,"max_len":64}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	description?: string;
	variables?: variable[];
	// Policy Set ID
	//
	// ID of the default policy set for this project. Unless you override this setting in an environment that is a child of this project, Code Pipes uses this policy set to validate the Terraform file in the environment's associated repo. You can get the policySetId from GET List Policy Sets.<br><br>
	// For more information on policy sets, see [policies](https://docs.codepipes.io/docs/policies).
	policySetId?: string;
	metadata?: Record<string, unknown>;
	// replaceVariables
	//
	// If false, the variables will not be updated. If true, all the existing
	// variables will be replaced with the given set.
	replaceVariables?: boolean;
}

export interface projectEntitiesDeleteRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	force?: boolean;
}

export interface projectGetReq {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Indicates if response should contain counts of associated users, applications and environments
	detailed?: boolean;
}

export interface project_entityCounts {
	users?: number;
	environments?: number;
	apps?: number;
}

export interface project {
	// ID of the project.
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// ID of the organization to which this project belongs.
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Name of the project.
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	// Description of the project.
	description?: string;
	variables?: variable[];
	// The default policy set for the project and its children. For more information, see [Policies](https://docs.codepipes.io/docs/policies).
	policySetId?: string;
	// The role that you have on this project. Values can be admin, editor, viewer, or limited. For more information, see [Roles](https://docs.codepipes.io/docs/roles).
	role?: string;
	// User ID of the last person to update the project.
	updatedBy?: string;
	// Date the project was created, expressed as an integer in epoch timestamp format. Example:  `1607065223`
	createdAt?: string;
	// Date the project was updated, expressed as an integer in epoch timestamp format. Example: `1607065223`
	updatedAt?: string;
	metadata?: Record<string, unknown>;
	// Read-only counts of the child entities. Only available if detailed flag is set in the request
	counts?: project_entityCounts;
	encryptedSecrets?: string;
}

export interface projects {
	projs?: project[];
}

export interface updateVariablesRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	variables?: variable[];
}

export interface deleteVariablesRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	variables?: variable[];
}

export interface listProjectRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Indicates if response should contain information about variables and counts of associated users, applications and environments
	detailed?: boolean;
}

export interface orgAccessGrant {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	userId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	role: string;
}

export interface orgAccessRevoke {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	userId: string;
}

export interface projAccessGrant {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	userId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	role: string;
}

export interface projAccessRevoke {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	projId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	userId: string;
}

export interface setNewPasswordRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":7}}}]
	password: string;
}

export interface resetPasswordRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":7}}}]
	password: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	code: string;
}

export interface signingRequest {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	path: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	action: string;
	// Options: [{"(validate.rules)":{"int64":{"lte":2000000}}}]
	size: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	type: string;
}

export interface signingResponse {
	URL?: string;
}

export interface incidentRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	summary: string;
	description?: string;
	artifacts?: string[];
	platform?: string;
	tags?: string[];
}

export interface ProjectId {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface CredsCreate {
	// name must be unique in the organization
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// deprecated. not used in update
	type?: credsType;
	github?: githubCreds;
	gcp?: gcpCreds;
	aws?: awsCreds;
	dockerhub?: dockerhubCreds;
	gitlab?: gitlabCreds;
	azure?: azureCreds;
	dockergeneric?: dockergenericCreds;
	gitgeneric?: gitgenericCreds;
	acr?: acrCreds;
	metadata?: Record<string, unknown>;
	// classificationId can not be updated once created.
	classificationId?: string;
}

export interface CredsUpdate {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// name must be unique in the organization
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// deprecated. not used in update
	type?: credsType;
	github?: githubCreds;
	gcp?: gcpCreds;
	aws?: awsCreds;
	dockerhub?: dockerhubCreds;
	gitlab?: gitlabCreds;
	azure?: azureCreds;
	dockergeneric?: dockergenericCreds;
	gitgeneric?: gitgenericCreds;
	acr?: acrCreds;
	// deprecated. not used in update
	displayId?: string;
	// deprecated. not used in update
	isNotSensitive?: boolean;
	// deprecated. not used in update
	extraInfo?: Record<string, unknown>;
	metadata?: Record<string, unknown>;
}

export interface Creds {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// dynamically derived from the cred object
	type?: credsType;
	github?: githubCreds;
	gcp?: gcpCreds;
	aws?: awsCreds;
	dockerhub?: dockerhubCreds;
	gitlab?: gitlabCreds;
	azure?: azureCreds;
	dockergeneric?: dockergenericCreds;
	gitgeneric?: gitgenericCreds;
	acr?: acrCreds;
	// dynamically derived from the cred object
	displayId?: string;
	// isNotSensitive is deprecated. because oneof creds already has knowledge of sensitive fields
	isNotSensitive?: boolean;
	// extraInfo is deprecated because the data here is redundent with oneof creds fields
	extraInfo?: Record<string, unknown>;
	metadata?: Record<string, unknown>;
	// credScope On cred list response, this will return all possible scopes.
	// On assignment response, this will return scopes that the cred is assigned with
	credScope?: CredScope[];
	// this is set only when querying the assignments and the cred is inherited from a parent entity
	inheritedFrom?: VGEntities;
	createdAt?: string;
	updatedAt?: string;
	updatedBy?: string;
	classificationId?: string;
	encryptedSecrets?: string;
	// entities where cred is assigned to
	entities?: AssignedCredEntity[];
}

export interface CredsList {
	creds?: Creds[];
}

export interface DeleteCredsRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface AppliedCredsRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	project?: ProjectId;
	environment?: EnvironmentId;
	app?: AppId;
}

export interface GetScopeAppliedCredRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	project?: ProjectId;
	environment?: EnvironmentId;
	app?: AppId;
	// Options: [{"(validate.rules)":{"enum":{"defined_only":true,"not_in":[0]}}}]
	scope: CredScope;
}

export interface CredsAssignment {
	// Array of credentials id
	creds?: string[];
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	project?: ProjectId;
	environment?: EnvironmentId;
	app?: AppId;
}

export interface credDetails {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	credId: string;
	// Options: [{"(validate.rules)":{"repeated":{"min_items":1}}}]
	credScope: CredScope[];
}

export interface CredsAssignmentRequest {
	// Options: [{"(validate.rules)":{"repeated":{"min_items":1}}}]
	creds: credDetails[];
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	project?: ProjectId;
	environment?: EnvironmentId;
	app?: AppId;
}

export interface credDetailsOptionalScope {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	credId: string;
	credScope?: CredScope[];
}

export interface CredsUnassignmentRequest {
	// Options: [{"(validate.rules)":{"repeated":{"min_items":1}}}]
	creds: credDetailsOptionalScope[];
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	project?: ProjectId;
	environment?: EnvironmentId;
	app?: AppId;
}

export interface CanChangeCredsOnEntityRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	project?: ProjectId;
	environment?: EnvironmentId;
	app?: AppId;
}

export interface githubPatOption {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	username: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	password: string;
}

export interface githubCreds {
	githubPatOption?: githubPatOption;
	githubOAuthOption?: githubOAuthOption;
	// read only
	OAuth?: boolean;
}

export interface githubOAuthOption {
	// [SECRET-VALUE]
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	token: string;
}

export interface gitlabCreds {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	username: string;
	// [SECRET-VALUE]
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	password: string;
	host?: string;
}

export interface gitgenericCreds {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	username: string;
	// [SECRET-VALUE]
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	password: string;
}

export interface dockerhubCreds {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	username: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	password: string;
	host?: string;
}

export interface dockergenericCreds {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	username: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	password: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	host: string;
}

export interface gcpCreds {
	// GCP Service Account
	// [SECRET-VALUE]
	//
	// Your GCP service account's identifying JSON file, expressed in base64 encoded format.
	// <br><br>
	// Example:<br><br>
	//
	// Here is an un-encoded serviceAccount:<br><br>
	//
	// ```{
	// "type": "service_account",
	// "project_id": "project-id",
	// "private_key_id": "key-id",
	// "private_key": "-----BEGIN PRIVATE KEY-----\nprivate-key\n-----END PRIVATE KEY-----\n",
	// "client_email": "service-account-email",
	// "client_id": "client-id",
	// "auth_uri": "https://accounts.google.com/o/oauth2/auth",
	// "token_uri": "https://accounts.google.com/o/oauth2/token",
	// "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
	// "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/service-account-email"
	// }
	// ```
	// <br><br>
	// And here is a serviceAccount in base64 encoded format - this format is what you put in the request body: <br><br>
	// ```
	// ewogICJ0eXBlIjogInNlcnZpY2VfYWNjb3VudCIsCiAgInByb2plY3RfaWQiOiAicHJvamVjdC1pZCIsCiAgInByaXZhdGVfa2V5X2lkIjogImtleS1pZCIsCiAgInByaXZhdGVfa2V5IjogIi0tLS0tQkVHSU4gUFJJVkFURSBLRVktLS0tLVxucHJpdmF0ZS1rZXlcbi0tLS0tRU5EIFBSSVZBVEUgS0VZLS0tLS1cbiIsCiAgImNsaWVudF9lbWFpbCI6ICJzZXJ2aWNlLWFjY291bnQtZW1haWwiLAogICJjbGllbnRfaWQiOiAiY2xpZW50LWlkIiwKICAiYXV0aF91cmkiOiAiaHR0cHM6Ly9hY2NvdW50cy5nb29nbGUuY29tL28vb2F1dGgyL2F1dGgiLAogICJ0b2tlbl91cmkiOiAiaHR0cHM6Ly9hY2NvdW50cy5nb29nbGUuY29tL28vb2F1dGgyL3Rva2VuIiwKICAiYXV0aF9wcm92aWRlcl94NTA5X2NlcnRfdXJsIjogImh0dHBzOi8vd3d3Lmdvb2dsZWFwaXMuY29tL29hdXRoMi92MS9jZXJ0cyIsCiAgImNsaWVudF94NTA5X2NlcnRfdXJsIjogImh0dHBzOi8vd3d3Lmdvb2dsZWFwaXMuY29tL3JvYm90L3YxL21ldGFkYXRhL3g1MDkvc2VydmljZS1hY2NvdW50LWVtYWlsIgp9Cg
	// ```
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	serviceAccount: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	project: string;
}

export interface awsCreds {
	awsSecretKeyOpts?: awsSecretKeyOption;
	awsRoleOpts?: awsRoleOption;
	// Options: [{"(validate.rules)":{"string":{"ignore_empty":true,"pattern":"((us(-gov)?|ap|ca|cn|eu|sa)-(central|(north|south)?(east|west)?)-[1-9]+)"}}}]
	region: string;
	accountId?: string;
	stateFileB64?: string;
	resourceIdentifiers?: awsResources;
}

export interface awsResources {
	artifactBucketArn?: string;
	codeBuildSnsArn?: string;
	codeBuildSqsURL?: string;
}

export interface azureClientSecretOpts {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	clientId: string;
	// [SECRET-VALUE]
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	clientSecret: string;
}

export interface azureDevOpsOpts {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	organizationName: string;
	// [SECRET-VALUE]
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	pat: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	projectName: string;
}

export interface azureCreds {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	tenantId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	subscriptionId: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	resourceGroup: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	region: string;
	azureClientSecretOpts?: azureClientSecretOpts;
	azureDevOpsOpts?: azureDevOpsOpts;
	// this is an optional param
	// if the acrName is given, clientID and secret
	// will be used to validate access on ACR
	acrName?: string;
}

export interface awsSecretKeyOption {
	// aws access key id
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	accessKey: string;
	// aws secret access key
	// [SECRET-VALUE]
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	secretKey: string;
}

export interface awsRoleOption {
	// Options: [{"(validate.rules)":{"string":{"pattern":"^(arn:aws:iam::[0-9]+:role/[0-9a-zA-Z_./-]+)$"}}}]
	roleARN: string;
	// for internal use only. caller must not set this.
	// Options: [{"(validate.rules)":{"string":{"max_len":1224}}}]
	externalId: string;
}

export interface GitDetailsRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	repoUrl?: string;
}

export interface Gitdetails {
	repositoriesList?: string[];
	branchesList?: string[];
	tagsList?: string[];
}

export interface GetCredsRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface SourceWithLines {
	language?: sourceEnum;
	lines?: number;
}

export interface GitLanguageListResponse {
	sortedLanguages?: SourceWithLines[];
}

export interface LanguageWithLines {
	language?: string;
	lines?: number;
}

export interface acrCreds {
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	username: string;
	// secret
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	password: string;
	// Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
	name: string;
}

export interface ListCredEntitiesRequest {
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	orgId: string;
}

export interface ListCredEntitiesResponse {
	entities?: AssignedCredEntity[];
}

export interface CredsMetaResponse_credMeta {
	credType?: credsType;
	credScope?: CredScope[];
}

export interface CredsMetaResponse {
	data?: CredsMetaResponse_credMeta[];
}

export interface AssignedCredEntity {
	project?: ProjectId;
	environment?: EnvironmentId;
	app?: AppId;
	entityType?: VGEntities;
	credScope?: CredScope[];
}

export interface ListCredsRequest {
	// orgId
	// Options: [{"(validate.rules)":{"string":{"uuid":true}}}]
	id: string;
	// Options: [{"(validate.rules)":{"string":{"uuid":true,"ignore_empty":true}}}]
	projId: string;
	includeAssignments?: boolean;
}

export interface identityService {
	// Service health check
	// Options: [{"(google.api.http)":{"get":"/v0/healthcheck/*"}}]
	healthCheck(request: HealthCheckRequest): Promise<HealthCheckResponse>;

	// Login
	// Options: [{"(google.api.http)":{"post":"/v0/login","body":"*"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Logs user into Code Pipes"}}]
	login(request: LoginRequest): Promise<LoginResponse>;

	// Signup
	// Options: [{"(google.api.http)":{"post":"/v0/signup","body":"*"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Registers you and creates a user profile for you in the Code Pipes system."}}]
	signup(request: SignupRequest): Promise<success>;

	// Login authentication callback
	// Options: [{"(google.api.http)":{"get":"/v0/callback"}}]
	callback(request: AuthCodeRequest): Promise<AuthCodeResponse>;

	// Invite a user via email to an organization, project, env, or app
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/invites","body":"*"}}]
	userInvite(request: InvitationRequest): Promise<success>;

	// List invited users
	//
	// lists invited users by organization/project/environment/application
	// takes into consideration the pending invites only
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/invites/users","body":"*"}}]
	listInvitedUsers(request: ListInvitedUsersRequest): Promise<ListInvitedUsersResponse>;

	// Update user invite
	//
	// role is the only updatable field in an invite
	// takes into consideration the pending invites only
	// responds with error if the invite is not found
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/invites/update","body":"*"}}]
	updateUserInvite(request: InvitationRequest): Promise<success>;

	// Delete user invite
	//
	// role is the only updatable field in an invite
	// takes into consideration the pending invites only
	// responds with error if the invite is not found
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/invites/delete","body":"*"}}]
	deleteUserInvite(request: InvitationRequest): Promise<success>;

	// List users by user ids
	// Options: [{"(google.api.http)":{"get":"/v0/users"}}]
	listUsers(request: ListUsersRequest): Promise<UserProfilesList>;

	// Find a user based on email address
	// Options: [{"(google.api.http)":{"get":"/v0/users/{email=*}"}}]
	findUser(request: Email): Promise<UserPublicProfile>;

	// Update a user profile
	// Options: [{"(google.api.http)":{"put":"/v0/users/self/profile","body":"*"}}]
	updateProfile(request: UserProfileUpdateRequest): Promise<success>;

	// Get a user profile
	// Options: [{"(google.api.http)":{"get":"/v0/users/self/profile"}}]
	getUserProfile(request: Record<string, never>): Promise<UserProfile>;

	// List organizations
	// Options: [{"(google.api.http)":{"get":"/v0/organizations"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Lists the organizations in your system to which you have access. For more information on access to Code Pipes entities (like organizations), see [Roles](https://docs.codepipes.io/docs/roles)."}}]
	listOrg(request: Record<string, never>): Promise<organizations>;

	// Create an organization
	// Options: [{"(google.api.http)":{"post":"/v0/organizations","body":"*"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Creates an organization. Organizations are used to control tenancy. Most APIs come through a single organization, so this is the level where most RBAC occurs."}}]
	createOrg(request: orgCreate): Promise<orgId>;

	// Get an organization
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{id=*}"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Get details about an organization, such as its name and description."}}]
	getOrg(request: orgId): Promise<organization>;

	// Update an organization
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{id=*}","body":"*"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Updates an organization's name and description."}}]
	updateOrg(request: orgUpdate): Promise<Record<string, never>>;

	// Delete an organization
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{id=*}"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Deletes the specified organization"}}]
	deleteOrg(request: orgId): Promise<success>;

	// Create a project
	//
	// You define projects within an organization. Projects can contain related environments, applications and deployments. From the point of view of the Code Pipes hierarchy, environments, applications, and deployments are children of the project to which they belong.<br><br>
	// Projects can also contain:<br><br>
	// * A list of environment and/or IaC (i.e. Terraform) variables. You can override these values when you create an individual environment within the project.<br><br>
	// * Any credentials required for the project to access external systems such as GitHub, GCP, and AWS.<br><br>
	// * The default policy set to be used for IaC validation.
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects","body":"*"}}]
	createProject(request: ProjectCreate): Promise<ProjectId>;

	// Get information about a project
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{id=*}"}}]
	getProject(request: projectGetReq): Promise<project>;

	// List projects in an organization
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Lists the projects in a given organization."}}]
	listProject(request: listProjectRequest): Promise<projects>;

	// Update a project
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/projects/{id=*}","body":"*"}}]
	updateProject(request: projectUpdate): Promise<Record<string, never>>;

	// Delete a project
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{id=*}"}}]
	deleteProject(request: ProjectId): Promise<success>;

	// Delete all child entities of this project
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{id=*}/delete-all"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"description":"Delete all entities owned by this project."}}]
	deleteProjectEntities(request: projectEntitiesDeleteRequest): Promise<success>;

	// Update variables associated with a project
	// Options: [{"(google.api.http)":{"patch":"/v0/organizations/{orgId=*}/projects/{id=*}/vars","body":"*"}}]
	updateVariables(request: updateVariablesRequest): Promise<success>;

	// Delete variables associated with a project
	// Options: [{"(google.api.http)":{"patch":"/v0/organizations/{orgId=*}/projects/{id=*}/vars/delete","body":"*"}}]
	deleteVariables(request: deleteVariablesRequest): Promise<success>;

	// Create credentials
	//
	// You can create a credential of any supported type.<br>
	// The credential is validated to by making a request to the provider. And is saved only if the cred is valid.<br>
	// If the credential contains sensitive fields, then its value is encrypted and is not returned in get responses.<br>
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/creds","body":"*"}}]
	createCreds(request: CredsCreate): Promise<Creds>;

	// Update a credential
	//
	// This updates an existing credential such that all the entities that already use the credential, start using the updated one.<br>
	// The credential is validated to by making a request to the provider. And is saved only if the cred is valid.<br><br>
	// We replace the entire cred object. It is the client's responsibility to make sure the new creds would work on existing assignments.<br><br>
	// Example use-case:<br>
	// * After AWS access key rotation, a user wants to update the old key pair with the new to make sure all the assignments works without interruption<br>
	// <br>
	// Known issues:<br>
	// * The non-secret info like cred name cannot be updated without putting in the secret data again. As the replacement would corrupt the secret fields otherwise.<br>
	// E.g.: Update just the credential name for the AWS access key pair creds<br>
	// Options: [{"(google.api.http)":{"put":"/v0/organizations/{orgId=*}/creds/{id=*}","body":"*"}}]
	updateCreds(request: CredsUpdate): Promise<Record<string, never>>;

	// Delete a credential
	//
	// A credential can only be deleted when it's not assigned to any entities<br>
	// If the credential doesn't exist, this would return an error<br>
	// It silently cleans up the associated secret after cred deletion<br>
	// Example use-case:<br>
	// * Cleanup all assignments and then call cred delete<br>
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/creds/{id=*}"}}]
	deleteCreds(request: DeleteCredsRequest): Promise<success>;

	// Get credential by ID
	//
	// Fetches the credential by given credential ID<br>
	// returns error if the credential ID could not be found<br>
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/creds/{id=*}"}}]
	getCreds(request: GetCredsRequest): Promise<Creds>;

	// List credentials
	//
	// Lists the various credentials that exist for a given organization.<br>
	// This also returns possible scopes for each credential.<br>
	// includeAssignments flag can be used to fetch assigned cred entities <br>
	// Example use-case:<br>
	// * Show available creds to assign on an entity<br>
	// * Show all cred on org for creds maangement<br>
	// * Show the corresponding cred entities to which creds are assignedTo <br>
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{id=*}/creds"}}]
	listCreds(request: ListCredsRequest): Promise<CredsList>;

	// Assign credentials
	//
	// This API is deprecated because this works without scope. Use "Assign credentials with scope" instead.<br>
	// For backward compatibility, we predict a scope based of cred type and then internally call the new API<br>
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/creds/assign","body":"*"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"deprecated":true}}]
	assignCreds(request: CredsAssignment): Promise<Record<string, never>>;

	// Assign credentials with scope
	//
	// Assign a credentail to an entity so that the entity may use the credential within the set scopes.<br>
	// If the cred is already assigned with a different scope, this appends the new scope.<br>
	// There could only be one assignment for one scope type.<br>
	// CredScopes which aren't allowed for a credType will result in error (allowed scopes can be verified through meta API or the cred list API)<br>
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/creds/assignscope","body":"*"}}]
	assignCredsScope(request: CredsAssignmentRequest): Promise<Record<string, never>>;

	// CanChangeCredsOnEntity returns an error if credential can be safely changed on a given entity.
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/creds/can-change","body":"*"}}]
	canChangeCredsOnEntity(request: CanChangeCredsOnEntityRequest): Promise<success>;

	// Unassign credentials
	//
	// This API is deprecated because this works without scope. Use "Unassign credentials with scope" instead.<br>
	// For backward compatibility, we unassign all scopes for the given creds in this API.<br>
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/creds/unassign","body":"*"}},{"(grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation)":{"deprecated":true}}]
	unassignCreds(request: CredsAssignment): Promise<Record<string, never>>;

	// Unassign credentials with scope
	//
	// Unassign a credentail from an entity so that the entity may not use the credential for the set scopes.<br>
	// If the cred is not assigned with the given scope, this errors out.<br>
	// It removes the given scopes form the cred assignment. If the scopes is not set then it will unassign all scopes for the cred<br>
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/creds/unassignscope","body":"*"}}]
	unassignCredsScope(request: CredsUnassignmentRequest): Promise<Record<string, never>>;

	// List assigned credentials by entity
	//
	// This returns all creds that are available to the given entity. i.e. assigned to it or one of it's parent entities.<br>
	// It also returns the inherited creds. The inherited creds has the data to identify, from where it has been inherited.<br>
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/creds/applied","body":"*"}}]
	appliedCreds(request: AppliedCredsRequest): Promise<CredsList>;

	// Get a credential by scope that is assigned to a project, environment or application
	//
	// This returns the one cred matching given scope that is available to the given entity.<br>
	// It also returns the inherited creds. The inherited creds has the data to identify, from where it has been inherited.<br>
	// One credential may appear multiple time in case the credential is assigned to the entity and it's parent with different scopes.<br>
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/creds/appliedscope","body":"*"}}]
	getScopeAppliedCred(request: GetScopeAppliedCredRequest): Promise<Creds>;

	// Grant a user access to an organization
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/users/{userId=*}","body":"*"}}]
	grantOrgAccess(request: orgAccessGrant): Promise<success>;

	// Revoke a user's access from an organization
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/users/{userId=*}"}}]
	revokeOrgAccess(request: orgAccessRevoke): Promise<success>;

	// List who has access to an organization
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{id=*}/users"}}]
	listOrgAccess(request: orgId): Promise<UserRoleList>;

	// Grant a user access to a project
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/projects/{projId=*}/users/{userId=*}","body":"*"}}]
	grantProjAccess(request: projAccessGrant): Promise<success>;

	// Revoke a user's access from a project
	// Options: [{"(google.api.http)":{"delete":"/v0/organizations/{orgId=*}/projects/{projId=*}/users/{userId=*}"}}]
	revokeProjAccess(request: projAccessRevoke): Promise<success>;

	// List who has access to a project
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/projects/{id=*}/users"}}]
	listProjAccess(request: ProjectId): Promise<UserRoleList>;

	// Change your password
	// Options: [{"(google.api.http)":{"post":"/v0/users/self/password","body":"*"}}]
	setNewPassword(request: setNewPasswordRequest): Promise<success>;

	// Logout
	// Options: [{"(google.api.http)":{"delete":"/v0/users/self/logout"}}]
	logout(request: Record<string, never>): Promise<success>;

	// Email verify callback
	// Options: [{"(google.api.http)":{"get":"/v0/callback/verifyemail"}}]
	verifyEmail(request: AuthCodeRequest): Promise<success>;

	// Request email verification link
	// Options: [{"(google.api.http)":{"post":"/v0/verificationlink","body":"*"}}]
	emailVerificationLink(request: Email): Promise<success>;

	// Request password reset link
	// Options: [{"(google.api.http)":{"post":"/v0/forgotpassword","body":"*"}}]
	forgotPasswordLink(request: Email): Promise<success>;

	// Send password reset
	// Options: [{"(google.api.http)":{"put":"/v0/forgotpassword","body":"*"}}]
	forgotPassword(request: resetPasswordRequest): Promise<success>;

	// Get a login token
	// Options: [{"(google.api.http)":{"post":"/v0/token","body":"*"}}]
	getToken(request: TokenRequest): Promise<LoginResponse>;

	// List Credentials assigned to an entity
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/creds/{id=*}/applied"}}]
	listCredEntities(request: ListCredEntitiesRequest): Promise<ListCredEntitiesResponse>;

	// Get Git repos associated with a credential
	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{orgId=*}/creds/{id=*}/gitdetails"}}]
	gitDetails(request: GitDetailsRequest): Promise<Gitdetails>;

	// Retrive credential scope metadata
	// Options: [{"(google.api.http)":{"get":"/v0/creds/meta"}}]
	credsMeta(request: Record<string, never>): Promise<CredsMetaResponse>;

	// Get a Signed URL
	// Options: [{"(google.api.http)":{"get":"/v0/getSignedURL"}}]
	getSignedURL(request: signingRequest): Promise<signingResponse>;

	// Submit an incident report
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/incident","body":"*"}}]
	incidentReport(request: incidentRequest): Promise<success>;

	// Options: [{"(google.api.http)":{"get":"/v0/organizations/{id=*}/projects/{projId=*}/creds"}}]
	listProjectCreds(request: ListCredsRequest): Promise<CredsList>;

	// Get list of all the languages used in a git repo and the number of lines
	// Options: [{"(google.api.http)":{"post":"/v0/organizations/{orgId=*}/creds/{id=*}/gitrepolangs","body":"*"}}]
	getGitLanguageList(request: GitDetailsRequest): Promise<GitLanguageListResponse>;
}
