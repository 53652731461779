import { FSelectOptionObject } from "@cldcvr/flow-core";

import { app } from "@/protocol/app";
import { GitServer, Provisioner } from "@/protocol/common";
import { CredScope, credsType, project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";

import { CREDENTIAL_TYPE_ICONS } from "./constants";

export type CredFilterCounts = Record<CredScope, number>;

export type CredFilters = Record<CredScope, boolean>;

export type showAddNewCredTypeForm =
	| credsType.credsType_aws
	| credsType.credsType_gcp
	| credsType.credsType_azure
	| credsType.credsType_github
	| credsType.credsType_dockerhub
	| credsType.credsType_dockergeneric
	| credsType.credsType_gitgeneric
	| credsType.credsType_gitlab
	| null;

export type credCategoryDetail = {
	scope: CredScope;
	icon: string;
	ctaTitle: string;
	headerTitle: string;
	tooltipTxt?: string;
	unassignToastTitle: string;
	assignedToastTitle: string;
	unassignToastBody: (credName: string, entityName: string) => string;
	assignToastBody: (credName: string, entityName: string) => string;
	assignToastBodyForProject: (credName: string, entityName: string) => string;
};

export const credCategoryDetails: Record<SupportedCredScope, credCategoryDetail> = {
	[CredScope.docker]: {
		icon: "i-box",
		scope: CredScope.docker,
		ctaTitle: "Default container",
		tooltipTxt: "No container credential assigned",
		headerTitle: "Add container credentials",
		unassignToastTitle: "Container credential is unassigned",
		assignedToastTitle: "Assigned container credential",
		unassignToastBody: (credName, entityName) =>
			`${credName} has been unassigned from ${entityName}.`,
		assignToastBody: (credName, entityName) =>
			`Succesfully added ${credName} container credential to the ${entityName}.`,
		assignToastBodyForProject: (credName, entityName) =>
			`${credName} credentials are assigned to project "${entityName}". You may now add and deploy applications and environments.`
	},
	[CredScope.cloud]: {
		icon: "i-cloud",
		scope: CredScope.cloud,
		ctaTitle: "Default cloud account",
		tooltipTxt: "No cloud credential assigned",
		headerTitle: "Add cloud credentials",
		unassignToastTitle: "Cloud credential is unassigned",
		assignedToastTitle: "Assigned cloud credential",
		unassignToastBody: (credName, entityName) =>
			`${credName} has been unassigned from ${entityName}.`,
		assignToastBody: (credName, entityName) =>
			`Succesfully added ${credName} cloud credential to the ${entityName}.`,
		assignToastBodyForProject: (credName, entityName) =>
			`${credName} credentials are assigned to project "${entityName}". You may now add and deploy applications and environments.`
	},
	[CredScope.git]: {
		icon: "i-git",
		scope: CredScope.git,
		ctaTitle: "Default git account",
		tooltipTxt: "No git credential assigned",
		headerTitle: "Add git credentials",
		unassignToastTitle: "Git credential is unassigned",
		assignedToastTitle: "Assigned git credential",
		unassignToastBody: (credName, entityName) =>
			`${credName} has been unassigned from ${entityName}.`,
		assignToastBody: (credName, entityName) =>
			`Succesfully added ${credName} git credential to the ${entityName}.`,
		assignToastBodyForProject: (credName, entityName) =>
			`${credName} credentials are assigned to project "${entityName}". You may now add and deploy applications and environments.`
	}
};

export type credOptions = {
	name: string;
	icon: string;
	type: credsType;
};

export const credentialOptionsMap: Partial<Record<credsType, credOptions>> = {
	[credsType.credsType_aws]: {
		name: "AWS",
		icon: CREDENTIAL_TYPE_ICONS.credsType_aws,
		type: credsType.credsType_aws
	},
	[credsType.credsType_azure]: {
		name: "Azure",
		icon: CREDENTIAL_TYPE_ICONS.credsType_azure,
		type: credsType.credsType_azure
	},
	[credsType.credsType_gcp]: {
		name: "GCP",
		icon: CREDENTIAL_TYPE_ICONS.credsType_gcp,
		type: credsType.credsType_gcp
	},
	[credsType.credsType_dockerhub]: {
		name: "Docker Hub",
		icon: CREDENTIAL_TYPE_ICONS.credsType_dockerhub,
		type: credsType.credsType_dockerhub
	},
	[credsType.credsType_dockergeneric]: {
		name: "Docker",
		icon: CREDENTIAL_TYPE_ICONS.credsType_dockergeneric,
		type: credsType.credsType_dockergeneric
	},
	[credsType.credsType_github]: {
		name: "GitHub",
		icon: CREDENTIAL_TYPE_ICONS.credsType_github,
		type: credsType.credsType_github
	},
	[credsType.credsType_gitgeneric]: {
		name: "Git",
		icon: CREDENTIAL_TYPE_ICONS.credsType_gitgeneric,
		type: credsType.credsType_gitgeneric
	},
	[credsType.credsType_gitlab]: {
		name: "GitLab",
		icon: CREDENTIAL_TYPE_ICONS.credsType_gitlab,
		type: credsType.credsType_gitlab
	}
};

export function getGitServerByRepo(repo: string) {
	if (repo.includes("github.com/")) {
		return GitServer.github;
	}
	if (repo.includes("gitlab.com/")) {
		return GitServer.gitlab;
	}
	if (repo.includes("codecommit")) {
		return GitServer.codecommit;
	}
	return GitServer.gitgeneric;
}

export type SupportedCredScope = CredScope.docker | CredScope.cloud | CredScope.git;

export type entityKind = "project" | "environment" | "app";

export type AssignCredToEntities = project | app | environment;

export type ClassificationType = FSelectOptionObject & {
	data: { id: string; icon: string };
};

export type credsTypeToProvisionerMap = {
	[credsType.credsType_aws]: Provisioner.aws;
	[credsType.credsType_gcp]: Provisioner.gcp;
	[credsType.credsType_azure]: Provisioner.azure;
	[credsType.credsType_no_support]: Provisioner.no_provisioner;
	[credsType.credsType_dockerhub]: Provisioner.no_provisioner;
	[credsType.credsType_dockergeneric]: Provisioner.no_provisioner;
	[credsType.credsType_github]: Provisioner.no_provisioner;
	[credsType.credsType_gitgeneric]: Provisioner.no_provisioner;
	[credsType.credsType_gitlab]: Provisioner.no_provisioner;
	[credsType.credsType_codecommit]: Provisioner.no_provisioner;
	[credsType.credsType_acr]: Provisioner.no_provisioner;
};
