<template>
	<PopOver
		:open="open"
		class="width-100-per"
		data-qa-popover-overlay-click
		@overlay-click="open = false"
	>
		<Container
			v-if="artifact_"
			:grow="1"
			padding="18px 12px 18px 40px"
			align="left center"
			class="cursor-pointer show-on-hover-parent"
			:data-qa-pipeline-module-info-text="artifact_.name"
			@click="open = true"
		>
			<Icon v-if="artifact_.icon" size="small" type="filled" :name="artifact_.icon" />

			<Container padding="0" direction="column" :gap="2">
				<Typography type="h4" :data-qa-pipeline-module-name="artifact_.name">{{
					artifact_.name
				}}</Typography>

				<Typography type="p2" color="gray-200" :data-qa-pipeline-module-desc="artifact_.desc">{{
					getSubtitle()
				}}</Typography>
			</Container>

			<slot />

			<Container :grow="1" align="right center" padding="0 12px 0 0">
				<ArtifactOverrideForm :artifact-meta="artifact_" @update="updateArtifact" />
				<RemovePipelineModule :pipeline="artifact" @remove="removeArtifact" />
			</Container>
		</Container>

		<template #content>
			<ModuleReadOnlyInfo v-if="artifact_" :pipeline="artifact_" @close="open = false" />
		</template>
	</PopOver>
</template>
<script lang="ts">
import { Container, Icon, PopOver, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { ArtifactType, ContainerRevisionOverride, GitRevisionOverride } from "@/protocol/common";

import { ArtifactMeta } from "../application-integration-store";

import ArtifactOverrideForm from "./ArtifactOverrideForm.vue";
import ModuleReadOnlyInfo from "./ModuleReadOnlyInfo.vue";
import RemovePipelineModule from "./RemovePipelineModule.vue";

export default defineComponent({
	name: "ArtifactListItem",

	components: {
		Container,
		Icon,
		PopOver,
		Typography,
		ArtifactOverrideForm,
		RemovePipelineModule,
		ModuleReadOnlyInfo
	},

	props: {
		artifact: {
			type: Object as PropType<ArtifactMeta>,
			required: true
		}
	},

	emits: ["update", "remove"],

	data() {
		return {
			open: false,
			artifact_: null as ArtifactMeta | null
		};
	},

	watch: {
		artifact: {
			immediate: true,

			handler(val: ArtifactMeta) {
				this.artifact_ = { ...val };
			}
		}
	},

	methods: {
		updateArtifact({
			values,
			type
		}: {
			values: GitRevisionOverride | ContainerRevisionOverride;
			type: ArtifactType;
		}) {
			switch (type) {
				case ArtifactType.GitCode: {
					if (this.artifact_?.data.gitCode) {
						this.artifact_.data.gitCode.type = (values as GitRevisionOverride).type;
						this.artifact_.data.gitCode.identifier = (values as GitRevisionOverride).identifier;
					}
					break;
				}
				case ArtifactType.ContainerImage: {
					if (this.artifact_?.data.containerImage) {
						this.artifact_.data.containerImage.reference = (
							values as ContainerRevisionOverride
						).reference;
					}
					break;
				}
				default:
					break;
			}
			this.$emit("update", this.artifact_);
		},

		removeArtifact() {
			this.$emit("remove", this.artifact_);
		},

		getSubtitle() {
			return this.artifact_?.data && this.artifact_.data.type === ArtifactType.GitCode
				? "Pipeline Input"
				: "Pipeline Output";
		}
	}
});
</script>
