<template>
	<HomePage />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import HomePage from "@/modules/home/components/HomePage.vue";

export default defineComponent({
	name: "HomePageWrapper",

	components: {
		HomePage
	}
});
</script>
