import { EnvironmentId } from "@/protocol/common";
import {
	classifications,
	environments,
	infraService,
	listTemplatesResponse,
	template
} from "@/protocol/infra";
import ApiService, { INFRA_API } from "@/services/api-service";

export const createEnvironment: infraService["createEnv"] = async function (payload) {
	const { orgId, projId } = payload;

	const response = await ApiService.post<EnvironmentId>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const editEnvironment: infraService["updateEnv"] = async function (payload) {
	const { orgId, projId, id } = payload;

	const response = await ApiService.put<Record<string, never>>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/envs/${id}`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listEnvTemplates: infraService["listTemplates"] = async function ({ orgId }) {
	const response = await ApiService.get<listTemplatesResponse>(
		`${INFRA_API}/organizations/${orgId}/templates`
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const listEnvClassifications: infraService["listClassification"] = async function ({
	orgId
}) {
	const response = await ApiService.get<classifications>(
		`${INFRA_API}/organizations/${orgId}/classifications`
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const createTemplate: infraService["createTemplate"] = async function (payload) {
	const { orgId } = payload;
	const response = await ApiService.post<template>(
		`${INFRA_API}/organizations/${orgId}/templates`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const createClassification: infraService["createClassification"] = async function (payload) {
	const { orgId } = payload;
	const response = await ApiService.post<template>(
		`${INFRA_API}/organizations/${orgId}/classifications`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const createEnvsFromTemplate: infraService["createEnvsFromTemplate"] = async function (
	payload
) {
	const { orgId, templateId, projId } = payload;
	const response = await ApiService.post<environments>(
		`${INFRA_API}/organizations/${orgId}/projects/${projId}/templates/${templateId}/envs`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};
