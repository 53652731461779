<template>
	<Container :padding="0" :grow="1" align="center" align-self="center">
		<EmptyState :icon="emptyIcon">
			<Container
				data-qa-empty-members-list
				align="center"
				direction="column"
				:gap="24"
				:padding="0"
				align-self="center"
			>
				<Container
					align="center"
					:gap="8"
					direction="column"
					:shrink="0"
					:padding="0"
					data-qa-empty-members
				>
					<Typography type="h4" weight="bold" color="gray-100" data-qa-empty-members-text
						>Integration pipeline logs will be displayed here</Typography
					>
				</Container>
			</Container>
		</EmptyState>
	</Container>
</template>
<script lang="ts">
import { Container, EmptyState, EmptyStateIconProp, Typography } from "@cldcvr/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyLogsScreen",
	components: { Container, Typography, EmptyState },

	data: () => ({
		emptyIcon: {
			name: "i-pipe",
			type: "filled",
			state: "default",
			size: "medium",
			color: "gray-200"
		} as EmptyStateIconProp
	})
});
</script>
