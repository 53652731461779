<template>
	<AddNewCredModel
		v-if="showAddNewCredModal"
		ref="addNewCredModel"
		:git-form-ref="gitFormRef"
		:cred-scope="credScope"
		:allowed-classification-ids="allowedClassificationIds"
		@go-back="showAddNewCredModal = false"
		@on-close="forceCloseModal"
		@on-cred-created="onCredCreated"
	/>
	<ConfirmCredAssignChanges
		v-else-if="showConfirmBox"
		:entity="entity"
		:header-title="confirmBoxHeaderTitle"
		:is-submitting="isLoading"
		:cred-to-assign="credToAssign"
		:cred-to-unassign="credToUnassign"
		:options="confirmationModaloptions"
		@on-confirm="applyChanges"
		@back="showConfirmBox = false"
		@close="closeModal"
	/>
	<Wrapper
		v-else
		width="450px"
		height="450px"
		max-height="50vh"
		border-radius="4px"
		background="element-light"
		overflow="scroll"
	>
		<Container :padding="0" :gap="0" direction="column" :grow="1" align="top">
			<Header>
				<slot name="header-item"></slot>
				<Icon
					v-if="showBackButton"
					name="i-arrow-left"
					size="small"
					:effects="true"
					data-qa="assign-cred-go-back-btn"
					@click="goBack()"
				/>
				<Typography type="h4" color="dark" :data-qa="`${credScope}-cred-assign-modal-title`">{{
					credCategoryDetail.headerTitle
				}}</Typography>
				<Container :padding="0" :grow="1" align="right center">
					<slot name="header-step"></slot>
					<!-- add new cred btn -->
					<Button
						v-if="isUserOrgAdmin"
						size="small"
						type="primary"
						state="icon"
						data-qa="assign-cred-model-add-new-cred-btn"
						@click="showAddNewCredModal = true"
					>
						<Icon name="i-plus" size="x-small" type="filled" :effects="false"></Icon>
					</Button>
					<Button
						size="small"
						:type="enableSearch ? 'primary' : 'default'"
						state="icon"
						:disabled="isSearchBtnEnabled"
						data-qa="assign-cred-model-search-cred-btn"
						@click="toggleSearchField"
					>
						<Icon name="i-search" size="x-small" type="filled" :effects="false"></Icon>
					</Button>
					<Icon
						name="i-close"
						type="filled"
						size="x-small"
						:data-qa="`${credScope}-cred-assign-project-action-modal-close-btn`"
						@click="safelyCloseModal"
					/>
				</Container>
			</Header>
			<Container v-if="enableSearch">
				<SearchInput
					ref="filter"
					v-model:value="searchText"
					size="small"
					placeholder="Search credentials..."
					data-qa-field="credentials-search"
				></SearchInput>
			</Container>
			<ClosePopoverConfirmationWarning
				ref="closeConfirmation"
				:initial-form-values="{ listTouched: isApplyBtnEnabled }"
				:form-values="{ listTouched: false }"
				@force-close="forceCloseModal"
			/>
			<ModalNotificationBanner
				v-if="credAddedSuccess"
				banner-type="success"
				:banner-body="credAddedSuccess"
				:show-close-icon="true"
				@close="credAddedSuccess = ''"
			/>
			<ModalNotificationBanner
				v-if="submitError"
				banner-type="error"
				:banner-body="submitError"
				:show-close-icon="true"
				@close="submitError = null"
			/>
			<Container v-if="!hasCredentials" padding="40px 0px 40px 0px">
				<EmptyState
					:icon="placeholderOptions.icon"
					:message="placeholderOptions.message"
					:subtitle="placeholderOptions.subtitle"
					:shape="placeholderOptions.shape"
					data-qa="no-credential-exists"
					:action="placeholderOptions.ctaText"
					@actions="showAddNewCredModal = true"
				/>
			</Container>
			<AssignableCredList
				v-else
				ref="AssignableCredList"
				v-model:selected-creds="selectedCreds"
				:cred-scope="credScope"
				:search-text="searchText"
				:credentials="credentials"
				:entity-kind="entityKind"
				:assign-to-entity="entity"
				:assigned-creds="assignedCreds"
			/>
			<Footer v-if="showFooterBtn">
				<Button
					state="full"
					type="success"
					:disabled="!isApplyBtnEnabled"
					:data-qa="`${credScope}-cred-assign-modal-cta-btn`"
					@click="showConfirmBox = true"
				>
					apply changes
				</Button>
			</Footer>
		</Container>
	</Wrapper>
</template>

<script lang="ts">
import {
	Button,
	Container,
	Footer,
	Header,
	Icon,
	SearchInput,
	Typography,
	Wrapper,
	EmptyState
} from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import AssignableCredList from "@/modules/credentials/components/credential-assign/AssignableCredList.vue";
import ConfirmCredAssignChanges from "@/modules/credentials/components/credential-assign/ConfirmCredAssignChanges.vue";
import AddNewCredModel from "@/modules/credentials/components/credential-form/AddNewCredModel.vue";
import { credentialStore, getCredByScope } from "@/modules/credentials/credential-store";
import {
	credCategoryDetails,
	entityKind,
	SupportedCredScope,
	AssignCredToEntities
} from "@/modules/credentials/credential-types";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { orgStore } from "@/modules/org/org-store";
import { VGEntities } from "@/protocol/common";
import { Creds, CredScope, project } from "@/protocol/identity";
import { environment } from "@/protocol/infra";
import { GithubOauthStorageService } from "@/services/storage-service";
import ClosePopoverConfirmationWarning from "@/shared/components/popovers/ClosePopoverConfirmationWarning.vue";
import ModalNotificationBanner from "@/shared/components/popovers/modal-notification/ModalNotificationBanner.vue";
import { captureError, getErrorMessage } from "@/utils";

export default defineComponent({
	name: "AssignCredentialList",

	components: {
		ClosePopoverConfirmationWarning,
		ConfirmCredAssignChanges,
		ModalNotificationBanner,
		AssignableCredList,
		AddNewCredModel,
		SearchInput,
		Typography,
		EmptyState,
		Container,
		Wrapper,
		Button,
		Footer,
		Header,
		Icon
	},

	props: {
		entity: {
			type: Object as PropType<AssignCredToEntities>,
			required: true
		},

		credScope: {
			type: String as PropType<SupportedCredScope>,
			required: true
		},

		entityKind: {
			type: String as PropType<entityKind>,
			required: true
		},

		gitFormRef: {
			type: String,
			required: true
		},

		showBackButton: {
			type: Boolean,
			default: false
		},

		unassignCredential: {
			type: Object as PropType<Creds | null>,

			default: () => {
				return null;
			}
		}
	},

	emits: ["onClose", "forceClose", "onCredentialAssigned", "backButtonAction"],

	data: () => ({
		selectedCreds: [] as Creds[],
		submitError: null as string | null,
		notificationToastContent: {
			selectedCredName: "",
			unassignedCredName: "",
			appliedToEntityName: ""
		} as InotificationToastContent,

		isLoading: false,
		enableSearch: false,
		searchText: "",
		showAddNewCredModal: false,
		showConfirmBox: false,
		credAddedSuccess: ""
	}),

	computed: {
		getAssignableCredList() {
			const creds = Object.values(credentialStore.genericCredentials);

			if (this.entityKind === "environment") {
				return this.getAssignableCredsForEnv(creds);
			}

			if (this.entityKind === "app") {
				return this.getAssignableCredsForApp(creds);
			}

			/**
			 * If the entity is project, then we show all the creds.
			 */
			return creds;
		},

		assignedCreds() {
			const assignedCreds = getCredByScope({
				entityId: this.entity.id,
				scope: this.credScope
			});

			if (this.entityKind === "environment") {
				return this.getAssignableCredsForEnv(assignedCreds);
			}

			if (this.entityKind === "app") {
				return this.getAssignableCredsForApp(assignedCreds);
			}
			/**
			 * If the entity is project, then we show all the creds.
			 */
			return assignedCreds;
		},

		credentials() {
			const assignedCredIds = this.assignedCreds.map(assigned => assigned.id);
			const creds = this.getAssignableCredList
				.filter(
					// filter all creds by scope and remove assigned creds from the list.
					cred => {
						return cred.credScope?.includes(this.credScope) && !assignedCredIds.includes(cred.id);
					}
				)
				.sort((a, b) => {
					if (a.classificationId && b.classificationId) {
						return a.classificationId.localeCompare(b.classificationId);
					}
					return a.name.localeCompare(b.name);
				});
			// move all assigned creds to top on list.
			return [...this.assignedCreds, ...creds];
		},

		hasCredentials() {
			return !!this.credentials.length;
		},

		credCategoryDetail() {
			return credCategoryDetails[this.credScope];
		},

		showFooterBtn() {
			return this.isUserOrgAdmin && this.hasCredentials;
		},

		isSearchBtnEnabled() {
			return !this.hasCredentials;
		},

		allowedClassificationIds() {
			// envs can belong to a classification so we return the classification id
			// when user is adding new cred to env we only allow creds which belongs to the same classification as the env.
			if (this.entityKind === "environment") {
				const env = this.entity as environment;
				const classificationId = env.classification?.id;
				return classificationId ? [classificationId] : [];
			}
			//app don't belong to any classification so we return empty array.
			if (this.entityKind === "app") {
				return [];
			}
			return undefined;
		},

		isUserOrgAdmin() {
			return orgStore.isUserOrgAdmin;
		},

		placeholderOptions() {
			const options = {
				message: "",
				subtitle: "",
				ctaText: "",
				iconName: ""
			};
			if (!this.hasCredentials) {
				const platfromNameMap = {
					[CredScope.docker]: "docker",
					[CredScope.cloud]: "cloud",
					[CredScope.git]: "git"
				};
				options.message = "Add Credential";
				options.subtitle = `Credentials on Code Pipes represent an existing account that you have with any ${
					platfromNameMap[this.credScope]
				} platforms.`;
				options.ctaText = "ADD CREDENTIAL";
				options.iconName = "i-plus";
			}

			return {
				...options,
				shape: "squircle",
				icon: {
					name: options.iconName,
					type: "filled",
					state: "default",
					size: "medium",
					color: "gray-200"
				}
			} as const;
		},

		credToAssign() {
			const alreadyAssignedCredIds = this.assignedCreds
				// filter inherited creds from list
				.filter(assignedCred => assignedCred.inheritedFrom === VGEntities.invalid_entity)
				.map(assignedCred => assignedCred.id);
			return this.selectedCreds.filter(
				selectedCred => !alreadyAssignedCredIds.includes(selectedCred.id)
			);
		},

		credToUnassign() {
			const newCredIds = this.selectedCreds.map(selectedCred => selectedCred.id);
			return this.assignedCreds.filter(
				assignedCred =>
					assignedCred.inheritedFrom === VGEntities.invalid_entity &&
					!newCredIds.includes(assignedCred.id)
			);
		},

		isApplyBtnEnabled() {
			return Boolean(this.credToAssign.length || this.credToUnassign.length);
		},

		confirmBoxHeaderTitle() {
			if (this.unassignCredential) {
				return "Unassign credential";
			}
			return this.credCategoryDetail.headerTitle;
		},

		confirmationModaloptions() {
			return {
				showBackBtn: !this.unassignCredential
			};
		}
	},

	mounted() {
		if (this.credScope === CredScope.git) {
			this.githubModalObserver();
		}

		credentialStore.GET_CLASSIFICATIONS({ orgId: this.entity.orgId });

		if (this.unassignCredential) {
			this.forceUnselectCred(this.unassignCredential);
			this.showConfirmBox = true;
		}
	},

	methods: {
		/**
		 * If the entity is environment, filter the creds which belongs to the same classification as the environment.
		 * and all the creds which are not classified.
		 */
		getAssignableCredsForEnv(creds: Creds[]) {
			const env = this.entity as environment;
			const envClassificationId = env.classification?.id;
			return creds.filter(
				cred => !cred.classificationId || cred.classificationId === envClassificationId
			);
		},

		/**
		 * If the entity is app, then we filter the creds which are not classified.
		 */
		getAssignableCredsForApp(creds: Creds[]) {
			return creds.filter(cred => !cred.classificationId);
		},

		closeModal() {
			this.$emit("onClose");
		},

		forceCloseModal() {
			this.$emit("forceClose");
		},

		githubModalObserver() {
			const githubOauthResult = GithubOauthStorageService.getGithubUserOauth();
			if (githubOauthResult.isUserOauthActive && githubOauthResult.gitFormRef === this.gitFormRef) {
				// render github form
				this.showAddNewCredModal = true;
			}
		},

		toggleSearchField() {
			this.enableSearch = !this.enableSearch;
			if (this.enableSearch) {
				this.$nextTick(() => {
					//@ts-expect-error DagChart component is not typed fully
					this.$refs.filter.$refs["search-input"].focus();
				});
			}
		},

		async applyChanges() {
			if (this.isLoading) {
				return;
			}
			try {
				this.isLoading = true;

				this.notificationToastContent = {
					selectedCredName: this.credToAssign.map(cred => cred.name).join(", "),
					unassignedCredName: this.credToUnassign.map(cred => cred.name).join(", "),
					appliedToEntityName: this.entity.name
				};

				const hasAssignedCred = !!this.credToAssign.length;
				const hasUnassignedCred = !!this.credToUnassign.length;

				await credentialStore.applyCredentialScopeToEntity({
					entity: this.entity,
					applyCredScope: [this.credScope],
					assignTo: this.entityKind,
					credToUnassign: this.credToUnassign,
					credToAssign: this.credToAssign
				});

				await credentialStore.getSetAppliedCredOnEntity({
					entity: this.entity,
					entityType: this.entityKind
				});

				this.forceCloseModal();
				this.sendToastNotification({
					hasAssignedCred,
					hasUnassignedCred
				});

				if (hasAssignedCred) {
					this.$emit("onCredentialAssigned");
				}
			} catch (error) {
				this.showConfirmBox = false;
				this.submitError = getErrorMessage(error);
				captureError(error);
			} finally {
				this.isLoading = false;
			}
		},

		sendToastNotification({
			hasAssignedCred,
			hasUnassignedCred
		}: {
			hasAssignedCred: boolean | null;
			hasUnassignedCred: boolean | null;
		}) {
			if (hasAssignedCred) {
				if (this.entityKind === "project") {
					notificationsStore.ADD_TOAST({
						qaId: `toast-${this.credScope}-account-added`,
						title: "Success",
						text: this.credCategoryDetail.assignToastBodyForProject(
							this.notificationToastContent.selectedCredName,
							this.notificationToastContent.appliedToEntityName
						),
						status: "success",
						autoDismiss: true,
						actions: [
							{
								actionType: "default",
								onAction: () => this.routeToPage("applications"),
								text: "DEPLOY APPLICATION",
								qaId: "data-qa-project-deploy-app-link-btn"
							},
							{
								actionType: "default",
								onAction: () => this.routeToPage("environments"),
								text: "CREATE ENVIRONMENT",
								qaId: "data-qa-project-create-env-link-btn"
							}
						]
					});
				} else {
					notificationsStore.ADD_TOAST({
						qaId: `toast-${this.credScope}-account-added`,
						title: this.credCategoryDetail.assignedToastTitle,
						text: this.credCategoryDetail.assignToastBody(
							this.notificationToastContent.selectedCredName,
							this.notificationToastContent.appliedToEntityName
						),
						status: "success"
					});
				}
			} else if (hasUnassignedCred) {
				notificationsStore.ADD_TOAST({
					qaId: `toast-${this.credScope}-account-unassigned`,
					title: this.credCategoryDetail.unassignToastTitle,
					text: this.credCategoryDetail.unassignToastBody(
						this.notificationToastContent.unassignedCredName,
						this.notificationToastContent.appliedToEntityName
					),
					status: "error"
				});
			}
		},

		routeToPage(tabName: string) {
			this.$router.replace({
				name: "projectListWithProject",
				params: {
					orgId: this.entity.orgId,
					projectId: (this.entity as project).id,
					tabName
				}
			});
			notificationsStore.DISMISS_ALL();
		},

		onCredCreated(newCred: Creds) {
			// show the assign select modal
			this.showAddNewCredModal = false;
			this.credAddedSuccess = `Successfully added credential ${newCred.name}`;
			// wait for assign list to render
			this.$nextTick(() => {
				const isSelected = this.forceSelectCred(newCred);
				if (isSelected) {
					this.applyChanges();
				}
			});
		},

		forceSelectCred(cred: Creds) {
			const assignCredList = this.$refs.AssignableCredList as InstanceType<
				typeof AssignableCredList
			>;
			if (assignCredList) {
				assignCredList.onAdd(cred);
				return true;
			}
			return false;
		},

		forceUnselectCred(cred: Creds) {
			const assignCredList = this.$refs.AssignableCredList as InstanceType<
				typeof AssignableCredList
			>;
			if (assignCredList) {
				assignCredList.onRemove(cred);
				return true;
			}
			return false;
		},

		// called by parent component
		// eslint-disable-next-line vue/no-unused-properties
		safelyCloseModal() {
			if (this.showAddNewCredModal) {
				const addNewCredModel = this.$refs.addNewCredModel as InstanceType<typeof AddNewCredModel>;
				if (addNewCredModel) {
					addNewCredModel.safelyCloseModal();
					return false;
				}
			}
			if (this.showConfirmBox) {
				this.forceCloseModal();
				return true;
			}

			const closeConfirmRef = this.$refs.closeConfirmation as InstanceType<
				typeof ClosePopoverConfirmationWarning
			>;
			if (closeConfirmRef) {
				const isFormTouched = closeConfirmRef.isFormTouched();
				if (!isFormTouched) {
					this.forceCloseModal();
					return true;
				}
			}

			return false;
		},

		goBack() {
			const closeConfirmRef = this.$refs.closeConfirmation as InstanceType<
				typeof ClosePopoverConfirmationWarning
			>;
			if (closeConfirmRef) {
				const isFormTouched = closeConfirmRef.isFormTouched();
				if (!isFormTouched) {
					this.$emit("backButtonAction");
				}
			}
		}
	}
});
type InotificationToastContent = {
	selectedCredName: string;
	unassignedCredName: string;
	appliedToEntityName: string;
};
</script>
