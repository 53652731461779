<template>
	<PopOver
		placement="bottom-start"
		:open="isFlagOpen[actionType]"
		@overlay-click="resetConfirmFlags"
	>
		<EnvironmentAction
			:type="actionType"
			:job="currentJob ?? undefined"
			:state="actionState"
			:duration="actionDuration"
			:disabled="isLoading || isDestroyDisabled"
			:is-destroy-disabled="isDestroyDisabled"
			:primary-qa-attribute="
				showLogsButton ? 'data-qa-logs-view-button' : 'data-qa-open-confirm-pipeline'
			"
			:secondary-qa-attribute="
				isPipelineActive ? 'data-qa-cancel-pipeline-button' : 'data-qa-open-confirm-pipeline'
			"
			@primary-action="showLogsButton ? viewPipeline() : openConfirmPipeline(actionType)"
			@secondary-action="isPipelineActive ? cancelPipeline() : openConfirmPipeline(actionType)"
		/>
		<template #content>
			<ConfirmPipeline
				:confirm-meta="confirmActionMeta[actionType]"
				:env="env"
				:action="actionType"
				@close="resetConfirmFlags"
				@toast="showToast"
			/>
		</template>
	</PopOver>
</template>

<script lang="ts">
import { PopOver } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { envListStore } from "@/modules/env-list/env-list-store";
import ConfirmPipeline from "@/modules/env-pipeline/components/ConfirmPipeline.vue";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { actionType, environment } from "@/protocol/infra";
import EnvironmentAction, {
	EnvironmentActionState
} from "@/shared/components/environment/EnvironmentAction.vue";
import {
	PipelineJobStatus,
	PIPELINE_ACTIVE_JOB_STATUSES,
	PIPELINE_UNFINISHED_JOB_STATUSES
} from "@/shared/pipeline-constants";

import { ENV_PIPELINE_CONFIRM_ACTION_META } from "../constants";
import { getSortedEnvJobs, newEnvPipelineStore } from "../env-pipeline-store";
import { EnvPipelineViewedJob } from "../env-pipeline-types";

export default defineComponent({
	name: "PipelineAction",

	components: {
		ConfirmPipeline,
		PopOver,
		EnvironmentAction
	},

	props: {
		actionType: {
			type: String as PropType<actionType>,
			required: true
		},

		clickAction: {
			type: Function as PropType<
				(props: { action: actionType; job: EnvPipelineViewedJob | null }) => void
			>,

			required: true
		},

		env: {
			type: Object as PropType<environment>,
			required: true
		},

		projectId: {
			type: String,
			required: true
		},

		envId: {
			type: String,
			required: true
		},

		isLoading: {
			type: Boolean,
			required: true
		},

		showValidatePopover: {
			type: Boolean,
			default: () => null
		}
	},

	emits: ["close", "jobLoading"],

	data: () => ({
		confirmActionMeta: ENV_PIPELINE_CONFIRM_ACTION_META,

		openConfirmFlags: {
			no_action_type_support: false,
			validateOnly: false,
			ValidateAndApply: false,
			destroy: false
		} as Record<actionType, boolean>
	}),

	computed: {
		isFlagOpen(): Record<actionType, boolean> {
			return {
				...this.openConfirmFlags,
				...{
					// When we are in a step based flow we need to force show the validate popover
					validateOnly: this.showValidatePopover || this.openConfirmFlags.validateOnly
				}
			};
		},

		actionState(): EnvironmentActionState {
			if (this.isLoading) {
				return "loading";
			}

			return this.currentJobStatus ?? "default";
		},

		actionDuration() {
			if (!this.currentJob || !this.currentJobStatus) {
				return;
			}

			const jobCompletedTime = this.currentJob.updatedAt ?? false;
			return jobCompletedTime ? jobCompletedTime : undefined;
		},

		isDestroyDisabled() {
			return this.actionType === "destroy" && !this.env.isDeployed;
		},

		currentJob() {
			const [currentJob] = getSortedEnvJobs(this.envId, this.actionType);

			return currentJob ?? null;
		},

		currentJobStatus() {
			return this.currentJob?.status as PipelineJobStatus | undefined;
		},

		isPipelineInProgress() {
			return (
				this.currentJobStatus && PIPELINE_UNFINISHED_JOB_STATUSES.includes(this.currentJobStatus)
			);
		},

		showLogsButton() {
			return !this.isLoading && this.currentJobStatus;
		},

		isPipelineActive() {
			return this.currentJobStatus && PIPELINE_ACTIVE_JOB_STATUSES.includes(this.currentJobStatus);
		}
	},

	watch: {
		isPipelineInProgress: {
			immediate: true,

			handler() {
				this.$emit("jobLoading", { isRunning: this.isPipelineInProgress, action: this.actionType });
				/**
				 * If the pipeline job is complete, we need to refetch the env object
				 * as we are using env.isDeployed key to enable/disable env pipeline triggers
				 */
				if (
					this.currentJobStatus === "done" &&
					(this.actionType === "ValidateAndApply" || this.actionType === "destroy")
				) {
					this.getLatestEnv();
				}
			}
		}
	},

	methods: {
		async getLatestEnv() {
			await envListStore.GET_ENV_BY_ID({
				envId: this.envId,
				orgId: this.env.orgId,
				projectId: this.projectId
			});
		},

		showToast({
			action,
			title,
			status,
			message,
			timeString
		}: {
			action: actionType;
			title: string;
			status: string;
			message: string;
			timeString: string;
		}) {
			let toastStatus: "error" | "success" | "info" = "info";

			if (status === "success") {
				toastStatus = "success";
			} else if (status === "error") {
				toastStatus = "error";
			}

			notificationsStore.ADD_TOAST({
				qaId: `toast-pipeline-${action}`,
				title,
				text: message,
				subText: timeString,
				status: toastStatus,
				actions: [
					{
						qaId: `view-pipeline-logs`,
						text: "View logs",
						actionType: "default",
						onAction: this.viewPipeline
					}
				]
			});
		},

		cancelPipeline() {
			if (!this.currentJob) {
				return;
			}

			newEnvPipelineStore.CANCEL_PIPELINE({
				job: this.currentJob,
				projId: this.env.projId,
				orgId: this.env.orgId
			});
		},

		viewPipeline() {
			this.clickAction({ action: this.actionType, job: this.currentJob });
		},

		resetConfirmFlags() {
			(Object.keys(this.openConfirmFlags) as actionType[]).forEach((flag: actionType) => {
				this.openConfirmFlags[flag] = false;
			});
			this.$emit("close");
		},

		openConfirmPipeline(action: actionType) {
			if (this.isPipelineInProgress ?? this.isDestroyDisabled) {
				return;
			}

			this.openConfirmFlags[action] = !this.openConfirmFlags[action];
		}
	}
});
</script>
<style lang="scss">
div.flow-wrapper.disabled {
	background: var(gray-500);
	opacity: 0.4;
	pointer-events: none;
}
</style>
