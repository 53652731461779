<template>
	<f-div direction="column" :gap="keyValueSideBySide ? 'x-small' : 'none'">
		<f-text v-if="keyValueSideBySide" size="x-small" variant="para" state="secondary">
			{{ title }}
		</f-text>
		<f-div v-else padding="large none none none">
			<f-text size="small" variant="heading" state="secondary">{{ title }}</f-text>
		</f-div>
		<f-div
			v-for="(value, key) in listData"
			:key="key"
			direction="row"
			gap="none"
			:border="keyValueSideBySide ? undefined : 'small solid default bottom'"
			height="hug-content"
		>
			<f-div
				:direction="keyValueSideBySide ? 'row' : 'column'"
				gap="x-small"
				width="100%"
				:padding="keyValueSideBySide ? 'none' : 'medium none'"
			>
				<f-text size="x-small" :variant="keyValueSideBySide ? 'para' : 'heading'">{{ key }}</f-text>
				<f-text
					size="x-small"
					variant="para"
					:state="keyValueSideBySide ? 'custom, #ffffff' : 'secondary'"
					>{{ value }}</f-text
				>
			</f-div>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "DependencyInputOutputTab",

	props: {
		listData: {
			type: Object,
			required: true
		},

		title: {
			type: String,
			required: true
		},

		keyValueSideBySide: {
			type: Boolean,
			default: false
		}
	}
});
</script>
