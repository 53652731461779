import { Router, RouteRecordRaw } from "vue-router";

import TerraformRegistryWrapper from "@/modules/terraform-registry/views/TerraformRegistryWrapper.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/org/:orgId/terraform-registry",
		name: "terraform-registry-global",
		component: TerraformRegistryWrapper,
		meta: {
			title: "Terraform Component Registry",
			requiresAuth: true,
			reuse: false
		},
		props: true
	}
];

export function registerTerraformRegistryRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
