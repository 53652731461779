import { FormBuilderField, html } from "@cldcvr/flow-form-builder";

import { credentialStore } from "@/modules/credentials/credential-store";
import { ClassificationType } from "@/modules/credentials/credential-types";
import { Creds } from "@/protocol/identity";

import { getClassificationIcon } from "./get-classification-icon";

type FormValue = {
	classification: ClassificationType;
};

type FormField = {
	classification: FormBuilderField;
};

export function getClassificationFormValue(classificationId: string): FormValue | null {
	const classification = credentialStore.classificationList.find(
		item => item.id === classificationId
	);

	if (!classification) {
		return null;
	}

	return {
		classification: {
			data: {
				id: classification.id,
				icon: getClassificationIcon(classification)
			},

			title: classification.name
		}
	};
}

export function getClassificationFormField(
	cred?: Creds,
	allowedClassificationIds?: string[],
	showWhen?: boolean
): FormField {
	const emptyClassifications = credentialStore.classificationList.length === 0;
	let classificationText = "";

	if (emptyClassifications) {
		classificationText = "Your organization doesn’t have any classifications to select from.";
	} else if (cred) {
		classificationText = "Credential classification will be mapped to environment classification.";
	}

	return {
		classification: {
			type: "select",
			clear: true,
			id: "classification",
			label: { title: "Classification", subTitle: !cred ? "Optional" : "" },
			helperText: classificationText,
			disabled: cred ?? credentialStore.classificationList.length === 0 ? true : false,
			...(showWhen && { showWhen: () => showWhen }),
			options: credentialStore.classificationList
				.filter(classification => {
					if (allowedClassificationIds) {
						return allowedClassificationIds.includes(classification.id);
					}
					return true;
				})
				.map(item => {
					return {
						data: { id: item.id, icon: getClassificationIcon(item) },
						title: item.name
					};
				}),

			placeholder: "Select Classification Class",
			//@ts-expect-error
			optionTemplate: (option: ClassificationType) =>
				html` <f-div direction="column" gap="x-small">
					<f-div gap="small">
						<f-icon state="warning" source="${option.data.icon}"> </f-icon>
						<f-text>${option.title}</f-text>
					</f-div>
				</f-div>`
		}
	};
}
