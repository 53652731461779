import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";

import { authStore } from "@/modules/auth/auth-store";
import { CodePipesStore } from "@/modules/store";
import { ENVIRONMENTS, getCmpUrl, getEnvironment, getErrorCode } from "@/utils";
import AxiosDuplicatePreventionAdapter from "@/utils/axios-duplicate-prevention-adapter";

import { TokenService } from "./storage-service";

// We could have API errors due to restarting services for example, so we should have a retry mechanism
axiosRetry(axios, {
	retries: 3,
	retryDelay: retryCount => {
		if (getEnvironment() === ENVIRONMENTS.PRODUCTION) {
			return retryCount * 1000;
		}

		return retryCount * 10000;
	},
	retryCondition: error => {
		const status = error.response?.status;
		return status === 503 || status === 502;
	}
});

class ApiServiceConstructor {
	private _401interceptor: number | null;
	store: null | CodePipesStore;
	duplicateRequestAdapter;

	constructor() {
		this._401interceptor = null;
		this.store = null;
		this.init(window.VUE_APP_API_BASE_URL);
		this.getSetTokenAndMountInterceptor();
		this.duplicateRequestAdapter = new AxiosDuplicatePreventionAdapter();
	}

	init(baseURL: string) {
		axios.defaults.baseURL = baseURL;
	}

	setStore(store: CodePipesStore) {
		this.store = store;
	}

	getSetTokenAndMountInterceptor() {
		const token = TokenService.getToken();
		if (!this._401interceptor && token) {
			this.mount401Interceptor();
		}
	}

	removeHeader() {
		axios.defaults.headers.common = {};
	}

	get<T = unknown>(resource: string) {
		const axiosConfig: AxiosRequestConfig = {
			headers: {},
			adapter: this.duplicateRequestAdapter.adapter
		};

		const token = TokenService.getToken();
		if (token) {
			axiosConfig.headers.Authorization = token;
		}

		return axios.get<T>(resource, axiosConfig);
	}

	post<T = unknown>(resource: string, data: unknown, config?: AxiosRequestConfig) {
		const axiosConfig = Object.assign({}, config);
		const token = TokenService.getToken();

		if (token) {
			if (!axiosConfig.headers) {
				axiosConfig.headers = {};
			}

			axiosConfig.headers.Authorization = token;
		}

		return axios.post<T>(resource, data, axiosConfig);
	}

	patch<T = unknown>(resource: string, data: unknown, config?: AxiosRequestConfig) {
		const axiosConfig = Object.assign({}, config);
		const token = TokenService.getToken();

		if (token) {
			if (!axiosConfig.headers) {
				axiosConfig.headers = {};
			}

			axiosConfig.headers.Authorization = token;
		}

		return axios.patch<T>(resource, data, axiosConfig);
	}

	put<T = unknown>(resource: string, data: unknown) {
		const axiosConfig: AxiosRequestConfig = {
			headers: {}
		};

		const token = TokenService.getToken();
		if (token) {
			axiosConfig.headers.Authorization = token;
		}

		return axios.put<T>(resource, data, axiosConfig);
	}

	delete<T = unknown>(resource: string) {
		const axiosConfig: AxiosRequestConfig = {
			headers: {}
		};

		const token = TokenService.getToken();
		if (token) {
			axiosConfig.headers.Authorization = token;
		}

		return axios.delete<T>(resource, axiosConfig);
	}

	/**
	 * Perform a custom Axios request.
	 *
	 * data is an object containing the following properties:
	 *  - method
	 *  - url
	 *  - data ... request payload
	 *  - auth (optional)
	 *    - username
	 *    - password
	 **/
	customRequest<T = unknown>(data: AxiosRequestConfig) {
		if (!data.headers) {
			data.headers = {};
		}

		const token = TokenService.getToken();
		if (token) {
			data.headers.Authorization = token;
		}

		return axios(data) as Promise<AxiosResponse<T>>;
	}

	mount401Interceptor() {
		this._401interceptor = axios.interceptors.response.use(
			response => {
				return response;
			},
			async error => {
				const requestUrl = error.config.url;
				const isTokenRequest = requestUrl.includes("/token");
				const reqStatusHttpCode = error.request.status;
				const is401 = reqStatusHttpCode === 401;
				// when get 401 response we try to refresh the token using
				// token API if we get error > 400 means the refresh token is also expired.

				const errorCode = getErrorCode(error);

				// Either expired or invalid token or unauthorized user (for token requests)
				const isTokenExpired =
					errorCode.includes("UtilsTokenExpired") ||
					errorCode.includes("UtilsInvalidToken") ||
					errorCode.includes("IdentityUnauthorisedUser");

				// Refresh token has failed. Logout the user
				if (isTokenRequest && isTokenExpired) {
					const flags = this.store?.getters["featureFlag/featureMap"];
					const isCmpUser = flags?.ENABLE_NX_PLATFORM;
					await authStore.USER_LOGOUT({
						forceLogout: false,
						isRedirect: !isCmpUser
					});
					if (isCmpUser) {
						const url = getCmpUrl();
						window.location.replace(url);
					}
					return;
				}

				if (is401 && !isTokenRequest) {
					// call refresh token api to get fresh access token.
					// note: we should not call refresh token api if we are getting 401 on token api hence is not isTokenRequest check.
					try {
						await authStore.REFRESH_TOKEN();
						// Retry the original request
						return this.customRequest({
							method: error.config.method,
							url: error.config.url,
							data: error.config.data
						});
					} catch (e) {
						// Refresh has failed - reject the original request
						throw error;
					}
					// Throw the original error so that the user can see the error message
				} else {
					throw error;
				}
			}
		);
	}

	unmount401Interceptor() {
		// Eject the interceptor
		if (this._401interceptor !== null) {
			axios.interceptors.response.eject(this._401interceptor);
			this._401interceptor = null;
		}
	}
}

const ApiService = new ApiServiceConstructor();

export default ApiService;

export const ACTIVITY_API = "/activity/v0";
export const APPLICATION_API = "/app/v0";
export const DEPLOYMENT_API = "/deployment/v0";
export const IDENTITY_API = "/identity/v0";
export const INFRA_API = "/infra/v0";
export const INSTALLER_API = "/installer/v0";
export const VALIDATION_API = "/validation/v0";
export const EXTERNAL_EVENTS = "/external/v0";

declare global {
	interface Window {
		VUE_APP_API_BASE_URL: string;
	}
}
