import { RouteRecordRaw, Router } from "vue-router";

import ProjectPageWrapper from "./views/ProjectPageWrapper.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/org/:orgId/projects/:projectId/:tabName?/:subSectionTab?",
		name: "projectListWithProject",
		component: ProjectPageWrapper,
		meta: {
			title: "Projects",
			requiresAuth: true,
			reuse: true
		},
		props: true
	},

	{
		path: "/org/:orgId/projects/:projectId/applications/:appId",
		name: "projectListWithApp",
		component: ProjectPageWrapper,
		meta: {
			title: "Projects",
			requiresAuth: true,
			reuse: true
		},
		props: true
	}
];

export function registerProjectLandingRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
