<template>
	<Container
		v-if="status === 'default'"
		background="element"
		align="space-between stretch"
		class="width-100-per"
		:padding="0"
		:gap="0"
	>
		<Container
			align="space-between stretch"
			background="element"
			class="width-100-per"
			:gap="0"
			:padding="0"
		>
			<Button
				v-tooltip="{
					content: 'Validate environment',
					placement: 'top-start'
				}"
				state="full"
				type="primary"
				data-qa-validate-environment
				@click="$emit('validate')"
				>validate</Button
			>
			<Button
				v-tooltip="{
					content: 'Deploy environment',
					placement: 'top-start'
				}"
				state="full"
				type="success"
				data-qa-validate-and-deploy-environment
				@click="$emit('deploy')"
				>deploy</Button
			>
		</Container>
	</Container>

	<Container
		v-else
		background="element"
		align="space-between stretch"
		:padding="0"
		class="width-100-per"
	>
		<Container :padding="10">
			<Icon
				v-if="statusIcon"
				:name="statusIcon.name"
				:state="statusIcon.state"
				type="filled"
				size="small"
				:effects="false"
				:animate="statusIcon.loading"
			/>

			<Typography
				v-if="statusText"
				v-tooltip="{
					content: statusText,
					placement: 'top-end'
				}"
				data-qa-status-text
				type="p2"
				overflow="ellipsis"
				>{{ statusText }}
			</Typography>
			<slot v-else name="status-text" />
		</Container>
		<template v-if="type !== 'initial-fetch'">
			<Container :padding="0" :gap="0" align="stretch" :shrink="0">
				<Container align="center" :padding="12" :gap="16" :shrink="0">
					<Typography
						type="p3"
						color="primary"
						:link="true"
						weight="medium"
						overflow="ellipsis"
						data-qa-view-logs
						@click="$emit('view-logs')"
						>View logs</Typography
					>
				</Container>

				<Button
					v-if="status === 'in-progress' || status === 'cancelling' || status === 'destroying'"
					v-tooltip="{
						content: cancelButtonTooltip,
						placement: 'top-start'
					}"
					data-qa-cancel
					state="full"
					:disabled="status === 'cancelling'"
					@click="$emit('cancel')"
				>
					<Icon name="i-stop" state="error" type="filled" size="small" :effects="false" />
				</Button>
				<Container v-else :padding="0" :shrink="0" :gap="0">
					<Button
						v-tooltip="{
							content: isEnvStatusFailed ? 'Retry Validation' : 'Revalidate environment',
							placement: 'top-start'
						}"
						state="full"
						type="primary"
						data-qa-validate-environment
						@click="$emit('validate')"
					>
						<Icon
							:name="isEnvStatusFailed ? 'i-reload' : 'i-tick'"
							type="filled"
							size="small"
							:effects="false"
							color="gray-600"
						/>
					</Button>

					<Button
						v-if="status !== 'destroyed'"
						v-tooltip="{
							content: isDeployStatusFailed ? 'Retry deployment' : 'Redeploy environment',
							placement: 'top-start'
						}"
						state="full"
						type="success"
						data-qa-validate-and-deploy-environment
						@click="$emit('deploy')"
					>
						<Icon
							:name="isDeployStatusFailed ? 'i-reload' : 'i-launch'"
							type="filled"
							size="small"
							:effects="false"
							color="gray-600"
						/>
					</Button>
				</Container>
			</Container>
		</template>
		<template v-else>
			<Container v-if="status === 'failed'" :padding="0" :gap="0" align="stretch" :shrink="0">
				<Container align="center" :padding="12" :gap="16" :shrink="0">
					<Typography type="p3" color="warning-300" weight="regular" :link="true">RETRY</Typography>
				</Container>
			</Container>
		</template>
	</Container>
</template>

<script lang="ts">
import { Icon, Button, Container, Typography, IconState } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "WidgetFooterContent",

	components: {
		Icon,
		Button,
		Container,
		Typography
	},

	props: {
		status: {
			type: String as PropType<WidgetFooterStatus>,
			required: true
		},

		type: {
			type: String as PropType<WidgetFooterType>,
			required: true
		},

		statusText: {
			type: String
		}
	},

	emits: ["view-logs", "cancel", "validate", "deploy"],

	computed: {
		statusIcon(): { name: string; state: IconState; loading?: boolean } | null {
			switch (this.status) {
				case "in-progress": {
					return {
						name:
							this.type === "env-validation" || this.type === "initial-fetch"
								? "i-loader"
								: "i-loader-success",

						state: "primary",
						loading: true
					};
				}
				case "done":
					return {
						name: this.type === "env-deploy" ? "i-launch" : "i-tick",
						state: this.type === "env-deploy" ? "success" : "primary",
						loading: false
					};
				case "cancelling":
					return { name: "i-loader-danger", state: "primary", loading: true };
				case "destroying":
					return { name: "i-loader-danger", state: "primary", loading: true };
				case "destroyed":
					return { name: "i-destroy", state: "error", loading: false };
				case "cancelled":
					return { name: "i-stop", state: "error", loading: false };
				case "failed":
					return {
						name: "i-alert",
						state: this.type !== "initial-fetch" ? "error" : "warning",
						loading: false
					};
			}

			return null;
		},

		cancelButtonTooltip() {
			switch (this.type) {
				case "env-deploy":
					return "Cancel deployment";

				case "env-validation":
					return "Cancel validation";

				case "env-destroy":
					return "Cancel destroy";
			}

			return "";
		},

		isEnvStatusFailed() {
			return this.status === "failed" && this.type === "env-validation";
		},

		isDeployStatusFailed() {
			return this.status === "failed" && this.type === "env-deploy";
		}
	}
});

export type WidgetFooterStatus =
	| "default"
	| "in-progress"
	| "destroying"
	| "destroyed"
	| "done"
	| "cancelling"
	| "cancelled"
	| "failed";

export type WidgetFooterType = "initial-fetch" | "env-validation" | "env-deploy" | "env-destroy";
</script>
