<template>
	<Wrapper
		border-radius="4px"
		background="gray-500"
		width="432px"
		height="316px"
		max-height="80vh"
		overflow="visible"
	>
		<Header>
			<Typography type="h4" color="dark" :data-qa-code-promo-app-name="app?.name">
				Promote {{ app?.name }} app
			</Typography>
			<Container :padding="0" :grow="1" align="right center">
				<Icon
					size="x-small"
					type="filled"
					name="i-close"
					data-qa-code-promo-popover-close-icon
					@click="$emit('close')"
				/>
			</Container>
		</Header>
		<Container :padding="0" :gap="0" direction="column" align="left top">
			<Divider />
			<Container padding="16px" :gap="16" align="left top" direction="column" overflow="visible">
				<Typography
					type="p1-para"
					:data-qa-code-promo-to-env-name="promoteToEnv"
					:data-qa-code-promo-from-env-name="promoteFromEnv"
				>
					{{ app?.name }} App will be promoted from {{ promoteFromEnv }} to {{ promoteToEnv }}. The
					app dependencies will be automatically resolved on {{ promoteToEnv }}.
				</Typography>
			</Container>
		</Container>
		<Container :padding="12" :gap="0" direction="column" align="left top" overflow="visible">
			<VersionSelector
				:versions="versions"
				:tag-pattern="versionTagPattern"
				@version-selected="setVersion"
			/>
		</Container>
		<Container v-if="submitError != ''" :data-qa-code-promo-submit-error="submitError">
			<p class="paragraph-2 fc-error" data-qa-code-promo-submit-error>{{ submitError }}</p>
		</Container>
		<Footer>
			<Button
				type="success"
				state="full"
				size="small"
				:disabled="!isVersionSelected"
				:loading="isSubmitting"
				data-qa-code-promo-submit-btn
				overflow="ellipsis"
				@click="promoteApp"
			>
				<Typography
					v-tooltip="{
						content: promoteToEnv,
						placement: 'top-end'
					}"
					type="p1"
					weight="bold"
					color="black"
					overflow="ellipsis"
					>{{ `Promote to ${promoteToEnv}` }}</Typography
				>
			</Button>
		</Footer>
	</Wrapper>
</template>
<script lang="ts">
import {
	Button,
	Container,
	Divider,
	Footer,
	Header,
	Icon,
	Typography,
	Wrapper
} from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { app as AppProto } from "@/protocol/app";
import { promotedVersion } from "@/protocol/deployment";

import { EnvDepMapping } from "../views/CodePromotionWrapper.vue";

import VersionSelector from "./VersionSelector.vue";

export default defineComponent({
	name: "PromoteAppConfirm",

	components: {
		VersionSelector,
		Container,
		Header,
		Footer,
		Icon,
		Typography,
		Wrapper,
		Button,
		Divider
	},

	props: {
		deployment: {
			type: Object as PropType<EnvDepMapping>
		},

		app: {
			type: Object as PropType<AppProto>,
			required: true
		},

		promoteFromEnv: {
			type: String,
			required: true
		},

		promoteToEnv: {
			type: String,
			required: true
		},

		versions: {
			type: Array as PropType<promotedVersion[]>,
			required: true
		},

		isSubmitting: {
			type: Boolean
		},

		submitError: {
			type: String
		}
	},

	data: () => ({
		isVersionSelected: false,
		selectedVersion: ""
	}),

	computed: {
		versionTagPattern() {
			return this.app.artifacts?.[0]?.containerImage?.versionTagPattern ?? null;
		}
	},

	methods: {
		promoteApp() {
			this.$emit("confirm", { dep: this.deployment, version: this.selectedVersion });
		},

		setVersion(val: string) {
			this.selectedVersion = val;
			if (val !== "") {
				this.isVersionSelected = true;
			} else {
				this.isVersionSelected = false;
			}
		}
	}
});
</script>
