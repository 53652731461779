<template>
	<div data-qa-deployment-entity-variables>
		<Container padding="0 0 10px 0" overflow="visible">
			<Typography type="h5" color="gray-200" transform="uppercase" weight="bold"
				>Variables</Typography
			>
			<Button
				state="icon"
				size="x-small"
				data-qa-add-environment-variable
				@click="addEnvironmentKey"
			>
				<Icon name="i-plus" size="x-small" type="filled" />
			</Button>
		</Container>

		<f-form-builder
			:key="formBuilderKeyHack"
			:field.prop="formFields"
			:values.prop="formValues"
			@input="emitInput"
			@state-change="formState = $event.detail"
		/>

		<f-popover
			v-if="prefillValues && prefillTargetId"
			:target="`#dropdown-${prefillTargetId}`"
			open
			size="small"
			placement="bottom"
			@overlay-click="prefillTargetId = null"
		>
			<f-div direction="column">
				<f-div
					v-for="(prefillValue, idx) in prefillValues"
					:key="prefillValue"
					data-qa-prefill-value
					state="secondary"
					padding="small"
					:border="idx < prefillValues.length - 1 ? 'small solid default bottom' : undefined"
					clickable
					@click="setPrefilledValue(prefillValue)"
				>
					<f-text variant="para" size="small" weight="regular" ellipsis>{{ prefillValue }}</f-text>
				</f-div>
			</f-div>
		</f-popover>

		<Container v-if="errorMessage" padding="10px 0 10px 0">
			<p class="paragraph-2 fc-error">{{ errorMessage }}</p>
		</Container>

		<Container v-if="noVariablesDefined" padding="10px 0 10px 0">
			<p class="paragraph-2 fc-normal">No variables defined</p>
		</Container>

		<f-spacer v-if="Object.keys(formValues).length > 0" size="small" />

		<table v-if="inheritedVariables.length > 0" class="key-value" data-row-gutter="8px">
			<tbody>
				<tr
					v-for="(variable, i) in inheritedVariables"
					:key="i"
					:data-qa-environment-variable-key="variable.key"
					:data-qa-environment-variable-value="variable.value"
					:data-qa-environment-variable-tags="variable.tag.map(tag => tag.name).join(',')"
				>
					<td>
						<f-text size="small" :tooltip="variable.key" ellipsis>{{ variable.key }}</f-text>
					</td>

					<td>
						<f-text size="small" :tooltip="variable.value" ellipsis>{{ variable.value }}</f-text>
					</td>

					<td>
						<Container align="right center" :grow="1" :padding="0" :gap="16">
							<Tag v-for="tag in variable.tag" :key="tag.name" :type="tag.color" size="small">{{
								tag.name
							}}</Tag>
						</Container>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script lang="ts">
import { FormBuilderField, FormBuilderState, html } from "@cldcvr/flow-form-builder";
import { Button, Container, Icon, Tag, TagType, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { DeploymentVar } from "@/modules/application-deployment/store-types";
import { projectStore } from "@/modules/project-list/project-store";
import { variableType } from "@/protocol/common";
import { AppDeployment } from "@/protocol/deployment";
import { environment } from "@/protocol/infra";
import { variableNameRule } from "@/shared/custom-validation-rules/entityNameRules";
import { getUniqueStringId } from "@/utils";

export default defineComponent({
	name: "DeploymentEntityVariables",

	components: {
		Button,
		Container,
		Icon,
		Tag,
		Typography
	},

	props: {
		env: {
			type: Object as PropType<environment>
		},

		appDeployment: {
			type: Object as PropType<AppDeployment>
		},

		variableInfo: {
			type: Object as PropType<VariableInfo>,
			required: true
		},

		prefillValues: {
			type: Array as PropType<string[]>
		}
	},

	emits: ["update:variableInfo"],

	data() {
		const propVariables = this.variableInfo.variables;

		return {
			prefillTargetId: null as string | null,
			formState: null as FormBuilderState | null,

			formValues: propVariables.reduce(
				(out, variable) => {
					out[getUniqueStringId()] = variable;
					return out;
				},
				{} as Record<string, DeploymentVar>
			),

			errorMessage: "",

			// @hack - Flow form builder seems to persist values in inputs when an identical item is added
			// to an array of objects, so we use this hack to work around it
			formBuilderKeyHack: 0
		};
	},

	computed: {
		formFields(): FormBuilderField {
			const fields: FormBuilderField = {
				type: "object",
				direction: "vertical",
				fieldSeparator: true,
				fields: {}
			};

			const hasPrefillValues = Boolean(this.prefillValues && this.prefillValues.length > 0);

			Object.entries(this.formValues).forEach(([id, fieldValue]) => {
				fields.fields[id] = {
					type: "object",
					direction: "vertical",
					qaId: "data-qa-environment-variable",
					fields: {
						key: {
							type: "text",
							id: "name",
							placeholder: "Enter variable name",
							label: {
								title: "Name",
								subTitle: html`
									<f-icon
										clickable="true"
										size="x-small"
										source="i-delete"
										state="danger"
										data-qa-add-remove-environment-variable
										tooltip="Delete variable"
										@click="${() => {
											this.removeEnvironmentKey(id);
										}}"
									></f-icon>
								`
							},

							validationRules: [
								{ name: "required" },
								variableNameRule(variableType.env_var),
								{
									name: "custom",
									validate: key => {
										if (typeof key === "string") {
											return !this.duplicateVariableError(key, id);
										}
										return false;
									},

									message: "Variable with same name exists already"
								}
							]
						},

						value: {
							type: "textarea",
							rows: "2",
							resizable: true,
							maskValue: fieldValue.sensitive,
							disabled: !fieldValue.canEdit,
							id: "value",
							label: {
								// Show dropdown to handle pre-filled values
								title: hasPrefillValues
									? html`<f-div gap="small">
											<f-text size="small">Value</f-text>
											<f-icon-button
												data-qa-open-prefill-dropdown
												tooltip="Values from environment"
												id="dropdown-${id}"
												size="x-small"
												icon="i-paragraph"
												state="neutral"
												category="packed"
												@click="${() => {
													this.prefillTargetId = id;
												}}"
											></f-icon-button>
									  </f-div>`
									: "Value",

								// Show "Mask this" checkbox if value is sensitive
								subTitle: html`
									<f-checkbox
										size="medium"
										value="unchecked"
										data-qa-toggle-sensitive
										data-qa-variable-sensitive-input
										@input="${(e: CustomEvent) => {
											fieldValue.sensitive = e.detail.value === "checked";
										}}"
									>
										<f-div slot="label" padding="none" data-qa-mask-for=${fieldValue.key}>
											<f-text variant="para" size="small">Mask this</f-text>
										</f-div>
									</f-checkbox>
								`
							},
							placeholder: "Enter variable value",
							validationRules: [{ name: "required" }]
						}
					}
				};
			});

			return fields;
		},

		noVariablesDefined() {
			return this.inheritedVariables.length === 0 && Object.keys(this.formValues).length === 0;
		},

		inheritedVariables() {
			const variables: InheritedVar[] = [];
			const projectVars = projectStore.currentProject?.variables;
			const envVars = this.env?.variables ?? [];

			// Add a tag to differentiate proj & env vars
			projectVars?.forEach(pVar => {
				if (!pVar.key || !pVar.value || pVar.type !== "env_var") {
					return;
				}

				variables.push({
					key: pVar.key,
					value: pVar.value,
					sensitive: pVar.sensitive,
					tag: [
						{
							name: "project var",
							color: "primary"
						}
					]
				});
			});

			// Add a tag to differentiate proj & env vars
			envVars.forEach(eVar => {
				if (!eVar.key || !eVar.value || eVar.type !== "env_var") {
					return;
				}

				const existingVar = variables.find(v => v.key === eVar.key);
				const tag: InheritedVar["tag"][number] = {
					name: "env var",
					color: "warning"
				};

				if (existingVar) {
					existingVar.value = eVar.value;
					existingVar.tag.unshift(tag);
				} else {
					variables.push({
						key: eVar.key,
						value: eVar.value,
						sensitive: eVar.sensitive ?? false,
						tag: [tag]
					});
				}
			});

			variables
				.sort((a, b) => (a.key ? a.key.localeCompare(b.key) : 0))
				.sort((a, b) =>
					a.tag[0]?.name && b.tag[0]?.name ? a.tag[0].name.localeCompare(b.tag[0].name) : 0
				);

			return variables;
		}
	},

	watch: {
		"formState.isValid": {
			immediate: true,

			handler() {
				this.emitInput();
			}
		},

		appDeployment: {
			deep: true,
			immediate: true,

			// emits initial variables and populated variableInfo prop in parent component.
			handler() {
				const localVariables = Object.values(this.formValues);
				const localKeys = Object.values(this.formValues).map(variable => variable.key);
				const appVars = this.appDeployment?.deploymentConfig?.env ?? {};
				const sensitiveVars = this.appDeployment?.deploymentConfig?.sensitive ?? {};

				Object.keys(appVars).forEach(key => {
					if (localKeys.includes(key)) {
						return;
					}

					localVariables.push({
						key,
						value: appVars[key]!,
						sensitive: false,
						canEdit: true
					});
				});

				Object.keys(sensitiveVars).forEach(key => {
					if (localKeys.includes(key)) {
						return;
					}

					localVariables.push({
						key,
						value: "*****",
						sensitive: true,
						canEdit: false
					});
				});

				localVariables.sort((a, b) => (a.key ? a.key.localeCompare(b.key) : 0));

				this.formValues = localVariables.reduce(
					(out, variable) => {
						out[getUniqueStringId()] = variable;
						return out;
					},
					{} as Record<string, DeploymentVar>
				);

				this.emitInput();
			}
		}
	},

	methods: {
		emitInput() {
			this.$emit("update:variableInfo", {
				variables: Object.values(this.formValues),
				isValid: this.formState?.isValid
			});
		},

		duplicateVariableError(fieldKey: string, fieldId: string) {
			return Object.entries(this.formValues).some(
				([id, item]) => item.key === fieldKey && fieldKey.trim() !== "" && id !== fieldId
			);
		},

		addEnvironmentKey() {
			// Firefox doesn't blur correctly when something is clicked
			(document.activeElement as HTMLInputElement | null)?.blur();

			// Object keys aren't "ordered" explicitly, but Map keys are so we convert from Object > Map > Object
			// We also can't do two-way binding with Flow 2 form builder as it doesn't support Map as values
			this.formValues = Object.fromEntries(
				new Map([
					[
						getUniqueStringId(),
						{
							key: "",
							value: "",
							sensitive: false,
							canEdit: true
						}
					],
					...Object.entries(this.formValues)
				])
			);

			// Increase key to render a new instance of form builder
			this.formBuilderKeyHack++;

			this.emitInput();
		},

		removeEnvironmentKey(key: string) {
			delete this.formValues[key];
			this.emitInput();
		},

		setPrefilledValue(value: string) {
			if (!this.prefillTargetId || !this.formValues[this.prefillTargetId]) {
				return;
			}

			this.formValues[this.prefillTargetId]!.value = value;
			// This is to get f-form-builder to re-render
			this.formValues = { ...this.formValues };
			this.prefillTargetId = null;
			this.emitInput();
		}
	}
});

type InheritedVar = {
	key: string;
	value: string;
	tag: {
		name: string;
		color: TagType;
	}[];
	sensitive?: boolean;
};

export type VariableInfo = {
	variables: DeploymentVar[];
	isValid: boolean;
};
</script>

<style lang="scss">
[data-qa-deployment-entity-variables] table.key-value tr {
	td,
	th {
		padding-left: 0 !important;
		vertical-align: top;
	}
}
</style>
