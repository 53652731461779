import { FSelectOptionObject, FSelectSingleOption, html } from "@cldcvr/flow-core";

import {
	ArtifactType,
	ContainerRegistryProvider,
	GitRevisionType,
	Provisioner,
	sourceEnum
} from "@/protocol/common";
import { triggerEvent, webhook } from "@/protocol/externalEvents";
import { credsType } from "@/protocol/identity";
import { simplifyUUID } from "@/utils";

export const IDENTITY_API = "/identity/v0";
export const INFRA_API = "/infra/v0";

export const APPLICATION_LANGUAGE_MAP: Record<sourceEnum, { header: string; id: sourceEnum }> = {
	[sourceEnum.source_no]: { id: sourceEnum.source_no, header: "Not specified" },
	[sourceEnum.source_go]: { id: sourceEnum.source_go, header: "Go lang" },
	[sourceEnum.source_node]: { id: sourceEnum.source_node, header: "Node.js" },
	[sourceEnum.source_java]: { id: sourceEnum.source_java, header: "Java" },
	[sourceEnum.source_dotnet]: { id: sourceEnum.source_dotnet, header: ".NET" },
	[sourceEnum.source_csharp]: { id: sourceEnum.source_csharp, header: "C#" },
	[sourceEnum.source_html]: { id: sourceEnum.source_html, header: "HTML" },
	[sourceEnum.source_js]: { id: sourceEnum.source_js, header: "Javascript" },
	[sourceEnum.source_ts]: { id: sourceEnum.source_ts, header: "Typescript" }
};

export const APPLICATION_LANGUAGE_LIST = Object.values(APPLICATION_LANGUAGE_MAP);

export const APPLICATION_LANGUAGE_MAP2: Record<
	sourceEnum,
	FSelectOptionObject & { data: { id: sourceEnum } }
> = {
	[sourceEnum.source_no]: { data: { id: sourceEnum.source_no }, title: "Not specified" },
	[sourceEnum.source_go]: { data: { id: sourceEnum.source_go }, title: "Go lang" },
	[sourceEnum.source_node]: { data: { id: sourceEnum.source_node }, title: "Node.js" },
	[sourceEnum.source_java]: { data: { id: sourceEnum.source_java }, title: "Java" },
	[sourceEnum.source_dotnet]: { data: { id: sourceEnum.source_dotnet }, title: ".NET" },
	[sourceEnum.source_csharp]: { data: { id: sourceEnum.source_csharp }, title: "C#" },
	[sourceEnum.source_html]: { data: { id: sourceEnum.source_html }, title: "HTML" },
	[sourceEnum.source_js]: { data: { id: sourceEnum.source_js }, title: "Javascript" },
	[sourceEnum.source_ts]: { data: { id: sourceEnum.source_ts }, title: "Typescript" }
};

export const APPLICATION_LANGUAGE_LIST2 = Object.values(APPLICATION_LANGUAGE_MAP2);

export const CredsTypeToContainerRegistryProvider: Record<
	credsType,
	ContainerRegistryProvider | null
> = {
	[credsType.credsType_no_support]: null,
	[credsType.credsType_github]: null,
	[credsType.credsType_gcp]: ContainerRegistryProvider.google_container_registry,
	[credsType.credsType_aws]: ContainerRegistryProvider.public_elastic_container_registry,
	[credsType.credsType_dockerhub]: ContainerRegistryProvider.dockerhub_container_registry,
	[credsType.credsType_gitlab]: ContainerRegistryProvider.gitlab_container_registry,
	[credsType.credsType_azure]: ContainerRegistryProvider.azure_container_registry,
	[credsType.credsType_dockergeneric]: null,
	[credsType.credsType_codecommit]: null,
	[credsType.credsType_gitgeneric]: null,
	[credsType.credsType_acr]: null
};

export const CredsTypeToProvisioner: Record<credsType, Provisioner | null> = {
	[credsType.credsType_no_support]: null,
	[credsType.credsType_github]: null,
	[credsType.credsType_gcp]: Provisioner.gcp,
	[credsType.credsType_aws]: Provisioner.aws,
	[credsType.credsType_dockerhub]: null,
	[credsType.credsType_gitlab]: null,
	[credsType.credsType_azure]: Provisioner.azure,
	[credsType.credsType_dockergeneric]: null,
	[credsType.credsType_codecommit]: null,
	[credsType.credsType_gitgeneric]: null,
	[credsType.credsType_acr]: null
};

export type GitRevisionOption = FSelectOptionObject & {
	data: { id: GitRevisionType };
};

export const BRANCH_GIT_REVISION_OPTION = {
	data: { id: GitRevisionType.branch },
	title: "Branch"
};

export const TAG_GIT_REVISION_OPTION = {
	data: { id: GitRevisionType.tag },
	title: "Tag"
};

export const COMMIT_GIT_REVISION_OPTION = {
	data: { id: GitRevisionType.commit },
	title: "Commit"
};

export const GIT_REVISION_OPTIONS: GitRevisionOption[] = [
	BRANCH_GIT_REVISION_OPTION,
	TAG_GIT_REVISION_OPTION,
	COMMIT_GIT_REVISION_OPTION
];

export const ARTIFACT_TYPE_ICON: Record<ArtifactType, string> = {
	[ArtifactType.GitCode]: "i-git",
	[ArtifactType.ContainerImage]: "i-box",
	[ArtifactType.BucketObject]: "i-bucket",
	[ArtifactType.Files]: "i-bucket",
	[ArtifactType.artifact_undefined]: "i-bucket"
};

export type WebhookOptionType = FSelectOptionObject & { data: { id: number }; subTitle: string };

export const GIT_WEBHOOK_OPTION: WebhookOptionType = {
	data: { id: 1 },
	title: "Git event",
	subTitle: "Webhook is triggered on a Git event"
};

export const CUSTOM_WEBHOOK_OPTION: WebhookOptionType = {
	data: { id: 2 },
	title: "Custom URL",
	subTitle: "Webhook is triggered when hitting a URL"
};

export const INTEGRATION_OPTION: WebhookOptionType = {
	data: { id: 3 },
	title: "Codepipes pipeline completion",
	subTitle: "Webhook is triggered when a Codepipes pipeline is completed"
};

export const WEBHOOK_OPTIONS: Array<WebhookOptionType> = [
	GIT_WEBHOOK_OPTION,
	CUSTOM_WEBHOOK_OPTION
];

export function webhookOptionTemplate(option: FSelectSingleOption) {
	return html`
		<f-div direction="column">
			<f-text size="small">${(option as WebhookOptionType).title}</f-text>
			<f-text size="x-small" state="secondary">${(option as WebhookOptionType).subTitle}</f-text>
		</f-div>
	`;
}

type triggerStringMethod = (identifier?: string) => string;

export const TRIGGER_EVENT_TO_STRING: Record<triggerEvent, triggerStringMethod> = {
	[triggerEvent.pushToBranch]: identifier =>
		`Push to ${identifier ? `the ${identifier}` : "a"} branch`,
	[triggerEvent.pullReq]: identifier =>
		`Pull request against ${identifier ? `the ${identifier}` : "a"} branch`,
	[triggerEvent.pushToTag]: identifier => `Push to ${identifier ? `the ${identifier}` : "a"} tag`,
	[triggerEvent.no_trigger_event_support]: () => "-"
};

export function getWebhookMessage(hook: webhook) {
	const webhookTriggerEvent = hook.githubTrigger?.triggerEvent;
	const webhookIdentifier = hook.githubTrigger?.identifier;
	if (webhookTriggerEvent && webhookIdentifier) {
		return TRIGGER_EVENT_TO_STRING[webhookTriggerEvent](webhookIdentifier);
	}

	if (hook.customTrigger) {
		return `Custom webhook URL #${simplifyUUID(hook.id!)}`;
	}

	return null;
}

export const MARKDOWN_HELPER_TOOLTIP = `This field supports basic markdown syntax.`;
