<template>
	<Container
		direction="column"
		class="flex-shrink-0"
		:grow="1"
		:padding="0"
		:gap="0"
		overflow="visible"
		align="left top"
	>
		<f-form-builder
			data-qa-version-selector-form
			:field.prop="formFields"
			:values.prop="formValues"
			@input="handleInput"
		/>
	</Container>
</template>
<script lang="ts">
import { FSelectOptionObject } from "@cldcvr/flow-core";
import { FormBuilderField } from "@cldcvr/flow-form-builder";
import { Container } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { promotedVersion } from "@/protocol/deployment";

export default defineComponent({
	name: "VersionSelector",

	components: {
		Container
	},

	props: {
		versions: {
			type: Array as PropType<promotedVersion[]>,
			required: true
		},

		tagPattern: {
			type: String as PropType<string | null>,
			required: true
		}
	},

	emits: ["versionSelected"],

	data(thisProp) {
		return {
			formValues: {
				__showCustomInput__: thisProp.versions.length === 0
			} as FormValues
		};
	},

	computed: {
		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					__showCustomInput__: {
						type: "hidden"
					},

					valueText: {
						type: "text",
						qaId: "valueText",
						label: { title: "Add custom version" },
						placeholder: `Version tag pattern ${this.tagPattern ?? ""}`,
						validationRules: [{ name: "required" }],
						//@ts-expect-error
						showWhen: (values: FormValues) => {
							return values.__showCustomInput__;
						}
					},

					valueSelect: {
						type: "select",
						qaId: "valueSelect",
						label: { title: "Select a version" },
						validationRules: [{ name: "required" }],
						options: this.versions.map(version => {
							return { title: version.version, data: { version } };
						}),

						placeholder: "Select",
						//@ts-expect-error
						showWhen: (values: FormValues) => {
							return !values.__showCustomInput__;
						}
					},

					__toggle__: {
						type: "button",
						label: this.formValues.__showCustomInput__
							? "View Available versions"
							: "Add custom version",

						onClick: this.toggleForm,
						variant: "block",
						iconLeft: this.formValues.__showCustomInput__ ? "" : "i-plus",
						category: "transparent",
						size: "small"
					}
				}
			};
		}
	},

	methods: {
		handleInput(event: CustomEvent<FormValues>) {
			const { __showCustomInput__, valueText, valueSelect } = event.detail;

			const selectedVersion = __showCustomInput__ ? valueText : valueSelect?.data.version.version;

			this.$emit("versionSelected", selectedVersion ?? "");
		},

		toggleForm() {
			this.formValues = {
				...this.formValues,
				__showCustomInput__: !this.formValues.__showCustomInput__
			};

			this.handleInput(new CustomEvent("input", { detail: this.formValues }));
		}
	}
});

type VersionOption = FSelectOptionObject & { data: { version: promotedVersion } };

type FormValues = {
	__showCustomInput__: boolean;
	valueText?: string;
	valueSelect?: VersionOption;
};
</script>
