import { Router, RouteRecordRaw } from "vue-router";

import AddIntegrationPipelineWrapper from "./components/AddIntegrationPipelineWrapper.vue";
import IntegrationPipelineWrapper from "./components/IntegrationPipelineWrapper.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/org/:orgId/projects/:projectId/apps/:appId/integrations/:integrationId",
		name: "stageViewIntegration",
		component: IntegrationPipelineWrapper,
		meta: {
			requiresAuth: true,
			title: "Integration Pipeline"
		},
		props: true
	},
	{
		path: "/org/:orgId/projects/:projectId/apps/:appId/integrations/:integrationId/job/:jobId",
		name: "stageViewIntegrationWithJob",
		component: IntegrationPipelineWrapper,
		meta: {
			requiresAuth: true,
			title: "Integration Pipeline"
		},
		props: true
	},
	{
		path: "/org/:orgId/projects/:projectId/apps/:appId/integrations/create-edit/:integrationId",
		name: "addIntegrationView",
		component: AddIntegrationPipelineWrapper,
		meta: {
			requiresAuth: true,
			title: "Integration Pipeline"
		},
		props: true
	}
];

export function registerIntegrationPipelineRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
