import { startApp } from "./start-app";
import { captureError, polyfillXpath, ENVIRONMENTS, getEnvironment, setupMSClarity } from "./utils";

// Flow type imports
import "@cldcvr/flow-core/dist/types/vue3";
import "@cldcvr/flow-form-builder/dist/types/vue3";

import "@cldcvr/flow-core";
import "@cldcvr/flow-form-builder";
import "@cldcvr/flow-system-icon";
import "@cldcvr/flow-product-icon";
import "@cldcvr/flow-table";

import "@cldcvr/flow-vue3/dist/style.css";
import "floating-vue/dist/style.css";
import "@cldcvr/flow-table/dist/types/vue3";

const environment = getEnvironment();
const isProduction = environment === ENVIRONMENTS.PRODUCTION;

if (isProduction) {
	setupMSClarity();
}

// Code to help with automation
polyfillXpath();

// HTTPS redirect
if (environment !== ENVIRONMENTS.DEVELOPMENT) {
	try {
		const url = new URL(window.location.href);

		if (url.protocol !== "https:") {
			url.protocol = "https:";
			window.location.replace(url.toString());
		}
	} catch (err) {
		captureError(err);
	}
}

startApp();

if (window.Cypress) {
	window.appLoaded = true;
}
