<template>
	<Container
		:padding="0"
		:grow="1"
		:gap="0"
		direction="column"
		class="width-100-per"
		data-qa-pipeline-modules-drag-list
	>
		<!-- INPUT ARTIFACT -->
		<SelectArtifactModulePopover
			v-if="inputArtifact === null"
			:modules="{}"
			data-qa="add-input-artifact"
			:artifacts="allInputArtifacts"
			label="Add input artifact"
			title="Input artifact for app integration pipeline"
			disabled-title="There are no input artifacts added to this app, please add to continue"
			@module-added="addModuleToList"
			@artifact-added="addInputArtifactToList"
		/>
		<ArtifactListItem
			v-else
			data-qa="edit-input-artifact"
			:artifact="inputArtifact"
			@update="addInputArtifactToList"
			@remove="removeInputArtifact"
		>
			<slot name="trigger-form" />
		</ArtifactListItem>

		<Divider />
		<!-- PIPELINE MODULES -->

		<DragDropList
			:key="dragKey"
			item-key="name"
			:list="internalPipelineModules"
			data-qa-pipeline-module-drag-component
			@drag-end="dragEnd"
		>
			<template #default="{ element, index }">
				<PopOver
					:open="isPopoverOpen[element.name + index] ?? false"
					class="width-100-per"
					data-qa-popver-overlay-click
					@overlay-click="
						isPopoverOpen[element.name + index] = false;
						moduleToEdit = null;
					"
				>
					<Container
						align="left center"
						padding="18px 12px"
						:grow="1"
						:data-qa-pipeline-module-item="element.name + index"
						direction="row"
						class="cursor-pointer show-on-hover-parent"
						:data-qa-pipeline-module-info-text="element.name + index"
						@click="isPopoverOpen[element.name + index] = true"
					>
						<Icon
							size="small"
							type="filled"
							name="i-drag-vertical"
							class="handle"
							:data-qa-pipeline-module-drag-icon="element.name + index"
						/>

						<Icon size="small" type="filled" :name="element.icon" />

						<Container direction="column" padding="0" :gap="2">
							<Typography type="h4" :data-qa-pipeline-module-name="element.name + index">{{
								element.name
							}}</Typography>

							<Typography type="p2" color="gray-200" :data-qa-pipeline-module-desc="element.desc">{{
								element.desc
							}}</Typography>
						</Container>

						<Container
							padding="0"
							class="show-on-hover"
							align="right center"
							:grow="1"
							overflow="visible"
						>
							<Icon
								v-tooltip="'Edit Module'"
								data-qa-edit-pipeline-module
								name="i-edit"
								size="small"
								type="filled"
								state="default"
								@click="openEditModulePopOver(index)"
							/>
							<RemovePipelineModule :pipeline="element" @remove="removePipelineFromList" />
						</Container>
					</Container>

					<template #content>
						<ModuleReadOnlyInfo
							v-if="isPopoverOpen[element.name + index] && moduleToEdit === null"
							:pipeline="element"
							@close="isPopoverOpen[element.name + index] = false"
						/>

						<AddNewModulePopOver
							v-if="moduleToEdit !== null"
							:show-back-btn="false"
							:pipeline-module-ref="currentPipelineModuleRef"
							:module="moduleToEdit"
							:save-mode="true"
							:order="index"
							@close="
								isPopoverOpen[element.name + index] = false;
								moduleToEdit = null;
							"
							@submitted="updatePipelineModule"
						/>
					</template>
				</PopOver>

				<Divider v-if="index < internalPipelineModules.length - 1" />
			</template>
		</DragDropList>

		<!-- ADD MODULES POPOVER -->
		<SelectArtifactModulePopover
			:modules="allModules"
			:artifacts="[]"
			data-qa="add-pipeline-modules"
			label="Add pipeline modules"
			title="Module for app integration pipeline"
			disabled-title="There are no pipeline modules available"
			@module-added="addModuleToList"
		/>

		<Divider />

		<SelectArtifactModulePopover
			v-if="outputArtifact === null"
			:modules="{}"
			:artifacts="allOutputArtifacts"
			data-qa="add-output-artifact"
			label="Add output artifact"
			title="Output artifact for app integration pipeline"
			disabled-title="There are no output artifacts added to this app, please add to continue"
			@module-added="addModuleToList"
			@artifact-added="addOutputArtifactToList"
		/>
		<template v-else>
			<ArtifactListItem
				:artifact="outputArtifact"
				@update="addOutputArtifactToList"
				@remove="removeOutputArtifact"
			/>
			<Divider />
		</template>
	</Container>
</template>
<script lang="ts">
import { Container, Divider, DragDropList, Icon, PopOver, Typography } from "@cldcvr/flow-vue3";
import { PropType, defineComponent } from "vue";

import { applicationStore } from "@/modules/application/application-store";
import { ArtifactType, Provisioner } from "@/protocol/common";
import { pipelineModule, pipelineModuleReference } from "@/protocol/pipeline";
import {
	PipelineModuleToAdd,
	PipelineModuleToAddRef,
	pipelineModuleWithRefId
} from "@/shared/pipeline-constants";
import { getUniqueId } from "@/utils";

import {
	ArtifactMeta,
	applicationIntegrationStore,
	filterModulesByProvisioner,
	getAllInputOutputArtifacts
} from "../application-integration-store";

import { PipelineModuleMeta } from "./AddIntegrationPipelineWrapper.vue";
import AddNewModulePopOver from "./AddNewModulePopOver.vue";
import ArtifactListItem from "./ArtifactListItem.vue";
import ModuleReadOnlyInfo from "./ModuleReadOnlyInfo.vue";
import RemovePipelineModule from "./RemovePipelineModule.vue";
import SelectArtifactModulePopover from "./SelectArtifactModulePopover.vue";

export default defineComponent({
	name: "PipelineModulesDragList",

	components: {
		DragDropList,
		Container,
		Icon,
		Typography,
		Divider,
		SelectArtifactModulePopover,
		RemovePipelineModule,
		PopOver,
		AddNewModulePopOver,
		ModuleReadOnlyInfo,
		ArtifactListItem
	},

	props: {
		pipelineModules: {
			type: Array as PropType<PipelineModuleMeta[]>,
			required: true
		},

		outputArtifact: {
			type: Object as PropType<ArtifactMeta | null>,
			default: () => null
		},

		inputArtifact: {
			type: Object as PropType<ArtifactMeta | null>,
			default: () => null
		},

		integrationId: {
			type: String as PropType<string | null>,
			default: () => null
		},

		appId: {
			type: String,
			required: true
		},

		provisioner: {
			type: String as PropType<Provisioner>,
			required: true
		}
	},

	emits: ["update:inputArtifact", "update:outputArtifact", "update:pipelineModules"],

	data: () => ({
		isPopoverOpen: {} as Record<string, boolean>,
		moduleToEdit: null as pipelineModuleWithRefId | null,
		currentPipelineModuleRef: null as pipelineModuleReference | null,
		dragKey: 0
	}),

	computed: {
		internalPipelineModules: {
			get() {
				return this.pipelineModules;
			},

			set(newPipelineModules: PipelineModuleMeta[]) {
				this.$emit("update:pipelineModules", newPipelineModules);
			}
		},

		integration() {
			if (!this.integrationId) {
				return undefined;
			}

			const integrations = Object.values(applicationIntegrationStore.integrations)
				.map(i => i)
				.flat();

			return integrations.find(integration => integration.id === this.integrationId);
		},

		app() {
			const { projectApps } = applicationStore;
			return Object.keys(projectApps)
				.map(projectId => projectApps[projectId])
				.flat()
				.find(app => app?.id === this.appId);
		},

		allModules(): Record<string, pipelineModuleWithRefId[]> {
			return filterModulesByProvisioner(this.integration, this.provisioner);
		},

		allInputArtifacts() {
			return getAllInputOutputArtifacts(this.integration, this.app, ArtifactType.GitCode) ?? [];
		},

		allOutputArtifacts() {
			return (
				getAllInputOutputArtifacts(this.integration, this.app, ArtifactType.ContainerImage) ?? []
			);
		}
	},

	watch: {
		pipelineModules: {
			deep: true,

			handler() {
				this.pipelineModules.forEach((module, i) => {
					this.isPopoverOpen[module.name + i] = false;
				});
			}
		}
	},

	methods: {
		removePipelineFromList(pipelineToRemove: PipelineModuleMeta) {
			this.internalPipelineModules = this.internalPipelineModules.filter(
				module => module !== pipelineToRemove
			);
		},

		getIcon(module: pipelineModule) {
			return module.provisioner === "gcp" || module.provisioner === "azure"
				? `p-${module.provisioner}`
				: `p-aws-dark`;
		},

		addModuleToList(selectedModule: PipelineModuleToAdd, isEditing = false, order?: number) {
			const { module, moduleValues } = selectedModule;
			this.currentPipelineModuleRef = {
				inputs: moduleValues,
				moduleRefId: module.moduleRefId
			};

			const module_ = {
				data: { ...module },
				moduleValues,
				desc: module.description!,
				icon: this.getIcon(module),
				name: module.displayName!,
				order: order ?? this.internalPipelineModules.length - 1
			};

			if (!isEditing) {
				this.internalPipelineModules.push(module_);
			} else {
				// if we are editing, then we replace the existing with the new one
				this.internalPipelineModules.splice(module_.order, 1, module_);
			}

			const moduleNameIndex = module.displayName! + module_.order;
			this.isPopoverOpen[moduleNameIndex] = false;
		},

		openEditModulePopOver(index: number) {
			/**
			 * Use index because there could be duplicate module
			 * The only way to identify current one is either by the index or by order
			 */
			const module: PipelineModuleMeta = this.internalPipelineModules.at(index)!;
			const pipelineModuleRef = module.data as pipelineModule;
			const module_: pipelineModuleWithRefId = {
				...pipelineModuleRef,
				...{
					moduleRefId: `${pipelineModuleRef.provisioner}:${pipelineModuleRef.name}@${pipelineModuleRef.version}`,
					isAdded: true,
					icon: this.getIcon(pipelineModuleRef),
					type: "module"
				}
			};
			this.moduleToEdit = module_;

			if (module.moduleValues) {
				this.currentPipelineModuleRef = {
					moduleRefId: module_.moduleRefId,
					inputs: module.moduleValues
				};
			}
		},

		updatePipelineModule(moduleAddRef: PipelineModuleToAddRef) {
			// Updated the existing modules list in the drag component
			this.addModuleToList(
				{
					module: moduleAddRef.module,
					moduleValues: moduleAddRef.moduleValues
				},
				true, // Is editing flag,
				moduleAddRef.order // order of the module
			);

			// Reset the edit var & close the popover
			this.moduleToEdit = null;

			if (moduleAddRef.order !== undefined && moduleAddRef.module.displayName) {
				const moduleNameIndex = moduleAddRef.module.displayName + moduleAddRef.order;
				this.isPopoverOpen[moduleNameIndex] = false;
			} else if (moduleAddRef.module.displayName) {
				const idx = this.internalPipelineModules.findIndex(
					m => m.data?.name === moduleAddRef.module.displayName
				);
				const moduleNameIndex = moduleAddRef.module.displayName + (idx === -1 ? 0 : idx);
				this.isPopoverOpen[moduleNameIndex] = false;
			}
		},

		addInputArtifactToList(artifact: ArtifactMeta) {
			this.$emit("update:inputArtifact", artifact);
			this.isPopoverOpen[artifact.name] = false;
		},

		addOutputArtifactToList(artifact: ArtifactMeta) {
			this.$emit("update:outputArtifact", artifact);
			this.isPopoverOpen[artifact.name] = false;
		},

		removeInputArtifact() {
			this.$emit("update:inputArtifact", null);
		},

		removeOutputArtifact() {
			this.$emit("update:outputArtifact", null);
		},

		dragEnd() {
			// generate random value for dragKey to force re-render
			this.dragKey = getUniqueId();
		}
	}
});
</script>
