import { createRouter, createWebHistory, RouteLocationNormalized } from "vue-router";

import { getStore } from "@/modules/store/store-module";
import { TokenService } from "@/services/storage-service";
import { reportPageView } from "@/utils/analytics";

import { authStore } from "../auth/auth-store";
import { breadcrumbStore } from "../core/breadcrumb-store";
import { orgStore } from "../org/org-store";
import { userStore } from "../user/user-store";

class RouterModule {
	router = createRouter({
		history: createWebHistory(import.meta.env.BASE_URL),
		routes: []
	});

	$store = getStore();

	install() {
		this.registerBeforeEachHook();
	}

	getTitle(to: RouteLocationNormalized) {
		const nearestWithTitle = to.matched
			.slice()
			.reverse()
			.find(r => r.meta.title);

		return nearestWithTitle ? String(nearestWithTitle.meta.title) : null;
	}

	getUserId() {
		let userId = null;
		const userProfile = userStore.profile;

		if (userProfile?.id) {
			userId = userProfile.id;
		}

		return userId;
	}

	registerBeforeEachHook() {
		this.router.beforeEach((to, from, next) => {
			const hasAuthToken = Boolean(TokenService.getToken());
			let currentOrgId = orgStore.activeOrgId;
			const userId = this.getUserId();

			// Setting next route org id
			const routeOrgId = to.params.orgId as string;

			/**
			 * Check if currentOrgId is empty and route has orgId
			 * Eg: When user opens new tab with the org route in it
			 * 1) the user has an active session.
			 * 2) Since we are storing the active orgId in session storage
			 *    it will not hold previous tab active orgId.
			 */
			if (!currentOrgId && routeOrgId) {
				currentOrgId = routeOrgId;
				orgStore.SET_ACTIVE_ORG_ID(routeOrgId);
			}

			/**
			 * Fallback to user's default orgId
			 */
			const routerToOrgLanding = currentOrgId ? currentOrgId : userId;

			const title = this.getTitle(to);
			if (title) {
				document.title = title;
			}

			/**
			 * forceLogout is set for routes which handle
			 * user password reset and email verification.
			 */
			if (to.meta.forceLogout && hasAuthToken && !from.query.code) {
				authStore.USER_LOGOUT({
					forceLogout: true,
					isRedirect: false
				});
			}

			if (to.meta.requiresAuth && !hasAuthToken) {
				return next({
					name: "login",
					query: {
						redirect: to.path
					}
				});
			}

			if (to.meta.onlyWhenLoggedOut && hasAuthToken && routerToOrgLanding) {
				return next({
					name: "home",
					params: {
						orgId: routerToOrgLanding
					}
				});
			}

			return next();
		});

		this.router.afterEach((to, from) => {
			reportPageView(String(to.name) || to.fullPath);

			// Reset breadcrumbs set by other stores
			if (to.name !== from.name) {
				breadcrumbStore.RESET_BREADCRUMBS();
			}
		});
	}

	addWildCardRoute() {
		this.router.addRoute({
			path: "/:pathMatch(.*)*",
			redirect: { name: "login" }
		});
	}
}

export default new RouterModule();
