import { Router, RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
	{
		path: "/fs-access-demo",
		name: "fileSystemAccessDemo",
		component: () => import("./FileSystemAccessDemo.vue"),
		meta: {
			title: "File System Access Demo",
			requiresAuth: false
		},
		props: true
	}
];

export function registerDebugRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
