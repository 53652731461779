<script setup lang="ts">
import { PopOver, Container, Tab, Tabs, Tag, Typography } from "@cldcvr/flow-vue3";
import { PropType, computed, ref, toRefs } from "vue";

import CreateAndDeployApp from "@/modules/env-list/components/CreateAndDeployApp.vue";
import { ProjectTabName } from "@/modules/project-landing/project-types";
import { projectStore } from "@/modules/project-list/project-store";
import AddVariableContent from "@/modules/variables-list/AddVariableContent.vue";
import { environment } from "@/protocol/infra";

const props = defineProps({
	env: {
		type: Object as PropType<environment>,
		required: true
	},

	currentTab: {
		type: String as PropType<ProjectTabName>,
		required: true
	},

	appsCount: Number,
	variablesCount: Number,
	outputCount: Number
});

const { env } = toRefs(props);

const isDeployModalOpen = ref(false);
const isVariableModalOpen = ref(false);

const currentProject = computed(() => {
	return projectStore.projects[env.value.projId];
});

function handleApplicationAddition(event: PointerEvent) {
	event.stopPropagation();
	isDeployModalOpen.value = true;
}

function handleAddVariable(event: PointerEvent) {
	event.stopPropagation();
	isVariableModalOpen.value = true;
}

defineEmits(["change-tab"]);
</script>

<template>
	<Tabs>
		<Tab
			:selected="currentTab === 'settings'"
			width="300px"
			data-qa-env-settings-tab
			@click="$emit('change-tab', 'settings')"
		>
			<Container :gap="8" :padding="0">
				<Typography
					type="p1"
					:weight="currentTab === 'settings' ? 'medium' : 'light'"
					:color="currentTab === 'settings' ? 'dark' : 'default'"
					>Settings</Typography
				>
			</Container>
		</Tab>

		<Tab
			:selected="currentTab === 'applications'"
			width="300px"
			data-qa-env-applications-tab
			@click="$emit('change-tab', 'applications')"
		>
			<Container :gap="8" :padding="0">
				<Typography
					type="p1"
					:weight="currentTab === 'applications' ? 'medium' : 'light'"
					:color="currentTab === 'applications' ? 'dark' : 'default'"
					>App deployments</Typography
				>
				<Tag v-if="appsCount !== 0" size="small" shape="rounded">
					<Typography data-qa-env-applications-tab-count type="p3" color="light">{{
						appsCount
					}}</Typography>
				</Tag>

				<f-icon-button
					data-qa-add-application
					icon="i-plus"
					size="x-small"
					category="outline"
					@click="handleApplicationAddition"
				></f-icon-button>
			</Container>
		</Tab>

		<Tab
			:selected="currentTab === 'output'"
			width="300px"
			data-qa-env-applications-tab
			@click="$emit('change-tab', 'output')"
		>
			<Container :gap="8" :padding="0">
				<Typography
					type="p1"
					:weight="currentTab === 'output' ? 'medium' : 'light'"
					:color="currentTab === 'output' ? 'dark' : 'default'"
					>Output</Typography
				>
				<Tag size="small" shape="rounded">
					<Typography data-qa-env-output-tab-count type="p3" color="light">{{
						outputCount
					}}</Typography>
				</Tag>
			</Container>
		</Tab>

		<Tab
			:selected="currentTab === 'variables'"
			width="300px"
			data-qa-env-variables-tab
			@click="$emit('change-tab', 'variables')"
		>
			<Container :gap="8" :padding="0">
				<Typography
					type="p1"
					:weight="currentTab === 'variables' ? 'medium' : 'light'"
					:color="currentTab === 'variables' ? 'dark' : 'default'"
					>Variables</Typography
				>
				<Tag v-if="variablesCount" size="small" shape="rounded">
					<Typography data-qa-env-variables-tab-count type="p3" color="light">{{
						variablesCount
					}}</Typography>
				</Tag>

				<f-icon-button
					data-qa-add-variable
					icon="i-plus"
					size="x-small"
					category="outline"
					@click="handleAddVariable"
				></f-icon-button>
			</Container>
		</Tab>

		<PopOver target="[data-qa-add-variable]" :open="isVariableModalOpen">
			<template #content>
				<AddVariableContent
					:pre-selected-ids="[env.id]"
					@close-dialog="isVariableModalOpen = false"
				/>
			</template>
		</PopOver>

		<CreateAndDeployApp
			v-if="currentProject"
			target="[data-qa-add-application]"
			:project="currentProject"
			:environment="env"
			:open="isDeployModalOpen"
			@back="isDeployModalOpen = false"
			@close="isDeployModalOpen = false"
		/>
	</Tabs>
</template>
