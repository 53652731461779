import { Artifact, ArtifactSelector } from "@/protocol/common";

export function artitactToArtifactSelector(artifact: Artifact): ArtifactSelector {
	const artifactSelector: ArtifactSelector = {
		id: artifact.id
	};

	if (artifact.gitCode) {
		artifactSelector.gitCode = {
			type: artifact.gitCode.type,
			identifier: artifact.gitCode.identifier
		};
	}

	if (artifact.containerImage) {
		artifactSelector.containerImage = {
			reference: artifact.containerImage.reference,
			digest: artifact.containerImage.digest
		};
	}

	if (artifact.bucketObject) {
		artifactSelector.bucketObject = {
			objectsPath: artifact.bucketObject.objectsPath
		};
	}

	if (artifact.files) {
		artifactSelector.files = artifact.files;
	}

	return artifactSelector;
}

export function getArtifactSubtitle(artifactSelectoe?: ArtifactSelector) {
	if (!artifactSelectoe) {
		return undefined;
	}

	if (artifactSelectoe.gitCode?.identifier) {
		return `Git revision: ${artifactSelectoe.gitCode.identifier}`;
	}

	if (artifactSelectoe.containerImage?.reference) {
		return `Container tag: ${artifactSelectoe.containerImage.reference}`;
	}
}
