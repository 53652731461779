<template>
	<Container :padding="20" align="center" :gap="0">
		<template v-for="(classification, idx) of classifications" :key="classification.id">
			<EnvElementCard :env-classification="classification" />
			<ArrowPointer v-if="idx !== classifications.length - 1" />
		</template>
	</Container>
</template>

<script lang="ts">
import { Container } from "@cldcvr/flow-vue3";
import { defineComponent, PropType } from "vue";

import { classification } from "@/protocol/infra";
import ArrowPointer from "@/shared/components/create-env-set/ArrowPointer.vue";

import EnvElementCard from "./EnvElementCard.vue";

export default defineComponent({
	name: "EnvSetElements",

	components: {
		EnvElementCard,
		ArrowPointer,
		Container
	},

	props: {
		classifications: {
			type: Array as PropType<classification[]>,
			required: true
		}
	}
});
</script>
